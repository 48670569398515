import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Logo from "./Logo";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: "#fff",
  borderTop: "6px solid #000",
  borderLeft: "6px solid #000",
  borderRight: "6px solid #000",
  flexDirection: "row",
  gap: theme.spacing(2),
  borderRadius: "24px 24px 0 0",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

  ".title, .logo img": {
    height: 40,
  },

  [theme.breakpoints.down("md")]: {
    width: "80%",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),

    ".title": {
      width: "100%",
      maxWidth: 300,
      height: "auto",
      objectFit: "scale-down",
    },
    ".logo img": {
      height: 40,
      objectFit: "scale-down",
    },
  },
}));

const PageHeader = ({ titleImg, titleAlt }) => {
  return (
    <Wrapper className="pageHeader">
      <Logo variant="text" />
      {titleImg != null && (
        <img src={titleImg} alt={titleAlt} className="title" />
      )}
    </Wrapper>
  );
};

export default PageHeader;
