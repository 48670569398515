import React from "react";
import StatWidget from "../molecules/StatWidget";
import { IoShield } from "react-icons/io5";
import { getGuildRating } from "helpers/guild";

const PerformanceGuild = ({ averageMMR, avgMMRRank }) => {
  return (
    <StatWidget
      title="Guild Performance"
      icon={<IoShield />}
      stats={[
        {
          label: "Guild Rating",
          amount: getGuildRating(averageMMR),
        },
        {
          label: "Avg Stars",
          amount: averageMMR,
          rank: avgMMRRank,
          addRankSuffix: true,
        },
      ]}
    />
  );
};

export default PerformanceGuild;
