import React from "react";
import { Typography } from "@mui/material";

const PageSuperTitle = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize="16px"
      fontWeight="600"
      color="text.regular"
    >
      {children}
    </Typography>
  );
};

export default PageSuperTitle;
