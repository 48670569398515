import React from "react";
import * as bodyPartIcons from "components/Axie/icons/bodyPartIcons";
import { Box } from "@mui/material";

const BodyPartIcon = ({ part, cardClass, handleOpenCardDialog }) => {
  const toDisplay =
    cardClass == null ? `${part}` : `${part}_${cardClass.toLowerCase()}`;

  return (
    <Box
      onClick={() => handleOpenCardDialog(part)}
      sx={{
        "&:hover": {
          cursor: "pointer",
          "& svg": {
            transform: "scale(1.3)",
          },
        },
        "& svg": {
          transition: "0.2s transform ease",
          height: 32,
          width: "auto",
        },
      }}
    >
      {bodyPartIcons[toDisplay]}
    </Box>
  );
};

export default BodyPartIcon;
