import { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { connect } from "react-redux";
import {
  updateGuildSearchQuery,
  setOpenEditScholarDialog,
  setOpenDeleteScholarDialog,
  updateNumberOfScholarsPerPage,
} from "store/guildDashboard/actions/guildDashboardActions";
import { DataGrid } from "@mui/x-data-grid";
import ImportScholarsDialog from "./ImportScholarsDialog";
import EditScholarDialog from "./EditScholarDialog";
import * as scholarSelectors from "store/guildDashboard/selectors/scholar";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import DeleteScholarDialog from "./DeleteScholarDialog";
import Card from "components/Card/Card";
import Controls from "../molecules/Controls";
import TabPanel from "components/Tabs/TabPanel";
import Tabs from "components/Tabs";

const ScholarTables = ({
  currentPage,
  setCurrentPage,
  activeTab,
  setActiveTab,
  sortModel,
  setSortModel,
  scholars,
  scholarsToDisplay,
  scholarToModify,
  searchQuery,
  updateGuildSearchQuery,
  numberOfScholarsPerPage,
  updateNumberOfScholarsPerPage,
  allGuildTeamIds,
}) => {
  const [validScholarsToImport, setValidScholarsToImport] = useState([]);
  const [invalidScholarsToImport, setInvalidScholarsToImport] = useState([]);
  const [openImportScholarsDialog, setOpenImportScholarsDialog] =
    useState(false);
  const id = "scholar-tabpanel";
  return (
    <Stack spacing={{ xs: 2, md: 4 }} position="relative">
      <ImportScholarsDialog
        open={openImportScholarsDialog}
        setOpen={setOpenImportScholarsDialog}
        validScholars={validScholarsToImport}
        invalidScholars={invalidScholarsToImport}
      />
      {scholarToModify && <EditScholarDialog />}
      {scholarToModify && <DeleteScholarDialog />}
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabsId={id}
            tabs={["Arena", "SLP", "Watchlist"]}
            ariaLabel="Scholar Table"
            onClick={() => setCurrentPage(0)}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controls
            scholars={scholars}
            scholarsToDisplay={scholarsToDisplay}
            searchQuery={searchQuery}
            updateGuildSearchQuery={updateGuildSearchQuery}
            setValidScholarsToImport={setValidScholarsToImport}
            setInvalidScholarsToImport={setInvalidScholarsToImport}
            setOpenImportScholarsDialog={setOpenImportScholarsDialog}
            allGuildTeamIds={allGuildTeamIds}
          />
        </Grid>
      </Grid>

      <Card>
        <TabPanel value={activeTab} index={0} id={id}>
          <DataGrid
            autoHeight
            rows={scholarsToDisplay?.rowsArena}
            columns={scholarsToDisplay?.columnsArena}
            pageSize={numberOfScholarsPerPage}
            onPageSizeChange={(newPageSize) =>
              updateNumberOfScholarsPerPage(newPageSize)
            }
            rowsPerPageOptions={[20, 50, 100]}
            sortModel={sortModel.ARENA}
            onSortModelChange={(model) => setSortModel("ARENA", model)}
            disableColumnMenu={true}
            page={currentPage.ARENA}
            onPageChange={(newPage) => setCurrentPage("ARENA", newPage)}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1} id={id}>
          <DataGrid
            autoHeight
            rows={scholarsToDisplay?.rowsSLP}
            columns={scholarsToDisplay?.columnsSLP}
            pageSize={numberOfScholarsPerPage}
            onPageSizeChange={(newPageSize) =>
              updateNumberOfScholarsPerPage(newPageSize)
            }
            rowsPerPageOptions={[20, 50, 100]}
            sortModel={sortModel.SLP}
            onSortModelChange={(model) => setSortModel("SLP", model)}
            disableColumnMenu={true}
            page={currentPage.SLP}
            onPageChange={(newPage) => setCurrentPage("SLP", newPage)}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2} id={id}>
          <DataGrid
            autoHeight
            rows={scholarsToDisplay?.rowsWatchlist}
            columns={scholarsToDisplay?.columnsWatchlist}
            pageSize={numberOfScholarsPerPage}
            onPageSizeChange={(newPageSize) =>
              updateNumberOfScholarsPerPage(newPageSize)
            }
            rowsPerPageOptions={[20, 50, 100]}
            sortModel={sortModel.WATCHLIST}
            onSortModelChange={(model) => setSortModel("WATCHLIST", model)}
            disableColumnMenu={true}
            page={currentPage.WATCHLIST}
            onPageChange={(newPage) => setCurrentPage("WATCHLIST", newPage)}
          />
        </TabPanel>
      </Card>
    </Stack>
  );
};

const mapDispatchToProps = {
  updateGuildSearchQuery,
  setOpenEditScholarDialog,
  setOpenDeleteScholarDialog,
  updateNumberOfScholarsPerPage,
};

const mapStateToProps = (state) => ({
  scholarsToDisplay: scholarSelectors.getScholarsToDisplay(state),
  scholarToModify: scholarSelectors.getScholarToModify(state),
  scholars: state.guildDashboard.guild.scholars,
  searchQuery: state.guildDashboard.guild.searchQuery,
  openEditScholarDialog: state.guildDashboard.openEditScholarDialog,
  openDeleteScholarDialog: state.guildDashboard.openDeleteScholarDialog,
  numberOfScholarsPerPage: state.guildDashboard.config.numberOfScholarsPerPage,
  allGuildTeamIds: teamSelectors.getAllTeamIds(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarTables);
