import { Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#252425",
    color: "#ffffff",
    padding: 8,
    maxWidth: 160,
    fontSize: "12px",
  },
}));

const InterDungeonTooltip = ({ children, title, styles }) => {
  return (
    <CustomTooltip sx={{ ...styles }} title={title} placement="top-start">
      {children}
    </CustomTooltip>
  );
};

export default InterDungeonTooltip;
