import { getPlayerAvatarImageUrls } from "helpers/arena";
import { Stack, styled } from "@mui/material";

const Wrapper = styled(Stack)(({ width }: { width: number }) => ({
  width,
  height: width,
  minWidth: width,
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
}));

const FrameImage = styled(Stack)(({ frame }: { frame: string }) => ({
  backgroundImage: `url(${frame})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  zIndex: 2,
}));

const AvatarImage = styled(Stack)(
  ({ avatar, width }: { avatar: string; width: number }) => ({
    backgroundImage: `url(${avatar})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: (width / 120) * 74,
    height: (width / 120) * 74,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  })
);

const Avatar = ({
  encodedAvatar,
  width = 120,
}: {
  encodedAvatar: string;
  width?: number;
}) => {
  const { avatar, frame } = getPlayerAvatarImageUrls(encodedAvatar);

  return (
    <Wrapper width={width}>
      <FrameImage frame={frame} />
      <AvatarImage avatar={avatar} width={width} />
    </Wrapper>
  );
};

export default Avatar;
