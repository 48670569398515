import { Stack, useTheme } from "@mui/material";
import Title from "./Title";

const StatWell = ({ children, title }) => {
  const theme = useTheme();
  return (
    <Stack
      gap={2}
      sx={{
        backgroundColor: theme.palette.card.pop1,
        borderRadius: "8px",
        padding: "32px",
        height: "100%",
      }}
    >
      <Title textAlign="left">{title}</Title>
      {children}
    </Stack>
  );
};

export default StatWell;
