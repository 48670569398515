import React from "react";
import { IoMenu } from "react-icons/io5";
import { IconButton, Box } from "@mui/material";

const MenuToggle = ({ show, handleToggleDrawer }) => {
  return show ? (
    <Box sx={{ paddingLeft: 1, paddingTop: 1 }}>
      <IconButton size="large" onClick={handleToggleDrawer}>
        <IoMenu />
      </IconButton>
    </Box>
  ) : (
    <></>
  );
};

export default MenuToggle;
