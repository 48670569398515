import React from "react";
import { Button as MuiButton } from "@mui/material";

const Button = ({ children, ...props }) => {
  return (
    <MuiButton
      disableRipple={false}
      sx={{
        color: "#ffffff",
        fontSize: "1rem",
        background: (theme) =>
          `linear-gradient(${theme.palette.colors.axieQuest.blue.light}, ${theme.palette.colors.axieQuest.blue.medium})`,
        border: (theme) =>
          `2px solid ${theme.palette.colors.axieQuest.blue.medium}`,
        boxShadow: (theme) =>
          `0 4px 0 ${theme.palette.colors.axieQuest.blue.dark}`,
        padding: "16px 24px 14px 24px",
        lineHeight: 1,
        borderRadius: "12px",
        transition: "0.2s all ease",
        "&:hover": {
          transform: "scale(1.1)",
          boxShadow: (theme) =>
            `8px 8px 0 ${theme.palette.colors.axieQuest.blue.light}`,
        },
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
