export const FETCH_PLAYER_CLASSIC = "FETCH_PLAYER_CLASSIC";
export const FETCH_PLAYER_CLASSIC_STARTED = "FETCH_PLAYER_CLASSIC_STARTED";
export const FETCH_PLAYER_CLASSIC_SUCCEEDED = "FETCH_PLAYER_CLASSIC_SUCCEEDED";
export const FETCH_PLAYER_CLASSIC_FAILED = "FETCH_PLAYER_CLASSIC_FAILED";

export const FETCH_PLAYER_ORIGIN = "FETCH_PLAYER_ORIGIN";
export const FETCH_PLAYER_ORIGIN_STARTED = "FETCH_PLAYER_ORIGIN_STARTED";
export const FETCH_PLAYER_ORIGIN_SUCCEEDED = "FETCH_PLAYER_ORIGIN_SUCCEEDED";
export const FETCH_PLAYER_ORIGIN_FAILED = "FETCH_PLAYER_ORIGIN_FAILED";

export const FETCH_PLAYER_CLASSIC_BATTLES = "FETCH_PLAYER_CLASSIC_BATTLES";
export const FETCH_PLAYER_CLASSIC_BATTLES_STARTED =
  "FETCH_PLAYER_CLASSIC_BATTLES_STARTED";
export const FETCH_PLAYER_CLASSIC_BATTLES_SUCCEEDED =
  "FETCH_PLAYER_CLASSIC_BATTLES_SUCCEEDED";
export const FETCH_PLAYER_CLASSIC_BATTLES_FAILED =
  "FETCH_PLAYER_CLASSIC_BATTLES_FAILED";

export const FETCH_PLAYER_ORIGIN_BATTLES = "FETCH_PLAYER_ORIGIN_BATTLES";
export const FETCH_PLAYER_ORIGIN_BATTLES_STARTED =
  "FETCH_PLAYER_ORIGIN_BATTLES_STARTED";
export const FETCH_PLAYER_ORIGIN_BATTLES_SUCCEEDED =
  "FETCH_PLAYER_ORIGIN_BATTLES_SUCCEEDED";
export const FETCH_PLAYER_ORIGIN_BATTLES_FAILED =
  "FETCH_PLAYER_ORIGIN_BATTLES_FAILED";

export const UPDATE_PLAYER_CLASSIC_BATTLES_PAGE =
  "UPDATE_PLAYER_CLASSIC_BATTLES_PAGE";
export const UPDATE_PLAYER_ORIGIN_BATTLES_PAGE =
  "UPDATE_PLAYER_ORIGIN_BATTLES_PAGE";
