import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2023-1-30</Date>
      <Change type="new">
        Added the Metalend Estimated Price to our{" "}
        <CustomLink to="/inspector" endSpace={false}>
          Axie Inspector
        </CustomLink>
        .
      </Change>
    </Entry>
  );
};

export default Changes;
