import React from "react";
import { useTheme, Box } from "@mui/material";

const PageGrid = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.gradient,
        p: "16px 4px",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "16px",
        [theme.breakpoints.up("md")]: {
          p: 2,
          gridTemplateColumns: "240px 1fr",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default PageGrid;
