import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { reverseKeyValue } from "helpers/display";
import Header from "./organisms/Header";
import Tabs from "./organisms/Tabs";
import TabPanels from "./organisms/TabPanels";
import {
  addFavoriteCards,
  addFavoriteRunes,
  addFavoriteCharms,
  addFavoriteCurses,
  addFavoriteTools,
  addFavoriteRevengeCards,
} from "store/explorer/explorerActions";
import { calculateNumberOfFavorites } from "store/explorer/selectors";
import { getAllV3Data } from "store/data/dataSelectors";

export const idxToPageMapping = {
  0: "cards",
  1: "curses",
  2: "tools",
  3: "revenge-cards",
  4: "runes",
  5: "charms",
  6: "status-effects",
  7: "favorites",
};

const Explorer = ({
  loading,
  failed,
  data,
  addFavoriteCards,
  addFavoriteRunes,
  addFavoriteCharms,
  addFavoriteCurses,
  addFavoriteTools,
  addFavoriteRevengeCards,
  numberOfFavorites,
}) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [infoDialogIsOpen, setInfoDialogIsOpen] = useState(false);
  const pageToIdxMapping = reverseKeyValue(idxToPageMapping);

  useEffect(() => {
    const idx = pageToIdxMapping[slug];
    setTab(parseInt(idx) || 0);
  }, [slug, pageToIdxMapping]);

  const handleSetTab = (newTab) => {
    setTab(newTab);
    navigate("/explorer/" + idxToPageMapping[newTab]);
  };

  const [initialLoad, setInitialLoad] = useState(true);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  useEffect(() => {
    const addFavoritesGeneric = (items, data, keyToMatch, callback) => {
      if (items !== "null" && items !== "") {
        const itemData = items.split(",").reduce((arr, item) => {
          const current = data.find((i) => i[keyToMatch] === item);
          if (current != null) {
            arr.push(current);
          }
          return arr;
        }, []);
        callback(itemData);
      }
    };

    if (initialLoad && loading === false && failed === false) {
      setInitialLoad(false);

      const cards = decodeURI(query.get("cards"));
      const runes = decodeURI(query.get("runes"));
      const charms = decodeURI(query.get("charms"));
      const curses = decodeURI(query.get("curses"));
      const tools = decodeURI(query.get("tools"));
      const revenge = decodeURI(query.get("revenge-cards"));

      addFavoritesGeneric(cards, data.cards, "partId", addFavoriteCards);
      addFavoritesGeneric(runes, data.runes, "name", addFavoriteRunes);
      addFavoritesGeneric(charms, data.charms, "name", addFavoriteCharms);
      addFavoritesGeneric(curses, data.curseCards, "name", addFavoriteCurses);
      addFavoritesGeneric(tools, data.toolCards, "name", addFavoriteTools);
      addFavoritesGeneric(
        revenge,
        data.revengeCards,
        "name",
        addFavoriteRevengeCards
      );
    }
  }, [
    initialLoad,
    loading,
    failed,
    query,
    data,
    addFavoriteCards,
    addFavoriteRunes,
    addFavoriteCharms,
    addFavoriteCurses,
    addFavoriteTools,
    addFavoriteRevengeCards,
  ]);

  return (
    <>
      <Header
        pageSlug={idxToPageMapping[tab]}
        infoDialogIsOpen={infoDialogIsOpen}
        setInfoDialogIsOpen={setInfoDialogIsOpen}
      />
      <Tabs
        tab={tab}
        setTab={handleSetTab}
        numberOfFavorites={numberOfFavorites}
      />
      <TabPanels
        tab={tab}
        setInfoDialogIsOpen={setInfoDialogIsOpen}
        loading={loading}
      />
    </>
  );
};

const mapDispatchToProps = {
  addFavoriteCards,
  addFavoriteRunes,
  addFavoriteCharms,
  addFavoriteCurses,
  addFavoriteTools,
  addFavoriteRevengeCards,
};

const mapStateToProps = (state) => ({
  loading: state.data.fetchingAxieCardsV3,
  failed: state.data.fetchingAxieCardsV3Failed,
  data: getAllV3Data(state),
  numberOfFavorites: calculateNumberOfFavorites(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);
