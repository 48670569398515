import React from "react";
import { Typography } from "@mui/material";

const NoneFoundMessage = ({ children, fontSize = 26 }) => {
  return (
    <Typography
      variant="span"
      color="text.muted"
      fontSize={fontSize}
      fontWeight="700"
      textAlign="center"
    >
      {children}
    </Typography>
  );
};

export default NoneFoundMessage;
