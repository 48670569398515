import React from "react";
import { useTheme, Typography } from "@mui/material";
import {
  displayShortenedRoninAddress,
  makeMarketplaceURLFromRoninAddress,
} from "helpers/display";
import { convertToRoninAddress } from "helpers/cleaning";
import CustomLink from "components/CustomLink/CustomLink";

const RoninAddress = ({ address }) => {
  const theme = useTheme();

  if (address == null) return null;

  return (
    <CustomLink
      href={makeMarketplaceURLFromRoninAddress(convertToRoninAddress(address))}
      style={{
        transition: "0.1s opacity ease",
        border: "none",
        padding: "4px 6px",
        borderRadius: "4px",
        background: theme.palette.card.pop2,
        display: "flex",
        opacity: 0.5,
        "&:hover": { border: "none", opacity: 1 },
      }}
    >
      <Typography
        variant="span"
        fontSize="11px"
        color="text.muted"
        fontWeight="600"
      >
        ronin:{displayShortenedRoninAddress(address)}
      </Typography>
    </CustomLink>
  );
};

export default RoninAddress;
