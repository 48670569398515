import React from "react";
import { useTheme, Typography, Stack } from "@mui/material";
import CapsuleLabel from "../atoms/CapsuleLabel";
import { useLocation, NavLink } from "react-router-dom";

const DropdownItem = ({ item, children, colorOverrides, onClick }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isActive = pathname.includes(item.to);

  return (
    <NavLink
      to={item.to}
      onClick={onClick}
      style={{ padding: 0, whiteSpace: "nowrap" }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          fontSize="14px"
          sx={{
            color: colorOverrides?.navigation?.dropdown?.text
              ? colorOverrides?.navigation?.dropdown?.text
              : isActive
              ? theme.palette.text.primary
              : theme.palette.text.regular,
            "&:hover": {
              color: colorOverrides?.navigation?.dropdown?.textHover
                ? colorOverrides?.navigation?.dropdown?.textHover
                : theme.palette.text.primary,
            },
            fontWeight:
              isActive && colorOverrides?.navigation?.dropdown?.fontWeight
                ? colorOverrides?.navigation?.dropdown?.fontWeight
                : isActive
                ? 700
                : 600,
            transition: "0.2s all ease",
            lineHeight: "44px",
          }}
        >
          {children}
        </Typography>

        <CapsuleLabel>{item.label}</CapsuleLabel>
      </Stack>
    </NavLink>
  );
};

export default DropdownItem;
