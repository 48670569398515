import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";

const FilterAxiePurity = ({ purity, setPurity, style }) => {
  return purity != null && setPurity != null ? (
    <CustomMultiSelect
      label="Purity"
      value={purity}
      setValue={setPurity}
      options={["1", "2", "3", "4", "5", "6"]}
      style={style}
    />
  ) : (
    <></>
  );
};

export default FilterAxiePurity;
