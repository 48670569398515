import * as types from "./explorerActionTypes";
import produce from "immer";
import {
  initialState,
  initialCharmFilters,
  initialRuneFilters,
  initialCardFilters,
  initialCurseFilters,
  initialToolFilters,
  initialStatusEffectFilters,
  initialRevengeCardFilters,
} from "./initialState";

export const explorerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // ---------------------- FAVORITE CARDS
      case types.EXPLORER_FAVORITE_CARD: {
        const index = draft.favoriteCards.findIndex(
          (i) => i.partId === action?.card?.partId
        );
        if (index !== undefined && index === -1) {
          draft.favoriteCards.push(action.card);
        }
        break;
      }
      case types.EXPLORER_UNFAVORITE_CARD: {
        const index = draft.favoriteCards.findIndex(
          (i) => i.partId === action?.card?.partId
        );
        if (index !== undefined && index !== -1) {
          draft.favoriteCards.splice(index, 1);
        }
        break;
      }

      // ---------------------- FAVORITE CURSES
      case types.EXPLORER_FAVORITE_CURSE: {
        const index = draft.favoriteCurses.findIndex(
          (i) => i.name === action?.curse?.name
        );
        if (index !== undefined && index === -1) {
          draft.favoriteCurses.push(action.curse);
        }
        break;
      }
      case types.EXPLORER_UNFAVORITE_CURSE: {
        const index = draft.favoriteCurses.findIndex(
          (i) => i.name === action?.curse?.name
        );
        if (index !== undefined && index !== -1) {
          draft.favoriteCurses.splice(index, 1);
        }
        break;
      }

      // ---------------------- FAVORITE TOOLS
      case types.EXPLORER_FAVORITE_TOOL: {
        const index = draft.favoriteTools.findIndex(
          (i) => i.name === action?.tool?.name
        );
        if (index !== undefined && index === -1) {
          draft.favoriteTools.push(action.tool);
        }
        break;
      }
      case types.EXPLORER_UNFAVORITE_TOOL: {
        const index = draft.favoriteTools.findIndex(
          (i) => i.name === action?.tool?.name
        );
        if (index !== undefined && index !== -1) {
          draft.favoriteTools.splice(index, 1);
        }
        break;
      }

      // ---------------------- FAVORITE RUNES
      case types.EXPLORER_FAVORITE_RUNE: {
        const index = draft.favoriteRunes.findIndex(
          (i) => i.name === action?.rune?.name
        );
        if (index !== undefined && index === -1) {
          draft.favoriteRunes.push(action.rune);
        }
        break;
      }
      case types.EXPLORER_UNFAVORITE_RUNE: {
        const index = draft.favoriteRunes.findIndex(
          (i) => i.name === action?.rune?.name
        );
        if (index !== undefined && index !== -1) {
          draft.favoriteRunes.splice(index, 1);
        }
        break;
      }

      // ---------------------- FAVORITE CHARMS
      case types.EXPLORER_FAVORITE_CHARM: {
        const index = draft.favoriteCharms.findIndex(
          (i) => i.name === action?.charm?.name
        );
        if (index !== undefined && index === -1) {
          draft.favoriteCharms.push(action.charm);
        }
        break;
      }
      case types.EXPLORER_UNFAVORITE_CHARM: {
        const index = draft.favoriteCharms.findIndex(
          (i) => i.name === action?.charm?.name
        );
        if (index !== undefined && index !== -1) {
          draft.favoriteCharms.splice(index, 1);
        }
        break;
      }

      // ---------------------- FAVORITE REVENGE CARDS
      case types.EXPLORER_FAVORITE_REVENGE_CARD: {
        const index = draft.favoriteRevengeCards.findIndex(
          (i) => i.id === action?.revengeCard?.id
        );
        if (index !== undefined && index === -1) {
          draft.favoriteRevengeCards.push(action.revengeCard);
        }
        break;
      }
      case types.EXPLORER_UNFAVORITE_REVENGE_CARD: {
        const index = draft.favoriteRevengeCards.findIndex(
          (i) => i.id === action?.revengeCard?.id
        );
        if (index !== undefined && index !== -1) {
          draft.favoriteRevengeCards.splice(index, 1);
        }
        break;
      }

      // ---------------------- ADD BULK FAVORITES
      case types.EXPLORER_ADD_FAVORITE_CARDS:
        draft.favoriteCards = action.favorites;
        break;

      case types.EXPLORER_ADD_FAVORITE_RUNES:
        draft.favoriteRunes = action.favorites;
        break;

      case types.EXPLORER_ADD_FAVORITE_CHARMS:
        draft.favoriteCharms = action.favorites;
        break;

      case types.EXPLORER_ADD_FAVORITE_CURSES:
        draft.favoriteCurses = action.favorites;
        break;

      case types.EXPLORER_ADD_FAVORITE_TOOLS:
        draft.favoriteTools = action.favorites;
        break;

      case types.EXPLORER_ADD_FAVORITE_REVENGE_CARDS:
        draft.favoriteRevengeCards = action.favorites;
        break;

      // ---------------------- UPDATE FILTERS
      case types.EXPLORER_UPDATE_CARD_FILTERS:
        draft.cardFilters = action.filters;
        break;

      case types.EXPLORER_UPDATE_RUNE_FILTERS:
        draft.runeFilters = action.filters;
        break;

      case types.EXPLORER_UPDATE_CHARM_FILTERS:
        draft.charmFilters = action.filters;
        break;

      case types.EXPLORER_UPDATE_CURSE_FILTERS:
        draft.curseFilters = action.filters;
        break;

      case types.EXPLORER_UPDATE_TOOL_FILTERS:
        draft.toolFilters = action.filters;
        break;

      case types.EXPLORER_UPDATE_STATUS_EFFECT_FILTERS:
        draft.statusEffectFilters = action.filters;
        break;

      case types.EXPLORER_UPDATE_REVENGE_CARD_FILTERS:
        draft.revengeCardFilters = action.filters;
        break;

      // ---------------------- RESET FILTERS
      case types.EXPLORER_RESET_CARD_FILTERS:
        draft.cardFilters = { ...draft.cardFilters, ...initialCardFilters };
        break;

      case types.EXPLORER_RESET_RUNE_FILTERS:
        draft.runeFilters = { ...draft.runeFilters, ...initialRuneFilters };
        break;

      case types.EXPLORER_RESET_CHARM_FILTERS:
        draft.charmFilters = { ...draft.charmFilters, ...initialCharmFilters };
        break;

      case types.EXPLORER_RESET_CURSE_FILTERS:
        draft.curseFilters = { ...draft.curseFilters, ...initialCurseFilters };
        break;

      case types.EXPLORER_RESET_TOOL_FILTERS:
        draft.toolFilters = { ...draft.toolFilters, ...initialToolFilters };
        break;

      case types.EXPLORER_RESET_STATUS_EFFECT_FILTERS:
        draft.statusEffectFilters = {
          ...draft.statusEffectFilters,
          ...initialStatusEffectFilters,
        };
        break;

      case types.EXPLORER_RESET_REVENGE_CARD_FILTERS:
        draft.revengeCardFilters = {
          ...draft.revengeCardFilters,
          ...initialRevengeCardFilters,
        };
        break;

      // ---------------------- CLEAR FAVORITES
      case types.EXPLORER_CLEAR_ALL_FAVORITES:
        draft.favoriteCards = [];
        draft.favoriteRunes = [];
        draft.favoriteCharms = [];
        draft.favoriteCurses = [];
        draft.favoriteTools = [];
        draft.favoriteRevengeCards = [];
        break;

      default:
        break;
    }
  });

export default explorerReducer;
