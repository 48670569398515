import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import Text from "components/Typography/Text";
import LargeCapsuleLabel from "pages/Inspector/atoms/LargeCapsuleLabel";

const Rarity = ({ axie }) => {
  const theme = useTheme();
  const { rarity, isOrigin, numberOfMysticParts } = axie;

  return (
    <Stack gap={2}>
      <SectionLabel>Rarity</SectionLabel>

      {rarity.loading ? (
        <LargeCapsuleLabel backgroundColor={theme.palette.card.background}>
          <SectionLabel color="text.bright">Loading...</SectionLabel>
        </LargeCapsuleLabel>
      ) : (
        <LargeCapsuleLabel backgroundColor={rarity.background}>
          <SectionLabel color="text.bright">{rarity.label}</SectionLabel>
          <Description
            numberOfSimilarAxies={rarity.numberOfSimilarAxies}
            axieClass={axie.class}
          />
        </LargeCapsuleLabel>
      )}

      {isOrigin && (
        <Stack gap={1}>
          <LargeCapsuleLabel backgroundColor={theme.palette.colors.axie.origin}>
            <SectionLabel color="text.bright">Origin</SectionLabel>
          </LargeCapsuleLabel>

          {numberOfMysticParts > 0 && (
            <LargeCapsuleLabel
              backgroundColor={theme.palette.colors.axie.mystic}
            >
              <SectionLabel color="text.bright">{`${numberOfMysticParts} Mystic`}</SectionLabel>
            </LargeCapsuleLabel>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const Description = ({ numberOfSimilarAxies, axieClass }) => (
  <Text>
    <Typography variant="span" fontWeight={800}>
      {numberOfSimilarAxies.toLocaleString()}
    </Typography>
    {` ${axieClass}${
      numberOfSimilarAxies === 1 ? " has" : "s have"
    } this cardset`}
  </Text>
);

export default Rarity;
