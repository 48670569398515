import {
  AXIE_TECH_TAGLINE,
  AXIE_TECH_TITLE,
  AXIE_TECH_VIP_MEMBERSHIP_TAGLINE,
} from "config";

export const augmentTitle = (title) => `${title} | ${AXIE_TECH_TITLE}`;

export const seo = {
  explorer: {
    title: augmentTitle("Explorer"),
    description:
      "Explore the Cards, Runes and Charms in Axie Infinity: Origins.",
  },
  terms: {
    title: augmentTitle("Terms and Conditions"),
    description: AXIE_TECH_TAGLINE,
  },
  privacyPolicy: {
    title: augmentTitle("Privacy Policy"),
    description: AXIE_TECH_TAGLINE,
  },
  indesCoaching: {
    title: augmentTitle("Indes Private Coaching"),
    description:
      "Up your game by scheduling a private coaching session with #1 Axie Infinity e-sports player, Indes.",
  },
  axieTechCoaching: {
    title: augmentTitle("Axie Tech Coaching"),
    description:
      "Get expert Axie advice by scheduling a session with an Axie Tech Core Partner.",
  },
  originsLeaderboard: {
    title: augmentTitle("Origins Leaderboard"),
    description:
      "The top 100 Axie Infinity: Origins arena players ranked by Victory Stars.",
  },
  classicLeaderboard: {
    title: augmentTitle("Classic Leaderboard"),
    description:
      "The top 100 Axie Infinity Classic arena players ranked by MMR.",
  },
  guildLeaderboards: {
    title: augmentTitle("Top 25 VIP Guilds"),
    description:
      "The top Axie Tech VIP guilds ranked by average daily SLP earnings per player and average player arena victory stars.",
  },
  guildBattleLeaderboard: {
    title: augmentTitle("Guild Battle Leaderboard"),
    description:
      "Register your guild team and compete for AXS prizes! Do your players have what it takes?",
  },
  playerLeaderboards: {
    title: augmentTitle("Top 200 VIP Players"),
    description:
      "The top Axie Tech VIP players by average daily SLP earnings and arena victory stars.",
  },
  arenaPlayer: {
    title: augmentTitle("Player Profile"),
    description:
      "An arena player profile page showing current rank and points, favorite team, battle history and more!",
  },
  teamBuilder: {
    title: augmentTitle("Axie Team Builder"),
    description:
      "Build and share your team of 3 Axies, including Cards, Charms and Runes.",
  },
  priceFloors: {
    title: augmentTitle("Price Floors"),
    description:
      "Current floor prices on the Axie Infinity marketplace. Filter by class to spot deals and identify market movements.",
  },
  recentlyListed: {
    title: augmentTitle("Recently Listed Axies"),
    description:
      "The most recently listed auctions on the Axie Infinity marketplace. Filter by class and stats to spot great deals!",
  },
  recentlySold: {
    title: augmentTitle("Recently Sold Axies"),
    description:
      "The most recently sold auctions on the Axie Infinity marketplace. Filter by class and stats to spot marketplace trends!",
  },
  breedingCosts: {
    title: augmentTitle("Breeding Costs"),
    description:
      "Total costs for breeding virgin axies using live ETH, AXS & SLP prices from Coingecko.",
  },
  guildEarningsCalculator: {
    title: augmentTitle("Guild Earnings Calculator"),
    description:
      "Calculate estimated daily, weekly and monthly earnings for players and guild managers in Axie Infinity.",
  },
  breedingCalculator: {
    title: augmentTitle("Breeding Calculator"),
    description:
      "Calculate the expected profit or loss for your desired number of axie breeds given current market prices.",
  },
  inspector: {
    title: augmentTitle("Axie Inspector"),
    description:
      "Enter an axie's ID to see its full profile, including genes, badges and price recommendations.",
  },
  breedingSimulator: {
    title: augmentTitle("Breeding Simulator"),
    description:
      "Before breeding your axies, use the Axie Tech breeding simulator to view the potential genes of offspring.",
  },
  games: {
    title: augmentTitle("Axie Infinity Games"),
    description:
      "Explore all of the games being built within the Axie Infinity ecosystem.",
  },
  changelog: {
    title: augmentTitle("Changelog"),
    description:
      "Notable changes and updates to the Axie Tech website and products.",
  },
  v2CardExplorer: {
    title: augmentTitle("Card Explorer"),
    description:
      "Explore all Axie cards and abilities. Card tiers are based on analysis by Arena Pro, Indes.",
  },
  guildMembership: {
    title: augmentTitle("Guild Membership"),
    description: AXIE_TECH_VIP_MEMBERSHIP_TAGLINE,
  },
  charts: {
    title: augmentTitle("Charts"),
    description:
      "SLP mint vs. burn chart and other data visualizations for the Axie Infinity ecosystem.",
  },
  dollLeaderboard: {
    title: augmentTitle("Axie DoLL Leaderboard"),
    description: "Top players in Axie Defenders of Lunacian Land.",
  },
  dollSidekicks: {
    title: augmentTitle("Axie DoLL Sidekicks"),
    description:
      "Browse the 2000 genesis Axie DoLL Sidekicks, companions that follow you around in-game!",
  },
  dollCardExpolorer: {
    title: augmentTitle("Axie DoLL Card Explorer"),
    description:
      "Explore all of the skills in Axie Defenders of Lunacian Land (DoLL), one of the Axie Infinity Builders Program games.",
  },
  axieQuestSkillExplorer: {
    title: augmentTitle("Axie Quest Skill Explorer"),
    description:
      "Explore all of the skills in Axie Quest, one of the Axie Infinity Builders Program games.",
  },
  interDungeonCardExplorer: {
    title: augmentTitle("Inter the Dungeon Card Explorer"),
    description:
      "Explore all of the cards in Inter the Dungeon, one of the Axie Infinity Builders Program games.",
  },
  axieRoninSupport: {
    title: augmentTitle("Axie & Ronin Support Portal"),
    description:
      "Find the help and support you need across Sky Mavis, Ronin and Axie Infinity games and ecosystem.",
  },
};
