import React from "react";
import Pairs from "../molecules/Pairs";
import { updateCurrentPageInvalid } from "store/breedingSimulator/breedingSimulatorActions";
import * as breedingSelectors from "store/breedingSimulator/breedingSelectors";
import { connect } from "react-redux";
import ResultsHeader from "../atoms/ResultsHeader";
import { Stack } from "@mui/material";

const HiddenResults = ({
  results,
  updateCurrentPageInvalid,
  currentPageInvalid,
  handleDeleteId,
}) => {
  return !results || results.numberOfInvalidResults === 0 ? (
    <></>
  ) : (
    <Stack gap={4}>
      <ResultsHeader
        title="Hidden Results"
        description="These breeding pairs are invalid."
      />
      <Pairs
        results={results.invalidResultsToDisplay}
        totalNumResults={results.numberOfInvalidResults}
        updateCurrentPage={updateCurrentPageInvalid}
        currentPage={currentPageInvalid}
        handleDeleteId={handleDeleteId}
        hideLock={true}
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCurrentPageInvalid,
};

const mapStateToProps = (state) => {
  return {
    results: breedingSelectors.getBreedingResults(state),
    currentPageInvalid: state.breedingSimulator.currentPageInvalid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HiddenResults);
