import { Stack } from "@mui/material";

const PlayerRankIcon = ({ rank = "" }: { rank: string }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className="rankIcon"
      sx={{
        width: 24,
        height: 24,
        background: (theme) => theme.palette.colors.origin.wood.backgroundDark,
        backgroundSize: "130%",
        backgroundPosition: "center center",
        backgroundImage: `url('/images/icons/arena-ranks/${rank.toLowerCase()}.png')`,
        borderRadius: "4px",
        overflow: "hidden",
      }}
    />
  );
};

export default PlayerRankIcon;
