import React from "react";
import { Typography } from "@mui/material";

const TooltipCapsule = ({ children, color, textAlign, textColor }) => (
  <Typography
    fontSize="14px"
    variant="span"
    fontWeight="700"
    textAlign={textAlign || "left"}
    color={textColor || "#fff"}
    sx={{
      backgroundColor: color,
      borderRadius: "4px",
      p: "4px 6px 5px 6px",
    }}
  >
    {children}
  </Typography>
);

export default TooltipCapsule;
