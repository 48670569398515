import * as types from "./leaderboardActionTypes";
import { put, retry, takeEvery } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./leaderboardActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchGuildBattleMMRLeaderboardRequest() {
  try {
    yield put(actions.fetchGuildBattleMMRLeaderboardStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGuildBattleMMRLeaderboard
    );
    yield put(actions.fetchGuildBattleMMRLeaderboardSucceeded(data));
  } catch (e) {
    yield put(actions.fetchGuildBattleMMRLeaderboardFailed());
  }
}

export default function* guildBattleLeaderboardSaga() {
  yield takeEvery(
    types.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD,
    makeFetchGuildBattleMMRLeaderboardRequest
  );
}
