import * as types from "./leaderboardActionTypes";
import { put, retry, takeEvery } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./leaderboardActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchScholarMMRLeaderboardRequest() {
  try {
    yield put(actions.fetchScholarMMRLeaderboardStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestScholarMMRLeaderboard
    );
    yield put(actions.fetchScholarMMRLeaderboardSucceeded(data));
  } catch (e) {
    yield put(actions.fetchScholarMMRLeaderboardFailed());
  }
}

export default function* scholarLeaderboardSaga() {
  yield takeEvery(
    types.FETCH_SCHOLAR_ARENA_LEADERBOARD,
    makeFetchScholarMMRLeaderboardRequest
  );
}
