import React from "react";
import { Stack, Tooltip } from "@mui/material";
import { createRankTierString, makeRankIconUrl } from "helpers/arena";
import ConditionalWrapper from "components/Utility/ConditionalWrapper";

const RankIcon = ({ rank, tier, size = 100 }) => {
  return (
    <ConditionalWrapper
      condition={rank != null}
      wrapper={(children) => (
        <Tooltip title={`Rank ${createRankTierString(rank, tier)}`}>
          {children}
        </Tooltip>
      )}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: size,
          minWidth: size,
          height: size,
          background: (theme) => theme.palette.card.pop1,
          borderRadius: "50%",
          img: {
            maxWidth: "100%",
          },
        }}
      >
        <img src={makeRankIconUrl(rank)} alt={rank} />
      </Stack>
    </ConditionalWrapper>
  );
};

export default RankIcon;
