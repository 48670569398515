import React from "react";
import { Typography } from "@mui/material";

const NumberOfIdsAdded = ({ numIds }) => {
  return (
    <Typography variant="h3" fontSize={16} color="text.regular">
      {`${numIds} Axie${numIds === 1 ? "" : "s"} Added`}
    </Typography>
  );
};

export default NumberOfIdsAdded;
