import { breedAxies } from "helpers/breeding";
import { formatEthPrice, formatTimestampAsDate } from "helpers/display";
import {
  axieIsAdult,
  axieIsOrigin,
  makeMarketplaceURLFromID,
  getAxieLastSold,
} from "helpers/axie";
import moment from "moment";

export const getTheMostRecentlySoldAxie = (axie, recentlySoldSimilarAxies) => {
  if (recentlySoldSimilarAxies.length === 0) {
    return undefined;
  }
  let axieId;
  let price;
  let timestamp = 0;
  recentlySoldSimilarAxies.forEach((a) => {
    if (a.lastSold.timestamp > timestamp && a.axieId !== axie.axieId) {
      axieId = a.axieId;
      price = a.lastSold.price;
      timestamp = a.lastSold.timestamp;
    }
  });
  return {
    axieId,
    price: formatEthOrUndefined(price),
    date: formatTimestampAsDate(timestamp),
    marketplaceUrl: makeMarketplaceURLFromID(axieId),
  };
};

export const getFloorPrice = (
  axie,
  cheapestSimilarAxies,
  numberOfCheapestSimilarListings
) => {
  if (
    numberOfCheapestSimilarListings === 0 ||
    (axie.order && numberOfCheapestSimilarListings === 1)
  ) {
    return undefined;
  }

  const cheapestAxieOnAuction = cheapestSimilarAxies.find(
    (a) => a.order != null && a.axieId !== axie.axieId
  );
  if (cheapestAxieOnAuction) {
    return cheapestAxieOnAuction.order.currentPrice;
  }
  return undefined;
};

export const sortByDateSold = (a, b) => {
  return a.lastSold.timestamp < b.lastSold.timestamp
    ? 1
    : a.lastSold.timestamp > b.lastSold.timestamp
    ? -1
    : 0;
};

export const getBadges = (axie, numberOfSimilarAxies, numberOfMysticParts) => {
  const isAdult = axieIsAdult(axie);
  let badges = [];
  badges.push({
    title: `${moment.unix(axie.birthDate).format("YYYY")} Baby`,
    icon: "🥚",
  });
  if (axie?.breedCount === 0 && isAdult) {
    badges.push({ title: "Virgin", icon: "👼" });
  }
  if (axie?.breedCount < 3 && isAdult) {
    badges.push({ title: "Breeder", icon: "🍆" });
  }
  if (axie?.purity?.purityCount === 6) {
    badges.push({ title: "6/6 Pure", icon: "🌟" });
  }
  if (axie?.purity?.purityPercent === 1) {
    badges.push({ title: "100% Pure", icon: "💯" });
  }
  if (numberOfSimilarAxies <= 1) {
    badges.push({ title: "Unique", icon: "🦄" });
  }
  if (axieIsOrigin(axie)) {
    badges.push({ title: "Origin", icon: "⭐️" });
  }
  if (numberOfMysticParts > 0) {
    badges.push({ title: `${numberOfMysticParts} x Mystic`, icon: "✨" });
  }
  return badges;
};

export const calculatePriceRecommendations = (basePrice, lastSold) =>
  basePrice == null && lastSold?.price == null
    ? undefined
    : {
        quickPrice: {
          label: "Quick Sale",
          eth: formatEthOrUndefined(
            Math.round(0.85 * basePrice * 10000) / 10000,
            basePrice
          ),
        },
        reasonablePrice: {
          label: "Reasonable",
          eth: formatEthOrUndefined(
            Math.round(basePrice * 10000) / 10000,
            basePrice
          ),
        },
        scaleUpPrice: {
          label: "Scale Up",
          ethStart: formatEthOrUndefined(
            Math.round(0.95 * basePrice * 10000) / 10000,
            basePrice
          ),
          ethEnd: formatEthOrUndefined(
            Math.round(1.2 * basePrice * 10000) / 1000,
            basePrice
          ),
        },
        scaleDownPrice: {
          label: "Scale Down",
          ethStart: formatEthOrUndefined(
            Math.round(1.1 * basePrice * 10000) / 10000,
            basePrice
          ),
          ethEnd: formatEthOrUndefined(
            Math.round(0.9 * basePrice * 10000) / 10000,
            basePrice
          ),
        },
        patientPrice: {
          label: "Patient",
          eth: formatEthOrUndefined(
            Math.round(1.5 * basePrice * 10000) / 10000,
            basePrice
          ),
        },
        lastSold: {
          label: "Last Sold",
          ...getAxieLastSold(lastSold),
        },
      };

export const calculateBasePrice = (
  theMostRecentlySoldAxiePrice,
  floorPrice
) => {
  if (theMostRecentlySoldAxiePrice == null && floorPrice == null) {
    return null;
  }
  if (theMostRecentlySoldAxiePrice * 1 > 0 && floorPrice > 0) {
    return Math.min(theMostRecentlySoldAxiePrice * 1.1, floorPrice);
  } else if (theMostRecentlySoldAxiePrice * 1 > 0) {
    return theMostRecentlySoldAxiePrice * 1.1;
  }
  return floorPrice;
};

export const getGeneProbabilities = (parentAxies) => {
  if (
    parentAxies == null ||
    parentAxies.length < 2 ||
    parentAxies[0] == null ||
    parentAxies[1] == null
  ) {
    return undefined;
  }
  return breedAxies(parentAxies[0], parentAxies[1]);
};

export const formatEthOrUndefined = (valueToFormat, valueToCheck) =>
  valueToCheck != null ? "Ξ" + formatEthPrice(valueToFormat) : undefined;

export const originMarketLabel = (numberOfMysticParts, total) =>
  `${
    numberOfMysticParts === 0
      ? `Origin Axie${total === 1 ? "" : "s"}`
      : `${numberOfMysticParts} Mystic Axie${total === 1 ? "" : "s"}`
  }`;

export const similarListingsLabel = (total) =>
  `Similar Listing${total === 1 ? "" : "s"}`;
