import React from "react";
import { Tooltip, useTheme, Stack, Button } from "@mui/material";
import {
  getColorForAxieClass,
  getOptionsForAxieFilterButtons,
} from "helpers/axie";

const AxieClassFilters = ({ filter, setFilter }) => {
  const theme = useTheme();
  const options = getOptionsForAxieFilterButtons();

  const filterTooltip = (category) => {
    if (category === "All") {
      return "Show all";
    }
    return `Show ${category} axies`;
  };

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={0.5}>
      {options.map((option, i) => (
        <Tooltip title={filterTooltip(option)} key={`FILTER_${i}`}>
          <Button
            variant="text"
            key={`FILTER_${i}`}
            onClick={() => setFilter(option)}
            style={{
              color: theme.palette.text.regular,
              fontSize: "13px",
              border: "1px solid transparent",
              borderColor:
                option === filter ? theme.palette.text.muted : "transparent",
            }}
          >
            <span
              style={{
                backgroundColor: getColorForAxieClass(
                  option,
                  theme.palette.mode
                ),
                width: "10px",
                height: "10px",
                borderRadius: "2px",
                display: "inline-block",
                marginRight: "4px",
                position: "relative",
                top: "-1px",
              }}
            ></span>
            {option}
          </Button>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default AxieClassFilters;
