import { matchesStr, strIsInArray } from "helpers/display";
import { createSelector } from "reselect";
import { getAllCardsV3 } from "store/data/dataSelectors";
import { constructDoLLCards } from "helpers/axieDoll";

export const getDollSkills = (state) => state.doll.skills.data || [];
export const getLoading = (state) => state.doll.skills.fetching;
export const getError = (state) => state.doll.skills.failed;

const getControls = (state) => state.doll.skills.controls;

export const getDollSkillsData = createSelector(
  [getDollSkills, getAllCardsV3, getLoading, getControls],
  (skills, cardsV3, loading, controls) => {
    const augmentedCards = constructDoLLCards(skills, cardsV3);

    const filteredCards = augmentedCards.filter(
      (card) =>
        (matchesStr(card.skillDescription, controls.searchText) ||
          matchesStr(card.skillName, controls.searchText)) &&
        strIsInArray(card.axieClassName, controls.axieClasses) &&
        strIsInArray(card.bodyPart, controls.partTypes)
    );

    let hasFiltered = false;

    if (
      controls.searchText !== "" ||
      controls.axieClasses.length > 0 ||
      controls.partTypes.length > 0
    ) {
      hasFiltered = true;
    }

    return {
      loading,
      controls,
      cards: filteredCards,
      hasFiltered,
    };
  }
);
