import React from "react";
import { Typography, useTheme } from "@mui/material";

const Label = ({ children, color, size = "12px" }) => {
  const theme = useTheme();
  return (
    <Typography
      fontWeight="700"
      sx={{ textTransform: "uppercase", whiteSpace: "nowrap" }}
      letterSpacing="0.5px"
      color={color ? color : theme.palette.text.muted}
      fontSize={size}
    >
      {children}
    </Typography>
  );
};

export default Label;
