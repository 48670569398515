import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Button, Stack } from "@mui/material";

const ClearAllButton = ({ axies, clear }) => {
  return axies.length ? (
    <Stack direction="row" justifyContent="center">
      <Button
        onClick={clear}
        variant="text"
        endIcon={<IoMdCloseCircleOutline />}
      >
        Clear All
      </Button>
    </Stack>
  ) : (
    <></>
  );
};

export default ClearAllButton;
