import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import Counter from "./Counter";

const Purity = ({ axie, color, width }) => {
  const theme = useTheme();
  return axie.purity != null &&
    axie.purity?.purityCount != null &&
    axie.purity?.purityPercent != null ? (
    <Stack
      direction="row"
      alignItems="center"
      gap={(0.5 / 320) * width}
      sx={{
        py: `${(4 / 320) * width}px`,
        px: `${(6 / 320) * width}px`,
        borderRadius: `${(6 / 320) * width}px`,
        border: `1px solid ${theme.palette.card.divider}`,
        background: theme.palette.card.background,
      }}
    >
      <Typography
        variant="span"
        fontSize={(12 / 320) * width}
        fontWeight={600}
        color="text.muted"
        sx={{ lineHeight: 1, whiteSpace: "nowrap", width: (52 / 320) * width }}
      >
        {`${axie.purity.purityCount} Purity`}
      </Typography>
      <Stack gap={(0.5 / 320) * width} direction="row" alignItems="center">
        <Counter
          filled={axie.purity.purityCount}
          total={6}
          color={color}
          width={width}
        />
        <Typography
          variant="span"
          fontSize={(12 / 320) * width}
          fontWeight={700}
          color={color}
        >{`${Math.round(axie.purity.purityPercent * 100)}%`}</Typography>
      </Stack>
    </Stack>
  ) : (
    <></>
  );
};

export default Purity;
