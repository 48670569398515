import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-4-2</Date>
      <Change type="new">
        Released our new{" "}
        <Link component={RouterLink} to="/team-builder">
          Team Builder
        </Link>
        !
      </Change>
    </Entry>
  );
};

export default Changes;
