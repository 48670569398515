import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

export const SelectBreedCount = ({
  parentNum,
  parentBreedCount,
  setParentBreedCount,
  handleUpdate,
  cls,
}) => {
  const handleChange = (event) => {
    setParentBreedCount(event.target.value);
    handleUpdate();
  };
  const options = ["0", "1", "2", "3", "4", "5", "6", "7"];
  return (
    <div className="custom-select">
      <FormControl fullWidth variant="filled" size="small" className={cls ? cls : {}}>
        <InputLabel id={`breed-count-select_${parentNum}`}>
          Breed Count
        </InputLabel>
        <Select
          labelId={`breed-count-select_${parentNum}`}
          value={parentBreedCount}
          onChange={handleChange}
          disableUnderline={true}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: 3,
                marginTop: 0.75,
                boxShadow: "0px 4px 8px rgba(0,0,0,.2)",
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBreedCount;
