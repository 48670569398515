import React from "react";
import { Stack, useTheme } from "@mui/material";
import SpecialGeneTag from "./SpecialGeneTag";
import PartNameText from "./PartNameText";

const PartName = ({ text, color, specialGenes, width, large }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      gap={(0.25 / 320) * width}
      sx={{
        width: large ? `${(110 / 320) * width}px` : `${(80 / 320) * width}px`,
        overflow: "hidden",
        position: "relative",
        [theme.breakpoints.down("lg")]: {
          width: `${(80 / 320) * width}px`,
        },
        "&:hover": {
          cursor: "default",
          overflow: "visible",
          zIndex: 2,
        },
      }}
    >
      {specialGenes && (
        <SpecialGeneTag specialGenes={specialGenes} width={width} />
      )}
      <PartNameText text={text} color={color} width={width} />
    </Stack>
  );
};

export default PartName;
