import React from "react";
import ListOfLinks from "./ListOfLinks";
import {
  dropdownItems,
  gamesPageUrl,
} from "components/Navigation/navigation.links";
import ColorButton from "../atoms/ColorButton";
import { FaStar, FaTools } from "react-icons/fa";
import ColumnWrapper from "../atoms/ColumnWrapper";
import LinkedWithScroll from "../atoms/LinkedWithScroll";

const Column4 = ({ colorOverrides }) => (
  <ColumnWrapper>
    <ListOfLinks
      links={dropdownItems.guilds}
      colorOverrides={colorOverrides}
      title="⭐️ Guilds"
    />

    <ListOfLinks links={[]} colorOverrides={colorOverrides} title="Learn">
      <ColorButton
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        href="https://welcome.axie.tech"
        target="_blank"
        endIcon={<FaStar />}
      >
        Guides
      </ColorButton>
    </ListOfLinks>

    <ListOfLinks links={[]} colorOverrides={colorOverrides} title="Explore">
      <LinkedWithScroll to={gamesPageUrl} underline="none">
        <ColorButton
          variant="contained"
          sx={{ alignSelf: "flex-start" }}
          endIcon={<FaTools />}
        >
          Games
        </ColorButton>
      </LinkedWithScroll>
    </ListOfLinks>
  </ColumnWrapper>
);

export default Column4;
