import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-3-28</Date>
      <Change type="new">
        Released our new{" "}
        <Link component={RouterLink} to="/explorer">
          Origin Card Explorer
        </Link>
        ! Browse all of the new Cards and abilities that are launching with Axie
        Infinity: Origin.
      </Change>
      <Change type="new">
        Released our new{" "}
        <Link component={RouterLink} to="/inspector">
          Axie Inspector
        </Link>
        ! The Inspector includes all of the original Axie Pricing tool features,
        plus a V2 vs V3 Cards comparison view, Axie Classifications and a
        completely refreshed design.
      </Change>
      <Change type="improved">
        Guild Dashboard: Added average Energy Usage metrics and chart.
      </Change>
    </Entry>
  );
};

export default Changes;
