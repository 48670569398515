export const CHARTS_FETCH_SLP_ISSUANCE = "CHARTS_FETCH_SLP_ISSUANCE";
export const CHARTS_FETCH_SLP_ISSUANCE_STARTED =
  "CHARTS_FETCH_SLP_ISSUANCE_STARTED";
export const CHARTS_FETCH_SLP_ISSUANCE_SUCCEEDED =
  "CHARTS_FETCH_SLP_ISSUANCE_SUCCEEDED";
export const CHARTS_FETCH_SLP_ISSUANCE_FAILED =
  "CHARTS_FETCH_SLP_ISSUANCE_FAILED";

export const CHARTS_UPDATE_SLP_ISSUANCE_BLOCKCHAIN_CONTROLS =
  "CHARTS_UPDATE_SLP_ISSUANCE_BLOCKCHAIN_CONTROLS";
export const CHARTS_UPDATE_SLP_ISSUANCE_INGAME_CONTROLS =
  "CHARTS_UPDATE_SLP_ISSUANCE_INGAME_CONTROLS";
