import React from "react";
import { Stack } from "@mui/material";
import {
  updateStatusEffectFilters,
  resetStatusEffectFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";

const StatusEffectControls = ({
  statusEffectFilters,
  updateStatusEffectFilters,
  resetStatusEffectFilters,
}) => {
  const handleUpdateStatusEffectFilters = (newFilters) => {
    updateStatusEffectFilters({ ...statusEffectFilters, ...newFilters });
  };

  return (
    <Stack gap={2}>
      <Search
        value={statusEffectFilters.searchText}
        handleUpdate={handleUpdateStatusEffectFilters}
        placeholder="Search status effects"
      />
      <TextButton
        onClick={resetStatusEffectFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateStatusEffectFilters,
  resetStatusEffectFilters,
};

const mapStateToProps = (state) => ({
  statusEffectFilters: state.explorer.statusEffectFilters,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusEffectControls);
