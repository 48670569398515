import * as types from "./cardExplorerActionTypes";

export const favoriteCard = (card) => ({
  type: types.FAVORITE_CARD,
  card,
});

export const unfavoriteCard = (card) => ({
  type: types.UNFAVORITE_CARD,
  card,
});

export const fetchCards = () => ({
  type: types.FETCH_CARDS,
});

export const fetchCardsStarted = () => ({
  type: types.FETCH_CARDS_STARTED,
});

export const fetchCardsSucceeded = (cards) => ({
  type: types.FETCH_CARDS_SUCCEEDED,
  cards,
});

export const fetchCardsFailed = () => ({
  type: types.FETCH_CARDS_FAILED,
});

export const addFavorites = (favorites) => ({
  type: types.ADD_FAVORITES,
  favorites,
});

export const addFavoritesStarted = () => ({
  type: types.ADD_FAVORITES_STARTED,
});

export const addFavoritesSucceeded = () => ({
  type: types.ADD_FAVORITES_SUCCEEDED,
});

export const addFavoritesFailed = () => ({
  type: types.ADD_FAVORITES_FAILED,
});

export const clearAllFavorites = () => ({
  type: types.CLEAR_ALL_FAVORITES,
});
