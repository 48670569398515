import React from "react";
import { Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { updateShowOnlyBreedingEventPairs } from "store/breedingSimulator/breedingSimulatorActions";
import Panel from "../atoms/Panel";
import { Stack, Typography, useTheme, Button } from "@mui/material";
import { isBreedingEventActive } from "helpers/time";

const Controls = ({
  updateShowOnlyBreedingEventPairs,
  showOnlyBreedingEventPairs,
}) => {
  const theme = useTheme();
  const breedingEventIsActive = isBreedingEventActive();

  return !breedingEventIsActive ? null : (
    <Panel>
      <Stack gap={1} alignItems="center">
        <Typography variant="h3" fontSize="16px" color="text.regular">
          Controls
        </Typography>
        <Tooltip
          placement="right"
          title="Only show results that have the chance to produce at least 1 summer part."
        >
          <Button
            onClick={() =>
              updateShowOnlyBreedingEventPairs(!showOnlyBreedingEventPairs)
            }
            variant={"outlined"}
            sx={{
              alignSelf: "stretch",
              justifyContent: "center",
              background: showOnlyBreedingEventPairs
                ? theme.palette.card.shade2
                : "transparent",
            }}
          >
            Summer Breeds Only
          </Button>
        </Tooltip>
      </Stack>
    </Panel>
  );
};

const mapDispatchToProps = {
  updateShowOnlyBreedingEventPairs,
};

const mapStateToProps = (state) => {
  return {
    showOnlyBreedingEventPairs:
      state.breedingSimulator.showOnlyBreedingEventPairs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
