import { Typography } from "@mui/material";
import { AXIE_INFINITY_ARENA_SEASON } from "config";
import fonts from "theme/fonts";

const SeasonNumber = () => {
  return (
    <Typography
      fontFamily={fonts.caesar}
      component="span"
      sx={{ color: (theme) => theme.palette.colors.origin.paper.medium }}
    >
      Season {AXIE_INFINITY_ARENA_SEASON}
    </Typography>
  );
};

export default SeasonNumber;
