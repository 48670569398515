import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-3-07</Date>
      <Change type="improved">
        Scholar Leaderboard: Updated the Scholar SLP Leaderboard to show Average
        SLP normalized by the scholar's total Energy.
      </Change>
    </Entry>
  );
};

export default Changes;
