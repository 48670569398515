export const TEAM_BUILDER_UPDATE_CARD = "TEAM_BUILDER_UPDATE_CARD";
export const TEAM_BUILDER_UPDATE_CHARM = "TEAM_BUILDER_UPDATE_CHARM";
export const TEAM_BUILDER_UPDATE_RUNE = "TEAM_BUILDER_UPDATE_RUNE";
export const TEAM_BUILDER_UPDATE_CLASS = "TEAM_BUILDER_UPDATE_CLASS";

export const TEAM_BUILDER_CLEAR_AXIE = "TEAM_BUILDER_CLEAR_AXIE";
export const TEAM_BUILDER_CLEAR_ALL_AXIES = "TEAM_BUILDER_CLEAR_ALL_AXIES";

export const TEAM_BUILDER_SET_DIALOG_OPEN = "TEAM_BUILDER_SET_DIALOG_OPEN";
export const TEAM_BUILDER_SET_DIALOG_CLOSED = "TEAM_BUILDER_SET_DIALOG_CLOSED";

export const TEAM_BUILDER_UPDATE_CARD_FILTERS =
  "TEAM_BUILDER_UPDATE_CARD_FILTERS";
export const TEAM_BUILDER_UPDATE_CHARM_FILTERS =
  "TEAM_BUILDER_UPDATE_CHARM_FILTERS";
export const TEAM_BUILDER_UPDATE_RUNE_FILTERS =
  "TEAM_BUILDER_UPDATE_RUNE_FILTERS";

export const TEAM_BUILDER_UPDATE_VIEW = "TEAM_BUILDER_UPDATE_VIEW";
export const TEAM_BUILDER_DISPLAY_BANISH_CARDS =
  "TEAM_BUILDER_DISPLAY_BANISH_CARDS";
