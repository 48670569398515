import { Stack } from "@mui/material";
import React from "react";

const VictoryStar = ({ height = 20 }) => {
  return (
    <Stack
      sx={{
        height,
        ".star": {
          maxHeight: "100%",
          objectFit: "contain",
          width: "auto",
        },
      }}
    >
      <img
        src="/images/icons/victory-star.png"
        alt="Victory Star"
        className="star"
      />
    </Stack>
  );
};

export default VictoryStar;
