import React from "react";
import StatWidget from "./StatWidget";
import { FaSmileBeam } from "react-icons/fa";

const WidgetHeadCount = ({
  numberOfScholars,
  numberOfAxies,
  numberOfTeams,
}) => {
  return (
    <StatWidget
      title="Stats"
      icon={<FaSmileBeam />}
      stats={[
        {
          label: "Teams",
          amount: numberOfTeams,
        },
        {
          label: "Scholars",
          amount: numberOfScholars,
        },
        {
          label: "Axies",
          amount: numberOfAxies,
        },
      ]}
    />
  );
};

export default WidgetHeadCount;
