import * as types from "./actionTypes";
import * as actions from "./action";
import api from "services/api";
import { takeEvery, retry, put, select } from "redux-saga/effects";
import { getDollSkills } from "./selectors";
import { isNullOrEmptyArray } from "helpers/cleaning";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchDollCardsRequest() {
  try {
    const existingData = yield select(getDollSkills);

    if (isNullOrEmptyArray(existingData)) {
      yield put(actions.fetchDollCardsStarted());
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestAxieDollSkills
      );
      yield put(actions.fetchDollCardsSucceeded(data));
    }
  } catch (e) {
    console.log("Error fetching cards");
    yield put(actions.fetchDollCardsFailed());
  }
}

function* dollCardsSaga() {
  yield takeEvery(
    types.DOLL_EXPLORER_FETCH_DOLLCARDS,
    makeFetchDollCardsRequest
  );
}
export default dollCardsSaga;
