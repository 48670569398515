import { Box, Stack, Typography } from "@mui/material";
import ColorModeButton from "components/ColorModeButton/ColorModeButton";
import TriballyBadge from "components/TriballyBadge/TriballyBadge";
import { TRIBALLY_TAGLINE, TRIBALLY_PLATFORM_URL } from "config";

const Credits = ({ colorOverrides }) => {
  const year = new Date().getFullYear();

  return (
    <Box sx={{ paddingTop: 6, paddingBottom: 6 }}>
      <Stack
        direction={{ sm: "column", md: "row", lg: "row" }}
        justifyContent={{
          sm: "flex-start",
          md: "space-between",
          lg: "space-between",
        }}
        alignItems="center"
        gap={2}
      >
        <Typography
          variant="span"
          fontWeight="600"
          fontSize="12px"
          color="rgba(255,255,255,.3)"
        >
          © {year} Axie.Tech
        </Typography>
        <TriballyBadge
          styles={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
          link={TRIBALLY_PLATFORM_URL}
          width={240}
          title={TRIBALLY_TAGLINE}
          alt={TRIBALLY_TAGLINE}
        />
        <ColorModeButton
          color={colorOverrides?.footer?.text}
          style={{ alignSelf: "center" }}
        />
      </Stack>
    </Box>
  );
};

export default Credits;
