import { Stack, Box } from "@mui/material";
import Card from "./components/Card";
import { makeMarketplaceURLFromID } from "helpers/axie";
import ClassIcon from "./components/ClassIcon";
import ID from "./components/ID";
import DeleteIcon from "./components/DeleteIcon";
import RefreshIcon from "./components/RefreshIcon";
import Unknown from "./components/Unknown";
import CustomLink from "components/CustomLink/CustomLink";

const VariantUnknown = ({
  width,
  disableElevation,
  id,
  refreshAxie,
  deleteAxie,
  style,
  fillSpace,
}) => {
  return (
    <Card
      disableElevation={disableElevation}
      hideInspectIcon={true}
      width={width}
      style={{
        position: "relative",
        minHeight: width * 0.75,
        ...(fillSpace && {
          height: "100%",
          flexGrow: 1,
        }),
        ...style,
      }}
    >
      <CustomLink
        href={makeMarketplaceURLFromID(id)}
        sx={{
          border: "none",
          position: "relative",
          zIndex: 1,
          "&:hover": { border: "none" },
          display: "flex",
          flexDirection: "column",
          flex: 1,
          maxWidth: width,
        }}
      >
        <Stack direction="row" alignItems="center" gap={(1 / 320) * width}>
          <ClassIcon width={width} />
          <ID id={id} width={width} />
        </Stack>

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "50%",
            transform: "translateX(50%) translateY(-50%)",
          }}
        >
          <Unknown width={width} />
        </Box>
      </CustomLink>

      <Stack
        direction="row"
        alignItems="center"
        gap={(0.25 / 320) * width}
        sx={{
          zIndex: 4,
          position: "absolute",
          bottom: `${(12 / 320) * width}px`,
          right: `${(12 / 320) * width}px`,
        }}
      >
        <RefreshIcon refreshAxie={refreshAxie} id={id} />
        <DeleteIcon deleteAxie={deleteAxie} id={id} />
      </Stack>
    </Card>
  );
};

export default VariantUnknown;
