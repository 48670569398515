import * as types from "./actionTypes";

export const fetchDollSidekicks = () => ({
  type: types.DOLL_SIDEKICKS_FETCH_SIDEKICKS,
});

export const fetchDollSidekicksStarted = () => ({
  type: types.DOLL_SIDEKICKS_FETCH_SIDEKICKS_STARTED,
});

export const fetchDollSidekicksFailed = () => ({
  type: types.DOLL_SIDEKICKS_FETCH_SIDEKICKS_FAILED,
});

export const fetchDollSidekicksSucceeded = (data) => ({
  type: types.DOLL_SIDEKICKS_FETCH_SIDEKICKS_SUCCEEDED,
  data,
});

export const updateControls = (controls) => ({
  type: types.DOLL_SIDEKICKS_UPDATE_CONTROLS,
  controls,
});
