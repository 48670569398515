import React from "react";
import items from "./items";
import TabPanel from "../molecules/TabPanel";

const TabPanels = ({ tab, setInfoDialogIsOpen, loading }) => {
  return (
    <>
      <TabPanel value={tab} index={0} loading={loading}>
        <items.Cards setInfoDialogIsOpen={setInfoDialogIsOpen} />
      </TabPanel>
      <TabPanel value={tab} index={1} loading={loading}>
        <items.Curses setInfoDialogIsOpen={setInfoDialogIsOpen} />
      </TabPanel>
      <TabPanel value={tab} index={2} loading={loading}>
        <items.Tools setInfoDialogIsOpen={setInfoDialogIsOpen} />
      </TabPanel>
      <TabPanel value={tab} index={3} loading={loading}>
        <items.RevengeCards setInfoDialogIsOpen={setInfoDialogIsOpen} />
      </TabPanel>
      <TabPanel value={tab} index={4} loading={loading}>
        <items.Runes setInfoDialogIsOpen={setInfoDialogIsOpen} />
      </TabPanel>
      <TabPanel value={tab} index={5} loading={loading}>
        <items.Charms setInfoDialogIsOpen={setInfoDialogIsOpen} />
      </TabPanel>
      <TabPanel value={tab} index={6} loading={loading}>
        <items.StatusEffects />
      </TabPanel>
      <TabPanel value={tab} index={7} loading={loading}>
        <items.Favorites />
      </TabPanel>
    </>
  );
};

export default TabPanels;
