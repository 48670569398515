import React from "react";
import { Typography } from "@mui/material";

const Text = ({
  children,
  textAlign = "left",
  color = "text.regular",
  lineHeight,
  style,
}) => {
  return (
    <Typography
      variant="body1"
      fontSize={14}
      fontWeight={600}
      color={color}
      textAlign={textAlign}
      style={{ ...style, lineHeight }}
    >
      {children}
    </Typography>
  );
};

export default Text;
