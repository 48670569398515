import React from "react";
import NumberOfBreedsRadioGroup from "./NumberOfBreedsRadioGroup";
import InputsHeader from "./InputsHeader";
import { Stack } from "@mui/material";

const NumberOfBreeds = ({
  numberOfBreeds,
  setNumberOfBreeds,
  handleUpdate,
}) => {
  return (
    <Stack gap={2}>
      <InputsHeader
        title="# of Breeds"
        description="How many times do you want to breed?"
      />
      <NumberOfBreedsRadioGroup
        numberOfBreeds={numberOfBreeds}
        setNumberOfBreeds={setNumberOfBreeds}
        handleUpdate={handleUpdate}
      />
    </Stack>
  );
};

export default NumberOfBreeds;
