import { Button, Stack, IconButton } from "@mui/material";
import PageTitle from "components/Typography/PageTitle";
import Controls from "../molecules/Controls";
import Card from "components/Card/Card";
import {
  AXIE_INFINITY_BUILDERS_PROGRAM_APPLICATION,
  AXIE_INFINITY_BUILDERS_PROGRAM_TWITTER,
} from "config";
import { FaArrowCircleRight, FaTwitter } from "react-icons/fa";
import CustomLink from "components/CustomLink/CustomLink";

const PageHeader = ({
  statuses,
  setStatuses,
  platforms,
  setPlatforms,
  gameTypes,
  setgameTypes,
}) => {
  return (
    <Card>
      <Stack gap={2}>
        <Stack
          gap={1}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          <PageTitle gutterBottom={false} textAlign="center">
            Games Directory
          </PageTitle>
        </Stack>

        <Controls
          setStatuses={setStatuses}
          statuses={statuses}
          platforms={platforms}
          setPlatforms={setPlatforms}
          gameTypes={gameTypes}
          setgameTypes={setgameTypes}
        />
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            href={AXIE_INFINITY_BUILDERS_PROGRAM_APPLICATION}
            target="_blank"
            endIcon={<FaArrowCircleRight />}
            sx={{ alignSelf: "center" }}
          >
            Apply to Builders Program
          </Button>
          <CustomLink
            href={AXIE_INFINITY_BUILDERS_PROGRAM_TWITTER}
            target="_blank"
            title="Axie Infinity Builders Program Twitter"
          >
            <IconButton sx={{ fontSize: "2rem", color: "rgb(114, 50, 194)" }}>
              <FaTwitter />
            </IconButton>
          </CustomLink>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PageHeader;
