import { Stack, Typography } from "@mui/material";
import React from "react";

const ViewFailed = ({ message }) => {
  return (
    <Stack sx={{ pt: 12 }} justifyContent="center" alignItems="center">
      <Typography
        variant="span"
        color="text.muted"
        fontSize="26px"
        fontWeight="700"
        textAlign="center"
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default ViewFailed;
