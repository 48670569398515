import { DialogTitle, Stack } from "@mui/material";
import React from "react";
import DialogGuildId from "../atoms/DialogGuildId";

const DialogHeader = ({ dialogTitle, guildId }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogGuildId guildId={guildId} />
    </Stack>
  );
};

export default DialogHeader;
