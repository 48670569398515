import * as types from "./cardExplorerActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./cardExplorerActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchCardsRequest() {
  try {
    yield put(actions.fetchCardsStarted());
    const cards = yield retry(RETRY_TIMES, RETRY_DELAY, api.requestCards);
    yield put(actions.fetchCardsSucceeded(cards));
  } catch (e) {
    console.log("Error fetching cards");
    yield put(actions.fetchCardsFailed());
  }
}

export function* addFavorites(action) {
  try {
    yield put(actions.addFavoritesStarted());
    const cards = yield retry(RETRY_TIMES, RETRY_DELAY, api.requestCards);
    for (const card of action.favorites) {
      const cardInfo = cards.find((c) => c.partId === card);
      if (cardInfo) {
        yield put(actions.favoriteCard(cardInfo));
      }
    }
    yield put(actions.addFavoritesSucceeded());
  } catch (e) {
    console.log("Error adding favorites");
    yield put(actions.addFavoritesFailed());
  }
}

export default function* cardExplorerSaga() {
  yield takeEvery(types.FETCH_CARDS, makeFetchCardsRequest);
  yield takeEvery(types.ADD_FAVORITES, addFavorites);
}
