import { fork } from "redux-saga/effects";
import classicLeaderboardSaga from "./leaderboard/classic/leaderboardSaga";
import inspectorSaga from "./inspector/inspectorSaga";
import guildDashboardSaga from "./guildDashboard/sagas/guildDashboardSaga";
import guildSaga from "./guild/guildSaga";
import cardExplorerSaga from "./cardExplorer/cardExplorerSaga";
import recentlySaga from "./recently/recentlySaga";
import breedingSimulatorSaga from "./breedingSimulator/breedingSimulatorSaga";
import guildLeaderboardSaga from "./leaderboard/guild/guildLeaderboardSaga";
import guildBattleLeaderboardSaga from "./leaderboard/guildBattle/guildBattleLeaderboardSaga";
import scholarLeaderboardSaga from "./leaderboard/scholar/scholarLeaderboardSaga";
import dataSaga from "./data/dataSaga";
import adminSaga from "./guildDashboardAdmin/adminSaga";
import chartsSaga from "./charts/chartsSaga";
import playerSaga from "./player/playerSaga";
import dollSaga from "./doll/dollSaga";
import axieQuestSaga from "./axieQuest/axieQuestSaga";

export default function* rootSaga() {
  yield fork(inspectorSaga);
  yield fork(guildDashboardSaga);
  yield fork(guildSaga);
  yield fork(cardExplorerSaga);
  yield fork(recentlySaga);
  yield fork(breedingSimulatorSaga);
  yield fork(guildLeaderboardSaga);
  yield fork(guildBattleLeaderboardSaga);
  yield fork(scholarLeaderboardSaga);
  yield fork(dataSaga);
  yield fork(adminSaga);
  yield fork(chartsSaga);
  yield fork(classicLeaderboardSaga);
  yield fork(playerSaga);
  yield fork(dollSaga);
  yield fork(axieQuestSaga);
}
