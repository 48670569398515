import CopyAddress from "components/Typography/CopyAddress";
import { Stack } from "@mui/material";
import VerticalStat from "../atoms/VerticalStat";
import Card from "components/Card/Card";

const ScholarAddresses = ({ roninAddress, payoutAddress }) => {
  return (
    <Card>
      <Stack gap={3}>
        <VerticalStat title="Account">
          <CopyAddress address={roninAddress} />
        </VerticalStat>

        {payoutAddress != null && (
          <VerticalStat title="Payout">
            <CopyAddress address={payoutAddress} />
          </VerticalStat>
        )}
      </Stack>
    </Card>
  );
};

export default ScholarAddresses;
