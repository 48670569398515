import { Typography } from "@mui/material";
import fonts from "theme/fonts";

const MainTitle = ({ children, styles }) => (
  <Typography
    fontFamily={fonts.changa}
    fontWeight={500}
    fontSize={32}
    variant="h2"
    color="text.bright"
    sx={styles}
    textAlign="center"
  >
    {children}
  </Typography>
);

export default MainTitle;
