import React from "react";
import { TextField, Chip, Stack, Typography } from "@mui/material";
import Autocomplete from "./Autocomplete";
import styled from "@emotion/styled";

const Option = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(1),
  alignItems: "center",
  fontSize: 13,
  lineHeight: 1.2,
  fontWeight: 600,
  img: {
    width: 20,
  },
  "&.Mui-selected": {
    background: "red",
  },
}));

const SelectedChips = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  fontSize: 12,
  position: "relative",
  zIndex: 2,
}));

const ChipLabel = styled(Stack)(({ theme }) => ({
  img: {
    width: 16,
  },
}));

export const ItemsDropdown = ({ label, value, setValue, options }) => {
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const itemsToShow = 3;

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={({ inputProps, ...rest }) => (
        <TextField
          {...rest}
          variant="filled"
          placeholder={value.length > 0 ? undefined : label}
          inputProps={{ ...inputProps, readOnly: true }}
        />
      )}
      renderTags={(value, getTagProps) => {
        let extraSelected = "";
        if (value.length > itemsToShow) {
          extraSelected = `+${value.length - itemsToShow}`;
        }
        return (
          <SelectedChips>
            {value.map((option, index) => {
              return (
                index < itemsToShow && (
                  <Chip
                    label={
                      <ChipLabel alignItems="center" justifyContent="center">
                        <img src={option.image} alt={option.name} />
                      </ChipLabel>
                    }
                    size="small"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    {...getTagProps({ index })}
                  />
                )
              );
            })}
            <Typography variant="span">{extraSelected}</Typography>
          </SelectedChips>
        );
      }}
      renderOption={(props, option) => {
        return (
          <Option key={option.id} {...props}>
            <img src={option.image} alt={option.name} />
            {option.name}
          </Option>
        );
      }}
    />
  );
};

export default ItemsDropdown;
