import React from "react";
import {
  getColorForAxieClass,
  getOptionsForAxieFilterButtons,
  getIconForAxieClass,
} from "helpers/axie";
import styles from "./select-all-buttons.module.scss";
import { Typography, useTheme, Stack } from "@mui/material";

const SelectAllButtons = ({ selectAll, updateSelectAll, filteredAxies }) => {
  const options = getOptionsForAxieFilterButtons();
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{
        height: "51px",
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          variant="span"
          fontSize="14px"
          fontWeight="600"
          color="text.muted"
        >
          Select:
        </Typography>
        <div className={styles.selectAllButtonsWrapper}>
          {options.map((option, i) => (
            <span
              className={`${styles.selectAllButton} ${
                selectAll.includes(option) ? styles.active : ""
              }`}
              style={{
                backgroundColor: getColorForAxieClass(
                  option,
                  theme.palette.mode
                ),
                opacity: theme.palette.mode === "light" ? 0.4 : 0.6,
              }}
              onClick={() => updateSelectAll(option, filteredAxies)}
              key={`SELECT_ALL_BUTTON_${i}`}
            >
              {option === "All" ? (
                <Typography
                  variant="span"
                  fontWeight="600"
                  color={theme.palette.card.background}
                  fontSize="13px"
                  sx={{ position: "relative", top: "1px" }}
                >
                  All
                </Typography>
              ) : (
                getIconForAxieClass(option)
              )}
            </span>
          ))}
        </div>
      </Stack>
    </Stack>
  );
};

export default SelectAllButtons;
