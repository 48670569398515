import { Stack } from "@mui/material";

const Section = ({ children, style }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 6,
        paddingBottom: 10,
        ...style,
      }}
    >
      {children}
    </Stack>
  );
};

export default Section;
