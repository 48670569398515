import React from "react";
import { connect } from "react-redux";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import HorizontalBarChart from "../../molecules/HorizontalBarChart";
import PerformanceGuild from "../../organisms/PerformanceGuild";
import WidgetHeadCount from "../../molecules/WidgetHeadCount";
import WidgetMVP from "../../molecules/WidgetMVP";
import { Grid, useMediaQuery } from "@mui/material";
import Card from "components/Card/Card";
import UpcomingMintableWidget from "../../molecules/UpcomingMintableWidget";
import { getUpcomingMintableItems } from "store/guildDashboard/selectors/inventory";

const Stats = ({ guildStats, guild, upcomingMintableItems }) => {
  const {
    numberOfScholars,
    numberOfAxies,
    highestMMR,
    arenaMVP,
    sortedMMRs,
    numberOfTeams,
  } = guildStats.overallStats;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Card highlight={true}>
          <PerformanceGuild
            averageMMR={guild?.arenaLeaderboardPosition?.averageElo}
            avgMMRRank={guild?.arenaLeaderboardPosition?.position}
          />
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Card>
          <WidgetHeadCount
            numberOfScholars={numberOfScholars}
            numberOfAxies={numberOfAxies}
            numberOfTeams={numberOfTeams}
          />
        </Card>
      </Grid>
      <UpcomingMintableWidgetWrapper>
        <Card>
          <UpcomingMintableWidget
            title="Next 10 Upcoming Mintable Runes"
            data={upcomingMintableItems.runes}
          />
        </Card>
      </UpcomingMintableWidgetWrapper>
      <UpcomingMintableWidgetWrapper>
        <Card>
          <UpcomingMintableWidget
            title="Next 10 Upcoming Mintable Charms"
            data={upcomingMintableItems.charms}
          />
        </Card>
      </UpcomingMintableWidgetWrapper>
      <Grid item xs={12} sm={12} md={4}>
        <Card>
          <WidgetMVP
            title="Team MVPs"
            highestMMR={highestMMR}
            arenaMVP={arenaMVP}
          />
        </Card>
      </Grid>
      {sortedMMRs.length > 0 && (
        <Grid item xs={12} sm={12} md={8}>
          <Card>
            <HorizontalBarChart
              title="Average Stars by Team"
              data={sortedMMRs}
              yAxis="name"
              xAxis="mmr"
              xAxisLabel="Stars"
            />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

const UpcomingMintableWidgetWrapper = ({ children }) => {
  const stacked = useMediaQuery("(max-width:1340px)");

  return stacked ? (
    <Grid item xs={12}>
      {children}
    </Grid>
  ) : (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      {children}
    </Grid>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guild: state.guildDashboard.guild,
  guildStats: guildSelectors.getGuildStats(state),
  upcomingMintableItems: getUpcomingMintableItems(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
