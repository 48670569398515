import { createSelector } from "reselect";
import { makeMarketplaceSearchStringFromParts } from "helpers/axie";

const getFavorites = (state) => {
  return state.cardExplorer?.favoriteCards || [];
};

export const getShareLink = createSelector([getFavorites], (favorites) => {
  const baseStem = "https://axie.tech/card-explorer";
  if (favorites.length === 0) {
    return baseStem;
  }
  const shareLink = baseStem + "?favorites=";
  const partIds = favorites.map((favorite) => favorite.partId);
  return shareLink + partIds.join(",");
});

export const getMarketplaceSearchLink = createSelector(
  [getFavorites],
  (favorites) => {
    const partIds = favorites.map((favorite) => favorite.partId);
    return makeMarketplaceSearchStringFromParts(partIds);
  }
);
