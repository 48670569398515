import React from "react";
import styles from "./card-explorer.module.scss";
import Card from "./Card";

const CardTier = ({ tier, cards }) => {
  if (cards.length > 0) {
    return (
      <div className={`${styles.tier} ${styles[`tier-${tier}`]}`}>
        <div className={styles.tierHeader}>
          <span className={styles.tierHeaderText}>{`${tier} Tier`}</span>
        </div>
        <div className={styles.tierCards}>
          {cards.map((card) => (
            <Card key={card.partId} card={card} />
          ))}
        </div>
      </div>
    );
  } else return <></>;
};

export default CardTier;
