import React from "react";
import { Stack } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import { AXIE_QUEST_WEBSITE } from "config";

const Logo = ({ width = 350 }) => {
  return (
    <CustomLink href={AXIE_QUEST_WEBSITE}>
      <Stack sx={{ width: "100%", maxWidth: width }}>
        <img
          src="/images/games/axie-quest/axie-quest-logo.png"
          alt="Axie Quest Logo"
        />
      </Stack>
    </CustomLink>
  );
};

export default Logo;
