import React from "react";
import { connect } from "react-redux";
import { getFavoriteRevengeCardsToDisplay } from "store/explorer/selectors";
import { favoriteRevengeCard } from "store/explorer/explorerActions";
import Section from "../../molecules/FavoritesSection";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";

const RevengeCards = ({
  favoriteRevengeCardsToDisplay,
  favoriteRevengeCard,
}) => {
  return (
    <Section
      type="Revenge Card"
      numItems={favoriteRevengeCardsToDisplay.length}
    >
      {favoriteRevengeCardsToDisplay.map((card) => (
        <WrappedCard
          key={card.id}
          favoriteCard={favoriteRevengeCard}
          card={card}
        />
      ))}
    </Section>
  );
};

const mapDispatchToProps = { favoriteRevengeCard };

const mapStateToProps = (state) => ({
  favoriteRevengeCardsToDisplay: getFavoriteRevengeCardsToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevengeCards);
