import React from "react";
import { IoRefreshCircle } from "react-icons/io5";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Button } from "@mui/material";

const RefreshButton = ({ fetch, loading }) =>
  loading ? (
    <Button
      disabled={true}
      variant="contained"
      endIcon={
        <CustomLoader
          width={16}
          marginTop={0}
          marginBottom={0}
          style={{ position: "relative", top: "2px" }}
        />
      }
      sx={{
        "& .MuiButton-endIcon > *": { fontSize: "14px" },
      }}
      size="large"
    >
      Refresh
    </Button>
  ) : (
    <Button
      variant="contained"
      onClick={fetch}
      endIcon={<IoRefreshCircle />}
      size="large"
    >
      Refresh
    </Button>
  );

export default RefreshButton;
