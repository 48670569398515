import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { FaClock, FaSkull } from "react-icons/fa";
import InfoItem from "pages/DoLL/Leaderboard/atoms/InfoItem";
import moment from "moment";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["gap", "fontSize"].includes(prop),
})(({ theme, gap, fontSize }) => ({
  gap: theme.spacing(gap),
  justifySelf: "flex-end",
  gridArea: "info",
  fontSize,
  fontWeight: 800,
}));

const Info = ({
  survivalTime,
  monstersDefeated,
  gap = 0.75,
  fontSize = 18,
}) => {
  if (survivalTime == null || monstersDefeated == null) {
    return null;
  }
  const survivalTimeFormatted = moment(Math.round(survivalTime) * 1000).format(
    "mm:ss"
  );

  return (
    <Wrapper className="info" gap={gap} fontSize={fontSize}>
      <InfoItem icon={<FaClock />} points={survivalTimeFormatted} />
      <InfoItem icon={<FaSkull />} points={monstersDefeated} />
    </Wrapper>
  );
};

export default Info;
