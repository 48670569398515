import {
  AXIE_DOLL_WEBSITE,
  AXIE_DOLL_CLASS_BONUSES,
  AXIE_DOLL_BODY_TYPE_BONUSES,
} from "config";

export const makeSidekickUrl = (img) =>
  img == null ? null : `${AXIE_DOLL_WEBSITE}/${img}`;

const constructPartId = (bodyPart, nameRaw) => {
  const name = (nameRaw || "").toLowerCase().replace(/ /g, "-");
  return `${bodyPart}-${name}`;
};

const parseName = (skillId, rawName) => {
  const specialNames = {
    SKILL_BACK_BUG_SANDAL: "Sandal",
  };

  let name;

  if (skillId in specialNames) {
    name = specialNames[skillId];
  } else {
    name = rawName.replace(/ *\([^)]*\) */g, "");
  }

  return name;
};

export const extractDollParts = (skillId, skillName) => {
  const bodyPart = (skillId || "").split("_")[1].toLowerCase();

  const name = parseName(skillId, skillName);

  return {
    dollPartId: constructPartId(bodyPart, name),
    bodyPart,
    skillName: name,
  };
};

export const constructSkillIdFromCard = (card) => {
  let axieClass = card.class || "";
  const part = card.partType || "";
  const name = (card.partName || "").split(" ").join("");

  if (axieClass === "Aquatic") {
    axieClass = "Aqua";
  }

  return `SKILL_${part}_${axieClass}_${name}`.toUpperCase();
};

export const constructDoLLCards = (skills, cardsV3) => {
  return skills.map((dollSkill) => {
    // Parse dollPartId from dollSkill
    const { dollPartId, bodyPart, skillName } = extractDollParts(
      dollSkill.skillId,
      dollSkill.skillName
    );

    // Find card in cardsV3 where partId = dollPartId
    const baseCard = cardsV3.find((card) => card.partId === dollPartId);

    // get image
    const image = baseCard?.cardImage;

    // get class
    const axieClassName = (baseCard?.class ?? "").toLowerCase();

    return {
      ...dollSkill,
      skillName,
      axieClassName,
      image,
      bodyPart,
    };
  });
};

export const getDoLLCardsForAxie = (axieCards, allDoLLSkills) => {
  if (axieCards == null) return null;
  const doLLCards = [];

  for (const card of axieCards) {
    const skillId = constructSkillIdFromCard(card);
    const skill = allDoLLSkills.find((s) => s.skillId === skillId);

    if (skill) {
      doLLCards.push({
        ...skill,
        skillName: card.cardName,
        axieClassName: card.class,
        image: card.cardImage,
        bodyPart: card.partType,
      });
    }
  }

  return doLLCards;
};

export const getBodyPartBonus = (bodyType) => {
  let parsedBodyType = (bodyType || "").replace("mystic-", "");

  if (parsedBodyType === "summer") {
    parsedBodyType = "wavy";
  }

  const bonus = AXIE_DOLL_BODY_TYPE_BONUSES[parsedBodyType];

  return {
    label: "Body Type",
    characteristic: bonus?.name,
    description: bonus?.description,
  };
};

export const getClassBonus = (axieClass) => {
  const bonus = AXIE_DOLL_CLASS_BONUSES[(axieClass || "").toLowerCase()];

  return {
    label: "Class",
    characteristic: bonus?.name,
    description: bonus?.description,
  };
};

export const getDoLLBonusesForAxie = (axie) => {
  const bodyPartBonus = getBodyPartBonus(axie?.body?.d);
  const classBonus = getClassBonus(axie?.class);

  return [bodyPartBonus, classBonus];
};

export const getDoLLSidekickMarketplaceUrl = (sidekickId) =>
  sidekickId == null
    ? null
    : `https://marketplace.skymavis.com/collections/ronin:97797358f0d12aee117209c15d964ee0022bf1aa/${sidekickId}`;
