import { useState } from "react";
import { Stack } from "@mui/material";
import Page from "components/Utility/Page";
import PageHeader from "./organisms/PageHeader";
import Projects from "./organisms/Projects";

const Builders = () => {
  const [statuses, setStatuses] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [gameTypes, setgameTypes] = useState([]);
  return (
    <Page>
      <Stack gap={{ xs: 6, sm: 6, md: 8 }}>
        <PageHeader
          setStatuses={setStatuses}
          statuses={statuses}
          platforms={platforms}
          setPlatforms={setPlatforms}
          gameTypes={gameTypes}
          setgameTypes={setgameTypes}
        />
        <Projects
          statuses={statuses}
          platforms={platforms}
          gameTypes={gameTypes}
        />
      </Stack>
    </Page>
  );
};

export default Builders;
