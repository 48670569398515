import React from "react";
import { TiArrowRight } from "react-icons/ti";
import { Stack } from "@mui/material";

const Arrow = () => {
  return (
    <Stack
      sx={{
        color: (theme) => theme.palette.game.victoryStars,
      }}
    >
      <TiArrowRight />
    </Stack>
  );
};

export default Arrow;
