import * as types from "store/guildDashboard/actions/actionTypes";
import {
  put,
  call,
  take,
  select,
  fork,
  takeEvery,
  takeLatest,
  delay,
  retry,
} from "redux-saga/effects";
import { convertTo0xAddress } from "helpers/cleaning";
import api from "services/api";
import * as actions from "store/guildDashboard/actions/guildDashboardActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* fetchAxiesForScholar(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_STARTED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
    const scholarAxiesVariables = {
      auctionType: "All",
      criteria: {},
      from: action.start,
      size: 100,
      sort: "IdDesc",
      owner: convertTo0xAddress(action.roninAddress),
    };

    const axies = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestSearchAxies,
      scholarAxiesVariables
    );

    yield put(
      actions.fetchAxiesForScholarSucceeded(
        action.roninAddress,
        action.guildTeamId,
        axies
      )
    );

    if (action.start + 100 < axies.total) {
      yield put(
        actions.fetchAxiesForScholar(
          action.roninAddress,
          action.guildTeamId,
          action.start + 100
        )
      );
    } else {
      const scholarAxies = yield* monitorScholarAxies(
        action.roninAddress,
        action.guildTeamId
      );
      yield call(
        fetchAxieDetails,
        action.roninAddress,
        action.guildTeamId,
        scholarAxies
      );
    }
  } catch (e) {
    console.log("Error fetching axies for scholar: ", e);
    yield put(
      actions.fetchAxiesForScholarFailed(
        action.roninAddress,
        action.guildTeamId
      )
    );
  }
}

export function* fetchAllScholarAxies(action) {
  try {
    const allScholars = action.scholars;
    for (const scholar in allScholars) {
      if (!allScholars[scholar].axies || action.forceRefresh) {
        yield put({
          type: types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR,
          roninAddress: allScholars[scholar].roninAddress,
          start: 0,
          guildTeamId: allScholars[scholar].guildTeamId,
        });
        yield delay(300);
      }
    }
  } catch (e) {
    console.log("Error fetching all scholar axies: ", e);
  }
}

export function* monitorScholarAxies(roninAddress, guildTeamId) {
  while (true) {
    let scholar;
    if (guildTeamId === 0) {
      scholar = yield select((state) =>
        state.guildDashboard.guild.scholars.find(
          (scholar) => scholar.roninAddress === roninAddress
        )
      );
    } else {
      const team = yield select((state) =>
        state.guildDashboard.guild.teams.find(
          (team) => team.guildTeamId === guildTeamId
        )
      );
      scholar = team.scholars.find(
        (scholar) => scholar.roninAddress === roninAddress
      );
    }

    if (scholar && scholar.axies) {
      return scholar.axies;
    }
    yield take("*");
  }
}

export function* fetchAxieDetails(roninAddress, guildTeamId, axies) {
  try {
    for (const idx in axies) {
      yield fork(
        makeFetchRoninAxieDetailRequest,
        roninAddress,
        guildTeamId,
        axies[idx].axieId
      );
    }
  } catch (e) {}
}

export function* makeFetchRoninAxieDetailRequest(
  roninAddress,
  guildTeamId,
  axieId
) {
  try {
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGetAxieDetail,
      axieId
    );
    yield put(
      actions.fetchAxieDetailSucceeded(roninAddress, guildTeamId, data)
    );
  } catch (e) {}
}

export default function* guildDashboardAxiesSaga() {
  yield takeLatest(
    types.GUILD_DASHBOARD_FETCH_ALL_SCHOLAR_AXIES,
    fetchAllScholarAxies
  );
  yield takeEvery(
    types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR,
    fetchAxiesForScholar
  );
}
