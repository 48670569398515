import React from "react";
import { Stack } from "@mui/material";
import { makeBlockchainAxieImageUrl } from "helpers/axie";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  transition: "0.3s transform ease",
}));

const AxieImage = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "image",
})(({ theme, image }) => ({
  backgroundImage: `url('${image}')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center 25%",
  backgroundSize: "130%",
  paddingBottom: "75%",
}));

const Axie = ({ id }) => {
  if (id == null) {
    return null;
  }

  return (
    <Wrapper className="axie">
      <AxieImage image={makeBlockchainAxieImageUrl(id)} />
    </Wrapper>
  );
};

export default Axie;
