import React from "react";
import RuneSlot from "../../atoms/RuneSlot";
import NameAndClass from "../AxieSlot/NameAndClass";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Sidebar = ({
  axie,
  updateRune,
  view,
  handleOpenRuneDialog,
  updateClass,
  updateCharm,
}) => {
  return (
    <Wrapper>
      <NameAndClass
        axie={axie}
        updateClass={updateClass}
        updateCharm={updateCharm}
        updateRune={updateRune}
      />
      <RuneSlot
        axie={axie}
        data={axie.rune}
        updateRune={updateRune}
        handleOpenRuneDialog={handleOpenRuneDialog}
        view={view}
      />
    </Wrapper>
  );
};

export default Sidebar;
