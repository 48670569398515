import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageFooter from "./PageFooter";
import PageContent from "./PageContent";
import TriballyDollExplorerBanner from "components/TriballyAdBanner/TriballyDollExplorerBanner";

const Wrapper = styled(Stack)(({ theme }) => ({
  paddingTop: `${theme.spacing(2)}`,
  paddingBottom: `${theme.spacing(16)}`,
  background: `repeating-linear-gradient(-45deg, #ffffff, #ffffff 30px, ${theme.palette.colors.doll.stripe} 30px, ${theme.palette.colors.doll.stripe} 60px)`,
  position: "relative",
  zIndex: 1,

  ".pageFooter": {
    zIndex: 2,
  },
}));

const Page = ({ titleImg, titleAlt, controls, children, breakpoint }) => {
  return (
    <div>
      <Wrapper>
        <TriballyDollExplorerBanner />

        <PageContent
          titleImg={titleImg}
          titleAlt={titleAlt}
          controls={controls}
          breakpoint={breakpoint}
        >
          {children}
        </PageContent>
        <PageFooter />
      </Wrapper>
    </div>
  );
};

export default Page;
