import CustomLink from "components/CustomLink/CustomLink";
import { useTheme } from "@mui/material";
import { TRIBALLY_PLATFORM_URL } from "config";

const TriballyDollExplorerBanner = () => {
  const theme = useTheme();
  return (
    <CustomLink
      style={{
        width: 600,
        alignSelf: "center",
        zIndex: 99,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        "&:hover": { transform: "scale(1.02)" },
      }}
      target="_blank"
      href={`${TRIBALLY_PLATFORM_URL}community/defenders-of-lunacian-land/tools/skill-explorer`}
    >
      <img
        style={{
          borderRadius: 16,
          width: "100%",
          border: `8px solid ${theme.palette.card.background}`,
          boxShadow: `2px 5px 10px rgba(0, 0, 0, 0.5)`,
        }}
        src="/images/tribally-banner-assets/backgrounds/doll-explorer.jpg"
        alt="DoLL Card Explorer On Tribally Platform"
      />
    </CustomLink>
  );
};

export default TriballyDollExplorerBanner;
