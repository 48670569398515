import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { makeSidekickUrl } from "helpers/axieDoll";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 100,
  transition: "0.3s transform ease",
  position: "relative",
  img: {
    width: 100,
  },
}));

const Sidekick = ({ img }) => {
  return (
    <Wrapper className="sidekick">
      {img != null && (
        <img src={makeSidekickUrl(img)} alt="Axie DoLL Sidekick" />
      )}
    </Wrapper>
  );
};

export default Sidekick;
