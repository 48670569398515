import React from "react";
import { Stack } from "@mui/material";

const StatIcon = ({ type }) => {
  const iconImages = {
    shield: "/images/icons/shield.png",
    arena: "/images/icons/vstar-outlined.png",
  };
  const iconImage = iconImages[type];
  if (!type || !iconImage) {
    return null;
  }

  return (
    <Stack
      className="statIcon"
      sx={{
        img: {
          width: 40,
        },
      }}
    >
      <img src={iconImage} alt={type} />
    </Stack>
  );
};

export default StatIcon;
