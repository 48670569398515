import React from "react";
import styled from "@emotion/styled";
import { Stack, Box } from "@mui/material";

const SkillDetails = styled(Stack)(({ width, theme }) => ({
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  fontWeight: "700",
  fontSize: "inherit",
}));

const Circle = styled(Box)(({ width, theme }) => ({
  display: "inline-flex",
  width: "20px",
  minWidth: "20px",
  height: "20px",
  borderRadius: "50%",
  background: theme.palette.colors.doll.pink,
  alignItems: "center",
  justifyContent: "center",
  color: "rgba(255, 255, 255)",
  fontSize: "12px",
  marginRight: "4px",
}));

const TooltipDetails = ({ width, levelUpItem, id }) => {
  return (
    <SkillDetails width={width}>
      <Circle>{id + 1}</Circle>
      <span>{levelUpItem}</span>
    </SkillDetails>
  );
};

export default TooltipDetails;
