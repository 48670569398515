import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-1-5</Date>
      <Change type="new">
        Launched Axie Tech Dark Mode! Click the button at the bottom of the page to go dark. 😎
      </Change>
      <Change type="new">
        Guild Dashboard Teams: Groups scholars into Teams to create leagues and competitions within your guild. Monitor scholar performance at the Team level.
      </Change>
      <Change type="new">
        Guild Dashboard Scholar pages: Track individual scholar performance with a daily earnings chart. See detailed axie info for each scholar.
      </Change>
      <Change type="improved">
        Guild Dashboard: Search scholar table by ronin address. Sort scholar teams by # of axies.
      </Change>
    </Entry>
  );
};

export default Changes;
