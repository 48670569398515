import styled from "@emotion/styled";
import { Stack, useTheme, Button } from "@mui/material";
import Outcome from "../atoms/Outcome";
import GameTimestamp from "../atoms/GameTimestamp";
import ReplayButton from "../atoms/ReplayButton";
import { FaCompressAlt } from "react-icons/fa";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px",
  },
}));

const ExpandedBattleHeader = ({ battle, handleClick }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        gap={2}
        alignItems="center"
      >
        <Outcome result={battle.result} />
        <GameTimestamp end={battle.gameTimestamp || battle.gameEnded} />
      </Stack>

      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
        <Button
          className="viewButtons"
          startIcon={<FaCompressAlt />}
          color="secondary"
          size="small"
          sx={{
            opacity: 0,
            transition: "0.2s opacity ease",
            [theme.breakpoints.down("md")]: {
              opacity: 1,
            },
          }}
          onClick={handleClick}
        >
          Collapse
        </Button>

        <ReplayButton replayUrl={battle.replayUrl} />
      </Stack>
    </Wrapper>
  );
};

export default ExpandedBattleHeader;
