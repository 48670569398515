import React from "react";
// import { makeStyles } from "@mui/material/core/styles";
import { Checkbox } from "@mui/material";

// const useStyles = makeStyles({
//   root: {
//     color: "#7ddbc4",
//     checked: {},
//     padding: 0,
//   },
// });

const CustomCheckbox = (props) => {
  //   const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      color="default"
      inputProps={{ "aria-label": "primary checkbox" }}
      {...props}
    />
  );
};

export default CustomCheckbox;
