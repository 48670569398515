import React, { useRef } from "react";
import { Stack } from "@mui/material";
import AxieSlot from "./molecules/AxieSlot/AxieSlot";
import { connect } from "react-redux";
import {
  getItemDialogData,
  getTeamBuilderAxies,
} from "store/teamBuilder/teamBuilderSelectors";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Header from "./atoms/Header";
import AxieSlotCondensed from "./molecules/AxieSlotCondensed/AxieSlotCondensed";
import ItemDialog from "./organisms/ItemDialog/ItemDialog";
import DeckView from "./molecules/AxieSlot/DeckView";
import {
  updateCard,
  updateCharm,
  updateRune,
  updateClass,
  setDialogOpen,
  clearAxie,
  setDialogClosed,
} from "store/teamBuilder/teamBuilderActions";
import { getAllV3Data, getAllV3DataLoading } from "store/data/dataSelectors";
import { usePopulateFromUrl } from "./usePopulateFromUrl";
import TriballyTeamBuilderBanner from "components/TriballyAdBanner/TriballyTeamBuilderBanner";

export const PART_ORDER = ["eyes", "ears", "back", "mouth", "horn", "tail"];
export const ITEM_WIDTH = 170;
export const TEAM_BUILDER_AXIE_SLOTS = ["front", "mid", "back"];

const TeamBuilder = ({
  axies,
  view,
  dialogOpen,
  updateCard,
  updateCharm,
  updateRune,
  updateClass,
  itemData,
  setDialogOpen,
  clearAxie,
  setDialogClosed,
  itemDialogData,
  loading,
}) => {
  const initialLoad = useRef(true);

  usePopulateFromUrl(
    initialLoad,
    TEAM_BUILDER_AXIE_SLOTS,
    updateClass,
    updateCard,
    updateCharm,
    updateRune,
    itemData,
    loading
  );

  const handleOpenCardDialog = (axie, part) => {
    setDialogOpen(axie.id, "cards", part);
  };

  const handleOpenCharmDialog = (axie, part, cardClass) => {
    setDialogOpen(axie.id, "charms", part, cardClass);
  };

  const handleOpenRuneDialog = (axie) => {
    setDialogOpen(axie.id, "runes", undefined, axie.class);
  };

  return (
    <>
      <ItemDialog
        open={dialogOpen}
        updateRune={updateRune}
        updateCard={updateCard}
        updateCharm={updateCharm}
        setDialogClosed={setDialogClosed}
        data={itemDialogData}
      />
      <Stack sx={{ pt: 5, pb: 8 }}>
        <Stack gap={4} alignItems="center" sx={{ px: 2 }}>
          <Header />
          <TriballyTeamBuilderBanner />
          {loading ? (
            <CustomLoader marginTop={200} marginBottom={200} />
          ) : view === "condensed" ? (
            TEAM_BUILDER_AXIE_SLOTS.map((slot) => (
              <AxieSlotCondensed
                axie={axies[slot]}
                handleOpenCharmDialog={handleOpenCharmDialog}
                handleOpenCardDialog={handleOpenCardDialog}
                handleOpenRuneDialog={handleOpenRuneDialog}
                updateRune={updateRune}
                updateCard={updateCard}
                updateCharm={updateCharm}
                updateClass={updateClass}
                clearAxie={clearAxie}
                view={view}
                key={slot}
              />
            ))
          ) : view === "deck" ? (
            <DeckView />
          ) : (
            TEAM_BUILDER_AXIE_SLOTS.map((slot) => (
              <AxieSlot
                axie={axies[slot]}
                handleOpenCharmDialog={handleOpenCharmDialog}
                handleOpenCardDialog={handleOpenCardDialog}
                handleOpenRuneDialog={handleOpenRuneDialog}
                updateRune={updateRune}
                updateCard={updateCard}
                updateCharm={updateCharm}
                updateClass={updateClass}
                clearAxie={clearAxie}
                view={view}
                key={slot}
              />
            ))
          )}
        </Stack>
      </Stack>
    </>
  );
};

const mapDispatchToProps = {
  updateCard,
  updateCharm,
  updateRune,
  updateClass,
  setDialogOpen,
  clearAxie,
  setDialogClosed,
};

const mapStateToProps = (state) => ({
  axies: getTeamBuilderAxies(state),
  view: state.teamBuilder.view,
  dialogOpen: state.teamBuilder.dialog.open,
  itemData: getAllV3Data(state),
  itemDialogData: getItemDialogData(state),
  loading: getAllV3DataLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamBuilder);
