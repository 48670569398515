import React from "react";
import { Stack, useTheme } from "@mui/material";
import Counter from "./Counter";
import Text from "components/Typography/Text";

const BreedCount = ({ axie, color }) => {
  const theme = useTheme();

  if (axie.breedCount == null) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        py: 1,
        px: 1.5,
        borderRadius: "6px",
        boxShadow: `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
        background: theme.palette.card.background,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.06)",
          cursor: "pointer",
        },
      }}
    >
      <Text style={{ lineHeight: 1, width: 60, whiteSpace: "nowrap" }}>
        {`${axie.breedCount} Breeds`}
      </Text>
      <Counter filled={axie.breedCount} total={7} color={color} />
    </Stack>
  );
};

export default BreedCount;
