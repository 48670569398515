import { Stack, useTheme, Typography, Button } from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import useViewAllAxiesModal from "../hooks/useViewAllAxiesModal";
import PlayerAxiesModal from "./PlayerAxiesModal";

const PlayerAxies = ({
  showGenes,
  filteredAxies,
  roninAddress,
  playerName,
}) => {
  const theme = useTheme();
  const viewAllAxiesModal = useViewAllAxiesModal();
  const numAxiesToShow = 10;
  const numHidden = filteredAxies.length - numAxiesToShow;

  return (
    <>
      <Stack
        gap={1}
        flexWrap="wrap"
        direction="row"
        sx={{
          [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
          },
        }}
      >
        {filteredAxies.slice(0, 10).map((axie) => (
          <AxieV3
            id={axie.axieId}
            axie={axie}
            showGenes={showGenes}
            key={`${roninAddress}_${axie.axieId}`}
            boxShadow={false}
            variant="full"
            width={300}
          />
        ))}
        {numHidden > 0 && (
          <Stack
            gap={0.5}
            sx={{
              alignSelf: "center",
              px: 8,
              py: 4,
              m: 4,
              border: `1px solid ${theme.palette.card.divider}`,
              borderRadius: 2,
            }}
          >
            <Typography
              variant="body2"
              color="text.bright"
              fontWeight="700"
              fontSize={17}
              sx={{ lineHeight: 1.2 }}
            >
              {`+${numHidden} More`}
            </Typography>
            <Button onClick={viewAllAxiesModal.onOpen}>View All</Button>
          </Stack>
        )}
        {viewAllAxiesModal.isOpen && (
          <PlayerAxiesModal
            axies={filteredAxies}
            showGenes={showGenes}
            isOpen={viewAllAxiesModal.isOpen}
            onClose={viewAllAxiesModal.onClose}
            playerName={playerName}
          />
        )}
      </Stack>
    </>
  );
};

export default PlayerAxies;
