import React from "react";
import SelectBreedCount from "./SelectBreedCount";
import Label from "components/Typography/Label";
import InputsHeader from "./InputsHeader";
import { Stack, TextField } from "@mui/material";

const Parents = ({
  parent1BreedCount,
  setParent1BreedCount,
  parent1Sale,
  setParent1Sale,
  parent2BreedCount,
  setParent2BreedCount,
  parent2Sale,
  setParent2Sale,
  parent1Cost,
  setParent1Cost,
  parent2Cost,
  setParent2Cost,
  handleUpdate,
}) => {
  return (
    <Stack gap={2}>
      <InputsHeader
        title="Parent Details"
        description="Enter the costs of the parent axies and their estimated sale prices
          after breeding."
      />

      <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={1}>
        <Stack gap={1}>
          <Label>Parent 1</Label>
          <SelectBreedCount
            parentNum={1}
            parentBreedCount={parent1BreedCount}
            setParentBreedCount={setParent1BreedCount}
            handleUpdate={handleUpdate}
          />
          <TextField
            onChange={(e) => {
              setParent1Cost(e.target.value);
              handleUpdate();
            }}
            label="Purchase Price (ETH)"
            type="number"
            variant="filled"
            size="small"
            value={parent1Cost}
          />
          <TextField
            onChange={(e) => {
              setParent1Sale(e.target.value);
              handleUpdate();
            }}
            label="Sale Price (ETH)"
            type="number"
            variant="filled"
            size="small"
            value={parent1Sale}
          />
        </Stack>
        <Stack gap={1}>
          <Label>Parent 2</Label>
          <SelectBreedCount
            parentNum={2}
            parentBreedCount={parent2BreedCount}
            setParentBreedCount={setParent2BreedCount}
            handleUpdate={handleUpdate}
          />
          <TextField
            onChange={(e) => {
              setParent2Cost(e.target.value);
              handleUpdate();
            }}
            label="Purchase Price (ETH)"
            type="number"
            variant="filled"
            size="small"
            value={parent2Cost}
          />
          <TextField
            onChange={(e) => {
              setParent2Sale(e.target.value);
              handleUpdate();
            }}
            label="Sale Price (ETH)"
            type="number"
            variant="filled"
            size="small"
            value={parent2Sale}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Parents;
