import * as config from "config";

export const fetchRetry = async (url, options, n = 3) => {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (n === 1) {
      console.log("Could not fetch.");
      return Promise.reject("Could not fetch.");
      // throw err;
    } else {
      console.log("Fetch failed. Trying again...");
      setTimeout(async () => {
        return await fetchRetry(url, options, n - 1);
      }, 300);
    }
  }
};

export const fetchCurrencyData = async () => {
  const res = await fetch(config.COINGECKO_API);
  return res.json();
};
