import { displayPercentRounded } from "helpers/display";
import { Stack, Typography, styled } from "@mui/material";
import VictoryStar from "components/Leaderboard/atoms/VictoryStar";
import { makeRankIconUrl } from "helpers/arena";

const Background = styled(Stack)(({ theme }) => ({
  backgroundImage: "linear-gradient(100.2deg, #FCAB15 0%, #B24C0A 100.05%)",
  borderRadius: 18,
  padding: 2,

  [theme.breakpoints.down("md")]: {
    alignSelf: "flex-start",
  },
  [theme.breakpoints.down("sm")]: {
    alignSelf: "stretch",
  },
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 32,
  alignItems: "center",
  borderRadius: 16,
  backgroundColor: theme.palette.card.background,
  padding: "10px 28px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    gap: 16,
    alignSelf: "stretch",
    padding: "14px 20px 20px 20px",
  },
}));

const RankIcon = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "rankIconUrl",
})(({ rankIconUrl }: { rankIconUrl: string }) => ({
  width: 130,
  height: 130,
  backgroundImage: `url(${rankIconUrl})`,
  backgroundSize: "160%",
  backgroundPosition: "center",
}));

const Stars = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 6,
  background: theme.palette.text.bright,
  height: 30,
  borderRadius: 15,
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.card.background,
  alignSelf: "flex-start",
  fontWeight: 700,
  fontSize: 20,
  padding: "0 10px",
}));

interface Props {
  topRank: number;
  vstar: number;
  winRate?: number;
  rankTier: string;
  rank: string;
}

const Ranking = ({ topRank, vstar, winRate, rank, rankTier }: Props) => {
  return (
    <Background>
      <Wrapper>
        <Stack>
          <Typography
            color="text.bright"
            fontSize={14}
            fontWeight={700}
            sx={{ textTransform: "uppercase", lineHeight: 1 }}
          >
            Rank {topRank}
          </Typography>
          <Typography
            color="text.bright"
            fontSize={24}
            fontWeight={800}
            mb={1}
            sx={{ lineHeight: 1.2 }}
          >
            {rankTier}
          </Typography>
          <Stars>
            <VictoryStar />
            {vstar}
          </Stars>
          {winRate != null && (
            <Typography
              color="text.regular"
              fontSize={14}
              fontWeight={700}
              sx={{ mt: 1 }}
            >
              Win Rate: {displayPercentRounded(winRate)}
            </Typography>
          )}
        </Stack>

        <RankIcon rankIconUrl={makeRankIconUrl(rank)} />
      </Wrapper>
    </Background>
  );
};

export default Ranking;
