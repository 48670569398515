import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Stack,
  useTheme,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { FaTrash } from "react-icons/fa";
import {
  deleteScholar,
  setOpenDeleteScholarDialog,
} from "store/guildDashboard/actions/guildDashboardActions";
import * as scholarSelectors from "store/guildDashboard/selectors/scholar";

export const DeleteScholarDialog = ({
  open,
  setOpen,
  scholar,
  deleteScholar,
}) => {
  const theme = useTheme();
  const { account } = useWeb3React();
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteScholar = () => {
    deleteScholar(account, scholar.roninAddress, scholar.guildTeamId);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack gap="6px" direction="row" alignItems="center">
          <Typography variant="span" fontSize="14px">
            <FaTrash />
          </Typography>
          Delete Scholar
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.regular">
          Are you sure you want to delete{" "}
          {scholar ? scholar.name : "this scholar"}?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteScholar}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.feedback.red,
            "&:hover": {
              backgroundColor: theme.palette.feedback.red,
            },
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  setOpen: setOpenDeleteScholarDialog,
  deleteScholar,
};

const mapStateToProps = (state) => ({
  scholar: scholarSelectors.getScholarToModify(state),
  open: state.guildDashboard.openDeleteScholarDialog,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteScholarDialog);
