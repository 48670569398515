import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
} from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";

const PlayerAxiesModal = ({
  axies,
  showGenes,
  onClose,
  isOpen,
  playerName,
}) => {
  return (
    <Dialog scroll="paper" maxWidth={"lg"} open={isOpen} onClose={onClose}>
      <DialogTitle>View All Axies for {playerName}</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {axies.map((axie) => (
          <AxieV3
            key={axie.axieId}
            id={axie.axieId}
            axie={axie}
            showGenes={showGenes}
            boxShadow={false}
            variant="full"
            width={300}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerAxiesModal;
