import React, { useState } from "react";
import Search from "pages/GuildDashboard/atoms/Search";
import { connect } from "react-redux";
import {
  updateAxiesBreedCount,
  updateAxiesPurity,
  updateAxiesRole,
  updateAxiesSpecialty,
  updateAxiesPurityPercent,
  updateAxiesHP,
  updateAxiesSkill,
  updateAxiesSpeed,
  updateAxiesMorale,
  updateAxiesSortType,
  updateAxiesAxieClasses,
  updateAxiesSearchQuery,
  updateAxiesShowGenes,
  resetAxieFilters,
  updateAxiesSelectedParts,
  updateAxiesSelectedPartsMatchD,
  updateAxiesSelectedPartsMatchR1,
  updateAxiesSelectedPartsMatchR2,
  updateAxiesSelectedPartsIndividualPartMatches,
} from "store/guildDashboard/actions/guildDashboardActions";
import { Stack } from "@mui/material";
import FilterAxiesMenu from "./FilterAxiesMenu";
import Sort from "pages/GuildDashboard/atoms/Sort";
import FilterPopupButton from "pages/GuildDashboard/atoms/FilterPopupButton";
import { getAllCardsV3 } from "store/data/dataSelectors";

const Controls = ({
  updateAxiesBreedCount,
  updateAxiesPurity,
  updateAxiesRole,
  updateAxiesSpecialty,
  updateAxiesPurityPercent,
  updateAxiesHP,
  updateAxiesSkill,
  updateAxiesSpeed,
  updateAxiesMorale,
  updateAxiesSortType,
  updateAxiesAxieClasses,
  updateAxiesSearchQuery,
  updateAxiesShowGenes,
  resetAxieFilters,
  breedCount,
  purity,
  role,
  specialty,
  purityPercent,
  hp,
  skill,
  speed,
  morale,
  sortType,
  axieClasses,
  searchQuery,
  showGenes,
  cards,
  selectedParts,
  updateAxiesSelectedParts,
  updateAxiesSelectedPartsMatchD,
  selectedPartsMatchD,
  updateAxiesSelectedPartsMatchR1,
  selectedPartsMatchR1,
  updateAxiesSelectedPartsMatchR2,
  selectedPartsMatchR2,
  updateAxiesSelectedPartsIndividualPartMatches,
  selectedPartsIndividualPartMatches,
}) => {
  const [axieFiltersEl, setAxieFiltersEl] = useState(null);
  const axieFiltersOpen = Boolean(axieFiltersEl);

  const handleOpenAxieFilters = (e) => {
    setAxieFiltersEl(e.currentTarget);
  };

  const handleUpdateAxiesSortType = (e) => {
    updateAxiesSortType(e.target.value);
  };

  const sortOptions = [
    { label: "Highest Stars", value: "MMR_DESC" },
    { label: "Lowest Stars", value: "MMR_ASC" },
    { label: "Name: A-Z", value: "NAME_ASC" },
    { label: "Name: Z-A", value: "NAME_DESC" },
    { label: "Most Axies", value: "AXIES_DESC" },
    { label: "Least Axies", value: "AXIES_ASC" },
  ];

  return (
    <>
      <FilterAxiesMenu
        resetAxieFilters={resetAxieFilters}
        updateAxiesShowGenes={updateAxiesShowGenes}
        setAxieFiltersEl={setAxieFiltersEl}
        axieFiltersEl={axieFiltersEl}
        axieFiltersOpen={axieFiltersOpen}
        showGenes={showGenes}
        axieClasses={axieClasses}
        updateAxiesAxieClasses={updateAxiesAxieClasses}
        purity={purity}
        updateAxiesPurity={updateAxiesPurity}
        breedCount={breedCount}
        updateAxiesBreedCount={updateAxiesBreedCount}
        role={role}
        updateAxiesRole={updateAxiesRole}
        specialty={specialty}
        updateAxiesSpecialty={updateAxiesSpecialty}
        hp={hp}
        updateAxiesHP={updateAxiesHP}
        speed={speed}
        updateAxiesSpeed={updateAxiesSpeed}
        skill={skill}
        updateAxiesSkill={updateAxiesSkill}
        morale={morale}
        updateAxiesMorale={updateAxiesMorale}
        purityPercent={purityPercent}
        updateAxiesPurityPercent={updateAxiesPurityPercent}
        cards={cards}
        selectedParts={selectedParts}
        updateAxiesSelectedParts={updateAxiesSelectedParts}
        selectedPartsMatchD={selectedPartsMatchD}
        updateAxiesSelectedPartsMatchD={updateAxiesSelectedPartsMatchD}
        selectedPartsMatchR1={selectedPartsMatchR1}
        updateAxiesSelectedPartsMatchR1={updateAxiesSelectedPartsMatchR1}
        selectedPartsMatchR2={selectedPartsMatchR2}
        updateAxiesSelectedPartsMatchR2={updateAxiesSelectedPartsMatchR2}
        selectedPartsIndividualPartMatches={selectedPartsIndividualPartMatches}
        updateAxiesSelectedPartsIndividualPartMatches={
          updateAxiesSelectedPartsIndividualPartMatches
        }
      />

      <Stack direction="row" justifyContent="flex-end" gap={2} flexWrap="wrap">
        <FilterPopupButton
          id="filter-axies-menu"
          isOpen={axieFiltersOpen}
          updateIsOpen={handleOpenAxieFilters}
        />
        <Sort
          sort={sortType}
          updateSort={handleUpdateAxiesSortType}
          options={sortOptions}
        />
        <Search value={searchQuery} updateValue={updateAxiesSearchQuery} placeholder="Search players" />
      </Stack>
    </>
  );
};

const mapDispatchToProps = {
  updateAxiesBreedCount,
  updateAxiesPurity,
  updateAxiesRole,
  updateAxiesSpecialty,
  updateAxiesPurityPercent,
  updateAxiesHP,
  updateAxiesSkill,
  updateAxiesSpeed,
  updateAxiesMorale,
  updateAxiesSortType,
  updateAxiesAxieClasses,
  updateAxiesSearchQuery,
  updateAxiesShowGenes,
  updateAxiesSelectedParts,
  updateAxiesSelectedPartsMatchD,
  updateAxiesSelectedPartsMatchR1,
  updateAxiesSelectedPartsMatchR2,
  updateAxiesSelectedPartsIndividualPartMatches,
  resetAxieFilters,
};

const mapStateToProps = (state) => ({
  breedCount: state.guildDashboard.axies.breedCount,
  purity: state.guildDashboard.axies.purity,
  role: state.guildDashboard.axies.role,
  specialty: state.guildDashboard.axies.specialty,
  purityPercent: state.guildDashboard.axies.purityPercent,
  hp: state.guildDashboard.axies.hp,
  skill: state.guildDashboard.axies.skill,
  speed: state.guildDashboard.axies.speed,
  morale: state.guildDashboard.axies.morale,
  sortType: state.guildDashboard.axies.sortType,
  axieClasses: state.guildDashboard.axies.axieClasses,
  searchQuery: state.guildDashboard.axies.searchQuery,
  showGenes: state.guildDashboard.axies.showGenes,
  selectedParts: state.guildDashboard.axies.selectedParts,
  selectedPartsMatchD: state.guildDashboard.axies.selectedPartsMatchD,
  selectedPartsMatchR1: state.guildDashboard.axies.selectedPartsMatchR1,
  selectedPartsMatchR2: state.guildDashboard.axies.selectedPartsMatchR2,
  selectedPartsIndividualPartMatches:
    state.guildDashboard.axies.selectedPartsIndividualPartMatches,
  cards: getAllCardsV3(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
