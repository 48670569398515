import * as actionTypes from "./breedingCalculatorActionTypes";

export const loadBreedingCalculator = () => ({
  type: actionTypes.LOAD_BREEDING_CALCULATOR,
});

export const updateBreedingCalculatorInputs = (data) => ({
  type: actionTypes.UPDATE_BREEDING_CALCULATOR_INPUTS,
  payload: data,
});
