import { RiAwardFill } from "react-icons/ri";

export const leaderboardStructures = {
  classic: {
    itemSlug: "roninAddress",
    grid: "36px 2.5fr 1fr",
    columns: [
      { text: "Name", fontWeight: "700", color: "text.primary", key: "name" },
      { text: "MMR", align: "center", key: "elo" },
    ],
    linkTo: "/arena-leaderboard",
    title: "Top Arena Players",
    titleIcon: <RiAwardFill />,
  },
  origin: {
    baseURL: "/player",
    itemSlug: "clientId",
    grid: "36px 2.5fr 1fr",
    columns: [
      { text: "Name", fontWeight: "700", color: "text.primary", key: "name" },
      { text: "Stars", align: "center", type: "victoryStars", key: "vstar" },
    ],
    title: "Top 100 Arena Players",
    linkTo: "/origins-leaderboard",
    titleIcon: <RiAwardFill />,
  },
  guildArena: {
    baseURL: "/guild",
    itemSlug: "logoUri",
    grid: "36px 32px 3fr 2fr 2fr",
    columns: [
      { text: "", key: "profileImage", type: "image" },
      {
        text: "Guild",
        fontWeight: "600",
        color: "text.primary",
        key: "guildName",
      },
      { text: "# Players", align: "center", key: "numScholars" },
      {
        text: "Avg Stars",
        type: "victoryStars",
        align: "center",
        key: "averageElo",
      },
    ],
    title: "Top Guilds - Average Victory Stars",
    linkTo: "/guild-leaderboards",
    titleIcon: <img src="/images/icons/victory-star.png" alt="Victory Star" />,
  },
  guildBattle: {
    baseURL: "/guild",
    itemSlug: "logoUri",
    grid: "32px 32px 3fr 1fr 2fr",
    columns: [
      { text: "", key: "profileImage", type: "image" },
      {
        text: "Guild",
        fontWeight: "600",
        color: "text.primary",
        key: "guildName",
      },
      { text: "# Players", align: "center", key: "numScholars" },
      // {
      //   text: "Avg Stars",
      //   type: "victoryStars",
      //   align: "center",
      //   key: "averageElo",
      // },
      //show Prize for results phase of Guild Battle
      {
        text: "Prize",
        type: "prize",
        align: "center",
        color: "text.primary",
        fontWeight: "600",
        fontSize: "18px",
        key: "prize",
      },
    ],
    title: "Axie Tech Guild Battle Leaderboard",
    linkTo: "/guild-membership",
    // titleIcon: <GiSwordsEmblem fontSize={35} />,
  },
  scholarArena: {
    baseURL: "/guild",
    itemSlug: "logoUri",
    grid: "36px 4fr 32px 3fr 3fr",
    columns: [
      { text: "Name", fontWeight: "600", color: "text.primary", key: "name" },
      { text: "", key: "profileImage", type: "image" },
      { text: "Guild", fontWeight: "600", key: "guildName" },
      { text: "Stars", type: "victoryStars", align: "center", key: "elo" },
    ],
    title: "Top Players - Victory Stars",
    linkTo: "/player-leaderboards",
    titleIcon: <img src="/images/icons/victory-star.png" alt="Victory Star" />,
  },
  scholarSLP: {
    baseURL: "/guild",
    itemSlug: "logoUri",
    grid: "36px 4fr 32px 3fr 2fr",
    columns: [
      {
        text: "Name",
        fontWeight: "600",
        color: "text.primary",
        key: "scholarName",
      },
      { text: "", key: "profileImage", type: "image" },
      { text: "Guild", fontWeight: "600", key: "guildName" },
      {
        text: "Avg SLP",
        align: "center",
        key: "averageDailySLP",
      },
    ],
    title: "Top Players - Average Daily SLP",
    linkTo: "/player-leaderboards",
    titleIcon: <img src="/images/icons/slp.png" alt="SLP" />,
  },
};
