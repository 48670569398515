import React from "react";
import { Box, useTheme } from "@mui/material";

const ResultWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background:
          theme.palette.mode === "light"
            ? theme.palette.card.pop1
            : theme.palette.card.shade1,
        border: `1px solid ${theme.palette.card.divider}`,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        position: "relative",
        gap: "8px",
        flexDirection: "column",
        flexWrap: "wrap",
        width: "100%",
        p: 1,
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
          flexDirection: "row",
          width: "auto",
          maxWidth: "100%",
          justifyContent: "center",
        },
        "@media (min-width: 1380px)": {
          width: 1142,
          justifyContent: "flex-start",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default ResultWrapper;
