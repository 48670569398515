import React from "react";
import { connect } from "react-redux";
import { getFavoriteRunesToDisplay } from "store/explorer/selectors";
import { favoriteRune } from "store/explorer/explorerActions";
import Section from "../../molecules/FavoritesSection";
import WrappedRune from "pages/Explorer/molecules/WrappedRune";

const Runes = ({ favoriteRunesToDisplay, favoriteRune }) => {
  return (
    <Section type="Rune" numItems={favoriteRunesToDisplay.length}>
      {favoriteRunesToDisplay.map((rune) => (
        <WrappedRune
          key={rune.id}
          rune={rune}
          favoriteRune={favoriteRune}
        />
      ))}
    </Section>
  );
};

const mapDispatchToProps = { favoriteRune };

const mapStateToProps = (state) => ({
  favoriteRunesToDisplay: getFavoriteRunesToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Runes);
