import * as types from "../actions/actionTypes";
import produce from "immer";
import { calculatePurityAxieTech } from "helpers/axie";
import { ARENA_SEASON_CURRENT } from "config";

const initialState = {
  guild: { searchQuery: "" },
  numRequests: 0,
  axies: {
    breedCount: [],
    purity: [],
    role: [],
    specialty: [],
    purityPercent: 0,
    hp: 27,
    skill: 27,
    speed: 27,
    morale: 27,
    sortType: "MMR_DESC",
    axieClasses: [],
    searchQuery: "",
    showGenes: true,
    selectedParts: [],
    selectedPartsMatchD: true,
    selectedPartsMatchR1: false,
    selectedPartsMatchR2: false,
    selectedPartsIndividualPartMatches: {},
    currentPage: 1,
  },
  scholarToModifyIds: {
    roninAddress: undefined,
    guildTeamId: undefined,
  },
  openAddTeamDialog: false,
  openEditScholarDialog: false,
  openEditTeamDialog: false,
  openDeleteScholarDialog: false,
  axieData: {},
  playerData: {},
  autopayout: {},
  config: {
    scholarEarningsChartToDisplay: ["total"],
    scholarBattlesChartToDisplay: ["pvp"],
    numberOfScholarsPerPage: 20,
    scholarPVPBattlesCurrentPage: 1,
    scholarsTable: {
      activeTab: 0,
      currentPage: {
        SLP: 0,
        ARENA: 0,
        WATCHLIST: 0,
      },
      sortModel: {
        SLP: [
          {
            field: "totalSLP",
            sort: "desc",
          },
        ],
        ARENA: [
          {
            field: "mmr",
            sort: "desc",
          },
        ],
        WATCHLIST: [
          {
            field: "totalSLP",
            sort: "desc",
          },
        ],
      },
    },
    scholarsTableTeam: {
      activeTab: 0,
      currentPage: {
        SLP: 0,
        ARENA: 0,
        WATCHLIST: 0,
      },
      sortModel: {
        SLP: [
          {
            field: "totalSLP",
            sort: "desc",
          },
        ],
        ARENA: [
          {
            field: "mmr",
            sort: "desc",
          },
        ],
        WATCHLIST: [
          {
            field: "totalSLP",
            sort: "desc",
          },
        ],
      },
    },
    activeTabScholarsTable: 0,
    activeTabScholarsTableTeam: 0,
    currentPageScholarsTable: {
      SLP: 0,
      ARENA: 0,
      WATCHLIST: 0,
    },
    currentPageScholarsTableTeam: {
      SLP: 0,
      ARENA: 0,
      WATCHLIST: 0,
    },
  },
  inventory: {
    controls: {
      searchQuery: "",
      sortType: "NAME_ASC",
      filters: {
        itemTypes: [],
        axieClasses: [],
        blockchainStatuses: [],
        seasons: [ARENA_SEASON_CURRENT.label],
      },
      sortOptions: [
        { label: "Name: A-Z", value: "NAME_ASC" },
        { label: "Name: Z-A", value: "NAME_DESC" },
        { label: "Highest Quantity", value: "QUANTITY_DESC" },
        { label: "Lowest Quantity", value: "QUANTITY_ASC" },
        { label: "Most Rare", value: "RARITY_DESC" },
        { label: "Least Rare", value: "RARITY_ASC" },
      ],
    },
  },
};

const getScholarIndex = (scholarArray, roninAddress) => {
  return scholarArray.findIndex((s) => s.roninAddress === roninAddress);
};

const getScholarArray = (draft, guildTeamId) => {
  if (!guildTeamId || guildTeamId === 0) {
    return draft.guild.scholars;
  }
  return draft.guild.teams.find((t) => t.guildTeamId === guildTeamId)?.scholars;
};

const getScholar = (draft, roninAddress, guildTeamId) => {
  const scholarArray = getScholarArray(draft, guildTeamId);
  return scholarArray
    ? scholarArray.find((s) => s.roninAddress === roninAddress)
    : undefined;
};

const getTeam = (draft, guildTeamId) => {
  return draft.guild.teams.find((t) => t.guildTeamId === guildTeamId);
};

const getTeamIndex = (draft, guildTeamId) => {
  return draft.guild.teams.findIndex((t) => t.guildTeamId === guildTeamId);
};

export const guildDashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // ---------------------- ADD / REMOVE REQUESTS
      case types.GUILD_DASHBOARD_ADD_REQUESTS:
        draft.numRequests = draft.numRequests + action.amount;
        break;
      case types.GUILD_DASHBOARD_REMOVE_REQUESTS:
        draft.numRequests = draft.numRequests - action.amount;
        break;

      // ---------------------- UPDATE USER PREFERENCES
      case types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_STARTED:
        draft.guild.updatingUserPreferences = true;
        break;
      case types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_SUCCEEDED:
        draft.guild = {
          ...draft.guild,
          ...action.userPreferences,
          updatingUserPreferences: false,
        };
        break;
      case types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_FAILED:
        draft.guild.failedToUpdateUserPreferences = true;
        draft.guild.updatingUserPreferences = false;
        break;

      // ---------------------- WATCH / UNWATCH
      case types.GUILD_DASHBOARD_WATCH_SCHOLAR_SUCCEEDED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.isOnWatchList = 1;
        }
        break;
      }
      case types.GUILD_DASHBOARD_UNWATCH_SCHOLAR_SUCCEEDED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.isOnWatchList = 0;
        }
        break;
      }

      // ---------------------- FETCH AXIES
      case types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_STARTED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingAxies = true;
          scholar.axies = undefined;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_SUCCEEDED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingAxies = false;
          scholar.axies = [...(scholar.axies ?? []), ...action.axies.axies];
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_FAILED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingAxies = false;
          scholar.failedToFetchAxies = true;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_AXIE_DETAIL_SUCCEEDED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          let axieToUpdate = scholar.axies.find(
            (axie) => axie.axieId === action.axie.axieId
          );
          if (axieToUpdate) {
            axieToUpdate = Object.assign(axieToUpdate, action.axie, {
              purity: calculatePurityAxieTech(action.axie),
            });
          }
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_SCHOLAR_AXIE_DATA_SUCCEEDED: {
        draft.axieData[action.axie.axieId] = {
          ...action.axie,
          purity: calculatePurityAxieTech(action.axie),
        };
        break;
      }

      // ---------------------- FETCH LIVE DATA
      case types.GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_STARTED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingLiveData = true;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_SUCCEEDED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingLiveData = false;
          scholar.liveData = Object.assign(
            scholar.liveData ?? {},
            action.payload
          );
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_FAILED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingLiveData = false;
          scholar.failedToFetchLiveData = true;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_STARTED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingBattlesData = true;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_SUCCEEDED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingBattlesData = false;
          scholar.liveData = Object.assign(
            scholar.liveData ?? {},
            action.payload
          );
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_FAILED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingBattlesData = false;
        }
        break;
      }

      // ---------------------- MANAGER LOGIN
      case types.GUILD_DASHBOARD_LOGIN_MANAGER_STARTED:
        draft.guild.fetching = true;
        break;
      case types.GUILD_DASHBOARD_LOGIN_MANAGER_SUCCEEDED:
        const scholarsWithAxies = (action.payload?.scholars ?? []).map(
          (scholar) => ({
            ...scholar,
            axies: (scholar.axies ?? []).map((axie) => ({
              ...axie,
              id: axie.axieId,
              purity:
                axie.stage === "4" || axie.stage === 4
                  ? calculatePurityAxieTech(axie)
                  : undefined,
            })),
          })
        );
        draft.guild = Object.assign(action.payload, {
          fetching: false,
          scholars: scholarsWithAxies,
          teams: action.payload?.teams ?? [],
        });
        break;
      case types.GUILD_DASHBOARD_LOGIN_MANAGER_FAILED:
        draft.guild.fetching = false;
        draft.guild.loginFailed = true;
        break;

      // ---------------------- ADD SCHOLAR
      case types.GUILD_DASHBOARD_ADD_SCHOLAR_STARTED:
        draft.guild.addingScholar = true;
        draft.guild.failedToAddScholar = false;
        break;
      case types.GUILD_DASHBOARD_ADD_SCHOLAR_SUCCEEDED: {
        const scholarArray = getScholarArray(
          draft,
          action.scholarData.guildTeamId
        );
        draft.guild.addingScholar = false;
        if (action.scholarData.guildTeamId !== 0 && !scholarArray) {
          const team = getTeam(draft, action.scholarData.guildTeamId);
          team.scholars = [action.scholarData];
        } else {
          scholarArray.push(action.scholarData);
        }
        break;
      }
      case types.GUILD_DASHBOARD_ADD_SCHOLAR_FAILED:
        draft.guild.addingScholar = false;
        draft.guild.failedToAddScholar = true;
        break;

      // ---------------------- SET SCHOLAR TO MODIFY
      case types.GUILD_DASHBOARD_SET_SCHOLAR_TO_MODIFY_IDS:
        draft.scholarToModifyIds = {
          roninAddress: action.roninAddress,
          guildTeamId: action.guildTeamId,
        };
        break;

      // ---------------------- DELETE SCHOLAR
      case types.GUILD_DASHBOARD_UPDATE_DELETE_SCHOLAR_DIALOG_IS_OPEN:
        draft.openDeleteScholarDialog = action.isOpen;
        break;
      case types.GUILD_DASHBOARD_DELETE_SCHOLAR_STARTED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.deleting = true;
        }
        break;
      }
      case types.GUILD_DASHBOARD_DELETE_SCHOLAR_SUCCEEDED: {
        const scholarArray = getScholarArray(draft, action.guildTeamId);
        const scholarIndex = getScholarIndex(scholarArray, action.roninAddress);
        if (scholarIndex !== -1) {
          scholarArray.splice(scholarIndex, 1);
        }
        draft.scholarToModifyIds = {
          roninAddress: undefined,
          guildTeamId: undefined,
        };
        if (draft.config.activeScholarRoninAddress === action.roninAddress) {
          draft.config.activeScholarRoninAddress = undefined;
        }
        break;
      }
      case types.GUILD_DASHBOARD_DELETE_SCHOLAR_FAILED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.deleting = false;
          scholar.failedToDelete = true;
        }
        break;
      }

      // ---------------------- EDIT SCHOLAR
      case types.GUILD_DASHBOARD_UPDATE_EDIT_SCHOLAR_DIALOG_IS_OPEN:
        draft.openEditScholarDialog = action.isOpen;
        break;
      case types.GUILD_DASHBOARD_EDIT_SCHOLAR_STARTED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.editing = true;
          scholar.failedToEdit = false;
        }
        break;
      }
      case types.GUILD_DASHBOARD_EDIT_SCHOLAR_SUCCEEDED: {
        const oldScholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        let editedScholarData = {
          ...oldScholar,
          ...action.scholarData,
          editing: false,
        };
        // Update in place (new guildTeamId === old guildTeamId)
        if (action.scholarData.guildTeamId === action.guildTeamId) {
          let scholar = getScholar(
            draft,
            action.roninAddress,
            action.guildTeamId
          );
          if (scholar) {
            scholar = Object.assign(scholar, editedScholarData);
          }
        }
        // Move to new team, delete from old team
        else {
          const scholarToDeleteArray = getScholarArray(
            draft,
            action.guildTeamId
          );
          const scholarToDeleteIndex = getScholarIndex(
            scholarToDeleteArray,
            action.roninAddress
          );
          const newLocationForScholar = getScholarArray(
            draft,
            action.scholarData.guildTeamId
          );

          scholarToDeleteArray.splice(scholarToDeleteIndex, 1);
          newLocationForScholar.push(editedScholarData);
        }
        draft.openEditScholarDialog = false;
        draft.scholarToModifyIds = {
          roninAddress: undefined,
          guildTeamId: undefined,
        };
        break;
      }
      case types.GUILD_DASHBOARD_EDIT_SCHOLAR_FAILED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.editing = false;
          scholar.failedToEdit = true;
        }
        break;
      }

      // ---------------------- FETCH SCHOLAR SNAPSHOT
      case types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_STARTED: {
        const scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar.fetchingSnapshot = true;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_SUCCEEDED: {
        let scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar = Object.assign(scholar, action.payload, {
            fetchingSnapshot: false,
          });
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_FAILED: {
        let scholar = getScholar(
          draft,
          action.roninAddress,
          action.guildTeamId
        );
        if (scholar) {
          scholar = Object.assign(scholar, {
            fetchingSnapshot: false,
            failedToFetchSnapshot: true,
          });
        }
        break;
      }

      // ---------------------- AXIES FILTERS
      case types.GUILD_DASHBOARD_UPDATE_AXIES_BREED_COUNT:
        draft.axies.currentPage = 1;
        draft.axies.breedCount = action.breedCount;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_PURITY:
        draft.axies.currentPage = 1;
        draft.axies.purity = action.purity;
        break;
      case types.GUILD_DASHBOARD_AXIE_UPDATE_ROLE:
        draft.axies.currentPage = 1;
        draft.axies.role = action.role;
        break;
      case types.GUILD_DASHBOARD_AXIE_UPDATE_SPECIALTY:
        draft.axies.currentPage = 1;
        draft.axies.specialty = action.specialty;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_PURITY_PERCENT:
        draft.axies.currentPage = 1;
        draft.axies.purityPercent = action.purityPercent;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_HP:
        draft.axies.currentPage = 1;
        draft.axies.hp = action.hp;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SKILL:
        draft.axies.currentPage = 1;
        draft.axies.skill = action.skill;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SPEED:
        draft.axies.currentPage = 1;
        draft.axies.speed = action.speed;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_MORALE:
        draft.axies.currentPage = 1;
        draft.axies.morale = action.morale;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SORT_TYPE:
        draft.axies.currentPage = 1;
        draft.axies.sortType = action.sortType;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_AXIE_CLASSES:
        draft.axies.currentPage = 1;
        draft.axies.axieClasses = action.axieClasses;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SEARCH_QUERY:
        draft.axies.currentPage = 1;
        draft.axies.searchQuery = action.searchQuery;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SHOW_GENES:
        draft.axies.showGenes = action.showGenes;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_CURRENT_PAGE:
        draft.axies.currentPage = action.currentPage;
        break;
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS: {
        if (action.selectedParts.length > draft.axies.selectedParts.length) {
          const existingPartIds = draft.axies.selectedParts.map(
            (part) => part.partId
          );
          const newPart = action.selectedParts.find(
            (part) => !existingPartIds.includes(part.partId)
          );
          draft.axies.selectedPartsIndividualPartMatches[newPart.partId] = {
            d: true,
          };
        }
        if (action.selectedParts.length < draft.axies.selectedParts.length) {
          const selectedPartIds = action.selectedParts.map(
            (part) => part.partId
          );
          const deletedPart = draft.axies.selectedParts.find(
            (part) => !selectedPartIds.includes(part.partId)
          );
          delete draft.axies.selectedPartsIndividualPartMatches[
            deletedPart.partId
          ];
        }
        draft.axies.selectedParts = action.selectedParts;
        break;
      }
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_D: {
        const selected = draft.axies.selectedPartsIndividualPartMatches;
        const parts = Object.keys(selected);
        draft.axies.selectedPartsMatchD = action.match;
        parts.forEach((part) => {
          selected[part].d = action.match;
        });
        break;
      }
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R1: {
        const selected = draft.axies.selectedPartsIndividualPartMatches;
        const parts = Object.keys(selected);
        draft.axies.selectedPartsMatchR1 = action.match;
        parts.forEach((part) => {
          selected[part].r1 = action.match;
        });
        break;
      }
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R2: {
        const selected = draft.axies.selectedPartsIndividualPartMatches;
        const parts = Object.keys(selected);
        draft.axies.selectedPartsMatchR2 = action.match;
        parts.forEach((part) => {
          selected[part].r2 = action.match;
        });
        break;
      }
      case types.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_INDIVIDUAL_PART_MATCHES: {
        const selected = draft.axies.selectedPartsIndividualPartMatches;
        if (action.partId in selected) {
          if (action.gene in selected[action.partId]) {
            selected[action.partId][action.gene] =
              !selected[action.partId][action.gene];
          } else {
            selected[action.partId][action.gene] = true;
          }
        } else {
          selected[action.partId] = {
            [action.gene]: true,
          };
        }
        draft.axies.selectedPartsMatchR2 = action.match;
        break;
      }
      case types.GUILD_DASHBOARD_RESET_AXIE_FILTERS:
        draft.axies.breedCount = [];
        draft.axies.purity = [];
        draft.axies.role = [];
        draft.axies.specialty = [];
        draft.axies.purityPercent = 0;
        draft.axies.hp = 27;
        draft.axies.skill = 27;
        draft.axies.speed = 27;
        draft.axies.morale = 27;
        draft.axies.sortType = "MMR_DESC";
        draft.axies.axieClasses = [];
        draft.axies.searchQuery = "";
        draft.axies.currentPage = 1;
        draft.axies.selectedParts = [];
        draft.axies.selectedPartsMatchD = true;
        draft.axies.selectedPartsMatchR1 = false;
        draft.axies.selectedPartsMatchR2 = false;
        draft.axies.selectedPartsIndividualPartMatches = {};
        break;

      // ---------------------- SEARCH SCHOLAR NAME
      case types.GUILD_DASHBOARD_UPDATE_GUILD_SEARCH_QUERY:
        draft.guild.searchQuery = action.searchQuery;
        break;

      // ---------------------- IMPORT SCHOLARS
      case types.GUILD_DASHBOARD_IMPORT_SCHOLARS_STARTED:
        draft.guild.importingScholars = true;
        break;
      case types.GUILD_DASHBOARD_IMPORT_SCHOLARS_SUCCEEDED:
        draft.guild.importingScholars = false;
        break;
      case types.GUILD_DASHBOARD_IMPORT_SCHOLARS_FAILED:
        draft.guild.importingScholars = false;
        draft.guild.failedToImportScholars = true;
        break;

      // ---------------------- ADD TEAM
      case types.GUILD_DASHBOARD_UPDATE_ADD_TEAM_DIALOG_IS_OPEN:
        draft.openAddTeamDialog = action.isOpen;
        break;
      case types.GUILD_DASHBOARD_ADD_TEAM_STARTED:
        draft.guild.addingTeam = true;
        draft.guild.addingTeamFailed = false;
        break;
      case types.GUILD_DASHBOARD_ADD_TEAM_SUCCEEDED:
        draft.guild.addingTeam = false;
        if (draft.guild.teams) {
          draft.guild.teams.push({
            guildTeamId: action.guildTeamId,
            guildId: action.guildId,
            scholars: [],
            ...action.teamData,
            isPublic: action.teamData.isPublic ? 1 : 0,
          });
        } else {
          draft.guild.teams = [
            {
              guildTeamId: action.guildTeamId,
              guildId: action.guildId,
              scholars: [],
              ...action.teamData,
              isPublic: action.teamData.isPublic ? 1 : 0,
            },
          ];
        }
        break;
      case types.GUILD_DASHBOARD_ADD_TEAM_FAILED:
        draft.guild.addingTeam = false;
        draft.guild.addingTeamFailed = true;
        break;

      // ---------------------- DELETE TEAM
      case types.GUILD_DASHBOARD_DELETE_TEAM_STARTED: {
        const team = getTeam(draft, action.guildTeamId);
        if (team) {
          team.deletingTeam = true;
          team.deletingTeamFailed = false;
        }
        break;
      }
      case types.GUILD_DASHBOARD_DELETE_TEAM_SUCCEEDED: {
        const teamIndex = getTeamIndex(draft, action.guildTeamId);
        if (teamIndex !== -1) {
          if (action.scholars && action.scholars.length > 0) {
            const scholarsInDeletedTeam = action.scholars.map((scholar) => ({
              ...scholar,
              guildTeamId: 0,
            }));
            draft.guild.scholars.push(...scholarsInDeletedTeam);
          }

          draft.guild.teams.splice(teamIndex, 1);
          draft.guild.activeGuildTeamId = undefined;
        }
        draft.openEditTeamDialog = false;
        break;
      }
      case types.GUILD_DASHBOARD_DELETE_TEAM_FAILED: {
        const team = getTeam(draft, action.guildTeamId);
        if (team) {
          team.deletingTeam = false;
          team.deletingTeamFailed = true;
        }
        break;
      }

      // ---------------------- EDIT TEAM
      case types.GUILD_DASHBOARD_UPDATE_EDIT_TEAM_DIALOG_IS_OPEN:
        draft.openEditTeamDialog = action.isOpen;
        break;
      case types.GUILD_DASHBOARD_EDIT_TEAM_STARTED: {
        const team = getTeam(draft, action.guildTeamId);
        if (team) {
          team.editingTeam = true;
          team.editingTeamFailed = false;
        }
        break;
      }
      case types.GUILD_DASHBOARD_EDIT_TEAM_SUCCEEDED: {
        let team = getTeam(draft, action.guildTeamId);
        if (team) {
          team = Object.assign(
            team,
            { ...action.teamData, isPublic: action.teamData.isPublic ? 1 : 0 },
            {
              editingTeam: false,
            }
          );
        }
        draft.openEditTeamDialog = false;
        break;
      }
      case types.GUILD_DASHBOARD_EDIT_TEAM_FAILED: {
        const team = getTeam(draft, action.guildTeamId);
        if (team) {
          team.editingTeam = false;
          team.editingTeamFailed = true;
        }
        break;
      }

      // ---------------------- FETCH TEAMS
      case types.GUILD_DASHBOARD_FETCH_TEAMS_STARTED:
        draft.guild.fetchingTeams = true;
        draft.guild.fetchingTeamsFailed = false;
        break;
      case types.GUILD_DASHBOARD_FETCH_TEAMS_SUCCEEDED:
        draft.guild.fetchingTeams = false;
        break;
      case types.GUILD_DASHBOARD_FETCH_TEAMS_FAILED:
        draft.guild.fetchingTeams = false;
        draft.guild.fetchingTeamsFailed = true;
        break;

      // ---------------------- FETCH TEAM
      case types.GUILD_DASHBOARD_FETCH_TEAM_STARTED: {
        const team = getTeam(draft, action.guildTeamId);
        if (team) {
          team.fetchingTeam = true;
          team.fetchingTeamFailed = false;
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_TEAM_SUCCEEDED: {
        const scholarsWithAxies = (action.teamData?.scholars ?? []).map(
          (scholar) => ({
            ...scholar,
            axies: (scholar.axies ?? []).map((axie) => ({
              ...axie,
              id: axie.axieId,
              purity: calculatePurityAxieTech(axie),
            })),
          })
        );
        let team = getTeam(draft, action.guildTeamId);
        if (team) {
          team = Object.assign(team, action.teamData, {
            fetchingTeam: false,
            scholars: scholarsWithAxies,
          });
        }
        break;
      }
      case types.GUILD_DASHBOARD_FETCH_TEAM_FAILED: {
        const team = getTeam(draft, action.guildTeamId);
        if (team) {
          team.fetchingTeam = false;
          team.fetchingTeamFailed = true;
        }
        break;
      }

      // ---------------------- SET ACTIVE TEAM
      case types.GUILD_DASHBOARD_SET_ACTIVE_TEAM:
        draft.guild.activeGuildTeamId = action.guildTeamId;
        if (action.guildTeamId === 0 && action.resetTable) {
          draft.config.scholarsTable.activeTab = 0;
          draft.config.scholarsTable.currentPage = {
            SLP: 0,
            ARENA: 0,
            WATCHLIST: 0,
          };
        } else if (action.guildTeamId !== 0 && action.resetTable) {
          draft.config.scholarsTableTeam.activeTab = 0;
          draft.config.scholarsTableTeam.currentPage = {
            SLP: 0,
            ARENA: 0,
            WATCHLIST: 0,
          };
        }
        break;

      // ---------------------- SET ACTIVE SCHOLAR
      case types.GUILD_DASHBOARD_SET_ACTIVE_SCHOLAR: {
        draft.config.activeScholarRoninAddress = action.roninAddress;
        draft.config.scholarPVPBattlesCurrentPage = 1;
        break;
      }

      // ---------------------- MOVE SCHOLARS TO TEAM
      case types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_STARTED:
        draft.guild.movingScholarsToTeam = true;
        draft.guild.movingScholarsToTeamFailed = false;

        break;
      case types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_SUCCEEDED:
        draft.guild.movingScholarsToTeam = false;
        break;
      case types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_FAILED:
        draft.guild.movingScholarsToTeam = false;
        draft.guild.movingScholarsToTeamFailed = true;
        break;

      // ---------------------- SCHOLAR TABLE
      case types.GUILD_DASHBOARD_UPDATE_NUMBER_OF_SCHOLARS_PER_PAGE:
        draft.config.numberOfScholarsPerPage = action.pageSize;
        break;
      case types.GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE:
        draft.config.scholarsTable.currentPage[action.tab] = action.page;
        break;
      case types.GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE_TEAM:
        draft.config.scholarsTableTeam.currentPage[action.tab] = action.page;
        break;
      case types.GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE:
        draft.config.scholarsTable.activeTab = action.tab;
        break;
      case types.GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE_TEAM:
        draft.config.scholarsTableTeam.activeTab = action.tab;
        break;
      case types.GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE:
        draft.config.scholarsTable.sortModel[action.tab] = action.sortModel;
        break;
      case types.GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE_TEAM:
        draft.config.scholarsTableTeam.sortModel[action.tab] = action.sortModel;
        break;

      // ---------------------- SCHOLAR PAGE
      case types.GUILD_DASHBOARD_UPDATE_SCHOLAR_EARNINGS_CHART_TO_DISPLAY:
        draft.config.scholarEarningsChartToDisplay = action.toDisplay;
        break;
      case types.GUILD_DASHBOARD_UPDATE_SCHOLAR_BATTLES_CHART_TO_DISPLAY:
        draft.config.scholarBattlesChartToDisplay = action.toDisplay;
        break;
      case types.GUILD_DASHBOARD_UPDATE_SCHOLAR_PVP_BATTLES_CURRENT_PAGE:
        draft.config.scholarPVPBattlesCurrentPage = action.currentPage;
        break;

      // ---------------------- AUTOPAYOUT LOG PAGE
      case types.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_STARTED:
        draft.autopayout.log = {
          fetching: true,
          failed: false,
        };
        break;
      case types.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_SUCCEEDED:
        draft.autopayout.log.fetching = false;
        draft.autopayout.log.data = action.data;
        break;
      case types.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_FAILED:
        draft.autopayout.log.fetching = false;
        draft.autopayout.log.failed = true;
        break;

      // ---------------------- INVENTORY
      case types.GUILD_DASHBOARD_UPDATE_INVENTORY_CONTROLS: {
        if (action.controlsType in draft.inventory.controls) {
          draft.inventory.controls[action.controlsType] = action.controls;
        }
        break;
      }

      default:
        break;
    }
  });

export default guildDashboardReducer;
