import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import ScholarInventory from "../atoms/ScholarInventory";
import Card from "components/Card/Card";
import ScholarIconStats from "../molecules/ScholarIconStats";
import SectionTitle from "pages/GuildDashboard/atoms/SectionTitle";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(6),
  alignItems: "center",
  flexWrap: "wrap",
  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: theme.spacing(4),
  },
}));

const Separator = styled(Stack)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.text.veryMuted}`,
  width: "1px",
  height: "16px",
  [theme.breakpoints.down("md")]: {
    borderRight: "none",
    borderBottom: `1px solid ${theme.palette.text.veryMuted}`,
    width: "16px",
    height: "1px",
    flexGrow: 1,
  },
}));

const InventoryBar = ({ scholar }) => {
  return (
    <Card style={{ paddingTop: "24px", paddingBottom: "24px" }}>
      <Wrapper>
        <SectionTitle>Inventory</SectionTitle>

        <ScholarInventory
          slp={scholar.totalSLP}
          moonshards={scholar.moonshards}
        />

        <Separator />

        <ScholarIconStats scholar={scholar} />
      </Wrapper>
    </Card>
  );
};

export default InventoryBar;
