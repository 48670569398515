import { Grid, IconButton, Stack } from "@mui/material";
import Search from "pages/GuildDashboard/atoms/Search";
import { Tooltip } from "@mui/material";
import { BiExport, BiImport } from "react-icons/bi";
import { exportJSON, loadScholarsJSON } from "../helpers/importExport";

const Controls = ({
  scholars,
  scholarsToDisplay,
  searchQuery,
  updateGuildSearchQuery,
  setValidScholarsToImport,
  setInvalidScholarsToImport,
  setOpenImportScholarsDialog,
  allGuildTeamIds,
}) => {
  const handleLoadImportJSON = () => {
    const createAddScholarPayload = (scholar, i) => {
      let guildTeamId = (scholar.guildTeamId || scholar.guildTeamID) ?? 0;

      if (allGuildTeamIds == null || !allGuildTeamIds.includes(guildTeamId)) {
        guildTeamId = 0;
      }

      return {
        name: scholar.name || `Scholar ${i + 1}`,
        managerShare: (scholar.managerShare || scholar.managerCut) ?? 0,
        investorShare: scholar.investorShare ?? 0,
        roninAddress: scholar.roninAddress ?? "",
        payoutAddress: scholar.payoutAddress ?? "",
        emailAddress: scholar.emailAddress ?? "",
        twitterId: (scholar.twitterId || scholar.twitterID) ?? "",
        discordId: (scholar.discordId || scholar.discordID) ?? "",
        region: scholar.region ?? "",
        country: scholar.country ?? "",
        dob: scholar.dob ?? "0000-00-00",
        dateJoined: scholar.dateJoined ?? "0000-00-00 00:00:00",
        notes: scholar.notes ?? "",
        dailyQuota: scholar.dailyQuota ?? 0,
        guildTeamId,
        profilePicUri: scholar.profilePicUri ?? "",
      };
    };

    const load = (response) => {
      const allScholars = JSON.parse(response);
      const validScholars = [];
      const invalidScholars = [];

      allScholars.forEach((scholar, i) => {
        if (
          scholars.find((s) => s.roninAddress === scholar.roninAddress) ===
          undefined
        ) {
          validScholars.push(createAddScholarPayload(scholar, i));
        } else {
          invalidScholars.push(scholar);
        }
      });
      setValidScholarsToImport(validScholars);
      setInvalidScholarsToImport(invalidScholars);
      setOpenImportScholarsDialog(true);
    };
    loadScholarsJSON(load);
  };

  const handleExport = () => {
    exportJSON(scholarsToDisplay.scholars);
  };

  return (
    <Grid container justifyContent="flex-end">
      <Stack direction="row" spacing={2} alignItems="center">
        <Tooltip title="Import JSON">
          <IconButton
            className="hoverGrowIcon"
            size="small"
            onClick={handleLoadImportJSON}
          >
            <BiImport />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export JSON">
          <IconButton
            className="hoverGrowIcon"
            size="small"
            onClick={handleExport}
          >
            <BiExport />
          </IconButton>
        </Tooltip>
        <Search
          placeholder="Search"
          value={searchQuery}
          updateValue={updateGuildSearchQuery}
        />
      </Stack>
    </Grid>
  );
};

export default Controls;
