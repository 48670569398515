import React from "react";
import { Stack, useTheme, Box } from "@mui/material";

const Counter = ({ total, filled, color }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap="2.5px"
      sx={{
        borderRadius: "4px",
        height: "15px",
      }}
    >
      {Array(total)
        .fill()
        .map((x, i) => i)
        .map((item, i) => {
          return (
            <Box
              sx={{
                background:
                  i < filled
                    ? color
                    : theme.palette.mode === "light"
                    ? theme.palette.card.pop3
                    : theme.palette.navigation.background,
                borderRadius: "4px",
                height: "100%",
                width: "9px",
              }}
              key={`COUNTER_ITEM_${i}`}
            />
          );
        })}
    </Stack>
  );
};

export default Counter;
