import React from "react";
import { connect } from "react-redux";
import { updateAxiesCurrentPage } from "store/guildDashboard/actions/guildDashboardActions";
import { getAxiesToDisplay } from "store/guildDashboard/selectors/axie";
import Pagination from "../../../atoms/Pagination";
import Player from "../molecules/Player";
import { Stack } from "@mui/material";

const Players = ({ axiesToDisplay, updateAxiesCurrentPage, currentPage }) => {
  if ((axiesToDisplay?.players || []).length === 0) return null;

  const handlePageChange = (e, value) => {
    updateAxiesCurrentPage(value);
  };

  return (
    <>
      <Stack gap={2}>
        {axiesToDisplay.players.map((player) => (
          <Player player={player} key={player.scholarId} />
        ))}
      </Stack>
      <Pagination
        currentPage={currentPage}
        numberOfPages={axiesToDisplay.numberOfPages}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateAxiesCurrentPage,
};

const mapStateToProps = (state) => ({
  axiesToDisplay: getAxiesToDisplay(state),
  currentPage: state.guildDashboard.axies.currentPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(Players);
