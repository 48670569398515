import { createSelector } from "reselect";
import { getAutopayoutLog } from "./common";
import moment from "moment";
import { DATE_TIME_FORMAT } from "config";
import {
  displayShortenedRoninAddress,
  makeRoninExplorerTXNURLFromHash,
  makeRoninExplorerURLFromAddress,
  displayShortenedTXNHash,
} from "helpers/display";
import { Link } from "@mui/material";

export const getAutopayoutLogData = createSelector(
  [getAutopayoutLog],
  (autopayoutLog) => {
    const rows =
      autopayoutLog &&
      autopayoutLog.map((entry) => ({
        id: entry.txnHashes,
        scholarName: entry.scholarName,
        claimedFrom: entry.claimedFrom,
        paidTo: entry.paidTo,
        slpPaid: entry.slpPaid,
        txnHash: entry.txnHashes,
        timestamp: entry.timestamp,
      }));

    const columns = [
      {
        field: "timestamp",
        headerName: "Date",
        width: 180,
        renderCell: (cellValues) =>
          moment.utc(cellValues.row.timestamp).local().format(DATE_TIME_FORMAT),
      },
      {
        field: "scholarName",
        headerName: "Scholar Name",
        width: 150,
      },
      {
        field: "slpPaid",
        headerName: "SLP Paid",
        width: 100,
      },
      {
        field: "claimedFrom",
        headerName: "From",
        width: 160,
        renderCell: (cellValues) => (
          <Link
            target="_blank"
            href={makeRoninExplorerURLFromAddress(cellValues.row.claimedFrom)}
          >
            {displayShortenedRoninAddress(cellValues.row.claimedFrom)}
          </Link>
        ),
      },
      {
        field: "paidTo",
        headerName: "To",
        width: 160,
        renderCell: (cellValues) => (
          <Link
            target="_blank"
            href={makeRoninExplorerURLFromAddress(cellValues.row.paidTo)}
          >
            {displayShortenedRoninAddress(cellValues.row.paidTo)}
          </Link>
        ),
      },
      {
        field: "txnHash",
        headerName: "TXN Hash",
        width: 150,
        renderCell: (cellValues) => (
          <Link
            target="_blank"
            href={makeRoninExplorerTXNURLFromHash(cellValues.row.txnHash)}
          >
            {displayShortenedTXNHash(cellValues.row.txnHash)}
          </Link>
        ),
      },
    ];

    return {
      rows,
      columns,
    };
  }
);
