import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Box)(({ specs, theme }) => ({
  display: "grid",
  padding: theme.spacing(1),
  userSelect: "none",
  gridGap: "8px",
  gridTemplateColumns: specs.grid || "1fr",
}));

const ColumnHeaders = ({ specs, fullSize }) => {
  const theme = useTheme();
  return (
    <Wrapper specs={specs}>
      <Typography
        variant="span"
        textAlign="center"
        fontSize={fullSize ? "16px" : "13px"}
        fontWeight="600"
        color="text.regular"
      >
        #
      </Typography>
      {specs.columns.map((column, j) => (
        <Typography
          variant="span"
          key={`COLUMN_${j}`}
          textAlign={column.align || "left"}
          fontWeight="600"
          color="text.regular"
          fontSize={fullSize ? "14px" : "13px"}
          sx={{
            whiteSpace: "nowrap",
            [theme.breakpoints.down("sm")]: {
              fontSize: "13px",
            },
          }}
        >
          {column.text}
        </Typography>
      ))}
    </Wrapper>
  );
};

export default ColumnHeaders;
