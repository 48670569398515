import { Stack, Tooltip, Typography } from "@mui/material";
import { MdInfo } from "react-icons/md";

const HorizontalStat = ({ label, amount, helper }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography
          variant="span"
          fontSize="15px"
          fontWeight="600"
          color="text.muted"
        >
          {label}
        </Typography>
        {helper != null && (
          <Tooltip title={helper}>
            <Typography
              variant="span"
              fontSize="14px"
              fontWeight="600"
              color="text.muted"
              sx={{
                transition: "0.2s all ease",
                opacity: 0.35,
                "&:hover": { opacity: 1, cursor: "pointer" },
              }}
            >
              <MdInfo />
            </Typography>
          </Tooltip>
        )}
      </Stack>
      <Typography
        variant="span"
        fontSize="15px"
        fontWeight="600"
        color="text.bright"
      >
        {amount != null ? amount : "?"}
      </Typography>
    </Stack>
  );
};

export default HorizontalStat;
