import React from "react";
import { FaAsterisk } from "react-icons/fa";
import { Tooltip, useTheme, IconButton } from "@mui/material";

const ToggleIcon = ({ id, requiredId, updateRequiredBreedingId }) => {
  const theme = useTheme();
  if (id == null || requiredId == null || updateRequiredBreedingId == null) {
    return null;
  }

  const handleToggleSetRequired = (id) => {
    if (id === requiredId) {
      updateRequiredBreedingId("");
    } else {
      updateRequiredBreedingId(id);
    }
  };

  return (
    <Tooltip title={`Pair must include #${id}`} placement="right">
      <IconButton
        onClick={() => handleToggleSetRequired(id)}
        size="small"
        sx={{
          color: id === requiredId ? theme.palette.feedback.green : undefined,
          "&:hover": {
            color: theme.palette.feedback.green,
          },
          svg: {
            width: "13px",
            height: "auto",
          },
        }}
      >
        <FaAsterisk />
      </IconButton>
    </Tooltip>
  );
};

export default ToggleIcon;
