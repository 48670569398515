export const EXPLORER_FAVORITE_CARD = "EXPLORER_FAVORITE_CARD";
export const EXPLORER_UNFAVORITE_CARD = "EXPLORER_UNFAVORITE_CARD";

export const EXPLORER_FAVORITE_RUNE = "EXPLORER_FAVORITE_RUNE";
export const EXPLORER_UNFAVORITE_RUNE = "EXPLORER_UNFAVORITE_RUNE";

export const EXPLORER_FAVORITE_CHARM = "EXPLORER_FAVORITE_CHARM";
export const EXPLORER_UNFAVORITE_CHARM = "EXPLORER_UNFAVORITE_CHARM";

export const EXPLORER_FAVORITE_CURSE = "EXPLORER_FAVORITE_CURSE";
export const EXPLORER_UNFAVORITE_CURSE = "EXPLORER_UNFAVORITE_CURSE";

export const EXPLORER_FAVORITE_TOOL = "EXPLORER_FAVORITE_TOOL";
export const EXPLORER_UNFAVORITE_TOOL = "EXPLORER_UNFAVORITE_TOOL";

export const EXPLORER_FAVORITE_REVENGE_CARD = "EXPLORER_FAVORITE_REVENGE_CARD";
export const EXPLORER_UNFAVORITE_REVENGE_CARD =
  "EXPLORER_UNFAVORITE_REVENGE_CARD";

export const EXPLORER_ADD_FAVORITE_CARDS = "EXPLORER_ADD_FAVORITE_CARDS";
export const EXPLORER_ADD_FAVORITE_RUNES = "EXPLORER_ADD_FAVORITE_RUNES";
export const EXPLORER_ADD_FAVORITE_CHARMS = "EXPLORER_ADD_FAVORITE_CHARMS";
export const EXPLORER_ADD_FAVORITE_CURSES = "EXPLORER_ADD_FAVORITE_CURSES";
export const EXPLORER_ADD_FAVORITE_TOOLS = "EXPLORER_ADD_FAVORITE_TOOLS";
export const EXPLORER_ADD_FAVORITE_REVENGE_CARDS =
  "EXPLORER_ADD_FAVORITE_REVENGE_CARDS";

export const EXPLORER_CLEAR_ALL_FAVORITES = "EXPLORER_CLEAR_ALL_FAVORITES";

export const EXPLORER_UPDATE_CARD_FILTERS = "EXPLORER_UPDATE_CARD_FILTERS";
export const EXPLORER_UPDATE_RUNE_FILTERS = "EXPLORER_UPDATE_RUNE_FILTERS";
export const EXPLORER_UPDATE_CHARM_FILTERS = "EXPLORER_UPDATE_CHARM_FILTERS";
export const EXPLORER_UPDATE_CURSE_FILTERS = "EXPLORER_UPDATE_CURSE_FILTERS";
export const EXPLORER_UPDATE_TOOL_FILTERS = "EXPLORER_UPDATE_TOOL_FILTERS";
export const EXPLORER_UPDATE_STATUS_EFFECT_FILTERS =
  "EXPLORER_UPDATE_STATUS_EFFECT_FILTERS";
export const EXPLORER_UPDATE_REVENGE_CARD_FILTERS =
  "EXPLORER_UPDATE_REVENGE_CARD_FILTERS";

export const EXPLORER_RESET_CARD_FILTERS = "EXPLORER_RESET_CARD_FILTERS";
export const EXPLORER_RESET_RUNE_FILTERS = "EXPLORER_RESET_RUNE_FILTERS";
export const EXPLORER_RESET_CHARM_FILTERS = "EXPLORER_RESET_CHARM_FILTERS";
export const EXPLORER_RESET_CURSE_FILTERS = "EXPLORER_RESET_CURSE_FILTERS";
export const EXPLORER_RESET_TOOL_FILTERS = "EXPLORER_RESET_TOOL_FILTERS";
export const EXPLORER_RESET_STATUS_EFFECT_FILTERS =
  "EXPLORER_RESET_STATUS_EFFECT_FILTERS";
export const EXPLORER_RESET_REVENGE_CARD_FILTERS =
  "EXPLORER_RESET_REVENGE_CARD_FILTERS";
