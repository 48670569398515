import React from "react";
import styles from "./call-to-action.module.scss";
import { Button, useTheme, Typography, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.colors.purple[300],
  backgroundColor: theme.palette.mode === "light" ? "#eee" : "#ddd",
  fontSize: "20px",
  whiteSpace: "nowrap",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

const CallToAction = ({
  title,
  titleIcon,
  button,
  button2,
  info,
  children,
}) => {
  const theme = useTheme();

  return (
    <Stack
      className={styles.cta}
      justifyContent="space-between"
      direction="row"
    >
      <Stack gap={1}>
        {title != null && (
          <div className={styles.title}>
            {titleIcon && <span className={styles.icon}>{titleIcon}</span>}
            <Typography
              variant="h4"
              color="#fff"
              fontSize="16px"
              sx={{ lineHeight: 1.2 }}
            >
              {title}
            </Typography>
          </div>
        )}
        <Typography
          variant="body1"
          color="#fff"
          fontSize="24px"
          sx={{
            lineHeight: 1.4,
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px",
            },
          }}
        >
          {children}
        </Typography>
      </Stack>
      <Stack
        gap={1}
        direction={{ sm: "row", md: "column", lg: "column" }}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        {button != null && button.href && (
          <ColorButton
            target="_blank"
            href={button.href}
            variant="contained"
            endIcon={button.icon}
            size="large"
            sx={{
              [theme.breakpoints.down("md")]: {
                alignSelf: "flex-start",
              },
            }}
          >
            {button.text}
          </ColorButton>
        )}

        {button != null && button.to && (
          <ColorButton
            component={RouterLink}
            to={button.to}
            variant="contained"
            endIcon={button.icon}
            size="large"
            sx={{
              [theme.breakpoints.down("md")]: {
                alignSelf: "flex-start",
              },
            }}
          >
            {button.text}
          </ColorButton>
        )}

        {button2 != null && button2.href && (
          <ColorButton
            target="_blank"
            href={button2.href}
            variant="contained"
            endIcon={button2.icon}
            size="large"
            sx={{
              [theme.breakpoints.down("md")]: {
                alignSelf: "flex-start",
              },
            }}
          >
            {button2.text}
          </ColorButton>
        )}

        {button2 != null && button2.to && (
          <ColorButton
            component={RouterLink}
            to={button2.to}
            variant="contained"
            endIcon={button2.icon}
            size="large"
            sx={{
              [theme.breakpoints.down("md")]: {
                alignSelf: "flex-start",
              },
            }}
          >
            {button2.text}
          </ColorButton>
        )}
        {info != null && (
          <Typography
            variant="span"
            fontSize="16px"
            color="#fff"
            fontWeight="600"
            textAlign="center"
            className={styles.info}
          >
            {info}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default CallToAction;
