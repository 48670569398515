import React from "react";
import FailedMessage from "components/FailedMessage/FailedMessage";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { formatUSD } from "helpers/display";
import Label from "components/Typography/Label";
import {
  Tooltip,
  useTheme,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import { GoLinkExternal } from "react-icons/go";
import { Link } from "react-router-dom";

const Price = ({ token, name, image, eth, usd, linkTo }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      sx={{
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        border: `1px solid ${theme.palette.card.divider}`,
        borderRadius: "8px",
        width: "320px",
        p: 3,
        position: "relative",
      }}
    >
      <Stack sx={{ position: "absolute", right: "8px", top: "8px" }}>
        <Tooltip title="View on Coingecko">
          <Link to={linkTo} target="_blank">
            <IconButton
              sx={{ fontSize: "16px", color: theme.palette.primary.main }}
            >
              <GoLinkExternal />
            </IconButton>
          </Link>
        </Tooltip>
      </Stack>
      <img src={image} alt={name} style={{ height: "60px" }} />
      <Stack>
        <Typography
          variant="h3"
          fontWeight="700"
          fontSize="34px"
          sx={{ lineHeight: 1 }}
          color="text.bright"
        >
          {token}
        </Typography>
        <Label size={14}>{name}</Label>

        <Stack gap={1} direction="row" alignItems="flex-end">
          <Typography
            variant="span"
            fontSize="18px"
            fontWeight="700"
            color="text.primary"
          >
            {formatUSD(usd)}
          </Typography>
          {eth && (
            <Typography
              variant="span"
              fontSize="14px"
              fontWeight="600"
              color="text.muted"
            >
              Ξ{eth}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const CryptoPrices = ({ data, status }) => {
  if (!status || status === "error") {
    return <FailedMessage text="Cannot load price data" />;
  }
  if (status === "loading") {
    return <CustomLoader />;
  }
  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={2}>
      <Price
        token="ETH"
        name="Ethereum"
        image="/images/icons/eth.png"
        usd={data["ethereum"]["usd"]}
        linkTo="https://www.coingecko.com/en/coins/ethereum"
      />
      <Price
        token="AXS"
        name="Axie Infinity"
        image="/images/icons/axs.png"
        usd={data["axie-infinity"]["usd"]}
        eth={data["axie-infinity"]["eth"]}
        linkTo="https://www.coingecko.com/en/coins/axie-infinity"
      />
      <Price
        token="SLP"
        name="Smooth Love Potion"
        image="/images/icons/slp.png"
        usd={data["smooth-love-potion"]["usd"]}
        eth={data["smooth-love-potion"]["eth"]}
        linkTo="https://www.coingecko.com/en/coins/smooth-love-potion"
      />
    </Stack>
  );
};

export default CryptoPrices;
