import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ConnectedProps, connect } from "react-redux";
import { Container, styled } from "@mui/material";
import Error from "./atoms/Error";
import Header from "./organisms/Header";
import {
  getPlayer,
  getPlayerBattles,
  getPlayerFavoriteTeams,
  getPlayerVictoryStarChart,
} from "store/player/playerSelectors";
import Loading from "./atoms/Loading";
import BattleLog from "components/BattleLog";
import { fetchAxieData } from "store/data/dataActions";
import {
  updateOriginPlayerBattlesPage,
  fetchOriginPlayer,
} from "store/player/playerActions";
import Profile from "./organisms/Profile";
import Tabs from "components/Tabs";
import TabPanel from "components/Tabs/TabPanel";
import { getAllCardsV3 } from "store/data/dataSelectors";
import { RootState } from "store/store";

const Wrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(16),
  minHeight: "70vh",
}));

interface Props extends PropsFromRedux {}

const Player = ({
  player,
  favoriteTeams,
  victoryStarChart,
  battles,
  loading,
  failed,
  loadingBattles,
  fetchPlayer,
  fetchAxieData,
  updateBattlesPage,
  cardsV3,
}: Props) => {
  const { clientId } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const handleUpdateBattlesPage = (
    e: ChangeEvent<unknown>,
    newPage: number
  ) => {
    updateBattlesPage(newPage);
  };

  useEffect(() => {
    fetchPlayer(clientId);
  }, [clientId, fetchPlayer]);

  if (loading) {
    return <Loading />;
  }
  if (failed) {
    return <Error handleRefresh={() => fetchPlayer(clientId)} />;
  }

  return (
    <Wrapper>
      <Header player={player} />
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsId="player-tabs"
        ariaLabel="Arena Player"
        tabs={["Overview", "Battles"]}
      />
      <TabPanel value={activeTab} index={0} id="overview">
        <Profile
          favoriteTeams={favoriteTeams}
          victoryStarChartData={victoryStarChart}
          fetchingBattles={loadingBattles}
          cardsV3={cardsV3}
          recentBattleSummary={battles.recentBattleSummary}
          setActiveTab={setActiveTab}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1} id="battle-log">
        <BattleLog
          name={player.name}
          fetchingBattles={loadingBattles}
          currentPage={battles.battleSummary.currentPage}
          updateCurrentPage={handleUpdateBattlesPage}
          numberOfPages={battles.battleSummary.numberOfPages}
          toDisplay={battles.battleSummary.toDisplay}
          fetchAxieData={fetchAxieData}
          totalBattles={player.battleStats.total}
          totalWins={player.battleStats.wins}
        />
      </TabPanel>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  fetchPlayer: fetchOriginPlayer,
  fetchAxieData,
  updateBattlesPage: updateOriginPlayerBattlesPage,
};

const mapStateToProps = (state: RootState) => ({
  loading: state.player.loading,
  failed: state.player.failed,
  player: getPlayer(state),
  battles: getPlayerBattles(state),
  favoriteTeams: getPlayerFavoriteTeams(state),
  victoryStarChart: getPlayerVictoryStarChart(state),
  loadingBattles: state.player.battles.loading,
  cardsV3: getAllCardsV3(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Player);
