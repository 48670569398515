import { Container, Stack, Typography, Box, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { getRandomIntFromInclusiveInterval } from "helpers/display";
import { FaExclamationTriangle } from "react-icons/fa";
import PageTitle from "components/Typography/PageTitle";

const CloudTheme = styled.div`
  padding-top: ${(props) => props.paddingTop + "px"};
  padding-bottom: ${(props) => props.paddingBottom + "px"};
  background: ${(props) => (props.mode === "light" ? "#0a98dc" : "#2c1b48")};
  position: relative;
  overflow: hidden;
`;
const LogoMark1Image = styled.img`
  position: absolute;
  width: 186px;
  margin: 0 auto;
  top: ${getRandomIntFromInclusiveInterval(-100, 160) + "px"};
  left: ${getRandomIntFromInclusiveInterval(0, 85) + "px"};
  opacity: 0.2;
`;
const LogoMark2Image = styled.img`
  position: absolute;
  width: 106px;
  margin: 0 auto;
  top: ${getRandomIntFromInclusiveInterval(-100, 160) + "px"};
  right: ${getRandomIntFromInclusiveInterval(0, 85) + "px"};
  opacity: 0.2;
  transform: rotate(-80deg);
`;
const AxieTechTheme = styled(Box)(({ theme, paddingBottom, paddingTop }) => ({
  background: theme.palette.colors.purple.gradient,
  paddingTop: paddingTop + "px",
  paddingBottom: paddingBottom + "px",
  overflow: "hidden",
  position: "relative",
  transition: "1s ease padding",

  "& .MuiLink-root": {
    color: "#fff",
    borderBottom: "2px solid #fff",

    "&:hover": {
      borderBottom: "1px solid transparent",
    },
  },
}));

const AxiesTheme = styled(Box)(({ theme, paddingBottom, paddingTop }) => ({
  backgroundImage: "url('/images/accents/axies-banner.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  paddingTop: 80,
  paddingBottom: 80,
}));

const ClassicTheme = styled(Box)(({ theme, paddingBottom, paddingTop }) => ({
  backgroundImage: "url('/images/accents/classic-leaderboard.png')",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  paddingTop: 80,
  paddingBottom: 80,
}));

const Accent = () => {
  const theme = useTheme();
  let width;
  const top = getRandomIntFromInclusiveInterval(0, 50) + "%";
  const right = getRandomIntFromInclusiveInterval(0, 100) + "%";
  if (theme.palette.mode === "light") {
    width = getRandomIntFromInclusiveInterval(30, 180) + "px";
    return (
      <img
        src="/images/cloud.png"
        alt="Cloud"
        style={{ width, top, right, position: "absolute" }}
      />
    );
  }
  width = getRandomIntFromInclusiveInterval(10, 60) + "px";
  const rotation = getRandomIntFromInclusiveInterval(0, 180) + "deg";
  return (
    <img
      src="/images/star.png"
      alt="Star"
      style={{
        width,
        top,
        right,
        position: "absolute",
        transform: `rotate(${rotation})`,
      }}
    />
  );
};

const Theme = ({ headerStyle, paddingTop, paddingBottom, children }) => {
  const theme = useTheme();
  if (headerStyle === "sky") {
    const nAccents = getRandomIntFromInclusiveInterval(2, 5);
    return (
      <CloudTheme
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        mode={theme.palette.mode}
      >
        <Container>
          <Stack>
            {children}
            {Array.from(Array(nAccents).keys()).map((cloud, i) => (
              <Accent key={`ACCENT_${i}`} />
            ))}
          </Stack>
        </Container>
      </CloudTheme>
    );
  }
  if (headerStyle === "axies") {
    return (
      <AxiesTheme>
        <Container>{children} </Container>
      </AxiesTheme>
    );
  }
  if (headerStyle === "classic") {
    return (
      <ClassicTheme>
        <Container>{children} </Container>
      </ClassicTheme>
    );
  }
  return (
    <AxieTechTheme paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Container>
        <Stack>
          {children}
          <LogoMark1Image src="/images/axie-tech-logo-mark-white.png" />
          <LogoMark2Image src="/images/axie-tech-logo-mark-white.png" />
        </Stack>
      </Container>
    </AxieTechTheme>
  );
};

const PageHeader = ({
  title,
  description,
  centered = false,
  paddingTop = 60,
  paddingBottom = 60,
  children,
  headerStyle = "AxieTech",
  disclaimer,
}) => {
  const theme = useTheme();
  return (
    <Stack>
      <Theme
        headerStyle={headerStyle}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      >
        <Stack sx={{ position: "relative", zIndex: "2" }} gap={4}>
          <Stack>
            {title != null && (
              <PageTitle
                textAlign="center"
                gutterBottom={false}
                color="#fff"
                style={{
                  mb: 0.2,
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.35)",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "32px",
                  },
                }}
              >
                {title}
              </PageTitle>
            )}
            {description != null && (
              <Typography
                fontSize="16px"
                color="#fff"
                fontWeight={500}
                textAlign={centered ? "center" : "left"}
                sx={{
                  maxWidth: "840px",
                  margin: "0 auto",
                  lineHeight: 1.6,
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.35)",
                }}
                variant="h2"
              >
                {description}
              </Typography>
            )}
          </Stack>
          {disclaimer != null && (
            <Stack
              gap={2}
              alignItems="center"
              direction="row"
              sx={{
                maxWidth: "650px",
                mb: 4,
                padding: "8px 16px",
                background: theme.palette.card.background,
                borderRadius: "4px",
                margin: "0 auto",
              }}
            >
              <Typography
                variant="span"
                fontSize="12px"
                color={theme.palette.feedback.red}
              >
                <FaExclamationTriangle />
              </Typography>
              <Typography
                variant="span"
                fontSize="13px"
                fontWeight="600"
                color="text.regular"
                sx={{ lineHeight: 1.55 }}
              >
                {disclaimer}
              </Typography>
            </Stack>
          )}
          {children}
        </Stack>
      </Theme>
    </Stack>
  );
};

export default PageHeader;
