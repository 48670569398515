import { Stack, Typography } from "@mui/material";
import PageTitle from "components/Typography/PageTitle";
import Controls from "pages/Support/molecules/Controls";
import Card from "components/Card/Card";

const PageHeader = ({ search, setSearch, category, setCategory }) => {
  return (
    <Card>
      <Stack gap={4}>
        <Stack
          gap={2}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          <PageTitle gutterBottom={false} textAlign="center">
            Axie & Ronin Support Portal{" "}
          </PageTitle>
          <Typography
            variant="span"
            color="text.muted"
            fontWeight={600}
            fontSize={16}
            sx={{ lineHeight: 1.4 }}
          >
            Find the help and support links you need across the ecosystem.
          </Typography>
        </Stack>

        <Controls
          search={search}
          setSearch={setSearch}
          category={category}
          setCategory={setCategory}
        />
      </Stack>
    </Card>
  );
};

export default PageHeader;
