// categories
export const AxieInfinity = {
  name: "Axie Infinity",
};

export const Ronin = {
  name: "Ronin",
};

export const RoninGame = {
  name: "Ronin Games",
};

export const ABP = {
  name: "ABP Games",
};

// data
export const axieSupport = [
  {
    id: "axie-infinity",
    category: AxieInfinity,
    link: "https://support.axieinfinity.com/hc/en-us",
    title: "General Support",
    logo: "/images/support-page/logos/axie-infinity.png",
    backgroundImage: "",
    showTitle: true,
    paddingZero: true,
  },
  {
    id: "mavis-hub",
    category: AxieInfinity,
    link: "https://support.axieinfinity.com/hc/en-us/categories/13984328138523-Mavis-Hub",
    title: "Mavis Hub",
    logo: "/images/support-page/logos/mavis-hub.png",
    backgroundImage: "",
  },
  {
    id: "app-axie",
    category: AxieInfinity,
    link: "https://support.axieinfinity.com/hc/en-us/categories/13984316470555-App-Axie",
    title: "App.Axie",
    logo: "/images/support-page/logos/axie-infinity.png",
    showTitle: true,
    paddingZero: true,
  },
  {
    id: "homeland",
    category: AxieInfinity,
    link: "https://support.axieinfinity.com/hc/en-us/categories/11503198132507-Land",
    title: "Homeland",
    logo: "/images/support-page/logos/homeland.png",
    backgroundImage: "/images/support-page/axie-games/homeland.jpg",
  },
  {
    id: "origins",
    category: AxieInfinity,
    link: "https://support.axieinfinity.com/hc/en-us/categories/4556716025115-Axie-Infinity-Origins",
    title: "Origins",
    logo: "/images/support-page/logos/axie-origin.png",
    backgroundImage: "/images/support-page/axie-games/origins.jpg",
  },
  {
    id: "classic",
    category: AxieInfinity,
    link: "https://support.axieinfinity.com/hc/en-us/categories/4404216056731-Axie-Infinity-Classic",
    title: "Classic",
    logo: null,
    backgroundImage: "/images/support-page/axie-games/classic.jpg",
    showTitle: true,
    whiteTitle: true,
  },
  {
    id: "raylights",
    category: AxieInfinity,
    link: "https://discord.com/invite/axie",
    title: "Raylights",
    logo: null,
    backgroundImage: "/images/support-page/axie-games/raylights.jpg",
  },
];

export const roninSupport = [
  {
    id: "ronin",
    category: Ronin,
    title: "General Support",
    logo: "/images/support-page/logos/ronin-network.png",
    link: "https://support.roninchain.com/hc/en-us",
    backgroundImage: "",
    showTitle: true,
    paddingZero: true,
  },
  {
    id: "ronin-wallet",
    category: Ronin,
    link: "https://support.roninchain.com/hc/en-us/categories/14035607522843-Ronin-Wallet",
    title: "Ronin Wallet",
    logo: null,
    backgroundImage: "/images/support-page/ronin-cards/ronin-wallet.png",
    showTitle: true,
    backgroundContain: true,
  },
  {
    id: "mavis-market",
    category: Ronin,
    link: "https://support.roninchain.com/hc/en-us/categories/15449866890907-Mavis-Market",
    title: "Mavis Market",
    logo: null,
    backgroundImage: "/images/support-page/ronin-cards/mavis-market.png",
    showTitle: true,
    backgroundContain: true,
  },
];

export const roninGames = [
  {
    id: "axie-champions",
    category: RoninGame,
    logo: null,
    link: "https://discord.gg/axiechampions",
    title: "Axie Champions",
    backgroundImage: "/images/support-page/ronin-games/axie-champions.jpg",
  },
  {
    id: "battle-bears",
    category: RoninGame,
    logo: null,
    link: "https://discord.com/invite/battlebears",
    title: "Battle Bears",
    backgroundImage: "/images/support-page/ronin-games/battle-bears.jpg",
  },
  {
    id: "bowled",
    category: RoninGame,
    logo: "/images/games/logos/bowled.png",
    link: "https://discord.com/invite/DB23M6bx4v",
    title: "Bowled.io",
    backgroundImage: "/images/support-page/ronin-games/bowled.jpg",
  },
  {
    id: "the-machines-arena",
    category: RoninGame,
    logo: null,
    link: "https://discord.com/invite/p6a97bKZ6H",
    title: "The Machines Arena",
    backgroundImage: "/images/support-page/ronin-games/the-machines-arena.jpg",
    positionBottom: true,
  },
  {
    id: "tribesters",
    category: RoninGame,
    logo: null,
    link: "https://twitter.com/TribestersWorld",
    title: "Tribesters",
    backgroundImage: "/images/support-page/ronin-games/tribesters.jpg",
  },
];

export const abpGames = [
  {
    id: "axie-quest",
    category: ABP,
    logo: null,
    link: "https://discord.com/invite/RY4Z97wStt",
    title: "Axie Quest",
    backgroundImage: "/images/games/axie-quest.jpg",
  },

  {
    id: "axie-war",
    category: ABP,
    logo: "/images/games/logos/axie-war.png",
    link: "https://discord.gg/DNxYAJh6tQ",
    title: "Axie War",
    backgroundImage: "/images/games/axie-infinity-war.jpg",
  },
  {
    id: "DoLL",
    category: ABP,
    logo: null,
    link: "https://discord.gg/THJpcAGhHT",
    title: "Defenders of Lunacian Land",
    backgroundImage: "/images/games/doll.jpg",
  },
  {
    id: "inter-dungeon",
    category: ABP,
    logo: null,
    link: "https://discord.gg/Q3PFEsk5Yf",
    title: "Inter The Dungeon Support",
    backgroundImage: "/images/games/inter-dungeon.jpg",
    positionBottom: true,
  },
  {
    id: "lunacia-cup",
    category: ABP,
    logo: "/images/games/logos/lunacia-cup.png",
    link: "https://discord.com/invite/T7c7VEzRJK",
    title: "Lunacia Cup",
    backgroundImage: "/images/games/lunacia-cup.jpg",
  },

  {
    id: "tacticards",
    category: ABP,
    logo: null,
    link: "https://discord.com/invite/4AF37DAQRf",
    title: "Tacticards Support",
    backgroundImage: "/images/games/tacticards.jpg",
  },
  {
    id: "skySmash",
    category: ABP,
    logo: null,
    link: "https://discord.gg/4fjcfqqAfJ",
    title: "Sky Smash",
    backgroundImage: "/images/games/axie-smash.jpg",
  },
];
