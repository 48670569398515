import { Pagination, Stack } from "@mui/material";
import Battle from "./organisms/Battle";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { AXIE_INFINITY_BATTLES_API_FUNCTIONAL } from "config";
import BattlesNotFunctional from "./atoms/BattlesNotFunctional";
import Title from "./molecules/Title";

interface Props {
  name: string;
  currentPage: number;
  updateCurrentPage: (event: React.ChangeEvent<unknown>, value: number) => void;
  numberOfPages: number;
  handleRefresh?: () => void;
  toDisplay: any[];
  fetchAxieData: (id: string) => void;
  totalBattles: number;
  totalWins: number;
  fetchingBattles: boolean;
}

const BattleLog = ({
  name,
  currentPage,
  updateCurrentPage,
  numberOfPages,
  handleRefresh,
  toDisplay,
  fetchAxieData,
  totalBattles,
  totalWins,
  fetchingBattles,
}: Props) => {
  if (fetchingBattles) {
    return <CustomLoader marginTop={200} />;
  }

  if (!AXIE_INFINITY_BATTLES_API_FUNCTIONAL) {
    return <BattlesNotFunctional />;
  }

  return (
    <Stack gap={3}>
      <Title
        totalBattles={totalBattles}
        totalWins={totalWins}
        handleRefresh={handleRefresh}
      />

      {toDisplay.map((battle, i) => (
        <Battle
          playerName={name}
          battle={battle}
          key={`BATTLE_${currentPage}_${i}`}
          fetchAxieData={fetchAxieData}
        />
      ))}

      {numberOfPages > 1 && (
        <Pagination
          shape="rounded"
          onChange={updateCurrentPage}
          count={numberOfPages}
          page={currentPage}
        />
      )}
    </Stack>
  );
};

export default BattleLog;
