import React from "react";
import { Stack } from "@mui/material";

const TitleAccent = ({ rotate }) => (
  <Stack
    sx={{
      position: "relative",
      top: 2,
      transform: rotate ? "rotate(180deg)" : "none",
      "& img": {
        width: 10,
        height: "auto",
      },
    }}
  >
    <img src="/images/explorer/accent.png" alt="Title Accent" />
  </Stack>
);

export default TitleAccent;
