import React from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";
import HiddenScholars from "./HiddenScholars";
import ArenaLeaderboard from "./ArenaLeaderboard";
import * as guildSelectors from "store/guild/guildSelectors";

const GuildLeaderboard = ({ guildStats }) => {
  return (
    <Stack gap={3} sx={{ mt: 4 }}>
      <ArenaLeaderboard scholars={guildStats.sortedMMRs} />
      <HiddenScholars scholars={guildStats.hiddenArenaScholars} />
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuildLeaderboard);
