import React from "react";
import { MARKET_FEE } from "config";
import { Stack, Tooltip, useTheme, Typography } from "@mui/material";
import EthereumPrice from "components/EthereumPrice/EthereumPrice";
import { roundToNumDecimals, formatUSD } from "helpers/display";
import { MdInfo } from "react-icons/md";
import styles from "./summary.module.scss";
import Label from "components/Typography/Label";
import BannerTitle from "./BannerTitle";
import HelperText from "./HelperText";
import PriceUSD from "./PriceUSD";
import useMediaQuery from "@mui/material/useMediaQuery";

const RowWrapper = ({ children }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      direction="row"
      sx={{
        background: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        border: `1px solid ${theme.palette.card.divider}`,
        borderRadius: "8px",
        flexWrap: md ? "nowrap" : "wrap",
      }}
    >
      {children}
    </Stack>
  );
};

const ItemCount = ({ children }) => (
  <Typography
    variant="span"
    fontSize="32px"
    fontWeight="600"
    color="text.bright"
  >
    {children}
  </Typography>
);

const ShoppingListItem = ({
  required,
  inventory,
  toBuy,
  info,
  icon,
  iconAlt,
  totalTitle,
  costETH,
  costUSD,
  helperText,
}) => {
  return (
    <RowWrapper>
      <div className={styles.rowLeftDetails}>
        <div className={styles.rowIconWrapper}>
          <img src={icon} alt={iconAlt} className={styles.rowIcon} />
        </div>
        <div className={styles.columns}>
          <div className={styles.column}>
            <Label>Required</Label>
            <ItemCount>{required}</ItemCount>
          </div>
          <div className={styles.column}>
            <Label>Inventory</Label>
            <ItemCount>{inventory}</ItemCount>
          </div>
          <div className={styles.column}>
            <Label>To Buy</Label>
            <ItemCount>{toBuy}</ItemCount>
          </div>
        </div>
      </div>
      <div className={styles.rowTotal}>
        <Label color="#fff">{totalTitle}</Label>
        <div className={styles.totalPrice}>
          <div className={styles.flexPrices}>
            <EthereumPrice
              formatEth={false}
              price={roundToNumDecimals(costETH)}
              large
            />
            <PriceUSD color="rgba(0,0,0,.5)">{formatUSD(costUSD)}</PriceUSD>
          </div>
          {info && (
            <Tooltip title={info}>
              <div className={styles.infoIcon}>{<MdInfo />}</div>
            </Tooltip>
          )}
        </div>
        {helperText && (
          <Typography
            variant="span"
            fontSize="12px"
            fontWeight="600"
            color="rgba(0, 0, 0, 0.5)"
          >
            {helperText}
          </Typography>
        )}
      </div>
    </RowWrapper>
  );
};

const ParentTransaction = ({
  icon,
  iconAlt,
  parent1ETH,
  parent1USD,
  parent2ETH,
  parent2USD,
  parentTotalETH,
  parentTotalUSD,
  parent1Title,
  parent2Title,
  totalTitle,
  helperText,
}) => {
  return (
    <RowWrapper>
      <div className={styles.rowLeftDetails}>
        <div className={styles.rowIconWrapper}>
          <img src={icon} alt={iconAlt} className={styles.rowIcon} />
        </div>
        <div className={`${styles.columns} ${styles.twoColumns}`}>
          <div className={styles.column}>
            <Label>{parent1Title}</Label>
            <div className={styles.flexPrices}>
              <EthereumPrice
                formatEth={false}
                price={roundToNumDecimals(parent1ETH)}
                large
              />
              <PriceUSD>{formatUSD(parent1USD)}</PriceUSD>
            </div>
          </div>
          <div className={styles.column}>
            <Label>{parent2Title}</Label>
            <div className={styles.flexPrices}>
              <EthereumPrice
                formatEth={false}
                price={roundToNumDecimals(parent2ETH)}
                large
              />
              <PriceUSD>{formatUSD(parent2USD)}</PriceUSD>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rowTotal}>
        <Label color="#fff">{totalTitle}</Label>
        <div className={styles.flexPrices}>
          <EthereumPrice
            formatEth={false}
            price={roundToNumDecimals(parentTotalETH)}
            large
          />
          <PriceUSD color="rgba(0,0,0,.5)">
            {formatUSD(parentTotalUSD)}
          </PriceUSD>
        </div>
        {helperText && (
          <Typography
            variant="span"
            fontSize="12px"
            fontWeight="600"
            color="rgba(0, 0, 0, 0.5)"
          >
            {helperText}
          </Typography>
        )}
      </div>
    </RowWrapper>
  );
};

const ChildTransaction = ({
  icon,
  iconAlt,
  salesETH,
  salesUSD,
  totalETH,
  totalUSD,
  totalTitle,
  helperText,
  numberOfBreeds,
}) => (
  <RowWrapper>
    <div className={styles.rowLeftDetails}>
      <div className={styles.rowIconWrapper}>
        <img src={icon} alt={iconAlt} className={styles.rowIcon} />
      </div>
      <div className={styles.columns}>
        {salesETH.map((sale, i) => {
          if (i < numberOfBreeds) {
            return (
              <div className={styles.column} key={`CHILD_TRANSACTION_${i}`}>
                <Label>{`Child Sale ${i + 1}`}</Label>
                <div className={styles.flexPrices}>
                  <EthereumPrice
                    formatEth={false}
                    price={roundToNumDecimals(sale)}
                    large
                  />
                  <PriceUSD>{formatUSD(salesUSD[i])}</PriceUSD>
                </div>
              </div>
            );
          } else {
            return <div key={`CHILD_TRANSACTION_${i}`}></div>;
          }
        })}
      </div>
    </div>
    <div className={styles.rowTotal}>
      <Label color="#fff">{totalTitle}</Label>
      <div className={styles.flexPrices}>
        <EthereumPrice
          formatEth={false}
          price={roundToNumDecimals(totalETH)}
          large
        />
        <PriceUSD color="rgba(0,0,0,.5)">{formatUSD(totalUSD)}</PriceUSD>
      </div>
      {helperText && (
        <Typography
          variant="span"
          fontSize="12px"
          fontWeight="600"
          color="rgba(0, 0, 0, 0.5)"
        >
          {helperText}
        </Typography>
      )}
    </div>
  </RowWrapper>
);

const TotalRawMaterials = ({ title, eth, usd }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={styles.totalRawMaterials}
      sx={{ border: `1px solid ${theme.palette.card.divider}` }}
    >
      <Label>{title}</Label>
      <div className={`${styles.flexPrices} ${styles.flexPricesMinWidth}`}>
        <EthereumPrice formatEth={false} price={roundToNumDecimals(eth)} />
        <PriceUSD>{formatUSD(usd)}</PriceUSD>
      </div>
    </Stack>
  );
};

const Total = ({
  title,
  eth,
  usd,
  description,
  avgCostPerAxieETH,
  avgCostPerAxieUSD,
}) => {
  const theme = useTheme();
  return (
    <div className={styles.bigSection}>
      <Label color={theme.palette.colors.purple[300]}>{title}</Label>
      <div className={styles.flexPrices}>
        <EthereumPrice
          formatEth={false}
          price={roundToNumDecimals(eth)}
          large={true}
          color={theme.palette.colors.black}
        />
        <PriceUSD large={true} color="rgba(0,0,0,.5)">
          {formatUSD(usd)}
        </PriceUSD>
      </div>
      <Stack gap={1} alignItems="center">
        <span className={styles.description}>{description}</span>
        {avgCostPerAxieETH && (
          <HelperText>
            Average cost per axie: Ξ{roundToNumDecimals(avgCostPerAxieETH)} (
            {formatUSD(avgCostPerAxieUSD)})
          </HelperText>
        )}
      </Stack>
    </div>
  );
};

const Summary = ({
  inputs,
  summary,
  currencies,
  slpCostETH,
  slpCostUSD,
  axsCostETH,
  axsCostUSD,
  totalRawMaterialsCostsETH,
  totalCostsETH,
  avgCostPerAxieETH,
  avgCostPerAxieUSD,
}) => {
  return (
    <Stack gap={2}>
      <Typography
        variant="h2"
        color="text.bright"
        textAlign="center"
      >{`Breeding Summary: ${inputs.numberOfBreeds} Breeds`}</Typography>
      <Stack gap={1}>
        <BannerTitle>Costs</BannerTitle>
        <ShoppingListItem
          icon="/images/icons/slp.png"
          iconAlt="Smooth Love Potions"
          required={summary.shoppingList.slp.required}
          inventory={summary.shoppingList.slp.inventory}
          toBuy={summary.shoppingList.slp.toBuy}
          costETH={slpCostETH}
          costUSD={slpCostUSD}
          info={
            <div>
              <span>Market price:</span>
              <br />
              <span>{currencies["smooth-love-potion"]["eth"]} ETH</span>
              <br />
              <span>(${currencies["smooth-love-potion"]["usd"]})</span>
            </div>
          }
          totalTitle="Total SLP Cost"
          helperText="Gas fees not included"
        />
        <ShoppingListItem
          icon="/images/icons/axs.png"
          iconAlt="Axie Infinity"
          required={summary.shoppingList.axs.required}
          inventory={summary.shoppingList.axs.inventory}
          toBuy={summary.shoppingList.axs.toBuy}
          costETH={axsCostETH}
          costUSD={axsCostUSD}
          info={
            <div>
              <span>Market price:</span>
              <br />
              <span>{currencies["axie-infinity"]["eth"]} ETH</span>
              <br />
              <span>(${currencies["axie-infinity"]["usd"]})</span>
            </div>
          }
          totalTitle="Total AXS Cost"
          helperText="Gas fees not included"
        />
        <TotalRawMaterials
          title="Total SLP + AXS Cost"
          eth={totalRawMaterialsCostsETH}
          usd={roundToNumDecimals(
            totalRawMaterialsCostsETH * currencies["ethereum"]["usd"]
          )}
        />
        <ParentTransaction
          icon="/images/icons/axie-face.png"
          iconAlt="Axie"
          title="Parent Purchases"
          parent1ETH={inputs.parent1.costETH}
          parent1USD={roundToNumDecimals(
            inputs.parent1.costETH * currencies["ethereum"]["usd"]
          )}
          parent2ETH={inputs.parent2.costETH}
          parent2USD={roundToNumDecimals(
            inputs.parent2.costETH * currencies["ethereum"]["usd"]
          )}
          parentTotalETH={summary.totalParentCostsETH}
          parentTotalUSD={roundToNumDecimals(
            summary.totalParentCostsETH * currencies["ethereum"]["usd"]
          )}
          parent1Title="Parent 1 Cost"
          parent2Title="Parent 2 Cost"
          totalTitle="Total Parent Cost"
        />
        <Total
          title="Total Cost"
          eth={totalCostsETH}
          usd={roundToNumDecimals(
            totalCostsETH * currencies["ethereum"]["usd"]
          )}
          description="SLP + AXS + Parents"
          avgCostPerAxieETH={avgCostPerAxieETH}
          avgCostPerAxieUSD={avgCostPerAxieUSD}
        />
      </Stack>
      <Stack gap={1}>
        <BannerTitle>Sales</BannerTitle>
        <ParentTransaction
          icon="/images/icons/axie-face.png"
          iconAlt="Axie"
          title="Parent Sales"
          parent1ETH={inputs.parent1.saleETH}
          parent1USD={roundToNumDecimals(
            inputs.parent1.saleETH * currencies["ethereum"]["usd"]
          )}
          parent2ETH={inputs.parent2.saleETH}
          parent2USD={roundToNumDecimals(
            inputs.parent2.saleETH * currencies["ethereum"]["usd"]
          )}
          parentTotalETH={summary.totalParentSalesETH}
          parentTotalUSD={roundToNumDecimals(
            summary.totalParentSalesETH * currencies["ethereum"]["usd"]
          )}
          parent1Title="Parent 1 Sale"
          parent2Title="Parent 2 Sale"
          totalTitle="Total Parent Sales"
          helperText={`Net of ${MARKET_FEE * 100}% fee`}
        />
        <ChildTransaction
          icon="/images/icons/egg.png"
          iconAlt="Axie"
          salesETH={inputs.childSalesETH}
          salesUSD={inputs.childSalesETH.map((sale) =>
            roundToNumDecimals(sale * currencies["ethereum"]["usd"])
          )}
          totalETH={summary.totalChildSalesETH}
          totalUSD={roundToNumDecimals(
            summary.totalChildSalesETH * currencies["ethereum"]["usd"]
          )}
          totalTitle="Total Child Sales"
          helperText={`Net of ${MARKET_FEE * 100}% fee`}
          numberOfBreeds={inputs.numberOfBreeds}
        />
        <Total
          title="Total Sales"
          eth={summary.totalSalesETH}
          usd={roundToNumDecimals(
            summary.totalSalesETH * currencies["ethereum"]["usd"]
          )}
          description="Parents + Children"
          priceClass="sales"
        />
      </Stack>
    </Stack>
  );
};

export default Summary;
