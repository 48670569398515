import React from "react";
import Rank from "../atoms/Rank";
import Name from "../atoms/Name";
import Points from "../atoms/Points";
import Info from "./Info";
import Team from "./Team";
import PlayerWrapper from "../atoms/PlayerWrapper";

const Player = ({
  username,
  xpTotal,
  survivalTime,
  monstersDefeated,
  avatar,
  rank,
  sidekick,
}) => {
  return (
    <PlayerWrapper rank={rank}>
      <Rank rank={rank} />

      <Name name={username} />

      <Team axieId={avatar?.axieId} sidekickImage={sidekick?.assets?.noBg} />

      <Points points={xpTotal} />

      <Info survivalTime={survivalTime} monstersDefeated={monstersDefeated} />
    </PlayerWrapper>
  );
};

export default Player;
