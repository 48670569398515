import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import { getColorForAxieClass } from "helpers/axie";
import Label from "components/Typography/Label";
import * as bodyPartIcons from "components/Axie/icons/bodyPartIcons";
import { displayPercent } from "helpers/display";

const GenesSimulated = ({ axie }) => {
  const theme = useTheme();

  return (
    <Stack gap={2}>
      <SectionLabel>Simulated Genes</SectionLabel>

      <Stack gap={3} flexWrap="wrap" flexDirection="row">
        {["eyes", "ears", "back", "mouth", "horn", "tail"].map(
          (bodyPart, i) => {
            let possibilities = axie.geneProbabilities?.parts[bodyPart];

            if (possibilities == null) {
              return null;
            }

            return (
              <BodyPart key={`BODY_PART_${i}`}>
                <BodyPartIcon bodyPart={bodyPart} topPart={possibilities[0]} />
                <Stack gap={0.5}>
                  {possibilities.map((p, j) => (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      key={`POSSIBILITIES_${i}_${j}`}
                      sx={{
                        borderBottom: `1px solid ${theme.palette.card.divider}`,
                      }}
                    >
                      <Trait axieClass={p.axieClass}>{p.trait}</Trait>
                      <Percentage>{displayPercent(p.percent)}</Percentage>
                    </Stack>
                  ))}
                </Stack>
              </BodyPart>
            );
          }
        )}
      </Stack>
    </Stack>
  );
};

const Trait = ({ children, axieClass }) => (
  <Typography
    color={getColorForAxieClass(axieClass)}
    variant="span"
    fontSize={12}
    fontWeight={700}
  >
    {children}
  </Typography>
);

const Percentage = ({ children }) => (
  <Typography
    sx={{ fontFamily: "monospace" }}
    variant="span"
    fontSize={12}
    fontWeight={600}
    color="text.bright"
  >
    {children}
  </Typography>
);

const BodyPart = ({ children }) => {
  const theme = useTheme();

  return (
    <Stack
      gap={1}
      sx={{
        background: theme.palette.card.background,
        border: `1px solid ${theme.palette.card.divider}`,
        p: 1,
        borderRadius: "12px",
        minHeight: 190,
        flexBasis: "calc((100% - 48px) / 3)",

        [theme.breakpoints.down("md")]: {
          flexBasis: "calc((100% - 48px) / 2)",
        },
        "@media(max-width:800px)": {
          flexBasis: "100%",
        },
      }}
    >
      {children}
    </Stack>
  );
};

const BodyPartIcon = ({ bodyPart, topPart }) => (
  <Stack
    gap={1}
    alignItems="center"
    sx={{ "& svg": { width: "auto", height: 40 } }}
  >
    {bodyPartIcons[`${bodyPart}_${topPart.axieClass.toLowerCase()}`]}
    <Label>{bodyPart}</Label>
  </Stack>
);

export default GenesSimulated;
