import * as types from "./craftingCollectionActionTypes";
import produce from "immer";

export const initialState = {
  collection: [],
};

export const craftingCollectionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CRAFTING_COLLECTION_TOGGLE_ITEM:
        const itemIndex = draft.collection.findIndex(
          (i) => i.id === action.item.id && i.itemType === action.itemType
        );
        if (itemIndex === -1) {
          draft.collection.push({ ...action.item, itemType: action.itemType });
        } else {
          draft.collection.splice(itemIndex, 1);
        }
        break;

      case types.CRAFTING_COLLECTION_BULK_ADD_ITEMS:
        draft.collection.push(...action.items);
        break;

      case types.CRAFTING_COLLECTION_CLEAR_ITEMS:
        if (action.itemType) {
          draft.collection = draft.collection.filter(
            (i) => i.itemType !== action.itemType
          );
        } else {
          draft.collection = [];
        }

        break;

      default:
        break;
    }
  });

export default craftingCollectionReducer;
