import React from "react";
import { Stack, Typography } from "@mui/material";
import ClassIcon from "components/ClassIcon/ClassIcon";

const PotentialPoints = ({
  potentialPoints,
  width,
  getAmount,
  propKey = "amount",
}) => {
  const height = (15 / 320) * width;

  return (
    <Stack
      gap={(0.5 / 320) * width}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
    >
      {potentialPoints != null &&
        potentialPoints.map((points, i) => (
          <Stack
            direction="row"
            gap={(0.5 / 320) * width}
            alignItems="center"
            key={`POINT_${i}`}
          >
            <ClassIcon axieClass={points.class} width={(24 / 320) * width} />
            <Stack sx={{ width: getAmount ? "auto" : height * 1.2 }}>
              <Typography
                variant="span"
                color="text.regular"
                fontSize={height}
                fontWeight={600}
                className="potentialPointText"
              >
                {getAmount ? getAmount(points) : points[propKey]}
              </Typography>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default PotentialPoints;
