import { matchesStr, matchesTagsOrText, strIsInArray } from "helpers/display";
import { createSelector } from "reselect";
import { itemIsFavorite } from "./";

export const getCards = (state) => {
  return state.data?.axieCardsV3.cards || [];
};

export const getCardFilters = (state) => {
  return state.explorer?.cardFilters || {};
};

export const getFavoriteCards = (state) => {
  return state.explorer?.favoriteCards || [];
};

const applyCardFilters = (card, filters) => {
  if (!filters || !card) {
    return true;
  }

  const partType = (card.partId || "").split("-")[0];

  return (
    (matchesStr(card.cardText, filters.searchText) ||
      matchesStr(card.partName, filters.searchText) ||
      matchesStr(card.cardName, filters.searchText)) &&
    strIsInArray(card.class, filters.axieClasses) &&
    strIsInArray(partType, filters.partTypes) &&
    strIsInArray(card.cardEnergy, filters.costs) &&
    matchesTagsOrText(card.tags, card.cardText, filters.tags) &&
    cardHasImage(card)
  );
};

const cardHasImage = (card) => (card.cardImage ?? "") !== "";

const applySortCards = (a, b, sortType) => {
  const aEnergy = isNaN(a.cardEnergy) ? -1 : a.cardEnergy;
  const bEnergy = isNaN(b.cardEnergy) ? -1 : b.cardEnergy;

  if (sortType === "DAMAGE_ASC") {
    return parseInt(a.cardDamage) - parseInt(b.cardDamage);
  } else if (sortType === "DAMAGE_DESC") {
    return parseInt(b.cardDamage) - parseInt(a.cardDamage);
  } else if (sortType === "SHIELD_ASC") {
    return parseInt(a.cardShield) - parseInt(b.cardShield);
  } else if (sortType === "SHIELD_DESC") {
    return parseInt(b.cardShield) - parseInt(a.cardShield);
  } else if (sortType === "HEAL_ASC") {
    return parseInt(a.cardHeal) - parseInt(b.cardHeal);
  } else if (sortType === "HEAL_DESC") {
    return parseInt(b.cardHeal) - parseInt(a.cardHeal);
  } else if (sortType === "ENERGY_ASC") {
    return aEnergy - bEnergy;
  } else if (sortType === "ENERGY_DESC") {
    return bEnergy - aEnergy;
  } else if (sortType === "NAME_DESC") {
    return b.cardName < a.cardName ? -1 : a.cardName < b.cardName ? 1 : 0;
  }
  return a.cardName < b.cardName ? -1 : b.cardName < a.cardName ? 1 : 0;
};

export const getCardsToDisplay = createSelector(
  [getCards, getCardFilters, getFavoriteCards],
  (cards, cardFilters, favoriteCards) => {
    return [...cards]
      .filter((card) => applyCardFilters(card, cardFilters))
      .sort((a, b) => applySortCards(a, b, cardFilters.sortType))
      .map((card) => ({
        ...card,
        isFavorite: itemIsFavorite(card, "partId", favoriteCards, "name"),
      }));
  }
);

export const getFavoriteCardsToDisplay = createSelector(
  [getFavoriteCards],
  (favoriteCards) => {
    return [...favoriteCards].map((card) => ({
      ...card,
      isFavorite: true,
    }));
  }
);
