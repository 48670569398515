import React from "react";
import { Stack } from "@mui/material";

const LargeCapsuleLabel = ({ children, backgroundImage, backgroundColor }) => {
  return (
    <Stack
      sx={{
        background: backgroundColor,
        backgroundImage,
        p: "4px",
        borderRadius: "40px",
        boxShadow: (theme) =>
          `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
        width: "100%",
        maxWidth: 330,
      }}
    >
      <Stack
        sx={{
          background: (theme) => theme.palette.card.background,
          p: 2,
          borderRadius: "36px",
        }}
      >
        <Stack gap={1} alignItems="center">
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LargeCapsuleLabel;
