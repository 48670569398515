import { Stack, styled } from "@mui/material";
import Name from "../molecules/Name";
import SubtleText from "../atoms/SubtleText";
import Avatar from "../atoms/Avatar";
import Ranking from "../molecules/Ranking";
import { PlayerAugmented } from "types/player";

const Wrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  marginBottom: -32,

  [theme.breakpoints.down("md")]: {
    marginBottom: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

const Header = ({ player }: { player: PlayerAugmented }) => {
  return (
    <Wrapper>
      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar encodedAvatar={player.avatar} />

        <Stack gap={0.5}>
          <Name name={player.name} />
          {player.lastActive != null && (
            <SubtleText>Last played: {player.lastActive}</SubtleText>
          )}
        </Stack>
      </Stack>

      <Ranking
        rankTier={player.rankTier}
        topRank={player.topRank}
        winRate={player.battleStats.winRate}
        vstar={player.vstar}
        rank={player.rank}
      />
    </Wrapper>
  );
};

export default Header;
