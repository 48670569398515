import React from "react";
import { Box, useTheme } from "@mui/material";

const Flag = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        top: -32,
        left: -27,
        "& img": { width: 420 },
        [theme.breakpoints.down("md")]: {
          left: "50%",
          transform: "translate(-50%)",
        },
      }}
    >
      <img src="/images/explorer/flag.png" alt="Flag" />
    </Box>
  );
};

export default Flag;
