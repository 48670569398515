import * as config from "config";
import * as classIcons from "components/Axie/icons/classIcons";
import { BODY_PARTS_DICT, SPECIAL_PART_MAPPING } from "./axieFeatures";
import * as bodyPartIcons from "components/Axie/icons/bodyPartIcons";
import moment from "moment";
import { formatEthPrice, formatUSD, formatTimestampAsDate } from "./display";
import {
  AXIE_CLASS_SORT_ORDER,
  STARTER_AXIE_PARTIDS,
  AXIE_POTENTIAL_POINT_ALLOCATION_ORDER,
  AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES,
  AXIE_CLASS_ID_MAPPING,
} from "config";
import { AxieType } from "types";

export const getGeneBodyPartKeys = (genes) => {
  return genes.flatMap((g) => {
    return config.BODY_PARTS.map(
      (p) => g + p.charAt(0).toUpperCase() + p.slice(1)
    );
  });
};

export const getColorFor = (axie, mode = "light") => {
  if (!axie || !("class" in axie) || axie.class == null) {
    if (mode === "light") {
      return config.AXIE_CLASS_COLORS.default;
    }
    return config.AXIE_CLASS_COLORS.defaultDark;
  }
  return getColorForAxieClass(axie.class, mode);
};

export const getColorForAxieClass = (axieClass, mode = "light") => {
  if (
    axieClass != null &&
    config.AXIE_CLASS_COLORS[axieClass.toLowerCase()] != null
  ) {
    return config.AXIE_CLASS_COLORS[axieClass.toLowerCase()];
  }
  return mode === "light"
    ? config.AXIE_CLASS_COLORS.default
    : config.AXIE_CLASS_COLORS.defaultDark;
};

export const getIconForAxieClass = (axieClass, color) => {
  if (axieClass != null) {
    const cls = axieClass.toLowerCase();
    if (cls in classIcons) {
      if (color) {
        return classIcons[cls](color);
      }
      return classIcons[cls]();
    }
  } else {
    if (color) {
      return classIcons.unknown(color);
    }
    return classIcons.unknown();
  }
};

export const calculatePurity = (axieClass, parts) => {
  return parts.filter((part) => part.class === axieClass).length;
};

export const calculatePurityAxieTech = (axie) => {
  if (!axie.class || !axie.stage || parseInt(axie.stage) !== 4) {
    return undefined;
  }

  let purityCount = 0;
  let purityPercent = 0;
  if (axie.parts) {
    ["d", "r1", "r2"].forEach((gene) => {
      config.BODY_PARTS.forEach((part) => {
        if (axie.parts[gene] && axie.parts[gene][part]) {
          const classOfPart =
            axie.parts[gene][part]?.class ||
            BODY_PARTS_DICT[axie.parts[gene][part]]?.class;

          if (classOfPart && classOfPart === axie.class.toLowerCase()) {
            purityPercent += config.PROBABILITIES[gene];

            if (gene === "d") purityCount += 1;
          }
        }
      });
    });
  }
  return { purityCount, purityPercent: purityPercent / config.MAX_PURITY };
};

export const calculatePurityPercents = (partsProbabilities, ignoreEyesEars) => {
  const purity = {};
  const purityPercents = {};
  Object.keys(partsProbabilities).forEach((part) => {
    partsProbabilities[part].forEach((trait) => {
      if (
        (ignoreEyesEars && part !== "eyes" && part !== "ears") ||
        !ignoreEyesEars
      ) {
        if (trait.axieClass in purity) {
          purity[trait.axieClass] += trait.percent;
        } else {
          purity[trait.axieClass] = trait.percent;
        }
      }
    });
  });
  Object.keys(purity).forEach((key) => {
    purityPercents[key] = ignoreEyesEars
      ? purity[key] / (config.NUMBER_OF_BODY_PARTS - 2)
      : purity[key] / config.NUMBER_OF_BODY_PARTS;
  });
  return purityPercents;
};

export const calculatePurityScore = (purityPercents) => {
  let maxVal = 0;
  Object.keys(purityPercents).forEach((key) => {
    if (purityPercents[key] >= maxVal) {
      maxVal = purityPercents[key];
    }
  });
  return maxVal;
};

export const makeMarketplaceURLFromID = (id) => {
  return `${AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES}/${id}`;
};

export const makeMarketplaceSearchStringFromAxie = (axie) => {
  if (!axie?.class || !axie.parts) {
    return undefined;
  }

  const partIds = extractAndCleanPartIds(axie.parts.d);
  const partsSearchString = makeMarketplaceSearchStringFromParts(partIds);

  return (
    `${partsSearchString}&class=` +
    axie.class +
    "&breedCount=0&breedCount=" +
    axie.breedCount
  );
};

export const makeMarketplaceSearchStringFromParts = (parts) => {
  const baseStem = AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES;

  if (parts.length === 0) {
    return baseStem;
  }

  const filteredParts = parts.filter(
    (part) => !STARTER_AXIE_PARTIDS.includes(part)
  );

  const shareLink = baseStem + "?auctionTypes=Sale&parts=";
  return shareLink + filteredParts.join("&parts=");
};

export const makeMarketplaceSearchStringFromNumMysticParts = (numParts) =>
  `${AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES}/?mystic=${numParts}&title=Origin&auctionTypes=Sale`;

export const matchesClass = (axieClass, selectedClasses) => {
  if (Array.isArray(selectedClasses)) {
    return selectedClasses.includes(axieClass) || selectedClasses.length === 0;
  }
  return axieClass === selectedClasses || selectedClasses === "All";
};

export const matchesRole = (axieRole, selectedRole) => {
  return selectedRole.includes(axieRole) || selectedRole.length === 0;
};

export const matchesSpecialty = (axieSpecialty, selectedSpecialty) => {
  return (
    selectedSpecialty.includes(axieSpecialty) || selectedSpecialty.length === 0
  );
};

export const matchesPriceRange = (price, minPrice, maxPrice) => {
  if (maxPrice === "" && minPrice !== "") {
    return price >= minPrice;
  }
  if ((maxPrice !== "") & (minPrice === "")) {
    return price <= maxPrice;
  }
  if ((maxPrice === "") & (minPrice === "")) {
    return true;
  }
  return price <= maxPrice && price >= minPrice;
};

export const matchesBreedCount = (axieBreedCount, breedCount) =>
  breedCount.includes(axieBreedCount) || breedCount.length === 0;

export const matchesPurity = (axiePurity, purity) =>
  purity.includes(axiePurity) || purity.length === 0;

export const matchesStage = (axieStage, stage) =>
  stage.includes(axieStage) || stage.length === 0;

export const matchesStat = (axieStat, minStat) =>
  axieStat >= minStat || minStat === 27;

export const matchesPurityPercent = (axiePurityPercent, minPurityPercent) =>
  Math.round(axiePurityPercent * 100) >= minPurityPercent ||
  minPurityPercent === 0;

export const matchesSelectedParts = (axieParts, selectedParts, all = true) => {
  if (
    [...selectedParts.d, ...selectedParts.r1, ...selectedParts.r2].length ===
      0 ||
    !axieParts ||
    Array.isArray(axieParts)
  ) {
    return true;
  }
  let dParts = [],
    r1Parts = [],
    r2Parts = [];
  // Axie detail format
  if (typeof axieParts.d.eyes === "object" && "partId" in axieParts.d.eyes) {
    dParts = Object.keys(axieParts.d).map((part) =>
      mapSpecialPartToNormalPart(axieParts.d[part].partId)
    );
    r1Parts = Object.keys(axieParts.r1).map((part) =>
      mapSpecialPartToNormalPart(axieParts.r1[part].partId)
    );
    r2Parts = Object.keys(axieParts.r2).map((part) =>
      mapSpecialPartToNormalPart(axieParts.r2[part].partId)
    );
  }
  // Scholar axie format
  else {
    dParts = Object.values(axieParts.d).map((p) =>
      mapSpecialPartToNormalPart(p)
    );
    r1Parts = Object.values(axieParts.r1).map((p) =>
      mapSpecialPartToNormalPart(p)
    );
    r2Parts = Object.values(axieParts.r2).map((p) =>
      mapSpecialPartToNormalPart(p)
    );
  }

  if (all) {
    return (
      selectedParts.d.every((el) => dParts.includes(el)) &&
      selectedParts.r1.every((el) => r1Parts.includes(el)) &&
      selectedParts.r2.every((el) => r2Parts.includes(el))
    );
  } else {
    return (
      selectedParts.d.some((el) => dParts.includes(el)) ||
      selectedParts.r1.some((el) => r1Parts.includes(el)) ||
      selectedParts.r2.some((el) => r2Parts.includes(el))
    );
  }
};

export const mapSpecialPartToNormalPart = (part) => {
  if (part in SPECIAL_PART_MAPPING) {
    return SPECIAL_PART_MAPPING[part];
  }
  return part;
};

export const getOptionsForAxieFilterButtons = () => [
  "All",
  ...config.AXIE_CLASSES,
];

export const calculateRequiredSLPForBreed = (
  numberOfBreeds,
  parent1BreedCount,
  parent2BreedCount
) => {
  if (
    parent1BreedCount + numberOfBreeds > 7 ||
    parent2BreedCount + numberOfBreeds > 7
  ) {
    return undefined;
  }
  let slp = 0;
  Array.from(Array(numberOfBreeds).keys()).map(
    (breed) =>
      (slp +=
        config.BREEDING_COST_SLP[parent1BreedCount + breed] +
        config.BREEDING_COST_SLP[parent2BreedCount + breed])
  );
  return slp;
};

export const calculateRequiredAXSForBreed = (
  numberOfBreeds,
  parent1BreedCount,
  parent2BreedCount
) => {
  if (
    parent1BreedCount + numberOfBreeds > 7 ||
    parent2BreedCount + numberOfBreeds > 7
  ) {
    return undefined;
  }
  let axs = 0;
  Array.from(Array(numberOfBreeds).keys()).map(
    (breed) => (axs += config.BREEDING_COST_AXS)
  );
  return axs;
};

export const getAxieRarity = (numAxies) => {
  if (numAxies > config.AXIE_PRICING_RARITIES.common.threshold) {
    return config.AXIE_PRICING_RARITIES.common;
  } else if (numAxies > config.AXIE_PRICING_RARITIES.uncommon.threshold) {
    return config.AXIE_PRICING_RARITIES.uncommon;
  } else if (numAxies > config.AXIE_PRICING_RARITIES.rare.threshold) {
    return config.AXIE_PRICING_RARITIES.rare;
  } else if (numAxies > config.AXIE_PRICING_RARITIES.epic.threshold) {
    return config.AXIE_PRICING_RARITIES.epic;
  } else {
    return config.AXIE_PRICING_RARITIES.unique;
  }
};

export const countNumberOfMysticParts = (axie) => {
  let result = 0;
  if (axie && axie.parts && axie.parts.d) {
    config.BODY_PARTS.forEach((part) => {
      if (
        axie.parts.d[part] &&
        axie.parts.d[part].specialGenes !== null &&
        axie.parts.d[part].specialGenes.toLowerCase() === "mystic"
      ) {
        result += 1;
      }
    });
  }
  return result;
};

export const calculateMaxNumberOfBreeds = (
  parent1BreedCount,
  parent2BreedCount
) => {
  return 7 - Math.max(parent1BreedCount, parent2BreedCount);
};

const CRITERIA_FUNCTIONS = {
  AXIE_CLASS: matchesClass,
  BREED_COUNT: matchesBreedCount,
  PURITY_COUNT: matchesPurity,
  STAT: matchesStat,
  PURITY_PERCENT: matchesPurityPercent,
  PRICE_RANGE: matchesPriceRange,
  AXIE_STAGE: matchesStage,
  SELECTED_PARTS: matchesSelectedParts,
  ROLE: matchesRole,
  SPECIALTY: matchesSpecialty,
};

export const axieMatchesCriteria = (criteria) => {
  return criteria.every((c) => CRITERIA_FUNCTIONS[c.key](...c.args));
};

export const getSelectedParts = (criteria) => {
  const parts = {
    d: [],
    r1: [],
    r2: [],
  };

  for (const part in criteria.selectedPartsIndividualPartMatches) {
    ["d", "r1", "r2"].forEach((g) => {
      if (criteria.selectedPartsIndividualPartMatches[part][g] === true) {
        parts[g].push(part);
      }
    });
  }

  return parts;
};

export const getBodyPartIcon = (part, cls) => {
  const key = `${part}_${cls}`.toLowerCase();
  if (key in bodyPartIcons) {
    return bodyPartIcons[key];
  }
  return "";
};

export const axieSoldSinceCutOff = (lastSold, cutOff) => {
  return (
    lastSold &&
    lastSold.transferHistory &&
    lastSold.transferHistory.results[0] &&
    lastSold.transferHistory.results[0].timestamp >= cutOff
  );
};

export const axieIsAdult = (axie) => {
  return (
    parseInt(axie.stage) === 4 || axie.class != null || axie.axie_type != null
  );
};

export const axieIsStarter = (axie) => {
  return axie && axie.axie_type === AxieType.STARTER;
};

export const axieIsOrigin = (axie) => axie.title === "Origin";

export const calculateEggHatchTimestamp = (axie) => {
  if (axie.birthDate != null) {
    return moment.unix(axie.birthDate).add(5, "day").valueOf();
  }
  return undefined;
};

export const calculateEggHatchProgress = (axie) => {
  if (axie.birthDate != null) {
    const now = moment().valueOf();
    const birth = moment.unix(axie.birthDate).valueOf();
    const hatch = moment(birth).add(5, "day").valueOf();

    return (now - birth) / (hatch - birth);
  }
  return undefined;
};

export const getPricesForAxie = (axie) => {
  return {
    eth: axie?.order?.currentPrice
      ? "Ξ " + formatEthPrice(axie?.order?.currentPrice)
      : undefined,
    usd: axie?.order?.currentPriceUsd
      ? formatUSD(axie?.order?.currentPriceUsd)
      : undefined,
  };
};

export const canEquipCharm = (charm, potentialPoints) => {
  let charmClass = charm.class.toLowerCase();
  const cost = parseInt(charm.potentialCost);
  let classToDeplete = charmClass === "neutral" ? "neutral" : charmClass;
  let availablePoints;

  if (charmClass === "neutral") {
    availablePoints = potentialPoints.reduce((prev, curr) => {
      if (curr.remaining != null) {
        prev += curr.remaining;
      }
      return prev;
    }, 0);
    return availablePoints >= cost;
  } else {
    availablePoints = potentialPoints.find((p) => p.class === classToDeplete);
    return availablePoints != null && availablePoints.remaining >= cost;
  }
};

export const getCardStatsAndTags = (cards) => {
  const cardStats = {
    cardEnergy: 0,
    cardDamage: 0,
    cardShield: 0,
    cardHeal: 0,
  };
  const cardTags = {};
  const mainTags = [
    "attack",
    "banish",
    "ethereal",
    "innate",
    "power",
    "retain_effect",
    "secret",
    "skill",
  ];

  cards.forEach((card) => {
    if (card != null) {
      Object.keys(cardStats).forEach((key) => {
        const amount = parseInt(card[key]);
        if (!isNaN(amount)) {
          cardStats[key] += amount;
        }
      });

      const tags = card.tags.split(",");

      tags.forEach((tag) => {
        if (mainTags.includes(tag)) {
          if (tag in cardTags) {
            cardTags[tag] += 1;
          } else {
            cardTags[tag] = 1;
          }
        }
      });
    }
  });

  return { cardStats, cardTags };
};

export const getCardsForAxie = (axie, cardsV3, cardsV2) => {
  if (axie?.parts?.d == null) {
    return undefined;
  }
  const mainTags = [
    "attack",
    "banish",
    "ethereal",
    "innate",
    "power",
    "retain_effect",
    "secret",
    "skill",
  ];
  const result = {
    cardV3Stats: { cardEnergy: 0, cardDamage: 0, cardShield: 0, cardHeal: 0 },
    cardV2Stats: { cardEnergy: 0, cardDamage: 0, cardShield: 0, cardHeal: 0 },
    cardV3Tags: {},
    cardsV3: {},
    cardsV2: {},
    cardV3Array: [],
    cardV2Array: [],
    cardV3Classes: [],
  };
  Object.keys(axie.parts.d).forEach((part) => {
    const rawPartId = axie.parts.d[part].partId || axie.parts.d[part];
    const partId = mapSpecialPartToNormalPart(rawPartId);

    const cardV3 =
      cardsV3 != null
        ? cardsV3.find((card) => card.partId === partId)
        : undefined;
    const cardV2 =
      cardsV2 != null
        ? cardsV2.find((card) => card.partId === partId)
        : undefined;

    result.cardsV3[part] = cardV3;
    result.cardsV2[part] = cardV2;

    if (cardV2 != null) {
      result.cardV2Array.push(cardV2);
    }

    if (cardV3 != null) {
      result.cardV3Array.push(cardV3);
      result.cardV3Classes.push(cardV3.class.toLowerCase());
      const tags = cardV3.tags.split(",");

      tags.forEach((tag) => {
        if (mainTags.includes(tag)) {
          if (tag in result.cardV3Tags) {
            result.cardV3Tags[tag] += 1;
          } else {
            result.cardV3Tags[tag] = 1;
          }
        }
      });
    }

    ["cardEnergy", "cardDamage", "cardShield", "cardHeal"].forEach((type) => {
      if (cardV3 && cardV3[type] != null) {
        const v3amount = parseInt(cardV3[type]);
        if (!isNaN(v3amount)) {
          result.cardV3Stats[type] += v3amount;
        }
      }
      if (cardV2 && cardV2[type] != null) {
        const amount = parseInt(cardV2[type]);
        if (!isNaN(amount)) {
          result.cardV2Stats[type] += amount;
        }
      }
    });
  });

  return result;
};

export const getAxieLastSold = (lastSold) => {
  return {
    eth:
      lastSold?.price != null
        ? "Ξ" + formatEthPrice(lastSold.price)
        : undefined,
    usd: lastSold?.priceUsd != null ? formatUSD(lastSold.priceUsd) : undefined,
    date:
      lastSold?.timestamp == null
        ? undefined
        : formatTimestampAsDate(lastSold.timestamp),
  };
};

export const augmentedAxieData = (axie) => {
  return {
    color: getColorForAxieClass(axie.class),
    marketplaceUrl: makeMarketplaceURLFromID(axie.axieId),
    purity: calculatePurityAxieTech(axie),
    currentPrice: getPricesForAxie(axie),
  };
};

export const calculatePotentialPoints = (axieCardClassArray, axieClass) => {
  if (axieCardClassArray == null || axieClass == null) {
    return [];
  }

  const potentialPoints = [];
  const classCounts = {};

  axieCardClassArray.forEach((c) => {
    const cls = (c || "").toLowerCase();
    if (cls in classCounts) {
      classCounts[cls] += 2;
    } else {
      classCounts[cls] = 2;
    }
  });

  if (axieClass.toLowerCase() in classCounts) {
    classCounts[axieClass.toLowerCase()] += 3;
  } else {
    classCounts[axieClass.toLowerCase()] = 3;
  }

  Object.keys(classCounts).forEach((key) => {
    const totalPoints = classCounts[key];
    potentialPoints.push({ class: key, amount: totalPoints });
  });

  return potentialPoints.sort((a, b) =>
    a.amount < b.amount ? 1 : a.amount > b.amount ? -1 : 0
  );
};

export const getAxieRole = (cards) => {
  if (cards == null || cards.length === 0) {
    return undefined;
  }

  const counts = {
    Support: 0,
    "Damage Dealer": 0,
    Defender: 0,
  };
  let numBanishCards = 0;

  cards.forEach((c) => {
    // Ignore Banish cards
    if (c.cardText != null && c.cardText.includes("<Banish>")) {
      numBanishCards += 1;
    } else {
      counts[c.classification.role] += 1;
    }
  });

  const offset = Math.floor(numBanishCards / 2);

  if (counts["Damage Dealer"] >= 4 - offset) {
    return "Damage Dealer";
  } else if (counts["Defender"] >= 3 - offset) {
    return "Defender";
  } else {
    return "Support";
  }
};

export const getAxieSpecialty = (cards) => {
  if (cards == null || cards.length === 0) {
    return undefined;
  }

  const counts = {
    Offensive: 0,
    Sustain: 0,
    Summoner: 0,
    Rage: 0,
    Ramp: 0,
    "Poison/Bleed": 0,
    Curse: 0,
    Tactical: 0,
    Backdoor: 0,
    Unclassified: 0,
  };
  const dmgRampCards = {};
  let numBanishCards = 0;
  let numDamageDealerCards = 0;

  cards.forEach((c) => {
    if (c.classification.role === "Damage Dealer") {
      numDamageDealerCards += 1;
    }

    // Ignore Banish cards
    if (c.cardText != null && c.cardText.includes("<Banish>")) {
      numBanishCards += 1;
    } else {
      counts[c.classification.speciality] += 1;

      if (c.classification.speciality === "Ramp") {
        if (c.cardName in dmgRampCards) {
          dmgRampCards[c.cardName] += 1;
        } else {
          dmgRampCards[c.cardName] = 1;
        }
      }
    }
  });

  const maxNumberOfDmgRampCards = Math.max(...Object.values(dmgRampCards));

  const offset = Math.floor(numBanishCards / 2);

  if (counts["Curse"] >= 3 - offset) {
    return "Curse+";
  } else if (counts["Sustain"] >= 4 - offset) {
    return "Sustain+";
  } else if (counts["Poison/Bleed"] >= 4 - offset) {
    return "Poison/Bleed+";
  } else if (maxNumberOfDmgRampCards >= 3 - offset) {
    return "Damage Ramp+";
  } else if (counts["Rage"] >= 3 - offset) {
    return "Rage+";
  } else if (counts["Summoner"] >= 3 - offset) {
    return "Summoner+";
  } else if (counts["Curse"] >= 2 - offset) {
    return "Curse";
  } else if (counts["Sustain"] >= 3 - offset) {
    return "Sustain";
  } else if (counts["Poison/Bleed"] >= 3 - offset) {
    return "Poison/Bleed";
  } else if (counts["Backdoor"] >= 3 - offset) {
    return "Backdoor+";
  } else if (counts["Offensive"] >= 3 - offset && numDamageDealerCards >= 6) {
    return "Offensive+";
  } else if (counts["Tactical"] >= 3 - offset) {
    return "Tactical+";
  } else if (maxNumberOfDmgRampCards >= 2 - offset) {
    return "Damage Ramp";
  } else if (counts["Rage"] >= 2 - offset) {
    return "Rage";
  } else if (counts["Summoner"] >= 2 - offset) {
    return "Summoner";
  } else if (counts["Backdoor"] >= 2 - offset) {
    return "Backdoor";
  } else if (counts["Offensive"] >= 2 - offset && numDamageDealerCards >= 5) {
    return "Offensive";
  } else if (counts["Tactical"] >= 2 - offset) {
    return "Tactical";
  } else {
    return "Neutral";
  }
};

export const getAxieClassification = (cards) => ({
  role: getAxieRole(cards),
  specialty: getAxieSpecialty(cards),
});

export const makeBlockchainAxieImageUrl = (id) =>
  `${config.AXIE_INFINITY_ORIGIN_IMAGE_URL}/${id}/axie/axie-full-transparent.png`;

export const getAxieImageUrl = (axie) => {
  if (axieIsStarter(axie)) {
    const starterAxie = config.STARTER_AXIE_DATA[axie.axieId];
    if (starterAxie) {
      return `/images/axies/starter-axies/${starterAxie.slug}.png`;
    }
    return null;
  }
  if (axie.stage != null && axie.stage !== 4) {
    return axie.image;
  }
  return makeBlockchainAxieImageUrl(axie.axieId);
};

export const sortAxiesByClass = (aClass, bClass) => {
  const aOrder = AXIE_CLASS_SORT_ORDER[aClass];
  const bOrder = AXIE_CLASS_SORT_ORDER[bClass];

  return aOrder < bOrder ? -1 : aOrder > bOrder ? 1 : 0;
};

export const getPotentialPointOrderStartingWithBaseCardClass = (
  baseCardClass
) => {
  const remainingClasses = AXIE_POTENTIAL_POINT_ALLOCATION_ORDER.filter(
    (cls) => cls !== baseCardClass
  );
  return [baseCardClass, ...remainingClasses];
};

export const getIdForAxieClass = (axieClass) => {
  return AXIE_CLASS_ID_MAPPING[axieClass.toLowerCase()];
};

export const getAxieClassforId = (id) => {
  return Object.keys(AXIE_CLASS_ID_MAPPING).find(
    (c) => AXIE_CLASS_ID_MAPPING[c] === id
  );
};

export const cleanPartId = (partId) => {
  if (partId == null || typeof partId !== "string") return "";

  return partId.replaceAll("?", "");
};

export const extractAndCleanPartIds = (parts) => {
  if (parts == null) return [];

  const partIds = [];

  const order = ["back", "mouth", "horn", "tail", "ears", "eyes"];

  for (const part of order) {
    partIds.push(cleanPartId(parts[part]?.partId));
  }

  return partIds;
};

export const isStarterAxiePartId = (partId) =>
  STARTER_AXIE_PARTIDS.includes(partId);

export const makeInspectorUrlFromId = (id) => {
  let url = "/inspector";
  if (id != null) {
    url += `/${id}`;
  }
  return url;
};
