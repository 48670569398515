import { Stack, Button, useTheme } from "@mui/material";
import Player from "components/Top3Players/molecules/Player";
import SecondaryTitle from "pages/Home/atoms/SecondaryTitle";
import { FaArrowRight } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

const Top3Players = ({ top3Players }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
      gap={2}
      alignItems="center"
      justifyContent="center"
    >
      <SecondaryTitle>Top 3 Origins Players</SecondaryTitle>
      <Stack
        width="100%"
        direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        {top3Players.map((player, i) => (
          <Player
            key={`${i}player-key`}
            topRank={player.topRank}
            name={player.name}
            rank={player.rank}
            tier={player.tier}
            vstar={player.vstar}
            clientId={player.clientId}
            favoriteTeam={player.favoriteTeam}
            showCharms={true}
          />
        ))}
      </Stack>
      <Button
        component={RouterLink}
        variant="text"
        size="small"
        to="/origins-leaderboard"
        endIcon={<FaArrowRight />}
      >
        View Leaderboard
      </Button>
    </Stack>
  );
};

export default Top3Players;
