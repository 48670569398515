import React from "react";
import { Typography, Stack } from "@mui/material";

const Message = ({ children }) => {
  return (
    <Stack justifyContent="center" alignItems="center" padding="200px 16px">
      <Typography
        color="text.veryMuted"
        fontSize="22px"
        fontWeight="700"
        textAlign="center"
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default Message;
