import React from "react";
import { Box, useTheme, Tooltip } from "@mui/material";

const Inactive = ({ isActive }) => {
  const theme = useTheme();

  if (isActive) {
    return null;
  }

  return (
    <Tooltip title="No battles played in the last 3 days.">
      <Box
        sx={{
          height: "6px",
          width: "6px",
          borderRadius: "50%",
          background: theme.palette.feedback.red,
          position: "absolute",
          left: 0,
          transform: "translateX(-200%)",
        }}
      />
    </Tooltip>
  );
};

export default Inactive;
