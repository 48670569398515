import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Button,
} from "@mui/material";
import CustomLoader from "components/CustomLoader/CustomLoader";

const LoadingDialog = ({ open, handleClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="select-axies-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          minWidth: "440px",
          [theme.breakpoints.down("sm")]: {
            minWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle id="select-axies-dialog-title">Loading...</DialogTitle>
      <DialogContent dividers={true}>
        <CustomLoader />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadingDialog;
