import React from "react";
import Card from "./Card";
import styles from "./card-explorer.module.scss";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Box, Typography } from "@mui/material";

const MutedCenteredText = ({ text }) => (
  <Box sx={{ py: 8 }}>
    <Typography
      variant="body2"
      color="text.muted"
      fontSize="24px"
      textAlign="center"
      fontWeight="700"
    >
      {text}
    </Typography>
  </Box>
);

const Favorites = ({ favoriteCards, cardsToDisplay, addingFavorites }) =>
  addingFavorites ? (
    <CustomLoader />
  ) : cardsToDisplay.length > 0 ? (
    <div className={`${styles.tier} ${styles.favorites}`}>
      <div className={styles.tierCards}>
        {cardsToDisplay.map((card, i) => (
          <Card key={`FAVORITES_${i}`} card={card} displayIndesTier={true} />
        ))}
      </div>
    </div>
  ) : cardsToDisplay.length === 0 && favoriteCards.length !== 0 ? (
    <MutedCenteredText text="No favorites match your search criteria." />
  ) : (
    <MutedCenteredText text="Wer favorites?" />
  );

export default Favorites;
