import React, { useEffect, useState } from "react";
import Text from "components/Typography/Text";
import { COINGECKO_API } from "config";

const PriceInDollars = ({ priceInEth }) => {
  const [usd, setUsd] = useState(null);
  // use promise- async await returning undefined- CoinGecko API sometimes returning a cors error?
  // made a condition to not display a dollar conversioin value when this happens, but can this be avoided?
  useEffect(() => {
    fetch(COINGECKO_API)
      .then((res) => res.json())
      .then((data) => {
        setUsd(data?.ethereum.usd);
      })
      .catch((err) => console.log(err));
  }, []);

  const priceInEthFormatted = priceInEth ? priceInEth.replace("Ξ", "") : null;
  const priceInUsd = usd ? priceInEthFormatted * usd : null;

  const priceInUsdFormatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
  }).format(priceInUsd);

  return (
    <Text color="text.muted" lineHeight={1}>
      {priceInUsdFormatted === "$0.00" ? null : priceInUsdFormatted}
    </Text>
  );
};

export default PriceInDollars;
