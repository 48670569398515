import React from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import {
  Typography,
  Stack,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Button,
  useTheme,
} from "@mui/material";
import Card from "components/Card/Card";
import { FaArrowRight } from "react-icons/fa";

const IconInfoCard = ({ features }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={2} justifyContent="center">
      {features.map((feature, i) => (
        <Grid item xs={12} sm={12} md={4} lg={4} key={`FEATURE_${i}`}>
          <Card style={{ padding: "22px" }} sx={{ minWidth: "30%" }}>
            <Stack
              gap={1}
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Stack>
                <Typography
                  variant="h3"
                  fontSize="26px"
                  fontWeight="700"
                  sx={{ color: theme.palette.text.secondary }}
                  color="text.primary"
                  gutterBottom
                  align="center"
                >
                  {feature.icon}
                </Typography>
                <Typography
                  variant="h3"
                  fontSize="21px"
                  fontWeight="700"
                  color="text.bright"
                  gutterBottom
                  align="center"
                >
                  {feature.title}
                </Typography>

                <List dense={true}>
                  {feature.bullets.map((bullet, i) => (
                    <ListItem key={`FEATURE_BULLET_${i}`} sx={{ padding: 0 }}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          marginRight: 1,
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <RiCheckboxCircleFill />
                      </ListItemIcon>
                      <Typography
                        variant="body1"
                        fontSize="13px"
                        fontWeight="600"
                        color="text.bright"
                        sx={{ my: 0.25 }}
                      >
                        {bullet}
                        {feature.links != null && feature.links.length > i && (
                          <Button
                            size="small"
                            variant="text"
                            href={feature.links[i].to}
                            endIcon={<FaArrowRight />}
                            sx={{ ml: "2px", py: 0 }}
                          >
                            {feature.links[i].text}
                          </Button>
                        )}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default IconInfoCard;
