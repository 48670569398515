import React from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";
import { calculateNumberOfFavorites } from "store/explorer/selectors";
import favoriteItems from "./favoriteItems";
import MutedCenteredText from "components/Origin/atoms/MutedCenteredText";

const Favorites = ({ numberOfFavorites }) => {
  if (numberOfFavorites === 0) {
    return <MutedCenteredText text="Wer favorites?" />;
  }

  return (
    <Stack gap={8} sx={{ py: 8 }}>
      <favoriteItems.Cards />
      <favoriteItems.Curses />
      <favoriteItems.Tools />
      <favoriteItems.RevengeCards />
      <favoriteItems.Runes />
      <favoriteItems.Charms />
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  numberOfFavorites: calculateNumberOfFavorites(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
