import { useState } from "react";
import { Stack } from "@mui/material";
import Card from "./components/Card";
import ClassIcon from "./components/ClassIcon";
import ID from "./components/ID";
import {
  getCardsForAxie,
  getColorForAxieClass,
  getPricesForAxie,
  axieIsStarter,
} from "helpers/axie";
import BreedCount from "./components/BreedCount";
import Purity from "./components/Purity";
import ParentClasses from "./components/ParentClasses";
import Image from "./components/Image";
import Cards from "./components/Cards";
import DecodedGenes from "./components/DecodedGenes/DecodedGenes";
import { connect } from "react-redux";
import Prices from "./components/Prices";
import DeleteIcon from "./components/DeleteIcon";
import RoninAddress from "./components/RoninAddress";
import RefreshIcon from "./components/RefreshIcon";
import MarketplaceLink from "./components/MarketplaceLink";
import CardSummary from "./components/CardSummary";
import CardsV2 from "./components/CardsV2";
import CardDisplayToggle from "./components/CardDisplayToggle";
import ToggleIcon from "./components/ToggleIcon";
import AxieItems from "./components/AxieItems";
import { getAllCardsV2, getAllCardsV3 } from "store/data/dataSelectors";

const VariantFull = ({
  axie,
  width,
  selected,
  disableElevation,
  disableMarketplaceLink,
  onClick,
  showGenes,
  deleteAxie,
  refreshAxie,
  address,
  lastSold,
  cardsV3,
  cardsV2,
  updateRequiredBreedingId,
  requiredId,
  fillSpace,
}) => {
  const [displayV3Cards, setDisplayV3Cards] = useState(true);
  let color = axie.color;
  if (color == null) {
    color = getColorForAxieClass(axie.class);
  }
  const cards = getCardsForAxie(axie, cardsV3, cardsV2);
  const prices = getPricesForAxie(axie);
  return (
    <Stack
      gap={1.5}
      sx={{
        ...(fillSpace && {
          height: "100%",
        }),
      }}
    >
      <Card
        axie={axie}
        selected={selected}
        width={width}
        onClick={onClick}
        disableElevation={disableElevation}
        style={{
          ...(fillSpace && {
            flexGrow: 1,
          }),
          "&:hover": {
            ".cardVersionButton": {
              opacity: 1,
            },
          },
        }}
      >
        <Stack gap={(1 / 320) * width}>
          <MarketplaceLink
            axie={axie}
            disable={disableMarketplaceLink || axieIsStarter(axie)}
          >
            <Stack gap={1}>
              <Stack direction="row" justifyContent="space-between">
                <Stack gap={1.5}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={(1 / 320) * width}
                  >
                    <ClassIcon axie={axie} width={width} color={color} />
                    <ID axie={axie} width={width} />
                  </Stack>

                  <Stack gap={0.5}>
                    <BreedCount axie={axie} color={color} width={width} />
                    <Purity axie={axie} color={color} width={width} />
                    <ParentClasses axie={axie} width={width} />
                  </Stack>

                  {displayV3Cards === "V3" ? (
                    <CardSummary data={cards?.cardV3Stats} width={width} />
                  ) : (
                    <CardSummary data={cards?.cardV2Stats} width={width} />
                  )}
                </Stack>

                <Image axie={axie} width={width} />
              </Stack>
            </Stack>
          </MarketplaceLink>

          <Stack sx={{ position: "relative" }}>
            <CardDisplayToggle
              setDisplayV3Cards={setDisplayV3Cards}
              displayV3Cards={displayV3Cards}
              display={cards?.cardsV3 != null || cards?.cardsV2 != null}
            />
            {displayV3Cards ? (
              <Cards cards={cards?.cardsV3} width={width} />
            ) : (
              <CardsV2 cards={cards?.cardsV2} width={width} />
            )}
          </Stack>
        </Stack>

        <Stack gap={(1 / 320) * width}>
          <DecodedGenes axie={axie} showGenes={showGenes} width={width} />

          {(prices?.eth != null ||
            prices?.usd != null ||
            address != null ||
            refreshAxie != null ||
            deleteAxie != null) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Prices prices={prices} width={width} lastSold={lastSold} />
              <Stack
                direction="row"
                alignItems="center"
                gap={(1 / 320) * width}
                sx={{ ml: "auto" }}
              >
                <RoninAddress address={address} width={width} />

                <Stack
                  direction="row"
                  alignItems="center"
                  gap={(0.2 / 320) * width}
                  sx={{ ml: "auto" }}
                >
                  <ToggleIcon
                    id={axie.axieId}
                    updateRequiredBreedingId={updateRequiredBreedingId}
                    requiredId={requiredId}
                  />
                  <RefreshIcon refreshAxie={refreshAxie} id={axie.axieId} />
                  <DeleteIcon deleteAxie={deleteAxie} id={axie.axieId} />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Card>
      {(axie.RuneData != null || axie.charmData != null) && (
        <AxieItems axie={axie} width={width} />
      )}
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  cardsV2: getAllCardsV2(state),
  cardsV3: getAllCardsV3(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(VariantFull);
