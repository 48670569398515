import { Divider } from "@mui/material";
import HorizontalStat from "../atoms/HorizontalStat";
import StatWell from "../atoms/StatWell";

const ArenaPerformance = ({ scholar }) => {
  return (
    <StatWell title="Arena Performance">
      <HorizontalStat label="Stars" amount={scholar.mmr} />
      {/* <HorizontalStat label="SLP/Win" amount={scholar.slpPerWin} /> */}
      <Divider variant="middle" sx={{ margin: "8px 0" }} />
      <HorizontalStat
        label="PVP Win Rate"
        amount={scholar.winRate.pvp ? `${scholar.winRate.pvp}%` : undefined}
        helper="Last 14 days"
      />
    </StatWell>
  );
};

export default ArenaPerformance;
