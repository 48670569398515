import { connect } from "react-redux";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import TeamsList from "./organisms/TeamsList";
import NotableTeams from "./organisms/NotableTeams";
import TeamsChart from "./molecules/TeamsChart";
import { Stack, Button } from "@mui/material";
import { HiPlus } from "react-icons/hi";
import AddTeamDialog from "./organisms/AddTeamDialog";
import { setOpenAddTeamDialog } from "store/guildDashboard/actions/guildDashboardActions";
import Welcome from "./molecules/Welcome";
import PageTitleWithChips from "pages/GuildDashboard/molecules/PageTitleWithChips";
import { RiSwordFill } from "react-icons/ri";
import BattleTeamList from "./organisms/BattleTeamList";
import { useWeb3React } from "@web3-react/core";
import { addTeam } from "store/guildDashboard/actions/guildDashboardActions";
import { GUILD_BATTLE_TEAM_NAME } from "config";

const Teams = ({
  setOpenAddTeamDialog,
  openAddTeamDialog,
  guildStats,
  guild,
  addTeam,
}) => {
  const { account } = useWeb3React();

  const guildBattleTeam = guild.teams.filter(
    (team) => team.name === GUILD_BATTLE_TEAM_NAME
  );

  return guildStats.overallStats.numberOfTeams > 0 ? (
    <>
      <Stack
        gap={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <PageTitleWithChips
          title="Teams"
          chips={[`${guildStats.overallStats.numberOfTeams} Total`]}
        />
        <Stack direction="row" spacing={2}>
          {guildBattleTeam && guildBattleTeam.length > 0 ? (
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<RiSwordFill />}
            >
              Guild Battle team already exists
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<RiSwordFill />}
              disabled
              onClick={() =>
                addTeam(account, guild.guildId, {
                  name: GUILD_BATTLE_TEAM_NAME,
                  isPublic: true,
                })
              }
            >
              {/* add Guild Battle team */}
              Guild Battle Registration is closed
            </Button>
          )}

          <Button
            variant="outlined"
            color="secondary"
            endIcon={<HiPlus />}
            onClick={() => setOpenAddTeamDialog(true)}
          >
            Add Team
          </Button>
        </Stack>

        <AddTeamDialog
          open={openAddTeamDialog}
          setOpen={setOpenAddTeamDialog}
        />
      </Stack>
      <NotableTeams />
      <TeamsChart data={guildStats.overallStats.sortedMMRs} />
      {guildBattleTeam && guildBattleTeam.length > 0 ? (
        <BattleTeamList />
      ) : null}
      <TeamsList />
    </>
  ) : (
    <Welcome />
  );
};

const mapDispatchToProps = {
  setOpenAddTeamDialog,
  addTeam,
};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
  openAddTeamDialog: state.guildDashboard.openAddTeamDialog,
  guild: state.guildDashboard.guild,
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
