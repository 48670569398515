import { Grid, useTheme } from "@mui/material";
import Card from "components/Card/Card";
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  Cell,
} from "recharts";
import WidgetTitle from "../../../atoms/WidgetTitle";
import { isArrayWithContents } from "helpers/display";

const TeamsChart = ({ data }) => {
  const theme = useTheme();

  if (!isArrayWithContents(data)) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <WidgetTitle text="Average Stars by Team" />
          <div style={{ minWidth: 0 }}>
            <ResponsiveContainer width="99%" height={240}>
              <BarChart data={data} margin={{ top: 30, left: -20 }}>
                <YAxis
                  stroke={theme.palette.card.subText}
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                />
                <XAxis
                  dataKey="name"
                  stroke={theme.palette.card.subText}
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                />
                <Bar
                  dataKey="mmr"
                  radius={[10, 10, 0, 0]}
                  label={{ position: "top" }}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={theme.palette.secondary.main}
                      fontSize={14}
                      fontWeight={600}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeamsChart;
