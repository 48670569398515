import { Stack, useTheme } from "@mui/material";
import { isBreedingEventActive } from "helpers/time";
import React from "react";
import Countdown from "../atoms/Countdown";

const CountdownBanner = () => {
  const theme = useTheme();

  if (!isBreedingEventActive()) {
    return null;
  }

  return (
    <Stack
      alignItems="flex-end"
      gap={0.25}
      sx={{
        position: "absolute",
        right: 0,
        top: -10,
        zIndex: 4,
        img: {
          height: 34,
        },
        [theme.breakpoints.down("sm")]: {
          position: "static",
          height: 60,
          alignItems: "center",
        },
      }}
    >
      <img
        src="/images/accents/summer-breeding-event-title.png"
        alt="Summer Breeding Event"
      />
      <Countdown />
    </Stack>
  );
};

export default CountdownBanner;
