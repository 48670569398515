import React from "react";
import TwoColumns from "../atoms/TwoColumns";
import Collection from "../molecules/Collection";

const LoadedView = ({
  runes,
  charms,
  stats,
  collection,
  toggleItem,
  clearItems,
  view,
}) => {
  const collections = [
    {
      title: "Runes",
      totalCollected: stats.totalRunes,
      grandTotal: stats.grandTotalRunes,
      items: runes,
      type: "rune",
    },
    {
      title: "Charms",
      totalCollected: stats.totalCharms,
      grandTotal: stats.grandTotalCharms,
      items: charms,
      type: "charm",
    },
  ];
  return (
    <TwoColumns>
      {collections.map((c, i) => (
        <Collection
          key={`COLLECTION_${i}`}
          title={c.title}
          totalCollected={c.totalCollected}
          grandTotal={c.grandTotal}
          items={c.items}
          type={c.type}
          collection={collection}
          toggleItem={toggleItem}
          clearItems={clearItems}
          view={view}
        />
      ))}
    </TwoColumns>
  );
};

export default LoadedView;
