import React from "react";
import { Typography, useTheme, Stack, Box } from "@mui/material";
import PageTitle from "components/Typography/PageTitle";

const ControlPanel = ({ title, description, children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ p: 1 }}>
      <Stack
        gap={3}
        sx={{
          backgroundColor: theme.palette.card.background,
          boxShadow: theme.palette.card.boxShadow,
          p: 4,
          maxWidth: "1000px",
          margin: "0 auto -40px auto",
          borderRadius: "16px",
          position: "relative",
          top: "-40px",
          [theme.breakpoints.down("sm")]: {
            px: 2,
            pt: 4,
            pb: 2,
          },
        }}
        alignItems="center"
      >
        <Stack>
          <PageTitle
            textAlign="center"
            style={{
              [theme.breakpoints.down("sm")]: {
                fontSize: "30px",
              },
            }}
          >
            {title}
          </PageTitle>
          <Typography
            textAlign="center"
            variant="h2"
            fontSize="16px"
            fontWeight="500"
            color="text.regular"
            sx={{
              maxWidth: "520px",
              margin: "0 auto",
              lineHeight: 1.5,
              [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
              },
            }}
          >
            {description}
          </Typography>
        </Stack>
        {children}
      </Stack>
    </Box>
  );
};

export default ControlPanel;
