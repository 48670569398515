import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import Logo from "./Logo";

const Wrapper = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "center",
}));

const PageHeader = () => {
  return (
    <Wrapper>
      <Logo width={235} />
      <Typography
        color="#ffff"
        variant="h1"
        sx={{
          textTransform: "uppercase",
          fontSize: "2.3rem",
          lineHeight: 1,
          textAlign: "center",
        }}
      >
        Card Explorer
      </Typography>
    </Wrapper>
  );
};

export default PageHeader;
