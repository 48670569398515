import { FaList } from "react-icons/fa";
import Quantity from "../atoms/Quantity";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: 4,
  alignItems: "center",
  flexDirection: "row",
}));

const PopupIcon = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.muted,
  fontSize: 12,

  "&:hover": {
    cursor: "pointer",
  },
}));

const ItemQuantities = ({
  quantity,
  withdrawalAmount,
  isBlockchain,
  handleOpen,
  handleClose,
  anchorEl,
  id,
}) => {
  return (
    <Wrapper>
      <Quantity
        quantity={quantity}
        withdrawalAmount={withdrawalAmount}
        isBlockchain={isBlockchain}
      />
      <PopupIcon
        ref={anchorEl}
        aria-owns={anchorEl ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={handleOpen}
      >
        <FaList />
      </PopupIcon>
    </Wrapper>
  );
};

export default ItemQuantities;
