import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import FailedMessage from "components/FailedMessage/FailedMessage";
import { FaQuestion } from "react-icons/fa";
import DollCard from "components/DoLL/DollCard";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(5),
  alignItems: "center",
}));
const Skills = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(3),
  justifyContent: "center",
  flexWrap: "wrap",
}));

const SkillsContainer = ({ data }) => {
  const width = 200;

  if (data.cards.length === 0 && data.hasFiltered) {
    return (
      <Stack
        spacing={2}
        width="100vw"
        alignItems="center"
        justifyContent="center"
        height="50vh"
      >
        <FaQuestion fontSize="2rem" color="EE649E" />
        <FailedMessage text="Your search returned no cards" />
      </Stack>
    );
  }

  return (
    <Wrapper>
      <Skills>
        {data.cards.map((item) => (
          <DollCard
            key={item.skillId}
            width={width}
            axieClassName={item.axieClassName}
            cardImage={item.image}
            description={item.skillDescription}
            name={item.skillName}
            bodyPart={item.bodyPart}
            levelUp={item.lvlUpText}
          />
        ))}
      </Skills>
    </Wrapper>
  );
};

export default SkillsContainer;
