import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-4-27</Date>
      <Change type="new">
        Guild Dashboard: Added an energy usage chart preview tooltip. To see it,
        hover over a scholar's Energy Usage stat in the Scholar table.
      </Change>
      <Change type="new">
        <Link component={RouterLink} to="/guild-leaderboards">
          Guild SLP Leaderboard
        </Link>
        : We have added normalized average daily SLP, which is normalized by
        scholar energy. The un-normalized average daily SLP stat has been
        renamed "raw".
      </Change>
      <Change type="improved">
        Guild Dashboard: Improved Scholar page chart labels.
      </Change>
    </Entry>
  );
};

export default Changes;
