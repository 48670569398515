import { Stack, useTheme } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import { makePlayerUrl } from "helpers/display";

const PlayerWrapper = ({ children, clientId }) => {
  const theme = useTheme();

  return (
    <CustomLink
      underline="none"
      to={makePlayerUrl(clientId)}
      style={{
        height: "100%",
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.03)",
        },
        [theme.breakpoints.down("lg")]: {
          width: "100%",
          "&:hover": {
            transform: "none",
          },
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        gap={2}
        sx={{
          height: "100%",
          backgroundColor: theme.palette.card.pop1,
          borderRadius: "10px",
          padding: "6px",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            padding: "8px 6px",
          },
          [theme.breakpoints.down("xs")]: {
            alignItems: "center",
            width: "100%",
          },
        }}
      >
        {children}
      </Stack>
    </CustomLink>
  );
};

export default PlayerWrapper;
