import { Stack, Button, Typography } from "@mui/material";
import { HiRefresh } from "react-icons/hi";

const Failed = ({ refetch }: { refetch: () => void }) => {
  return (
    <Stack sx={{ py: 12 }} gap={2} alignItems="center">
      <Typography
        component="span"
        fontSize={16}
        fontWeight={700}
        textAlign="center"
        sx={{
          color: (theme) => theme.palette.colors.origin.wood.text,
          lineHeight: 1.4,
        }}
      >
        Hmm, we can't fetch the leaderboard. <br /> Try again?
      </Typography>
      <Button variant="origin" onClick={refetch} endIcon={<HiRefresh />}>
        Refresh Data
      </Button>
    </Stack>
  );
};

export default Failed;
