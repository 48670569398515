import React from "react";
import FilterAxieClass from "components/Axie/Controls/FilterAxieClass";
import PopupMenu from "pages/GuildDashboard/atoms/PopupMenu";
import FilterBlockchainStatus from "components/Items/FilterBlockchainStatus";
import FilterItemType from "components/Items/FilterItemType";
import { AXIE_CLASSES } from "config";
import styled from "@emotion/styled";
import { IconButton, Stack, useTheme } from "@mui/material";
import FilterSeason from "components/Items/FilterSeason";
import { IoClose } from "react-icons/io5";

const Wrapper = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "24px",
}));

const FiltersMenu = ({
  anchorEl,
  isOpen,
  setAnchorEl,
  controls,
  updateControls,
}) => {
  const theme = useTheme();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <PopupMenu
      title="Filter Inventory"
      id="filter-inventory-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      paperStyles={{
        position: "relative",
        maxWidth: "350px",
        [theme.breakpoints.down("sm")]: {
          px: 3,
          py: 2,
          top: "0 !important",
          left: "0 !important",
          minHeight: "100vh",
          maxWidth: "none",
          width: "100%",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
          },
        }}
      >
        <IoClose />
      </IconButton>
      <Wrapper>
        <FilterItemType
          value={controls.filters.itemTypes}
          setValue={(v) =>
            updateControls("filters", {
              ...controls.filters,
              itemTypes: v,
            })
          }
        />
        <FilterBlockchainStatus
          value={controls.filters.blockchainStatuses}
          setValue={(v) =>
            updateControls("filters", {
              ...controls.filters,
              blockchainStatuses: v,
            })
          }
        />
        <FilterAxieClass
          axieClasses={controls.filters.axieClasses}
          setAxieClasses={(v) =>
            updateControls("filters", { ...controls.filters, axieClasses: v })
          }
          options={["Neutral", ...AXIE_CLASSES]}
        />
        <FilterSeason
          value={controls.filters.seasons}
          setValue={(v) =>
            updateControls("filters", {
              ...controls.filters,
              seasons: v,
            })
          }
        />
      </Wrapper>
    </PopupMenu>
  );
};

export default FiltersMenu;
