import React from "react";
import { useTheme, Stack, Typography } from "@mui/material";

const BannerTitle = ({ children }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        background: theme.palette.text.veryMuted,
        borderRadius: "4px",
        p: "4px",
      }}
    >
      <Typography
        fontSize="14px"
        color="text.bright"
        fontWeight="700"
        textAlign="center"
        sx={{ textTransform: "uppercase" }}
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default BannerTitle;
