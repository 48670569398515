import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "sidebarWidth",
})(({ theme, sidebarWidth }) => ({
  display: "grid",
  gridTemplateColumns: `1fr ${sidebarWidth}px`,
  gap: theme.spacing(4),

  "@media(max-width:1300px)": {
    gridTemplateColumns: "1fr",
    gap: theme.spacing(6),
  },
}));

const TwoColumnLayout = ({ children, sidebarWidth = 330 }) => {
  return <Wrapper sidebarWidth={sidebarWidth}>{children}</Wrapper>;
};

export default TwoColumnLayout;
