import { getPaginationControls, matchesStr } from "helpers/display";
import { createSelector } from "reselect";

const getLeaderboard = (state) => state.doll.leaderboard.data || [];
const getLoading = (state) => state.doll.leaderboard.fetching;
const getControls = (state) => state.doll.leaderboard.controls || {};

const NUM_PLAYERS_PER_PAGE = 100;

export const getLeaderboardData = createSelector(
  [getLeaderboard, getLoading, getControls],
  (leaderboard, loading, controls) => {
    const filteredPlayers = leaderboard.filter((player) =>
      matchesStr(player.username, controls.searchText)
    );

    const { start, end, numberOfPages } = getPaginationControls(
      controls.currentPage,
      NUM_PLAYERS_PER_PAGE,
      filteredPlayers.length
    );

    return {
      top3players: leaderboard.slice(0, 3),
      loading,
      players: filteredPlayers.slice(start, end),
      numberOfPages,
      currentPage: controls.currentPage,
    };
  }
);
