import { Stack } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import { AXIE_DOLL_WEBSITE } from "config";

const logoImages = {
  default: "/images/games/doll/axie-doll-logo.png",
  text: "/images/games/doll/doll-logo-text.png",
  mark: "/images/games/doll/doll-logo-mark.png",
};

const Logo = ({ variant = "default", width = 340 }) => {
  return (
    <CustomLink href={AXIE_DOLL_WEBSITE} underline="none">
      <Stack sx={{ width: "100%", maxWidth: width }} className="logo">
        <img
          src={logoImages[variant] || logoImages.default}
          alt="Axie DoLL logo"
        />
      </Stack>
    </CustomLink>
  );
};

export default Logo;
