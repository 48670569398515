import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import VictoryStars from "components/Leaderboard/molecules/VictoryStars";
import Arrow from "../atoms/Arrow";
import StarChangeAmount from "../atoms/StarChangeAmount";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  background: theme.palette.card.background,
  padding: "8px",
}));

const StarChange = ({ mmrBefore, mmrAfter }) => {
  const change = mmrAfter - mmrBefore;

  if (mmrBefore == null && mmrAfter == null) {
    return <></>;
  }

  return (
    <Wrapper>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <VictoryStars height={14} stars={mmrBefore} />
        <Arrow />
        <VictoryStars height={14} stars={mmrAfter} />
      </Stack>

      <StarChangeAmount change={change} />
    </Wrapper>
  );
};

export default StarChange;
