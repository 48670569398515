import React from "react";
import { IoTrophy } from "react-icons/io5";
import StatWidget from "./StatWidget";

const WidgetMVP = ({ title, highestMMR, arenaMVP }) => {
  let stats = [];
  if (highestMMR) {
    stats.push({
      label: "Arena MVP",
      amount: highestMMR,
      amountSuffix: " Stars",
      subtext: arenaMVP,
    });
  }

  return <StatWidget title={title} icon={<IoTrophy />} stats={stats} />;
};

export default WidgetMVP;
