import { Stack, Box } from "@mui/material";
import Title from "pages/Home/atoms/Title";
import { MdQueryStats } from "react-icons/md";
import CustomLink from "components/CustomLink/CustomLink";

interface Props {
  title: string;
  imageUrl: string;
  imageHref: string;
  imageAltText: string;
}

const Header = ({ title, imageUrl, imageHref, imageAltText }: Props) => {
  return (
    <Stack gap={0.5} alignItems="center" justifyContent="center">
      <Box
        sx={{
          width: "40%",
          "&:hover": { transform: "scale(1.02)", transition: "0.2s all ease" },
        }}
      >
        <CustomLink href={imageHref}>
          <img
            width="100%"
            src={imageUrl}
            alt={imageAltText}
            title={imageAltText}
          />
        </CustomLink>
      </Box>

      <Title>
        <MdQueryStats /> {title}
      </Title>
    </Stack>
  );
};

export default Header;
