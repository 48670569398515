import React, { useState } from "react";
import {
  Stack,
  Box,
  TextField,
  InputAdornment,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import { FaSearch } from "react-icons/fa";

const Search = ({ cancelFetching, setView, failed }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [axieId, setAxieId] = useState("");

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleSearch(e);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    cancelFetching();
    setView(["PROFILE"]);
    if (axieId && axieId !== "") {
      navigate("/inspector/" + axieId);
    }
  };

  const handleSetAxieId = (e) => {
    setAxieId(e.target.value);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        pt: 20,
        pb: 60,
        px: 2,
        position: "relative",
        background: theme.palette.background.gradient,
        [theme.breakpoints.down("md")]: {
          pt: 20,
          pb: 40,
        },
        [theme.breakpoints.down("sm")]: {
          pt: 10,
          pb: 20,
        },
      }}
    >
      <Stack
        gap={3}
        sx={{
          position: "relative",
          zIndex: 2,
          background: theme.palette.card.background,
          py: 4,
          px: 2,
          borderRadius: "32px",
          maxWidth: "560px",
          margin: "0 auto",
          filter: "drop-shadow(0px 0px 4px rgba(0,0,0,.1))",
        }}
      >
        <Stack gap={1}>
          <Stack
            sx={{
              borderRadius: "50%",
              position: "absolute",
              right: 0,
              transform: "translateX(10%) translateY(-72%)",
              img: {
                width: 140,
                margin: "0 auto",
                [theme.breakpoints.down("md")]: {
                  width: 80,
                  mt: -1,
                },
              },
              [theme.breakpoints.down("md")]: {
                position: "static",
                transform: "none",
              },
            }}
          >
            <img
              src={
                theme.palette.mode === "light"
                  ? "/images/axies/axie-1.png"
                  : "/images/axies/axie-1-dark.png"
              }
              alt="Axie"
            />
          </Stack>
          <Typography
            variant="h1"
            color="text.bright"
            fontSize={32}
            textAlign="center"
          >
            Axie Inspector
          </Typography>
          <Typography
            variant="body2"
            fontSize="17px"
            fontWeight="500"
            color={theme.palette.card.subText}
            lineHeight="1.45"
            textAlign="center"
          >
            Enter an Axie's ID to see its full profile, including genes, badges
            and price recommendations.
          </Typography>
        </Stack>
        <form onKeyPress={handleKeypress}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            flexWrap="wrap"
          >
            <TextField
              hiddenLabel
              placeholder="Axie ID"
              variant="outlined"
              spellCheck={false}
              onChange={handleSetAxieId}
              onWheel={(e) => e.target.blur()}
              value={axieId}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaSearch />
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              sx={{
                width: 200,
                [theme.breakpoints.down("sm")]: {
                  width: 180,
                },
              }}
              autoComplete="off"
            />
            <Button
              sx={{ alignSelf: "stretch" }}
              variant="contained"
              size="large"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Stack>
        </form>

        {failed && (
          <Typography
            variant="body1"
            color="text.secondary"
            fontSize={13}
            fontWeight={600}
            textAlign="center"
          >
            Hmmm, we're having trouble finding that axie.
          </Typography>
        )}
      </Stack>

      <Box
        style={{
          backgroundImage: "url(/images/clouds.png)",
          opacity: theme.palette.mode === "light" ? 1 : 0.15,
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: 500,
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      />
    </Stack>
  );
};

export default Search;
