import React from "react";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  useTheme,
} from "@mui/material";

export const NumberOfBreedsRadioGroup = ({
  numberOfBreeds,
  setNumberOfBreeds,
  handleUpdate,
}) => {
  const theme = useTheme();
  const handleChange = (event) => {
    setNumberOfBreeds(event.target.value);
    handleUpdate();
  };

  const options = ["1", "2", "3", "4", "5", "6", "7"];

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="numberOfBreeds"
        name="numberOfBreeds"
        value={numberOfBreeds}
        onChange={handleChange}
        row={true}
        sx={{ justifyContent: "center" }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio />}
            label={option}
            sx={{ color: theme.palette.text.regular }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default NumberOfBreedsRadioGroup;
