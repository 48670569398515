import React from "react";
import { getGuildLogoURL } from "helpers/guild";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "profileImage",
})(({ profileImage, theme }) => ({
  width: 120,
  height: 120,
  borderRadius: "8px",
  ...(!profileImage && {
    background: theme.palette.text.veryMuted,
  }),
  ...(profileImage && {
    backgroundImage: `url("${profileImage}")`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  }),
}));

const GuildLogo = ({ guild }) => {
  return <Wrapper profileImage={getGuildLogoURL(guild.profileImage)} />;
};

export default GuildLogo;
