import styled from "@emotion/styled";
import { Stack, useTheme } from "@mui/material";
import Card from "components/Card/Card";
import PlayerExpanded from "./PlayerExpanded";
import ExpandedBattleHeader from "../molecules/ExpandedBattleHeader";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
  },
}));

const BattleExpanded = ({
  battle,
  playerName,
  fetchAxieData,
  handleClick,
  opponentName,
}) => {
  const theme = useTheme();

  const cardStyles = {
    "&:hover": {
      "& .viewButtons": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      p: "16px 0",
    },
  };

  return (
    <Card style={cardStyles}>
      <Wrapper>
        <ExpandedBattleHeader battle={battle} handleClick={handleClick} />

        <PlayerExpanded
          axies={battle.playerTeam}
          mmrBefore={battle.playerMmrBefore}
          mmrAfter={battle.playerMmrAfter}
          slpWon={battle.playerSlpWonInBattle}
          name={playerName}
          fetchAxieData={fetchAxieData}
        />

        <PlayerExpanded
          axies={battle.opponentTeam}
          mmrBefore={battle.opponentMmrBefore}
          mmrAfter={battle.opponentMmrAfter}
          slpWon={battle.opponentSlpWon}
          name={opponentName}
          fetchAxieData={fetchAxieData}
        />
      </Wrapper>
    </Card>
  );
};

export default BattleExpanded;
