import { Stack } from "@mui/material";
import React from "react";
import RuneTemplateSmall from "components/GameTemplates/RuneTemplateSmall";
import RuneTemplateTiny from "components/GameTemplates/RuneTemplateTiny";
import CharmTemplateSmall from "components/GameTemplates/CharmTemplateSmall";
import CharmTemplateTiny from "components/GameTemplates/CharmTemplateTiny";
import ClickableItem from "../atoms/ClickableItem";

const Items = ({ items, type, collection, toggleItem, view }) => {
  let template;

  const handleToggleItem = (item, itemType) => {
    toggleItem(item, itemType);
  };

  const checkItemIsCollected = (item) => {
    return (
      collection.find((i) => i.id === item.id && i.name === item.name) != null
    );
  };

  if (type === "rune") {
    if (view === "SCREENSHOT") {
      template = <RuneTemplateTiny />;
    } else {
      template = <RuneTemplateSmall />;
    }
  } else if (type === "charm") {
    if (view === "SCREENSHOT") {
      template = <CharmTemplateTiny />;
    } else {
      template = <CharmTemplateSmall />;
    }
  }
  return template == null ? (
    <></>
  ) : (
    <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={1.5}>
      {items != null &&
        items.map((item, i) => (
          <ClickableItem
            key={`ITEM_${type}_${i}`}
            onClick={() => handleToggleItem(item, type)}
            isCollected={checkItemIsCollected(item)}
            tooltip={view === "SCREENSHOT" ? item.name : null}
          >
            {React.cloneElement(template, {
              [type]: item,
              width: view === "SCREENSHOT" ? 50 : 110,
            })}
          </ClickableItem>
        ))}
    </Stack>
  );
};

export default Items;
