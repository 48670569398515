import { Stack, useTheme } from "@mui/material";
import Label from "pages/GuildDashboard/atoms/Label";
import SectionTitle from "pages/GuildDashboard/atoms/SectionTitle";

const VerticalStat = ({ label, title, children }) => {
  const theme = useTheme();

  return (
    <Stack gap={0.5}>
      {label && (
        <Label color={theme.palette.text.veryMuted} fontSize={11}>
          {label}
        </Label>
      )}
      {title && <SectionTitle>{title}</SectionTitle>}
      {children}
    </Stack>
  );
};

export default VerticalStat;
