import React from "react";
import { Stack, useTheme, Box, Tooltip } from "@mui/material";
import CardTemplate from "./CardTemplate";
import CardTemplateV2 from "./V2/CardTemplateV2";
import { v2v3Compare, v3v2Compare } from "icons/icons";

const CardCompareIcon = ({ card, display, version }) => {
  const theme = useTheme();
  return card == null || !display ? null : (
    <Tooltip
      placement="right"
      title={
        version === "V3" ? (
          <WithDropShadow>
            <CardTemplate card={card} width={160} />
          </WithDropShadow>
        ) : (
          <WithDropShadow>
            <CardTemplateV2 card={card} width={170} />
          </WithDropShadow>
        )
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
            boxShadow: "none",
            border: "none",
          },
        },
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 5,
          background: theme.palette.colors.white,
          boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
          borderRadius: "4px",
          p: "6px 8px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        {version === "V3"
          ? v2v3Compare(theme.palette.colors.black)
          : v3v2Compare(theme.palette.colors.black)}
      </Stack>
    </Tooltip>
  );
};

const WithDropShadow = ({ children }) => (
  <Box sx={{ filter: "drop-shadow(0px 0px 2px #000)" }}>{children}</Box>
);

export default CardCompareIcon;
