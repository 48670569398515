import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-9-16</Date>
      <Change type="improved">
        Added favorite axies, runes and charms to the{" "}
        <CustomLink to="/origins-leaderboard" endSpace={false}>
          Origins Leaderboard
        </CustomLink>
        . Also, re-enabled profile pages for top players - just click on any
        player in the leaderboard to check out their favorite axies, victory
        star history and battle log!
      </Change>
    </Entry>
  );
};

export default Changes;
