import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import DollCard from "components/DoLL/DollCard";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";

const CardsWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  flexWrap: "wrap",
}));

const Cards = ({ cards }) => {
  return (
    <Stack gap={4}>
      <SectionLabel>Cards</SectionLabel>
      <CardsWrapper>
        {cards.map((card) => (
          <DollCard
            key={card.skillId}
            width={200}
            axieClassName={card.axieClassName}
            cardImage={card.image}
            description={card.skillDescription}
            name={card.skillName}
            bodyPart={card.bodyPart}
            levelUp={card.lvlUpText}
          />
        ))}
      </CardsWrapper>
    </Stack>
  );
};

export default Cards;
