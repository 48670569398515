import React from "react";
import { Stack, Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { PART_ORDER } from "pages/TeamBuilder";
import CharmSlot from "pages/TeamBuilder/atoms/CharmSlot";
import CardSlot from "pages/TeamBuilder/atoms/CardSlot";
import Buttons from "./Buttons";

const Main = ({
  axie,
  updateCard,
  updateCharm,
  clearAxie,
  view,
  handleOpenCharmDialog,
  handleOpenCardDialog,
}) => {
  const midSized = useMediaQuery("(max-width:1440px)");

  return (
    <Stack justifyContent="space-between" gap={4}>
      <Buttons axie={axie} clearAxie={clearAxie} />

      <Box
        sx={{
          display: midSized ? "flex" : "grid",
          gridTemplateColumns: "repeat(6,1fr)",
          gridGap: 24,
          gap: 3,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {PART_ORDER.map((part, i) => {
          const cardData = axie.parts[part].card;
          const cardClass = cardData == null ? undefined : cardData.class;

          return (
            <Stack gap={3} key={`CARD_${i}`} alignItems="flex-end">
              <CardSlot
                axie={axie}
                data={axie.parts[part].card}
                part={part}
                updateCard={updateCard}
                updateCharm={updateCharm}
                view={view}
                handleOpenCardDialog={handleOpenCardDialog}
              />
              <CharmSlot
                axie={axie}
                data={axie.parts[part].charm}
                part={part}
                updateCharm={updateCharm}
                isCardSet={cardData != null}
                cardClass={cardClass}
                view={view}
                handleOpenCharmDialog={handleOpenCharmDialog}
              />
            </Stack>
          );
        })}
      </Box>
    </Stack>
  );
};

export default Main;
