import { Typography } from "@mui/material";

interface Props {
  name: string;
}

const Name = ({ name }: Props) => {
  return (
    <Typography
      variant="h1"
      fontSize={{ xs: 22, sm: 22, md: 30 }}
      color="text.bright"
      sx={{ lineHeight: 1.2 }}
    >
      {name}
    </Typography>
  );
};

export default Name;
