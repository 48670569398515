import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-10-24</Date>
      <Change type="new">
        Axie DoLL: Added a new
        <CustomLink to="/doll/card-explorer">DoLL Card Explorer</CustomLink>
        page!
      </Change>
      <Change type="new">
        Axie DoLL: Added a new
        <CustomLink to="/doll/sidekicks">DoLL Sidekick Explorer</CustomLink>
        page!
      </Change>
      <Change type="improved">
        Improved the controls and added summary statistics to our
        <CustomLink to="/charts" endSpace={false}>
          SLP Issuance Chart
        </CustomLink>
        .
      </Change>
    </Entry>
  );
};

export default Changes;
