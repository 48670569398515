import { useState } from "react";
import { Stack, styled } from "@mui/material";
import Player from "../molecules/Player";
import ToggleDetailedView from "../molecules/ToggleDetailedView";
import TopRunes from "./TopRunes";
import Controls from "pages/Leaderboards/Origin/molecules/Controls";
import NoPlayers from "../atoms/NoPlayers";
import { LeaderboardPlayerAugmented } from "types";
import { OriginsLeaderboardControls } from "store/leaderboard/origin/leaderboardReducer";
import { UpdateControlsActionCreator } from "store/leaderboard/origin/leaderboardActions";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  width: 820,
  minHeight: 400,
  margin: "0 auto",
  position: "relative",
  zIndex: 2,
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

interface Props {
  data: {
    players: LeaderboardPlayerAugmented[];
    numPlayers: number;
    topRunes: any[];
    numTopRunes: number;
  };
  controls: OriginsLeaderboardControls;
  updateControls: UpdateControlsActionCreator;
}

const Leaderboard = ({ data, controls, updateControls }: Props) => {
  const [showItems, setShowItems] = useState(true);

  return (
    <Stack gap={6} sx={{ width: "100%" }}>
      <Stack
        gap={4}
        alignItems="center"
        sx={{ position: "relative", zIndex: 2 }}
      >
        <Controls />
        <TopRunes
          topRunes={data.topRunes}
          numPlayers={data.numPlayers}
          numTopRunes={data.numTopRunes}
          controls={controls}
          updateControls={updateControls}
        />
        <ToggleDetailedView showItems={showItems} setShowItems={setShowItems} />
      </Stack>
      <Wrapper>
        {data.players.length > 0 ? (
          data.players.map((p) => (
            <Player
              key={p.clientId}
              player={p}
              showRune={showItems}
              showCharms={showItems}
            />
          ))
        ) : (
          <NoPlayers numPlayers={data.numPlayers} />
        )}
      </Wrapper>
    </Stack>
  );
};

export default Leaderboard;
