import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Row from "../atoms/Row";
import CustomLink from "components/CustomLink/CustomLink";

const OuterWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isTop3",
})(({ theme, isTop3 }) => ({
  transition: "0.2s background ease",
  borderRadius: "4px",
  "&:nth-of-type(2n)": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.colors.purple[600]
        : "#27253b",
  },
  "&:hover": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.colors.purple[500]
        : "#3b3854",
  },
  ...(isTop3 && {
    background: "linear-gradient(45deg, #6314ae, #e86b5a) !important",
    margin: "2px 0",
    padding: "3px",
  }),
}));

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["isTop3", "grid"].includes(prop),
})(({ theme, grid, isTop3 }) => ({
  display: "grid",
  alignItems: "center",
  padding: "14px 8px",
  gridGap: "8px",
  gridTemplateColumns: grid || "1fr",

  img: {
    borderRadius: "50%",
    height: 32,
    width: 32,
    margin: "0 auto",
    display: "block",
  },
  ...(isTop3 && {
    background: theme.palette.card.background,
    borderRadius: "2px",
  }),
}));

const Rows = ({ data, specs }) => {
  const rowLink = (itemSlug) =>
    specs.baseURL ? `${specs.baseURL}/${itemSlug}` : undefined;
  return data.map((row, i) => {
    const isTop3 = row.topRank ? row.topRank < 4 : i < 3;
    return (
      <OuterWrapper isTop3={isTop3} key={`ROW_${i}`}>
        <CustomLink to={rowLink(row[specs.itemSlug])}>
          <Wrapper isTop3={isTop3} grid={specs.grid}>
            <Row row={row} details={specs.columns} rank={i + 1} />
          </Wrapper>
        </CustomLink>
      </OuterWrapper>
    );
  });
};

export default Rows;
