import React from "react";
import IdsAdded from "./IdsAdded";
import AddIds from "./AddIds";
import { Stack } from "@mui/material";
import EventBanner from "../atoms/EventBanner";
import Controls from "./Controls";
import SidebarStructure from "../atoms/SidebarStructure";

const Sidebar = ({ setOpenSelectAxiesDialog, handleDeleteId }) => {
  return (
    <Stack gap={2}>
      <AddIds setOpenSelectAxiesDialog={setOpenSelectAxiesDialog} />

      <SidebarStructure>
        <IdsAdded handleDeleteId={handleDeleteId} />
        <Controls />
        <EventBanner />
      </SidebarStructure>
    </Stack>
  );
};

export default Sidebar;
