import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import BoldText from "../atoms/BoldText";

const CollectedOutOf = ({ total, outOf }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      gap={0.5}
      sx={{ "& > *": { color: theme.palette.colors.origin.wood.background } }}
    >
      <BoldText>{total}</BoldText>
      <Typography variant="span" fontSize={16}>
        out of
      </Typography>
      <BoldText>{outOf}</BoldText>
    </Stack>
  );
};

export default CollectedOutOf;
