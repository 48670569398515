import * as types from "./leaderboardActionTypes";

export const fetchGuildBattleMMRLeaderboard = () => ({
  type: types.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD,
});

export const fetchGuildBattleMMRLeaderboardStarted = () => ({
  type: types.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD_STARTED,
});

export const fetchGuildBattleMMRLeaderboardFailed = () => ({
  type: types.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD_FAILED,
});

export const fetchGuildBattleMMRLeaderboardSucceeded = (data) => ({
  type: types.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD_SUCCEEDED,
  data,
});
