import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";

const SLPWon = ({ amount, size, style }) => {
  const theme = useTheme();

  if (!amount || amount <= 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      sx={{
        background: theme.palette.game.slp,
        borderRadius: "8px",
        p: size === "small" ? "4px 6px" : "10px 12px",
        "& img": {
          height: size === "small " ? "22px" : "18px",
        },
        ...style,
      }}
    >
      <img src="/images/icons/slp.png" alt="SLP Icon" />
      <Typography
        variant="span"
        fontSize={size === "small" ? "14px" : "18px"}
        color="text.regular"
        fontWeight="700"
      >
        {`x ${amount}`}
      </Typography>
    </Stack>
  );
};

export default SLPWon;
