import React from "react";
import { MenuItem, TextField } from "@mui/material";

const Sort = ({ sort, updateSort, sortOptions }) => {
  return sortOptions == null ? null : (
    <TextField
      select
      value={sort}
      onChange={updateSort}
      size="small"
      variant="outlined"
      sx={{
        "& .MuiOutlinedInput-root": {
          height: 47,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: (theme) =>
            `2px solid ${theme.palette.input.outlined.borderColor}`,
        },
        "& .MuiOutlinedInput-input": {
          fontSize: "15px",
        },
      }}
    >
      {sortOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ fontSize: 16, fontWeight: 500 }}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Sort;
