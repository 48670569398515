import { AXIE_INFINITY_WEBSITE } from "config";
import moment from "moment";

export const formatUSD = (amount, maximumSignificantDigits) => {
  if (amount > 100000000) {
    return new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "usd",
      maximumSignificantDigits,
      notation: "scientific",
    }).format(amount);
  }
  return new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "usd",
    maximumSignificantDigits,
  }).format(amount);
};

export const formatEthPrice = (price) => {
  if (price * Math.pow(10, -18) > 10000) {
    return (
      Math.round((price * Math.pow(10, -18) + Number.EPSILON) * 10000) / 10000
    ).toPrecision(5);
  }
  return (
    Math.round((price * Math.pow(10, -18) + Number.EPSILON) * 10000) / 10000
  );
};

export const roundToNumDecimals = (num, n = 3) => {
  return Math.round((num + Number.EPSILON) * 10 ** n) / 10 ** n;
};

export const formatTimestampAsDate = (timestamp) => {
  const miliseconds = timestamp * 1000;
  const newDate = new Date(miliseconds);
  const config = { year: "numeric", month: "short", day: "numeric" };
  return newDate.toLocaleDateString("en-US", config);
};

export const formatTimestampAsDateTime = (timestamp, options) => {
  const miliseconds = timestamp * 1000;
  const newDate = new Date(miliseconds);
  if (options) {
    return newDate.toLocaleString([], options);
  }
  return newDate.toLocaleString();
};

export const displayPercent = (x) => {
  return Math.round(x * 100 * 1000) / 1000 + "%";
};

export const displayPercentRounded = (x, digits = 10) => {
  return Math.round(x * 100 * digits) / digits + "%";
};

export const getDateString = (date) => {
  const year = date.getFullYear();
  const monthWithOffset = date.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const day =
    date.getUTCDate().toString().length < 2
      ? `0${date.getUTCDate()}`
      : date.getUTCDate();
  return `${year}-${month}-${day}`;
};

export const getSortedPairArray = (arr) => {
  return parseInt(arr[0]) < parseInt(arr[1])
    ? [arr[0], arr[1]]
    : [arr[1], arr[0]];
};

export const getSortedPairString = (ids) => getSortedPairArray(ids).join("-");

export const getRandomIntFromInclusiveInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const makeRoninExplorerURLFromAddress = (address) => {
  return `https://explorer.roninchain.com/address/${address}`;
};

export const makeRoninExplorerTXNURLFromHash = (hash) => {
  return `https://explorer.roninchain.com/tx/${hash}`;
};

export const displayShortenedRoninAddress = (roninAddress, stem = "") => {
  if (roninAddress == null) {
    return "";
  }
  if (roninAddress.startsWith("ronin:")) {
    return stem + roninAddress.slice(0, 9) + "..." + roninAddress.slice(-4);
  }
  return stem + roninAddress.slice(2, 5) + "..." + roninAddress.slice(-4);
};

export const displayShortenedTXNHash = (hash) => {
  return hash.slice(0, 6) + "..." + hash.slice(-5);
};

export const makeMarketplaceURLFromRoninAddress = (
  roninAddress,
  destinationTab
) => {
  const parsedAddress = roninAddress.replace("0x", "ronin:");

  if (destinationTab) {
    return `${AXIE_INFINITY_WEBSITE}/profile/${parsedAddress}/${destinationTab}`;
  }
  return `${AXIE_INFINITY_WEBSITE}/profile/${parsedAddress}`;
};

export const makeEtherscanUrlFromEthAddress = (ethAddress) => {
  return `https://etherscan.io/address/${ethAddress}`;
};

export const getOrdinalSuffix = (n) => {
  if (n == null) {
    return undefined;
  }
  const j = n % 10;
  const k = n % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

export const addOrdinalSuffix = (n) => {
  if (n == null) {
    return undefined;
  }
  const suffix = getOrdinalSuffix(n);
  return n + suffix;
};

export const getSLPValueInFiat = (
  slp,
  currencyPrices,
  baseCurrency,
  formatter
) => {
  if (
    currencyPrices &&
    "smooth-love-potion" in currencyPrices &&
    [baseCurrency] in currencyPrices["smooth-love-potion"]
  ) {
    return formatter.format(
      slp * currencyPrices["smooth-love-potion"][baseCurrency]
    );
  }
  return undefined;
};

export const toggleTextWithDelay = (
  setText,
  defaultText,
  updatedText,
  delay
) => {
  setText(updatedText);
  setTimeout(() => {
    setText(defaultText);
  }, delay);
};

export const getPaginationStartIndex = (currentPage, numPerPage) =>
  (currentPage - 1) * numPerPage;
export const getPaginationEndIndex = (start, numPerPage) => start + numPerPage;

export const makePlayerUrl = (id) => `/player/${id}`;

export const reverseKeyValue = (obj) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));

export const pluralize = (text, num) => {
  if (num === 1) {
    return `${num} ${text}`;
  } else return `${num} ${text}s`;
};

export const getPaginationControls = (currentPage, numPerPage, total) => {
  const start = getPaginationStartIndex(currentPage, numPerPage);
  const end = getPaginationEndIndex(start, numPerPage);
  const numberOfPages = total ? Math.ceil(total / numPerPage) : 1;

  return {
    start,
    end,
    numberOfPages,
  };
};

export const matchesStr = (str, toMatch, matchCase = false) => {
  if (!toMatch) {
    return true;
  }
  if (!str || typeof str !== "string" || typeof toMatch !== "string") {
    return false;
  }
  if (matchCase) {
    return str.includes(toMatch);
  }
  return str.toLowerCase().includes(toMatch.toLowerCase());
};

export const strIsInArray = (str, arr, matchCase = false) => {
  if (!arr || !Array.isArray(arr)) {
    return true;
  }

  const strType = typeof str;

  if (!str || (strType !== "string" && strType !== "number")) {
    return false;
  }

  let parsedStr = str;
  if (strType === "number") {
    parsedStr = parsedStr.toString();
  }

  if (matchCase) {
    return arr.includes(parsedStr) || arr.length === 0;
  }
  const arrLowerCase = arr.map((a) => a.toLowerCase());

  return (
    arrLowerCase.includes(parsedStr.toLowerCase()) || arrLowerCase.length === 0
  );
};

export const matchesTagsOrText = (tags, text, arr) => {
  if (!arr || !Array.isArray(arr)) {
    return true;
  }

  return (
    arr.some((el) => (tags || "").split(",").includes(el)) ||
    arr.some((el) => (text || "").toLowerCase().includes("<" + el + ">")) ||
    arr.length === 0
  );
};

export const atLeastOneMatchInArray = (current, target) => {
  return current.some((el) => target.includes(el)) || target.length === 0;
};

export const sortByKey = (arr, key, asc = true) => {
  if (!Array.isArray(arr)) return null;

  if (key == null) return arr;

  if (asc) {
    return [...arr].sort((a, b) => {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    });
  }
  return [...arr].sort((a, b) => {
    return a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;
  });
};

export const sortByName = (arr, asc = true) => {
  if (asc) {
    return arr.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }
  return arr.sort((a, b) => (a.name < b.name ? 1 : a.name > b.name ? -1 : 0));
};

export const matchesDateRange = (data, start, end) => {
  if (start == null || end == null) {
    return data;
  }
  return data.filter(
    (el) =>
      moment(el.date).unix() >= moment(start).unix() &&
      moment(el.date).unix() <= moment(end).unix()
  );
};

export const isStringWithContents = (val) => {
  if (val != null && val !== "") return true;
  return false;
};

export const isArrayWithContents = (val) => {
  if (val != null && Array.isArray(val) && val.length > 0) return true;
  return false;
};

export const capitalizeWord = (word = "") => {
  if (typeof word !== "string" || word.length === 0) return "";
  return word[0].toUpperCase() + word.substring(1);
};
