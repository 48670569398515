import React from "react";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import { Stack, useTheme } from "@mui/material";

const EmptyCard = ({ styles }) => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        background: theme.palette.card.background,
        border: `3px dashed ${theme.palette.card.divider}`,
        width: 204,
        borderRadius: "20px",
        color: theme.palette.card.divider,
        fontSize: 40,
        alignSelf: "center",
        height: 269,
      }}
    >
      <MdOutlineRemoveCircleOutline />
    </Stack>
  );
};

export default EmptyCard;
