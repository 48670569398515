import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-9-26</Date>
      <Change type="new">
        Added a new page for the
        <CustomLink to="/doll/leaderboard" endSpace={false}>
          AxieDoLL Leaderboard
        </CustomLink>
        !
      </Change>
      <Change type="improved">
        You can now search by name and filter for runes and charms in the{" "}
        <CustomLink to="/origins-leaderboard" endSpace={false}>
          Origins Leaderboard
        </CustomLink>
        .
      </Change>
    </Entry>
  );
};

export default Changes;
