import styled from "@emotion/styled";
import { Box, Typography, useTheme } from "@mui/material";
import { GiBroadsword } from "react-icons/gi";
import InterDungeonTooltip from "components/InterDungeon/InterDungeonCard/atoms/InterDungeonTooltip";

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "width" })(
  ({ width = 200 }) => ({
    display: "flex",
    width: `${(width / 200) * 30}px`,
    minWidth: `${(width / 200) * 30}px`,
    height: `${(width / 200) * 24}px`,
    minHeight: `${(width / 200) * 24}px`,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: `${(width / 200) * 2}px`,
  })
);

const OpposingValue = ({ opposingValue, width }) => {
  const theme = useTheme();
  return (
    <InterDungeonTooltip
      title={
        <>
          <GiBroadsword style={{ transform: "rotate(-45deg)" }} />
          <br />
          {`Reduces opponent's Battle Score by ${opposingValue}`}
        </>
      }
    >
      <Wrapper
        sx={{
          background: theme.palette.colors.interDungeon.cardElements.lightBg,
        }}
        width={width}
      >
        <Typography
          variant="span"
          sx={{
            color: theme.palette.colors.interDungeon.cardElements.textRed,
            fontFamily: "roboto",
            fontSize: (width / 200) * 10,
          }}
        ></Typography>
        <Typography
          variant="span"
          sx={{
            color: theme.palette.colors.interDungeon.cardElements.textRed,
            fontFamily: "roboto",
            fontSize: (width / 200) * 16,
            fontWeight: 700,
          }}
        >
          {opposingValue}
        </Typography>
      </Wrapper>
    </InterDungeonTooltip>
  );
};

export default OpposingValue;
