import React from "react";
import { Stack, useTheme, Tooltip } from "@mui/material";

const NotClickableSlot = ({ width, height }) => {
  const theme = useTheme();
  return (
    <Tooltip title="First, select the the Card.">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          p: "3px",
          background: theme.palette.card.pop1,
          borderRadius: 2,
          width: width,
          minHeight: height,
          userSelect: "none",
        }}
      />
    </Tooltip>
  );
};

export default NotClickableSlot;
