import { Stack } from "@mui/material";
import styled from "@emotion/styled";

export const DropdownWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["alignRight", "colorOverrides", "twoColumn"].includes(prop),
})(({ theme, alignRight, colorOverrides, twoColumn }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  backgroundColor:
    colorOverrides?.navigation?.dropdown?.background ||
    theme.palette.navigation.dropdownBackground,
  boxShadow: theme.palette.navigation.boxShadow,
  border: `1px solid ${
    colorOverrides?.navigation?.dropdown?.border ||
    theme.palette.navigation.border
  }`,
  alignItems: "flex-start",
  borderRadius: theme.spacing(2),
  flexDirection: "column",
  position: "absolute",
  zIndex: 2,
  top: "100%",
  left: "-16px",

  "&:before": {
    content: '""',
    position: "absolute",
    borderColor: "rgba(194, 225, 245, 0)",
    border: "solid transparent",
    borderWidth: "11px",
    left: "22px",
    top: "-21px",
    zIndex: 1,
    borderBottomColor: `${
      colorOverrides?.navigation?.dropdown.background ||
      theme.palette.navigation.dropdownBackground
    } !important`,
  },

  "&:after": {
    content: '""',
    position: "absolute",
    left: "23px",
    top: "-21px",
    width: "0",
    height: "0",
    border: "solid transparent",
    borderWidth: "10px",
    zIndex: 0,
    borderBottomColor: `${
      colorOverrides?.navigation?.dropdown?.border ||
      theme.palette.navigation.border
    } !important`,
  },

  ...(alignRight && {
    right: -16,
    left: "initial",
    alignItems: "flex-end",
    "&::before": {
      left: "initial",
      right: "20px",
    },
    "&::after": {
      left: "initial",
      right: "17px",
    },
  }),

  ...(twoColumn && {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "24px",
  }),
}));
