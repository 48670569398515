import { Grid } from "@mui/material";
import ScholarInfo from "../molecules/ScholarInfo";
import Ranking from "../molecules/ScholarRankings";
import ScholarAddresses from "../molecules/ScholarAddresses";
import InventoryBar from "./InventoryBar";

const TopSection = ({ scholar }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={7} lg={6}>
        <ScholarInfo scholar={scholar} />
      </Grid>

      <Grid item xs={12} sm={12} md={5} lg={3}>
        <ScholarAddresses
          roninAddress={scholar.roninAddress}
          payoutAddress={scholar.payoutAddress}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Ranking scholar={scholar} />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <InventoryBar scholar={scholar} />
      </Grid>
    </Grid>
  );
};

export default TopSection;
