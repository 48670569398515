import { spawn } from "redux-saga/effects";
import axieCardsSaga from "./axieCardsSaga";
import axieDataSaga from "./axieDataSaga";
import playerDataSaga from "./playerDataSaga";

export default function* dataSaga() {
  yield spawn(axieCardsSaga);
  yield spawn(playerDataSaga);
  yield spawn(axieDataSaga);
}
