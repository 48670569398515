import { connect } from "react-redux";
import Header from "./organisms/Header";
import { updateInventoryControls } from "store/guildDashboard/actions/inventoryActions";
import Items from "./organisms/Items";
import { getInventoryData } from "store/guildDashboard/selectors/inventory";
import { Stack } from "@mui/material";

const Inventory = ({ data, updateControls }) => {
  return (
    <Stack gap={4}>
      <Header
        title={data.title}
        controls={data.controls}
        updateControls={updateControls}
      />
      <Items items={data.items} />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateControls: updateInventoryControls,
};

const mapStateToProps = (state) => ({
  data: getInventoryData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
