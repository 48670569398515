import React from "react";
import { Stack } from "@mui/material";
import ChartTitle from "../atoms/ChartTitle";
import styled from "@emotion/styled";
import Footnote from "../atoms/Footnote";

const Controls = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(4),
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ChartHeader = ({ title, children, footnote }) => {
  return (
    <Stack
      gap={{ xs: 3, sm: 3, md: 3, lg: 4 }}
      direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
      justifyContent={{ xs: "center", sm: "center", md: "space-between" }}
      alignItems="center"
      textAlign={{ xs: "center", sm: "center", md: "left" }}
      flexWrap="wrap"
    >
      <Stack gap={1} alignItems="flex-start">
        <ChartTitle>{title}</ChartTitle>
        {footnote != null && <Footnote text={footnote} />}
      </Stack>
      <Controls>{children}</Controls>
    </Stack>
  );
};

export default ChartHeader;
