import React from "react";
import { connect } from "react-redux";
import { Stack, useTheme } from "@mui/material";
import Controls from "./Controls";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import PageTitleWithChips from "pages/GuildDashboard/molecules/PageTitleWithChips";

const Header = ({ guildStats }) => {
  const theme = useTheme();
  const axieTotals = [
    `${guildStats.overallStats.numberOfAxies} Total`,
    `${
      guildStats.overallStats.numberOfAxies -
      guildStats.overallStats.numberOfEggs
    } Adults`,
    `${guildStats.overallStats.numberOfEggs} Eggs`,
  ];
  return (
    <Stack
      gap={2}
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <PageTitleWithChips title="Axies" chips={axieTotals} />
      <Controls />
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
