import * as types from "./inspectorActionTypes";
import {
  put,
  takeEvery,
  retry,
  select,
  call,
  fork,
  take,
} from "redux-saga/effects";
import { countNumberOfMysticParts } from "helpers/axie";
import api from "services/api";
import * as actions from "./inspectorActions";
import { channel } from "redux-saga";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* fetchMysticFloor(action) {
  try {
    yield put(actions.fetchMysticFloorStarted());

    const mysticFloorVariables = {
      auctionType: "Sale",
      numMystic: countNumberOfMysticParts(action.axie),
      title: ["Origin"],
      from: 0,
      size: 9,
      sort: "PriceAsc",
    };

    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestSearchAxies,
      mysticFloorVariables
    );

    yield put(actions.fetchMysticFloorSucceeded(data));

    const axieData = yield select((state) => state.inspector.axieData);

    for (const idx in data.axies) {
      if (!(data.axies[idx].axieId in axieData)) {
        yield put(actions.fetchMysticAxieDetail(data.axies[idx].axieId));
      }
    }
  } catch (e) {
    yield put(actions.fetchMysticFloorFailed());
  }
}

export function* watchFetchAxieData() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchAxieDataRequest, chan);
  }

  try {
    while (true) {
      const { axieId } = yield take(types.INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL);
      yield put(chan, axieId);
    }
  } catch (e) {}
}

export function* makeFetchAxieDataRequest(chan) {
  try {
    while (true) {
      const axieId = yield take(chan);
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        axieId
      );
      yield put(actions.fetchMysticAxieDetailSucceeded(data));
    }
  } catch (e) {}
}

export default function* mysticFloorSaga() {
  yield fork(watchFetchAxieData);
  yield takeEvery(types.INSPECTOR_FETCH_MYSTIC_FLOOR, fetchMysticFloor);
}
