import { useEffect } from "react";
import styles from "pages/Home/home.module.scss";
import EmailSignup from "components/EmailSignup/EmailSignup";
import { fetchRecentlyListed } from "store/recently/recentlyActions";
import { fetchDollLeaderboard } from "store/doll/leaderboard/actions";
import { fetchGuildMMRLeaderboard } from "store/leaderboard/guild/leaderboardActions";
import { getOriginLeaderboardData } from "store/leaderboard/origin/leaderboardSelectors";
import { getLeaderboardData } from "store/doll/leaderboard/selectors";
import { getGuildLeaderboardData } from "store/leaderboard/guild/guildLeaderboardSelector";
import { connect } from "react-redux";
import { Container, useTheme, Box, Grid, Stack } from "@mui/material";
import QuickLinks from "./molecules/QuickLinks";
import Leaderboards from "./organisms/Leaderboards";
import OriginsWidget from "./organisms/OriginsWidget";
import DoLLWidget from "./organisms/DoLLWidget";
import DiscoverGamesWidget from "./organisms/DiscoverGamesWidget";
import RecentlyListed from "./organisms/RecentlyListed";
import GuildShowcaseBanner from "pages/GuildMembership/GuildShowcaseBanner";
import { BiMedal } from "react-icons/bi";
import CustomLoader from "components/CustomLoader/CustomLoader";
import TriballyBadge from "components/TriballyBadge/TriballyBadge";
import { TRIBALLY_PLATFORM_URL, TRIBALLY_TAGLINE } from "config";
import CustomLink from "components/CustomLink/CustomLink";

const Home = ({
  guildLeaderboard,
  fetchGuildMMRLeaderboard,
  fetchRecentlyListed,
  fetchLeaderboard,
  axies,
  fetchingAxies,
  failedAxies,
  originData,
  dollLeaderboard,
  guildShowcaseData,
  guildLeaderboardLoading,
}) => {
  const theme = useTheme();

  useEffect(() => {
    fetchGuildMMRLeaderboard();
    fetchRecentlyListed();
    fetchLeaderboard();
  }, [fetchGuildMMRLeaderboard, fetchRecentlyListed, fetchLeaderboard]);

  return (
    <div className={styles.home}>
      <Stack
        sx={{
          paddingBottom: 8,
          [theme.breakpoints.down("lg")]: {
            paddingBottom: 6,
          },
          [theme.breakpoints.down("md")]: {
            paddingBottom: 4,
          },
          backgroundPosition: "bottom center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            theme.palette.mode === "light"
              ? "url(/images/homepage-graphics/Clouds-Bg-Light-Small.png)"
              : "url(/images/homepage-graphics/Clouds-Bg-Dark-Small.png)",
        }}
      >
        <TriballyBadge
          width={200}
          link={TRIBALLY_PLATFORM_URL}
          title={TRIBALLY_TAGLINE}
          alt={TRIBALLY_TAGLINE}
          image={
            theme.palette.mode === "light"
              ? `/images/tribally-assets/light-mode.png`
              : `/images/tribally-assets/dark-mode.png`
          }
          styles={{
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
            position: "absolute",
            right: 7,
            paddingTop: 1,
            zIndex: 10,
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        />

        <Container fixed>
          <Box sx={{ py: 4, position: "relative" }}>
            <img
              src={
                theme.palette.mode === "light"
                  ? "/images/welcome-banner-light.jpg"
                  : "/images/welcome-banner-dark.jpg"
              }
              alt="Welcome to Axie Tech"
              className={styles.bannerImage}
            />
          </Box>
        </Container>

        <QuickLinks />
        <Container
          fixed
          sx={{
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        >
          <CustomLink
            style={{
              py: 4,
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
            href={TRIBALLY_PLATFORM_URL}
            target="_blank"
          >
            <img
              src="/images/tribally-banner-assets/backgrounds/tribally-banner.jpg"
              alt="Join us at tribally.games"
              className={styles.bannerImage}
            />
          </CustomLink>
        </Container>

        <Container sx={{ paddingTop: 4 }} fixed>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7}>
              <OriginsWidget
                topRunes={originData.topRunes}
                numPlayers={originData.numPlayers}
                numTopRunes={originData.numTopRunes}
                top3Players={originData.top3Players}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <DoLLWidget
                loading={dollLeaderboard.loading}
                topPlayers={dollLeaderboard.top3players}
                fetchDollLeaderboard={fetchLeaderboard}
              />
            </Grid>
          </Grid>
        </Container>
      </Stack>
      <Stack
        gap={10}
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingTop: 12,
          paddingBottom: 12,
          [theme.breakpoints.down("lg")]: {
            paddingTop: 6,
            paddingBottom: 6,
          },
          [theme.breakpoints.down("sm")]: {
            paddingTop: 4,
            paddingBottom: 4,
          },
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            theme.palette.mode === "light"
              ? "url(/images/accents/forest-light.jpg)"
              : "url(/images/accents/forest-dark.jpg)",
        }}
      >
        <DiscoverGamesWidget />
      </Stack>

      <RecentlyListed
        fetchRecentlyListed={fetchRecentlyListed}
        axies={axies.slice(0, 5)}
        failedAxies={failedAxies}
        fetchingAxies={fetchingAxies}
      />

      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingTop: 12,
          paddingBottom: 12,
          [theme.breakpoints.down("lg")]: {
            paddingTop: 6,
            paddingBottom: 6,
          },
          [theme.breakpoints.down("sm")]: {
            paddingTop: 4,
            paddingBottom: 4,
          },
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            theme.palette.mode === "light"
              ? "url(/images/homepage-graphics/Clouds-Bg-Light-Small.png)"
              : "url(/images/homepage-graphics/Clouds-Bg-Dark-Small.png)",
        }}
      >
        <Leaderboards
          guildShowcaseData={guildShowcaseData}
          guildLeaderboard={guildLeaderboard}
          fetchGuildLeaderboard={fetchGuildMMRLeaderboard}
        />
      </Stack>

      {!guildLeaderboardLoading && guildShowcaseData ? (
        <GuildShowcaseBanner
          prefix="We support"
          amount="150+ guilds"
          suffix="– rising daily!"
          guildDetails={guildShowcaseData.guildDetails}
          title={"Our Top Guilds"}
          icon={<BiMedal />}
        />
      ) : (
        <CustomLoader />
      )}

      <EmailSignup />
    </div>
  );
};

const mapDispatchToProps = {
  fetchGuildMMRLeaderboard,
  fetchRecentlyListed,
  fetchLeaderboard: fetchDollLeaderboard,
};

const mapStateToProps = (state) => ({
  axies: state.recently.recentlyListed.axies,
  fetchingAxies: state.recently.recentlyListed.fetching,
  failedAxies: state.recently.recentlyListed.failed,
  dollLeaderboard: getLeaderboardData(state),
  originData: getOriginLeaderboardData(state),
  guildLeaderboard: state.guildLeaderboard.mmrLeaderboard,
  guildLeaderboardLoading: state.guildLeaderboard.mmrLeaderboard.fetching,
  guildShowcaseData: getGuildLeaderboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
