import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageHeader from "./PageHeader";
import PageControls from "./PageControls";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "breakpoint",
})(({ theme, breakpoint }) => ({
  padding: `${theme.spacing(10)} 0 ${theme.spacing(16)}`,
  background: "#fff",
  borderTop: "6px solid #000",
  borderBottom: "6px solid #000",
  position: "relative",
  minHeight: "80vh",
  marginTop: 120,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  ".pageHeader": {
    position: "absolute",
    bottom: "100%",
    left: 32,
  },

  ".pageControls": {
    position: "absolute",
    bottom: "100%",
    right: 32,
  },

  [`@media (max-width: ${breakpoint}px)`]: {
    paddingTop: theme.spacing(4),
    gap: theme.spacing(6),

    ".pageHeader": {
      left: "50%",
      transform: "translateX(-50%)",
    },

    ".pageControls": {
      position: "static",
      borderRadius: "24px",
      border: "none",
      alignSelf: "center",
    },
  },
}));

const PageContent = ({
  titleImg,
  titleAlt,
  controls,
  children,
  breakpoint = "900",
}) => {
  return (
    <Wrapper breakpoint={breakpoint}>
      <PageHeader titleImg={titleImg} titleAlt={titleAlt} />
      <PageControls>{controls}</PageControls>
      {children}
    </Wrapper>
  );
};

export default PageContent;
