import { TextField, IconButton, InputAdornment } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const Search = ({
  placeholder,
  width = 300,
  searchText,
  updateSearchText,
  variant = "filled",
  style,
}) => {
  return (
    <TextField
      hiddenLabel
      value={searchText}
      onChange={updateSearchText}
      variant={variant}
      autoComplete="off"
      spellCheck={false}
      placeholder={placeholder}
      sx={{ ...style }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            position="end"
            onClick={() =>
              updateSearchText({
                target: {
                  value: "",
                },
              })
            }
          >
            <IoCloseCircle />
          </IconButton>
        ),
      }}
    />
  );
};

export default Search;
