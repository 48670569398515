import * as types from "./dataActionTypes";

export const fetchAxieCards = () => ({
  type: types.FETCH_AXIE_CARDS,
});

export const fetchAxieCardsStarted = () => ({
  type: types.FETCH_AXIE_CARDS_STARTED,
});

export const fetchAxieCardsFailed = () => ({
  type: types.FETCH_AXIE_CARDS_FAILED,
});

export const fetchAxieCardsSucceeded = (cards) => ({
  type: types.FETCH_AXIE_CARDS_SUCCEEDED,
  cards,
});

export const fetchAxieCardsV3 = () => ({
  type: types.FETCH_AXIE_CARDS_V3,
});

export const fetchAxieCardsV3Started = () => ({
  type: types.FETCH_AXIE_CARDS_V3_STARTED,
});

export const fetchAxieCardsV3Failed = () => ({
  type: types.FETCH_AXIE_CARDS_V3_FAILED,
});

export const fetchAxieCardsV3Succeeded = (cards) => ({
  type: types.FETCH_AXIE_CARDS_V3_SUCCEEDED,
  cards,
});

export const fetchPlayerData = (roninAddress) => ({
  type: types.FETCH_PLAYER_DATA,
  roninAddress,
});

export const fetchPlayerDataSucceeded = (roninAddress, data) => ({
  type: types.FETCH_PLAYER_DATA_SUCCEEDED,
  roninAddress,
  data,
});

export const fetchAxieData = (axieId) => ({
  type: types.FETCH_AXIE_DATA,
  axieId,
});

export const fetchAxieDataSucceeded = (axie) => ({
  type: types.FETCH_AXIE_DATA_SUCCEEDED,
  axie,
});
