import { Routes as RRoutes, Route } from "react-router";
import { Pages as p } from "pages";
import SitePage from "components/Utility/SitePage";
import { originTheme } from "theme/origin/theme";
import { interDungeonTheme } from "theme/interDungeon/theme";

const Routes = () => {
  return (
    <RRoutes>
      <Route
        exact
        path="/"
        element={
          <SitePage angledFooter={false}>
            <p.Home />
          </SitePage>
        }
      />
      <Route
        exact
        path="/classic/arena-leaderboard"
        element={
          <SitePage seoPage="classicLeaderboard">
            <p.ClassicLeaderboard />
          </SitePage>
        }
      />
      <Route
        exact
        path="/guild-leaderboards"
        element={
          <SitePage seoPage="guildLeaderboards">
            <p.GuildLeaderboard />
          </SitePage>
        }
      />
      <Route
        exact
        path="/guild-battle-leaderboard"
        element={
          <SitePage seoPage="guildBattleLeaderboard">
            <p.GuildBattleLeaderboard />
          </SitePage>
        }
      />
      <Route
        exact
        path="/origins-leaderboard"
        element={
          <SitePage seoPage="originsLeaderboard" colorOverrides={originTheme}>
            <p.OriginLeaderboard />
          </SitePage>
        }
      />
      <Route
        exact
        path="/player/:clientId?"
        element={
          <SitePage seoPage="arenaPlayer">
            <p.Player type="origin" />
          </SitePage>
        }
      />
      <Route
        exact
        path="/team-builder"
        element={
          <SitePage seoPage="teamBuilder">
            <p.TeamBuilder />
          </SitePage>
        }
      />
      <Route
        exact
        path="/recently-listed/:axieClass?"
        element={
          <SitePage seoPage="recentlyListed">
            <p.RecentlyListed />
          </SitePage>
        }
      />
      <Route
        exact
        path="/recently-sold/:axieClass?"
        element={
          <SitePage seoPage="recentlySold">
            <p.RecentlySold />
          </SitePage>
        }
      />
      <Route
        exact
        path="/breeding-costs"
        element={
          <SitePage seoPage="breedingCosts">
            <p.BreedingCosts />
          </SitePage>
        }
      />
      <Route
        exact
        path="/breeding-calculator"
        element={
          <SitePage seoPage="breedingCalculator">
            <p.BreedingCalculator />
          </SitePage>
        }
      />
      <Route
        exact
        path="/inspector/:id?"
        element={
          <SitePage seoPage="inspector" angledFooter={false}>
            <p.Inspector />
          </SitePage>
        }
      />
      <Route
        exact
        path="/breeding-simulator"
        element={
          <SitePage seoPage="breedingSimulator" angledFooter={false}>
            <p.BreedingSimulator />
          </SitePage>
        }
      />
      <Route
        exact
        path="/games"
        element={
          <SitePage seoPage="games">
            <p.Games />
          </SitePage>
        }
      />
      <Route
        exact
        path="/axie-ronin-support"
        element={
          <SitePage angledFooter={false} seoPage="axieRoninSupport">
            <p.Support />
          </SitePage>
        }
      />
      <Route
        exact
        path="/doll/leaderboard"
        element={
          <SitePage seoPage="dollLeaderboard" angledFooter={false}>
            <p.DoLLLeaderboard />
          </SitePage>
        }
      />
      <Route
        exact
        path="/doll/card-explorer"
        element={
          <SitePage seoPage="dollCardExpolorer" angledFooter={false}>
            <p.DoLLCardExplorer />
          </SitePage>
        }
      />
      <Route
        exact
        path="/doll/sidekicks"
        element={
          <SitePage seoPage="dollSidekicks" angledFooter={false}>
            <p.DoLLSidekicks />
          </SitePage>
        }
      />
      <Route
        exact
        path="/axie-quest/skill-explorer"
        element={
          <SitePage seoPage="axieQuestSkillExplorer" angledFooter={false}>
            <p.AxieQuestExplorer />
          </SitePage>
        }
      />
      <Route
        exact
        path="/inter-the-dungeon/card-explorer"
        element={
          <SitePage
            seoPage="interDungeonCardExplorer"
            angledFooter={false}
            colorOverrides={interDungeonTheme}
          >
            <p.InterDungeonExplorer />
          </SitePage>
        }
      />
      <Route
        exact
        path="/terms"
        element={
          <SitePage seoPage="terms">
            <p.TermsAndConditions />
          </SitePage>
        }
      />
      <Route
        exact
        path="/privacy-policy"
        element={
          <SitePage seoPage="privacyPolicy">
            <p.PrivacyPolicy />
          </SitePage>
        }
      />
      <Route
        exact
        path="/changelog"
        element={
          <SitePage seoPage="changelog">
            <p.Changelog />
          </SitePage>
        }
      />
      <Route
        exact
        path="/classic/card-explorer"
        element={
          <SitePage seoPage="v2CardExplorer" angledFooter={false}>
            <p.CardExplorerV2 />
          </SitePage>
        }
      />
      <Route
        exact
        path="/guild-membership"
        element={
          <SitePage seoPage="guildMembership" angledFooter={false}>
            <p.GuildMembership />
          </SitePage>
        }
      />
      <Route
        exact
        path="/guild/:guildSlug"
        element={
          <SitePage>
            <p.Guild />
          </SitePage>
        }
      />
      <Route
        exact
        path="/craftingdex/:slug?"
        element={
          <SitePage colorOverrides={originTheme}>
            <p.CraftingCollection />
          </SitePage>
        }
      />
      <Route
        exact
        path="/guild-dashboard/autopayout-log"
        element={
          <p.GuildDashboard>
            <p.AutopayoutLog />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/scholars"
        element={
          <p.GuildDashboard>
            <p.Scholars />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/axies"
        element={
          <p.GuildDashboard>
            <p.Axies />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/teams"
        element={
          <p.GuildDashboard>
            <p.Teams />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/team/:guildTeamId?"
        element={
          <p.GuildDashboard>
            <p.Team />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/scholar/:roninAddress?"
        element={
          <p.GuildDashboard>
            <p.Scholar />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/admin"
        element={
          <p.GuildDashboard>
            <p.Admin />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard/inventory"
        element={
          <p.GuildDashboard>
            <p.Inventory />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/guild-dashboard"
        element={
          <p.GuildDashboard>
            <p.Overview />
          </p.GuildDashboard>
        }
      />
      <Route
        exact
        path="/explorer/:slug?"
        element={
          <SitePage seoPage="explorer" colorOverrides={originTheme}>
            <p.Explorer />
          </SitePage>
        }
      />
      <Route
        exact
        path="/charts"
        element={
          <SitePage seoPage="charts">
            <p.Charts />
          </SitePage>
        }
      />
      <Route
        element={
          <SitePage>
            <p.Page404 />
          </SitePage>
        }
      />
    </RRoutes>
  );
};

export default Routes;
