import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IoRefreshCircleOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";

const RefreshIcon = ({ refreshAxie, id }) =>
  refreshAxie != null && id != null ? (
    <Tooltip title="Refresh axie">
      <IconButton onClick={() => refreshAxie(id)} size="small">
        <IoRefreshCircleOutline />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );

export default RefreshIcon;
