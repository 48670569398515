import React, { useState } from "react";
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import * as breedingSelectors from "store/breedingSimulator/breedingSelectors";
import { IoLockClosed } from "react-icons/io5";
import { connect } from "react-redux";
import {
  updateCurrentPageInvalid,
  updateCurrentPageUnlocked,
  updateSortType,
} from "store/breedingSimulator/breedingSimulatorActions";
import LockedResults from "./LockedResults";
import UnlockedResults from "./UnlockedResults";
import HiddenResults from "./HiddenResults";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CountdownBanner from "../molecules/CountdownBanner";
import { styled } from "@mui/material/styles";
import { a11yProps } from "helpers/accessibility";
import { isBreedingEventActive } from "helpers/time";
import TabPanel from "components/Tabs/TabPanel";

const ResultsPanel = ({
  results,
  sortType,
  updateSortType,
  handleDeleteId,
}) => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);

  const unlockedLabel = `${results.numberOfUnlockedResults} Result${
    results.numberOfUnlockedResults === 1 ? "" : "s"
  }`;
  const lockedLabel = `${results.numberOfLockedResults} Locked`;
  const hiddenLabel = `${results.numberOfInvalidResults} Hidden`;

  const normalSortOptions = [
    { label: "Highest Purity", value: "PURITY_DESC" },
    { label: "Lowest Purity", value: "PURITY_ASC" },
    { label: "Highest Cost", value: "COST_DESC" },
    { label: "Lowest Cost", value: "COST_ASC" },
  ];
  const specialSortOptions = [
    { label: "Most Summer Parts", value: "BREEDING_EVENT_PARTS_DESC" },
    {
      label: "Fewest Summer Parts",
      value: "BREEDING_EVENT_PARTS_ASC",
    },
  ];
  const sortOptions = isBreedingEventActive()
    ? [...normalSortOptions, ...specialSortOptions]
    : normalSortOptions;

  return (
    <Box
      sx={{
        position: "relative",
        filter:
          theme.palette.mode === "light"
            ? "drop-shadow(0 0 10px #5807ca13)"
            : "drop-shadow(0 0 10px #1c1c28)",
      }}
    >
      <CountdownBanner />
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        aria-label="Axie Infinity Breeding Simulator"
        sx={{
          "& .MuiTab-root": {
            backgroundColor: theme.palette.card.background,
            "&:hover": {
              backgroundColor: theme.palette.card.background,
              color: theme.palette.text.primary,
            },
          },
          "& .MuiTab-root.Mui-selected": {
            backgroundColor: theme.palette.card.background,
            color: theme.palette.text.primary,
          },
          "& .MuiTab-root.Mui-selected svg": {
            color: "#ffcf24",
          },
        }}
      >
        <CustomTab
          disableRipple
          label={unlockedLabel}
          {...a11yProps("breeding-simulator-tabpanel", 0)}
        />
        <CustomTab
          disableRipple
          label={
            <span>
              <IoLockClosed />
              {lockedLabel}
            </span>
          }
          {...a11yProps("breeding-simulator-tabpanel", 1)}
        />
        {results.numberOfInvalidResults > 0 && (
          <CustomTab
            disableRipple
            label={hiddenLabel}
            {...a11yProps("breeding-simulator-tabpanel", 2)}
          />
        )}
      </Tabs>

      <Box
        sx={{
          background: theme.palette.card.background,
          padding: "32px 32px 48px 32px",
          minHeight: 624,
          borderRadius: "16px",
          position: "relative",
          borderTopLeftRadius: 0,
          [theme.breakpoints.down("md")]: {
            p: 2,
            minHeight: 0,
          },
        }}
      >
        <CustomSelect
          val={sortType}
          setVal={updateSortType}
          hiddenLabel={true}
          variant="outlined"
          options={sortOptions}
          formStyle={{
            mb: 2,
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              right: 32,
              top: 32,
            },
          }}
          style={{ fontSize: 14 }}
        />

        <TabPanel value={tab} index={0} id="breeding-simulator-tabpanel">
          <UnlockedResults handleDeleteId={handleDeleteId} />
        </TabPanel>

        <TabPanel value={tab} index={1} id="breeding-simulator-tabpanel">
          <LockedResults handleDeleteId={handleDeleteId} />
        </TabPanel>

        <TabPanel value={tab} index={2} id="breeding-simulator-tabpanel">
          <HiddenResults handleDeleteId={handleDeleteId} />
        </TabPanel>
      </Box>
    </Box>
  );
};

const CustomTab = styled(Tab)(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  color: theme.palette.text.muted,
  [theme.breakpoints.up("md")]: {
    fontSize: 16,
    minWidth: 140,
  },
  svg: {
    marginRight: "4px",
    position: "relative",
    top: "2px",
  },
  "&:hover": {
    svg: {
      color: theme.palette.game.breedingSimulator.lock,
    },
  },
}));

const mapDispatchToProps = {
  updateCurrentPageUnlocked,
  updateCurrentPageInvalid,
  updateSortType,
};

const mapStateToProps = (state) => {
  return {
    results: breedingSelectors.getBreedingResults(state),
    sortType: state.breedingSimulator.sortType,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPanel);
