import React from "react";
import { Container, useTheme, Box, Divider, Stack } from "@mui/material";
import Column1 from "./molecules/Column1";
import Column2 from "./molecules/Column2";
import Column3 from "./molecules/Column3";
import Column4 from "./molecules/Column4";
import Credits from "./molecules/Credits";
import FooterAngle from "./atoms/FooterAngle";

const Footer = ({ colorOverrides, angledFooter = true }) => {
  const theme = useTheme();

  return (
    <Stack>
      {angledFooter && !colorOverrides?.footer?.background && <FooterAngle />}
      <Box
        style={{
          background:
            colorOverrides?.footer?.background ||
            theme.palette.footer.background,
        }}
      >
        <Container fixed>
          <Box
            sx={{
              pt: 14,
              pb: 6,
              display: "grid",
              gridTemplateColumns: "repeat(4,1fr)",
              gridGap: 24,
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "repeat(2,1fr)",
                gridGap: 32,
              },
              [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "1fr",
                pt: 10,
              },
            }}
          >
            <Column1 colorOverrides={colorOverrides} />
            <Column2 colorOverrides={colorOverrides} />
            <Column3 colorOverrides={colorOverrides} />
            <Column4 colorOverrides={colorOverrides} />
          </Box>
          <Divider sx={{ borderColor: "rgba(255,255,255,0.05)" }} />
          <Credits colorOverrides={colorOverrides} />
        </Container>
      </Box>
    </Stack>
  );
};

export default Footer;
