import * as types from "./actionTypes";
import produce from "immer";

const initialState = {
  controls: {
    searchText: "",
    axieClasses: [],
    partTypes: [],
    activeEffectTypes: [],
    passiveEffectTypes: [],
    resources: [],
  },
  fetching: true,
  data: undefined,
};

export const axieQuestCardsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_STARTED:
        draft.fetching = true;
        draft.failed = false;
        break;

      case types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_SUCCEEDED:
        draft.fetching = false;
        draft.data = action.data?.data?.listAxieBases?.items;
        break;

      case types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_FAILED:
        draft.fetching = false;
        draft.failed = true;
        break;

      case types.AXIE_QUEST_EXPLORER_UPDATE_CONTROLS:
        draft.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default axieQuestCardsReducer;
