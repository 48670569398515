import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { FaPlay } from "react-icons/fa";
import { AXIE_QUEST_WEBSITE } from "config";
import Button from "components/AxieQuest/Layout/Button";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: `${theme.spacing(8)} 0 ${theme.spacing(8)}`,
}));

const PageFooter = () => {
  return (
    <Wrapper>
      <Button endIcon={<FaPlay />} href={AXIE_QUEST_WEBSITE} target="_blank">
        Play Axie Quest
      </Button>
    </Wrapper>
  );
};

export default PageFooter;
