import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-22</Date>
      <Change type="new">
        Guild Dashboard: Display eggs in scholar accounts.
      </Change>
      <Change type="improved">
        Guild Dashboard: Redesigned team layout to fit more information,
        including the number of axies and eggs in each scholar account.
      </Change>
    </Entry>
  );
};

export default Changes;
