import arenaLeaderboardReducer from "./leaderboard/classic/leaderboardReducer";
import playerReducer from "./player/playerReducer";
import guildLeaderboardReducer from "./leaderboard/guild/leaderboardReducer";
import guildBattleLeaderboardReducer from "./leaderboard/guildBattle/leaderboardReducer";
import scholarLeaderboardReducer from "./leaderboard/scholar/leaderboardReducer";
import recentlyReducer from "./recently/recentlyReducer";
import breedingCalculatorReducer from "./breedingCalculator/breedingCalculatorReducer";
import inspectorReducer from "./inspector/inspectorReducer";
import breedingSimulatorReducer from "./breedingSimulator/breedingSimulatorReducer";
import guildDashboardReducer from "./guildDashboard/reducer/guildDashboardReducer";
import guildReducer from "./guild/guildReducer";
import cardExplorerReducer from "./cardExplorer/cardExplorerReducer";
import dataReducer from "./data/dataReducer";
import explorerReducer from "./explorer/explorerReducer";
import teamBuilderReducer from "./teamBuilder/teamBuilderReducer";
import adminReducer from "./guildDashboardAdmin/adminReducer";
import craftingCollectionReducer from "./craftingCollection/craftingCollectionReducer";
import originLeaderboardReducer from "./leaderboard/origin/leaderboardReducer";
import chartsReducer from "./charts/chartsReducer";
import dollReducer from "./doll/dollReducer";
import axieQuestReducer from "./axieQuest/axieQuestReducer";

const reducer = {
  arenaLeaderboard: arenaLeaderboardReducer,
  player: playerReducer,
  guildLeaderboard: guildLeaderboardReducer,
  guildBattleLeaderboard: guildBattleLeaderboardReducer,
  scholarLeaderboard: scholarLeaderboardReducer,
  recently: recentlyReducer,
  breedingCalculator: breedingCalculatorReducer,
  inspector: inspectorReducer,
  breedingSimulator: breedingSimulatorReducer,
  guildDashboard: guildDashboardReducer,
  guildDashboardAdmin: adminReducer,
  guild: guildReducer,
  cardExplorer: cardExplorerReducer,
  data: dataReducer,
  explorer: explorerReducer,
  teamBuilder: teamBuilderReducer,
  craftingCollection: craftingCollectionReducer,
  charts: chartsReducer,
  originLeaderboard: originLeaderboardReducer,
  doll: dollReducer,
  axieQuest: axieQuestReducer,
};

export default reducer;
