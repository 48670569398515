import * as types from "./actionTypes";
import produce from "immer";

const initialState = {
  controls: {
    searchText: "",
    axieClasses: [],
    partTypes: [],
  },
  fetching: true,
};

export const dollCardsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.DOLL_EXPLORER_FETCH_DOLLCARDS_STARTED:
        draft.fetching = true;
        draft.failed = false;
        break;

      case types.DOLL_EXPLORER_FETCH_DOLLCARDS_SUCCEEDED:
        draft.fetching = false;
        draft.data = action.data;
        break;

      case types.DOLL_EXPLORER_FETCH_DOLLCARDS_FAILED:
        draft.fetching = false;
        draft.failed = true;
        break;

      case types.DOLL_EXPLORER_UPDATE_CONTROLS:
        draft.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default dollCardsReducer;
