import React from "react";
import Navigation from "components/Navigation/Navigation";
import Footer from "components/Footer/Footer";
import ScrollToTop from "components/Utility/ScrollToTop";
import PageSEO from "components/Utility/PageSEO";
import { seo } from "data/seo";
import { AXIE_TECH_TITLE, AXIE_TECH_TAGLINE } from "config";
import { Box } from "@mui/material";

const SitePage = ({ children, colorOverrides, seoPage, angledFooter }) => (
  <>
    <PageSEO
      title={seoPage in seo ? seo[seoPage].title : AXIE_TECH_TITLE}
      description={
        seoPage in seo ? seo[seoPage].description : AXIE_TECH_TAGLINE
      }
    />
    <ScrollToTop />
    <Box sx={{ background: colorOverrides?.background || "inherit" }}>
      <Navigation colorOverrides={colorOverrides} />
      {children}
      <Footer angledFooter={angledFooter} colorOverrides={colorOverrides} />
    </Box>
  </>
);

export default SitePage;
