import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";

const SpecialGeneTag = ({ specialGenes, width }) => {
  const theme = useTheme();
  return specialGenes !== "japan" && specialGenes !== "mystic" ? null : (
    <Stack
      sx={{
        background: theme.palette.colors.axie.mystic,
        padding: `${(1 / 320) * width}px`,
        borderRadius: `${(2 / 320) * width}px`,
        alignSelf: "center",
      }}
    >
      <Typography
        fontSize={(8 / 320) * width}
        variant="span"
        fontWeight={600}
        color="#fff"
      >
        {specialGenes === "japan" ? "J" : "M"}
      </Typography>
    </Stack>
  );
};

export default SpecialGeneTag;
