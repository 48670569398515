import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAutopayoutLogData } from "store/guildDashboard/selectors/autopayout";
import { Stack } from "@mui/material";
import PageTitle from "../../atoms/PageTitle";
import { fetchAutopayoutLog } from "store/guildDashboard/actions/guildDashboardActions";
import { useWeb3React } from "@web3-react/core";
import { DataGrid } from "@mui/x-data-grid";
import Card from "components/Card/Card";
import CustomLoader from "components/CustomLoader/CustomLoader";

const AutopayoutLog = ({ fetchAutopayoutLog, logData, loading, failed }) => {
  const { account } = useWeb3React();

  useEffect(() => {
    fetchAutopayoutLog(account);
  }, [fetchAutopayoutLog, account]);

  return (
    <Stack gap={4} sx={{ maxWidth: "100%" }}>
      <PageTitle>Autopayout Log</PageTitle>

      <Card>
        {loading ? (
          <CustomLoader />
        ) : (
          <DataGrid
            autoHeight
            rows={logData.rows}
            columns={logData.columns}
            pageSize={50}
            rowsPerPageOptions={[50, 100, 200]}
            disableSelectionOnClick
          />
        )}
      </Card>
    </Stack>
  );
};

const mapDispatchToProps = {
  fetchAutopayoutLog,
};

const mapStateToProps = (state) => ({
  logData: getAutopayoutLogData(state),
  loading: state.guildDashboard.autopayout.log?.fetching,
  failed: state.guildDashboard.autopayout.log?.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(AutopayoutLog);
