import {
  AXIE_INFINITY_WEBSITE,
  STARTER_AXIE_PARTIDS,
  STARTER_AXIE_CHARMIDS,
  STARTER_AXIE_RUNEIDS,
  ARENA_SEASON_CURRENT,
  AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES,
} from "config";
import {
  getAxieClassforId,
  getPotentialPointOrderStartingWithBaseCardClass,
} from "./axie";
import { strIsInArray } from "helpers/display";

export const makeMarketplaceUrlForItem = (itemType, id) => {
  if (itemType == null || id == null || id === 0) {
    return null;
  }
  if (itemType.toLowerCase() === "charm") {
    return `${AXIE_INFINITY_WEBSITE}/marketplace/charms/${id}`;
  }
  if (itemType.toLowerCase() === "rune") {
    return `${AXIE_INFINITY_WEBSITE}/marketplace/runes/${id}`;
  }
  if (itemType.toLowerCase() === "card") {
    if (partIdIsMarketplaceCompatible(id)) {
      return `${AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES}/?auctionTypes=Sale&parts=${id}`;
    }
  }
  return null;
};

export const makeUrlForItemIcon = (image) =>
  image
    ? `https://storage.googleapis.com/origin-production/assets/item/${image}`
    : null;

export const partIdIsMarketplaceCompatible = (partId) => {
  return !STARTER_AXIE_PARTIDS.includes(partId);
};

export const cardIsMarketplaceCompatible = (card) => {
  return !STARTER_AXIE_PARTIDS.includes(card.partId);
};

export const getMarketplaceCompatibleCards = (cards) => {
  return cards.filter((c) => cardIsMarketplaceCompatible(c));
};

export const isItemACharm = (item) => {
  if (item?.itemId && item.itemId.startsWith("ecard_")) {
    return true;
  }
  return false;
};

export const isItemARune = (item) => {
  if (item?.itemId && item.itemId.startsWith("rune_")) {
    return true;
  }
  return false;
};

export const getItemBlockchainStatus = (isBlockchain) => {
  if (isBlockchain == null) return null;

  return isBlockchain === 1 || isBlockchain ? "Blockchain" : "Non-Blockchain";
};

export const countItemsOfType = (items, type) => {
  if (items == null || !Array.isArray(items)) {
    return 0;
  }

  if (type === "Charm") {
    return items.filter((item) => isItemACharm(item)).length;
  } else if (type === "Rune") {
    return items.filter((item) => isItemARune(item)).length;
  }

  return 0;
};

export const makeSeasonTag = (season) => {
  if (season == null) {
    return "-";
  }
  return season.replace("Season ", "S").replace("Alpha", "A");
};

export const getCurrentSeason = () => ARENA_SEASON_CURRENT.value;

export const isCurrentSeasonItem = (season) => {
  return season === getCurrentSeason();
};

export const isCurrentSeasonNft = (season, isBlockchain) => {
  return isCurrentSeasonItem(season) && isBlockchain === 1;
};

export const isStarterRuneOrCharm = (allItems) => {
  return allItems.filter(
    (item) =>
      strIsInArray(
        item?.itemId,
        STARTER_AXIE_RUNEIDS.concat(STARTER_AXIE_CHARMIDS)
      ) && item.season === "Unknown"
  );
};

// add starter runes and charms to current season items
export const extractCurrentSeasonItems = (allItems) => {
  const starterItems = isStarterRuneOrCharm(allItems);
  const itemObj = allItems.reduce((prev, curr) => {
    if (isCurrentSeasonItem(curr.season)) {
      if (!(curr.name in prev)) {
        prev[curr.name] = { ...curr };
      } else {
        if (
          (prev[curr.name].tokenId === 0 || prev[curr.name].tokenId == null) &&
          (curr.tokenId !== 0 || curr.tokenId != null)
        ) {
          prev[curr.name] = { ...curr };
        }
      }
    }
    return prev;
  }, {});
  return Object.values(itemObj).concat(Object.values(starterItems));
};

export const getTopNRunes = (players, n) => {
  if (!n) {
    return [];
  }

  const counts = {};

  for (const player of players) {
    if (player.favoriteTeam) {
      for (const axie of player.favoriteTeam) {
        if (axie.runeData) {
          const runeName = axie.runeData.name;
          if (runeName in counts) {
            counts[runeName].count += 1;
          } else {
            counts[runeName] = {
              count: 1,
              data: axie.runeData,
            };
          }
        }
      }
    }
  }

  const sorted = Object.entries(counts)
    .sort((a, b) => {
      const aCount = a[1].count;
      const bCount = b[1].count;

      if (aCount < bCount) {
        return 1;
      } else if (aCount > bCount) {
        return -1;
      } else {
        const aName = a[1].data.name;
        const bName = b[1].data.name;
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      }
    })
    .map((item) => {
      return {
        count: item[1].count,
        data: item[1].data,
      };
    });

  return sorted.slice(0, n);
};

export const calculatePotentialPointsUsed = (
  charm,
  cardClass,
  potentialPoints
) => {
  const points = [...potentialPoints];
  let remainingCost = charm.potentialCost;
  const potentialPointsUsed = [];

  if (charm.class === "Neutral") {
    const allocationOrder =
      getPotentialPointOrderStartingWithBaseCardClass(cardClass);

    for (const cls of allocationOrder) {
      const pointsForClass = points.find(
        (p) => p.class.toLowerCase() === cls.toLowerCase()
      );

      // Are there available points
      if (remainingCost > 0 && pointsForClass?.remaining > 0) {
        if (pointsForClass.remaining == null) {
          pointsForClass.remaining = pointsForClass.amount;
        }

        // More than enough
        if (pointsForClass.remaining >= remainingCost) {
          potentialPointsUsed.push({ class: cls, amount: remainingCost });
          remainingCost = 0;
        }
        // Not sufficient
        else {
          potentialPointsUsed.push({
            class: cls,
            amount: pointsForClass.remaining,
          });
          remainingCost -= pointsForClass.remaining;
        }
      }
    }
  } else {
    potentialPointsUsed.push({ class: charm.class, amount: remainingCost });
  }

  return potentialPointsUsed;
};

export const makePPUsedArrayFromUrlString = (charmStr) => {
  const segments = charmStr.split("_");
  const ppUsed = [];

  for (let i = 0; i < segments.length; i += 2) {
    ppUsed.push({
      class: getAxieClassforId(segments[i]),
      amount: parseInt(segments[i + 1]),
    });
  }
  return ppUsed;
};
