import { connect, ConnectedProps } from "react-redux";
import { Box, Stack, styled } from "@mui/material";
import Search from "components/Origin/atoms/Search";
import { updateControls } from "store/leaderboard/origin/leaderboardActions";
import ItemsDropdown from "components/Origin/atoms/ItemsDropdown";
import {
  getAllUniqueRunesForCurrentSeason,
  getAllUniqueCharmsForCurrentSeason,
} from "store/data/dataSelectors";
import { sortByName } from "helpers/display";
import { RootState } from "store/store";
import { OriginsLeaderboardControls } from "store/leaderboard/origin/leaderboardReducer";
import { Charm, Rune } from "types";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  display: "grid",
  gridTemplateColumns: "200px 1fr 1fr",
  gridTemplateAreas: `
    'search runes charms'
  `,
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `
      'search search'
      'runes charms'
    `,
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

interface Props extends PropsFromRedux {}

const Controls = ({ updateControls, controls, allRunes, allCharms }: Props) => {
  const handleUpdateControls = (
    newControls: Partial<OriginsLeaderboardControls>
  ) => {
    updateControls({ ...controls, ...newControls });
  };
  const handleUpdateDropdown = (property: "runes" | "charms") => {
    return (newVal: Rune[] | Charm[]) => {
      handleUpdateControls({
        [property]: newVal,
      });
    };
  };

  return (
    <Wrapper>
      <Box sx={{ gridArea: "search" }}>
        <Search
          value={controls.searchText}
          handleUpdate={handleUpdateControls}
          placeholder="Search players"
        />
      </Box>
      <Box sx={{ gridArea: "runes" }}>
        <ItemsDropdown
          label="Runes"
          value={controls.runes}
          setValue={handleUpdateDropdown("runes")}
          options={sortByName(allRunes)}
        />
      </Box>
      <Box sx={{ gridArea: "charms" }}>
        <ItemsDropdown
          label="Charms"
          value={controls.charms}
          setValue={handleUpdateDropdown("charms")}
          options={sortByName(allCharms)}
        />
      </Box>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  updateControls,
};

const mapStateToProps = (state: RootState) => ({
  controls: state.originLeaderboard.controls,
  allRunes: getAllUniqueRunesForCurrentSeason(state),
  allCharms: getAllUniqueCharmsForCurrentSeason(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Controls);
