import { Stack, Typography, useTheme } from "@mui/material";
import fonts from "theme/fonts";

const PlayerRankNumber = ({ number }) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: "4px",
        height: 32,
      }}
    >
      <Typography
        variant="span"
        fontWeight={400}
        fontSize={24}
        fontFamily={fonts.changa}
        color={theme.palette.text.secondary}
      >
        {number}
      </Typography>
    </Stack>
  );
};

export default PlayerRankNumber;
