import React from "react";
import {
  ToggleButton,
  useTheme,
  Stack,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import Label from "components/Typography/Label";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  padding: "4px",
  background: theme.palette.toggles.background,
  borderRadius: "4px",
}));

const Group = styled(ToggleButtonGroup)(({ theme }) => ({
  justifyContent: "center",
  alignSelf: "center",
  gap: "4px",

  "> .MuiToggleButton-root": { fontWeight: "600" },
  "& .MuiToggleButtonGroup-grouped": {
    margin: "4px",
    border: "none",

    "&.Mui-disabled": {
      border: "none",
    },
    "&:not(:first-of-type), &:first-of-type": {
      borderRadius: "4px",
      margin: 0,
      borderLeft: "inherit",
    },

    "&.toggleIconButton": {
      backgroundColor: theme.palette.toggles.default.background,
      border: `2px solid transparent`,

      "&:hover, &.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.toggles.default.background,
        border: `2px solid ${theme.palette.toggles.selected.background}`,
      },
    },

    "&.toggleButton": {
      color: theme.palette.toggles.default.color,
      backgroundColor: theme.palette.toggles.default.background,
      minWidth: 88,
      "&:hover, &.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.toggles.selected.color,
        backgroundColor: theme.palette.toggles.selected.background,
      },
    },
  },
}));

const ToggleGroup = ({ title, icons, options, control, updateControl }) => {
  const theme = useTheme();
  if (options == null) {
    return null;
  }
  const handleUpdateControl = (e, newControl) => {
    if (newControl && control[0] !== newControl) {
      updateControl(newControl);
    }
  };
  return (
    <Wrapper>
      <Label>{title}</Label>
      <Group exclusive value={control} onChange={handleUpdateControl}>
        {options.map((option) =>
          icons && icons[option.value] ? (
            <ToggleButton
              size="small"
              key={option.label}
              value={option.value}
              aria-label={option.value}
              className="toggleIconButton"
            >
              <Tooltip title={option.label}>
                <Stack>{icons[option.value](theme)}</Stack>
              </Tooltip>
            </ToggleButton>
          ) : (
            <ToggleButton
              size="small"
              key={option.label}
              value={option.value}
              aria-label={option.value}
              className="toggleButton"
            >
              {(icons && icons[option.value]()) || option.label}
            </ToggleButton>
          )
        )}
      </Group>
    </Wrapper>
  );
};

export default ToggleGroup;
