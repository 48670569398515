import CustomLink from "components/CustomLink/CustomLink";
import { logo } from "logos";
import { Stack, useTheme } from "@mui/material";

const Logo = ({ color }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        svg: {
          width: 140,
          height: "auto",
        },
      }}
    >
      <CustomLink to="/" style={{ display: "flex" }}>
        {logo(color || theme.palette.primary.main)}
      </CustomLink>
    </Stack>
  );
};

export default Logo;
