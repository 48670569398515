import React, { useState } from "react";
import {
  setScholarToModifyIds,
  setOpenDeleteScholarDialog,
  setOpenEditScholarDialog,
} from "store/guildDashboard/actions/guildDashboardActions";
import { connect } from "react-redux";
import { RiInformationFill, RiPencilFill } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  toggleTextWithDelay,
  formatTimestampAsDateTime,
} from "helpers/display";
import { Stack, IconButton as MuiIconButton, Tooltip } from "@mui/material";
import moment from "moment";
import styled from "@emotion/styled";

const IconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  transition: "0.2s all ease",
  fontSize: 14,
  color: isActive ? theme.palette.feedback.red : theme.palette.text.muted,
  "&:hover, &:active": {
    transform: "scale(1.2)",
  },
}));

export const ActionButtons = ({
  deleting,
  roninAddress,
  guildTeamId,
  payoutAddress,
  setScholarToModifyIds,
  setOpenEditScholarDialog,
  setOpenDeleteScholarDialog,
  snapshotTimestamp,
  snapshotDataType,
}) => {
  const [copyTooltip, setCopyTooltip] = useState("Copy payout address");

  const handleToggleTooltipText = () => {
    toggleTextWithDelay(setCopyTooltip, "Copy payout address", "Copied", 1000);
  };

  const handleSetOpenEditScholarDialog = () => {
    setScholarToModifyIds(roninAddress, guildTeamId);
    setOpenEditScholarDialog(true);
  };

  const handleSetOpenDeleteScholarDialog = () => {
    if (!deleting) {
      setScholarToModifyIds(roninAddress, guildTeamId);
      setOpenDeleteScholarDialog(true);
    }
  };

  const slpSnapshotTimestamp = snapshotTimestamp
    ? `${snapshotDataType} snapshot: ${formatTimestampAsDateTime(
        moment.utc(snapshotTimestamp).unix(),
        {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
      )}`
    : "?";

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      {payoutAddress && (
        <CopyToClipboard text={payoutAddress} onCopy={handleToggleTooltipText}>
          <Tooltip title={copyTooltip}>
            <IconButton>
              <IoSend />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      )}
      <Tooltip title="Edit scholar">
        <IconButton onClick={handleSetOpenEditScholarDialog}>
          <RiPencilFill />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete scholar">
        <IconButton
          isActive={deleting}
          onClick={handleSetOpenDeleteScholarDialog}
        >
          <FaTrash />
        </IconButton>
      </Tooltip>
      {snapshotTimestamp && snapshotDataType === "SLP" ? (
        <Tooltip title={slpSnapshotTimestamp}>
          <IconButton>
            <RiInformationFill />
          </IconButton>
        </Tooltip>
      ) : snapshotDataType === "SLP" ? (
        <IconButton disabled={true}>
          <RiInformationFill />
        </IconButton>
      ) : (
        <></>
      )}
    </Stack>
  );
};

const mapDispatchToProps = {
  setScholarToModifyIds,
  setOpenDeleteScholarDialog,
  setOpenEditScholarDialog,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActionButtons);
