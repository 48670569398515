import React from "react";
import { useTheme } from "@mui/material";
import SliderWithIcon from "components/CustomSlider/SliderWithIcon";
import * as icons from "components/Axie/icons/statIcons";

const FilterStat = ({ stat, setStat, statDef }) => {
  let icon;
  const theme = useTheme();

  if (statDef.icon in icons) {
    icon = icons[statDef.icon];
  }

  return (stat != null) & (setStat != null) ? (
    <SliderWithIcon
      value={stat}
      setValue={setStat}
      icon={icon(theme.palette.text.regular)}
      iconTitle={statDef.label}
      min={statDef.min}
      max={statDef.max}
      step={statDef.step}
    />
  ) : (
    <></>
  );
};

export default FilterStat;
