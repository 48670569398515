import React from "react";
import { Chip, Stack } from "@mui/material";
import PageTitle from "../atoms/PageTitle";

const PageTitleWithChips = ({ title, chips, children }) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "row" }}
      gap={1}
      alignItems="center"
    >
      <PageTitle>{title}</PageTitle>
      {chips &&
        chips.map((chip, i) => (
          <Chip
            variant={i === 0 ? "contained" : "outlined"}
            color="primary"
            size="small"
            label={chip}
            key={`CHIP_${i}`}
          />
        ))}
      {children}
    </Stack>
  );
};

export default PageTitleWithChips;
