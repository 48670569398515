import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const UseCodeAxieTechWrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: theme.spacing(3),
  [theme.breakpoints.down("lg")]: {
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

export default UseCodeAxieTechWrapper;
