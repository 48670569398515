import { Typography, useTheme } from "@mui/material";

const ScholarName = ({ name }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h1"
      fontSize="26px"
      color="text.bright"
      sx={{
        lineHeight: 1.2,
        maxWidth: "220px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("lg")]: {
          maxWidth: "initial",
        },
      }}
    >
      {name}
    </Typography>
  );
};

export default ScholarName;
