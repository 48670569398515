import {
  axieQuestLinks,
  dollLinks,
  interDungeonLinks,
} from "components/Navigation/navigation.links";
import { sortByStatus } from "./utils";

const data = [
  {
    id: 1,
    title: "Inter the Dungeon",
    gameType: "ABP",
    tagline: "Push-your-luck dungeon crawl game",
    socials: [
      { type: "website", url: "https://www.interthedungeon.com/" },
      { type: "twitter", url: "https://twitter.com/interthedungeon" },
    ],
    image: "inter-the-dungeon.jpg",
    linkTo: "website",
    status: "Alpha",
    platforms: ["Android", "PC", "Mac", "iOS"],
    tools: interDungeonLinks,
  },
  {
    id: 2,
    title: "Defenders of Lunacian Land (DOLL)",
    gameType: "ABP",
    tagline: "Exhilarating survival action game",
    socials: [
      { type: "website", url: "https://doll.tioland.com/" },
      { type: "twitter", url: "https://twitter.com/AxieDOLL" },
      { type: "discord", url: "https://discord.gg/GPJftsYeNX" },
    ],
    image: "doll.jpg",
    // videoId: "CwK6CVuB2Tw",
    linkTo: "website",
    status: "Beta",
    platforms: ["Android", "PC"],
    tools: dollLinks,
  },
  {
    id: 3,
    title: "Axieology",
    gameType: "ABP",
    tagline: "Tower defense game",
    socials: [
      { type: "website", url: "http://game.axieology.com/whitepaper.php" },
      { type: "twitter", url: "https://twitter.com/axieology" },
    ],
    image: "axieology.jpg",
    linkTo: "website",
    platforms: ["iOS"],
    status: "Alpha",
  },
  {
    id: 4,
    title: "Lunacia Cup",
    gameType: "ABP",
    tagline: "Racing game",
    socials: [
      { type: "website", url: "https://lunaciacup.github.io/landingpage/" },
      { type: "twitter", url: "https://twitter.com/lunaciacup" },
      { type: "discord", url: "https://discord.gg/T7c7VEzRJK" },
    ],
    image: "lunacia-cup.jpg",
    linkTo: "website",
    status: "Alpha",
    platforms: ["PC"],
  },
  {
    id: 5,
    title: "Axie Run",
    gameType: "ABP",
    tagline: "Runner game",
    socials: [{ type: "twitter", url: "https://twitter.com/AxieRun" }],
    image: "run-axie-run.jpg",
    linkTo: "twitter",
    status: "Building",
    platforms: ["Android", "iOS"],
  },
  {
    id: 6,
    title: "Axie Infinity War",
    gameType: "ABP",
    tagline: "2.5D platform battle royal game",
    socials: [
      { type: "website", url: "https://www.axieinfinitywar.com/" },
      { type: "discord", url: "https://discord.gg/DNxYAJh6tQ" },
      { type: "twitter", url: "https://twitter.com/AxieWar" },
    ],
    image: "axie-infinity-war.jpg",
    linkTo: "website",
    status: "Beta",
    platforms: ["PC", "Android", "Mac"],
  },
  {
    id: 7,
    title: "Axie Brawl",
    gameType: "ABP",
    tagline: "Arena brawler",
    socials: [{ type: "twitter", url: "https://twitter.com/AxieBrawl" }],
    image: "axie-brawl.jpg",
    linkTo: "twitter",
    status: "Building",
    platforms: ["PC"],
  },
  {
    id: 8,
    title: "Axie Champions",
    gameType: "Indy",
    tagline:
      "Calling out Axie Champions - Match3 Puzzle game with PVP League and Championship!",
    socials: [
      { type: "twitter", url: "https://twitter.com/AxieChampions" },
      { type: "discord", url: "https://discord.gg/axiechampions" },
    ],
    image: "axie-champions.jpg",
    linkTo: "twitter",
    status: "Beta",
    platforms: ["Android", "iOS"],
  },
  {
    id: 9,
    title: "Across Lunacia",
    gameType: "ABP",
    tagline: "Pixel art platformer",
    socials: [
      {
        type: "website",
        url: "https://acrosslunacia.com/",
      },
      { type: "twitter", url: "https://twitter.com/AcrossLunacia" },
      { type: "discord", url: "https://discord.gg/H57cf3DhXR" },
    ],
    image: "across-lunacia.jpg",
    linkTo: "website",
    status: "Alpha",
    platforms: ["Browser"],
  },
  {
    id: 10,
    title: "Axie Blitz",
    gameType: "ABP",
    tagline: "Match 3 multiplayer game",
    socials: [
      {
        type: "website",
        url: "https://www.axieblitz.com/",
      },
      { type: "twitter", url: "https://twitter.com/AxieBlitzGame" },
      { type: "discord", url: "https://discord.com/invite/axieblitz" },
    ],
    image: "axie-blitz.jpg",
    videoId: "kRmHxJEGyjM",
    linkTo: "website",
    status: "Alpha",
    platforms: ["Browser"],
  },
  {
    id: 11,
    title: "TactiCards",
    gameType: "ABP",
    tagline: "Tactics and trading card game",
    socials: [
      {
        type: "website",
        url: "https://tacticards.net/",
      },
      { type: "twitter", url: "https://twitter.com/TactiCards" },
      { type: "discord", url: "https://discord.com/invite/4AF37DAQRf" },
    ],
    image: "tacticards.jpg",
    videoId: "AUBf6E2pgj8",
    linkTo: "website",
    status: "Alpha",
    platforms: ["PC"],
  },
  {
    id: 12,
    title: "Axie Infinity Raylights",
    gameType: "ABP",
    tagline: "Land mini-game available to all landowners",
    socials: [
      { type: "website", url: "https://app.axieinfinity.com/games/raylights/" },
      { type: "twitter", url: "https://twitter.com/axieraylights" },
    ],
    image: "raylights.jpg",
    videoId: "VuR-QHvp68U",
    linkTo: "website",
    status: "Alpha",
    platforms: ["Browser"],
  },
  {
    id: 13,
    title: "Arcadian Rhythm",
    gameType: "Indy",
    tagline: "Fast-paced 3D action-adventure game",
    socials: [
      { type: "website", url: "https://arcadianrhythm.io/" },
      { type: "twitter", url: "https://twitter.com/ArcadianAxie" },
    ],
    image: "arcadian-rhythm.jpg",
    linkTo: "twitter",
    status: "Building",
    platforms: ["PC"],
  },
  {
    id: 14,
    title: "Axie Raids",
    gameType: "ABP",
    tagline: "Tower-Defense-like Tug of War game",
    socials: [{ type: "twitter", url: "https://twitter.com/AxieRaids" }],
    image: "axie-raids.png",
    linkTo: "twitter",
    status: "Building",
    platforms: ["Browser"],
  },
  {
    id: 15,
    title: "Axie Quest",
    gameType: "ABP",
    tagline:
      "A new world where Puzzle-Matching and RPG collides, bringing Lunacians on a quest for treasure, glory and fame!",
    socials: [
      { type: "website", url: "https://www.axiequest.com/" },
      { type: "twitter", url: "https://twitter.com/AxieQuest" },
      { type: "discord", url: "https://discord.com/invite/RY4Z97wStt" },
    ],
    image: "axie-quest.jpg",
    linkTo: "website",
    status: "Alpha",
    platforms: ["Android", "PC"],
    tools: axieQuestLinks,
  },
  {
    id: 16,
    title: "Sky Smash",
    gameType: "Indy",
    tagline:
      "Multiplayer, turn-based tactical artillery game where you control your own axies and smash other axies with powerful artillery weapons!",
    socials: [{ type: "twitter", url: "https://twitter.com/skysmashaxie" }],
    image: "axie-smash.jpg",
    linkTo: "twitter",
    status: "Beta",
    platforms: ["Android", "iOS", "PC", "Mac"],
  },
  {
    id: 17,
    title: "Axie Tri Force",
    gameType: "Indy",
    tagline:
      "This casual game combines Little Green Games and SkyMavis, with an axie character inspired by the popular SkyMavis game Axie Infinity",
    socials: [
      { type: "twitter", url: "https://twitter.com/littlegreen_ga" },
      { type: "website", url: "https://littlegreen.games" },
    ],
    image: "tri-force.png",
    linkTo: "website",
    status: "Alpha",
    platforms: ["iOS", "PC", "Mac"],
  },
  {
    id: 18,
    title: "Axie Infinity: Culinary Wars",
    gameType: "Indy",
    tagline:
      "In Culinary Wars, you are the head chef in charge of a crazy kitchen!",
    socials: [
      { type: "website", url: "https://welcome.skymavis.com/download/" },
    ],
    videoId: "JI781Q3P-fo",
    linkTo: "website",
    status: "Alpha",
    platforms: ["PC", "Mac"],
  },
];

export const statusOptions = [];
export const platformOptions = [];
export const gameTypeOptions = [];

data
  .sort((a, b) => sortByStatus(a, b, false))
  .forEach((project) => {
    if (project.status && !statusOptions.includes(project.status)) {
      statusOptions.push(project.status);
    }
    project.platforms.forEach((platform) => {
      if (!platformOptions.includes(platform)) {
        platformOptions.push(platform);
      }
    });
    if (project.gameType && !gameTypeOptions.includes(project.gameType)) {
      gameTypeOptions.push(project.gameType);
    }
  });

export default data;
