import React from "react";
import DashboardHeader from "../../organisms/DashboardHeader";
import Stats from "./Stats";

const Overview = ({ currencyPrices, formatter, baseCurrency }) => {
  return (
    <>
      <DashboardHeader
        title="Guild Stats"
        currencyPrices={currencyPrices}
        formatter={formatter}
        baseCurrency={baseCurrency}
      />
      <Stats
        currencyPrices={currencyPrices}
        formatter={formatter}
        baseCurrency={baseCurrency}
      />
    </>
  );
};

export default Overview;
