import React from "react";
import {
  Select,
  useTheme,
  MenuItem,
  Chip,
  InputLabel,
  FormControl,
} from "@mui/material";

export const CustomMultiSelect = ({
  label,
  value,
  setValue,
  options,
  style,
  minWidth = 130,
  fullWidth = true,
}) => {
  const theme = useTheme();
  return (
    <FormControl
      variant="filled"
      size="small"
      fullWidth={fullWidth}
      sx={{ minWidth }}
    >
      <InputLabel id={`${label}-select`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        renderValue={(selected) => (
          <div className="selected-items">
            {selected.map((value) => (
              <Chip key={value} label={value} className="item" />
            ))}
          </div>
        )}
        multiple
        sx={{
          "& .MuiChip-root": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            marginRight: "2px",
            height: "24px",
          },
          ...style,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultiSelect;
