import React from "react";
import {
  Chip,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { RiErrorWarningFill } from "react-icons/ri";

const Text = ({ amount, description }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="span"
      color="text.bright"
      fontWeight="700"
      fontSize="20px"
      sx={{ [theme.breakpoints.down("sm")]: { fontSize: "17px" } }}
    >
      <Typography variant="span" color="text.primary" fontWeight="700">
        {amount}{" "}
      </Typography>
      {description}
    </Typography>
  );
};

const SelectAxiesDialogTitle = ({
  total,
  selected,
  loading,
  validRoninAddresses,
  validRoninAddressesWithAxies,
}) => {
  const theme = useTheme();
  const numZero =
    validRoninAddresses.length - validRoninAddressesWithAxies.length;
  const warningTooltip = `${numZero} account${
    numZero === 1 ? "" : "s"
  } did not have any axies`;

  return (
    <DialogTitle id="select-axies-dialog-title">
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
          <Stack direction="row" gap={0.5} alignItems="center" flexWrap="wrap">
            <Text
              amount={total}
              description={`Adult Axie${total === 1 ? "" : "s"} from`}
            />
            <Text
              amount={validRoninAddressesWithAxies.length}
              description={`Ronin Address${
                validRoninAddressesWithAxies.length === 1 ? "" : "es"
              }`}
            />
          </Stack>
          {selected > 0 && (
            <Chip label={`${selected} selected`} color="primary" />
          )}
        </Stack>

        {loading && (
          <Stack direction="row" alignItems="center">
            <CustomLoader
              width={32}
              centered={false}
              marginTop={0}
              marginBottom={0}
            />
          </Stack>
        )}

        {!loading && numZero > 0 && (
          <Tooltip title={warningTooltip}>
            <IconButton sx={{ color: theme.palette.secondary.main }}>
              <RiErrorWarningFill />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </DialogTitle>
  );
};

export default SelectAxiesDialogTitle;
