import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const FullWidthWrapper = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(12),
  width: "100%",
  maxWidth: 680,
  margin: "0 auto",

  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default FullWidthWrapper;
