import { Stack, Typography, Menu } from "@mui/material";
import MarketplaceIcon from "../atoms/MarketplaceIcon";
import Quantity from "../atoms/Quantity";
import ItemDetails from "./ItemDetails";
import Owners from "./Owners";

const ItemPopup = ({
  id,
  item,
  anchorEl,
  isOpen,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Menu
      id={id}
      open={isOpen}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave,
        sx: {
          pointerEvents: "auto",
          px: 3,
          py: 2,
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
          maxWidth: 620,
        },
      }}
      sx={{
        pointerEvents: "none",
      }}
    >
      <Stack gap={2}>
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <ItemDetails
              rarity={item.rarity}
              isBlockchain={item.isBlockchain}
            />
            <Quantity
              quantity={item.quantity}
              withdrawalAmount={item.withdrawalAmount}
              isBlockchain={item.isBlockchain}
            />
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <Typography
              variant="span"
              fontWeight={800}
              fontSize={15}
              color="text.bright"
            >
              {item.name}
            </Typography>
            <MarketplaceIcon itemType={item.itemType} tokenId={item.tokenId} />
          </Stack>
        </Stack>
        <Owners data={item.owners} isBlockchain={item.isBlockchain} />
      </Stack>
    </Menu>
  );
};

export default ItemPopup;
