import React from "react";
import { Tab, Container } from "@mui/material";
import { FaStar } from "react-icons/fa";
import { cards, charms, runes, curses, tools } from "icons/icons";
import { FaExclamationCircle } from "react-icons/fa";
import { StyledTabs } from "../atoms/StyledTabs";
import { a11yProps } from "helpers/accessibility";
import TabLabel from "../atoms/TabLabel";

const Tabs = ({ tab, setTab, numberOfFavorites }) => {
  const tabsId = "explorer-tabpanel";

  return (
    <Container>
      <StyledTabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        aria-label="Axie Infinity: Origins Card, Rune &amp; Charm Explorer"
      >
        <Tab
          disableRipple
          label={<TabLabel text="Cards" icon={cards()} />}
          {...a11yProps(tabsId, 0)}
        />
        <Tab
          disableRipple
          label={<TabLabel text="Curses" icon={curses()} />}
          {...a11yProps(tabsId, 1)}
        />
        <Tab
          disableRipple
          label={<TabLabel text="Tools" icon={tools()} />}
          {...a11yProps(tabsId, 2)}
        />
        <Tab
          disableRipple
          label={<TabLabel text="Revenge" icon={cards()} />}
          {...a11yProps(tabsId, 3)}
        />
        <Tab
          disableRipple
          label={<TabLabel text="Runes" icon={runes()} />}
          {...a11yProps(tabsId, 4)}
        />
        <Tab
          disableRipple
          label={<TabLabel text="Charms" icon={charms()} />}
          {...a11yProps(tabsId, 5)}
        />
        <Tab
          disableRipple
          label={
            <TabLabel text="Status Effects" icon={<FaExclamationCircle />} />
          }
          {...a11yProps(tabsId, 6)}
        />
        <Tab
          disableRipple
          label={
            <TabLabel
              text={`${
                numberOfFavorites === 0 ? "" : numberOfFavorites
              } Favorite${numberOfFavorites === 1 ? "" : "s"}`}
              icon={<FaStar />}
            />
          }
          {...a11yProps(tabsId, 7)}
        />
      </StyledTabs>
    </Container>
  );
};

export default Tabs;
