import { createSelector } from "reselect";
import {
  calculateStamina,
  calculateManagerSLP,
  calculateMMR,
  calculateNumberOfAxies,
  calculateScholarSLP,
  calculateTotalSLP,
  scholarMatchesSearchQuery,
  calculateNumberOfBattlesYesterday,
  calculateInvestorSLP,
  getDailyChartData,
  getScholarRankInGuild,
  getScholarRankInTeam,
  scholarIsActive,
  calculateRank,
  calculateNumRank,
  calculateTier,
  getScholarMoonshards,
  countAxiesByClass,
} from "helpers/scholars";
import {
  getWinSLPRewardForRankTier,
  getBattleSummary,
  getVictoryStarsChartData,
  getFavoriteTeams,
  createRankTierString,
  augmentBattleData,
  getBattleStats,
} from "helpers/arena";
import moment from "moment";
import { getScholarTeamName } from "helpers/team";
import { columnDefinitions } from "pages/GuildDashboard/pages/Scholars/helpers/columnDefinitions";
import {
  getScholarToModifyIds,
  getTeamlessScholars,
  getGuildTeams,
  getGuildSearchQuery,
  getActiveGuildTeamId,
  getAllScholars,
  getColumnPreferences,
  getActiveScholarRoninAddress,
  getScholarPVPBattlesCurrentPage,
  getAxieData,
} from "./common";
import { getAllCharms, getAllRunes } from "store/data/dataSelectors";

export const applySearchFilter = (scholars, searchQuery) =>
  scholars.filter((scholar) => scholarMatchesSearchQuery(scholar, searchQuery));

export const getScholarToModify = createSelector(
  [getScholarToModifyIds, getTeamlessScholars, getGuildTeams],
  (scholarToModifyIds, teamlessScholars, teams) => {
    let scholar;

    if (
      scholarToModifyIds.roninAddress != null &&
      scholarToModifyIds.guildTeamId != null
    ) {
      if (scholarToModifyIds.guildTeamId === 0) {
        scholar = teamlessScholars.find(
          (scholar) => scholar.roninAddress === scholarToModifyIds.roninAddress
        );
      } else {
        let team = teams.find(
          (team) => team.guildTeamId === scholarToModifyIds.guildTeamId
        );
        scholar = team.scholars.find(
          (scholar) => scholar.roninAddress === scholarToModifyIds.roninAddress
        );
      }
    }
    return scholar;
  }
);

export const getScholarsToDisplay = createSelector(
  [
    getGuildSearchQuery,
    getGuildTeams,
    getActiveGuildTeamId,
    getAllScholars,
    getColumnPreferences,
  ],
  (searchQuery, teams, activeGuildTeamId, allScholars, columnPreferences) => {
    const currentTimestamp = moment(Date.now()).unix();
    let scholars = [];
    let watchedScholars = [];
    let prevPath;

    scholars = allScholars;

    if (!activeGuildTeamId || activeGuildTeamId === 0) {
      prevPath = "/guild-dashboard/scholars";
      scholars = applySearchFilter(allScholars, searchQuery);
    } else {
      prevPath = `/guild-dashboard/team/${activeGuildTeamId}`;
      let team = teams.find((team) => team.guildTeamId === activeGuildTeamId);
      if (!team) {
        return {
          scholars: [],
          watchedScholars: [],
        };
      }
      scholars = applySearchFilter(
        team.scholars ? team.scholars : [],
        searchQuery
      );
    }

    watchedScholars = scholars.filter(
      (scholar) => scholar?.isOnWatchList === 1
    );

    // Get table rows & columns
    const rowsSLP =
      scholars &&
      scholars.map((scholar) => ({
        id: scholar.roninAddress,
        payoutAddress: scholar.payoutAddress,
        name: scholar.name,
        dob: scholar.dob,
        guildTeamId: scholar.guildTeamId,
        guildTeamName: getScholarTeamName(scholar, teams),
        totalSLP: calculateTotalSLP(scholar),
        managerSLP: calculateManagerSLP(scholar),
        investorSLP: calculateInvestorSLP(scholar),
        axieClassCounts: countAxiesByClass(scholar),
        totalAxies: (scholar.axies || []).length,
        fetchingAxies: scholar?.fetchingAxies,
        stamina: calculateStamina(scholar),
        deleting: scholar.deleting,
        slpTimestamp: scholar.SLPTimestamp,
        dailyBattleHistory: scholar.dailyBattleHistory,
        isActive: scholarIsActive(scholar),
        moonshards: getScholarMoonshards(scholar),
      }));

    const rowsArena =
      scholars &&
      scholars.map((scholar) => {
        const rank = calculateRank(scholar);
        const tier = calculateTier(scholar);
        const mmr = calculateMMR(scholar);

        return {
          id: scholar.roninAddress,
          payoutAddress: scholar.payoutAddress,
          name: scholar.name,
          dob: scholar.dob,
          guildTeamId: scholar.guildTeamId,
          guildTeamName: getScholarTeamName(scholar, teams),
          mmr,
          numRank: calculateNumRank(scholar),
          axieClassCounts: countAxiesByClass(scholar),
          totalAxies: (scholar.axies || []).length,
          fetchingAxies: scholar?.fetchingAxies,
          stamina: calculateStamina(scholar),
          deleting: scholar.deleting,
          numberOfBattles: calculateNumberOfBattlesYesterday(scholar),
          isActive: scholarIsActive(scholar),
          dailyBattleHistory: scholar.dailyBattleHistory,
          slpPerWin: getWinSLPRewardForRankTier(rank, tier, mmr),
          rankTier: createRankTierString(rank, tier),
        };
      });

    const rowsWatchlist =
      watchedScholars &&
      watchedScholars.map((scholar) => {
        const rank = calculateRank(scholar);
        const tier = calculateTier(scholar);
        const mmr = calculateMMR(scholar);

        return {
          id: scholar.roninAddress,
          payoutAddress: scholar.payoutAddress,
          name: scholar.name,
          dob: scholar.dob,
          guildTeamId: scholar.guildTeamId,
          guildTeamName: getScholarTeamName(scholar, teams),
          mmr,
          totalSLP: calculateTotalSLP(scholar),
          managerSLP: calculateManagerSLP(scholar),
          investorSLP: calculateInvestorSLP(scholar),
          axieClassCounts: countAxiesByClass(scholar),
          totalAxies: (scholar.axies || []).length,
          fetchingAxies: scholar?.fetchingAxies,
          stamina: calculateStamina(scholar),
          deleting: scholar.deleting,
          slpTimestamp: scholar.SLPTimestamp,
          isActive: scholarIsActive(scholar),
          numberOfBattles: calculateNumberOfBattlesYesterday(scholar),
          dailyBattleHistory: scholar.dailyBattleHistory,
          moonshards: getScholarMoonshards(scholar),
          slpPerWin: getWinSLPRewardForRankTier(rank, tier, mmr),
          rankTier: createRankTierString(rank, tier),
        };
      });

    const columnsToShowSLP = [
      {
        field: "watch",
        show: true,
      },
      {
        field: "name",
        show: true,
      },
      {
        field: "totalSLP",
        show: true,
      },
      {
        field: "managerSLP",
        show: true,
      },
      {
        field: "investorSLP",
        show: columnPreferences.showInvestorShare === 1,
      },
      {
        field: "moonshards",
        show: true,
      },
      {
        field: "stamina",
        show: columnPreferences.showEnergy === 1,
      },
      {
        field: "actionButtonsSLP",
        show: true,
      },
      {
        field: "axieClassCounts",
        show: columnPreferences.showAxies === 1,
      },
    ];

    const columnsToShowArena = [
      {
        field: "watch",
        show: true,
      },
      {
        field: "name",
        show: true,
      },
      {
        field: "mmr",
        show: true,
      },
      {
        field: "rankTier",
        show: true,
      },
      {
        field: "numRank",
        show: true,
      },
      {
        field: "stamina",
        show: columnPreferences.showEnergy === 1,
      },
      {
        field: "numberOfBattles",
        show: true,
      },
      {
        field: "actionButtonsArena",
        show: true,
      },
      {
        field: "axieClassCounts",
        show: columnPreferences.showAxies === 1,
      },
    ];

    const columnsToShowWatchlist = [
      {
        field: "watch",
        show: true,
      },
      {
        field: "name",
        show: true,
      },
      {
        field: "mmr",
        show: true,
      },
      {
        field: "rankTier",
        show: true,
      },
      {
        field: "totalSLP",
        show: true,
      },
      {
        field: "managerSLP",
        show: true,
      },
      {
        field: "investorSLP",
        show: columnPreferences.showInvestorShare === 1,
      },
      {
        field: "moonshards",
        show: true,
      },
      {
        field: "stamina",
        show: columnPreferences.showEnergy === 1,
      },
      {
        field: "numberOfBattles",
        show: true,
      },
      {
        field: "actionButtonsSLP",
        show: true,
      },
      {
        field: "axieClassCounts",
        show: columnPreferences.showAxies === 1,
      },
    ];

    const columnsSLP = columnsToShowSLP.reduce((arr, column) => {
      if (column.show) {
        arr.push(
          columnDefinitions(scholars, currentTimestamp, prevPath)[column.field]
        );
      }
      return arr;
    }, []);

    const columnsArena = columnsToShowArena.reduce((arr, column) => {
      if (column.show) {
        arr.push(
          columnDefinitions(scholars, currentTimestamp, prevPath)[column.field]
        );
      }
      return arr;
    }, []);

    const columnsWatchlist = columnsToShowWatchlist.reduce((arr, column) => {
      if (column.show) {
        arr.push(
          columnDefinitions(watchedScholars, currentTimestamp, prevPath)[
            column.field
          ]
        );
      }
      return arr;
    }, []);

    return {
      scholars,
      watchedScholars,
      rowsSLP,
      columnsSLP,
      rowsArena,
      columnsArena,
      rowsWatchlist,
      columnsWatchlist,
    };
  }
);

export const getActiveScholarData = createSelector(
  [
    getActiveScholarRoninAddress,
    getGuildTeams,
    getAllScholars,
    getScholarPVPBattlesCurrentPage,
    getAxieData,
    getAllRunes,
    getAllCharms,
  ],
  (
    activeScholarRoninAddress,
    teams,
    allScholars,
    pvpBattlesCurrentPage,
    axieData,
    allRunes,
    allCharms
  ) => {
    const scholar = [...allScholars].find(
      (scholar) => scholar.roninAddress === activeScholarRoninAddress
    );

    if (!scholar) {
      return undefined;
    }

    const scholarRankInGuild = getScholarRankInGuild(
      scholar.roninAddress,
      allScholars
    );
    const scholarRankInTeam = getScholarRankInTeam(
      scholar.roninAddress,
      scholar.guildTeamId,
      teams
    );
    const dailyChartData = getDailyChartData(scholar);

    const battles = augmentBattleData(
      scholar.liveData?.battles?.pvp || [],
      allRunes,
      allCharms,
      axieData
    );

    const battleSummary = getBattleSummary(battles, pvpBattlesCurrentPage);
    const mmr = calculateMMR(scholar);

    // Create combined axie data object with scholar's axies
    const combinedAxieData = { ...axieData };
    if (scholar.axies) {
      for (var i = 0, n = scholar.axies.length; i < n; i++) {
        combinedAxieData[scholar.axies[i].axieId] = scholar.axies[i];
      }
    }

    return {
      ...scholar,
      chartData: dailyChartData.chartData,
      winRate: dailyChartData.winRate,
      battleSummary: battleSummary,
      favoriteTeam: getFavoriteTeams(battles),
      arenaChartData: getVictoryStarsChartData(battles),
      mmrTeamRank: scholarRankInTeam.mmr,
      mmrGuildRank: scholarRankInGuild.mmr,
      teamName: getScholarTeamName(scholar, teams),
      numberOfAxies: calculateNumberOfAxies(scholar),
      stamina: calculateStamina(scholar),
      totalSLP: calculateTotalSLP(scholar),
      investorSLP: calculateInvestorSLP(scholar),
      managerSLP: calculateManagerSLP(scholar),
      scholarSLP: calculateScholarSLP(scholar),
      mmr,
      rank: calculateRank(scholar),
      tier: calculateTier(scholar),
      slpPerWin: getWinSLPRewardForRankTier(mmr),
      moonshards: getScholarMoonshards(scholar),
      axieData: combinedAxieData,
      battleStats: getBattleStats(battles),
    };
  }
);
