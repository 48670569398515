import { Box } from "@mui/material";
import YouTubeEmbed from "components/YouTubeEmbed/YouTubeEmbed";
import React from "react";

const Video = ({ videoId, title }) => {
  return (
    <Box sx={{ borderRadius: 3, overflow: "hidden" }}>
      <YouTubeEmbed embedId={videoId} title={title} />
    </Box>
  );
};

export default Video;
