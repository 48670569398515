import { Stack, Box, styled } from "@mui/material";
import Name from "../atoms/PlayerName";
import RankNumber from "../atoms/PlayerRankNumber";
import Wrapper from "../atoms/PlayerWrapper";
import RankTier from "../atoms/PlayerRankTier";
import PlayerRankIcon from "../atoms/PlayerRankIcon";
import VictoryStars from "components/Leaderboard/molecules/VictoryStars";
import FavoriteTeam from "./FavoriteTeam";
import { LeaderboardPlayerAugmented, ThemeMode } from "types";
import Avatar from "pages/Player/atoms/Avatar";

const ContentWrapper = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "48px 1fr 100px 256px", // Rank Name Points Axies+Spacing
  gridTemplateAreas: `
    'rank name stars team'
  `,
  gap: theme.spacing(1.5),
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(2),
    gridTemplateColumns: "48px 1fr 100px",
    gridTemplateAreas: `
      'rank name stars'
      '. team team'
    `,
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    gap: theme.spacing(1),
    ".name": {
      alignItems: "center",
    },
  },
}));

interface Props {
  player: LeaderboardPlayerAugmented;
  showCharms: boolean;
  showRune: boolean;
}

const Player = ({ player, showCharms, showRune }: Props) => {
  const { topRank, name, rank, tier, vstar, clientId, favoriteTeam, avatar } =
    player;

  return (
    <Wrapper topRank={topRank} clientId={clientId}>
      <ContentWrapper>
        <RankNumber number={topRank} />

        <Stack
          className="name"
          sx={{
            gridArea: "name",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar encodedAvatar={avatar} width={50} />

            <Stack>
              <Name>{name}</Name>
              <Stack gap={0.5} direction="row" alignItems="center">
                <PlayerRankIcon rank={rank} />
                <RankTier rank={rank} tier={tier} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Box
          sx={{
            gridArea: "stars",
          }}
        >
          <VictoryStars stars={vstar} colorMode={ThemeMode.dark} />
        </Box>

        <Box
          sx={{
            gridArea: "team",
          }}
        >
          <FavoriteTeam
            favoriteTeam={favoriteTeam}
            showCharms={showCharms}
            showRune={showRune}
          />
        </Box>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Player;
