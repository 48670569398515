import { Stack, Typography } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import Text from "components/Typography/Text";
import styled from "@emotion/styled";
import { FaCheck } from "react-icons/fa";
import { ImArrowUp, ImCross } from "react-icons/im";

// check dark mode colors- add in theme
const Level = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  color: "#C595FF",
  lineHeight: 1.6,
}));

const Axp = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  color: "#52B6CB",
}));

const NextLevel = ({ nextOnchainLevel, axpToLevelUp, shouldAscend }) => {
  return (
    <Stack justifyContent="flex-start" gap={1}>
      <SectionLabel>Next Level</SectionLabel>

      <Stack alignItems="flex-start" gap={1}>
        <Text>Next On-Chain Level</Text>
        <Stack direction="row" alignItems="center">
          <Level>
            <ImArrowUp />
            {nextOnchainLevel}
          </Level>
        </Stack>
      </Stack>
      <Stack alignItems="flex-start" gap={1}>
        <Text>AXP Until Next Level</Text>
        <Stack direction="row" alignItems="center">
          <Axp>
            <ImArrowUp />
            {axpToLevelUp}
          </Axp>
        </Stack>
      </Stack>
      {shouldAscend ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          py={0.5}
          sx={{ background: "#6cc000", borderRadius: 4 }}
        >
          <FaCheck color="#ffff" />
          <Text color="#ffff">Can Ascend</Text>
        </Stack>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          py={0.5}
          sx={{ background: "#ff5341", borderRadius: 4 }}
        >
          <ImCross fontSize={13} color="#ffff" />
          <Typography color="#ffff" fontSize={12}>
            Can't Ascend Yet
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default NextLevel;
