export const speed = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.682 9.00015H72.2769C72.3957 8.99882 72.5129 9.02674 72.618 9.08126C72.723 9.13579 72.8127 9.21539 72.8788 9.31265C72.9449 9.4099 72.9855 9.52171 72.9968 9.63828C73.0081 9.75485 72.9897 9.87241 72.9434 9.98025L60.2323 41.6756C60.1891 41.7836 60.1732 41.9001 60.1859 42.0155C60.1985 42.1309 60.2393 42.2416 60.3049 42.3379C60.3704 42.4343 60.4587 42.5137 60.5622 42.569C60.6656 42.6244 60.7811 42.6541 60.8987 42.6557H71.9013C72.0343 42.6573 72.1643 42.6948 72.2772 42.7642C72.39 42.8336 72.4814 42.9322 72.5413 43.0493C72.6013 43.1664 72.6276 43.2973 72.6173 43.428C72.607 43.5588 72.5605 43.6845 72.4829 43.791L35.4523 92.7083C35.3538 92.8402 35.2125 92.9349 35.0519 92.9765C34.8913 93.0181 34.7211 93.0041 34.5696 92.9371C34.4181 92.8701 34.2944 92.754 34.2192 92.608C34.1439 92.462 34.1215 92.2949 34.1558 92.1347L41.1839 57.4754C41.2033 57.3713 41.1994 57.2643 41.1726 57.1617C41.1458 57.0592 41.0966 56.9635 41.0286 56.8816C40.9605 56.7997 40.8752 56.7337 40.7786 56.6876C40.682 56.6415 40.5763 56.6167 40.4689 56.6149H28.7151C28.6093 56.6132 28.5053 56.5886 28.4101 56.5432C28.3149 56.4977 28.2309 56.4323 28.164 56.3515C28.097 56.2707 28.0488 56.1765 28.0226 56.0754C27.9964 55.9744 27.9929 55.869 28.0123 55.7664L36.9791 9.51398C37.022 9.36365 37.1143 9.23176 37.2415 9.13875C37.3687 9.04573 37.5237 8.99695 37.682 9.00015V9.00015Z"
      fill="#F7AC09"
    />
  </svg>
);

export const hp = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.4918 87C68.7188 79.7054 84.1314 67.2524 91.2471 54.6432C98.2533 42.086 97.0173 29.5809 90.7774 22.0779C85.1396 15.3564 77.805 12.8033 70.3609 13.0117C62.9168 13.2201 55.6917 18.6068 50.4918 22.9315C45.2919 18.6068 38.0668 13.2201 30.6227 13.0117C23.1786 12.8033 15.844 15.3564 10.2062 22.0779C3.96636 29.5809 2.73035 42.086 9.84601 54.6432C16.8522 67.2524 32.2648 79.7054 50.4918 87Z"
      fill="#7BBC85"
    />
  </svg>
);

export const morale = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78.2868 50.6739L78.2169 50.5572C76.3465 45.4531 73.1277 40.953 68.9038 37.5366C64.4684 33.4451 49.5208 18.6409 54.7129 9.56026C54.7549 9.49636 54.7757 9.4207 54.7721 9.34426C54.7684 9.26781 54.7407 9.19443 54.6928 9.1348C54.6449 9.07516 54.5793 9.03234 54.5055 9.01242C54.4317 8.9925 54.3535 8.99664 54.2821 9.02409C49.6256 11.064 35.1669 23.4552 43.0714 31.7199C50.976 39.9846 43.0714 31.7199 43.0714 31.7199H43.0016C44.214 32.6122 45.1531 33.8262 45.7126 35.2249C46.2722 36.6236 46.43 38.1512 46.1681 39.6349C45.7563 41.4912 44.71 43.1453 43.21 44.3113C41.7099 45.4773 39.8503 46.0819 37.9523 46.0211C36.0543 45.9603 34.2371 45.2377 32.8145 43.9781C31.392 42.7184 30.4535 41.0009 30.1611 39.1221C30.1587 39.0624 30.1393 39.0047 30.1053 38.9556C30.0714 38.9065 30.0242 38.8681 29.9693 38.8449C29.9143 38.8217 29.854 38.8146 29.7952 38.8244C29.7364 38.8343 29.6816 38.8605 29.6372 38.9004C25.6509 42.5897 22.739 47.2931 21.2118 52.5089C19.6846 57.7248 19.5993 63.258 20.965 68.5186C22.3308 73.7791 25.0964 78.5699 28.9671 82.3807C32.8378 86.1914 37.6686 88.8791 42.9446 90.1574C48.2206 91.4357 53.7441 91.2568 58.9265 89.6395C64.1089 88.0221 68.756 85.0271 72.3724 80.9737C75.9889 76.9202 78.4392 71.9604 79.4623 66.6224C80.4854 61.2843 80.043 55.7683 78.1821 50.6622L78.2868 50.6739Z"
      fill="#C23A3A"
    />
  </svg>
);

export const skill = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68.3869 30.2934L92.3201 38.4755C92.6906 38.6083 93.0251 38.8255 93.297 39.1095C93.569 39.3936 93.7711 39.7367 93.8872 40.1121C94.0033 40.4876 94.0302 40.8849 93.9659 41.2725C93.9016 41.6602 93.7478 42.0276 93.5167 42.3457L78.7739 62.8316C78.4724 63.2543 78.3056 63.758 78.2953 64.2769L77.8884 89.4926C77.8844 89.8885 77.7876 90.2782 77.6056 90.63C77.4235 90.9817 77.1614 91.2859 76.8402 91.5183C76.519 91.7506 76.1478 91.9045 75.7563 91.9677C75.3647 92.0308 74.9637 92.0012 74.5856 91.8816L50.5328 84.2489C50.0381 84.0939 49.5077 84.0939 49.013 84.2489L24.8525 91.6547C24.4757 91.7724 24.0766 91.8003 23.6871 91.7361C23.2977 91.6719 22.9287 91.5176 22.6099 91.2853C22.2911 91.0529 22.0312 90.7491 21.8513 90.3984C21.6713 90.0477 21.5762 89.6598 21.5736 89.2657L21.442 63.9424C21.4425 63.4254 21.2838 62.9209 20.9873 62.4971L6.45984 41.8561C6.23268 41.5327 6.08444 41.1608 6.02694 40.77C5.96944 40.3793 6.00427 39.9808 6.12868 39.6059C6.2531 39.231 6.46362 38.8903 6.74345 38.6111C7.02327 38.3319 7.36465 38.1219 7.74027 37.9978L31.7333 30.0662C32.2124 29.899 32.6296 29.5908 32.9299 29.1823L48.1155 9.01923C48.3516 8.7029 48.6586 8.44585 49.0118 8.26888C49.365 8.09191 49.7547 8 50.1499 8C50.545 8 50.9348 8.09191 51.288 8.26888C51.6412 8.44585 51.9481 8.7029 52.1842 9.01923L67.1544 29.3258C67.4526 29.7701 67.884 30.1089 68.3869 30.2934V30.2934Z"
      fill="#693AC2"
    />
  </svg>
);

export const purityPercent = (color) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.3333 69C75.3333 67.2847 74.7066 65.8003 73.4531 64.5469C72.1997 63.2934 70.7153 62.6667 69 62.6667C67.2847 62.6667 65.8003 63.2934 64.5469 64.5469C63.2934 65.8003 62.6667 67.2847 62.6667 69C62.6667 70.7153 63.2934 72.1997 64.5469 73.4531C65.8003 74.7066 67.2847 75.3333 69 75.3333C70.7153 75.3333 72.1997 74.7066 73.4531 73.4531C74.7066 72.1997 75.3333 70.7153 75.3333 69ZM37.3333 31C37.3333 29.2847 36.7066 27.8003 35.4531 26.5469C34.1997 25.2934 32.7153 24.6667 31 24.6667C29.2847 24.6667 27.8003 25.2934 26.5469 26.5469C25.2934 27.8003 24.6667 29.2847 24.6667 31C24.6667 32.7153 25.2934 34.1997 26.5469 35.4531C27.8003 36.7066 29.2847 37.3333 31 37.3333C32.7153 37.3333 34.1997 36.7066 35.4531 35.4531C36.7066 34.1997 37.3333 32.7153 37.3333 31ZM88 69C88 74.2448 86.1445 78.7227 82.4336 82.4336C78.7227 86.1445 74.2448 88 69 88C63.7552 88 59.2773 86.1445 55.5664 82.4336C51.8555 78.7227 50 74.2448 50 69C50 63.7552 51.8555 59.2773 55.5664 55.5664C59.2773 51.8555 63.7552 50 69 50C74.2448 50 78.7227 51.8555 82.4336 55.5664C86.1445 59.2773 88 63.7552 88 69ZM83.25 15.1667C83.25 15.8264 83.0356 16.4531 82.6068 17.0469L30.3568 86.7135C29.73 87.5712 28.8889 88 27.8333 88H19.9167C19.059 88 18.3168 87.6866 17.6901 87.0599C17.0634 86.4332 16.75 85.691 16.75 84.8333C16.75 84.1736 16.9644 83.5469 17.3932 82.9531L69.6432 13.2865C70.27 12.4288 71.1111 12 72.1667 12H80.0833C80.941 12 81.6832 12.3134 82.3099 12.9401C82.9366 13.5668 83.25 14.309 83.25 15.1667ZM50 31C50 36.2448 48.1445 40.7227 44.4336 44.4336C40.7227 48.1445 36.2448 50 31 50C25.7552 50 21.2773 48.1445 17.5664 44.4336C13.8555 40.7227 12 36.2448 12 31C12 25.7552 13.8555 21.2773 17.5664 17.5664C21.2773 13.8555 25.7552 12 31 12C36.2448 12 40.7227 13.8555 44.4336 17.5664C48.1445 21.2773 50 25.7552 50 31Z"
      fill={color}
    />
  </svg>
);
