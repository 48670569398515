import CustomLink from "components/CustomLink/CustomLink";
import { useTheme } from "@mui/material";
import { TRIBALLY_PLATFORM_URL } from "config";

const TriballyTeamBuilderBanner = () => {
  const theme = useTheme();
  return (
    <CustomLink
      style={{
        width: 650,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
      target="_blank"
      href={`${TRIBALLY_PLATFORM_URL}community/axie-infinity-origins/tools/team-builder`}
    >
      <img
        style={{
          borderRadius: 16,
          width: "100%",
          border: `8px solid ${theme.palette.card.background}`,
        }}
        src="/images/tribally-banner-assets/backgrounds/team-builder.jpg"
        alt="Origins Team Builder On Tribally Platform"
      />
    </CustomLink>
  );
};

export default TriballyTeamBuilderBanner;
