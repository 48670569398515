import React from "react";
import { Stack, Typography } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import Text from "components/Typography/Text";

const CurrentPrice = ({ currentPrice }) => {
  if (currentPrice?.eth == null) return null;

  return (
    <Stack gap={2}>
      <SectionLabel>Current Price</SectionLabel>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          variant="span"
          color="text.bright"
          fontSize={24}
          fontWeight={700}
        >
          {currentPrice.eth}
        </Typography>
        <Text color="text.muted">{currentPrice.usd}</Text>
      </Stack>
    </Stack>
  );
};

export default CurrentPrice;
