import {
  Dialog as MuiDialog,
  FormLabel,
  Typography,
  useTheme,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "../hooks/useForm";
import DialogForm from "../molecules/DialogForm";
import DialogGuildActions from "../molecules/DialogGuildActions";
import DialogContent from "../molecules/DialogContent";
import { validate0xAddress } from "helpers/cleaning";
import Input from "../atoms/Input";
import RadioGroup from "../atoms/RadioGroup";
import ManagePFP from "../molecules/ManagePFP";
import DialogHeader from "../molecules/DialogHeader";

const initialValues = {
  guildId: null,
  name: "",
  logoUri: "",
  profileImage: "",
  profileImageFullUrl: "",
  loginAddress: "",
  isEnabled: 1,
  isIndesCoached: 0,
  isAMS: 0,
};

const statuses = [
  { id: 1, title: "Active" },
  { id: 0, title: "Inactive" },
];

const Dialog = ({
  open,
  setOpen,
  recordForEdit,
  dialogTitle,
  addOrEditFn,
  status,
  guilds,
  requestPresignedUrl,
}) => {
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    isEditingAvatar,
    setIsEditingAvatar,
  } = useForm(initialValues);

  const theme = useTheme();

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit, setValues]);

  const validate = () => {
    let err = { ...errors };

    if ("name" in values) {
      err.name = values.name !== "" ? "" : "Name is required";
    }
    if ("logoUri" in values) {
      err.logoUri = verifyUniqueLogoUri(values.logoUri, values.guildId)
        ? ""
        : "A unique page slug is required";
    }
    if ("loginAddress" in values) {
      err.loginAddress = validate0xAddress(values.loginAddress)
        ? ""
        : "Bad ETH address";
    }
    // If we are updating a guild, the profile image is required
    if (values.guildId && "profileImage" in values) {
      err.profileImage =
        values.profileImage && values.profileImage !== ""
          ? ""
          : "Avatar is required";
    }
    setErrors({ ...err });
    return Object.values(err).every((x) => x === "");
  };

  const verifyUniqueLogoUri = (logoUri, guildId) => {
    if (!logoUri || logoUri === "") {
      return false;
    }
    if (guilds.find((g) => g.logoUri === logoUri && g.guildId !== guildId)) {
      return false;
    }
    return true;
  };

  const cleanData = (data) => {
    return {
      ...data,
      isEnabled: parseInt(data.isEnabled),
    };
  };

  const handleUpdateAvatar = (presignedUrl, filename, dataUri) => {
    setValues({
      ...values,
      profileImage: filename,
      avatar: {
        presignedUrl,
        filename,
        dataUri,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = cleanData(values);
      addOrEditFn(formData, resetForm);
    }
  };

  const handleClose = () => {
    setIsEditingAvatar(false);
    setOpen(false);
    resetForm();
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth={"lg"}
      sx={{ ".MuiPaper-root": { minWidth: 520 } }}
    >
      <DialogHeader dialogTitle={dialogTitle} guildId={values.guildId} />
      <DialogForm onSubmit={handleSubmit}>
        <DialogContent>
          <Input
            onChange={handleInputChange}
            label="Name"
            name="name"
            value={values.name}
            error={errors.name}
          />
          <Input
            onChange={handleInputChange}
            label="Page Slug"
            name="logoUri"
            value={values.logoUri}
            error={errors.logoUri}
          />
          <Input
            onChange={handleInputChange}
            label="Login Address"
            name="loginAddress"
            value={values.loginAddress}
            error={errors.loginAddress}
          />
          <RadioGroup
            onChange={handleInputChange}
            label="Status"
            name="isEnabled"
            value={values.isEnabled}
            items={statuses}
          />
          {values.guildId != null && (
            <>
              <Stack gap={1}>
                <FormLabel>Avatar</FormLabel>
                <Typography
                  variant="span"
                  fontWeight={600}
                  fontSize="0.75rem"
                  color={theme.palette.feedback.red}
                >
                  {errors.profileImage}
                </Typography>
              </Stack>
              <ManagePFP
                profileImageFullUrl={values.profileImageFullUrl}
                alt={values.name}
                guildId={values.guildId}
                width={80}
                requestPresignedUrl={requestPresignedUrl}
                handleUpdateAvatar={handleUpdateAvatar}
                isEditingAvatar={isEditingAvatar}
                setIsEditingAvatar={setIsEditingAvatar}
              />
            </>
          )}
        </DialogContent>
        <DialogGuildActions
          handleEdit={handleSubmit}
          handleClose={handleClose}
          status={status}
          isEditingAvatar={isEditingAvatar}
        />
      </DialogForm>
    </MuiDialog>
  );
};

export default Dialog;
