import { Typography, SxProps } from "@mui/material";
import fonts from "theme/fonts";

interface Props {
  children: React.ReactNode;
  fontSize?: number;
  styles?: SxProps;
}

const Title = ({ children, fontSize, styles = {} }: Props) => (
  <Typography
    sx={{ letterSpacing: "0.8px", ...styles }}
    fontFamily={fonts.changa}
    fontWeight={700}
    fontSize={fontSize ? fontSize : 24}
    variant="h2"
    color="text.bright"
  >
    {children}
  </Typography>
);

export default Title;
