import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Stack } from "@mui/material";
import DecorativePattern from "./atoms/DecorativePattern";
import {
  toggleItem,
  bulkAddItems,
  clearItems,
} from "store/craftingCollection/craftingCollectionActions";
import Header from "./organisms/Header";
import {
  getCollectionStats,
  getShareLink,
} from "store/craftingCollection/craftingCollectionSelectors";
import CustomLoader from "components/CustomLoader/CustomLoader";
import LoadedView from "./organisms/LoadedView";
import { useLocation } from "react-router-dom";
import {
  getAllUniqueCharmsForCurrentSeason,
  getAllUniqueRunesForCurrentSeason,
} from "store/data/dataSelectors";

const CraftingCollection = ({
  runes,
  charms,
  collection,
  toggleItem,
  stats,
  shareLink,
  loading,
  failed,
  bulkAddItems,
  clearItems,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [view, setView] = useState("SCREENSHOT");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  useEffect(() => {
    const addItems = (items, data, itemType, callback) => {
      if (items !== "null" && items !== "") {
        const itemData = items.split(",").reduce((arr, item) => {
          const parsedItem = parseInt(item);

          if (!isNaN(parsedItem)) {
            const current = data.find((i) => i.id === parsedItem);
            if (current != null) {
              arr.push({ ...current, itemType });
            }
          }

          return arr;
        }, []);
        callback(itemData);
      }
    };

    if (initialLoad && loading === false && failed === false) {
      setInitialLoad(false);

      const charmIds = decodeURI(query.get("charms"));
      const runeIds = decodeURI(query.get("runes"));

      addItems(runeIds, runes, "rune", bulkAddItems);
      addItems(charmIds, charms, "charm", bulkAddItems);
    }
  }, [initialLoad, loading, failed, query, charms, runes, bulkAddItems]);

  return (
    <Stack
      gap={{ xs: 2, sm: 2, md: 4 }}
      sx={{ pt: 4, pb: 8, px: 4, position: "relative" }}
    >
      <DecorativePattern />

      <Header
        stats={stats}
        shareLink={shareLink}
        view={view}
        setView={setView}
        clearItems={clearItems}
      />

      {loading ? (
        <CustomLoader variant="origin" marginTop={200} marginBottom={200} />
      ) : (
        <LoadedView
          charms={charms}
          runes={runes}
          stats={stats}
          collection={collection}
          toggleItem={toggleItem}
          clearItems={clearItems}
          view={view}
        />
      )}
    </Stack>
  );
};

const mapDispatchToProps = {
  toggleItem,
  bulkAddItems,
  clearItems,
};

const mapStateToProps = (state) => ({
  loading: state.data.fetchingAxieCardsV3,
  failed: state.data.fetchingAxieCardsV3Failed,
  runes: getAllUniqueRunesForCurrentSeason(state),
  charms: getAllUniqueCharmsForCurrentSeason(state),
  collection: state.craftingCollection.collection,
  stats: getCollectionStats(state),
  shareLink: getShareLink(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CraftingCollection);
