import React, { useState } from "react";
import PageTitleWithChips from "pages/GuildDashboard/molecules/PageTitleWithChips";
import Search from "pages/GuildDashboard/atoms/Search";
import Sort from "pages/GuildDashboard/atoms/Sort";
import { Stack, Chip } from "@mui/material";
import FilterPopupButton from "pages/GuildDashboard/atoms/FilterPopupButton";
import FiltersMenu from "./FiltersMenu";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(4),
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const Controls = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Header = ({ title, controls, updateControls }) => {
  const [inventoryFiltersEl, setInventoryFiltersEl] = useState(null);
  const inventoryFiltersOpen = Boolean(inventoryFiltersEl);
  const activeChip = controls.filters.itemTypes;

  return (
    <>
      <FiltersMenu
        isOpen={inventoryFiltersOpen}
        anchorEl={inventoryFiltersEl}
        setAnchorEl={setInventoryFiltersEl}
        controls={controls}
        updateControls={updateControls}
      />
      <Wrapper>
        <PageTitleWithChips title={title.text}>
          <Chip
            variant={activeChip.length === 0 ? "contained" : "outlined"}
            color="primary"
            size="small"
            label={title.chips["All"]}
            onClick={() =>
              updateControls("filters", {
                ...controls.filters,
                itemTypes: [],
              })
            }
          />
          <Chip
            variant={activeChip.includes("Rune") ? "contained" : "outlined"}
            color="primary"
            size="small"
            label={title.chips["Rune"]}
            onClick={() =>
              updateControls("filters", {
                ...controls.filters,
                itemTypes: ["Rune"],
              })
            }
          />
          <Chip
            variant={activeChip.includes("Charm") ? "contained" : "outlined"}
            color="primary"
            size="small"
            label={title.chips["Charm"]}
            onClick={() =>
              updateControls("filters", {
                ...controls.filters,
                itemTypes: ["Charm"],
              })
            }
          />
        </PageTitleWithChips>
        <Controls>
          <FilterPopupButton
            id="filter-inventory-menu"
            isOpen={inventoryFiltersOpen}
            updateIsOpen={(e) => setInventoryFiltersEl(e.currentTarget)}
          />
          <Sort
            sort={controls.sortType}
            updateSort={(e) => updateControls("sortType", e.target.value)}
            sortOptions={controls.sortOptions}
          />
          <Search
            placeholder="Search"
            value={controls.searchQuery}
            updateValue={(v) => updateControls("searchQuery", v)}
          />
        </Controls>
      </Wrapper>
    </>
  );
};

export default Header;
