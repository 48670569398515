import React from "react";
import { Box } from "@mui/material";
import { getGuildLogoURL } from "helpers/guild";
import styled from "@emotion/styled";
import CustomLink from "components/CustomLink/CustomLink";

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ width }) => ({
  width,
  height: width,
  padding: 2,
  img: {
    width,
    height: width,
    borderRadius: "50%",
  },
}));

const GuildLogoImg = ({ width, guildImage, guildLink, guildName }) => {
  return (
    <CustomLink to={`/guild/${guildLink}`}>
      <Wrapper>
        <img
          width={width}
          src={getGuildLogoURL(guildImage)}
          alt={`${guildName} Guild Logo`}
          title={`${guildName} Guild Logo`}
        />
      </Wrapper>
    </CustomLink>
  );
};

export default GuildLogoImg;
