import React from "react";
import styled from "@emotion/styled";
import { Stack, useTheme } from "@mui/material";
import CardArt from "./atoms/CardArt";
import CardInfo from "./atoms/CardInfo";
import BodyPart from "./atoms/BodyPart";
import Tooltip from "./atoms/Tooltip";
import ClassIcon from "./atoms/ClassIcon";

const Wrapper = styled(Stack)(({ theme, width }) => ({
  width: "100%",
  height: "100%",
  borderRadius: `${(width / 200) * 8}px `,
  overflow: "hidden",
  position: "relative",
  userSelect: "none",
}));

const Container = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["width", "hover", "borderColor"].includes(prop),
})(({ theme, width, hover, borderColor }) => ({
  width,
  height: `${(width / 200) * 288}px `,
  borderRadius: `${(width / 200) * 12}px `,
  overflow: "hidden",
  border: `solid ${(width / 200) * 3}px ${borderColor}`,
  filter: `drop-shadow(0px ${(width / 200) * 2}px ${
    (width / 200) * 5
  }px rgba(6, 26, 30, 0.18))`,
  transition: "0.2s all ease",

  ...(hover && {
    "&:hover": {
      filter: `drop-shadow(0px ${(width / 200) * 2}px ${
        (width / 200) * 10
      }px rgba(6, 26, 30, 0.2))`,
      transform: "scale(1.02)",
    },
  }),
}));

const Gradient = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }) => ({
  backgroundImage: `linear-gradient(180deg, transparent 40%, ${color} 54%)`,
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
}));

const DollCard = ({
  width = 200,
  cardImage,
  bodyPart,
  name,
  description,
  axieClassName = "",
  levelUp,
  hover = true,
  selected = false,
  borderColor = "#ffffff",
}) => {
  const theme = useTheme();

  const axieClass = axieClassName.toLowerCase();
  const gradientColor =
    theme.palette.colors.doll.axieClass[axieClass] || "#000";

  return (
    <Container width={width} hover={hover} borderColor={borderColor}>
      <Wrapper width={width}>
        <Gradient color={gradientColor} />
        <Stack
          padding="0.45rem"
          textAlign="center"
          width="100%"
          position="absolute"
          direction="row"
          justifyContent="space-between"
          zIndex="2"
        >
          <BodyPart
            bodyPart={bodyPart}
            alt={bodyPart}
            title={bodyPart}
            width={width}
          />
          <Tooltip levelUp={levelUp} width={width} />
        </Stack>
        <CardArt cardImage={cardImage} alt={name} width={width} />
        <CardInfo name={name} description={description} width={width} />
        <ClassIcon axieClassName={axieClassName} />
      </Wrapper>
    </Container>
  );
};

export default DollCard;
