import { Typography } from "@mui/material";
import React from "react";

const Date = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize="20px"
      fontWeight="600"
      color="text.muted"
    >
      {children}
    </Typography>
  );
};

export default Date;
