import * as breedingActions from "./breedingSimulatorActions";
import * as types from "./breedingSimulatorActionTypes";
import { AXIE_CLASSES } from "config";

export const addBreedingAxieId = (axieId) => ({
  type: types.ADD_BREEDING_AXIE_ID,
  axieId,
});

export const fetchAxie = (axieId) => ({
  type: types.FETCH_AXIE,
  axieId,
});

export const fetchAxieStarted = (axieId) => ({
  type: types.FETCH_AXIE_STARTED,
  axieId,
});

export const fetchAxieSucceeded = (data) => ({
  type: types.FETCH_AXIE_SUCCEEDED,
  data,
});

export const fetchAxieFailed = (axieId) => ({
  type: types.FETCH_AXIE_FAILED,
  axieId,
});

export const clearRoninAxies = () => ({
  type: types.CLEAR_RONIN_BREEDING_AXIES,
});

export const fetchAllRoninAxies = (addresses) => ({
  type: types.FETCH_ALL_RONIN_AXIES,
  addresses,
});

export const fetchAllRoninAxiesStarted = () => ({
  type: types.FETCH_ALL_RONIN_AXIES_STARTED,
});

export const fetchAllRoninAxiesSucceeded = () => ({
  type: types.FETCH_ALL_RONIN_AXIES_SUCCEEDED,
});

export const fetchAllRoninAxiesFailed = () => ({
  type: types.FETCH_ALL_RONIN_AXIES_FAILED,
});

export const fetchRoninAxiesForAddressStarted = (address) => ({
  type: types.FETCH_RONIN_AXIES_FOR_ADDRESS_STARTED,
  address,
});

export const fetchRoninAxiesForAddressSucceeded = (axies, address) => ({
  type: types.FETCH_RONIN_AXIES_FOR_ADDRESS_SUCCEEDED,
  axies,
  address,
});

export const fetchRoninAxiesForAddressFailed = (address) => ({
  type: types.FETCH_RONIN_AXIES_FOR_ADDRESS_FAILED,
  address,
});

export const fetchRoninAxieDetail = (axieId) => ({
  type: types.FETCH_RONIN_AXIE_DETAIL,
  axieId,
});

export const fetchRoninAxieDetailSucceeded = (axie) => ({
  type: types.FETCH_RONIN_AXIE_DETAIL_SUCCEEDED,
  axie,
});

export const updateNumberOfBreedsForPair = (pair, numberOfBreeds) => ({
  type: types.UPDATE_NUMBER_OF_BREEDS_FOR_PAIR,
  payload: {
    pair,
    numberOfBreeds,
  },
});

export const deleteBreedingAxiePair = (pair) => ({
  type: types.DELETE_BREEDING_AXIE_PAIR,
  payload: pair,
});

export const deleteBreedingAxieId = (id) => ({
  type: types.DELETE_BREEDING_AXIE_ID,
  payload: id,
});

export const deleteAllUnlockedBreedingAxieIds = () => ({
  type: types.DELETE_ALL_UNLOCKED_BREEDING_AXIE_IDS,
});

export const deleteAllBreedingAxieIds = () => ({
  type: types.DELETE_ALL_BREEDING_AXIE_IDS,
});

export const updateRequiredBreedingId = (id) => ({
  type: types.UPDATE_REQUIRED_BREEDING_ID,
  payload: id,
});

export const toggleLockBreedingPair = (ids) => ({
  type: types.TOGGLE_LOCK_BREEDING_PAIR,
  payload: ids,
});

export const updateCurrentPageUnlocked = (currentPage) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_UNLOCKED,
  currentPage,
});

export const updateCurrentPageLocked = (currentPage) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_LOCKED,
  currentPage,
});

export const updateCurrentPageInvalid = (currentPage) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_INVALID,
  currentPage,
});

export const updateSortType = (sortType) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_SORT_TYPE,
  sortType,
});

export const updateShowOnlyBreedingEventPairs = (
  showOnlyBreedingEventPairs
) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_SHOW_ONLY_BREEDING_EVENT_PAIRS,
  showOnlyBreedingEventPairs,
});

export const updateHideInvalid = (hideInvalid) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_HIDE_INVALID,
  hideInvalid,
});

export const updateCurrentPageSelectAxiesDialog = (currentPage) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_SELECT_AXIES_DIALOG,
  currentPage,
});

export const updateBreedCountSelectAxiesDialog = (breedCount) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_BREED_COUNT_SELECT_AXIES_DIALOG,
  breedCount,
});

export const updatePuritySelectAxiesDialog = (purity) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_PURITY_SELECT_AXIES_DIALOG,
  purity,
});

export const updateAxieClassesSelectAxiesDialog = (axieClasses) => ({
  type: types.UPDATE_BREEDING_SIMULATOR_AXIE_CLASSES_SELECT_AXIES_DIALOG,
  axieClasses,
});

export const updateSelectAllSelectAxiesDialog =
  (selectAll, filteredAxies) => (dispatch, getState) => {
    const currentlySelected =
      getState().breedingSimulator.selectAllSelectAxiesDialog;
    let newSelectAll;

    if (selectAll === "All") {
      if (currentlySelected.length === 0) {
        newSelectAll = [...AXIE_CLASSES];
        dispatch(
          breedingActions.selectAllFilteredRoninAxiesOfClass(filteredAxies, [
            ...AXIE_CLASSES,
          ])
        );
      } else {
        newSelectAll = [];
        dispatch(
          breedingActions.deselectAllFilteredRoninAxiesOfClass(filteredAxies, [
            ...AXIE_CLASSES,
          ])
        );
      }
    } else {
      if (currentlySelected.includes(selectAll)) {
        const filtered = currentlySelected.filter((a) => a !== selectAll);
        newSelectAll = filtered;
        dispatch(
          breedingActions.deselectAllFilteredRoninAxiesOfClass(filteredAxies, [
            selectAll,
          ])
        );
      } else {
        newSelectAll = [...currentlySelected, selectAll];
        dispatch(
          breedingActions.selectAllFilteredRoninAxiesOfClass(
            filteredAxies,
            newSelectAll
          )
        );
      }
    }

    dispatch({
      type: types.UPDATE_BREEDING_SIMULATOR_SELECT_ALL_SELECT_AXIES_DIALOG,
      selectAll: newSelectAll,
    });
  };

export const selectAllFilteredRoninAxiesOfClass = (
  filteredAxies,
  axieClasses
) => ({
  type: types.SELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS,
  filteredAxies,
  axieClasses,
});

export const deselectAllFilteredRoninAxiesOfClass = (
  filteredAxies,
  axieClasses
) => ({
  type: types.DESELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS,
  filteredAxies,
  axieClasses,
});

export const deselectAllRoninAxies = () => ({
  type: types.DESELECT_ALL_RONIN_AXIES,
});

export const selectRoninAxie = (selectedAxie) => ({
  type: types.SELECT_RONIN_AXIE,
  selectedAxie,
});
