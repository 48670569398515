import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-27</Date>
      <Change type="new">
        Scholarship Calculator &amp; Guild Dashboard: Added AUD (A$) as a new
        currency option.
      </Change>
      <Change type="improved">
        Guild Pages: You can now click on "hidden scholars" to view the names of
        any scholars who have been added too recently to display on the
        leaderboard.
      </Change>
    </Entry>
  );
};

export default Changes;
