import * as types from "./actionTypes";
import { put, takeEvery, retry, select } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./actions";
import moment from "moment";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makefetchDollSidekicksRequest() {
  try {
    const lastFetched = yield select(
      (state) => state.doll.sidekicks.lastFetched
    );

    const threshold = moment().subtract(10, "minutes").unix();

    if (lastFetched == null || lastFetched < threshold) {
      yield put(actions.fetchDollSidekicksStarted());
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestAxieDollSidekicks
      );
      const sorted = (data || []).sort((a, b) => a.tokenId - b.tokenId);
      yield put(actions.fetchDollSidekicksSucceeded(sorted));
    }
  } catch (e) {
    yield put(actions.fetchDollSidekicksFailed());
  }
}

export default function* dollSidekicksSaga() {
  yield takeEvery(
    types.DOLL_SIDEKICKS_FETCH_SIDEKICKS,
    makefetchDollSidekicksRequest
  );
}
