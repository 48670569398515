import React from "react";
import SocialIcons from "components/Social/SocialIcons";
import { logo } from "logos.js";
import { Stack, useTheme } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import { AXIE_TECH_URL } from "config";

const SidebarFooter = () => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="center" gap={1} alignItems="center">
      <CustomLink href={AXIE_TECH_URL}>
        <Stack
          sx={{
            transition: "0.15s transform ease",
            position: "relative",
            top: "1px",
            svg: {
              width: "80px",
              height: "auto",
            },
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          {logo(theme.palette.text.muted)}
        </Stack>
      </CustomLink>
      <SocialIcons size="16px" gap={0.5} />
    </Stack>
  );
};

export default SidebarFooter;
