import React from "react";
import SliderWithTitle from "components/CustomSlider/SliderWithTitle";

const FilterAxiePurityPercent = ({ purityPercent, setPurityPercent }) => {
  return purityPercent != null && setPurityPercent != null ? (
    <SliderWithTitle
      val={purityPercent}
      setVal={setPurityPercent}
      title="Purity %"
      step={1}
      min={0}
      max={100}
    />
  ) : (
    <></>
  );
};

export default FilterAxiePurityPercent;
