import React from "react";
import VariantFull from "./VariantFull";
import VariantPreview from "./VariantPreview";

const CardTemplateV2 = ({ card, variant, style, width }) => {
  return variant === "preview" ? (
    <VariantPreview card={card} width={width} />
  ) : (
    <VariantFull card={card} style={style} width={width} />
  );
};

export default CardTemplateV2;
