import { Stack, useTheme } from "@mui/material";
import CustomLink, { Underline } from "components/CustomLink/CustomLink";
import { makePlayerUrl } from "helpers/display";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  topRank: number;
  clientId: string;
}

const PlayerWrapper = ({ children, topRank, clientId }: Props) => {
  const theme = useTheme();
  const outerStyles =
    topRank < 4
      ? {
          backgroundImage:
            "linear-gradient(90.41deg, #FE7061 0%, #FADA5E 45.83%, #D56E3D 100%)",
        }
      : { background: "#4F3629" };

  return (
    <CustomLink
      underline={Underline.NONE}
      to={makePlayerUrl(clientId)}
      style={{
        color: theme.palette.colors.origin.wood.text,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.03)",
        },
        [theme.breakpoints.down("md")]: {
          "&:hover": {
            transform: "none",
          },
        },
      }}
    >
      <Stack
        sx={{
          p: "2px",
          borderRadius: "11px",
          ...outerStyles,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{
            background: theme.palette.colors.origin.wood.input.background,
            borderRadius: "10px",
            padding: "4px 4px 6px 4px",
            position: "relative",
            [theme.breakpoints.down("md")]: {
              padding: "8px 6px",
            },
            [theme.breakpoints.down("sm")]: {
              padding: "8px 16px 16px 16px",
            },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </CustomLink>
  );
};

export default PlayerWrapper;
