import AxieDoLL from "./AxieDoLL";
import Cards from "./Cards";
import Profile from "./Profile";
import RecentSales from "./RecentSales";
import SimilarListings from "./SimilarListings";

const Tabs = {
  AxieDoLL,
  Cards,
  Profile,
  RecentSales,
  SimilarListings,
};

export default Tabs;
