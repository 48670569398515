import * as types from "./recentlyActionTypes";
import produce from "immer";
import { calculatePurityAxieTech } from "helpers/axie";

const initialState = {
  recentlyListed: {
    axies: [],
  },
  recentlySold: {
    axies: [],
  },
};

export const addNewItemsToArray = (currentArray, newArray) => {
  if (!currentArray || currentArray.length === 0) {
    return newArray;
  }
  let matchIndex = newArray.findIndex(
    (el) => el.axieId === currentArray[0].axieId
  );
  if (matchIndex < 0) {
    matchIndex = newArray.length;
  }
  return [...newArray.slice(0, matchIndex), ...currentArray];
};

export const recentlyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_RECENTLY_LISTED_STARTED:
        draft.recentlyListed.fetching = true;
        draft.recentlyListed.failed = false;
        break;

      case types.FETCH_RECENTLY_LISTED_FAILED:
        draft.recentlyListed.fetching = false;
        draft.recentlyListed.failed = true;
        break;

      case types.FETCH_RECENTLY_LISTED_SUCCEEDED: {
        const axiesWithPurity = action.data.axies.map((axie) => ({
          ...axie,
          purity: calculatePurityAxieTech(axie),
        }));
        draft.recentlyListed.fetching = false;
        draft.recentlyListed.axies = addNewItemsToArray(
          draft.recentlyListed.axies,
          axiesWithPurity
        );
        break;
      }

      case types.DELETE_RECENTLY_LISTED_AXIE: {
        draft.recentlyListed.axies = draft.recentlyListed.axies.filter(
          (axie) => axie.axieId !== action.axieId
        );
        break;
      }

      case types.CLEAR_RECENTLY_LISTED:
        draft.recentlyListed.axies = [];
        break;

      case types.FETCH_RECENTLY_SOLD_STARTED:
        draft.recentlySold.fetching = true;
        draft.recentlySold.failed = false;
        break;

      case types.FETCH_RECENTLY_SOLD_FAILED:
        draft.recentlySold.fetching = false;
        draft.recentlySold.failed = true;
        break;

      case types.FETCH_RECENTLY_SOLD_SUCCEEDED: {
        const axiesWithPurity = action.data.axies.map((axie) => ({
          ...axie,
          purity: calculatePurityAxieTech(axie),
        }));
        draft.recentlySold.fetching = false;
        draft.recentlySold.axies = addNewItemsToArray(
          draft.recentlySold.axies,
          axiesWithPurity
        );
        break;
      }

      case types.DELETE_RECENTLY_SOLD_AXIE: {
        draft.recentlySold.axies = draft.recentlySold.axies.filter(
          (axie) => axie.axieId !== action.axieId
        );
        break;
      }

      case types.CLEAR_RECENTLY_SOLD:
        draft.recentlySold.axies = [];
        break;

      default:
        break;
    }
  });

export default recentlyReducer;
