import React from "react";
import styles from "../changelog.module.scss";
import { Typography } from "@mui/material";

const Change = ({ children, type }) => {
  let cls = styles.improved;

  switch (type) {
    case "new":
      cls = styles.new;
      break;
    case "bug":
      cls = styles.bug;
      break;
    case "improved":
    default:
      cls = styles.improved;
      break;
  }
  return (
    <div className={cls}>
      <Typography variant="span">{children}</Typography>
    </div>
  );
};

export default Change;
