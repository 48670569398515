import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  padding: (width / 200) * 16,
  gap: (width / 200) * 2,
}));

const TokenId = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  fontSize: (width / 200) * 22,
  fontWeight: 900,
  color: "#000",
}));

const Info = ({ tokenId, width }) => {
  return (
    <Wrapper width={width}>
      {tokenId != null && (
        <TokenId width={width} variant="span">
          {tokenId}
        </TokenId>
      )}
    </Wrapper>
  );
};

export default Info;
