import { ReactNode } from "react";
import { Link, SxProps, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

interface CustomLinkProps {
  children: ReactNode;
  to?: string;
  hashTo?: string;
  href?: string;
  startSpace?: boolean;
  endSpace?: boolean;
  style?: SxProps;
  underline?: Underline;
  target?: string;
  color?: string;
  title?: string;
  onClick?: () => void;
  className?: string;
}

interface LinkWrappedProps {
  children: ReactNode;
  to?: string;
  hashTo?: string;
  href?: string;
  style?: SxProps;
  underline?: Underline;
  target?: string;
  title?: string;
  onClick?: () => void;
  className?: string;
}

export enum Underline {
  NONE = "none",
  HOVER = "hover",
  ALWAYS = "always",
}

const CustomLink = ({
  children,
  to,
  hashTo,
  href,
  startSpace = true,
  endSpace = true,
  style,
  underline = Underline.NONE,
  target = "_self",
  color,
  title,
  onClick,
  className,
}: CustomLinkProps) => {
  if (to == null && hashTo == null && href == null && onClick == null) {
    return <>{children}</>;
  }
  return (
    <>
      {startSpace && " "}
      <LinkWrapped
        hashTo={hashTo}
        to={to}
        href={href}
        style={{ color, ...style }}
        underline={underline}
        target={target}
        title={title}
        onClick={onClick}
        className={className}
      >
        {children}
      </LinkWrapped>
      {endSpace && " "}
    </>
  );
};

const LinkWrapped = ({
  to,
  hashTo,
  href,
  children,
  style,
  underline,
  target,
  title,
  onClick,
  className,
}: LinkWrappedProps) =>
  onClick != null ? (
    <Typography
      component="span"
      onClick={onClick}
      sx={style}
      className={className}
    >
      {children}
    </Typography>
  ) : to != null ? (
    <Link
      to={to}
      component={RouterLink}
      sx={style}
      underline={underline}
      target={target}
      title={title}
      className={className}
    >
      {children}
    </Link>
  ) : hashTo != null ? (
    <Link
      to={hashTo}
      component={HashLink}
      sx={style}
      underline={underline}
      target={target}
      title={title}
      className={className}
    >
      {children}
    </Link>
  ) : (
    <Link
      href={href}
      target="_blank"
      sx={style}
      underline={underline}
      title={title}
      className={className}
    >
      {children}
    </Link>
  );

export default CustomLink;
