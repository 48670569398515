import { Typography } from "@mui/material";

const Name = ({ username }) => {
  return (
    <Typography
      fontSize={{ xs: 14, sm: 14, md: 17 }}
      variant="span"
      fontWeight={700}
      color="text.bright"
      sx={{
        lineHeight: 1.4,
        display: "block",
      }}
    >
      {username}
    </Typography>
  );
};

export default Name;
