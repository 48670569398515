import { Stack } from "@mui/material";
import Ranking from "../atoms/ScholarRanking";

const Rankings = ({ scholar }) => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      sx={{
        height: "100%",
        alignItems: "stretch",
      }}
    >
      <Ranking
        amount={scholar.mmr}
        label="Stars"
        type="star"
        teamRank={scholar.mmrTeamRank}
        guildRank={scholar.mmrGuildRank}
      />
    </Stack>
  );
};

export default Rankings;
