import * as types from "./leaderboardActionTypes";

export const fetchScholarMMRLeaderboard = () => ({
  type: types.FETCH_SCHOLAR_ARENA_LEADERBOARD,
});

export const fetchScholarMMRLeaderboardStarted = () => ({
  type: types.FETCH_SCHOLAR_ARENA_LEADERBOARD_STARTED,
});

export const fetchScholarMMRLeaderboardFailed = () => ({
  type: types.FETCH_SCHOLAR_ARENA_LEADERBOARD_FAILED,
});

export const fetchScholarMMRLeaderboardSucceeded = (data) => ({
  type: types.FETCH_SCHOLAR_ARENA_LEADERBOARD_SUCCEEDED,
  data,
});
