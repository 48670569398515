export const INSPECTOR_CANCEL_FETCHING = "INSPECTOR_CANCEL_FETCHING";

export const INSPECTOR_SEARCH = "INSPECTOR_SEARCH";
export const INSPECTOR_FETCH_AXIE = "INSPECTOR_FETCH_AXIE";
export const INSPECTOR_FETCH_AXIE_STARTED = "INSPECTOR_FETCH_AXIE_STARTED";
export const INSPECTOR_FETCH_AXIE_FAILED = "INSPECTOR_FETCH_AXIE_FAILED";
export const INSPECTOR_FETCH_AXIE_SUCCEEDED = "INSPECTOR_FETCH_AXIE_SUCCEEDED";
export const INSPECTOR_AXIE_ALREADY_FETCHED = "INSPECTOR_AXIE_ALREADY_FETCHED";

export const INSPECTOR_FETCH_PARENTS = "INSPECTOR_FETCH_PARENTS";
export const INSPECTOR_FETCH_PARENTS_STARTED =
  "INSPECTOR_FETCH_PARENTS_STARTED";
export const INSPECTOR_FETCH_PARENTS_FAILED = "INSPECTOR_FETCH_PARENTS_FAILED";
export const INSPECTOR_FETCH_PARENTS_SUCCEEDED =
  "INSPECTOR_FETCH_PARENTS_SUCCEEDED";

export const INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES =
  "INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES";
export const INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_STARTED =
  "INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_STARTED";
export const INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_SUCCEEDED =
  "INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_SUCCEEDED";
export const INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_FAILED =
  "INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_FAILED";

export const INSPECTOR_FETCH_LAST_SOLD = "INSPECTOR_FETCH_LAST_SOLD";
export const INSPECTOR_FETCH_LAST_SOLD_STARTED =
  "INSPECTOR_FETCH_LAST_SOLD_STARTED";
export const INSPECTOR_FETCH_LAST_SOLD_FAILED =
  "INSPECTOR_FETCH_LAST_SOLD_FAILED";
export const INSPECTOR_FETCH_LAST_SOLD_SUCCEEDED =
  "INSPECTOR_FETCH_LAST_SOLD_SUCCEEDED";

export const INSPECTOR_FETCH_MYSTIC_FLOOR = "INSPECTOR_FETCH_MYSTIC_FLOOR";
export const INSPECTOR_FETCH_MYSTIC_FLOOR_STARTED =
  "INSPECTOR_FETCH_MYSTIC_FLOOR_STARTED";
export const INSPECTOR_FETCH_MYSTIC_FLOOR_FAILED =
  "INSPECTOR_FETCH_MYSTIC_FLOOR_FAILED";
export const INSPECTOR_FETCH_MYSTIC_FLOOR_SUCCEEDED =
  "INSPECTOR_FETCH_MYSTIC_FLOOR_SUCCEEDED";
export const INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL =
  "INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL";
export const INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL_SUCCEEDED =
  "INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL_SUCCEEDED";

export const INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS =
  "INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS";
export const INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_STARTED =
  "INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_STARTED";
export const INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_SUCCEEDED =
  "INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_SUCCEEDED";
export const INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_FAILED =
  "INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_FAILED";

export const INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL =
  "INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL";
export const INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL_SUCCEEDED =
  "INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL_SUCCEEDED";

export const INSPECTOR_FETCH_RECENTLY_SOLD = "INSPECTOR_FETCH_RECENTLY_SOLD";
export const INSPECTOR_FETCH_RECENTLY_SOLD_STARTED =
  "INSPECTOR_FETCH_RECENTLY_SOLD_STARTED";
export const INSPECTOR_FETCH_RECENTLY_SOLD_FAILED =
  "INSPECTOR_FETCH_RECENTLY_SOLD_FAILED";
export const INSPECTOR_FETCH_RECENTLY_SOLD_SUCCEEDED =
  "INSPECTOR_FETCH_RECENTLY_SOLD_SUCCEEDED";

export const INSPECTOR_FETCH_RECENTLY_SOLD_TRANSFER_HISTORY =
  "INSPECTOR_FETCH_RECENTLY_SOLD_TRANSFER_HISTORY";

export const INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL =
  "INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL";
export const INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL_SUCCEEDED =
  "INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL_SUCCEEDED";

// fetch price from metalend
export const INSPECTOR_FETCH_ESTIMATED_PRICE =
  "INSPECTOR_FETCH_ESTIMATED_PRICE";
export const INSPECTOR_FETCH_ESTIMATED_PRICE_STARTED =
  "INSPECTOR_FETCH_ESTIMATED_PRICE_STARTED";
export const INSPECTOR_FETCH_ESTIMATED_PRICE_FAILED =
  "INSPECTOR_FETCH_ESTIMATED_PRICE_FAILED";
export const INSPECTOR_FETCH_ESTIMATED_PRICE_SUCCEEDED =
  "INSPECTOR_FETCH_ESTIMATED_PRICE_SUCCEEDED";
