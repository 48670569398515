import React from "react";
import { Typography } from "@mui/material";

const MutedText = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize={22}
      fontWeight={700}
      color="text.veryMuted"
      lineHeight={1.1}
    >
      {children}
    </Typography>
  );
};

export default MutedText;
