import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as scholarSelectors from "store/guildDashboard/selectors/scholar";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import NoScholar from "./organisms/NoScholar";
import ScholarAxies from "./organisms/Axies";
import CustomLoader from "components/CustomLoader/CustomLoader";
import EditScholarDialog from "../Scholars/organisms/EditScholarDialog";
import {
  setScholarToModifyIds,
  setOpenEditScholarDialog,
  setActiveScholar,
  updateScholarPVPBattlesCurrentPage,
  fetchBattlesDataForScholar,
} from "store/guildDashboard/actions/guildDashboardActions";
import AdminButtons from "./molecules/AdminButtons";
import Overview from "./organisms/Overview";
import TopSection from "./organisms/TopSection";
import BattleLog from "components/BattleLog";
import FavoriteTeam from "components/FavoriteTeam/FavoriteTeam";
import Tabs from "./molecules/Tabs";
import { fetchAxieData } from "store/data/dataActions";
import Card from "components/Card/Card";
import TabPanel from "components/Tabs/TabPanel";
import Inventory from "../Inventory";

const Scholar = ({
  setActiveScholar,
  scholar,
  fetchingTeams,
  setScholarToModifyIds,
  setOpenEditScholarDialog,
  scholarToModify,
  fetchBattlesDataForScholar,
  updateScholarPVPBattlesCurrentPage,
  fetchAxieData,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { roninAddress } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [hasFetchedBattles, setHasFetchedBattles] = useState(false);

  const tabsId = "scholar-tabpanel";

  useEffect(() => {
    return function cleanup() {
      setActiveScholar(undefined);
    };
  }, [setActiveScholar]);

  const handleUpdateCurrentPage = (e, newPage) => {
    updateScholarPVPBattlesCurrentPage(newPage, scholar);
  };

  useEffect(() => {
    if (scholar?.deleting) {
      navigate("/guild-dashboard/scholars");
    }
  }, [scholar?.deleting, navigate]);

  useEffect(() => {
    if (scholar && !hasFetchedBattles) {
      fetchBattlesDataForScholar(scholar.roninAddress, scholar.guildTeamId);
      setHasFetchedBattles(true);
    }
  }, [
    scholar,
    setHasFetchedBattles,
    fetchBattlesDataForScholar,
    hasFetchedBattles,
  ]);

  useEffect(() => {
    if (roninAddress != null) {
      setActiveScholar(roninAddress);
    }
  }, [roninAddress, setActiveScholar]);

  if (fetchingTeams && !scholar) {
    return <CustomLoader marginTop={200} />;
  } else if (!scholar) {
    return <NoScholar />;
  } else {
    return (
      <>
        {scholarToModify && <EditScholarDialog />}

        <AdminButtons
          scholar={scholar}
          setScholarToModifyIds={setScholarToModifyIds}
          setOpenEditScholarDialog={setOpenEditScholarDialog}
          prevPath={state?.prevPath}
        />

        <TopSection scholar={scholar} />

        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabsId={tabsId}
        />

        <TabPanel value={activeTab} index={0} id={tabsId}>
          <Overview scholar={scholar} />
        </TabPanel>
        <TabPanel value={activeTab} index={1} id={tabsId}>
          <ScholarAxies
            axies={scholar.axies}
            total={scholar.numberOfAxies.total}
            eggs={scholar.numberOfAxies.eggs}
            adults={scholar.numberOfAxies.adults}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2} id={tabsId}>
          {/* data seems different to inventory page- find why- should match */}
          <Inventory />
        </TabPanel>
        <TabPanel value={activeTab} index={3} id={tabsId}>
          <BattleLog
            name={scholar.name}
            fetchingBattles={scholar.fetchingBattlesData}
            currentPage={scholar.battleSummary.currentPage}
            updateCurrentPage={handleUpdateCurrentPage}
            numberOfPages={scholar.battleSummary.numberOfPages}
            toDisplay={scholar.battleSummary.toDisplay}
            fetchAxieData={fetchAxieData}
            totalBattles={scholar.battleStats.total}
            totalWins={scholar.battleStats.wins}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={4} id={tabsId}>
          <Card>
            <FavoriteTeam favoriteTeam={scholar.favoriteTeam} />
          </Card>
        </TabPanel>
      </>
    );
  }
};

const mapDispatchToProps = {
  setActiveScholar,
  setOpenEditScholarDialog,
  setScholarToModifyIds,
  fetchBattlesDataForScholar,
  updateScholarPVPBattlesCurrentPage,
  fetchAxieData,
};

const mapStateToProps = (state) => ({
  fetchingTeams: state.guildDashboard.guild.fetchingTeams,
  scholar: scholarSelectors.getActiveScholarData(state),
  scholarToModify: scholarSelectors.getScholarToModify(state),
  openEditScholarDialog: state.guildDashboard.openEditScholarDialog,
});

export default connect(mapStateToProps, mapDispatchToProps)(Scholar);
