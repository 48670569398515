import Search from "components/DoLL/Layout/Search";
import React from "react";

const Controls = ({ controls, handleUpdateControls }) => {
  return (
    <>
      <Search
        placeholder="Search"
        width={200}
        searchText={controls.searchText}
        updateSearchText={(e) =>
          handleUpdateControls("searchText")(e.target.value)
        }
      />
    </>
  );
};

export default Controls;
