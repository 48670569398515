import React from "react";
import Pairs from "../molecules/Pairs";
import { updateCurrentPageLocked } from "store/breedingSimulator/breedingSimulatorActions";
import * as breedingSelectors from "store/breedingSimulator/breedingSelectors";
import { connect } from "react-redux";
import ResultsHeader from "../atoms/ResultsHeader";
import { Stack } from "@mui/material";
import Message from "../atoms/Message";
import CopyJsonButton from "../atoms/CopyJsonButton";
import CsvDownloadButton from "../atoms/CsvDownloadButton";
import TotalLockedCost from "../molecules/TotalLockedCost";

const LockedResults = ({
  lockedResultJSON,
  updateCurrentPageLocked,
  results,
  currentPageLocked,
  handleDeleteId,
}) => {
  return !results || results.numberOfLockedResults === 0 ? (
    <Message>Wer locked?</Message>
  ) : (
    <Stack gap={4}>
      <ResultsHeader
        title="Locked Results"
        description="These axies are ready to woohoo!"
      >
        <TotalLockedCost
          slp={results.totalLockedBreedingCost.slp}
          axs={results.totalLockedBreedingCost.axs}
        />
        <Stack direction="row" gap={2} alignItems="center">
          <CopyJsonButton lockedResultJSON={lockedResultJSON} />
          <CsvDownloadButton lockedResultJSON={lockedResultJSON} />
        </Stack>
      </ResultsHeader>

      <Pairs
        results={results.lockedResultsToDisplay}
        totalNumResults={results.numberOfLockedResults}
        updateCurrentPage={updateCurrentPageLocked}
        locked={true}
        currentPage={currentPageLocked}
        handleDeleteId={handleDeleteId}
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCurrentPageLocked,
};

const mapStateToProps = (state) => {
  return {
    results: breedingSelectors.getBreedingResults(state),
    lockedResultJSON: breedingSelectors.createLockedResultJSON(state),
    currentPageLocked: state.breedingSimulator.currentPageLocked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LockedResults);
