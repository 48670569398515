import { Button as MuiButton } from "@mui/material";

const Button = ({ children, ...props }) => {
  return (
    <MuiButton
      disableRipple={false}
      sx={{
        color: "#ffffff",
        fontSize: "1rem",
        background: (theme) => theme.palette.colors.interDungeon.controls.teal,
        border: "none",
        boxShadow: `0 4px 0 #1A2C28`,
        padding: "16px 24px 14px 24px",
        lineHeight: 1,
        borderRadius: "12px",
        transition: "0.2s all ease",
        "&:hover": {
          background: (theme) =>
            theme.palette.colors.interDungeon.controls.teal,
          transform: "scale(1.1)",
          boxShadow: (theme) =>
            `8px 8px 0 ${theme.palette.colors.interDungeon.controls.shadow}`,
        },
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
