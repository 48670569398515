export const ADD_BREEDING_AXIE_ID = "ADD_BREEDING_AXIE_ID";
export const UPDATE_REQUIRED_BREEDING_ID = "UPDATE_REQUIRED_BREEDING_ID";

export const FETCH_AXIE = "FETCH_AXIE";
export const FETCH_AXIE_STARTED = "FETCH_AXIE_STARTED";
export const FETCH_AXIE_SUCCEEDED = "FETCH_AXIE_SUCCEEDED";
export const FETCH_AXIE_FAILED = "FETCH_AXIE_FAILED";

export const FETCH_ALL_RONIN_AXIES = "FETCH_ALL_RONIN_AXIES";
export const FETCH_ALL_RONIN_AXIES_STARTED = "FETCH_ALL_RONIN_AXIES_STARTED";
export const FETCH_ALL_RONIN_AXIES_SUCCEEDED =
  "FETCH_ALL_RONIN_AXIES_SUCCEEDED";
export const FETCH_ALL_RONIN_AXIES_FAILED = "FETCH_ALL_RONIN_AXIES_FAILED";

export const FETCH_RONIN_AXIES_FOR_ADDRESS_STARTED =
  "FETCH_RONIN_AXIES_FOR_ADDRESS_STARTED";
export const FETCH_RONIN_AXIES_FOR_ADDRESS_SUCCEEDED =
  "FETCH_RONIN_AXIES_FOR_ADDRESS_SUCCEEDED";
export const FETCH_RONIN_AXIES_FOR_ADDRESS_FAILED =
  "FETCH_RONIN_AXIES_FOR_ADDRESS_FAILED";

export const FETCH_RONIN_AXIE_DETAIL = "FETCH_RONIN_AXIE_DETAIL";
export const FETCH_RONIN_AXIE_DETAIL_SUCCEEDED =
  "FETCH_RONIN_AXIE_DETAIL_SUCCEEDED";

export const CLEAR_RONIN_BREEDING_AXIES = "CLEAR_RONIN_BREEDING_AXIES";

export const DELETE_BREEDING_AXIE_PAIR = "DELETE_BREEDING_AXIE_PAIR";
export const DELETE_BREEDING_AXIE_ID = "DELETE_BREEDING_AXIE_ID";
export const DELETE_ALL_BREEDING_AXIE_IDS = "DELETE_ALL_BREEDING_AXIE_IDS";
export const DELETE_ALL_UNLOCKED_BREEDING_AXIE_IDS =
  "DELETE_ALL_UNLOCKED_BREEDING_AXIE_IDS";

export const TOGGLE_LOCK_BREEDING_PAIR = "TOGGLE_LOCK_BREEDING_PAIR";
export const UPDATE_NUMBER_OF_BREEDS_FOR_PAIR =
  "UPDATE_NUMBER_OF_BREEDS_FOR_PAIR";

export const UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_UNLOCKED =
  "UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_UNLOCKED";
export const UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_LOCKED =
  "UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_LOCKED";
export const UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_INVALID =
  "UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_INVALID";
export const UPDATE_BREEDING_SIMULATOR_SORT_TYPE =
  "UPDATE_BREEDING_SIMULATOR_SORT_TYPE";
export const UPDATE_BREEDING_SIMULATOR_SHOW_ONLY_BREEDING_EVENT_PAIRS =
  "UPDATE_BREEDING_SIMULATOR_SHOW_ONLY_BREEDING_EVENT_PAIRS";
export const UPDATE_BREEDING_SIMULATOR_HIDE_INVALID =
  "UPDATE_BREEDING_SIMULATOR_HIDE_INVALID";

export const UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_SELECT_AXIES_DIALOG =
  "UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_SELECT_AXIES_DIALOG";
export const UPDATE_BREEDING_SIMULATOR_BREED_COUNT_SELECT_AXIES_DIALOG =
  "UPDATE_BREEDING_SIMULATOR_BREED_COUNT_SELECT_AXIES_DIALOG";
export const UPDATE_BREEDING_SIMULATOR_PURITY_SELECT_AXIES_DIALOG =
  "UPDATE_BREEDING_SIMULATOR_PURITY_SELECT_AXIES_DIALOG";
export const UPDATE_BREEDING_SIMULATOR_AXIE_CLASSES_SELECT_AXIES_DIALOG =
  "UPDATE_BREEDING_SIMULATOR_AXIE_CLASSES_SELECT_AXIES_DIALOG";
export const UPDATE_BREEDING_SIMULATOR_SELECT_ALL_SELECT_AXIES_DIALOG =
  "UPDATE_BREEDING_SIMULATOR_SELECT_ALL_SELECT_AXIES_DIALOG";
export const SELECT_RONIN_AXIE = "SELECT_RONIN_AXIE";
export const DESELECT_ALL_RONIN_AXIES = "DESELECT_ALL_RONIN_AXIES";
export const SELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS =
  "SELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS";
export const DESELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS =
  "DESELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS";
