import React from "react";
import { Chip, useTheme, Stack, Typography } from "@mui/material";
import CardSummary from "components/Axie/AxieV3/components/CardSummary";
import SidebarSection from "./SidebarSection";
import SidebarPotentialPoints from "./SidebarPotentialPoints";

const SidebarInfo = ({ axie, midSized }) => {
  const theme = useTheme();

  const styles = midSized
    ? { display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "24px" }
    : {};

  return (
    <Stack
      gap={2}
      sx={{
        ...styles,
        [theme.breakpoints.down("md")]: {},
      }}
    >
      <SidebarSection title="Stats" display={true}>
        <Info
          label="Health:"
          value={axie.health.total}
          chipValue={axie.health.bonus}
          style={{ mt: -0.5 }}
        />
      </SidebarSection>

      <SidebarSection
        title="Classification"
        display={
          axie.classification.role != null &&
          axie.classification.specialty != null
        }
      >
        <Stack direction="row" gap={0.5} flexWrap="wrap">
          <Chip
            label={axie.classification.role}
            size="small"
            color="secondary"
            sx={{ fontSize: 12, "& .MuiChip-label": { px: "5px" } }}
          />
          <Chip
            label={axie.classification.specialty}
            size="small"
            color="secondary"
            sx={{ fontSize: 12, "& .MuiChip-label": { px: "5px" } }}
          />
        </Stack>
      </SidebarSection>

      <SidebarPotentialPoints
        potentialPoints={axie.potentialPoints}
        display={axie.hasCards}
      />

      <SidebarSection title="Card Stats" display={axie.hasCards}>
        <CardSummary data={axie.cardStats} width={380} gapMultiplier={1.2} />
      </SidebarSection>

      <SidebarSection title="Card Tags" display={axie.hasCards}>
        <CardSummary
          data={axie.cardTags}
          width={380}
          addCardsToLabel={true}
          gapMultiplier={1.4}
        />
      </SidebarSection>
    </Stack>
  );
};

const Info = ({ label, value, chipValue, style }) => (
  <Stack direction="row" alignItems="center" gap={1} sx={style}>
    <Typography
      variant="span"
      color="text.bright"
      fontSize={14}
      fontWeight={600}
    >
      {label}
    </Typography>
    <Typography
      variant="span"
      color="text.regular"
      fontSize={14}
      fontWeight={600}
    >
      {value}
    </Typography>
    {chipValue > 0 && (
      <Chip
        label={`+${chipValue}`}
        size="small"
        color="primary"
        sx={{ height: 20 }}
      />
    )}
  </Stack>
);

export default SidebarInfo;
