import * as types from "./inspectorActionTypes";
import { put, takeEvery, retry, call, fork, take } from "redux-saga/effects";
import { calculatePurityAxieTech, axieSoldSinceCutOff } from "helpers/axie";
import api from "services/api";
import { channel } from "redux-saga";
import * as actions from "./inspectorActions";
import { AXIE_INFINITY_TRANSFER_HISTORY_API_FUNCTIONAL } from "config";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export const getTwoWeeksAgoTimestamp = () =>
  Math.round((Date.now() - 12096e5) / 1000);

const constructSimilarVariables = (from, axie) => {
  const purity = calculatePurityAxieTech(axie).purityCount;

  return {
    auctionType: "All",
    minBreedCount: 0,
    maxBreedCount: axie.breedCount,
    classes: [axie.class],
    parts: [
      axie.parts.d.back.partId,
      axie.parts.d.mouth.partId,
      axie.parts.d.horn.partId,
      axie.parts.d.tail.partId,
      axie.parts.d.ears.partId,
      axie.parts.d.eyes.partId,
    ],
    minPureness: purity === 0 ? undefined : purity,
    maxPureness: purity === 0 ? undefined : 6,
    from,
    size: 50,
    sort: "IdDesc",
  };
};

export function* fetchRecentlySold(action) {
  try {
    if (AXIE_INFINITY_TRANSFER_HISTORY_API_FUNCTIONAL) {
      yield put(actions.fetchRecentlySoldStarted());

      let results = [];

      for (const idx in [0, 1, 2]) {
        const recentlySold = yield retry(
          RETRY_TIMES,
          RETRY_DELAY,
          api.requestSearchAxies,
          constructSimilarVariables(idx * 50, action.axie)
        );

        results.push(...recentlySold.axies);

        if (recentlySold.axies.length === 0) {
          break;
        }
      }

      const twoWeeksAgo = getTwoWeeksAgoTimestamp();

      for (const idx in results) {
        yield put(
          actions.fetchRecentlySoldTransferHistory(
            results[idx].axieId,
            twoWeeksAgo
          )
        );
      }

      yield put(actions.fetchRecentlySoldSucceeded());
    }
  } catch (e) {
    yield put(actions.fetchRecentlySoldFailed());
  }
}

export function* watchFetchTransferHistory() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchTransferHistoryRequest, chan);
  }

  try {
    while (true) {
      const { axieId, timeCutOff } = yield take(
        types.INSPECTOR_FETCH_RECENTLY_SOLD_TRANSFER_HISTORY
      );
      yield put(chan, axieId, timeCutOff);
    }
  } catch (e) {}
}

export function* makeFetchTransferHistoryRequest(chan) {
  try {
    while (true) {
      const axieId = yield take(chan);
      const timeCutOff = yield take(chan);
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestTransferHistory,
        axieId
      );

      if (axieSoldSinceCutOff(data, timeCutOff)) {
        yield put(actions.fetchRecentlySoldAxieDetail(data.axieId, data));
      }
    }
  } catch (e) {}
}

export function* watchFetchAxieData() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchAxieDataRequest, chan);
  }

  try {
    while (true) {
      const { axieId, lastSold } = yield take(
        types.INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL
      );
      yield put(chan, axieId, lastSold);
    }
  } catch (e) {}
}

export function* makeFetchAxieDataRequest(chan) {
  try {
    while (true) {
      const axieId = yield take(chan);
      const lastSold = yield take(chan);
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        axieId
      );
      yield put(actions.fetchRecentlySoldAxieDetailSucceeded(data, lastSold));
    }
  } catch (e) {}
}

export default function* recentlySoldSaga() {
  yield fork(watchFetchAxieData);
  yield fork(watchFetchTransferHistory);
  yield takeEvery(types.INSPECTOR_FETCH_RECENTLY_SOLD, fetchRecentlySold);
}
