import { useState, useEffect } from "react";
import { IoMenu, IoClose } from "react-icons/io5";
import { dropdownItems } from "./navigation.links";
import { IconButton, Box, useTheme, Stack, Container } from "@mui/material";
import MobileNav from "./organisms/MobileNav";
import DesktopNav from "./organisms/DesktopNav";
import Logo from "./atoms/Logo";

export const MOBILE_BREAKPOINT = 768;

const Navigation = ({ colorOverrides }) => {
  const theme = useTheme();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [originsDropdown, setOriginsDropdown] = useState(false);
  const [axiesDropdown, setAxiesDropdown] = useState(false);
  const [roninDropdown, setRoninDropdown] = useState(false);
  const [classicDropdown, setClassicDropdown] = useState(false);
  const [guildsDropdown, setGuildsDropdown] = useState(false);
  const [renderDesktop, setRenderDesktop] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 900) {
      setRenderDesktop(true);
    } else {
      setRenderDesktop(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        handleSetMobileMenuOpen(false);
        setRenderDesktop(true);
      } else {
        setRenderDesktop(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSetMobileMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  };

  const navigationLinks = {
    axies: {
      text: "Axies",
      dropdownItems: dropdownItems.axies,
      isOpen: axiesDropdown,
      setIsOpen: setAxiesDropdown,
    },
    origins: {
      text: "Origins",
      dropdownItems: dropdownItems.origins,
      isOpen: originsDropdown,
      setIsOpen: setOriginsDropdown,
    },
    classic: {
      text: "Classic",
      dropdownItems: dropdownItems.classic,
      isOpen: classicDropdown,
      setIsOpen: setClassicDropdown,
    },
    charts: {
      text: "Charts",
      to: "/charts",
    },
    games: {
      text: "Games",
      dropdownItems: dropdownItems.games,
      secondaryDropdownItems: dropdownItems.gamesSecondary,
      isOpen: roninDropdown,
      setIsOpen: setRoninDropdown,
    },
    guilds: {
      text: "Guilds",
      dropdownItems: dropdownItems.guilds,
      isOpen: guildsDropdown,
      setIsOpen: setGuildsDropdown,
    },
  };

  return (
    <Box
      style={{
        position: "relative",
        zIndex: 99,
        backgroundColor:
          colorOverrides?.navigation?.background ||
          theme.palette.navigation.background,
        borderBottom: `1px solid ${
          colorOverrides?.navigation?.border || theme.palette.navigation.border
        }`,
      }}
    >
      <Container fixed>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Logo
            color={
              colorOverrides?.navigation?.logo || theme.palette.primary.main
            }
          />
          {!renderDesktop && (
            <MobileNav
              links={navigationLinks}
              setMobileMenuOpen={handleSetMobileMenuOpen}
              mobileMenuOpen={mobileMenuOpen}
            />
          )}
          {renderDesktop && (
            <DesktopNav
              links={navigationLinks}
              setMobileMenuOpen={handleSetMobileMenuOpen}
              mobileMenuOpen={mobileMenuOpen}
              colorOverrides={colorOverrides}
            />
          )}
          <IconButton
            onClick={() => handleSetMobileMenuOpen(!mobileMenuOpen)}
            sx={{
              my: 1,
              [theme.breakpoints.up("md")]: {
                display: "none",
              },
            }}
          >
            {mobileMenuOpen ? <IoClose /> : <IoMenu />}
          </IconButton>
        </Stack>
      </Container>
    </Box>
  );
};

export default Navigation;
