import {
  ResponsiveContainer,
  LineChart,
  YAxis,
  XAxis,
  Tooltip,
  Line,
} from "recharts";
import { Typography, useTheme } from "@mui/material";
import TooltipWrapper from "components/Charts/TooltipWrapper";
import TooltipCapsule from "components/Charts/TooltipCapsule";
import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "config";
import ChartInfoMessage from "./ChartInfoMessage";

const ArenaChart = ({ data, height = 300 }) => {
  const theme = useTheme();

  return data != null && data.length > 0 ? (
    <div style={{ minWidth: 0 }}>
      <ResponsiveContainer width="99%" height={height}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
          }}
        >
          <XAxis
            dataKey="name"
            axisLine={{ stroke: theme.palette.card.pop3 }}
            tickLine={{ stroke: theme.palette.card.pop3 }}
            reversed
            tick={<CustomizedTick />}
          />
          <YAxis
            stroke={theme.palette.card.subText}
            axisLine={{ stroke: theme.palette.card.pop3 }}
            style={{
              fontSize: "15px",
              fontWeight: "600",
            }}
            tickLine={{ stroke: theme.palette.card.pop3 }}
            type="number"
            domain={["dataMin - 100", "dataMax + 100"]}
          />
          <Tooltip
            content={<ChartTooltip />}
            cursor={{ stroke: "transparent" }}
          />
          <Line
            type="monotone"
            dataKey="victoryStars"
            stroke={theme.palette.secondary.main}
            activeDot={{ r: 8 }}
            strokeWidth={2}
            dot={{
              fill: theme.palette.secondary.main,
              r: 2,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <ChartInfoMessage>
      We're still collecting gameplay data for this scholar.
    </ChartInfoMessage>
  );
};

const ChartTooltip = ({ active, payload }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <TooltipCapsule
          color={theme.palette.secondary.main}
          textColor={theme.palette.secondary.contrastText}
          textAlign="center"
        >{`Stars: ${payload[0].value}`}</TooltipCapsule>
        <Typography
          variant="span"
          color="text.muted"
          fontSize="13px"
          fontWeight="600"
          textAlign="center"
        >
          {moment(payload[0].payload.name).format(DATE_TIME_FORMAT)}
        </Typography>
      </TooltipWrapper>
    );
  }
  return null;
};

const CustomizedTick = ({ x, y, stroke, payload }) => {
  const theme = useTheme();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill={theme.palette.card.subText}
        fontSize="13px"
        fontWeight="600"
      >
        {moment(payload.value).format(DATE_FORMAT)}
      </text>
    </g>
  );
};

export default ArenaChart;
