import React from "react";
import { Stack, Typography } from "@mui/material";
import { getGuildRating } from "helpers/guild";

const SidebarStats = ({ averageStars }) => (
  <Stack sx={{ p: 2 }} gap={1}>
    <Stack direction="row" justifyContent="space-between">
      <StatLabel>Guild Rating</StatLabel>
      <StatAmount>{getGuildRating(averageStars) ?? "?"}</StatAmount>
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <StatLabel>Average Stars</StatLabel>
      <StatAmount>{averageStars ?? "?"}</StatAmount>
    </Stack>
  </Stack>
);

const StatLabel = ({ children }) => (
  <Typography
    variant="span"
    fontWeight="600"
    color="text.primary"
    fontSize="14px"
  >
    {children}
  </Typography>
);

const StatAmount = ({ children }) => (
  <Typography
    variant="span"
    fontWeight="700"
    color="text.bright"
    fontSize="14px"
  >
    {children}
  </Typography>
);

export default SidebarStats;
