import React from "react";
import Label from "pages/TeamBuilder/atoms/Label";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: "6px",
}));

const Header = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "4px",
  alignItems: "center",

  "& .icon": {
    justifyContent: "center",
  },
  svg: {
    color: theme.palette.text.muted,
  },
}));

const SidebarSection = ({ children, title, display, icon }) => {
  if (!display) return null;

  return (
    <Wrapper>
      <Header>
        <Label>{title}</Label> {icon && icon}
      </Header>
      {children}
    </Wrapper>
  );
};

export default SidebarSection;
