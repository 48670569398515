import { Chip } from "@mui/material";
import React from "react";

const GameType = ({ gameType }) => {
  return gameType == null ? null : (
    <Chip
      size="small"
      color="secondary"
      label={gameType}
      sx={{ maxWidth: 70 }}
    />
  );
};

export default GameType;
