import React from "react";
import { Box } from "@mui/material";
import Linked from "./Linked";

const Image = ({ image, title, linkTo }) => {
  return (
    <Box sx={{ img: { borderRadius: 3, width: "100%" } }}>
      <Linked url={linkTo}>
        <img src={`/images/games/${image}`} alt={title} />
      </Linked>
    </Box>
  );
};

export default Image;
