import * as types from "store/guildDashboard/actions/actionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import { axieTechAPI } from "store/sagaHelpers";
import endpoints from "services/api/endpoints";

export function* updateUserPreferences(action) {
  try {
    yield put({ type: types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_STARTED });
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        ...action.userPreferences,
      }),
    };
    yield call(axieTechAPI, endpoints.guildUserPreferences, params);
    yield put({
      type: types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_SUCCEEDED,
      userPreferences: action.userPreferences,
    });
  } catch (e) {
    console.log("Error updating user preferences: ", e);
    yield put({
      type: types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_FAILED,
      error: e,
    });
  }
}

export default function* guildDashboardUserSaga() {
  yield takeLatest(
    types.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES,
    updateUserPreferences
  );
}
