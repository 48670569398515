import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  position: "relative",
  width,
  minWidth: width,
  height: width,
  minHeight: width,

  ".static, .gif": {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  ".static": {
    zIndex: 1,
  },

  ".gif": {
    zIndex: 2,
  },
}));

const Asset = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "img",
})(({ img }) => ({
  backgroundImage: `url('${img}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const Art = ({ toDisplay, staticImg, width }) => {
  return (
    <Wrapper width={width} className="art">
      <Asset img={toDisplay} className="gif" />
      <Asset img={staticImg} className="static" />
    </Wrapper>
  );
};

export default Art;
