import { createSelector } from "reselect";
import { calculateStatsForScholarGroup, getGuildRating } from "helpers/guild";

const getGuildScholars = (state) => {
  return state.guild?.scholars || [];
};

const getGuildAverageStars = (state) => {
  return state.guild?.arenaLeaderboardPosition?.averageElo || 0;
};

export const getGuildStats = createSelector(
  [getGuildScholars, getGuildAverageStars],
  (scholars, averageStars) => {
    const showUnderperformers = true;

    return {
      ...calculateStatsForScholarGroup(scholars, showUnderperformers),
      guildRating: getGuildRating(averageStars),
    };
  }
);
