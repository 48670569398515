import { AXIE_TECH_TAGLINE, AXIE_TECH_TITLE, AXIE_TECH_URL } from "config";
import React from "react";
import Helmet from "react-helmet";

const SEO = (props) => {
  const title = AXIE_TECH_TITLE;
  const description = AXIE_TECH_TAGLINE;
  const url = AXIE_TECH_URL;
  const siteName = AXIE_TECH_TITLE;

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="axie tech, axie.tech, axie infinity, axie pricing, axie breeding simulator, breeding calculator, guild player tracker, axie leaderboard, axie guild, axie player, play and earn, crypto gaming, nft gaming"
      />
      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://axie.tech/images/social/axie-tech-facebook.jpg"
      />
      <meta property="og:site_name" content={siteName} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@axie_tech" />
      <meta
        name="twitter:image"
        content="https://axie.tech/images/social/axie-tech-twitter.jpg"
      />
      <meta name="twitter:creator" content="@axie_tech" />
    </Helmet>
  );
};

export default SEO;
