import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-19</Date>
      <Change type="bug">
        Guild Dashboard: Today's SLP for some scholars is incorrect because the
        calculation is based on incorrect data stemming from Ronin instability.
        As a preventative measure, we have capped Today's SLP so that it will
        never be less than 0.
      </Change>
      <Change type="improved">
        Guild Pages: As at dates are now shown on public Guild pages. Hover over
        the "i" icon to see when Average SLP and Average MMR snapshot data were
        captured.
      </Change>
      <Change type="improved">
        Automatically load axies for scholars in Guild Dashboard.
      </Change>
    </Entry>
  );
};

export default Changes;
