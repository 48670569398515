import * as config from "config";
import { getDateString } from "helpers/display";

export const removeNonAlphaChars = (str) => {
  return str.replace(/[^a-zA-Z]/g, "");
};

export const removeNonNumericChars = (str) => {
  return str.replace(/\D/g, "");
};

export const parseRoninAddress = (address) => {
  if (/^ronin:[a-f0-9]{40}$/.test(address.toLocaleLowerCase())) {
    return address;
  }
  throw new Error("Bad Ronin Address");
};

export const parse0xAddress = (address) => {
  if (/^0x[a-f0-9]{40}$/.test(address.toLocaleLowerCase())) {
    return address;
  }
  throw new Error("Bad Ronin Address");
};

export const validate0xAddress = (address) => {
  if (/^0x[a-f0-9]{40}$/.test(address.toLocaleLowerCase())) {
    return true;
  }
  return false;
};

export const isValidAxieClass = (axieClass) =>
  ["All", ...config.AXIE_CLASSES].indexOf(axieClass) !== -1;

export const cleanAxieClass = (axieClass) => {
  let cleaned = removeNonAlphaChars(axieClass);
  if (cleaned && cleaned.length > 0) {
    cleaned = cleaned.charAt(0).toUpperCase() + cleaned.slice(1);
  }
  if (isValidAxieClass(cleaned)) {
    return cleaned;
  }
  return undefined;
};

export const extractRoninAddressesFromString = (str) => {
  return str
    .replace(/\s+/g, "")
    .replace(/(\r\n|\n|\r)/gm, "")
    .split(",");
};

export const validateRoninAddress = (address) => {
  if (address.startsWith("ronin:")) {
    address = address.replace("ronin:", "0x");
  }
  if (/^0x[a-f0-9]{40}$/.test(address.toLocaleLowerCase())) {
    return address;
  }
  throw new Error("Bad Ronin Address");
};

export const convertTo0xAddress = (roninAddress) =>
  roninAddress ? roninAddress.replace("ronin:", "0x") : undefined;

export const convertToRoninAddress = (roninAddress) =>
  roninAddress ? roninAddress.replace("0x", "ronin:") : undefined;

export const forceNumWithinRange = (num, min, max) => {
  if (min != null && num < min) {
    return min;
  } else if (max != null && num > max) {
    return max;
  } else {
    return num;
  }
};

export const dateOrNullDate = (data) => {
  if (!isNaN(Date.parse(data))) {
    return getDateString(new Date(data));
  }
  return "";
};

export const dateOrNullDateTime = (data) => {
  if (!isNaN(Date.parse(data))) {
    return getDateString(new Date(data));
  }
  return "";
};

export const parseCardEnergyToInt = (cardEnergy) => {
  const parsed = parseInt(cardEnergy);

  if (!isNaN(parsed)) {
    return parsed;
  }
  return undefined;
};

export const removeSpecialCharactersInDescription = (str) => {
  return str.replace(/[<>\[\]\{\}]/g, ""); //eslint-disable-line
};

export const isNullOrEmptyString = (val) => {
  if (val == null || val === "") return true;
  return false;
};

export const isNullOrEmptyArray = (val) => {
  if (val == null || !Array.isArray(val) || val.length === 0) return true;
  return false;
};
