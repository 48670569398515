import React from "react";
import { Box } from "@mui/material";
import CustomLoader from "components/CustomLoader/CustomLoader";

const TabPanel = (props) => {
  const { children, value, index, loading, sort, ...other } = props;

  return (
    <Box
      sx={{
        px: 1,
        pb: 4,
      }}
      hidden={value !== index}
    >
      <Box
        role="tabpanel"
        id={`card-explorer-tabpanel-${index}`}
        aria-labelledby={`card-explorer-tabpanel-${index}`}
        {...other}
        sx={{
          zIndex: 3,
          p: 2,
          borderRadius: 6,
          background: (theme) => theme.palette.colors.origin.paper.medium,
        }}
      >
        <Box
          sx={{
            pt: 4,
            pb: 10,
            background: (theme) => theme.palette.colors.origin.paper.light,
            borderRadius: 4,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundImage: "url('/images/explorer/pattern.png')",
              height: 56,
              width: "100%",
              backgroundRepeat: "repeat-x",
              opacity: 0.2,
              position: "absolute",
              top: 25,
            }}
          />

          {loading === true ? (
            <CustomLoader variant="origin" marginTop={200} marginBottom={200} />
          ) : (
            value === index && <div>{children}</div>
          )}

          <Box
            sx={{
              backgroundImage: "url('/images/explorer/pattern.png')",
              height: 56,
              width: "100%",
              backgroundRepeat: "repeat-x",
              opacity: 0.2,
              transform: "rotate(180deg)",
              position: "absolute",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TabPanel;
