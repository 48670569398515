import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import {
  editTeam,
  deleteTeam,
} from "store/guildDashboard/actions/guildDashboardActions";
import { useWeb3React } from "@web3-react/core";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import LoadingButton from "@mui/lab/LoadingButton";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import Error from "components/Typography/Error";

const EditTeamDialog = ({ open, setOpen, team, editTeam, deleteTeam }) => {
  const { account } = useWeb3React();
  const [teamName, setTeamName] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const theme = useTheme();

  const handleClose = useCallback(() => {
    setOpen(false);
    setError("");
    setConfirmDelete(false);
  }, [setOpen, setError, setConfirmDelete]);

  useEffect(() => {
    setTeamName(team.name);
    setIsPublic(team.isPublic === 1);
  }, [team.name, team.isPublic]);

  useEffect(() => {
    if (team.deletingTeam === false && team.deletingTeamFailed === true) {
      setError("Unable to delete team...");
    } else if (
      team.deletingTeam === false &&
      team.deletingTeamFailed === false
    ) {
      handleClose();
    } else if (team.editingTeam === false && team.editingTeamFailed === true) {
      setError("Unable to edit team...");
    } else if (
      team &&
      team.editingTeam === false &&
      team.editingTeamFailed === false
    ) {
      handleClose();
    }
  }, [team, handleClose]);

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleEditTeam(e);
    }
    if (e.which === 27) {
      setOpen(false);
    }
  };

  const handleUpdateIsPublic = (e) => {
    setIsPublic(e.target.checked);
  };

  const handleEditTeam = () => {
    if (teamName === "") {
      setError("Team name cannot be empty.");
    } else {
      editTeam(account, team.guildId, team.guildTeamId, {
        name: teamName,
        isPublic,
      });
    }
  };

  const handleDeleteTeam = () => {
    deleteTeam(account, team.guildId, team.guildTeamId, team?.scholars);
  };

  const handleClickDelete = () => {
    if (!team.deletingTeam) {
      if (confirmDelete) {
        handleDeleteTeam();
      } else {
        setConfirmDelete(true);
      }
    }
  };

  const publicLabel = isPublic ? <>Public</> : <>Private</>;
  const publicMessage = isPublic ? (
    <>
      Scholars in this team <strong>are visible</strong> on your public
      leaderboard.
    </>
  ) : (
    <>
      Scholars in this team <strong>are not visible</strong> on your public
      leaderboard.
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="edit-team-dialog-title"
      sx={{ "& .MuiPaper-root": { width: "380px" } }}
    >
      <DialogTitle id="edit-team-dialog-title">Edit Team</DialogTitle>

      <form onKeyPress={(e) => handleKeypress(e)}>
        <DialogContent dividers={true}>
          <Stack gap={2}>
            <Error>{error}</Error>
            <TextField
              onChange={(e) => setTeamName(e.target.value)}
              label="Team Name"
              variant="filled"
              size="small"
              value={teamName}
              required
              spellCheck={false}
            />
            <Stack gap={1}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                direction="row"
              >
                <Typography
                  variant="span"
                  color="text.primary"
                  fontWeight="600"
                  fontSize="16px"
                >
                  {publicLabel}
                </Typography>
                <CustomSwitch
                  checked={isPublic}
                  onChange={handleUpdateIsPublic}
                />
              </Stack>

              <Typography
                variant="span"
                fontSize="14px"
                fontWeight="500"
                color="text.muted"
                lineHeight={1.4}
              >
                {publicMessage}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          {team.deletingTeam ? (
            <LoadingButton
              variant="contained"
              loading
              sx={{ alignSelf: "stretch" }}
            />
          ) : (
            <Button
              onClick={handleClickDelete}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.feedback.red,
                "&:hover": {
                  backgroundColor: theme.palette.feedback.red,
                },
              }}
            >
              {confirmDelete ? "Click again to confirm" : "Delete"}
            </Button>
          )}
          <Stack gap={1} direction="row">
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            {team.editingTeam ? (
              <LoadingButton
                variant="contained"
                loading
                sx={{ alignSelf: "stretch" }}
              />
            ) : (
              <Button onClick={handleEditTeam} variant="contained">
                Save
              </Button>
            )}
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapDispatchToProps = {
  editTeam,
  deleteTeam,
};

const mapStateToProps = (state) => ({
  team: teamSelectors.getActiveTeam(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamDialog);
