import React from "react";
import CurrencyPrice from "../atoms/CurrencyPrice";
import { Stack } from "@mui/material";

const CurrencyPrices = ({ currencyPrices, baseCurrency }) => {
  const slpFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: baseCurrency,
    minimumFractionDigits: 4,
  });
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: baseCurrency,
  });

  return (
    <Stack direction="row" gap={2}>
      <CurrencyPrice
        data={
          currencyPrices && "smooth-love-potion" in currencyPrices
            ? slpFormatter.format(
                currencyPrices["smooth-love-potion"][baseCurrency]
              )
            : "?"
        }
        img="slp.png"
      />
      <CurrencyPrice
        data={
          currencyPrices && "axie-infinity" in currencyPrices
            ? formatter.format(currencyPrices["axie-infinity"][baseCurrency])
            : "?"
        }
        img="axs.png"
      />
      <CurrencyPrice
        data={
          currencyPrices && "ethereum" in currencyPrices
            ? formatter.format(currencyPrices["ethereum"][baseCurrency])
            : "?"
        }
        img="eth.png"
      />
    </Stack>
  );
};

export default CurrencyPrices;
