import React from "react";
import { connect } from "react-redux";
import { getFavoriteCursesToDisplay } from "store/explorer/selectors";
import { favoriteCurse } from "store/explorer/explorerActions";
import Section from "../../molecules/FavoritesSection";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";

const Curses = ({ favoriteCursesToDisplay, favoriteCurse }) => {
  return (
    <Section type="Curse" numItems={favoriteCursesToDisplay.length}>
      {favoriteCursesToDisplay.map((card) => (
        <WrappedCard key={card.id} card={card} favoriteCard={favoriteCurse} />
      ))}
    </Section>
  );
};

const mapDispatchToProps = { favoriteCurse };

const mapStateToProps = (state) => ({
  favoriteCursesToDisplay: getFavoriteCursesToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Curses);
