import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-9-2</Date>
      <Change type="new">
        Guild Dashboard: Added a new Inventory page so that you can visualize
        and search through all of your players' runes and charms.
      </Change>
      <Change type="new">
        Guild Dashboard: Added more player data including rank and tier and
        SLP/moonshard holdings.
      </Change>
    </Entry>
  );
};

export default Changes;
