import React from "react";
import { Stack, Typography } from "@mui/material";

const TeamStat = ({ label, amount }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        variant="span"
        color="text.muted"
        fontSize="15px"
        fontWeight="600"
      >
        {label}
      </Typography>
      <Typography
        variant="span"
        color="text.bright"
        fontSize="15px"
        fontWeight="600"
      >
        {amount}
      </Typography>
    </Stack>
  );
};

export default TeamStat;
