import { useEffect } from "react";
import Header from "./organisms/Header";
import { Stack } from "@mui/material";
import Players from "./organisms/Players";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import {
  fetchDollLeaderboard,
  updateControls,
} from "store/doll/leaderboard/actions";
import { getLeaderboardData } from "store/doll/leaderboard/selectors";
import CustomLoader from "components/CustomLoader/CustomLoader";
import SkyBackground from "./atoms/SkyBackground";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  padding: `${theme.spacing(12)} ${theme.spacing(2)}`,
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    gap: theme.spacing(4),
  },
}));

const Leaderboard = ({
  leaderboard,
  fetchLeaderboard,
  controls,
  updateControls,
}) => {
  useEffect(() => {
    fetchLeaderboard();
  }, [fetchLeaderboard]);

  return (
    <SkyBackground>
      <Wrapper>
        <Header />

        {leaderboard.loading || leaderboard.players == null ? (
          <CustomLoader color="#fff" marginTop={200} marginBottom={200} />
        ) : (
          <Players
            leaderboard={leaderboard}
            controls={controls}
            updateControls={updateControls}
          />
        )}
      </Wrapper>
    </SkyBackground>
  );
};

const mapDispatchToProps = {
  fetchLeaderboard: fetchDollLeaderboard,
  updateControls,
};

const mapStateToProps = (state) => ({
  leaderboard: getLeaderboardData(state),
  controls: state.doll.leaderboard.controls,
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
