import React from "react";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import { Tooltip } from "@mui/material";
import {
  IoHeart,
  IoLockClosed,
  IoLockOpen,
  IoCloseCircle,
} from "react-icons/io5";
import styles from "./breeding-result.module.scss";
import { Stack } from "@mui/material";

const Parent = ({
  data,
  parentIds,
  order,
  handleDeleteId,
  handleRefreshAxie,
  requiredId,
  updateRequiredBreedingId,
}) => {
  return (
    <AxieV3
      axie={data}
      key={`PARENT_AXIE_${parentIds[0]}_${parentIds[1]}_${order}`}
      address={data?.ownerRonin}
      deleteAxie={handleDeleteId}
      refreshAxie={handleRefreshAxie}
      id={parentIds[order]}
      displayCard={true}
      requiredId={requiredId}
      updateRequiredBreedingId={updateRequiredBreedingId}
    />
  );
};

const Parents = ({
  data,
  parentIds,
  handleDeleteId,
  failed,
  toggleLockPair,
  deleteBreedingAxiePair,
  locked,
  handleRefreshAxie,
  requiredId,
  updateRequiredBreedingId,
  hideLock,
}) => {
  const handleDeleteBreedingAxiePair = () => {
    deleteBreedingAxiePair(parentIds);
  };

  const handleToggleLockPair = () => {
    toggleLockPair(parentIds);
  };

  return (
    <div className={styles.parents}>
      <Parent
        data={data[0]}
        parentIds={parentIds}
        order={0}
        handleDeleteId={handleDeleteId}
        handleRefreshAxie={handleRefreshAxie}
        requiredId={requiredId}
        updateRequiredBreedingId={updateRequiredBreedingId}
      />
      <Stack gap={2} alignItems="center" cls={styles.actionButtons}>
        {!failed && !hideLock && (
          <Tooltip title={`${locked ? "Unlock Pair" : "Lock Pair"}`}>
            <div
              className={styles.actionButtonWrapper}
              onClick={handleToggleLockPair}
            >
              {locked ? <IoLockClosed /> : <IoLockOpen />}
            </div>
          </Tooltip>
        )}
        <div className={styles.heart}>
          <IoHeart />
        </div>
        <Tooltip title="Delete pair">
          <div
            className={styles.actionButtonWrapper}
            onClick={handleDeleteBreedingAxiePair}
          >
            <IoCloseCircle />
          </div>
        </Tooltip>
      </Stack>
      <Parent
        data={data[1]}
        parentIds={parentIds}
        order={1}
        handleDeleteId={handleDeleteId}
        handleRefreshAxie={handleRefreshAxie}
        requiredId={requiredId}
        updateRequiredBreedingId={updateRequiredBreedingId}
      />
    </div>
  );
};

export default Parents;
