import React from "react";
import { Button } from "@mui/material";

const RefreshButton = ({ handleRefresh }) => {
  if (handleRefresh == null) {
    return null;
  }
  return (
    <Button variant="text" size="small" onClick={handleRefresh}>
      Refresh Battles
    </Button>
  );
};

export default RefreshButton;
