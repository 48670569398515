import { AXIE_TECH_API } from "config";

const cards = `${AXIE_TECH_API}/cards`;
const cardsV3 = `${AXIE_TECH_API}/cards/v3`;
const recentlyListed = `${AXIE_TECH_API}/axies/recently-listed`;
const recentlySold = `${AXIE_TECH_API}/axies/recently-sold`;
const searchAxies = `${AXIE_TECH_API}/axies/filter`;
const guildLogin = `${AXIE_TECH_API}/guild/login`;
const guildTeamLogin = `${AXIE_TECH_API}/guild/team/login`;
const guildUserPreferences = `${AXIE_TECH_API}/guild/preferences`;
const scholar = `${AXIE_TECH_API}/scholar`;
const watchlist = `${AXIE_TECH_API}/scholar/watchlist`;
const guilds = `${AXIE_TECH_API}/guilds`;
const addGuild = `${AXIE_TECH_API}/guild`;
const slpIssuance = `${AXIE_TECH_API}/slp-issuance`;
const originLeaderboard = `${AXIE_TECH_API}/leaderboard/origin/arena`;
const classicLeaderboard = `${AXIE_TECH_API}/leaderboard/arena`;
const axieDollLeaderboard = `${AXIE_TECH_API}/doll/leaderboard`;
const axieDollSidekicks = `${AXIE_TECH_API}/doll/sidekicks`;
const axieDollSkills = `${AXIE_TECH_API}/doll/skills`;
const axieSalePriceEstimate = `${AXIE_TECH_API}/axies/sale-price-estimates/`;
const axieQuestSkills = `${AXIE_TECH_API}/axiequest/game-services`;
const interDungeonCards = `${AXIE_TECH_API}/interDungeon/some-endpoint`;

const constructGetAxieDetail = (id) => `${AXIE_TECH_API}/axies/${id}`;

const constructTransferHistory = (id) =>
  `${AXIE_TECH_API}/axies/${id}/transfer-history`;

const constructGuildDashboardAutopayoutLog = (loginAddress) =>
  `${AXIE_TECH_API}/autopayout/${loginAddress}`;

const constructPlayer = (clientId) => {
  return `${AXIE_TECH_API}/player/origin/${clientId}`;
};

const constructPlayerPVPBattles = (clientId) => {
  return `${AXIE_TECH_API}/player/origin/${clientId}/battles/pvp`;
};

const constructPlayerPVEBattles = (clientId) => {
  return `${AXIE_TECH_API}/player/origin/${clientId}/battles/pve`;
};

const constructClassicPlayer = (roninAddress) => {
  return `${AXIE_TECH_API}/player/classic/${roninAddress}`;
};

const constructClassicPlayerPVPBattles = (roninAddress) => {
  return `${AXIE_TECH_API}/player/classic/${roninAddress}/battles/pvp`;
};

const constructClassicPlayerPVEBattles = (roninAddress) => {
  return `${AXIE_TECH_API}/player/classic/${roninAddress}/battles/pve`;
};

const constructGuildLeaderboardMMR = () => {
  return `${AXIE_TECH_API}/leaderboard/guild/mmr`;
};

const constructGuildBattleLeaderboardMMR = () => {
  return `${AXIE_TECH_API}/leaderboard/guild-battle`;
};

const constructScholarLeaderboardMMR = () => {
  return `${AXIE_TECH_API}/leaderboard/scholar/mmr`;
};

const constructGuildScholars = (guildSlug) =>
  `${AXIE_TECH_API}/guild/${guildSlug}/scholars`;

const constructGuildScholarSnapshot = (guildSlug, roninAddress) =>
  `${AXIE_TECH_API}/guild/${guildSlug}/scholar/${roninAddress}`;

const constructGuildTeam = (guildId) =>
  `${AXIE_TECH_API}/guild/${guildId}/team`;

const constructUpdateGuild = (guildId) => `${AXIE_TECH_API}/guild/${guildId}`;

const constructPresignedUrl = (guildId, filename, fileExtension) =>
  `${AXIE_TECH_API}/guild/${guildId}/signed-url/${filename}.${fileExtension}`;

const exported = {
  cards,
  cardsV3,
  recentlyListed,
  recentlySold,
  searchAxies,
  guildLogin,
  guildTeamLogin,
  guildUserPreferences,
  scholar,
  watchlist,
  guilds,
  addGuild,
  slpIssuance,
  originLeaderboard,
  classicLeaderboard,
  axieDollLeaderboard,
  axieDollSidekicks,
  axieDollSkills,
  axieQuestSkills,
  axieSalePriceEstimate,
  interDungeonCards,
  constructGetAxieDetail,
  constructTransferHistory,
  constructGuildDashboardAutopayoutLog,
  constructPlayer,
  constructClassicPlayer,
  constructPlayerPVPBattles,
  constructClassicPlayerPVPBattles,
  constructPlayerPVEBattles,
  constructClassicPlayerPVEBattles,
  constructGuildLeaderboardMMR,
  constructGuildBattleLeaderboardMMR,
  constructScholarLeaderboardMMR,
  constructGuildScholars,
  constructGuildScholarSnapshot,
  constructGuildTeam,
  constructUpdateGuild,
  constructPresignedUrl,
};

export default exported;
