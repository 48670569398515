import React, { useState } from "react";
import { Stack, Typography, Menu, MenuItem } from "@mui/material";
import ClassIcon from "components/ClassIcon/ClassIcon";
import { AXIE_CLASSES } from "config";
import { PART_ORDER } from "pages/TeamBuilder";

const NameAndClass = ({
  axie,
  updateClass,
  updateCharm,
  updateRune,
  direction = "row",
}) => {
  const [classOptionsAnchorEl, setClassOptionsAnchorEl] = useState(null);
  const classOptionsOpen = Boolean(classOptionsAnchorEl);
  const handleClickClassIcon = (event) => {
    setClassOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseClassOptions = () => {
    setClassOptionsAnchorEl(null);
  };

  return (
    <>
      <ClassOptions
        handleClose={handleCloseClassOptions}
        open={classOptionsOpen}
        anchorEl={classOptionsAnchorEl}
        updateClass={updateClass}
        updateCharm={updateCharm}
        updateRune={updateRune}
        axieId={axie.id}
      />
      <Stack
        direction={direction}
        gap={1}
        alignItems="center"
        sx={{ height: direction === "row" ? 38 : "auto" }}
      >
        <Stack
          id="class-options-button"
          aria-controls={classOptionsOpen ? "class-options" : undefined}
          aria-haspopup="true"
          aria-expanded={classOptionsOpen ? "true" : undefined}
          onClick={handleClickClassIcon}
        >
          <ClassIcon
            disableTooltip={true}
            axieClass={axie.class}
            style={{
              transition: "0.2s transform ease",
              "&:hover": { transform: "scale(1.2)", cursor: "pointer" },
            }}
          />
        </Stack>
        <Typography
          variant="span"
          fontWeight={700}
          color="text.bright"
          sx={{ whiteSpace: "nowrap" }}
          fontSize={20}
        >
          {axie.name}
        </Typography>
      </Stack>
    </>
  );
};

const ClassOptions = ({
  handleClose,
  updateCharm,
  updateClass,
  open,
  anchorEl,
  axieId,
  updateRune,
}) => {
  const handleClickOption = (cls) => {
    updateClass(axieId, cls);
    updateRune(axieId, undefined);
    for (const part of PART_ORDER) {
      updateCharm(axieId, part, undefined);
    }
    handleClose();
  };
  return (
    <Menu
      id="class-options"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "class-options-button",
      }}
    >
      {AXIE_CLASSES.map((cls, i) => (
        <MenuItem onClick={() => handleClickOption(cls)} key={`MENU_ITEM_${i}`}>
          <ClassIcon axieClass={cls} width={32} disableTooltip={true} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default NameAndClass;
