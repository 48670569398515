import RuneIcon from "components/GameTemplates/RuneIcon";
import { Stack, Typography, styled } from "@mui/material";
import { Rune } from "types";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  alignItems: "center",
}));

interface Props {
  data: Rune;
  count: number;
  handleClick: () => void;
}

const TopRune = ({ data, count, handleClick }: Props) => {
  return (
    <Wrapper onClick={handleClick}>
      <RuneIcon rune={data} mode="dark" size={40} />
      <Typography
        component="span"
        fontWeight={600}
        fontSize={16}
        sx={{ color: (theme) => theme.palette.colors.origin.wood.text }}
      >
        {count}
      </Typography>
    </Wrapper>
  );
};
export default TopRune;
