import { ReactNode } from "react";
import { Typography } from "@mui/material";

const PlayerName = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      fontSize={{ xs: 18, sm: 18, md: 20 }}
      component="span"
      fontWeight={700}
      color="#fff"
      sx={{
        lineHeight: 1.4,
        display: "block",
      }}
    >
      {children}
    </Typography>
  );
};

export default PlayerName;
