import {
  atLeastOneMatchInArray,
  matchesStr,
  strIsInArray,
} from "helpers/display";

export const cardMatchesFilters = (
  card,
  { searchText, rarity, battlePower, equippedPower, equippable }
) => {
  return (
    (matchesStr(card.name, searchText) || !searchText) &&
    (strIsInArray(card.rarity, rarity) || rarity.length === 0) &&
    (strIsInArray(card.nonEquippableModifer?.value, battlePower) ||
      battlePower.length === 0) &&
    (strIsInArray(card.equippableModifier?.value, equippedPower) ||
      equippedPower.length === 0) &&
    (atLeastOneMatchInArray(
      (card.equippableModifier?.equippableSlotTypes || []).map((val) => val),
      equippable
    ) ||
      equippable.length === 0)
  );
};
