import CustomLink from "components/CustomLink/CustomLink";
import { TRIBALLY_PLATFORM_URL } from "config";
import { useTheme } from "@mui/material";

const TriballyOriginsExplorerBanner = () => {
  const theme = useTheme();
  return (
    <CustomLink
      style={{
        width: 550,
        marginTop: 8,
        zIndex: 99,
        "&:hover": {
          transform: "scale(1.02)",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          marginRight: 2,
          marginLeft: 2,
        },
      }}
      target="_blank"
      href={`${TRIBALLY_PLATFORM_URL}community/axie-infinity-origins/tools/explorer?view=cards`}
    >
      <img
        style={{
          borderRadius: 16,
          width: "100%",
          border: `8px solid #F0DCC0`,
        }}
        src="/images/tribally-banner-assets/backgrounds/origins-explorer.jpg"
        alt="Origins Card Explorer On Tribally Platform"
      />
    </CustomLink>
  );
};

export default TriballyOriginsExplorerBanner;
