import Cards from "./Cards";
import Runes from "./Runes";
import Charms from "./Charms";
import Curses from "./Curses";
import Tools from "./Tools";
import RevengeCards from "./RevengeCards";

const Favorites = {
  Cards,
  Runes,
  Charms,
  Curses,
  Tools,
  RevengeCards,
};

export default Favorites;
