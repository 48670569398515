import React from "react";
import { ReactComponent as FooterAngleSvg } from "icons/footer-angle.svg";

import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  svg: {
    width: "100%",
    height: "auto",
    path: {
      fill: theme.palette.footer.backgroundSolid,
    },
  },
}));

const FooterAngle = () => {
  return (
    <Wrapper>
      <FooterAngleSvg />
    </Wrapper>
  );
};

export default FooterAngle;
