import React from "react";
import styled from "@emotion/styled";
import { Stack, useTheme, Typography, useMediaQuery } from "@mui/material";
import StarChange from "../molecules/StarChange";
import SLPWon from "../atoms/SLPWon";
import AxieV3 from "components/Axie/AxieV3/AxieV3";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: "center",
  position: "relative",
  minWidth: "380px",
  background:
    theme.palette.mode === "light"
      ? theme.palette.card.pop1
      : theme.palette.card.shade1,
  borderRadius: "16px",
  padding: theme.spacing(2),
  [theme.breakpoints.down("xl")]: {
    minWidth: "300px",
    padding: theme.spacing(1),
  },

  ".playerName": {
    fontWeight: 700,
    color: theme.palette.text.bright,
    fontSize: 18,
    textAlign: "center",
    lineHeight: 1.1,
    maxWidth: "280px",
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial",
    },
  },
}));

const PlayerCondensed = ({
  name,
  axies,
  mmrBefore,
  mmrAfter,
  slpWon,
  fetchAxieData,
}) => {
  const theme = useTheme();
  const smallScreens = useMediaQuery(theme.breakpoints.down("md"));
  const handleRefreshAxie = (axieId) => {
    fetchAxieData(axieId);
  };
  return (
    <Wrapper>
      {name != null && (
        <Typography variant="span" className="playerName">
          {name}
        </Typography>
      )}

      <SLPWon
        amount={slpWon}
        size="small"
        style={{ position: "absolute", right: "8px", top: "8px" }}
      />

      <Stack direction="row" gap={1} sx={{ mt: "-16px" }}>
        {axies != null &&
          axies.map((axie, i) => (
            <AxieV3
              key={`AXIE_${i}`}
              variant="image"
              axie={axie}
              refreshAxie={handleRefreshAxie}
              width={smallScreens ? 90 : 100}
              axieType={axie.axie_type}
              id={axie.axieId}
              showCharms={true}
              showRune={true}
            />
          ))}
      </Stack>

      <StarChange mmrBefore={mmrBefore} mmrAfter={mmrAfter} />
    </Wrapper>
  );
};

export default PlayerCondensed;
