import { BsFillInfoCircleFill } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";
import { pluralize } from "helpers/display";

const InfoButton = ({
  numPlayers,
  numTopRunes,
}: {
  numPlayers: number;
  numTopRunes: number;
}) => {
  return (
    <Tooltip
      title={`The top ${numTopRunes} runes used by the top ${pluralize(
        "player",
        numPlayers
      )}.`}
    >
      <IconButton
        sx={{
          color: (theme) => theme.palette.colors.origin.paper.dark,
          padding: "1px",
          "&:hover": {
            color: (theme) => theme.palette.colors.origin.wood.background,
            backgroundColor: (theme) => theme.palette.colors.origin.paper.light,
          },
        }}
        size="small"
      >
        <BsFillInfoCircleFill />
      </IconButton>
    </Tooltip>
  );
};

export default InfoButton;
