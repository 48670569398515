import React, { useState } from "react";
import { Stack } from "@mui/material";
import MutedText from "components/Typography/MutedText";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import ByPart from "./ByPart";
import ByVersion from "./ByVersion";
import Text from "components/Typography/Text";

const Cards = ({ cards, isAdult }) => {
  const [viewByPart, setViewByPart] = useState(false);

  const handleUpdateSetViewByPart = (e) => {
    setViewByPart(e.target.checked);
  };

  if (!isAdult) {
    return (
      <Stack alignItems="center" sx={{ pt: 40 }}>
        <MutedText>Wen hatching?</MutedText>
      </Stack>
    );
  }

  return (
    <Stack gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{ alignSelf: "flex-end" }}
      >
        <Text style={{ minWidth: 120 }} textAlign="right">
          {viewByPart ? "By body part" : "By game version"}
        </Text>
        <CustomSwitch
          checked={viewByPart}
          onChange={handleUpdateSetViewByPart}
        />
      </Stack>

      {viewByPart ? <ByPart cardObj={cards} /> : <ByVersion cardObj={cards} />}
    </Stack>
  );
};

export default Cards;
