import React from "react";
import ListOfLinks from "./ListOfLinks";
import { dropdownItems } from "components/Navigation/navigation.links";
import ColumnWrapper from "../atoms/ColumnWrapper";

const Column3 = ({ colorOverrides }) => (
  <ColumnWrapper>
    <ListOfLinks
      links={dropdownItems.origins}
      colorOverrides={colorOverrides}
      title="Origins"
    />
    <ListOfLinks
      links={dropdownItems.classic}
      colorOverrides={colorOverrides}
      title="Classic"
    />
  </ColumnWrapper>
);

export default Column3;
