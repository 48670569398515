import { getDateString } from "helpers/display";

export const exportJSON = async (scholars) => {
  const fileName = `scholars-axietech-${getDateString(new Date())}.json`;
  const json = JSON.stringify(scholars);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const loadScholarsJSON = (callback) => {
  const input = document.createElement("input");
  input.type = "file";

  input.onchange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");

    reader.onload = (readerEvent) => {
      return callback(readerEvent.target.result);
    };
  };

  input.click();
};
