import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Name from "../atoms/PlayerName";
import RankNumber from "../atoms/PlayerRankNumber";
import Wrapper from "components/Top3Players/molecules/PlayerWrapper";
import RankTier from "components/Top3Players//atoms/PlayerRankTier";
import PlayerRankIcon from "components/Top3Players/atoms/PlayerRankIcon";
import VictoryStars from "components/Leaderboard/molecules/VictoryStars";
import FavoriteTeam from "components/Top3Players/molecules/FavoriteTeam";

const ContentWrapper = styled(Stack)(({ theme }) => ({
  height: "100%",
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Player = ({
  topRank,
  name,
  rank,
  tier,
  vstar,
  clientId,
  favoriteTeam,
  showCharms,
}) => {
  return (
    <Wrapper clientId={clientId}>
      <ContentWrapper
        direction={{ xs: "column", sm: "row", md: "row", lg: "column" }}
      >
        <RankNumber number={topRank} />
        <Name>{name}</Name>
        <Stack alignItems="center" justifyContent="center">
          <Stack
            gap={0.5}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <PlayerRankIcon rank={rank} />
            <RankTier rank={rank} tier={tier} />
          </Stack>
          <VictoryStars stars={vstar} colorMode="dark" />
        </Stack>

        <FavoriteTeam favoriteTeam={favoriteTeam} showCharms={showCharms} />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Player;
