import React from "react";
import { leaderboardStructures } from "./leaderboard.structures.js";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";
import { Stack, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ColumnHeaders from "./molecules/ColumnHeaders";
import Title from "./atoms/Title";
import ViewMoreButton from "./atoms/ViewMoreButton";
import Rows from "./molecules/Rows";

const Leaderboard = ({
  data,
  type,
  loading,
  failed,
  retry,
  viewMore,
  title,
  failedMessage = "Unable to load leaderboard",
  fullSize = true,
}) => {
  const theme = useTheme();
  const specs = leaderboardStructures[type];
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      gap={2}
      sx={{
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        padding: smUp ? "24px" : "32px",
        borderRadius: "8px",
        mb: 2,
      }}
    >
      <Title
        title={title || specs.title}
        titleIcon={specs.titleIcon}
        titleTooltip={specs.titleTooltip}
      />
      <DisplayLoadingOrContent
        loading={loading}
        failed={failed}
        retry={retry}
        failedMessage={failedMessage}
        data={data}
      >
        <Stack gap={2}>
          <Stack>
            <ColumnHeaders specs={specs} fullSize={fullSize} />
            <Rows data={data} specs={specs} fullSize={fullSize} />
          </Stack>
          <ViewMoreButton viewMore={viewMore} specs={specs} />
        </Stack>
      </DisplayLoadingOrContent>
    </Stack>
  );
};

export default Leaderboard;
