import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-12-06</Date>

      <Change type="improved">
          Released our{" "}
          <Link component={RouterLink} to="/breeding-simulator">
            Breeding Simulator v2
          </Link>
          ! This update includes improvements to the user interface, including
          paginated breeding results and a fullscreen select ronin axies dialog.
          We've also improved load times and page performance when simulating
          lots of potential breeds.
      </Change>
    </Entry>
  );
};

export default Changes;
