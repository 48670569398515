import { Stack, Box, useTheme } from "@mui/material";
import Card from "components/Card/Card";
import TopRunes from "pages/Leaderboards/Origin/organisms/TopRunes";
import Top3Players from "components/Top3Players/organisms/Top3Players";
import Header from "components/Widget/Header";
import { useGetOriginsLeaderboardQuery } from "store/apiSlice";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";
import { AXIE_INFINITY_ARENA_SEASON, AXIE_INFINITY_WEBSITE } from "config";

const OriginsWidget = ({
  topRunes,
  numPlayers,
  numTopRunes,
  top3Players,
  styles,
}) => {
  const theme = useTheme();
  const {
    data: originsLeaderboard,
    refetch: refetchOriginsLeaderboard,
    isError: isErrorOriginsLeaderboard,
    isFetching: isLoadingOriginsLeaderboard,
  } = useGetOriginsLeaderboardQuery();

  return (
    <Card>
      <Stack
        gap={3}
        alignItems="center"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <Header
          title={`Season ${AXIE_INFINITY_ARENA_SEASON} Snapshot`}
          imageUrl="/images/games/axie-infinity-origins-logo.png"
          imageHref={AXIE_INFINITY_WEBSITE}
          imageAltText="Axie Infiity Origins Logo"
        />

        {/* ena decoration */}
        <Box
          sx={{
            position: "absolute",
            left: "480px",
            top: "119px",
            width: "90px",
            [theme.breakpoints.down("lg")]: {
              width: "80px",
              left: "350px",
              top: "124px",
            },
            [theme.breakpoints.down("md")]: {
              left: "395px",
              top: "125px",
            },
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          <img
            width="100%"
            src="images/axies/starter-axies/ena.png"
            alt="Ena"
            title="Ena"
          />
        </Box>
        <Box
          sx={{
            background: theme.palette.card.gradient,
            padding: `8px 24px`,
            borderRadius: 4,
            border: `solid 7px ${theme.palette.card.shade2}`,
          }}
        >
          <TopRunes
            topRunes={topRunes}
            numPlayers={numPlayers}
            numTopRunes={numTopRunes}
            controls={[]}
            updateControls={null}
          />
        </Box>
        <DisplayLoadingOrContent
          loading={isLoadingOriginsLeaderboard}
          failed={isErrorOriginsLeaderboard}
          retry={refetchOriginsLeaderboard}
          data={originsLeaderboard}
          failedMessage="Unable to load players"
        >
          <Top3Players top3Players={top3Players} />
        </DisplayLoadingOrContent>
      </Stack>
    </Card>
  );
};

export default OriginsWidget;
