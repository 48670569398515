import React from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Outcome from "../atoms/Outcome";
import { MdInfo } from "react-icons/md";
import RefreshButton from "../atoms/RefreshButton";

const Title = ({ totalBattles, totalWins, handleRefresh }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
    >
      <Stack gap={1} alignItems="center" direction="row" flexWrap="wrap">
        <Stack alignItems="center" direction="row">
          <Typography
            variant="span"
            fontSize="20px"
            fontWeight="600"
            color="text.bright"
          >{`${totalBattles} Battle${
            totalBattles === 1 ? "" : "s"
          }`}</Typography>

          <Tooltip title="Includes recent arena battles">
            <IconButton size="small" sx={{ opacity: 0.5 }}>
              <MdInfo />
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack gap={1} alignItems="center" direction="row">
          <Outcome result="W" amount={totalWins} size="small" />
          <Outcome result="L" amount={totalBattles - totalWins} size="small" />
        </Stack>
      </Stack>
      <RefreshButton handleRefresh={handleRefresh} />
    </Stack>
  );
};

export default Title;
