import React from "react";
import { Stack } from "@mui/material";
import CardTemplateV2 from "components/GameTemplates/V2/CardTemplateV2";

const CardsV2 = ({ cards, width }) => {
  if (cards == null) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={(0.25 / width) * width}
    >
      {Object.values(cards).map(
        (card, i) =>
          card != null && (
            <CardTemplateV2
              key={`CARD_${i}`}
              card={card}
              variant="preview"
              width={width / 4}
            />
          )
      )}
    </Stack>
  );
};

export default CardsV2;
