import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-6-17</Date>
      <Change type="new">
        Added a page to showcase{" "}
        <Link to="/games" component={RouterLink}>
          Axie Infinity Builders Program projects
        </Link>
        .
      </Change>
    </Entry>
  );
};

export default Changes;
