import { useState } from "react";

const useViewAllAxiesModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return {
    isOpen,
    onClose,
    onOpen,
  };
};

export default useViewAllAxiesModal;
