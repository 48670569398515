import { createSelector } from "reselect";

const getGuildLeaderboard = (state) =>
  state.guildLeaderboard?.mmrLeaderboard?.data || [];

export const getGuildLeaderboardData = createSelector(
  [getGuildLeaderboard],
  (leaderboardData) => {
    const guildDetails = leaderboardData.map((item) => {
      return {
        guildId: item.guildId,
        profileImage: item.profileImage,
        logoUri: item.logoUri,
        guildName: item.guildName,
      };
    });
    const top50 = leaderboardData.slice(0, 50);
    const top25 = leaderboardData.slice(0, 25);

    return {
      top25,
      top50,
      guildDetails,
    };
  }
);
