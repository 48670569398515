import React, { useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "../hooks/useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import { Button, FormLabel, Stack, TextField } from "@mui/material";

const Crop = ({ setCrop, crop, imgSrc, handleDone, handleCancel }) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const aspect = 1;

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(
        centerCrop(
          makeAspectCrop(
            {
              unit: "%",
              width: 100,
            },
            1,
            width,
            height
          ),
          width,
          height
        )
      );
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  const getCroppedDataURL = (sourceCanvas, width) => {
    let destCanvas = document.createElement("canvas");

    destCanvas.width = width;
    destCanvas.height = width;

    destCanvas
      .getContext("2d")
      .drawImage(sourceCanvas, 0, 0, destCanvas.width, destCanvas.height);

    const dataUri = destCanvas.toDataURL("image/jpg", 1.0);

    destCanvas.remove();
    return dataUri;
  };

  const exportCanvas = () => {
    const canvas = previewCanvasRef.current;
    const maxSize = 600;

    if (canvas) {
      if (canvas.width > maxSize) {
        return getCroppedDataURL(canvas, maxSize);
      } else {
        return canvas.toDataURL("image/jpg", 1.0);
      }
    }
    return undefined;
  };

  if (imgSrc == null) {
    return null;
  }

  return (
    <Stack sx={{ width: 500 }} gap={2}>
      <Stack direction="row" alignItems="flex-start" gap={2}>
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale})` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}

        <Stack gap={2}>
          <Stack gap={1}>
            <FormLabel htmlFor="scale-input">Scale:</FormLabel>
            <TextField
              id="scale-input"
              type="number"
              size="small"
              value={scale}
              disabled={!imgSrc}
              onChange={(e) => setScale(Number(e.target.value))}
              InputProps={{
                inputProps: {
                  step: ".1",
                },
              }}
            />
          </Stack>

          <Stack gap={1}>
            <FormLabel>Preview:</FormLabel>
            {Boolean(completedCrop) && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: 80,
                  height: 80,
                }}
              />
            )}
          </Stack>

          <Button
            variant="contained"
            size="small"
            onClick={() => handleDone(exportCanvas())}
            sx={{ whiteSpace: "nowrap" }}
          >
            Done Editing
          </Button>

          <Button variant="outlined" size="small" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export async function canvasPreview(image, canvas, crop, scale = 1) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = Math.floor(crop.width * scaleX);
  canvas.height = Math.floor(crop.height * scaleY);

  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export default Crop;
