import React from "react";
import {
  Stack,
  useTheme,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import LinkedWithScroll from "../atoms/LinkedWithScroll";
import Title from "../atoms/Title";

const ListOfLinks = ({ links, title, colorOverrides, children }) => {
  const theme = useTheme();

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        {title != null && (
          <Title text={title} color={colorOverrides?.footer?.headerText} />
        )}
        {links.length > 0 && (
          <List>
            {links.map((link, i) => (
              <ListItem key={`LINK_${i}`} sx={{ px: 0, py: 0.5 }}>
                <ListItemText>
                  <LinkedWithScroll to={link.to} href={link.href}>
                    <Typography
                      sx={{
                        transition: "0.2s color ease",
                        "&:hover": {
                          color: colorOverrides?.footer?.textHover
                            ? colorOverrides?.footer?.textHover
                            : theme.palette.mode === "light"
                            ? "#fff"
                            : theme.palette.text.primary,
                        },
                      }}
                      fontWeight={500}
                      fontSize="14px"
                      color={
                        colorOverrides?.footer?.text
                          ? colorOverrides?.footer?.text
                          : theme.palette.footer.color
                      }
                    >
                      {link.text}
                    </Typography>
                  </LinkedWithScroll>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default ListOfLinks;
