import { CountryRegionData } from "react-country-region-selector";
import { MenuItem, TextField } from "@mui/material";
import { countryRegionMapping } from "./countryRegionMapping";

const getRegions = (countryStr) => {
  if (
    !countryStr ||
    countryStr === "" ||
    !(countryStr in countryRegionMapping)
  ) {
    return [];
  }
  const regionPairs = countryRegionMapping[countryStr]["regions"];
  return regionPairs.split("|").map((regionPair) => {
    // eslint-disable-next-line
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

export const CountryRegionSelect = ({
  country,
  setCountry,
  region,
  setRegion,
  style,
}) => {
  const nullEntry = [""];

  return (
    <div className="country-region-selectors" style={style}>
      <TextField
        label="Country"
        value={country}
        select
        onChange={(e) => {
          setCountry(e.target.value);
          setRegion("");
        }}
        variant="filled"
        size="small"
      >
        {[nullEntry, ...CountryRegionData].map((option, index) => (
          <MenuItem
            key={option[0]}
            value={option[0]}
            sx={{ minHeight: "33px !important" }}
          >
            {option[0]}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Region"
        value={region}
        select
        onChange={(e) => setRegion(e.target.value)}
        variant="filled"
        size="small"
        disabled={country == null || country === ""}
      >
        {[nullEntry, ...getRegions(country)].map((option, index) => (
          <MenuItem
            key={option}
            value={option}
            sx={{ minHeight: "33px !important" }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default CountryRegionSelect;
