import { Stack, Box, useTheme } from "@mui/material";
import Axie from "pages/DoLL/Leaderboard/atoms/Axie";
import Sidekick from "pages/DoLL/Leaderboard/atoms/Sidekick";
import Points from "pages/DoLL/Leaderboard/atoms/Points";
import Info from "pages/DoLL/Leaderboard/molecules/Info";
import Rank from "components/Top3DollPlayers/atoms/Rank";
import Name from "components/Top3DollPlayers/atoms/Name";
const Player = ({
  username,
  xpTotal,
  survivalTime,
  monstersDefeated,
  rank,
  sidekick,
  avatar,
}) => {
  const theme = useTheme();
  return (
    <Stack
      height="135px"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        position: "relative",
        borderRadius: 3,
        background: (theme) => theme.palette.card.pop1,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.03)",
        },
        [theme.breakpoints.down("sm")]: {
          height: "auto",
        },
      }}
    >
      <Stack alignItems="center" justifyContent="center">
        <Rank rank={rank} />
        <Name username={username} />
      </Stack>

      <Stack
        gap={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Points points={xpTotal} />
        <Info
          survivalTime={survivalTime}
          monstersDefeated={monstersDefeated}
          gap={0}
          fontSize={16}
        />
      </Stack>
      {/* axie and sidekick images */}
      <Stack
        width="100%"
        direction="row"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Box
          sx={{
            position: "absolute",
            width: "27%",
            right: "312px",
            bottom: "10px",
            [theme.breakpoints.down("lg")]: {
              width: "32%",
              right: "215px",
              bottom: "45px",
            },
            [theme.breakpoints.down("md")]: {
              width: "22%",
              right: "390px",
              bottom: "28px",
            },
            [theme.breakpoints.down("sm")]: {
              position: "static",
              width: "95px",
            },
          }}
        >
          <Axie id={avatar?.axieId} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: "335px",
            bottom: "10px",
            [theme.breakpoints.down("lg")]: {
              left: "215px",
              bottom: "50px",
            },
            [theme.breakpoints.down("md")]: {
              left: "418px",
              bottom: "40px",
            },
            [theme.breakpoints.down("sm")]: {
              position: "static",
              width: "35%",
            },
          }}
        >
          <Sidekick img={sidekick?.assets?.noBg} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default Player;
