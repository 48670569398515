import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  background:
    "linear-gradient(180deg, #4977CD 0%, #5888E5 25%, #23B8D1 75%, #2BD4B8 100%)",
  position: "relative",
  ".sky": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
}));

const Sky = styled(Stack)(({ theme }) => ({
  background: "url('/images/games/doll/clouds.png')",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  paddingBottom: "37%",
  width: "100%",
}));

const SkyBackground = ({ children }) => {
  return (
    <Wrapper>
      <Sky className="sky" />
      {children}
    </Wrapper>
  );
};

export default SkyBackground;
