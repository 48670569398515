import React from "react";
import { Stack, Tooltip, useTheme, Typography } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import { GoLinkExternal } from "react-icons/go";
import PriceInDollars from "pages/Inspector/atoms/PriceInDollars";

const Market = ({
  loading,
  label,
  total,
  floorPrice,
  setView,
  marketplaceUrl,
  tooltip,
}) => {
  const tooltipText = "View on Marketplace";

  return total == null ? (
    <></>
  ) : (
    <Stack gap={2}>
      <SectionLabel
        icon={<GoLinkExternal />}
        tooltip={tooltipText}
        iconUrl={marketplaceUrl}
      >
        Marketplace
      </SectionLabel>

      {loading ? (
        <Wrapper justifyContent="center" setView={setView} tooltip={tooltip}>
          <SectionLabel color="text.secondary">Loading...</SectionLabel>
        </Wrapper>
      ) : (
        <Wrapper setView={setView} tooltip={tooltip}>
          <SectionLabel fontSize={24} color="text.bright">
            {total}
          </SectionLabel>
          <Stack gap={0.5}>
            <SectionLabel color="text.regular">{label}</SectionLabel>
            {floorPrice != null && (
              <Stack>
                <Typography
                  color="text.veryMuted"
                  fontWeight={600}
                  fontSize={14}
                  variant="span"
                >
                  Min: {floorPrice}
                </Typography>
                <PriceInDollars priceInEth={floorPrice} />
              </Stack>
            )}
          </Stack>
        </Wrapper>
      )}
    </Stack>
  );
};

const Wrapper = ({
  children,
  justifyContent = "flex-start",
  setView,
  tooltip,
}) => {
  const theme = useTheme();

  return (
    <Tooltip title={tooltip}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={justifyContent}
        gap={3}
        sx={{
          borderRadius: "12px",
          py: 2,
          px: 4,
          background: theme.palette.inspector.shaded,
          transition: "0.2s transform ease",
          "&:hover": { cursor: "pointer", transform: "scale(1.05)" },
        }}
        onClick={() => setView(["SIMILAR_LISTINGS"])}
      >
        {children}
      </Stack>
    </Tooltip>
  );
};

export default Market;
