import * as types from "./inspectorActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./inspectorActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* fetchEstimatedPrice(action) {
  try {
    yield put(actions.fetchEstimatedPriceStarted());

    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGetSaleEstimate,
      {
        axies: [parseInt(action.axieId)],
        land: [],
      }
    );

    yield put(actions.fetchEstimatedPriceSucceeded(data));
  } catch (e) {
    yield put(actions.fetchEstimatedPriceFailed());
  }
}

export default function* estimatedPriceSaga() {
  yield takeEvery(types.INSPECTOR_FETCH_AXIE, fetchEstimatedPrice);
}
