import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { todayIsSholarsBirthday } from "helpers/scholars";

const BirthdayCake = ({ dob, name }) => {
  if (!todayIsSholarsBirthday(dob)) {
    return null;
  }
  return (
    <Tooltip title={`Happy birthday, ${name}!`}>
      <Typography variant="span" sx={{ cursor: "pointer", marginLeft: "8px" }}>
        🎂
      </Typography>
    </Tooltip>
  );
};

export default BirthdayCake;
