import React from "react";
import CustomLoader from "components/CustomLoader/CustomLoader";
import FailedMessage from "components/FailedMessage/FailedMessage";
import { IoRefreshCircle } from "react-icons/io5";
import { Container, Stack, Button, Box } from "@mui/material";

const DisplayLoadingOrContent = ({
  loading,
  failed,
  retry,
  data,
  loader = <CustomLoader marginTop={80} marginBottom={80} />,
  failedMessage = "Something went wrong",
  retryButtonText = "Try again",
  retryButtonIcon = <IoRefreshCircle />,
  children,
}) => {
  if (loading) {
    return <Container fixed>{loader}</Container>;
  }
  if (failed) {
    return (
      <Container fixed>
        <Box sx={{ pt: 8, pb: 12 }}>
          <Stack justifyContent="center" alignItems="center" gap={2}>
            <FailedMessage text={failedMessage} />
            <Button
              endIcon={retryButtonIcon}
              onClick={() => retry()}
              variant="contained"
              size="large"
            >
              {retryButtonText}
            </Button>
          </Stack>
        </Box>
      </Container>
    );
  }
  if (data) {
    return children;
  }
  return <></>;
};

export default DisplayLoadingOrContent;
