import { Stack } from "@mui/material";
import Charms from "./Charms";
import RuneTemplateTiny from "components/GameTemplates/RuneTemplateTiny";

const AxieItems = ({ axie, width }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={(width / 240) * 0.5}
      alignItems="center"
    >
      <RuneTemplateTiny
        rune={axie?.runeData}
        width={(width / 240) * 42}
        tooltip={true}
      />
      <Charms
        charmData={axie?.charmData}
        width={(width / 240) * 180}
        showBodyParts={true}
      />
    </Stack>
  );
};

export default AxieItems;
