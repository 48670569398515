import { ORIGIN_LEADERBOARD, GUILD_ARENA_LEADERBOARD } from "config";
import styles from "pages/Home/home.module.scss";
import Leaderboard from "components/Leaderboard";
import {
  Container,
  useTheme,
  Stack,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useGetOriginsLeaderboardQuery } from "store/apiSlice";
import { Link as RouterLink } from "react-router-dom";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import MainTitle from "pages/Home/atoms/MainTitle";

const Leaderboards = ({ fetchGuildLeaderboard, guildLeaderboard }) => {
  const theme = useTheme();
  const {
    data: originsLeaderboard,
    refetch: refetchOriginsLeaderboard,
    isError: isErrorOriginsLeaderboard,
    isFetching: isLoadingOriginsLeaderboard,
  } = useGetOriginsLeaderboardQuery();

  const numGuildsToShow = 10;

  return (
    <div className={styles.leaderboards}>
      <Container fixed>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            alignItems="center"
            justifyContent="center"
          >
            <Stack alignItems="center" justifyContent="center">
              <MainTitle>Top Players and Guilds</MainTitle>
            </Stack>
          </Grid>
          <Grid item sm={12} md={6}>
            <Leaderboard
              title="Top 20 Arena Players"
              type={ORIGIN_LEADERBOARD}
              data={originsLeaderboard?.slice(0, 10)}
              viewMore={true}
              fullSize={false}
              loading={isLoadingOriginsLeaderboard}
              failed={isErrorOriginsLeaderboard}
              retry={refetchOriginsLeaderboard}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Leaderboard
              title={`Top ${numGuildsToShow} Guilds - Stars`}
              type={GUILD_ARENA_LEADERBOARD}
              data={guildLeaderboard.data?.slice(0, numGuildsToShow)}
              viewMore={true}
              fullSize={false}
              loading={guildLeaderboard.fetching}
              failed={guildLeaderboard.failed}
              retry={fetchGuildLeaderboard}
            />
          </Grid>
        </Grid>

        <Stack
          gap={1}
          mt={8}
          alignItems="center"
          justifyContent="center"
          sx={{
            [theme.breakpoints.down("sm")]: {
              marginTop: 3,
            },
          }}
        >
          <Typography
            variant="body1"
            color="text.secondary"
            fontSize={20}
            fontWeight="700"
            sx={{ lineHeight: 1.2 }}
          >
            Want to see how your guild stacks up?
          </Typography>
          <Typography
            variant="body2"
            fontWeight={600}
            color="text.regular"
            fontSize={16}
            sx={{ mb: 2 }}
          >
            Become an Axie Tech Guild Member and claim your spot on the
            leaderboards!
          </Typography>
          <Button
            component={RouterLink}
            variant="outlined"
            size="medium"
            to="/guild-membership"
            endIcon={<IoCaretForwardCircleOutline />}
          >
            Learn More
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default Leaderboards;
