import React from "react";
import * as bodyPartIcons from "components/Axie/icons/bodyPartIcons";
import { Box, Stack } from "@mui/material";

const PartIcon = ({ part, dPartClass, width, large }) =>
  dPartClass != null ? (
    <Stack
      sx={{
        "& svg": {
          width: `${(22 / 320) * width * (large ? 1.2 : 1)}px`,
          height: "auto",
        },
      }}
    >
      {bodyPartIcons[`${part}_${dPartClass.toLowerCase()}`]}
    </Stack>
  ) : (
    <Box style={{ width: `${(26 / 320) * width}px` }} />
  );

export default PartIcon;
