import BattlesChart from "../atoms/BattlesChart";
import { Stack } from "@mui/material";
import Title from "../atoms/Title";

const BattlesChartWrapper = ({ data, toDisplay }) => {
  return (
    <Stack gap={1} sx={{ height: "100%", pt: "32px" }}>
      <Stack gap={1} direction="row" justifyContent="space-between">
        <Title>Battles</Title>
      </Stack>
      <BattlesChart data={data} />
    </Stack>
  );
};

export default BattlesChartWrapper;
