import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  removeNonNumericChars,
  validateRoninAddress,
} from "helpers/cleaning";
import { connect } from "react-redux";
import SelectAxiesDialog from "./molecules/SelectAxiesDialog";
import {
  addBreedingAxieId,
  deleteBreedingAxieId,
  updateRequiredBreedingId,
  fetchAllRoninAxies,
} from "store/breedingSimulator/breedingSimulatorActions";
import ResultsPanel from "./organisms/ResultsPanel";
import Sidebar from "./molecules/Sidebar";
import PageGrid from "./atoms/PageGrid";

const BreedingSimulator = ({
  addBreedingAxieId,
  requiredId,
  updateRequiredBreedingId,
  deleteBreedingAxieId,
  fetchAllRoninAxies,
}) => {
  const [openSelectAxiesDialog, setOpenSelectAxiesDialog] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const ids = query.get("ids");
  const addresses = query.get("addresses");

  useEffect(() => {
    if (ids != null) {
      const idsArray = ids.split(",");
      idsArray.forEach((id) => {
        const cleanId = removeNonNumericChars(id);
        if (cleanId !== "") {
          addBreedingAxieId(cleanId);
        }
      });
    }
  }, [ids, addBreedingAxieId]);

  useEffect(() => {
    if (addresses != null) {
      const addressesArray = addresses.split(",");
      setOpenSelectAxiesDialog(true);
      const validAddresses = [];
      addressesArray.forEach((address) => {
        try {
          const validAddress = validateRoninAddress(address);
          validAddresses.push(validAddress);
        } catch (e) {
          console.log("Bad ronin address: ", address);
        }
      });

      if (validAddresses.length > 0) {
        fetchAllRoninAxies(validAddresses);
      }
    }
  }, [addresses, fetchAllRoninAxies]);

  const handleDeleteId = (id) => {
    if (id === requiredId) {
      updateRequiredBreedingId("");
    }
    deleteBreedingAxieId(id);
  };

  return (
    <>
      <PageGrid>
        <Sidebar
          setOpenSelectAxiesDialog={setOpenSelectAxiesDialog}
          handleDeleteId={handleDeleteId}
        />
        <ResultsPanel handleDeleteId={handleDeleteId} />
      </PageGrid>
      <SelectAxiesDialog
        open={openSelectAxiesDialog}
        setOpen={setOpenSelectAxiesDialog}
      />
    </>
  );
};

const mapDispatchToProps = {
  addBreedingAxieId,
  deleteBreedingAxieId,
  updateRequiredBreedingId,
  fetchAllRoninAxies,
};

const mapStateToProps = (state) => {
  return {
    requiredId: state.breedingSimulator.requiredId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreedingSimulator);
