import React from "react";
import { Stack, Typography } from "@mui/material";
import { FaQuestion } from "react-icons/fa";

const Unknown = ({ width }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%) translateY(-50%)",
        top: "50%",
      }}
    >
      <Typography
        color="text.muted"
        fontSize={(60 / 320) * width}
        sx={{ lineHeight: 1, position: "relative", top: 2 }}
      >
        <FaQuestion />
      </Typography>
    </Stack>
  );
};

export default Unknown;
