import React from "react";
import { Typography } from "@mui/material";

const PageTitle = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize="24px"
      fontWeight="700"
      color="text.bright"
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
