import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";

const IconMessage = ({
  icon,
  text,
  background,
  textColor,
  color = "red",
  style,
  fontSize = 15,
  iconSize,
}) => {
  const theme = useTheme();
  const textColorMapping = {
    red: theme.palette.colors.white,
    orange: theme.palette.colors.white,
    yellow: theme.palette.colors.black,
    green:
      theme.palette.mode === "light"
        ? theme.palette.colors.white
        : theme.palette.colors.black,
  };

  return (
    <Stack
      direction="row"
      gap={0.5}
      sx={{
        p: "4px 8px 5px 8px",
        background:
          background ||
          theme.palette.feedback[color] ||
          theme.palette.feedback.red,
        borderRadius: "4px",
        ...style,
      }}
      alignItems="center"
      justifyContent="center"
    >
      {icon !== undefined && (
        <Stack
          sx={{
            fontSize: `${iconSize || fontSize + 1}px`,
            color: textColor || textColorMapping[color],
          }}
        >
          {icon}
        </Stack>
      )}
      <Typography
        variant="span"
        fontSize={`${fontSize}px`}
        fontWeight="600"
        color={textColor || textColorMapping[color]}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default IconMessage;
