import { Stack, Typography, useTheme } from "@mui/material";
import { addAlphaToHex } from "helpers/colors";
import { CSSProperties } from "react";
import { ImCross, ImCheckmark } from "react-icons/im";
import { BattleResult } from "types";

interface Props {
  result: BattleResult;
  amount?: number;
  size?: "xsmall" | "small" | "medium";
  iconOnly?: boolean;
  style?: CSSProperties;
}

const Outcome = ({
  result,
  amount,
  size = "medium",
  iconOnly = false,
  style,
}: Props) => {
  const theme = useTheme();
  const color =
    result === "W" ? theme.palette.feedback.green : theme.palette.feedback.red;

  const padding = {
    xsmall: "4px 8px",
    small: "8px 12px",
    medium: "10px 16px",
  };

  const fontSize = {
    xsmall: 12,
    small: 16,
    medium: 22,
  };

  const borderRadius = {
    xsmall: "4px",
    small: "10px",
    medium: "16px",
  };

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        background: addAlphaToHex(color, 0.24),
        p: padding[size],
        borderRadius: borderRadius[size],
        ...style,
      }}
    >
      <Stack
        sx={{
          fontSize: fontSize[size],
          color,
        }}
      >
        {result === "W" ? <ImCheckmark /> : <ImCross />}
      </Stack>
      {amount == null && !iconOnly ? (
        <Text fontSize={fontSize[size]}>
          {result === "W" ? "Victory" : "Defeat"}
        </Text>
      ) : amount != null ? (
        <Text fontSize={fontSize[size]}>{`${amount} ${
          result === "W"
            ? amount === 1
              ? "Victory"
              : "Victories"
            : amount === 1
            ? "Defeat"
            : "Defeats"
        }`}</Text>
      ) : (
        <></>
      )}
    </Stack>
  );
};

const Text = ({
  children,
  fontSize,
}: {
  children: React.ReactNode;
  fontSize: number;
}) => (
  <Typography
    component="span"
    fontSize={fontSize}
    color="text.regular"
    fontWeight="600"
  >
    {children}
  </Typography>
);

export default Outcome;
