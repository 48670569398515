import React from "react";
import { Stack } from "@mui/material";
import ColumnLabel from "../atoms/ColumnLabel";
import ColumnAmount from "../atoms/ColumnAmount";

const Column = ({ label, amount, color, capsule }) => {
  return (
    <Stack gap={1} justifyContent="center">
      <ColumnLabel>{label}</ColumnLabel>
      <ColumnAmount color={color} capsule={capsule}>
        {amount}
      </ColumnAmount>
    </Stack>
  );
};

export default Column;
