import React from "react";
import {
  TextField,
  useTheme,
  InputAdornment,
  Stack,
  Container,
} from "@mui/material";
import styles from "./card-explorer.module.scss";
import { FaSearch } from "react-icons/fa";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { AXIE_CLASSES } from "config";

const Controls = ({ searchCriteria, setSearchCriteria, title }) => {
  const theme = useTheme();
  const handleUpdateSearchCriteria = (newCriteria) => {
    setSearchCriteria({ ...searchCriteria, ...newCriteria });
  };
  const handleUpdateMultiSelect = (property) => {
    return (newVal) =>
      handleUpdateSearchCriteria({
        [property]: newVal,
      });
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        borderBottom: `1px solid ${theme.palette.card.divider}`,
      }}
    >
      <Container>
        <Stack
          direction="row"
          className={styles.header}
          alignItems="center"
          flexWrap="wrap"
          gap={6}
        >
          <Stack gap={3} sx={{ maxWidth: "600px" }}>
            {title}
          </Stack>

          <Stack gap={2} alignItems="flex-end">
            <TextField
              onChange={(e) =>
                handleUpdateSearchCriteria({
                  searchText: e.target.value.toLowerCase(),
                })
              }
              hiddenLabel
              variant="filled"
              placeholder="Search cards"
              value={searchCriteria.searchText}
              sx={{ width: 316 }}
              spellCheck={false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaSearch />
                  </InputAdornment>
                ),
                sx: {
                  paddingLeft: 2,
                },
              }}
            />
            <div className={styles.dropdowns}>
              <CustomMultiSelect
                label="Class"
                value={searchCriteria.axieClasses}
                setValue={handleUpdateMultiSelect("axieClasses")}
                options={AXIE_CLASSES}
                style={{ width: 150, maxWidth: 300, height: 51 }}
              />
              <CustomMultiSelect
                label="Part Type"
                value={searchCriteria.partTypes}
                setValue={handleUpdateMultiSelect("partTypes")}
                options={["back", "horn", "mouth", "tail"]}
                style={{ width: 150, height: 51 }}
              />
              <CustomSelect
                label="Cost"
                setVal={handleUpdateMultiSelect("cost")}
                val={searchCriteria.cost}
                options={["any", "1", "0"]}
                style={{ width: 150, height: 51 }}
              />
              <CustomSelect
                label="Attack Type"
                value={searchCriteria.attackType}
                setVal={handleUpdateMultiSelect("attackType")}
                options={["any", "melee", "ranged"]}
                style={{ width: 150, height: 51 }}
              />
            </div>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Controls;
