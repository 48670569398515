import React, { useEffect } from "react";
import * as config from "config";
import { connect } from "react-redux";
import { FaEnvelope } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io5";
import CallToAction from "components/CallToAction/CallToAction";
import GuildShowcaseBanner from "./GuildShowcaseBanner";
import { features } from "./guildMembership.features";
import Testimonials from "./Testimonials";
import { Typography, Link, Stack } from "@mui/material";
import Page from "components/Utility/Page";
import PageTitle from "components/Typography/PageTitle";
import { Link as RouterLink } from "react-router-dom";
import YouTubeEmbed from "components/YouTubeEmbed/YouTubeEmbed";
import IconInfoCard from "./IconInfoCard";
import { fetchGuildMMRLeaderboard } from "store/leaderboard/guild/leaderboardActions";
import { getGuildLeaderboardData } from "store/leaderboard/guild/guildLeaderboardSelector";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { BiMedal } from "react-icons/bi";

const GuildMembership = ({ fetchGuildMMRLeaderboard, data, loading }) => {
  useEffect(() => {
    fetchGuildMMRLeaderboard();
  }, [fetchGuildMMRLeaderboard]);

  const button = {
    text: "Email Us",
    href: "mailto:lev@axie.tech,rare@axie.tech,best@axie.tech?subject=Axie Tech Guild Membership",
    icon: <FaEnvelope />,
  };
  const button2 = {
    text: "Tweet Us",
    href: config.AXIE_TECH_TWITTER,
    icon: <IoLogoTwitter />,
  };

  return (
    <>
      <Page>
        <PageTitle>Guild Membership</PageTitle>

        <Stack gap={4}>
          <IntroText />

          <IconInfoCard features={features} />
          <img
            style={{
              display: "block",
              width: "100%",
              borderRadius: "16px",
            }}
            src="/images/guild-battle-may2023.png"
            title="Axie Tech Guild Battle Finals"
            alt="Axie Tech Guild Battle Finals"
          />

          <CallToAction button={button} button2={button2}>
            <strong>Are you ready to take your guild to the next level?</strong>
            <br />
            Membership is free! 🎉
            <br />
            Get in touch to sign up.
          </CallToAction>
        </Stack>
      </Page>

      {!loading && data ? (
        <GuildShowcaseBanner
          prefix="We support"
          amount="150+ guilds"
          suffix="– rising daily!"
          guildDetails={data.guildDetails}
          title={"Our Top Guilds"}
          icon={<BiMedal />}
        />
      ) : (
        <CustomLoader />
      )}

      <Page>
        <Stack gap={4}>
          <Typography
            variant="h3"
            fontWeight="700"
            color="text.bright"
            textAlign="center"
          >
            Demo Video
          </Typography>

          <YouTubeEmbed
            embedId="BhZhfuW8nEQ"
            title="Axie Tech Guild Dashboard"
            style={{ borderRadius: "16px" }}
          />

          <Testimonials />
        </Stack>
      </Page>
    </>
  );
};

const IntroText = () => {
  return (
    <Stack gap={1}>
      <Typography
        variant="body1"
        component="h2"
        fontWeight="600"
        color="text.regular"
      >
        The Axie.Tech Guild Dashboard includes a suite of tools, allowing guilds
        to streamline their day-to-day operations as well as track and improve
        player performance. What's more, it's all free!
      </Typography>
      <Typography
        variant="body1"
        component="h2"
        fontWeight="600"
        color="text.regular"
      >
        Join today and start climbing the{" "}
        <Link to={"/guild-leaderboards"} component={RouterLink}>
          guild leaderboards
        </Link>
        !
      </Typography>
    </Stack>
  );
};

const mapDispatchToProps = {
  fetchGuildMMRLeaderboard,
};

const mapStateToProps = (state) => ({
  data: getGuildLeaderboardData(state),
  mmrLeaderboard: state.guildLeaderboard.mmrLeaderboard.data || [],
  loading: state.guildLeaderboard.mmrLeaderboard.fetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(GuildMembership);
