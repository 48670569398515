import { createSelector } from "reselect";
import {
  calculateRequiredSLPForBreed,
  calculateRequiredAXSForBreed,
} from "helpers/axie";
import { MARKET_FEE } from "config";

const getBreedingCalculatorInputs = (state) => {
  return state.breedingCalculator.inputs || {};
};

export const calculateChildSales = (numberOfBreeds, childSales) => {
  let sales = 0;
  Array.from(Array(numberOfBreeds).keys()).map(
    (breed) => (sales += childSales[breed])
  );
  return sales * (1 - MARKET_FEE);
};

export const calculateParentSales = (parent1Sale, parent2Sale) => {
  return (parent1Sale + parent2Sale) * (1 - MARKET_FEE);
};

export const generateBreedingSummary = createSelector(
  [getBreedingCalculatorInputs],
  (inputs) => {
    const numberOfBreeds = parseInt(inputs.numberOfBreeds);
    const requiredSLP = calculateRequiredSLPForBreed(
      numberOfBreeds,
      inputs.parent1.breedCount,
      inputs.parent2.breedCount
    );
    const requiredAXS = calculateRequiredAXSForBreed(
      numberOfBreeds,
      inputs.parent1.breedCount,
      inputs.parent2.breedCount
    );
    const totalParentCosts = inputs.parent1.costETH + inputs.parent2.costETH;
    const totalParentSales = calculateParentSales(
      inputs.parent1.saleETH,
      inputs.parent2.saleETH
    );
    const totalChildSales = calculateChildSales(
      inputs.numberOfBreeds,
      inputs.childSalesETH
    );
    return {
      shoppingList: {
        slp: {
          required: requiredSLP,
          inventory: inputs.inventory.slp,
          toBuy:
            requiredSLP - inputs.inventory.slp < 0
              ? 0
              : requiredSLP - inputs.inventory.slp,
          costETH: inputs.inventory.useMarketPrice.slp
            ? undefined
            : inputs.inventory.basisCosts.slp.eth,
          costUSD: inputs.inventory.useMarketPrice.slp
            ? undefined
            : inputs.inventory.basisCosts.slp.usd,
        },
        axs: {
          required: requiredAXS,
          inventory: inputs.inventory.axs,
          toBuy:
            requiredAXS - inputs.inventory.axs < 0
              ? 0
              : requiredAXS - inputs.inventory.axs,
          costETH: inputs.inventory.useMarketPrice.axs
            ? undefined
            : inputs.inventory.basisCosts.axs.eth,
          costUSD: inputs.inventory.useMarketPrice.axs
            ? undefined
            : inputs.inventory.basisCosts.axs.usd,
        },
      },
      totalParentCostsETH: totalParentCosts,
      totalParentSalesETH: totalParentSales,
      totalChildSalesETH: totalChildSales,
      totalSalesETH: totalParentSales + totalChildSales,
    };
  }
);

export const breedingSummaryMessage = createSelector(
  [getBreedingCalculatorInputs],
  (inputs) => {
    if (inputs.parent1.breedCount === 7 || inputs.parent2.breedCount === 7) {
      return "Axies cannot be bred more than 7 times.";
    }
    if (
      inputs.parent1.breedCount + inputs.numberOfBreeds > 7 &&
      inputs.parent2.breedCount + inputs.numberOfBreeds > 7
    ) {
      return `The parents cannot be bred ${inputs.numberOfBreeds} times`;
    }
    if (inputs.parent1.breedCount + inputs.numberOfBreeds > 7) {
      return `Parent 1 cannot be bred ${inputs.numberOfBreeds} times`;
    }
    if (inputs.parent2.breedCount + inputs.numberOfBreeds > 7) {
      return `Parent 2 cannot be bred ${inputs.numberOfBreeds} times`;
    }
    return undefined;
  }
);
