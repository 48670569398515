import { Stack, styled } from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import { Axie } from "types";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.colors.origin.wood.backgroundDark,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: 8,
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "-4px",
  gap: theme.spacing(1),
}));

interface Props {
  favoriteTeam: any;
  showCharms?: boolean;
  width?: number;
  showRune?: boolean;
}

const FavoriteTeam = ({
  favoriteTeam,
  showCharms,
  width = 80,
  showRune,
}: Props) => {
  return favoriteTeam == null ? (
    <></>
  ) : (
    <Wrapper>
      {favoriteTeam.map((axie: Axie, i: number) => (
        <AxieV3
          variant="image"
          axie={axie}
          width={width}
          key={`AXIE_${i}`}
          disableMarketplaceLink={true}
          showCharms={showCharms}
          showRune={showRune}
          showBodyParts={false}
        />
      ))}
    </Wrapper>
  );
};

export default FavoriteTeam;
