import { IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { IoCloseCircle } from "react-icons/io5";

const ClearButton = ({ onClick }) => {
  const theme = useTheme();
  return (
    <Tooltip title="Clear All">
      <IconButton
        onClick={onClick}
        sx={{
          position: "absolute",
          right: 20,
          top: 20,
          color: theme.palette.colors.origin.paper.dark,
          zIndex: 4,
          minWidth: 0,
          transition: "0.2s all ease",
          "&:hover": {
            background: theme.palette.colors.origin.paper.dark,
            color: theme.palette.colors.origin.wood.background,
          },
        }}
      >
        <IoCloseCircle />
      </IconButton>
    </Tooltip>
  );
};

export default ClearButton;
