import { createSelector } from "reselect";
import { scholarMatchesSearchQuery } from "helpers/scholars";
import { NUM_SCHOLAR_TEAMS_PER_PAGE } from "config";
import {
  axieMatchesCriteria,
  getAxieRole,
  getAxieSpecialty,
  getCardsForAxie,
  getSelectedParts,
} from "helpers/axie";
import {
  getTeamlessScholars,
  getTeamedScholars,
  getAxiesCriteria,
  sortTeams,
} from "./common";
import { getAllCardsV3 } from "store/data/dataSelectors";

export const getAxiesToDisplay = createSelector(
  [getTeamlessScholars, getTeamedScholars, getAxiesCriteria, getAllCardsV3],
  (teamlessScholars, teamedScholars, criteria, allCards) => {
    const start = (criteria.currentPage - 1) * NUM_SCHOLAR_TEAMS_PER_PAGE;
    const end = start + NUM_SCHOLAR_TEAMS_PER_PAGE;

    const players = [...teamlessScholars, ...teamedScholars];

    const playersToDisplay = players
      .reduce((result, scholar) => {
        if (scholarMatchesSearchQuery(scholar, criteria.searchQuery)) {
          if (scholar.fetchingAxies) {
            result.push({
              ...scholar,
              filteredAxies: undefined,
              numAxies: "?",
              numEggs: "?",
              numAdults: "?",
            });
          } else if (scholar.axies !== undefined && scholar.axies.length > 0) {
            let numEggs = 0;
            let filteredAxies = scholar.axies.filter((axie) => {
              const cards = getCardsForAxie(axie, allCards);
              const axieRole = getAxieRole(cards?.cardV3Array);
              const axieSpecialty = getAxieSpecialty(cards?.cardV3Array);
              if (axie.stage === "1" || axie.stage === 1) {
                numEggs += 1;
              }
              return axieMatchesCriteria([
                {
                  key: "SELECTED_PARTS",
                  args: [axie?.parts, getSelectedParts(criteria)],
                },
                {
                  key: "AXIE_STAGE",
                  args: [parseInt(axie.stage), [4]],
                },
                {
                  key: "AXIE_CLASS",
                  args: [axie.class, criteria.axieClasses],
                },
                {
                  key: "BREED_COUNT",
                  args: [axie.breedCount.toString(), criteria.breedCount],
                },
                {
                  key: "ROLE",
                  args: [axieRole, criteria.role],
                },
                {
                  key: "SPECIALTY",
                  args: [axieSpecialty, criteria.specialty],
                },
                {
                  key: "PURITY_COUNT",
                  args: [
                    axie.purity && axie.purity.purityCount
                      ? axie.purity.purityCount.toString()
                      : undefined,
                    criteria.purity,
                  ],
                },
                {
                  key: "STAT",
                  args: [axie?.stats?.hp, criteria.hp],
                },
                {
                  key: "STAT",
                  args: [axie?.stats?.skill, criteria.skill],
                },
                {
                  key: "STAT",
                  args: [axie?.stats?.morale, criteria.morale],
                },
                {
                  key: "STAT",
                  args: [axie?.stats?.speed, criteria.speed],
                },
                {
                  key: "PURITY_PERCENT",
                  args: [
                    axie.purity && axie.purity.purityPercent
                      ? axie.purity.purityPercent
                      : undefined,
                    criteria.purityPercent,
                  ],
                },
              ]);
            });
            if (filteredAxies.length > 0) {
              const numAxies = scholar.axies.length;
              result.push({
                ...scholar,
                filteredAxies,
                numAxies,
                numEggs,
                numAdults: numAxies - numEggs,
              });
            }
          }
        }
        return result;
      }, [])
      .sort((a, b) => sortTeams(a, b, criteria.sortType));

    return {
      players: playersToDisplay.slice(start, end),
      numberOfPages: playersToDisplay
        ? Math.ceil(playersToDisplay.length / NUM_SCHOLAR_TEAMS_PER_PAGE)
        : 1,
    };
  }
);
