import React from "react";
import IconMessage from "components/IconMessage/IconMessage";

const ReadyNow = ({ style }) => {
  return (
    <IconMessage text="Ready Now!" color="green" fontSize={13} style={style} />
  );
};

export default ReadyNow;
