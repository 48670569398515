import React from "react";
import { DialogTitle, Stack, Chip } from "@mui/material";
import Label from "pages/TeamBuilder/atoms/Label";
import PotentialPoints from "components/Axie/AxieV3/components/PotentialPoints";

const Title = ({ data, title }) => {
  return (
    <DialogTitle id="item-dialog">
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          {data.title}
          {title !== "" && (
            <Chip size="small" label={title} color="secondary" />
          )}
        </Stack>
        {data.potentialPoints != null && (
          <Stack direction="row" alignItems="center" gap={1}>
            <Label>Available Potential Points: </Label>

            <PotentialPoints
              potentialPoints={data.potentialPoints}
              width={320}
              propKey="remaining"
            />
          </Stack>
        )}
      </Stack>
    </DialogTitle>
  );
};

export default Title;
