import React from "react";
import { ToggleButton, useTheme, ToggleButtonGroup } from "@mui/material";
import { connect } from "react-redux";
import { updateView } from "store/teamBuilder/teamBuilderActions";

const Toggle = ({ view, updateView }) => {
  const theme = useTheme();

  const handleUpdate = (e, newView) => {
    if (newView != null) {
      updateView(newView);
    }
  };

  const buttonStyles = {
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.card.background,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-selected": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      },
    },
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={view}
      onChange={handleUpdate}
      sx={{
        justifyContent: "center",
        background: "transparent",
        "> .MuiToggleButton-root": { fontWeight: "600" },
        "& .MuiToggleButtonGroup-grouped": {
          margin: "4px",
          border: 0,
          "&.Mui-disabled": {
            border: 0,
          },
          "&:not(:first-of-type), &:first-of-type": {
            borderRadius: "4px",
          },
        },
        [theme.breakpoints.down("md")]: {
          position: "static",
          justifyContent: "center",
        },
      }}
    >
      <ToggleButton
        size="small"
        value="detailed"
        aria-label="detailed"
        sx={buttonStyles}
      >
        Detailed View
      </ToggleButton>
      <ToggleButton
        size="small"
        value="condensed"
        aria-label="condensed"
        sx={buttonStyles}
      >
        Condensed View
      </ToggleButton>
      <ToggleButton
        size="small"
        value="deck"
        aria-label="deck"
        sx={buttonStyles}
      >
        Deck View
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const mapDispatchToProps = {
  updateView,
};

const mapStateToProps = (state) => ({
  view: state.teamBuilder.view,
});

export default connect(mapStateToProps, mapDispatchToProps)(Toggle);
