import { Button, Stack } from "@mui/material";
import { IoCaretBackCircleOutline } from "react-icons/io5";
import { RiPencilFill } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";

const AdminButtons = ({
  scholar,
  setOpenEditScholarDialog,
  setScholarToModifyIds,
  prevPath,
}) => {
  const handleSetOpenEditScholarDialog = (roninAddress, guildTeamId) => {
    setScholarToModifyIds(roninAddress, guildTeamId);
    setOpenEditScholarDialog(true);
  };

  return (
    <Stack
      gap={2}
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <RouterLink
        to={prevPath}
        state={{
          prevPath: "SCHOLAR_PAGE",
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<IoCaretBackCircleOutline />}
        >
          Back
        </Button>
      </RouterLink>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={<RiPencilFill />}
        onClick={() =>
          handleSetOpenEditScholarDialog(
            scholar.roninAddress,
            scholar.guildTeamId
          )
        }
      >
        Edit Scholar
      </Button>
    </Stack>
  );
};

export default AdminButtons;
