import React from "react";
import { Typography } from "@mui/material";

const DropdownSectionLabel = ({ children, colorOverrides }) => {
  return (
    <Typography
      fontWeight={700}
      sx={{
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        lineHeight: "20px",
      }}
      letterSpacing="0.5px"
      color={colorOverrides?.navigation?.dropdown?.sectionLabel || "text.muted"}
      fontSize={11}
    >
      {children}
    </Typography>
  );
};

export default DropdownSectionLabel;
