import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearAllFavorites } from "store/cardExplorer/cardExplorerActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoLink } from "react-icons/io5";
import { FaStore, FaCheckCircle } from "react-icons/fa";
import * as cardExplorerSelectors from "store/cardExplorer/cardExplorerSelectors";
import { Typography, useTheme, Stack, Button } from "@mui/material";

const FavoritesTitle = ({
  shareLink,
  marketplaceSearchLink,
  favoriteCards,
  clearAllFavorites,
}) => {
  const [hasClickedCopy, setHasClickedCopy] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (hasClickedCopy) {
      setTimeout(() => {
        setHasClickedCopy(false);
      }, 1000);
    }
  }, [hasClickedCopy]);

  const handleClickCopy = () => {
    setHasClickedCopy(true);
  };

  const handleClearAllFavorites = () => {
    clearAllFavorites();
  };

  return (
    <>
      <Typography
        variant="body1"
        fontSize="20px"
        color="text.bright"
        fontWeight="600"
      >
        Your favorite cards.
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={1}
        justifyContent="center"
        sx={{
          [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
          },
        }}
      >
        <Button
          variant="contained"
          endIcon={<FaStore />}
          href={marketplaceSearchLink}
          target="_blank"
        >
          Search Marketplace
        </Button>
        <CopyToClipboard text={shareLink}>
          <Button
            variant="contained"
            endIcon={hasClickedCopy ? <FaCheckCircle /> : <IoLink />}
            onClick={handleClickCopy}
          >
            {hasClickedCopy ? "Copied" : "Copy share link"}
          </Button>
        </CopyToClipboard>
        {favoriteCards.length > 0 && (
          <Button onClick={handleClearAllFavorites} variant="outlined">
            Clear All
          </Button>
        )}
      </Stack>
    </>
  );
};

const mapDispatchToProps = { clearAllFavorites };

const mapStateToProps = (state) => ({
  favoriteCards: state.cardExplorer.favoriteCards,
  shareLink: cardExplorerSelectors.getShareLink(state),
  marketplaceSearchLink: cardExplorerSelectors.getMarketplaceSearchLink(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesTitle);
