import { takeEvery, put, retry } from "redux-saga/effects";
import api from "services/api";
import * as types from "./recentlyActionTypes";
import * as actions from "./recentlyActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchRecentlyListedRequest() {
  try {
    yield put(actions.fetchRecentlyListedStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGetRecentlyListed
    );
    yield put(actions.fetchRecentlyListedSucceeded(data));
  } catch (e) {
    yield put(actions.fetchRecentlyListedFailed());
  }
}

export function* makeFetchRecentlySoldRequest() {
  try {
    yield put(actions.fetchRecentlySoldStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGetRecentlySold
    );
    yield put(actions.fetchRecentlySoldSucceeded(data));
  } catch (e) {
    yield put(actions.fetchRecentlySoldFailed());
  }
}

export default function* recentlySaga() {
  yield takeEvery(types.FETCH_RECENTLY_LISTED, makeFetchRecentlyListedRequest);
  yield takeEvery(types.FETCH_RECENTLY_SOLD, makeFetchRecentlySoldRequest);
}
