import { FaHandshake } from "react-icons/fa";
import { IoRocketSharp } from "react-icons/io5";
import { VscGraphLine } from "react-icons/vsc";

export const features = [
  {
    icon: <VscGraphLine />,
    title: "Guild Tools",
    bullets: [
      "Track unlimited player accounts",
      "Monitor key performance data",
      "View battle history & replays",
    ],
  },
  {
    icon: <IoRocketSharp />,
    title: "Hosted Guild Page",
    bullets: [
      "Claim your personalised Axie.Tech URL",
      "Create friendly competition within your guild",
      "Compete with other guilds & win prizes",
    ],
  },
  {
    icon: <FaHandshake />,
    title: "Private Discord",
    bullets: [
      "Join our private discord",
      "Network with like minded guild owners & managers",
      "Get early access to new tools & features",
    ],
  },
];
