import * as types from "./inspectorActionTypes";
import {
  put,
  takeEvery,
  retry,
  call,
  fork,
  take,
  select,
} from "redux-saga/effects";
import { calculatePurityAxieTech } from "helpers/axie";
import api from "services/api";
import * as actions from "./inspectorActions";
import { channel } from "redux-saga";
import { extractAndCleanPartIds } from "helpers/axie";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;
const NUM_TO_FETCH = 9;

export function* fetchCheapestSimilarListings(action) {
  try {
    yield put(actions.fetchCheapestSimilarListingsStarted());

    const purity = calculatePurityAxieTech(action.axie).purityCount;

    const clearnedPartIds = extractAndCleanPartIds(action.axie.parts.d);

    const cheapestSimilarVariables = {
      auctionType: "Sale",
      minBreedCount: 0,
      maxBreedCount: action.axie.breedCount,
      classes: [action.axie.class],
      parts: clearnedPartIds,
      minPureness: purity === 0 ? undefined : purity,
      maxPureness: purity === 0 ? undefined : 6,
      from: 0,
      size: NUM_TO_FETCH,
      sort: "PriceAsc",
    };

    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestSearchAxies,
      cheapestSimilarVariables
    );

    const axieData = yield select((state) => state.inspector.axieData);
    const filteredResults = { total: 0, axies: [] };

    for (const idx in data.axies) {
      if (data.axies[idx].axieId !== action.axie.axieId) {
        filteredResults.axies.push(data.axies[idx]);
        filteredResults.total += 1;
        if (!(data.axies[idx].axieId in axieData)) {
          yield put(
            actions.fetchCheapSimilarAxieDetail(data.axies[idx].axieId)
          );
        }
      }
    }

    yield put(actions.fetchCheapestSimilarListingsSucceeded(filteredResults));
  } catch (e) {
    yield put(actions.fetchCheapestSimilarListingsFailed());
  }
}

export function* watchFetchAxieData() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchAxieDataRequest, chan);
  }

  try {
    while (true) {
      const { axieId } = yield take(
        types.INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL
      );
      yield put(chan, axieId);
    }
  } catch (e) {}
}

export function* makeFetchAxieDataRequest(chan) {
  try {
    while (true) {
      const axieId = yield take(chan);
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        axieId
      );
      yield put(actions.fetchCheapSimilarAxieDetailSucceeded(data));
    }
  } catch (e) {}
}

export default function* cheapestSimilarListingsSaga() {
  yield fork(watchFetchAxieData);
  yield takeEvery(
    types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS,
    fetchCheapestSimilarListings
  );
}
