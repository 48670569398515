import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { IconButton, Tooltip } from "@mui/material";

const ClearButton = ({ axie, clearAxie }) => {
  return (
    <Tooltip title="Clear Axie">
      <IconButton
        size="small"
        className="delete"
        onClick={() => clearAxie(axie.id)}
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          opacity: 0.2,
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        <IoCloseCircle />
      </IconButton>
    </Tooltip>
  );
};

export default ClearButton;
