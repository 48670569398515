import React from "react";
import { Box, useTheme } from "@mui/material";

const Panel = ({ children, style }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        p: 2,
        borderRadius: 2,
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default Panel;
