import React from "react";
import ChildSaleInputs from "./ChildSaleInputs";
import InputsHeader from "./InputsHeader";
import { Stack } from "@mui/material";

const ChildSales = ({ numberOfBreeds, arrayOfChildFuncs, handleUpdate }) => {
  return (
    <Stack gap={2}>
      <InputsHeader
        title="Child Details"
        description="Enter the estimated sale prices of the child axies."
      />
      <ChildSaleInputs
        numberOfBreeds={numberOfBreeds}
        arrayOfChildFuncs={arrayOfChildFuncs}
        handleUpdate={handleUpdate}
      />
    </Stack>
  );
};

export default ChildSales;
