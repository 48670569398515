import { Stack, Button, Typography } from "@mui/material";
import { HiRefresh } from "react-icons/hi";

const Failed = ({ refetch }) => {
  return (
    <Stack sx={{ py: 12 }} gap={2} alignItems="center">
      <Typography
        component="span"
        fontSize={16}
        fontWeight={700}
        textAlign="center"
        sx={{
          color: "#ffffff",
          lineHeight: 1.4,
        }}
      >
        Wer Cards? <br /> Try again?
      </Typography>
      <Button onClick={refetch} endIcon={<HiRefresh />}>
        Refresh Data
      </Button>
    </Stack>
  );
};

export default Failed;
