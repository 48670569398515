import styled from "@emotion/styled";
import { Stack, useTheme } from "@mui/material";
import Row from "../atoms/Row";
import CustomLink from "components/CustomLink/CustomLink";
import useMediaQuery from "@mui/material/useMediaQuery";

const OuterWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isTop3",
})(({ theme, isTop3 }) => ({
  position: "relative",
  zIndex: 99,
  transition: "0.2s background ease",
  borderRadius: "4px",
  "&:nth-of-type(2n)": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.colors.purple[600]
        : "#27253b",
  },
  "&:hover": {
    background:
      theme.palette.mode === "light"
        ? theme.palette.colors.purple[500]
        : "#3b3854",
  },
  ...(isTop3 && {
    background: "linear-gradient(45deg, #6314ae, #e86b5a) !important",
    margin: "2px 0",
    padding: "3px",
  }),
}));

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["isTop3", "grid"].includes(prop),
})(({ isTop3, grid, theme }) => ({
  display: "grid",
  alignItems: "center",
  padding: "14px 8px",
  gridGap: "8px",
  gridTemplateColumns: grid,
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "32px 32px 3fr 1fr 2fr",
  },

  img: {
    borderRadius: "50%",
    height: 32,
    width: 32,
    margin: "0 auto",
    display: "block",
  },

  ...(isTop3 && {
    background: theme.palette.card.background,
    borderRadius: "2px",
  }),
}));

const Rows = ({ data, specs, icon, icon2, secondPlace, topPrize }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const rowLink = (itemSlug) =>
    specs.baseURL ? `${specs.baseURL}/${itemSlug}` : undefined;

  return data.map((row, i) => {
    const isTop3 = row.id ? row.id < 4 : i < 3;
    const isWinner = row.id === 1 || i === 0;
    const isSecond = row.id === 2 || i === 1;
    return (
      <OuterWrapper isTop3={isTop3} key={`ROW_${i}`}>
        <CustomLink to={rowLink(row[specs.itemSlug])}>
          <Wrapper grid={specs.grid} isTop3={isTop3}>
            <Row
              row={row}
              details={specs.columns}
              rank={row.id ? row.id : i + 1}
            />
            {smUp ? (
              <Stack
                direction="column"
                gap={1}
                alignItems="center"
                justifyContent="center"
              >
                {icon ? icon : null}
                {isWinner ? topPrize : null}
                {isSecond ? secondPlace : null}
                {!isWinner && !isSecond ? icon2 : null}
              </Stack>
            ) : null}
          </Wrapper>
        </CustomLink>
      </OuterWrapper>
    );
  });
};

export default Rows;
