import React from "react";
import { Chip, Grid, useTheme, Stack } from "@mui/material";
import CardTemplateV2 from "components/GameTemplates/V2/CardTemplateV2";
import CardTemplate from "components/GameTemplates/CardTemplate";
import Label from "components/Typography/Label";
import { getColorForAxieClass } from "helpers/axie";
import EmptyCard from "./EmptyCard";

const ByPart = ({ cardObj }) => {
  return (
    <Grid container spacing={2}>
      <CardPair cardObj={cardObj} part="eyes" />
      <CardPair cardObj={cardObj} part="ears" />
      <CardPair cardObj={cardObj} part="mouth" />
      <CardPair cardObj={cardObj} part="horn" />
      <CardPair cardObj={cardObj} part="back" />
      <CardPair cardObj={cardObj} part="tail" />
    </Grid>
  );
};

const CardPair = ({ part, cardObj }) => {
  const theme = useTheme();
  const cardV2 = cardObj.cardsV2[part];
  const cardV3 = cardObj.cardsV3[part];

  const cardStyles = {
    transition: "0.2s transform ease",
    "&:hover": {
      transform: "scale(1.08)",
    },
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Stack gap={1}>
        <PartLabel
          bodyPart={part}
          partName={cardV3?.partName}
          cardClass={cardV3?.class}
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 4, sm: 2, md: 2, lg: 4 }}
          sx={{
            background: theme.palette.inspector.shaded,
            px: 1,
            pt: 4,
            pb: 2,
            borderRadius: "24px",
            height: "100%",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          {cardV2 == null ? (
            <EmptyCard />
          ) : (
            <CardTemplateV2
              card={cardV2}
              style={{
                ...cardStyles,
                ml: -2,
                minWidth: 220,
                width: "220px !important",
              }}
            />
          )}
          {cardV3 == null ? (
            <EmptyCard />
          ) : (
            <CardTemplate
              card={cardV3}
              width={220}
              style={{ ...cardStyles, mt: -2 }}
            />
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};

const PartLabel = ({ partName, cardClass, bodyPart }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={1}
    sx={{
      mr: -1,
      "& img": {
        width: 30,
      },
    }}
  >
    <img
      src={`/images/templates/card/body-parts/${bodyPart}.png`}
      alt={`Axie ${bodyPart} card`}
    />
    <Label>{bodyPart.toUpperCase()}</Label>
    {partName != null && (
      <Chip
        label={partName}
        sx={{
          backgroundColor: getColorForAxieClass(cardClass),
          color: "#fff",
          fontSize: 12,
          "& .MuiChip-label": {
            textShadow: "0px 0px 3px rgba(0,0,0,0.2)",
          },
        }}
        size="small"
      />
    )}
  </Stack>
);

export default ByPart;
