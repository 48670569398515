import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Axie from "../atoms/Axie";
import Sidekick from "../atoms/Sidekick";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gridArea: "team",
  position: "relative",
}));

const Team = ({ axieId, sidekickImage }) => {
  return (
    <Wrapper className="team">
      <Axie id={axieId} />
      <Sidekick img={sidekickImage} />
    </Wrapper>
  );
};

export default Team;
