import { Avatar, useTheme, Tooltip } from "@mui/material";
import {
  getIconForAxieClass,
  getColorForAxieClass,
  axieIsStarter,
} from "helpers/axie";
import { STARTER_AXIE_DATA } from "config";
import { Axie } from "types";
import { ReactElement } from "react";

interface Props {
  axie: Axie;
  width: number;
  color?: string;
  cls?: string;
}

const ClassIcon = ({ axie, width, color, cls }: Props) => {
  const theme = useTheme();
  const iconWidth = (20 / 320) * width;
  let axieColor = color;
  let starterAxie;

  if (axieIsStarter(axie)) {
    starterAxie = STARTER_AXIE_DATA[axie.axieId];
    if (starterAxie) {
      axieColor = getColorForAxieClass(starterAxie.class);
    }
  } else {
    if (axieColor == null) {
      axieColor = getColorForAxieClass(cls);
    }
  }

  return (
    <TooltipWrapper title={axie?.class || cls}>
      <Avatar
        sx={{
          backgroundColor: axieColor,
          width: iconWidth,
          height: iconWidth,
          p: `${(4 / 320) * width}px`,
        }}
      >
        {getIconForAxieClass(
          cls || axie?.class || starterAxie?.class,
          theme.palette.card.background
        )}
      </Avatar>
    </TooltipWrapper>
  );
};

const TooltipWrapper = ({
  children,
  title,
}: {
  children: ReactElement;
  title?: string;
}) =>
  title == null ? <>{children}</> : <Tooltip title={title}>{children}</Tooltip>;

export default ClassIcon;
