import React from "react";
import { Typography, Stack } from "@mui/material";
import { getOrdinalSuffix } from "helpers/display";
import styled from "@emotion/styled";

const Wrapper = styled(Stack, { shouldForwardProp: (prop) => prop !== "rank" })(
  ({ rank }) => ({
    position: "relative",
    fontWeight: 800,
    gridArea: "rank",
    width: "100%",
    height: 40,
    fontSize: rank >= 100 ? 32 : 44,
    ".rankText": {
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
    },
  })
);

const ColorText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "rank",
})(({ rank }) => ({
  zIndex: 2,
  color: "#fff",
  background:
    "linear-gradient(180deg, #F4FFFE 12.67%, #AFFAF5 42.18%, #32B4AB 53.99%, #5ECEC7 64.06%, #8EE6E0 79.33%)",
  ...(rank === 1 && {
    background:
      "linear-gradient(180deg, #F2D34B 12.67%, #FFFFB0 42.18%, #E2AD4A 54.1%, #EFC333 70.06%, #FEED45 79.33%)",
  }),
  ...(rank === 2 && {
    background:
      "linear-gradient(180deg, #B7B7B7 12.67%, #E4E4E4 42.18%, #959595 51.9%, #949494 68.92%, #C6C6C6 79.33%)",
  }),
  ...(rank === 3 && {
    background:
      "linear-gradient(180deg, #E9CBB9 12.67%, #EBC1A0 42.18%, #A06F50 53.99%, #BC7945 64.06%, #CD9A76 79.33%)",
  }),
  webkitBackgroundClip: "text",
  webkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

const OutlineText = styled(Typography)(({ theme }) => ({
  zIndex: 1,
  WebkitTextStroke: "5px black",
}));

const Suffix = ({ children }) => (
  <Typography variant="span" fontSize={24}>
    {children}
  </Typography>
);

const Rank = ({ rank }) => {
  const suffix = getOrdinalSuffix(rank);
  return (
    <Wrapper rank={rank} className="rank">
      <ColorText variant="span" rank={rank} className="rankText">
        {rank}
        <Suffix>{suffix}</Suffix>
      </ColorText>
      <OutlineText variant="span" className="rankText">
        {rank}
        <Suffix>{suffix}</Suffix>
      </OutlineText>
    </Wrapper>
  );
};

export default Rank;
