import React from "react";
import { Stack, useTheme, Box } from "@mui/material";
import { getColorFor } from "helpers/axie";
import InspectAxieIcon from "./InspectAxieIcon";

const Card = ({
  axie,
  selected,
  width,
  disableElevation,
  children,
  hideInspectIcon,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const color = getColorFor(axie, theme.palette.mode);
  const defaultShadow = disableElevation ? "" : "0 4px 4px rgba(0,0,0,0.1)";
  const boxShadow = selected
    ? `${defaultShadow}, inset 0 2px ${color}, inset 0 -2px ${color}, inset 2px 0 ${color}, inset -2px 0 ${color}`
    : `${defaultShadow}`;

  return (
    <Stack
      onClick={onClick}
      gap={(1 / 320) * width}
      sx={{
        position: "relative",
        transition: "0.1s border ease",
        background: theme.palette.axieCard.background,
        border: `1px solid ${theme.palette.axieCard.border}`,
        borderRadius: "16px",
        boxShadow,
        p: width ? `${(12 / 320) * width}px` : 0,
        width,
        "&:hover": {
          border: `1px solid ${theme.palette.axieCard.borderHover}`,
        },
        ...style,
      }}
    >
      {!hideInspectIcon && axie?.axieId != null && (
        <Box
          sx={{
            position: "absolute",
            right: (6 / 230) * width,
            top: (6 / 230) * width,
            zIndex: 5,
          }}
        >
          <InspectAxieIcon axie={axie} width={width} />
        </Box>
      )}
      {children}
    </Stack>
  );
};

export default Card;
