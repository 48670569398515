import React from "react";
import { connect } from "react-redux";
import { getFavoriteCharmsToDisplay } from "store/explorer/selectors";
import { favoriteCharm } from "store/explorer/explorerActions";
import Section from "../../molecules/FavoritesSection";
import WrappedCharm from "pages/Explorer/molecules/WrappedCharm";

const Charms = ({ favoriteCharmsToDisplay, favoriteCharm }) => {
  return (
    <Section type="Charm" numItems={favoriteCharmsToDisplay.length}>
      {favoriteCharmsToDisplay.map((charm) => (
        <WrappedCharm
          key={charm.id}
          charm={charm}
          favoriteCharm={favoriteCharm}
        />
      ))}
    </Section>
  );
};

const mapDispatchToProps = { favoriteCharm };

const mapStateToProps = (state) => ({
  favoriteCharmsToDisplay: getFavoriteCharmsToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Charms);
