import * as types from "./teamBuilderActionTypes";
import produce from "immer";
import { capitalizeWord } from "helpers/display";
import { TEAM_BUILDER_AXIE_SLOTS } from "pages/TeamBuilder";

export const initialCardFilters = {
  searchText: "",
  costs: [],
  tags: [],
  axieClasses: [],
};
export const initialRuneFilters = {
  searchText: "",
  rarities: [],
  axieClasses: [],
};
export const initialCharmFilters = {
  searchText: "",
  potentialCosts: [],
  rarities: [],
  axieClasses: [],
};

export const initialAxies = () => {
  const axies = {};
  const startingClasses = ["plant", "aquatic", "bird"];

  TEAM_BUILDER_AXIE_SLOTS.forEach((slot, i) => {
    axies[slot] = createInitialAxie(slot, startingClasses[i]);
  });
  return axies;
};

export const createInitialAxie = (id, axieClass = "plant") => ({
  id,
  name: `${capitalizeWord(id)} Axie`,
  class: axieClass,
  role: "",
  parts: {
    eyes: {},
    ears: {},
    back: {},
    mouth: {},
    horn: {},
    tail: {},
  },
  rune: undefined,
});

export const initialState = {
  ...initialAxies(),
  view: "detailed",
  displayBanishCards: true,
  dialog: {
    open: false,
    itemType: undefined,
    part: undefined,
    requiredClass: undefined,
    filters: {
      cards: initialCardFilters,
      charms: initialCharmFilters,
      runes: initialRuneFilters,
    },
  },
};

export const teamBuilderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // ---------------------- UPDATE AXIE
      case types.TEAM_BUILDER_UPDATE_CARD: {
        draft[action.axieId].parts[action.part].card = action.card;
        break;
      }
      case types.TEAM_BUILDER_UPDATE_CHARM: {
        draft[action.axieId].parts[action.part].charm = action.charm;
        break;
      }
      case types.TEAM_BUILDER_UPDATE_RUNE: {
        draft[action.axieId].rune = action.rune;
        break;
      }
      case types.TEAM_BUILDER_UPDATE_CLASS:
        draft[action.axieId].class = action.axieClass;
        break;

      // ---------------------- UPDATE FILTERS
      case types.TEAM_BUILDER_UPDATE_CARD_FILTERS:
        draft.dialog.filters.cards = action.filters;
        break;
      case types.TEAM_BUILDER_UPDATE_CHARM_FILTERS:
        draft.dialog.filters.charms = action.filters;
        break;
      case types.TEAM_BUILDER_UPDATE_RUNE_FILTERS:
        draft.dialog.filters.runes = action.filters;
        break;

      // ---------------------- DIALOG
      case types.TEAM_BUILDER_SET_DIALOG_OPEN:
        draft.dialog.open = true;
        draft.dialog.axieId = action.axieId;
        draft.dialog.itemType = action.itemType;
        draft.dialog.part = action.part;
        draft.dialog.requiredClass = action.requiredClass
          ? action.requiredClass.toLowerCase()
          : undefined;
        break;
      case types.TEAM_BUILDER_SET_DIALOG_CLOSED:
        draft.dialog.open = false;
        draft.dialog.axieId = undefined;
        draft.dialog.itemType = undefined;
        draft.dialog.part = undefined;
        draft.dialog.requiredClass = undefined;
        draft.dialog.filters = {
          cards: initialCardFilters,
          charms: initialCharmFilters,
          runes: initialRuneFilters,
        };
        break;

      // ---------------------- VIEW
      case types.TEAM_BUILDER_UPDATE_VIEW:
        draft.view = action.view;
        break;

      // ---------------------- DISPLAY BANISH CARDS
      case types.TEAM_BUILDER_DISPLAY_BANISH_CARDS:
        draft.displayBanishCards = action.display;
        break;

      // ---------------------- CLEAR
      case types.TEAM_BUILDER_CLEAR_AXIE:
        draft[action.id] = createInitialAxie(action.id, draft[action.id].class);
        break;
      case types.TEAM_BUILDER_CLEAR_ALL_AXIES:
        return {
          ...draft,
          ...initialAxies(),
        };

      default:
        break;
    }
  });

export default teamBuilderReducer;
