import React from "react";
import { Typography } from "@mui/material";

const PageTitle = ({
  children,
  textAlign = "left",
  style,
  color,
  gutterBottom = true,
}) => {
  return (
    <Typography
      variant="h1"
      gutterBottom={gutterBottom}
      color={color || "text.bright"}
      textAlign={textAlign}
      sx={style}
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
