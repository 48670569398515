import React from "react";
import { Typography, useTheme } from "@mui/material";

const Footnote = ({ color, fontSize, text }) => {
  const theme = useTheme();

  return (
    <Typography
      fontWeight="700"
      color={color ? color : theme.palette.text.muted}
      fontSize={fontSize ? fontSize : "13px"}
    >
      {text ? text : null}
    </Typography>
  );
};
export default Footnote;
