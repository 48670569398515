import {
  BREEDING_EVENT_SHINY_PERCENTAGE,
  BREEDING_EVENT_SHINY_PERCENTAGE_SPECIAL,
  PROBABILITIES,
  BREEDING_EVENT_PART_MAPPING,
  BREEDING_EVENT_SPECIAL_GENES,
} from "config";
import { calculatePurityPercents, calculatePurityScore } from "./axie";
import { BODY_PARTS_DICT } from "./axieFeatures";
import { isBreedingEventActive } from "./time";

export const breedAxies = (a, b) => {
  const breedingEventIsActive = isBreedingEventActive();
  const parents = [{ ...a }, { ...b }];

  if (breedingEventIsActive) {
    return breedForEvent(parents);
  } else {
    return breedNormally(parents);
  }
};

export const breedNormally = (parents) => {
  const tiers = ["d", "r1", "r2"];
  const probabilities = {
    eyes: {},
    ears: {},
    back: {},
    mouth: {},
    horn: {},
    tail: {},
  };

  Object.keys(probabilities).forEach((bodyPart) => {
    tiers.forEach((tier) => {
      parents.forEach((parent) => {
        let thisParentPart = parent.parts[tier][bodyPart];

        if (isASummerPart(thisParentPart)) {
          thisParentPart = getRequirementPartFor(thisParentPart);
        }

        if (thisParentPart.partId in probabilities[bodyPart]) {
          probabilities[bodyPart][thisParentPart.partId].percent +=
            PROBABILITIES[tier];
        } else {
          probabilities[bodyPart][thisParentPart.partId] = {
            trait: thisParentPart.name,
            percent: PROBABILITIES[tier],
            axieClass: thisParentPart.class,
            specialGenes: thisParentPart.specialGenes,
          };
        }
      });
    });
  });

  const partsProbabilities = flattenAndSort(probabilities);
  const purityPercents = calculatePurityPercents(partsProbabilities);

  return {
    parts: partsProbabilities,
    purityPercents: purityPercents,
    purityScore: calculatePurityScore(purityPercents),
    numberOfSpecialParts: 0,
  };
};

export const breedForEvent = (parents) => {
  const double6OutOf6 = bothParents6OutOf6(parents);
  const shinyRate = double6OutOf6
    ? BREEDING_EVENT_SHINY_PERCENTAGE_SPECIAL
    : BREEDING_EVENT_SHINY_PERCENTAGE;

  const probabilities = {
    eyes: {},
    ears: {},
    back: {},
    mouth: {},
    horn: {},
    tail: {},
  };

  Object.keys(probabilities).forEach((bodyPart) => {
    const parent1DPart = parents[0].parts.d[bodyPart];
    const parent2DPart = parents[1].parts.d[bodyPart];

    // Sum summer probabilities as if they were the base part
    probabilities[bodyPart] = resolveToBaseAndSum(parents, bodyPart);

    const breed = getTypeOfBreed(parent1DPart, parent2DPart);

    if (breed.type === "SUMMER+SUMMER") {
      const part = breed.summerParts[0];
      const pTotal = probabilities[bodyPart][part.requires].percent;
      const pInheritance = part.inheritance;

      probabilities[bodyPart][part.partId] = {
        trait: part.name,
        axieClass: part.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pTotal * pInheritance * (1 - shinyRate),
      };

      probabilities[bodyPart][`${part.partId}-shiny`] = {
        trait: `Shiny ${part.name}`,
        axieClass: part.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pTotal * pInheritance * shinyRate,
      };

      if (pInheritance === 1) {
        delete probabilities[bodyPart][part.requires];
      } else {
        probabilities[bodyPart][part.requires].percent =
          pTotal * (1 - pInheritance);
      }
    }

    if (breed.type === "SUMMER1+SUMMER2") {
      const summerPart1 = breed.summerParts[0];
      const summerPart2 = breed.summerParts[1];
      const p1Total = probabilities[bodyPart][summerPart1.requires].percent;
      const p2Total = probabilities[bodyPart][summerPart2.requires].percent;
      const p1Inheritance = summerPart1.inheritance;
      const p2Inheritance = summerPart2.inheritance;

      // Red Ear & Furball
      if (summerPart1.requires !== summerPart2.requires) {
        probabilities[bodyPart][summerPart1.partId] = {
          trait: summerPart1.name,
          axieClass: summerPart1.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p1Total * p1Inheritance * (1 - shinyRate),
        };

        probabilities[bodyPart][`${summerPart1.partId}-shiny`] = {
          trait: `Shiny ${summerPart1.name}`,
          axieClass: summerPart1.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p1Total * p1Inheritance * shinyRate,
        };

        probabilities[bodyPart][summerPart2.partId] = {
          trait: summerPart2.name,
          axieClass: summerPart2.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p2Total * p2Inheritance * (1 - shinyRate),
        };

        probabilities[bodyPart][`${summerPart2.partId}-shiny`] = {
          trait: `Shiny ${summerPart2.name}`,
          axieClass: summerPart2.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p2Total * p2Inheritance * shinyRate,
        };

        // Remove base part
        if (p1Inheritance === 1) {
          delete probabilities[bodyPart][summerPart1.requires];
        }
        // Update base part percent
        else {
          probabilities[bodyPart][summerPart1.requires].percent =
            p1Total * (1 - p1Inheritance);
        }

        // Remove base part
        if (p2Inheritance === 1) {
          delete probabilities[bodyPart][summerPart2.requires];
        }
        // Update base part percent
        else {
          probabilities[bodyPart][summerPart2.requires].percent =
            p2Total * (1 - p2Inheritance);
        }
      }

      // Unko
      else {
        let p1;
        let p2;

        if (p1Inheritance === 1) {
          p1 = p1Total / 2;
        } else {
          p1 = (p1Total / 2) * p1Inheritance;
        }

        if (p2Inheritance === 1) {
          p2 = p2Total / 2;
        } else {
          p2 = (p2Total / 2) * p2Inheritance;
        }

        if (p1Inheritance === 1 && p2Inheritance === 1) {
          delete probabilities[bodyPart][summerPart1.requires];
        } else {
          probabilities[bodyPart][summerPart1.requires].percent =
            p1Total - p1 - p2;
        }

        probabilities[bodyPart][summerPart1.partId] = {
          trait: summerPart1.name,
          axieClass: summerPart1.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p1 * (1 - shinyRate),
        };

        probabilities[bodyPart][`${summerPart1.partId}-shiny`] = {
          trait: `Shiny ${summerPart1.name}`,
          axieClass: summerPart1.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p1 * shinyRate,
        };

        probabilities[bodyPart][summerPart2.partId] = {
          trait: summerPart2.name,
          axieClass: summerPart2.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p2 * (1 - shinyRate),
        };

        probabilities[bodyPart][`${summerPart2.partId}-shiny`] = {
          trait: `Shiny ${summerPart2.name}`,
          axieClass: summerPart2.class,
          specialGenes: BREEDING_EVENT_SPECIAL_GENES,
          percent: p2 * shinyRate,
        };
      }
    }

    if (breed.type === "SUMMER+BASE") {
      const summerPart = breed.summerPart;
      const pTotal = probabilities[bodyPart][summerPart.requires].percent;

      probabilities[bodyPart][summerPart.partId] = {
        trait: summerPart.name,
        axieClass: summerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: (pTotal / 2) * (1 - shinyRate),
      };

      probabilities[bodyPart][`${summerPart.partId}-shiny`] = {
        trait: `Shiny ${summerPart.name}`,
        axieClass: summerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: (pTotal / 2) * shinyRate,
      };

      probabilities[bodyPart][summerPart.requires].percent = pTotal / 2;
    }

    if (breed.type === "SUMMER+MATCH") {
      const summerPart = breed.summerPart;
      const newSummerPart = breed.newSummerPart;
      const pTotal = probabilities[bodyPart][summerPart.requires].percent;
      const pInheritance = newSummerPart.inheritance;
      let pNewSummerPart;

      // Inheritence of summer part is guaranteed
      if (pInheritance === 1) {
        pNewSummerPart = pTotal;
        delete probabilities[bodyPart][summerPart.requires];
      }
      // Inheritence not guaranteed, remaining % goes to base part
      else {
        pNewSummerPart = pTotal * pInheritance;
        probabilities[bodyPart][summerPart.requires].percent =
          pTotal * (1 - pInheritance);
      }

      probabilities[bodyPart][newSummerPart.partId] = {
        trait: newSummerPart.name,
        axieClass: newSummerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pNewSummerPart * (1 - shinyRate),
      };

      probabilities[bodyPart][`${newSummerPart.partId}-shiny`] = {
        trait: `Shiny ${newSummerPart.name}`,
        axieClass: newSummerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pNewSummerPart * shinyRate,
      };
    }

    if (breed.type === "SUMMER+OTHER") {
      const summerPart = breed.summerPart;
      const pTotal = probabilities[bodyPart][summerPart.requires].percent;

      probabilities[bodyPart][summerPart.partId] = {
        trait: summerPart.name,
        axieClass: summerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pTotal * (1 - shinyRate),
      };

      probabilities[bodyPart][`${summerPart.partId}-shiny`] = {
        trait: `Shiny ${summerPart.name}`,
        axieClass: summerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pTotal * shinyRate,
      };

      delete probabilities[bodyPart][summerPart.requires];
    }

    if (breed.type === "BASE+MATCH") {
      const summerPart = breed.summerPart;
      const pTotal = probabilities[bodyPart][summerPart.requires].percent;
      const pInheritance = summerPart.inheritance;

      probabilities[bodyPart][summerPart.partId] = {
        trait: summerPart.name,
        axieClass: summerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pTotal * pInheritance * (1 - shinyRate),
      };

      probabilities[bodyPart][`${summerPart.partId}-shiny`] = {
        trait: `Shiny ${summerPart.name}`,
        axieClass: summerPart.class,
        specialGenes: BREEDING_EVENT_SPECIAL_GENES,
        percent: pTotal * pInheritance * shinyRate,
      };

      if (pInheritance === 1) {
        delete probabilities[bodyPart][summerPart.requires];
      } else {
        probabilities[bodyPart][summerPart.requires].percent =
          pTotal * (1 - pInheritance);
      }
    }
  });

  const partsProbabilities = flattenAndSort(probabilities);
  const purityPercents = calculatePurityPercents(partsProbabilities);
  const numberOfSpecialParts = countSpecialParts(partsProbabilities);

  return {
    parts: partsProbabilities,
    purityPercents: purityPercents,
    purityScore: calculatePurityScore(purityPercents),
    numberOfSpecialParts,
  };
};

export const getTypeOfBreed = (part1Raw, part2Raw) => {
  const part1 = removeShiny(part1Raw);
  const part2 = removeShiny(part2Raw);

  const part1IsSummer = isASummerPart(part1);
  const part2IsSummer = isASummerPart(part2);

  // At least 1 of the parts is a summer part
  if (part1IsSummer || part2IsSummer) {
    if (part1IsSummer && part2IsSummer) {
      if (part1.partId === part2.partId) {
        return {
          summerParts: [augmentSummerPart(part1), augmentSummerPart(part2)],
          type: "SUMMER+SUMMER",
        };
      } else {
        return {
          summerParts: [augmentSummerPart(part1), augmentSummerPart(part2)],
          type: "SUMMER1+SUMMER2",
        };
      }
    } else if (
      part1IsSummer &&
      isABaseForSummerPart(augmentSummerPart(part1), part2)
    ) {
      return {
        summerPart: augmentSummerPart(part1),
        basePart: part2,
        type: "SUMMER+BASE",
      };
    } else if (
      part2IsSummer &&
      isABaseForSummerPart(augmentSummerPart(part2), part1)
    ) {
      return {
        summerPart: augmentSummerPart(part2),
        basePart: part1,
        type: "SUMMER+BASE",
      };
    } else if (part1IsSummer && isAMatchForSummerPart(part1, part2)) {
      const required = getRequirementPartFor(part1);
      return {
        summerPart: augmentSummerPart(part1),
        matchPart: part2,
        newSummerPart: getProducedSummerPart(required, part2),
        type: "SUMMER+MATCH",
      };
    } else if (part2IsSummer && isAMatchForSummerPart(part2, part1)) {
      const required = getRequirementPartFor(part2);
      return {
        summerPart: augmentSummerPart(part2),
        matchPart: part1,
        newSummerPart: getProducedSummerPart(required, part1),
        type: "SUMMER+MATCH",
      };
    } else if (part1IsSummer) {
      return {
        summerPart: augmentSummerPart(part1),
        otherPart: part2,
        type: "SUMMER+OTHER",
      };
    } else if (part2IsSummer) {
      return {
        summerPart: augmentSummerPart(part2),
        otherPart: part1,
        type: "SUMMER+OTHER",
      };
    }
  }

  // Regular parts
  if (producesASummerPart(part1, part2)) {
    return {
      summerPart: getProducedSummerPart(part1, part2),
      type: "BASE+MATCH",
    };
  } else if (producesASummerPart(part2, part1)) {
    return {
      summerPart: getProducedSummerPart(part2, part1),
      type: "BASE+MATCH",
    };
  }

  return {
    otherParts: [part1, part2],
    type: "OTHER",
  };
};

export const removeShiny = (part) => {
  if (!part) {
    return undefined;
  }
  return {
    ...part,
    name: part.name.replace(" Shiny", ""),
    partId: part.partId.replace("-shiny", ""),
  };
};

export const augmentSummerPart = (summerPart) => {
  const key = Object.keys(BREEDING_EVENT_PART_MAPPING).find(
    (k) => BREEDING_EVENT_PART_MAPPING[k].name === summerPart.name
  );
  return {
    ...summerPart,
    inheritance: BREEDING_EVENT_PART_MAPPING[key].inheritance,
    requires: BREEDING_EVENT_PART_MAPPING[key].requires,
  };
};

export const resolveToBaseAndSum = (parents, bodyPart) => {
  const tiers = ["d", "r1", "r2"];
  const probabilities = {};

  tiers.forEach((tier) => {
    parents.forEach((parent) => {
      if (tier in parent.parts && bodyPart in parent.parts[tier]) {
        let thisParentPart = parent.parts[tier][bodyPart];
        // If it's a summer part, resolve to the base part instead
        if (isASummerPart(thisParentPart)) {
          const basePart = getRequirementPartFor(thisParentPart);

          if (basePart.partId in probabilities) {
            probabilities[basePart.partId].percent += PROBABILITIES[tier];
          } else {
            probabilities[basePart.partId] = {
              trait: basePart.name,
              percent: PROBABILITIES[tier],
              axieClass: basePart.class,
              specialGenes: basePart.specialGenes,
            };
          }
        }
        // Any other parts, count them as normal
        else {
          if (thisParentPart.partId in probabilities) {
            probabilities[thisParentPart.partId].percent += PROBABILITIES[tier];
          } else {
            probabilities[thisParentPart.partId] = {
              trait: thisParentPart.name,
              percent: PROBABILITIES[tier],
              axieClass: thisParentPart.class,
              specialGenes: thisParentPart.specialGenes,
            };
          }
        }
      }
    });
  });

  return probabilities;
};

export const isASummerPart = (part) =>
  part?.specialGenes === BREEDING_EVENT_SPECIAL_GENES;

export const getRequirementPartFor = (summerPart) => {
  const summerPartKey = Object.keys(BREEDING_EVENT_PART_MAPPING).find(
    (k) =>
      BREEDING_EVENT_PART_MAPPING[k].name ===
      summerPart.name.replace(" Shiny", "")
  );
  const partId = BREEDING_EVENT_PART_MAPPING[summerPartKey]?.requires;

  return partId ? BODY_PARTS_DICT[partId] : undefined;
};

export const isABaseForSummerPart = (summerPart, part) => {
  const summerPartKey = Object.keys(BREEDING_EVENT_PART_MAPPING).find(
    (k) =>
      BREEDING_EVENT_PART_MAPPING[k].name ===
      summerPart.name.replace(" Shiny", "")
  );

  return (
    summerPartKey != null &&
    BREEDING_EVENT_PART_MAPPING[summerPartKey].requires === part.partId
  );
};

export const isAMatchForSummerPart = (summerPart, part) => {
  const summerPartBase = getRequirementPartFor(summerPart);

  return `${part.name}+${summerPartBase.name}` in BREEDING_EVENT_PART_MAPPING;
};

export const producesASummerPart = (part1, part2) => {
  if (!part1 || !part2) {
    return false;
  }
  const pairString = [part2.name, part1.name].join("+");

  if (Object.keys(BREEDING_EVENT_PART_MAPPING).includes(pairString)) {
    return true;
  }

  return false;
};

export const getProducedSummerPart = (part1, part2) => {
  const pairString = [part2.name, part1.name].join("+");

  return BREEDING_EVENT_PART_MAPPING[pairString];
};

export const bothParents6OutOf6 = (parents) => {
  let numSpecialParts = 0;

  parents.forEach((parent) => {
    Object.keys(parent.parts.d).forEach((k) => {
      if (parent.parts.d[k].specialGenes === BREEDING_EVENT_SPECIAL_GENES) {
        numSpecialParts += 1;
      }
    });
  });

  return numSpecialParts === 12;
};

const countSpecialParts = (partsObj) => {
  let numSpecialParts = 0;
  Object.keys(partsObj).forEach((k) => {
    partsObj[k].forEach((p) => {
      if (p.specialGenes === BREEDING_EVENT_SPECIAL_GENES) {
        numSpecialParts += 1;
      }
    });
  });

  return numSpecialParts;
};

const flattenAndSort = (obj) => {
  const result = { ...obj };
  Object.keys(result).forEach((bodyPart) => {
    result[bodyPart] = Object.keys(result[bodyPart])
      .map((trait) => ({
        trait: result[bodyPart][trait].trait,
        percent: result[bodyPart][trait].percent,
        axieClass: result[bodyPart][trait].axieClass,
        ...(result[bodyPart][trait].specialGenes != null && {
          specialGenes: result[bodyPart][trait].specialGenes,
        }),
        ...(result[bodyPart][trait].shiny != null && {
          shiny: result[bodyPart][trait].shiny,
        }),
      }))
      .sort((a, b) =>
        a.percent > b.percent ? -1 : a.percent < b.percent ? 1 : 0
      );
  });
  return result;
};
