import React from "react";
import { DialogActions, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const DialogGuildActions = ({
  handleEdit,
  handleClose,
  status,
  isEditingAvatar,
}) => {
  return (
    <DialogActions sx={{ justifyContent: "space-between" }}>
      <Button onClick={handleClose} variant="outlined">
        Cancel
      </Button>

      {status.updatingGuild || status.addingGuild ? (
        <LoadingButton
          variant="contained"
          loading
          sx={{ alignSelf: "stretch" }}
        />
      ) : (
        <Button
          onClick={handleEdit}
          variant="contained"
          disabled={isEditingAvatar}
        >
          Save
        </Button>
      )}
    </DialogActions>
  );
};

export default DialogGuildActions;
