import React from "react";
import styled from "@emotion/styled";
import Card from "components/Card/Card";
import { Button, useTheme, Stack } from "@mui/material";
import Player from "./PlayerCondensed";
import GameTimestamp from "../atoms/GameTimestamp";
import ReplayButton from "../atoms/ReplayButton";
import Outcome from "../atoms/Outcome";
import { FaExpandAlt } from "react-icons/fa";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(4),
  position: "relative",

  ".expandButton": {
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  [theme.breakpoints.down("xl")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    ".expandButton": {
      position: "static",
      margin: "0 auto",
    },
  },
}));

const PlayerWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  flexGrow: 1,
  [theme.breakpoints.down("xl")]: {
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const BattleCondensed = ({
  battle,
  playerName,
  detailAxieView,
  fetchAxieData,
  handleClick,
  opponentName,
}) => {
  const theme = useTheme();
  const cardStyles = {
    p: 1,
    "&:hover": {
      "& .expandButton": {
        opacity: 1,
      },
    },
  };

  return (
    <Card style={cardStyles}>
      <Wrapper>
        <PlayerWrapper>
          <Button
            className="expandButton"
            startIcon={<FaExpandAlt />}
            color="secondary"
            size="small"
            sx={{
              opacity: 0,
              transition: "0.2s opacity ease",
              [theme.breakpoints.down("md")]: {
                opacity: 1,
              },
            }}
            onClick={handleClick}
          >
            Expand
          </Button>

          <Player
            axies={battle.playerTeam}
            mmrBefore={battle.playerMmrBefore}
            mmrAfter={battle.playerMmrAfter}
            slpWon={battle.playerSlpWon}
            name={playerName}
            detailAxieView={detailAxieView}
            fetchAxieData={fetchAxieData}
          />

          <Stack
            gap={2}
            alignItems="center"
            sx={{
              alignSelf: "center",
            }}
          >
            <Outcome
              result={battle.result}
              iconOnly={true}
              style={{ alignSelf: "center" }}
            />
            <GameTimestamp end={battle.gameTimestamp || battle.gameEnded} />
          </Stack>

          <Player
            axies={battle.opponentTeam}
            mmrBefore={battle.opponentMmrBefore}
            mmrAfter={battle.opponentMmrAfter}
            slpWon={battle.opponentSlpWon}
            detailAxieView={detailAxieView}
            fetchAxieData={fetchAxieData}
            name={opponentName}
          />
        </PlayerWrapper>

        <Stack alignSelf="center">
          <ReplayButton replayUrl={battle.replayUrl} />
        </Stack>
      </Wrapper>
    </Card>
  );
};

export default BattleCondensed;
