import React from "react";
import PotentialPoints from "components/Axie/AxieV3/components/PotentialPoints";
import { MdInfo } from "react-icons/md";
import { Stack, Tooltip } from "@mui/material";
import SidebarSection from "./SidebarSection";
import styled from "@emotion/styled";

const PointsWrapper = styled(Stack)(({ theme }) => ({
  "& .potentialPointText": {
    color: theme.palette.tooltip.color,
  },
}));

const SidebarPotentialPoints = ({ potentialPoints, display }) => {
  return (
    <SidebarSection
      title="Potential Points"
      display={display}
      icon={
        <Tooltip
          title={
            <PointsWrapper>
              <span>Total Potential Points</span>
              <PotentialPoints
                potentialPoints={potentialPoints}
                propKey="amount"
                width={380}
              />
            </PointsWrapper>
          }
        >
          <Stack>
            <MdInfo />
          </Stack>
        </Tooltip>
      }
    >
      <PotentialPoints
        potentialPoints={potentialPoints}
        propKey="remaining"
        width={380}
      />
    </SidebarSection>
  );
};

export default SidebarPotentialPoints;
