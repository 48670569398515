import React from "react";
import { Drawer, useTheme } from "@mui/material";

const DrawerDesktop = ({ children, drawerWidth }) => {
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: theme.palette.navigation.background,
          boxShadow: theme.palette.navigation.boxShadow,
        },
      }}
      open
    >
      {children}
    </Drawer>
  );
};

export default DrawerDesktop;
