import moment from "moment";
import { getPaginationEndIndex, getPaginationStartIndex } from "./display";
import { NUM_BATTLES_PER_PAGE, ARENA_RANK_SLP_REWARDS } from "config";
import { ARENA_CHALLENGER_TIERS } from "config";
import { STARTER_AXIE_DATA } from "config";
import { Battle, BattleAxie, BattleAxieRaw, BattleRaw } from "types/battle";
import { Rune } from "types/rune";
import { Charm, CharmByBodyPart, CharmByBodyPartRaw } from "types/charm";
import { Axie, AxieType } from "types/axie";
import { VictoryStarDataPoint } from "types/chart";

export const getMedalForPlace = (place: number, all = false) => {
  switch (place) {
    case 1:
      return "🏆";
    case 2:
      return "🥈";
    case 3:
      return "🥉";
    default:
      return all ? "🏅" : place;
  }
};

export const augmentAxieWithRuneData = (
  axie: BattleAxieRaw,
  allRunes: Rune[]
) => {
  const runeId = axie.runes ? axie.runes[0] : null;
  let runeData;

  if (runeId && runeId !== "") {
    runeData = (allRunes || []).find((rune) => rune.itemId === runeId);
  }

  return {
    ...(runeData && { runeData }),
  };
};

export const augmentAxieWithCharmData = (
  axieCharms: CharmByBodyPartRaw | undefined,
  allCharms: Charm[]
) => {
  if (!axieCharms) {
    return {};
  }

  let charmData: CharmByBodyPart = {};

  for (const bodyPart of Object.keys(axieCharms) as Array<
    keyof typeof axieCharms
  >) {
    const charmId = axieCharms[bodyPart];
    if (charmId !== "") {
      charmData[bodyPart] = (allCharms || []).find(
        (charm) => charm.itemId === charmId
      );
    }
  }

  return {
    charmData,
  };
};

export const augmentAxieWithAxieData = (
  axie: BattleAxieRaw,
  axieData?: { [key: string]: Axie }
) => {
  if (axie.axie_type === AxieType.STARTER) {
    return {
      ...axie,
      ...STARTER_AXIE_DATA[axie.axie_id],
    };
  }

  const data = axieData ? axieData[axie.axie_id] : undefined;

  if (data) {
    return data;
  }

  return {
    axieId: axie.axie_id,
  };
};

export const augmentTeam = (
  team: BattleAxieRaw[],
  allRunes: Rune[],
  allCharms: Charm[],
  axieData: { [key: string]: Axie }
) => {
  return team.map((axie) => {
    return {
      ...axie,
      ...augmentAxieWithRuneData(axie, allRunes),
      ...augmentAxieWithCharmData(axie.charms, allCharms),
      ...augmentAxieWithAxieData(axie, axieData),
    };
  });
};

export const getFavoriteTeams = (battles: Battle[]) => {
  const axieMapping: { [key: string]: BattleAxie[] } = {};

  let maxCount = 0;
  const counts = battles.reduce(
    (counter: { [key: string]: number }, battle: Battle) => {
      const battleAxies = ([...(battle.playerTeam || [])] || []).sort(
        (a, b) => a.axie_id - b.axie_id
      );

      const battleAxieIds = battleAxies.map((a) => a.axie_id);
      const idsStr = JSON.stringify(battleAxieIds);

      if (counter[idsStr] != null) {
        counter[idsStr] += 1;
        if (counter[idsStr] > maxCount) {
          maxCount = counter[idsStr];
        }
      } else {
        counter[idsStr] = 1;
        axieMapping[idsStr] = battleAxies;
      }
      return counter;
    },
    {}
  );

  const teams = Object.keys(counts).reduce(
    (arr: BattleAxie[][], key: string) => {
      if (counts[key] === maxCount) {
        arr.push(axieMapping[key]);
      }
      return arr;
    },
    []
  );

  return teams;
};

export const getTier = (stars: number) => {
  if (stars >= ARENA_CHALLENGER_TIERS[1]) {
    return 1;
  }
  if (stars >= ARENA_CHALLENGER_TIERS[2]) {
    return 2;
  }
  if (stars >= ARENA_CHALLENGER_TIERS[3]) {
    return 3;
  }
  if (stars >= ARENA_CHALLENGER_TIERS[4]) {
    return 4;
  }
  return undefined;
};

export const getWinSLPRewardForRankTier = (
  rank: string,
  tier: number,
  stars: number
) => {
  if (!(rank in ARENA_RANK_SLP_REWARDS)) {
    return 0;
  }
  let actualTier = rank === "Challenger" ? getTier(stars) : tier;

  if (actualTier == null) {
    return 0;
  }

  return ARENA_RANK_SLP_REWARDS[rank][actualTier];
};

export const sortByGameEnded = (a: BattleRaw, b: BattleRaw) => {
  const aEnd = moment.utc(a.endTimestamp).unix();
  const bEnd = moment.utc(b.endTimestamp).unix();
  return bEnd - aEnd;
};

export const extractBlockchainAxieIdsFromFavoriteTeams = (
  teams: [BattleAxie[]]
) => {
  const ids: number[] = [];
  for (const team of teams) {
    for (const axie of team) {
      if (!ids.includes(axie.axie_id) && axie.axie_type !== AxieType.STARTER) {
        ids.push(axie.axie_id);
      }
    }
  }

  return ids;
};

export const extractBlockchainAxieIdsFromBattles = (battles: BattleRaw[]) => {
  const ids: number[] = [];

  battles.forEach((battle) => {
    [...battle.playerTeam, ...battle.opponentTeam].forEach((axie) => {
      if (!ids.includes(axie.axie_id) && axie.axie_type !== AxieType.STARTER) {
        ids.push(axie.axie_id);
      }
    });
  });
  return ids;
};

export const getVictoryStarsChartData = (battles: BattleRaw[]) =>
  battles.reduce((prev: VictoryStarDataPoint[], battle: BattleRaw) => {
    if (battle.playerMmrAfter) {
      prev.push({
        name: battle.gameTimestamp,
        victoryStars: battle.playerMmrAfter,
        label: "Stars",
      });
    }

    return prev;
  }, []);

export const augmentBattleData = (
  battles: BattleRaw[],
  allRunes: Rune[],
  allCharms: Charm[],
  axieData: { [key: string]: Axie }
) => {
  return battles.map((battle: BattleRaw) => {
    return {
      ...battle,
      playerTeam: augmentTeam(battle.playerTeam, allRunes, allCharms, axieData),
      opponentTeam: augmentTeam(
        battle.opponentTeam,
        allRunes,
        allCharms,
        axieData
      ),
    };
  });
};

export const getBattleStats = (battles: BattleRaw[] | Battle[]) => {
  const total = battles.length;
  const wins = battles.filter((battle) => battle.result === "W").length;

  return {
    total,
    wins,
    losses: total - wins,
    winRate: total ? wins / total : undefined,
  };
};

export const getBattleSummary = (battles: Battle[], currentPage: number) => {
  const total = battles.length;

  const startBattles = getPaginationStartIndex(
    currentPage,
    NUM_BATTLES_PER_PAGE
  );
  const endBattles = getPaginationEndIndex(startBattles, NUM_BATTLES_PER_PAGE);

  const battlesToDisplay = battles.slice(startBattles, endBattles);

  return {
    toDisplay: battlesToDisplay,
    numberOfPages: total ? Math.ceil(total / NUM_BATTLES_PER_PAGE) : 1,
    currentPage,
  };
};

export const createRankTierString = (rank: string, tier: number) => {
  return `${rank} ${rank === "Challenger" ? "" : tier}`;
};

export const createRankNumString = (rankNum: string | undefined) => {
  if (rankNum != null) {
    return `#${rankNum}`;
  }
  return null;
};

export const makeRankIconUrl = (rank: string | undefined) => {
  const urlBase = "/images/icons/arena-ranks";
  if (rank != null && typeof rank === "string") {
    return `${urlBase}/${rank.toLowerCase()}.png`;
  }
  return `${urlBase}/egg.png`;
};

export const getPlayerAvatarImageUrls = (encodedAvatar: string | undefined) => {
  const fallbackFrame = "0";
  const fallbackAvatar = "good_boy";
  let frame;
  let avatar;

  const specialFrames = [
    "rubber_ducky",
    "lion_lunar23",
    "temple_gate_lunar23",
    "fluffy_hooves",
    "triumphant_paws",
    "paschal_eggs",
  ];

  if (encodedAvatar != null && typeof encodedAvatar === "string") {
    const avatarSegments = encodedAvatar.split(";");

    // Avatar encoding has both frame and avatar
    if (avatarSegments.length > 1) {
      avatar = avatarSegments[0];

      // These frames are returned as is
      if (specialFrames.includes(avatarSegments[1])) {
        frame = avatarSegments[1];
      }
      // Otherwise, need to rearrange the frame segments
      else {
        const frameSegments = avatarSegments[1].split("_");

        if (frameSegments.length === 1) {
          frame = frameSegments[0];
        } else if (frameSegments.length === 2) {
          frame = frameSegments[1] + "_" + frameSegments[0];
        } else if (frameSegments.length === 3) {
          frame =
            frameSegments[2] + "_" + frameSegments[0] + "_" + frameSegments[1];
        }
      }
    }
  }

  return {
    avatar: `https://storage.googleapis.com/origin-production/assets/avatar/${
      avatar || fallbackAvatar
    }.png`,
    frame: `https://storage.googleapis.com/origin-production/assets/border/${
      frame || fallbackFrame
    }.png`,
  };
};

export const getPlayerLastActive = (lastBattle: Battle | null) => {
  if (lastBattle == null || lastBattle.endTimestamp == null) return null;

  return moment(lastBattle.endTimestamp).fromNow();
};
