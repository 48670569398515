import { getGuildLogoURL } from "helpers/guild";
import { createSelector } from "reselect";

const getGuilds = (state) => state.guildDashboardAdmin.guilds.data;
const getSearchQuery = (state) =>
  state.guildDashboardAdmin.controls.searchQuery;
const getShowInactive = (state) =>
  state.guildDashboardAdmin.controls.showInactive;

const matchesSearchQuery = (guild, searchQuery) =>
  (searchQuery &&
    guild?.name &&
    guild?.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  (searchQuery &&
    guild?.loginAddress &&
    guild?.loginAddress.toLowerCase().includes(searchQuery.toLowerCase())) ||
  !searchQuery ||
  searchQuery === "";

const matchesShowInactive = (guild, showInactive) =>
  showInactive || (!showInactive && guild.isEnabled);

export const guildMatchesSearchQuery = (guild, searchQuery, showInactive) => {
  return (
    matchesSearchQuery(guild, searchQuery) &&
    matchesShowInactive(guild, showInactive)
  );
};

export const getGuildsToDisplay = createSelector(
  [getGuilds, getSearchQuery, getShowInactive],
  (guilds, searchQuery, showInactive) => {
    return guilds
      .filter((guild) =>
        guildMatchesSearchQuery(guild, searchQuery, showInactive)
      )
      .map((guild) => ({
        ...guild,
        profileImageFullUrl: guild.profileImage
          ? getGuildLogoURL(guild.profileImage)
          : undefined,
      }));
  }
);
