import React from "react";
import { useTheme, Container, Box } from "@mui/material";

const Page = ({ children }) => {
  const theme = useTheme();
  return (
    <Container fixed>
      <Box
        sx={{
          py: 12,
          [theme.breakpoints.down("sm")]: {
            py: 6,
          },
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default Page;
