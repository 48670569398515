import { Typography, useTheme } from "@mui/material";
import fonts from "theme/fonts";

const Rank = ({ rank }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="span"
      fontWeight={400}
      fontSize={24}
      fontFamily={fonts.changa}
      color={theme.palette.text.secondary}
      sx={{
        position: "relative",
        bottom: 1,
        [theme.breakpoints.down("md")]: {
          fontSize: 24,
        },
      }}
    >
      {rank}
    </Typography>
  );
};

export default Rank;
