import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button, Typography } from "@mui/material";

const ErrorDialog = ({ open, handleClose, validRoninAddresses }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="select-axies-dialog-title"
      sx={{ "& .MuiPaper-root": { width: "540px" } }}
    >
      <DialogTitle id="select-axies-dialog-title">
        Oops, something went wrong.
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="body2" color="text.regular">
          We were unable to load any axies. Please double check that there are
          axies in{" "}
          {validRoninAddresses.length > 1 ? "those accounts" : "that account"}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
