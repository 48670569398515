import React from "react";
import CardList from "./CardList";
import { Box, useTheme } from "@mui/material";

const CardExplorer = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          pt: 8,
          background:
            theme.palette.mode === "light"
              ? theme.palette.colors.purple.gradientLight
              : theme.palette.colors.purple.gradientDark,
        }}
      >
        <CardList />
      </Box>
    </>
  );
};

export default CardExplorer;
