import React from "react";
import { Stack, Typography } from "@mui/material";

const CurrencyPrice = ({ data, img }) => {
  return (
    <Stack direction="row" gap={0.5}>
      <img
        src={`/images/icons/${img}`}
        style={{ height: "14px", position: "relative", top: "-1px" }}
        alt={`${img} icon`}
      />
      <Typography
        variant="span"
        fontSize="13px"
        fontWeight="600"
        color="text.muted"
      >
        {data}
      </Typography>
    </Stack>
  );
};

export default CurrencyPrice;
