import React from "react";
import { Button } from "@mui/material";
import { FaStar } from "react-icons/fa";

const GuidesButton = () => {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        lineHeight: 1.5,
        mx: 1,
        "& .MuiButton-endIcon": {
          ml: "6px",
        },
      }}
      href="https://welcome.axie.tech"
      target="_blank"
      endIcon={<FaStar />}
    >
      Guides
    </Button>
  );
};

export default GuidesButton;
