import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 16,
  borderRadius: 16,
  paddingBottom: 60,
  paddingTop: 24,
  [theme.breakpoints.down("sm")]: { paddingBottom: 32, paddingTop: 0 },
}));

const PageControls = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PageControls;
