import React from "react";
import { Stack } from "@mui/material";
import SectionLabel from "../atoms/SectionLabel";
import Stats from "components/Axie/AxieV3/components/Stats";

const StatsV2 = ({ axie }) => {
  return axie.isAdult ? (
    <Stack gap={1.5}>
      <SectionLabel>Stats</SectionLabel>
      <Stats axie={axie} width={200} />
    </Stack>
  ) : (
    <></>
  );
};

export default StatsV2;
