import React from "react";
import { Stack } from "@mui/material";
import Sidebar from "./Sidebar";
import Main from "./Main";
import ClearButton from "./ClearButton";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(3),
  padding: "8px 16px",
  background: theme.palette.card.background,
  borderRadius: 4,
  boxShadow: theme.palette.card.boxShadow,
  margin: "0 auto",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    flexWrap: "wrap",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "16px",
  },
}));

const AxieSlotCondensed = ({
  axie,
  updateRune,
  updateCharm,
  updateCard,
  view,
  clearAxie,
  handleOpenCardDialog,
  handleOpenCharmDialog,
  handleOpenRuneDialog,
  updateClass,
}) => {
  if (axie == null) {
    return null;
  }

  return (
    <Wrapper>
      <ClearButton axie={axie} clearAxie={clearAxie} />
      <Sidebar
        axie={axie}
        view={view}
        handleOpenRuneDialog={handleOpenRuneDialog}
        updateRune={updateRune}
        updateClass={updateClass}
        updateCharm={updateCharm}
      />
      <Main
        axie={axie}
        updateCard={updateCard}
        updateCharm={updateCharm}
        view={view}
        handleOpenCardDialog={handleOpenCardDialog}
        handleOpenCharmDialog={handleOpenCharmDialog}
      />
    </Wrapper>
  );
};

export default AxieSlotCondensed;
