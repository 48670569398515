import { Tabs as MuiTabs, Tab } from "@mui/material";
import { a11yProps } from "helpers/accessibility";
import { CSSProperties } from "react";

interface TabsProps {
  activeTab: number;
  setActiveTab: (newValue: number) => void;
  tabsId: string;
  tabs: string[];
  ariaLabel: string;
  onClick?: (i: number) => void;
  tabStyle?: CSSProperties;
  tabsStyle?: CSSProperties;
}

const Tabs = ({
  activeTab,
  setActiveTab,
  tabsId,
  tabs,
  ariaLabel,
  onClick,
  tabStyle,
  tabsStyle,
}: TabsProps) => {
  return (
    <MuiTabs
      value={activeTab}
      onChange={(e, newValue) => setActiveTab(newValue)}
      aria-label={ariaLabel}
      sx={tabsStyle}
    >
      {tabs.map((tab, i) => (
        <Tab
          disableRipple
          label={tab}
          {...a11yProps(tabsId, i)}
          key={`TAB_${i}`}
          onClick={onClick ? () => onClick(i) : undefined}
          sx={tabStyle}
        />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
