import { Stack } from "@mui/material";
import React from "react";
import Stat from "../atoms/Stat";

const Stats = ({ stats }) => {
  return (
    <Stack direction="row" gap={1}>
      <Stat title="Total Collected" amount={stats.total} />
      <Stat title="Total Runes" amount={stats.totalRunes} />
      <Stat title="Total Charms" amount={stats.totalCharms} />
    </Stack>
  );
};

export default Stats;
