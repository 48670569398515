import * as types from "./inspectorActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./inspectorActions";
import { extractAndCleanPartIds } from "helpers/axie";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* fetchNumberOfSimilarAxies(action) {
  try {
    yield put(actions.fetchNumberOfSimilarAxiesStarted());

    const clearnedPartIds = extractAndCleanPartIds(action.axie.parts.d);

    const similarAxieVariables = {
      auctionType: "All",
      minBreedCount: 0,
      maxBreedCount: 7,
      classes: [action.axie.class],
      parts: clearnedPartIds,
      from: 0,
      size: 1,
    };
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestSearchAxies,
      similarAxieVariables
    );

    yield put(actions.fetchNumberOfSimilarAxiesSucceeded(data.total));

    if (data.total > 1) {
      yield put(actions.fetchCheapestSimilarListings(action.axie));
    }
  } catch (e) {
    yield put(actions.fetchNumberOfSimilarAxiesFailed());
  }
}

export default function* numberOfSimilarAxiesSaga() {
  yield takeEvery(
    types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES,
    fetchNumberOfSimilarAxies
  );
}
