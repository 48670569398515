import { connect } from "react-redux";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import Stat from "../../../molecules/Stat";
import { Link } from "react-router-dom";
import Card from "components/Card/Card";
import { Typography, useTheme, Box, Grid, Stack, Chip } from "@mui/material";
import { getGuildBattleTeam } from "store/guildDashboard/selectors/team";

const BattleTeam = ({ team, guildStats }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        transition: "0.15s transform ease",
        "&:hover": { transform: "scale(1.01)" },
        border: "solid 3px #7232C2",
        borderRadius: "16px",
      }}
    >
      <Link to={`/guild-dashboard/team/${team.guildTeamId}`}>
        <Card>
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={2}
            alignItems="center"
            sx={{
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 3,
              },
            }}
          >
            <Box>
              <Typography fontSize="35px" variant="span">
                ⚔️
              </Typography>
            </Box>
            <Stack
              gap={0.5}
              sx={{
                minWidth: "200px",
                width: "200px",
                [theme.breakpoints.down("sm")]: {
                  borderBottom: `1px solid ${theme.palette.card.divider}`,
                  paddingBottom: 2,
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 2,
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant="span"
                color="text.primary"
                fontWeight="700"
                fontSize="24px"
                sx={{
                  lineHeight: 1.25,
                  maxWidth: "200px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {team.name}
              </Typography>
              <Chip
                variant="outlined"
                size="small"
                label={"Public"}
                sx={{
                  alignSelf: "flex-start",
                  color: theme.palette.text.muted,
                }}
              />
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                <Stat
                  label="Scholars"
                  amount={
                    guildStats.teamStats[team.guildTeamId].numberOfScholars
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                <Stat
                  label="Axies"
                  amount={guildStats.teamStats[team.guildTeamId].numberOfAxies}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                <Stat
                  label="Avg Stars"
                  amount={
                    guildStats.teamStats[team.guildTeamId].overallAverageMMR
                  }
                  amountSuffix="Stars"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                <Stat
                  label="Arena MVP"
                  amount={guildStats.teamStats[team.guildTeamId].highestMMR}
                  amountSuffix="Stars"
                />
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Link>
    </Box>
  );
};

const BattleTeamList = ({ teams, guildStats }) => {
  return (
    <>
      <Stack gap={2}>
        <Typography variant="h1" fontSize="20px" color="text.regular">
          Guild Battle Team
        </Typography>
        {teams &&
          teams.length > 0 &&
          teams.map((team) => (
            <BattleTeam
              team={team}
              guildStats={guildStats}
              key={team.guildTeamId}
            />
          ))}
      </Stack>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guild: state.guildDashboard.guild,
  teams: getGuildBattleTeam(state),
  guildStats: guildSelectors.getGuildStats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(BattleTeamList);
