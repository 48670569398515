import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { formatDataForChart, createOptions } from "../atoms/chart";
import { Stack, useTheme } from "@mui/material";
import Toggles from "../molecules/Toggles";
import ChartHeader from "../molecules/ChartHeader";
import CustomLoader from "components/CustomLoader/CustomLoader";
import DatePickers from "../molecules/DatePickers";
import Totals from "../molecules/Totals";

ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SLPIssuance = ({ rawData, total, controls, updateControls, loading }) => {
  const theme = useTheme();

  const handleUpdateControl = (key) => (val) =>
    updateControls({ ...controls, [key]: val });

  const data = rawData[controls.variant];
  const chartData = formatDataForChart(data, theme);

  const optionFns = {
    stacked: createOptions.stacked,
    net: createOptions.net,
    positiveNegative: createOptions.positiveNegative,
    dual: createOptions.dual,
    bar: createOptions.bar,
  };
  const chartOptions = optionFns[controls.variant](data, theme);

  return (
    <Stack gap={3}>
      <ChartHeader title={rawData.title} footnote={rawData.footnote}>
        <Totals
          totalBurnt={total?.burnt}
          totalMinted={total?.minted}
          totalSpent={total?.spent}
        />
      </ChartHeader>

      <DatePickers
        startDate={controls.startDate}
        endDate={controls.endDate}
        handleUpdateControl={handleUpdateControl}
      />

      <Stack>
        {loading ? (
          <CustomLoader />
        ) : (
          <Stack
            sx={{
              height: 600,
              [theme.breakpoints.down("md")]: {
                height: 500,
              },
              [theme.breakpoints.down("sm")]: {
                height: 400,
                py: 2,
              },
            }}
          >
            <Bar options={chartOptions} data={chartData} />
          </Stack>
        )}
      </Stack>
      <Toggles controls={controls} handleUpdateControl={handleUpdateControl} />
    </Stack>
  );
};

export default SLPIssuance;
