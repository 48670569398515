import React from "react";
import { Drawer, useTheme } from "@mui/material";

const DrawerMobile = ({ children, container, open, onClose, drawerWidth }) => {
  const theme = useTheme();
  return (
    <Drawer
      container={container}
      variant="temporary"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: theme.palette.navigation.background,
          boxShadow: theme.palette.navigation.boxShadow,
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerMobile;
