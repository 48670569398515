import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageFooter from "./PageFooter";
import PageContent from "./PageContent";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} 0`,
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.colors.axieQuest.pageBackground,
}));

const Page = ({ controls, children }) => {
  return (
    <Wrapper>
      <PageContent controls={controls}>{children}</PageContent>
      <PageFooter />
    </Wrapper>
  );
};

export default Page;
