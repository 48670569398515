import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import Columns from "../atoms/Columns";

const Wrapper = styled(Stack)(({ theme, rank }) => ({
  backgroundColor: theme.palette.card.background,
  boxShadow: theme.palette.card.boxShadow,
  border: `1px solid ${theme.palette.card.border}`,
  transition: "0.2s transform ease",
  padding: "3px",
  borderRadius: theme.spacing(2),

  "&:hover": {
    transform: "scale(1.015)",
  },

  ...(rank === 1 && {
    background: "linear-gradient(to right, #f99500, #ffe99b)",
  }),
  ...(rank === 2 && {
    background: "linear-gradient(to right, #b6b6b6, #e5e5e5)",
  }),
  ...(rank === 3 && {
    background: "linear-gradient(to right, #a76a0f, #f8d49e)",
  }),
}));

const Row = ({ children, rowType, rank }) => {
  return (
    <Wrapper rank={rank}>
      <Columns rowType={rowType}>{children}</Columns>
    </Wrapper>
  );
};

export default Row;
