import React from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Container, Stack } from "@mui/material";
import { getRevengeCardsToDisplay } from "store/explorer/selectors";
import {
  favoriteRevengeCard,
  updateRevengeCardFilters,
} from "store/explorer/explorerActions";
import Sort from "../../molecules/Sort";
import InfoButton from "components/Origin/atoms/InfoButton";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";

const RevengeCards = ({
  revengeCardsToDisplay,
  fetchingAxieCards,
  favoriteRevengeCard,
  revengeCardFilters,
  updateRevengeCardFilters,
  setInfoDialogIsOpen,
}) => {
  return (
    <>
      {fetchingAxieCards ? (
        <CustomLoader />
      ) : (
        <Stack gap={6}>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Sort
                filters={revengeCardFilters}
                updateFilters={updateRevengeCardFilters}
                options={[
                  { label: "Tag", value: "TYPE_ASC" },
                  { label: "Name A-Z", value: "NAME_ASC" },
                  { label: "Name Z-A", value: "NAME_DESC" },
                ]}
              />
              <InfoButton onClick={() => setInfoDialogIsOpen(true)} />
            </Stack>
          </Container>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            gap={3}
            sx={{ px: 2 }}
          >
            {revengeCardsToDisplay.map((card) => (
              <WrappedCard
                key={card.id}
                card={card}
                favoriteCard={favoriteRevengeCard}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapDispatchToProps = { favoriteRevengeCard, updateRevengeCardFilters };

const mapStateToProps = (state) => ({
  revengeCardsToDisplay: getRevengeCardsToDisplay(state),
  fetchingCards: state.data.fetchingAxieCards,
  revengeCardFilters: state.explorer.revengeCardFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(RevengeCards);
