import {
  AXIE_QUEST_EFFECT_TYPES,
  AXIE_QUEST_TRIGGER_EVENT_TYPES,
} from "config";
import {
  atLeastOneMatchInArray,
  matchesStr,
  sortByKey,
  strIsInArray,
} from "helpers/display";
import { createSelector } from "reselect";
import { getAllCardsV3 } from "store/data/dataSelectors";

export const getAxieQuestCards = (state) => state.axieQuest.cards.data || [];
export const getLoading = (state) => state.axieQuest.cards.fetching;
export const getFailed = (state) => state.axieQuest.cards.failed;
const getControls = (state) => state.axieQuest.cards.controls;

const getResource = (level) => {
  if (level != null) {
    if (level.sunReq > 0) {
      return {
        type: "sun",
        amount: level.sunReq,
      };
    }
    if (level.moonReq > 0) {
      return {
        type: "moon",
        amount: level.moonReq,
      };
    }
    if (level.starReq > 0) {
      return {
        type: "star",
        amount: level.starReq,
      };
    }
  }
  return {
    type: "passive",
  };
};

const skillTypes = [
  {
    type: "Active",
    parentKey: "activeSkillEffectBases",
    childKey: "activeEffectType",
  },
  {
    type: "Passive",
    parentKey: "passiveSkillEffectBases",
    childKey: "passiveEffectType",
  },
  {
    type: "Trigger",
    parentKey: "triggerSkillEffectBases",
    childKey: "triggerEffectType",
    event: "triggerEventType",
  },
];

export const applyFilterCriteria = (cards, filters) => {
  return [...cards].filter(
    (card) =>
      card.image !== null &&
      (matchesStr(card.partName, filters.searchText) ||
        matchesStr(card.part, filters.searchText)) &&
      strIsInArray(card.axieClassName, filters.axieClasses) &&
      strIsInArray(card.partType, filters.partTypes) &&
      strIsInArray(card.levels[1].resource.type, filters.resources) &&
      atLeastOneMatchInArray(
        Object.values(card.levels[1].effectTypes).flatMap((val) => {
          return val.map((mapped) => mapped.prettyLabel);
        }),
        filters.activeEffectTypes
      ) &&
      atLeastOneMatchInArray(
        Object.values(card.levels[1].effectTypes).flatMap((val) => {
          return val.map((mapped) => mapped.prettyLabel);
        }),
        filters.passiveEffectTypes
      )
  );
};

const checkIfHasFiltered = (filters) => {
  return (
    filters.searchText !== "" ||
    filters.axieClasses.length > 0 ||
    filters.partTypes.length > 0 ||
    filters.activeEffectTypes.length > 0 ||
    filters.passiveEffectTypes.length > 0 ||
    filters.resources.length > 0
  );
};

export const getPrettyLevels = (levels) => {
  const data = {};

  levels.forEach((level) => {
    const currentLevel = level.level;

    const levelData = {
      resource: getResource(level),
      effectTypes: {},
    };

    skillTypes.forEach((skillType) => {
      if (level[skillType.parentKey].items.length > 0) {
        const skills = level[skillType.parentKey].items.map((i) => {
          return {
            ...(skillType.type === "Trigger" && {
              triggerEventLabel: {
                ...AXIE_QUEST_TRIGGER_EVENT_TYPES[i[skillType.event]],
              },
            }),
            ...AXIE_QUEST_EFFECT_TYPES[i[skillType.childKey]],
            factor: i.factor,
          };
        });

        levelData.effectTypes[skillType.type] = skills;
      }
    });

    data[currentLevel] = levelData;
  });

  return data;
};

const getSkillType = (levels) => {
  if (!levels[1]) return "";

  const level1EffectTypes = levels[1].effectTypes;
  if (level1EffectTypes.Active) {
    return "Active";
  }
  if (level1EffectTypes.Passive) {
    return "Passive";
  }
  if (level1EffectTypes.Trigger) {
    return "Trigger";
  }
  return "";
};

export const getAxieQuestCardsData = createSelector(
  [getAxieQuestCards, getAllCardsV3, getLoading, getControls],
  (axieQuestCards, baseAxieCards, loading, controls) => {
    const augmentedCards = axieQuestCards.flatMap((axieClass) => {
      const parts = axieClass.partBases.items;
      return parts.map((part) => {
        // get partId for the Axie Quest skill
        const axieQuestPartId = part.partId;
        // find card that matches axieQuest partId
        const matchingCard = baseAxieCards.find(
          (card) => card.partId === axieQuestPartId
        );
        // get image
        const image = matchingCard?.cardImage;
        // get class
        const axieClassName = (matchingCard?.class || "").toLowerCase();
        // get levels and prettify data
        const levels = getPrettyLevels(part.skillBases.items);

        return {
          ...part,
          axieQuestPartId,
          image,
          axieClassName,
          skillType: getSkillType(levels),
          levels,
        };
      });
    });

    const filteredCards = applyFilterCriteria(augmentedCards, controls);
    const sortedCards = sortByKey(filteredCards, "partName");
    const hasFiltered = checkIfHasFiltered(controls);

    return {
      controls,
      loading,
      cards: sortedCards,
      hasFiltered,
    };
  }
);
