import React from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import CardTemplateV2 from "components/GameTemplates/V2/CardTemplateV2";
import CardTemplate from "components/GameTemplates/CardTemplate";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import CardSummary from "components/Axie/AxieV3/components/CardSummary";

const ByVersion = ({ cardObj }) => {
  return (
    <Stack gap={4}>
      <Stack gap={{ xs: 4, sm: 4, md: 2 }}>
        <Stack
          gap={{ xs: 2, sm: 2, md: 3 }}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <SectionLabel>Origins Cards</SectionLabel>
          <Stack direction="row" gap={2} flexWrap="wrap">
            <CardSummary
              data={cardObj.cardV3Stats}
              width={330}
              title="Stats"
              noWrapper={false}
              gapMultiplier={1.5}
            />
            <CardSummary
              data={cardObj.cardV3Tags}
              width={330}
              title="Tags"
              addCardsToLabel={true}
              noWrapper={false}
              gapMultiplier={1.5}
            />
          </Stack>
        </Stack>
        <Cards cards={cardObj.cardsV3} version="V3" gap={2} />
      </Stack>

      <Stack gap={{ xs: 4, sm: 4, md: 2 }}>
        <Stack
          gap={{ xs: 2, sm: 2, md: 3 }}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <SectionLabel>Classic Cards</SectionLabel>
          <CardSummary
            data={cardObj.cardV2Stats}
            width={330}
            title="Stats"
            noWrapper={false}
            gapMultiplier={1.5}
          />
        </Stack>
        <Cards cards={cardObj.cardsV2} version="V2" gap={3} />
      </Stack>
    </Stack>
  );
};

const Cards = ({ cards, version, gap = 0 }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const cardStyles = {
    transition: "0.2s transform ease",
    "&:hover": {
      transform: "scale(1.06)",
    },
  };
  return (
    <Wrapper gap={gap}>
      {["mouth", "horn", "back", "tail", "eyes", "ears"].map((part, i) => {
        let card = cards[part];

        if (version === "V2") {
          if (card == null) {
            return null;
          } else {
            return (
              <CardTemplateV2
                key={`PART_${i}`}
                card={card}
                style={cardStyles}
                width={mdDown ? 196 : 216}
              />
            );
          }
        }
        return (
          <CardTemplate
            width={mdDown ? 190 : 210}
            card={card}
            style={cardStyles}
            key={`PART_${i}`}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = ({ children, gap }) => {
  const theme = useTheme();
  return (
    <Stack
      gap={gap}
      flexWrap="wrap"
      direction="row"
      justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
      sx={{
        background: theme.palette.inspector.shaded,
        p: 1,
        borderRadius: "24px",
        [theme.breakpoints.down("md")]: {
          gap: gap === 1 ? 5 : gap,
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default ByVersion;
