import React, { useState } from "react";
import styles from "./card.module.scss";
import * as bodyPartIcons from "components/Axie/icons/bodyPartIcons";
import { IoStarOutline } from "react-icons/io5";
import { connect } from "react-redux";
import {
  favoriteCard,
  unfavoriteCard,
} from "store/cardExplorer/cardExplorerActions";
import { Box, useTheme, Stack, Typography } from "@mui/material";
import CardTemplateV2 from "components/GameTemplates/V2/CardTemplateV2";
import CardCompareIcon from "components/GameTemplates/CardCompareIcon";
import { getAllCardsV3 } from "store/data/dataSelectors";

const Card = ({
  card,
  favoriteCards,
  favoriteCard,
  unfavoriteCard,
  displayIndesTier,
  cardsV3,
}) => {
  const [displayV3, setDisplayV3] = useState(false);
  const bodyPart = card.partId.split("-")[0];
  const theme = useTheme();

  const handleOnMouseEnter = () => {
    setDisplayV3(true);
  };

  const handleOnMouseLeave = () => {
    setDisplayV3(false);
  };

  const cardIsFavorited = (currentCard) =>
    favoriteCards.find((c) => c.partId === currentCard.partId);

  const cardV3 = cardsV3
    ? cardsV3.find((c) => c.partId === card.partId)
    : undefined;

  const handleClickCard = () => {
    if (cardIsFavorited(card)) {
      unfavoriteCard(card);
    } else {
      favoriteCard(card);
    }
  };

  return (
    <Box
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      className={styles.cardWrapper}
      onClick={handleClickCard}
      sx={{
        "&:hover": {
          "& .favoriteIcon": {
            background: theme.palette.feedback.yellow,
            "& svg": {
              color: theme.palette.colors.black,
              "& path": {
                fill: theme.palette.colors.black,
              },
            },
          },
        },
      }}
    >
      <div className={styles.cardHeader}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className={styles.headerLeft}>
            <span className={styles.partIcon}>
              {bodyPartIcons[`${bodyPart}_${card.class.toLowerCase()}`]}
            </span>
            <Typography
              variant="span"
              fontSize="15px"
              color="text.regular"
              fontWeight="600"
              className={styles.partName}
              sx={{ maxWidth: "89px" }}
            >
              {card.partName}
            </Typography>
          </div>
          <Stack
            direction="row"
            alignItems="stretch"
            className={styles.headerRight}
          >
            <Stack
              className={styles.isRanged}
              alignItems="center"
              justifyContent="center"
              sx={{
                background: theme.palette.card.pop1,
                borderRadius: "4px",
                padding: "0 6px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                variant="span"
                fontWeight="600"
                color="text.muted"
                fontSize="11px"
              >
                {card.isRanged === 1 ? "Ranged" : "Melee"}
              </Typography>
            </Stack>
            <Stack
              className={`${styles.favorite} favoriteIcon`}
              sx={{
                background: cardIsFavorited(card)
                  ? theme.palette.feedback.yellow
                  : theme.palette.card.pop1,
                "& svg": {
                  color: cardIsFavorited(card)
                    ? theme.palette.colors.black
                    : theme.palette.text.muted,
                  "& path": {
                    fill: cardIsFavorited(card)
                      ? theme.palette.colors.black
                      : theme.palette.text.muted,
                  },
                },
              }}
            >
              <IoStarOutline className="star" />
            </Stack>
          </Stack>
        </Stack>
      </div>

      <Box
        sx={{
          position: "relative",
          ".compareCard": {
            transition: "0.2s opacity ease",
            opacity: 0.1,
          },
          "&:hover": {
            ".compareCard": {
              opacity: 1,
            },
          },
        }}
      >
        <CardCompareIcon card={cardV3} display={displayV3} version="V3" />
        <CardTemplateV2 card={card} />
      </Box>

      {displayIndesTier && (
        <div className={styles.indesTierWrapper}>
          <div
            className={`${styles.indesTier} ${
              styles[`tier-${card.cardIndesTier}`]
            }`}
          >{`${card.cardIndesTier} Tier`}</div>
        </div>
      )}
    </Box>
  );
};

const mapDispatchToProps = { favoriteCard, unfavoriteCard };

const mapStateToProps = (state) => ({
  favoriteCards: state.cardExplorer.favoriteCards,
  cardsV3: getAllCardsV3(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
