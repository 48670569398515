import { axieIsAdult, getAxieImageUrl } from "helpers/axie";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "style",
})(({ width, style }) => ({
  width: `${(140 / 320) * width}px`,
  ...style,
}));

const AdultWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "image",
})(({ image }) => ({
  width: "100%",
  backgroundImage: `url(${image})`,
  backgroundSize: "130%",
  backgroundPosition: "center center",
  paddingBottom: "75%",
  backgroundRepeat: "no-repeat",
}));

const EggWrapper = styled(Stack)(({ width }) => ({
  img: {
    display: "block",
    width: `${(180 / 320) * width}px`,
    margin: "0 auto",
  },
}));

const Image = ({ axie, width, style }) => {
  const imageUrl = getAxieImageUrl(axie);

  return axieIsAdult(axie) ? (
    <Wrapper width={width} style={style}>
      <AdultWrapper image={imageUrl} />
    </Wrapper>
  ) : (
    <EggWrapper width={width}>
      <img alt={`Egg axie – #${axie.axieId}`} src={imageUrl} />
    </EggWrapper>
  );
};

export default Image;
