import { fetchRetry } from "helpers/actions";

export const axieTechAPI = async (url, params) => {
  try {
    const response = await fetchRetry(url, params);
    if (response.status === 406) {
      return Promise.resolve();
    }
    if (response.status < 200 || response.status > 300) {
      return Promise.reject();
    }
    const data = await response.json();
    return data;
  } catch (e) {
    //console.log("error: ", e, e.message);
  }
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
