import { useState } from "react";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import Outcome from "components/BattleLog/atoms/Outcome";
import Card from "components/Card/Card";
import { Stack } from "@mui/material";
import { FaVideo } from "react-icons/fa";
import { fadeInOut } from "theme/animations/fadeInOut";
import { Axie, Battle } from "types";

interface Props {
  battle: Battle;
}

const RecentBattle = ({ battle }: Props) => {
  const [showWatchReplay, setShowWatchReplay] = useState(false);

  const isBattleLoading = [...battle.playerTeam, ...battle.opponentTeam].some(
    (axie) => axie.axieId == null
  );

  return (
    <Stack
      onMouseEnter={
        isBattleLoading ? undefined : () => setShowWatchReplay(true)
      }
      onMouseLeave={
        isBattleLoading ? undefined : () => setShowWatchReplay(false)
      }
      sx={{ flexGrow: 1 }}
    >
      <Card
        style={{
          p: 0,
          justifyContent: "center",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {isBattleLoading ? (
          <Loading />
        ) : showWatchReplay ? (
          <WatchReplay replayUrl={battle.replayUrl} />
        ) : (
          <Stack
            gap={0.5}
            px={1}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            height={40}
          >
            <Axies axies={battle.playerTeam} />

            <Outcome size="xsmall" result={battle.result} iconOnly={true} />

            <Axies axies={battle.opponentTeam} />
          </Stack>
        )}
      </Card>
    </Stack>
  );
};

const Loading = () => {
  return (
    <Stack
      alignSelf={"center"}
      sx={{
        height: 40,
        padding: "6px",
        width: "80%",
      }}
    >
      <Stack
        alignSelf={"center"}
        sx={{
          background: (theme) => theme.palette.axieCard.loading,
          borderRadius: "4px",
          width: "100%",
          flexGrow: 1,
          animation: `${fadeInOut} ease 4s infinite`,
        }}
      />
    </Stack>
  );
};

const WatchReplay = ({ replayUrl }: { replayUrl: string }) => {
  return (
    <Stack
      component="a"
      href={replayUrl}
      target="_blank"
      sx={{
        width: "100%",

        fontWeight: 800,
        height: "100%",
        color: (theme) => theme.palette.card.background,
        background: (theme) => theme.palette.primary.main,
        cursor: "pointer",
      }}
    >
      <Stack
        height={40}
        flexDirection="row"
        gap={1}
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        <FaVideo /> Watch Replay
      </Stack>
    </Stack>
  );
};

const Axies = ({ axies }: { axies: Axie[] }) => {
  const axieWidth = 50;

  return (
    <Stack direction="row">
      {axies.map((axie) => (
        <AxieV3
          axie={axie}
          key={axie.axieId}
          variant="image"
          width={axieWidth}
        />
      ))}
    </Stack>
  );
};

export default RecentBattle;
