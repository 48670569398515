import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import InterDungeonCard from "components/InterDungeon/InterDungeonCard/index";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(3),
  padding: theme.spacing(6),
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  width: "90%",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
  },
}));

const CardsContainer = ({ data }) => {
  const width = 215;
  return (
    <Wrapper>
      {data.map((card) => (
        <InterDungeonCard
          key={`INTER_DUNGEON_CARD ${card.id}`}
          id={card.id}
          name={card.name}
          cardImage={card.cardImage.url}
          width={width}
          equippableModifier={card.equippableModifier}
          nonEquippableModifer={card.nonEquippableModifer}
          rarity={card.rarity}
          description={card.flavorText}
        />
      ))}
    </Wrapper>
  );
};

export default CardsContainer;
