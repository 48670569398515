import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AxieClassButtons from "components/Axie/Controls/AxieClassButtons";
import {
  fetchRecentlySold,
  clearRecentlySold,
  deleteRecentlySoldAxie,
} from "store/recently/recentlyActions";
import { cleanAxieClass } from "helpers/cleaning";
import { axieMatchesCriteria } from "helpers/axie";
import { formatEthPrice } from "helpers/display";
import RefreshButton from "../RefreshButton";
import Axies from "../Axies";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";
import ControlPanel from "../ControlPanel";
import FilterAxieBreedCount from "components/Axie/Controls/FilterAxieBreedCount";
import FilterAxiePrice from "components/Axie/Controls/FilterAxiePrice";
import { Grid } from "@mui/material";

const SoldContent = ({
  axies,
  fetching,
  failed,
  fetchRecentlySold,
  clearRecentlySold,
  deleteRecentlySoldAxie,
}) => {
  const [axieClassFilter, setAxieClassFilter] = useState("All");
  const [breedCount, setBreedCount] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [filteredAxies, setFilteredAxies] = useState([]);
  const navigate = useNavigate();
  const { axieClass } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (axieClass != null) {
      const cleanedClass = cleanAxieClass(axieClass);
      if (cleanedClass !== "") {
        setAxieClassFilter(cleanedClass);
      }
    } else {
    }
  }, [axieClass]);

  const handleSetAxieClassFilter = (axieClass) => {
    navigate("/recently-sold/" + axieClass.toLowerCase());
    setAxieClassFilter(axieClass);
  };

  const handleDeleteRecentlySoldAxie = (axieId) => {
    deleteRecentlySoldAxie(axieId);
  };

  useEffect(() => {
    fetchRecentlySold();
    setFirstLoad(false);
  }, [fetchRecentlySold]);

  useEffect(() => {
    setFilteredAxies(axies);
  }, [axies]);

  useEffect(() => {
    const applyFilter = (axies) => {
      const filtered = [];
      axies.forEach((axie) => {
        if (
          axieMatchesCriteria([
            {
              key: "AXIE_CLASS",
              args: [axie.class, axieClassFilter],
            },
            {
              key: "BREED_COUNT",
              args: [axie.breedCount.toString(), breedCount],
            },
            {
              key: "PRICE_RANGE",
              args: [
                formatEthPrice(axie.transferHistory.results[0].withPrice),
                minPrice,
                maxPrice,
              ],
            },
          ])
        ) {
          filtered.push(axie);
        }
      });
      return filtered;
    };

    setFilteredAxies(applyFilter(axies));
  }, [breedCount, minPrice, maxPrice, axies, axieClassFilter]);

  return (
    <>
      <ControlPanel
        title="Recently Sold"
        description="The 20 most recent sales. Click Refresh to fetch the newest sales and add them to the
        top of the list."
        style={{ marginTop: -80 }}
      >
        <RefreshButton fetch={fetchRecentlySold} loading={fetching} />
        <AxieClassButtons
          filter={axieClassFilter}
          setFilter={handleSetAxieClassFilter}
        />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4} md={3}>
            <FilterAxieBreedCount
              breedCount={breedCount}
              setBreedCount={setBreedCount}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FilterAxiePrice
              price={minPrice}
              setPrice={setMinPrice}
              label="Min Price"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FilterAxiePrice
              price={maxPrice}
              setPrice={setMaxPrice}
              label="Max Price"
            />
          </Grid>
        </Grid>
      </ControlPanel>
      <DisplayLoadingOrContent
        loading={firstLoad}
        failed={failed}
        retry={fetchRecentlySold}
        data={filteredAxies}
        failedMessage="Unable to load axies"
      >
        <Axies
          axies={filteredAxies}
          clear={clearRecentlySold}
          showGenes={false}
          deleteAxie={handleDeleteRecentlySoldAxie}
          displayLastSold={true}
        />
      </DisplayLoadingOrContent>
    </>
  );
};

const mapDispatchToProps = {
  fetchRecentlySold,
  clearRecentlySold,
  deleteRecentlySoldAxie,
};

const mapStateToProps = (state) => ({
  axies: state.recently.recentlySold.axies,
  fetching: state.recently.recentlySold.fetching,
  failed: state.recently.recentlySold.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(SoldContent);
