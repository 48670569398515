import { Stack, Typography, styled } from "@mui/material";
import TopRune from "../molecules/TopRune";
import InfoButton from "../atoms/InfoButton";
import { pluralize } from "helpers/display";
import { UpdateControlsActionCreator } from "store/leaderboard/origin/leaderboardActions";
import { OriginsLeaderboardControls } from "store/leaderboard/origin/leaderboardReducer";
import { Rune } from "types";

const RunesWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(1),
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  },
}));

interface Props {
  topRunes: any[];
  numPlayers: number;
  numTopRunes: number;
  updateControls: UpdateControlsActionCreator;
  controls: OriginsLeaderboardControls;
}

const TopRunes = ({
  topRunes,
  numPlayers,
  numTopRunes,
  updateControls,
  controls,
}: Props) => {
  const handleUpdateControls = (
    newControls: Partial<OriginsLeaderboardControls>
  ) => {
    updateControls({ ...controls, ...newControls });
  };
  const handleUpdateRune = (rune: Rune) => {
    let updatedRunes = [...controls.runes];
    const foundIndex = controls.runes.findIndex((r) => r.id === rune.id);

    if (foundIndex === -1) {
      updatedRunes = [...updatedRunes, rune];
    } else {
      updatedRunes.splice(foundIndex, 1);
    }

    handleUpdateControls({
      runes: updatedRunes,
    });
  };

  return (
    <Stack alignItems="center" gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography component="span" fontWeight={600} sx={{ color: "#fff" }}>
          Top {pluralize("Rune", numTopRunes)}
        </Typography>

        <InfoButton numPlayers={numPlayers} numTopRunes={numTopRunes} />
      </Stack>
      <RunesWrapper>
        {topRunes.map((rune, i) => (
          <TopRune
            key={`RUNE_${i}`}
            data={rune.data}
            count={rune.count}
            handleClick={() => handleUpdateRune(rune.data)}
          />
        ))}
      </RunesWrapper>
    </Stack>
  );
};

export default TopRunes;
