import { IoCaretBackCircleOutline } from "react-icons/io5";
import { Stack, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NoScholar = () => {
  return (
    <>
      <Stack sx={{ marginTop: "80px" }} gap={4}>
        <Stack gap={2}>
          <Typography
            variant="span"
            fontSize="26px"
            fontWeight="700"
            textAlign="center"
            color="text.regular"
          >
            Wer scholar?
          </Typography>
          <Typography
            variant="span"
            color="text.muted"
            fontSize="20px"
            fontWeight="600"
            textAlign="center"
          >
            We can't find a scholar with that ronin address.
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          component={Link}
          to="/guild-dashboard/scholars"
          style={{ alignSelf: "center" }}
          startIcon={<IoCaretBackCircleOutline />}
          size="large"
        >
          Back to All Scholars
        </Button>
      </Stack>
    </>
  );
};

export default NoScholar;
