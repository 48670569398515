import React from "react";
import { Typography } from "@mui/material";
import fonts from "theme/fonts";

const Title = ({ children, fontSize = 46, style }) => {
  return (
    <Typography
      variant="h1"
      color="#fff"
      fontSize={fontSize}
      fontFamily={fonts.changa}
      fontWeight={500}
      sx={style}
    >
      {children}
    </Typography>
  );
};

export default Title;
