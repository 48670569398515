import { Stack, Typography } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import { CSSProperties } from "react";

interface Props {
  text?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  to?: string;
  style?: CSSProperties;
  className?: string;
}

const MoreLink = ({ text, icon, onClick, to, style, className }: Props) => {
  return (
    <CustomLink
      to={to}
      onClick={onClick}
      style={{ userSelect: "none", cursor: "pointer", ...style }}
      className={className}
    >
      <Stack alignItems="center" direction="row" gap={0.5}>
        {icon && (
          <Typography component="span" color="text.muted" fontSize={14}>
            <Stack alignItems="center" justifyContent="center">
              {icon}
            </Stack>
          </Typography>
        )}

        {text && (
          <Typography
            component="span"
            fontWeight={800}
            color="text.muted"
            fontSize={12}
          >
            {text}
          </Typography>
        )}
      </Stack>
    </CustomLink>
  );
};

export default MoreLink;
