import React from "react";
import { TextField, IconButton, InputAdornment, Stack } from "@mui/material";
import { IoSearch, IoCloseCircle } from "react-icons/io5";

const Search = ({ value, updateValue, style, placeholder = "Search" }) => {
  return (
    <Stack sx={{ width: 200, ...style }}>
      <TextField
        size="small"
        hiddenLabel
        placeholder={placeholder}
        spellCheck={false}
        value={value}
        variant="outlined"
        autoComplete="off"
        onChange={(e) => updateValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IoSearch />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              size="small"
              position="end"
              onClick={() => updateValue("")}
            >
              <IoCloseCircle />
            </IconButton>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: 47,
          },
          "& .MuiOutlinedInput-input": {
            fontSize: 15,
          },
          "& .MuiIconButton-root": {
            opacity: 0,
            transition: "0.2s opacity ease",
          },
          "& .MuiOutlinedInput-root:hover": {
            "& .MuiIconButton-root": {
              opacity: 0.8,
            },
          },
        }}
      />
    </Stack>
  );
};

export default Search;
