import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { updateUserPreferences } from "store/guildDashboard/actions/guildDashboardActions";
import CustomSelect from "components/CustomSelect/CustomSelect";
import LoadingButton from "@mui/lab/LoadingButton";
import Label from "components/Typography/Label";
import ColorModeButton from "../atoms/ColorModeButton";
import Error from "components/Typography/Error";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { ColorModeContext } from "App";
import { createUserPreferencesPayload } from "helpers/guild";

const UserPreferencesDialog = ({
  open,
  setOpen,
  updateUserPreferences,
  guild,
}) => {
  const colorMode = useContext(ColorModeContext);
  const { account } = useWeb3React();
  const fallbackToTrue = (property) => {
    if (guild[property] == null) {
      return true;
    }
    return guild[property] === 1;
  };
  const fallbackToFalse = (property) => {
    if (guild[property] == null) {
      return false;
    }
    return guild[property] === 1;
  };

  const [defaultCurrency, setDefaultCurrency] = useState(
    guild.defaultCurrency || "USD"
  );
  const [defaultManagerShare, setDefaultManagerShare] = useState(
    guild.defaultManagerShare || 35
  );

  const [showAxies, setShowAxies] = useState(fallbackToTrue("showAxies"));
  const [showEnergy, setShowEnergy] = useState(fallbackToTrue("showEnergy"));
  const [showInvestorShare, setShowInvestorShare] = useState(
    fallbackToTrue("showInvestorShare")
  );
  const [isDarkMode, setIsDarkMode] = useState(fallbackToFalse("isDarkMode"));
  const [error, setError] = useState("");

  const initialState = {
    defaultCurrency: guild.defaultCurrency,
    defaultManagerShare: guild.defaultManagerShare,
    showTodaySLP: fallbackToTrue("showTodaySLP"),
    showYesterdaySLP: fallbackToTrue("showYesterdaySLP"),
    showAxies: fallbackToTrue("showAxies"),
    showEnergy: fallbackToTrue("showEnergy"),
    showInvestorShare: fallbackToTrue("showInvestorShare"),
    isDarkMode: fallbackToFalse("isDarkMode"),
  };

  const updateColorModeIfChanged = () => {
    if (isDarkMode !== initialState.isDarkMode) {
      colorMode.toggleColorMode();
    }
  };

  const preferencesUpdated = () => {
    if (
      defaultCurrency !== initialState.defaultCurrency ||
      defaultManagerShare !== initialState.defaultManagerShare ||
      guild.showTodaySLP !== initialState.showTodaySLP ||
      guild.showYesterdaySLP !== initialState.showYesterdaySLP ||
      showAxies !== initialState.showAxies ||
      showEnergy !== initialState.showEnergy ||
      showInvestorShare !== initialState.showInvestorShare ||
      isDarkMode !== initialState.isDarkMode
    ) {
      return true;
    }

    return false;
  };

  const handleUpdateUserPreferences = () => {
    try {
      if (preferencesUpdated()) {
        updateColorModeIfChanged();
        const payload = createUserPreferencesPayload(
          defaultCurrency,
          defaultManagerShare,
          guild.showTodaySLP,
          guild.showYesterdaySLP,
          showAxies,
          showEnergy,
          showInvestorShare,
          isDarkMode
        );
        updateUserPreferences(account, payload);
      }
      setOpen(false);
    } catch (e) {
      setError("Something went wrong.");
    }
  };

  const handleSetDefaultManagerShare = (num) => {
    if (num < 0) {
      setDefaultManagerShare(0);
    } else if (num > 100) {
      setDefaultManagerShare(100);
    } else {
      setDefaultManagerShare(num);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="edit-user-preferences-dialog-title"
      sx={{ "& .MuiPaper-root": { width: "380px" } }}
    >
      <DialogTitle id="edit-user-preferences-dialog-title">
        User Preferences
      </DialogTitle>
      <DialogContent dividers={true}>
        <Stack gap={2}>
          <Error>{error}</Error>
          <Label>User Defaults</Label>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomSelect
                label="Currency"
                setVal={setDefaultCurrency}
                val={defaultCurrency}
                options={["USD", "GBP", "PHP", "EUR", "SEK", "AUD"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => handleSetDefaultManagerShare(e.target.value)}
                label="Manager Share %"
                variant="filled"
                size="small"
                value={defaultManagerShare}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Stack gap={1}>
            <Label>Scholar Table Columns</Label>

            <Stack>
              <Stack direction="row" alignItems="center">
                <CustomCheckbox
                  checked={showEnergy}
                  onChange={() => setShowEnergy(!showEnergy)}
                />
                <Label>Show Stamina</Label>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CustomCheckbox
                  checked={showAxies}
                  onChange={() => setShowAxies(!showAxies)}
                />
                <Label>Show Axies</Label>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CustomCheckbox
                  checked={showInvestorShare}
                  onChange={() => setShowInvestorShare(!showInvestorShare)}
                />
                <Label>Show Investor Share</Label>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Label>Color Preference</Label>

            <ColorModeButton
              isDarkMode={isDarkMode}
              setIsDarkMode={setIsDarkMode}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        {guild.updatingUserPreferences ? (
          <LoadingButton
            variant="contained"
            loading
            sx={{ alignSelf: "stretch" }}
          />
        ) : (
          <Button
            variant="contained"
            onClick={(e) => handleUpdateUserPreferences(e)}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  updateUserPreferences,
};

const mapStateToProps = (state) => ({
  guild: state.guildDashboard.guild,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPreferencesDialog);
