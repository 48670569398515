import { getPaginationControls, matchesStr } from "helpers/display";
import { createSelector } from "reselect";

const getSidekicks = (state) => state.doll.sidekicks.data || [];
const getLoading = (state) => state.doll.sidekicks.fetching;
const getControls = (state) => state.doll.sidekicks.controls || {};

const NUM_SIDEKICKS_PER_PAGE = 50;

export const augmentWithTraitRarity = (sidekicks) => {
  if (sidekicks.length === 0) {
    return sidekicks;
  }
  const traitCount = {};
  for (const sidekick of sidekicks) {
    for (const part of Object.keys(sidekick.parts)) {
      const partName = sidekick.parts[part];
      if (partName in traitCount) {
        traitCount[partName] += 1;
      } else {
        traitCount[partName] = 1;
      }
    }
  }

  return sidekicks.map((sidekick) => {
    const partRarity = {};
    for (const part of Object.keys(sidekick.parts)) {
      const partName = sidekick.parts[part];
      partRarity[part] = traitCount[partName] / sidekicks.length;
    }

    return {
      ...sidekick,
      partRarity,
    };
  });
};

export const getSidekickData = createSelector(
  [getSidekicks, getLoading, getControls],
  (sidekicks, loading, controls) => {
    const sidekicksWithRarity = augmentWithTraitRarity(sidekicks);

    const filteredSidekicks = sidekicksWithRarity.filter(
      (sidekick) =>
        matchesStr((sidekick.tokenId || "").toString(), controls.searchText) ||
        matchesStr(sidekick.ownedBy.username, controls.searchText)
    );

    const { start, end, numberOfPages } = getPaginationControls(
      controls.currentPage,
      NUM_SIDEKICKS_PER_PAGE,
      filteredSidekicks.length
    );

    return {
      loading,
      sidekicks: filteredSidekicks.slice(start, end),
      controls: {
        ...controls,
        numberOfPages,
      },
    };
  }
);
