import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { FaArrowCircleUp } from "react-icons/fa";

const Wrapper = styled(Box)(({ theme, width }) => ({
  display: "inline-flex",
  width: `${(width / 200) * 30}px`,
  minWidth: `${(width / 200) * 30}px`,
  height: `${(width / 200) * 30}px`,
  minHeight: `${(width / 200) * 30}px`,
  borderRadius: "50%",
  background: "rgba(255, 255, 255, 0.8)",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.colors.doll.pink,
  fontSize: `${(width / 200) * 16}px`,
  transition: "0.2s all ease",

  "&:hover": {
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
  },

  svg: {
    width: `${(width / 200) * 20}px`,
    height: `${(width / 200) * 20}px`,
  },
}));

const InfoButton = ({ width }) => {
  return (
    <Wrapper width={width}>
      <FaArrowCircleUp />
    </Wrapper>
  );
};

export default InfoButton;
