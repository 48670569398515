import fonts from "theme/fonts";
import styled from "@emotion/styled";
import { Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    borderRadius: "8px 8px 0 0",
    transition: "0.2s all ease",
    color: theme.palette.colors.origin.text.brown,
    padding: "12px 14px",
    background: theme.palette.colors.origin.paper.dark,
    fontSize: 19,
    fontFamily: fonts.changa,
    fontWeight: 500,
    whiteSpace: "nowrap",
    "& svg": {
      transition: "0.2s all ease",
      color: theme.palette.colors.origin.text.brown,
      height: 14,
      width: "auto",
      "& path": {
        fill: theme.palette.colors.origin.text.brown,
      },
    },
    "&:hover, &:hover svg": {
      color: theme.palette.colors.origin.wood.background,
      background: theme.palette.colors.origin.paper.medium,
      "& path": {
        fill: theme.palette.colors.origin.wood.background,
      },
    },
    "&.Mui-selected": {
      color: theme.palette.colors.origin.wood.background,
      background: theme.palette.colors.origin.paper.medium,
    },
  },
  "& .MuiTab-root.Mui-selected svg": {
    color: theme.palette.colors.origin.wood.background,
    "& path": {
      fill: theme.palette.colors.origin.wood.background,
    },
  },

  [theme.breakpoints.down("lg")]: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      flexWrap: "wrap",
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    "& .MuiTab-root": {
      borderRadius: "8px",
      flexBasis: "48%",
      margin: 0,
    },
  },
}));
