import { connect, ConnectedProps } from "react-redux";
import { updateControls } from "store/leaderboard/origin/leaderboardActions";
import Header from "./organisms/Header";
import DecorativePattern from "./atoms/DecorativePattern";
import Leaderboard from "./organisms/Leaderboard";
import PageWrapper from "./atoms/PageWrapper";
import CustomLoader, { Variants } from "components/CustomLoader/CustomLoader";
import OtherLeaderboardButtons from "components/Leaderboard/molecules/OtherLeaderboardButtons";
import Failed from "./molecules/Failed";
import { getOriginLeaderboardData } from "store/leaderboard/origin/leaderboardSelectors";
import { useGetOriginsLeaderboardQuery } from "store/apiSlice";
import { RootState } from "store/store";

interface Props extends PropsFromRedux {}

const OriginLeaderboard = ({ data, updateControls, controls }: Props) => {
  const { isFetching, isError, refetch } = useGetOriginsLeaderboardQuery();

  return (
    <PageWrapper>
      <DecorativePattern />
      <Header />

      {isFetching ? (
        <CustomLoader variant={Variants.ORIGIN} marginTop={200} />
      ) : isError ? (
        <Failed refetch={refetch} />
      ) : (
        <Leaderboard
          data={data}
          updateControls={updateControls}
          controls={controls}
        />
      )}

      <OtherLeaderboardButtons variant="origin" buttons={["GUILD", "BATTLE"]} />
    </PageWrapper>
  );
};

const mapDispatchToProps = {
  updateControls,
};

const mapStateToProps = (state: RootState) => ({
  data: getOriginLeaderboardData(state),
  controls: state.originLeaderboard.controls,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OriginLeaderboard);
