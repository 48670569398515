import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Sidekick from "../molecules/Sidekick";
import Traits from "../molecules/Traits";
import CustomLink from "components/CustomLink/CustomLink";
import { getDoLLSidekickMarketplaceUrl } from "helpers/axieDoll";

const SidekickWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2.5),
  display: "grid",
  gridTemplateColumns: "340px 1fr",

  "& .sidekick": {
    width: "100%",
  },

  "& .sidekick .art": {
    width: "100% !important",
    height: "auto",
    paddingBottom: "100%",
  },

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Name = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: theme.palette.text.bright,
  fontSize: 24,
  lineHeight: 1,
}));

const Subtitle = styled(Stack)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.regular,
  fontSize: 14,
  lineHeight: 1,
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
}));

const TraitDialog = ({ open, handleClose, sidekick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          maxWidth: 700,
        },
      }}
    >
      <Stack gap={1}>
        <DialogTitle>
          <Stack gap={1} mt={2}>
            <Name variant="span">{`Sidekick #${sidekick.tokenId}`}</Name>

            <Subtitle>
              <CustomLink
                href={getDoLLSidekickMarketplaceUrl(sidekick.tokenId)}
              >
                View on Marketplace
              </CustomLink>
            </Subtitle>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <SidekickWrapper>
            <Sidekick {...sidekick} hideInfo={true} />
            <Traits parts={sidekick.parts} partRarity={sidekick.partRarity} />
          </SidekickWrapper>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default TraitDialog;
