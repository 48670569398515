import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";

const CapsuleLabel = ({ children, style }) => {
  const theme = useTheme();

  return children == null ? null : (
    <Stack
      sx={{
        background: theme.palette.feedback.red,
        borderRadius: "6px",
        p: "1px 4px",
        userSelect: "none",
        ...style,
      }}
    >
      <Typography
        fontSize={11}
        fontWeight={700}
        color="#fff"
        variant="span"
        sx={{ position: "relative", bottom: 0.5 }}
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default CapsuleLabel;
