import React from "react";
import { Tooltip } from "@mui/material";
import { connect } from "react-redux";
import {
  watchScholar,
  unwatchScholar,
} from "store/guildDashboard/actions/guildDashboardActions";
import { useWeb3React } from "@web3-react/core";
import { IconButton } from "@mui/material";
import { FaStar, FaRegStar } from "react-icons/fa";

export const WatchIcon = ({ watchScholar, unwatchScholar, scholar }) => {
  const { account } = useWeb3React();

  const handleClick = () => {
    if (scholar?.isOnWatchList === 1) {
      unwatchScholar(account, scholar.roninAddress, scholar.guildTeamId);
    } else {
      watchScholar(account, scholar.roninAddress, scholar.guildTeamId);
    }
  };
  return (
    <Tooltip
      title={`${
        scholar?.isOnWatchList === 1
          ? "Remove from watchlist"
          : "Add to watchlist"
      }`}
    >
      <IconButton
        size="small"
        onClick={handleClick}
        className="watchIcon"
        sx={{
          color:
            scholar?.isOnWatchList === 1
              ? (theme) => theme.palette.primary.main
              : (theme) => theme.palette.text.veryMuted,
        }}
      >
        {scholar?.isOnWatchList === 1 ? <FaStar /> : <FaRegStar />}
      </IconButton>
    </Tooltip>
  );
};

const mapDispatchToProps = {
  watchScholar,
  unwatchScholar,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WatchIcon);
