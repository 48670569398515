import { createSelector } from "reselect";
import {
  calculateOverallStats,
  calculateStatsForScholarGroup,
  getGuildRating,
} from "helpers/guild";
import { getTeamlessScholars, getGuildTeams } from "./common";

const extractTeamData = (teamRaw) => ({
  scholars: teamRaw.topScholars,
  name: teamRaw.name,
  slp: teamRaw.slp,
  mmr: teamRaw.mmr,
  guildTeamId: teamRaw.guildTeamId,
});

const getWorstTeam = (sortedTeams) => {
  if (sortedTeams.length < 2) {
    return null;
  }
  const lastItem = sortedTeams[sortedTeams.length - 1];

  return extractTeamData(lastItem);
};

const getBestTeam = (sortedTeams) => {
  if (sortedTeams.length < 1) {
    return null;
  }

  const firstItem = sortedTeams[0];

  return extractTeamData(firstItem);
};

export const getGuildStats = createSelector(
  [getTeamlessScholars, getGuildTeams],
  (teamlessScholars, teams) => {
    const teamStats = {};
    for (const team in teams) {
      const currentTeamStats = calculateStatsForScholarGroup(
        teams[team].scholars
      );
      teamStats[teams[team].guildTeamId] = {
        ...currentTeamStats,
        name: teams[team].name,
        guildTeamId: teams[team].guildTeamId,
        teamRating: getGuildRating(currentTeamStats.overallAverageMMR),
      };
    }
    teamStats[0] = calculateStatsForScholarGroup(teamlessScholars);
    teamStats[0].guildTeamId = 0;
    const overallStats = calculateOverallStats(teamStats);

    return {
      teamStats,
      overallStats,
      bestTeam: getBestTeam(overallStats.sortedMMRs),
      worstTeam: getWorstTeam(overallStats.sortedMMRs),
    };
  }
);
