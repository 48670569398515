import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Tooltip as MuiTooltip,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InfoButton from "./InfoButton";
import { tooltipClasses } from "@mui/material/Tooltip";
import TooltipDetails from "./TooltipDetails";

const Popup = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 160,
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "16px",
    fontSize: "12px",
    padding: "16px",
  },
}));

const Title = styled(Typography)(({ theme, width }) => ({
  width,
  fontWeight: "700",
  fontSize: "inherit",
  color: theme.palette.colors.doll.muted,
}));

const Tooltip = ({ width = 200, levelUp }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const toggleTooltip = () => {
    setOpen((state) => (state === open ? !open : open));
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const levelUpArray = Object.values(levelUp);
  const levellingAvailable = levelUpArray.filter((item) => item !== "");

  return (
    <Popup
      open={open}
      placement="right-start"
      title={
        <>
          <Stack spacing={1} fontSize="inherit">
            <Title>Skill Upgrades</Title>
            {levellingAvailable.map((item, i) => (
              <TooltipDetails levelUpItem={item} id={i + 1} key={i + 1} />
            ))}
          </Stack>
        </>
      }
    >
      <Stack
        onClick={mdDown ? toggleTooltip : null}
        onMouseEnter={mdDown ? null : handleTooltipOpen}
        onMouseLeave={mdDown ? null : handleTooltipClose}
      >
        <InfoButton width={width} />
      </Stack>
    </Popup>
  );
};

export default Tooltip;
