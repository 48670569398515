import Player from "components/BattleLog/organisms/PlayerExpanded";
import { Stack } from "@mui/material";
import { AXIE_INFINITY_BATTLES_API_FUNCTIONAL } from "config";
import BattlesNotFunctional from "components/BattleLog/atoms/BattlesNotFunctional";
import NoneFoundMessage from "components/Typography/NoneFoundMessage";
import { fetchAxieData } from "store/data/dataActions";
import { connect } from "react-redux";

const FavoriteTeam = ({
  favoriteTeam,
  fetchAxieData,
  disableElevation = false,
}) => {
  if (favoriteTeam == null || favoriteTeam.length === 0) {
    return <NoneFoundMessage fontSize={20}>No favorite team</NoneFoundMessage>;
  }

  if (!AXIE_INFINITY_BATTLES_API_FUNCTIONAL) {
    return <BattlesNotFunctional />;
  }

  return (
    <Stack gap={4} flexWrap="wrap">
      {favoriteTeam.map((team, i) => (
        <Player
          axies={team}
          detailAxieView={true}
          fetchAxieData={fetchAxieData}
          key={`FAVORITE_TEAM_${i}`}
          disableElevation={disableElevation}
        />
      ))}
    </Stack>
  );
};

const mapDispatchToProps = {
  fetchAxieData,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteTeam);
