import { useState, useEffect, useMemo, createContext } from "react";
import "./styles/styles.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { StyledEngineProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { Web3ReactProvider } from "@web3-react/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getDesignTokens } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import CookieConsentBanner from "./components/Utility/CookieConsentBanner";
import { fetchAxieCards, fetchAxieCardsV3 } from "./store/data/dataActions";
import { Web3Provider } from "@ethersproject/providers";
import { ThemeMode } from "types";
import { useDispatch } from "react-redux";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

const queryClient = new QueryClient();

function getLibrary(provider: any) {
  return new Web3Provider(provider);
}

const App = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState<ThemeMode>(ThemeMode.light);

  useEffect(() => {
    dispatch(fetchAxieCards());
    dispatch(fetchAxieCardsV3());
  }, [dispatch]);

  useEffect(() => {
    const savedMode = localStorage.getItem("mode");
    if (savedMode) {
      savedMode === "light"
        ? setMode(ThemeMode.light)
        : setMode(ThemeMode.dark);
    } else {
      setMode(ThemeMode.light);
      localStorage.setItem("mode", ThemeMode.light);
    }
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode =
            prevMode === "light" ? ThemeMode.dark : ThemeMode.light;
          localStorage.setItem("mode", newMode);
          return newMode;
        });
      },
      setColorMode: (newMode: ThemeMode) => {
        setMode(newMode);
      },
    }),
    []
  );
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
              <Router>
                <CssBaseline />
                <Routes />
                <CookieConsentBanner />
              </Router>
            </StyledEngineProvider>
          </QueryClientProvider>
        </Web3ReactProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
