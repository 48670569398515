import React from "react";
import CsvDownload from "react-json-to-csv";
import { getDateString } from "helpers/display";
import { Button } from "@mui/material";
import fonts from "theme/fonts";

const CsvDownloadButton = ({ lockedResultJSON }) => {
  return (
    <CsvDownload
      data={lockedResultJSON}
      filename={`locked-breeding-pairs-${getDateString(new Date())}.csv`}
      style={{
        background: "transparent",
        padding: 0,
        border: "none",
        fontWeight: 700,
        fontFamily: fonts.body,
      }}
    >
      <Button variant="contained">Export to CSV</Button>
    </CsvDownload>
  );
};

export default CsvDownloadButton;
