import { matchesDateRange } from "helpers/display";
import moment from "moment";
import { createSelector } from "reselect";

const getSlpIssuance = (state) => {
  return state.charts?.slpIssuance.data || [];
};

const getSlpIssuanceBlockchainControls = (state) => {
  return state.charts?.slpIssuance.blockchain.controls || {};
};

const getSlpIssuanceInGameControls = (state) => {
  return state.charts?.slpIssuance.inGame.controls || {};
};

const getSlpIssuanceLoading = (state) => {
  return state.charts?.slpIssuance.fetching;
};

export const updateMin = (newValue, currentMin) => {
  if (newValue < currentMin) {
    currentMin = newValue;
  }
  return currentMin;
};

export const updateMax = (newValue, currentMax) => {
  if (newValue > currentMax) {
    currentMax = newValue;
  }
  return currentMax;
};

const sortByDate = (a, b) => {
  const aTimestamp = moment(a.date).unix();
  const bTimestamp = moment(b.date).unix();
  return aTimestamp < bTimestamp ? -1 : aTimestamp > bTimestamp ? 1 : 0;
};

export const makeSlpIssuanceBlockchainChartData = createSelector(
  [getSlpIssuance, getSlpIssuanceBlockchainControls, getSlpIssuanceLoading],
  (slpIssuance, controls, loading) => {
    const sortedSlpData = [...slpIssuance].sort((a, b) => sortByDate(a, b));
    const slpDataForDateRange = matchesDateRange(
      sortedSlpData,
      controls.startDate,
      controls.endDate
    );
    let totalMinted = 0;
    let totalBurnt = 0;

    const stacked = {
      labels: [],
      datasets: {
        slpBurnt: {
          label: "SLP Burnt",
          data: [],
        },
        slpMinted: {
          label: "SLP Minted",
          data: [],
        },
      },
      domain: {
        y: {
          min: Infinity,
          max: -Infinity,
        },
      },
    };

    const positiveNegative = {
      labels: [],
      datasets: {
        slpMinted: {
          label: "SLP Minted",
          data: [],
        },
        slpBurnt: {
          label: "SLP Burnt",
          data: [],
        },
      },
      domain: {
        y: {
          min: Infinity,
          max: -Infinity,
        },
      },
    };

    const net = {
      labels: [],
      datasets: {
        slpNet: {
          label: "Net SLP Issued",
          data: [],
        },
      },
      domain: {
        y: {
          min: Infinity,
          max: -Infinity,
        },
      },
    };

    for (const d of slpDataForDateRange) {
      if (d.slpBurnt != null && d.slpMinted != null) {
        totalBurnt += d.slpBurnt;
        totalMinted += d.slpMinted;

        // Stacked Chart Type
        stacked.labels.push(d.date);
        stacked.datasets.slpBurnt.data.push(d.slpBurnt);
        stacked.datasets.slpMinted.data.push(d.slpMinted);
        stacked.domain.y.min = updateMin(d.slpBurnt, stacked.domain.y.min);
        stacked.domain.y.min = updateMin(d.slpMinted, stacked.domain.y.min);
        stacked.domain.y.max = updateMax(d.slpBurnt, stacked.domain.y.max);
        stacked.domain.y.max = updateMax(d.slpMinted, stacked.domain.y.max);

        // Net Chart Type
        const netIssued = d.slpMinted - d.slpBurnt;
        net.labels.push(d.date);
        net.datasets.slpNet.data.push(netIssued);
        net.domain.y.min = updateMin(netIssued, net.domain.y.min);
        net.domain.y.max = updateMax(netIssued, net.domain.y.max);

        // Positive/Negative Chart Type
        positiveNegative.labels.push(d.date);
        positiveNegative.datasets.slpBurnt.data.push(-1 * d.slpBurnt);
        positiveNegative.datasets.slpMinted.data.push(d.slpMinted);
        positiveNegative.domain.y.min = updateMin(
          -1 * d.slpBurnt,
          positiveNegative.domain.y.min
        );
        positiveNegative.domain.y.min = updateMin(
          d.slpMinted,
          positiveNegative.domain.y.min
        );
        positiveNegative.domain.y.max = updateMax(
          -1 * d.slpBurnt,
          positiveNegative.domain.y.max
        );
        positiveNegative.domain.y.max = updateMax(
          d.slpMinted,
          positiveNegative.domain.y.max
        );
      }
    }

    return {
      data: {
        stacked,
        dual: stacked,
        net,
        positiveNegative,
        controls,
        title: "SLP Burnt & Minted on the Blockchain",
        footnote:
          "SLP spent on breeding and SLP moved into/out of the game via the Origins portal.",
      },
      total: {
        burnt: totalBurnt.toLocaleString(),
        minted: totalMinted.toLocaleString(),
      },
      loading,
    };
  }
);

export const makeSlpIssuanceInGameChartData = createSelector(
  [getSlpIssuance, getSlpIssuanceInGameControls, getSlpIssuanceLoading],
  (slpIssuance, controls, loading) => {
    const sortedSlpData = [...slpIssuance].sort((a, b) => sortByDate(a, b));
    const slpDataForDateRange = matchesDateRange(
      sortedSlpData,
      controls.startDate,
      controls.endDate
    );
    let totalBurnt = 0;

    const bar = {
      labels: [],
      datasets: {
        slpBurnt: {
          label: "SLP Spent on Crafting",
          data: [],
        },
      },
      domain: {
        y: {
          min: 0,
          max: 0,
        },
      },
    };

    for (const d of slpDataForDateRange) {
      if (d.slpBurntInGame != null) {
        totalBurnt += d.slpBurntInGame;

        bar.labels.push(d.date);
        bar.datasets.slpBurnt.data.push(d.slpBurntInGame);
        bar.domain.y.min = updateMin(d.slpBurntInGame, bar.domain.y.min);
        bar.domain.y.max = updateMax(d.slpBurntInGame, bar.domain.y.max);
      }
    }

    return {
      data: {
        bar,
        controls,
        title: "SLP Spent Crafting In-Game",
        footnote: "",
      },
      loading,
      total: {
        spent: totalBurnt.toLocaleString(),
      },
    };
  }
);
