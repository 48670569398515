import React from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const Search = ({ placeholder, width = 300, searchText, updateSearchText }) => {
  return (
    <TextField
      hiddenLabel
      value={searchText}
      onChange={updateSearchText}
      variant="filled"
      autoComplete="off"
      spellCheck={false}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            position="end"
            onClick={() =>
              updateSearchText({
                target: {
                  value: "",
                },
              })
            }
          >
            <IoCloseCircle />
          </IconButton>
        ),
      }}
      sx={{
        "& .MuiFilledInput-root": {
          borderRadius: 40,
          background: searchText === "" ? "rgba(255,255,255,0.2)" : "#fff",
          width: "100%",
          maxWidth: width,
          border: "none",
          padding: "0 20px",

          "& .MuiInputAdornment-root": {
            color: searchText === "" ? "#fff" : "#000",
          },

          "& .MuiIconButton-root": {
            color: "transparent",
          },

          "&:hover, &:active, &.Mui-focused": {
            boxShadow: "none",
            border: "none",
            background: "#fff",

            "& .MuiInputAdornment-root, & .MuiIconButton-root": {
              color: "#000",
            },
          },

          "&::after, &::before": {
            display: "none",
          },
        },
        "& .MuiFilledInput-input": {
          background: "transparent",
          boxShadow: "none",
          border: "none",
          paddingLeft: "8px",
          color: "#000",

          "&::placeholder": {
            color: "#fff",
            opacity: 0.5,
          },

          "&:focus": {
            background: "transparent",
            "&::placeholder": {
              color: (theme) => theme.palette.colors.doll.muted,
              opacity: 0.5,
            },
          },

          "&:hover": {
            "&::placeholder": {
              color: (theme) => theme.palette.colors.doll.muted,
              opacity: 0.5,
            },
          },
        },
      }}
    />
  );
};

export default Search;
