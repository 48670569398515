import { createSelector } from "reselect";
import {
  augmentBattleData,
  createRankNumString,
  createRankTierString,
  getBattleStats,
  getBattleSummary,
  getFavoriteTeams,
  getVictoryStarsChartData,
} from "helpers/arena";
import {
  getAllCharms,
  getAllRunes,
  getAxieData,
} from "store/data/dataSelectors";
import { getPlayerLastActive } from "helpers/arena";

const getPlayerRaw = (state) => {
  return state.player.data || {};
};

const getPlayerBattlesRaw = (state) => {
  return state.player.battles.data || [];
};

const getPlayerBattlesPage = (state) => {
  return state.player.controls.currentPageBattles || 1;
};

export const getPlayer = createSelector(
  [getPlayerRaw, getPlayerBattlesRaw],
  (player, battlesRaw) => {
    return {
      ...player,
      topRank: createRankNumString(player.topRank),
      rankTier: createRankTierString(player.rank, player.tier),
      lastActive: getPlayerLastActive(battlesRaw[0]),
      battleStats: getBattleStats(battlesRaw),
    };
  }
);

export const getPlayerBattles = createSelector(
  [
    getPlayerBattlesRaw,
    getPlayerBattlesPage,
    getAllRunes,
    getAllCharms,
    getAxieData,
  ],
  (battlesRaw, battlesPage, allRunes, allCharms, axieData) => {
    const battles = augmentBattleData(
      battlesRaw,
      allRunes,
      allCharms,
      axieData
    );
    const battleSummary = getBattleSummary(battles, battlesPage);
    const recentBattleSummary = getBattleSummary(battles.slice(0, 3), 1);

    return {
      battleSummary,
      recentBattleSummary,
    };
  }
);

export const getPlayerFavoriteTeams = createSelector(
  [getPlayerBattlesRaw, getAllRunes, getAllCharms, getAxieData],
  (battlesRaw, allRunes, allCharms, axieData) => {
    const battles = augmentBattleData(
      battlesRaw,
      allRunes,
      allCharms,
      axieData
    );

    return getFavoriteTeams(battles);
  }
);

export const getPlayerVictoryStarChart = createSelector(
  [getPlayerBattlesRaw],
  (battlesRaw) => {
    return getVictoryStarsChartData(battlesRaw);
  }
);
