import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const FilterAxiePrice = ({ price, setPrice, label }) => {
  return price != null && setPrice ? (
    <TextField
      onChange={(e) => setPrice(e.target.value)}
      label={label}
      type="number"
      variant="filled"
      size="small"
      fullWidth
      value={price}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <strong>Ξ</strong>
          </InputAdornment>
        ),
        disableUnderline: true,
        inputProps: {
          min: 0,
          step: ".01",
        },
      }}
    />
  ) : (
    <></>
  );
};

export default FilterAxiePrice;
