import React from "react";
import { Typography } from "@mui/material";

const SectionTitle = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize={14}
      fontWeight={600}
      color="text.regular"
    >
      {children}
    </Typography>
  );
};

export default SectionTitle;
