import React from "react";
import { Select, InputLabel, MenuItem, FormControl } from "@mui/material";

function isObject(o) {
  return o instanceof Object && o.constructor === Object;
}

export const CustomSelect = ({
  val,
  setVal,
  label,
  options,
  cls,
  style,
  hiddenLabel,
  fullWidth,
  formStyle,
  variant = "filled",
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      size="small"
      className={cls ? cls : ""}
      sx={formStyle}
    >
      {label ? <InputLabel id="select-label">{label}</InputLabel> : <></>}
      <Select
        labelId="select-label"
        value={val}
        onChange={(e) => setVal(e.target.value)}
        label={label}
        sx={style}
      >
        {options.map((option) => (
          <MenuItem
            key={isObject(option) && "value" in option ? option.value : option}
            value={
              isObject(option) && "value" in option ? option.value : option
            }
          >
            {isObject(option) && "label" in option ? option.label : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
