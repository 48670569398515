import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import Counter from "./Counter";

const BreedCount = ({ axie, color, width }) => {
  const theme = useTheme();

  if (axie.breedCount == null) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={(0.5 / 320) * width}
      sx={{
        py: `${(4 / 320) * width}px`,
        px: `${(6 / 320) * width}px`,
        borderRadius: `${(6 / 320) * width}px`,
        border: `1px solid ${theme.palette.card.divider}`,
        background: theme.palette.card.background,
      }}
    >
      <Typography
        variant="span"
        fontSize={(12 / 320) * width}
        fontWeight={600}
        color="text.muted"
        sx={{ lineHeight: 1, whiteSpace: "nowrap", width: (52 / 320) * width }}
      >
        {`${axie.breedCount} Breeds`}
      </Typography>
      <Counter filled={axie.breedCount} total={7} color={color} width={width} />
    </Stack>
  );
};

export default BreedCount;
