import { Button, Stack } from "@mui/material";
import React from "react";
import Search from "pages/GuildDashboard/atoms/Search";
import { IoEye, IoEyeOff } from "react-icons/io5";

const Controls = ({
  searchQuery,
  updateSearchQuery,
  showInactive,
  toggleShowInactive,
}) => {
  return (
    <Stack direction="row" justifyContent="flex-end" gap={2}>
      <Button
        onClick={() => toggleShowInactive(!showInactive)}
        variant="contained"
        size="small"
        startIcon={showInactive ? <IoEyeOff /> : <IoEye />}
      >
        {showInactive ? "Hide Inactive" : "Show Inactive"}
      </Button>
      <Search
        style={{
          alignItems: "flex-end",
          "& .MuiOutlinedInput-root": { maxWidth: 200 },
        }}
        value={searchQuery}
        updateValue={updateSearchQuery}
        placeholder="Search"
      />
    </Stack>
  );
};

export default Controls;
