import React from "react";
import CardSlot from "pages/TeamBuilder/atoms/CardSlot";
import CharmSlot from "pages/TeamBuilder/atoms/CharmSlot";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import BodyPartIcon from "./BodyPartIcon";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: "8px 0",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
  },
}));

const Part = ({
  part,
  cardClass,
  axie,
  updateCard,
  updateCharm,
  view,
  handleOpenCardDialog,
  handleOpenCharmDialog,
}) => {
  return (
    <Wrapper>
      <BodyPartIcon
        part={part}
        cardClass={cardClass}
        handleOpenCardDialog={handleOpenCardDialog}
      />
      <Stack gap={1} sx={{ height: "100%" }}>
        <CardSlot
          axie={axie}
          data={axie.parts[part].card}
          part={part}
          updateCard={updateCard}
          handleOpenCardDialog={handleOpenCardDialog}
          updateCharm={updateCharm}
          view={view}
        />
        <CharmSlot
          axie={axie}
          data={axie.parts[part].charm}
          part={part}
          updateCharm={updateCharm}
          isCardSet={cardClass != null}
          cardClass={cardClass}
          handleOpenCharmDialog={handleOpenCharmDialog}
          view={view}
        />
      </Stack>
    </Wrapper>
  );
};

export default Part;
