import { Stack, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { FaDiscord, FaTwitter } from "react-icons/fa";

const SocialIcons = ({
  gap = 1,
  size = 20,
  centered,
  color,
  twitter,
  discord,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={gap}
      justifyContent={centered ? "center" : "flex-start"}
    >
      <Link to={twitter} target="_blank">
        <IconButton sx={{ fontSize: size, color: color }}>
          <FaTwitter />
        </IconButton>
      </Link>
      <Link to={discord} target="_blank">
        <IconButton sx={{ fontSize: size, color: color }}>
          <FaDiscord />
        </IconButton>
      </Link>
    </Stack>
  );
};

export default SocialIcons;
