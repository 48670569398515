import styled from "@emotion/styled";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { GiAxeSword } from "react-icons/gi";
import InterDungeonTooltip from "components/InterDungeon/InterDungeonCard/atoms/InterDungeonTooltip";

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "width" })(
  ({ width = 200 }) => ({
    display: "inline-flex",
    width: `${(width / 200) * 42}px`,
    minWidth: `${(width / 200) * 42}px`,
    height: `${(width / 200) * 42}px`,
    minHeight: `${(width / 200) * 42}px`,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
  })
);

const CombatValue = ({ value, name, width }) => {
  const theme = useTheme();
  // filter out zero combat values?? some undefined?? ar they meant to have a value property??
  // Mech suit and Lasso missing value

  if (!value) {
    return (
      <Wrapper
        sx={{
          background: "transparent",
        }}
        width={width}
      />
    );
  }

  return (
    <InterDungeonTooltip
      title={
        <>
          <GiAxeSword fontSize={20} />
          <br />
          {name} adds {value} to your Battle Score
        </>
      }
    >
      <Wrapper
        sx={{
          background: theme.palette.colors.interDungeon.cardElements.lightBg,
        }}
        width={width}
      >
        <Stack direction="row" alignItems="flex-end" justifyContent="center">
          <Typography
            variant="span"
            sx={{
              color: theme.palette.colors.interDungeon.cardElements.darkBg,
              fontSize: (width / 200) * 20,
              fontWeight: 700,
            }}
          >
            {value}
          </Typography>
        </Stack>
      </Wrapper>
    </InterDungeonTooltip>
  );
};

export default CombatValue;
