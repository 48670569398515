import React from "react";
import Inputs from "./Inputs";
import Summary from "./Summary";
import PageHeader from "components/PageHeader/PageHeader";
import ProfitLoss from "./ProfitLoss";
import { connect } from "react-redux";
import { fetchCurrencyData } from "helpers/actions";
import { REACT_QUERY_STALE_TIME } from "config";
import { useQuery } from "react-query";
import FailedMessage from "components/FailedMessage/FailedMessage";
import CustomLoader from "components/CustomLoader/CustomLoader";
import * as breedingCalculatorSelectors from "store/breedingCalculator/breedingCalculatorSelectors";
import * as breedingCalculatorActions from "store/breedingCalculator/breedingCalculatorActions";
import styles from "./inputs.module.scss";
import PullUp from "components/Utility/PullUp";
import Page from "components/Utility/Page";
import { Stack, useTheme } from "@mui/material";

const BreedingCalculator = ({
  firstLoad,
  loadBreedingCalculator,
  inputs,
  summary,
  updateBreedingCalculatorInputs,
  message,
}) => {
  const theme = useTheme();
  const { data, status } = useQuery("currency", fetchCurrencyData, {
    staleTime: REACT_QUERY_STALE_TIME,
  });

  if (status === "loading") {
    return (
      <>
        <PageHeader title="Breeding Calculator" />
        <CustomLoader marginTop={200} marginBottom={200} />
      </>
    );
  }

  if (!status || status === "error") {
    return <FailedMessage text="Cannot load price data" />;
  }

  const calculatePriceForNTokens = (n, token, abbrev, currency) => {
    if (inputs.inventory.useMarketPrice[abbrev]) {
      return n * data[token][currency];
    }
    return n * inputs.inventory.basisCosts[abbrev][currency];
  };

  const slpCostETH = calculatePriceForNTokens(
    summary.shoppingList.slp.toBuy,
    "smooth-love-potion",
    "slp",
    "eth"
  );
  const slpCostUSD = calculatePriceForNTokens(
    summary.shoppingList.slp.toBuy,
    "smooth-love-potion",
    "slp",
    "usd"
  );

  const axsCostETH = calculatePriceForNTokens(
    summary.shoppingList.axs.toBuy,
    "axie-infinity",
    "axs",
    "eth"
  );
  const axsCostUSD = calculatePriceForNTokens(
    summary.shoppingList.axs.toBuy,
    "axie-infinity",
    "axs",
    "usd"
  );

  const totalRawMaterialsCostsETH = slpCostETH + axsCostETH;
  const totalCostsETH = slpCostETH + axsCostETH + summary.totalParentCostsETH;
  const avgCostPerAxieETH = totalCostsETH / inputs.numberOfBreeds;

  const totalProfitLossETH = summary.totalSalesETH - totalCostsETH;
  const profitPercent = totalProfitLossETH / totalCostsETH;

  return (
    <>
      <PageHeader
        title="Breeding Calculator"
        description="Estimate the total costs and return on investment for breeding your axies."
        disclaimer="Remember, breeding is risky and there are many factors to consider. The results of this calculator are not financial advice. Please do your own research."
        centered
      />
      <div className={styles.outerCenteredWrapper}>
        <PullUp>
          <Stack
            gap={4}
            sx={{
              p: 2,
              borderRadius: "8px",
              background: theme.palette.card.background,
              boxShadow: theme.palette.card.boxShadow,
            }}
          >
            <Inputs
              updateBreedingCalculatorInputs={updateBreedingCalculatorInputs}
              firstLoad={firstLoad}
              loadBreedingCalculator={loadBreedingCalculator}
              currencies={data}
            />
            <ProfitLoss
              currencies={data}
              totalProfitLossETH={totalProfitLossETH}
              totalProfitLossUSD={totalProfitLossETH * data["ethereum"]["usd"]}
              profitPercent={profitPercent}
              message={message}
              firstLoad={firstLoad}
            />
          </Stack>
        </PullUp>
      </div>
      <Page>
        {!message && !firstLoad && (
          <Summary
            inputs={inputs}
            summary={summary}
            currencies={data}
            slpCostETH={slpCostETH}
            slpCostUSD={slpCostUSD}
            axsCostETH={axsCostETH}
            axsCostUSD={axsCostUSD}
            totalRawMaterialsCostsETH={totalRawMaterialsCostsETH}
            totalCostsETH={totalCostsETH}
            avgCostPerAxieETH={avgCostPerAxieETH}
            avgCostPerAxieUSD={avgCostPerAxieETH * data["ethereum"]["usd"]}
          />
        )}
      </Page>
    </>
  );
};

const mapDispatchToProps = {
  updateBreedingCalculatorInputs:
    breedingCalculatorActions.updateBreedingCalculatorInputs,
  loadBreedingCalculator: breedingCalculatorActions.loadBreedingCalculator,
};

const mapStateToProps = (state) => {
  return {
    inputs: state.breedingCalculator.inputs,
    firstLoad: state.breedingCalculator.firstLoad,
    summary: breedingCalculatorSelectors.generateBreedingSummary(state),
    message: breedingCalculatorSelectors.breedingSummaryMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreedingCalculator);
