import React from "react";
import { Stack, Tooltip } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import SectionLabel from "../atoms/SectionLabel";
import Text from "components/Typography/Text";

const OtherInfo = ({ birthday, owner }) => {
  return (
    <Stack gap={1.5} sx={{ width: "100%" }}>
      <SectionLabel>Other Info</SectionLabel>
      <Info label="Birthday" value={<Text>{birthday}</Text>} />
      <Info
        label="Owner"
        value={
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: 600,
                },
              },
            }}
            title={
              <Stack
                sx={{
                  whiteSpace: "nowrap",
                  "& > *": { whiteSpace: "nowrap" },
                }}
              >
                {owner.ronin}
              </Stack>
            }
          >
            <CustomLink href={owner.marketplaceUrl}>
              <Text>{owner.roninShort}</Text>
            </CustomLink>
          </Tooltip>
        }
      />
    </Stack>
  );
};

const Info = ({ label, value }) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Text color="text.bright">{label}</Text>
    {value}
  </Stack>
);

export default OtherInfo;
