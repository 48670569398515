import React, { useState } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Art from "../atoms/Art";
import Info from "../atoms/Info";

const Wrapper = styled(Stack)(({ theme, width }) => ({
  width,
  borderRadius: `${(width / 200) * 12}px `,
  overflow: "hidden",
  background: "#fff",
  filter: `drop-shadow(0px ${(width / 200) * 2}px ${
    (width / 200) * 5
  }px rgba(6, 26, 30, 0.18))`,
  transition: "0.2s filter ease",

  "&:hover": {
    filter: `drop-shadow(0px ${(width / 200) * 2}px ${
      (width / 200) * 10
    }px rgba(6, 26, 30, 0.3))`,
  },
}));

const Sidekick = ({
  tokenId,
  assets,
  hideInfo,
  handleInspectSidekick,
  width = 200,
}) => {
  const [artToDisplay, setArtToDisplay] = useState(assets?.bg);

  const handleOnMouseEnter = () => {
    setArtToDisplay(assets?.gif);
  };

  const handleOnMouseLeave = () => {
    setArtToDisplay(assets?.bg);
  };

  return (
    <Wrapper
      className="sidekick"
      width={width}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={() => {
        if (!hideInfo) {
          handleInspectSidekick(tokenId);
        }
      }}
    >
      <Art toDisplay={artToDisplay} staticImg={assets?.bg} width={width} />
      {!hideInfo && <Info tokenId={tokenId} width={width} />}
    </Wrapper>
  );
};

export default Sidekick;
