import React from "react";
import { Stack } from "@mui/material";
import PageTitle from "../atoms/PageTitle";
import CurrencyPrices from "../molecules/CurrencyPrices";
import PageSuperTitle from "../atoms/PageSuperTitle";

const DashboardHeader = ({
  currencyPrices,
  title,
  superTitle,
  baseCurrency,
  children,
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      direction="row"
      gap={2}
    >
      <Stack gap={2} alignItems="center" direction="row">
        <Stack gap={1}>
          <PageSuperTitle>{superTitle}</PageSuperTitle>
          <PageTitle>{title}</PageTitle>
        </Stack>
        {children}
      </Stack>
      <CurrencyPrices
        currencyPrices={currencyPrices}
        baseCurrency={baseCurrency}
      />
    </Stack>
  );
};

export default DashboardHeader;
