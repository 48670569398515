import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Typography,
  Stack,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import { BsFillInfoCircleFill } from "react-icons/bs";
import CharmTemplate from "components/GameTemplates/CharmTemplate";
import RuneTemplate from "components/GameTemplates/RuneTemplate";
import CardTemplate from "components/GameTemplates/CardTemplate";
import { connect } from "react-redux";
import fonts from "theme/fonts";
import { getAllV3Data } from "store/data/dataSelectors";

const InfoDialog = ({ open, setOpen, type, data }) => {
  const theme = useTheme();

  if (type == null) {
    return null;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const infoMapping = {
    card: {
      title: "Card Info",
      description:
        "Each Axie has 6 different Cards that determine its abilities in battle.",
      diagram: <CardDiagram data={data} />,
    },
    curse: {
      title: "Curse Info",
      description:
        "Curse Cards are added to your Opponent's deck to cause negative effects over time.",
      diagram: <CurseDiagram data={data} />,
    },
    tool: {
      title: "Tool Info",
      description:
        "Tool Cards are added to your deck. They come with a variety of different benefits.",
      diagram: <ToolDiagram data={data} />,
    },
    rune: {
      title: "Rune Info",
      description:
        "Runes are special wearable items that provide passive effects that automatically activate in battle. You can equip 1 Rune per Axie.",
      diagram: <RuneDiagram data={data} />,
    },
    charm: {
      title: "Charm Info",
      description:
        "Charms are special wearable items that provide bonus effects to Axie abilities. You can equip 1 Charm per Card.",
      diagram: <CharmDiagram data={data} />,
    },
    revenge: {
      title: "Revenge Card Info",
      description:
        "When one of your Axies dies, all of its cards in your Hand and in the Draw/Discard Piles disappear and the same amount of Revenge Cards (up to 4) are shuffled into the Discard Pile. Since all Revenge Cards have Ethereal and Banish Tags, they will be discarded at the end of your Turn regardless of whether you choose to play them.",
      diagram: <RevengeCardDiagram data={data} />,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="info-dialog"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: 600,
          background: theme.palette.colors.origin.wood.background,
        },
      }}
    >
      <DialogTitle
        id="info-dialog"
        sx={{ color: theme.palette.colors.origin.wood.text }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <BsFillInfoCircleFill />
          {infoMapping[type].title}
        </Stack>
      </DialogTitle>

      <DialogContent dividers={true}>
        <Stack gap={4}>
          <Typography variant="body2" color="#fff">
            {infoMapping[type].description}
          </Typography>

          {data != null && infoMapping[type].diagram}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button variant="origin" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CardDiagram = ({ data }) => {
  const card = data.cards.find((c) => c.cardName === "Buzz Buzz");

  if (card == null) {
    return null;
  }

  const features = [
    { n: 1, title: "Card Name", left: "46%", top: "51%" },
    { n: 2, title: "Description", left: "72%", top: "86%" },
    { n: 3, title: "Tags", left: "66%", top: "65%" },
    { n: 4, title: "Body Part", left: "13%", top: "52%" },
    {
      n: 5,
      title: "Card Stats",
      description: "Damage, Shield and/or Heal",
      left: "52%",
      top: "8%",
    },
    { n: 6, title: "Energy Cost", left: "17%", top: "16%" },
    { n: 7, title: "Class", left: "37%", top: "91%" },
  ];
  return (
    <Stack gap={4} direction="row" alignItems="center" flexWrap="wrap">
      <Box sx={{ position: "relative" }}>
        <CardTemplate card={card} width={250} />
        <Labels features={features} />
      </Box>
      <FeaturesList features={features} />
    </Stack>
  );
};

const RevengeCardDiagram = ({ data }) => {
  const card = data.revengeCards.find(
    (c) => c.name === "The Fallen's Longsword"
  );

  if (card == null) {
    return null;
  }

  const features = [
    { n: 1, title: "Card Name", left: "46%", top: "51%" },
    { n: 2, title: "Description", left: "62%", top: "84%" },
    { n: 3, title: "Tags", left: "83%", top: "65%" },
    {
      n: 4,
      title: "Card Stats",
      description: "Damage, Shield and/or Heal",
      left: "52%",
      top: "8%",
    },
    { n: 5, title: "Energy Cost", left: "17%", top: "16%" },
  ];
  return (
    <Stack gap={4} direction="row" alignItems="center" flexWrap="wrap">
      <Box sx={{ position: "relative" }}>
        <CardTemplate card={card} width={250} />
        <Labels features={features} />
      </Box>
      <FeaturesList features={features} />
    </Stack>
  );
};

const CurseDiagram = ({ data }) => {
  const curse = data.curseCards.find((c) => c.name === "Burn");

  if (curse == null) {
    return null;
  }

  const features = [
    { n: 1, title: "Curse Name", left: "37%", top: "52%" },
    { n: 2, title: "Description", left: "77%", top: "86%" },
    { n: 3, title: "Tags", left: "83%", top: "59%" },
  ];
  return (
    <Stack gap={4} direction="row" alignItems="center" flexWrap="wrap">
      <Box sx={{ position: "relative" }}>
        <CardTemplate card={curse} width={250} />
        <Labels features={features} />
      </Box>
      <FeaturesList features={features} />
    </Stack>
  );
};

const ToolDiagram = ({ data }) => {
  const tool = data.toolCards.find((c) => c.name === "Strawberry");

  if (tool == null) {
    return null;
  }

  const features = [
    { n: 1, title: "Tool Name", left: "27%", top: "51%" },
    { n: 2, title: "Description", left: "66%", top: "82%" },
    { n: 3, title: "Tags", left: "78%", top: "64%" },
    {
      n: 4,
      title: "Card Stats",
      description: "Damage, Shield and/or Heal",
      left: "52%",
      top: "8%",
    },
  ];
  return (
    <Stack gap={4} direction="row" alignItems="center" flexWrap="wrap">
      <Box sx={{ position: "relative" }}>
        <CardTemplate card={tool} width={250} />
        <Labels features={features} />
      </Box>
      <FeaturesList features={features} />
    </Stack>
  );
};

const RuneDiagram = ({ data }) => {
  const rune = data.runes.find((c) => c.name === "Leftover Potion");

  if (rune == null) {
    return null;
  }

  const features = [
    { n: 1, title: "Rune Name", left: "15%", top: "40%" },
    { n: 2, title: "Description", left: "66%", top: "76%" },
    { n: 3, title: "Class", left: "27%", top: "3%" },
  ];
  return (
    <Stack gap={4} direction="row" alignItems="center" flexWrap="wrap">
      <Box sx={{ position: "relative" }}>
        <RuneTemplate rune={rune} width={250} />
        <Labels features={features} />
      </Box>
      <FeaturesList features={features} />
    </Stack>
  );
};

const CharmDiagram = ({ data }) => {
  const charm = data.charms.find((c) => c.name === "Charm of Faith III");

  if (charm == null) {
    return null;
  }

  const features = [
    { n: 1, title: "Charm Name", left: "70%", top: "53%" },
    { n: 2, title: "Description", left: "66%", top: "81%" },
    { n: 3, title: "Class", left: "27%", top: "3%" },
    { n: 4, title: "Potential Cost", left: "11%", top: "33%" },
  ];
  return (
    <Stack gap={4} direction="row" alignItems="center" flexWrap="wrap">
      <Box sx={{ position: "relative" }}>
        <CharmTemplate charm={charm} width={250} />
        <Labels features={features} />
      </Box>
      <FeaturesList features={features} />
    </Stack>
  );
};

const Labels = ({ features }) =>
  features.map((feature, i) => (
    <Label
      key={`LABEL_${i}`}
      n={feature.n}
      title={feature.title}
      left={feature.left}
      top={feature.top}
    />
  ));

const FeaturesList = ({ features }) => (
  <Stack gap={2}>
    {features.map((feature, i) => (
      <Feature
        n={feature.n}
        title={feature.title}
        description={feature.description}
      />
    ))}
  </Stack>
);

const Feature = ({ n, title, description }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography
        variant="span"
        fontSize={18}
        fontFamily={fonts.changa}
        color={theme.palette.colors.origin.yellow}
        sx={{ position: "relative", bottom: 1 }}
      >
        {n}
      </Typography>
      <Stack gap={0.5}>
        <Typography
          variant="span"
          fontSize={16}
          color="#fff"
          fontWeight={600}
          sx={{ position: "relative", bottom: 1 }}
        >
          {title}
        </Typography>
        {description != null && (
          <Typography
            variant="span"
            fontSize={12}
            color="#fff"
            sx={{ position: "relative", bottom: 1 }}
          >
            {description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const Label = ({ n, title, left, top }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#fff",
            color: "#3A3A3A",
            fontWeight: "bold",
            fontSize: 14,
          },
        },
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          background: theme.palette.colors.origin.yellow,
          borderRadius: "50%",
          fontSize: 22,
          width: 30,
          height: 30,
          cursor: "pointer",
          zIndex: 8,
          transition: "0.2s transform ease",
          left,
          top,
          boxShadow: "0px 0px 8px rgba(0,0,0,0.8)",
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      >
        <Typography
          variant="span"
          fontSize={22}
          fontFamily={fonts.changa}
          color="#3A3A3A"
          sx={{ position: "relative", bottom: 1 }}
        >
          {n}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  data: getAllV3Data(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoDialog);
