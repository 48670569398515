import React from "react";
import { Stack } from "@mui/material";
import IconMessage from "components/IconMessage/IconMessage";
import { RiErrorWarningFill } from "react-icons/ri";
import {
  fetchAxie,
  updateRequiredBreedingId,
} from "store/breedingSimulator/breedingSimulatorActions";
import { connect } from "react-redux";
import Parents from "./Parents";
import ResultWrapper from "../atoms/ResultWrapper";
import CostsAndPurity from "./CostsAndPurity";
import PartProbabilities from "./PartProbabilities";

const BreedingResult = ({
  data,
  parentData,
  parentIds,
  toggleLockPair,
  locked,
  deleteBreedingAxiePair,
  handleDeleteId,
  numberOfBreeds,
  updateNumberOfBreedsForPair,
  fetchAxie,
  requiredId,
  updateRequiredBreedingId,
  hideLock,
}) => {
  const handleRefreshAxie = (axieId) => {
    fetchAxie(axieId);
  };
  return (
    <ResultWrapper>
      <Stack gap={1}>
        <Parents
          data={parentData}
          parentIds={parentIds}
          handleDeleteId={handleDeleteId}
          toggleLockPair={toggleLockPair}
          locked={locked}
          deleteBreedingAxiePair={deleteBreedingAxiePair}
          handleRefreshAxie={handleRefreshAxie}
          requiredId={requiredId}
          updateRequiredBreedingId={updateRequiredBreedingId}
          hideLock={hideLock}
        />
        {data.failMessage ? (
          <IconMessage text={data.failMessage} icon={<RiErrorWarningFill />} />
        ) : (
          <CostsAndPurity
            purityScore={data.offspring.purityScore}
            breedingCost={data.breedingCost}
            numberOfBreeds={numberOfBreeds}
            updateNumberOfBreedsForPair={updateNumberOfBreedsForPair}
            parentIds={parentIds}
            parentData={parentData}
          />
        )}
      </Stack>

      {data.offspring.parts != null && (
        <PartProbabilities parts={data.offspring.parts} />
      )}
    </ResultWrapper>
  );
};

const mapDispatchToProps = {
  fetchAxie,
  updateRequiredBreedingId,
};

const mapStateToProps = (state) => ({
  requiredId: state.breedingSimulator.requiredId,
});

export default connect(mapStateToProps, mapDispatchToProps)(BreedingResult);
