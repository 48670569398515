import { Stack } from "@mui/material";
import WatchIcon from "../../../atoms/WatchIcon";
import { formatTimestampAsDateTime } from "helpers/display";
import ReadyNow from "../../../atoms/ReadyNow";
import ActionButtons from "../atoms/ActionButtons";
import AxiePreviews from "../atoms/AxiePreviews";
import ScholarName from "../atoms/ScholarName";
import CustomChip from "components/Typography/CustomChip";
import NumberOfBattles from "../atoms/NumberOfBattles";

export const columnDefinitions = (scholars, currentTimestamp, prevPath) => ({
  watch: {
    field: "watch",
    headerName: "",
    width: 40,
    align: "center",
    sortable: false,
    renderCell: (cellValues) => (
      <WatchIcon
        scholar={scholars.find(
          (scholar) => scholar.roninAddress === cellValues.row.id
        )}
      />
    ),
  },
  name: {
    field: "name",
    headerName: "Name",
    minWidth: 300,
    renderCell: (cellValues) => (
      <ScholarName
        id={cellValues.row.id}
        name={cellValues.row.name}
        dob={cellValues.row.dob}
        guildTeamName={cellValues.row.guildTeamName}
        prevPath={prevPath}
        isActive={cellValues.row.isActive}
      />
    ),
  },
  averageSLP: {
    field: "averageSLP",
    headerName: "Avg SLP",
    minWidth: 100,
    align: "right",
    type: "number",
  },
  yesterdaySLP: {
    field: "yesterdaySLP",
    headerName: "Yesterday\nSLP",
    type: "number",
    minWidth: 110,
    align: "right",
  },
  totalSLP: {
    field: "totalSLP",
    headerName: "Total SLP",
    type: "number",
    minWidth: 110,
    align: "right",
  },
  moonshards: {
    field: "moonshards",
    headerName: "Moonshards",
    type: "number",
    minWidth: 100,
    align: "right",
  },
  managerSLP: {
    field: "managerSLP",
    headerName: "Manager SLP",
    type: "number",
    minWidth: 110,
    align: "right",
    renderCell: (cellValues) => {
      if (cellValues.row.managerSLP == null) {
        return "";
      }
      return Math.floor(cellValues.row.managerSLP).toLocaleString();
    },
  },
  investorSLP: {
    field: "investorSLP",
    headerName: "Investor SLP",
    type: "number",
    minWidth: 100,
    align: "right",
    renderCell: (cellValues) => {
      if (cellValues.row.investorSLP == null) {
        return "";
      }
      return Math.floor(cellValues.row.investorSLP).toLocaleString();
    },
  },
  stamina: {
    field: "stamina",
    headerName: "Stamina",
    type: "number",
    minWidth: 20,
    align: "right",
  },
  nextClaim: {
    field: "nextClaim",
    headerName: "Next Claim",
    type: "number",
    minWidth: 160,
    sortComparator: (v1, v2) => {
      return v1 < v2 || !v2 ? -1 : v1 > v2 ? 1 : 0;
    },
    renderCell: (cellValues) => {
      if (cellValues.row.nextClaim == null) {
        return undefined;
      }
      if (cellValues.row.nextClaim < currentTimestamp) {
        return <ReadyNow style={{ lineHeight: 1 }} />;
      }
      return formatTimestampAsDateTime(cellValues.row.nextClaim, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
  actionButtonsSLP: {
    field: "actionButtons",
    headerName: "",
    width: 190,
    sortable: false,
    disableClickEventBubbling: true,
    align: "right",
    renderCell: (cellValues) => (
      <ActionButtons
        roninAddress={cellValues.row.id}
        guildTeamId={cellValues.row.guildTeamId}
        payoutAddress={cellValues.row.payoutAddress}
        deleting={cellValues.row.deleting}
        snapshotTimestamp={cellValues.row.slpTimestamp}
        snapshotDataType="SLP"
      />
    ),
  },
  actionButtonsArena: {
    field: "actionButtons",
    headerName: "",
    width: 160,
    sortable: false,
    disableClickEventBubbling: true,
    align: "right",
    renderCell: (cellValues) => (
      <ActionButtons
        roninAddress={cellValues.row.id}
        guildTeamId={cellValues.row.guildTeamId}
        payoutAddress={cellValues.row.payoutAddress}
        deleting={cellValues.row.deleting}
      />
    ),
  },
  axieClassCounts: {
    field: "axieClassCounts",
    headerName: "Axies",
    valueGetter: (cellValues) => cellValues.row.totalAxies,
    minWidth: 340,
    flex: 1,
    sortComparator: (v1, v2) => {
      return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    },
    renderCell: (cellValues) => (
      <AxiePreviews
        axieClassCounts={cellValues.row.axieClassCounts}
        totalAxies={cellValues.row.totalAxies}
        roninAddress={cellValues.row.id}
        fetchingAxies={cellValues.row.fetchingAxies}
        guildTeamId={cellValues.row.guildTeamId}
      />
    ),
  },
  rankTier: {
    field: "rankTier",
    headerName: "Rank",
    headerAlign: "right",
    minWidth: 200,
    align: "right",
    renderCell: (cellValues) => {
      return (
        <Stack direction="row" gap={1} alignItems="center">
          <span>{cellValues.row.rankTier}</span>
          <CustomChip style={{ fontSize: "13px" }}>
            {`${cellValues.row.slpPerWin} SLP/win`}
          </CustomChip>
        </Stack>
      );
    },
  },
  numRank: {
    field: "numRank",
    headerName: "#",
    type: "number",
    minWidth: 130,
    align: "right",
    renderCell: (cellValues) => {
      if (cellValues.row.numRank == null) {
        return "";
      }
      return <span>{cellValues.row.numRank.toLocaleString()}</span>;
    },
  },
  mmr: {
    field: "mmr",
    headerName: "Stars",
    type: "number",
    minWidth: 40,
    align: "right",
  },
  numberOfBattles: {
    field: "numberOfBattles",
    headerName: "# Battles",
    type: "number",
    minWidth: 20,
    align: "right",
    renderCell: (cellValues) => (
      <NumberOfBattles
        energy={cellValues.row.energy}
        numberOfBattles={cellValues.row.numberOfBattles}
      />
    ),
  },
});
