import { Menu, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const PopupMenu = ({ children, title, paperStyles, ...props }) => {
  const theme = useTheme();
  return (
    <Menu
      PaperProps={{
        sx: {
          px: 4,
          py: 3,
          maxWidth: "700px",
          [theme.breakpoints.down("sm")]: {
            px: 3,
            py: 2,
            top: "0 !important",
            left: "0 !important",
            minHeight: "100vh",
            width: "100%",
          },
          ...paperStyles,
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      {...props}
    >
      <Stack gap={2}>
        {title && (
          <Typography variant="span" fontWeight="600" color="text.bright">
            {title}
          </Typography>
        )}
        {children}
      </Stack>
    </Menu>
  );
};

export default PopupMenu;
