import { Typography } from "@mui/material";
import { createRankTierString } from "helpers/arena";

const PlayerRankTier = ({ rank, tier }) => {
  return (
    <Typography
      fontSize={13}
      variant="span"
      fontWeight={800}
      sx={{
        color: "#FFAB2D",
        whiteSpace: "nowrap",
        lineHeight: 1.3,
      }}
    >
      {createRankTierString(rank, tier)}
    </Typography>
  );
};

export default PlayerRankTier;
