import { Tooltip } from "@mui/material";
import React from "react";

const TooltipWrapper = ({ tooltip, children }) => {
  return tooltip == null ? (
    <>{children}</>
  ) : (
    <Tooltip title={tooltip} placement="bottom">
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
