import React from "react";
import StatWidget from "../molecules/StatWidget";
import { IoShield } from "react-icons/io5";

const PerformanceTeam = ({
  title,
  rating,
  avgSLP,
  avgSLPRank,
  averageMMR,
  avgMMRRank,
  ratingTitle = "Guild Rating",
}) => {
  return (
    <StatWidget
      title={title}
      icon={<IoShield />}
      stats={[
        {
          label: ratingTitle,
          amount: rating,
        },
        {
          label: "Avg SLP",
          amount: avgSLP,
          rank: avgSLPRank,
          addRankSuffix: true,
        },
        {
          label: "Avg Stars",
          amount: averageMMR,
          rank: avgMMRRank,
          addRankSuffix: true,
        },
      ]}
    />
  );
};

export default PerformanceTeam;
