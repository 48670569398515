import { Stack, Typography } from "@mui/material";

const ChartInfoMessage = ({ children }) => {
  return (
    <Stack sx={{ height: "100%", minHeight: 300 }} justifyContent="center">
      <Typography
        variant="span"
        color="text.muted"
        fontSize="16px"
        fontWeight="600"
        textAlign="center"
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default ChartInfoMessage;
