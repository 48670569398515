import { Stack, styled } from "@mui/material";
import { addAlphaToHex } from "helpers/colors";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: addAlphaToHex(
    theme.palette.colors.origin.wood.backgroundDark,
    0.2
  ),
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  fontWeight: 600,
  color: theme.palette.colors.origin.wood.text,
  fontSize: 14,
}));

const NoPlayers = ({ numPlayers }: { numPlayers: number }) => {
  return <Wrapper>None found in the top {numPlayers}</Wrapper>;
};

export default NoPlayers;
