import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-1-12</Date>
      <Change type="improved">
        Guild Dashboard: Improved sorting, pagination &amp; appearance of
        scholars table. Added an info icon to display snapshot date for each
        scholar.
      </Change>
    </Entry>
  );
};

export default Changes;
