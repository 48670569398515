import React from "react";
import styled from "@emotion/styled";
import { FaQuestion } from "react-icons/fa";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: 16,
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  svg: {
    fontSize: 40,
    color: "#ffffff",
    path: {
      fill: "#ffffff",
    },
  },
}));

const NoResults = () => {
  return (
    <Wrapper>
      <FaQuestion sx={{ color: "#ffff" }} />
      <Typography color="#ffffff" fontSize="1.5rem">
        Your Search return no results!
      </Typography>
    </Wrapper>
  );
};

export default NoResults;
