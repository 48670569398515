import { FaArrowLeft } from "react-icons/fa";
import styles from "../welcome.module.scss";
import { Stack, useTheme, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Header = () => {
  const theme = useTheme();
  return (
    <div className={styles.header}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Button
          variant="text"
          component={RouterLink}
          size="small"
          startIcon={<FaArrowLeft />}
          to="/"
        >
          Back to main site
        </Button>
        <RouterLink to="/">
          {theme.palette.mode === "light" ? (
            <img
              src="/images/axie-tech-logo.png"
              className={styles.logo}
              alt="Axie Tech Logo"
            />
          ) : (
            <img
              src="/images/axie-tech-logo-white.png"
              className={styles.logo}
              alt="Axie Tech Logo"
            />
          )}
        </RouterLink>
      </Stack>
    </div>
  );
};

export default Header;
