import React from "react";
import { Stack, Box, useTheme } from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import MutedText from "components/Typography/MutedText";
import Header from "./Header";

const SimilarListings = ({ inspector }) => {
  const theme = useTheme();
  return (
    <Stack gap={4}>
      {inspector.market.total === 0 ? (
        <Stack
          alignItems="center"
          sx={{
            pt: 40,
            [theme.breakpoints.down("md")]: {
              py: 20,
            },
          }}
        >
          <MutedText>No similar listings</MutedText>
        </Stack>
      ) : (
        <>
          <Header
            total={inspector.market.total}
            marketplaceUrl={inspector.market.marketplaceUrl}
            floorPrice={inspector.market.floorPrice}
            tooltip={inspector.market.tooltip}
          />
          <Box>
            <Stack direction="row" gap={3} flexWrap="wrap">
              {inspector.market.axies.map((axie, i) => (
                <AxieV3 axie={axie} key={`AXIE_${i}`} />
              ))}
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default SimilarListings;
