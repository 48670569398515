import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const Search = ({ handleUpdate, value, placeholder }) => {
  return (
    <TextField
      onChange={(e) =>
        handleUpdate({
          searchText: e.target.value.toLowerCase(),
        })
      }
      //   onChange={(e) => handleUpdate(e.target.value)}
      hiddenLabel
      variant="filled"
      placeholder={placeholder}
      value={value}
      spellCheck={false}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            position="end"
            onClick={() =>
              handleUpdate({
                searchText: "",
              })
            }
          >
            <IoCloseCircle />
          </IconButton>
        ),
        sx: {
          paddingLeft: 2,
          height: 50,
        },
      }}
      sx={{
        "& .MuiFilledInput-input": {
          height: 48,
          padding: 0,
          background: "transparent",
        },
        "&:hover": {
          "& .MuiIconButton-root": {
            opacity: 1,
          },
        },
        "& .MuiIconButton-root": {
          opacity: 0,
        },
      }}
    />
  );
};

export default Search;
