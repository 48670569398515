import { Stack, Popper, Fade, Paper, Typography, Theme } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import { makeInspectorUrlFromId, makeMarketplaceURLFromID } from "helpers/axie";

interface PopoverMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  axieId: string;
}

interface LinkTextProps {
  children: React.ReactNode;
  href: string;
}

const PopoverMenu = ({ open, anchorEl, axieId }: PopoverMenuProps) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement="top" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{ p: 2, boxShadow: (theme) => theme.palette.card.boxShadow }}
          >
            <Stack gap={1}>
              <LinkText href={makeInspectorUrlFromId(axieId)}>
                Inspect Axie
              </LinkText>
              <LinkText href={makeMarketplaceURLFromID(axieId)}>
                View on Marketplace
              </LinkText>
            </Stack>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const LinkText = ({ children, href }: LinkTextProps) => {
  return (
    <CustomLink href={href}>
      <Typography
        fontSize={14}
        color="text.regular"
        sx={{
          "&:hover": {
            color: (theme: Theme) => theme.palette.text.bright,
          },
        }}
      >
        {children}
      </Typography>
    </CustomLink>
  );
};

export default PopoverMenu;
