import React from "react";
import { Stack, useTheme, Box } from "@mui/material";
import { ITEM_WIDTH } from "pages/TeamBuilder";

const ClickableSlot = ({ icon, onClick, part, itemType }) => {
  const heightMapping = {
    card: (276.48 / 180) * ITEM_WIDTH,
    rune: (246.45 / 180) * ITEM_WIDTH,
    charm: (246.45 / 180) * ITEM_WIDTH,
  };
  const theme = useTheme();
  return (
    <Stack
      onClick={onClick}
      alignItems="center"
      justifyContent="center"
      sx={{
        background: theme.palette.card.pop1,
        borderRadius: 3,
        width: ITEM_WIDTH,
        minWidth: ITEM_WIDTH,
        height: heightMapping[itemType],
        transition: "0.2s all ease",
        border: `1px dashed ${theme.palette.text.muted}`,
        "& .icon svg": {
          transition: "0.2s all ease",
          opacity: 0.5,
          height: 32,
          width: "auto",
        },
        "& .bodyPart": {
          opacity: 0.5,
          width: 36,
          transition: "0.2s all ease",
        },
        "&:hover": {
          cursor: "pointer",
          border: `1px dashed ${theme.palette.text.regular}`,
          "& .icon svg": {
            opacity: 1,
            transform: "scale(1.5)",
          },
          "& .bodyPart": {
            opacity: 1,
            transform: "scale(1.5)",
          },
        },
        "&:active": {
          "& .icon svg": {
            opacity: 1,
            transform: "scale(1)",
          },
          "& .bodyPart": {
            opacity: 1,
            transform: "scale(1.4)",
          },
        },
      }}
    >
      {icon != null && (
        <Box className="icon">{icon(theme.palette.text.muted)}</Box>
      )}
      {part != null && (
        <img
          src={`/images/templates/card/body-parts/${part}.png`}
          alt={`${part} Body Part Card`}
          className="bodyPart"
        />
      )}
    </Stack>
  );
};

export default ClickableSlot;
