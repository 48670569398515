import React from "react";
import { Stack } from "@mui/material";
import DropdownSectionLabel from "../atoms/DropdownSectionLabel";
import DropdownItem from "../atoms/DropdownItem";

const DropdownSection = ({ item, colorOverrides, onClick }) => {
  return (
    <Stack>
      <DropdownSectionLabel colorOverrides={colorOverrides}>
        {item.label}
      </DropdownSectionLabel>
      {item.links.map((link, i) => (
        <DropdownItem
          item={link}
          key={`ITEM_${i}`}
          colorOverrides={colorOverrides}
          onClick={onClick}
        >
          {link.text}
        </DropdownItem>
      ))}
    </Stack>
  );
};

export default DropdownSection;
