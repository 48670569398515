import React from "react";
import { Typography, useTheme } from "@mui/material";

const Error = ({ children }) => {
  const theme = useTheme();

  return children !== "" ? (
    <Typography
      variant="span"
      fontSize="13px"
      color={theme.palette.feedback.red}
      fontWeight="700"
    >
      {children}
    </Typography>
  ) : (
    <></>
  );
};

export default Error;
