import { OriginsLeaderboardControls } from "./leaderboardReducer";

export enum ActionTypes {
  ORIGINS_LEADERBOARD_UPDATE_CONTROLS = "ORIGINS_LEADERBOARD_UPDATE_CONTROLS",
}

export interface UpdateControlsAction {
  type: ActionTypes.ORIGINS_LEADERBOARD_UPDATE_CONTROLS;
  controls: OriginsLeaderboardControls;
}

export type UpdateControlsActionCreator = (
  controls: OriginsLeaderboardControls
) => UpdateControlsAction;

export const updateControls = (controls: OriginsLeaderboardControls) => ({
  type: ActionTypes.ORIGINS_LEADERBOARD_UPDATE_CONTROLS,
  controls,
});
