import * as types from "./leaderboardActionTypes";
import produce from "immer";

const initialState = {
  leaderboard: { fetching: true },
};

export const arenaLeaderboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_ARENA_LEADERBOARD_STARTED:
        draft.leaderboard.fetching = true;
        draft.leaderboard.failed = false;
        break;

      case types.FETCH_ARENA_LEADERBOARD_SUCCEEDED:
        draft.leaderboard.fetching = false;
        draft.leaderboard.data = action.data;
        break;

      case types.FETCH_ARENA_LEADERBOARD_FAILED:
        draft.leaderboard.fetching = false;
        draft.leaderboard.failed = true;
        break;

      default:
        break;
    }
  });

export default arenaLeaderboardReducer;
