import { Typography } from "@mui/material";

const CardTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography variant="h2" fontWeight={800} color="text.bright" fontSize={18}>
      {children}
    </Typography>
  );
};

export default CardTitle;
