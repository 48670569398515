import React from "react";
import { Typography, useTheme } from "@mui/material";
import { addAlphaToHex } from "helpers/colors";

const CustomChip = ({ children, style }) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="15px"
      color={theme.palette.secondary.main}
      fontWeight="700"
      variant="span"
      sx={{
        background: addAlphaToHex(theme.palette.secondary.main, 0.16),
        padding: "5px 8px 4px 8px",
        borderRadius: "8px",
        lineHeight: 1,
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomChip;
