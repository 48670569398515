import { useState } from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Stack, Container } from "@mui/material";
import { getCharmsToDisplay } from "store/explorer/selectors";
import {
  favoriteCharm,
  updateCharmFilters,
} from "store/explorer/explorerActions";
import InfoButton from "components/Origin/atoms/InfoButton";
import Sort from "../../molecules/Sort";
import ToggleStarterItems from "../../molecules/ToggleStarterItems";
import WrappedCharm from "pages/Explorer/molecules/WrappedCharm";
import { STARTER_AXIE_CHARMIDS } from "config";
import { isStarterRuneOrCharm } from "helpers/items";
import TriballyOriginsExplorerBanner from "components/TriballyAdBanner/TriballyOriginsExplorerBanner";

const Charms = ({
  charmsToDisplay,
  fetchingAxieCharms,
  favoriteCharm,
  updateCharmFilters,
  charmFilters,
  setInfoDialogIsOpen,
}) => {
  const showStarterCharms = isStarterRuneOrCharm(charmsToDisplay);
  const excludeStarterCharms = charmsToDisplay.filter(
    (rune) => !STARTER_AXIE_CHARMIDS.includes(rune.itemId)
  );
  const [value, setValue] = useState("showAll");

  return (
    <>
      {fetchingAxieCharms ? (
        <CustomLoader />
      ) : (
        <Stack gap={6}>
          <Container>
            <Stack
              direction={{ md: "column", lg: "row" }}
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <TriballyOriginsExplorerBanner />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <ToggleStarterItems
                  value={value}
                  setValue={setValue}
                  options={[
                    { label: "Starter Charms", value: "starterCharms" },
                    { label: "Personal Charms", value: "hideStarterCharms" },
                    { label: "Show All", value: "showAll" },
                  ]}
                />
                <Sort
                  filters={charmFilters}
                  updateFilters={updateCharmFilters}
                  options={[
                    { label: "Name A-Z", value: "NAME_ASC" },
                    { label: "Name Z-A", value: "NAME_DESC" },
                    { label: "Highest Rarity", value: "RARITY_DESC" },
                    { label: "Lowest Rarity", value: "RARITY_ASC" },
                    {
                      label: "Highest Potential Cost",
                      value: "POTENTIAL_DESC",
                    },
                    { label: "Lowest Potential Cost", value: "POTENTIAL_ASC" },
                  ]}
                />
                <InfoButton onClick={() => setInfoDialogIsOpen(true)} />
              </Stack>
            </Stack>
          </Container>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            gap={6}
            sx={{ px: 2 }}
          >
            {value === "starterCharms"
              ? showStarterCharms.map((charm) => (
                  <WrappedCharm
                    key={charm.id}
                    charm={charm}
                    favoriteCharm={favoriteCharm}
                  />
                ))
              : null}
            {value === "hideStarterCharms"
              ? excludeStarterCharms.map((charm) => (
                  <WrappedCharm
                    key={charm.id}
                    charm={charm}
                    favoriteCharm={favoriteCharm}
                  />
                ))
              : null}
            {value === "showAll"
              ? charmsToDisplay.map((charm) => (
                  <WrappedCharm
                    key={charm.id}
                    charm={charm}
                    favoriteCharm={favoriteCharm}
                  />
                ))
              : null}
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapDispatchToProps = { favoriteCharm, updateCharmFilters };

const mapStateToProps = (state) => ({
  charmsToDisplay: getCharmsToDisplay(state),
  fetchingCharms: state.data.fetchingAxieCharms,
  charmFilters: state.explorer.charmFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(Charms);
