import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import React from "react";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "rowType",
})(({ theme, rowType }) => ({
  display: "flex",
  padding: theme.spacing(2),
  borderRadius: "12px",
  flexWrap: "wrap",
  gap: theme.spacing(3),
  background: theme.palette.card.background,

  [theme.breakpoints.up("md")]: {
    display: "grid",
    gridGap: theme.spacing(2),
  },

  ...(rowType === "slp" && {
    gridTemplateColumns: "360px 1fr 1fr 1fr 2fr",
  }),
  ...(rowType === "arena" && {
    gridTemplateColumns: "360px 1fr 2fr",
  }),
}));

const Columns = ({ children, rowType }) => {
  return <Wrapper rowType={rowType}>{children}</Wrapper>;
};

export default Columns;
