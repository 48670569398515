import * as types from "./inspectorActionTypes";
import { put, takeEvery, retry, select } from "redux-saga/effects";
import { axieIsAdult, axieIsOrigin } from "helpers/axie";
import api from "services/api";
import * as actions from "./inspectorActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* fetchAxie(action) {
  try {
    yield put(actions.fetchAxieStarted());

    const axieData = yield select((state) => state.inspector.axieData);
    let axie;

    if (!(action.axieId in axieData)) {
      axie = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        action.axieId
      );
      yield put(actions.fetchAxieSucceeded(axie));
    } else {
      axie = axieData[action.axieId];
      yield put(actions.axieAlreadyFetched(action.axieId));
    }

    yield put(actions.fetchParents(axie));

    if (axieIsAdult(axie)) {
      yield put(actions.fetchNumberOfSimilarAxies(axie));

      yield put(actions.fetchLastSold(axie));

      if (axieIsOrigin(axie)) {
        yield put(actions.fetchMysticFloor(axie));
      } else {
        yield put(actions.fetchRecentlySold(axie));
      }
    }
  } catch (e) {
    yield put(actions.fetchAxieFailed());
  }
}

export function* fetchParents(action) {
  try {
    yield put(actions.fetchParentsStarted());

    let matron;
    let sire;

    if (action.axie?.matronId) {
      matron = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        action.axie.matronId
      );
    }
    if (action.axie?.sireId) {
      sire = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        action.axie.sireId
      );
    }

    yield put(actions.fetchParentsSucceeded([matron, sire]));
  } catch (e) {
    yield put(actions.fetchParentsFailed());
  }
}

export function* fetchLastSold(action) {
  try {
    yield put(actions.fetchLastSoldStarted());

    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestTransferHistory,
      action.axie.axieId
    );

    yield put(actions.fetchLastSoldSucceeded(data));
  } catch (e) {
    yield put(actions.fetchLastSoldFailed());
  }
}

export default function* axieSaga() {
  yield takeEvery(types.INSPECTOR_FETCH_AXIE, fetchAxie);
  yield takeEvery(types.INSPECTOR_FETCH_PARENTS, fetchParents);
  yield takeEvery(types.INSPECTOR_FETCH_LAST_SOLD, fetchLastSold);
}
