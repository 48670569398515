import { pluralize } from "helpers/display";
import { createSelector } from "reselect";
import { ITEM_RARITY_MAPPING } from "config";
import { getAllScholars, getActiveScholarRoninAddress } from "./common";
import { getAllRunes, getAllCharms } from "store/data/dataSelectors";
import {
  getItemBlockchainStatus,
  isItemACharm,
  isItemARune,
} from "helpers/items";
import { ARENA_SEASONS } from "config";
import moment from "moment";

export const getControls = (state) =>
  state.guildDashboard.inventory.controls || {};

export const getAllItemsRaw = (scholars) => {
  const items = {};

  for (const scholar of scholars) {
    for (const scholarItem of scholar.items || []) {
      const ownerInfo = {
        name: scholar.name,
        roninAddress: scholar.roninAddress,
        quantity: scholarItem.quantity,
        withdrawalAmount: scholarItem.withdrawalAmount ?? 0,
        nextWithdrawTime: scholarItem.nextWithdrawTime,
      };

      if (scholarItem.itemId in items) {
        items[scholarItem.itemId].owners.push(ownerInfo);
        items[scholarItem.itemId].quantity += scholarItem.quantity;
        items[scholarItem.itemId].withdrawalAmount +=
          scholarItem.withdrawalAmount ?? 0;
      } else {
        items[scholarItem.itemId] = {
          ...scholarItem,
          owners: [ownerInfo],
        };
      }
    }
  }
  return Object.values(items);
};

export const getItemsRawForRoninAddress = (scholars, ronin) => {
  const scholar = scholars.find((scholar) => scholar.roninAddress === ronin);

  if (!scholar) {
    return null;
  }

  return scholar.items || [];
};

const sortItems = (a, b, sortType) => {
  const aName = a.name;
  const bName = b.name;
  const aQuantity = a.quantity;
  const bQuantity = b.quantity;
  const aRarity = ITEM_RARITY_MAPPING[a.rarity];
  const bRarity = ITEM_RARITY_MAPPING[b.rarity];

  switch (sortType) {
    case "QUANTITY_DESC":
      return aQuantity > bQuantity ? -1 : aQuantity < bQuantity ? 1 : 0;
    case "QUANTITY_ASC":
      return aQuantity < bQuantity ? -1 : aQuantity > bQuantity ? 1 : 0;
    case "RARITY_DESC":
      return aRarity > bRarity ? -1 : aRarity < bRarity ? 1 : 0;
    case "RARITY_ASC":
      return aRarity < bRarity ? -1 : aRarity > bRarity ? 1 : 0;
    case "NAME_ASC":
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    case "NAME_DESC":
    default:
      return aName > bName ? -1 : aName < bName ? 1 : 0;
  }
};

const matchesSearchQuery = (item, searchQuery) => {
  return (
    searchQuery === "" ||
    (item.name || "").toLowerCase().includes(searchQuery) ||
    (item.effect || "").toLowerCase().includes(searchQuery) ||
    (item.owners || []).find((o) =>
      (o.name || "").toLowerCase().includes(searchQuery)
    ) ||
    (item.owners || []).find((o) =>
      (o.roninAddress || "").toLowerCase().includes(searchQuery)
    )
  );
};

const matchesAxieClasses = (item, axieClasses) =>
  axieClasses.includes(item.class) || axieClasses.length === 0;

const matchesBlockchainStatus = (item, blockchainStatuses) =>
  blockchainStatuses.includes(item.blockchainStatus) ||
  blockchainStatuses.length === 0;

const matchesItemTypes = (item, itemTypes) =>
  itemTypes.includes(item.itemType) || itemTypes.length === 0;

const matchesSeason = (item, seasons) => {
  const season = ARENA_SEASONS.find((season) => season.value === item.season);

  if (!season) {
    return true;
  }

  return seasons.includes(season.label) || seasons.length === 0;
};

const filterItems = (item, controls) => {
  const { searchQuery, filters } = controls;
  if (
    matchesSearchQuery(item, searchQuery) &&
    matchesAxieClasses(item, filters.axieClasses) &&
    matchesBlockchainStatus(item, filters.blockchainStatuses) &&
    matchesItemTypes(item, filters.itemTypes) &&
    matchesSeason(item, filters.seasons)
  ) {
    return true;
  }
  return false;
};

export const addItemMeta = (rawItems, allRunes, allCharms) => {
  if (allRunes == null || allCharms == null) {
    return null;
  }

  const augmented = [];

  for (const rawItem of rawItems) {
    let itemData;
    let itemType;

    if (isItemACharm(rawItem)) {
      itemData = allCharms.find((c) => c.itemId === rawItem.itemId);
      itemType = "Charm";
    } else if (isItemARune(rawItem)) {
      itemData = allRunes.find((c) => c.itemId === rawItem.itemId);
      itemType = "Rune";
    }

    if (itemData) {
      augmented.push({
        ...rawItem,
        ...itemData,
        isBlockchain: itemData.isBlockchain === 1,
        withdrawalAmount: rawItem.withdrawalAmount ?? 0,
        blockchainStatus: getItemBlockchainStatus(itemData.isBlockchain),
        itemType,
      });
    }
  }

  return Object.values(augmented);
};

export const getInventoryData = createSelector(
  [
    getControls,
    getAllScholars,
    getActiveScholarRoninAddress,
    getAllRunes,
    getAllCharms,
  ],
  (controls, allScholars, activeScholarRoninAddress, allRunes, allCharms) => {
    let items;
    let totalItems = 0;
    let numRunes = 0;
    let numCharms = 0;
    let itemsToDisplay = [];

    if (activeScholarRoninAddress) {
      items = getItemsRawForRoninAddress(
        allScholars,
        activeScholarRoninAddress
      );
    } else {
      items = getAllItemsRaw(allScholars);
    }

    if (items) {
      const augmentedItems = addItemMeta(items, allRunes, allCharms);

      if (augmentedItems) {
        totalItems = augmentedItems.length;
        numRunes = augmentedItems.filter((i) => i.itemType === "Rune").length;
        numCharms = augmentedItems.filter((i) => i.itemType === "Charm").length;

        itemsToDisplay = augmentedItems
          .filter((i) => filterItems(i, controls))
          .sort((a, b) => sortItems(a, b, controls.sortType));
      }
    }

    return {
      title: {
        text: "Inventory",
        chips: {
          All: pluralize("Item", totalItems),
          Rune: pluralize("Rune", numRunes),
          Charm: pluralize("Charm", numCharms),
        },
      },
      controls,
      items: itemsToDisplay,
    };
  }
);

const sortItemsByNextWidthdrawTime = (items) => {
  return [...items].sort((a, b) =>
    moment.utc(a.nextWithdrawTime).diff(moment.utc(b.nextWithdrawTime))
  );
};

export const getUpcomingMintableItems = createSelector(
  [getAllScholars, getAllRunes, getAllCharms],
  (allScholars, allRunes, allCharms) => {
    const runes = [];
    const charms = [];
    const NUMBER_TO_SHOW = 10;

    for (const scholar of allScholars) {
      for (const item of scholar.items || []) {
        if (item.nextWithdrawTime != null) {
          let itemData;

          const baseData = {
            ownerName: scholar.name,
            roninAddress: scholar.roninAddress,
            nextWithdrawTime: item.nextWithdrawTime,
          };

          if (isItemACharm(item)) {
            itemData = allCharms.find((c) => c.itemId === item.itemId);
            if (itemData) {
              charms.push({
                ...itemData,
                ...baseData,
              });
            }
          } else if (isItemARune(item)) {
            itemData = allRunes.find((c) => c.itemId === item.itemId);
            if (itemData) {
              runes.push({
                ...itemData,
                ...baseData,
              });
            }
          }
        }
      }
    }

    const sortedRunes = sortItemsByNextWidthdrawTime(runes);
    const sortedCharms = sortItemsByNextWidthdrawTime(charms);

    return {
      runes: sortedRunes.slice(0, NUMBER_TO_SHOW),
      charms: sortedCharms.slice(0, NUMBER_TO_SHOW),
    };
  }
);
