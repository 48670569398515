import { createSelector } from "reselect";
import {
  calculatePotentialPoints,
  getAxieSpecialty,
  getAxieRole,
  getCardStatsAndTags,
  makeMarketplaceSearchStringFromParts,
  getIdForAxieClass,
} from "helpers/axie";
import { PART_ORDER } from "pages/TeamBuilder";
import * as selectors from "./teamBuilderSelectors";
import {
  getAllUniqueRunesForCurrentSeason,
  getAllUniqueCharmsForCurrentSeason,
  getAllCardsV3,
} from "store/data/dataSelectors";
import { AXIE_BASE_HP } from "config";

const getDialog = (state) => {
  return state.teamBuilder.dialog || {};
};

const getFront = (state) => {
  return state.teamBuilder.front || {};
};

const getMid = (state) => {
  return state.teamBuilder.mid || {};
};

const getBack = (state) => {
  return state.teamBuilder.back || {};
};

const getDisplayBanishCards = (state) => {
  return state.teamBuilder.displayBanishCards;
};

export const getTeamBuilderAxies = createSelector(
  [
    getFront,
    getMid,
    getBack,
    getAllCardsV3,
    getAllUniqueCharmsForCurrentSeason,
    getAllUniqueRunesForCurrentSeason,
  ],
  (front, mid, back, allCards, allCharms, allRunes) => {
    return {
      front: augmentAxie(front, allCards, allCharms, allRunes),
      mid: augmentAxie(mid, allCards, allCharms, allRunes),
      back: augmentAxie(back, allCards, allCharms, allRunes),
    };
  }
);

const updateHP = (axie) => {
  let originalHealth = AXIE_BASE_HP;
  let health = originalHealth;
  const regex = new RegExp(/([+\d]\d+)/);

  Object.keys(axie.parts).forEach((part) => {
    if (axie.parts[part].charm != null) {
      const charm = axie.parts[part].charm;

      if (
        charm != null &&
        charm?.effect &&
        charm.effect.includes("Axie's HP")
      ) {
        const results = charm.effect.match(regex);

        if (results && results.length > 0) {
          const healthToAdd = parseInt(results[0].replace("+", ""));
          if (!isNaN(healthToAdd)) {
            health += healthToAdd;
          }
        }
      }
    }
  });

  return { total: health, bonus: health - originalHealth };
};

const augmentAxie = (axie) => {
  const cards = Object.values(axie.parts).reduce((arr, current) => {
    if (current?.card) {
      arr.push(current.card);
    }
    return arr;
  }, []);

  const potentialPoints = recalculatePotentialPoints(axie.class, axie.parts);

  const marketplaceSearchUrl = makeMarketplaceSearchStringFromCards(cards);

  return {
    ...axie,
    hasCards: cards.length > 0,
    class: axie.class == null ? "neutral" : axie.class,
    health: updateHP(axie),
    classification: {
      role: getAxieRole(cards),
      specialty: getAxieSpecialty(cards),
    },
    ...getCardStatsAndTags(cards),
    potentialPoints,
    marketplaceSearchUrl,
  };
};

export const makeMarketplaceSearchStringFromCards = (cardsArray) => {
  const partIds = cardsArray.map((favorite) => favorite.partId);
  return makeMarketplaceSearchStringFromParts(partIds);
};

const applyCardFilters = (allCards, filters, part) => {
  return [...allCards].filter(
    (card) =>
      card.partType === part &&
      ((card.cardText || "").toLowerCase().includes(filters.searchText) ||
        (card.cardName || "").toLowerCase().includes(filters.searchText)) &&
      (filters.axieClasses.includes(card.class) ||
        filters.axieClasses.length === 0) &&
      (filters.costs.includes(card.cardEnergy) || filters.costs.length === 0) &&
      (filters.tags.some((el) => (card.tags || "").split(",").includes(el)) ||
        filters.tags.length === 0)
  );
};

export const applyRuneFilters = (allRunes, filters, requiredClass) => {
  return [...allRunes].filter(
    (rune) =>
      (rune.class.toLowerCase() === requiredClass ||
        rune.class.toLowerCase() === "neutral") &&
      (filters.axieClasses.includes(rune.class.toLowerCase()) ||
        filters.axieClasses.length === 0) &&
      (rune.effect.toLowerCase().includes(filters.searchText) ||
        rune.name.toLowerCase().includes(filters.searchText)) &&
      (filters.rarities.includes(rune.rarity.toLowerCase()) ||
        filters.rarities.length === 0)
  );
};

export const applyCharmFilters = (allCharms, filters, cardClass) => {
  return [...allCharms].filter(
    (charm) =>
      (charm.class.toLowerCase() === cardClass ||
        charm.class.toLowerCase() === "neutral") &&
      (filters.axieClasses.includes(charm.class.toLowerCase()) ||
        filters.axieClasses.length === 0) &&
      (charm.effect.toLowerCase().includes(filters.searchText) ||
        charm.name.toLowerCase().includes(filters.searchText)) &&
      (filters.rarities.includes(charm.rarity.toLowerCase()) ||
        filters.rarities.length === 0) &&
      (filters.potentialCosts.includes(charm.potentialCost) ||
        filters.potentialCosts.length === 0)
  );
};

export const getItemDialogData = createSelector(
  [
    getDialog,
    getAllCardsV3,
    getAllUniqueCharmsForCurrentSeason,
    getAllUniqueRunesForCurrentSeason,
    getFront,
    getMid,
    getBack,
  ],
  (dialog, allCards, allCharms, allRunes, front, mid, back) => {
    let options;
    let title;
    let axie;
    let currentCharm;
    let currentCard;
    let potentialPoints;

    if (dialog.axieId === "front") {
      axie = { ...front };
    } else if (dialog.axieId === "mid") {
      axie = { ...mid };
    } else {
      axie = { ...back };
    }

    // Card View
    if (dialog.itemType === "cards") {
      options = applyCardFilters(allCards, dialog.filters.cards, dialog.part);

      title = `Select ${
        dialog.part.charAt(0).toUpperCase() + dialog.part.slice(1)
      } Card`;
      currentCharm = axie.parts[dialog.part]?.charm;
    }

    // Charm View
    else if (dialog.itemType === "charms") {
      currentCard = axie.parts[dialog.part].card;

      options = applyCharmFilters(
        allCharms,
        dialog.filters.charms,
        dialog.requiredClass
      );
      title = `Select ${
        dialog.part.charAt(0).toUpperCase() + dialog.part.slice(1)
      } Charm`;

      potentialPoints = recalculatePotentialPoints(
        axie.class,
        axie.parts,
        dialog.part
      );
    }

    // Rune View
    else if (dialog.itemType === "runes") {
      options = applyRuneFilters(
        allRunes,
        dialog.filters.runes,
        dialog.requiredClass
      );
      title = `Select Rune`;
    } else {
      options = [];
      title = "";
    }

    return {
      ...dialog,
      options,
      title,
      currentCharm,
      currentCard,
      potentialPoints,
    };
  }
);

export const getCardsToDisplay = createSelector(
  [getFront, getMid, getBack, getDisplayBanishCards],
  (front, mid, back, displayBanishCards) => {
    const cards = [];
    const axies = [front, mid, back];

    axies.forEach((axie) => {
      PART_ORDER.forEach((part) => {
        const card = axie.parts[part].card;

        if (
          card &&
          ((!card.tags.split(",").includes("banish") && !displayBanishCards) ||
            displayBanishCards)
        ) {
          cards.push(card);
        }
      });
    });

    return cards;
  }
);

export const getUrlStem = () => {
  const location = window.location;

  if (location != null) {
    return location.protocol + "//" + location.host;
  }

  return undefined;
};

export const getShareLink = createSelector(
  [getFront, getMid, getBack],
  (front, mid, back) => {
    const location = window.location;
    if (location == null) {
      return "";
    }

    let link = selectors.getUrlStem() + "/team-builder?";
    const axies = [
      {
        data: front,
        label: "front=",
      },
      { data: mid, label: "&mid=" },
      { data: back, label: "&back=" },
    ];

    axies.forEach((axie) => {
      link += axie.label + getIdForAxieClass(axie.data.class) + ".";
      const cards = [];
      const charms = [];

      PART_ORDER.forEach((part) => {
        cards.push(axie.data.parts[part].card?.id || "0");
      });

      link += cards.join("-");

      PART_ORDER.forEach((part) => {
        const charm = axie.data.parts[part].charm;
        const ppUsedStr = [];
        // Charm id
        let charmStr = `${charm?.id || "0"}`;
        // Charm potential points used
        if (charm) {
          charmStr += "~";
          for (const ppUsed of charm.potentialPointsUsed) {
            ppUsedStr.push(
              `${getIdForAxieClass(ppUsed.class)}_${ppUsed.amount}`
            );
          }
          charmStr += ppUsedStr.join("_");
        }

        charms.push(charmStr);
      });

      link += "." + charms.join("-");

      link += "." + (axie.data.rune?.id || "0");
    });
    return encodeURI(link);
  }
);

export const recalculatePotentialPoints = (axieClass, parts, currentPart) => {
  const cardClassArray = Object.keys(parts).reduce((arr, current) => {
    if (parts[current].card?.class) {
      arr.push(parts[current].card?.class);
    }
    return arr;
  }, []);

  const potentialPoints = calculatePotentialPoints(
    cardClassArray,
    axieClass
  ).map((cls) => ({ ...cls, remaining: cls.amount }));

  // Loop through all parts and decrement PP for each equipped charm
  Object.keys(parts).forEach((part) => {
    const currentCharm = parts[part].charm;
    let points;

    // Ignore the charm attached to the current part for the dialog
    if (currentPart !== part && currentCharm?.name != null) {
      for (const ppUsed of currentCharm.potentialPointsUsed) {
        points = potentialPoints.find(
          (p) => p.class.toLowerCase() === ppUsed.class.toLowerCase()
        );
        if (points) {
          if (points.remaining == null) {
            points.remaining = points.amount - ppUsed.amount;
          } else {
            points.remaining -= ppUsed.amount;
          }
        }
      }
    }
  });

  return potentialPoints;
};
