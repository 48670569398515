import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  background: "#B51EEF",
  borderRadius: 32,
  padding: "5px 14px 5px 6px",
  fontWeight: 800,
  color: "#fff",
  ".MuiTypography-span": {
    position: "relative",
    top: 1,
  },
}));

const Gem = styled(Stack)(({ theme }) => ({
  background: "#fff",
  borderRadius: "50%",
  width: 24,
  height: 24,
  padding: 4,
  img: {
    width: "100%",
  },
}));

const Points = ({ points }) => {
  return (
    <Wrapper className="points" gridArea="points">
      <Gem>
        <img src="/images/games/doll/gem.png" alt="AxieDoll Gem" />
      </Gem>
      <Typography variant="span">{points}</Typography>
    </Wrapper>
  );
};

export default Points;
