import React from "react";
import SocialIcons from "../../Social/SocialIcons";
import { IoMenu, IoClose } from "react-icons/io5";
import { IconButton, useTheme, Stack, Container, List } from "@mui/material";
import GuidesButton from "../atoms/GuidesButton";
import MobileNavItem from "../molecules/MobileNavItem";
import styled from "@emotion/styled";
import Logo from "../atoms/Logo";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})(({ theme, isOpen }) => ({
  transition: "0.2s left ease",
  left: isOpen ? 0 : "-150%",
  height: "100%",
  overflow: "auto",
  position: "fixed",
  padding: "4px 0 32px 0",
  top: "0",
  right: "0",
  width: "100%",
  zIndex: 98,
  backgroundColor: theme.palette.navigation.background,
}));

export const MobileNav = ({ links, setMobileMenuOpen, mobileMenuOpen }) => {
  const theme = useTheme();
  const closeAndCollapse = () => {
    Object.keys(links).forEach((link) => {
      if ("setIsOpen" in links[link]) {
        links[link].setIsOpen(false);
      }
    });
    setMobileMenuOpen(false);
  };
  return (
    <Wrapper isOpen={mobileMenuOpen}>
      <Container fixed>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 0.5 }}
        >
          <Logo color={theme.palette.primary.main} />
          <IconButton
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            sx={{ fontSize: 30 }}
          >
            {mobileMenuOpen ? <IoClose /> : <IoMenu />}
          </IconButton>
        </Stack>

        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 6,
            p: 0,
            overflowY: "auto",
          }}
        >
          {Object.keys(links).map((link, i) => (
            <MobileNavItem
              link={links[link]}
              closeAndCollapse={closeAndCollapse}
              key={`MOBILE_ITEM_${i}`}
            />
          ))}
        </List>

        <Stack direction="row" alignItems="center" gap={2} sx={{ pt: 4 }}>
          <GuidesButton />
          <SocialIcons size="20px" gap={1.5} />
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default MobileNav;
