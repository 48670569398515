import React from "react";
import { connect } from "react-redux";
import {
  getFavoriteCardsToDisplay,
  getMarketplaceSearchLink,
} from "store/explorer/selectors";
import { favoriteCard } from "store/explorer/explorerActions";
import Section from "../../molecules/FavoritesSection";
import { Stack, Button } from "@mui/material";
import { FaStore } from "react-icons/fa";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";
import { getAllCardsV2 } from "store/data/dataSelectors";

const Cards = ({
  favoriteCardsToDisplay,
  favoriteCard,
  marketplaceSearchLink,
  cardsV2,
}) => {
  return (
    <Section
      type="Card"
      numItems={favoriteCardsToDisplay.length}
      buttons={<Buttons marketplaceSearchLink={marketplaceSearchLink} />}
      gap={3}
    >
      {favoriteCardsToDisplay.map((card) => (
        <WrappedCard
          card={card}
          key={card.id}
          cardsV2={cardsV2}
          favoriteCard={favoriteCard}
        />
      ))}
    </Section>
  );
};

const Buttons = ({ marketplaceSearchLink }) => {
  return (
    <Stack>
      <Button
        endIcon={<FaStore />}
        href={marketplaceSearchLink}
        target="_blank"
        sx={{
          "&.MuiButton-root": {
            color: (theme) => theme.palette.colors.origin.wood.background,
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.5)",
            },
          },
        }}
      >
        Search Marketplace
      </Button>
    </Stack>
  );
};

const mapDispatchToProps = { favoriteCard };

const mapStateToProps = (state) => ({
  marketplaceSearchLink: getMarketplaceSearchLink(state),
  cardsV2: getAllCardsV2(state),
  favoriteCardsToDisplay: getFavoriteCardsToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
