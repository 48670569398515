import React from "react";
import { Typography, Stack } from "@mui/material";

const ResultsHeader = ({ title, description, children }) => {
  return (
    <Stack gap={1.5}>
      <Stack gap={0.5}>
        <Typography variant="h2" fontSize="22px" color="text.bright">
          {title}
        </Typography>
        <Typography variant="body1" fontSize="16px" color="text.muted">
          {description}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default ResultsHeader;
