import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-1-21</Date>
      <Change type="new">
        Released our new Arena Player profile pages! Check out what axies top players
        are using and dig into recent wins and losses in the detailed
        battle log. To see one of the new profile pages, head over to the{" "}
        <Link component={RouterLink} to="/arena-leaderboard">
          Arena Leaderboard
        </Link>{" "}
        and click on any player!
      </Change>
      <Change type="improved">
        Guild Dashboard Scholar Page 2.0: new layout, more metrics and charts
        and a detailed battle log.
      </Change>
      <Change type="improved">
        Improved the look of axies across all of our tools. Added axie
        card/ability previews.
      </Change>
      <Change type="improved">
        Recently Listed &amp; Sold: Added the ability to delete individual axies
        from the feed.
      </Change>
    </Entry>
  );
};

export default Changes;
