import { Stack, Typography, Button } from "@mui/material";
import { HiPlus } from "react-icons/hi";
import AddTeamDialog from "../organisms/AddTeamDialog";
import { connect } from "react-redux";
import { setOpenAddTeamDialog } from "store/guildDashboard/actions/guildDashboardActions";

const Welcome = ({ setOpenAddTeamDialog, openAddTeamDialog }) => {
  return (
    <Stack gap={3} sx={{ pt: 20 }}>
      <Stack gap={1}>
        <Typography
          textAlign="center"
          fontWeight="700"
          variant="span"
          color="text.bright"
          fontSize="32px"
        >
          Welcome to your teams page!
        </Typography>
        <Typography
          textAlign="center"
          fontWeight="600"
          variant="span"
          color="text.muted"
          sx={{ lineHeight: 1.4 }}
        >
          Teams are sub-groups of scholars within your guild.
        </Typography>
      </Stack>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        endIcon={<HiPlus />}
        onClick={() => setOpenAddTeamDialog(true)}
        sx={{ alignSelf: "center" }}
      >
        Add Team
      </Button>
      <AddTeamDialog open={openAddTeamDialog} setOpen={setOpenAddTeamDialog} />
    </Stack>
  );
};

const mapDispatchToProps = {
  setOpenAddTeamDialog,
};

const mapStateToProps = (state) => ({
  openAddTeamDialog: state.guildDashboard.openAddTeamDialog,
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
