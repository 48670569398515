import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  Tooltip,
  Rectangle,
} from "recharts";
import { Stack, useTheme, Typography } from "@mui/material";
import ChartInfoMessage from "./ChartInfoMessage";
import { ImCross, ImCheckmark } from "react-icons/im";
import BarChartLabel from "./BarChartLabel";
import TooltipWrapper from "components/Charts/TooltipWrapper";
import TooltipCapsule from "components/Charts/TooltipCapsule";

const Wins = ({ amount }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Typography
        fontSize="14px"
        variant="span"
        color={theme.palette.feedback.green}
      >
        <ImCheckmark />
      </Typography>
      <Typography
        fontSize="16px"
        variant="span"
        fontWeight="700"
        color={theme.palette.feedback.green}
      >
        {amount}
      </Typography>
    </Stack>
  );
};

const Losses = ({ amount }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Typography
        fontSize="11px"
        variant="span"
        color={theme.palette.feedback.red}
      >
        <ImCross />
      </Typography>
      <Typography
        fontSize="16px"
        variant="span"
        fontWeight="700"
        color={theme.palette.feedback.red}
      >
        {amount}
      </Typography>
    </Stack>
  );
};

const WinRate = ({ amount, type }) => {
  const theme = useTheme();
  return amount != null ? (
    <Typography
      fontSize="16px"
      variant="span"
      fontWeight="700"
      color={theme.palette.game[type].dark}
    >
      {amount}%
    </Typography>
  ) : (
    <></>
  );
};

const ChartTooltip = ({ active, payload }) => {
  const theme = useTheme();

  const mapping = {
    label: {
      numPvPWins: "pvp",
      numPvEWins: "pve",
    },
    type: {
      numPvPWins: "arena",
      numPvEWins: "adventure",
    },
    winRateKey: {
      numPvPWins: "winRatePvP",
      numPvEWins: "winRatePvE",
    },
  };

  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        {payload[0]?.value != null && payload[1]?.value != null && (
          <Stack direction="row" alignItems="center" gap={2}>
            <TooltipCapsule
              color={
                theme.palette.game[mapping.type[payload[0].name]].alternate
              }
            >
              {payload[0].value + payload[1].value} total
            </TooltipCapsule>
            <Stack direction="row" alignItems="center" gap={1}>
              <Wins amount={payload[0].value} />
              <Losses amount={payload[1].value} />
              <WinRate
                amount={payload[0].payload[mapping.winRateKey[payload[0].name]]}
                type={mapping.type[payload[0].name]}
              />
            </Stack>
          </Stack>
        )}
      </TooltipWrapper>
    );
  }
  return null;
};

const DailyEarningsChart = ({ data, height = 220 }) => {
  const theme = useTheme();

  return data != null && data.length > 0 ? (
    <>
      <div style={{ minWidth: 0 }}>
        <ResponsiveContainer width="99%" height={height}>
          <BarChart data={data} margin={{ top: 20, left: -20 }}>
            <YAxis
              stroke={theme.palette.card.subText}
              axisLine={{ stroke: theme.palette.card.pop3 }}
              style={{
                fontSize: "15px",
                fontWeight: "600",
              }}
              tickLine={{ stroke: theme.palette.card.pop3 }}
            />
            <XAxis
              dataKey="name"
              axisLine={{ stroke: theme.palette.card.pop3 }}
              stroke={theme.palette.card.subText}
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
              tickLine={{ stroke: theme.palette.card.pop3 }}
              reversed
              interval={0}
            />
            <Tooltip
              content={<ChartTooltip />}
              cursor={{ fill: "transparent" }}
            />

            <Bar
              stackId="pvp"
              dataKey="numPvPWins"
              shape={(props) => {
                if (props.payload.numPvPLosses === 0) {
                  props.radius = [8, 8, 0, 0];
                } else {
                  props.radius = [0, 0, 0, 0];
                }

                return <Rectangle {...props} />;
              }}
              fill={theme.palette.game.arena.alternate}
            />
            <Bar
              dataKey="numPvPLosses"
              radius={[8, 8, 0, 0]}
              stackId="pvp"
              fill={theme.palette.game.arena.main}
              label={
                <BarChartLabel fill={theme.palette.game.arena.alternate} />
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  ) : (
    <ChartInfoMessage>
      We're still collecting battles data for this scholar.
    </ChartInfoMessage>
  );
};

export default DailyEarningsChart;
