import { Stack, useTheme, Typography } from "@mui/material";
import React from "react";

const Or = () => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "relative",
        width: "50px",
        margin: "0 8px",
        padding: "0 4px",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        "&::before, &::after": {
          content: "''",
          width: "10px",
          height: "1px",
          display: "block",
          backgroundColor: theme.palette.text.muted,
          position: "absolute",
          zIndex: 1,
          top: "50%",
        },
        "&::before": {
          left: "40px",
        },
        "&::after": {
          right: "40px",
        },
      }}
    >
      <Typography
        fontSize={13}
        color="text.muted"
        variant="span"
        fontWeight={600}
        sx={{ position: "relative" }}
      >
        or
      </Typography>
    </Stack>
  );
};

export default Or;
