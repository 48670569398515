import * as types from "./playerActionTypes";
import {
  put,
  takeEvery,
  retry,
  fork,
  select,
  takeLatest,
} from "redux-saga/effects";
import api from "services/api";
import * as actions from "./playerActions";
import * as dataActions from "store/data/dataActions";
import { NUM_BATTLES_PER_PAGE } from "config";
import {
  extractBlockchainAxieIdsFromBattles,
  extractBlockchainAxieIdsFromFavoriteTeams,
  getFavoriteTeams,
} from "helpers/arena";
import {
  getPaginationEndIndex,
  getPaginationStartIndex,
} from "helpers/display";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchOriginPlayerRequest(action) {
  try {
    yield put(actions.fetchOriginPlayerStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestPlayer,
      action.clientId
    );
    yield put(actions.fetchOriginPlayerSucceeded(action.clientId, data));

    yield put(actions.fetchOriginPlayerBattles(action.clientId));
  } catch (e) {
    console.log("Error fetching player");
    yield put(actions.fetchOriginPlayerFailed());
  }
}

export function* makeFetchOriginPlayerBattles(action) {
  try {
    yield put(actions.fetchOriginPlayerBattlesStarted());
    const battles = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestPlayerPVPBattles,
      action.clientId
    );
    yield put(actions.fetchOriginPlayerBattlesSucceeded(battles));

    // Get favorite teams of axies
    const favoriteTeams = getFavoriteTeams(battles);

    // Extract unique list of favorite axie ids
    const favoriteTeamsAxieIds =
      extractBlockchainAxieIdsFromFavoriteTeams(favoriteTeams);

    // Get most recent 5 battles
    const recentBattles = battles.slice(0, NUM_BATTLES_PER_PAGE);

    // Extract unique list of axie ids in recent battles
    const recentBattlesAxieIds =
      extractBlockchainAxieIdsFromBattles(recentBattles);

    // Start fetching axies
    yield fork(fetchAxieData, [
      ...favoriteTeamsAxieIds,
      ...recentBattlesAxieIds,
    ]);
  } catch (e) {
    yield put(actions.fetchOriginPlayerBattlesFailed());
  }
}

/* Page Update */
export function* fetchDataForOriginPlayerBattlesPage(action) {
  try {
    const start = getPaginationStartIndex(
      action.currentPage,
      NUM_BATTLES_PER_PAGE
    );
    const end = getPaginationEndIndex(start, NUM_BATTLES_PER_PAGE);
    const battles = yield select((state) =>
      state.player.originPlayer.battles.data.slice(start, end)
    );
    const battlesAxieIds = extractBlockchainAxieIdsFromBattles(battles);

    yield fork(fetchAxieData, battlesAxieIds);
  } catch (e) {}
}

/* Axie data */
export function* fetchAxieData(uniqueIds) {
  try {
    const axieData = yield select((state) => state.data.axieData);

    for (const id of uniqueIds) {
      if (!(id in axieData)) {
        yield put(dataActions.fetchAxieData(id));
      }
    }
  } catch (e) {}
}

export default function* playerSaga() {
  yield takeEvery(types.FETCH_PLAYER_ORIGIN, makeFetchOriginPlayerRequest);
  yield takeLatest(
    types.FETCH_PLAYER_ORIGIN_BATTLES,
    makeFetchOriginPlayerBattles
  );
  yield takeEvery(
    types.UPDATE_PLAYER_ORIGIN_BATTLES_PAGE,
    fetchDataForOriginPlayerBattlesPage
  );
}
