import { spawn } from "redux-saga/effects";
import guildDashboardScholarSaga from "./guildDashboardScholarSaga";
import guildDashboardTeamsSaga from "./guildDashboardTeamsSaga";
import guildDashboardAxiesSaga from "./guildDashboardAxiesSaga";
import guildDashboardLoginSaga from "./guildDashboardLoginSaga";
import guildDashboardUserSaga from "./guildDashboardUserSaga";
import guildDashboardScholarBattlesSaga from "./guildDashboardScholarBattlesSaga";
import guildDashboardAutopayoutSaga from "./guildDashboardAutopayoutSaga";

export default function* guildDashboardSaga() {
  yield spawn(guildDashboardScholarSaga);
  yield spawn(guildDashboardTeamsSaga);
  yield spawn(guildDashboardAxiesSaga);
  yield spawn(guildDashboardLoginSaga);
  yield spawn(guildDashboardUserSaga);
  yield spawn(guildDashboardScholarBattlesSaga);
  yield spawn(guildDashboardAutopayoutSaga);
}
