import { Stack } from "@mui/material";
import React from "react";
import ToggleButton from "../atoms/ToggleButton";

const ToggleView = ({ view, setView }) => {
  return (
    <Stack direction="row" gap={2}>
      <ToggleButton
        isActive={view === "SCREENSHOT"}
        onClick={() => setView("SCREENSHOT")}
      >
        Screenshot View
      </ToggleButton>
      <ToggleButton isActive={view === "FULL"} onClick={() => setView("FULL")}>
        Full View
      </ToggleButton>
    </Stack>
  );
};

export default ToggleView;
