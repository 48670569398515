import React from "react";
import { Stack, useTheme, Box } from "@mui/material";

const Entry = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        my: 4,
        color: theme.palette.text.regular,
        fontSize: "18px",
        fontWeight: "500",
      }}
    >
      <Stack gap={2}>{children}</Stack>
    </Box>
  );
};

export default Entry;
