import React from "react";
import { DialogActions, Button } from "@mui/material";

const Buttons = ({ handleClose, handleUpdateItem }) => {
  return (
    <DialogActions sx={{ justifyContent: "space-between" }}>
      <Button variant="outlined" onClick={handleClose}>
        Cancel
      </Button>
      <Button onClick={handleUpdateItem} text="Done" variant="contained">
        Done
      </Button>
    </DialogActions>
  );
};

export default Buttons;
