import { Stack, Typography, Tooltip, Link } from "@mui/material";
import React from "react";
import { GoLinkExternal } from "react-icons/go";
import { getAxieImageUrl } from "helpers/axie";

const Image = ({ axie }) => (
  <Link
    href={axie.marketplaceUrl}
    underline="none"
    target="_blank"
    sx={{
      alignSelf: "center",
      p: 0,
      mx: -4,
      border: "none",
      "& .axieImage": {
        transition: "0.2s transform ease",
        transform: "scaleX(-1)",
      },
      "&:hover": {
        border: "none",
        "& .axieImage": {
          transform: "scaleX(-1) scale(1.05)",
        },
      },
    }}
  >
    <Stack alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ img: { width: 300, mx: -2, mb: -2.5, mt: -2, minHeight: 225 } }}
      >
        <img
          src={getAxieImageUrl(axie)}
          alt={`${axie.class} class axie #${axie.axieId}`}
          className="axieImage"
        />
      </Stack>
      <IdPlate color={axie.color} id={axie.axieId} icon={axie.icon} />
    </Stack>
  </Link>
);

const IdPlate = ({ color, id, icon }) => (
  <Stack
    sx={{
      background: color,
      svg: { height: 16, width: "auto", path: { fill: "#fff" } },
      borderRadius: "8px",
      py: 0.5,
      pl: 1,
      pr: 1.5,

      "&:hover": {
        "& .marketplaceLink": {
          opacity: 1,
        },
      },
    }}
    gap={0.5}
    direction="row"
    alignItems="center"
  >
    <Stack
      gap={0.5}
      direction="row"
      alignItems="center"
      sx={{ filter: "drop-shadow(0px 0px 3px rgba(0,0,0,0.2))" }}
    >
      {icon}
      <Typography
        color="#fff"
        fontWeight={600}
        fontSize={14}
        sx={{
          position: "relative",
          top: 1,
        }}
      >
        #{id}
      </Typography>
    </Stack>

    <Tooltip title="View on Marketplace">
      <Stack
        sx={{ opacity: 0.5, transition: "0.2s opacity ease" }}
        className="marketplaceLink"
      >
        <GoLinkExternal />
      </Stack>
    </Tooltip>
  </Stack>
);

export default Image;
