import React from "react";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import CardTemplate from "./CardTemplate";
import { AXIE_CLASS_COLORS } from "config";
import CardSummary from "components/Axie/AxieV3/components/CardSummary";
import fonts from "theme/fonts";
import { getOriginCardArtUrl } from "helpers/card";

const CardTemplatePreview = ({ card, width = 1181 }) => {
  if (card == null) return null;

  const cardArtImageUrl = getOriginCardArtUrl(card.cardImage);

  return (
    <Tooltip
      placement="top"
      title={<CardTemplate card={card} width={160} />}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
            boxShadow: "none",
            border: "none",
          },
        },
      }}
    >
      <Stack
        sx={{
          background: AXIE_CLASS_COLORS[card.class.toLowerCase()],
          p: "2px",
          width,
          height: (1050 / 1181) * width,
          position: "relative",
          borderRadius: `${(48 / 1181) * width}px`,
        }}
      >
        <Stack
          sx={{
            background: (theme) =>
              theme.palette.mode === "light" ? "#fff" : "#070707",
            height: "100%",
            borderRadius: `${(46 / 1181) * width}px`,
          }}
        >
          <Box
            sx={{
              height: width / 1.5,
              overflow: "hidden",
              backgroundImage: `url('${cardArtImageUrl}')`,
              backgroundPosition: "center top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: `${(44 / 1181) * width}px`,
            }}
          ></Box>
        </Stack>

        <CardName name={card.cardName} width={width} />
        <Stack
          sx={{
            position: "absolute",
            bottom: "6%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <CardSummary
            width={width * 2.27}
            data={{
              cardEnergy: card.cardEnergy,
              cardDamage: card.cardDamage,
              cardShield: card.cardShield,
              cardHeal: card.cardHeal,
            }}
          />
        </Stack>
      </Stack>
    </Tooltip>
  );
};

const CardName = ({ name, width }) => {
  return (
    <Typography
      textAlign="center"
      fontSize={(160 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      fontWeight={500}
      sx={{
        position: "absolute",
        top: "5%",
        left: "50%",
        transform: "translateX(-50%)",
        color: "#fff",
        zIndex: 5,
        textShadow: "0px 1px #000, 0px 1px 10px #000",
        width: "95%",
      }}
    >
      {name}
    </Typography>
  );
};

export default CardTemplatePreview;
