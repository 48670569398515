import React, { useEffect } from "react";
import { fetchDollCards } from "store/doll/cardExplorer/action";
import { connect } from "react-redux";
import { getLoading } from "store/doll/cardExplorer/selectors";
import CustomLoader from "components/CustomLoader/CustomLoader";
import TwoColumnLayout from "pages/Inspector/atoms/TwoColumnLayout";
import Cards from "./Cards";
import Bonuses from "./Bonuses";

const AxieDoLL = ({ data = {}, loading, fetchDollCards }) => {
  useEffect(() => {
    fetchDollCards();
  }, [fetchDollCards]);

  if (loading) {
    return <CustomLoader marginTop={100} />;
  }

  return (
    <TwoColumnLayout sidebarWidth={400}>
      <Cards cards={data.cards || []} />
      <Bonuses bonuses={data.bonuses} />
    </TwoColumnLayout>
  );
};

const mapDispatchToProps = {
  fetchDollCards,
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AxieDoLL);
