export const BODY_PART_LIST = [
  {
    class: "aquatic",
    name: "Anemone",
    partId: "horn-anemone",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Anemone",
    partId: "back-anemone",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Ant",
    partId: "tail-ant",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bug",
    name: "Antenna",
    partId: "horn-antenna",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Arco",
    partId: "horn-arco",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Axie Kiss",
    partId: "mouth-axie-kiss",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "aquatic",
    name: "Babylonia",
    partId: "horn-babylonia",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Balloon",
    partId: "back-balloon",
    specialGenes: null,
    type: "back",
  },
  {
    class: "plant",
    name: "Bamboo Shoot",
    partId: "horn-bamboo-shoot",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "plant",
    name: "Beech",
    partId: "horn-beech",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bug",
    name: "Beetle Spike",
    partId: "ears-beetle-spike",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Belieber",
    partId: "ears-belieber",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Bidens",
    partId: "back-bidens",
    specialGenes: null,
    type: "back",
  },
  {
    class: "plant",
    name: "Blossom",
    partId: "eyes-blossom",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Blue Moon",
    partId: "back-blue-moon",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Bone Sail",
    partId: "back-bone-sail",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Bookworm",
    partId: "eyes-bookworm",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bug",
    name: "Broken Bookworm",
    partId: "eyes-broken-bookworm",
    specialGenes: "mystic",
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Bubblemaker",
    partId: "ears-bubblemaker",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "reptile",
    name: "Bumpy",
    partId: "horn-bumpy",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bug",
    name: "Buzz Buzz",
    partId: "back-buzz-buzz",
    specialGenes: null,
    type: "back",
  },
  {
    class: "plant",
    name: "Cactus",
    partId: "horn-cactus",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "plant",
    name: "Santa's Gift",
    partId: "horn-santas-gift",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Calico Zeal",
    partId: "eyes-calico-zeal",
    specialGenes: "mystic",
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Candy Babylonia",
    partId: "horn-candy-babylonia",
    specialGenes: "mystic",
    type: "horn",
  },
  {
    class: "bug",
    name: "Candy Canes",
    partId: "back-candy-canes",
    specialGenes: "xmas",
    type: "back",
  },
  {
    class: "plant",
    name: "Carrot",
    partId: "tail-carrot",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bug",
    name: "Caterpillars",
    partId: "horn-caterpillars",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Catfish",
    partId: "mouth-catfish",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "plant",
    name: "Cattail",
    partId: "tail-cattail",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Cerastes",
    partId: "horn-cerastes",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Chubby",
    partId: "eyes-chubby",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Clamshell",
    partId: "horn-clamshell",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Clear",
    partId: "eyes-clear",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bird",
    name: "Cloud",
    partId: "tail-cloud",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "plant",
    name: "Clover",
    partId: "ears-clover",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Confident",
    partId: "mouth-confident",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "plant",
    name: "Confused",
    partId: "eyes-confused",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "beast",
    name: "Cottontail",
    partId: "tail-cottontail",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Crimson Gecko",
    partId: "eyes-crimson-gecko",
    specialGenes: "mystic",
    type: "eyes",
  },
  {
    class: "reptile",
    name: "Croc",
    partId: "back-croc",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Crystal Hermit",
    partId: "back-crystal-hermit",
    specialGenes: "mystic",
    type: "back",
  },
  {
    class: "bird",
    name: "Cuckoo",
    partId: "horn-cuckoo",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "plant",
    name: "Cucumber Slice",
    partId: "eyes-cucumber-slice",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bird",
    name: "Cupid",
    partId: "back-cupid",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bird",
    name: "Curly",
    partId: "ears-curly",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "reptile",
    name: "Curved Spine",
    partId: "ears-curved-spine",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bug",
    name: "Cute Bunny",
    partId: "mouth-cute-bunny",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Dango",
    partId: "mouth-dango",
    specialGenes: "japan",
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Deadly Pogona",
    partId: "ears-deadly-pogona",
    specialGenes: "mystic",
    type: "ears",
  },
  {
    class: "reptile",
    name: "December Surprise",
    partId: "tail-december-surprise",
    specialGenes: "xmas",
    type: "tail",
  },
  {
    class: "reptile",
    name: "Dokuganryu",
    partId: "eyes-dokuganryu",
    specialGenes: "japan",
    type: "eyes",
  },
  {
    class: "bird",
    name: "Doubletalk",
    partId: "mouth-doubletalk",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "plant",
    name: "Dreamy Papi",
    partId: "eyes-dreamy-papi",
    specialGenes: "mystic",
    type: "eyes",
  },
  {
    class: "beast",
    name: "Dual Blade",
    partId: "horn-dual-blade",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bug",
    name: "Ear Breathing",
    partId: "ears-ear-breathing",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bird",
    name: "Early Bird",
    partId: "ears-early-bird",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bug",
    name: "Earwing",
    partId: "ears-earwing",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bird",
    name: "Eggshell",
    partId: "horn-eggshell",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "reptile",
    name: "Escaped Gecko",
    partId: "tail-escaped-gecko",
    specialGenes: "mystic",
    type: "tail",
  },
  {
    class: "bug",
    name: "Feasting Mosquito",
    partId: "mouth-feasting-mosquito",
    specialGenes: "mystic",
    type: "mouth",
  },
  {
    class: "bird",
    name: "Feather Fan",
    partId: "tail-feather-fan",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bird",
    name: "Feather Spear",
    partId: "horn-feather-spear",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bug",
    name: "Fire Ant",
    partId: "tail-fire-ant",
    specialGenes: "mystic",
    type: "tail",
  },
  {
    class: "bug",
    name: "Fish Snack",
    partId: "tail-fish-snack",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Friezard",
    partId: "ears-friezard",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Furball",
    partId: "back-furball",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Garish Worm",
    partId: "back-garish-worm",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Gecko",
    partId: "eyes-gecko",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Geisha",
    partId: "mouth-geisha",
    specialGenes: "japan",
    type: "mouth",
  },
  {
    class: "beast",
    name: "Gerbil",
    partId: "tail-gerbil",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Gero",
    partId: "eyes-gero",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "reptile",
    name: "Gila",
    partId: "tail-gila",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Gill",
    partId: "ears-gill",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Goda",
    partId: "mouth-goda",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "plant",
    name: "Golden Bamboo Shoot",
    partId: "horn-golden-bamboo-shoot",
    specialGenes: "mystic",
    type: "horn",
  },
  {
    class: "bird",
    name: "Golden Shell",
    partId: "horn-golden-shell",
    specialGenes: "mystic",
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Goldfish",
    partId: "back-goldfish",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bird",
    name: "Granma's Fan",
    partId: "tail-granmas-fan",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Grass Snake",
    partId: "tail-grass-snake",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bug",
    name: "Gravel Ant",
    partId: "tail-gravel-ant",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Green Thorns",
    partId: "back-green-thorns",
    specialGenes: null,
    type: "back",
  },
  {
    class: "beast",
    name: "Hamaya",
    partId: "back-hamaya",
    specialGenes: "japan",
    type: "back",
  },
  {
    class: "beast",
    name: "Hare",
    partId: "tail-hare",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Hasagi",
    partId: "back-hasagi",
    specialGenes: "mystic",
    type: "back",
  },
  {
    class: "plant",
    name: "Hatsune",
    partId: "tail-hatsune",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bird",
    name: "Heart Cheek",
    partId: "ears-heart-cheek",
    specialGenes: "mystic",
    type: "ears",
  },
  {
    class: "plant",
    name: "Herbivore",
    partId: "mouth-herbivore",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "aquatic",
    name: "Hermit",
    partId: "back-hermit",
    specialGenes: null,
    type: "back",
  },
  {
    class: "beast",
    name: "Hero",
    partId: "back-hero",
    specialGenes: null,
    type: "back",
  },
  {
    class: "plant",
    name: "Hollow",
    partId: "ears-hollow",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Pinecones",
    partId: "ears-pinecones",
    specialGenes: "xmas",
    type: "ears",
  },
  {
    class: "plant",
    name: "Hot Butt",
    partId: "tail-hot-butt",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "plant",
    name: "Humorless",
    partId: "mouth-humorless",
    specialGenes: "mystic",
    type: "mouth",
  },
  {
    class: "bird",
    name: "Hungry Bird",
    partId: "mouth-hungry-bird",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Iguana",
    partId: "tail-iguana",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Imp",
    partId: "horn-imp",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "reptile",
    name: "Incisor",
    partId: "horn-incisor",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "reptile",
    name: "Indian Star",
    partId: "back-indian-star",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Frozen Bucket",
    partId: "back-frozen-bucket",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Inkling",
    partId: "ears-inkling",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Innocent Lamb",
    partId: "ears-innocent-lamb",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "aquatic",
    name: "Insomnia",
    partId: "eyes-insomnia",
    specialGenes: "mystic",
    type: "eyes",
  },
  {
    class: "beast",
    name: "Jaguar",
    partId: "back-jaguar",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Kabuki",
    partId: "eyes-kabuki",
    specialGenes: "japan",
    type: "eyes",
  },
  {
    class: "bird",
    name: "Karimata",
    partId: "ears-karimata",
    specialGenes: "japan",
    type: "ears",
  },
  {
    class: "bug",
    name: "Kawaii",
    partId: "mouth-kawaii",
    specialGenes: "japan",
    type: "mouth",
  },
  {
    class: "beast",
    name: "Kendama",
    partId: "horn-kendama",
    specialGenes: "japan",
    type: "horn",
  },
  {
    class: "bird",
    name: "Kestrel",
    partId: "horn-kestrel",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Kingfisher",
    partId: "back-kingfisher",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Koi",
    partId: "tail-koi",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Koinobori",
    partId: "tail-koinobori",
    specialGenes: "japan",
    type: "tail",
  },
  {
    class: "reptile",
    name: "Kotaro",
    partId: "mouth-kotaro",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "bug",
    name: "Kotaro?",
    partId: "eyes-kotaro",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bug",
    name: "Kotaro?",
    partId: "eyes-kotaro?",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Kuro Koi",
    partId: "tail-kuro-koi",
    specialGenes: "mystic",
    type: "tail",
  },
  {
    class: "bug",
    name: "Lagging",
    partId: "horn-lagging",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bug",
    name: "Laggingggggg",
    partId: "horn-laggingggggg",
    specialGenes: "mystic",
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Lam",
    partId: "mouth-lam",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "aquatic",
    name: "Lam Handsome",
    partId: "mouth-lam-handsome",
    specialGenes: "mystic",
    type: "mouth",
  },
  {
    class: "bug",
    name: "Larva",
    partId: "ears-larva",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bug",
    name: "Leaf Bug",
    partId: "ears-leaf-bug",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bug",
    name: "Leaf Bug",
    partId: "horn-leaf-bug",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "plant",
    name: "Leafy",
    partId: "ears-leafy",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Little Branch",
    partId: "horn-little-branch",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Little Owl",
    partId: "eyes-little-owl",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bird",
    name: "Little Owl",
    partId: "mouth-little-owl",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "beast",
    name: "Little Peas",
    partId: "eyes-little-peas",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "plant",
    name: "Lotus",
    partId: "ears-lotus",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bird",
    name: "Lucas",
    partId: "eyes-lucas",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bird",
    name: "Icy Gaze",
    partId: "eyes-icy-gaze",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "plant",
    name: "Maiko",
    partId: "ears-maiko",
    specialGenes: "japan",
    type: "ears",
  },
  {
    class: "bug",
    name: "Maki",
    partId: "tail-maki",
    specialGenes: "japan",
    type: "tail",
  },
  {
    class: "bird",
    name: "Mavis",
    partId: "eyes-mavis",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "beast",
    name: "Merry",
    partId: "horn-merry",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Merry Lamb",
    partId: "ears-merry-lamb",
    specialGenes: "xmas",
    type: "ears",
  },
  {
    class: "plant",
    name: "Mint",
    partId: "back-mint",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Mon",
    partId: "ears-mon",
    specialGenes: "japan",
    type: "ears",
  },
  {
    class: "bug",
    name: "Mosquito",
    partId: "mouth-mosquito",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "bird",
    name: "Mr. Doubletalk",
    partId: "mouth-mr-doubletalk",
    specialGenes: "mystic",
    type: "mouth",
  },
  {
    class: "plant",
    name: "Namek Carrot",
    partId: "tail-namek-carrot",
    specialGenes: "mystic",
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Navaga",
    partId: "tail-navaga",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bug",
    name: "Neo",
    partId: "eyes-neo",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bug",
    name: "Nerdy",
    partId: "eyes-nerdy",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Nimo",
    partId: "ears-nimo",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "aquatic",
    name: "Nimo",
    partId: "tail-nimo",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Nut Cracker",
    partId: "ears-nut-cracker",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Nut Cracker",
    partId: "mouth-nut-cracker",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "beast",
    name: "Nut Cracker",
    partId: "tail-nut-cracker",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Nyan",
    partId: "ears-nyan",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bird",
    name: "Omatsuri",
    partId: "tail-omatsuri",
    specialGenes: "japan",
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Oranda",
    partId: "horn-oranda",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Origami",
    partId: "back-origami",
    specialGenes: "japan",
    type: "back",
  },
  {
    class: "bird",
    name: "Owl",
    partId: "ears-owl",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bug",
    name: "P4R451T3",
    partId: "horn-p4r451t3",
    specialGenes: "bionic",
    type: "horn",
  },
  {
    class: "plant",
    name: "Papi",
    partId: "eyes-papi",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bug",
    name: "Parasite",
    partId: "horn-parasite",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Peace Maker",
    partId: "ears-peace-maker",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bird",
    name: "Peace Maker",
    partId: "mouth-peace-maker",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "aquatic",
    name: "Perch",
    partId: "back-perch",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bird",
    name: "Pigeon Post",
    partId: "back-pigeon-post",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Pincer",
    partId: "mouth-pincer",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "bird",
    name: "Pink Cheek",
    partId: "ears-pink-cheek",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Pink Turnip",
    partId: "back-pink-turnip",
    specialGenes: "mystic",
    type: "back",
  },
  {
    class: "reptile",
    name: "Pinku Unko",
    partId: "horn-pinku-unko",
    specialGenes: "mystic",
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Piranha",
    partId: "mouth-piranha",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "bug",
    name: "Pliers",
    partId: "horn-pliers",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Pocky",
    partId: "horn-pocky",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "reptile",
    name: "Pogona",
    partId: "ears-pogona",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Pointy Nyan",
    partId: "ears-pointy-nyan",
    specialGenes: "mystic",
    type: "ears",
  },
  {
    class: "bird",
    name: "Post Fight",
    partId: "tail-post-fight",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "plant",
    name: "Potato Leaf",
    partId: "tail-potato-leaf",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "plant",
    name: "Pumpkin",
    partId: "back-pumpkin",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Pupae",
    partId: "tail-pupae",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Puppy",
    partId: "eyes-puppy",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "beast",
    name: "Puppy",
    partId: "ears-puppy",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "aquatic",
    name: "Ranchu",
    partId: "tail-ranchu",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bird",
    name: "Raven",
    partId: "back-raven",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Razor Bite",
    partId: "mouth-razor-bite",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Red Ear",
    partId: "back-red-ear",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Red Nimo",
    partId: "ears-red-nimo",
    specialGenes: "mystic",
    type: "ears",
  },
  {
    class: "beast",
    name: "Rice",
    partId: "tail-rice",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Risky Beast",
    partId: "back-risky-beast",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bird",
    name: "Risky Bird",
    partId: "ears-risky-bird",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "aquatic",
    name: "Risky Fish",
    partId: "mouth-risky-fish",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "bird",
    name: "Robin",
    partId: "eyes-robin",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "beast",
    name: "Ronin",
    partId: "back-ronin",
    specialGenes: null,
    type: "back",
  },
  {
    class: "plant",
    name: "Rosa",
    partId: "ears-rosa",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Rose Bud",
    partId: "horn-rose-bud",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "plant",
    name: "Rudolph",
    partId: "mouth-rudolph",
    specialGenes: "xmas",
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Rugged Sail",
    partId: "back-rugged-sail",
    specialGenes: "mystic",
    type: "back",
  },
  {
    class: "plant",
    name: "Sakura",
    partId: "ears-sakura",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "beast",
    name: "Sakura Cottontail",
    partId: "tail-sakura-cottontail",
    specialGenes: "mystic",
    type: "tail",
  },
  {
    class: "bug",
    name: "Sandal",
    partId: "back-sandal",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Scaly Spear",
    partId: "horn-scaly-spear",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "reptile",
    name: "Scaly Spoon",
    partId: "horn-scaly-spoon",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "reptile",
    name: "Scar",
    partId: "eyes-scar",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bug",
    name: "Scarab",
    partId: "back-scarab",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Seaslug",
    partId: "ears-seaslug",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Serious",
    partId: "mouth-serious",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "beast",
    name: "Shiba",
    partId: "tail-shiba",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "plant",
    name: "Shiitake",
    partId: "back-shiitake",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Shoal Star",
    partId: "horn-shoal-star",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Shrimp",
    partId: "tail-shrimp",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Sidebarb",
    partId: "ears-sidebarb",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Silence Whisper",
    partId: "mouth-silence-whisper",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "beast",
    name: "Skull Cracker",
    partId: "mouth-skull-cracker",
    specialGenes: "mystic",
    type: "mouth",
  },
  {
    class: "bird",
    name: "Sky Mavis",
    partId: "eyes-sky-mavis",
    specialGenes: "mystic",
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Sleepless",
    partId: "eyes-sleepless",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "reptile",
    name: "Small Frill",
    partId: "ears-small-frill",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "bug",
    name: "Snail Shell",
    partId: "back-snail-shell",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Snake Jar",
    partId: "tail-snake-jar",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Snowflakes",
    partId: "eyes-snowflakes",
    specialGenes: "xmas",
    type: "eyes",
  },
  {
    class: "bird",
    name: "Snowy Swallow",
    partId: "tail-snowy-swallow",
    specialGenes: "mystic",
    type: "tail",
  },
  {
    class: "bug",
    name: "Spiky Wing",
    partId: "back-spiky-wing",
    specialGenes: null,
    type: "back",
  },
  {
    class: "aquatic",
    name: "Sponge",
    partId: "back-sponge",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bird",
    name: "Spruce Spear",
    partId: "horn-spruce-spear",
    specialGenes: "xmas",
    type: "horn",
  },
  {
    class: "bug",
    name: "Square Teeth",
    partId: "mouth-square-teeth",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "bird",
    name: "Starry Balloon",
    partId: "back-starry-balloon",
    specialGenes: "mystic",
    type: "back",
  },
  {
    class: "bug",
    name: "Starry Shell",
    partId: "back-starry-shell",
    specialGenes: "mystic",
    type: "back",
  },
  {
    class: "plant",
    name: "Strawberry Shortcake",
    partId: "horn-strawberry-shortcake",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Swallow",
    partId: "tail-swallow",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Swirl",
    partId: "ears-swirl",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "aquatic",
    name: "Tadpole",
    partId: "tail-tadpole",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bug",
    name: "Tassels",
    partId: "ears-tassels",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "aquatic",
    name: "Teal Shell",
    partId: "horn-teal-shell",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "aquatic",
    name: "Telescope",
    partId: "eyes-telescope",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "plant",
    name: "The Last Leaf",
    partId: "ears-the-last-leaf",
    specialGenes: "mystic",
    type: "ears",
  },
  {
    class: "bird",
    name: "The Last One",
    partId: "tail-the-last-one",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "bug",
    name: "Thorny Caterpillar",
    partId: "tail-thorny-caterpillar",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Timber",
    partId: "back-timber",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Tiny Dino",
    partId: "tail-tiny-dino",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "reptile",
    name: "Fir Trunk",
    partId: "tail-fir-trunk",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Tiny Fan",
    partId: "ears-tiny-fan",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "reptile",
    name: "Tiny Turtle",
    partId: "mouth-tiny-turtle",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Tiny Carrot",
    partId: "mouth-tiny-carrot",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Toothless Bite",
    partId: "mouth-toothless-bite",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Topaz",
    partId: "eyes-topaz",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bird",
    name: "Tri Feather",
    partId: "back-tri-feather",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Tri Spikes",
    partId: "back-tri-spikes",
    specialGenes: null,
    type: "back",
  },
  {
    class: "reptile",
    name: "Tricky",
    partId: "eyes-tricky",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "bird",
    name: "Trump",
    partId: "horn-trump",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "plant",
    name: "Turnip",
    partId: "back-turnip",
    specialGenes: null,
    type: "back",
  },
  {
    class: "bug",
    name: "Twin Tail",
    partId: "tail-twin-tail",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "beast",
    name: "Umaibo",
    partId: "horn-umaibo",
    specialGenes: "japan",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Unko",
    partId: "horn-unko",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bug",
    name: "Vector",
    partId: "ears-vector",
    specialGenes: "mystic",
    type: "ears",
  },
  {
    class: "reptile",
    name: "Venom Bite",
    partId: "mouth-venom-bite",
    specialGenes: "mystic",
    type: "mouth",
  },
  {
    class: "reptile",
    name: "Wall Gecko",
    partId: "tail-wall-gecko",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "plant",
    name: "Watering Can",
    partId: "back-watering-can",
    specialGenes: null,
    type: "back",
  },
  {
    class: "plant",
    name: "Watermelon",
    partId: "horn-watermelon",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "bird",
    name: "Wing Horn",
    partId: "horn-wing-horn",
    specialGenes: null,
    type: "horn",
  },
  {
    class: "beast",
    name: "Winter Branch",
    partId: "horn-winter-branch",
    specialGenes: "mystic",
    type: "horn",
  },
  {
    class: "plant",
    name: "Yakitori",
    partId: "back-yakitori",
    specialGenes: "japan",
    type: "back",
  },
  {
    class: "plant",
    name: "Yam",
    partId: "tail-yam",
    specialGenes: null,
    type: "tail",
  },
  {
    class: "aquatic",
    name: "Yen",
    partId: "eyes-yen",
    specialGenes: "japan",
    type: "eyes",
  },
  {
    class: "plant",
    name: "Yorishiro",
    partId: "horn-yorishiro",
    specialGenes: "japan",
    type: "horn",
  },
  {
    class: "beast",
    name: "Zeal",
    partId: "eyes-zeal",
    specialGenes: null,
    type: "eyes",
  },
  {
    class: "beast",
    name: "Zen",
    partId: "ears-zen",
    specialGenes: null,
    type: "ears",
  },
  {
    class: "plant",
    name: "Zigzag",
    partId: "mouth-zigzag",
    specialGenes: null,
    type: "mouth",
  },
  {
    class: "reptile",
    name: "1ND14N-5T4R",
    partId: "back-1nd14n-5t4r",
    specialGenes: "bionic",
    type: "back",
  },
  {
    class: "aquatic",
    name: "5H04L-5T4R",
    partId: "horn-5h04l-5t4r",
    specialGenes: "bionic",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Watermelon Ice Cream",
    partId: "horn-watermelon-ice-cream",
    specialGenes: "summer-2022",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Strawberry Ice Cream",
    partId: "horn-strawberry-ice-cream",
    specialGenes: "summer-2022",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Vanilla Ice Cream",
    partId: "horn-vanilla-ice-cream",
    specialGenes: "summer-2022",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Turtle Buoy",
    partId: "back-turtle-buoy",
    specialGenes: "summer-2022",
    type: "back",
  },
  {
    class: "beast",
    name: "Beach Ball",
    partId: "back-beach-ball",
    specialGenes: "summer-2022",
    type: "back",
  },
  {
    class: "bug",
    name: "Flower Sunglasses",
    partId: "eyes-flower-sunglasses",
    specialGenes: "summer-2022",
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Bubble Fish",
    partId: "mouth-bubble-fish",
    specialGenes: "summer-2022",
    type: "mouth",
  },
  {
    class: "beast",
    name: "Cotton Candy",
    partId: "tail-cotton-candy",
    specialGenes: "summer-2022",
    type: "tail",
  },
  {
    class: "beast",
    name: "Coca",
    partId: "ears-coca",
    specialGenes: "summer-2022",
    type: "ears",
  },
  {
    class: "reptile",
    name: "Watermelon Ice Cream",
    partId: "horn-watermelon-ice-cream-shiny",
    specialGenes: "summer-2022",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Strawberry Ice Cream",
    partId: "horn-strawberry-ice-cream-shiny",
    specialGenes: "summer-2022",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Vanilla Ice Cream",
    partId: "horn-vanilla-ice-cream-shiny",
    specialGenes: "summer-2022",
    type: "horn",
  },
  {
    class: "reptile",
    name: "Turtle Buoy",
    partId: "back-turtle-buoy-shiny",
    specialGenes: "summer-2022",
    type: "back",
  },
  {
    class: "beast",
    name: "Beach Ball",
    partId: "back-beach-ball-shiny",
    specialGenes: "summer-2022",
    type: "back",
  },
  {
    class: "bug",
    name: "Flower Sunglasses",
    partId: "eyes-flower-sunglasses-shiny",
    specialGenes: "summer-2022",
    type: "eyes",
  },
  {
    class: "aquatic",
    name: "Bubble Fish",
    partId: "mouth-bubble-fish-shiny",
    specialGenes: "summer-2022",
    type: "mouth",
  },
  {
    class: "beast",
    name: "Cotton Candy",
    partId: "tail-cotton-candy-shiny",
    specialGenes: "summer-2022",
    type: "tail",
  },
  {
    class: "beast",
    name: "Coca",
    partId: "ears-coca-shiny",
    specialGenes: "summer-2022",
    type: "ears",
  },
];

export const SPECIAL_PART_MAPPING = {
  "eyes-kotaro?": "eyes-kotaro",
  "eyes-broken-bookworm": "eyes-bookworm",
  "eyes-calico-zeal": "eyes-zeal",
  "horn-candy-babylonia": "horn-babylonia",
  "eyes-crimson-gecko": "eyes-gecko",
  "back-crystal-hermit": "back-hermit",
  "ears-deadly-pogona": "ears-pogona",
  "eyes-dreamy-papi": "eyes-papi",
  "tail-escaped-gecko": "tail-wall-gecko",
  "mouth-feasting-mosquito": "mouth-mosquito",
  "tail-fire-ant": "tail-ant",
  "horn-golden-bamboo-shoot": "horn-bamboo-shoot",
  "horn-golden-shell": "horn-eggshell",
  "back-hasagi": "back-ronin",
  "ears-heart-cheek": "ears-pink-cheek",
  "mouth-humorless": "mouth-serious",
  "eyes-insomnia": "eyes-sleepless",
  "tail-kuro-koi": "tail-koi",
  "horn-laggingggggg": "horn-lagging",
  "mouth-lam-handsome": "mouth-lam",
  "mouth-mr-doubletalk": "mouth-doubletalk",
  "tail-namek-carrot": "tail-carrot",
  "back-pink-turnip": "back-turnip",
  "horn-pinku-unko": "horn-unko",
  "ears-pointy-nyan": "ears-nyan",
  "ears-red-nimo": "ears-nimo",
  "back-rugged-sail": "back-bone-sail",
  "tail-sakura-cottontail": "tail-cottontail",
  "mouth-skull-cracker": "mouth-nut-cracker",
  "eyes-sky-mavis": "eyes-mavis",
  "tail-snowy-swallow": "tail-swallow",
  "back-starry-balloon": "back-balloon",
  "back-starry-shell": "back-snail-shell",
  "ears-the-last-leaf": "ears-leafy",
  "ears-vector": "ears-larva",
  "mouth-venom-bite": "mouth-toothless-bite",
  "horn-winter-branch": "horn-little-branch",
  "mouth-dango": "mouth-tiny-turtle",
  "eyes-dokuganryu": "eyes-scar",
  "mouth-geisha": "mouth-piranha",
  "back-hamaya": "back-risky-beast",
  "eyes-kabuki": "eyes-topaz",
  "ears-karimata": "ears-risky-bird",
  "mouth-kawaii": "mouth-cute-bunny",
  "horn-kendama": "horn-imp",
  "tail-koinobori": "tail-koi",
  "ears-maiko": "ears-sakura",
  "tail-maki": "tail-fish-snack",
  "ears-mon": "ears-earwing",
  "tail-omatsuri": "tail-granmas-fan",
  "back-origami": "back-cupid",
  "horn-umaibo": "horn-pocky",
  "back-yakitori": "back-shiitake",
  "eyes-yen": "eyes-sleepless",
  "horn-yorishiro": "horn-beech",

  "back-candy-canes": "back-garish-worm",
  "ears-pinecones": "ears-hollow",
  "tail-december-surprise": "tail-snake-jar",
  "ears-merry-lamb": "ears-innocent-lamb",
  "mouth-rudolph": "mouth-zigzag",
  "eyes-snowflakes": "eyes-little-peas",
  "horn-spruce-spear": "horn-feather-spear",
  "eyes-icy-gaze": "eyes-lucas",
  "mouth-tiny-carrot": "mouth-tiny-turtle",
  "back-frozen-bucket": "back-indian-star",
  "tail-fir-trunk": "tail-tiny-dino",
  "horn-santas-gift": "horn-cactus",

  "horn-watermelon-ice-cream": "horn-unko",
  "horn-watermelon-ice-cream-shiny": "horn-unko",

  "horn-strawberry-ice-cream": "horn-unko",
  "horn-strawberry-ice-cream-shiny": "horn-unko",

  "horn-vanilla-ice-cream": "horn-unko",
  "horn-vanilla-ice-cream-shiny": "horn-unko",

  "back-turtle-buoy": "back-red-ear",
  "back-turtle-buoy-shiny": "back-red-ear",

  "back-beach-ball": "back-furball",
  "back-beach-ball-shiny": "back-furball",

  "eyes-flower-sunglasses": "eyes-neo",
  "eyes-flower-sunglasses-shiny": "eyes-neo",

  "mouth-bubble-fish": "mouth-risky-fish",
  "mouth-bubble-fish-shiny": "mouth-risky-fish",

  "tail-cotton-candy": "tail-cottontail",
  "tail-cotton-candy-shiny": "tail-cottontail",

  "ears-coca": "ears-nyan",
  "ears-coca-shiny": "ears-nyan",
};

const createBodyPartsDict = () => {
  const bodyPartsDict = {};
  for (let i in BODY_PART_LIST) {
    bodyPartsDict[BODY_PART_LIST[i].partId] = BODY_PART_LIST[i];
  }
  return bodyPartsDict;
};

export const BODY_PARTS_DICT = createBodyPartsDict();
