import * as types from "store/guildDashboard/actions/actionTypes";
import { put, fork, retry, takeEvery, select } from "redux-saga/effects";
import api from "services/api";
import * as actions from "store/guildDashboard/actions/guildDashboardActions";
import { NUM_BATTLES_PER_PAGE } from "config";
import {
  getPaginationEndIndex,
  getPaginationStartIndex,
} from "helpers/display";
import { extractBlockchainAxieIdsFromBattles, sortByGameEnded } from "helpers/arena";
import * as dataActions from "store/data/dataActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 300;

export function* makeFetchBattlesDataForScholar(action) {
  try {
    yield put(
      actions.fetchBattlesDataForScholarStarted(
        action.roninAddress,
        action.guildTeamId
      )
    );

    const pvp = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestPlayerPVPBattles,
      action.roninAddress
    );

    const sortedPVP = [...pvp].sort((a, b) => sortByGameEnded(a, b));

    yield put(
      actions.fetchBattlesDataForScholarSucceeded(
        action.roninAddress,
        action.guildTeamId,
        {
          battles: {
            pvp: sortedPVP,
            pve: [],
          },
        }
      )
    );

    const recentPVPBattles = sortedPVP.slice(0, NUM_BATTLES_PER_PAGE);
    yield fork(fetchAxiesForPagePVPBattles, recentPVPBattles);
  } catch (e) {
    yield put(
      actions.fetchBattlesDataForScholarFailed(
        action.roninAddress,
        action.guildTeamId
      )
    );
  }
}

export function* fetchDataForPagePVPBattles(action) {
  try {
    const start = getPaginationStartIndex(
      action.currentPage,
      NUM_BATTLES_PER_PAGE
    );
    const end = getPaginationEndIndex(start, NUM_BATTLES_PER_PAGE);
    const battles = action.scholar.liveData.battles.pvp.slice(start, end);

    yield fork(fetchAxiesForPagePVPBattles, battles);
  } catch (e) {}
}

/* Axie data */
export function* fetchAxiesForPagePVPBattles(battles) {
  try {
    const axieData = yield select((state) => state.data.axieData);

    const uniqueIds = extractBlockchainAxieIdsFromBattles(battles);

    if (uniqueIds.length > 0) {
      for (const idx in uniqueIds) {
        if (!(uniqueIds[idx] in axieData)) {
          yield put(dataActions.fetchAxieData(uniqueIds[idx]));
        }
      }
    }
  } catch (e) {}
}

export default function* guildDashboardScholarBattlesSaga() {
  yield takeEvery(
    types.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR,
    makeFetchBattlesDataForScholar
  );
  yield takeEvery(
    types.GUILD_DASHBOARD_UPDATE_SCHOLAR_PVP_BATTLES_CURRENT_PAGE,
    fetchDataForPagePVPBattles
  );
}
