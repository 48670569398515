import * as types from "./adminActionTypes";
import { put, takeEvery, call, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./adminActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchGuildsRequest(action) {
  try {
    yield put(actions.fetchGuildsStarted());

    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGuildsAsAdmin,
      action.loginAddress
    );

    yield put(actions.fetchGuildsSucceeded(data));
  } catch (e) {
    yield put(actions.fetchGuildsFailed(e.message));
  }
}

export function* makeAddGuildRequest(action) {
  try {
    yield put(actions.addGuildStarted());

    const data = yield call(
      api.requestAddGuildAsAdmin,
      action.loginAddress,
      action.guild
    );

    const newGuild = { ...action.guild, guildId: data.guildId };

    yield put(actions.addGuildSucceeded(newGuild));

    yield call(action.onSuccess);
  } catch (e) {
    yield put(actions.addGuildFailed());
  }
}

export function* makeUpdateGuildRequest(action) {
  try {
    yield put(actions.updateGuildStarted());

    const payload = {
      name: action.guild.name,
      loginAddress: action.guild.loginAddress,
      logoUri: action.guild.logoUri,
      isAMS: action.guild.isAMS,
      isIndesCoached: action.guild.isIndesCoached,
      isEnabled: action.guild.isEnabled,
      profileImage: action.guild.profileImage,
    };

    // Upload image
    if (action.guild.avatar) {
      const { filename, presignedUrl, dataUri } = action.guild.avatar;

      yield call(api.requestUploadImageToS3, dataUri, presignedUrl);
      payload.profileImage = filename;
    }

    // Update guild
    yield call(
      api.requestUpdateGuildAsAdmin,
      action.loginAddress,
      action.guild.guildId,
      payload
    );

    yield put(
      actions.updateGuildSucceeded({
        ...payload,
        guildId: action.guild.guildId,
      })
    );

    yield call(action.onSuccess);
  } catch (e) {
    yield put(actions.updateGuildFailed());
  }
}

export function* makeRequestPresignedUrlRequest(action) {
  try {
    yield put(actions.requestPresignedUrlStarted());

    const data = yield call(
      api.requestPresignedUrl,
      action.loginAddress,
      action.guildId,
      action.filename,
      action.fileExtension
    );

    yield put(actions.requestPresignedUrlSucceeded());

    yield call(
      action.onSuccess,
      data.uploadUrl,
      action.filename,
      action.dataUri
    );
  } catch (e) {
    console.log("Error fetching presigned url: ", e);
    yield put(actions.requestPresignedUrlFailed());
  }
}

export default function* adminSaga() {
  yield takeEvery(types.ADMIN_FETCH_GUILDS, makeFetchGuildsRequest);
  yield takeEvery(types.ADMIN_ADD_GUILD, makeAddGuildRequest);
  yield takeEvery(types.ADMIN_UPDATE_GUILD, makeUpdateGuildRequest);
  yield takeEvery(
    types.ADMIN_REQUEST_PRESIGNED_URL,
    makeRequestPresignedUrlRequest
  );
}
