import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchGuilds } from "store/guildDashboardAdmin/adminActions";
import { useWeb3React } from "@web3-react/core";
import ViewAuthorized from "./organisms/ViewAuthorized";
import CustomLoader from "components/CustomLoader/CustomLoader";
import ViewFailed from "./organisms/ViewFailed";

const Admin = ({ fetchGuilds, loading, failed, unauthorized }) => {
  const { account } = useWeb3React();

  useEffect(() => {
    if (account) {
      fetchGuilds(account);
    }
  }, [account, fetchGuilds]);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : unauthorized ? (
        <ViewFailed message="Unauthorized" />
      ) : failed ? (
        <ViewFailed message="Something went wrong..." />
      ) : (
        <ViewAuthorized />
      )}
    </>
  );
};

const mapDispatchToProps = { fetchGuilds };

const mapStateToProps = (state) => ({
  loading: state.guildDashboardAdmin.guilds.fetching,
  failed: state.guildDashboardAdmin.guilds.failed,
  unauthorized: state.guildDashboardAdmin.guilds.unauthorized,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
