import React from "react";
import { Box, Typography, Stack, useTheme } from "@mui/material";
import fonts from "theme/fonts";

const Rune = ({ onClick, rune, width = 1181, style }) => {
  if (rune == null) {
    return null;
  }
  const cls = rune.class.toLowerCase();
  return (
    <Box
      onClick={onClick}
      sx={{
        width,
        height: width,
        position: "relative",
        "&::before": {
          content: "''",
          background: "#39241a78",
          filter: `blur(${(14 / 1181) * width}px)`,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: `${(180 / 1181) * width}px`,
        },
        ...style,
      }}
    >
      <RuneBackground />
      <RuneIcon image={rune.image} name={rune.name} />

      <RuneName name={rune.name} width={width} />
      <ClassIcon axieClass={cls} />
    </Box>
  );
};

const RuneBackground = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      "& img": {
        width: "100%",
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img
      src={`/images/templates/rune/background-small.png`}
      alt="Rune background"
    />
  </Box>
);

const RuneIcon = ({ image, name }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "absolute",
        top: "22%",
        height: "39.5%",
        left: 0,
        right: 0,
        zIndex: 2,
        "& img": {
          maxWidth: "40%",
          maxHeight: "100%",
          m: "0 auto",
          display: "block",
        },
      }}
    >
      <img src={image} alt={name} />
    </Stack>
  );
};

const ClassIcon = ({ axieClass }) => (
  <Box
    sx={{
      zIndex: 3,
      position: "absolute",
      top: "8%",
      left: "8%",
      width: "20.7%",
      "& img": {
        width: "100%",
      },
    }}
  >
    <img
      src={`/images/class-icons/${axieClass}.png`}
      alt={`${axieClass} class charm`}
    />
  </Box>
);

const RuneName = ({ name, width }) => {
  const theme = useTheme();

  return (
    <Typography
      textAlign="center"
      fontSize={(118 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      sx={{
        position: "absolute",
        maxWidth: "90%",
        margin: "0 auto",
        left: 0,
        right: 0,
        color: theme.palette.colors.origin.wood.background,
        top: "68%",
        lineHeight: 1.15,
        zIndex: 3,
      }}
    >
      {name}
    </Typography>
  );
};

export default Rune;
