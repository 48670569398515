import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: (theme) => theme.palette.colors.origin.wood.backgroundDark,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.spacing(2),
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "-4px",
  gap: theme.spacing(0.5),
}));

const FavoriteTeam = ({ favoriteTeam, showCharms }) => {
  return favoriteTeam == null ? (
    <></>
  ) : (
    <Wrapper>
      {favoriteTeam.map((axie, i) => (
        <AxieV3
          variant="image"
          axie={axie}
          width={58}
          key={`AXIE_${i}`}
          disableMarketplaceLink={true}
          showCharms={showCharms}
          showRune={true}
        />
      ))}
    </Wrapper>
  );
};

export default FavoriteTeam;
