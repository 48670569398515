import React from "react";
import { LinearProgress, useTheme } from "@mui/material";

const CustomLinearProgress = ({ value, color, width = "100px" }) => {
  const theme = useTheme();
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: 10,
        borderRadius: 5,
        width,
        backgroundColor: theme.palette.axieCard.statBackground,
        [`& .MuiLinearProgress-bar`]: {
          borderRadius: 5,
          backgroundColor: color,
        },
      }}
    />
  );
};

export default CustomLinearProgress;
