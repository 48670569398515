// import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Page from "components/AxieQuest/Layout/Page";
// import Skills from "pages/AxieQuest/Explorer/organisms/Skills";
// import Controls from "pages/AxieQuest/Explorer/organisms/Controls";
import {
  fetchAxieQuestCards,
  updateControls,
} from "store/axieQuest/explorer/action";
import {
  getAxieQuestCardsData,
  getLoading,
  getFailed,
} from "store/axieQuest/explorer/selectors";
// import CustomLoader from "components/CustomLoader/CustomLoader";
// import styled from "@emotion/styled";
// import { Stack } from "@mui/material";
// import Failed from "./organisms/Failed";
// import NoResults from "./organisms/NoResults";

// const Wrapper = styled(Stack)(({ theme }) => ({
//   backgroundColor: "#ffffff",
//   gap: theme.spacing(10),
//   padding: "32px 24px",
//   minHeight: "80vh",
// }));

const Explorer = ({
  fetchAxieQuestCards,
  data,
  loading,
  failed,
  updateControls,
}) => {
  // useEffect(() => {
  //   fetchAxieQuestCards();
  // }, [fetchAxieQuestCards]);

  // const handleUpdateControls = useCallback(
  //   (key) => (val) => {
  //     updateControls({ ...data.controls, [key]: val });
  //   },
  //   [updateControls, data.controls]
  // );

  return (
    <Page
    // controls={
    //   <Controls
    //     width={400}
    //     controls={data.controls}
    //     handleUpdateControls={handleUpdateControls}
    //   />
    // }
    >
      {/* <Wrapper> */}
      {/* {loading ? (
          <CustomLoader
            variant="axie-quest"
            marginTop={100}
            marginBottom={100}
          />
        ) : data?.cards.length === 0 && data.hasFiltered ? (
          <NoResults />
        ) : failed ? (
          <Failed fetchAxieQuestCards={fetchAxieQuestCards} />
        ) : (
          <Skills cards={data.cards} hasFiltered={data.hasFiltered} />
        )} */}
      {/* </Wrapper> */}
    </Page>
  );
};

const mapDispatchToProps = {
  fetchAxieQuestCards,
  updateControls,
};

const mapStateToProps = (state) => ({
  data: getAxieQuestCardsData(state),
  loading: getLoading(state),
  failed: getFailed(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);
