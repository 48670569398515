import * as types from "./guildActionTypes";

export const fetchGuild = (guildSlug) => ({
  type: types.FETCH_GUILD,
  guildSlug,
});

export const fetchGuildStarted = () => ({
  type: types.FETCH_GUILD_STARTED,
});

export const fetchGuildFailed = () => ({
  type: types.FETCH_GUILD_FAILED,
});

export const fetchGuildSucceeded = (payload) => ({
  type: types.FETCH_GUILD_SUCCEEDED,
  payload,
});
