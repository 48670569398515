import React from "react";
import {
  Stack,
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Button,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import { getBodyPartIcon } from "helpers/axie";
import Label from "components/Typography/Label";
import { IoSearch, IoCloseCircle } from "react-icons/io5";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";

const AxiePartFilter = ({
  cards,
  parts,
  setParts,
  matchD,
  setMatchD,
  matchR1,
  setMatchR1,
  matchR2,
  setMatchR2,
  individualPartMatches,
  setIndividualPartMatches,
}) => {
  const theme = useTheme();
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.partName + option.cardName,
  });

  const handleDelete = (partId) => {
    setParts(parts.filter((p) => p.partId !== partId));
  };

  const handleChange = (e, newValue) => {
    setParts(newValue);
  };

  if (!cards) {
    return <></>;
  }

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1}>
          <Label>Parts</Label>
          <Autocomplete
            multiple
            disableCloseOnSelect={true}
            value={parts}
            noOptionsText="No results"
            onChange={handleChange}
            options={cards}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.partName}
            renderOption={(props, option) => {
              return (
                <li key={option.partId}>
                  <PartRow props={props} option={option} />
                </li>
              );
            }}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search parts and abilities"
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        marginLeft: "8px",
                        marginTop: "0px !important",
                        "& svg": {
                          fontSize: "18px",
                        },
                      }}
                    >
                      <IoSearch />
                    </InputAdornment>
                  ),
                  onKeyDown: (e) => {
                    if (e.key === "f" || e.key === "F") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
            ListboxProps={{
              sx: {
                padding: 2,
                "& .MuiAutocomplete-option": {
                  justifyContent: "space-between",
                  py: 1,
                  my: 0.5,
                },
                [theme.breakpoints.down("sm")]: {
                  p: 1,
                },
              },
            }}
            sx={{
              ".MuiFilledInput-root": {
                paddingTop: "8px",
                paddingBottom: "4px",
              },
            }}
          />
        </Stack>

        <Stack gap={2}>
          <Stack sx={{ maxHeight: "600px", overflowY: "auto" }} gap={2}>
            {parts.map((part, i) => (
              <PartRowSelected
                option={part}
                key={part.partId}
                handleDelete={() => handleDelete(part.partId)}
                individualPartMatches={individualPartMatches}
                setIndividualPartMatches={setIndividualPartMatches}
              />
            ))}
          </Stack>

          {parts.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  gap: 1,
                },
              }}
            >
              <Label>Global: </Label>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    flexBasis: "100%",
                  },
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setMatchD(!matchD)}
                  >
                    Match D
                  </Button>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setMatchR1(!matchR1)}
                  >
                    Match R1
                  </Button>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setMatchR2(!matchR2)}
                  >
                    Match R2
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

const PartRow = ({ props, option, handleDelete }) => {
  const theme = useTheme();
  return (
    <Stack
      {...props}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={4}
      sx={{
        width: "100%",
        borderRadius: "8px",
        py: 1,
        lineHeight: 1,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 1,
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {handleDelete !== undefined && (
          <IconButton size="small" onClick={() => handleDelete(option.partId)}>
            <IoCloseCircle />
          </IconButton>
        )}
        <Icon bodyPart={option.partId.split("-")[0]} axieClass={option.class} />
        <Name part={option.partName} card={option.cardName} />
      </Stack>
      <Description description={option.cardText} />
    </Stack>
  );
};

const PartRowSelected = ({
  props,
  option,
  handleDelete,
  individualPartMatches,
  setIndividualPartMatches,
}) => {
  const theme = useTheme();
  return (
    <Stack
      {...props}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={4}
      sx={{
        width: "100%",
        borderRadius: "8px",
        py: 1,
        lineHeight: 1,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 1,
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <IconButton onClick={() => handleDelete(option.partId)}>
          <IoCloseCircle />
        </IconButton>
        <Icon bodyPart={option.partId.split("-")[0]} axieClass={option.class} />
        <Name part={option.partName} card={option.cardName} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack direction="row" alignItems="center">
          <CustomCheckbox
            checked={individualPartMatches[option.partId]?.d || false}
            onChange={() => setIndividualPartMatches(option.partId, "d")}
          />
          <Label>D</Label>
        </Stack>
        <Stack direction="row" alignItems="center">
          <CustomCheckbox
            checked={individualPartMatches[option.partId]?.r1 || false}
            onChange={() => setIndividualPartMatches(option.partId, "r1")}
          />
          <Label>R1</Label>
        </Stack>
        <Stack direction="row" alignItems="center">
          <CustomCheckbox
            checked={individualPartMatches[option.partId]?.r2 || false}
            onChange={() => setIndividualPartMatches(option.partId, "r2")}
          />
          <Label>R2</Label>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Icon = ({ bodyPart, axieClass }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      width: "30px",
      "& svg": {
        height: "32px",
        width: "auto",
      },
    }}
  >
    {getBodyPartIcon(bodyPart, axieClass)}
  </Stack>
);

const Name = ({ part, card }) => (
  <Stack gap={0.5} sx={{ minWidth: "160px" }}>
    <Typography
      variant="span"
      fontSize="16px"
      fontWeight="700"
      color="text.bright"
    >
      {part}
    </Typography>
    <Typography
      variant="span"
      fontSize="16px"
      fontWeight="500"
      color="text.bright"
    >
      {card}
    </Typography>
  </Stack>
);

const Description = ({ description }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="span"
      fontSize="14px"
      fontWeight="500"
      color="text.muted"
      textAlign="right"
      sx={{
        lineHeight: 1.3,
        [theme.breakpoints.down("sm")]: {
          textAlign: "left",
        },
      }}
    >
      {description}
    </Typography>
  );
};

export default AxiePartFilter;
