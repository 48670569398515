import React from "react";
import { Stack, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { AXIE_TECH_TWITTER, TRIBALLY_DISCORD } from "config";
import { FaDiscord, FaTwitter } from "react-icons/fa";

const SocialIcons = ({ gap = 1, size = "18px", centered, color }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={gap}
      justifyContent={centered ? "center" : "flex-start"}
    >
      <Link to={AXIE_TECH_TWITTER} target="_blank">
        <IconButton sx={{ fontSize: size, color }}>
          <FaTwitter />
        </IconButton>
      </Link>
      <Link to={TRIBALLY_DISCORD} target="_blank" className="icon">
        <IconButton sx={{ fontSize: size, color }}>
          <FaDiscord />
        </IconButton>
      </Link>
    </Stack>
  );
};

export default SocialIcons;
