import React from "react";
import { connect } from "react-redux";
import EthereumPrice from "components/EthereumPrice/EthereumPrice";
import { roundToNumDecimals, formatUSD } from "helpers/display";
import * as breedingCalculatorSelectors from "store/breedingCalculator/breedingCalculatorSelectors";
import Label from "components/Typography/Label";
import styles from "./summary.module.scss";
import HelperText from "./HelperText";
import { Stack, useTheme } from "@mui/material";
import PriceUSD from "./PriceUSD";

const Result = ({ totalProfitLossETH, totalProfitLossUSD, profitPercent }) => {
  const theme = useTheme();

  return (
    <div
      className={`${styles.bigSection} ${
        totalProfitLossETH < 0 ? styles.loss : styles.profit
      }`}
    >
      <Label color={theme.palette.colors.black}>
        {totalProfitLossETH < 0 ? "Estimated Loss" : "Estimated Profit"}
      </Label>
      <div className={styles.flexPrices}>
        <EthereumPrice
          large={true}
          price={roundToNumDecimals(totalProfitLossETH)}
          formatEth={false}
          color={theme.palette.colors.black}
        />
        <PriceUSD large="true" color="rgba(0,0,0,.5)">
          {formatUSD(totalProfitLossUSD)}
        </PriceUSD>
      </div>
      <Stack gap={1} alignItems="center">
        <span className={styles.description}>Total Sales - Total Costs</span>
        <HelperText>% Profit: {(profitPercent * 100).toFixed(2)}%</HelperText>
      </Stack>
    </div>
  );
};

const StarterMessage = ({ title, description }) => (
  <div className={styles.starterMessage}>
    <Label color="#fff" size="14px">
      {title}
    </Label>
    <span className={styles.description}>{description}</span>
  </div>
);

const ProfitLoss = ({
  totalProfitLossETH,
  totalProfitLossUSD,
  profitPercent,
  message,
  firstLoad,
}) => {
  if (firstLoad) {
    return (
      <StarterMessage
        title="Getting Started"
        description="Enter your breeding details above to calculate expected profit/loss"
      />
    );
  }
  if (message) {
    return <StarterMessage title="Invalid Breed" description={message} />;
  }
  return (
    <Result
      totalProfitLossETH={totalProfitLossETH}
      totalProfitLossUSD={totalProfitLossUSD}
      profitPercent={profitPercent}
    />
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    summary: breedingCalculatorSelectors.generateBreedingSummary(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitLoss);
