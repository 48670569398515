import React from "react";
import ClickableSlotCondensed from "./ClickableSlotCondensed";
import ClickableCardName from "./ClickableCardName";
import { getColorForAxieClass } from "helpers/axie";
import { ITEM_WIDTH } from "pages/TeamBuilder";
import ClickableItem from "./ClickableItem";
import CardTemplate from "components/GameTemplates/CardTemplate";
import ClickableSlot from "./ClickableSlot";

const CardSlot = ({
  view,
  data,
  axie,
  part,
  updateCard,
  updateCharm,
  handleOpenCardDialog,
}) => {
  const deleteCard = (part) => () => {
    updateCharm(axie.id, part, undefined);
    updateCard(axie.id, part, undefined);
  };
  const handleOpen = () => {
    handleOpenCardDialog(axie, part);
  };

  if (view === "condensed") {
    return data == null ? (
      <ClickableSlotCondensed onClick={handleOpen} />
    ) : (
      <ClickableCardName
        onClick={handleOpen}
        data={data}
        value={data.cardName}
        color={getColorForAxieClass(data.class)}
        deleteItem={deleteCard(part)}
      />
    );
  }

  return data == null ? (
    <ClickableSlot onClick={handleOpen} part={part} itemType="card" />
  ) : (
    <ClickableItem
      onClick={handleOpen}
      deleteItem={deleteCard(part)}
      data={data}
    >
      <CardTemplate card={data} width={ITEM_WIDTH} style={{ pt: 0, pl: 0 }} />
    </ClickableItem>
  );
};

export default CardSlot;
