import React from "react";
import { Pagination as MuiPagination } from "@mui/material";

const Pagination = ({ numberOfPages, handlePageChange, currentPage }) => {
  return (
    numberOfPages > 1 && (
      <MuiPagination
        count={numberOfPages}
        onChange={handlePageChange}
        page={currentPage}
        shape="rounded"
      />
    )
  );
};

export default Pagination;
