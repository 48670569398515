import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Sidekick from "../molecules/Sidekick";
import Pagination from "components/DoLL/Layout/Pagination";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(10),
}));

const Sidekicks = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(3),
  justifyContent: "center",
  flexWrap: "wrap",
}));

const SidekickShowcase = ({
  controls,
  handleUpdateControls,
  sidekicks,
  handleInspectSidekick,
}) => {
  return (
    <Wrapper>
      <Sidekicks>
        {sidekicks.map((sidekick) => (
          <Sidekick
            key={sidekick.tokenId}
            {...sidekick}
            handleInspectSidekick={handleInspectSidekick}
          />
        ))}
      </Sidekicks>

      <Pagination
        variant="dark"
        onChange={(e, v) => handleUpdateControls("currentPage")(v)}
        count={controls.numberOfPages}
        page={controls.currentPage}
      />
    </Wrapper>
  );
};

export default SidekickShowcase;
