import React from "react";
import { Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const LinkedWithScroll = ({ children, to, href, ...props }) => {
  const location = useLocation();
  const handleClick = (to) => {
    if (to === location.pathname) {
      window.scrollTo(0, 0);
    }
  };

  return to != null ? (
    <Link
      to={to}
      component={RouterLink}
      onClick={() => handleClick(to)}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <Link href={href} target="_blank" {...props}>
      {children}
    </Link>
  );
};

export default LinkedWithScroll;
