import React from "react";
import { Stack, Typography } from "@mui/material";
import Card from "components/Card/Card";
import CustomLink from "components/CustomLink/CustomLink";

const GuildError = () => {
  return (
    <Card outline={true}>
      <Stack gap={2} alignItems="center">
        <Typography
          fontSize="28px"
          color="text.regular"
          fontWeight="700"
          textAlign="center"
          variant="h2"
        >
          Hmm...we can't find that guild.
        </Typography>
        <Typography
          fontSize="20px"
          color="text.regular"
          textAlign="center"
          variant="body1"
        >
          Would you like to check out the{" "}
          <CustomLink to="/guild-leaderboards" endSpace={false}>
            Guild Leaderboards
          </CustomLink>
          ?
        </Typography>
      </Stack>
    </Card>
  );
};

export default GuildError;
