import * as types from "./actionTypes";

export const fetchAxieQuestCards = () => ({
  type: types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS,
});

export const fetchAxieQuestCardsStarted = () => ({
  type: types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_STARTED,
});

export const fetchAxieQuestCardsSucceeded = (data) => ({
  type: types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_SUCCEEDED,
  data,
});

export const fetchAxieQuestCardsFailed = () => ({
  type: types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_FAILED,
});

export const updateControls = (controls) => ({
  type: types.AXIE_QUEST_EXPLORER_UPDATE_CONTROLS,
  controls,
});
