import { Box } from "@mui/material";

const DecorativePattern = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "12%",
        left: 0,
        right: 0,
        img: {
          width: "100%",
        },
      }}
    >
      <img
        src="/images/accents/pattern-decorative-light.png"
        alt="Decorative Pattern"
      />
    </Box>
  );
};

export default DecorativePattern;
