import React from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  ".MuiFilledInput-root": {
    lineHeight: "13px",
    backgroundColor: "transparent !important",
    "&:hover, &:focus, &:active, &.Mui-focused": {
      boxShadow: "none",
      borderColor: "transparent",
      backgroundColor: "transparent !important",
    },
  },
  ".MuiSelect-select": { minHeight: "0 !important", paddingBottom: "5px" },
  ".MuiInputBase-input:focus": { backgroundColor: "transparent !important" },
  ".MuiInputBase-input": { backgroundColor: "transparent !important" },
  ".MuiSelect-filled": {
    padding: "0 16px 0 0 !important",
    fontWeight: 700,
    letterSpacing: "0.5px",
    color: theme.palette.text.muted,
    fontSize: 13,
    textTransform: "uppercase",
    minHeight: "0",
  },
  ".MuiSelect-iconFilled": {
    right: "-2px",
    top: "-4px",
    width: "20px",
    height: "20px",
    path: { fill: theme.palette.text.muted },
  },
}));

function isObject(o) {
  return o instanceof Object && o.constructor === Object;
}

export const CustomSelectMinimal = ({ val, setVal, label, options, cls }) => {
  return (
    <Wrapper>
      <FormControl variant="filled" size="small" className={cls ? cls : ""}>
        {label ? <InputLabel id="select-label">{label}</InputLabel> : <></>}
        <Select
          labelId="select-label"
          value={val}
          onChange={(e) => setVal(e.target.value)}
          label={label}
          disableUnderline={true}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: 0.5,
                borderRadius: 3,
                boxShadow: "0px 4px 8px rgba(0,0,0,.2)",
                "& .MuiMenuItem-root": {
                  fontSize: 15,
                  fontWeight: 600,
                },
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={
                isObject(option) && "value" in option ? option.value : option
              }
              value={
                isObject(option) && "value" in option ? option.value : option
              }
            >
              {isObject(option) && "label" in option ? option.label : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  );
};

export default CustomSelectMinimal;
