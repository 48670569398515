import React from "react";
import { RiPencilFill } from "react-icons/ri";
import { Tooltip, Stack, IconButton } from "@mui/material";

const ActionButtons = ({ guild, handleOpenEditGuild }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Tooltip title="Edit guild">
        <IconButton onClick={() => handleOpenEditGuild(guild)} size="small">
          <RiPencilFill />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ActionButtons;
