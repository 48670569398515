import React from "react";
import Page from "components/Utility/Page";
import { Typography, Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IoCaretForwardCircleOutline } from "react-icons/io5";

const Page404 = () => {
  return (
    <Page>
      <Stack gap={2} alignItems="flex-start">
        <Typography variant="h1" color="text.bright">
          Wer content?
        </Typography>
        <Typography variant="body1" color="text.regular">
          We searched high and low but couldn't find the page you were looking
          for.
        </Typography>
        <Button
          component={RouterLink}
          to="/"
          variant="contained"
          endIcon={<IoCaretForwardCircleOutline />}
        >
          Go Home
        </Button>
      </Stack>
    </Page>
  );
};

export default Page404;
