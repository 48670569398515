import { Stack, Typography } from "@mui/material";
import InterDungeonTooltip from "components/InterDungeon/InterDungeonCard/atoms/InterDungeonTooltip";

const Reuses = ({ reuses, width, name }) => {
  return (
    <InterDungeonTooltip
      title={`Reuse ${name} ${reuses} times before discarding`}
    >
      <Stack alignItems="center" justifyContent="center" position="relative">
        <img
          alt={`Reuse ${name} ${reuses} times before discarding}`}
          src={"/images/games/inter-the-dungeon/icons/reuse.png"}
          width={(width / 200) * 27}
          height="auto"
        />
        <Typography
          position="absolute"
          align="center"
          component="span"
          sx={{
            left: 0,
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            color: "#fff",
            fontSize: (width / 200) * 12,
            fontWeight: 700,
            width: "100%",
          }}
        >
          {reuses}
        </Typography>
      </Stack>
    </InterDungeonTooltip>
  );
};

export default Reuses;
