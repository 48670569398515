import * as types from "store/guildDashboard/actions/actionTypes";
import {
  put,
  call,
  takeLatest,
  takeEvery,
  fork,
  select,
  take,
  retry,
} from "redux-saga/effects";
import { axieTechAPI } from "store/sagaHelpers";
import api from "services/api";
import * as actions from "store/guildDashboard/actions/guildDashboardActions";
import endpoints from "services/api/endpoints";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* addTeam(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_ADD_TEAM_STARTED,
    });
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        ...action.teamData,
      }),
    };
    const payload = yield call(
      axieTechAPI,
      endpoints.constructGuildTeam(action.guildId),
      params
    );
    yield put({
      type: types.GUILD_DASHBOARD_ADD_TEAM_SUCCEEDED,
      guildTeamId: payload.guildTeamId,
      guildId: action.guildId,
      teamData: action.teamData,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_ADD_TEAM_FAILED,
    });
  }
}

export function* deleteTeam(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_DELETE_TEAM_STARTED,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        guildTeamId: action.guildTeamId,
      }),
    };
    yield call(
      axieTechAPI,
      endpoints.constructGuildTeam(action.guildId),
      params
    );
    yield put({
      type: types.GUILD_DASHBOARD_DELETE_TEAM_SUCCEEDED,
      guildTeamId: action.guildTeamId,
      scholars: action.scholars,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_DELETE_TEAM_FAILED,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* editTeam(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_EDIT_TEAM_STARTED,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        guildTeamId: action.guildTeamId,
        ...action.teamData,
      }),
    };
    yield call(
      axieTechAPI,
      endpoints.constructGuildTeam(action.guildId),
      params
    );
    yield put({
      type: types.GUILD_DASHBOARD_EDIT_TEAM_SUCCEEDED,
      guildTeamId: action.guildTeamId,
      teamData: action.teamData,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_EDIT_TEAM_FAILED,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* fetchTeam(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_TEAM_STARTED,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        guildTeamId: action.guildTeamId,
      }),
    };
    const response = yield call(axieTechAPI, endpoints.guildTeamLogin, params);
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_TEAM_SUCCEEDED,
      guildTeamId: action.guildTeamId,
      teamData: response,
    });
    return response;
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_TEAM_FAILED,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* monitorTeamSelector(guildTeamId) {
  while (true) {
    const teams = yield select((state) => state.guildDashboard.guild.teams);
    const team = teams.find((team) => team.guildTeamId === guildTeamId);

    if (team.fetchingTeam === false) {
      return team.scholars ?? [];
    }
    yield take("*");
  }
}

export function* fetchTeams(teams, loginAddress) {
  try {
    yield put({ type: types.GUILD_DASHBOARD_FETCH_TEAMS_STARTED });

    for (const team in teams) {
      if (teams[team].guildTeamId !== 0) {
        yield call(fetchTeam, {
          guildTeamId: teams[team].guildTeamId,
          loginAddress: loginAddress,
        });
      }
    }

    yield put({ type: types.GUILD_DASHBOARD_FETCH_TEAMS_SUCCEEDED });
  } catch (e) {
    console.log("Error fetching teams");
    yield put({ type: types.GUILD_DASHBOARD_FETCH_TEAMS_FAILED });
  }
}

export function* monitorScholarAxies(roninAddress) {
  while (true) {
    const scholar = yield select((state) =>
      state.guildDashboard.guild.scholars.find(
        (scholar) => scholar.roninAddress === roninAddress
      )
    );

    if (scholar && scholar.axies) {
      return scholar.axies;
    }
    yield take("*");
  }
}

export function* fetchAxieDetails(roninAddress, axies) {
  try {
    for (const idx in axies) {
      yield fork(
        makeFetchRoninAxieDetailRequest,
        roninAddress,
        axies[idx].axieId
      );
    }
  } catch (e) {}
}

export function* makeFetchRoninAxieDetailRequest(roninAddress, axieId) {
  try {
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGetAxieDetail,
      axieId
    );
    yield put(actions.fetchAxieDetailSucceeded(roninAddress, data));
  } catch (e) {}
}

export default function* guildDashboardTeamsSaga() {
  yield takeLatest(types.GUILD_DASHBOARD_ADD_TEAM, addTeam);
  yield takeLatest(types.GUILD_DASHBOARD_DELETE_TEAM, deleteTeam);
  yield takeLatest(types.GUILD_DASHBOARD_EDIT_TEAM, editTeam);
  yield takeEvery(types.GUILD_DASHBOARD_FETCH_TEAM, fetchTeam);
}
