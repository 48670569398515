import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AxieClassButtons from "components/Axie/Controls/AxieClassButtons";
import {
  fetchRecentlyListed,
  clearRecentlyListed,
  deleteRecentlyListedAxie,
} from "store/recently/recentlyActions";
import { cleanAxieClass } from "helpers/cleaning";
import { axieMatchesCriteria } from "helpers/axie";
import RefreshButton from "../RefreshButton";
import Axies from "../Axies";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";
import ControlPanel from "../ControlPanel";
import { formatEthPrice } from "helpers/display";
import { Grid, Stack } from "@mui/material";
import { AXIE_STATS } from "config";
import FilterAxieStat from "components/Axie/Controls/FilterAxieStat";
import FilterAxieBreedCount from "components/Axie/Controls/FilterAxieBreedCount";
import FilterAxiePurity from "components/Axie/Controls/FilterAxiePurity";
import FilterAxiePrice from "components/Axie/Controls/FilterAxiePrice";
import FilterAxiePurityPercent from "components/Axie/Controls/FilterAxiePurityPercent";

const ListedContent = ({
  axies,
  fetching,
  failed,
  fetchRecentlyListed,
  clearRecentlyListed,
  deleteRecentlyListedAxie,
}) => {
  const [axieClassFilter, setAxieClassFilter] = useState("All");
  const [breedCount, setBreedCount] = useState([]);
  const [purity, setPurity] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [hp, setHP] = useState(27);
  const [skill, setSkill] = useState(27);
  const [speed, setSpeed] = useState(27);
  const [morale, setMorale] = useState(27);
  const [purityPercent, setPurityPercent] = useState(0);
  const [filteredAxies, setFilteredAxies] = useState([]);
  const navigate = useNavigate();
  const { axieClass } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (axieClass != null) {
      const cleanedClass = cleanAxieClass(axieClass);
      if (cleanedClass !== "") {
        setAxieClassFilter(cleanedClass);
      }
    } else {
    }
  }, [axieClass]);

  const handleSetAxieClassFilter = (axieClass) => {
    navigate("/recently-listed/" + axieClass.toLowerCase());
    setAxieClassFilter(axieClass);
  };

  const handleDeleteRecentlyListedAxie = (axieId) => {
    deleteRecentlyListedAxie(axieId);
  };

  useEffect(() => {
    fetchRecentlyListed();
    setFirstLoad(false);
  }, [fetchRecentlyListed]);

  useEffect(() => {
    setFilteredAxies(axies);
  }, [axies]);

  useEffect(() => {
    const applyFilter = (axies) => {
      const filtered = [];
      axies.forEach((axie) => {
        if (
          axieMatchesCriteria([
            {
              key: "AXIE_CLASS",
              args: [axie.class, axieClassFilter],
            },
            {
              key: "BREED_COUNT",
              args: [axie.breedCount.toString(), breedCount],
            },
            {
              key: "PURITY_COUNT",
              args: [
                axie.purity ? axie.purity.purityCount.toString() : undefined,
                purity,
              ],
            },
            {
              key: "STAT",
              args: [axie.stats.hp, hp],
            },
            {
              key: "STAT",
              args: [axie.stats.skill, skill],
            },
            {
              key: "STAT",
              args: [axie.stats.morale, morale],
            },
            {
              key: "STAT",
              args: [axie.stats.speed, speed],
            },
            {
              key: "PURITY_PERCENT",
              args: [
                axie.purity ? axie.purity.purityPercent : undefined,
                purityPercent,
              ],
            },
            {
              key: "PRICE_RANGE",
              args: [
                formatEthPrice(axie.auction?.currentPrice),
                minPrice,
                maxPrice,
              ],
            },
          ])
        ) {
          filtered.push(axie);
        }
      });
      return filtered;
    };

    setFilteredAxies(applyFilter(axies));
  }, [
    breedCount,
    purity,
    minPrice,
    maxPrice,
    hp,
    skill,
    speed,
    morale,
    purityPercent,
    axies,
    axieClassFilter,
  ]);

  return (
    <>
      <ControlPanel
        title="Recently Listed"
        description="The 20 most recently listed auctions. Click Refresh to fetch the newest
                auctions and add them to the top of the list."
      >
        <RefreshButton fetch={fetchRecentlyListed} loading={fetching} />
        <AxieClassButtons
          filter={axieClassFilter}
          setFilter={handleSetAxieClassFilter}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <FilterAxieBreedCount
                  breedCount={breedCount}
                  setBreedCount={setBreedCount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FilterAxiePurity purity={purity} setPurity={setPurity} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FilterAxiePrice
                  price={minPrice}
                  setPrice={setMinPrice}
                  label="Min Price"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FilterAxiePrice
                  price={maxPrice}
                  setPrice={setMaxPrice}
                  label="Max Price"
                />
              </Grid>
              <Grid item xs={12}>
                <FilterAxiePurityPercent
                  purityPercent={purityPercent}
                  setPurityPercent={setPurityPercent}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Stack gap={1}>
              <FilterAxieStat
                stat={hp}
                setStat={setHP}
                statDef={AXIE_STATS.HEALTH}
              />
              <FilterAxieStat
                stat={speed}
                setStat={setSpeed}
                statDef={AXIE_STATS.SPEED}
              />
              <FilterAxieStat
                stat={skill}
                setStat={setSkill}
                statDef={AXIE_STATS.SKILL}
              />
              <FilterAxieStat
                stat={morale}
                setStat={setMorale}
                statDef={AXIE_STATS.MORALE}
              />
            </Stack>
          </Grid>
        </Grid>
      </ControlPanel>
      <DisplayLoadingOrContent
        loading={firstLoad}
        failed={failed}
        retry={fetchRecentlyListed}
        data={filteredAxies}
        failedMessage="Unable to load axies"
      >
        <Axies
          axies={filteredAxies}
          clear={clearRecentlyListed}
          deleteAxie={handleDeleteRecentlyListedAxie}
        />
      </DisplayLoadingOrContent>
    </>
  );
};

const mapDispatchToProps = {
  fetchRecentlyListed,
  clearRecentlyListed,
  deleteRecentlyListedAxie,
};

const mapStateToProps = (state) => ({
  axies: state.recently.recentlyListed.axies,
  fetching: state.recently.recentlyListed.fetching,
  failed: state.recently.recentlyListed.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListedContent);
