import React from "react";
import { Tooltip, IconButton, Stack } from "@mui/material";
import { HiRefresh } from "react-icons/hi";
import CopyAddress from "components/Typography/CopyAddress";
import WatchIcon from "../../../atoms/WatchIcon";

const PlayerControls = ({ scholar, handleFetchAxiesForScholar }) => {
  return (
    <Stack gap={2}>
      <CopyAddress address={scholar.roninAddress} />

      <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
        <Tooltip title="Refresh axies">
          <IconButton onClick={handleFetchAxiesForScholar} size="small">
            <HiRefresh />
          </IconButton>
        </Tooltip>
        <WatchIcon scholar={scholar} />
      </Stack>
    </Stack>
  );
};

export default PlayerControls;
