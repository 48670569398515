import React from "react";
import { Typography, Stack } from "@mui/material";

const Title = () => {
  return (
    <Stack gap={1}>
      <Typography variant="h1" color="text.bright" fontSize="20px">
        Breeding Simulator
      </Typography>
      <Typography
        fontSize="15px"
        color="text.muted"
        variant="body2"
        sx={{ lineHeight: 1.3 }}
      >
        Enter the axie IDs that you would like to breed. Or, import axies from a
        ronin address. All possible pairs will be simulated.
      </Typography>
    </Stack>
  );
};

export default Title;
