import React from "react";
import { TextField } from "@mui/material";
import styles from "./inputs.module.scss";
import Label from "components/Typography/Label";

export const ChildSaleInputs = ({
  numberOfBreeds,
  arrayOfChildFuncs,
  handleUpdate,
}) => {
  return (
    <div className={styles.childSalesWrapper}>
      {Array(parseInt(numberOfBreeds))
        .fill()
        .map((x, i) => i)
        .map((breedNum) => (
          <div key={breedNum} className={styles.childSales}>
            <Label>Child {breedNum + 1}</Label>
            <TextField
              onChange={(e) => {
                arrayOfChildFuncs[breedNum]["setChildSale"](e.target.value);
                handleUpdate();
              }}
              label="Sale price (ETH)"
              type="number"
              variant="filled"
              size="small"
              value={arrayOfChildFuncs[breedNum]["childSale"]}
              className={styles.childSaleInput}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default ChildSaleInputs;
