import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-7-15</Date>
      <Change type="new">
        Updated our{" "}
        <CustomLink to="/breeding-simulator">Breeding Simulator</CustomLink>
        to help you plan your breeds during the Summer Breeding Event!
      </Change>
    </Entry>
  );
};

export default Changes;
