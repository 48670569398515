import { ActionTypes } from "./leaderboardActions";
import produce from "immer";
import { UpdateControlsAction } from "./leaderboardActions";
import { Charm, Rune } from "types";

export interface OriginsLeaderboardControls {
  searchText: string;
  runes: Rune[];
  charms: Charm[];
}

interface OriginsLeaderboardState {
  controls: OriginsLeaderboardControls;
}

type OriginsLeaderboardAction = UpdateControlsAction;

const initialState: OriginsLeaderboardState = {
  controls: {
    searchText: "",
    runes: [],
    charms: [],
  },
};

export const originLeaderboardReducer = (
  state = initialState,
  action: OriginsLeaderboardAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.ORIGINS_LEADERBOARD_UPDATE_CONTROLS:
        draft.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default originLeaderboardReducer;
