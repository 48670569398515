import React from "react";
import styles from "./testimonial.module.scss";
import { BsFillChatQuoteFill } from "react-icons/bs";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { Typography, useTheme, Stack } from "@mui/material";

const Testimonial = ({ text, author, bio }) => {
  const theme = useTheme();

  return (
    <Stack
      gap={2}
      className={styles.testimonial}
      style={{
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        borderColor: theme.palette.card.divider,
      }}
    >
      <Typography variant="body2" fontWeight="600" color="text.regular">
        <Typography
          variant="span"
          className={styles.quote}
          color="text.veryMuted"
        >
          <ImQuotesLeft />
        </Typography>
        {text}
        <Typography
          variant="span"
          className={styles.quote}
          color="text.veryMuted"
        >
          <ImQuotesRight />
        </Typography>
      </Typography>
      <Stack gap={1}>
        <Typography
          variant="span"
          fontSize="20px"
          fontWeight="700"
          color="text.secondary"
          display="block"
        >
          {author}
        </Typography>
        <Typography
          variant="span"
          fontSize="16px"
          fontWeight="600"
          color="text.muted"
        >
          {bio}
        </Typography>
      </Stack>
      <Typography
        variant="span"
        className={styles.decorative}
        color="text.primary"
      >
        <BsFillChatQuoteFill />
      </Typography>
    </Stack>
  );
};

export default Testimonial;
