import React from "react";
import { Stack, useTheme, Button, IconButton, Grid } from "@mui/material";
import AxiePartFilter from "components/Axie/Controls/AxiePartFilter";
import FilterAxieClass from "components/Axie/Controls/FilterAxieClass";
import FilterAxieBreedCount from "components/Axie/Controls/FilterAxieBreedCount";
import FilterAxiePurity from "components/Axie/Controls/FilterAxiePurity";
import FilterAxieStat from "components/Axie/Controls/FilterAxieStat";
import { AXIE_ROLES, AXIE_SPECIALTIES, AXIE_STATS } from "config";
import { IoClose } from "react-icons/io5";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import PopupMenu from "pages/GuildDashboard/atoms/PopupMenu";

const FilterAxiesMenu = ({
  resetAxieFilters,
  updateAxiesShowGenes,
  axieFiltersEl,
  setAxieFiltersEl,
  axieFiltersOpen,
  showGenes,
  axieClasses,
  updateAxiesAxieClasses,
  purity,
  updateAxiesPurity,
  breedCount,
  updateAxiesBreedCount,
  role,
  updateAxiesRole,
  specialty,
  updateAxiesSpecialty,
  hp,
  updateAxiesHP,
  speed,
  updateAxiesSpeed,
  skill,
  updateAxiesSkill,
  morale,
  updateAxiesMorale,
  purityPercent,
  updateAxiesPurityPercent,
  cards,
  selectedParts,
  updateAxiesSelectedParts,
  selectedPartsMatchD,
  updateAxiesSelectedPartsMatchD,
  selectedPartsMatchR1,
  updateAxiesSelectedPartsMatchR1,
  selectedPartsMatchR2,
  updateAxiesSelectedPartsMatchR2,
  selectedPartsIndividualPartMatches,
  updateAxiesSelectedPartsIndividualPartMatches,
}) => {
  const theme = useTheme();

  const handleResetFilters = () => {
    resetAxieFilters();
  };

  const handleToggleGenes = () => {
    updateAxiesShowGenes(!showGenes);
  };

  const handleCloseAxieFilters = () => {
    setAxieFiltersEl(null);
  };

  return (
    <PopupMenu
      title="Filter Axies"
      id="filter-axies-menu"
      anchorEl={axieFiltersEl}
      open={axieFiltersOpen}
      onClose={handleCloseAxieFilters}
      MenuListProps={{
        "aria-labelledby": "filter-axies-button",
      }}
    >
      <Stack gap={2}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap",
              justifyContent: "flex-start",
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <IconButton
              onClick={handleCloseAxieFilters}
              sx={{
                display: "none",
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                },
              }}
            >
              <IoClose />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button variant="text" size="small" onClick={handleToggleGenes}>
              {showGenes ? "Hide Genes" : "Show Genes"}
            </Button>
            <Button variant="text" size="small" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FilterAxieClass
                  axieClasses={axieClasses}
                  setAxieClasses={updateAxiesAxieClasses}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1} direction="row">
                  <FilterAxieBreedCount
                    breedCount={breedCount}
                    setBreedCount={updateAxiesBreedCount}
                  />
                  <FilterAxiePurity
                    purity={purity}
                    setPurity={updateAxiesPurity}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1} direction="row">
                  <CustomMultiSelect
                    label="Role"
                    value={role}
                    setValue={updateAxiesRole}
                    options={Object.keys(AXIE_ROLES)}
                  />
                  <CustomMultiSelect
                    label="Specialty"
                    value={specialty}
                    setValue={updateAxiesSpecialty}
                    options={Object.keys(AXIE_SPECIALTIES)}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack gap={1}>
              <FilterAxieStat
                stat={hp}
                setStat={updateAxiesHP}
                statDef={AXIE_STATS.HEALTH}
              />
              <FilterAxieStat
                stat={speed}
                setStat={updateAxiesSpeed}
                statDef={AXIE_STATS.SPEED}
              />
              <FilterAxieStat
                stat={skill}
                setStat={updateAxiesSkill}
                statDef={AXIE_STATS.SKILL}
              />
              <FilterAxieStat
                stat={morale}
                setStat={updateAxiesMorale}
                statDef={AXIE_STATS.MORALE}
              />
              <FilterAxieStat
                stat={purityPercent}
                setStat={updateAxiesPurityPercent}
                statDef={AXIE_STATS.PURITY_PERCENT}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <AxiePartFilter
              cards={cards}
              parts={selectedParts}
              setParts={updateAxiesSelectedParts}
              matchD={selectedPartsMatchD}
              setMatchD={updateAxiesSelectedPartsMatchD}
              matchR1={selectedPartsMatchR1}
              setMatchR1={updateAxiesSelectedPartsMatchR1}
              matchR2={selectedPartsMatchR2}
              setMatchR2={updateAxiesSelectedPartsMatchR2}
              individualPartMatches={selectedPartsIndividualPartMatches}
              setIndividualPartMatches={
                updateAxiesSelectedPartsIndividualPartMatches
              }
            />
          </Grid>
        </Grid>
      </Stack>
    </PopupMenu>
  );
};

export default FilterAxiesMenu;
