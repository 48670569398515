import * as types from "./dataActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./dataActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchCardsRequest() {
  try {
    yield put(actions.fetchAxieCardsStarted());
    const cards = yield retry(RETRY_TIMES, RETRY_DELAY, api.requestCards);
    yield put(actions.fetchAxieCardsSucceeded(cards));
  } catch (e) {
    yield put(actions.fetchAxieCardsFailed());
  }
}

export function* makeFetchCardsV3Request() {
  try {
    yield put(actions.fetchAxieCardsV3Started());
    const cards = yield retry(RETRY_TIMES, RETRY_DELAY, api.requestCardsV3);
    yield put(actions.fetchAxieCardsV3Succeeded(cards));
  } catch (e) {
    yield put(actions.fetchAxieCardsV3Failed());
  }
}

export default function* axieCardsSaga() {
  yield takeEvery(types.FETCH_AXIE_CARDS, makeFetchCardsRequest);
  yield takeEvery(types.FETCH_AXIE_CARDS_V3, makeFetchCardsV3Request);
}
