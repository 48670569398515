import React from "react";
import styled from "@emotion/styled";
import { Stack, Tooltip } from "@mui/material";
import { makeSeasonTag } from "helpers/items";

const Wrapper = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.muted}`,
  padding: "2px 4px",
  borderRadius: "4px",
  color: theme.palette.text.muted,
  fontWeight: 600,
  fontSize: 12,
  userSelect: "none",
}));

const Season = ({ season }) => {
  const tooltip = season == null ? "Non-Seasonal" : season;
  return (
    <Tooltip title={tooltip}>
      <Wrapper>{makeSeasonTag(season)}</Wrapper>
    </Tooltip>
  );
};

export default Season;
