import { pluralize } from "helpers/display";

export const AXIE_TECH_TWITTER = "https://twitter.com/axie_tech";
export const TRIBALLY_DISCORD = "https://discord.gg/tribally";

export const AXIE_TECH_TITLE = "Axie Tech";
export const AXIE_TECH_URL = "https://axie.tech";
export const AXIE_TECH_TAGLINE =
  "Axie.Tech, the number 1 Axie Infinity Fan site, offers tools & resources that allow players & guild managers to increase their income & boost ROI.";
export const AXIE_TECH_VIP_MEMBERSHIP_TAGLINE =
  "Our Guild Membership includes a suite of tools, which allow guild managers to streamline their day-to-day operations, whilst improving performance; both overall and at the individual level.";

export const TRIBALLY_TAGLINE =
  "The Social Platform For The Next Generation Of Gamers";

export const TRIBALLY_PLATFORM_URL = "https://tribally.games/";

export const BEST_AXIES_TWITTER = "https://twitter.com/bestaxies";
export const BEST_AXIES_EMAIL = "best@axie.tech";
export const RARE_AXIES_TWITTER = "https://twitter.com/RareAxies";
export const RARE_AXIES_EMAIL = "rare@axie.tech";
export const LEV_TWITTER = "https://twitter.com/LevTrades";
export const LEV_EMAIL = "lev@axie.tech";
export const AMY_TWITTER = "https://twitter.com/amypeniston";
export const AMY_EMAIL = "amy@axie.tech";
export const AMY_LINKEDIN = "https://www.linkedin.com/in/amypeniston/";

export const INDES_TWITTER = "https://twitter.com/Indes_Axiegg";
export const INDES_YOUTUBE =
  "https://www.youtube.com/channel/UCjDIxlMxjq7HXYynxrTI5jw";

export const REACT_QUERY_STALE_TIME = 300000;
export const AXIE_TECH_API = `https://${process.env.REACT_APP_API_HOSTNAME}`;
export const AXIE_TECH_CDN = `https://${
  process.env.REACT_APP_CDN_HOSTNAME || "cdn.tribally.xyz"
}`;

export const AXIE_DOLL_WEBSITE = "https://doll.tioland.com";
export const AXIE_DOLL_GITBOOK = "https://tiyo.gitbook.io/doll";

export const AXIE_QUEST_WEBSITE = "https://www.axiequest.com/";
export const AXIE_QUEST_GITBOOK = "https://gitbook.axiequest.com/";

export const CLASSIC_LEADERBOARD = "classic";
export const ORIGIN_LEADERBOARD = "origin";
export const GUILD_ARENA_LEADERBOARD = "guildArena";
export const GUILD_BATTLE_LEADERBOARD = "guildBattle";
export const SCHOLAR_ARENA_LEADERBOARD = "scholarArena";

// Guild Battle
export const GUILD_BATTLE_FEATURE_FLAG = true;
export const GUILD_BATTLE_TEAM_NAME = "Guild Battle";
export const GUILD_BATTLE_PRIZE_POOL = "1000 AXS";
export const GUILD_BATTLE_TOP_PRIZE = "293 AXS";
export const GUILD_BATTLE_LEADERBOARD_TITLE = "Axie Tech Guild Battle";

export const AXIE_INFINITY_ARENA_SEASON = 7;

export const AXIE_INFINITY_BATTLES_API_FUNCTIONAL = true;
export const AXIE_INFINITY_TRANSFER_HISTORY_API_FUNCTIONAL = false;
export const COINGECKO_API =
  "https://api.coingecko.com/api/v3/simple/price?ids=axie-infinity%2Cethereum%2Csmooth-love-potion&vs_currencies=eth%2Cusd%2Cphp%2Cgbp%2Ceur%2Csek%2Caud";

export const AXIE_INFINITY_ORIGIN_IMAGE_URL =
  "https://axiecdn.axieinfinity.com/axies";
export const AXIE_INFINITY_WEBSITE = "https://app.axieinfinity.com";
export const AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES = `${AXIE_INFINITY_WEBSITE}/marketplace/axies`;
export const AXIE_INFINITY_MARKETPLACE = `${AXIE_INFINITY_WEBSITE}/marketplace`;
export const AXIE_INFINITY_BUILDERS_PROGRAM_APPLICATION =
  "https://skymavis.typeform.com/to/F6X80eks?typeform-source=x1tv4vaf40x.typeform.com";
export const AXIE_INFINITY_BUILDERS_PROGRAM_TWITTER =
  "https://twitter.com/BuildersAxie";

export const AXIE_BASE_HP = 400;
export const AXIE_POTENTIAL_POINT_ALLOCATION_ORDER = [
  "Aquatic",
  "Beast",
  "Bird",
  "Bug",
  "Dawn",
  "Dusk",
  "Mech",
  "Plant",
  "Reptile",
];
export const AXIE_CLASSES = [
  "Aquatic",
  "Beast",
  "Bird",
  "Bug",
  "Dawn",
  "Dusk",
  "Mech",
  "Plant",
  "Reptile",
];
export const AXIE_CLASS_SORT_ORDER = {
  Beast: 0,
  Aquatic: 1,
  Plant: 2,
  Bug: 3,
  Bird: 4,
  Reptile: 5,
  Mech: 6,
  Dawn: 7,
  Dusk: 8,
  Egg: 99,
};
export const AXIE_CLASS_ID_MAPPING = {
  aquatic: "1",
  beast: "2",
  bird: "3",
  bug: "4",
  dawn: "5",
  dusk: "6",
  mech: "7",
  plant: "8",
  reptile: "9",
};

export const AXIE_CLASSES_REGULAR = [
  "Aquatic",
  "Beast",
  "Bird",
  "Bug",
  "Plant",
  "Reptile",
];

export const AXIE_STATS = {
  HEALTH: {
    label: "Health",
    icon: "hp",
  },
  SPEED: {
    label: "Speed",
    icon: "speed",
  },
  SKILL: {
    label: "Skill",
    icon: "skill",
  },
  MORALE: {
    label: "Morale",
    icon: "morale",
  },
  PURITY_PERCENT: {
    label: "Purity Percent",
    icon: "purityPercent",
    min: 0,
    max: 100,
    step: 1,
  },
};

export const AXIE_CLASS_COLORS = {
  plant: "#6cc000",
  bird: "#ff8bbd",
  beast: "#ffb812",
  bug: "#ff5341",
  aquatic: "#00b8ce",
  reptile: "#dc8be4",
  dusk: "#129092",
  dawn: "#beceff",
  mech: "#c6bdd4",
  default: "#71819D",
  defaultDark: "#A7A4BC",
};
export const AXIE_CLASS_CARD_STAT_BACKGROUNDS = {
  Aquatic: "#2F3F46",
  Reptile: "#392D48",
  Plant: "#37452D",
  Bug: "#482B2C",
  Bird: "#4F2D36",
  Beast: "#4D3A30",
};

export const BREEDING_COST_AXS = 0.5;
export const BREEDING_COST_SLP = {
  0: 900,
  1: 1350,
  2: 2250,
  3: 3600,
  4: 5850,
  5: 9450,
  6: 15300,
};
export const MARKET_FEE = 0.0425;

export const BODY_PARTS = ["eyes", "mouth", "ears", "horn", "back", "tail"];
export const NUMBER_OF_BODY_PARTS = BODY_PARTS.length;
export const BODY_PART_ORDER = [0, 2, 4, 1, 3, 5];
export const BODY_PARTS_IN_ORDER = BODY_PART_ORDER.map(
  (idx) => BODY_PARTS[idx]
);
export const PROBABILITIES = { d: 0.375, r1: 0.09375, r2: 0.03125 };
export const MAX_PURITY =
  6 * (PROBABILITIES.d + PROBABILITIES.r1 + PROBABILITIES.r2);
export const DEFAULT_NUMBER_OF_BREEDS_FOR_CALCULATOR = "4";

export const NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE = 20;
export const NUM_BREEDING_SIMULATOR_RONIN_AXIES_PER_PAGE = 50;
export const NUM_BATTLES_PER_PAGE = 5;
export const NUM_SCHOLAR_TEAMS_PER_PAGE = 10;

export const AXIE_PRICING_RARITIES = {
  common: {
    threshold: 2000,
    label: "Common",
    background: "linear-gradient(95deg, #f2f2f2 0%, #e1e1e1 100%)",
  },
  uncommon: {
    threshold: 500,
    label: "💠 Uncommon 💠",
    background: "linear-gradient(95deg, #7AF5AC 0%, #35CA71 100%)",
  },
  rare: {
    threshold: 10,
    label: "✨ Rare ✨",
    background: "linear-gradient(95deg, #b9c8ff 0%, #8686ff 100%)",
  },
  epic: {
    threshold: 1,
    label: "🔥 Epic 🔥",
    background: "linear-gradient(95deg, #e0a7fc 0%, #ca76f5 100%)",
  },
  unique: {
    threshold: 0,
    label: "⭐🦄 UNIQUE 🦄⭐",
    background: "linear-gradient(95deg, #F6E288 0%, #F1CB22 100%)",
  },
};

export const AXIE_PRICING_BADGES = {
  unique: {
    label: "Unique",
    icon: "🦄",
  },
  crit: {
    label: "Lethal",
    icon: "⚔️",
  },
  health: {
    label: "Survivor",
    icon: "💗",
  },
  speed: {
    label: "Quick",
    icon: "⚡️",
  },
  breeder: {
    label: "Breeder",
    icon: "🍆",
  },
  virgin: {
    label: "Virgin",
    icon: "👼",
  },
  pure: {
    label: "Pure",
    icon: "💯",
  },
  mystic: {
    label: "Mystic",
    icon: "✨",
  },
};

export const GUILD_RATING_STAR_THRESHOLDS = {
  s: 2000,
  a: 1800,
  b: 1600,
  c: 1400,
};

export const GUILD_NOT_EARNING_MMR_THRESHOLD = 800;
export const GUILD_DASHBOARD_FREE_NUMBER_OF_SCHOLARS = 10;
export const SCHOLAR_PERFORMANCE_SLP_THRESHOLDS = {
  great: { threshold: 65 },
  good: { threshold: 50 },
  ok: { threshold: 35 },
  poor: { threshold: 0 },
};
export const NO_TEAM_NAME = "No team";
export const DATE_TIME_FORMAT = "MMM Do, h:mma";
export const DATE_FORMAT = "MMM D";

export const STARTER_AXIE_RUNEIDS = [
  "rune_aquatic_puffy_1",
  "rune_beast_buba_1",
  "rune_beast_tripp_1",
  "rune_bird_momo_1",
  "rune_bug_pomodoro_1",
  "rune_plant_ena_1",
  "rune_plant_olek_1",
  "rune_reptile_venoki_1",
  "rune_beast_xia_1",
  "rune_beast_bing_1",
  "rune_aquatic_noir_1 ",
  "rune_aquatic_rouge_1 ",
];

export const STARTER_AXIE_CHARMIDS = [
  "ecard_aquatic_puffy_1",
  "ecard_beast_tripp_1",
  "ecard_beast_buba_1",
  "ecard_bird_momo_1",
  "ecard_bug_pomodoro_1",
  "ecard_plant_olek_1",
  "ecard_plant_ena_1",
  "ecard_reptile_venoki_1",
  "ecard_beast_xia_1",
  "ecard_beast_bing_1",
  "ecard_aquatic_noir_1",
  "ecard_aquatic_rouge_1",
];

export const STARTER_AXIE_PARTIDS = [
  "back-cone-shell",
  "back-cone-shell-",
  "back-death-shroom",
  "back-death-shroom-",
  "back-feather-melody",
  "back-forest-hero",
  "back-forest-hero-",
  "back-lil-bro",
  "back-lil-bro-",
  "back-magic-sack",
  "back-magic-sack-",
  "back-nutdha-statue",
  "back-nutdha-statue-",
  "back-pangolin-slayer",
  "back-pangolin-slayer-",
  "back-paper-wing",
  "back-rubber-duckling",
  "back-sponge-",
  "back-succulent",
  "back-succulent-",
  "back-tiny-dino",
  "back-tiny-dino-",
  "back-village-hero",
  "back-village-hero-",
  "ears-belieber-",
  "ears-brimstone",
  "ears-brimstone-",
  "ears-cursed-doll",
  "ears-foxy",
  "ears-foxy-",
  "ears-gill-",
  "ears-greenwood-rhythm",
  "ears-hidden-ears",
  "ears-hidden-ears-",
  "ears-leaf-bug-",
  "ears-little-crab",
  "ears-little-crab-",
  "ears-nut-cracker-",
  "ears-puppy-",
  "ears-sakura-",
  "ears-termites",
  "ears-venom-nail",
  "ears-venom-nail-",
  "eyes-baby",
  "eyes-baby-",
  "eyes-cold-fish",
  "eyes-cold-fish-",
  "eyes-concentrate",
  "eyes-concentrate-",
  "eyes-cursed-doll",
  "eyes-grand-finale",
  "eyes-hardboiled",
  "eyes-hardboiled-",
  "eyes-kind-fish",
  "eyes-kind-fish-",
  "eyes-ladybug-goggles",
  "eyes-nut-cracker",
  "eyes-nut-cracker-",
  "eyes-papi-",
  "eyes-passion",
  "eyes-passion-",
  "eyes-phantom",
  "eyes-punky",
  "eyes-punky-",
  "eyes-puppy-",
  "eyes-risky-trunk",
  "eyes-risky-trunk-",
  "eyes-sobby",
  "eyes-sparky",
  "eyes-sparky-",
  "eyes-tetrix",
  "horn-aborted-dream",
  "horn-aborted-dream-",
  "horn-acorn-cap",
  "horn-acorn-cap-",
  "horn-ballad-of-the-shore",
  "horn-beast-bun",
  "horn-beast-bun-",
  "horn-big-sister",
  "horn-big-sister-",
  "horn-boomerang",
  "horn-jellytacle",
  "horn-jellytacle-",
  "horn-lotus",
  "horn-lotus-",
  "horn-lump",
  "horn-mandarine",
  "horn-mandarine-",
  "horn-persimmon",
  "horn-persimmon-",
  "horn-poison-tube",
  "horn-poison-tube-",
  "horn-rocky-skull",
  "horn-rocky-skull-",
  "horn-rusty-helm",
  "horn-rusty-helm-",
  "horn-sacrifice",
  "horn-shocker",
  "horn-toy-ball",
  "horn-toy-ball-",
  "mouth-beetroot",
  "mouth-beetroot-",
  "mouth-blackflame-tongue",
  "mouth-buddy-chorus",
  "mouth-chemical-fang",
  "mouth-chemical-fang-",
  "mouth-cub",
  "mouth-cub-",
  "mouth-feather-dagger",
  "mouth-feathery-dart",
  "mouth-feathery-dart-",
  "mouth-foxy",
  "mouth-foxy-",
  "mouth-hazelnut",
  "mouth-hazelnut-",
  "mouth-minion-bell",
  "mouth-nose-drill",
  "mouth-nut-cracker-",
  "mouth-puppy",
  "mouth-puppy-",
  "mouth-platypus",
  "mouth-platypus-",
  "mouth-puff",
  "mouth-puff-",
  "mouth-ranchu",
  "mouth-ranchu-",
  "mouth-sniffle",
  "mouth-tiny-dino",
  "tail-aegis-talisman",
  "tail-aegis-talisman-",
  "tail-buba-brush",
  "tail-buba-brush-",
  "tail-centipede",
  "tail-centipede-",
  "tail-death-shower",
  "tail-death-shower-",
  "tail-eye-wing",
  "tail-eye-wing-",
  "tail-leaf-bug",
  "tail-leaf-bug-",
  "tail-mainspring",
  "tail-maraca",
  "tail-mystery-seed",
  "tail-nut-cracker-",
  "tail-oranda-",
  "tail-pangolin",
  "tail-pangolin-",
  "tail-puff",
  "tail-puff-",
  "tail-shiba",
  "tail-shiba-",
  "tail-shield-crusher",
  "tail-shield-shroom",
  "tail-sprout",
  "tail-sprout-",
  "tail-tropical-guardian",
];

export const STARTER_AXIE_DATA: {
  [key: number | string]: {
    name: string;
    slug: string;
    class: string;
    axieId: number;
    axieType: number;
  };
} = {
  1: {
    name: "Buba",
    slug: "buba",
    class: "beast",
    axieId: 1,
    axieType: 0,
  },
  2: {
    name: "Olek",
    slug: "olek",
    class: "plant",
    axieId: 2,
    axieType: 0,
  },
  3: {
    name: "Puffy",
    slug: "puffy",
    class: "aquatic",
    axieId: 3,
    axieType: 0,
  },
  5: {
    name: "Tripp",
    slug: "tripp",
    class: "beast",
    axieId: 5,
    axieType: 0,
  },
  7: {
    name: "Venoki",
    slug: "venoki",
    class: "reptile",
    axieId: 7,
    axieType: 0,
  },
  9: {
    name: "Heero",
    slug: "heero",
    class: "aquatic",
    axieId: 9,
    axieType: 0,
  },
  12: {
    name: "Momo",
    slug: "momo",
    class: "bird",
    axieId: 12,
    axieType: 0,
  },
  16: {
    name: "Ena",
    slug: "ena",
    class: "plant",
    axieId: 16,
    axieType: 0,
  },
  17: {
    name: "Pomodoro",
    slug: "pomodoro",
    class: "bug",
    axieId: 17,
    axieType: 0,
  },
  21: {
    name: "Xia",
    slug: "xia",
    class: "beast",
    axieId: 21,
    axieType: 0,
  },
  22: {
    name: "Bing",
    slug: "bing",
    class: "beast",
    axieId: 22,
    axieType: 0,
  },
  23: {
    name: "Rouge",
    slug: "rouge",
    class: "aquatic",
    axieId: 23,
    axieType: 0,
  },
  24: {
    name: "Noir",
    slug: "noir",
    class: "aquatic",
    axieId: 24,
    axieType: 0,
  },
};

export const AXIE_ROLES = {
  "Damage Dealer": {
    icon: "damageDealer",
  },
  Defender: {
    icon: "defender",
  },
  Support: {
    icon: "support",
  },
};

export const AXIE_SPECIALTIES = {
  Offensive: {
    icon: "offensive",
  },
  Sustain: {
    icon: "sustain",
  },
  Summoner: {
    icon: "summoner",
  },
  Rage: {
    icon: "rage",
  },
  "Damage Ramp": {
    icon: "damageRamp",
  },
  "Poison/Bleed": {
    icon: "poisonBleed",
  },
  Curse: {
    icon: "curse",
  },
  Tactical: {
    icon: "tactical",
  },
  Backdoor: {
    icon: "backdoor",
  },
  Neutral: {
    icon: "neutral",
  },
};

export const AXIE_CLASSIFICATIONS = { ...AXIE_ROLES, ...AXIE_SPECIALTIES };

export const BREEDING_EVENT_INFO_URL =
  "https://axie.substack.com/p/summerskins";
export const BREEDING_EVENT_COUNTDOWN_URL =
  "https://explorer.roninchain.com/block/16831982";
export const BREEDING_EVENT_END_DATE = "2022-09-02 08:58:46+00:00";
export const BREEDING_EVENT_SHINY_PERCENTAGE = 0.01;
export const BREEDING_EVENT_SHINY_PERCENTAGE_SPECIAL = 0.05;
export const BREEDING_EVENT_SPECIAL_GENES = "summer-2022";

export const BREEDING_EVENT_PART_MAPPING = {
  "Watermelon+Unko": {
    name: "Watermelon Ice Cream",
    partId: "horn-watermelon-ice-cream",
    inheritance: 1,
    requires: "horn-unko",
    class: "reptile",
  },
  "Strawberry Shortcake+Unko": {
    name: "Strawberry Ice Cream",
    partId: "horn-strawberry-ice-cream",
    inheritance: 1,
    requires: "horn-unko",
    class: "reptile",
  },
  "Eggshell+Unko": {
    name: "Vanilla Ice Cream",
    partId: "horn-vanilla-ice-cream",
    inheritance: 0.13,
    requires: "horn-unko",
    class: "reptile",
  },
  "Balloon+Red Ear": {
    name: "Turtle Buoy",
    partId: "back-turtle-buoy",
    inheritance: 0.13,
    requires: "back-red-ear",
    class: "reptile",
  },
  "Balloon+Furball": {
    name: "Beach Ball",
    partId: "back-beach-ball",
    inheritance: 1,
    requires: "back-furball",
    class: "beast",
  },
  "Blossom+Neo": {
    name: "Flower Sunglasses",
    partId: "eyes-flower-sunglasses",
    inheritance: 1,
    requires: "eyes-neo",
    class: "bug",
  },
  "Silence Whisper+Risky Fish": {
    name: "Bubble Fish",
    partId: "mouth-bubble-fish",
    inheritance: 1,
    requires: "mouth-risky-fish",
    class: "aquatic",
  },
  "Cloud+Cottontail": {
    name: "Cotton Candy",
    partId: "tail-cotton-candy",
    inheritance: 1,
    requires: "tail-cottontail",
    class: "beast",
  },
  "Seaslug+Nyan": {
    name: "Coca",
    partId: "ears-coca",
    inheritance: 1,
    requires: "ears-nyan",
    class: "beast",
  },
};

export const BREEDING_EVENT_PART_LIST = Object.keys(
  BREEDING_EVENT_PART_MAPPING
).flatMap((k) => k.split("+"));

export const BREEDING_EVENT_PART_PAIRS = Object.keys(
  BREEDING_EVENT_PART_MAPPING
).map((k) => k.split("+"));

export const BREEDING_EVENT_SKIN_LIST = Object.values(
  BREEDING_EVENT_PART_MAPPING
).map((v) => v.name);

export const ITEM_RARITY_MAPPING = { Common: 1, Rare: 2, Epic: 3, Mystic: 4 };

export const ARENA_RANKS = [
  "Egg",
  "Chick",
  "Hare",
  "Boar",
  "Wolf",
  "Bear",
  "Tiger",
  "Dragon",
  "Challenger",
];

export const ARENA_RANK_SLP_REWARDS: {
  [key: string]: {
    [key: number]: number;
  };
} = {
  Egg: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  },
  Chick: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  },
  Hare: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  },
  Boar: {
    1: 1,
    2: 1,
    3: 1,
    4: 1,
  },
  Wolf: {
    1: 2,
    2: 2,
    3: 2,
    4: 2,
  },
  Bear: {
    1: 4,
    2: 4,
    3: 3,
    4: 3,
  },
  Tiger: {
    1: 6,
    2: 6,
    3: 5,
    4: 5,
  },
  Dragon: {
    1: 10,
    2: 9,
    3: 8,
    4: 7,
  },
  Challenger: {
    1: 14,
    2: 13,
    3: 12,
    4: 11,
  },
};

export const ARENA_CHALLENGER_TIERS: {
  1: number;
  2: number;
  3: number;
  4: number;
} = {
  1: 2400,
  2: 2200,
  3: 2000,
  4: 1800,
};

// ITEMS

export const ARENA_SEASONS = [
  { label: "Non-Seasonal", value: null },
  { label: "Alpha", value: "Season Alpha" },
  { label: "S0", value: "Season 0" },
  { label: "S1", value: "Season 1" },
  { label: "S2", value: "Season 2" },
  { label: "S3", value: "Season 3" },
  { label: "S4", value: "Season 4" },
  { label: "S5", value: "Season 5" },
  { label: "S6", value: "Season 6" },
  { label: "S7", value: "Season 7" },
];
export const ARENA_SEASON_CURRENT = ARENA_SEASONS[9];

// DOLL

export const AXIE_DOLL_CURRENT_SEASON = 3;

export const AXIE_DOLL_BODY_TYPE_BONUSES = {
  normal: {
    name: "Normal",
    description: "120 HP + 20% movespeed + 1 HP regen/sec",
  },
  fuzzy: {
    name: "Fuzzy",
    description: "120 HP + 20% movespeed + 10% armor + 1 HP regen/sec",
  },
  curly: {
    name: "Curly",
    description: "120 HP + 3 HP regen/sec",
  },
  bigyak: {
    name: "Big Yak",
    description: "300 HP + 1 HP regen/sec",
  },
  wetdog: {
    name: "Wet Dog",
    description: "120 HP + 60% movespeed + 1 HP regen/sec",
  },
  spiky: {
    name: "Spiky",
    description: "120 HP + 45% damage reduction + 1 HP regen/sec",
  },
  sumo: {
    name: "Sumo",
    description: "240 HP + 5 flat damage reduction + 0.5 HP regen/sec",
  },
  wavy: {
    name: "Wavy",
    description: "120 HP + 40% movespeed + 1 HP regen/sec",
  },
  frosty: {
    name: "Frosty",
    description: "240 HP + 10 flat damage reduction",
  },
};

const AXIE_DOLL_CLASS_BONUSES_COMING_SOON = "Class bonuses coming soon!";

export const AXIE_DOLL_CLASS_BONUSES = {
  plant: {
    name: "Plant",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  bird: {
    name: "Bird",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  beast: {
    name: "Beast",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  bug: {
    name: "Bug",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  reptile: {
    name: "Reptile",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  aquatic: {
    name: "Aquatic",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  mech: {
    name: "Mech",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  dawn: {
    name: "Dawn",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
  dusk: {
    name: "Dusk",
    description: AXIE_DOLL_CLASS_BONUSES_COMING_SOON,
  },
};

export const AXIE_QUEST_TYPES = {
  active: "active",
  passive: "passive",
  trigger: "trigger",
};

export const AXIE_QUEST_UNITS = {
  number: "number",
  percentage: "percentage",
  percentageString: "percentageString",
};

export const AXIE_QUEST_ACTIVE_EFFECTS = [
  "Break Moon",
  "Break Star",
  "Break Sun",
  "Break Random",
  "Break Power",
  "Break Shield",
  "Convert Moon To Star",
  "Convert Star to Moon",
  "Convert Sun to Star",
  "Convert Moon to Sun",
  "Convert Star to Sun",
  "Convert Sun to Moon",
  "Damage",
  "Destroy Star",
  "Destroy Moon",
  "Destroy Sun",
  "Destroy Random",
  "Gain Moon",
  "Gain Star",
  "Gain Sun",
  "Gain Random",
  "Heal",
  "Shield",
  "Steal Sun",
  "Steal Random",
  "Steal Moon",
  "Steal Star",
  "Steal Health",
];

export const AXIE_QUEST_PASSIVE_EFFECTS = [
  "Decrease Health",
  "Decrease Skill",
  "Decrease Morale",
  "Decrease Speed",
  "Increase Accuracy Rate",
  "Increase Critical Rate",
  "Increase Dodge Rate",
  "Increase Skill Affinity",
  "Increase Damage Power",
  "Increase Heal Power",
  "Increase Shield Power",
  "Increase Health",
  "Increase Speed",
  "Increase Skill",
  "Increase Morale",
  "Increase Max Health",
  "Increase Sun",
  "Increase Star",
  "Increase Moon",
  "Increase Power",
  "Swap Health To Morale",
  "Swap Health To Skill",
  "Swap Health To Speed",
  "Swap Speed To Health",
  "Swap Speed To Skill",
  "Swap Speed To Morale",
  "Swap Skill to Health",
  "Swap Skill to Morale",
  "Swap Skill to Speed",
  "Swap Morale to Health",
  "Swap Morale to Skill",
  "Swap Morale to Speed",
];

// trigger events pretty labels and tooltip descriptions
export const AXIE_QUEST_TRIGGER_EVENT_TYPES = {
  Attack: {
    prettyLabel: "Attack",
    description: "On damaging enemy",
  },
  BreakShield: {
    prettyLabel: "Break Shield",
    description: "On breaking enemy shield",
  },
  ReceiveHeal: {
    prettyLabel: "Receive Heal",
    description: "On receiving healing",
  },
  ReceiveShield: {
    prettyLabel: "Receive Shield",
    description: "On receiving shielding",
  },
  ReceiveDamage: {
    prettyLabel: "Receive Damage",
    description: "On receiving damage",
  },
  ReceiveShieldBreak: {
    prettyLabel: "Receive Shield Break",
    description: "On shield broken",
  },
  EnemyHeal: {
    prettyLabel: "Enemy Heal",
    description: "On enemy healing",
  },
  EnemyShield: {
    prettyLabel: "Enemy Shield",
    description: "On enemy shielding",
  },
  DealCritical: {
    prettyLabel: "Deal Critical",
    description: "On landing critical hit",
  },
  Dodge: {
    prettyLabel: "Dodge",
    description: "On dodging an attack",
  },
  BreakMoon: {
    prettyLabel: "Break Moon",
    description: "On matching Moon blocks",
  },
  BreakStar: {
    prettyLabel: "Break Star",
    description: "On matching Star blocks",
  },
  BreakSun: {
    prettyLabel: "Break Sun",
    description: "On matching Sun blocks",
  },
  BreakPower: {
    prettyLabel: "Break Power",
    description: "On matching Power blocks",
  },
  EndTurn: {
    prettyLabel: "End Turn",
    description: "At end of your turn",
  },
};

export const AXIE_QUEST_EFFECT_TYPES = {
  BreakSun: {
    prettyLabel: "Break Sun",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Crushes ${pluralize("Sun block", n)}`,
  },
  BreakMoon: {
    prettyLabel: "Break Moon",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Crushes ${pluralize("Moon block", n)}`,
  },
  BreakRandom: {
    prettyLabel: "Break Random",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Crushes ${pluralize("Random block", n)}`,
  },
  BreakPower: {
    prettyLabel: "Break Power",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Crushes ${pluralize("Power block", n)}`,
  },
  BreakStar: {
    prettyLabel: "Break Star",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Crushes ${pluralize("Star block", n)}`,
  },
  BreakShield: {
    prettyLabel: "Break Shield",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Breaks ${n * 100}% enemy shield`,
  },
  ConvertStarToMoon: {
    prettyLabel: "Convert Star to Moon",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Converts ${n} Star to Moon`,
  },
  ConvertSunToStar: {
    prettyLabel: "Convert Sun to Star",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Converts ${n} Sun to Star`,
  },
  ConvertMoonToSun: {
    prettyLabel: "Convert Moon to Sun",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Converts ${n} Moon to Sun`,
  },
  ConvertMoonToStar: {
    prettyLabel: "Convert Moon to Star",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Converts ${n} Moon to Star`,
  },
  ConvertStarToSun: {
    prettyLabel: "Convert Star to Sun",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Converts ${n} Star to Sun`,
  },
  ConvertSunToMoon: {
    prettyLabel: "Convert Sun to Moon",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Converts ${n} Sun to Moon`,
  },
  Damage: {
    prettyLabel: "Damage",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Deals ${n * 100}% damage`,
  },
  DestroyStar: {
    prettyLabel: "Destroy Star",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Destroys ${n} enemy's Star`,
  },
  DestroyRandom: {
    prettyLabel: "Destroy Random",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Destroys ${n} enemy's Random resources`,
  },
  DestroyMoon: {
    prettyLabel: "Destroy Moon",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Destroys ${n} enemy's Moon`,
  },
  DestroySun: {
    prettyLabel: "Destroy Sun",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Destroys ${n} enemy's Sun`,
  },
  GainStar: {
    prettyLabel: "Gain Star",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Gain ${n} Star`,
  },
  GainSun: {
    prettyLabel: "Gain Sun",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Gain ${n} Sun`,
  },
  GainRandom: {
    prettyLabel: "Gain Random",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Gain ${n} Random resources`,
  },
  GainMoon: {
    prettyLabel: "Gain Moon",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Gain ${n} Moon`,
  },
  Heal: {
    prettyLabel: "Heal",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Heals ${n * 100}% healing power`,
  },
  Shield: {
    prettyLabel: "Shield",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Shields ${n * 100}% shielding power`,
  },
  StealSun: {
    prettyLabel: "Steal Sun",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Steals ${n} enemy's Sun`,
  },
  StealRandom: {
    prettyLabel: "Steal Random",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Steals ${n} enemy's Random resources`,
  },
  StealMoon: {
    prettyLabel: "Steal Moon",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Steals ${n} enemy's Moon`,
  },
  StealStar: {
    prettyLabel: "Steal Star",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Steals ${n} enemy's Star`,
  },
  StealHealth: {
    prettyLabel: "Steal Health",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Steals HP from enemy ${n * 100}% damage`,
  },
  DecHealth: {
    prettyLabel: "Decrease Health",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Decreases enemy's Health by ${n}`,
  },
  DecSkill: {
    prettyLabel: "Decrease Skill",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Decreases enemy's Skill by ${n}`,
  },
  DecMorale: {
    prettyLabel: "Decrease Morale",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Decreases enemy's Morale by ${n}`,
  },
  DecSpeed: {
    prettyLabel: "Decrease Speed",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Decreases enemy's Speed by ${n}`,
  },
  IncAccuracyRate: {
    prettyLabel: "Increase Accuracy Rate",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Increase Accuracy Rate by ${n * 100}%`,
  },
  IncCriticalRate: {
    prettyLabel: "Increase Critical Rate",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Increase Critical Rate by ${n * 100}%`,
  },
  IncDodgeRate: {
    prettyLabel: "Increase Dodge Rate",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Increase Dodge Rate by ${n * 100}%`,
  },
  IncSkillAffinity: {
    prettyLabel: "Increase Skill Affinity",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Increase Skill Affinity by ${n * 100}%`,
  },
  IncDamage: {
    prettyLabel: "Increase Damage Power",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Increase Damage Power by ${n * 100}%`,
  },
  IncHealPower: {
    prettyLabel: "Increase Heal Power",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Increase Healing Power by ${n}`,
  },
  IncShieldPower: {
    prettyLabel: "Increase Shield Power",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Increase Shielding Power by ${n}`,
  },
  IncHealth: {
    prettyLabel: "Increase Health",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Increase Health by ${n}`,
  },
  IncSpeed: {
    prettyLabel: "Increase Speed",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Increase Speed by ${n}`,
  },
  IncSkill: {
    prettyLabel: "Increase Skill",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Increase Skill by ${n}`,
  },
  IncMorale: {
    prettyLabel: "Increase Morale",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Increase Morale by ${n}`,
  },
  IncMaxHealth: {
    prettyLabel: "Increase Max Health",
    unit: AXIE_QUEST_UNITS.percentage,
    formatter: (n: number) => `Increase Max HP by ${n * 100}%`,
  },
  // percentage- but factor + "%" not multiplied
  IncSun: {
    prettyLabel: "Increase Sun",
    unit: AXIE_QUEST_UNITS.percentageString,
    formatter: (n: number) => `Increase chance of Sun blocks by ${n}%`,
  },
  IncStar: {
    prettyLabel: "Increase Star",
    unit: AXIE_QUEST_UNITS.percentageString,
    formatter: (n: number) => `Increase chance of Star blocks by ${n}%`,
  },
  IncMoon: {
    prettyLabel: "Increase Moon",
    unit: AXIE_QUEST_UNITS.percentageString,
    formatter: (n: number) => `Increase chance of Moon blocks by ${n}%`,
  },
  IncPower: {
    prettyLabel: "Increase Power",
    unit: AXIE_QUEST_UNITS.percentageString,
    formatter: (n: number) => `Increase chance of Power blocks by ${n}%`,
  },
  SwapHealthToMorale: {
    prettyLabel: "Swap Health To Morale",
    type: AXIE_QUEST_TYPES.passive,
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Health to Morale`,
  },
  SwapHealthToSkill: {
    prettyLabel: "Swap Health To Skill",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Health To Skill`,
  },
  SwapHealthToSpeed: {
    prettyLabel: "Swap Health To Speed",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Health To Speed`,
  },
  SwapSpeedToHealth: {
    prettyLabel: "Swap Speed To Health",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Speed To Health`,
  },
  SwapSpeedToSkill: {
    prettyLabel: "Swap Speed To Skill",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Speed To Skill`,
  },
  SwapSpeedToMorale: {
    prettyLabel: "Swap Speed To Morale",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Speed To Morale`,
  },
  SwapSkillToHealth: {
    prettyLabel: "Swap Skill to Health",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Skill To Health`,
  },
  SwapSkillToMorale: {
    prettyLabel: "Swap Skill to Morale",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Skill To Morale`,
  },
  SwapSkillToSpeed: {
    prettyLabel: "Swap Skill to Speed",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Skill To Speed`,
  },
  SwapMoraleToHealth: {
    prettyLabel: "Swap Morale to Health",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Morale To Health`,
  },
  SwapMoraleToSkill: {
    prettyLabel: "Swap Morale to Skill",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Morale To Skill`,
  },
  SwapMoraleToSpeed: {
    prettyLabel: "Swap Morale to Speed",
    unit: AXIE_QUEST_UNITS.number,
    formatter: (n: number) => `Swap ${n} Morale To Speed`,
  },
};

export const AXIE_QUEST_RESOURCE_TYPES = ["Sun", "Moon", "Star"];

// inter the dungeon

export const INTER_THE_DUNGEON_WEBSITE = "https://interthedungeon.com/";
export const INTER_THE_DUNGEON_TWITTER = "https://twitter.com/interthedungeon";
