import React from "react";
import { addAlphaToHex } from "helpers/colors";
import { Stack, Button } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  flexDirection: "row",
  justifyContent: "flex-end",
  height: 38,
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const Buttons = ({ axie, clearAxie }) => {
  return (
    <Wrapper>
      <Stack gap={3} direction="row">
        {axie.marketplaceSearchUrl != null && (
          <Button
            variant="text"
            size="small"
            href={axie.marketplaceSearchUrl}
            target="_blank"
            sx={{ alignSelf: "center" }}
          >
            Search Marketplace
          </Button>
        )}
        <Button
          variant="text"
          size="small"
          onClick={() => clearAxie(axie.id)}
          sx={{
            alignSelf: "center",
            "&:hover": {
              color: (theme) => theme.palette.feedback.red,
              background: (theme) =>
                addAlphaToHex(theme.palette.feedback.red, 0.05),
            },
          }}
        >
          Clear Axie
        </Button>
      </Stack>
    </Wrapper>
  );
};

export default Buttons;
