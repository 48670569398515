import React, { useState, useEffect } from "react";
import { Stack, Button } from "@mui/material";
import Title from "../atoms/Title";
import Stats from "../molecules/Stats";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCheckCircle } from "react-icons/fa";
import { IoLink } from "react-icons/io5";
import ToggleView from "../molecules/ToggleView";

const Header = ({ stats, shareLink, view, setView, clearItems }) => {
  const [hasClickedCopy, setHasClickedCopy] = useState(false);

  useEffect(() => {
    if (hasClickedCopy) {
      setTimeout(() => {
        setHasClickedCopy(false);
      }, 1000);
    }
  }, [hasClickedCopy]);

  const handleClickCopy = () => {
    setHasClickedCopy(true);
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
      gap={{ xs: 3, sm: 3, md: 3, lg: 0 }}
      alignItems="center"
      justifyContent="space-between"
      sx={{ position: "relative", zIndex: 2 }}
    >
      <Stack
        gap={{ xs: 3, sm: 3, md: 3, lg: 0.5 }}
        alignItems={{
          xs: "center",
          sm: "center",
          md: "center",
          lg: "flex-start",
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap={{ xs: 0.7, sm: 2 }}
        >
          <Title />

          <Stack direction="row" gap={2}>
            <Button variant="origin" onClick={() => clearItems()}>
              Clear All
            </Button>

            <CopyToClipboard text={shareLink}>
              <Button
                variant="origin"
                endIcon={hasClickedCopy ? <FaCheckCircle /> : <IoLink />}
                onClick={handleClickCopy}
              >
                {hasClickedCopy ? "Copied" : "Copy share link"}
              </Button>
            </CopyToClipboard>
          </Stack>
        </Stack>

        <ToggleView view={view} setView={setView} />
      </Stack>

      <Stats stats={stats} />
    </Stack>
  );
};

export default Header;
