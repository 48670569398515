import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CircularProgress, useTheme } from "@mui/material";
import { CSSProperties } from "react";

interface WrapperProps {
  centered: boolean;
  marginTop: number;
  marginBottom: number;
}

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["centered", "marginTop", "marginBottom"].includes(prop),
})<WrapperProps>(({ centered, marginTop, marginBottom }) => ({
  alignItems: centered ? "center" : "flex-start",
  textAlign: "center",
  marginTop: marginTop,
  marginBottom: marginBottom,
}));

interface Props {
  width?: number;
  color?: string;
  marginTop?: number;
  marginBottom?: number;
  centered?: boolean;
  style?: CSSProperties;
  variant?: Variants;
}

export enum Variants {
  DOLL = "doll",
  ORIGIN = "origin",
  AXIE_QUEST = "axie-quest",
  INTER_DUNGEON = "inter-the-dungeon",
}

const CustomLoader = ({
  width = 70,
  color,
  marginTop = 48,
  marginBottom = 48,
  centered = true,
  style = {},
  variant,
}: Props) => {
  const theme = useTheme();
  if (variant === Variants.DOLL) {
    color = theme.palette.colors.doll.pink;
  }
  if (variant === Variants.ORIGIN) {
    color = theme.palette.colors.origin.paper.dark;
  }
  if (variant === Variants.AXIE_QUEST) {
    color = theme.palette.colors.axieQuest.blue.light;
  }
  if (variant === Variants.INTER_DUNGEON) {
    color = theme.palette.colors.interDungeon.controls.teal;
  }
  if (!color) {
    color = theme.palette.colors.purple[400];
  }
  return (
    <Wrapper
      centered={centered}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <CircularProgress size={width} sx={{ color, ...style }} />
    </Wrapper>
  );
};

export default CustomLoader;
