import * as types from "./explorerActionTypes";

export const favoriteCard = (card) => {
  if (card.isFavorite) {
    return {
      type: types.EXPLORER_UNFAVORITE_CARD,
      card,
    };
  }
  return {
    type: types.EXPLORER_FAVORITE_CARD,
    card,
  };
};

export const favoriteRune = (rune) => {
  if (rune.isFavorite) {
    return {
      type: types.EXPLORER_UNFAVORITE_RUNE,
      rune,
    };
  }
  return {
    type: types.EXPLORER_FAVORITE_RUNE,
    rune,
  };
};

export const favoriteCharm = (charm) => {
  if (charm.isFavorite) {
    return {
      type: types.EXPLORER_UNFAVORITE_CHARM,
      charm,
    };
  }
  return {
    type: types.EXPLORER_FAVORITE_CHARM,
    charm,
  };
};

export const favoriteCurse = (curse) => {
  if (curse.isFavorite) {
    return {
      type: types.EXPLORER_UNFAVORITE_CURSE,
      curse,
    };
  }
  return {
    type: types.EXPLORER_FAVORITE_CURSE,
    curse,
  };
};

export const favoriteTool = (tool) => {
  if (tool.isFavorite) {
    return {
      type: types.EXPLORER_UNFAVORITE_TOOL,
      tool,
    };
  }
  return {
    type: types.EXPLORER_FAVORITE_TOOL,
    tool,
  };
};

export const favoriteRevengeCard = (revengeCard) => {
  if (revengeCard.isFavorite) {
    return {
      type: types.EXPLORER_UNFAVORITE_REVENGE_CARD,
      revengeCard,
    };
  }
  return {
    type: types.EXPLORER_FAVORITE_REVENGE_CARD,
    revengeCard,
  };
};

export const addFavoriteCards = (favorites) => ({
  type: types.EXPLORER_ADD_FAVORITE_CARDS,
  favorites,
});

export const addFavoriteRunes = (favorites) => ({
  type: types.EXPLORER_ADD_FAVORITE_RUNES,
  favorites,
});

export const addFavoriteCharms = (favorites) => ({
  type: types.EXPLORER_ADD_FAVORITE_CHARMS,
  favorites,
});

export const addFavoriteCurses = (favorites) => ({
  type: types.EXPLORER_ADD_FAVORITE_CURSES,
  favorites,
});

export const addFavoriteTools = (favorites) => ({
  type: types.EXPLORER_ADD_FAVORITE_TOOLS,
  favorites,
});

export const addFavoriteRevengeCards = (favorites) => ({
  type: types.EXPLORER_ADD_FAVORITE_REVENGE_CARDS,
  favorites,
});

export const clearAllFavorites = () => ({
  type: types.EXPLORER_CLEAR_ALL_FAVORITES,
});

export const updateCardFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_CARD_FILTERS,
  filters,
});

export const updateRuneFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_RUNE_FILTERS,
  filters,
});

export const updateCurseFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_CURSE_FILTERS,
  filters,
});

export const updateToolFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_TOOL_FILTERS,
  filters,
});

export const updateCharmFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_CHARM_FILTERS,
  filters,
});

export const updateStatusEffectFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_STATUS_EFFECT_FILTERS,
  filters,
});

export const updateRevengeCardFilters = (filters) => ({
  type: types.EXPLORER_UPDATE_REVENGE_CARD_FILTERS,
  filters,
});

export const resetCardFilters = () => ({
  type: types.EXPLORER_RESET_CARD_FILTERS,
});

export const resetRuneFilters = () => ({
  type: types.EXPLORER_RESET_RUNE_FILTERS,
});

export const resetCharmFilters = () => ({
  type: types.EXPLORER_RESET_CHARM_FILTERS,
});

export const resetCurseFilters = () => ({
  type: types.EXPLORER_RESET_CURSE_FILTERS,
});

export const resetToolFilters = () => ({
  type: types.EXPLORER_RESET_TOOL_FILTERS,
});

export const resetStatusEffectFilters = () => ({
  type: types.EXPLORER_RESET_STATUS_EFFECT_FILTERS,
});

export const resetRevengeCardFilters = () => ({
  type: types.EXPLORER_RESET_REVENGE_CARD_FILTERS,
});
