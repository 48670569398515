import React from "react";
import { Stack, useTheme, Box } from "@mui/material";

const Counter = ({ total, filled, color, width }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={`${(2.5 / 320) * width}px`}
      sx={{
        borderRadius: `${(4 / 320) * width}px`,
        height: `${(10 / 320) * width}px`,
      }}
    >
      {Array(total)
        .fill()
        .map((x, i) => i)
        .map((item, i) => {
          return (
            <Box
              sx={{
                background:
                  i < filled ? color : theme.palette.axieCard.statBackground,
                borderRadius: `${(4 / 320) * width}px`,
                height: "100%",
                width: `${(7 / 320) * width}px`,
              }}
              key={`COUNTER_ITEM_${i}`}
            />
          );
        })}
    </Stack>
  );
};

export default Counter;
