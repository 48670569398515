import React from "react";
import { Typography } from "@mui/material";

const HelperText = ({ children }) => {
  return (
    <Typography
      variant="span"
      color="rgba(0,0,0,.5)"
      fontSize="13px"
      fontWeight="600"
      sx={{ lineHeight: 1 }}
    >
      {children}
    </Typography>
  );
};

export default HelperText;
