import { Stack } from "@mui/material";
import TitleOnSign from "../molecules/TitleOnSign";

const Header = () => {
  return (
    <Stack gap={4} alignItems="center">
      <TitleOnSign />
    </Stack>
  );
};

export default Header;
