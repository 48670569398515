import { Stack, Typography } from "@mui/material";
import { addOrdinalSuffix } from "helpers/display";
import Card from "components/Card/Card";
import VerticalStat from "./VerticalStat";

const Ranking = ({ amount, units, teamRank, guildRank, type }) => {
  const icons = {
    slp: "/images/icons/slp.png",
    star: "/images/icons/vstar.png",
  };
  return (
    <Card style={{ padding: "20px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        sx={{
          flexGrow: 1,
          width: "100%",
          img: {
            width: 20,
          },
        }}
      >
        <Stack gap={1} direction="row" alignItems="center">
          <img src={icons[type]} alt={type} />
          <Typography
            variant="span"
            color="text.bright"
            fontSize="24px"
            fontWeight="700"
            sx={{ lineHeight: 1 }}
          >
            {amount == null ? "?" : amount}

            <Typography
              variant="span"
              color="text.bright"
              fontSize={15}
              fontWeight="700"
              sx={{ lineHeight: 1 }}
            >
              {units}
            </Typography>
          </Typography>
        </Stack>

        <Stack gap={2} direction="row" alignItems="center">
          {teamRank != null && <Segment amount={teamRank} label="team" />}
          {guildRank != null && <Segment amount={guildRank} label="guild" />}
        </Stack>
      </Stack>
    </Card>
  );
};

const Segment = ({ amount, label }) => {
  const rank = addOrdinalSuffix(amount);
  return amount != null ? (
    <VerticalStat label={label}>
      <Typography
        variant="span"
        fontSize={14}
        fontWeight={800}
        color="text.regular"
      >
        {rank}
      </Typography>
    </VerticalStat>
  ) : (
    <></>
  );
};

export default Ranking;
