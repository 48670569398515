import { Stack, Typography, Button, Box } from "@mui/material";
import { IoCaretBackCircleOutline } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";
import NoneFoundMessage from "components/Typography/NoneFoundMessage";

const NoTeam = () => {
  return (
    <Box sx={{ pt: 12 }}>
      <Stack gap={4}>
        <Stack gap={1}>
          <NoneFoundMessage>Wer team?</NoneFoundMessage>
          <Typography
            variant="span"
            color="text.regular"
            fontSize="18px"
            fontWeight="600"
            textAlign="center"
          >
            We can't find a team with that ID.
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          to="/guild-dashboard/teams"
          style={{ alignSelf: "center" }}
          startIcon={<IoCaretBackCircleOutline />}
          component={RouterLink}
        >
          Back to All Teams
        </Button>
      </Stack>
    </Box>
  );
};

export default NoTeam;
