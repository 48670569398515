import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { Button, useTheme } from "@mui/material";

const ColorModeButton = ({ isDarkMode, setIsDarkMode }) => {
  const theme = useTheme();

  const handleSetIsDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <Button
      variant="text"
      size="small"
      endIcon={isDarkMode ? <FaMoon /> : <FaSun />}
      onClick={handleSetIsDarkMode}
      sx={{
        color: theme.palette.colors.purple[400],
        alignSelf: "flex-start",
        fontWeight: "600",
        "&:hover": {
          color: theme.palette.colors.purple[400],
          background: "#6314ae33",
        },
        "& .MuiButton-endIcon svg": {
          top: 0,
        },
      }}
    >
      {isDarkMode ? "Dark Mode" : "Light Mode"}
    </Button>
  );
};

export default ColorModeButton;
