const white: string = "#ffffff";
const black: string = "#0D0C22";

const neutral: NeutralColors = {
  100: "#3c3d4d",
  200: "#484b63",
  300: "#898ca4",
  400: "#c5c7d2",
  500: "#e2e2e8",
  600: "#f3f4f6",
  700: "#f9f9f9",
};

const purple: PurpleColors = {
  100: "#320755",
  200: "#4d0d82",
  300: "#6314ae",
  400: "#ae83d5",
  500: "#ede2fa",
  600: "#f8f6fb",
  700: "#fdfcff",
  gradient: "linear-gradient(90deg, #4d0d82, #6e00b6)",
  gradientDark: `linear-gradient(90deg, #010103, #2c1b48)`,
  gradientLight: `linear-gradient(90deg, #ede2fa, #eee5f7)`,
};

const sharedGameColors: SharedGameColors = {
  adventure: {
    main: "#00B3CB",
    alternate: "#048291",
  },
  arena: {
    main: "#FEC349",
    alternate: "#E28200",
  },
  breedingSimulator: {
    lock: "#ffcf24",
  },
};

const sharedCardColors: SharedCardColors = {
  skill: "#00D830",
  ethereal: "#55FAFF",
  banish: "#3C87FF",
  innate: "#C8FE54",
  secret: "#E74EFF",
  power: "#FEB400",
  attack: "#FD6100",
  retain_effect: "#00F39D",
};

const lightColors: BaseModeColors = {
  bright: "#0D0C22",
  regular: "#484B63",
  muted: "#71819D",
  veryMuted: "#AEB1CF",
  veryVeryMuted: "#EFEFF6",
  shade1: "#F4EDFC",
  shade2: "#E9DCF7",
  pop1: "#f5f7fa",
  pop2: "#F0F1F5",
  pop3: "#E4E7ED",
  gradientContrast: `linear-gradient(90deg, #6314AE 0%, #D11DA8 50%, #E86B5A 100%)`,
  primary: {
    main: "#7232C2",
    contrastText: white,
  },
  secondary: {
    main: "#B76FFF",
    contrastText: white,
  },
  cardBackground: "#ffffff",
};

const light: ModeColors = {
  ...lightColors,
  text: {
    primary: lightColors.primary.main,
    secondary: lightColors.secondary.main,
    bright: lightColors.bright,
    regular: lightColors.regular,
    muted: lightColors.muted,
    gradient: lightColors.gradientContrast,
    veryMuted: lightColors.veryMuted,
    veryVeryMuted: lightColors.veryVeryMuted,
  },
  card: {
    background: lightColors.cardBackground,
    gradient: purple.gradient,
    title: lightColors.regular,
    divider: lightColors.veryVeryMuted,
    icon: lightColors.secondary.main,
    boxShadow: "0px 4px 8px rgba(52, 12, 90, 0.05)",
    label: lightColors.primary.main,
    text: black,
    subText: "#8286A5",
    shade1: lightColors.shade1,
    shade2: lightColors.shade2,
    pop1: lightColors.pop1,
    pop2: lightColors.pop2,
    pop3: lightColors.pop3,
    border: "#edeff5",
  },
  background: {
    default: "#f6f8f9",
    subtle: purple[500],
    gradient: `linear-gradient(#fbf7ff, #ebe8f2)`,
    gradientContrast: lightColors.gradientContrast,
  },
  footer: {
    color: "#dee1f1",
    header: "#ffffff",
    backgroundSolid: "#632F93",
    background: "linear-gradient(180deg, #632F93, #311D4E",
  },
  navigation: {
    background: white,
    dropdownBackground: white,
    border: neutral[500],
    boxShadow: "0px 2px 5px #31064d26",
  },
  feedback: {
    red: "#e85a5a",
    orange: "#fb8b3b",
    yellow: "#FFD944",
    green: "#63C161",
  },
  feedbackContrast: {
    red: white,
    orange: white,
    yellow: white,
    green: white,
  },
  scrollbar: {
    thumb: "#D5D7E5",
    thumbHover: "#9CA8BD",
  },
  tooltip: {
    background: neutral[200],
    boxShadow: `0px 2px 2px rgba(0,0,0,0.25)`,
    color: white,
  },
  chart: {
    background: {
      teal: "#00CEBB",
      purple: "#A05AF4",
      yellow: "#FFCF54",
      orange: "#FD6F5C",
    },
    gridlines: "#eee",
  },
  toggles: {
    background: "#f2f2ff",
    selected: {
      background: "#5a59f4",
      color: "#ffffff",
    },
    default: {
      background: "#ffffff",
      color: lightColors.bright,
    },
  },
  popover: {
    background: lightColors.cardBackground,
    divider: "#EFEFF6",
    boxShadow: "0px 4px 8px #2b0c482e",
  },
  input: {
    outlined: {
      background: white,
      borderColor: "#E3EAF4",
      borderColorSecondary: "#C689FF",
      boxShadow: "0 0 0 3px rgba(197,149,255,0.48)",
    },
    filled: {
      background: "#E3EAF4",
      backgroundSecondary: "#ffffff",
      backgroundDisabled: "#f3f7ff",
      borderColor: "#C689FF",
      boxShadow: "0px 0px 0 3px rgba(197,149,255,0.48)",
    },
  },
  table: {
    rowBackground: white,
    rowBackgroundContrast: purple[500],
    rowOutlineContrast: purple[400],
    rowBoxShadow: "0px 0px 6px rgba(52,12,90,0.15)",
  },
  game: {
    victoryStars: "#d7550b",
    ...sharedGameColors,
    cards: {
      cardEnergy: "#f7a411",
      cardDamage: "#BA3650",
      cardShield: "#006DBD",
      cardHeal: "#008F61",
      ...sharedCardColors,
    },
  },
  axieCard: {
    background: lightColors.cardBackground,
    border: "#ecedf1",
    borderHover: "#d4d7e1",
    statBackground: "#F0F1F5",
    loading: "#E4E7ED",
  },
  inspector: {
    background: "#fefefe",
    boxShadow: "rgba(0,0,0,0.1)",
    shaded: "#eef1f7",
  },
};

const darkColors: BaseModeColors = {
  bright: "#eeeeee",
  regular: "#d2d0e5",
  muted: "#A7A4BC",
  veryMuted: "#5F5B7B",
  veryVeryMuted: neutral[100],
  shade1: "#242438",
  shade2: "#1C1C28",
  pop1: "#3B3854",
  pop2: "#524F6C",
  pop3: "#787499",
  gradientContrast: `linear-gradient(90deg, #B378FF 0%, #4AABF9 50%, #05D9C5 100%)`,
  primary: {
    main: "#C595FF",
    contrastText: black,
  },
  secondary: {
    main: "#09DDC9",
    contrastText: black,
  },
  cardBackground: "#2d2a44",
};

const dark: ModeColors = {
  ...darkColors,
  text: {
    primary: darkColors.primary.main,
    secondary: darkColors.secondary.main,
    bright: darkColors.bright,
    regular: darkColors.regular,
    muted: darkColors.muted,
    gradient: darkColors.gradientContrast,
    veryMuted: darkColors.veryMuted,
    veryVeryMuted: darkColors.veryVeryMuted,
  },
  card: {
    background: darkColors.cardBackground,
    gradient: purple.gradient,
    title: darkColors.regular,
    divider: darkColors.veryVeryMuted,
    icon: darkColors.secondary.main,
    boxShadow: "0px 4px 8px #131e24",
    label: darkColors.primary.main,
    text: darkColors.bright,
    subText: "#AEA9D6",
    shade1: darkColors.shade1,
    shade2: darkColors.shade2,
    pop1: darkColors.pop1,
    pop2: darkColors.pop2,
    pop3: darkColors.pop3,
    border: "#2d2a44",
  },
  background: {
    default: "#21212F",
    subtle: "#16141D",
    gradient: `linear-gradient(#262636, #16141D)`,
    gradientContrast: darkColors.gradientContrast,
  },
  navigation: {
    background: "#12121c",
    dropdownBackground: "#3B3854",
    border: "#1c1c28",
    boxShadow: `0px 2px 4px #131e24b3`,
  },
  footer: {
    color: "#A7A4BC",
    header: "#D9D9D9",
    backgroundSolid: "#12121c",
    background: "linear-gradient(#12121c, #100c1c)",
  },
  feedback: {
    red: "#F2607C",
    orange: "#F08A51",
    yellow: "#F3E885",
    green: "#72E29F",
  },
  feedbackContrast: {
    red: black,
    orange: black,
    yellow: black,
    green: black,
  },
  scrollbar: {
    thumb: "#5A5674",
    thumbHover: "#7F7AA0",
  },
  tooltip: {
    background: neutral[600],
    boxShadow: `0px 2px 4px rgba(0,0,0,0.3)`,
    color: neutral[200],
  },
  chart: {
    background: {
      teal: "#00F1DA",
      purple: "#AC68FF",
      yellow: "#FFD465",
      orange: "#FF7360",
    },
    gridlines: "#000000",
  },
  toggles: {
    background: "#20202e",
    selected: {
      background: "#686cff",
      color: "#ffffff",
    },
    default: {
      background: darkColors.cardBackground,
      color: darkColors.bright,
    },
  },
  popover: {
    background: "#373452",
    divider: neutral[200],
    boxShadow: "0px 4px 8px #131e24",
  },
  input: {
    outlined: {
      background: darkColors.cardBackground,
      borderColor: "#4d496c",
      borderColorSecondary: "#A282C9",
      boxShadow: "0 0 0 3px rgba(197,149,255,0.48)",
    },
    filled: {
      background: "#222033",
      backgroundSecondary: "#222033",
      backgroundDisabled: "#29263d",
      borderColor: "#7E62A5",
      boxShadow: "0px 0px 0 3px rgba(197,149,255,0.18)",
    },
  },
  table: {
    rowBackground: darkColors.cardBackground,
    rowBackgroundContrast: "#3b3854",
    rowOutlineContrast: purple[400],
    rowBoxShadow: "0px 0px 8px rgba(24,24,35,0.82)",
  },
  game: {
    victoryStars: "#FFB00F",
    ...sharedGameColors,
    cards: {
      cardEnergy: "#f4bb57",
      cardDamage: "#fc496c",
      cardShield: "#309ae8",
      cardHeal: "#1ebe8a",
      ...sharedCardColors,
    },
  },
  axieCard: {
    background: "rgba(255,255,255,0.02)",
    border: "#3c3c4a",
    borderHover: "#545462",
    statBackground: "#1c1c28",
    loading: "#3B3854",
  },
  inspector: {
    background: "#21212F",
    boxShadow: "rgba(0,0,0,0.2)",
    shaded: "#18131f",
  },
};

const axie: AxieColors = {
  mystic: "#8d5fff",
  origin: "#fe4c52",
  woohoo: "#ff7c8a",
  hp: "#7BBC85",
  speed: "#F7AC09",
  skill: "#693AC2",
  morale: "#C23A3A",
};

const origin: OriginColors = {
  yellow: "#FADA5E",
  orange: "#FE7061",
  wood: {
    background: "#39241A",
    backgroundDark: "#1B120C",
    text: "#FAE6C7",
    input: {
      background: "#271A11",
      border: "rgba(255,255,255,0.25)",
    },
  },
  paper: {
    light: "#FFF0DC",
    medium: "#F0DCC0",
    dark: "#E2CFB2",
  },
  text: {
    brown: "#936E59",
  },
  button: {
    color: "#FF6060",
    background: {
      default:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FADA5E",
      hover:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FF6060",
    },
    boxShadow: {
      default:
        "3.05268px 3.05268px 0px rgba(0, 0, 0, 0.08), inset 0px -4.57903px 0px rgba(233, 84, 95, 0.24), inset 0px 3.05268px 0px rgba(255, 255, 255, 0.17)",
      hover:
        "3.05268px 3.05268px 0px rgba(0, 0, 0, 0.08), inset 0px -4.57903px 0px rgba(165, 59, 67, 0.24), inset 0px 3.05268px 0px rgba(255, 255, 255, 0.17)",
    },
  },
};

const events: EventColors = {
  summerBreeding: "linear-gradient(270deg, #ffd158 0%, #ff8368 100%)",
  summerBreedingShiny:
    "linear-gradient(220deg, #ffd158 0%, #ffdba9 50%, #ff8368 100%)",
};

const rarity: RarityColors = {
  Common: "#7E7272",
  Rare: "#76902F",
  Epic: "#34708E",
  Mystic: "#6B36A0",
};

const doll: DollColors = {
  input: "rgb(125, 201, 219)",
  blue: "#23B8D1",
  stripe: "#EEF9FE",
  muted: "#7DA0BA",
  pink: "#EE649E",
  pinkDark: "#993D7A",
  axieClass: {
    aquatic: "#235596",
    beast: "#955d1d",
    bug: "#692F2E",
    plant: "#44622C",
    bird: "#8F2F69",
    dawn: "#3D305B",
    dusk: "#254D50",
    mech: "#4A5050",
    reptile: "#603679",
  },
};

const axieQuest: AxieQuestColors = {
  pageBackground: "rgb(216, 241, 254)",
  moon: "#D32B2C",
  sun: "#E3B609",
  star: "#2F57F3",
  gradient: {
    sun: "linear-gradient(#ECBE10, #D28004)",
    moon: "linear-gradient(#DB3536, #980102)",
    star: "linear-gradient(#2F57F3, #102D9C)",
    passive: "linear-gradient(#EFF1F6, #919194)",
  },
  darkYellow: "#FFA010",
  chipBackground: {
    transparent: "rgba(0, 0, 0, 40%)",
    solid: "rgba(0, 0, 0, 100%)",
  },
  blue: {
    light: "#045E9C",
    medium: "#00376F",
    dark: "#03426F",
  },
  triggerEvent: "#5DB11E",
  tooltip: "rgba(216,241,254,1)",
};

const interDungeon: InterDungeonColors = {
  cardContainer: "rgba(253, 220, 171, 0.2)",
  darkRed: "#662D2A",
  darkGreen: "#3D4627",
  lightOrange: "rgba(229, 160, 79, 1)",
  darkOrange: "rgba(231, 94, 81, 1)",
  cardBorder: {
    common: "rgba(68, 82, 43, 1)",
    uncommon: "#662D2A",
    rare: "#6C7576",
    legendary: "#7A3AAB",
  },
  gradient: {
    common: "linear-gradient(rgba(59, 70, 35, 1), rgba(68, 82, 43, 1))",
    uncommon: "linear-gradient(#6C7576, #8C999A)",
    rare: "linear-gradient(#363F57, #363F57)",
    legendary: "linear-gradient(#662D2A, #87332F)",
  },
  controls: {
    teal: "rgba(38, 56, 52, 1)",
    tealOpaque: "rgba(38, 56, 52, 0.6)",
    lightTeal: "rgba(85, 96, 95, 1)",
    shadow: "#141A18",
  },
  cardElements: {
    lightBg: "#E3D7B7",
    darkBg: "rgba(40, 38, 35, 1)",
    greyBrown: "rgba(79, 73, 60, 1)",
    mediumBg: "rgba(41, 37, 44, 1)",
    textBlue: "#2D9BF0",
    textRed: "#9C2F22",
    highlight: "#B2DE59",
  },
};

const brands: BrandColors = {
  twitter: "#4ca0eb",
  website: purple[400],
  youtube: "#ea3323",
  twitch: "#8650f6",
  email: purple[400],
  linkedIn: "#0c66c2",
};

export const colors: Colors = {
  black,
  white,
  neutral,
  purple,
  axie,
  brands,
  events,
  origin,
  doll,
  rarity,
  axieQuest,
  interDungeon,
};

export const themeColors: ThemeColors = {
  light,
  dark,
};

interface BrandColors {
  twitter: string;
  website: string;
  youtube: string;
  twitch: string;
  email: string;
  linkedIn: string;
}

interface AxieColors {
  mystic: string;
  origin: string;
  woohoo: string;
  hp: string;
  speed: string;
  skill: string;
  morale: string;
}

interface NeutralColors {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
}

interface PurpleColors {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  gradient: string;
  gradientDark: string;
  gradientLight: string;
}

interface SharedGameColors {
  adventure: {
    main: string;
    alternate: string;
  };
  arena: {
    main: string;
    alternate: string;
  };
  breedingSimulator: {
    lock: string;
  };
}

interface DollColors {
  input: string;
  blue: string;
  stripe: string;
  muted: string;
  pink: string;
  pinkDark: string;
  axieClass: {
    aquatic: string;
    beast: string;
    bug: string;
    plant: string;
    bird: string;
    dawn: string;
    dusk: string;
    mech: string;
    reptile: string;
  };
}

interface OriginColors {
  yellow: string;
  orange: string;
  wood: {
    background: string;
    backgroundDark: string;
    text: string;
    input: {
      background: string;
      border: string;
    };
  };
  paper: {
    light: string;
    medium: string;
    dark: string;
  };
  text: {
    brown: string;
  };
  button: {
    color: string;
    background: {
      default: string;
      hover: string;
    };
    boxShadow: {
      default: string;
      hover: string;
    };
  };
}

interface AxieQuestColors {
  pageBackground: string;
  moon: string;
  sun: string;
  star: string;
  gradient: {
    sun: string;
    moon: string;
    star: string;
    passive: string;
  };
  darkYellow: string;
  chipBackground: {
    transparent: string;
    solid: string;
  };
  blue: {
    light: string;
    medium: string;
    dark: string;
  };
  triggerEvent: string;
  tooltip: string;
}

interface InterDungeonColors {
  cardContainer: string;
  darkRed: string;
  darkGreen: string;
  lightOrange: string;
  darkOrange: string;
  cardBorder: {
    common: string;
    uncommon: string;
    rare: string;
    legendary: string;
  };
  gradient: {
    common: string;
    uncommon: string;
    rare: string;
    legendary: string;
  };
  controls: {
    teal: string;
    tealOpaque: string;
    lightTeal: string;
    shadow: string;
  };
  cardElements: {
    lightBg: string;
    darkBg: string;
    mediumBg: string;
    textBlue: string;
    textRed: string;
    greyBrown: string;
    highlight: string;
  };
}

interface EventColors {
  summerBreeding: string;
  summerBreedingShiny: string;
}

interface RarityColors {
  Common: string;
  Rare: string;
  Epic: string;
  Mystic: string;
}

interface BaseModeColors {
  bright: string;
  regular: string;
  muted: string;
  veryMuted: string;
  veryVeryMuted: string;
  shade1: string;
  shade2: string;
  pop1: string;
  pop2: string;
  pop3: string;
  gradientContrast: string;
  primary: {
    main: string;
    contrastText: string;
  };
  secondary: {
    main: string;
    contrastText: string;
  };
  cardBackground: string;
}

export interface ModeColors extends BaseModeColors {
  text: {
    primary: string;
    secondary: string;
    bright: string;
    regular: string;
    muted: string;
    gradient: string;
    veryMuted: string;
    veryVeryMuted: string;
  };
  card: {
    background: string;
    gradient: string;
    title: string;
    divider: string;
    icon: string;
    boxShadow: string;
    label: string;
    text: string;
    subText: string;
    shade1: string;
    shade2: string;
    pop1: string;
    pop2: string;
    pop3: string;
    border: string;
  };
  background: {
    default: string;
    subtle: string;
    gradient: string;
    gradientContrast: string;
  };
  navigation: {
    background: string;
    dropdownBackground: string;
    border: string;
    boxShadow: string;
  };
  footer: {
    color: string;
    header: string;
    backgroundSolid: string;
    background: string;
  };
  feedback: {
    red: string;
    orange: string;
    yellow: string;
    green: string;
  };
  feedbackContrast: {
    red: string;
    orange: string;
    yellow: string;
    green: string;
  };
  scrollbar: {
    thumb: string;
    thumbHover: string;
  };
  tooltip: {
    background: string;
    boxShadow: string;
    color: string;
  };
  chart: {
    background: {
      teal: string;
      purple: string;
      yellow: string;
      orange: string;
    };
    gridlines: string;
  };
  toggles: {
    background: string;
    selected: {
      background: string;
      color: string;
    };
    default: {
      background: string;
      color: string;
    };
  };
  popover: {
    background: string;
    divider: string;
    boxShadow: string;
  };
  input: {
    outlined: {
      background: string;
      borderColor: string;
      borderColorSecondary: string;
      boxShadow: string;
    };
    filled: {
      background: string;
      backgroundSecondary: string;
      backgroundDisabled: string;
      borderColor: string;
      boxShadow: string;
    };
  };
  table: {
    rowBackground: string;
    rowBackgroundContrast: string;
    rowOutlineContrast: string;
    rowBoxShadow: string;
  };
  game: {
    victoryStars: string;
    cards: CardColors;
    adventure: {
      main: string;
      alternate: string;
    };
    arena: {
      main: string;
      alternate: string;
    };
    breedingSimulator: {
      lock: string;
    };
  };
  axieCard: {
    background: string;
    border: string;
    borderHover: string;
    statBackground: string;
    loading: string;
  };
  inspector: {
    background: string;
    boxShadow: string;
    shaded: string;
  };
}

interface SharedCardColors {
  skill: string;
  ethereal: string;
  banish: string;
  innate: string;
  secret: string;
  power: string;
  attack: string;
  retain_effect: string;
}

export interface CardColors extends SharedCardColors {
  cardEnergy: string;
  cardDamage: string;
  cardShield: string;
  cardHeal: string;
}

export interface Colors {
  black: string;
  white: string;
  neutral: NeutralColors;
  purple: PurpleColors;
  axie: AxieColors;
  brands: BrandColors;
  events: EventColors;
  origin: OriginColors;
  doll: DollColors;
  rarity: RarityColors;
  axieQuest: AxieQuestColors;
  interDungeon: InterDungeonColors;
}

export interface ThemeColors {
  light: ModeColors;
  dark: ModeColors;
}
