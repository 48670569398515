import { useState } from "react";
import { Stack, Tooltip, IconButton } from "@mui/material";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { toggleTextWithDelay, makePlayerUrl } from "helpers/display";
import { Link } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";

const SocialIcons = ({ scholar }) => {
  const [discordIconTooltip, setDiscordIconTooltip] = useState(
    scholar.discordId
  );
  const [twitterIconTooltip, setTwitterIconTooltip] = useState(
    scholar.twitterId
  );

  return (
    <Stack direction="row" gap={1.5}>
      <Tooltip title="Open public player page">
        <Link to={makePlayerUrl(scholar.roninAddress)} target="_blank">
          <CustomIconButton>
            <BsPersonCircle />
          </CustomIconButton>
        </Link>
      </Tooltip>

      {scholar.discordId != null && scholar.discordId !== "" && (
        <CopyToClipboard text={scholar.discordId}>
          <Tooltip
            title={discordIconTooltip}
            onClick={() =>
              toggleTextWithDelay(
                setDiscordIconTooltip,
                scholar.discordId,
                "Copied",
                1000
              )
            }
          >
            <Stack>
              <CustomIconButton>
                <FaDiscord />
              </CustomIconButton>
            </Stack>
          </Tooltip>
        </CopyToClipboard>
      )}
      {scholar.twitterId != null && scholar.twitterId !== "" && (
        <CopyToClipboard text={scholar.twitterId}>
          <Tooltip
            title={twitterIconTooltip}
            onClick={() =>
              toggleTextWithDelay(
                setTwitterIconTooltip,
                scholar.twitterId,
                "Copied",
                1000
              )
            }
          >
            <Stack>
              <CustomIconButton>
                <FaTwitter />
              </CustomIconButton>
            </Stack>
          </Tooltip>
        </CopyToClipboard>
      )}
    </Stack>
  );
};

const CustomIconButton = ({ children }) => {
  return (
    <IconButton
      size="small"
      sx={{ color: (theme) => theme.palette.text.muted }}
    >
      {children}
    </IconButton>
  );
};

export default SocialIcons;
