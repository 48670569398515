import React from "react";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import Bonus from "./Bonus";
import { Stack } from "@mui/material";
import { MdInfo } from "react-icons/md";
import { AXIE_DOLL_GITBOOK } from "config";

const Bonuses = ({ bonuses }) => {
  return (
    <Stack gap={2}>
      <SectionLabel
        icon={
          <MdInfo style={{ color: (theme) => theme.palette.text.regular }} />
        }
        iconUrl={`${AXIE_DOLL_GITBOOK}/about-the-game/body-type`}
        tooltip="Learn More"
      >
        Bonuses
      </SectionLabel>

      {bonuses.map((bonus) => (
        <Bonus key={bonus.label} bonus={bonus} />
      ))}
    </Stack>
  );
};

export default Bonuses;
