import { cancel, fork, put, take, takeEvery } from "redux-saga/effects";
import axieSaga from "./axieSaga";
import numberOfSimilarAxiesSaga from "./numberOfSimilarAxiesSaga";
import mysticFloorSaga from "./mysticFloorSaga";
import cheapestSimilarListingsSaga from "./cheapestSimilarListingsSaga";
import estimatedPriceSaga from "./estimatedPriceSaga";
import recentlySoldSaga from "./recentlySoldSaga";
import * as types from "./inspectorActionTypes";
import * as actions from "./inspectorActions";

export function* forkSagas(action) {
  const axieTask = yield fork(axieSaga);
  const numberOfSimilarAxiesTask = yield fork(numberOfSimilarAxiesSaga);
  const mysticFloorTask = yield fork(mysticFloorSaga);
  const similarListingsTask = yield fork(cheapestSimilarListingsSaga);
  const recentlySoldTask = yield fork(recentlySoldSaga);
  const estimatedPriceTask = yield fork(estimatedPriceSaga);

  yield put(actions.fetchAxie(action.axieId));

  yield take(types.INSPECTOR_CANCEL_FETCHING);
  yield cancel(axieTask);
  yield cancel(numberOfSimilarAxiesTask);
  yield cancel(mysticFloorTask);
  yield cancel(similarListingsTask);
  yield cancel(recentlySoldTask);
  yield cancel(estimatedPriceTask);
}

export default function* inspectorSaga() {
  yield takeEvery(types.INSPECTOR_SEARCH, forkSagas);
}
