import styles from "../welcome.module.scss";
import { Stack, useTheme, Typography, Button, Link } from "@mui/material";
import { FaArrowCircleRight } from "react-icons/fa";
import { addAlphaToHex } from "helpers/colors";

const WelcomeMessage = ({
  handleConnect,
  loginFailed,
  loading,
  theme,
  chainIdNotSupportedError,
}) => {
  const mailTo =
    "mailto:lev@axie.tech,rare@axie.tech,best@axie.tech?subject=Axie%20Tech%20VIP%20Membership";

  return (
    <div className={styles.welcomeMessageWrapper}>
      <div
        className={styles.welcomeMessage}
        style={{
          backgroundColor: theme.palette.card.background,
          boxShadow: theme.palette.card.boxShadow,
        }}
      >
        <Stack alignItems="center" gap={2}>
          {theme.palette.mode === "light" ? (
            <img
              src="/images/axie-tech-logo-mark-purple.png"
              className={`${styles.logoMark} ${loading ? styles.rotate : ""}`}
              alt="Axie Tech Logo"
            />
          ) : (
            <img
              src="/images/axie-tech-logo-mark-purple-light.png"
              className={`${styles.logoMark} ${loading ? styles.rotate : ""}`}
              alt="Axie Tech Logo"
            />
          )}

          <Typography
            variant="h1"
            fontSize="30px"
            fontWeight="700"
            color="text.bright"
          >
            Greetings, Manager!
          </Typography>

          {loading && (
            <Paragraph>We're getting things ready for you...</Paragraph>
          )}
          {!loading && (
            <>
              <Paragraph>
                Axie.Tech VIP Membership includes a suite of tools, which allow
                guild managers to streamline their day-to-day operations, whilst
                improving performance; both overall and at the individual level.
              </Paragraph>
              <Button
                variant="contained"
                size="medium"
                onClick={handleConnect}
                endIcon={<FaArrowCircleRight />}
                color="primary"
              >
                Login with MetaMask
              </Button>
            </>
          )}

          {chainIdNotSupportedError && (
            <Warning>Please switch your network to Ethereum Mainnet</Warning>
          )}

          {loginFailed && (
            <Warning>
              Do you need an account?{" "}
              <Link
                to="#"
                onClick={(e) => {
                  window.open(mailTo, "_blank");
                  e.preventDefault();
                }}
              >
                <Typography
                  variant="span"
                  fontSize="14px"
                  fontWeight="600"
                  color={theme.palette.primary.main}
                >
                  Contact us!
                </Typography>
              </Link>
            </Warning>
          )}
        </Stack>
      </div>
    </div>
  );
};

const Paragraph = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      fontSize="17px"
      fontWeight="500"
      color={theme.palette.card.subText}
      lineHeight="1.45"
      gutterBottom
    >
      {children}
    </Typography>
  );
};

const Warning = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="span"
      fontSize="14px"
      fontWeight="600"
      color={theme.palette.text.bright}
      borderRadius="4px"
      p={1}
      backgroundColor={addAlphaToHex(theme.palette.primary.main, 0.08)}
      sx={{ userSelect: "none" }}
    >
      {children}
    </Typography>
  );
};

export default WelcomeMessage;
