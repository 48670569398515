import TextButton from "components/Origin/atoms/TextButton";

interface Props {
  showItems: boolean;
  setShowItems: (showItems: boolean) => void;
}

const ToggleDetailedView = ({ showItems, setShowItems }: Props) => {
  return (
    <TextButton
      textAlign="center"
      text={showItems ? "Hide Detailed View" : "Show Detailed View"}
      onClick={() => setShowItems(!showItems)}
    />
  );
};

export default ToggleDetailedView;
