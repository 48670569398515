import { Link as RouterLink } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { todayIsSholarsBirthday } from "helpers/scholars";
import Inactive from "./Inactive";
import { makeScholarPageURLFromRoninAddress } from "helpers/guild";
import CopyAddress from "components/Typography/CopyAddress";

const ScholarName = ({ id, name, dob, guildTeamName, prevPath, isActive }) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
    >
      <RouterLink
        to={makeScholarPageURLFromRoninAddress(id)}
        state={{
          prevPath,
        }}
        sx={{
          lineHeight: 1,
          pb: 0,
          border: "none",
          "&:hover": {
            border: "none",
          },
        }}
      >
        <Stack gap={0.25} sx={{ pl: "4px" }}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Inactive isActive={isActive} />
            <Typography
              variant="span"
              fontSize="14px"
              color="text.primary"
              fontWeight="700"
              sx={{
                lineHeight: 1,
              }}
            >
              {name}
            </Typography>

            {todayIsSholarsBirthday(dob) && (
              <Typography
                variant="span"
                fontSize="20px"
                sx={{ position: "absolute", left: "105%" }}
              >
                🎂
              </Typography>
            )}
          </Stack>
          {guildTeamName != null && (
            <Typography
              variant="span"
              fontSize="12px"
              color="text.muted"
              fontWeight="600"
              sx={{ lineHeight: 1 }}
            >
              {guildTeamName}
            </Typography>
          )}
        </Stack>
      </RouterLink>
      <CopyAddress address={id} />
    </Stack>
  );
};

export default ScholarName;
