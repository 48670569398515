import Cards from "./Cards";
import Runes from "./Runes";
import Charms from "./Charms";
import Tools from "./Tools";
import Curses from "./Curses";
import StatusEffects from "./StatusEffects";
import RevengeCards from "./RevengeCards";
import Favorites from "../Favorites";

const Items = {
  Cards,
  Runes,
  Charms,
  Tools,
  Curses,
  StatusEffects,
  RevengeCards,
  Favorites,
};

export default Items;
