import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import RuneTemplateTiny from "components/GameTemplates/RuneTemplateTiny";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "mode",
})(({ theme, mode }) => ({
  background:
    mode === "light"
      ? theme.palette.colors.white
      : theme.palette.colors.origin.wood.backgroundDark,
  borderRadius: "100%",
  padding: "2px",
  transition: "0.2s transform ease",

  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const RuneIcon = ({ rune, mode = "light", size = 20 }) => {
  return rune == null ? (
    <></>
  ) : (
    <Wrapper className="runeIcon" mode={mode}>
      <RuneTemplateTiny rune={rune} width={size} tooltip={true} />
    </Wrapper>
  );
};

export default RuneIcon;
