const ORDER = {
  Beta: 3,
  Alpha: 2,
  "Closed Testing": 1,
  Building: 0,
};

export const sortByStatus = (a, b, sortAlphabetical) => {
  const aStatus = ORDER[a.status] || 0;
  const bStatus = ORDER[b.status] || 0;

  if (aStatus < bStatus) {
    return 1;
  } else if (aStatus > bStatus) {
    return -1;
  } else if (sortAlphabetical) {
    return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
  }
};
