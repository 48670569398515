import { createSelector } from "reselect";
import {
  getPaginationEndIndex,
  getPaginationStartIndex,
  getSortedPairString,
} from "helpers/display";
import {
  axieIsAdult,
  calculateRequiredAXSForBreed,
  calculateRequiredSLPForBreed,
} from "helpers/axie";
import { breedAxies } from "helpers/breeding";
import {
  NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE,
  NUM_BREEDING_SIMULATOR_RONIN_AXIES_PER_PAGE,
} from "config";
import { isBreedingEventActive } from "helpers/time";

const getBreedingAxieIds = (state) => {
  return state.breedingSimulator?.ids || [];
};

const getRequiredBreedingAxieId = (state) => {
  return state.breedingSimulator?.requiredId || "";
};

const getAxiesDataForBreeding = (state) => {
  return state.breedingSimulator?.axieData || {};
};

const getDeletedBreedingPairs = (state) => {
  return state.breedingSimulator?.deletedPairs || [];
};

const getShowOnlyBreedingEventPairs = (state) => {
  return state.breedingSimulator?.showOnlyBreedingEventPairs || false;
};

const getNumberOfBreeds = (state) => {
  return state.breedingSimulator?.numberOfBreeds || {};
};

const getLockedPairs = (state) => {
  return state.breedingSimulator?.lockedPairs || [];
};

const getLockedIds = (state) => {
  return state.breedingSimulator?.lockedIds || [];
};

const getCurrentPageUnlocked = (state) => {
  return state.breedingSimulator?.currentPageUnlocked || 1;
};

const getCurrentPageLocked = (state) => {
  return state.breedingSimulator?.currentPageLocked || 1;
};

const getCurrentPageInvalid = (state) => {
  return state.breedingSimulator?.currentPageInvalid || 1;
};

const getSortType = (state) => {
  return state.breedingSimulator?.sortType || true;
};

const getAxiesFromRonin = (state) => {
  return state.breedingSimulator?.axiesFromRonin || [];
};

const getCurrentPageSelectAxiesDialog = (state) => {
  return state.breedingSimulator?.currentPageSelectAxiesDialog || [];
};

const getBreedCountSelectAxiesDialog = (state) => {
  return state.breedingSimulator?.breedCountSelectAxiesDialog || [];
};

const getPuritySelectAxiesDialog = (state) => {
  return state.breedingSimulator?.puritySelectAxiesDialog || [];
};

const getAxieClassesSelectAxiesDialog = (state) => {
  return state.breedingSimulator?.axieClassesSelectAxiesDialog || [];
};

export const axiesAreRelated = (a, b) => {
  if (
    !a ||
    !b ||
    (a.sireId === 0 && a.matronId === 0 && b.sireId === 0 && b.matronId === 0)
  ) {
    return false;
  }
  if (
    a.sireId === parseInt(b.axieId) ||
    a.matronId === parseInt(b.axieId) ||
    b.sireId === parseInt(a.axieId) ||
    b.matronId === parseInt(a.axieId)
  ) {
    return true;
  }
  if (
    a.sireId === b.sireId ||
    a.matronId === b.sireId ||
    a.sireId === b.matronId ||
    a.matronId === b.matronId
  ) {
    return true;
  }
  return false;
};

export const getPairsOfAxiesToBreed = createSelector(
  [getBreedingAxieIds, getDeletedBreedingPairs],
  (ids, deletedPairs) => {
    const filteredPairs = [];
    ids.map((v, i) =>
      ids.slice(i + 1).forEach((w) => {
        if (!deletedPairs.includes(getSortedPairString([v, w]))) {
          filteredPairs.push([v, w]);
        }
      })
    );
    return [...filteredPairs];
  }
);

export const getBreedingMessage = createSelector(
  [getBreedingAxieIds],
  (ids) => {
    if (ids.length === 1) {
      return "Enter at least 2 ids to breed";
    }
    return undefined;
  }
);

export const createLockedResultJSON = createSelector(
  [getLockedPairs, getNumberOfBreeds, getAxiesDataForBreeding],
  (lockedPairs, numberOfBreeds, axieData) => {
    return lockedPairs.map((pairStr) => {
      const pair = pairStr.split("-");
      const axie1data = axieData[pair[0]];
      const axie2data = axieData[pair[1]];
      return {
        parent1: parseInt(pair[0]),
        parent2: parseInt(pair[1]),
        numberOfBreeds: numberOfBreeds[pairStr] || 1,
        axs: calculateRequiredAXSForBreed(
          numberOfBreeds[getSortedPairString([pair[0], pair[1]])] || 1,
          axie1data.breedCount,
          axie2data.breedCount
        ),
        slp: calculateRequiredSLPForBreed(
          numberOfBreeds[getSortedPairString([pair[0], pair[1]])] || 1,
          axie1data.breedCount,
          axie2data.breedCount
        ),
      };
    });
  }
);

const sortResults = (a, b, sortType) => {
  switch (sortType) {
    case "BREEDING_EVENT_PARTS_DESC":
      return a.offspring?.numberOfSpecialParts >
        b.offspring?.numberOfSpecialParts
        ? -1
        : a.offspring?.numberOfSpecialParts < b.offspring?.numberOfSpecialParts
        ? 1
        : 0;
    case "BREEDING_EVENT_PARTS_ASC":
      return a.offspring?.numberOfSpecialParts <
        b.offspring?.numberOfSpecialParts
        ? -1
        : a.offspring?.numberOfSpecialParts > b.offspring?.numberOfSpecialParts
        ? 1
        : 0;
    case "COST_DESC":
      return a.breedingCost?.slp > b.breedingCost?.slp
        ? -1
        : a.breedingCost?.slp < b.breedingCost?.slp
        ? 1
        : 0;
    case "COST_ASC":
      return a.breedingCost?.slp < b.breedingCost?.slp
        ? -1
        : a.breedingCost?.slp > b.breedingCost?.slp
        ? 1
        : 0;
    case "PURITY_ASC":
      return a.offspring?.purityScore < b.offspring?.purityScore
        ? -1
        : a.offspring?.purityScore > b.offspring?.purityScore
        ? 1
        : 0;
    case "PURITY_DESC":
    default:
      return a.offspring?.purityScore > b.offspring?.purityScore
        ? -1
        : a.offspring?.purityScore < b.offspring?.purityScore
        ? 1
        : 0;
  }
};

export const getBreedingResults = createSelector(
  [
    getPairsOfAxiesToBreed,
    getAxiesDataForBreeding,
    getShowOnlyBreedingEventPairs,
    getNumberOfBreeds,
    getCurrentPageUnlocked,
    getCurrentPageLocked,
    getCurrentPageInvalid,
    getSortType,
    getLockedIds,
    getLockedPairs,
    getRequiredBreedingAxieId,
  ],
  (
    pairs,
    axieData,
    showOnlyBreedingEventPairs,
    numberOfBreeds,
    currentPageUnlocked,
    currentPageLocked,
    currentPageInvalid,
    sortType,
    lockedIds,
    lockedPairs,
    requiredId
  ) => {
    const breedingEventIsActive = isBreedingEventActive();
    const startUnlocked = getPaginationStartIndex(
      currentPageUnlocked,
      NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE
    );
    const endUnlocked = getPaginationEndIndex(
      startUnlocked,
      NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE
    );

    const startLocked = getPaginationStartIndex(
      currentPageLocked,
      NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE
    );
    const endLocked = getPaginationEndIndex(
      startLocked,
      NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE
    );

    const startInvalid = getPaginationStartIndex(
      currentPageInvalid,
      NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE
    );
    const endInvalid = getPaginationEndIndex(
      startInvalid,
      NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE
    );

    const emptyResult = {
      breedingCost: {
        slp: 0,
        axs: 0,
      },
      offspring: {
        purityScore: 0,
      },
    };

    let numberOfUnlockedResults = 0;
    let numberOfLockedResults = 0;
    let numberOfInvalidResults = 0;

    const totalLockedBreedingCost = { slp: 0, axs: 0 };

    const breedingResults = pairs.reduce(
      (result, pair) => {
        // Make sure the requiredId is the first index, if it's in the pair
        if (pair[1] === requiredId) {
          [pair[0], pair[1]] = [pair[1], pair[0]];
        }

        let axie1 = axieData[pair[0]];
        let axie2 = axieData[pair[1]];
        let currentResult = {
          ...emptyResult,
          pair,
        };

        if (axie1 === undefined || axie2 === undefined) {
          currentResult.invalid = true;
          currentResult.failMessage = "Cannot find axie";
        } else if (axie1.fetching || axie2.fetching) {
          currentResult.invalid = true;
          currentResult.failMessage = "Loading...";
        } else if (axie1 || axie2) {
          if (!axieIsAdult(axie1) || !axieIsAdult(axie2)) {
            currentResult.invalid = true;
            currentResult.failMessage = "Cannot breed eggs";
          } else if (axie1.breedCount === 7 || axie2.breedCount === 7) {
            currentResult.invalid = true;
            currentResult.failMessage = "Cannot breed more than 7 times";
            currentResult.offspring = breedAxies(axie1, axie2);
          } else if (axiesAreRelated(axie1, axie2)) {
            currentResult.invalid = true;
            currentResult.failMessage = "Cannot breed related axies";
            currentResult.offspring = breedAxies(axie1, axie2);
          } else {
            if (!axie1.parts || !axie2.parts) {
              currentResult.invalid = true;
              currentResult.failMessage = "Cannot breed these axies";
            } else {
              currentResult.offspring = breedAxies(axie1, axie2);
              currentResult.breedingCost = {
                axs: calculateRequiredAXSForBreed(
                  numberOfBreeds[getSortedPairString([pair[0], pair[1]])] || 1,
                  axie1.breedCount,
                  axie2.breedCount
                ),
                slp: calculateRequiredSLPForBreed(
                  numberOfBreeds[getSortedPairString([pair[0], pair[1]])] || 1,
                  axie1.breedCount,
                  axie2.breedCount
                ),
              };
            }
          }
        }

        // Result is invalid
        if (
          currentResult.invalid &&
          currentResult.failMessage !== "Loading..."
        ) {
          if (matchesRequiredId(requiredId, pair)) {
            result.invalid.push(currentResult);
            numberOfInvalidResults += 1;
          }
        }
        // Result is valid
        else {
          // Result doesn't match breeding event criteria
          if (
            !showOnlyBreedingEventPairs ||
            (breedingEventIsActive &&
              showOnlyBreedingEventPairs &&
              currentResult.offspring.numberOfSpecialParts > 0)
          ) {
            // Result is not locked
            if (
              pairIsUnlocked(lockedIds, pair) &&
              matchesRequiredId(requiredId, pair)
            ) {
              result.unlocked.push(currentResult);
              numberOfUnlockedResults += 1;
            }
            //Result is locked
            else if (lockedPairs.includes(getSortedPairString(pair))) {
              totalLockedBreedingCost.slp += currentResult.breedingCost.slp;
              totalLockedBreedingCost.axs += currentResult.breedingCost.axs;
              result.locked.push(currentResult);
              numberOfLockedResults += 1;
            }
          }
        }

        return result;
      },
      { locked: [], unlocked: [], invalid: [] }
    );

    breedingResults.unlocked.sort((a, b) => sortResults(a, b, sortType));
    breedingResults.locked.sort((a, b) => sortResults(a, b, sortType));

    return {
      numberOfUnlockedResults,
      numberOfLockedResults,
      numberOfInvalidResults,
      unlockedResultsToDisplay: breedingResults.unlocked.slice(
        startUnlocked,
        endUnlocked
      ),
      lockedResultsToDisplay: breedingResults.locked.slice(
        startLocked,
        endLocked
      ),
      invalidResultsToDisplay: breedingResults.invalid.slice(
        startInvalid,
        endInvalid
      ),
      totalLockedBreedingCost,
    };
  }
);

const pairIsUnlocked = (lockedIds, pair) =>
  !lockedIds.includes(pair[0]) && !lockedIds.includes(pair[1]);

const matchesRequiredId = (requiredId, pair) =>
  requiredId === "" || pair[0] === requiredId || pair[1] === requiredId;

export const getAxiesFromRoninToDisplay = createSelector(
  [
    getAxiesFromRonin,
    getCurrentPageSelectAxiesDialog,
    getBreedCountSelectAxiesDialog,
    getPuritySelectAxiesDialog,
    getAxieClassesSelectAxiesDialog,
    getAxiesDataForBreeding,
  ],
  (axies, currentPage, breedCount, purity, axieClasses, axieData) => {
    const start = getPaginationStartIndex(
      currentPage,
      NUM_BREEDING_SIMULATOR_RONIN_AXIES_PER_PAGE
    );
    const end = getPaginationEndIndex(
      start,
      NUM_BREEDING_SIMULATOR_RONIN_AXIES_PER_PAGE
    );

    const filteredAxies = axies.filter(
      (axie) =>
        (breedCount.includes(axie.breedCount.toString()) ||
          breedCount.length === 0) &&
        (!axieData[axie.axieId] ||
          axieData[axie.axieId].fetching ||
          purity.includes(
            axieData[axie.axieId].purity.purityCount.toString()
          ) ||
          purity.length === 0) &&
        (axieClasses.includes(axie.class) || axieClasses.length === 0)
    );

    return {
      axies: filteredAxies.slice(start, end),
      filteredAxies,
      totalNumberOfAxies: axies.length,
      numberOfFilteredAxies: filteredAxies.length,
    };
  }
);
