import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Stack, useTheme, ListItem } from "@mui/material";
import CapsuleLabel from "../atoms/CapsuleLabel";
import LinkText from "../atoms/LinkText";
import DropdownSections from "./DropdownSections";

const MobileNavItem = ({ link, closeAndCollapse }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const calculateHeight = (dropdownItems, secondaryDropdownItems) => {
    const heightPerItem = 50;
    const heightPerSection = 16 + 20; // Padding top + label height
    let sections = 0;
    let numLinks = (dropdownItems || []).length;

    if (secondaryDropdownItems) {
      for (const item of secondaryDropdownItems) {
        sections += 1;
        numLinks += (item.links || []).length;
      }
    }
    return numLinks * heightPerItem + sections * heightPerSection;
  };

  const navHeight = calculateHeight(
    link.dropdownItems,
    link.secondaryDropdownItems
  );

  const mainLinkActive =
    (link.dropdownItems || []).map((item) => item.to).includes(pathname) ||
    link.to === pathname;

  return (link.dropdownItems || []).length ? (
    <ListItem
      onClick={() => link.setIsOpen(!link.isOpen)}
      sx={{
        borderBottom: `1px solid ${theme.palette.card.divider}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Stack direction="row" alignItems="center">
        <LinkText isActive={mainLinkActive} text={link.text} />
        <CapsuleLabel style={{ ml: -1 }}>{link.label}</CapsuleLabel>
      </Stack>
      <Stack
        sx={{
          overflow: "hidden",
          transition: "0.36s height ease",
          height: link.isOpen ? navHeight : 0,
          ml: theme.spacing(4),
        }}
      >
        {link.dropdownItems.map((item, i) => (
          <NavLink
            to={item.to}
            key={`MOBILE_LINK_${i}`}
            onClick={closeAndCollapse}
          >
            <Stack direction="row" alignItems="center">
              <LinkText isActive={item.to === pathname} text={item.text} />
              <CapsuleLabel>{item.label}</CapsuleLabel>
            </Stack>
          </NavLink>
        ))}
        {link.secondaryDropdownItems != null && (
          <DropdownSections
            secondaryItems={link.secondaryDropdownItems}
            isMobile={true}
            onClick={closeAndCollapse}
          />
        )}
      </Stack>
    </ListItem>
  ) : (
    <ListItem sx={{ borderBottom: `1px solid ${theme.palette.card.divider}` }}>
      <NavLink to={link.to} onClick={() => closeAndCollapse()}>
        <LinkText isActive={mainLinkActive} text={link.text} />
      </NavLink>
    </ListItem>
  );
};

export default MobileNavItem;
