import React from "react";
import { Typography } from "@mui/material";
import fonts from "theme/fonts";

const SubTitle = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize={18}
      fontFamily={fonts.changa}
      sx={{
        color: (theme) => theme.palette.colors.origin.wood.text,
        userSelect: "none",
      }}
    >
      {children}
    </Typography>
  );
};

export default SubTitle;
