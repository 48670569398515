import moment from "moment";
import { createSelector } from "reselect";
import {
  axieIsAdult,
  axieIsOrigin,
  countNumberOfMysticParts,
  getAxieRarity,
  getIconForAxieClass,
  makeMarketplaceSearchStringFromAxie,
  makeMarketplaceSearchStringFromNumMysticParts,
  getCardsForAxie,
  calculatePotentialPoints,
  getAxieRole,
  getAxieSpecialty,
} from "helpers/axie";
import { convertToRoninAddress } from "helpers/cleaning";
import {
  displayShortenedRoninAddress,
  makeMarketplaceURLFromRoninAddress,
} from "helpers/display";
import { getAllCardsV3, getAllCardsV2 } from "store/data/dataSelectors";
import { getDollSkills } from "store/doll/cardExplorer/selectors";
import * as utils from "./utils";
import { getDoLLBonusesForAxie, getDoLLCardsForAxie } from "helpers/axieDoll";

// add get price from MetaLend
const getAxieRaw = (state) => {
  return state.inspector.axie || {};
};

const getAxieData = (state) => {
  return state.inspector.axieData || {};
};

const getParents = (state) => {
  return state.inspector.parents || {};
};

const getRecentlySold = (state) => {
  return state.inspector.recentlySold || {};
};

const getNumberOfSimilarAxies = (state) => {
  return state.inspector.numberOfSimilarAxies || {};
};

const getMysticFloor = (state) => {
  return state.inspector.mysticFloor || {};
};

const getCheapestSimilarListings = (state) => {
  return state.inspector.cheapestSimilarListings || {};
};

const getLastSold = (state) => {
  return state.inspector.lastSold || {};
};

const getEstimatedPrice = (state) => {
  return state.inspector.estimatedPrice || {};
};

export const getInspectorData = createSelector(
  [
    getAxieRaw,
    getCheapestSimilarListings,
    getNumberOfSimilarAxies,
    getMysticFloor,
    getRecentlySold,
    getLastSold,
    getAxieData,
    getParents,
    getAllCardsV3,
    getAllCardsV2,
    getDollSkills,
    getEstimatedPrice,
  ],
  (
    axieRaw,
    cheapestSimilarListings,
    numberOfSimilarAxies,
    mysticFloor,
    recentlySold,
    lastSold,
    axieData,
    parents,
    cardsV3,
    cardsV2,
    allDoLLSkills,
    estimatedPrice
  ) => {
    const axie = axieData[axieRaw.axieId];

    if (axie == null) {
      return undefined;
    }
    const isAdult = axieIsAdult(axie);
    const isOrigin = axieIsOrigin(axie);
    const numberOfMysticParts = countNumberOfMysticParts(axie);
    const badges = utils.getBadges(
      axie,
      numberOfSimilarAxies.total,
      numberOfMysticParts
    );
    const birthday = moment.unix(axie.birthDate).format("MMM Do YYYY");
    const rarity = getAxieRarity(numberOfSimilarAxies.total);
    const parentAxies = parents?.axieIds
      ? parents.axieIds.map((axieId) => axieData[axieId])
      : undefined;

    const cheapestSimilarAxies = cheapestSimilarListings?.axieIds
      ? cheapestSimilarListings.axieIds.reduce((arr, id) => {
          if (axieData[id]) {
            arr.push(axieData[id]);
          }

          return arr;
        }, [])
      : [];
    const mysticFloorAxies = mysticFloor?.axieIds
      ? mysticFloor.axieIds.reduce((arr, id) => {
          if (axieData[id]) {
            arr.push(axieData[id]);
          }

          return arr;
        }, [])
      : [];
    const recentlySoldAxies =
      recentlySold?.axieIds?.length > 0
        ? recentlySold.axieIds
            .map((id) => axieData[id])
            .sort((a, b) => utils.sortByDateSold(a, b))
        : [];
    const theMostRecentlySoldAxie = utils.getTheMostRecentlySoldAxie(
      axie,
      recentlySoldAxies
    );
    const floorPrice = utils.getFloorPrice(
      axie,
      cheapestSimilarAxies,
      cheapestSimilarListings.total
    );
    const priceRecommendations = isOrigin
      ? utils.calculatePriceRecommendations(mysticFloor.price, lastSold)
      : utils.calculatePriceRecommendations(
          utils.calculateBasePrice(theMostRecentlySoldAxie?.price, floorPrice),
          lastSold
        );

    /* Cards */
    const axieCards = getCardsForAxie(axie, cardsV3, cardsV2);
    const axieDoLLCards = getDoLLCardsForAxie(
      axieCards?.cardV3Array,
      allDoLLSkills
    );
    const axieDollBonuses = getDoLLBonusesForAxie(axie);

    return {
      axie: {
        ...axie,
        cannotPrice: isOrigin
          ? mysticFloor.total === 0 && lastSold.price == null
          : cheapestSimilarListings.total === 0 &&
            (recentlySold.total || 0) === 0 &&
            lastSold.price == null,
        role: getAxieRole(axieCards?.cardV3Array),
        specialty: getAxieSpecialty(axieCards?.cardV3Array),
        birthday,
        badges,
        lastSold,
        isAdult,
        isOrigin,
        numberOfMysticParts,
        icon: getIconForAxieClass(axie.class),
        potentialPoints: calculatePotentialPoints(
          axieCards?.cardV3Classes,
          axie.class
        ),
        rarity: {
          ...rarity,
          numberOfSimilarAxies: numberOfSimilarAxies.total || 0,
          loading: numberOfSimilarAxies.fetching,
        },
        owner: {
          ronin: convertToRoninAddress(axie.ownerRonin),
          roninShort: displayShortenedRoninAddress(axie.ownerRonin, "ronin:"),
          marketplaceUrl: makeMarketplaceURLFromRoninAddress(
            convertToRoninAddress(axie.ownerRonin)
          ),
        },
        priceRecommendations,
        cards: axieCards,
        geneProbabilities: isAdult
          ? undefined
          : utils.getGeneProbabilities(parentAxies),
      },
      parents: {
        ...parents,
        parentAxies,
      },
      axieData,
      loading: axieRaw.fetching,
      failed: axieRaw.failed,
      recentlySold: {
        axies: recentlySoldAxies,
        total: recentlySoldAxies.length,
        theMostRecentlySoldAxie,
        loading: recentlySold.fetching,
      },
      axieDoLL: {
        cards: axieDoLLCards,
        bonuses: axieDollBonuses,
      },
      market: {
        display: isAdult ? true : false,
        loading: isOrigin
          ? mysticFloor.fetching
          : numberOfSimilarAxies.fetching || cheapestSimilarListings.fetching,
        total: isOrigin ? mysticFloor.total : cheapestSimilarListings.total,
        floorPrice: isOrigin
          ? utils.formatEthOrUndefined(mysticFloor.price, mysticFloor.price)
          : utils.formatEthOrUndefined(floorPrice, floorPrice),
        marketplaceUrl: isOrigin
          ? makeMarketplaceSearchStringFromNumMysticParts(numberOfMysticParts)
          : makeMarketplaceSearchStringFromAxie(axie),
        label: isOrigin
          ? utils.originMarketLabel(numberOfMysticParts, mysticFloor.total)
          : utils.similarListingsLabel(cheapestSimilarListings.total),
        tooltip: isOrigin
          ? `Origin Axies with ${numberOfMysticParts} mystic part${
              numberOfMysticParts === 1 ? "" : "s"
            } `
          : "Axies with the same class, cards and minimum purity and the same breed count or lower",
        axies: isOrigin ? mysticFloorAxies : cheapestSimilarAxies,
      },
      estimatedPrice,
    };
  }
);
