import { useEffect } from "react";
import { getAxieClassforId } from "helpers/axie";
import { makePPUsedArrayFromUrlString } from "helpers/items";
import { PART_ORDER } from "pages/TeamBuilder";
import { useLocation } from "react-router-dom";

export const usePopulateFromUrl = (
  initialLoad,
  slots,
  updateClass,
  updateCard,
  updateCharm,
  updateRune,
  itemData,
  loading
) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  useEffect(() => {
    if (initialLoad.current && !loading) {
      const updateTeam = (axieId, segments) => {
        // Set class
        const axieClass = getAxieClassforId(segments[0]);
        if (axieClass) {
          updateClass(axieId, axieClass);
        }

        const cardIds = segments[1] || [];
        const charmStrs = segments[2] || [];
        const runeId = segments[3];
        const cardClasses = [];

        // Set cards
        if (itemData?.cards) {
          cardIds.split("-").forEach((id, i) => {
            const card = itemData.cards.find((c) => c.id === parseInt(id));

            cardClasses.push(card?.class);
            if (card && card.partType === PART_ORDER[i]) {
              updateCard(axieId, PART_ORDER[i], card);
            }
          });
        }

        // Set charms
        if (itemData?.charms) {
          charmStrs.split("-").forEach((str, i) => {
            const segments = str.split("~");
            const charmId = segments[0];

            if (charmId !== "0") {
              const charm = itemData.charms.find(
                (c) => c.id === parseInt(charmId)
              );
              const ppUsed = makePPUsedArrayFromUrlString(segments[1]);

              if (
                charm?.class === cardClasses[i] ||
                charm?.class === "Neutral"
              ) {
                updateCharm(axieId, PART_ORDER[i], charm, ppUsed);
              }
            }
          });
        }

        // Set rune
        if (itemData?.runes) {
          const rune = itemData?.runes.find((r) => r.id === parseInt(runeId));

          if (rune) {
            updateRune(axieId, rune);
          }
        }
      };
      initialLoad.current = false;

      slots.forEach((axie) => {
        const dataSegments = decodeURI(query.get(axie)).split(".");
        if (dataSegments.length > 1) {
          updateTeam(axie, dataSegments);
        }
      });
    }
  }, [
    query,
    itemData,
    initialLoad,
    updateRune,
    updateCharm,
    updateCard,
    updateClass,
    slots,
    loading,
  ]);
};
