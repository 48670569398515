import { Stack, Typography, Button } from "@mui/material";
import { IoRefreshCircle } from "react-icons/io5";

interface Props {
  handleRefresh: () => void;
}

const Error = ({ handleRefresh }: Props) => {
  return (
    <Stack gap={2} sx={{ py: 20, px: 2 }} alignItems="center">
      <Typography
        variant="h1"
        fontSize="26px"
        color="text.bright"
        textAlign="center"
      >
        Unable to load player profile
      </Typography>
      <Button
        endIcon={<IoRefreshCircle />}
        onClick={handleRefresh}
        variant="contained"
      >
        Try again
      </Button>
    </Stack>
  );
};

export default Error;
