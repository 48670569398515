import React from "react";
import { formatEthPrice } from "helpers/display";
import { Typography, Stack } from "@mui/material";

const EthereumPrice = ({ price, formatEth = true, large, color }) => {
  return (
    <Stack direction="row" alignItems="center" gap={0.25}>
      <Typography
        variant="span"
        color={color ? color : "text.regular"}
        fontSize={large ? "28px" : "18px"}
        fontWeight="700"
        sx={{ lineHeight: 1 }}
      >
        Ξ
      </Typography>
      <Typography
        variant="span"
        color={color ? color : "text.regular"}
        fontSize={large ? "32px" : "22px"}
        fontWeight="700"
        sx={{ lineHeight: 1 }}
      >
        {formatEth ? formatEthPrice(price) : price}
      </Typography>
    </Stack>
  );
};

export default EthereumPrice;
