import { useState } from "react";
import { Stack, Button, Box, Typography, useTheme } from "@mui/material";
import { FaArrowCircleRight } from "react-icons/fa";
import Card from "components/Card/Card";
import CustomLink from "components/CustomLink/CustomLink";

const SupportCard = ({ card }) => {
  const theme = useTheme();
  const {
    link,
    id,
    backgroundImage,
    logo,
    showTitle,
    title,
    backgroundContain,
    positionBottom,
    paddingZero,
    whiteTitle,
  } = card;
  const [hover, setHover] = useState(false);
  return (
    <CustomLink
      id="link"
      style={{
        width: "100%",
        borderRadius: "16px",
        boxShadow: `0px 0px 10px rgba(52, 12, 90, 0.05)`,
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: `2px 2px 30px rgba(52, 12, 90, 0.1)`,
        },
      }}
      href={link}
      target="_blank"
      key={id}
    >
      <Card
        id="card"
        style={{
          position: "relative",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: positionBottom ? "bottom center" : "top center",
          backgroundSize: backgroundContain ? "contain" : "cover",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <Stack
          id="mouseover here"
          onMouseEnter={(i) => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{
            width: "100%",
            minHeight: 280,
            maxHeight: 280,
            gap: 2,
            padding: 5,
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down("lg")]: { minHeight: 220, maxHeight: 220 },
            [theme.breakpoints.down("sm")]: { minHeight: 200, maxHeight: 200 },
          }}
        >
          <Box sx={{ maxWidth: 180 }}>
            {logo && (
              <img
                style={{ objectFit: "contain", width: "100%" }}
                alt={title}
                src={logo}
              />
            )}
          </Box>

          {showTitle && (
            <Typography
              variant="span"
              color={whiteTitle ? "#fff" : "text.bright"}
              fontWeight={600}
              fontSize={16}
              sx={{
                lineHeight: 1.4,
                paddingTop: paddingZero ? 0 : 14,
                paddingBottom: paddingZero ? 4 : 0,
              }}
            >
              {title}
            </Typography>
          )}

          {hover ? (
            <Button
              variant="outlined"
              endIcon={<FaArrowCircleRight />}
              sx={{
                position: "absolute",
                fontSize: 12,
                alignSelf: "center",
                color: "text.regular",
                borderColor: "text.regular",
                background: theme.palette.card.background,
                mb: 2,
                bottom: 1,
                "&:hover": {
                  borderColor: "text.regular",
                  background: theme.palette.card.background,
                },
              }}
            >
              Visit Support
            </Button>
          ) : null}
        </Stack>
      </Card>
    </CustomLink>
  );
};

export default SupportCard;
