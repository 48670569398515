import * as types from "./cardExplorerActionTypes";
import produce from "immer";

export const initialState = {
  favoriteCards: [],
  cards: [],
};

export const cardExplorerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const cardIndex =
      draft.favoriteCards &&
      draft.favoriteCards.findIndex((i) => i.partId === action?.card?.partId);

    switch (action.type) {
      case types.FAVORITE_CARD:
        if (cardIndex !== undefined && cardIndex === -1) {
          draft.favoriteCards.push(action.card);
        }
        break;
      case types.UNFAVORITE_CARD:
        if (cardIndex !== undefined && cardIndex !== -1) {
          draft.favoriteCards.splice(cardIndex, 1);
        }
        break;
      case types.FETCH_CARDS_STARTED:
        draft.fetchingCards = true;
        draft.failedToFetchCards = false;
        break;
      case types.FETCH_CARDS_SUCCEEDED:
        draft.fetchingCards = false;
        draft.cards = action.cards;
        break;
      case types.FETCH_CARDS_FAILED:
        draft.fetchingCards = false;
        draft.failedToFetchCards = true;
        break;
      case types.ADD_FAVORITES_STARTED:
        draft.addingFavorites = true;
        draft.failedToAddFavorites = false;
        break;
      case types.ADD_FAVORITES_SUCCEEDED:
        draft.addingFavorites = false;
        break;
      case types.ADD_FAVORITES_FAILED:
        draft.addingFavorites = false;
        draft.failedToAddFavorites = true;
        break;
      case types.CLEAR_ALL_FAVORITES:
        draft.favoriteCards = [];
        break;
      default:
        break;
    }
  });

export default cardExplorerReducer;
