import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextareaAutosize,
  TextField,
  DialogActions,
  Button,
  Stack,
  Grid,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import { addScholar } from "store/guildDashboard/actions/guildDashboardActions";
import CountryRegionSelect from "components/CountryRegionSelect/CountryRegionSelect";
import { parseRoninAddress, forceNumWithinRange } from "helpers/cleaning";
import Label from "components/Typography/Label";
import { useWeb3React } from "@web3-react/core";
import { createScholarPayload } from "helpers/scholars";
import CustomSelect from "components/CustomSelect/CustomSelect";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import LoadingButton from "@mui/lab/LoadingButton";
import Error from "components/Typography/Error";

const AddScholarDialog = ({
  open,
  setOpen,
  scholars,
  addScholar,
  guild,
  allScholarRoninAddresses,
  teamsList,
  forceGuildTeamId,
}) => {
  const { account } = useWeb3React();
  const [roninAddress, setRoninAddress] = useState("");
  const [scholarName, setScholarName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [discordId, setDiscordId] = useState("");
  const [managerShare, setManagerShare] = useState(0);
  const [investorShare, setInvestorShare] = useState(0);
  const [payoutAddress, setPayoutAddress] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [dob, setDOB] = useState("");
  const [dailyQuota, setDailyQuota] = useState(0);
  const [dateJoined, setDateJoined] = useState("");
  const [guildTeamId, setGuildteamId] = useState(0);
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const theme = useTheme();

  const handleClose = useCallback(() => {
    setOpen(false);
    setRoninAddress("");
    setScholarName("");
    setEmailAddress("");
    setTwitterId("");
    setDiscordId("");
    setManagerShare("");
    setInvestorShare("");
    setPayoutAddress("");
    setDailyQuota(0);
    setRegion("");
    setCountry("");
    setDOB("");
    setDateJoined("");
    setNotes("");
    setError("");
  }, [
    setOpen,
    setRoninAddress,
    setScholarName,
    setEmailAddress,
    setTwitterId,
    setDiscordId,
    setManagerShare,
    setInvestorShare,
    setPayoutAddress,
    setDailyQuota,
    setRegion,
    setCountry,
    setDOB,
    setDateJoined,
    setNotes,
    setError,
  ]);

  useEffect(() => {
    if (forceGuildTeamId) {
      setGuildteamId(forceGuildTeamId);
    }
  }, [forceGuildTeamId]);

  useEffect(() => {
    if (guild.addingScholar === false && guild.failedToAddScholar === true) {
      setError("Something went wrong...");
    } else if (
      guild.addingScholar === false &&
      guild.failedToAddScholar === false
    ) {
      handleClose();
    }
  }, [guild.addingScholar, guild.failedToAddScholar, handleClose]);

  useEffect(() => {
    if (guild.defaultManagerShare) {
      setManagerShare(guild.defaultManagerShare);
    }
  }, [open, guild.defaultManagerShare]);

  const isNewRoninAddress = (address) => {
    return allScholarRoninAddresses.find((a) => a === address) === undefined;
  };

  const handleAddScholar = () => {
    if (roninAddress === "") {
      setError("Ronin address cannot be empty!");
    } else if (managerShare === "") {
      setError("Manager share cannot be empty!");
    } else {
      try {
        const parsedRoninAddress = parseRoninAddress(roninAddress);

        try {
          const parsedPayoutAddress = payoutAddress
            ? parseRoninAddress(payoutAddress)
            : "";

          try {
            if (isNewRoninAddress(parsedRoninAddress)) {
              const nameOrFallback =
                scholarName || `Scholar ${allScholarRoninAddresses.length + 1}`;
              const payload = createScholarPayload(
                nameOrFallback,
                managerShare,
                parsedRoninAddress,
                parsedPayoutAddress,
                emailAddress,
                twitterId,
                discordId,
                region,
                country,
                dob,
                dateJoined,
                notes,
                dailyQuota,
                guildTeamId,
                investorShare
              );
              addScholar(account, guild.logoURI, payload);
            } else {
              setError("Duplicate ronin address!");
            }
          } catch (e) {
            console.log("Error adding scholar: ", e);
          }
        } catch (e) {
          setError("Bad payout address!");
        }
      } catch (e) {
        setError("Bad ronin address!");
      }
    }
  };

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleAddScholar(e);
    }
    if (e.which === 27) {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll="paper"
      maxWidth={"lg"}
      aria-labelledby="add-scholar-dialog-title"
    >
      <DialogTitle id="add-scholar-dialog-title">Add Scholar</DialogTitle>

      <form onKeyPress={(e) => handleKeypress(e)}>
        <DialogContent dividers={true}>
          <Stack gap={2}>
            <Error>{error}</Error>
            <Label>Key Details</Label>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setRoninAddress(e.target.value)}
                  label="Ronin Address"
                  variant="filled"
                  size="small"
                  value={roninAddress}
                  required
                  placeholder="ronin:"
                  spellCheck={false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setPayoutAddress(e.target.value)}
                  label="Payout Address"
                  variant="filled"
                  size="small"
                  value={payoutAddress}
                  placeholder="ronin:"
                  spellCheck={false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setScholarName(e.target.value)}
                  label="Name"
                  variant="filled"
                  size="small"
                  value={scholarName}
                  spellCheck={false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  onChange={(e) =>
                    setManagerShare(forceNumWithinRange(e.target.value, 0, 100))
                  }
                  label="Manager Share %"
                  variant="filled"
                  size="small"
                  type="number"
                  value={managerShare}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  onChange={(e) =>
                    setInvestorShare(
                      forceNumWithinRange(e.target.value, 0, 100)
                    )
                  }
                  label="Investor Share %"
                  variant="filled"
                  size="small"
                  type="number"
                  value={investorShare}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  val={guildTeamId}
                  setVal={setGuildteamId}
                  options={teamsList}
                  fullWidth={true}
                  label="Team Name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) =>
                    setDailyQuota(
                      forceNumWithinRange(e.target.value, 0, undefined)
                    )
                  }
                  label="Daily SLP Quota"
                  variant="filled"
                  size="small"
                  type="number"
                  value={dailyQuota}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Label>Additional Info</Label>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  onChange={(e) => setDiscordId(e.target.value)}
                  label="Discord Id"
                  variant="filled"
                  size="small"
                  value={discordId}
                  spellCheck={false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onChange={(e) => setTwitterId(e.target.value)}
                  label="Twitter Id"
                  variant="filled"
                  size="small"
                  value={twitterId}
                  spellCheck={false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onChange={(e) => setEmailAddress(e.target.value)}
                  label="Email Address"
                  variant="filled"
                  size="small"
                  value={emailAddress}
                  spellCheck={false}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setDateJoined(e.target.value)}
                  type="date"
                  label="Date Joined"
                  variant="filled"
                  size="small"
                  value={dateJoined}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setDOB(e.target.value)}
                  type="date"
                  label="Birthday"
                  variant="filled"
                  size="small"
                  value={dob}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>

            <CountryRegionSelect
              setCountry={setCountry}
              country={country}
              region={region}
              setRegion={setRegion}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "16px",
              }}
            />
            <TextareaAutosize
              aria-label="note"
              minRows={6}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              spellCheck={false}
              placeholder="Notes"
              style={{
                background: theme.palette.input.filled.background,
                color: theme.palette.text.primary,
                fontSize: "16px",
                fontWeight: "500",
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          {guild.addingScholar ? (
            <LoadingButton
              variant="contained"
              loading
              sx={{ alignSelf: "stretch" }}
            />
          ) : (
            <Button onClick={(e) => handleAddScholar(e)} variant="contained">
              OK
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapDispatchToProps = {
  addScholar,
};

const mapStateToProps = (state) => ({
  scholars: state.guildDashboard.guild.scholars,
  guild: state.guildDashboard.guild,
  allScholarRoninAddresses: teamSelectors.getAllScholarRoninAddresses(state),
  teamsList: teamSelectors.getTeamsListForDropdown(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddScholarDialog);
