import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import CustomLink from "components/CustomLink/CustomLink";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  maxWidth: 250,
  width,
}));

const TriballyBadge = ({ width, title, alt, styles, link, image }) => {
  const imageSrc = image
    ? image
    : `/images/tribally-assets/powered-by-tribally-badge.png`;

  return (
    <Wrapper sx={styles} width={width}>
      <CustomLink href={link}>
        <img width={width} src={imageSrc} title={title} alt={alt} />
      </CustomLink>
    </Wrapper>
  );
};

export default TriballyBadge;
