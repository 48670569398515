import { createSelector } from "reselect";
import {
  getAllUniqueRunesForCurrentSeason,
  getAllUniqueCharmsForCurrentSeason,
} from "store/data/dataSelectors";

const getCollection = (state) => state.craftingCollection.collection;

export const getCollectionStats = createSelector(
  [
    getCollection,
    getAllUniqueRunesForCurrentSeason,
    getAllUniqueCharmsForCurrentSeason,
  ],
  (collection, allRunes, allCharms) => {
    const runes = collection.filter((i) => i.itemType === "rune");
    const charms = collection.filter((i) => i.itemType === "charm");

    return {
      total: collection.length,
      totalCharms: charms?.length || 0,
      totalRunes: runes?.length || 0,
      grandTotalCharms: allCharms?.length || 0,
      grandTotalRunes: allRunes?.length || 0,
    };
  }
);

export const getShareLink = createSelector([getCollection], (collection) => {
  const runeIds = collection
    .filter((i) => i.itemType === "rune")
    .map((i) => i.id);
  const charmIds = collection
    .filter((i) => i.itemType === "charm")
    .map((i) => i.id);
  let baseStem = "https://axie.tech/craftingdex/";

  if (collection.length === 0) {
    return baseStem;
  }

  let start = true;

  if (runeIds.length > 0) {
    start = false;
    baseStem += "?runes=" + runeIds.join(",");
  }

  if (charmIds.length > 0) {
    if (start) {
      baseStem += "?charms=";
    } else {
      baseStem += "&charms=";
    }
    baseStem += charmIds.join(",");
  }

  return baseStem;
});
