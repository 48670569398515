import { Box, useTheme } from "@mui/material";
import React from "react";

const TwoColumns = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gridGap: 24,
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default TwoColumns;
