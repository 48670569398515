import { Stack, Typography } from "@mui/material";

const Title = ({ children, textAlign, icon }) => {
  return (
    <Stack sx={{ mb: 2 }} direction="row" alignItems="center" gap={1.5}>
      {icon != null && (
        <Typography
          variant="span"
          color="text.regular"
          fontSize="16px"
          fontWeight="700"
          textAlign={textAlign || "center"}
          sx={{ opacity: 0.4 }}
        >
          {icon}
        </Typography>
      )}
      <Typography
        variant="span"
        color="text.regular"
        fontSize="16px"
        fontWeight="700"
        textAlign={textAlign || "center"}
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default Title;
