import React, { useState } from "react";
import DashboardHeaderGuilds from "../organisms/DashboardHeaderGuilds";
import Dialog from "./Dialog";
import GuildTable from "../organisms/GuildTable";
import { connect } from "react-redux";
import {
  addGuild,
  updateGuild,
  updateSearchQuery,
  requestPresignedUrl,
  toggleShowInactive,
} from "store/guildDashboardAdmin/adminActions";
import { useWeb3React } from "@web3-react/core";
import Controls from "../molecules/Controls";

const ViewAuthorized = ({
  addGuild,
  updateGuild,
  status,
  guilds,
  searchQuery,
  updateSearchQuery,
  requestPresignedUrl,
  showInactive,
  toggleShowInactive,
}) => {
  const { account } = useWeb3React();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");

  const handleOpenEditGuild = (item) => {
    setRecordForEdit(item);
    setDialogTitle("Edit Guild");
    setDialogIsOpen(true);
  };

  const handleOpenAddGuild = () => {
    setRecordForEdit(null);
    setDialogTitle("Add Guild");
    setDialogIsOpen(true);
  };

  const addOrEditFn = (guild, resetForm) => {
    const onSuccess = () => {
      resetForm();
      setRecordForEdit(null);
      setDialogIsOpen(false);
    };

    if (guild.guildId == null) {
      addGuild(account, guild, onSuccess);
    } else {
      updateGuild(account, guild, onSuccess);
    }
  };

  return (
    <>
      <DashboardHeaderGuilds handleOpenAddGuild={handleOpenAddGuild} />

      <Controls
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        showInactive={showInactive}
        toggleShowInactive={toggleShowInactive}
      />

      <GuildTable handleOpenEditGuild={handleOpenEditGuild} />

      <Dialog
        open={dialogIsOpen}
        setOpen={setDialogIsOpen}
        recordForEdit={recordForEdit}
        dialogTitle={dialogTitle}
        addOrEditFn={addOrEditFn}
        status={status}
        guilds={guilds}
        requestPresignedUrl={requestPresignedUrl}
      />
    </>
  );
};
const mapDispatchToProps = {
  addGuild,
  updateGuild,
  updateSearchQuery,
  requestPresignedUrl,
  toggleShowInactive,
};

const mapStateToProps = (state) => ({
  status: state.guildDashboardAdmin.status,
  guilds: state.guildDashboardAdmin.guilds.data,
  searchQuery: state.guildDashboardAdmin.controls.searchQuery,
  showInactive: state.guildDashboardAdmin.controls.showInactive,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAuthorized);
