import { useContext } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { ColorModeContext } from "App";
import { Button, useTheme } from "@mui/material";

const ColorModeButton = ({ color, style = {} }) => {
  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();

  return (
    <Button
      variant="text"
      size="small"
      endIcon={theme.palette.mode === "dark" ? <FaMoon /> : <FaSun />}
      onClick={colorMode.toggleColorMode}
      sx={{
        color: color ? color : theme.palette.colors.purple[400],
        alignSelf: "flex-start",
        fontWeight: "600",
        "&:hover": {
          color: color ? color : theme.palette.colors.purple[400],
          background: color ? "rgba(255,255,255,0.1)" : "#6314ae33",
        },
        "& .MuiButton-endIcon svg": {
          top: 0,
        },
        ...style,
      }}
    >
      {theme.palette.mode === "dark" ? "Dark Mode" : "Light Mode"}
    </Button>
  );
};

export default ColorModeButton;
