import * as types from "store/guildDashboard/actions/actionTypes";
import { put, takeLatest, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "store/guildDashboard/actions/guildDashboardActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchAutopayoutLogRequest(action) {
  try {
    yield put(actions.fetchAutopayoutLogStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestAutopayoutLogData,
      action.loginAddress
    );

    yield put(actions.fetchAutopayoutLogSucceeded(data));
  } catch (e) {
    yield put(actions.fetchAutopayoutLogFailed());
  }
}

export default function* guildDashboardAutopayoutSaga() {
  yield takeLatest(
    types.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG,
    makeFetchAutopayoutLogRequest
  );
}
