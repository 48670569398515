import React, { useEffect, useContext, useState } from "react";
import Sidebar from "./organisms/Sidebar";
import { Snackbar, useTheme, IconButton, Stack } from "@mui/material";
import { ColorModeContext } from "App";
import { useQuery } from "react-query";
import { fetchCurrencyData } from "helpers/actions";
import { REACT_QUERY_STALE_TIME } from "config";
import { IoMdClose } from "react-icons/io";

const LoggedInWrapper = ({ children, guild, fetchingGuild, fetchingTeams }) => {
  const colorMode = useContext(ColorModeContext);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setOpen(fetchingTeams);
  }, [fetchingTeams]);

  useEffect(() => {
    if (fetchingGuild === false) {
      colorMode.setColorMode(guild.isDarkMode === 1 ? "dark" : "light");
    }
  }, [fetchingGuild, colorMode, guild.isDarkMode]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const { data } = useQuery("currency", fetchCurrencyData, {
    staleTime: REACT_QUERY_STALE_TIME,
  });
  const baseCurrency = guild.defaultCurrency
    ? guild.defaultCurrency.toLowerCase()
    : "usd";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: baseCurrency,
  });

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        onClick={handleCloseAlert}
        sx={{ color: theme.palette.mode === "light" ? "#fff" : "#000" }}
      >
        <IoMdClose />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleCloseAlert}
        message="Loading scholars..."
        action={action}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <Sidebar
        currencyPrices={data}
        formatter={formatter}
        baseCurrency={baseCurrency}
      />
      <Stack
        gap={4}
        flexWrap="wrap"
        sx={{
          width: "calc(100% - 230px)",
          marginLeft: "230px",
          p: 4,
          maxWidth: 1720,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            ml: 0,
            p: 2,
            maxWidth: "auto",
          },
        }}
      >
        {React.cloneElement(children, {
          currencyPrices: data,
          formatter,
          baseCurrency,
        })}
      </Stack>
    </>
  );
};

export default LoggedInWrapper;
