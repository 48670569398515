import * as React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  Chip,
} from "@mui/material";

const MenuProps = {
  PaperProps: {
    sx: {
      backgroundColor: "rgba(255, 255, 255, 1)",
      width: 90,
      borderRadius: "5px",
      top: "275px",
      color: "rgba(0, 0, 0, 0.7)",
      "& .MuiMenuItem-root": {
        margin: "1px 5px",
        "&:focus": {
          backgroundColor: "rgba(35, 184, 209, 0.7) !important",
          color: "#000",
          "&:hover": {
            backgroundColor: "rgba(35, 184, 209, 0.4) !important",
            color: "#000",
          },
        },
        "&:hover, &:active, &.Mui-selected, &.Mui-focusVisible, &:focus-visible":
          {
            backgroundColor: "rgba(35, 184, 209, 0.5) !important",
            color: "#000",
          },
      },
    },
  },
};

export default function CustomMultiSelect({
  width,
  label,
  value,
  setValue,
  options,
  updateSelectValue,
}) {
  return (
    <FormControl
      variant="filled"
      size="small"
      sx={{
        minWidth: width,
        height: "100%",
        "& .MuiFilledInput-root": {
          borderRadius: (width / 125) * 160,
          background: "rgba(255,255,255,0.2)",
          border: `none`,
          height: "100%",
          "&:hover, &:active, &.Mui-focused": {
            background: "rgba(255,255,255,0.2)",
            border: `none`,
            boxShadow: "none",
          },
        },
        "& .MuiFilledInput-input": {
          borderRadius: (width / 125) * 160,
          "&:focus": {
            borderRadius: (width / 125) * 160,
            background: "transparent",
          },
        },
        "& .MuiInputLabel-root": {
          color: "rgba(255, 255, 255, 0.7)",
          padding: "3px",
          fontWeight: 600,
          "&.Mui-focused": {
            fontSize: (width / 125) * 16,
            color: "rgba(255, 255, 255, 1)",
            fontWeight: 600,
          },
        },
        "& .MuiSelect-select": {
          borderRadius: "160px",
          backgroundColor: "rgba(35, 184, 209, 0.2)",
        },
        "&.MuiSvgIcon-root, .MuiSelect-iconFilled ": {
          color: "#ffff",
        },
      }}
    >
      <InputLabel id={`${label}-select`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select`}
        multiple
        value={value}
        onChange={updateSelectValue}
        renderValue={(selected) => (
          <Box
            sx={{
              maxWidth: "130px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "no-wrap",
              overflow: "hidden",
              gap: 0.5,
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        sx={{
          "& .MuiChip-root": {
            backgroundColor: "#000",
            color: "#fff",
            marginRight: "2px",
            height: "24px",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
