import React from "react";
import { Typography, useTheme } from "@mui/material";

const TextButton = ({ onClick, text, textAlign }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="span"
      onClick={onClick}
      fontSize={12}
      textAlign={textAlign}
      fontWeight={500}
      sx={{
        color: (theme) => theme.palette.colors.origin.wood.text,
        opacity: 0.5,
        transition: "0.2s all ease",
        "&:hover": {
          cursor: "pointer",
          opacity: 1,
        },
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      }}
    >
      {text}
    </Typography>
  );
};

export default TextButton;
