export const FETCH_RECENTLY_LISTED = "FETCH_RECENTLY_LISTED";
export const FETCH_RECENTLY_LISTED_STARTED = "FETCH_RECENTLY_LISTED_STARTED";
export const FETCH_RECENTLY_LISTED_SUCCEEDED =
  "FETCH_RECENTLY_LISTED_SUCCEEDED";
export const FETCH_RECENTLY_LISTED_FAILED = "FETCH_RECENTLY_LISTED_FAILED";
export const CLEAR_RECENTLY_LISTED = "CLEAR_RECENTLY_LISTED";

export const FETCH_RECENTLY_SOLD = "FETCH_RECENTLY_SOLD";
export const FETCH_RECENTLY_SOLD_STARTED = "FETCH_RECENTLY_SOLD_STARTED";
export const FETCH_RECENTLY_SOLD_SUCCEEDED = "FETCH_RECENTLY_SOLD_SUCCEEDED";
export const FETCH_RECENTLY_SOLD_FAILED = "FETCH_RECENTLY_SOLD_FAILED";
export const CLEAR_RECENTLY_SOLD = "CLEAR_RECENTLY_SOLD";

export const DELETE_RECENTLY_LISTED_AXIE = "DELETE_RECENTLY_LISTED_AXIE";
export const DELETE_RECENTLY_SOLD_AXIE = "DELETE_RECENTLY_SOLD_AXIE";
