import React from "react";
import { Stack, Typography } from "@mui/material";

const Title = () => {
  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: 800,
        filter: "drop-shadow(0 0 6px rgba(29, 64, 156, 0.8))",
        "& .MuiTypography-root": {
          height: 0,
          transform: "translateX(-9999px)",
        },
      }}
    >
      <img
        src="/images/games/doll/leaderboard-title.png"
        alt="Leaderboard"
      />
      <Typography variant="h1">Leaderboard</Typography>
    </Stack>
  );
};

export default Title;
