import { Stack } from "@mui/material";
import Card from "components/Card/Card";
import Header from "./ProjectHeader";
import Image from "../atoms/Image";
import Footer from "./ProjectFooter";
import Video from "../atoms/Video";
import Tools from "./Tools";

const Project = (props) => {
  const {
    title,
    socials,
    tagline,
    image,
    videoId,
    linkTo,
    platforms,
    status,
    tools,
    gameType,
  } = props.project;
  let linkUrl;

  const { fontSize, direction, gap } = props;

  if (socials) {
    const target = socials.find((s) => s.type === linkTo);
    if (target) {
      linkUrl = target.url;
    }
  }

  let sortedSocials = socials.sort((a, b) => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  });

  return (
    <Card>
      <Stack gap={{ xs: 2, sm: 2, md: 2 }}>
        <Header
          gap={gap}
          direction={direction}
          fontSize={fontSize}
          title={title}
          tagline={tagline}
          status={status}
          linkTo={linkUrl}
          gameType={gameType}
        />
        {videoId ? (
          <Video videoId={videoId} title={title} />
        ) : (
          <Image image={image} title={title} linkTo={linkUrl} />
        )}
        <Tools tools={tools} />
        <Footer platforms={platforms.sort()} socials={sortedSocials} />
      </Stack>
    </Card>
  );
};

export default Project;
