import React from "react";
import styles from "../molecules/breeding-result.module.scss";
import { Typography, useTheme, Stack } from "@mui/material";

const Capsule = ({ amount, icon, iconWidth = "28px" }) => {
  const theme = useTheme();
  return (
    <Stack
      className={styles.capsule}
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        background:
          theme.palette.mode === "light"
            ? theme.palette.colors.white
            : theme.palette.card.shade2,
      }}
    >
      <img
        className={styles.slpIcon}
        alt="SLP"
        src={icon}
        style={{ width: iconWidth }}
      />
      <Typography
        variant="span"
        fontWeight="600"
        color="text.regular"
        fontSize="16px"
      >
        {amount}
      </Typography>
    </Stack>
  );
};

export default Capsule;
