import { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Button,
  DialogActions,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import { displayShortenedRoninAddress } from "helpers/display";
import AxiePreviews from "pages/GuildDashboard/pages/Scholars/atoms/AxiePreviews";
import { useWeb3React } from "@web3-react/core";
import { moveScholarsToTeam } from "store/guildDashboard/actions/guildDashboardActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Error from "components/Typography/Error";
import IconMessage from "components/IconMessage/IconMessage";

const MoveScholarDialog = ({
  open,
  setOpen,
  rows,
  moveScholarsToTeam,
  forceGuildTeamId,
  movingScholarsToTeamFailed,
  movingScholarsToTeam,
  fetchingTeams,
}) => {
  const { account } = useWeb3React();
  const [error, setError] = useState("");
  const [roninAddresses, setRoninAddresses] = useState([]);

  const handleClose = useCallback(() => {
    setError("");
    setOpen(false);
  }, [setError, setOpen]);

  const handleMoveScholars = () => {
    if (roninAddresses.length === 0) {
      setError("You must select at least 1 scholar.");
    } else {
      setError("");
      moveScholarsToTeam(account, roninAddresses, forceGuildTeamId);
    }
  };

  useEffect(() => {
    if (
      movingScholarsToTeam === false &&
      movingScholarsToTeamFailed === false
    ) {
      handleClose();
    }
  }, [handleClose, movingScholarsToTeam, movingScholarsToTeamFailed]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      renderCell: (cellValues) => (
        <Stack>
          <Typography
            variant="span"
            fontSize="14px"
            color="text.primary"
            fontWeight="600"
            sx={{
              lineHeight: 1,
            }}
          >
            {cellValues.row.name}
          </Typography>
          {cellValues.row.guildTeamName != null && (
            <Typography
              variant="span"
              fontSize="12px"
              color="text.muted"
              fontWeight="600"
              sx={{ lineHeight: 1 }}
            >
              {cellValues.row.guildTeamName}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: "id",
      headerName: "Ronin Address",
      minWidth: 180,
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.id}>
          <Typography variant="span">
            {displayShortenedRoninAddress(cellValues.row.id)}
          </Typography>
        </Tooltip>
      ),
    },
    { field: "mmr", headerName: "Stars", minWidth: 80 },
    { field: "totalSLP", headerName: "Total SLP", minWidth: 80 },
    { field: "moonshards", headerName: "Moonshards", minWidth: 80 },
    {
      field: "axies",
      headerName: "Axies",
      minWidth: 200,
      renderCell: (cellValues) => (
        <AxiePreviews
          axieClassCounts={cellValues.row.axieClassCounts}
          totalAxies={cellValues.row.totalAxies}
          roninAddress={cellValues.row.id}
          fetchingAxies={cellValues.row.fetchingAxies}
          guildTeamId={cellValues.row.guildTeamId}
        />
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      fullScreen={true}
      aria-labelledby="move-scholar-dialog-title"
    >
      <DialogTitle id="move-scholar-dialog-title">Move Scholar</DialogTitle>

      <DialogContent dividers={true}>
        <Stack gap={2} sx={{ height: "100%" }}>
          {fetchingTeams && (
            <IconMessage text="We're still fetching some of your scholars." />
          )}
          {movingScholarsToTeamFailed && <Error>Something went wrong...</Error>}
          {error !== "" && <Error>{error}</Error>}

          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setRoninAddresses(newSelectionModel);
              }}
              selectionModel={roninAddresses}
            />
          </div>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        {movingScholarsToTeam ? (
          <LoadingButton
            variant="contained"
            loading
            sx={{ alignSelf: "stretch" }}
          />
        ) : (
          <Button variant="contained" onClick={handleMoveScholars}>
            {`Move ${
              roninAddresses.length === 0
                ? "Scholars"
                : roninAddresses.length === 1
                ? "1 Scholar"
                : `${roninAddresses.length} Scholars`
            }`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = { moveScholarsToTeam };

const mapStateToProps = (state) => ({
  rows: teamSelectors.getAllScholarsNotInActiveTeam(state),
  movingScholarsToTeam: state.guildDashboard.guild.movingScholarsToTeam,
  movingScholarsToTeamFailed:
    state.guildDashboard.guild.movingScholarsToTeamFailed,
  fetchingTeams: state.guildDashboard.guild.fetchingTeams,
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveScholarDialog);
