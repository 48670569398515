import * as types from "./actionTypes";
import * as actions from "./action";
import api from "services/api";
import { takeEvery, retry, put, select } from "redux-saga/effects";
import { getAxieQuestCards } from "./selectors";
import { isNullOrEmptyArray } from "helpers/cleaning";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchAxieQuestCardsRequest() {
  try {
    const existingData = yield select(getAxieQuestCards);

    if (isNullOrEmptyArray(existingData)) {
      yield put(actions.fetchAxieQuestCardsStarted());
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestAxieQuestCards
      );
      yield put(actions.fetchAxieQuestCardsSucceeded(data));
    }
  } catch (e) {
    console.log("Error fetching cards");
    yield put(actions.fetchAxieQuestCardsFailed());
  }
}

function* axieQuestCardsSaga() {
  yield takeEvery(
    types.AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS,
    makeFetchAxieQuestCardsRequest
  );
}
export default axieQuestCardsSaga;
