const rawData = [
  {
    clientId: "1ecc19a3-bbb8-6d9c-8fae-64a00168e792",
    name: "Ralo | SURA",
    rank: "Challenger",
    tier: 0,
    topRank: 1,
    vstar: 2208,
    avatar: "shy;fluffy_hooves",
    battles: [
      {
        battleUuid: "feb4d811-cc88-4230-a0b9-ca7dda6146b5",
        gameTimestamp: "2023-05-28 01:54:36",
        startTimestamp: "2023-05-28 01:51:55",
        endTimestamp: "2023-05-28 01:54:36",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=feb4d811-cc88-4230-a0b9-ca7dda6146b5&userId=1ecc19a3-bbb8-6d9c-8fae-64a00168e792",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5034274",
        oponentTeamId: "5037766",
        opponent: "1ec9eb6f-6169-6bdb-a60c-8b9a49c7d037",
        playerTeam: [
          {
            gene: "0x20000000000001000101c0c0c40000000001000410208108000100080840430a000100102081050600010010288081020001000410a0820a000100101880c002",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11713759,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001018020820c000000010004100101040001000808808202000100102081030600010010300044080001000818604302000100000800440c",
            runes: ["rune_dusk_2011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11691912,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004040c30400000001000410a1050800010008080041040001001020810408000100042821410a0001000410414104000100101880c502",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11725280,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c060910410000000010004282142020001001410a0850a000100043021810c000100082880c40c000100083041820c000100082841420a",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_bug_2013_s4_nft",
              eyes: "ecard_neutral_2018_s4",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11678330,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c040b00008000000010000104100040001000028014404000100001800c502000100082841430400010008304183020001000008004502",
            runes: ["rune_dawn_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2012_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11728399,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000341609082100000000100042821400a0001001410a08204000100043021810c00010008288043080001000830418504000100082820430a",
            runes: ["rune_beast_2010_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bug_2013_s4_nft",
              eyes: "ecard_neutral_2018_s4_nondec",
              horn: "ecard_bird_3011_s4_nft",
              tail: "ecard_bird_2012_s4_nondec",
              mouth: "ecard_neutral_2012_s4",
            },
            axie_id: 11693995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6daec868-eca6-4e97-8eda-d5b4fee1afd4",
        gameTimestamp: "2023-05-28 00:52:48",
        startTimestamp: "2023-05-28 00:50:13",
        endTimestamp: "2023-05-28 00:52:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6daec868-eca6-4e97-8eda-d5b4fee1afd4&userId=1ecc19a3-bbb8-6d9c-8fae-64a00168e792",
        battleType: "pvp",
        playerSlpWon: 26,
        playerTeamId: "5091955",
        oponentTeamId: "5105972",
        opponent: "1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        playerTeam: [
          {
            gene: "0x20000000000001000101c0d0800c00000001001428a1450a0001000428a140040001000018a0420c000100143060c50c000100002880c4060001001010214404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748692,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a020c30c00000001001428a1450a000100042821410a000100001800c506000100143021050c000100002880c00a000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743625,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160b0c20c0000000100142860450a000100042841010a000100001800c0060001001008a1830400010004100141040001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749882,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000001a000820800000001001428214402000100102840840a0001000018a100060001001008818008000100101880c4060001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11726908,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2047,
        opponentMmrAfter: 2042,
        opponentSlpWon: 0,
        playerMmrBefore: 2203,
        playerMmrAfter: 2208,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "58c48074-9abc-4717-8871-9b1f60abb5ac",
        gameTimestamp: "2023-05-28 00:38:14",
        startTimestamp: "2023-05-28 00:30:54",
        endTimestamp: "2023-05-28 00:38:14",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=58c48074-9abc-4717-8871-9b1f60abb5ac&userId=1ecc19a3-bbb8-6d9c-8fae-64a00168e792",
        battleType: "pvp",
        playerSlpWon: 26,
        playerTeamId: "5091955",
        oponentTeamId: "5062261",
        opponent: "1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
        playerTeam: [
          {
            gene: "0x20000000000001000101c0d0800c00000001001428a1450a0001000428a140040001000018a0420c000100143060c50c000100002880c4060001001010214404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748692,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a020c30c00000001001428a1450a000100042821410a000100001800c506000100143021050c000100002880c00a000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743625,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160b0c20c0000000100142860450a000100042841010a000100001800c0060001001008a1830400010004100141040001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749882,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002c1d800840400000001000410208104000100080840420200010010208104080001000428214308000100081840c2060001001018a08504",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11742989,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010000e0840800000001000410a1010400010008084042020001001020810006000100103081040200010008182081020001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11705856,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001f00090c21000000001000c082083020001000808404202000100102080c002000100042881010a00010004104081040001001018614002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11724299,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2094,
        opponentMmrAfter: 2087,
        opponentSlpWon: 0,
        playerMmrBefore: 2196,
        playerMmrAfter: 2203,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a86c6cf4-a047-43d2-8dcc-87541831bd8f",
        gameTimestamp: "2023-05-28 00:26:20",
        startTimestamp: "2023-05-28 00:21:33",
        endTimestamp: "2023-05-28 00:26:20",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a86c6cf4-a047-43d2-8dcc-87541831bd8f&userId=1ecc19a3-bbb8-6d9c-8fae-64a00168e792",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5091955",
        oponentTeamId: "5090650",
        opponent: "1ec9eb77-511b-64e6-a60c-2bc1f8ec5d6f",
        playerTeam: [
          {
            gene: "0x20000000000001000101c0d0800c00000001001428a1450a0001000428a140040001000018a0420c000100143060c50c000100002880c4060001001010214404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748692,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a020c30c00000001001428a1450a000100042821410a000100001800c506000100143021050c000100002880c00a000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743625,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160b0c20c0000000100142860450a000100042841010a000100001800c0060001001008a1830400010004100141040001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749882,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140c050820800000001000c286083020001000c106083020001000c088043060001000c086144020001000c302043080001000c1061000a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11603356,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000038008308000000010010102144040001000c082140020001000c0840810c0001000c08604406000100041820c304000100000820c106",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "",
              mouth: "",
            },
            axie_id: 11741192,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000381a0e080040000000100002041440400010010282102040001000c0880c30a0001000c0800830a000100102861010a0001001030a0410c",
            runes: ["rune_beast_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 2473299,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2101,
        opponentMmrAfter: 2094,
        opponentSlpWon: 0,
        playerMmrBefore: 2189,
        playerMmrAfter: 2196,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a2ee8f85-e934-41ef-8b79-216a93a0a715",
        gameTimestamp: "2023-05-28 00:09:51",
        startTimestamp: "2023-05-28 00:05:07",
        endTimestamp: "2023-05-28 00:09:51",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a2ee8f85-e934-41ef-8b79-216a93a0a715&userId=1ecc19a3-bbb8-6d9c-8fae-64a00168e792",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5091955",
        oponentTeamId: "4983041",
        opponent: "1ec9eb6f-57a6-6838-a60c-4eb0cc8fb901",
        playerTeam: [
          {
            gene: "0x20000000000001000101c0d0800c00000001001428a1450a0001000428a140040001000018a0420c000100143060c50c000100002880c4060001001010214404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748692,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a020c30c00000001001428a1450a000100042821410a000100001800c506000100143021050c000100002880c00a000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743625,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160b0c20c0000000100142860450a000100042841010a000100001800c0060001001008a1830400010004100141040001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749882,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c08040c30800000001001428a1440a00010004286082080001000018808006000100040821410a000100041060c406000100101000c404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11717681,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180c050c2080000000100142861050a0001001028214504000100001800c0060001001430a1820a00010010184184080001001010808404",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730442,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002404070c2000000000100142881440a000100102041040a0001000018804408000100103081840c000100101880c4060001001010218404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11697205,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1937,
        opponentMmrAfter: 1934,
        opponentSlpWon: 0,
        playerMmrBefore: 2186,
        playerMmrAfter: 2189,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:02.000Z",
  },
  {
    clientId: "1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
    name: "^..La raloneta que cambio el meta",
    rank: "Challenger",
    tier: 0,
    topRank: 2,
    vstar: 2206,
    avatar: "s3_top1k;gold_04_s3",
    battles: [
      {
        battleUuid: "783c8246-b04f-483d-9f47-0b7876635702",
        gameTimestamp: "2023-05-29 12:57:56",
        startTimestamp: "2023-05-29 12:51:19",
        endTimestamp: "2023-05-29 12:57:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=783c8246-b04f-483d-9f47-0b7876635702&userId=1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        battleType: "pvp",
        playerSlpWon: 26,
        playerTeamId: "5104556",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2024,
        opponentMmrAfter: 2019,
        opponentSlpWon: 0,
        playerMmrBefore: 2201,
        playerMmrAfter: 2206,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e516477b-55f3-4077-bc89-f41ab0edd343",
        gameTimestamp: "2023-05-29 12:50:18",
        startTimestamp: "2023-05-29 12:44:41",
        endTimestamp: "2023-05-29 12:50:18",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e516477b-55f3-4077-bc89-f41ab0edd343&userId=1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        battleType: "pvp",
        playerSlpWon: 26,
        playerTeamId: "5104556",
        oponentTeamId: "5087063",
        opponent: "1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        playerTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2046,
        opponentMmrAfter: 2041,
        opponentSlpWon: 0,
        playerMmrBefore: 2196,
        playerMmrAfter: 2201,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9addf66b-bcfe-4db7-8bb9-cb612f0e2f82",
        gameTimestamp: "2023-05-29 12:09:43",
        startTimestamp: "2023-05-29 12:04:21",
        endTimestamp: "2023-05-29 12:09:43",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9addf66b-bcfe-4db7-8bb9-cb612f0e2f82&userId=1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104556",
        oponentTeamId: "4735960",
        opponent: "1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        playerTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2082,
        opponentMmrAfter: 2076,
        opponentSlpWon: 0,
        playerMmrBefore: 2190,
        playerMmrAfter: 2196,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ac25339c-aa6b-4c14-9386-58e28085e8ef",
        gameTimestamp: "2023-05-29 12:01:54",
        startTimestamp: "2023-05-29 11:56:33",
        endTimestamp: "2023-05-29 12:01:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ac25339c-aa6b-4c14-9386-58e28085e8ef&userId=1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104556",
        oponentTeamId: "5111487",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100014040e0c30c00000001001428a1450a0001000428a08004000100001800c0060001001430a1850c000100002880c00a000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751686,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1971,
        opponentMmrAfter: 1967,
        opponentSlpWon: 0,
        playerMmrBefore: 2186,
        playerMmrAfter: 2190,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f61203f7-c430-40ca-af0b-c86690977291",
        gameTimestamp: "2023-05-29 09:22:59",
        startTimestamp: "2023-05-29 09:16:25",
        endTimestamp: "2023-05-29 09:22:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f61203f7-c430-40ca-af0b-c86690977291&userId=1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104556",
        oponentTeamId: "5110202",
        opponent: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        playerTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11732056,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c08070820c00000001001428a14502000100042881440a000100001800c006000100143081800c00010004102085060001001010808306",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11730499,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000a02090820c00000001001428a1450a0001000820410404000100001800c402000100103081450c0001001018004202000100101080850a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11743238,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2033,
        opponentMmrAfter: 2027,
        opponentSlpWon: 0,
        playerMmrBefore: 2180,
        playerMmrAfter: 2186,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:03.000Z",
  },
  {
    clientId: "1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
    name: "J0N LFG",
    rank: "Challenger",
    tier: 0,
    topRank: 3,
    vstar: 2187,
    avatar: "s3_top1k;gold_s3",
    battles: [
      {
        battleUuid: "f7428c36-3ac9-4062-819c-07c1d80dc80a",
        gameTimestamp: "2023-05-29 12:24:29",
        startTimestamp: "2023-05-29 12:20:30",
        endTimestamp: "2023-05-29 12:24:29",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f7428c36-3ac9-4062-819c-07c1d80dc80a&userId=1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108103",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2137,
        opponentMmrAfter: 2129,
        opponentSlpWon: 0,
        playerMmrBefore: 2179,
        playerMmrAfter: 2187,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "242f6c52-c6bc-4747-b06c-82bffdb06f9b",
        gameTimestamp: "2023-05-29 12:18:40",
        startTimestamp: "2023-05-29 12:14:45",
        endTimestamp: "2023-05-29 12:18:40",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=242f6c52-c6bc-4747-b06c-82bffdb06f9b&userId=1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108103",
        oponentTeamId: "5103103",
        opponent: "1ec9eb7e-53ff-68ec-a60c-867cd1ac725a",
        playerTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x280000000000010000f00020440c0000000100041020800a00010008084042020001001020810102000100042821410a0001000818204008000100101880c002",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740434,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c160b0820c00000001000c08208008000100080840410a000100102081450c000100102800440a00010008182102060001000008014406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nondec",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11678469,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c0b8008308000000010000202105080001000808a14202000100102080c40c000100042821410a000100041040c506000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11718173,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1911,
        opponentMmrAfter: 1908,
        opponentSlpWon: 0,
        playerMmrBefore: 2176,
        playerMmrAfter: 2179,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "2e4e8ef6-9210-4721-aa7b-e6a8ca0c51bb",
        gameTimestamp: "2023-05-29 12:05:33",
        startTimestamp: "2023-05-29 11:59:59",
        endTimestamp: "2023-05-29 12:05:33",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2e4e8ef6-9210-4721-aa7b-e6a8ca0c51bb&userId=1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108103",
        oponentTeamId: "5054342",
        opponent: "1ec9eb6f-a0ea-6f8e-a60c-01598590b9e2",
        playerTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101c020c30c00000001001428014102000100042860410a0001000018014302000100143041430c00010004102081040001001010004406",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742027,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100038100708308000000010014288105040001001020808408000100001800c00600010014308180040001001018810104000100101080820c",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730505,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080f010c2000000000100142840420800010010208100080001000018008004000100081840420c000100041080c0020001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693166,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1909,
        opponentMmrAfter: 1906,
        opponentSlpWon: 0,
        playerMmrBefore: 2173,
        playerMmrAfter: 2176,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "42b4d4d9-7123-4d30-8327-dcf4882be3fe",
        gameTimestamp: "2023-05-29 11:50:20",
        startTimestamp: "2023-05-29 11:45:18",
        endTimestamp: "2023-05-29 11:50:20",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=42b4d4d9-7123-4d30-8327-dcf4882be3fe&userId=1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108103",
        oponentTeamId: "4590444",
        opponent: "1ed7d5ef-ead5-6530-a45b-115d8b9cee2f",
        playerTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002c1606103040000000100102880430800010008088142020001000c20808408000100102880c40a000100081840c3040001001018a08102",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11654431,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100000000e08208000000010004102085040001000428404202000100102081010c0001000428614402000100081840c2060001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_3011_s4_nondec",
            },
            axie_id: 11728208,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0e0b0410800000001001008214304000100080840440400010010200105020001001030410208000100081840c20a0001001018004002",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11677259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1963,
        opponentMmrAfter: 1959,
        opponentSlpWon: 0,
        playerMmrBefore: 2169,
        playerMmrAfter: 2173,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "669dd335-3227-4f66-b015-6c759cc11def",
        gameTimestamp: "2023-05-29 11:44:17",
        startTimestamp: "2023-05-29 11:40:09",
        endTimestamp: "2023-05-29 11:44:17",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=669dd335-3227-4f66-b015-6c759cc11def&userId=1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108103",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2042,
        opponentMmrAfter: 2036,
        opponentSlpWon: 0,
        playerMmrBefore: 2163,
        playerMmrAfter: 2169,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:04.000Z",
  },
  {
    clientId: "1ec9eb73-89e8-676b-a60c-3ab8156b257e",
    name: "LYNX",
    rank: "Challenger",
    tier: 0,
    topRank: 4,
    vstar: 2182,
    avatar: "good_boy;triumphant_paws",
    battles: [
      {
        battleUuid: "0ec44b62-f854-4823-8ddc-d5347a7bbbf6",
        gameTimestamp: "2023-05-29 02:50:19",
        startTimestamp: "2023-05-29 02:45:31",
        endTimestamp: "2023-05-29 02:50:19",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0ec44b62-f854-4823-8ddc-d5347a7bbbf6&userId=1ec9eb73-89e8-676b-a60c-3ab8156b257e",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104178",
        oponentTeamId: "5107175",
        opponent: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        playerTeam: [
          {
            gene: "0x20000000000001000081a051041000000001001010808108000100002881440a000100001800c40a0001000018a0400200010004280141040001000c10004002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11717429,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10002602080820c0000000100000801040200010000280141040001000018018006000100103081830c000100100880c208000100000880830c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750423,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000200e020820800000001000010008002000100000800410800010000100080020001000008004306000100003000400a000100002801430a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741164,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2102,
        opponentMmrAfter: 2095,
        opponentSlpWon: 0,
        playerMmrBefore: 2175,
        playerMmrAfter: 2182,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "505676f9-3690-4350-acad-e0ed9523a36f",
        gameTimestamp: "2023-05-29 02:43:40",
        startTimestamp: "2023-05-29 02:40:39",
        endTimestamp: "2023-05-29 02:43:40",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=505676f9-3690-4350-acad-e0ed9523a36f&userId=1ec9eb73-89e8-676b-a60c-3ab8156b257e",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102139",
        oponentTeamId: "5096712",
        opponent: "1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        playerTeam: [
          {
            gene: "0x8000000000001000341c040820c00000001001428a1450a0001000428214504000100001800c0060001001430a14008000100101800800a0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11752980,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240e000820800000001001428a1400a0001000010a08108000100001800c404000100043081410400010010188101040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11694797,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010003810081030c00000001001428a141020001000c20210002000100001800c30c0001001030a104080001001010018406000100101080c204",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11705938,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x9000000000000100020100908408000000010010100080040001000028a1050a000100001800c006000100000860800200010004100041060001000008a0c006",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11676311,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407004100000000100001000800a00010000284040020001000018a0c106000100041000800200010000080040020001000408004206",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_bug_3010_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538878,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081207084000000000100000821000a00010000080040020001000010008004000100000800800a0001000030004006000100002800400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 1969448,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6fa6c94c-6855-4352-a365-d7cd5532e825",
        gameTimestamp: "2023-05-29 02:39:55",
        startTimestamp: "2023-05-29 02:36:13",
        endTimestamp: "2023-05-29 02:39:55",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6fa6c94c-6855-4352-a365-d7cd5532e825&userId=1ec9eb73-89e8-676b-a60c-3ab8156b257e",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102139",
        oponentTeamId: "5096712",
        opponent: "1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        playerTeam: [
          {
            gene: "0x8000000000001000341c040820c00000001001428a1450a0001000428214504000100001800c0060001001430a14008000100101800800a0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11752980,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240e000820800000001001428a1400a0001000010a08108000100001800c404000100043081410400010010188101040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11694797,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010003810081030c00000001001428a141020001000c20210002000100001800c30c0001001030a104080001001010018406000100101080c204",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11705938,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x9000000000000100020100908408000000010010100080040001000028a1050a000100001800c006000100000860800200010004100041060001000008a0c006",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11676311,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407004100000000100001000800a00010000284040020001000018a0c106000100041000800200010000080040020001000408004206",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_bug_3010_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538878,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081207084000000000100000821000a00010000080040020001000010008004000100000800800a0001000030004006000100002800400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 1969448,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5182a37e-e9e1-4aee-9c1c-fc9690e4ffd7",
        gameTimestamp: "2023-05-29 02:21:59",
        startTimestamp: "2023-05-29 02:18:17",
        endTimestamp: "2023-05-29 02:21:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5182a37e-e9e1-4aee-9c1c-fc9690e4ffd7&userId=1ec9eb73-89e8-676b-a60c-3ab8156b257e",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104178",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x20000000000001000081a051041000000001001010808108000100002881440a000100001800c40a0001000018a0400200010004280141040001000c10004002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11717429,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10002602080820c0000000100000801040200010000280141040001000018018006000100103081830c000100100880c208000100000880830c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750423,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000200e020820800000001000010008002000100000800410800010000100080020001000008004306000100003000400a000100002801430a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741164,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008160208210000000010000100101040001001028a0400400010000304100020001000008004002000100000800c208000100001800c00a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11567779,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2062,
        opponentMmrAfter: 2055,
        opponentSlpWon: 0,
        playerMmrBefore: 2168,
        playerMmrAfter: 2175,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "923f94ef-2b1e-42bd-8a32-ad5e59840b18",
        gameTimestamp: "2023-05-29 02:16:36",
        startTimestamp: "2023-05-29 02:11:47",
        endTimestamp: "2023-05-29 02:16:36",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=923f94ef-2b1e-42bd-8a32-ad5e59840b18&userId=1ec9eb73-89e8-676b-a60c-3ab8156b257e",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102052",
        oponentTeamId: "5024274",
        opponent: "1ec9eb7b-288a-6930-a60c-10305ebdc6c4",
        playerTeam: [
          {
            gene: "0x28000000000001000141a050830c00000001001408a04502000100142841000800010014208185080001001418a0810400010014106105040001001408610008",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_reptile_3010_s4_nft",
              eyes: "ecard_reptile_3010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "ecard_reptile_3010_s4_nft",
            },
            axie_id: 11604434,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002c0c080000400000001000028a085020001001428a1050a0001001420a10508000100002801430800010014186101040001000c20610102",
            runes: ["rune_reptile_2011_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11725035,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0e060c20800000001001408a0400200010014286102040001001420a10508000100142081450800010014186185060001001428a14502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_reptile_3012_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11709817,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c04020c30c000000010004104101040001000808a1030a000100102000c406000100042821420c0001000410604104000100001080c506",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11636331,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010000004020430c0000000100101020810400010008082141080001001020810408000100042820c10a000100041020800c0001001018410408",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2018_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740932,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100000080e102080000000100001020840800010004204042020001001020808408000100103080410a00010008182040080001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "ecard_neutral_2018_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11676704,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:04.000Z",
  },
  {
    clientId: "1ec9eb7e-6abe-66ca-a60c-9eee49bce2ef",
    name: "MeatValue | SURA",
    rank: "Challenger",
    tier: 0,
    topRank: 5,
    vstar: 2170,
    avatar: "0;gold_07_s2",
    battles: [
      {
        battleUuid: "adc38a78-948f-405c-8e50-c0c3e1cea6d9",
        gameTimestamp: "2023-05-28 00:31:27",
        startTimestamp: "2023-05-28 00:25:49",
        endTimestamp: "2023-05-28 00:31:27",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=adc38a78-948f-405c-8e50-c0c3e1cea6d9&userId=1ec9eb7e-6abe-66ca-a60c-9eee49bce2ef",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104709",
        oponentTeamId: "5051435",
        opponent: "1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        playerTeam: [
          {
            gene: "0x20000000000001000100a050c20000000001001428a1400a0001000428a08208000100001800c302000100143001850c000100002880c00a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11750729,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020830800000001001428a1450a000100042821410a000100001800c0060001001430a1850c0001000028a044060001001010808008",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751652,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016050820800000001001428a1430a0001000428810208000100001800c006000100100821420600010008080141040001001010808006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11726775,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2101,
        opponentMmrAfter: 2093,
        opponentSlpWon: 0,
        playerMmrBefore: 2162,
        playerMmrAfter: 2170,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "df19a968-a5cc-459f-99ff-ed29e7b4569d",
        gameTimestamp: "2023-05-28 00:24:59",
        startTimestamp: "2023-05-28 00:19:46",
        endTimestamp: "2023-05-28 00:24:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=df19a968-a5cc-459f-99ff-ed29e7b4569d&userId=1ec9eb7e-6abe-66ca-a60c-9eee49bce2ef",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104709",
        oponentTeamId: "5080139",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x20000000000001000100a050c20000000001001428a1400a0001000428a08208000100001800c302000100143001850c000100002880c00a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11750729,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020830800000001001428a1450a000100042821410a000100001800c0060001001430a1850c0001000028a044060001001010808008",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751652,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016050820800000001001428a1430a0001000428810208000100001800c006000100100821420600010008080141040001001010808006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11726775,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040820c0000000100142821050a000100042821410a000100001800c006000100143060440a00010000102143060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11728609,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2086,
        opponentMmrAfter: 2078,
        opponentSlpWon: 0,
        playerMmrBefore: 2154,
        playerMmrAfter: 2162,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "88555196-d5d5-409e-9d9c-48190f5eda04",
        gameTimestamp: "2023-05-28 00:19:16",
        startTimestamp: "2023-05-28 00:15:55",
        endTimestamp: "2023-05-28 00:19:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=88555196-d5d5-409e-9d9c-48190f5eda04&userId=1ec9eb7e-6abe-66ca-a60c-9eee49bce2ef",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104709",
        oponentTeamId: "5105092",
        opponent: "1ecb6080-f8d7-638f-8ba8-beb9cd2501e7",
        playerTeam: [
          {
            gene: "0x20000000000001000100a050c20000000001001428a1400a0001000428a08208000100001800c302000100143001850c000100002880c00a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11750729,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020830800000001001428a1450a000100042821410a000100001800c0060001001430a1850c0001000028a044060001001010808008",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751652,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016050820800000001001428a1430a0001000428810208000100001800c006000100100821420600010008080141040001001010808006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11726775,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x90000000000001000140a050841000000001000420a04302000100001040400400010004086081040001000c1060810600010004206180060001000c10608302",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11195266,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804020c00c0000000100002801400a0001000008210002000100001000850800010000080040040001000008004106000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "ecard_beast_2012_s4_nft",
              horn: "ecard_beast_3012_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 7129765,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07002000000000100000800850400010000204044080001000018008004000100002801000200010000080080060001000c1000c10c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2019_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11553102,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1909,
        opponentMmrAfter: 1906,
        opponentSlpWon: 0,
        playerMmrBefore: 2151,
        playerMmrAfter: 2154,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f9ea2313-8deb-49f6-ba1f-5c67270e9fd2",
        gameTimestamp: "2023-05-28 00:14:47",
        startTimestamp: "2023-05-28 00:09:14",
        endTimestamp: "2023-05-28 00:14:47",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f9ea2313-8deb-49f6-ba1f-5c67270e9fd2&userId=1ec9eb7e-6abe-66ca-a60c-9eee49bce2ef",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104709",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x20000000000001000100a050c20000000001001428a1400a0001000428a08208000100001800c302000100143001850c000100002880c00a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11750729,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020830800000001001428a1450a000100042821410a000100001800c0060001001430a1850c0001000028a044060001001010808008",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751652,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016050820800000001001428a1430a0001000428810208000100001800c006000100100821420600010008080141040001001010808006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11726775,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000202090820c00000001001428a1420a0001000820a1040a0001000018a10102000100100881850c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11714606,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160b0c30c00000001001428a1450a0001000820814208000100001800c0060001001008a1810a000100041001800a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749881,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2033,
        opponentMmrAfter: 2027,
        opponentSlpWon: 0,
        playerMmrBefore: 2145,
        playerMmrAfter: 2151,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "2bc3e4a4-673b-4063-b091-3837d20383d5",
        gameTimestamp: "2023-05-28 00:08:14",
        startTimestamp: "2023-05-28 00:03:55",
        endTimestamp: "2023-05-28 00:08:14",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2bc3e4a4-673b-4063-b091-3837d20383d5&userId=1ec9eb7e-6abe-66ca-a60c-9eee49bce2ef",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104709",
        oponentTeamId: "5090015",
        opponent: "1ed82d7f-5283-61cd-9e0e-dc96e62a3697",
        playerTeam: [
          {
            gene: "0x20000000000001000100a050c20000000001001428a1400a0001000428a08208000100001800c302000100143001850c000100002880c00a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11750729,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020830800000001001428a1450a000100042821410a000100001800c0060001001430a1850c0001000028a044060001001010808008",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751652,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016050820800000001001428a1430a0001000428810208000100001800c006000100100821420600010008080141040001001010808006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11726775,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002412060821000000001000028014304000100002800400a000100001800c50c0001000830414202000100083021020c0001000008004002",
            runes: ["rune_dusk_3011_s4_nft"],
            charms: {
              back: "ecard_bird_3013_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "",
            },
            axie_id: 11677120,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001012091021000000001000820410102000100082841420a0001000818a14206000100083041420c000100083040420c0001000810408204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734242,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001404050820c00000001000c088142040001000c106085040001000c306180040001000c2861410a0001000c3061830c0001000c10608302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 8219475,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1940,
        opponentMmrAfter: 1936,
        opponentSlpWon: 0,
        playerMmrBefore: 2141,
        playerMmrAfter: 2145,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:05.000Z",
  },
  {
    clientId: "1ec9eb73-58cb-6f5b-a60c-740b21294d64",
    name: "Im So FucKING BaD | SFG",
    rank: "Challenger",
    tier: 0,
    topRank: 6,
    vstar: 2162,
    avatar: "1;gold_s3",
    battles: [
      {
        battleUuid: "eef0ff1d-7508-48b8-a137-0d8b02417857",
        gameTimestamp: "2023-05-29 07:38:11",
        startTimestamp: "2023-05-29 07:33:05",
        endTimestamp: "2023-05-29 07:38:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=eef0ff1d-7508-48b8-a137-0d8b02417857&userId=1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093305",
        oponentTeamId: "5022556",
        opponent: "1ed48b15-7db4-6151-af17-2542f0edf74b",
        playerTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000000600082080000000100101080840400010010288140020001000410208302000100042020810800010004302185040001000c2820c106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11747831,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002404050820800000001001428a14102000100042821420a000100001800c10a0001000010a185020001000010014406000100101020c404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11745098,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2151,
        opponentMmrAfter: 2142,
        opponentSlpWon: 0,
        playerMmrBefore: 2153,
        playerMmrAfter: 2162,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "442faadb-5ff0-4aa5-9e17-7961f0e721f8",
        gameTimestamp: "2023-05-29 07:32:25",
        startTimestamp: "2023-05-29 07:23:28",
        endTimestamp: "2023-05-29 07:32:25",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=442faadb-5ff0-4aa5-9e17-7961f0e721f8&userId=1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5093305",
        oponentTeamId: "3702096",
        opponent: "1ec9eb73-582b-66fa-a60c-919d13644954",
        playerTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000000600082080000000100101080840400010010288140020001000410208302000100042020810800010004302185040001000c2820c106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11747831,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000381c0e082100000000100041000410400010008084045080001001020810302000100042881840a000100081840c402000100101880c102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725601,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010080c102100000000100041020850400010008084042020001001020810408000100103081840c000100081840c5020001000008a0c40c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11721862,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e0c21000000001000c0841430a00010008084042020001001020004408000100042801010a00010004102081040001001018814406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11718907,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1966,
        opponentMmrAfter: 1981,
        opponentSlpWon: 22,
        playerMmrBefore: 2168,
        playerMmrAfter: 2153,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "8d6ba4a5-08fc-4af3-b100-b5481f675a1c",
        gameTimestamp: "2023-05-29 07:18:11",
        startTimestamp: "2023-05-29 07:10:41",
        endTimestamp: "2023-05-29 07:18:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8d6ba4a5-08fc-4af3-b100-b5481f675a1c&userId=1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093305",
        oponentTeamId: "5108873",
        opponent: "1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        playerTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000000600082080000000100101080840400010010288140020001000410208302000100042020810800010004302185040001000c2820c106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11747831,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2067,
        opponentMmrAfter: 2060,
        opponentSlpWon: 0,
        playerMmrBefore: 2161,
        playerMmrAfter: 2168,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ae994c39-3479-4f01-b5c4-f40b48843911",
        gameTimestamp: "2023-05-29 07:09:10",
        startTimestamp: "2023-05-29 07:04:15",
        endTimestamp: "2023-05-29 07:09:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ae994c39-3479-4f01-b5c4-f40b48843911&userId=1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093305",
        oponentTeamId: "5111487",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000000600082080000000100101080840400010010288140020001000410208302000100042020810800010004302185040001000c2820c106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11747831,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040820c0000000100142821050a000100042821410a000100001800c006000100143060440a00010000102143060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11728609,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2087,
        opponentMmrAfter: 2079,
        opponentSlpWon: 0,
        playerMmrBefore: 2153,
        playerMmrAfter: 2161,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9ed30668-2ce5-4c86-a547-5264592fbd44",
        gameTimestamp: "2023-05-29 06:59:44",
        startTimestamp: "2023-05-29 06:53:30",
        endTimestamp: "2023-05-29 06:59:44",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9ed30668-2ce5-4c86-a547-5264592fbd44&userId=1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093305",
        oponentTeamId: "5102797",
        opponent: "1ec9eb6f-9b34-68e1-a60c-5e42d2af228f",
        playerTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000000600082080000000100101080840400010010288140020001000410208302000100042020810800010004302185040001000c2820c106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11747831,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000300000082000000000100041061430200010008080145020001001020210408000100042821410a00010004102041040001001418010406",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11725478,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030060c082100000000100041000440800010008084042020001001020810408000100100821440c000100081840c3080001000008004002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11715044,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100000120b0c40800000001000c082081080001000808404508000100102041040a000100102841840a00010008184082060001001018408406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_neutral_2017_s4_nft",
              horn: "ecard_aquatic_3012_s4_nondec",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11686088,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1911,
        opponentMmrAfter: 1907,
        opponentSlpWon: 0,
        playerMmrBefore: 2149,
        playerMmrAfter: 2153,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:06.000Z",
  },
  {
    clientId: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
    name: "DONT HUG ME IM SCARED",
    rank: "Challenger",
    tier: 0,
    topRank: 7,
    vstar: 2158,
    avatar: "s3_top20k;patrick23",
    battles: [
      {
        battleUuid: "c59b7262-c926-4b89-81c4-fb133501244b",
        gameTimestamp: "2023-05-29 13:34:10",
        startTimestamp: "2023-05-29 13:28:25",
        endTimestamp: "2023-05-29 13:34:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c59b7262-c926-4b89-81c4-fb133501244b&userId=1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022978",
        oponentTeamId: "5091414",
        opponent: "1ed223d3-9714-6f20-9f21-4c540ced8c1f",
        playerTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100038140a0c30000000001001428a142080001000428810402000100001800c006000100041021440a000100002880c406000100101081840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749067,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812020c30c00000001001428a1400a0001000428a0810800010000188041060001001430a1850c00010014300141040001001010410004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11751730,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010003808050820800000001001428a144040001000c1081040a0001000018004006000100143081410a00010004102085040001001010418404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11738842,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1913,
        opponentMmrAfter: 1910,
        opponentSlpWon: 0,
        playerMmrBefore: 2155,
        playerMmrAfter: 2158,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bc446f2e-4ebe-4019-a122-88f0e52e7033",
        gameTimestamp: "2023-05-29 13:27:32",
        startTimestamp: "2023-05-29 13:23:33",
        endTimestamp: "2023-05-29 13:27:32",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bc446f2e-4ebe-4019-a122-88f0e52e7033&userId=1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022978",
        oponentTeamId: "5058873",
        opponent: "1ec9eb7e-588c-6671-a60c-891de6da708f",
        playerTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c1c040830800000001001428a14502000100042881010a00010000186080060001001030a1850c00010010182144060001001010808208",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11728613,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001404020831000000001001428a1450a000100042821010a000100001800c2020001001430a1850c0001000028414406000100101081820c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11735674,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100806082080000000100142880450a0001000820410104000100001800c002000100103020c50c000100101840c406000100101080840c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719618,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1947,
        opponentMmrAfter: 1943,
        opponentSlpWon: 0,
        playerMmrBefore: 2151,
        playerMmrAfter: 2155,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "cff934e4-e3b6-43fd-bf67-5b0ba7c9433f",
        gameTimestamp: "2023-05-29 13:22:31",
        startTimestamp: "2023-05-29 13:15:23",
        endTimestamp: "2023-05-29 13:22:31",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cff934e4-e3b6-43fd-bf67-5b0ba7c9433f&userId=1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022978",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2070,
        opponentMmrAfter: 2082,
        opponentSlpWon: 24,
        playerMmrBefore: 2163,
        playerMmrAfter: 2151,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "e9b24345-8045-4fe2-8260-6154c7a179c6",
        gameTimestamp: "2023-05-29 13:14:22",
        startTimestamp: "2023-05-29 13:10:33",
        endTimestamp: "2023-05-29 13:14:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e9b24345-8045-4fe2-8260-6154c7a179c6&userId=1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022978",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2069,
        opponentMmrAfter: 2062,
        opponentSlpWon: 0,
        playerMmrBefore: 2156,
        playerMmrAfter: 2163,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "10f82f45-a72a-4fdb-a2d2-c5fecfd9c63d",
        gameTimestamp: "2023-05-29 13:09:53",
        startTimestamp: "2023-05-29 13:02:22",
        endTimestamp: "2023-05-29 13:09:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=10f82f45-a72a-4fdb-a2d2-c5fecfd9c63d&userId=1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022978",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2140,
        opponentMmrAfter: 2131,
        opponentSlpWon: 0,
        playerMmrBefore: 2147,
        playerMmrAfter: 2156,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:06.000Z",
  },
  {
    clientId: "1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
    name: "METAT8 | Gaid",
    rank: "Challenger",
    tier: 0,
    topRank: 8,
    vstar: 2150,
    avatar: "buba_joker;silver_s2",
    battles: [
      {
        battleUuid: "f3b563e2-b602-4c91-a43c-957b985774cd",
        gameTimestamp: "2023-05-29 09:57:46",
        startTimestamp: "2023-05-29 09:53:08",
        endTimestamp: "2023-05-29 09:57:46",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f3b563e2-b602-4c91-a43c-957b985774cd&userId=1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104659",
        oponentTeamId: "5027313",
        opponent: "1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        playerTeam: [
          {
            gene: "0x90000000000001000200e0708108000000010010108084040001000028a1000a000100001881430c00010010280040020001001028208002000100000800450c",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11623986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000180e1040c0000000100000821440a0001000028604202000100001861830c0001000c2861420400010010180083060001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11746158,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d10008aa-d530-4d65-be45-a75ac8d5ad15",
        gameTimestamp: "2023-05-29 09:52:19",
        startTimestamp: "2023-05-29 09:47:18",
        endTimestamp: "2023-05-29 09:52:19",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d10008aa-d530-4d65-be45-a75ac8d5ad15&userId=1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104659",
        oponentTeamId: "5027313",
        opponent: "1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        playerTeam: [
          {
            gene: "0x90000000000001000200e0708108000000010010108084040001000028a1000a000100001881430c00010010280040020001001028208002000100000800450c",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11623986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000180e1040c0000000100000821440a0001000028604202000100001861830c0001000c2861420400010010180083060001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11746158,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b3079415-6f20-4a62-8cb8-885d7e05af36",
        gameTimestamp: "2023-05-29 09:46:22",
        startTimestamp: "2023-05-29 09:42:54",
        endTimestamp: "2023-05-29 09:46:22",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b3079415-6f20-4a62-8cb8-885d7e05af36&userId=1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104659",
        oponentTeamId: "5027313",
        opponent: "1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        playerTeam: [
          {
            gene: "0x90000000000001000200e0708108000000010010108084040001000028a1000a000100001881430c00010010280040020001001028208002000100000800450c",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11623986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000180e1040c0000000100000821440a0001000028604202000100001861830c0001000c2861420400010010180083060001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11746158,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ebba637e-fec0-481d-bf28-96e919325778",
        gameTimestamp: "2023-05-29 09:35:00",
        startTimestamp: "2023-05-29 09:30:15",
        endTimestamp: "2023-05-29 09:35:00",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ebba637e-fec0-481d-bf28-96e919325778&userId=1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110468",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x8000000000001000340a041030c00000001000410214102000100042820450a00010000184081040001000428214102000100101820410a000100040820c20c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_bug_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11717203,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001c0e0208310000000010004204141040001000028014408000100001861830c000100000820830200010004108141060001000008808106",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11751631,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f56fca6b-153d-472e-8b31-06ef0b7aa650",
        gameTimestamp: "2023-05-29 09:29:41",
        startTimestamp: "2023-05-29 09:24:21",
        endTimestamp: "2023-05-29 09:29:41",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f56fca6b-153d-472e-8b31-06ef0b7aa650&userId=1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110468",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x8000000000001000340a041030c00000001000410214102000100042820450a00010000184081040001000428214102000100101820410a000100040820c20c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_bug_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11717203,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001c0e0208310000000010004204141040001000028014408000100001861830c000100000820830200010004108141060001000008808106",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11751631,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:07.000Z",
  },
  {
    clientId: "1ecad0d8-5e7b-686f-b354-289fc53ead42",
    name: "The Chosen Topaz One Trick",
    rank: "Challenger",
    tier: 0,
    topRank: 9,
    vstar: 2148,
    avatar: "puffy_vday23;gold_s1",
    battles: [
      {
        battleUuid: "65dc75ce-7543-4261-a5bb-f7f19a5b8a63",
        gameTimestamp: "2023-05-28 13:59:45",
        startTimestamp: "2023-05-28 13:54:28",
        endTimestamp: "2023-05-28 13:59:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=65dc75ce-7543-4261-a5bb-f7f19a5b8a63&userId=1ecad0d8-5e7b-686f-b354-289fc53ead42",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5061513",
        oponentTeamId: "5094798",
        opponent: "1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        playerTeam: [
          {
            gene: "0x80000000000010002c1a0a0820800000001001428804308000100082000430200010000184040060001001030018104000100002801410c000100101080800a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11714378,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c00000830800000001001428a1410a000100102821020400010000186100060001001008a1820c0001000410a081040001001010818404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730886,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101c050c00c0000000100142820400a000100042860410a000100001800c3020001001430414206000100041080800a0001001010808002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11734007,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736392,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720304,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2028,
        opponentMmrAfter: 2022,
        opponentSlpWon: 0,
        playerMmrBefore: 2142,
        playerMmrAfter: 2148,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1a15ab34-41af-4871-8a41-0a0529cd05d6",
        gameTimestamp: "2023-05-28 13:51:32",
        startTimestamp: "2023-05-28 13:44:34",
        endTimestamp: "2023-05-28 13:51:32",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1a15ab34-41af-4871-8a41-0a0529cd05d6&userId=1ecad0d8-5e7b-686f-b354-289fc53ead42",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5061513",
        oponentTeamId: "5093305",
        opponent: "1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        playerTeam: [
          {
            gene: "0x80000000000010002c1a0a0820800000001001428804308000100082000430200010000184040060001001030018104000100002801410c000100101080800a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11714378,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c00000830800000001001428a1410a000100102821020400010000186100060001001008a1820c0001000410a081040001001010818404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730886,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101c050c00c0000000100142820400a000100042860410a000100001800c3020001001430414206000100041080800a0001001010808002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11734007,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040418030c308000000010010108082020001001028a08104000100041000840a000100042060440a00010004300181020001000418010002",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11716121,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2119,
        opponentMmrAfter: 2110,
        opponentSlpWon: 0,
        playerMmrBefore: 2133,
        playerMmrAfter: 2142,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "fbed2a26-ba99-4b4c-9130-4d5acc5e5fdd",
        gameTimestamp: "2023-05-28 13:42:09",
        startTimestamp: "2023-05-28 13:38:21",
        endTimestamp: "2023-05-28 13:42:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fbed2a26-ba99-4b4c-9130-4d5acc5e5fdd&userId=1ecad0d8-5e7b-686f-b354-289fc53ead42",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5061513",
        oponentTeamId: "5108150",
        opponent: "1ec9f1c4-2017-6ada-ad4d-981495267dcf",
        playerTeam: [
          {
            gene: "0x80000000000010002c1a0a0820800000001001428804308000100082000430200010000184040060001001030018104000100002801410c000100101080800a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11714378,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c00000830800000001001428a1410a000100102821020400010000186100060001001008a1820c0001000410a081040001001010818404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730886,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101c050c00c0000000100142820400a000100042860410a000100001800c3020001001430414206000100041080800a0001001010808002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11734007,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101a0408208000000010004088100080001000428214002000100001820c206000100042840810a00010004108041060001000408010002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nondec",
            },
            axie_id: 11675018,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c0e070800000000001001020008002000100002801400a000100001800c00a000100003001000a00010000108080020001000008004304",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11584165,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081002082100000000100001000840a000100001000800a0001000030818004000100000800400a00010000080140060001000020010102",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11498805,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1987,
        opponentMmrAfter: 1981,
        opponentSlpWon: 0,
        playerMmrBefore: 2127,
        playerMmrAfter: 2133,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6d0c7186-00bb-4ece-9145-72608f3335f5",
        gameTimestamp: "2023-05-28 13:34:07",
        startTimestamp: "2023-05-28 13:28:10",
        endTimestamp: "2023-05-28 13:34:07",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6d0c7186-00bb-4ece-9145-72608f3335f5&userId=1ecad0d8-5e7b-686f-b354-289fc53ead42",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5061513",
        oponentTeamId: "5034208",
        opponent: "1ec9eb6f-5036-6738-a60c-58789df68b8e",
        playerTeam: [
          {
            gene: "0x80000000000010002c1a0a0820800000001001428804308000100082000430200010000184040060001001030018104000100002801410c000100101080800a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11714378,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c00000830800000001001428a1410a000100102821020400010000186100060001001008a1820c0001000410a081040001001010818404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730886,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101c050c00c0000000100142820400a000100042860410a000100001800c3020001001430414206000100041080800a0001001010808002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11734007,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001000080c20800000001001408a043040001001428a1450a000100142081840a0001001420a105060001001418a0c00c0001000c28004308",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_3010_s4_nft",
              horn: "",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "",
            },
            axie_id: 11720492,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002010000c30800000001001408a0440a000100142860850a0001001420a1000a0001000c18a1040a0001001418a0c5060001000c2820850c",
            runes: ["rune_reptile_2011_s4_nondec"],
            charms: {
              back: "ecard_reptile_3013_s4_nft",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11673371,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002010080c304000000010014080085020001001428a1450a000100001800c50a0001000c30a102040001001418a0c5060001000c28a18302",
            runes: ["rune_reptile_2011_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_3012_s4_nft",
            },
            axie_id: 11733542,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1875,
        opponentMmrAfter: 1872,
        opponentSlpWon: 0,
        playerMmrBefore: 2124,
        playerMmrAfter: 2127,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "33e8ca96-0f7b-4668-bd3a-cd79961564b9",
        gameTimestamp: "2023-05-28 13:26:23",
        startTimestamp: "2023-05-28 13:21:31",
        endTimestamp: "2023-05-28 13:26:23",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=33e8ca96-0f7b-4668-bd3a-cd79961564b9&userId=1ecad0d8-5e7b-686f-b354-289fc53ead42",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5061513",
        oponentTeamId: "5031070",
        opponent: "1ed1cae7-8089-6d9a-a312-15278b4edefa",
        playerTeam: [
          {
            gene: "0x80000000000010002c1a0a0820800000001001428804308000100082000430200010000184040060001001030018104000100002801410c000100101080800a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11714378,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c00000830800000001001428a1410a000100102821020400010000186100060001001008a1820c0001000410a081040001001010818404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730886,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101c050c00c0000000100142820400a000100042860410a000100001800c3020001001430414206000100041080800a0001001010808002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11734007,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c12040c2080000000100142841450a0001000428814002000100001820c1020001000030a0830c00010000280043080001001010814002",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11747057,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c10070840800000001001428210408000100102020430a000100001800c40c0001001428814204000100101881010c000100101080830a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11685975,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000181a060830000000001001428a1450a0001001020008204000100001800c30a000100100820840200010010188104060001001010218404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747521,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1945,
        opponentMmrAfter: 1940,
        opponentSlpWon: 0,
        playerMmrBefore: 2119,
        playerMmrAfter: 2124,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:08.000Z",
  },
  {
    clientId: "1ec9eb6f-4004-6f6c-a60c-b650107f8686",
    name: "JaWiii | KYT | PZL",
    rank: "Challenger",
    tier: 0,
    topRank: 10,
    vstar: 2147,
    avatar: "s3_top20k;gold_s3",
    battles: [
      {
        battleUuid: "f752a9a5-7679-4d95-bba2-afc46d52b9c9",
        gameTimestamp: "2023-05-29 12:31:13",
        startTimestamp: "2023-05-29 12:27:37",
        endTimestamp: "2023-05-29 12:31:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f752a9a5-7679-4d95-bba2-afc46d52b9c9&userId=1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103996",
        oponentTeamId: "5106274",
        opponent: "1ec9eb6f-550e-6b25-a60c-a7391a7f65aa",
        playerTeam: [
          {
            gene: "0x1800000000000100023000c084080000000100000861430400010004106141040001000018604504000100001860c30a0001000c280101040001000c10214306",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11669683,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040208408000000010004206105040001000028a08108000100001800420c0001000408008102000100042800410a000100101080410a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11519038,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0810410000000010000100140020001000008004002000100001020c004000100001000810c0001000030004002000100002801420a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5457881,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002b000b041040000000100080800830400010004202104020001000018204308000100041081010400010004102084020001000428208408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 8425280,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804050441000000001000808208302000100041061450a0001000018804306000100040820400800010004082045060001000428618008",
            runes: ["rune_dawn_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_bug_3010_s4_nft",
            },
            axie_id: 11687439,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000380004080000000000100080881030a00010004200080080001000c1001840a0001000c3060c10200010004186181080001000410618302",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 2004586,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1904,
        opponentMmrAfter: 1900,
        opponentSlpWon: 0,
        playerMmrBefore: 2143,
        playerMmrAfter: 2147,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0a4acf0e-67b4-4a89-bbb3-bf04993aab5d",
        gameTimestamp: "2023-05-29 12:22:46",
        startTimestamp: "2023-05-29 12:17:56",
        endTimestamp: "2023-05-29 12:22:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0a4acf0e-67b4-4a89-bbb3-bf04993aab5d&userId=1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103996",
        oponentTeamId: "5106640",
        opponent: "1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        playerTeam: [
          {
            gene: "0x1800000000000100023000c084080000000100000861430400010004106141040001000018604504000100001860c30a0001000c280101040001000c10214306",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11669683,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040208408000000010004206105040001000028a08108000100001800420c0001000408008102000100042800410a000100101080410a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11519038,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0810410000000010000100140020001000008004002000100001020c004000100001000810c0001000030004002000100002801420a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5457881,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2095,
        opponentMmrAfter: 2087,
        opponentSlpWon: 0,
        playerMmrBefore: 2135,
        playerMmrAfter: 2143,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "52e9704a-e503-4f8d-a839-2187eecd410d",
        gameTimestamp: "2023-05-29 12:15:39",
        startTimestamp: "2023-05-29 12:12:24",
        endTimestamp: "2023-05-29 12:15:39",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=52e9704a-e503-4f8d-a839-2187eecd410d&userId=1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103996",
        oponentTeamId: "5087063",
        opponent: "1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        playerTeam: [
          {
            gene: "0x1800000000000100023000c084080000000100000861430400010004106141040001000018604504000100001860c30a0001000c280101040001000c10214306",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11669683,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040208408000000010004206105040001000028a08108000100001800420c0001000408008102000100042800410a000100101080410a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11519038,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0810410000000010000100140020001000008004002000100001020c004000100001000810c0001000030004002000100002801420a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5457881,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2056,
        opponentMmrAfter: 2049,
        opponentSlpWon: 0,
        playerMmrBefore: 2128,
        playerMmrAfter: 2135,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a7d9895d-761f-4b9f-9da1-17225669c528",
        gameTimestamp: "2023-05-29 11:55:15",
        startTimestamp: "2023-05-29 11:51:05",
        endTimestamp: "2023-05-29 11:55:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a7d9895d-761f-4b9f-9da1-17225669c528&userId=1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103996",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x1800000000000100023000c084080000000100000861430400010004106141040001000018604504000100001860c30a0001000c280101040001000c10214306",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11669683,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040208408000000010004206105040001000028a08108000100001800420c0001000408008102000100042800410a000100101080410a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11519038,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0810410000000010000100140020001000008004002000100001020c004000100001000810c0001000030004002000100002801420a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5457881,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2067,
        opponentMmrAfter: 2059,
        opponentSlpWon: 0,
        playerMmrBefore: 2120,
        playerMmrAfter: 2128,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "faef671a-b8e8-41e6-86d6-e697a65c8cba",
        gameTimestamp: "2023-05-29 11:45:00",
        startTimestamp: "2023-05-29 11:40:22",
        endTimestamp: "2023-05-29 11:45:00",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=faef671a-b8e8-41e6-86d6-e697a65c8cba&userId=1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103996",
        oponentTeamId: "5027313",
        opponent: "1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        playerTeam: [
          {
            gene: "0x1800000000000100023000c084080000000100000861430400010004106141040001000018604504000100001860c30a0001000c280101040001000c10214306",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11669683,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040208408000000010004206105040001000028a08108000100001800420c0001000408008102000100042800410a000100101080410a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11519038,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0810410000000010000100140020001000008004002000100001020c004000100001000810c0001000030004002000100002801420a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5457881,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2087,
        opponentMmrAfter: 2078,
        opponentSlpWon: 0,
        playerMmrBefore: 2111,
        playerMmrAfter: 2120,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:09.000Z",
  },
  {
    clientId: "1ec9eb73-5a3f-6406-a60c-5a6c19bf8ced",
    name: "la_lotto | guildless",
    rank: "Challenger",
    tier: 0,
    topRank: 11,
    vstar: 2144,
    avatar: "good_boy;0",
    battles: [
      {
        battleUuid: "67f22a11-7bfa-49ef-8de7-319bd48722c9",
        gameTimestamp: "2023-05-28 15:13:56",
        startTimestamp: "2023-05-28 15:07:35",
        endTimestamp: "2023-05-28 15:13:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=67f22a11-7bfa-49ef-8de7-319bd48722c9&userId=1ec9eb73-5a3f-6406-a60c-5a6c19bf8ced",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108354",
        oponentTeamId: "2885215",
        opponent: "1ec9eb6f-627f-6b6b-a60c-3a93dc97e53e",
        playerTeam: [
          {
            gene: "0x900000000000010001008030430000000001001428a14208000100102801000400010000180180040001000c0840c40a000100002880c20c0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2019_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11713637,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140e0b0c210000000010014282140040001001008814408000100001800c00c0001000830608404000100101841420c0001001010214404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_3011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735104,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002c0e02042100000000100142860400a00010010288103020001000c0800c0040001000c08a0840a00010000086144060001001010808404",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11731353,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002c08060040800000001000c0860400a0001000c08a1020a0001000c1841030a0001000c286085020001000c3020830c0001000428818302",
            runes: ["rune_plant_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1012_s4",
              eyes: "ecard_neutral_2017_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 3546527,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c12070c200000000010010288084040001001020810408000100102081040a000100103081840c000100101880c4060001001010808404",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nft",
              ears: "ecard_neutral_2018_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 2940701,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0e07083080000000100101080840400010010200044020001001020208106000100103041030a0001001018a1810c0001001030818108",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_puffy_1",
              mouth: "ecard_aquatic_2011_s4_nondec",
            },
            axie_id: 3602016,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9865fc23-3636-480a-b8df-84ab1c232ed0",
        gameTimestamp: "2023-05-28 15:06:56",
        startTimestamp: "2023-05-28 15:02:32",
        endTimestamp: "2023-05-28 15:06:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9865fc23-3636-480a-b8df-84ab1c232ed0&userId=1ec9eb73-5a3f-6406-a60c-5a6c19bf8ced",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108354",
        oponentTeamId: "4733940",
        opponent: "1ec9eb6f-669a-631c-a60c-e9e9f7bcfada",
        playerTeam: [
          {
            gene: "0x900000000000010001008030430000000001001428a14208000100102801000400010000180180040001000c0840c40a000100002880c20c0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2019_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11713637,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140e0b0c210000000010014282140040001001008814408000100001800c00c0001000830608404000100101841420c0001001010214404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_3011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735104,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002c0e02042100000000100142860400a00010010288103020001000c0800c0040001000c08a0840a00010000086144060001001010808404",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11731353,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002408051020c00000001000c106043020001000c084103020001000c106082060001000c2820830a0001000c2061030c0001000c30004502",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 6344037,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0710208000000010000282100080001000020004002000100003000800400010000100080040001000008810008000100002801400a",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 6905476,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000240e02104100000000100082880800a0001000008004002000100083040400400010000100080040001000008004002000100002801400a",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 6976578,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e79d40f5-bed0-4c09-ae7a-c6cc0a9b44a3",
        gameTimestamp: "2023-05-28 13:56:48",
        startTimestamp: "2023-05-28 13:54:06",
        endTimestamp: "2023-05-28 13:56:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e79d40f5-bed0-4c09-ae7a-c6cc0a9b44a3&userId=1ec9eb73-5a3f-6406-a60c-5a6c19bf8ced",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108354",
        oponentTeamId: "16",
        opponent: "bot-client-id",
        playerTeam: [
          {
            gene: "0x900000000000010001008030430000000001001428a14208000100102801000400010000180180040001000c0840c40a000100002880c20c0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2019_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11713637,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140e0b0c210000000010014282140040001001008814408000100001800c00c0001000830608404000100101841420c0001001010214404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_3011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735104,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002c0e02042100000000100142860400a00010010288103020001000c0800c0040001000c08a0840a00010000086144060001001010808404",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11731353,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002412090000000000001000c046023010001000c04602301000100140ca06503000100400e0070030001000c046023010001000c04602301",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 2,
            position: 0,
            axie_type: "starter",
          },
          {
            gene: "0x180000000000010002412090000000000001000c08604302000100001400a0050001000c206103080001000c0c606303000100440e207103000100440e207103",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 16,
            position: 2,
            axie_type: "starter",
          },
          {
            gene: "0x1000000000000000000000100001c00e007000100000800400200010000100080040001000c1c60e3070001004406203101000100002801400a",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 5,
            position: 5,
            axie_type: "starter",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "2a4f75ff-c2cc-44ca-9da1-0ddeaa77e4d3",
        gameTimestamp: "2023-05-28 13:53:33",
        startTimestamp: "2023-05-28 13:50:23",
        endTimestamp: "2023-05-28 13:53:33",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2a4f75ff-c2cc-44ca-9da1-0ddeaa77e4d3&userId=1ec9eb73-5a3f-6406-a60c-5a6c19bf8ced",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108354",
        oponentTeamId: "5107162",
        opponent: "1edfd198-260b-6bba-92aa-a5dc3dbff812",
        playerTeam: [
          {
            gene: "0x900000000000010001008030430000000001001428a14208000100102801000400010000180180040001000c0840c40a000100002880c20c0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2019_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11713637,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140e0b0c210000000010014282140040001001008814408000100001800c00c0001000830608404000100101841420c0001001010214404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_3011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735104,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002c0e02042100000000100142860400a00010010288103020001000c0800c0040001000c08a0840a00010000086144060001001010808404",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11731353,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002412090000000000001000c046023010001000c04602301000100140ca06503000100400e0070030001000c046023010001000c04602301",
            runes: ["rune_neutral_1012_s4"],
            charms: {
              back: "ecard_neutral_1013_s4",
              ears: "ecard_neutral_1004",
              eyes: "ecard_plant_0001",
              horn: "ecard_neutral_1002",
              tail: "ecard_neutral_2014_s4",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 2,
            position: 0,
            axie_type: "starter",
          },
          {
            gene: "0x1000000000000000000000100000c0060030001000004002001000100000c0060030001000c046023010001000c0c606303000100000c006003",
            runes: ["rune_beast_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1011_s4",
              ears: "ecard_neutral_1011_s4",
              eyes: "ecard_beast_2013_s4_nondec",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_neutral_1002",
              mouth: "ecard_neutral_2018_s4",
            },
            axie_id: 1,
            position: 1,
            axie_type: "starter",
          },
          {
            gene: "0x200000000000010002c160b0000000000001001004802401000100000c00600300010010048024010001001004802401000100140ca065030001001004802401",
            runes: ["rune_neutral_1010_s4"],
            charms: {
              back: "ecard_neutral_1002",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1002",
              tail: "ecard_neutral_1003",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 3,
            position: 4,
            axie_type: "starter",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b35df277-a532-4fab-802e-2727f76b5ab3",
        gameTimestamp: "2023-05-28 13:49:45",
        startTimestamp: "2023-05-28 13:45:31",
        endTimestamp: "2023-05-28 13:49:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b35df277-a532-4fab-802e-2727f76b5ab3&userId=1ec9eb73-5a3f-6406-a60c-5a6c19bf8ced",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108354",
        oponentTeamId: "1860699",
        opponent: "1ec9eb7e-36e7-69af-a60c-f5366f1533d4",
        playerTeam: [
          {
            gene: "0x900000000000010001008030430000000001001428a14208000100102801000400010000180180040001000c0840c40a000100002880c20c0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2019_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11713637,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140e0b0c210000000010014282140040001001008814408000100001800c00c0001000830608404000100101841420c0001001010214404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_3011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735104,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002c0e02042100000000100142860400a00010010288103020001000c0800c0040001000c08a0840a00010000086144060001001010808404",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11731353,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000340e070c308000000010010208081020001001020408408000100101880c406000100082081840c000100101880c5020001001008210508",
            runes: ["rune_neutral_utility_1"],
            charms: {
              back: "ecard_aquatic_3013_s4_nft",
              ears: "ecard_neutral_2015_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 2655729,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002602091020800000001001008810402000100100881440a000100102880440800010010088144020001001018a144060001001010808404",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_aquatic_2012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 324878,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e07080000000000100100880800a000100102081040400010010288104080001001030818406000100101880c4060001001008814402",
            runes: ["rune_aquatic_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_aquatic_2013_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1011_s4",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 3956067,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:09.000Z",
  },
  {
    clientId: "1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
    name: "LongTan | Ancient8",
    rank: "Challenger",
    tier: 0,
    topRank: 12,
    vstar: 2123,
    avatar: "s2_top1k;gold_s2",
    battles: [
      {
        battleUuid: "101c49c4-f581-4ee7-bc15-8d80c4d7b575",
        gameTimestamp: "2023-05-29 11:01:34",
        startTimestamp: "2023-05-29 10:57:07",
        endTimestamp: "2023-05-29 11:01:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=101c49c4-f581-4ee7-bc15-8d80c4d7b575&userId=1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108082",
        oponentTeamId: "5054809",
        opponent: "1ec9eb6f-a1cf-681b-a60c-31d4471a1f46",
        playerTeam: [
          {
            gene: "0x80000000000010001404090830c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751748,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003804020c30c00000001001428a1450a000100042821410a000100001800c4020001001430a0450c000100002880c402000100101041050c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11751728,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003808070c20800000001001428a1450a000100042841010a000100001800c4020001000428a1050200010004106044040001001010418404",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11713087,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080e0820c00000001001428a1450a000100042860850a000100001800c006000100143020450c000100041080c1040001001010a10404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11725192,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001016050820000000001001428a1450a0001000820810408000100001800c006000100102821410a000100101820c4060001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2019_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11745530,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c0a0b0830000000001001428614502000100102081040a000100001800c0060001001030608504000100101880c102000100101020400c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11729671,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1927,
        opponentMmrAfter: 1923,
        opponentSlpWon: 0,
        playerMmrBefore: 2119,
        playerMmrAfter: 2123,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "25e5c702-67d7-4563-94b2-0f414882a6c4",
        gameTimestamp: "2023-05-29 09:10:45",
        startTimestamp: "2023-05-29 09:07:12",
        endTimestamp: "2023-05-29 09:10:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=25e5c702-67d7-4563-94b2-0f414882a6c4&userId=1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108082",
        oponentTeamId: "5107742",
        opponent: "1ec9eb73-b15b-6f76-a60c-f5d2e26a6aac",
        playerTeam: [
          {
            gene: "0x80000000000010001404090830c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751748,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003804020c30c00000001001428a1450a000100042821410a000100001800c4020001001430a0450c000100002880c402000100101041050c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11751728,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003808070c20800000001001428a1450a000100042841010a000100001800c4020001000428a1050200010004106044040001001010418404",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11713087,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101a02081000000000100041041020800010008084141080001001020404406000100042821010800010004102084020001001018818004",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11598669,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003800040820800000001000410a0850400010008084041080001001020810404000100103080410a00010008184101020001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11676724,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001006000a090821000000001000c086045020001000808404504000100102081010c000100042821430a0001000410208104000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731766,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1963,
        opponentMmrAfter: 1958,
        opponentSlpWon: 0,
        playerMmrBefore: 2114,
        playerMmrAfter: 2119,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f5605a65-f7bf-4d22-90a7-c56cdc917a8e",
        gameTimestamp: "2023-05-29 09:05:58",
        startTimestamp: "2023-05-29 09:02:13",
        endTimestamp: "2023-05-29 09:05:58",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f5605a65-f7bf-4d22-90a7-c56cdc917a8e&userId=1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108082",
        oponentTeamId: "5111487",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x80000000000010001404090830c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751748,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003804020c30c00000001001428a1450a000100042821410a000100001800c4020001001430a0450c000100002880c402000100101041050c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11751728,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003808070c20800000001001428a1450a000100042841010a000100001800c4020001000428a1050200010004106044040001001010418404",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11713087,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100014040e0c30c00000001001428a1450a0001000428a08004000100001800c0060001001430a1850c000100002880c00a000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751686,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2042,
        opponentMmrAfter: 2034,
        opponentSlpWon: 0,
        playerMmrBefore: 2106,
        playerMmrAfter: 2114,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b3137bc6-48a1-49b7-90f3-658fe8735dfb",
        gameTimestamp: "2023-05-29 08:43:23",
        startTimestamp: "2023-05-29 08:40:05",
        endTimestamp: "2023-05-29 08:43:23",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b3137bc6-48a1-49b7-90f3-658fe8735dfb&userId=1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108082",
        oponentTeamId: "5033213",
        opponent: "1ec9eb77-522f-6403-a60c-3d03fbfe2751",
        playerTeam: [
          {
            gene: "0x80000000000010001404090830c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751748,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003804020c30c00000001001428a1450a000100042821410a000100001800c4020001001430a0450c000100002880c402000100101041050c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11751728,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003808070c20800000001001428a1450a000100042841010a000100001800c4020001000428a1050200010004106044040001001010418404",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11713087,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000100a040c20c00000001001428a1450a000100042821410a00010000182041080001000428204408000100001001010c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2019_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2018_s4_nondec",
            },
            axie_id: 11711386,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002008090c30800000001001428a142040001000c10808108000100001800c0040001001430a1400c000100002880c0020001001010410404",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "",
            },
            axie_id: 11730710,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000181c031020800000001001428a1410a0001001028210402000100001800c0040001001018818002000100101880c404000100101080840c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2013_s4_nft",
            },
            axie_id: 11739007,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1866,
        opponentMmrAfter: 1862,
        opponentSlpWon: 0,
        playerMmrBefore: 2102,
        playerMmrAfter: 2106,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d42bb71f-a3c6-4796-8a28-b2bb877b757b",
        gameTimestamp: "2023-05-29 08:38:48",
        startTimestamp: "2023-05-29 08:32:50",
        endTimestamp: "2023-05-29 08:38:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d42bb71f-a3c6-4796-8a28-b2bb877b757b&userId=1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108082",
        oponentTeamId: "5108287",
        opponent: "1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        playerTeam: [
          {
            gene: "0x80000000000010001404090830c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751748,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003804020c30c00000001001428a1450a000100042821410a000100001800c4020001001430a0450c000100002880c402000100101041050c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11751728,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003808070c20800000001001428a1450a000100042841010a000100001800c4020001000428a1050200010004106044040001001010418404",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11713087,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2045,
        opponentMmrAfter: 2037,
        opponentSlpWon: 0,
        playerMmrBefore: 2094,
        playerMmrAfter: 2102,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:10.000Z",
  },
  {
    clientId: "1ec9eb7b-2999-60a3-a60c-6446ed901bb4",
    name: "DADDY BORJ BUFF",
    rank: "Challenger",
    tier: 0,
    topRank: 13,
    vstar: 2121,
    avatar: "easter_momo;0",
    battles: [
      {
        battleUuid: "8a7b6bde-c8a4-4dee-8027-703da6b5d0d0",
        gameTimestamp: "2023-05-29 05:00:19",
        startTimestamp: "2023-05-29 04:52:57",
        endTimestamp: "2023-05-29 05:00:19",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8a7b6bde-c8a4-4dee-8027-703da6b5d0d0&userId=1ec9eb7b-2999-60a3-a60c-6446ed901bb4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5029763",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x80000000000010001c120708204000000010014282084020001000428004302000100001800c00a000100143020810400010000282184040001001010618002",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726710,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a040830000000001001428a14408000100042860430a000100001800c204000100143041820a000100041020800a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748326,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0a050820800000001001428a1450a0001000820414408000100001800c30200010010308184060001001018a143020001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739910,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2045,
        opponentMmrAfter: 2057,
        opponentSlpWon: 24,
        playerMmrBefore: 2133,
        playerMmrAfter: 2121,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "6361b973-9c24-4e4b-99f2-ad104c97bf54",
        gameTimestamp: "2023-05-29 04:52:07",
        startTimestamp: "2023-05-29 04:47:08",
        endTimestamp: "2023-05-29 04:52:07",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6361b973-9c24-4e4b-99f2-ad104c97bf54&userId=1ec9eb7b-2999-60a3-a60c-6446ed901bb4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5029763",
        oponentTeamId: "5090015",
        opponent: "1ed82d7f-5283-61cd-9e0e-dc96e62a3697",
        playerTeam: [
          {
            gene: "0x80000000000010001c120708204000000010014282084020001000428004302000100001800c00a000100143020810400010000282184040001001010618002",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726710,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a040830000000001001428a14408000100042860430a000100001800c204000100143041820a000100041020800a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748326,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0a050820800000001001428a1450a0001000820414408000100001800c30200010010308184060001001018a143020001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739910,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002412060821000000001000028014304000100002800400a000100001800c50c0001000830414202000100083021020c0001000008004002",
            runes: ["rune_dusk_3011_s4_nft"],
            charms: {
              back: "ecard_bird_3013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "",
            },
            axie_id: 11677120,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001012091021000000001000820410102000100082841420a0001000818a14206000100083041420c000100083040420c0001000810408204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734242,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002c000c0820800000001000c100081040001000c08404204000100140800c5020001000c080042060001000c2061830c0001000c10610308",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 7967235,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1885,
        opponentMmrAfter: 1882,
        opponentSlpWon: 0,
        playerMmrBefore: 2130,
        playerMmrAfter: 2133,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9defdcd4-6696-459b-a872-1354081a0850",
        gameTimestamp: "2023-05-29 04:46:06",
        startTimestamp: "2023-05-29 04:40:38",
        endTimestamp: "2023-05-29 04:46:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9defdcd4-6696-459b-a872-1354081a0850&userId=1ec9eb7b-2999-60a3-a60c-6446ed901bb4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5029763",
        oponentTeamId: "5093305",
        opponent: "1ec9eb73-58cb-6f5b-a60c-740b21294d64",
        playerTeam: [
          {
            gene: "0x80000000000010001c120708204000000010014282084020001000428004302000100001800c00a000100143020810400010000282184040001001010618002",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726710,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a040830000000001001428a14408000100042860430a000100001800c204000100143041820a000100041020800a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748326,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0a050820800000001001428a1450a0001000820414408000100001800c30200010010308184060001001018a143020001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739910,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8800000000000100008140b1041000000001001028814404000100002801050a000100001880400c0001001010a0c40c00010010080044020001000008414502",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11711485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000000600082080000000100101080840400010010288140020001000410208302000100042020810800010004302185040001000c2820c106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11747831,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180a060840800000001000408a081040001000428414102000100001800c0020001001430a10106000100140800800c0001000408010502",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_neutral_1001",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11712733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2138,
        opponentMmrAfter: 2128,
        opponentSlpWon: 0,
        playerMmrBefore: 2120,
        playerMmrAfter: 2130,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "317d777b-7870-4bd9-a60b-9a68e8d3e455",
        gameTimestamp: "2023-05-29 04:39:52",
        startTimestamp: "2023-05-29 04:32:53",
        endTimestamp: "2023-05-29 04:39:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=317d777b-7870-4bd9-a60b-9a68e8d3e455&userId=1ec9eb7b-2999-60a3-a60c-6446ed901bb4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5029763",
        oponentTeamId: "5101964",
        opponent: "1ecfa5ef-322f-62a2-9f8b-8e62f0db1235",
        playerTeam: [
          {
            gene: "0x80000000000010001c120708204000000010014282084020001000428004302000100001800c00a000100143020810400010000282184040001001010618002",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726710,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a040830000000001001428a14408000100042860430a000100001800c204000100143041820a000100041020800a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748326,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0a050820800000001001428a1450a0001000820414408000100001800c30200010010308184060001001018a143020001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739910,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100008040710004000000010004102083020001000808a082020001001020810408000100042821440c000100081840c104000100101880c406",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100000180e08208000000010004102080040001000808404008000100102081010c000100042881840c000100081840c1020001000008a10208",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2011_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745076,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e1041000000001000c0860420200010008084040020001001020a08002000100042881410a0001000410208408000100101880c504",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_2017_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11748465,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1899,
        opponentMmrAfter: 1895,
        opponentSlpWon: 0,
        playerMmrBefore: 2116,
        playerMmrAfter: 2120,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "cda1cff2-253e-4b75-9d57-8e96261cb5c8",
        gameTimestamp: "2023-05-29 04:26:39",
        startTimestamp: "2023-05-29 04:20:41",
        endTimestamp: "2023-05-29 04:26:39",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cda1cff2-253e-4b75-9d57-8e96261cb5c8&userId=1ec9eb7b-2999-60a3-a60c-6446ed901bb4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5029763",
        oponentTeamId: "5068030",
        opponent: "1ec9eb7b-094e-6508-a60c-4cc3120d8c16",
        playerTeam: [
          {
            gene: "0x80000000000010001c120708204000000010014282084020001000428004302000100001800c00a000100143020810400010000282184040001001010618002",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726710,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a040830000000001001428a14408000100042860430a000100001800c204000100143041820a000100041020800a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748326,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0a050820800000001001428a1450a0001000820414408000100001800c30200010010308184060001001018a143020001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739910,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0e060820c00000001001428a1450a000100042800430800010000180180060001001430814508000100041080c4060001001010218404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11724977,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060c2080000000100142840450a00010008202141080001000018414206000100143021440c00010004102084040001001010808404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11724528,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c06020800800000001001428a14504000100102881000a000100001840840c0001001030a18402000100101880c20a0001001010408202",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11746769,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2036,
        opponentMmrAfter: 2029,
        opponentSlpWon: 0,
        playerMmrBefore: 2109,
        playerMmrAfter: 2116,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:10.000Z",
  },
  {
    clientId: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
    name: "Profe Sergio TQ / SURA",
    rank: "Challenger",
    tier: 0,
    topRank: 14,
    vstar: 2116,
    avatar: "good_boy;triumphant_paws",
    battles: [
      {
        battleUuid: "9b1bc547-a9b8-4904-aea7-923f567758ef",
        gameTimestamp: "2023-05-29 13:35:10",
        startTimestamp: "2023-05-29 13:30:34",
        endTimestamp: "2023-05-29 13:35:10",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9b1bc547-a9b8-4904-aea7-923f567758ef&userId=1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022359",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2074,
        opponentMmrAfter: 2085,
        opponentSlpWon: 0,
        playerMmrBefore: 2116,
        playerMmrAfter: 2105,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "5ba8647b-554f-4fce-a984-ef10b29db0df",
        gameTimestamp: "2023-05-29 13:30:01",
        startTimestamp: "2023-05-29 13:29:53",
        endTimestamp: "2023-05-29 13:30:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5ba8647b-554f-4fce-a984-ef10b29db0df&userId=1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5022359",
        oponentTeamId: "5112052",
        opponent: "1ed5a8dd-b672-63e6-8b70-bd62a6c5da7c",
        playerTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001004040a02100000000000100142881450a0001000428404004000100001800c40c000100143041450c0001001018a085080001001010608404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749038,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0e070800800000001001428a142080001001028608102000100001800c00c0001001030a0410a00010010184081020001001010808404",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11738779,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100038080b0c20000000001001428a14308000100102881440800010000188100020001001030a1820a00010010186042040001001010808102",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11740040,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1862,
        opponentMmrAfter: 1859,
        opponentSlpWon: 0,
        playerMmrBefore: 2113,
        playerMmrAfter: 2116,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0bda2490-be24-412e-bf01-b6f6595922a5",
        gameTimestamp: "2023-05-29 13:28:42",
        startTimestamp: "2023-05-29 13:21:59",
        endTimestamp: "2023-05-29 13:28:42",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0bda2490-be24-412e-bf01-b6f6595922a5&userId=1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022359",
        oponentTeamId: "5022556",
        opponent: "1ed48b15-7db4-6151-af17-2542f0edf74b",
        playerTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2099,
        opponentMmrAfter: 2109,
        opponentSlpWon: 0,
        playerMmrBefore: 2123,
        playerMmrAfter: 2113,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "f0d6b19e-18e0-4e80-988b-b8bbacab3a87",
        gameTimestamp: "2023-05-29 13:21:28",
        startTimestamp: "2023-05-29 13:16:00",
        endTimestamp: "2023-05-29 13:21:28",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f0d6b19e-18e0-4e80-988b-b8bbacab3a87&userId=1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022359",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2062,
        opponentMmrAfter: 2074,
        opponentSlpWon: 0,
        playerMmrBefore: 2135,
        playerMmrAfter: 2123,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "711613e6-1f84-49bd-8775-adeeab7fd2cf",
        gameTimestamp: "2023-05-29 13:15:15",
        startTimestamp: "2023-05-29 13:11:30",
        endTimestamp: "2023-05-29 13:15:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=711613e6-1f84-49bd-8775-adeeab7fd2cf&userId=1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5022359",
        oponentTeamId: "5109049",
        opponent: "1ec9eb7e-3639-6f34-a60c-d9bc5cb95d4b",
        playerTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010003000051040800000001000c086043040001000c086043020001000c086083020001000c086043020001000c306182080001000c1060830c",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11615574,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c10021021000000001000c2821030a0001000c086043080001000c086043080001000c08a0c20c00010010088041060001000c0800c102",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_2013_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11734503,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010000f00020800c00000001000c0861040800010010206045080001000c0860440c0001000c0881830600010004182081060001001030814506",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_2017_s4_nft",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11730066,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1899,
        opponentMmrAfter: 1895,
        opponentSlpWon: 0,
        playerMmrBefore: 2131,
        playerMmrAfter: 2135,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:11.000Z",
  },
  {
    clientId: "1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
    name: "sushi1| sushiclub| PZL",
    rank: "Challenger",
    tier: 0,
    topRank: 15,
    vstar: 2115,
    avatar: "sleepy_beast;gold_s3",
    battles: [
      {
        battleUuid: "dfc3191f-48db-47c4-a828-a48cfeff99b9",
        gameTimestamp: "2023-05-29 09:03:46",
        startTimestamp: "2023-05-29 08:58:57",
        endTimestamp: "2023-05-29 09:03:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=dfc3191f-48db-47c4-a828-a48cfeff99b9&userId=1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102440",
        oponentTeamId: "5022556",
        opponent: "1ed48b15-7db4-6151-af17-2542f0edf74b",
        playerTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002404050820800000001001428a14102000100042821420a000100001800c10a0001000010a185020001000010014406000100101020c404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11745098,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2117,
        opponentMmrAfter: 2107,
        opponentSlpWon: 0,
        playerMmrBefore: 2105,
        playerMmrAfter: 2115,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "910596d3-a2b2-408e-ab1b-68ac2629d80f",
        gameTimestamp: "2023-05-29 03:32:20",
        startTimestamp: "2023-05-29 03:28:21",
        endTimestamp: "2023-05-29 03:32:20",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=910596d3-a2b2-408e-ab1b-68ac2629d80f&userId=1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102440",
        oponentTeamId: "5087028",
        opponent: "1ed34028-6bd3-6a1c-967d-208f0dcba826",
        playerTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001008050c30000000001001428a14308000100042880410a000100001800c0060001001430a1850c000100041020800a0001001010808002",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742709,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003804080c30c00000001001428a1450a000100042041410a000100001800c1080001001430a18306000100002841800a000100101041040c",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11715448,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000181a000c308000000010014288043080001001028008402000100001800c3080001001030408402000100041080c4060001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747505,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1980,
        opponentMmrAfter: 1974,
        opponentSlpWon: 0,
        playerMmrBefore: 2099,
        playerMmrAfter: 2105,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d190e866-0619-4194-9701-1be082fc2722",
        gameTimestamp: "2023-05-29 03:27:21",
        startTimestamp: "2023-05-29 03:20:57",
        endTimestamp: "2023-05-29 03:27:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d190e866-0619-4194-9701-1be082fc2722&userId=1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102440",
        oponentTeamId: "5108103",
        opponent: "1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        playerTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2173,
        opponentMmrAfter: 2161,
        opponentSlpWon: 0,
        playerMmrBefore: 2087,
        playerMmrAfter: 2099,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "00965a7a-2bae-45ad-b7e0-9b3086538354",
        gameTimestamp: "2023-05-29 03:20:26",
        startTimestamp: "2023-05-29 03:14:33",
        endTimestamp: "2023-05-29 03:20:26",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=00965a7a-2bae-45ad-b7e0-9b3086538354&userId=1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102440",
        oponentTeamId: "5042811",
        opponent: "1ec9eb7b-2999-6860-a60c-8796c3d23b5d",
        playerTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100028160a0400c0000000100142820450a0001000428604402000100001840410c000100143040c00c000100002880c0060001001010608006",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11720084,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000241a090820c0000000100142800850a000100001001450a000100001880c002000100001001420a0001000008a102020001000008004002",
            runes: ["rune_beast_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11725899,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000101a0d0840800000001001428a14208000100102881040a00010000184181020001000c2820420a000100101821400a0001001010808102",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11720062,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2025,
        opponentMmrAfter: 2037,
        opponentSlpWon: 24,
        playerMmrBefore: 2099,
        playerMmrAfter: 2087,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "501560d0-b87d-4982-ba1f-e6ce57a836ac",
        gameTimestamp: "2023-05-29 03:13:52",
        startTimestamp: "2023-05-29 03:07:33",
        endTimestamp: "2023-05-29 03:13:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=501560d0-b87d-4982-ba1f-e6ce57a836ac&userId=1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102440",
        oponentTeamId: "5105972",
        opponent: "1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        playerTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2129,
        opponentMmrAfter: 2118,
        opponentSlpWon: 0,
        playerMmrBefore: 2088,
        playerMmrAfter: 2099,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:12.000Z",
  },
  {
    clientId: "1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
    name: "turr0 | Huru",
    rank: "Challenger",
    tier: 0,
    topRank: 16,
    vstar: 2112,
    avatar: "s3_top1k;silver_s3",
    battles: [
      {
        battleUuid: "d6feb0de-8003-4c14-b844-475e81f6277d",
        gameTimestamp: "2023-05-29 03:59:50",
        startTimestamp: "2023-05-29 03:54:55",
        endTimestamp: "2023-05-29 03:59:50",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d6feb0de-8003-4c14-b844-475e81f6277d&userId=1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105972",
        oponentTeamId: "5102140",
        opponent: "1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        playerTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2126,
        opponentMmrAfter: 2116,
        opponentSlpWon: 0,
        playerMmrBefore: 2102,
        playerMmrAfter: 2112,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6a9cb50c-7ced-4eac-bc99-77a9cfa3dc91",
        gameTimestamp: "2023-05-29 03:54:10",
        startTimestamp: "2023-05-29 03:46:47",
        endTimestamp: "2023-05-29 03:54:10",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6a9cb50c-7ced-4eac-bc99-77a9cfa3dc91&userId=1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105972",
        oponentTeamId: "5065158",
        opponent: "1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        playerTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2022,
        opponentMmrAfter: 2034,
        opponentSlpWon: 24,
        playerMmrBefore: 2114,
        playerMmrAfter: 2102,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "c63101d5-bd9b-4034-8bcf-47d6e9c0700f",
        gameTimestamp: "2023-05-29 03:46:14",
        startTimestamp: "2023-05-29 03:41:44",
        endTimestamp: "2023-05-29 03:46:14",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c63101d5-bd9b-4034-8bcf-47d6e9c0700f&userId=1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105972",
        oponentTeamId: "5105505",
        opponent: "1ec9eb6f-586c-66b1-a60c-efefc0356bd0",
        playerTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010003004050c30c00000001000c206143020001000c106083040001000c28614008000100043080430a0001000c182101080001000c3061830c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9972478,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000130007080100000000100001000810200010000282145020001000018a0420800010000182140060001001420a0850c0001000408004102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11684341,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e010821000000001000010008004000100002001020a0001000030018302000100000881800200010000080040020001001418a0c006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11620192,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1851,
        opponentMmrAfter: 1848,
        opponentSlpWon: 0,
        playerMmrBefore: 2111,
        playerMmrAfter: 2114,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "582deb89-1dcc-4042-8e70-9440b61a5dd9",
        gameTimestamp: "2023-05-29 03:39:38",
        startTimestamp: "2023-05-29 03:34:40",
        endTimestamp: "2023-05-29 03:39:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=582deb89-1dcc-4042-8e70-9440b61a5dd9&userId=1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5105972",
        oponentTeamId: "5081716",
        opponent: "1ec9eb6f-41d2-641b-a60c-18eccea4e78c",
        playerTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c08070c30c0000000100041020850200010008080042020001001020810408000100042841800400010004102101040001001018010304",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11730619,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038080010210000000010004102085040001000808404108000100102040c4080001000428214402000100081840c2060001000008818406",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11715027,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010003b000e0821000000001000c084141040001000808a1020200010010208100020001000428414206000100081840c104000100101880c404",
            runes: ["rune_reptile_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2012_s4_nft",
            },
            axie_id: 11718891,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1919,
        opponentMmrAfter: 1914,
        opponentSlpWon: 0,
        playerMmrBefore: 2106,
        playerMmrAfter: 2111,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0d126dbb-7678-4cda-a481-67a4b500eb58",
        gameTimestamp: "2023-05-29 03:33:44",
        startTimestamp: "2023-05-29 03:28:40",
        endTimestamp: "2023-05-29 03:33:44",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0d126dbb-7678-4cda-a481-67a4b500eb58&userId=1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105972",
        oponentTeamId: "5108103",
        opponent: "1ed366a3-5779-612d-b6a0-ef2e8e1155b5",
        playerTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010040406040c20c0000000100142840450a0001000428204502000100001800c2020001001430a1850400010010082144060001001010808202",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749303,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0c30c0000000100142840850a000100042821420a000100001800c0060001001430a1820200010000280144060001001010408302",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751891,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142841450a000100042801410200010000186180060001001008a1850c00010000280140080001001010a04404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11742570,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2161,
        opponentMmrAfter: 2169,
        opponentSlpWon: 24,
        playerMmrBefore: 2114,
        playerMmrAfter: 2106,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:13.000Z",
  },
  {
    clientId: "1ed48b15-7db4-6151-af17-2542f0edf74b",
    name: "MVP | imcute | Axie.gg",
    rank: "Challenger",
    tier: 0,
    topRank: 17,
    vstar: 2109,
    avatar: "0;0",
    battles: [
      {
        battleUuid: "0bda2490-be24-412e-bf01-b6f6595922a5",
        gameTimestamp: "2023-05-29 13:28:42",
        startTimestamp: "2023-05-29 13:21:59",
        endTimestamp: "2023-05-29 13:28:42",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0bda2490-be24-412e-bf01-b6f6595922a5&userId=1ed48b15-7db4-6151-af17-2542f0edf74b",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022556",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2123,
        opponentMmrAfter: 2113,
        opponentSlpWon: 0,
        playerMmrBefore: 2099,
        playerMmrAfter: 2109,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "34e06f01-e073-4a1c-9da6-659093b1337c",
        gameTimestamp: "2023-05-29 13:20:56",
        startTimestamp: "2023-05-29 13:16:10",
        endTimestamp: "2023-05-29 13:20:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=34e06f01-e073-4a1c-9da6-659093b1337c&userId=1ed48b15-7db4-6151-af17-2542f0edf74b",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022556",
        oponentTeamId: "5090650",
        opponent: "1ec9eb77-511b-64e6-a60c-2bc1f8ec5d6f",
        playerTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140c050820800000001000c286083020001000c106083020001000c088043060001000c086144020001000c302043080001000c1061000a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11603356,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000038008308000000010010102144040001000c082140020001000c0840810c0001000c08604406000100041820c304000100000820c106",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "",
              mouth: "",
            },
            axie_id: 11741192,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c0a021020800000001000c282085080001000c10a083040001000c0860430a0001000c0841020800010004184104040001001030a0c008",
            runes: ["rune_beast_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "",
            },
            axie_id: 11753281,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2015,
        opponentMmrAfter: 2008,
        opponentSlpWon: 0,
        playerMmrBefore: 2092,
        playerMmrAfter: 2099,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8a4d6f9b-3af3-4009-99d9-98241a25f526",
        gameTimestamp: "2023-05-29 13:15:32",
        startTimestamp: "2023-05-29 13:11:51",
        endTimestamp: "2023-05-29 13:15:32",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8a4d6f9b-3af3-4009-99d9-98241a25f526&userId=1ed48b15-7db4-6151-af17-2542f0edf74b",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022556",
        oponentTeamId: "5087655",
        opponent: "1ec9eb6f-666c-61c0-a60c-c82fd8ed87bf",
        playerTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a050830c00000001001428a1450a000100042821420a000100001800c0060001001430a1820200010000288084060001001010408404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11743640,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001016050800800000001001428a140080001000820a1450a000100001800c102000100103001400a000100101880c3060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737093,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c050820800000001001428a142020001000820008108000100001800c3060001001008a1850c000100101860c1040001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11742223,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1999,
        opponentMmrAfter: 1992,
        opponentSlpWon: 0,
        playerMmrBefore: 2085,
        playerMmrAfter: 2092,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7e66c01f-c0fe-4470-a6ed-8464aee8e001",
        gameTimestamp: "2023-05-29 13:11:14",
        startTimestamp: "2023-05-29 13:04:14",
        endTimestamp: "2023-05-29 13:11:14",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7e66c01f-c0fe-4470-a6ed-8464aee8e001&userId=1ed48b15-7db4-6151-af17-2542f0edf74b",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022556",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2068,
        opponentMmrAfter: 2059,
        opponentSlpWon: 0,
        playerMmrBefore: 2076,
        playerMmrAfter: 2085,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f8c050e3-0213-4add-b565-693bcceace86",
        gameTimestamp: "2023-05-29 12:57:55",
        startTimestamp: "2023-05-29 12:52:48",
        endTimestamp: "2023-05-29 12:57:55",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f8c050e3-0213-4add-b565-693bcceace86&userId=1ed48b15-7db4-6151-af17-2542f0edf74b",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5022556",
        oponentTeamId: "5101048",
        opponent: "1eca7faf-813a-6bd2-9fe9-581dcef10f70",
        playerTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001410021020800000001000c200080040001000028004002000100001880c5020001000010418004000100000840c3060001000008004204",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11518730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008040b0801000000001000010a140040001000020004008000100003001800c00010000080040020001000008004002000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11591470,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000180e061031000000001000c086043040001000c088083020001000c2061830a0001000c2860c4080001000c3020430c0001000c10610102",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 416844,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2025,
        opponentMmrAfter: 2017,
        opponentSlpWon: 0,
        playerMmrBefore: 2068,
        playerMmrAfter: 2076,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:14.000Z",
  },
  {
    clientId: "1ecb1b4a-f534-6092-9fc2-7cb72129a807",
    name: "Kaneyan | sushiclub | PZL",
    rank: "Challenger",
    tier: 0,
    topRank: 18,
    vstar: 2106,
    avatar: "8;gold_s1",
    battles: [
      {
        battleUuid: "1be0a13d-97a1-4c72-b34e-1eface268883",
        gameTimestamp: "2023-05-29 13:34:00",
        startTimestamp: "2023-05-29 13:26:10",
        endTimestamp: "2023-05-29 13:34:00",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1be0a13d-97a1-4c72-b34e-1eface268883&userId=1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5109165",
        oponentTeamId: "5101852",
        opponent: "1ed8ce2b-72be-6d9f-a991-017171f15bfd",
        playerTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11742247,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0820000000001001428a140040001000428804004000100001800c4060001000c2841450c000100043080c4080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11748753,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001004020430c00000001001428a143040001000428810108000100001800c006000100143021050c000100002860c00a0001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11748867,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c06051000c00000001001428a14404000100102081040a000100001800c402000100103020c406000100101880c50c0001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "",
            },
            axie_id: 11716164,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000180805082080000000100142840450a0001000c08210102000100001800c306000100042821410a000100041020830c0001001010808108",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11710145,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e839fa2f-0aef-4a49-8c4e-0358d366233d",
        gameTimestamp: "2023-05-29 13:04:23",
        startTimestamp: "2023-05-29 12:57:31",
        endTimestamp: "2023-05-29 13:04:23",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e839fa2f-0aef-4a49-8c4e-0358d366233d&userId=1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5109165",
        oponentTeamId: "5112315",
        opponent: "1edf3f57-f579-6b2b-b339-d42199c0bc26",
        playerTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11742247,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0820000000001001428a140040001000428804004000100001800c4060001000c2841450c000100043080c4080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11748753,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001410050830800000001001428a1450a0001000428214102000100001800c006000100143000850c000100002820c00a0001001010608404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751759,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c30c00000001001428a10408000100042821410a000100001800c0060001001430a1850c000100002880c50c0001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751725,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c040020800000001001428a14508000100082020840a000100001800c00600010010308045060001001018204406000100101080850c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713840,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4b847107-b2c3-4b9e-9239-19747af24ebe",
        gameTimestamp: "2023-05-29 12:55:50",
        startTimestamp: "2023-05-29 12:49:04",
        endTimestamp: "2023-05-29 12:55:50",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4b847107-b2c3-4b9e-9239-19747af24ebe&userId=1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5109165",
        oponentTeamId: "5112315",
        opponent: "1edf3f57-f579-6b2b-b339-d42199c0bc26",
        playerTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11742247,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0820000000001001428a140040001000428804004000100001800c4060001000c2841450c000100043080c4080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11748753,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001410050830800000001001428a1450a0001000428214102000100001800c006000100143000850c000100002820c00a0001001010608404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751759,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c30c00000001001428a10408000100042821410a000100001800c0060001001430a1850c000100002880c50c0001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751725,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c040020800000001001428a14508000100082020840a000100001800c00600010010308045060001001018204406000100101080850c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713840,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5b8160c0-de1a-4b11-9517-73655761da74",
        gameTimestamp: "2023-05-29 12:47:04",
        startTimestamp: "2023-05-29 12:39:39",
        endTimestamp: "2023-05-29 12:47:04",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5b8160c0-de1a-4b11-9517-73655761da74&userId=1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5109165",
        oponentTeamId: "5112315",
        opponent: "1edf3f57-f579-6b2b-b339-d42199c0bc26",
        playerTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11742247,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0820000000001001428a140040001000428804004000100001800c4060001000c2841450c000100043080c4080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11748753,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001410050830800000001001428a1450a0001000428214102000100001800c006000100143000850c000100002820c00a0001001010608404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751759,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c30c00000001001428a10408000100042821410a000100001800c0060001001430a1850c000100002880c50c0001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751725,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c040020800000001001428a14508000100082020840a000100001800c00600010010308045060001001018204406000100101080850c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713840,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8c9c42a6-cb39-4a6a-b034-cf5aa24cda06",
        gameTimestamp: "2023-05-29 12:33:15",
        startTimestamp: "2023-05-29 12:26:56",
        endTimestamp: "2023-05-29 12:33:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8c9c42a6-cb39-4a6a-b034-cf5aa24cda06&userId=1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104924",
        oponentTeamId: "5060908",
        opponent: "1ec9eb6f-84c9-65d0-a60c-cd60c9040f95",
        playerTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11742247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c08060820800000001001428a1450a0001000428610002000100001800c0060001000818a18502000100041040c2060001001010808508",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_3011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11738827,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000101c0c0c41000000001000c106043040001000c106083040001000c306180020001000c2821830a000100042860c3060001001408610108",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9830390,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020e0c2100000000100001001400400010000100140080001000018408204000100002000400c0001000008810002000100000861040a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2012_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11638704,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1209102080000000100000800400a00010000080040020001000010004004000100001000800a000100101800c002000100002801400a",
            runes: ["rune_beast_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 330732,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:14.000Z",
  },
  {
    clientId: "1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
    name: "euri",
    rank: "Challenger",
    tier: 0,
    topRank: 19,
    vstar: 2105,
    avatar: "s3_top20k;0",
    battles: [
      {
        battleUuid: "1d567ad4-9555-4abf-afb2-7f5fd85c894e",
        gameTimestamp: "2023-05-29 11:51:22",
        startTimestamp: "2023-05-29 11:47:29",
        endTimestamp: "2023-05-29 11:51:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1d567ad4-9555-4abf-afb2-7f5fd85c894e&userId=1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102140",
        oponentTeamId: "5085206",
        opponent: "1ed34028-6bd3-6a1c-967d-208f0dcba826",
        playerTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000180a060820800000001000c0860820800010000100081080001000c1081830c0001000c086083060001000430a143080001000c10218508",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11249737,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000240c050820c000000010004108100040001000028214002000100001800c1060001000010414106000100083061820c0001000008414308",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11647539,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120408208000000010008204102080001000828a1020a000100081840c402000100083041820c000100083041820c0001000810408208",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11693448,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e6d6f3f8-556b-4e91-84fc-d83fcc56000d",
        gameTimestamp: "2023-05-29 11:29:26",
        startTimestamp: "2023-05-29 11:25:35",
        endTimestamp: "2023-05-29 11:29:26",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e6d6f3f8-556b-4e91-84fc-d83fcc56000d&userId=1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102140",
        oponentTeamId: "5095381",
        opponent: "1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        playerTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2095,
        opponentMmrAfter: 2086,
        opponentSlpWon: 0,
        playerMmrBefore: 2096,
        playerMmrAfter: 2105,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5ad46909-152b-4214-8c7d-a5aa83bc01b8",
        gameTimestamp: "2023-05-29 11:24:29",
        startTimestamp: "2023-05-29 11:20:19",
        endTimestamp: "2023-05-29 11:24:29",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5ad46909-152b-4214-8c7d-a5aa83bc01b8&userId=1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102140",
        oponentTeamId: "5109165",
        opponent: "1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        playerTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11742247,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0820000000001001428a140040001000428804004000100001800c4060001000c2841450c000100043080c4080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11748753,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2109,
        opponentMmrAfter: 2118,
        opponentSlpWon: 24,
        playerMmrBefore: 2105,
        playerMmrAfter: 2096,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "90ae03f5-1281-45de-8c8b-d309eb7642c8",
        gameTimestamp: "2023-05-29 11:19:30",
        startTimestamp: "2023-05-29 11:14:32",
        endTimestamp: "2023-05-29 11:19:30",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=90ae03f5-1281-45de-8c8b-d309eb7642c8&userId=1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102140",
        oponentTeamId: "5101048",
        opponent: "1eca7faf-813a-6bd2-9fe9-581dcef10f70",
        playerTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001410021020800000001000c200080040001000028004002000100001880c5020001000010418004000100000840c3060001000008004204",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11518730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008040b0801000000001000010a140040001000020004008000100003001800c00010000080040020001000008004002000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11591470,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000180e061031000000001000c086043040001000c088083020001000c2061830a0001000c2860c4080001000c3020430c0001000c10610102",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 416844,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1985,
        opponentMmrAfter: 1998,
        opponentSlpWon: 22,
        playerMmrBefore: 2118,
        playerMmrAfter: 2105,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "3c6e904a-0fe6-42ce-b302-bcca70ac1c4b",
        gameTimestamp: "2023-05-29 11:13:30",
        startTimestamp: "2023-05-29 11:10:04",
        endTimestamp: "2023-05-29 11:13:30",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3c6e904a-0fe6-42ce-b302-bcca70ac1c4b&userId=1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5102140",
        oponentTeamId: "5088890",
        opponent: "1ec9eb6f-6fba-6db4-a60c-3abb34d661c7",
        playerTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002408091010c0000000100101080440200010004288145080001001030018402000100102860840a00010010186080080001001010804504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_aquatic_2013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 1468352,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000381c07102100000000100001000800a00010000100140040001000018008006000100002800430800010000100084080001000008004002",
            runes: ["rune_beast_2011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 11551260,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0508010000000010000100080040001000020004302000100003081800c00010000080041060001000008a180020001001418a0c506",
            runes: ["rune_beast_2010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11556308,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1872,
        opponentMmrAfter: 1869,
        opponentSlpWon: 0,
        playerMmrBefore: 2115,
        playerMmrAfter: 2118,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:15.000Z",
  },
  {
    clientId: "1ec9eb77-78ce-6106-a60c-446d97702d21",
    name: "Zeliaser | YGG | 0TZ",
    rank: "Challenger",
    tier: 0,
    topRank: 20,
    vstar: 2103,
    avatar: "easter_eggs;gold_06_s2",
    battles: [
      {
        battleUuid: "81a79775-ca77-40e1-885e-a4e8674d76a9",
        gameTimestamp: "2023-05-29 08:53:12",
        startTimestamp: "2023-05-29 08:49:47",
        endTimestamp: "2023-05-29 08:53:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=81a79775-ca77-40e1-885e-a4e8674d76a9&userId=1ec9eb77-78ce-6106-a60c-446d97702d21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5098929",
        oponentTeamId: "5026641",
        opponent: "1ec9eb73-b491-656d-a60c-2ebb6144527e",
        playerTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412040820800000001001428a1450a0001000428204102000100001800c006000100041041010a00010004084184040001001010a10508",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e000c2100000000100142801450a0001000820410402000100001800c00a0001001030a1440c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11749565,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002404050c20400000001001428408504000100082020840800010000184180060001000828a18402000100101041800a0001001010814008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11728770,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2026,
        opponentMmrAfter: 2018,
        opponentSlpWon: 0,
        playerMmrBefore: 2095,
        playerMmrAfter: 2103,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "71dc995c-4375-4360-af64-504246d848bd",
        gameTimestamp: "2023-05-29 08:47:48",
        startTimestamp: "2023-05-29 08:42:15",
        endTimestamp: "2023-05-29 08:47:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=71dc995c-4375-4360-af64-504246d848bd&userId=1ec9eb77-78ce-6106-a60c-446d97702d21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5098929",
        oponentTeamId: "5091036",
        opponent: "1ec9eb73-5d3a-6a8a-a60c-6d41702e9a80",
        playerTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c16040820800000001001428a1450a000100042860850a0001000018214006000100143021440c000100041000c1040001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11738617,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240a05083100000000100142820850a000100042881010a000100001800c006000100143020800600010000180141040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721724,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000141a040820c0000000100142861050a00010014280082080001000018018006000100103081830400010010180184060001001010204404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11740692,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2004,
        opponentMmrAfter: 1997,
        opponentSlpWon: 0,
        playerMmrBefore: 2088,
        playerMmrAfter: 2095,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e55c70b0-82e6-494b-9a8f-90c9f3227fae",
        gameTimestamp: "2023-05-29 08:41:30",
        startTimestamp: "2023-05-29 08:36:01",
        endTimestamp: "2023-05-29 08:41:30",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e55c70b0-82e6-494b-9a8f-90c9f3227fae&userId=1ec9eb77-78ce-6106-a60c-446d97702d21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5098929",
        oponentTeamId: "5108873",
        opponent: "1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        playerTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2068,
        opponentMmrAfter: 2059,
        opponentSlpWon: 0,
        playerMmrBefore: 2079,
        playerMmrAfter: 2088,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "162f7446-a515-4179-b631-003ac477a230",
        gameTimestamp: "2023-05-29 08:35:38",
        startTimestamp: "2023-05-29 08:31:45",
        endTimestamp: "2023-05-29 08:35:38",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=162f7446-a515-4179-b631-003ac477a230&userId=1ec9eb77-78ce-6106-a60c-446d97702d21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5098929",
        oponentTeamId: "5110148",
        opponent: "1ec9eb7e-599b-6d71-a60c-0578e6a367f0",
        playerTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a040820c00000001001428a1450a000100042821010a000100001800c004000100143060450800010000286085080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749945,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001416050c30c00000001001428a1450a0001000428a14004000100001800c0060001001430a18302000100041020800a0001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11726505,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c040b0820c00000001001428a1450a000100002041430a000100001800c1080001001008a18008000100101880c0080001001010418408",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "",
              mouth: "ecard_beast_2013_s4_nft",
            },
            axie_id: 11709216,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1941,
        opponentMmrAfter: 1955,
        opponentSlpWon: 0,
        playerMmrBefore: 2093,
        playerMmrAfter: 2079,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "948fe6d6-048a-4032-8b1b-65b2fdebbcc3",
        gameTimestamp: "2023-05-29 08:31:16",
        startTimestamp: "2023-05-29 08:28:31",
        endTimestamp: "2023-05-29 08:31:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=948fe6d6-048a-4032-8b1b-65b2fdebbcc3&userId=1ec9eb77-78ce-6106-a60c-446d97702d21",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5098929",
        oponentTeamId: "5089257",
        opponent: "1ec9eb6f-7d40-6db1-a60c-5218dd0e7d55",
        playerTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001412041040c000000010000100144080001000028a0420a000100001800c0060001000830814208000100000820c1020001000820004406",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11618751,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120c1040800000001000820410208000100082841420a000100081840c206000100083000420c000100083041020c000100081040820a",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11716617,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000000c0c41000000001000c082040080001000c086043020001000408a1040c0001000c0860850c0001000c18a042060001000c10610006",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 7622947,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1873,
        opponentMmrAfter: 1869,
        opponentSlpWon: 0,
        playerMmrBefore: 2089,
        playerMmrAfter: 2093,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:16.000Z",
  },
  {
    clientId: "1ec9eb7e-38d5-6960-a60c-761f09258599",
    name: "the wise",
    rank: "Challenger",
    tier: 0,
    topRank: 21,
    vstar: 2097,
    avatar: "patrick23_beer;bronze_s3",
    battles: [
      {
        battleUuid: "8f8890e2-f605-49ff-8996-b523a2820526",
        gameTimestamp: "2023-05-28 23:25:27",
        startTimestamp: "2023-05-28 23:15:45",
        endTimestamp: "2023-05-28 23:25:27",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8f8890e2-f605-49ff-8996-b523a2820526&userId=1ec9eb7e-38d5-6960-a60c-761f09258599",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103854",
        oponentTeamId: "5097128",
        opponent: "1ecf7ebf-49c4-65c0-bcd5-eaa52ea50ff3",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040820c00000001001428a1450a0001000428a14302000100001800c006000100143020450c00010000282085080001001010808404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11747097,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0c050041000000001001428a1450a00010014286084020001000018818006000100103080850a00010010180144060001001010618404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11746066,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e070830800000001001428a145080001001028810408000100001800c30c00010010288185080001000008418002000100101080820c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750065,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000000c021041000000001000c0800810a00010010200043020001000c086043020001000c086043060001000c18a0430c0001000c1060430c",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2012_s4_nft",
              horn: "ecard_plant_2013_s4_nft",
              tail: "ecard_plant_2011_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11716865,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000141809084100000000100042881430a0001000c2080410a0001000c0801440c0001000c088180060001000c106083080001000c08610304",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2013_s4_nft",
              mouth: "ecard_neutral_2018_s4_nft",
            },
            axie_id: 92801,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030000d102000000000100001020830200010010208103040001000c08a144080001000c0860430400010004106185020001001030808004",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11738150,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1870,
        opponentMmrAfter: 1886,
        opponentSlpWon: 22,
        playerMmrBefore: 2113,
        playerMmrAfter: 2097,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "3e413288-7289-46f8-82b8-1af63eebe170",
        gameTimestamp: "2023-05-28 23:14:46",
        startTimestamp: "2023-05-28 23:07:20",
        endTimestamp: "2023-05-28 23:14:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3e413288-7289-46f8-82b8-1af63eebe170&userId=1ec9eb7e-38d5-6960-a60c-761f09258599",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103854",
        oponentTeamId: "5043888",
        opponent: "1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040820c00000001001428a1450a0001000428a14302000100001800c006000100143020450c00010000282085080001001010808404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11747097,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0c050041000000001001428a1450a00010014286084020001000018818006000100103080850a00010010180144060001001010618404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11746066,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e070830800000001001428a145080001001028810408000100001800c30c00010010288185080001000008418002000100101080820c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750065,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2052,
        opponentMmrAfter: 2044,
        opponentSlpWon: 0,
        playerMmrBefore: 2105,
        playerMmrAfter: 2113,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5b0c94d7-b143-485a-b500-2e26eb385f14",
        gameTimestamp: "2023-05-28 23:04:14",
        startTimestamp: "2023-05-28 22:55:54",
        endTimestamp: "2023-05-28 23:04:14",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5b0c94d7-b143-485a-b500-2e26eb385f14&userId=1ec9eb7e-38d5-6960-a60c-761f09258599",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103854",
        oponentTeamId: "5060290",
        opponent: "1ec9eb6f-9e69-623b-a60c-cc20553f6c6a",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040820c00000001001428a1450a0001000428a14302000100001800c006000100143020450c00010000282085080001001010808404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11747097,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0c050041000000001001428a1450a00010014286084020001000018818006000100103080850a00010010180144060001001010618404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11746066,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e070830800000001001428a145080001001028810408000100001800c30c00010010288185080001000008418002000100101080820c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750065,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003008000800800000001000410a1400a00010008082101020001001020810202000100042860840a000100081840c408000100101801410a",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11695215,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002418000820800000001001010208104000100080860830a0001001020214408000100103081440c0001000818a1410a0001000008004408",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729515,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c18040c30c00000001000c08004508000100002061020200010010208102080001000428214002000100041040c00a000100101880c308",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11738925,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1990,
        opponentMmrAfter: 1984,
        opponentSlpWon: 0,
        playerMmrBefore: 2099,
        playerMmrAfter: 2105,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8fc4f000-cf16-4b1d-934a-24b23c4f03bb",
        gameTimestamp: "2023-05-28 22:45:27",
        startTimestamp: "2023-05-28 22:39:50",
        endTimestamp: "2023-05-28 22:45:27",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8fc4f000-cf16-4b1d-934a-24b23c4f03bb&userId=1ec9eb7e-38d5-6960-a60c-761f09258599",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103854",
        oponentTeamId: "5106723",
        opponent: "1ec9eb6f-476f-6652-a60c-68e08b885697",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040820c00000001001428a1450a0001000428a14302000100001800c006000100143020450c00010000282085080001001010808404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11747097,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0c050041000000001001428a1450a00010014286084020001000018818006000100103080850a00010010180144060001001010618404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11746066,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e070830800000001001428a145080001001028810408000100001800c30c00010010288185080001000008418002000100101080820c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750065,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a0e1021000000001001008a142020001001408810308000100001800820a0001001030a1840a000100101880c20c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11701992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c140a1041000000001000010008004000100002001400800010000188184060001000028004308000100001000c30a0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nondec",
              horn: "ecard_beast_3010_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11577795,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e104100000000100001000800400010000200100020001000030018406000100000800410a00010000080040020001001418a0c506",
            runes: ["rune_beast_2010_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_buba_1",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11675213,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1872,
        opponentMmrAfter: 1868,
        opponentSlpWon: 0,
        playerMmrBefore: 2095,
        playerMmrAfter: 2099,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "df679ec4-27b5-4943-9167-4c17db8ecc6a",
        gameTimestamp: "2023-05-28 22:38:44",
        startTimestamp: "2023-05-28 22:33:40",
        endTimestamp: "2023-05-28 22:38:44",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=df679ec4-27b5-4943-9167-4c17db8ecc6a&userId=1ec9eb7e-38d5-6960-a60c-761f09258599",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103854",
        oponentTeamId: "5079651",
        opponent: "1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040820c00000001001428a1450a0001000428a14302000100001800c006000100143020450c00010000282085080001001010808404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11747097,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0c050041000000001001428a1450a00010014286084020001000018818006000100103080850a00010010180144060001001010618404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11746066,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e070830800000001001428a145080001001028810408000100001800c30c00010010288185080001000008418002000100101080820c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750065,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2031,
        opponentMmrAfter: 2023,
        opponentSlpWon: 0,
        playerMmrBefore: 2087,
        playerMmrAfter: 2095,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:17.000Z",
  },
  {
    clientId: "1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
    name: "TRICKZ | YGG SEA",
    rank: "Challenger",
    tier: 0,
    topRank: 22,
    vstar: 2096,
    avatar: "0;fluffy_hooves",
    battles: [
      {
        battleUuid: "c259a202-f02c-475d-8361-c9787f48eceb",
        gameTimestamp: "2023-05-29 13:25:10",
        startTimestamp: "2023-05-29 13:18:31",
        endTimestamp: "2023-05-29 13:25:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c259a202-f02c-475d-8361-c9787f48eceb&userId=1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095298",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x80000000000010001300040c208000000010004104041040001000428214504000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734309,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0e051040800000001000010014102000100002861420200010000188180060001000c28018102000100041080c002000100042000420a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11722173,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0700410000000010000108080040001000008004002000100001000c00400010000080140020001000008004404000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11464519,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2074,
        opponentMmrAfter: 2065,
        opponentSlpWon: 0,
        playerMmrBefore: 2087,
        playerMmrAfter: 2096,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bb83342d-69f3-42e5-b0d6-587cdba4d99e",
        gameTimestamp: "2023-05-29 10:26:49",
        startTimestamp: "2023-05-29 10:22:24",
        endTimestamp: "2023-05-29 10:26:49",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bb83342d-69f3-42e5-b0d6-587cdba4d99e&userId=1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095298",
        oponentTeamId: "5026641",
        opponent: "1ec9eb73-b491-656d-a60c-2ebb6144527e",
        playerTeam: [
          {
            gene: "0x80000000000010001300040c208000000010004104041040001000428214504000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734309,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0e051040800000001000010014102000100002861420200010000188180060001000c28018102000100041080c002000100042000420a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11722173,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0700410000000010000108080040001000008004002000100001000c00400010000080140020001000008004404000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11464519,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412040820800000001001428a1450a0001000428204102000100001800c006000100041041010a00010004084184040001001010a10508",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e000c2100000000100142801450a0001000820410402000100001800c00a0001001030a1440c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11749565,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002404050c20400000001001428408504000100082020840800010000184180060001000828a18402000100101041800a0001001010814008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11728770,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2013,
        opponentMmrAfter: 2005,
        opponentSlpWon: 0,
        playerMmrBefore: 2079,
        playerMmrAfter: 2087,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3ca161f2-b2af-480b-a7a7-96d7407fba06",
        gameTimestamp: "2023-05-29 08:06:15",
        startTimestamp: "2023-05-29 08:01:25",
        endTimestamp: "2023-05-29 08:06:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3ca161f2-b2af-480b-a7a7-96d7407fba06&userId=1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095298",
        oponentTeamId: "5108287",
        opponent: "1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        playerTeam: [
          {
            gene: "0x80000000000010001300040c208000000010004104041040001000428214504000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734309,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0e051040800000001000010014102000100002861420200010000188180060001000c28018102000100041080c002000100042000420a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11722173,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0700410000000010000108080040001000008004002000100001000c00400010000080140020001000008004404000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11464519,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2046,
        opponentMmrAfter: 2037,
        opponentSlpWon: 0,
        playerMmrBefore: 2070,
        playerMmrAfter: 2079,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4fd30297-dd0b-473b-86ff-6263f60e7a3f",
        gameTimestamp: "2023-05-29 04:51:04",
        startTimestamp: "2023-05-29 04:47:49",
        endTimestamp: "2023-05-29 04:51:04",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4fd30297-dd0b-473b-86ff-6263f60e7a3f&userId=1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095298",
        oponentTeamId: "5100042",
        opponent: "1ec9eb7b-31ea-644a-a60c-90bbfed78000",
        playerTeam: [
          {
            gene: "0x80000000000010001300040c208000000010004104041040001000428214504000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734309,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0e051040800000001000010014102000100002861420200010000188180060001000c28018102000100041080c002000100042000420a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11722173,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0700410000000010000108080040001000008004002000100001000c00400010000080140020001000008004404000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11464519,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001c0e07104100000000100001000800400010000280040080001000018214106000100041800400200010000080040020001001010004002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538765,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0a02104100000000100001000820a0001000020a0420a0001001030a1440c00010000080040020001000008004302000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11572988,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c0e0830000000001000020a1450a000100102021440a00010014086045080001001430a1410a000100101880c1060001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734717,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2009,
        opponentMmrAfter: 2001,
        opponentSlpWon: 0,
        playerMmrBefore: 2062,
        playerMmrAfter: 2070,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "128c4675-4aa5-4548-8dc7-8a54cb3eec63",
        gameTimestamp: "2023-05-29 02:08:04",
        startTimestamp: "2023-05-29 02:05:24",
        endTimestamp: "2023-05-29 02:08:04",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=128c4675-4aa5-4548-8dc7-8a54cb3eec63&userId=1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5095298",
        oponentTeamId: "4438481",
        opponent: "1ec9eb7e-579e-6a97-a60c-dd46969f474e",
        playerTeam: [
          {
            gene: "0x80000000000010001300040c208000000010004104041040001000428214504000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734309,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0e051040800000001000010014102000100002861420200010000188180060001000c28018102000100041080c002000100042000420a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11722173,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0700410000000010000108080040001000008004002000100001000c00400010000080140020001000008004404000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11464519,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x280000000000010001c0c080c30000000001001408a043040001001410a1450a0001001420a105080001000820a105080001000c188105060001000c2061050a",
            runes: ["rune_reptile_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1012_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2013_s4_nondec",
            },
            axie_id: 11486338,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000201a070c210000000010014286040080001001428a145040001001420a1010a00010014204045060001001418a0c506000100143061050c",
            runes: ["rune_reptile_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1005",
              ears: "ecard_neutral_1005",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_reptile_2013_s4_nondec",
              mouth: "ecard_reptile_2013_s4_nondec",
            },
            axie_id: 11599828,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000200a050c00c00000001001408a083080001000c08a1440a0001001420a105080001000c10814508000100141801830c0001001428608502",
            runes: ["rune_neutral_utility_1"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1014_s4",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_plant_3010_s4_nondec",
            },
            axie_id: 11608829,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:17.000Z",
  },
  {
    clientId: "1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
    name: "Riikku",
    rank: "Challenger",
    tier: 0,
    topRank: 23,
    vstar: 2095,
    avatar: "buba_joker;gold_s3",
    battles: [
      {
        battleUuid: "b123f2aa-c09c-46ce-bd00-0db971a3f49c",
        gameTimestamp: "2023-05-29 13:11:09",
        startTimestamp: "2023-05-29 13:05:58",
        endTimestamp: "2023-05-29 13:11:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b123f2aa-c09c-46ce-bd00-0db971a3f49c&userId=1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5027313",
        oponentTeamId: "5106640",
        opponent: "1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        playerTeam: [
          {
            gene: "0x18000000000001000301c0c0c40800000001000c0860850a0001000c084083020001000c086180060001000c08a143060001000c2001410a000100000860450a",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11736487,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2111,
        opponentMmrAfter: 2101,
        opponentSlpWon: 0,
        playerMmrBefore: 2085,
        playerMmrAfter: 2095,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "2d6f99de-6bde-42c6-894e-c9c5e711365c",
        gameTimestamp: "2023-05-29 13:03:43",
        startTimestamp: "2023-05-29 12:59:33",
        endTimestamp: "2023-05-29 13:03:43",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2d6f99de-6bde-42c6-894e-c9c5e711365c&userId=1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5027313",
        oponentTeamId: "5095652",
        opponent: "1ec9eb6f-4c8d-640e-a60c-f3e4cc422e67",
        playerTeam: [
          {
            gene: "0x18000000000001000301c0c0c40800000001000c0860850a0001000c084083020001000c086180060001000c08a143060001000c2001410a000100000860450a",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11736487,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100008160c0c10800000001000410a0830a000100080801420200010010200104080001000428214502000100081860410c000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11746776,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010060013801021000000001000c082081020001000808404108000100102041850a000100042820c50a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11712810,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c000208108000000010004102083020001000808a0800800010010208084080001000428218506000100081860820c000100000880c406",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11744514,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7b66b14d-c436-4a7c-b510-ebc4a2cc491c",
        gameTimestamp: "2023-05-29 11:51:10",
        startTimestamp: "2023-05-29 11:45:57",
        endTimestamp: "2023-05-29 11:51:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7b66b14d-c436-4a7c-b510-ebc4a2cc491c&userId=1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5027313",
        oponentTeamId: "5103218",
        opponent: "1ec9eb73-5d7e-67ea-a60c-8947fb7b9def",
        playerTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000018000840800000001000410a1030200010008080145020001001020210208000100042880410a000100041080c1040001001018a0c506",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11718882,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030180c08408000000010004108100020001000808404202000100102081020c000100042881850200010008188084040001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11715042,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2800000000000100024120e0420800000001000c08a102020001000808404202000100102020440c00010004282141040001000410208306000100101880c308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11724388,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1993,
        opponentMmrAfter: 1986,
        opponentSlpWon: 0,
        playerMmrBefore: 2078,
        playerMmrAfter: 2085,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "faef671a-b8e8-41e6-86d6-e697a65c8cba",
        gameTimestamp: "2023-05-29 11:45:00",
        startTimestamp: "2023-05-29 11:40:22",
        endTimestamp: "2023-05-29 11:45:00",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=faef671a-b8e8-41e6-86d6-e697a65c8cba&userId=1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5027313",
        oponentTeamId: "5103996",
        opponent: "1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        playerTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100023000c084080000000100000861430400010004106141040001000018604504000100001860c30a0001000c280101040001000c10214306",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11669683,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040208408000000010004206105040001000028a08108000100001800420c0001000408008102000100042800410a000100101080410a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11519038,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0810410000000010000100140020001000008004002000100001020c004000100001000810c0001000030004002000100002801420a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5457881,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2111,
        opponentMmrAfter: 2120,
        opponentSlpWon: 24,
        playerMmrBefore: 2087,
        playerMmrAfter: 2078,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "ace9a322-df08-4a80-aac5-bab7bb3bbbde",
        gameTimestamp: "2023-05-29 11:39:47",
        startTimestamp: "2023-05-29 11:35:33",
        endTimestamp: "2023-05-29 11:39:47",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ace9a322-df08-4a80-aac5-bab7bb3bbbde&userId=1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5027313",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c286042080001000c086043040001000c3061830a0001000c086140020001000c304085060001000008604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9138691,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2036,
        opponentMmrAfter: 2028,
        opponentSlpWon: 0,
        playerMmrBefore: 2079,
        playerMmrAfter: 2087,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:18.000Z",
  },
  {
    clientId: "1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
    name: "not Pomchi",
    rank: "Challenger",
    tier: 0,
    topRank: 24,
    vstar: 2091,
    avatar: "13;0",
    battles: [
      {
        battleUuid: "62041265-328b-4adc-b10c-dfa799098c93",
        gameTimestamp: "2023-05-29 13:06:03",
        startTimestamp: "2023-05-29 13:00:18",
        endTimestamp: "2023-05-29 13:06:03",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=62041265-328b-4adc-b10c-dfa799098c93&userId=1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108287",
        oponentTeamId: "5067843",
        opponent: "1ec9eb7e-6efb-6b0a-a60c-6d80c721f88d",
        playerTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000081c07082080000000100041020800a0001000808014202000100102081050a0001000428214304000100081840c20c000100101880c406",
            runes: ["rune_mech_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11713813,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100024120c0c21000000001000410208404000100080840440400010010208101060001000428214206000100081840c304000100000880c408",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11718902,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000b00070430c00000001000410010302000100080840450a0001001020810002000100042881840c00010008182102060001001018004406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11718742,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2017,
        opponentMmrAfter: 2009,
        opponentSlpWon: 0,
        playerMmrBefore: 2083,
        playerMmrAfter: 2091,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4522003e-fde7-4f77-a4a1-eb017427dd59",
        gameTimestamp: "2023-05-29 12:59:38",
        startTimestamp: "2023-05-29 12:54:29",
        endTimestamp: "2023-05-29 12:59:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4522003e-fde7-4f77-a4a1-eb017427dd59&userId=1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108287",
        oponentTeamId: "5089931",
        opponent: "1ec9eb6f-7cd1-69fb-a60c-2fa7ff2a0dbb",
        playerTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003804000820000000001001428a14108000100102021430a000100001860c4080001000c08a1810200010000286044060001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11715572,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a000c30000000001001428a141020001000820210002000100001800c1020001000c0881810200010010180184060001001010610404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2019_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11713797,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100038180e0c4100000000100142801410200010008104043040001000c082142080001000c0860410a000100083001410a0001000820604302",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_3011_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713873,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1878,
        opponentMmrAfter: 1874,
        opponentSlpWon: 0,
        playerMmrBefore: 2079,
        playerMmrAfter: 2083,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e42b851f-e298-4a81-9a19-9486086012de",
        gameTimestamp: "2023-05-29 12:53:30",
        startTimestamp: "2023-05-29 12:47:14",
        endTimestamp: "2023-05-29 12:53:30",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e42b851f-e298-4a81-9a19-9486086012de&userId=1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108287",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2145,
        opponentMmrAfter: 2133,
        opponentSlpWon: 0,
        playerMmrBefore: 2067,
        playerMmrAfter: 2079,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "041ab4da-8464-47ed-a7c2-8808f4866a4d",
        gameTimestamp: "2023-05-29 12:46:39",
        startTimestamp: "2023-05-29 12:42:52",
        endTimestamp: "2023-05-29 12:46:39",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=041ab4da-8464-47ed-a7c2-8808f4866a4d&userId=1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108287",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2039,
        opponentMmrAfter: 2030,
        opponentSlpWon: 0,
        playerMmrBefore: 2058,
        playerMmrAfter: 2067,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9e538c25-390c-4f7c-a101-4d28f68d7cbc",
        gameTimestamp: "2023-05-29 11:35:06",
        startTimestamp: "2023-05-29 11:29:51",
        endTimestamp: "2023-05-29 11:35:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9e538c25-390c-4f7c-a101-4d28f68d7cbc&userId=1ec9eb6f-57e3-6754-a60c-1c12d7aa1179",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108287",
        oponentTeamId: "4975964",
        opponent: "1ec9eb73-830c-6552-a60c-6ae276fdd9bc",
        playerTeam: [
          {
            gene: "0x8000000000001000081c050840c00000001001428a1450a00010004288100040001000018804206000100143001850c000100002880c4060001001010408404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11751922,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804080840800000001001428a1430a0001000428214408000100001800c0060001001430a1850c000100002880c4040001001010008404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11751853,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816020820000000001001428a1450a000100042821450a00010000180180060001001008214008000100001880c0020001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731015,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001018040820800000001000c08a14108000100101060430a0001001430604304000100141021830c0001000c3061830c0001000c10610208",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2017_s4",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 10065892,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a040820c0000000100101000400400010014200100020001000018008102000100141000820600010014100105040001000008404506",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11677137,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024180a084080000000100082041050a000100082841430a000100081840c206000100083041820c00010008304182020001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bird_2012_s4_nondec",
            },
            axie_id: 11683971,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1921,
        opponentMmrAfter: 1915,
        opponentSlpWon: 0,
        playerMmrBefore: 2052,
        playerMmrAfter: 2058,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:19.000Z",
  },
  {
    clientId: "1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
    name: "chan1chan | sushiclub | PZL",
    rank: "Challenger",
    tier: 0,
    topRank: 25,
    vstar: 2090,
    avatar: "xmas22;gold_s2",
    battles: [
      {
        battleUuid: "e4c4fec2-8634-4a4f-8e01-e5db817558aa",
        gameTimestamp: "2023-05-29 10:59:30",
        startTimestamp: "2023-05-29 10:54:46",
        endTimestamp: "2023-05-29 10:59:30",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e4c4fec2-8634-4a4f-8e01-e5db817558aa&userId=1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5082942",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2077,
        opponentMmrAfter: 2068,
        opponentSlpWon: 0,
        playerMmrBefore: 2081,
        playerMmrAfter: 2090,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6929ed5b-d191-475c-b34f-f6f4729ab052",
        gameTimestamp: "2023-05-29 10:50:22",
        startTimestamp: "2023-05-29 10:47:16",
        endTimestamp: "2023-05-29 10:50:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6929ed5b-d191-475c-b34f-f6f4729ab052&userId=1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5082942",
        oponentTeamId: "5089257",
        opponent: "1ec9eb6f-7d40-6db1-a60c-5218dd0e7d55",
        playerTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001412041040c000000010000100144080001000028a0420a000100001800c0060001000830814208000100000820c1020001000820004406",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11618751,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120c1040800000001000820410208000100082841420a000100081840c206000100083000420c000100083041020c000100081040820a",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11716617,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000000c0c41000000001000c082040080001000c086043020001000408a1040c0001000c0860850c0001000c18a042060001000c10610006",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 7622947,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1885,
        opponentMmrAfter: 1881,
        opponentSlpWon: 0,
        playerMmrBefore: 2077,
        playerMmrAfter: 2081,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e991aec0-5ece-4bc7-8c0a-690655e1293e",
        gameTimestamp: "2023-05-29 10:46:07",
        startTimestamp: "2023-05-29 10:43:01",
        endTimestamp: "2023-05-29 10:46:07",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e991aec0-5ece-4bc7-8c0a-690655e1293e&userId=1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5082942",
        oponentTeamId: "5112052",
        opponent: "1ed5a8dd-b672-63e6-8b70-bd62a6c5da7c",
        playerTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001004040a02100000000000100142881450a0001000428404004000100001800c40c000100143041450c0001001018a085080001001010608404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749038,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0e070800800000001001428a142080001001028608102000100001800c00c0001001030a0410a00010010184081020001001010808404",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11738779,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100038080b0c20000000001001428a14308000100102881440800010000188100020001001030a1820a00010010186042040001001010808102",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11740040,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1922,
        opponentMmrAfter: 1917,
        opponentSlpWon: 0,
        playerMmrBefore: 2072,
        playerMmrAfter: 2077,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e3c3f9b4-62da-448e-a2d6-d8b7edb6de04",
        gameTimestamp: "2023-05-29 10:41:52",
        startTimestamp: "2023-05-29 10:37:21",
        endTimestamp: "2023-05-29 10:41:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e3c3f9b4-62da-448e-a2d6-d8b7edb6de04&userId=1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5082942",
        oponentTeamId: "5102140",
        opponent: "1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        playerTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2130,
        opponentMmrAfter: 2119,
        opponentSlpWon: 0,
        playerMmrBefore: 2061,
        playerMmrAfter: 2072,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bccbf68e-fea5-47d4-a9ee-f7fab02fab91",
        gameTimestamp: "2023-05-29 10:32:31",
        startTimestamp: "2023-05-29 10:26:10",
        endTimestamp: "2023-05-29 10:32:31",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bccbf68e-fea5-47d4-a9ee-f7fab02fab91&userId=1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5082942",
        oponentTeamId: "5044806",
        opponent: "1ecda672-7ddd-6991-8179-49518582467f",
        playerTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2060,
        opponentMmrAfter: 2070,
        opponentSlpWon: 0,
        playerMmrBefore: 2071,
        playerMmrAfter: 2061,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:20.000Z",
  },
  {
    clientId: "1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
    name: "MAGTHEWHITECAT",
    rank: "Challenger",
    tier: 0,
    topRank: 26,
    vstar: 2087,
    avatar: "shy;paschal_eggs",
    battles: [
      {
        battleUuid: "efc096d8-25ad-43b7-9250-6ef50b8c7d37",
        gameTimestamp: "2023-05-29 00:36:21",
        startTimestamp: "2023-05-29 00:33:27",
        endTimestamp: "2023-05-29 00:36:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=efc096d8-25ad-43b7-9250-6ef50b8c7d37&userId=1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5066884",
        oponentTeamId: "4995636",
        opponent: "1ec9eb6f-98f2-6aec-a60c-fa140ec23865",
        playerTeam: [
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a0001000428204002000100001800c50c000100100821450800010000282085080001001010a08404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11733343,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a050c30c00000001001428a1450a000100042881040200010000180140060001001430a1810c000100101880c20a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721664,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008091020800000001001428a1430a00010010288104080001000018618502000100100820850a0001001018810106000100101041010c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11685968,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010000810060020c0000000100042060430200010004102082040001000428a1810c0001001420a1050800010014208145080001000410a04208",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_reptile_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2012_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_2017_s4_nft",
            },
            axie_id: 11647792,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002c1a0b0c30c00000001000410414504000100002001020a000100101060c40a0001001430a184080001001408a185020001001010808306",
            runes: ["rune_neutral_2011_s4_nft"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 10907457,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000001040400000001001410a083020001000c2801050800010000182142040001000430a104020001001420a144040001000410204102",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2017_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11713263,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1996,
        opponentMmrAfter: 1989,
        opponentSlpWon: 0,
        playerMmrBefore: 2080,
        playerMmrAfter: 2087,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d8120776-fb5f-4d57-b94d-2b2f4720a115",
        gameTimestamp: "2023-05-29 00:31:55",
        startTimestamp: "2023-05-29 00:25:44",
        endTimestamp: "2023-05-29 00:31:55",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d8120776-fb5f-4d57-b94d-2b2f4720a115&userId=1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5066884",
        oponentTeamId: "5075273",
        opponent: "1ec9eb7e-320e-6afc-a60c-d89c0f57aabf",
        playerTeam: [
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a0001000428204002000100001800c50c000100100821450800010000282085080001001010a08404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11733343,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a050c30c00000001001428a1450a000100042881040200010000180140060001001430a1810c000100101880c20a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721664,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008091020800000001001428a1430a00010010288104080001000018618502000100100820850a0001001018810106000100101041010c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11685968,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0e0c30c00000001001428a1420400010004282144080001000018008006000100143081850c000100000801410c0001001010808006",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11709535,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001404040830800000001001428a14204000100001020430a0001000018604508000100041020850c0001001018a044060001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_bug_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11716020,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a090030800000001001428a1450a00010004284103040001000018418006000100001861850c00010004108044020001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11731287,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2011,
        opponentMmrAfter: 2003,
        opponentSlpWon: 0,
        playerMmrBefore: 2072,
        playerMmrAfter: 2080,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "02242cdc-24ab-4750-8661-ffc1bf91e25c",
        gameTimestamp: "2023-05-29 00:23:23",
        startTimestamp: "2023-05-29 00:20:43",
        endTimestamp: "2023-05-29 00:23:23",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=02242cdc-24ab-4750-8661-ffc1bf91e25c&userId=1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5066884",
        oponentTeamId: "5109105",
        opponent: "1ec9eb7e-5bb0-6bde-a60c-534f3b09ec86",
        playerTeam: [
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a0001000428204002000100001800c50c000100100821450800010000282085080001001010a08404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11733343,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a050c30c00000001001428a1450a000100042881040200010000180140060001001430a1810c000100101880c20a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721664,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008091020800000001001428a1430a00010010288104080001000018618502000100100820850a0001001018810106000100101041010c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11685968,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000380a0e042080000000100142821050a0001000428208408000100001800c108000100143081450c00010000102180020001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11707941,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0820c00000001001428a1430a000100042821410a00010000186180060001001430a1850c0001000028a0820a0001001010808008",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751647,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340c0e1020800000001001428a144080001000428410408000100001800c1060001000010a18408000100002880c4060001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1004",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11753585,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2001,
        opponentMmrAfter: 1993,
        opponentSlpWon: 0,
        playerMmrBefore: 2064,
        playerMmrAfter: 2072,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0d7d8e5f-5eb6-4329-b5b3-dd3174fed72c",
        gameTimestamp: "2023-05-29 00:19:13",
        startTimestamp: "2023-05-29 00:11:53",
        endTimestamp: "2023-05-29 00:19:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0d7d8e5f-5eb6-4329-b5b3-dd3174fed72c&userId=1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5066884",
        oponentTeamId: "5107955",
        opponent: "1ec9eb6f-5cef-6af0-a60c-ef5df78b78cb",
        playerTeam: [
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a0001000428204002000100001800c50c000100100821450800010000282085080001001010a08404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11733343,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a050c30c00000001001428a1450a000100042881040200010000180140060001001430a1810c000100101880c20a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721664,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008091020800000001001428a1430a00010010288104080001000018618502000100100820850a0001001018810106000100101041010c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11685968,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000804050c40c00000001001428a1450a000100042821440a000100001800c0060001001430a1850c00010000280144040001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11751681,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000381c020820c00000001001428a1450a000100042880410a000100001860c0060001001430a1850c000100002840c5020001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1004",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11751651,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050830800000001001428014502000100102881440a000100001800c4080001001030a0c50a000100101860c4060001001010808502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11751283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1922,
        opponentMmrAfter: 1916,
        opponentSlpWon: 0,
        playerMmrBefore: 2058,
        playerMmrAfter: 2064,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "fae43855-5fc0-44bf-bdcc-fcb9e092e387",
        gameTimestamp: "2023-05-29 00:10:08",
        startTimestamp: "2023-05-29 00:04:20",
        endTimestamp: "2023-05-29 00:10:08",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fae43855-5fc0-44bf-bdcc-fcb9e092e387&userId=1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5066884",
        oponentTeamId: "5095631",
        opponent: "1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        playerTeam: [
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a0001000428204002000100001800c50c000100100821450800010000282085080001001010a08404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11733343,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a050c30c00000001001428a1450a000100042881040200010000180140060001001430a1810c000100101880c20a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721664,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008091020800000001001428a1430a00010010288104080001000018618502000100100820850a0001001018810106000100101041010c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11685968,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2005,
        opponentMmrAfter: 1997,
        opponentSlpWon: 0,
        playerMmrBefore: 2050,
        playerMmrAfter: 2058,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:20.000Z",
  },
  {
    clientId: "1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
    name: "ulo mo",
    rank: "Challenger",
    tier: 0,
    topRank: 27,
    vstar: 2086,
    avatar: null,
    battles: [
      {
        battleUuid: "e6d6f3f8-556b-4e91-84fc-d83fcc56000d",
        gameTimestamp: "2023-05-29 11:29:26",
        startTimestamp: "2023-05-29 11:25:35",
        endTimestamp: "2023-05-29 11:29:26",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e6d6f3f8-556b-4e91-84fc-d83fcc56000d&userId=1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5095381",
        oponentTeamId: "5102140",
        opponent: "1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        playerTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2096,
        opponentMmrAfter: 2105,
        opponentSlpWon: 0,
        playerMmrBefore: 2095,
        playerMmrAfter: 2086,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "09fecbb9-0852-4f36-b3be-1a6d44da4487",
        gameTimestamp: "2023-05-29 11:24:55",
        startTimestamp: "2023-05-29 11:19:46",
        endTimestamp: "2023-05-29 11:24:55",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=09fecbb9-0852-4f36-b3be-1a6d44da4487&userId=1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095381",
        oponentTeamId: "4998450",
        opponent: "1ed19f15-3cc6-65ce-b138-35f04e5ecadf",
        playerTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040020c000000010004100142020001000810014104000100001800c102000100042800c10a0001001420a10508000100040880830a",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11691934,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040b10410000000010000100080040001000028610308000100001800c0060001000428414304000100041041820c000100000840c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718510,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c020b0840800000001000010008004000100002020840a000100003061000c000100000800440600010000088182020001000018a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11727730,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2008,
        opponentMmrAfter: 2001,
        opponentSlpWon: 0,
        playerMmrBefore: 2088,
        playerMmrAfter: 2095,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "747868dc-9613-4d29-b1a7-79c675ba9d9d",
        gameTimestamp: "2023-05-29 11:18:03",
        startTimestamp: "2023-05-29 11:13:42",
        endTimestamp: "2023-05-29 11:18:03",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=747868dc-9613-4d29-b1a7-79c675ba9d9d&userId=1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095381",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nondec",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2073,
        opponentMmrAfter: 2064,
        opponentSlpWon: 0,
        playerMmrBefore: 2079,
        playerMmrAfter: 2088,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5f11ab5d-d23e-4126-a7ed-d3e22c2b9ae0",
        gameTimestamp: "2023-05-29 11:13:05",
        startTimestamp: "2023-05-29 11:08:29",
        endTimestamp: "2023-05-29 11:13:05",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5f11ab5d-d23e-4126-a7ed-d3e22c2b9ae0&userId=1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5095381",
        oponentTeamId: "5055615",
        opponent: "1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        playerTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nondec",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2041,
        opponentMmrAfter: 2052,
        opponentSlpWon: 24,
        playerMmrBefore: 2090,
        playerMmrAfter: 2079,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "ddd54f58-530a-48c1-80d5-d898a2966cb8",
        gameTimestamp: "2023-05-29 11:07:16",
        startTimestamp: "2023-05-29 11:02:18",
        endTimestamp: "2023-05-29 11:07:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ddd54f58-530a-48c1-80d5-d898a2966cb8&userId=1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095381",
        oponentTeamId: "5090650",
        opponent: "1ec9eb77-511b-64e6-a60c-2bc1f8ec5d6f",
        playerTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nondec",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140c050820800000001000c286083020001000c106083020001000c088043060001000c086144020001000c302043080001000c1061000a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11603356,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000038008308000000010010102144040001000c082140020001000c0840810c0001000c08604406000100041820c304000100000820c106",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "",
              mouth: "",
            },
            axie_id: 11741192,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c0a021020800000001000c282085080001000c10a083040001000c0860430a0001000c0841020800010004184104040001001030a0c008",
            runes: ["rune_beast_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "",
            },
            axie_id: 11753281,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2091,
        opponentMmrAfter: 2081,
        opponentSlpWon: 0,
        playerMmrBefore: 2080,
        playerMmrAfter: 2090,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:21.000Z",
  },
  {
    clientId: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
    name: "TQ | Twitter: @Gianlex22",
    rank: "Challenger",
    tier: 0,
    topRank: 28,
    vstar: 2086,
    avatar: "s3_top1k;gold_s3",
    battles: [
      {
        battleUuid: "29c54df3-2839-4392-afcd-674fe6b92c16",
        gameTimestamp: "2023-05-29 04:15:10",
        startTimestamp: "2023-05-29 04:08:25",
        endTimestamp: "2023-05-29 04:15:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=29c54df3-2839-4392-afcd-674fe6b92c16&userId=1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107175",
        oponentTeamId: "5095412",
        opponent: "1ec9eb7e-6a0d-6e97-a60c-aee122ad13a1",
        playerTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0a04083000000000100142840850a0001000428214102000100001800c006000100143021400800010004102040040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724393,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000140a050830800000001001428a1450a000100042881020800010000182140060001001430a183080001000028208306000100101020c102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749954,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c040821000000001001428a1450a0001000010410408000100001800c0060001001030a1820c000100101800810a0001001010808506",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11752896,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2068,
        opponentMmrAfter: 2059,
        opponentSlpWon: 0,
        playerMmrBefore: 2077,
        playerMmrAfter: 2086,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d752b67b-6dba-4760-b643-a6d271e049b9",
        gameTimestamp: "2023-05-29 04:07:48",
        startTimestamp: "2023-05-29 04:03:46",
        endTimestamp: "2023-05-29 04:07:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d752b67b-6dba-4760-b643-a6d271e049b9&userId=1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5107175",
        oponentTeamId: "5057779",
        opponent: "1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
        playerTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100008040208200000000010004102081040001000808a042020001001020810408000100042881810a0001000818814006000100101880c208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e08208000000010004102084040001000808a142020001001020218408000100103021410a000100081840c2060001000008418406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11732025,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001004040e020c10c00000001000410208402000100080840410a000100102021830c0001000428a1810a00010004102145060001001018008406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11736031,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1994,
        opponentMmrAfter: 1987,
        opponentSlpWon: 0,
        playerMmrBefore: 2070,
        playerMmrAfter: 2077,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9dfa890c-2bcc-4fc9-94a1-a12dd6c7d244",
        gameTimestamp: "2023-05-29 04:03:07",
        startTimestamp: "2023-05-29 03:57:43",
        endTimestamp: "2023-05-29 04:03:07",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9dfa890c-2bcc-4fc9-94a1-a12dd6c7d244&userId=1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5107175",
        oponentTeamId: "5026641",
        opponent: "1ec9eb73-b491-656d-a60c-2ebb6144527e",
        playerTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412040820800000001001428a1450a0001000428204102000100001800c006000100041041010a00010004084184040001001010a10508",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e000c2100000000100142801450a0001000820410402000100001800c00a0001001030a1440c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11749565,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002404050c20400000001001428408504000100082020840800010000184180060001000828a18402000100101041800a0001001010814008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11728770,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2018,
        opponentMmrAfter: 2010,
        opponentSlpWon: 0,
        playerMmrBefore: 2062,
        playerMmrAfter: 2070,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "63a98b17-01f4-40ec-9940-ced56d84bcbc",
        gameTimestamp: "2023-05-29 03:57:12",
        startTimestamp: "2023-05-29 03:51:35",
        endTimestamp: "2023-05-29 03:57:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=63a98b17-01f4-40ec-9940-ced56d84bcbc&userId=1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5107175",
        oponentTeamId: "5106002",
        opponent: "1ec9eb6f-65b7-6cd2-a60c-52e37f14c52a",
        playerTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000c0a040830c0000000100142860850a0001000428804408000100001800c006000100143001010c000100002860420a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747919,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100a040c30c00000001001428a1450a0001000428410208000100001800c0060001001430214302000100041020800a0001001010808008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749937,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000000e000c20800000001001428a14108000100102881420400010000188040060001001030804104000100101880c5040001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747486,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1984,
        opponentMmrAfter: 1976,
        opponentSlpWon: 0,
        playerMmrBefore: 2054,
        playerMmrAfter: 2062,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "04a7e11e-8195-4e46-9f5d-e274d304ef99",
        gameTimestamp: "2023-05-29 03:50:53",
        startTimestamp: "2023-05-29 03:45:54",
        endTimestamp: "2023-05-29 03:50:53",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=04a7e11e-8195-4e46-9f5d-e274d304ef99&userId=1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107175",
        oponentTeamId: "5060007",
        opponent: "1ec9eb6f-490a-620b-a60c-c736e162e257",
        playerTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2043,
        opponentMmrAfter: 2053,
        opponentSlpWon: 24,
        playerMmrBefore: 2064,
        playerMmrAfter: 2054,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:22.000Z",
  },
  {
    clientId: "1ec9eb6f-41b2-6328-a60c-007456abd012",
    name: "Dhar Lucero | ProGamerX",
    rank: "Challenger",
    tier: 0,
    topRank: 29,
    vstar: 2083,
    avatar: "buba_joker;rainbow",
    battles: [
      {
        battleUuid: "fdae6c8e-de6d-4c47-908b-4a4c7d782b0b",
        gameTimestamp: "2023-05-29 13:29:04",
        startTimestamp: "2023-05-29 13:25:24",
        endTimestamp: "2023-05-29 13:29:04",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fdae6c8e-de6d-4c47-908b-4a4c7d782b0b&userId=1ec9eb6f-41b2-6328-a60c-007456abd012",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4859951",
        oponentTeamId: "5056612",
        opponent: "1ec9eb6f-4189-6498-a60c-76b150e5a03e",
        playerTeam: [
          {
            gene: "0x18000000000001000040a020821000000001000c08608302000100002860420a0001000c3061830c0001000c28614304000100000861830a0001000c0861830c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 2753269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100600040b0830c00000001001010a14404000100100881400800010000180084080001001030818202000100101880c5040001000408808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693077,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07102100000000100001060430a000100000801400a000100001000850a000100000800c0040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 7605259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080e0c2080000000100142861450a0001000428204102000100001800c006000100143041810a0001000410a081080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11730520,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003812020820800000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010218008",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743574,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000000009082080000000100142880450a0001001028810508000100001800c00600010010300102020001001018a144060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11734171,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1998,
        opponentMmrAfter: 1991,
        opponentSlpWon: 0,
        playerMmrBefore: 2076,
        playerMmrAfter: 2083,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f4e92f7f-0d24-4110-ac19-f23a22bb8f13",
        gameTimestamp: "2023-05-29 11:57:35",
        startTimestamp: "2023-05-29 11:53:57",
        endTimestamp: "2023-05-29 11:57:35",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f4e92f7f-0d24-4110-ac19-f23a22bb8f13&userId=1ec9eb6f-41b2-6328-a60c-007456abd012",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4859951",
        oponentTeamId: "4583491",
        opponent: "1ecd90cc-5b2b-6038-9390-e96571259efb",
        playerTeam: [
          {
            gene: "0x18000000000001000040a020821000000001000c08608302000100002860420a0001000c3061830c0001000c28614304000100000861830a0001000c0861830c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 2753269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100600040b0830c00000001001010a14404000100100881400800010000180084080001001030818202000100101880c5040001000408808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693077,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07102100000000100001060430a000100000801400a000100001000850a000100000800c0040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 7605259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001c0e0d0c20c00000001000028a142040001000008004004000100000841800a000100001021400400010000080140020001000008a08206",
            runes: ["rune_beast_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 6752915,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000141a040820c00000001000c0860430a0001000c106083080001000c1081450c0001000c2860430a0001000c184043060001000c30210108",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_2017_s4",
              horn: "ecard_neutral_2018_s4",
              tail: "",
              mouth: "",
            },
            axie_id: 10546578,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402104100000000100000800430a000100000800400200010000100083040001000010008004000100000800450c000100002801400a",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_tripp_1",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 627559,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "813e8f49-27a7-412b-a17b-224c8bebcee4",
        gameTimestamp: "2023-05-29 10:45:13",
        startTimestamp: "2023-05-29 10:39:43",
        endTimestamp: "2023-05-29 10:45:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=813e8f49-27a7-412b-a17b-224c8bebcee4&userId=1ec9eb6f-41b2-6328-a60c-007456abd012",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4859951",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x18000000000001000040a020821000000001000c08608302000100002860420a0001000c3061830c0001000c28614304000100000861830a0001000c0861830c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 2753269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100600040b0830c00000001001010a14404000100100881400800010000180084080001001030818202000100101880c5040001000408808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693077,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07102100000000100001060430a000100000801400a000100001000850a000100000800c0040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 7605259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2126,
        opponentMmrAfter: 2115,
        opponentSlpWon: 0,
        playerMmrBefore: 2065,
        playerMmrAfter: 2076,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9a7e48c8-60fb-46a8-a691-23a298e7759e",
        gameTimestamp: "2023-05-29 08:26:53",
        startTimestamp: "2023-05-29 08:23:41",
        endTimestamp: "2023-05-29 08:26:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9a7e48c8-60fb-46a8-a691-23a298e7759e&userId=1ec9eb6f-41b2-6328-a60c-007456abd012",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4859951",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x18000000000001000040a020821000000001000c08608302000100002860420a0001000c3061830c0001000c28614304000100000861830a0001000c0861830c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 2753269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100600040b0830c00000001001010a14404000100100881400800010000180084080001001030818202000100101880c5040001000408808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693077,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07102100000000100001060430a000100000801400a000100001000850a000100000800c0040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 7605259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2091,
        opponentMmrAfter: 2080,
        opponentSlpWon: 0,
        playerMmrBefore: 2054,
        playerMmrAfter: 2065,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "45d503f0-15a4-4bc3-b137-f93117da9da5",
        gameTimestamp: "2023-05-29 08:22:51",
        startTimestamp: "2023-05-29 08:18:53",
        endTimestamp: "2023-05-29 08:22:51",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=45d503f0-15a4-4bc3-b137-f93117da9da5&userId=1ec9eb6f-41b2-6328-a60c-007456abd012",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4859951",
        oponentTeamId: "5083225",
        opponent: "1ec9eb6f-820c-6d62-a60c-9a1f7853cb83",
        playerTeam: [
          {
            gene: "0x18000000000001000040a020821000000001000c08608302000100002860420a0001000c3061830c0001000c28614304000100000861830a0001000c0861830c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 2753269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100600040b0830c00000001001010a14404000100100881400800010000180084080001001030818202000100101880c5040001000408808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693077,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07102100000000100001060430a000100000801400a000100001000850a000100000800c0040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 7605259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a020801000000001001428a1450a000100042881010a00010000180080060001001430a1850c000100101880c0080001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000804040430c00000001001428a1450a0001000428408204000100001800c1080001001430a1830200010000282144060001001010808006",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11753041,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101a050830c00000001001428a144040001000020a08004000100001800c0060001001008204202000100000880c00a000100101020840a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11695659,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1970,
        opponentMmrAfter: 1963,
        opponentSlpWon: 0,
        playerMmrBefore: 2047,
        playerMmrAfter: 2054,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:22.000Z",
  },
  {
    clientId: "1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
    name: "bananaque",
    rank: "Challenger",
    tier: 0,
    topRank: 30,
    vstar: 2082,
    avatar: "0;0",
    battles: [
      {
        battleUuid: "438aea6f-8abf-4e18-8187-be51f86993a6",
        gameTimestamp: "2023-05-29 12:30:02",
        startTimestamp: "2023-05-29 12:24:10",
        endTimestamp: "2023-05-29 12:30:02",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=438aea6f-8abf-4e18-8187-be51f86993a6&userId=1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4735960",
        oponentTeamId: "5056612",
        opponent: "1ec9eb6f-4189-6498-a60c-76b150e5a03e",
        playerTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080e0c2080000000100142861450a0001000428204102000100001800c006000100143041810a0001000410a081080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11730520,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003812020820800000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010218008",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743574,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000000009082080000000100142880450a0001001028810508000100001800c00600010010300102020001001018a144060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11734171,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2019,
        opponentMmrAfter: 2011,
        opponentSlpWon: 0,
        playerMmrBefore: 2074,
        playerMmrAfter: 2082,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "190b52f4-9586-45e6-916c-59c7650af8d0",
        gameTimestamp: "2023-05-29 12:23:09",
        startTimestamp: "2023-05-29 12:18:01",
        endTimestamp: "2023-05-29 12:23:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=190b52f4-9586-45e6-916c-59c7650af8d0&userId=1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4735960",
        oponentTeamId: "5026311",
        opponent: "1ec9eb6f-8e03-67d9-a60c-a0cd8ab0ef27",
        playerTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e07002040000000100041020830200010008084042020001001020404408000100042881810a000100081840c206000100101880c20c",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11713769,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c0c082100000000100041081010400010008084041080001001020810408000100103040c10a000100081840c4040001000008818002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11705793,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010060012080840800000001000c088083020001000808004504000100102000450a00010004282141060001000410014102000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11712809,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1984,
        opponentMmrAfter: 1977,
        opponentSlpWon: 0,
        playerMmrBefore: 2067,
        playerMmrAfter: 2074,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8fdac70e-98c8-4e9b-8193-15dabe2cb91f",
        gameTimestamp: "2023-05-29 12:17:00",
        startTimestamp: "2023-05-29 12:11:04",
        endTimestamp: "2023-05-29 12:17:00",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8fdac70e-98c8-4e9b-8193-15dabe2cb91f&userId=1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4735960",
        oponentTeamId: "5106640",
        opponent: "1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        playerTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2086,
        opponentMmrAfter: 2095,
        opponentSlpWon: 24,
        playerMmrBefore: 2076,
        playerMmrAfter: 2067,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "9addf66b-bcfe-4db7-8bb9-cb612f0e2f82",
        gameTimestamp: "2023-05-29 12:09:43",
        startTimestamp: "2023-05-29 12:04:21",
        endTimestamp: "2023-05-29 12:09:43",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9addf66b-bcfe-4db7-8bb9-cb612f0e2f82&userId=1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4735960",
        oponentTeamId: "5104556",
        opponent: "1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        playerTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2190,
        opponentMmrAfter: 2196,
        opponentSlpWon: 24,
        playerMmrBefore: 2082,
        playerMmrAfter: 2076,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "7e9590ad-0732-4f4a-a0b7-e8401f55b1b6",
        gameTimestamp: "2023-05-29 12:01:01",
        startTimestamp: "2023-05-29 11:54:42",
        endTimestamp: "2023-05-29 12:01:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7e9590ad-0732-4f4a-a0b7-e8401f55b1b6&userId=1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4735960",
        oponentTeamId: "5109138",
        opponent: "1ec9eb6f-5741-624e-a60c-647c4afcd6a8",
        playerTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e05043100000000100142821450a0001000428214008000100001800c106000100143000810c000100002820430c0001001010818404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753644,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410050830800000001001428a1450a000100040821410a000100001800c0060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004000830800000001001428a10404000100042881440a000100001800c0060001000420a18208000100000880c1080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11740330,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2003,
        opponentMmrAfter: 1996,
        opponentSlpWon: 0,
        playerMmrBefore: 2075,
        playerMmrAfter: 2082,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:23.000Z",
  },
  {
    clientId: "1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
    name: "Kastor_Foker",
    rank: "Challenger",
    tier: 0,
    topRank: 31,
    vstar: 2081,
    avatar: "red_envelope_lunar23;rainbow",
    battles: [
      {
        battleUuid: "ea974a98-d2d6-4b35-acce-ad664c4b542e",
        gameTimestamp: "2023-05-29 04:24:27",
        startTimestamp: "2023-05-29 04:16:21",
        endTimestamp: "2023-05-29 04:24:27",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ea974a98-d2d6-4b35-acce-ad664c4b542e&userId=1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043888",
        oponentTeamId: "5108873",
        opponent: "1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        playerTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2013,
        opponentMmrAfter: 2005,
        opponentSlpWon: 0,
        playerMmrBefore: 2073,
        playerMmrAfter: 2081,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a7b2609b-50ab-418d-85ae-3925f2326934",
        gameTimestamp: "2023-05-29 04:15:25",
        startTimestamp: "2023-05-29 04:10:14",
        endTimestamp: "2023-05-29 04:15:25",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a7b2609b-50ab-418d-85ae-3925f2326934&userId=1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043888",
        oponentTeamId: "5042811",
        opponent: "1ec9eb7b-2999-6860-a60c-8796c3d23b5d",
        playerTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100028160a0400c0000000100142820450a0001000428604402000100001840410c000100143040c00c000100002880c0060001001010608006",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11720084,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000241a090820c0000000100142800850a000100001001450a000100001880c002000100001001420a0001000008a102020001000008004002",
            runes: ["rune_beast_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11725899,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000101a0d0840800000001001428a14208000100102881040a00010000184181020001000c2820420a000100101821400a0001001010808102",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "",
            },
            axie_id: 11720062,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1983,
        opponentMmrAfter: 1976,
        opponentSlpWon: 0,
        playerMmrBefore: 2066,
        playerMmrAfter: 2073,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d69281e6-7bf6-4070-ad26-cfc428d9abcd",
        gameTimestamp: "2023-05-29 04:09:37",
        startTimestamp: "2023-05-29 04:06:23",
        endTimestamp: "2023-05-29 04:09:37",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d69281e6-7bf6-4070-ad26-cfc428d9abcd&userId=1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5043888",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1993,
        opponentMmrAfter: 2005,
        opponentSlpWon: 0,
        playerMmrBefore: 2078,
        playerMmrAfter: 2066,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "75e39518-1cc4-422f-b850-8dbb8fc56098",
        gameTimestamp: "2023-05-29 03:58:09",
        startTimestamp: "2023-05-29 03:52:28",
        endTimestamp: "2023-05-29 03:58:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=75e39518-1cc4-422f-b850-8dbb8fc56098&userId=1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043888",
        oponentTeamId: "5108980",
        opponent: "1ec9eb77-539a-6ee2-a60c-3d6da056b6bb",
        playerTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100024000608208000000010014080102020001000c086043080001000c30a1820a0001000c28a1050c0001000c1061040a0001000c10618304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 10969817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c120710008000000010008104082040001000028804204000100001800c008000100002841420a0001000830418502000100000860c002",
            runes: ["rune_dawn_3012_s4_nft"],
            charms: {
              back: "ecard_bird_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11678172,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1209082080000000100082041050a0001000828414104000100081840c308000100042041820c0001000830a144080001000810410208",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_3011_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3012_s4_nft",
            },
            axie_id: 11716807,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1989,
        opponentMmrAfter: 1982,
        opponentSlpWon: 0,
        playerMmrBefore: 2071,
        playerMmrAfter: 2078,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "993e7165-59c5-46ab-9747-336db810d719",
        gameTimestamp: "2023-05-29 03:17:22",
        startTimestamp: "2023-05-29 03:10:09",
        endTimestamp: "2023-05-29 03:17:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=993e7165-59c5-46ab-9747-336db810d719&userId=1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043888",
        oponentTeamId: "5107175",
        opponent: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        playerTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2099,
        opponentMmrAfter: 2088,
        opponentSlpWon: 0,
        playerMmrBefore: 2060,
        playerMmrAfter: 2071,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:24.000Z",
  },
  {
    clientId: "1ecda672-7ddd-6991-8179-49518582467f",
    name: "....",
    rank: "Challenger",
    tier: 0,
    topRank: 32,
    vstar: 2081,
    avatar: "s3_top20k;0",
    battles: [
      {
        battleUuid: "623109e2-0e01-4f26-bbc9-8596fd73315f",
        gameTimestamp: "2023-05-29 10:50:54",
        startTimestamp: "2023-05-29 10:45:45",
        endTimestamp: "2023-05-29 10:50:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=623109e2-0e01-4f26-bbc9-8596fd73315f&userId=1ecda672-7ddd-6991-8179-49518582467f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5044806",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2115,
        opponentMmrAfter: 2104,
        opponentSlpWon: 0,
        playerMmrBefore: 2070,
        playerMmrAfter: 2081,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bccbf68e-fea5-47d4-a9ee-f7fab02fab91",
        gameTimestamp: "2023-05-29 10:32:31",
        startTimestamp: "2023-05-29 10:26:10",
        endTimestamp: "2023-05-29 10:32:31",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bccbf68e-fea5-47d4-a9ee-f7fab02fab91&userId=1ecda672-7ddd-6991-8179-49518582467f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5044806",
        oponentTeamId: "5082942",
        opponent: "1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        playerTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2071,
        opponentMmrAfter: 2061,
        opponentSlpWon: 0,
        playerMmrBefore: 2060,
        playerMmrAfter: 2070,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1cdb0301-47ff-4968-8b69-de9e0bfa4ee7",
        gameTimestamp: "2023-05-29 10:25:21",
        startTimestamp: "2023-05-29 10:20:10",
        endTimestamp: "2023-05-29 10:25:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1cdb0301-47ff-4968-8b69-de9e0bfa4ee7&userId=1ecda672-7ddd-6991-8179-49518582467f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5044806",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1992,
        opponentMmrAfter: 1984,
        opponentSlpWon: 0,
        playerMmrBefore: 2052,
        playerMmrAfter: 2060,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8644c2f1-977b-4bd4-9b69-03583cacdabe",
        gameTimestamp: "2023-05-29 10:19:32",
        startTimestamp: "2023-05-29 10:12:51",
        endTimestamp: "2023-05-29 10:19:32",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8644c2f1-977b-4bd4-9b69-03583cacdabe&userId=1ecda672-7ddd-6991-8179-49518582467f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5044806",
        oponentTeamId: "5107887",
        opponent: "1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        playerTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c070c20c000000010014282045080001000428214102000100001800c1080001000428010104000100041080c2040001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11730561,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1981,
        opponentMmrAfter: 1973,
        opponentSlpWon: 0,
        playerMmrBefore: 2044,
        playerMmrAfter: 2052,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1b119431-e706-462f-9432-645b0eca3222",
        gameTimestamp: "2023-05-29 10:10:59",
        startTimestamp: "2023-05-29 10:05:47",
        endTimestamp: "2023-05-29 10:10:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1b119431-e706-462f-9432-645b0eca3222&userId=1ecda672-7ddd-6991-8179-49518582467f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5044806",
        oponentTeamId: "5060007",
        opponent: "1ec9eb6f-490a-620b-a60c-c736e162e257",
        playerTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2051,
        opponentMmrAfter: 2041,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2044,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:24.000Z",
  },
  {
    clientId: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
    name: "Deafflower",
    rank: "Challenger",
    tier: 0,
    topRank: 33,
    vstar: 2080,
    avatar: "buba_joker;silver_s1",
    battles: [
      {
        battleUuid: "ba92cb70-bdec-4632-9821-f4c1ba183197",
        gameTimestamp: "2023-05-29 13:34:57",
        startTimestamp: "2023-05-29 13:31:17",
        endTimestamp: "2023-05-29 13:34:57",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ba92cb70-bdec-4632-9821-f4c1ba183197&userId=1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108093",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2054,
        opponentMmrAfter: 2065,
        opponentSlpWon: 0,
        playerMmrBefore: 2091,
        playerMmrAfter: 2080,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "5bde502b-48b5-4168-9c23-9d5b4c51e6a0",
        gameTimestamp: "2023-05-29 13:30:11",
        startTimestamp: "2023-05-29 13:23:31",
        endTimestamp: "2023-05-29 13:30:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5bde502b-48b5-4168-9c23-9d5b4c51e6a0&userId=1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108093",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2077,
        opponentMmrAfter: 2068,
        opponentSlpWon: 0,
        playerMmrBefore: 2082,
        playerMmrAfter: 2091,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "cff934e4-e3b6-43fd-bf67-5b0ba7c9433f",
        gameTimestamp: "2023-05-29 13:22:31",
        startTimestamp: "2023-05-29 13:15:23",
        endTimestamp: "2023-05-29 13:22:31",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cff934e4-e3b6-43fd-bf67-5b0ba7c9433f&userId=1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108093",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2163,
        opponentMmrAfter: 2151,
        opponentSlpWon: 0,
        playerMmrBefore: 2070,
        playerMmrAfter: 2082,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "801f4c37-2e26-4601-8fd1-daed6c0a1598",
        gameTimestamp: "2023-05-29 13:14:38",
        startTimestamp: "2023-05-29 13:05:53",
        endTimestamp: "2023-05-29 13:14:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=801f4c37-2e26-4601-8fd1-daed6c0a1598&userId=1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108093",
        oponentTeamId: "5107887",
        opponent: "1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        playerTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c070c20c000000010014282045080001000428214102000100001800c1080001000428010104000100041080c2040001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11730561,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2035,
        opponentMmrAfter: 2026,
        opponentSlpWon: 0,
        playerMmrBefore: 2061,
        playerMmrAfter: 2070,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d584b386-c32d-4a82-99dd-87af7a2ff9c8",
        gameTimestamp: "2023-05-29 13:05:21",
        startTimestamp: "2023-05-29 13:01:36",
        endTimestamp: "2023-05-29 13:05:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d584b386-c32d-4a82-99dd-87af7a2ff9c8&userId=1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5108093",
        oponentTeamId: "5104175",
        opponent: "1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        playerTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0c08103000000000100002001440400010000280142080001000018018006000100041041430c00010008204180040001000008618502",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11699815,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2037,
        opponentMmrAfter: 2028,
        opponentSlpWon: 0,
        playerMmrBefore: 2052,
        playerMmrAfter: 2061,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:25.000Z",
  },
  {
    clientId: "1ec9eb6f-45c0-658e-a60c-67ea302bb316",
    name: "Stark | SURA",
    rank: "Challenger",
    tier: 0,
    topRank: 34,
    vstar: 2080,
    avatar: "lion_dance_lunar23;lion_lunar23",
    battles: [
      {
        battleUuid: "2719490d-1d0b-4b70-8b4d-8f2a8e486038",
        gameTimestamp: "2023-05-29 08:05:26",
        startTimestamp: "2023-05-29 08:00:00",
        endTimestamp: "2023-05-29 08:05:26",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2719490d-1d0b-4b70-8b4d-8f2a8e486038&userId=1ec9eb6f-45c0-658e-a60c-67ea302bb316",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096502",
        oponentTeamId: "5111487",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x200000000000010001808050820c00000001001428a1450a0001000820410508000100001800c006000100103080440c000100002880c4060001001010808406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742106,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000708208000000010014280082020001001028408402000100001800c4020001001008818402000100041080c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747524,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c040e0c31000000001001428a1440a0001000428214208000100001860430c0001001430218408000100041081820a000100101081830c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11745511,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100014040e0c30c00000001001428a1450a0001000428a08004000100001800c0060001001430a1850c000100002880c00a000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751686,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2042,
        opponentMmrAfter: 2053,
        opponentSlpWon: 24,
        playerMmrBefore: 2091,
        playerMmrAfter: 2080,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "f5954f77-d5f6-4731-af2b-c9241cfacb7d",
        gameTimestamp: "2023-05-29 07:36:16",
        startTimestamp: "2023-05-29 07:31:09",
        endTimestamp: "2023-05-29 07:36:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f5954f77-d5f6-4731-af2b-c9241cfacb7d&userId=1ec9eb6f-45c0-658e-a60c-67ea302bb316",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096502",
        oponentTeamId: "5073353",
        opponent: "1ec9eb6f-75bd-67f0-a60c-35fbc98984d9",
        playerTeam: [
          {
            gene: "0x200000000000010001808050820c00000001001428a1450a0001000820410508000100001800c006000100103080440c000100002880c4060001001010808406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742106,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000708208000000010014280082020001001028408402000100001800c4020001001008818402000100041080c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747524,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c040e0c31000000001001428a1440a0001000428214208000100001860430c0001001430218408000100041081820a000100101081830c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11745511,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000381c00082080000000100041020840a00010008084104040001001020810204000100042840c40c000100081840c008000100101880c108",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11735237,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001d80082100000000100041020820a000100080840420a0001001020810106000100042821410600010004104141040001000008008406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "",
            },
            axie_id: 11731818,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000240a0b0820c00000001000c08804308000100080820830a0001001020810304000100103081040a00010008182084060001001018010406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11695158,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1835,
        opponentMmrAfter: 1832,
        opponentSlpWon: 0,
        playerMmrBefore: 2088,
        playerMmrAfter: 2091,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "57d2dd08-36b8-4edd-8d02-4dce4d5ee105",
        gameTimestamp: "2023-05-28 23:45:30",
        startTimestamp: "2023-05-28 23:40:29",
        endTimestamp: "2023-05-28 23:45:30",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=57d2dd08-36b8-4edd-8d02-4dce4d5ee105&userId=1ec9eb6f-45c0-658e-a60c-67ea302bb316",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096502",
        oponentTeamId: "5107175",
        opponent: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        playerTeam: [
          {
            gene: "0x200000000000010001808050820c00000001001428a1450a0001000820410508000100001800c006000100103080440c000100002880c4060001001010808406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742106,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000708208000000010014280082020001001028408402000100001800c4020001001008818402000100041080c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747524,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c040e0c31000000001001428a1440a0001000428214208000100001860430c0001001430218408000100041081820a000100101081830c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11745511,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2079,
        opponentMmrAfter: 2069,
        opponentSlpWon: 0,
        playerMmrBefore: 2078,
        playerMmrAfter: 2088,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "77dec23e-6ece-4a3c-bd4f-bb0f52453612",
        gameTimestamp: "2023-05-28 23:39:20",
        startTimestamp: "2023-05-28 23:34:26",
        endTimestamp: "2023-05-28 23:39:20",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=77dec23e-6ece-4a3c-bd4f-bb0f52453612&userId=1ec9eb6f-45c0-658e-a60c-67ea302bb316",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096502",
        oponentTeamId: "4983505",
        opponent: "1eca6432-07f1-6dd6-a667-659767cd1744",
        playerTeam: [
          {
            gene: "0x200000000000010001808050820c00000001001428a1450a0001000820410508000100001800c006000100103080440c000100002880c4060001001010808406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742106,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000708208000000010014280082020001001028408402000100001800c4020001001008818402000100041080c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747524,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c040e0c31000000001001428a1440a0001000428214208000100001860430c0001001430218408000100041081820a000100101081830c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11745511,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100000000e082080000000100041020820a00010008088104040001001020a14504000100042881820c0001000818608206000100101880c308",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11684362,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010180c102080000000100041020850400010008084041080001001020808404000100042880440c000100081840c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_3011_s4_nft",
            },
            axie_id: 11691884,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001404071020c0000000100041020810a00010008084041080001001020810008000100042881410a000100041040c00a0001001018a1800a",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_aquatic_2011_s4_nondec",
              mouth: "ecard_bird_3011_s4_nft",
            },
            axie_id: 11706884,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1866,
        opponentMmrAfter: 1862,
        opponentSlpWon: 0,
        playerMmrBefore: 2074,
        playerMmrAfter: 2078,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b554e4b1-658b-452b-abef-f074dff855a2",
        gameTimestamp: "2023-05-28 22:57:25",
        startTimestamp: "2023-05-28 22:49:42",
        endTimestamp: "2023-05-28 22:57:25",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b554e4b1-658b-452b-abef-f074dff855a2&userId=1ec9eb6f-45c0-658e-a60c-67ea302bb316",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096502",
        oponentTeamId: "5104556",
        opponent: "1ec9eb6f-9ef3-621d-a60c-e5f48bc71648",
        playerTeam: [
          {
            gene: "0x200000000000010001808050820c00000001001428a1450a0001000820410508000100001800c006000100103080440c000100002880c4060001001010808406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742106,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000708208000000010014280082020001001028408402000100001800c4020001001008818402000100041080c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747524,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002c040e0c31000000001001428a1440a0001000428214208000100001860430c0001001430218408000100041081820a000100101081830c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11745511,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002804040c21000000001001428a083040001000428214502000100001800c3080001001430a18108000100002880c20c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748741,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001404090830800000001001428a1450a0001000428214408000100001800c0060001001430a1800c00010000280144060001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a050c20800000001001428a142080001000428214108000100001800c2020001001008a1030200010004108044060001001010a14108",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11709851,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2169,
        opponentMmrAfter: 2157,
        opponentSlpWon: 0,
        playerMmrBefore: 2062,
        playerMmrAfter: 2074,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:26.000Z",
  },
  {
    clientId: "1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
    name: "BonS33 |Anger Issue| Tribe Guild",
    rank: "Challenger",
    tier: 0,
    topRank: 35,
    vstar: 2079,
    avatar: "1;patrick23",
    battles: [
      {
        battleUuid: "8f3951f5-60da-4c81-8d6e-bddd5a786f51",
        gameTimestamp: "2023-05-29 08:36:34",
        startTimestamp: "2023-05-29 08:30:38",
        endTimestamp: "2023-05-29 08:36:34",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8f3951f5-60da-4c81-8d6e-bddd5a786f51&userId=1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5111701",
        oponentTeamId: "5110625",
        opponent: "1ec9eb7e-580f-6839-a60c-fa77eebc5501",
        playerTeam: [
          {
            gene: "0x8000000000001000140a050820800000001000c202143020001000410208104000100043060c10c0001000428218304000100083020c10a000100040820850a",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "ecard_plant_2012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "",
            },
            axie_id: 11547623,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000381c07084100000000100001040400a000100002801400800010000180080060001000028008002000100003060810a0001000008004002",
            runes: ["rune_neutral_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2014_s4",
              mouth: "ecard_beast_tripp_1",
            },
            axie_id: 11531660,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100008040b0840c000000010004086041020001000c20414308000100043000800400010008284145020001000830a0850c0001000828018304",
            runes: ["rune_aquatic_puffy_1"],
            charms: {
              back: "ecard_bird_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2012_s4",
              mouth: "",
            },
            axie_id: 11506304,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003408040c40c0000000100040821010200010004282141080001000018008506000100042821410a000100041020c1080001000408818204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11720939,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c180210210000000010000100080080001000028204508000100001800c0060001000c280100040001000008010006000100000800450a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11581870,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e02084080000000100001001400a0001000008004002000100001041000600010000100080040001000008010008000100002801450c",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 8993015,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "483db494-d779-438c-8aac-998655403f1f",
        gameTimestamp: "2023-05-29 05:30:05",
        startTimestamp: "2023-05-29 05:28:05",
        endTimestamp: "2023-05-29 05:30:05",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=483db494-d779-438c-8aac-998655403f1f&userId=1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5085210",
        oponentTeamId: "4420916",
        opponent: "1ed7aa6c-952e-6ed3-8f08-0b92477b3783",
        playerTeam: [
          {
            gene: "0x80000000000010001c04031040800000001000c1060800400010000288105040001000c2020810600010000100044080001000008a103040001000c1021840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 5356781,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000380407084100000000100000801400a000100002801400a00010000180080060001000008004506000100101801030c0001000008004002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2014_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11533994,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e0708410000000010000100140040001000008004002000100001000830c00010000100080060001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_beast_3010_s4_nft",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3303749,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001c0407102100000000100002840450a000100002801410a000100041000800400010000306142080001000008414102000100001821850c",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_beast_tripp_1",
            },
            axie_id: 11505584,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000380e02084100000000100002801400a000100002801450a0001000030004208000100000800800a0001000020a180020001000808008004",
            runes: ["rune_beast_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1011_s4",
              ears: "ecard_beast_2012_s4_nondec",
              eyes: "ecard_beast_2013_s4_nondec",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_beast_tripp_1",
            },
            axie_id: 11429839,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100038020b084100000000100080800800400010000200102080001000030a0c00c000100000800400200010000080040020001000028818506",
            runes: ["rune_beast_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_1011_s4",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_beast_3012_s4_nondec",
            },
            axie_id: 11542946,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "89055cf9-7215-4da5-bb3a-e2d64793a5c1",
        gameTimestamp: "2023-05-29 05:27:38",
        startTimestamp: "2023-05-29 05:27:31",
        endTimestamp: "2023-05-29 05:27:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=89055cf9-7215-4da5-bb3a-e2d64793a5c1&userId=1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5085210",
        oponentTeamId: "3343373",
        opponent: "1ec9eb6f-53e1-6097-a60c-edbcb4ad3ed4",
        playerTeam: [
          {
            gene: "0x80000000000010001c04031040800000001000c1060800400010000288105040001000c2020810600010000100044080001000008a103040001000c1021840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 5356781,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000380407084100000000100000801400a000100002801400a00010000180080060001000008004506000100101801030c0001000008004002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2014_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11533994,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e0708410000000010000100140040001000008004002000100001000830c00010000100080060001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_beast_3010_s4_nft",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3303749,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0e04082080000000100080880810a00010008102041020001001410008002000100042820c10a00010004082043060001001420004002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_3011_s4",
            },
            axie_id: 11341302,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001408041030800000001000c080104040001000c106043040001000c2860830a00010004286085060001000c182103060001000c30618304",
            runes: ["rune_neutral_3011_s4"],
            charms: {
              back: "ecard_neutral_2014_s4",
              ears: "ecard_plant_2013_s4_nft",
              eyes: "ecard_plant_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_plant_3012_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 10242124,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000101202003080000000100080880800a0001000c20608308000100140820c40800010004082184080001000c182042080001001420a04204",
            runes: ["rune_bird_3012_s4_nondec"],
            charms: {
              back: "ecard_plant_2012_s4_nft",
              ears: "ecard_neutral_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2013_s4",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_neutral_2018_s4_nondec",
            },
            axie_id: 1434032,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0c76b7f1-a937-4fa5-8ca0-ea85d7d0c014",
        gameTimestamp: "2023-05-29 05:27:05",
        startTimestamp: "2023-05-29 05:25:29",
        endTimestamp: "2023-05-29 05:27:05",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0c76b7f1-a937-4fa5-8ca0-ea85d7d0c014&userId=1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5085210",
        oponentTeamId: "3343373",
        opponent: "1ec9eb6f-53e1-6097-a60c-edbcb4ad3ed4",
        playerTeam: [
          {
            gene: "0x80000000000010001c04031040800000001000c1060800400010000288105040001000c2020810600010000100044080001000008a103040001000c1021840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 5356781,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000380407084100000000100000801400a000100002801400a00010000180080060001000008004506000100101801030c0001000008004002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2014_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11533994,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e0708410000000010000100140040001000008004002000100001000830c00010000100080060001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_beast_3010_s4_nft",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3303749,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0e04082080000000100080880810a00010008102041020001001410008002000100042820c10a00010004082043060001001420004002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_3011_s4",
            },
            axie_id: 11341302,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001408041030800000001000c080104040001000c106043040001000c2860830a00010004286085060001000c182103060001000c30618304",
            runes: ["rune_neutral_3011_s4"],
            charms: {
              back: "ecard_neutral_2014_s4",
              ears: "ecard_plant_2013_s4_nft",
              eyes: "ecard_plant_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_plant_3012_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 10242124,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000101202003080000000100080880800a0001000c20608308000100140820c40800010004082184080001000c182042080001001420a04204",
            runes: ["rune_bird_3012_s4_nondec"],
            charms: {
              back: "ecard_plant_2012_s4_nft",
              ears: "ecard_neutral_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2013_s4",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_neutral_2018_s4_nondec",
            },
            axie_id: 1434032,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "016a6fa3-8381-4cf4-887b-4885142c7212",
        gameTimestamp: "2023-05-29 05:25:02",
        startTimestamp: "2023-05-29 05:22:48",
        endTimestamp: "2023-05-29 05:25:02",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=016a6fa3-8381-4cf4-887b-4885142c7212&userId=1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5085210",
        oponentTeamId: "5072231",
        opponent: "1ec9eb7e-3038-6090-a60c-300e3090e1a6",
        playerTeam: [
          {
            gene: "0x80000000000010001c04031040800000001000c1060800400010000288105040001000c2020810600010000100044080001000008a103040001000c1021840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 5356781,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000380407084100000000100000801400a000100002801400a00010000180080060001000008004506000100101801030c0001000008004002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2014_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11533994,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e0708410000000010000100140040001000008004002000100001000830c00010000100080060001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_beast_3010_s4_nft",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3303749,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002412090000000000001000c046023010001000c04602301000100140ca06503000100400e0070030001000c046023010001000c04602301",
            runes: ["rune_neutral_1010_s4"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_plant_2012_s4_nft",
              horn: "ecard_neutral_2018_s4",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_3013_s4_nondec",
            },
            axie_id: 2,
            position: 1,
            axie_type: "starter",
          },
          {
            gene: "0x100000000000010000818021041000000001000808404002000100082841020a000100080840c20c000100080840420200010008202180040001000020a14008",
            runes: ["rune_bird_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 9562775,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412090c30c00000001001020008408000100102841450800010010208044080001001020810408000100102881440a000100101080840a",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nondec",
            },
            axie_id: 9574827,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:26.000Z",
  },
  {
    clientId: "1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
    name: "Furyzera ☁ Red Cloudz",
    rank: "Challenger",
    tier: 0,
    topRank: 36,
    vstar: 2079,
    avatar: "xmas22;gold_s3",
    battles: [
      {
        battleUuid: "708dec8b-fd39-4b5e-96d4-14d84b61e4c5",
        gameTimestamp: "2023-05-29 13:23:59",
        startTimestamp: "2023-05-29 13:18:02",
        endTimestamp: "2023-05-29 13:23:59",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=708dec8b-fd39-4b5e-96d4-14d84b61e4c5&userId=1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106640",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2055,
        opponentMmrAfter: 2066,
        opponentSlpWon: 0,
        playerMmrBefore: 2090,
        playerMmrAfter: 2079,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "22a25792-4e27-49f3-8268-b0ca6bebb9ec",
        gameTimestamp: "2023-05-29 13:16:51",
        startTimestamp: "2023-05-29 13:11:41",
        endTimestamp: "2023-05-29 13:16:51",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=22a25792-4e27-49f3-8268-b0ca6bebb9ec&userId=1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106640",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2059,
        opponentMmrAfter: 2070,
        opponentSlpWon: 0,
        playerMmrBefore: 2101,
        playerMmrAfter: 2090,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "b123f2aa-c09c-46ce-bd00-0db971a3f49c",
        gameTimestamp: "2023-05-29 13:11:09",
        startTimestamp: "2023-05-29 13:05:58",
        endTimestamp: "2023-05-29 13:11:09",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b123f2aa-c09c-46ce-bd00-0db971a3f49c&userId=1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106640",
        oponentTeamId: "5027313",
        opponent: "1ec9eb6f-55df-6e76-a60c-26a5fcc0a616",
        playerTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000301c0c0c40800000001000c0860850a0001000c084083020001000c086180060001000c08a143060001000c2001410a000100000860450a",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11736487,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c00c00000001000c2881430a0001001410a0850a0001000c08a085040001001410a0c5060001001410a085040001001408608502",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 830020,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001014031020000000001000408a1030a000100102081030a0001000c088144060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11731440,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2085,
        opponentMmrAfter: 2095,
        opponentSlpWon: 24,
        playerMmrBefore: 2111,
        playerMmrAfter: 2101,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "de0cf532-a5ac-43ec-bd9e-cfc4e1965ebf",
        gameTimestamp: "2023-05-29 13:05:15",
        startTimestamp: "2023-05-29 12:59:57",
        endTimestamp: "2023-05-29 13:05:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=de0cf532-a5ac-43ec-bd9e-cfc4e1965ebf&userId=1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5106640",
        oponentTeamId: "5047939",
        opponent: "1ec9eb6f-9e55-6fc3-a60c-f57acd8f6ba6",
        playerTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001800070820800000001001428a143040001000428014002000100001800c00c0001001430814406000100101880c4060001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_3013_s4_nondec",
            },
            axie_id: 11731022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c00050020000000001001428a1440400010008208104080001000018a0c408000100041041440c000100101880c4060001001010808308",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "",
            },
            axie_id: 11727491,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010003816050c2080000000100142821450a0001000820810202000100001800c002000100003021440a00010004102085040001001010808402",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11730488,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1978,
        opponentMmrAfter: 1972,
        opponentSlpWon: 0,
        playerMmrBefore: 2105,
        playerMmrAfter: 2111,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "18a488e3-9fcf-4113-80c8-b0c1bad3f6d5",
        gameTimestamp: "2023-05-29 12:46:53",
        startTimestamp: "2023-05-29 12:40:05",
        endTimestamp: "2023-05-29 12:46:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=18a488e3-9fcf-4113-80c8-b0c1bad3f6d5&userId=1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5106640",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2145,
        opponentMmrAfter: 2134,
        opponentSlpWon: 0,
        playerMmrBefore: 2094,
        playerMmrAfter: 2105,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:27.000Z",
  },
  {
    clientId: "1ec9eb6f-57ad-6a83-a60c-edd3b0494c46",
    name: "Melon | Strong Push",
    rank: "Challenger",
    tier: 0,
    topRank: 37,
    vstar: 2078,
    avatar: "2;gold_s1",
    battles: [
      {
        battleUuid: "c2476fe7-7f52-490e-9574-816afb054e42",
        gameTimestamp: "2023-05-29 02:15:43",
        startTimestamp: "2023-05-29 02:12:02",
        endTimestamp: "2023-05-29 02:15:43",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c2476fe7-7f52-490e-9574-816afb054e42&userId=1ec9eb6f-57ad-6a83-a60c-edd3b0494c46",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5087196",
        oponentTeamId: "5108163",
        opponent: "1ec9eb73-5b41-62e0-a60c-299d63c28684",
        playerTeam: [
          {
            gene: "0x200000000000010003808050c00c00000001001428a143080001000428a0410a0001000018014006000100042801800c000100002820800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11733883,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008090c20800000001001428a1450a000100042821410a000100001800c506000100143081440a000100001000c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11740474,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c00090c20800000001001428a14404000100002041020800010000188100060001001030818202000100101880c4060001001010a18508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11734454,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0f801020000000001001428a142020001000c08008102000100001800c00600010004100081040001000c0880c1040001001010808204",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_plant_3010_s4_nondec",
              ears: "ecard_neutral_2015_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11703143,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140f80082080000000100142880850a000100042820430a000100001800c006000100041020800c0001000c0820830a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11710287,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010003401010800c0000000100142841020400010010204144020001000018a041020001001020614004000100002080c5040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11668140,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2045,
        opponentMmrAfter: 2036,
        opponentSlpWon: 0,
        playerMmrBefore: 2069,
        playerMmrAfter: 2078,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "59fbb200-fafa-4899-ae9e-38dc482b557a",
        gameTimestamp: "2023-05-29 02:11:20",
        startTimestamp: "2023-05-29 02:07:43",
        endTimestamp: "2023-05-29 02:11:20",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=59fbb200-fafa-4899-ae9e-38dc482b557a&userId=1ec9eb6f-57ad-6a83-a60c-edd3b0494c46",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087196",
        oponentTeamId: "5100288",
        opponent: "1ed06b56-8485-69d1-99e8-a285bfab2eb5",
        playerTeam: [
          {
            gene: "0x200000000000010003808050c00c00000001001428a143080001000428a0410a0001000018014006000100042801800c000100002820800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11733883,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008090c20800000001001428a1450a000100042821410a000100001800c506000100143081440a000100001000c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11740474,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c00090c20800000001001428a14404000100002041020800010000188100060001001030818202000100101880c4060001001010a18508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11734454,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a050800800000001001428a1450a000100042881020a0001000018008006000100143040450c00010000288084060001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743726,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e040c40c00000001001428604202000100082061020a000100001800c10a000100103021440c000100101820c40a0001001010418508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737174,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408060020c000000010014284042040001000820814102000100001800c20600010014308104080001000410208404000100101080840a",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730540,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2009,
        opponentMmrAfter: 2021,
        opponentSlpWon: 24,
        playerMmrBefore: 2081,
        playerMmrAfter: 2069,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "d6facbc0-3b5b-477b-9b2d-f32be2e475d1",
        gameTimestamp: "2023-05-29 01:10:29",
        startTimestamp: "2023-05-29 01:05:17",
        endTimestamp: "2023-05-29 01:10:29",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d6facbc0-3b5b-477b-9b2d-f32be2e475d1&userId=1ec9eb6f-57ad-6a83-a60c-edd3b0494c46",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5087196",
        oponentTeamId: "5088916",
        opponent: "1ec9eb7e-5459-62ce-a60c-4616a14edfeb",
        playerTeam: [
          {
            gene: "0x200000000000010003808050c00c00000001001428a143080001000428a0410a0001000018014006000100042801800c000100002820800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11733883,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008090c20800000001001428a1450a000100042821410a000100001800c506000100143081440a000100001000c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11740474,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c00090c20800000001001428a14404000100002041020800010000188100060001001030818202000100101880c4060001001010a18508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11734454,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a06083080000000100142820450a0001000428a1430a000100001800c00600010004282141040001000410a14002000100101080810a",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11726545,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a0001001020814204000100001800c0060001001430a1050c000100002880c10a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11725856,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100038000d1030c00000001001428808004000100040881400400010000184080060001001030818504000100101880c2060001001010808102",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2018_s4_nft",
            },
            axie_id: 11724482,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1847,
        opponentMmrAfter: 1843,
        opponentSlpWon: 0,
        playerMmrBefore: 2077,
        playerMmrAfter: 2081,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7556eed3-955a-4db6-8d4e-e901cc118964",
        gameTimestamp: "2023-05-29 01:04:13",
        startTimestamp: "2023-05-29 00:58:10",
        endTimestamp: "2023-05-29 01:04:13",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7556eed3-955a-4db6-8d4e-e901cc118964&userId=1ec9eb6f-57ad-6a83-a60c-edd3b0494c46",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087196",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x200000000000010003808050c00c00000001001428a143080001000428a0410a0001000018014006000100042801800c000100002820800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11733883,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008090c20800000001001428a1450a000100042821410a000100001800c506000100143081440a000100001000c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11740474,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c00090c20800000001001428a14404000100002041020800010000188100060001001030818202000100101880c4060001001010a18508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11734454,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018180610208000000010014284045040001000428010102000100001800c30c000100143041450c0001000028a08102000100101041440a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733032,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2075,
        opponentMmrAfter: 2085,
        opponentSlpWon: 24,
        playerMmrBefore: 2087,
        playerMmrAfter: 2077,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "f58df21d-ecab-4ace-8075-e195bc2a519e",
        gameTimestamp: "2023-05-29 00:57:23",
        startTimestamp: "2023-05-29 00:52:08",
        endTimestamp: "2023-05-29 00:57:23",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f58df21d-ecab-4ace-8075-e195bc2a519e&userId=1ec9eb6f-57ad-6a83-a60c-edd3b0494c46",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087196",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x200000000000010003808050c00c00000001001428a143080001000428a0410a0001000018014006000100042801800c000100002820800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11733883,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008090c20800000001001428a1450a000100042821410a000100001800c506000100143081440a000100001000c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11740474,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c00090c20800000001001428a14404000100002041020800010000188100060001001030818202000100101880c4060001001010a18508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11734454,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002c1c090840000000001000010414104000100002801400a0001000018210006000100101801400c00010004102081040001000008004102",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11726128,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008160208210000000010000100101040001001028a0400400010000304100020001000008004002000100000800c208000100001800c00a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11567779,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2072,
        opponentMmrAfter: 2082,
        opponentSlpWon: 24,
        playerMmrBefore: 2097,
        playerMmrAfter: 2087,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:28.000Z",
  },
  {
    clientId: "1ed57738-fb5b-67da-895c-8d36800bd76c",
    name: "ookoo",
    rank: "Challenger",
    tier: 0,
    topRank: 38,
    vstar: 2078,
    avatar: "18;gold_09_s2",
    battles: [
      {
        battleUuid: "a5e02536-097b-4a4f-8f9b-036550c49762",
        gameTimestamp: "2023-05-29 13:33:21",
        startTimestamp: "2023-05-29 13:26:38",
        endTimestamp: "2023-05-29 13:33:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a5e02536-097b-4a4f-8f9b-036550c49762&userId=1ed57738-fb5b-67da-895c-8d36800bd76c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108308",
        oponentTeamId: "5011198",
        opponent: "1ec9eb6f-46e7-65f1-a60c-d36d819a685f",
        playerTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002f01011020800000001000c0860830a0001000c0860410a0001000c30608402000100043060810c0001000c1080430c0001001418a0c302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 527643,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10002004070c20c0000000100001001400a0001000008004504000100001021450c000100001000830800010000080040020001000028014004",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 3240706,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000380c060840800000001000c106043040001000c104103040001000c206183040001000c286143040001000c1860c1060001000c1000430c",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nondec",
            },
            axie_id: 10442572,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1917,
        opponentMmrAfter: 1912,
        opponentSlpWon: 0,
        playerMmrBefore: 2073,
        playerMmrAfter: 2078,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4950ca52-4e81-4445-b9a7-4a5cb69faf31",
        gameTimestamp: "2023-05-29 13:25:33",
        startTimestamp: "2023-05-29 13:20:45",
        endTimestamp: "2023-05-29 13:25:33",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4950ca52-4e81-4445-b9a7-4a5cb69faf31&userId=1ed57738-fb5b-67da-895c-8d36800bd76c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108308",
        oponentTeamId: "5101861",
        opponent: "1ec9eb6f-4b40-6235-a60c-5c5b3d3c2a2d",
        playerTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000381c0e082080000000100001060800400010008084044080001001020a14408000100042881440c0001000818204404000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11684914,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c120708010000000010010088080020001001028810408000100102080c408000100103081840200010008182144060001001018808404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_puffy_1",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11624171,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c04082080000000100041020810400010008084042020001001020810504000100100820c40c000100081840c2060001000008004002",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11735554,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1859,
        opponentMmrAfter: 1855,
        opponentSlpWon: 0,
        playerMmrBefore: 2069,
        playerMmrAfter: 2073,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "601b3fa6-a7d3-4ac3-b3a9-f855ebfdee43",
        gameTimestamp: "2023-05-29 13:19:49",
        startTimestamp: "2023-05-29 13:13:43",
        endTimestamp: "2023-05-29 13:19:49",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=601b3fa6-a7d3-4ac3-b3a9-f855ebfdee43&userId=1ed57738-fb5b-67da-895c-8d36800bd76c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108308",
        oponentTeamId: "5101048",
        opponent: "1eca7faf-813a-6bd2-9fe9-581dcef10f70",
        playerTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001410021020800000001000c200080040001000028004002000100001880c5020001000010418004000100000840c3060001000008004204",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11518730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008040b0801000000001000010a140040001000020004008000100003001800c00010000080040020001000008004002000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11591470,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000180e061031000000001000c086043040001000c088083020001000c2061830a0001000c2860c4080001000c3020430c0001000c10610102",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 416844,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2006,
        opponentMmrAfter: 1998,
        opponentSlpWon: 0,
        playerMmrBefore: 2061,
        playerMmrAfter: 2069,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1c4ff988-a3c4-43df-bb06-4ac16be4f386",
        gameTimestamp: "2023-05-29 13:13:10",
        startTimestamp: "2023-05-29 13:08:11",
        endTimestamp: "2023-05-29 13:13:10",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1c4ff988-a3c4-43df-bb06-4ac16be4f386&userId=1ed57738-fb5b-67da-895c-8d36800bd76c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108308",
        oponentTeamId: "5095412",
        opponent: "1ec9eb7e-6a0d-6e97-a60c-aee122ad13a1",
        playerTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0a04083000000000100142840850a0001000428214102000100001800c006000100143021400800010004102040040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724393,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000140a050830800000001001428a1450a000100042881020800010000182140060001001430a183080001000028208306000100101020c102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749954,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c040821000000001001428a1450a0001000010410408000100001800c0060001001030a1820c000100101800810a0001001010808506",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11752896,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2037,
        opponentMmrAfter: 2047,
        opponentSlpWon: 24,
        playerMmrBefore: 2071,
        playerMmrAfter: 2061,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "62ae011b-2d60-4f37-99fe-8f78cec53fb8",
        gameTimestamp: "2023-05-29 13:07:17",
        startTimestamp: "2023-05-29 13:02:54",
        endTimestamp: "2023-05-29 13:07:17",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=62ae011b-2d60-4f37-99fe-8f78cec53fb8&userId=1ed57738-fb5b-67da-895c-8d36800bd76c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108308",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2008,
        opponentMmrAfter: 2000,
        opponentSlpWon: 0,
        playerMmrBefore: 2063,
        playerMmrAfter: 2071,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:28.000Z",
  },
  {
    clientId: "1ed68029-91ad-621c-8d13-709ea70f2723",
    name: "YOJI09",
    rank: "Challenger",
    tier: 0,
    topRank: 39,
    vstar: 2074,
    avatar: "s3_top20k;bronze_s2",
    battles: [
      {
        battleUuid: "9b1bc547-a9b8-4904-aea7-923f567758ef",
        gameTimestamp: "2023-05-29 13:35:10",
        startTimestamp: "2023-05-29 13:30:34",
        endTimestamp: "2023-05-29 13:35:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9b1bc547-a9b8-4904-aea7-923f567758ef&userId=1ed68029-91ad-621c-8d13-709ea70f2723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5050689",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2116,
        opponentMmrAfter: 2105,
        opponentSlpWon: 0,
        playerMmrBefore: 2074,
        playerMmrAfter: 2085,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e08acd3f-0eb3-46e0-97ff-acf3e1cb3990",
        gameTimestamp: "2023-05-29 13:29:11",
        startTimestamp: "2023-05-29 13:24:46",
        endTimestamp: "2023-05-29 13:29:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e08acd3f-0eb3-46e0-97ff-acf3e1cb3990&userId=1ed68029-91ad-621c-8d13-709ea70f2723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5050689",
        oponentTeamId: "5108521",
        opponent: "1ed65bf6-c991-663b-b49e-940ffd623333",
        playerTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000c040e0820800000001000410208304000100080860820200010010202141080001000428814104000100081840c304000100101880c406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_aquatic_3010_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711649,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c0603082080000000100041020830800010008084042020001001020a08408000100103060840c000100081840c206000100000800440c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745112,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001006001c080c40000000001000c0860420a00010008084042020001001020810206000100042821410c0001000410804002000100101880c406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3011_s4_nondec",
            },
            axie_id: 11748430,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2013,
        opponentMmrAfter: 2005,
        opponentSlpWon: 0,
        playerMmrBefore: 2066,
        playerMmrAfter: 2074,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "708dec8b-fd39-4b5e-96d4-14d84b61e4c5",
        gameTimestamp: "2023-05-29 13:23:59",
        startTimestamp: "2023-05-29 13:18:02",
        endTimestamp: "2023-05-29 13:23:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=708dec8b-fd39-4b5e-96d4-14d84b61e4c5&userId=1ed68029-91ad-621c-8d13-709ea70f2723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5050689",
        oponentTeamId: "5106640",
        opponent: "1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        playerTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2090,
        opponentMmrAfter: 2079,
        opponentSlpWon: 0,
        playerMmrBefore: 2055,
        playerMmrAfter: 2066,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "18fa4e34-2a86-4add-9a17-f6d5f3085020",
        gameTimestamp: "2023-05-29 13:17:22",
        startTimestamp: "2023-05-29 13:12:19",
        endTimestamp: "2023-05-29 13:17:22",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=18fa4e34-2a86-4add-9a17-f6d5f3085020&userId=1ed68029-91ad-621c-8d13-709ea70f2723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5050689",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2065,
        opponentMmrAfter: 2074,
        opponentSlpWon: 0,
        playerMmrBefore: 2064,
        playerMmrAfter: 2055,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "97032aaf-6ee3-4120-95f5-9201f6984709",
        gameTimestamp: "2023-05-29 13:11:28",
        startTimestamp: "2023-05-29 13:07:37",
        endTimestamp: "2023-05-29 13:11:28",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=97032aaf-6ee3-4120-95f5-9201f6984709&userId=1ed68029-91ad-621c-8d13-709ea70f2723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5050689",
        oponentTeamId: "5021551",
        opponent: "1ec9eb77-7cc0-6e01-a60c-740323563770",
        playerTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a0e0c20c00000001001428604404000100042821010a000100001800c0060001001430a18402000100101801420c0001001010808006",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_3011_s4_nondec",
            },
            axie_id: 11743960,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014040e1031000000001001428a1450a0001000428210408000100001800c0060001001430a1850c00010000288044060001001010808504",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11743759,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000c0e090840c0000000100142881410a00010010288100040001000018604206000100103020840c00010010182045040001001010808502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11723474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1913,
        opponentMmrAfter: 1927,
        opponentSlpWon: 22,
        playerMmrBefore: 2078,
        playerMmrAfter: 2064,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:29.000Z",
  },
  {
    clientId: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
    name: "Shattermymonster",
    rank: "Challenger",
    tier: 0,
    topRank: 40,
    vstar: 2074,
    avatar: "s3_top1k;fluffy_hooves",
    battles: [
      {
        battleUuid: "f0d6b19e-18e0-4e80-988b-b8bbacab3a87",
        gameTimestamp: "2023-05-29 13:21:28",
        startTimestamp: "2023-05-29 13:16:00",
        endTimestamp: "2023-05-29 13:21:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f0d6b19e-18e0-4e80-988b-b8bbacab3a87&userId=1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106158",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2135,
        opponentMmrAfter: 2123,
        opponentSlpWon: 0,
        playerMmrBefore: 2062,
        playerMmrAfter: 2074,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e9b24345-8045-4fe2-8260-6154c7a179c6",
        gameTimestamp: "2023-05-29 13:14:22",
        startTimestamp: "2023-05-29 13:10:33",
        endTimestamp: "2023-05-29 13:14:22",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e9b24345-8045-4fe2-8260-6154c7a179c6&userId=1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106158",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2156,
        opponentMmrAfter: 2163,
        opponentSlpWon: 0,
        playerMmrBefore: 2069,
        playerMmrAfter: 2062,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "16e987c6-dae0-401f-8601-4010406c58c9",
        gameTimestamp: "2023-05-29 13:08:47",
        startTimestamp: "2023-05-29 13:04:56",
        endTimestamp: "2023-05-29 13:08:47",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=16e987c6-dae0-401f-8601-4010406c58c9&userId=1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106158",
        oponentTeamId: "5105119",
        opponent: "1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        playerTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2029,
        opponentMmrAfter: 2040,
        opponentSlpWon: 0,
        playerMmrBefore: 2080,
        playerMmrAfter: 2069,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "4f5d0635-73d8-4bb0-bf7d-efdcfcc35f40",
        gameTimestamp: "2023-05-29 13:03:42",
        startTimestamp: "2023-05-29 12:58:54",
        endTimestamp: "2023-05-29 13:03:42",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4f5d0635-73d8-4bb0-bf7d-efdcfcc35f40&userId=1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106158",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2078,
        opponentMmrAfter: 2068,
        opponentSlpWon: 0,
        playerMmrBefore: 2070,
        playerMmrAfter: 2080,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e6ed8bb9-2361-43ef-9a90-9292e4f21ca5",
        gameTimestamp: "2023-05-29 12:58:02",
        startTimestamp: "2023-05-29 12:52:09",
        endTimestamp: "2023-05-29 12:58:02",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e6ed8bb9-2361-43ef-9a90-9292e4f21ca5&userId=1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5106158",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1988,
        opponentMmrAfter: 1981,
        opponentSlpWon: 0,
        playerMmrBefore: 2063,
        playerMmrAfter: 2070,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:30.000Z",
  },
  {
    clientId: "1ec9eb6f-58ce-6da8-a60c-06c1e90c53dc",
    name: "Kamote LFG",
    rank: "Challenger",
    tier: 0,
    topRank: 41,
    vstar: 2072,
    avatar: "easter_momo;silver_s3",
    battles: [
      {
        battleUuid: "9e3f987b-8da3-44cd-a9b0-d40b3f7f400e",
        gameTimestamp: "2023-05-29 10:29:03",
        startTimestamp: "2023-05-29 10:24:25",
        endTimestamp: "2023-05-29 10:29:03",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9e3f987b-8da3-44cd-a9b0-d40b3f7f400e&userId=1ec9eb6f-58ce-6da8-a60c-06c1e90c53dc",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5076681",
        oponentTeamId: "5078551",
        opponent: "1ed88ffd-e20d-6eb3-8602-883cbaad5512",
        playerTeam: [
          {
            gene: "0x800000000000010001c0e0e0820800000001001428a1450a0001000428414208000100001800c10c0001001430a1810800010010182081040001001010808306",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11738577,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000301c020820800000001001428a1420a000100042880440800010000188080060001001430a1850c0001000028a08208000100101080850c",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11725646,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c09082080000000100142861450a000100082060440800010000186180060001001030a14406000100101880c00a000100101080810a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11716376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000341a0b0800800000001001428404404000100042860420400010000182044060001001430a102040001000028a180020001001010608102",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11741635,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101a050041000000001001428a14504000100102881410400010000182100060001001030814306000100101880c406000100101040410c",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11721363,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400000820800000001001428a14402000100102821420400010000188185080001001030818308000100101880c4060001001010a10404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11713969,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1912,
        opponentMmrAfter: 1907,
        opponentSlpWon: 0,
        playerMmrBefore: 2067,
        playerMmrAfter: 2072,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ae2a3fc1-fd73-4b1d-a7f8-c276998bf084",
        gameTimestamp: "2023-05-29 10:23:18",
        startTimestamp: "2023-05-29 10:18:34",
        endTimestamp: "2023-05-29 10:23:18",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ae2a3fc1-fd73-4b1d-a7f8-c276998bf084&userId=1ec9eb6f-58ce-6da8-a60c-06c1e90c53dc",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5076681",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x800000000000010001c0e0e0820800000001001428a1450a0001000428414208000100001800c10c0001001430a1810800010010182081040001001010808306",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11738577,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000301c020820800000001001428a1420a000100042880440800010000188080060001001430a1850c0001000028a08208000100101080850c",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11725646,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c09082080000000100142861450a000100082060440800010000186180060001001030a14406000100101880c00a000100101080810a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11716376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2076,
        opponentMmrAfter: 2066,
        opponentSlpWon: 0,
        playerMmrBefore: 2057,
        playerMmrAfter: 2067,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "af7ba387-8a63-487b-ba51-89f61d3cc6d9",
        gameTimestamp: "2023-05-29 09:42:18",
        startTimestamp: "2023-05-29 09:36:34",
        endTimestamp: "2023-05-29 09:42:18",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=af7ba387-8a63-487b-ba51-89f61d3cc6d9&userId=1ec9eb6f-58ce-6da8-a60c-06c1e90c53dc",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5076681",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x800000000000010001c0e0e0820800000001001428a1450a0001000428414208000100001800c10c0001001430a1810800010010182081040001001010808306",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11738577,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000301c020820800000001001428a1420a000100042880440800010000188080060001001430a1850c0001000028a08208000100101080850c",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11725646,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c09082080000000100142861450a000100082060440800010000186180060001001030a14406000100101880c00a000100101080810a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11716376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100014080e0000c0000000100142840450a000100042880410a000100001800840c0001000c2820850c00010000088045060001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748710,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2145,
        opponentMmrAfter: 2133,
        opponentSlpWon: 0,
        playerMmrBefore: 2045,
        playerMmrAfter: 2057,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b3addd8f-9af1-4f78-90e2-7d7bac8c0e72",
        gameTimestamp: "2023-05-28 20:27:43",
        startTimestamp: "2023-05-28 20:22:03",
        endTimestamp: "2023-05-28 20:27:43",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b3addd8f-9af1-4f78-90e2-7d7bac8c0e72&userId=1ec9eb6f-58ce-6da8-a60c-06c1e90c53dc",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5076681",
        oponentTeamId: "5021791",
        opponent: "1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        playerTeam: [
          {
            gene: "0x800000000000010001c0e0e0820800000001001428a1450a0001000428414208000100001800c10c0001001430a1810800010010182081040001001010808306",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11738577,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000301c020820800000001001428a1420a000100042880440800010000188080060001001430a1850c0001000028a08208000100101080850c",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11725646,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c09082080000000100142861450a000100082060440800010000186180060001001030a14406000100101880c00a000100101080810a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11716376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000c1c000421000000001000410214104000100080840420200010010204044080001000428214202000100081840c206000100101880c106",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11735278,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010180c082080000000100041000440800010008084042020001001020810408000100103080410a000100081840c2060001000008614002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11676729,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003300070c10400000001000c0860450200010008082082020001001020614408000100042880c008000100081840c206000100101880c406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11702071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2102,
        opponentMmrAfter: 2091,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2045,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8cae1ffa-8a02-4b0c-9a89-8c461b2f7509",
        gameTimestamp: "2023-05-28 20:21:22",
        startTimestamp: "2023-05-28 20:15:52",
        endTimestamp: "2023-05-28 20:21:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8cae1ffa-8a02-4b0c-9a89-8c461b2f7509&userId=1ec9eb6f-58ce-6da8-a60c-06c1e90c53dc",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5076681",
        oponentTeamId: "5107062",
        opponent: "1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
        playerTeam: [
          {
            gene: "0x800000000000010001c0e0e0820800000001001428a1450a0001000428414208000100001800c10c0001001430a1810800010010182081040001001010808306",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11738577,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000301c020820800000001001428a1420a000100042880440800010000188080060001001430a1850c0001000028a08208000100101080850c",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11725646,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c09082080000000100142861450a000100082060440800010000186180060001001030a14406000100101880c00a000100101080810a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11716376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001e02071030c00000001000410208508000100002860400400010000086083040001000c282043060001000c3061830c0001000018a18002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11508847,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1007104100000000100041061450800010000280080080001000c106080020001000c2820440a000100000881810a0001000810014002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2019_s4",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11491605,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0409104100000000100000801400a0001000008a080020001000010a0c30400010000100082080001000008418202000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 652546,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1981,
        opponentMmrAfter: 1973,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2034,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:30.000Z",
  },
  {
    clientId: "1ec9eb6f-53ad-6f46-a60c-c956174fd50c",
    name: "Maia.GG | NFG Club ",
    rank: "Challenger",
    tier: 0,
    topRank: 42,
    vstar: 2070,
    avatar: "0;silver_s1",
    battles: [
      {
        battleUuid: "1b55fd5c-ffee-4a17-b24a-d434fd320794",
        gameTimestamp: "2023-05-29 06:33:14",
        startTimestamp: "2023-05-29 06:27:54",
        endTimestamp: "2023-05-29 06:33:14",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1b55fd5c-ffee-4a17-b24a-d434fd320794&userId=1ec9eb6f-53ad-6f46-a60c-c956174fd50c",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102522",
        oponentTeamId: "5034391",
        opponent: "1ec9eb6f-6196-640c-a60c-df6605401e89",
        playerTeam: [
          {
            gene: "0x80000000000010003018020830800000001001428a1450a00010004282101080001000018a1840a0001001430a1850c00010000284101020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749517,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c070831000000001001428a1450a0001000820408508000100001800c402000100100801440c0001001018014406000100101080800c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11733812,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c30c00000001001428a144040001000428214208000100001860c0060001001430a185080001000410208508000100101080800c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_3011_s4_nondec",
            },
            axie_id: 11749865,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0e07082080000000100041060420a000100080801050400010010202044080001000428404206000100081860c2060001001018208406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11724777,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000301c04082080000000100041020810400010008084042020001001020810404000100103040c4020001000818804102000100000880c40c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11699445,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000100a020c31000000001000410208402000100080840420200010010208083060001000428418204000100041040c306000100101861440a",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11725991,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1978,
        opponentMmrAfter: 1971,
        opponentSlpWon: 0,
        playerMmrBefore: 2063,
        playerMmrAfter: 2070,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6b65300d-7553-4dd5-b658-22eaadf3c9e3",
        gameTimestamp: "2023-05-29 06:21:11",
        startTimestamp: "2023-05-29 06:17:02",
        endTimestamp: "2023-05-29 06:21:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6b65300d-7553-4dd5-b658-22eaadf3c9e3&userId=1ec9eb6f-53ad-6f46-a60c-c956174fd50c",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102522",
        oponentTeamId: "5108873",
        opponent: "1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        playerTeam: [
          {
            gene: "0x80000000000010003018020830800000001001428a1450a00010004282101080001000018a1840a0001001430a1850c00010000284101020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749517,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c070831000000001001428a1450a0001000820408508000100001800c402000100100801440c0001001018014406000100101080800c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11733812,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c30c00000001001428a144040001000428214208000100001860c0060001001430a185080001000410208508000100101080800c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_3011_s4_nondec",
            },
            axie_id: 11749865,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2062,
        opponentMmrAfter: 2052,
        opponentSlpWon: 0,
        playerMmrBefore: 2053,
        playerMmrAfter: 2063,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d5de6ff1-e48e-4943-87d7-9d09adb1fc97",
        gameTimestamp: "2023-05-29 06:16:33",
        startTimestamp: "2023-05-29 06:12:30",
        endTimestamp: "2023-05-29 06:16:33",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d5de6ff1-e48e-4943-87d7-9d09adb1fc97&userId=1ec9eb6f-53ad-6f46-a60c-c956174fd50c",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102522",
        oponentTeamId: "5026311",
        opponent: "1ec9eb6f-8e03-67d9-a60c-a0cd8ab0ef27",
        playerTeam: [
          {
            gene: "0x80000000000010003018020830800000001001428a1450a00010004282101080001000018a1840a0001001430a1850c00010000284101020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749517,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c070831000000001001428a1450a0001000820408508000100001800c402000100100801440c0001001018014406000100101080800c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11733812,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c30c00000001001428a144040001000428214208000100001860c0060001001430a185080001000410208508000100101080800c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_3011_s4_nondec",
            },
            axie_id: 11749865,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e07002040000000100041020830200010008084042020001001020404408000100042881810a000100081840c206000100101880c20c",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11713769,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c0c082100000000100041081010400010008084041080001001020810408000100103040c10a000100081840c4040001000008818002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11705793,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010060012080840800000001000c088083020001000808004504000100102000450a00010004282141060001000410014102000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11712809,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1965,
        opponentMmrAfter: 1958,
        opponentSlpWon: 0,
        playerMmrBefore: 2046,
        playerMmrAfter: 2053,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5ffdb54e-667d-4192-af8c-2a652bcaf3cd",
        gameTimestamp: "2023-05-29 06:11:28",
        startTimestamp: "2023-05-29 06:06:41",
        endTimestamp: "2023-05-29 06:11:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5ffdb54e-667d-4192-af8c-2a652bcaf3cd&userId=1ec9eb6f-53ad-6f46-a60c-c956174fd50c",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5102522",
        oponentTeamId: "5079895",
        opponent: "1ec9eb7e-419e-6fd0-a60c-c2b772b5e7ca",
        playerTeam: [
          {
            gene: "0x80000000000010003018020830800000001001428a1450a00010004282101080001000018a1840a0001001430a1850c00010000284101020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749517,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c070831000000001001428a1450a0001000820408508000100001800c402000100100801440c0001001018014406000100101080800c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11733812,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c30c00000001001428a144040001000428214208000100001860c0060001001430a185080001000410208508000100101080800c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_3011_s4_nondec",
            },
            axie_id: 11749865,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c00050820800000001001428a14002000100042821450200010000180145060001001430818008000100101880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11739413,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000050830800000001001428a140040001000010804404000100001800c006000100103081840c000100101820820c0001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11739259,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000060c0080000000100142821410a0001001028604008000100001800c0060001001008818402000100081881840c000100101080840c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11747515,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1949,
        opponentMmrAfter: 1942,
        opponentSlpWon: 0,
        playerMmrBefore: 2039,
        playerMmrAfter: 2046,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "30bcede2-0138-4199-93b6-b44c6cb5bfff",
        gameTimestamp: "2023-05-29 06:05:48",
        startTimestamp: "2023-05-29 06:00:30",
        endTimestamp: "2023-05-29 06:05:48",
        result: "D",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=30bcede2-0138-4199-93b6-b44c6cb5bfff&userId=1ec9eb6f-53ad-6f46-a60c-c956174fd50c",
        battleType: "pvp",
        playerSlpWon: 12,
        playerTeamId: "5102522",
        oponentTeamId: "5081096",
        opponent: "1ec9eb6f-6b7e-6ab9-a60c-a0047de0946b",
        playerTeam: [
          {
            gene: "0x80000000000010003018020830800000001001428a1450a00010004282101080001000018a1840a0001001430a1850c00010000284101020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749517,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100c070831000000001001428a1450a0001000820408508000100001800c402000100100801440c0001001018014406000100101080800c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11733812,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c30c00000001001428a144040001000428214208000100001860c0060001001430a185080001000410208508000100101080800c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_3011_s4_nondec",
            },
            axie_id: 11749865,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010000804040c310000000010004102081040001000808404202000100102081040400010004282142040001000818208104000100101800c506",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11684063,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008021030800000001000410010104000100080840410800010010208103060001001030a0810a0001000818604302000100000800400a",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691913,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002b01800420800000001000c08208104000100080881020200010010204104080001000428214206000100041040c1040001001018a18406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11747635,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2017,
        opponentMmrAfter: 2017,
        opponentSlpWon: 12,
        playerMmrBefore: 2039,
        playerMmrAfter: 2039,
        playerMoonshardWon: 248,
        opponentMoonshardWon: 248,
      },
    ],
    lastUpdated: "2023-05-29T13:35:31.000Z",
  },
  {
    clientId: "1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
    name: "Lovebug",
    rank: "Challenger",
    tier: 0,
    topRank: 43,
    vstar: 2070,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "a958e5a3-1ade-42fe-88e5-d87e8ad947ce",
        gameTimestamp: "2023-05-29 09:27:08",
        startTimestamp: "2023-05-29 09:21:36",
        endTimestamp: "2023-05-29 09:27:08",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a958e5a3-1ade-42fe-88e5-d87e8ad947ce&userId=1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108873",
        oponentTeamId: "5091036",
        opponent: "1ec9eb73-5d3a-6a8a-a60c-6d41702e9a80",
        playerTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c16040820800000001001428a1450a000100042860850a0001000018214006000100143021440c000100041000c1040001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11738617,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240a05083100000000100142820850a000100042881010a000100001800c006000100143020800600010000180141040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721724,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000141a040820c0000000100142861050a00010014280082080001000018018006000100103081830400010010180184060001001010204404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11740692,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2006,
        opponentMmrAfter: 1998,
        opponentSlpWon: 0,
        playerMmrBefore: 2062,
        playerMmrAfter: 2070,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6cc88014-d210-4357-8451-b8aebe1ea6c0",
        gameTimestamp: "2023-05-29 09:20:50",
        startTimestamp: "2023-05-29 09:15:57",
        endTimestamp: "2023-05-29 09:20:50",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6cc88014-d210-4357-8451-b8aebe1ea6c0&userId=1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108873",
        oponentTeamId: "5105092",
        opponent: "1ecb6080-f8d7-638f-8ba8-beb9cd2501e7",
        playerTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c1a0708208000000010010104100040001000028410104000100001881840c0001001030008402000100103080c0020001000c10808306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_aquatic_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_plant_2011_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11681982,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c100208410000000010000100080040001000008004102000100001000c004000100000820c0020001000008004504000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11501859,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07002000000000100000800850400010000204044080001000018008004000100002801000200010000080080060001000c1000c10c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11553102,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2003,
        opponentMmrAfter: 1995,
        opponentSlpWon: 0,
        playerMmrBefore: 2054,
        playerMmrAfter: 2062,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "da2b023c-b434-4fb9-a2a6-da09d1de046a",
        gameTimestamp: "2023-05-29 09:15:18",
        startTimestamp: "2023-05-29 09:09:34",
        endTimestamp: "2023-05-29 09:15:18",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=da2b023c-b434-4fb9-a2a6-da09d1de046a&userId=1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108873",
        oponentTeamId: "5049406",
        opponent: "1ec9eb6f-a5e3-63b9-a60c-ad5183c64c4b",
        playerTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x28000000000001000180c040c30c000000010014088145020001000820a1440200010014206185020001000c30a100040001001418a0c30c0001000c2800430a",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_reptile_3010_s4_nondec",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11724799,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010000c00040c20c00000001001410a0430a000100142861450a00010000186085080001001420a1010a00010014180145060001000408a14102",
            runes: ["rune_reptile_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2017_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11696219,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000080e0820800000001001408010502000100142820450a0001001420a1050c0001001420a101080001001418a0c40c0001000c2861450c",
            runes: ["rune_plant_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_reptile_3010_s4_nft",
              horn: "ecard_reptile_2012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11747077,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1812,
        opponentMmrAfter: 1808,
        opponentSlpWon: 0,
        playerMmrBefore: 2050,
        playerMmrAfter: 2054,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9e325504-69b5-426e-b16c-17920824997d",
        gameTimestamp: "2023-05-29 09:08:34",
        startTimestamp: "2023-05-29 09:03:53",
        endTimestamp: "2023-05-29 09:08:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9e325504-69b5-426e-b16c-17920824997d&userId=1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108873",
        oponentTeamId: "5111727",
        opponent: "1ec9eb6f-409e-6014-a60c-9aeb1cf26bc7",
        playerTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002416060820800000001001428a14002000100042821410a000100001801410a000100041021450c000100101880c1040001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747112,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038000d08210000000010014288080040001001020008402000100001861030a000100103081840c00010000108084060001001010808404",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 11703232,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0807082080000000100142840450a00010010208101040001000018610006000100143081810a000100101880c1020001000008814404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11724581,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1949,
        opponentMmrAfter: 1942,
        opponentSlpWon: 0,
        playerMmrBefore: 2043,
        playerMmrAfter: 2050,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4b685f10-151f-4db2-862d-141f432f8398",
        gameTimestamp: "2023-05-29 09:02:15",
        startTimestamp: "2023-05-29 08:55:41",
        endTimestamp: "2023-05-29 09:02:15",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4b685f10-151f-4db2-862d-141f432f8398&userId=1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108873",
        oponentTeamId: "4824021",
        opponent: "1ec9eb6f-6fa3-69b1-a60c-2702aa02d150",
        playerTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000301c0c1030c0000000100002860410200010004284083040001000c086041080001000c080144040001000c1021430a0001000008604302",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11737285,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002404090000c00000001000c0860820800010000200105040001000c0821830a0001000c08604208000100041881020c000100141881820a",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2012_s4_nft",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11746565,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024100e1021000000001000428a0800a000100002061000a0001000c080180020001000c0820c30a000100081021430c0001000c10604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2012_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bug_2012_s4_nft",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11666336,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1981,
        opponentMmrAfter: 1993,
        opponentSlpWon: 22,
        playerMmrBefore: 2055,
        playerMmrAfter: 2043,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:31.000Z",
  },
  {
    clientId: "1ed264ea-e4ff-6278-aad0-7c8421181f38",
    name: "Mitt",
    rank: "Challenger",
    tier: 0,
    topRank: 44,
    vstar: 2069,
    avatar: "theorycrafter_s2;silver_s1",
    battles: [
      {
        battleUuid: "bf171415-a39f-4301-be16-f9d3f5b7825e",
        gameTimestamp: "2023-05-29 02:55:41",
        startTimestamp: "2023-05-29 02:49:15",
        endTimestamp: "2023-05-29 02:55:41",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bf171415-a39f-4301-be16-f9d3f5b7825e&userId=1ed264ea-e4ff-6278-aad0-7c8421181f38",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109021",
        oponentTeamId: "5102440",
        opponent: "1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        playerTeam: [
          {
            gene: "0x80000000000010000c0c0010210000000010000100081020001000428208004000100001821040c00010014102085080001000c3080830c0001000008204006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11729648,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002412090820800000001000820408308000100082841400a000100081840c2060001000830a0810600010008304183080001000810818204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_3012_s4_nondec",
              ears: "",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729969,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100034040610308000000010008088044020001000010a0830400010000186082020001000c0860830600010008108043020001000c10004506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 13174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2102,
        opponentMmrAfter: 2091,
        opponentSlpWon: 0,
        playerMmrBefore: 2058,
        playerMmrAfter: 2069,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e9984741-7a91-421f-bf7f-22cdd9d60389",
        gameTimestamp: "2023-05-29 02:48:31",
        startTimestamp: "2023-05-29 02:43:32",
        endTimestamp: "2023-05-29 02:48:31",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e9984741-7a91-421f-bf7f-22cdd9d60389&userId=1ed264ea-e4ff-6278-aad0-7c8421181f38",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109021",
        oponentTeamId: "5105972",
        opponent: "1ec9eb7b-2956-6814-a60c-a757f2cd4acc",
        playerTeam: [
          {
            gene: "0x80000000000010000c0c0010210000000010000100081020001000428208004000100001821040c00010014102085080001000c3080830c0001000008204006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11729648,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002412090820800000001000820408308000100082841400a000100081840c2060001000830a0810600010008304183080001000810818204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_3012_s4_nondec",
              ears: "",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729969,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100034040610308000000010008088044020001000010a0830400010000186082020001000c0860830600010008108043020001000c10004506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 13174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000100403083080000000100142800850a0001000428804404000100001800c40c000100143000c50c000100101800c5080001001010808504",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740247,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002408030c30400000001001428a1450a0001000428a042080001000018a14208000100143081050c00010000280142060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752307,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050820c0000000100142800450a0001001028204504000100001800c108000100042821430200010004106101080001001010010202",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11742995,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2123,
        opponentMmrAfter: 2111,
        opponentSlpWon: 0,
        playerMmrBefore: 2046,
        playerMmrAfter: 2058,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "942577c2-25d1-4343-ade1-08a3106ac877",
        gameTimestamp: "2023-05-29 02:42:53",
        startTimestamp: "2023-05-29 02:37:24",
        endTimestamp: "2023-05-29 02:42:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=942577c2-25d1-4343-ade1-08a3106ac877&userId=1ed264ea-e4ff-6278-aad0-7c8421181f38",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109021",
        oponentTeamId: "5057206",
        opponent: "1ec9eb6f-74d8-6ddd-a60c-06a2e615aec8",
        playerTeam: [
          {
            gene: "0x80000000000010000c0c0010210000000010000100081020001000428208004000100001821040c00010014102085080001000c3080830c0001000008204006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11729648,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002412090820800000001000820408308000100082841400a000100081840c2060001000830a0810600010008304183080001000810818204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_3012_s4_nondec",
              ears: "",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729969,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100034040610308000000010008088044020001000010a0830400010000186082020001000c0860830600010008108043020001000c10004506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 13174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101c040810c00000001001428a1450a000100042860850a00010000186100060001000410204104000100002820850a0001001010a10404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731295,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c08040c2080000000100142841050a000100041081000a000100001861430c000100100820410a00010000084144060001001010a14402",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11729750,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100805083000000000100142861050a000100042821410a000100001800c0060001001430a1820600010004308082040001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11734587,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1994,
        opponentMmrAfter: 1986,
        opponentSlpWon: 0,
        playerMmrBefore: 2038,
        playerMmrAfter: 2046,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b5625206-a1e8-4a6a-ab5f-7bde8e74ac64",
        gameTimestamp: "2023-05-29 02:36:28",
        startTimestamp: "2023-05-29 02:30:17",
        endTimestamp: "2023-05-29 02:36:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b5625206-a1e8-4a6a-ab5f-7bde8e74ac64&userId=1ed264ea-e4ff-6278-aad0-7c8421181f38",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109021",
        oponentTeamId: "5108783",
        opponent: "1ec9eb73-a403-6333-a60c-8000c1c224e7",
        playerTeam: [
          {
            gene: "0x80000000000010000c0c0010210000000010000100081020001000428208004000100001821040c00010014102085080001000c3080830c0001000008204006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11729648,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002412090820800000001000820408308000100082841400a000100081840c2060001000830a0810600010008304183080001000810818204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_3012_s4_nondec",
              ears: "",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729969,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100034040610308000000010008088044020001000010a0830400010000186082020001000c0860830600010008108043020001000c10004506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 13174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000081c050c3080000000100142820450a0001000428a0810a000100001841400a0001001430a1850c00010010180044040001001010808208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740025,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c1a070820c00000001001428a1450a000100100841040a000100001800c0060001001030818102000100101880c4060001001010204404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11740698,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340a0b0800000000001001428a1410a0001000820204008000100001800c006000100103081850a000100101880c4020001001010808106",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11751406,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1997,
        opponentMmrAfter: 1988,
        opponentSlpWon: 0,
        playerMmrBefore: 2029,
        playerMmrAfter: 2038,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "38e2c21b-23f4-49aa-a966-4640a3e5c3a5",
        gameTimestamp: "2023-05-29 02:29:35",
        startTimestamp: "2023-05-29 02:23:55",
        endTimestamp: "2023-05-29 02:29:35",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=38e2c21b-23f4-49aa-a966-4640a3e5c3a5&userId=1ed264ea-e4ff-6278-aad0-7c8421181f38",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109021",
        oponentTeamId: "5103941",
        opponent: "1ec9eb6f-5043-6b45-a60c-84c89f6ecc67",
        playerTeam: [
          {
            gene: "0x80000000000010000c0c0010210000000010000100081020001000428208004000100001821040c00010014102085080001000c3080830c0001000008204006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11729648,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002412090820800000001000820408308000100082841400a000100081840c2060001000830a0810600010008304183080001000810818204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_3012_s4_nondec",
              ears: "",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729969,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100034040610308000000010008088044020001000010a0830400010000186082020001000c0860830600010008108043020001000c10004506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 13174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c080b0841000000001000810410408000100002841000a000100001800c30c000100141040400c0001000c30618002000100000800c004",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11642208,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120910208000000010008204102080001000828414002000100081840c206000100083041010c000100083041820c0001000810418204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11663603,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002c180b1021000000001000c1060810800010000100083080001000c30a082060001001410a0850400010004102101080001000c1060800a",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2018_s4_nft",
            },
            axie_id: 11304060,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1975,
        opponentMmrAfter: 1967,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2029,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:32.000Z",
  },
  {
    clientId: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
    name: "Beast Slayer Kaneko",
    rank: "Challenger",
    tier: 0,
    topRank: 45,
    vstar: 2068,
    avatar: "1;silver_s1",
    battles: [
      {
        battleUuid: "5bde502b-48b5-4168-9c23-9d5b4c51e6a0",
        gameTimestamp: "2023-05-29 13:30:11",
        startTimestamp: "2023-05-29 13:23:31",
        endTimestamp: "2023-05-29 13:30:11",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5bde502b-48b5-4168-9c23-9d5b4c51e6a0&userId=1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108429",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2082,
        opponentMmrAfter: 2091,
        opponentSlpWon: 24,
        playerMmrBefore: 2077,
        playerMmrAfter: 2068,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "d0e52075-68c9-4191-8a59-21b698dae69e",
        gameTimestamp: "2023-05-29 13:22:53",
        startTimestamp: "2023-05-29 13:17:35",
        endTimestamp: "2023-05-29 13:22:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d0e52075-68c9-4191-8a59-21b698dae69e&userId=1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108429",
        oponentTeamId: "5063533",
        opponent: "1ec9eb6f-4b65-69a7-a60c-eb9d21d02c52",
        playerTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a050820800000001000428204104000100042821440800010004084181060001000428010408000100040860830c000100040820c206",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11570859,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c050c41000000001000420204104000100002820830a000100140800c0060001000408204508000100002820400a000100040821410a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11671316,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c160b083080000000100001040420a0001000020010508000100103081030600010000086085020001000008818504000100002001430c",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11551521,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1988,
        opponentMmrAfter: 1981,
        opponentSlpWon: 0,
        playerMmrBefore: 2070,
        playerMmrAfter: 2077,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "22a25792-4e27-49f3-8268-b0ca6bebb9ec",
        gameTimestamp: "2023-05-29 13:16:51",
        startTimestamp: "2023-05-29 13:11:41",
        endTimestamp: "2023-05-29 13:16:51",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=22a25792-4e27-49f3-8268-b0ca6bebb9ec&userId=1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108429",
        oponentTeamId: "5106640",
        opponent: "1ec9eb7e-3a8d-6fc0-a60c-4351ed7198d7",
        playerTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c1c040820800000001001428a1450a000100042881010a000100001800c0060001001430a1850c000100101821410a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11736352,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080405084080000000100142800850a0001000428010408000100001800c0060001001430a1850c000100002820c4060001001010010404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11735459,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002412040820800000001001428a044020001001028208208000100001880c40c000100100881440200010000086144040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750563,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2101,
        opponentMmrAfter: 2090,
        opponentSlpWon: 0,
        playerMmrBefore: 2059,
        playerMmrAfter: 2070,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7e66c01f-c0fe-4470-a6ed-8464aee8e001",
        gameTimestamp: "2023-05-29 13:11:14",
        startTimestamp: "2023-05-29 13:04:14",
        endTimestamp: "2023-05-29 13:11:14",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7e66c01f-c0fe-4470-a6ed-8464aee8e001&userId=1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108429",
        oponentTeamId: "5022556",
        opponent: "1ed48b15-7db4-6151-af17-2542f0edf74b",
        playerTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2076,
        opponentMmrAfter: 2085,
        opponentSlpWon: 0,
        playerMmrBefore: 2068,
        playerMmrAfter: 2059,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "4f5d0635-73d8-4bb0-bf7d-efdcfcc35f40",
        gameTimestamp: "2023-05-29 13:03:42",
        startTimestamp: "2023-05-29 12:58:54",
        endTimestamp: "2023-05-29 13:03:42",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4f5d0635-73d8-4bb0-bf7d-efdcfcc35f40&userId=1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5108429",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2070,
        opponentMmrAfter: 2080,
        opponentSlpWon: 0,
        playerMmrBefore: 2078,
        playerMmrAfter: 2068,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:33.000Z",
  },
  {
    clientId: "1ec9eb7e-387e-6f67-a60c-8926a641ca73",
    name: "Machy | Huru",
    rank: "Challenger",
    tier: 0,
    topRank: 46,
    vstar: 2066,
    avatar: "s3_top1k;silver_s3",
    battles: [
      {
        battleUuid: "039b2f7a-262f-40ef-b543-c4c67825a48a",
        gameTimestamp: "2023-05-29 04:42:54",
        startTimestamp: "2023-05-29 04:39:11",
        endTimestamp: "2023-05-29 04:42:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=039b2f7a-262f-40ef-b543-c4c67825a48a&userId=1ec9eb7e-387e-6f67-a60c-8926a641ca73",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5092431",
        oponentTeamId: "5103218",
        opponent: "1ec9eb73-5d7e-67ea-a60c-8947fb7b9def",
        playerTeam: [
          {
            gene: "0x800000000000100014080e0020800000001001428a14208000100042880410a000100001800c40c000100143061850c0001001018a0c5080001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748709,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101c060830c00000001001428a1450a000100082040820a000100001800c006000100103001440600010010180185060001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11742122,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070c30c00000001001428a104020001001020a0810400010000186185060001001008814404000100101000410a000100101080800a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11725172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000018000840800000001000410a1030200010008080145020001001020210208000100042880410a000100041080c1040001001018a0c506",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11718882,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030180c08408000000010004108100020001000808404202000100102081020c000100042881850200010008188084040001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11715042,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2800000000000100024120e0420800000001000c08a102020001000808404202000100102020440c00010004282141040001000410208306000100101880c308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11724388,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1977,
        opponentMmrAfter: 1970,
        opponentSlpWon: 0,
        playerMmrBefore: 2059,
        playerMmrAfter: 2066,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "188700b4-7f98-4d04-860d-4fbefc8b7c6a",
        gameTimestamp: "2023-05-29 04:33:11",
        startTimestamp: "2023-05-29 04:29:18",
        endTimestamp: "2023-05-29 04:33:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=188700b4-7f98-4d04-860d-4fbefc8b7c6a&userId=1ec9eb7e-387e-6f67-a60c-8926a641ca73",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5092431",
        oponentTeamId: "5107742",
        opponent: "1ec9eb73-b15b-6f76-a60c-f5d2e26a6aac",
        playerTeam: [
          {
            gene: "0x800000000000100014080e0020800000001001428a14208000100042880410a000100001800c40c000100143061850c0001001018a0c5080001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748709,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101c060830c00000001001428a1450a000100082040820a000100001800c006000100103001440600010010180185060001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11742122,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070c30c00000001001428a104020001001020a0810400010000186185060001001008814404000100101000410a000100101080800a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11725172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101a02081000000000100041041020800010008084141080001001020404406000100042821010800010004102084020001001018818004",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11598669,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003800040820800000001000410a0850400010008084041080001001020810404000100103080410a00010008184101020001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11676724,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001006000a090821000000001000c086045020001000808404504000100102081010c000100042821430a0001000410208104000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731766,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1979,
        opponentMmrAfter: 1972,
        opponentSlpWon: 0,
        playerMmrBefore: 2052,
        playerMmrAfter: 2059,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ea93e535-9764-43ca-992b-9f0183104de0",
        gameTimestamp: "2023-05-29 03:45:54",
        startTimestamp: "2023-05-29 03:40:38",
        endTimestamp: "2023-05-29 03:45:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ea93e535-9764-43ca-992b-9f0183104de0&userId=1ec9eb7e-387e-6f67-a60c-8926a641ca73",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5092431",
        oponentTeamId: "5097853",
        opponent: "1ec9eb7e-59a3-6f4e-a60c-59361f2c12ea",
        playerTeam: [
          {
            gene: "0x800000000000100014080e0020800000001001428a14208000100042880410a000100001800c40c000100143061850c0001001018a0c5080001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748709,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101c060830c00000001001428a1450a000100082040820a000100001800c006000100103001440600010010180185060001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11742122,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070c30c00000001001428a104020001001020a0810400010000186185060001001008814404000100101000410a000100101080800a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11725172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100038040e082080000000100041001010a0001000808410202000100102081040a0001000420418302000100081840c0060001001018210406",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_aquatic_3013_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_3010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11720895,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000241c0c10208000000010004100045080001000808404108000100102081020c000100042800440c00010008186182060001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11729512,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000808020c10c0000000100041041020a000100080840410a0001001020810408000100042880810a000100041040c504000100141880c102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11717478,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4ec0d53e-b858-4684-a8a0-d6925ef8acc6",
        gameTimestamp: "2023-05-29 03:39:22",
        startTimestamp: "2023-05-29 03:34:24",
        endTimestamp: "2023-05-29 03:39:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4ec0d53e-b858-4684-a8a0-d6925ef8acc6&userId=1ec9eb7e-387e-6f67-a60c-8926a641ca73",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5092431",
        oponentTeamId: "5097853",
        opponent: "1ec9eb7e-59a3-6f4e-a60c-59361f2c12ea",
        playerTeam: [
          {
            gene: "0x800000000000100014080e0020800000001001428a14208000100042880410a000100001800c40c000100143061850c0001001018a0c5080001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748709,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101c060830c00000001001428a1450a000100082040820a000100001800c006000100103001440600010010180185060001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11742122,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070c30c00000001001428a104020001001020a0810400010000186185060001001008814404000100101000410a000100101080800a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11725172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100038040e082080000000100041001010a0001000808410202000100102081040a0001000420418302000100081840c0060001001018210406",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_aquatic_3013_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_3010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11720895,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000241c0c10208000000010004100045080001000808404108000100102081020c000100042800440c00010008186182060001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11729512,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000808020c10c0000000100041041020a000100080840410a0001001020810408000100042880810a000100041040c504000100141880c102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11717478,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "54d97976-7f3b-43e9-9442-a6986659a4aa",
        gameTimestamp: "2023-05-29 03:33:22",
        startTimestamp: "2023-05-29 03:27:10",
        endTimestamp: "2023-05-29 03:33:22",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=54d97976-7f3b-43e9-9442-a6986659a4aa&userId=1ec9eb7e-387e-6f67-a60c-8926a641ca73",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5092431",
        oponentTeamId: "5097853",
        opponent: "1ec9eb7e-59a3-6f4e-a60c-59361f2c12ea",
        playerTeam: [
          {
            gene: "0x800000000000100014080e0020800000001001428a14208000100042880410a000100001800c40c000100143061850c0001001018a0c5080001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748709,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101c060830c00000001001428a1450a000100082040820a000100001800c006000100103001440600010010180185060001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11742122,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070c30c00000001001428a104020001001020a0810400010000186185060001001008814404000100101000410a000100101080800a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11725172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100038040e082080000000100041001010a0001000808410202000100102081040a0001000420418302000100081840c0060001001018210406",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_aquatic_3013_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_3010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11720895,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000241c0c10208000000010004100045080001000808404108000100102081020c000100042800440c00010008186182060001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11729512,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000808020c10c0000000100041041020a000100080840410a0001001020810408000100042880810a000100041040c504000100141880c102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11717478,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:33.000Z",
  },
  {
    clientId: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
    name: "hermontv ☁ Red Cloudz",
    rank: "Challenger",
    tier: 0,
    topRank: 47,
    vstar: 2065,
    avatar: "13;silver_s1",
    battles: [
      {
        battleUuid: "ba92cb70-bdec-4632-9821-f4c1ba183197",
        gameTimestamp: "2023-05-29 13:34:57",
        startTimestamp: "2023-05-29 13:31:17",
        endTimestamp: "2023-05-29 13:34:57",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ba92cb70-bdec-4632-9821-f4c1ba183197&userId=1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105045",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2091,
        opponentMmrAfter: 2080,
        opponentSlpWon: 0,
        playerMmrBefore: 2054,
        playerMmrAfter: 2065,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3f4f65f0-6334-44b0-890f-49bca0cb9ec5",
        gameTimestamp: "2023-05-29 13:30:46",
        startTimestamp: "2023-05-29 13:25:37",
        endTimestamp: "2023-05-29 13:30:46",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3f4f65f0-6334-44b0-890f-49bca0cb9ec5&userId=1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105045",
        oponentTeamId: "5104175",
        opponent: "1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        playerTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001412080820c0000000100000800400400010000282144080001000018414506000100103000440c00010010080042080001000008a0430c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750424,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2018,
        opponentMmrAfter: 2029,
        opponentSlpWon: 24,
        playerMmrBefore: 2065,
        playerMmrAfter: 2054,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "c259a202-f02c-475d-8361-c9787f48eceb",
        gameTimestamp: "2023-05-29 13:25:10",
        startTimestamp: "2023-05-29 13:18:31",
        endTimestamp: "2023-05-29 13:25:10",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c259a202-f02c-475d-8361-c9787f48eceb&userId=1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105045",
        oponentTeamId: "5095298",
        opponent: "1ec9eb6f-9f61-68c3-a60c-7fdfbc853338",
        playerTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001300040c208000000010004104041040001000428214504000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734309,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0e051040800000001000010014102000100002861420200010000188180060001000c28018102000100041080c002000100042000420a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11722173,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0700410000000010000108080040001000008004002000100001000c00400010000080140020001000008004404000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11464519,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2087,
        opponentMmrAfter: 2096,
        opponentSlpWon: 24,
        playerMmrBefore: 2074,
        playerMmrAfter: 2065,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "18fa4e34-2a86-4add-9a17-f6d5f3085020",
        gameTimestamp: "2023-05-29 13:17:22",
        startTimestamp: "2023-05-29 13:12:19",
        endTimestamp: "2023-05-29 13:17:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=18fa4e34-2a86-4add-9a17-f6d5f3085020&userId=1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105045",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2064,
        opponentMmrAfter: 2055,
        opponentSlpWon: 0,
        playerMmrBefore: 2065,
        playerMmrAfter: 2074,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7546df8d-3b99-4a06-92d6-501d4cbc5902",
        gameTimestamp: "2023-05-29 13:11:48",
        startTimestamp: "2023-05-29 13:07:53",
        endTimestamp: "2023-05-29 13:11:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7546df8d-3b99-4a06-92d6-501d4cbc5902&userId=1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105045",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2000,
        opponentMmrAfter: 1992,
        opponentSlpWon: 0,
        playerMmrBefore: 2057,
        playerMmrAfter: 2065,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:34.000Z",
  },
  {
    clientId: "1ec9eb6f-5723-6b13-a60c-06d08618c771",
    name: "Allo | CTG",
    rank: "Challenger",
    tier: 0,
    topRank: 48,
    vstar: 2061,
    avatar: "16;gold_s3",
    battles: [
      {
        battleUuid: "362d3d1c-1c9c-4b9f-a6eb-0511cb16d903",
        gameTimestamp: "2023-05-29 12:12:58",
        startTimestamp: "2023-05-29 12:08:38",
        endTimestamp: "2023-05-29 12:12:58",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=362d3d1c-1c9c-4b9f-a6eb-0511cb16d903&userId=1ec9eb6f-5723-6b13-a60c-06d08618c771",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5087514",
        oponentTeamId: "4598187",
        opponent: "1ec9eb6f-43cd-63cc-a60c-902dcd4c5a71",
        playerTeam: [
          {
            gene: "0x1800000000000100030080e0820800000001000c2860450a0001000c106040040001000c3061830a0001000c086044060001000c18a0c3060001000c10608304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11734219,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140e020430800000001000c1021020400010014286045040001000c086043020001001410a145040001001410a081040001000008218308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11743219,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002414091021000000001000820410208000100082880420a000100081840c206000100083041820c000100083041430c0001000810804204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722983,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x9000000000000100600160b083040000000100101060840400010004200101080001000c3020440400010014184181040001000410a045040001000c1020c404",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 10047268,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100010080408208000000010004102081020001000428610504000100001800c10a000100042821410a00010004102181080001000408204102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751425,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000240e0208410000000010000080140080001000008004004000100001000800400010000088040020001000008004002000100002800c006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_buba_1",
              eyes: "",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11494371,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1900,
        opponentMmrAfter: 1895,
        opponentSlpWon: 0,
        playerMmrBefore: 2056,
        playerMmrAfter: 2061,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "cc4cd2eb-e068-4fda-95f5-af75cb56970b",
        gameTimestamp: "2023-05-29 12:07:41",
        startTimestamp: "2023-05-29 12:02:38",
        endTimestamp: "2023-05-29 12:07:41",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cc4cd2eb-e068-4fda-95f5-af75cb56970b&userId=1ec9eb6f-5723-6b13-a60c-06d08618c771",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087514",
        oponentTeamId: "4931088",
        opponent: "1ec9eb77-919f-6651-a60c-191bad752b3d",
        playerTeam: [
          {
            gene: "0x1800000000000100030080e0820800000001000c2860450a0001000c106040040001000c3061830a0001000c086044060001000c18a0c3060001000c10608304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11734219,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140e020430800000001000c1021020400010014286045040001000c086043020001001410a145040001001410a081040001000008218308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11743219,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002414091021000000001000820410208000100082880420a000100081840c206000100083041820c000100083041430c0001000810804204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722983,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002410090820c000000010004106101080001000028014504000100001800c106000100003001820800010004104180060001000008808306",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751717,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c0e0908410000000010008200144080001000828414308000100081840c204000100083041820c000100083041810a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11716521,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000180208208000000010004100143040001000c0860440a00010000186080060001000c0801840a0001000c3000420c0001000c1060410c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11754619,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1986,
        opponentMmrAfter: 1998,
        opponentSlpWon: 22,
        playerMmrBefore: 2068,
        playerMmrAfter: 2056,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "e37aeb02-6381-4d59-a0e0-47ecd7f5c496",
        gameTimestamp: "2023-05-29 12:02:04",
        startTimestamp: "2023-05-29 11:59:39",
        endTimestamp: "2023-05-29 12:02:04",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e37aeb02-6381-4d59-a0e0-47ecd7f5c496&userId=1ec9eb6f-5723-6b13-a60c-06d08618c771",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5087514",
        oponentTeamId: "5063533",
        opponent: "1ec9eb6f-4b65-69a7-a60c-eb9d21d02c52",
        playerTeam: [
          {
            gene: "0x1800000000000100030080e0820800000001000c2860450a0001000c106040040001000c3061830a0001000c086044060001000c18a0c3060001000c10608304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11734219,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140e020430800000001000c1021020400010014286045040001000c086043020001001410a145040001001410a081040001000008218308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11743219,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002414091021000000001000820410208000100082880420a000100081840c206000100083041820c000100083041430c0001000810804204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722983,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a050820800000001000428204104000100042821440800010004084181060001000428010408000100040860830c000100040820c206",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11570859,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c050c41000000001000420204104000100002820830a000100140800c0060001000408204508000100002820400a000100040821410a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11671316,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c160b083080000000100001040420a0001000020010508000100103081030600010000086085020001000008818504000100002001430c",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11551521,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1997,
        opponentMmrAfter: 1989,
        opponentSlpWon: 0,
        playerMmrBefore: 2060,
        playerMmrAfter: 2068,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "82dd7f39-555e-4f78-a7e9-9d0744717e5a",
        gameTimestamp: "2023-05-29 11:59:13",
        startTimestamp: "2023-05-29 11:52:44",
        endTimestamp: "2023-05-29 11:59:13",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=82dd7f39-555e-4f78-a7e9-9d0744717e5a&userId=1ec9eb6f-5723-6b13-a60c-06d08618c771",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087514",
        oponentTeamId: "5108308",
        opponent: "1ed57738-fb5b-67da-895c-8d36800bd76c",
        playerTeam: [
          {
            gene: "0x1800000000000100030080e0820800000001000c2860450a0001000c106040040001000c3061830a0001000c086044060001000c18a0c3060001000c10608304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11734219,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140e020430800000001000c1021020400010014286045040001000c086043020001001410a145040001001410a081040001000008218308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11743219,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002414091021000000001000820410208000100082880420a000100081840c206000100083041820c000100083041430c0001000810804204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722983,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2083,
        opponentMmrAfter: 2092,
        opponentSlpWon: 0,
        playerMmrBefore: 2069,
        playerMmrAfter: 2060,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "5fcfa02b-f165-4175-8ee7-8ff2b03f3854",
        gameTimestamp: "2023-05-29 11:52:20",
        startTimestamp: "2023-05-29 11:48:05",
        endTimestamp: "2023-05-29 11:52:20",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5fcfa02b-f165-4175-8ee7-8ff2b03f3854&userId=1ec9eb6f-5723-6b13-a60c-06d08618c771",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087514",
        oponentTeamId: "5087063",
        opponent: "1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        playerTeam: [
          {
            gene: "0x1800000000000100030080e0820800000001000c2860450a0001000c106040040001000c3061830a0001000c086044060001000c18a0c3060001000c10608304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11734219,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140e020430800000001000c1021020400010014286045040001000c086043020001001410a145040001001410a081040001000008218308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11743219,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002414091021000000001000820410208000100082880420a000100081840c206000100083041820c000100083041430c0001000810804204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722983,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2037,
        opponentMmrAfter: 2048,
        opponentSlpWon: 0,
        playerMmrBefore: 2080,
        playerMmrAfter: 2069,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:34.000Z",
  },
  {
    clientId: "1ec9eb73-b3bd-6db3-a60c-d1ba7231dcc3",
    name: "Jonakinho ☁ Red Cloudz",
    rank: "Challenger",
    tier: 0,
    topRank: 49,
    vstar: 2061,
    avatar: "0;patrick23",
    battles: [
      {
        battleUuid: "e3545c81-7e3a-40d6-8512-9bb729ced4b6",
        gameTimestamp: "2023-05-28 22:20:09",
        startTimestamp: "2023-05-28 22:12:36",
        endTimestamp: "2023-05-28 22:20:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e3545c81-7e3a-40d6-8512-9bb729ced4b6&userId=1ec9eb73-b3bd-6db3-a60c-d1ba7231dcc3",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5051099",
        oponentTeamId: "5109700",
        opponent: "1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
        playerTeam: [
          {
            gene: "0x8000000000001006000a0308208000000010004108040020001000808208304000100102081440a0001000c1821440a000100081840c1040001001018808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11701378,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100002020c0020800000001001028a105040001000808404504000100102081020c0001001030214104000100081840c0080001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11676731,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001f00070c208000000010004102085020001000808214208000100102000450a0001000430608308000100041040c302000100101880c502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729682,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100010080b0830800000001001428a142040001000428204008000100001800c0060001001430a1850c000100002820840c000100101080850c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11747091,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a0e0c40800000001001428a1410a000100042821410a000100001800c0060001001430a1850c0001001018a0c20a0001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751757,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008070c20800000001001428a0400a0001000428210208000100001800c3060001001020a1840c0001000028204406000100101080830c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746428,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1988,
        opponentMmrAfter: 1980,
        opponentSlpWon: 0,
        playerMmrBefore: 2053,
        playerMmrAfter: 2061,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "686c80d9-aee8-4159-b415-8aade35de809",
        gameTimestamp: "2023-05-28 21:40:55",
        startTimestamp: "2023-05-28 21:35:54",
        endTimestamp: "2023-05-28 21:40:55",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=686c80d9-aee8-4159-b415-8aade35de809&userId=1ec9eb73-b3bd-6db3-a60c-d1ba7231dcc3",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5051099",
        oponentTeamId: "5079651",
        opponent: "1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        playerTeam: [
          {
            gene: "0x8000000000001006000a0308208000000010004108040020001000808208304000100102081440a0001000c1821440a000100081840c1040001001018808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11701378,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100002020c0020800000001001028a105040001000808404504000100102081020c0001001030214104000100081840c0080001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11676731,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001f00070c208000000010004102085020001000808214208000100102000450a0001000430608308000100041040c302000100101880c502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729682,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2019,
        opponentMmrAfter: 2030,
        opponentSlpWon: 24,
        playerMmrBefore: 2064,
        playerMmrAfter: 2053,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "7693d56a-81bf-4028-9488-206bb7581c95",
        gameTimestamp: "2023-05-28 21:35:21",
        startTimestamp: "2023-05-28 21:29:11",
        endTimestamp: "2023-05-28 21:35:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7693d56a-81bf-4028-9488-206bb7581c95&userId=1ec9eb73-b3bd-6db3-a60c-d1ba7231dcc3",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5051099",
        oponentTeamId: "5091036",
        opponent: "1ec9eb73-5d3a-6a8a-a60c-6d41702e9a80",
        playerTeam: [
          {
            gene: "0x8000000000001006000a0308208000000010004108040020001000808208304000100102081440a0001000c1821440a000100081840c1040001001018808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11701378,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100002020c0020800000001001028a105040001000808404504000100102081020c0001001030214104000100081840c0080001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11676731,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001f00070c208000000010004102085020001000808214208000100102000450a0001000430608308000100041040c302000100101880c502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729682,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c16040820800000001001428a1450a000100042860850a0001000018214006000100143021440c000100041000c1040001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11738617,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240a05083100000000100142820850a000100042881010a000100001800c006000100143020800600010000180141040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721724,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000141a040820c0000000100142861050a00010014280082080001000018018006000100103081830400010010180184060001001010204404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11740692,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1972,
        opponentMmrAfter: 1965,
        opponentSlpWon: 0,
        playerMmrBefore: 2057,
        playerMmrAfter: 2064,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3166b0d8-2401-4bd2-a887-05e3a1d11b5c",
        gameTimestamp: "2023-05-28 21:28:09",
        startTimestamp: "2023-05-28 21:18:09",
        endTimestamp: "2023-05-28 21:28:09",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3166b0d8-2401-4bd2-a887-05e3a1d11b5c&userId=1ec9eb73-b3bd-6db3-a60c-d1ba7231dcc3",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5051099",
        oponentTeamId: "5086077",
        opponent: "1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        playerTeam: [
          {
            gene: "0x8000000000001006000a0308208000000010004108040020001000808208304000100102081440a0001000c1821440a000100081840c1040001001018808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11701378,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100002020c0020800000001001028a105040001000808404504000100102081020c0001001030214104000100081840c0080001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11676731,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001f00070c208000000010004102085020001000808214208000100102000450a0001000430608308000100041040c302000100101880c502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729682,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_neutral_2013_s4_nft",
            },
            axie_id: 11712758,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1981,
        opponentMmrAfter: 1993,
        opponentSlpWon: 0,
        playerMmrBefore: 2069,
        playerMmrAfter: 2057,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "b3acfd00-e2bb-4157-a8c7-ea1924d09080",
        gameTimestamp: "2023-05-28 21:17:01",
        startTimestamp: "2023-05-28 21:12:08",
        endTimestamp: "2023-05-28 21:17:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b3acfd00-e2bb-4157-a8c7-ea1924d09080&userId=1ec9eb73-b3bd-6db3-a60c-d1ba7231dcc3",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5051099",
        oponentTeamId: "5107320",
        opponent: "1ec9eb6f-7d6a-6b66-a60c-26db45156a6a",
        playerTeam: [
          {
            gene: "0x8000000000001006000a0308208000000010004108040020001000808208304000100102081440a0001000c1821440a000100081840c1040001001018808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11701378,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100002020c0020800000001001028a105040001000808404504000100102081020c0001001030214104000100081840c0080001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11676731,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001f00070c208000000010004102085020001000808214208000100102000450a0001000430608308000100041040c302000100101880c502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729682,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003a02020c20c00000001001428a1450a0001000428810302000100001800c40a0001001430a1850c000100041800820a0001001010808208",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1012_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3011_s4",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3011_s4",
            },
            axie_id: 11743797,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002406070021000000001001428a1450a0001000408008508000100001800c10a0001001030610108000100041880c1060001001010808504",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1012_s4",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11750201,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100e06080080000000100142821050800010004082143020001000018814402000100100881440a000100041801810a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11739037,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1824,
        opponentMmrAfter: 1821,
        opponentSlpWon: 0,
        playerMmrBefore: 2066,
        playerMmrAfter: 2069,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:35.000Z",
  },
  {
    clientId: "1ec9eb6f-4803-61a3-a60c-380d2e66803c",
    name: "Tapioca ☁ Red Cloudz",
    rank: "Challenger",
    tier: 0,
    topRank: 50,
    vstar: 2060,
    avatar: "red_envelope_lunar23;rainbow",
    battles: [
      {
        battleUuid: "e21bc0d4-9104-4bd0-b1ba-6c5879a18c06",
        gameTimestamp: "2023-05-29 13:24:24",
        startTimestamp: "2023-05-29 13:17:43",
        endTimestamp: "2023-05-29 13:24:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e21bc0d4-9104-4bd0-b1ba-6c5879a18c06&userId=1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096712",
        oponentTeamId: "5111681",
        opponent: "1ed07690-5368-66ea-a5db-6f5f5c331ccc",
        playerTeam: [
          {
            gene: "0x200000000000010001c00000820c000000010010108082020001001428210408000100140881040a0001001008818406000100101860c4060001001010008306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 6277900,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081207084000000000100000821000a00010000080040020001000010008004000100000800800a0001000030004006000100002800400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 1969448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407004100000000100001000800a00010000284040020001000018a0c106000100041000800200010000080040020001000408004206",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538878,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101404103080000000100142881050a0001000428a082040001000018004206000100143041450c00010000286184080001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741977,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c1a070c00800000001001428a1450a0001001020810002000100001880440c000100103000840c000100101880c4060001001010408202",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11697854,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000341c020840000000001001428a1440200010010206041020001000018614006000100102840820a000100101880c4060001001010404202",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "",
            },
            axie_id: 11716178,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d75524f2-e394-4905-8c99-04bc193a4c2f",
        gameTimestamp: "2023-05-29 13:17:03",
        startTimestamp: "2023-05-29 13:09:26",
        endTimestamp: "2023-05-29 13:17:03",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d75524f2-e394-4905-8c99-04bc193a4c2f&userId=1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096712",
        oponentTeamId: "5107669",
        opponent: "1ed07690-5368-66ea-a5db-6f5f5c331ccc",
        playerTeam: [
          {
            gene: "0x200000000000010001c00000820c000000010010108082020001001428210408000100140881040a0001001008818406000100101860c4060001001010008306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 6277900,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081207084000000000100000821000a00010000080040020001000010008004000100000800800a0001000030004006000100002800400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 1969448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407004100000000100001000800a00010000284040020001000018a0c106000100041000800200010000080040020001000408004206",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538878,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100030000e0830c000000010010108141020001000808a0840400010010208104080001000428a105040001000818808304000100101880c406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_aquatic_3013_s4_nft",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11714669,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000100c00081040000000100041041050a00010008084145040001001020810204000100042821830a0001000818a102060001000008218504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729729,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003000060c20c0000000100041020410400010008080085040001001020810408000100103060810c000100081840c2060001001018018406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2011_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729726,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "c631d84e-0b78-4c61-92ac-160ddbcce1d2",
        gameTimestamp: "2023-05-29 13:01:25",
        startTimestamp: "2023-05-29 12:56:14",
        endTimestamp: "2023-05-29 13:01:25",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c631d84e-0b78-4c61-92ac-160ddbcce1d2&userId=1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096712",
        oponentTeamId: "5065223",
        opponent: "1edd2ed4-bb94-68fb-9449-86a1daa3fe85",
        playerTeam: [
          {
            gene: "0x200000000000010001c00000820c000000010010108082020001001428210408000100140881040a0001001008818406000100101860c4060001001010008306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 6277900,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081207084000000000100000821000a00010000080040020001000010008004000100000800800a0001000030004006000100002800400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 1969448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407004100000000100001000800a00010000284040020001000018a0c106000100041000800200010000080040020001000408004206",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538878,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100020120d0000c00000001001408a10108000100102881440a00010010088043060001000810014504000100043080810c000100100820c402",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11684606,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340e0210410000000010000280140040001001028210208000100001821840a000100081040800400010000080102040001000008210304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_beast_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11663934,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100020060d0000c00000001000428804108000100102881440a00010010088042040001000810408008000100101021800c000100100820c402",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_2013_s4_nft",
              eyes: "ecard_bug_3011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_puffy_1",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11699537,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a153b808-60e7-4b9a-850c-3912647f88ac",
        gameTimestamp: "2023-05-29 12:54:59",
        startTimestamp: "2023-05-29 12:49:05",
        endTimestamp: "2023-05-29 12:54:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a153b808-60e7-4b9a-850c-3912647f88ac&userId=1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096712",
        oponentTeamId: "5065223",
        opponent: "1edd2ed4-bb94-68fb-9449-86a1daa3fe85",
        playerTeam: [
          {
            gene: "0x200000000000010001c00000820c000000010010108082020001001428210408000100140881040a0001001008818406000100101860c4060001001010008306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 6277900,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081207084000000000100000821000a00010000080040020001000010008004000100000800800a0001000030004006000100002800400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 1969448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407004100000000100001000800a00010000284040020001000018a0c106000100041000800200010000080040020001000408004206",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538878,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100020120d0000c00000001001408a10108000100102881440a00010010088043060001000810014504000100043080810c000100100820c402",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11684606,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340e0210410000000010000280140040001001028210208000100001821840a000100081040800400010000080102040001000008210304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11663934,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100020060d0000c00000001000428804108000100102881440a00010010088042040001000810408008000100101021800c000100100820c402",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_puffy_1",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11699537,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d4bf8a7b-73a8-4284-adc9-5bb4134f40e6",
        gameTimestamp: "2023-05-29 12:20:21",
        startTimestamp: "2023-05-29 12:12:54",
        endTimestamp: "2023-05-29 12:20:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d4bf8a7b-73a8-4284-adc9-5bb4134f40e6&userId=1ec9eb6f-4803-61a3-a60c-380d2e66803c",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5021791",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x80000000000010000c1c000421000000001000410214104000100080840420200010010204044080001000428214202000100081840c206000100101880c106",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11735278,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010180c082080000000100041000440800010008084042020001001020810408000100103080410a000100081840c2060001000008614002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "",
            },
            axie_id: 11676729,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003300070c10400000001000c0860450200010008082082020001001020614408000100042880c008000100081840c206000100101880c406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11702071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2059,
        opponentMmrAfter: 2049,
        opponentSlpWon: 0,
        playerMmrBefore: 2050,
        playerMmrAfter: 2060,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:36.000Z",
  },
  {
    clientId: "1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
    name: "THER | sushiclub | PZL",
    rank: "Challenger",
    tier: 0,
    topRank: 51,
    vstar: 2060,
    avatar: "0;0",
    battles: [
      {
        battleUuid: "29295450-62f7-4ad6-951a-6eb158651fb0",
        gameTimestamp: "2023-05-29 05:44:13",
        startTimestamp: "2023-05-29 05:39:34",
        endTimestamp: "2023-05-29 05:44:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=29295450-62f7-4ad6-951a-6eb158651fb0&userId=1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4715530",
        oponentTeamId: "5072683",
        opponent: "1ec9eb7e-36e9-6da2-a60c-ed0a11570bd1",
        playerTeam: [
          {
            gene: "0x1000000e071020800000001000010008004000100002801400a0001001408414404000100000800400200010000188080060001000008010306",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11535769,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e082100000000100001000800a0001000008004002000100001000800200010000100080040001000008004308000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nondec",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 734108,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030180c0820c00000001000c080083020001000c0860420a0001000c306182040001000c2861430a0001000c306181020001000c10418302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nondec",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9256507,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c040410308000000010014280105080001000428a102020001000018810004000100102080440c000100041081810a0001001010808004",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740863,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408090820800000001001428a1450a000100082081440a0001000018808006000100143081840c000100101880c4060001000008808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713079,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c12090800800000001001428a1040a0001000820810308000100001840440c000100101080c40c000100002860c40a0001001010218406",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11722283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1cc6ee72-5dfe-4b70-98dc-a69a99c4578b",
        gameTimestamp: "2023-05-29 05:38:53",
        startTimestamp: "2023-05-29 05:35:51",
        endTimestamp: "2023-05-29 05:38:53",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1cc6ee72-5dfe-4b70-98dc-a69a99c4578b&userId=1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4715530",
        oponentTeamId: "5072683",
        opponent: "1ec9eb7e-36e9-6da2-a60c-ed0a11570bd1",
        playerTeam: [
          {
            gene: "0x1000000e071020800000001000010008004000100002801400a0001001408414404000100000800400200010000188080060001000008010306",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11535769,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e082100000000100001000800a0001000008004002000100001000800200010000100080040001000008004308000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nondec",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 734108,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030180c0820c00000001000c080083020001000c0860420a0001000c306182040001000c2861430a0001000c306181020001000c10418302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nondec",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9256507,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c040410308000000010014280105080001000428a102020001000018810004000100102080440c000100041081810a0001001010808004",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740863,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408090820800000001001428a1450a000100082081440a0001000018808006000100143081840c000100101880c4060001000008808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713079,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c12090800800000001001428a1040a0001000820810308000100001840440c000100101080c40c000100002860c40a0001001010218406",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11722283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "02fd6dd9-11ad-4bed-b86e-0e2f5cf88ec5",
        gameTimestamp: "2023-05-29 05:35:18",
        startTimestamp: "2023-05-29 05:30:45",
        endTimestamp: "2023-05-29 05:35:18",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=02fd6dd9-11ad-4bed-b86e-0e2f5cf88ec5&userId=1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4715530",
        oponentTeamId: "5072683",
        opponent: "1ec9eb7e-36e9-6da2-a60c-ed0a11570bd1",
        playerTeam: [
          {
            gene: "0x1000000e071020800000001000010008004000100002801400a0001001408414404000100000800400200010000188080060001000008010306",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11535769,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e082100000000100001000800a0001000008004002000100001000800200010000100080040001000008004308000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nondec",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 734108,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030180c0820c00000001000c080083020001000c0860420a0001000c306182040001000c2861430a0001000c306181020001000c10418302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nondec",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9256507,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c040410308000000010014280105080001000428a102020001000018810004000100102080440c000100041081810a0001001010808004",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740863,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408090820800000001001428a1450a000100082081440a0001000018808006000100143081840c000100101880c4060001000008808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713079,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c12090800800000001001428a1040a0001000820810308000100001840440c000100101080c40c000100002860c40a0001001010218406",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11722283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "345c16b8-7379-4051-8542-bdb74e6865f5",
        gameTimestamp: "2023-05-29 05:30:21",
        startTimestamp: "2023-05-29 05:26:28",
        endTimestamp: "2023-05-29 05:30:21",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=345c16b8-7379-4051-8542-bdb74e6865f5&userId=1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4715530",
        oponentTeamId: "5072683",
        opponent: "1ec9eb7e-36e9-6da2-a60c-ed0a11570bd1",
        playerTeam: [
          {
            gene: "0x1000000e071020800000001000010008004000100002801400a0001001408414404000100000800400200010000188080060001000008010306",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11535769,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e082100000000100001000800a0001000008004002000100001000800200010000100080040001000008004308000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nondec",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 734108,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030180c0820c00000001000c080083020001000c0860420a0001000c306182040001000c2861430a0001000c306181020001000c10418302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nondec",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9256507,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c040410308000000010014280105080001000428a102020001000018810004000100102080440c000100041081810a0001001010808004",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740863,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408090820800000001001428a1450a000100082081440a0001000018808006000100143081840c000100101880c4060001000008808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713079,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c12090800800000001001428a1040a0001000820810308000100001840440c000100101080c40c000100002860c40a0001001010218406",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11722283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "585baeee-5333-4fe7-939d-265a0f3bcaf9",
        gameTimestamp: "2023-05-29 05:25:59",
        startTimestamp: "2023-05-29 05:22:49",
        endTimestamp: "2023-05-29 05:25:59",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=585baeee-5333-4fe7-939d-265a0f3bcaf9&userId=1ec9eb7e-5c02-6deb-a60c-c858ed350fe6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4715530",
        oponentTeamId: "5072683",
        opponent: "1ec9eb7e-36e9-6da2-a60c-ed0a11570bd1",
        playerTeam: [
          {
            gene: "0x1000000e071020800000001000010008004000100002801400a0001001408414404000100000800400200010000188080060001000008010306",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11535769,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e082100000000100001000800a0001000008004002000100001000800200010000100080040001000008004308000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nondec",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 734108,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030180c0820c00000001000c080083020001000c0860420a0001000c306182040001000c2861430a0001000c306181020001000c10418302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nondec",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9256507,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c040410308000000010014280105080001000428a102020001000018810004000100102080440c000100041081810a0001001010808004",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740863,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408090820800000001001428a1450a000100082081440a0001000018808006000100143081840c000100101880c4060001000008808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713079,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c12090800800000001001428a1040a0001000820810308000100001840440c000100101080c40c000100002860c40a0001001010218406",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11722283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:36.000Z",
  },
  {
    clientId: "1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
    name: "FORDEX | Marino443",
    rank: "Challenger",
    tier: 0,
    topRank: 52,
    vstar: 2057,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "3768cb49-2bc3-4fe3-ba61-cdb452ecc3f7",
        gameTimestamp: "2023-05-29 09:52:42",
        startTimestamp: "2023-05-29 09:45:33",
        endTimestamp: "2023-05-29 09:52:42",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3768cb49-2bc3-4fe3-ba61-cdb452ecc3f7&userId=1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5086077",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2096,
        opponentMmrAfter: 2085,
        opponentSlpWon: 0,
        playerMmrBefore: 2046,
        playerMmrAfter: 2057,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "29983523-f833-4bd3-8121-a6e6a55c9a1a",
        gameTimestamp: "2023-05-29 09:44:54",
        startTimestamp: "2023-05-29 09:41:25",
        endTimestamp: "2023-05-29 09:44:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=29983523-f833-4bd3-8121-a6e6a55c9a1a&userId=1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5086077",
        oponentTeamId: "4998450",
        opponent: "1ed19f15-3cc6-65ce-b138-35f04e5ecadf",
        playerTeam: [
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040020c000000010004100142020001000810014104000100001800c102000100042800c10a0001001420a10508000100040880830a",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11691934,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040b10410000000010000100080040001000028610308000100001800c0060001000428414304000100041041820c000100000840c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718510,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c020b0840800000001000010008004000100002020840a000100003061000c000100000800440600010000088182020001000018a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11727730,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1989,
        opponentMmrAfter: 1981,
        opponentSlpWon: 0,
        playerMmrBefore: 2038,
        playerMmrAfter: 2046,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3519548c-09f4-4afa-b9af-2afb4b5a806f",
        gameTimestamp: "2023-05-29 09:40:38",
        startTimestamp: "2023-05-29 09:30:46",
        endTimestamp: "2023-05-29 09:40:38",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3519548c-09f4-4afa-b9af-2afb4b5a806f&userId=1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5086077",
        oponentTeamId: "5089435",
        opponent: "1ec9eb6f-73ec-66ce-a60c-91ba382945e6",
        playerTeam: [
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100000180008208000000010004104143040001000808a043080001001020808308000100042820810a0001000818014206000100101880c308",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11713479,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100000180e0820800000001000410208504000100080840440a00010010208144080001001030a1810a000100081840c30a0001000008410002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11715077,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002701800420800000001000c082085020001000808014202000100102081000200010004282144060001000410208406000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11702116,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2008,
        opponentMmrAfter: 2019,
        opponentSlpWon: 24,
        playerMmrBefore: 2049,
        playerMmrAfter: 2038,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "6130752e-6e29-437e-bdb3-fd8f40af4d15",
        gameTimestamp: "2023-05-29 09:30:12",
        startTimestamp: "2023-05-29 09:27:01",
        endTimestamp: "2023-05-29 09:30:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6130752e-6e29-437e-bdb3-fd8f40af4d15&userId=1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5086077",
        oponentTeamId: "5026641",
        opponent: "1ec9eb73-b491-656d-a60c-2ebb6144527e",
        playerTeam: [
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412040820800000001001428a1450a0001000428204102000100001800c006000100041041010a00010004084184040001001010a10508",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e000c2100000000100142801450a0001000820410402000100001800c00a0001001030a1440c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11749565,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002404050c20400000001001428408504000100082020840800010000184180060001000828a18402000100101041800a0001001010814008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11728770,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2004,
        opponentMmrAfter: 1996,
        opponentSlpWon: 0,
        playerMmrBefore: 2041,
        playerMmrAfter: 2049,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8a1a21b7-66fe-4486-90d1-da076b5523dd",
        gameTimestamp: "2023-05-29 09:26:34",
        startTimestamp: "2023-05-29 09:20:54",
        endTimestamp: "2023-05-29 09:26:34",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8a1a21b7-66fe-4486-90d1-da076b5523dd&userId=1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5086077",
        oponentTeamId: "5033550",
        opponent: "1ec9eb6f-5ecd-62c7-a60c-b3aa0c2fb14d",
        playerTeam: [
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x280000000000010002806070000000000001001008a04404000100080841000200010010208104020001000428410404000100081840c1020001001018614508",
            runes: ["rune_reptile_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11707739,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010180e0820800000001000410208008000100080840420200010010208104080001000428214502000100081840c10a0001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11728532,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100010060008200000000010004102080020001001010a08202000100102081010600010004282142060001000818810206000100101820c10a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nondec",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11669091,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1987,
        opponentMmrAfter: 1998,
        opponentSlpWon: 22,
        playerMmrBefore: 2052,
        playerMmrAfter: 2041,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:37.000Z",
  },
  {
    clientId: "1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
    name: "Tres peces locos\r\n",
    rank: "Challenger",
    tier: 0,
    topRank: 53,
    vstar: 2054,
    avatar: null,
    battles: [
      {
        battleUuid: "415e1f12-6abb-4fde-b885-5255c48cf86d",
        gameTimestamp: "2023-05-29 10:19:13",
        startTimestamp: "2023-05-29 10:18:55",
        endTimestamp: "2023-05-29 10:19:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=415e1f12-6abb-4fde-b885-5255c48cf86d&userId=1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110433",
        oponentTeamId: "5110705",
        opponent: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        playerTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001408060830800000001001428a1450a0001000428814504000100001800c006000100143021450c0001000410208104000100101080830a",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11747167,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000000c0d0c20000000001001428a1450a0001001028814204000100001800c402000100100881840c000100101880c2040001001010404404",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11747496,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2043,
        opponentMmrAfter: 2034,
        opponentSlpWon: 0,
        playerMmrBefore: 2045,
        playerMmrAfter: 2054,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d123c083-8d7e-4514-bbb6-70ea54cf32e8",
        gameTimestamp: "2023-05-29 10:18:12",
        startTimestamp: "2023-05-29 10:16:01",
        endTimestamp: "2023-05-29 10:18:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d123c083-8d7e-4514-bbb6-70ea54cf32e8&userId=1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110433",
        oponentTeamId: "4931088",
        opponent: "1ec9eb77-919f-6651-a60c-191bad752b3d",
        playerTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002410090820c000000010004106101080001000028014504000100001800c106000100003001820800010004104180060001000008808306",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751717,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c0e0908410000000010008200144080001000828414308000100081840c204000100083041820c000100083041810a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11716521,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000180208208000000010004100143040001000c0860440a00010000186080060001000c0801840a0001000c3000420c0001000c1060410c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11754619,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2003,
        opponentMmrAfter: 1994,
        opponentSlpWon: 0,
        playerMmrBefore: 2036,
        playerMmrAfter: 2045,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f4534fd8-7660-499b-80d1-1f4a1d38bbf2",
        gameTimestamp: "2023-05-29 10:15:35",
        startTimestamp: "2023-05-29 10:11:05",
        endTimestamp: "2023-05-29 10:15:35",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f4534fd8-7660-499b-80d1-1f4a1d38bbf2&userId=1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110433",
        oponentTeamId: "5049492",
        opponent: "1edb3382-ec4e-6d32-9b2f-cfec81e89567",
        playerTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000180a051021000000001001428a1020a000100040801400a00010000182085060001001430a0410c000100002860440a0001001010808202",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11697020,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001806000820c00000001001428a1420a0001000820814408000100001800c1020001001030818104000100101800440c0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11727029,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000240a0b0820c00000001001428a1450a00010004282141020001000018804002000100042801410a000100041040c10a000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724409,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1986,
        opponentMmrAfter: 1997,
        opponentSlpWon: 22,
        playerMmrBefore: 2047,
        playerMmrAfter: 2036,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "8a66c33f-de1c-42e2-a8d1-17fa416d2bcc",
        gameTimestamp: "2023-05-29 10:10:31",
        startTimestamp: "2023-05-29 10:05:07",
        endTimestamp: "2023-05-29 10:10:31",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8a66c33f-de1c-42e2-a8d1-17fa416d2bcc&userId=1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110433",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2110,
        opponentMmrAfter: 2098,
        opponentSlpWon: 0,
        playerMmrBefore: 2035,
        playerMmrAfter: 2047,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "2d195391-efce-4d11-96b8-8f25b2ac5b03",
        gameTimestamp: "2023-05-29 10:04:28",
        startTimestamp: "2023-05-29 10:00:55",
        endTimestamp: "2023-05-29 10:04:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2d195391-efce-4d11-96b8-8f25b2ac5b03&userId=1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110433",
        oponentTeamId: "5094798",
        opponent: "1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        playerTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736392,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720304,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2042,
        opponentMmrAfter: 2032,
        opponentSlpWon: 0,
        playerMmrBefore: 2025,
        playerMmrAfter: 2035,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:38.000Z",
  },
  {
    clientId: "1ecad86f-e474-634a-b10f-438c79d70755",
    name: "Enrico | YGG",
    rank: "Challenger",
    tier: 0,
    topRank: 54,
    vstar: 2053,
    avatar: "shy;gold_03_s3",
    battles: [
      {
        battleUuid: "05d7c7a2-b796-469a-b88e-ab6c00458f39",
        gameTimestamp: "2023-05-29 07:49:53",
        startTimestamp: "2023-05-29 07:44:10",
        endTimestamp: "2023-05-29 07:49:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=05d7c7a2-b796-469a-b88e-ab6c00458f39&userId=1ecad86f-e474-634a-b10f-438c79d70755",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5105124",
        oponentTeamId: "5108530",
        opponent: "1ec9eb7e-3daa-6fea-a60c-23ee421975a6",
        playerTeam: [
          {
            gene: "0x8000000000001000381c000820c00000001000410a0820a00010008084042020001001020810408000100042881840c000100081840c2060001001018418002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nondec",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11662331,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000081c03082080000000100041020810400010008084044080001001020218408000100103020800a0001000818a042060001000008218002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728522,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003018030c10400000001000c08a1430800010008088105020001001020810408000100042860840a000100041040c2080001001018804308",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11634376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002008080c30c0000000100142840850a0001000428a0410a0001000018008006000100143081050c00010000288041060001001010608404",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11705952,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a030830c00000001001428a1450a0001000428408502000100001800c2080001001430a1850200010000280182060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11752303,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000240c060820800000001001428a1450a0001000428214002000100001800c0060001000818408502000100041040c2060001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738825,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1975,
        opponentMmrAfter: 1967,
        opponentSlpWon: 0,
        playerMmrBefore: 2045,
        playerMmrAfter: 2053,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7b649da9-9b54-4a92-9507-d042466c59a9",
        gameTimestamp: "2023-05-29 07:43:36",
        startTimestamp: "2023-05-29 07:38:50",
        endTimestamp: "2023-05-29 07:43:36",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7b649da9-9b54-4a92-9507-d042466c59a9&userId=1ecad86f-e474-634a-b10f-438c79d70755",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105124",
        oponentTeamId: "5022556",
        opponent: "1ed48b15-7db4-6151-af17-2542f0edf74b",
        playerTeam: [
          {
            gene: "0x8000000000001000381c000820c00000001000410a0820a00010008084042020001001020810408000100042881840c000100081840c2060001001018418002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nondec",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11662331,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000081c03082080000000100041020810400010008084044080001001020218408000100103020800a0001000818a042060001000008218002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728522,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003018030c10400000001000c08a1430800010008088105020001001020810408000100042860840a000100041040c2080001001018804308",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11634376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002404050820800000001001428a14102000100042821420a000100001800c10a0001000010a185020001000010014406000100101020c404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11745098,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2142,
        opponentMmrAfter: 2149,
        opponentSlpWon: 24,
        playerMmrBefore: 2052,
        playerMmrAfter: 2045,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "fcab8d1e-fc13-4ba3-8172-0b110a1c56f1",
        gameTimestamp: "2023-05-29 07:37:48",
        startTimestamp: "2023-05-29 07:31:47",
        endTimestamp: "2023-05-29 07:37:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fcab8d1e-fc13-4ba3-8172-0b110a1c56f1&userId=1ecad86f-e474-634a-b10f-438c79d70755",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5105124",
        oponentTeamId: "5089833",
        opponent: "1ecc1502-6e24-62a2-badb-20451ca3da98",
        playerTeam: [
          {
            gene: "0x8000000000001000381c000820c00000001000410a0820a00010008084042020001001020810408000100042881840c000100081840c2060001001018418002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nondec",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11662331,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000081c03082080000000100041020810400010008084044080001001020218408000100103020800a0001000818a042060001000008218002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728522,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003018030c10400000001000c08a1430800010008088105020001001020810408000100042860840a000100041040c2080001001018804308",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11634376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001812050420c00000001000c080100040001000c200080020001000c2001400400010010086144020001000c106082060001000c1061810a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 101487,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c12071040800000001000008a141020001000820010304000100001800c00600010010100043060001000008a1450a000100102080c004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11711470,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0407104100000000100002001420400010000080040020001000010a14004000100001000800400010000080040020001000028014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nondec",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 619281,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1990,
        opponentMmrAfter: 1982,
        opponentSlpWon: 0,
        playerMmrBefore: 2044,
        playerMmrAfter: 2052,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "87309b2e-37a8-4267-a198-dc6d72abe5f6",
        gameTimestamp: "2023-05-29 07:29:57",
        startTimestamp: "2023-05-29 07:26:29",
        endTimestamp: "2023-05-29 07:29:57",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=87309b2e-37a8-4267-a198-dc6d72abe5f6&userId=1ecad86f-e474-634a-b10f-438c79d70755",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105124",
        oponentTeamId: "5087309",
        opponent: "1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        playerTeam: [
          {
            gene: "0x8000000000001000381c000820c00000001000410a0820a00010008084042020001001020810408000100042881840c000100081840c2060001001018418002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nondec",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11662331,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000081c03082080000000100041020810400010008084044080001001020218408000100103020800a0001000818a042060001000008218002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728522,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003018030c10400000001000c08a1430800010008088105020001001020810408000100042860840a000100041040c2080001001018804308",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11634376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001008050c00c00000001001428a1450a000100042821410a00010000186040060001001030018206000100041020810c0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11714648,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001202020c30800000001001428a1430400010008202142040001000018a0c006000100143020850c00010000282141040001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11740191,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000000e0b0830000000001001428a1410a000100102840840a00010000186100060001001008818404000100041040c4060001001010808404",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11730877,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2055,
        opponentMmrAfter: 2064,
        opponentSlpWon: 24,
        playerMmrBefore: 2053,
        playerMmrAfter: 2044,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "27eeb735-a70a-4d4a-9316-2e4ee856e6a4",
        gameTimestamp: "2023-05-29 07:26:01",
        startTimestamp: "2023-05-29 07:20:41",
        endTimestamp: "2023-05-29 07:26:01",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=27eeb735-a70a-4d4a-9316-2e4ee856e6a4&userId=1ecad86f-e474-634a-b10f-438c79d70755",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105124",
        oponentTeamId: "5077935",
        opponent: "1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
        playerTeam: [
          {
            gene: "0x8000000000001000381c000820c00000001000410a0820a00010008084042020001001020810408000100042881840c000100081840c2060001001018418002",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nondec",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11662331,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000081c03082080000000100041020810400010008084044080001001020218408000100103020800a0001000818a042060001000008218002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728522,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003018030c10400000001000c08a1430800010008088105020001001020810408000100042860840a000100041040c2080001001018804308",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11634376,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000081802083080000000100142860450a0001000428208308000100001800c0060001001430a1850c000100002880c1080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11715971,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000000820c0000000100142861040a000100102021030400010000188045080001001008818402000100041080c2040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11714440,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c1a0d082100000000100142880820a0001001028810208000100001800c3080001001030814502000100101881420a0001001010a10306",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11734111,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2047,
        opponentMmrAfter: 2057,
        opponentSlpWon: 24,
        playerMmrBefore: 2063,
        playerMmrAfter: 2053,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:38.000Z",
  },
  {
    clientId: "1ec9eb6f-4cf7-6e19-a60c-1b4dbe52e026",
    name: "WildTV | GG",
    rank: "Challenger",
    tier: 0,
    topRank: 55,
    vstar: 2052,
    avatar: "2;0",
    battles: [
      {
        battleUuid: "f8a7f04d-def8-4e85-ade8-8cebdb2c6566",
        gameTimestamp: "2023-05-29 06:50:10",
        startTimestamp: "2023-05-29 06:46:50",
        endTimestamp: "2023-05-29 06:50:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f8a7f04d-def8-4e85-ade8-8cebdb2c6566&userId=1ec9eb6f-4cf7-6e19-a60c-1b4dbe52e026",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5077915",
        oponentTeamId: "4443718",
        opponent: "1ecc6ca3-9415-678d-9cac-fb8356503441",
        playerTeam: [
          {
            gene: "0x800000000000010003800040830800000001001428a141080001000428a14404000100001800c2060001001430a1840c000100041080c406000100101080850c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11730402,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100014080e0820c00000001001428a081080001000428208408000100001800c0060001001430a041020001000028008406000100101080830a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11721186,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340c05082080000000100142821410a0001001020010102000100001880c408000100103021050c000100101880c002000100101020c50c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11712358,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000100c050820800000001000c100143020001000c086043020001000c106083020001000c2861430a0001000c3021030c0001000c08604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 10287350,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000140b801020800000001000c086143020001000c086043020001000c0861830c0001000c2861430a0001000c3061830a0001000c0800c30c",
            runes: ["rune_plant_2010_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nondec",
              ears: "ecard_plant_3013_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "ecard_plant_ena_1",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 10301914,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000141c0e0821000000001000c102043040001000c086043020001000c086083080001000c2861430a0001000c3061830c0001000c08004302",
            runes: ["rune_neutral_1011_s4"],
            charms: {
              back: "ecard_neutral_2017_s4",
              ears: "ecard_neutral_1012_s4",
              eyes: "ecard_plant_ena_1",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_plant_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 5088629,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5dd41ea1-1865-4328-986d-03eb6624dde3",
        gameTimestamp: "2023-05-29 03:19:46",
        startTimestamp: "2023-05-29 03:14:54",
        endTimestamp: "2023-05-29 03:19:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5dd41ea1-1865-4328-986d-03eb6624dde3&userId=1ec9eb6f-4cf7-6e19-a60c-1b4dbe52e026",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5077915",
        oponentTeamId: "5074913",
        opponent: "1ec9eb6f-6240-657e-a60c-3afb003e6ca0",
        playerTeam: [
          {
            gene: "0x800000000000010003800040830800000001001428a141080001000428a14404000100001800c2060001001430a1840c000100041080c406000100101080850c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11730402,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100014080e0820c00000001001428a081080001000428208408000100001800c0060001001430a041020001000028008406000100101080830a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11721186,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340c05082080000000100142821410a0001001020010102000100001880c408000100103021050c000100101880c002000100101020c50c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11712358,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010002c160b102080000000100001021010200010004080080040001000c304082060001001410a0850400010004208105040001000c10608304",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11331324,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c040210208000000010000100084080001000020a10008000100003001810600010000080040020001000008004002000100001840c104",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11612638,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003804000820c00000001000c084040040001000c1060820a0001000c3061830a0001000c2861430a00010004208081080001000c1060850a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11123112,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1987,
        opponentMmrAfter: 1979,
        opponentSlpWon: 0,
        playerMmrBefore: 2044,
        playerMmrAfter: 2052,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bcea13f5-276e-4df2-95c1-783877a77ab8",
        gameTimestamp: "2023-05-29 03:14:09",
        startTimestamp: "2023-05-29 03:09:49",
        endTimestamp: "2023-05-29 03:14:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bcea13f5-276e-4df2-95c1-783877a77ab8&userId=1ec9eb6f-4cf7-6e19-a60c-1b4dbe52e026",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5077915",
        oponentTeamId: "5041986",
        opponent: "1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
        playerTeam: [
          {
            gene: "0x800000000000010003800040830800000001001428a141080001000428a14404000100001800c2060001001430a1840c000100041080c406000100101080850c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11730402,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100014080e0820c00000001001428a081080001000428208408000100001800c0060001001430a041020001000028008406000100101080830a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11721186,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340c05082080000000100142821410a0001001020010102000100001880c408000100103021050c000100101880c002000100101020c50c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11712358,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001008020841000000001000c1060830400010000100084020001000c3081030c0001000c1041810200010010182101080001000c10608304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11141730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e001021000000001000010214002000100002801000a00010000186101020001000c28018002000100001000830a0001000008604108",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718771,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000102100040001000008010002000100001000800400010000100080080001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5511329,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2040,
        opponentMmrAfter: 2030,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2044,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "58542b5f-2f1f-4023-8cd2-605f364203cb",
        gameTimestamp: "2023-05-29 03:09:23",
        startTimestamp: "2023-05-29 03:04:40",
        endTimestamp: "2023-05-29 03:09:23",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=58542b5f-2f1f-4023-8cd2-605f364203cb&userId=1ec9eb6f-4cf7-6e19-a60c-1b4dbe52e026",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5077915",
        oponentTeamId: "5107175",
        opponent: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        playerTeam: [
          {
            gene: "0x800000000000010003800040830800000001001428a141080001000428a14404000100001800c2060001001430a1840c000100041080c406000100101080850c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11730402,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100014080e0820c00000001001428a081080001000428208408000100001800c0060001001430a041020001000028008406000100101080830a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11721186,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340c05082080000000100142821410a0001001020010102000100001880c408000100103021050c000100101880c002000100101020c50c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11712358,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2091,
        opponentMmrAfter: 2099,
        opponentSlpWon: 24,
        playerMmrBefore: 2042,
        playerMmrAfter: 2034,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "21e2478b-a9d7-4a91-9770-42ab519ad7e9",
        gameTimestamp: "2023-05-29 03:03:51",
        startTimestamp: "2023-05-29 02:58:48",
        endTimestamp: "2023-05-29 03:03:51",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=21e2478b-a9d7-4a91-9770-42ab519ad7e9&userId=1ec9eb6f-4cf7-6e19-a60c-1b4dbe52e026",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5077915",
        oponentTeamId: "5108783",
        opponent: "1ec9eb73-a403-6333-a60c-8000c1c224e7",
        playerTeam: [
          {
            gene: "0x800000000000010003800040830800000001001428a141080001000428a14404000100001800c2060001001430a1840c000100041080c406000100101080850c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11730402,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100014080e0820c00000001001428a081080001000428208408000100001800c0060001001430a041020001000028008406000100101080830a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11721186,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340c05082080000000100142821410a0001001020010102000100001880c408000100103021050c000100101880c002000100101020c50c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11712358,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000081c050c3080000000100142820450a0001000428a0810a000100001841400a0001001430a1850c00010010180044040001001010808208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740025,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c1a070820c00000001001428a1450a000100100841040a000100001800c0060001001030818102000100101880c4060001001010204404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11740698,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340a0b0800000000001001428a1410a0001000820204008000100001800c006000100103081850a000100101880c4020001001010808106",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11751406,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1995,
        opponentMmrAfter: 1987,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2042,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:39.000Z",
  },
  {
    clientId: "1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
    name: "Anakin | ☁ Red Cloudz ☁",
    rank: "Challenger",
    tier: 0,
    topRank: 56,
    vstar: 2052,
    avatar: "s3_top1k;silver_s3",
    battles: [
      {
        battleUuid: "fec5944e-7141-43ee-b03d-f6a689c9993e",
        gameTimestamp: "2023-05-29 04:27:02",
        startTimestamp: "2023-05-29 04:23:05",
        endTimestamp: "2023-05-29 04:27:02",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fec5944e-7141-43ee-b03d-f6a689c9993e&userId=1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096276",
        oponentTeamId: "5103941",
        opponent: "1ec9eb6f-5043-6b45-a60c-84c89f6ecc67",
        playerTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c080b0841000000001000810410408000100002841000a000100001800c30c000100141040400c0001000c30618002000100000800c004",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11642208,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120910208000000010008204102080001000828414002000100081840c206000100083041010c000100083041820c0001000810418204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11663603,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002c180b1021000000001000c1060810800010000100083080001000c30a082060001001410a0850400010004102101080001000c1060800a",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2018_s4_nft",
            },
            axie_id: 11304060,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2000,
        opponentMmrAfter: 1992,
        opponentSlpWon: 0,
        playerMmrBefore: 2044,
        playerMmrAfter: 2052,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "caccde1c-30ca-4633-960c-329fc0ea9561",
        gameTimestamp: "2023-05-29 04:22:35",
        startTimestamp: "2023-05-29 04:16:45",
        endTimestamp: "2023-05-29 04:22:35",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=caccde1c-30ca-4633-960c-329fc0ea9561&userId=1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096276",
        oponentTeamId: "5086077",
        opponent: "1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        playerTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2038,
        opponentMmrAfter: 2028,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2044,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "92df2f38-b0be-46a7-9098-4f69a88cb587",
        gameTimestamp: "2023-05-29 04:15:56",
        startTimestamp: "2023-05-29 04:10:57",
        endTimestamp: "2023-05-29 04:15:56",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=92df2f38-b0be-46a7-9098-4f69a88cb587&userId=1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096276",
        oponentTeamId: "5108873",
        opponent: "1ed343d0-4d9d-6c14-9110-6fefe1ecc723",
        playerTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001408070c2080000000100142840450a000100042820440a0001000018a184020001001430618508000100101880430a0001001010808002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748779,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c020c2080000000100142881040a0001000428214104000100001800c10a0001001430a1840400010000282040040001001010004002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741436,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c0c020830c000000010014280043080001000428214002000100001800c00a000100103080440a000100041080c1080001001010218404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748023,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2002,
        opponentMmrAfter: 2013,
        opponentSlpWon: 24,
        playerMmrBefore: 2045,
        playerMmrAfter: 2034,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "ffc80cf2-1f0d-4498-b2f0-de9205ed2123",
        gameTimestamp: "2023-05-29 04:09:40",
        startTimestamp: "2023-05-29 04:04:27",
        endTimestamp: "2023-05-29 04:09:40",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ffc80cf2-1f0d-4498-b2f0-de9205ed2123&userId=1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5096276",
        oponentTeamId: "5042811",
        opponent: "1ec9eb7b-2999-6860-a60c-8796c3d23b5d",
        playerTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100028160a0400c0000000100142820450a0001000428604402000100001840410c000100143040c00c000100002880c0060001001010608006",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11720084,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000241a090820c0000000100142800850a000100001001450a000100001880c002000100001001420a0001000008a102020001000008004002",
            runes: ["rune_beast_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11725899,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000101a0d0840800000001001428a14208000100102881040a00010000184181020001000c2820420a000100101821400a0001001010808102",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "",
            },
            axie_id: 11720062,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1991,
        opponentMmrAfter: 1983,
        opponentSlpWon: 0,
        playerMmrBefore: 2037,
        playerMmrAfter: 2045,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9e43fc91-de13-4f6a-9f36-edc9d0d818f4",
        gameTimestamp: "2023-05-29 04:03:51",
        startTimestamp: "2023-05-29 03:58:19",
        endTimestamp: "2023-05-29 04:03:51",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9e43fc91-de13-4f6a-9f36-edc9d0d818f4&userId=1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5096276",
        oponentTeamId: "5095412",
        opponent: "1ec9eb7e-6a0d-6e97-a60c-aee122ad13a1",
        playerTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0a04083000000000100142840850a0001000428214102000100001800c006000100143021400800010004102040040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724393,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000140a050830800000001001428a1450a000100042881020800010000182140060001001430a183080001000028208306000100101020c102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11749954,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100c040821000000001001428a1450a0001000010410408000100001800c0060001001030a1820c000100101800810a0001001010808506",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11752896,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2059,
        opponentMmrAfter: 2068,
        opponentSlpWon: 24,
        playerMmrBefore: 2046,
        playerMmrAfter: 2037,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:40.000Z",
  },
  {
    clientId: "1ec9eb77-4c4e-684a-a60c-cb2cb30b2421",
    name: "Zion x slvvrabbtplays | Guildless ",
    rank: "Challenger",
    tier: 0,
    topRank: 57,
    vstar: 2051,
    avatar: "easter_eggs;paschal_eggs",
    battles: [
      {
        battleUuid: "6824ab24-ea50-4985-a805-f4e280c64600",
        gameTimestamp: "2023-05-29 03:30:53",
        startTimestamp: "2023-05-29 03:24:59",
        endTimestamp: "2023-05-29 03:30:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6824ab24-ea50-4985-a805-f4e280c64600&userId=1ec9eb77-4c4e-684a-a60c-cb2cb30b2421",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088874",
        oponentTeamId: "5107175",
        opponent: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        playerTeam: [
          {
            gene: "0x80000000000010000000020c20000000001001428a1450a0001000428214304000100001800c0060001001430814008000100041080c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11739382,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001408070830c00000001001428a1450a0001001428a14208000100001800c006000100103081850c000100101880c1020001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11732174,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000340e050840000000001001428a143080001000820a14408000100001800c006000100103060840c000100101880c406000100101080c006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11740695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2080,
        opponentMmrAfter: 2069,
        opponentSlpWon: 0,
        playerMmrBefore: 2040,
        playerMmrAfter: 2051,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "caf18932-3a07-4a59-9c8b-ba2d12bd7d7a",
        gameTimestamp: "2023-05-29 03:24:20",
        startTimestamp: "2023-05-29 03:18:54",
        endTimestamp: "2023-05-29 03:24:20",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=caf18932-3a07-4a59-9c8b-ba2d12bd7d7a&userId=1ec9eb77-4c4e-684a-a60c-cb2cb30b2421",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5088874",
        oponentTeamId: "5057896",
        opponent: "1ec9eb6f-5bd5-660f-a60c-d18fed252681",
        playerTeam: [
          {
            gene: "0x80000000000010000000020c20000000001001428a1450a0001000428214304000100001800c0060001001430814008000100041080c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11739382,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001408070830c00000001001428a1450a0001001428a14208000100001800c006000100103081850c000100101880c1020001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11732174,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000340e050840000000001001428a143080001000820a14408000100001800c006000100103060840c000100101880c406000100101080c006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11740695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000180c020020000000001001428a14404000100042881010a000100001800c00c0001001430804508000100041080c4060001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11731020,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808090820c000000010014284142020001000820810204000100001800c10a000100103081840c00010010188185060001001010610006",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11727051,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a090030800000001001428a140080001001020810408000100001860420c000100102840840c00010010182144060001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11726163,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1987,
        opponentMmrAfter: 1998,
        opponentSlpWon: 22,
        playerMmrBefore: 2051,
        playerMmrAfter: 2040,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "83310aae-9b35-4ffd-a6a4-9dc90bff333a",
        gameTimestamp: "2023-05-29 01:42:16",
        startTimestamp: "2023-05-29 01:37:01",
        endTimestamp: "2023-05-29 01:42:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=83310aae-9b35-4ffd-a6a4-9dc90bff333a&userId=1ec9eb77-4c4e-684a-a60c-cb2cb30b2421",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088874",
        oponentTeamId: "5107205",
        opponent: "1ec9eb7e-291e-687e-a60c-e0c550d311b3",
        playerTeam: [
          {
            gene: "0x80000000000010000000020c20000000001001428a1450a0001000428214304000100001800c0060001001430814008000100041080c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11739382,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001408070830c00000001001428a1450a0001001428a14208000100001800c006000100103081850c000100101880c1020001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11732174,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000340e050840000000001001428a143080001000820a14408000100001800c006000100103060840c000100101880c406000100101080c006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11740695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001008050c3000000000100142880400a000100042860420a000100001800c006000100143040c20a000100001080800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11720505,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001816040c30c00000001001428a1450a000100042820450a000100001800c0060001000428a1810a00010004102044060001001010808404",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11719901,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c1a040c30800000001001428a1420a0001000428814204000100001821830c000100101041450c000100101880c1080001001010808506",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11710855,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1989,
        opponentMmrAfter: 1981,
        opponentSlpWon: 0,
        playerMmrBefore: 2043,
        playerMmrAfter: 2051,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bc5da004-049e-4cf8-9db7-41e07a4ebd25",
        gameTimestamp: "2023-05-29 01:35:29",
        startTimestamp: "2023-05-29 01:31:08",
        endTimestamp: "2023-05-29 01:35:29",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bc5da004-049e-4cf8-9db7-41e07a4ebd25&userId=1ec9eb77-4c4e-684a-a60c-cb2cb30b2421",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088874",
        oponentTeamId: "4949081",
        opponent: "1ec9eb6f-514f-6d6e-a60c-816c8fa2ae07",
        playerTeam: [
          {
            gene: "0x80000000000010000000020c20000000001001428a1450a0001000428214304000100001800c0060001001430814008000100041080c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11739382,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001408070830c00000001001428a1450a0001001428a14208000100001800c006000100103081850c000100101880c1020001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11732174,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000340e050840000000001001428a143080001000820a14408000100001800c006000100103060840c000100101880c406000100101080c006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11740695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10002010020800800000001001408014004000100002000810a0001000820414302000100003001400a00010000200040060001000830a0420c",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 9455603,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10002410090820800000001000010a1400400010000204143080001000018408006000100002801820c000100000801030c0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11579091,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x100024040e10410000000010000280143080001000020408304000100081000c50c000100002841410a0001000008a1010a000100000820c202",
            runes: ["rune_beast_2011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_beast_2012_s4_nft",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11534640,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1899,
        opponentMmrAfter: 1893,
        opponentSlpWon: 0,
        playerMmrBefore: 2037,
        playerMmrAfter: 2043,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "53c0b6ce-86bd-426c-918b-6173fd42d444",
        gameTimestamp: "2023-05-29 01:29:38",
        startTimestamp: "2023-05-29 01:24:59",
        endTimestamp: "2023-05-29 01:29:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=53c0b6ce-86bd-426c-918b-6173fd42d444&userId=1ec9eb77-4c4e-684a-a60c-cb2cb30b2421",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088874",
        oponentTeamId: "5093989",
        opponent: "1ed3aa31-a070-6a69-a1a9-760cc219389a",
        playerTeam: [
          {
            gene: "0x80000000000010000000020c20000000001001428a1450a0001000428214304000100001800c0060001001430814008000100041080c4060001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11739382,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001408070830c00000001001428a1450a0001001428a14208000100001800c006000100103081850c000100101880c1020001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11732174,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000340e050840000000001001428a143080001000820a14408000100001800c006000100103060840c000100101880c406000100101080c006",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11740695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000100a050c30800000001000410a14104000100041021450a0001000018018006000100143021850a000100002801420a0001000408204102",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11742883,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240e0a10408000000010010286144080001001028614304000100001801830c000100101081820a000100100800410c0001000008004406",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "",
            },
            axie_id: 11721323,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000e03010830c00000001001010808102000100102820820a00010004100084080001000420a1440a00010004302185040001000418010008",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11729961,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1948,
        opponentMmrAfter: 1941,
        opponentSlpWon: 0,
        playerMmrBefore: 2030,
        playerMmrAfter: 2037,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:40.000Z",
  },
  {
    clientId: "1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
    name: "Hi",
    rank: "Challenger",
    tier: 0,
    topRank: 58,
    vstar: 2051,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "9f6f706f-86c2-49e0-9df8-b3d5a3ee684c",
        gameTimestamp: "2023-05-29 12:01:02",
        startTimestamp: "2023-05-29 11:59:22",
        endTimestamp: "2023-05-29 12:01:02",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9f6f706f-86c2-49e0-9df8-b3d5a3ee684c&userId=1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5041986",
        oponentTeamId: "4955144",
        opponent: "1ec9eb6f-6c32-6569-a60c-a17b7efcf654",
        playerTeam: [
          {
            gene: "0x180000000000010001008020841000000001000c1060830400010000100084020001000c3081030c0001000c1041810200010010182101080001000c10608304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11141730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e001021000000001000010214002000100002801000a00010000186101020001000c28018002000100001000830a0001000008604108",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718771,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000102100040001000008010002000100001000800400010000100080080001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5511329,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x280000000000010002c1a0d0c20800000001001420a0430a0001001428a145040001001420a102060001001420a1020a0001001418a0c5040001000c20a04502",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2017_s4",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4",
              tail: "",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11463434,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2800000000000100020160d0820800000001001408a045020001001428a1410a0001001420a104040001001420a1050a0001001418a0c5060001001408a14502",
            runes: ["rune_reptile_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "",
              eyes: "",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_reptile_3012_s4_nondec",
              mouth: "ecard_neutral_2018_s4_nondec",
            },
            axie_id: 11556569,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010003012090820800000001000028208104000100140841430800010004304104020001001418410208000100041020c108000100082801820a",
            runes: ["rune_neutral_1011_s4"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_neutral_1012_s4",
              eyes: "ecard_neutral_1012_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_1012_s4",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11506981,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e8bea508-fe55-4bf5-a89f-64423ac52d9f",
        gameTimestamp: "2023-05-29 10:39:58",
        startTimestamp: "2023-05-29 10:36:17",
        endTimestamp: "2023-05-29 10:39:58",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e8bea508-fe55-4bf5-a89f-64423ac52d9f&userId=1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5041986",
        oponentTeamId: "4824021",
        opponent: "1ec9eb6f-6fa3-69b1-a60c-2702aa02d150",
        playerTeam: [
          {
            gene: "0x180000000000010001008020841000000001000c1060830400010000100084020001000c3081030c0001000c1041810200010010182101080001000c10608304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11141730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e001021000000001000010214002000100002801000a00010000186101020001000c28018002000100001000830a0001000008604108",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718771,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000102100040001000008010002000100001000800400010000100080080001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5511329,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000301c0c1030c0000000100002860410200010004284083040001000c086041080001000c080144040001000c1021430a0001000008604302",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11737285,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002404090000c00000001000c0860820800010000200105040001000c0821830a0001000c08604208000100041881020c000100141881820a",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2012_s4_nft",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11746565,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024100e1021000000001000428a0800a000100002061000a0001000c080180020001000c0820c30a000100081021430c0001000c10604302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2012_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bug_2012_s4_nft",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11666336,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1993,
        opponentMmrAfter: 1985,
        opponentSlpWon: 0,
        playerMmrBefore: 2043,
        playerMmrAfter: 2051,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ae51c69d-0989-496e-807d-ed55d27b6bb1",
        gameTimestamp: "2023-05-29 10:35:08",
        startTimestamp: "2023-05-29 10:29:30",
        endTimestamp: "2023-05-29 10:35:08",
        result: "D",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ae51c69d-0989-496e-807d-ed55d27b6bb1&userId=1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5041986",
        oponentTeamId: "5088254",
        opponent: "1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        playerTeam: [
          {
            gene: "0x180000000000010001008020841000000001000c1060830400010000100084020001000c3081030c0001000c1041810200010010182101080001000c10608304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11141730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e001021000000001000010214002000100002801000a00010000186101020001000c28018002000100001000830a0001000008604108",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718771,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000102100040001000008010002000100001000800400010000100080080001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5511329,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2001,
        opponentMmrAfter: 2002,
        opponentSlpWon: 12,
        playerMmrBefore: 2044,
        playerMmrAfter: 2043,
        playerMoonshardWon: 74,
        opponentMoonshardWon: 248,
      },
      {
        battleUuid: "6a857189-3274-42ba-9658-c8f99534a7bb",
        gameTimestamp: "2023-05-29 10:28:27",
        startTimestamp: "2023-05-29 10:23:24",
        endTimestamp: "2023-05-29 10:28:27",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6a857189-3274-42ba-9658-c8f99534a7bb&userId=1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5041986",
        oponentTeamId: "4767735",
        opponent: "1ecb411f-945c-6fdd-83bd-b6a65b11faec",
        playerTeam: [
          {
            gene: "0x180000000000010001008020841000000001000c1060830400010000100084020001000c3081030c0001000c1041810200010010182101080001000c10608304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11141730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e001021000000001000010214002000100002801000a00010000186101020001000c28018002000100001000830a0001000008604108",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718771,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000102100040001000008010002000100001000800400010000100080080001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5511329,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x28000000000001000100a040400400000001001408a145080001001428a1450a0001001420a105080001001420a100060001001418a0c00c0001000008614504",
            runes: ["rune_reptile_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_3010_s4_nft",
              ears: "ecard_reptile_2011_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11707219,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140c060c30c00000001001408a04502000100142841450a0001001420a104020001000c30a08506000100141801020c0001000c20a0c20c",
            runes: ["rune_reptile_2011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_2017_s4_nft",
              eyes: "ecard_reptile_3013_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11713914,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000008000400c00000001001408804002000100142861450a000100142040c1020001001420a105080001001418a0c5060001001428a18308",
            runes: ["rune_dawn_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_2017_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "",
              tail: "ecard_reptile_2013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nondec",
            },
            axie_id: 11710711,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1846,
        opponentMmrAfter: 1842,
        opponentSlpWon: 0,
        playerMmrBefore: 2040,
        playerMmrAfter: 2044,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "44006a5c-72d9-4473-99ec-bea72cc09f61",
        gameTimestamp: "2023-05-29 10:22:24",
        startTimestamp: "2023-05-29 10:20:39",
        endTimestamp: "2023-05-29 10:22:24",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=44006a5c-72d9-4473-99ec-bea72cc09f61&userId=1ec9eb6f-5fae-6b55-a60c-df8a6826c88d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5041986",
        oponentTeamId: "4733733",
        opponent: "1edbc8c1-c618-65a4-b935-4f0b93985933",
        playerTeam: [
          {
            gene: "0x180000000000010001008020841000000001000c1060830400010000100084020001000c3081030c0001000c1041810200010010182101080001000c10608304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11141730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e001021000000001000010214002000100002801000a00010000186101020001000c28018002000100001000830a0001000008604108",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718771,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000102100040001000008010002000100001000800400010000100080080001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 5511329,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010000004030c3080000000100041041410a00010008084042020001001020810408000100042821440c000100081840c20c0001001018808406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_bug_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11662334,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003018040020800000001000410208008000100080840420200010010208104080001001030214206000100081840c10a000100000800440c",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11745141,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001c0b800830800000001000c0821430200010008082102020001001020018406000100042821440800010004106142040001001018010002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717241,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1983,
        opponentMmrAfter: 1994,
        opponentSlpWon: 22,
        playerMmrBefore: 2051,
        playerMmrAfter: 2040,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:41.000Z",
  },
  {
    clientId: "1ec9eb77-530b-66df-a60c-aa6a69b618a0",
    name: "Tomasino ???? | Looking for Guild",
    rank: "Challenger",
    tier: 0,
    topRank: 59,
    vstar: 2051,
    avatar: "s3_top20k;silver_s3",
    battles: [
      {
        battleUuid: "55bb3fae-eccc-430c-825c-96f0793dc872",
        gameTimestamp: "2023-05-29 11:18:53",
        startTimestamp: "2023-05-29 11:12:20",
        endTimestamp: "2023-05-29 11:18:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=55bb3fae-eccc-430c-825c-96f0793dc872&userId=1ec9eb77-530b-66df-a60c-aa6a69b618a0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5068577",
        oponentTeamId: "5083225",
        opponent: "1ec9eb6f-820c-6d62-a60c-9a1f7853cb83",
        playerTeam: [
          {
            gene: "0x280000000000010001c14021040c000000010004102082080001000808404502000100102080c2060001000018a1020c0001000410208108000100101880c102",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e104080000000100042020850400010008084041080001001020a144080001001030a104020001000818204206000100000800450a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11714986,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001408020c3000000000100041020840400010008084045020001001020808306000100042821410a0001000818804206000100101861440a",
            runes: ["rune_dusk_2011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11732904,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a020801000000001001428a1450a000100042881010a00010000180080060001001430a1850c000100101880c0080001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000804040430c00000001001428a1450a0001000428408204000100001800c1080001001430a1830200010000282144060001001010808006",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11753041,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101a050830c00000001001428a144040001000020a08004000100001800c0060001001008204202000100000880c00a000100101020840a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11695659,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2001,
        opponentMmrAfter: 1993,
        opponentSlpWon: 0,
        playerMmrBefore: 2043,
        playerMmrAfter: 2051,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "696bf9db-e285-464d-a8d7-afaaa2a63649",
        gameTimestamp: "2023-05-29 11:08:59",
        startTimestamp: "2023-05-29 11:01:19",
        endTimestamp: "2023-05-29 11:08:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=696bf9db-e285-464d-a8d7-afaaa2a63649&userId=1ec9eb77-530b-66df-a60c-aa6a69b618a0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5068577",
        oponentTeamId: "5107887",
        opponent: "1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        playerTeam: [
          {
            gene: "0x280000000000010001c14021040c000000010004102082080001000808404502000100102080c2060001000018a1020c0001000410208108000100101880c102",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e104080000000100042020850400010008084041080001001020a144080001001030a104020001000818204206000100000800450a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11714986,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001408020c3000000000100041020840400010008084045020001001020808306000100042821410a0001000818804206000100101861440a",
            runes: ["rune_dusk_2011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11732904,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c070c20c000000010014282045080001000428214102000100001800c1080001000428010104000100041080c2040001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11730561,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2010,
        opponentMmrAfter: 2001,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2043,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6a7b0fde-85bc-4935-be41-23ea5677cde2",
        gameTimestamp: "2023-05-29 11:00:46",
        startTimestamp: "2023-05-29 10:54:07",
        endTimestamp: "2023-05-29 11:00:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6a7b0fde-85bc-4935-be41-23ea5677cde2&userId=1ec9eb77-530b-66df-a60c-aa6a69b618a0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5068577",
        oponentTeamId: "5055323",
        opponent: "1ec9eb6f-a0b4-6852-a60c-a678abcaf4ee",
        playerTeam: [
          {
            gene: "0x280000000000010001c14021040c000000010004102082080001000808404502000100102080c2060001000018a1020c0001000410208108000100101880c102",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e104080000000100042020850400010008084041080001001020a144080001001030a104020001000818204206000100000800450a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11714986,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001408020c3000000000100041020840400010008084045020001001020808306000100042821410a0001000818804206000100101861440a",
            runes: ["rune_dusk_2011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11732904,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a040800800000001001428a1450a0001000428214102000100001800c4020001001430810008000100041020410a0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11717652,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010003816070c20800000001001428808404000100102081040a00010000186100060001001030814502000100101881410a0001001010a10404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11726268,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c120008208000000010014288044020001001020810408000100001820c0060001001008a0440a000100101820c4060001001010614404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11656775,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1906,
        opponentMmrAfter: 1900,
        opponentSlpWon: 0,
        playerMmrBefore: 2028,
        playerMmrAfter: 2034,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "50d3631e-207e-4cac-bf1b-dcc35a7e417a",
        gameTimestamp: "2023-05-29 08:43:59",
        startTimestamp: "2023-05-29 08:38:14",
        endTimestamp: "2023-05-29 08:43:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=50d3631e-207e-4cac-bf1b-dcc35a7e417a&userId=1ec9eb77-530b-66df-a60c-aa6a69b618a0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5068577",
        oponentTeamId: "5049492",
        opponent: "1edb3382-ec4e-6d32-9b2f-cfec81e89567",
        playerTeam: [
          {
            gene: "0x280000000000010001c14021040c000000010004102082080001000808404502000100102080c2060001000018a1020c0001000410208108000100101880c102",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e104080000000100042020850400010008084041080001001020a144080001001030a104020001000818204206000100000800450a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11714986,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001408020c3000000000100041020840400010008084045020001001020808306000100042821410a0001000818804206000100101861440a",
            runes: ["rune_dusk_2011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11732904,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a0d0440c00000001001428a140080001001020810208000100001800c1020001001430214006000100101841840c000100101080800c",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11729197,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001806000820c00000001001428a1420a0001000820814408000100001800c1020001001030818104000100101800440c0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11727029,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000240a0b0820c00000001001428a1450a00010004282141020001000018804002000100042801410a000100041040c10a000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724409,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2043,
        opponentMmrAfter: 2033,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2028,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "72608ba0-f4e4-4180-b5c0-ad69ead194bf",
        gameTimestamp: "2023-05-29 08:37:07",
        startTimestamp: "2023-05-29 08:30:56",
        endTimestamp: "2023-05-29 08:37:07",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=72608ba0-f4e4-4180-b5c0-ad69ead194bf&userId=1ec9eb77-530b-66df-a60c-aa6a69b618a0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5068577",
        oponentTeamId: "5044806",
        opponent: "1ecda672-7ddd-6991-8179-49518582467f",
        playerTeam: [
          {
            gene: "0x280000000000010001c14021040c000000010004102082080001000808404502000100102080c2060001000018a1020c0001000410208108000100101880c102",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e104080000000100042020850400010008084041080001001020a144080001001030a104020001000818204206000100000800450a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11714986,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001408020c3000000000100041020840400010008084045020001001020808306000100042821410a0001000818804206000100101861440a",
            runes: ["rune_dusk_2011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11732904,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_neutral_3010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2033,
        opponentMmrAfter: 2042,
        opponentSlpWon: 24,
        playerMmrBefore: 2027,
        playerMmrAfter: 2018,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:42.000Z",
  },
  {
    clientId: "1ec9eb6f-4cda-6d02-a60c-8f618113ea4f",
    name: ".Elcapo/Vnzla | free agent",
    rank: "Challenger",
    tier: 0,
    topRank: 60,
    vstar: 2050,
    avatar: "good_boy;fluffy_hooves",
    battles: [
      {
        battleUuid: "ed6a9931-5bb0-4844-a467-87494683b855",
        gameTimestamp: "2023-05-29 02:36:52",
        startTimestamp: "2023-05-29 02:32:23",
        endTimestamp: "2023-05-29 02:36:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ed6a9931-5bb0-4844-a467-87494683b855&userId=1ec9eb6f-4cda-6d02-a60c-8f618113ea4f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5024862",
        oponentTeamId: "5092431",
        opponent: "1ec9eb7e-387e-6f67-a60c-8926a641ca73",
        playerTeam: [
          {
            gene: "0x8000000000001000140a0210108000000010000286042080001000028404104000100001820820c000100003041430800010010184181080001000408004002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11685718,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402102000000000100001000800400010000204100080001000018004204000100002881800c000100000820400a0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11571311,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1607084100000000100001000800400010000206140020001000030408004000100000800450800010000080045080001001418414106",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11592091,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100014080e0020800000001001428a14208000100042880410a000100001800c40c000100143061850c0001001018a0c5080001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748709,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000101c060830c00000001001428a1450a000100082040820a000100001800c006000100103001440600010010180185060001001010808508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11742122,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070c30c00000001001428a104020001001020a0810400010000186185060001001008814404000100101000410a000100101080800a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_puffy_1",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11725172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2039,
        opponentMmrAfter: 2030,
        opponentSlpWon: 0,
        playerMmrBefore: 2041,
        playerMmrAfter: 2050,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "38922d6c-59c0-4f45-820c-f040d020a709",
        gameTimestamp: "2023-05-29 02:31:08",
        startTimestamp: "2023-05-29 02:24:09",
        endTimestamp: "2023-05-29 02:31:08",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=38922d6c-59c0-4f45-820c-f040d020a709&userId=1ec9eb6f-4cda-6d02-a60c-8f618113ea4f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5024862",
        oponentTeamId: "5018425",
        opponent: "1ed47ad1-e453-693b-8a28-108ddd08b24d",
        playerTeam: [
          {
            gene: "0x8000000000001000140a0210108000000010000286042080001000028404104000100001820820c000100003041430800010010184181080001000408004002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11685718,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402102000000000100001000800400010000204100080001000018004204000100002881800c000100000820400a0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11571311,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1607084100000000100001000800400010000206140020001000030408004000100000800450800010000080045080001001418414106",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11592091,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a0210308000000010014284084080001000c2881010a00010000186140060001001430a1800800010000282040020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11729639,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e071040c000000010000100084040001000008004508000100001000840800010000080041020001000008210508000100002800c00a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3012_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11474697,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c20800000001000408a141040001000028404302000100001820420400010004308185080001001420a045080001000408204106",
            runes: ["rune_mech_2011_s4_nondec"],
            charms: {
              back: "ecard_reptile_2012_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11688223,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2003,
        opponentMmrAfter: 1994,
        opponentSlpWon: 0,
        playerMmrBefore: 2032,
        playerMmrAfter: 2041,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "913c6444-5ed2-4066-8d0b-2b450c97efa2",
        gameTimestamp: "2023-05-29 02:23:10",
        startTimestamp: "2023-05-29 02:17:24",
        endTimestamp: "2023-05-29 02:23:10",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=913c6444-5ed2-4066-8d0b-2b450c97efa2&userId=1ec9eb6f-4cda-6d02-a60c-8f618113ea4f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5024862",
        oponentTeamId: "5109165",
        opponent: "1ecb1b4a-f534-6092-9fc2-7cb72129a807",
        playerTeam: [
          {
            gene: "0x8000000000001000140a0210108000000010000286042080001000028404104000100001820820c000100003041430800010010184181080001000408004002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11685718,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402102000000000100001000800400010000204100080001000018004204000100002881800c000100000820400a0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11571311,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1607084100000000100001000800400010000206140020001000030408004000100000800450800010000080045080001001418414106",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11592091,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x880000000000010002404030c20000000001001428a1410a000100042800810a0001000018a14108000100143081410a00010000282144060001001010608404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11742247,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100024080e0800c00000001001428a1450a0001001028008004000100001800c00c0001001430814408000100002880c10a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11732435,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0820000000001001428a140040001000428804004000100001800c4060001000c2841450c000100043080c4080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748753,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2119,
        opponentMmrAfter: 2126,
        opponentSlpWon: 24,
        playerMmrBefore: 2039,
        playerMmrAfter: 2032,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "b15cdc27-714b-49e5-b3f1-42b044a6e2c3",
        gameTimestamp: "2023-05-29 02:15:12",
        startTimestamp: "2023-05-29 02:10:49",
        endTimestamp: "2023-05-29 02:15:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b15cdc27-714b-49e5-b3f1-42b044a6e2c3&userId=1ec9eb6f-4cda-6d02-a60c-8f618113ea4f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5024862",
        oponentTeamId: "5105119",
        opponent: "1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        playerTeam: [
          {
            gene: "0x8000000000001000140a0210108000000010000286042080001000028404104000100001820820c000100003041430800010010184181080001000408004002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11685718,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402102000000000100001000800400010000204100080001000018004204000100002881800c000100000820400a0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11571311,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1607084100000000100001000800400010000206140020001000030408004000100000800450800010000080045080001001418414106",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11592091,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2059,
        opponentMmrAfter: 2049,
        opponentSlpWon: 0,
        playerMmrBefore: 2029,
        playerMmrAfter: 2039,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "04890f1c-c3d0-442a-a1ef-3bebdb20a10c",
        gameTimestamp: "2023-05-29 02:09:32",
        startTimestamp: "2023-05-29 02:04:23",
        endTimestamp: "2023-05-29 02:09:32",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=04890f1c-c3d0-442a-a1ef-3bebdb20a10c&userId=1ec9eb6f-4cda-6d02-a60c-8f618113ea4f",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5024862",
        oponentTeamId: "5108783",
        opponent: "1ec9eb73-a403-6333-a60c-8000c1c224e7",
        playerTeam: [
          {
            gene: "0x8000000000001000140a0210108000000010000286042080001000028404104000100001820820c000100003041430800010010184181080001000408004002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11685718,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402102000000000100001000800400010000204100080001000018004204000100002881800c000100000820400a0001000008004002",
            runes: ["rune_beast_2011_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11571311,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1607084100000000100001000800400010000206140020001000030408004000100000800450800010000080045080001001418414106",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11592091,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000081c050c3080000000100142820450a0001000428a0810a000100001841400a0001001430a1850c00010010180044040001001010808208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740025,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c1a070820c00000001001428a1450a000100100841040a000100001800c0060001001030818102000100101880c4060001001010204404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11740698,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000340a0b0800000000001001428a1410a0001000820204008000100001800c006000100103081850a000100101880c4020001001010808106",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11751406,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1965,
        opponentMmrAfter: 1977,
        opponentSlpWon: 22,
        playerMmrBefore: 2041,
        playerMmrAfter: 2029,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:43.000Z",
  },
  {
    clientId: "1ec9eb6f-490a-620b-a60c-c736e162e257",
    name: "Staz | YGG",
    rank: "Challenger",
    tier: 0,
    topRank: 61,
    vstar: 2049,
    avatar: "2;gold_08_s2",
    battles: [
      {
        battleUuid: "7966ab33-ec6a-4677-8571-4ba0842c0f21",
        gameTimestamp: "2023-05-29 11:32:22",
        startTimestamp: "2023-05-29 11:27:12",
        endTimestamp: "2023-05-29 11:32:22",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7966ab33-ec6a-4677-8571-4ba0842c0f21&userId=1ec9eb6f-490a-620b-a60c-c736e162e257",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5060007",
        oponentTeamId: "5022556",
        opponent: "1ed48b15-7db4-6151-af17-2542f0edf74b",
        playerTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001004080020c00000001001428a14108000100042881020a000100001800c108000100143080c50c000100101880c10a0001001010808102",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2019_s4",
            },
            axie_id: 11744737,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410021021000000001001428a1430a000100042821010a000100001800c0060001001430a1850c00010000288084060001001010808004",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751854,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004020c30c0000000100142800450a0001000428204204000100001841400600010010282081040001000028814408000100101080810a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11743442,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2083,
        opponentMmrAfter: 2072,
        opponentSlpWon: 0,
        playerMmrBefore: 2038,
        playerMmrAfter: 2049,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "20035969-24c0-4137-96f2-eebe5f269127",
        gameTimestamp: "2023-05-29 11:26:31",
        startTimestamp: "2023-05-29 11:22:37",
        endTimestamp: "2023-05-29 11:26:31",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=20035969-24c0-4137-96f2-eebe5f269127&userId=1ec9eb6f-490a-620b-a60c-c736e162e257",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5060007",
        oponentTeamId: "4782584",
        opponent: "1ec9eb7e-5786-6057-a60c-87d41d1814d8",
        playerTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000000100010160e0020800000001001428a0850a000100082081450a0001000018618006000100103021450200010010182082040001001010408404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11713787,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100018080e082000000000100142820850a000100042841040a000100001800c006000100143021410a000100041080c1040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11725195,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008001020800000001001428a1440200010010208144080001000018204502000100100820840a00010010188141060001001010808002",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11677998,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1972,
        opponentMmrAfter: 1964,
        opponentSlpWon: 0,
        playerMmrBefore: 2030,
        playerMmrAfter: 2038,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0f2a6634-c13c-4224-b100-d5a15531cf2f",
        gameTimestamp: "2023-05-29 11:22:05",
        startTimestamp: "2023-05-29 11:16:49",
        endTimestamp: "2023-05-29 11:22:05",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0f2a6634-c13c-4224-b100-d5a15531cf2f&userId=1ec9eb6f-490a-620b-a60c-c736e162e257",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5060007",
        oponentTeamId: "5090650",
        opponent: "1ec9eb77-511b-64e6-a60c-2bc1f8ec5d6f",
        playerTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140c050820800000001000c286083020001000c106083020001000c088043060001000c086144020001000c302043080001000c1061000a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11603356,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000038008308000000010010102144040001000c082140020001000c0840810c0001000c08604406000100041820c304000100000820c106",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "",
              mouth: "",
            },
            axie_id: 11741192,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c0a021020800000001000c282085080001000c10a083040001000c0860430a0001000c0841020800010004184104040001001030a0c008",
            runes: ["rune_beast_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "",
            },
            axie_id: 11753281,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2098,
        opponentMmrAfter: 2086,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2030,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "356a25e2-9da2-450c-8bc4-ad8a55fe8373",
        gameTimestamp: "2023-05-29 11:16:15",
        startTimestamp: "2023-05-29 11:11:58",
        endTimestamp: "2023-05-29 11:16:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=356a25e2-9da2-450c-8bc4-ad8a55fe8373&userId=1ec9eb6f-490a-620b-a60c-c736e162e257",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5060007",
        oponentTeamId: "5087063",
        opponent: "1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        playerTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2057,
        opponentMmrAfter: 2046,
        opponentSlpWon: 0,
        playerMmrBefore: 2007,
        playerMmrAfter: 2018,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "01cbbec3-9a9c-4727-800d-c83520e361c3",
        gameTimestamp: "2023-05-29 11:11:23",
        startTimestamp: "2023-05-29 11:06:55",
        endTimestamp: "2023-05-29 11:11:23",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=01cbbec3-9a9c-4727-800d-c83520e361c3&userId=1ec9eb6f-490a-620b-a60c-c736e162e257",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5060007",
        oponentTeamId: "4979416",
        opponent: "1ec9eb6f-4d1b-624b-a60c-eb69e0ff3866",
        playerTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001300040c20800000001000410a041080001000428214308000100001800c10a000100042821430800010004202101080001000408204102",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742499,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0e031020800000001000010a14008000100002800840a000100001800c004000100001041410c000100000841800c0001000008414304",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_beast_2011_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11639170,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000041402102080000000100001000800800010000200100040001000030018202000100000800400200010000080040080001001418a0c006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11630187,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1952,
        opponentMmrAfter: 1963,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2007,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:43.000Z",
  },
  {
    clientId: "1ec9eb6f-98a0-6a43-a60c-36e8525fc007",
    name: "RickAxie",
    rank: "Challenger",
    tier: 0,
    topRank: 62,
    vstar: 2049,
    avatar: "s3_top20k;0",
    battles: [
      {
        battleUuid: "3cf816c6-7997-4935-b41b-22e4c37785a6",
        gameTimestamp: "2023-05-29 12:21:32",
        startTimestamp: "2023-05-29 12:15:49",
        endTimestamp: "2023-05-29 12:21:32",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3cf816c6-7997-4935-b41b-22e4c37785a6&userId=1ec9eb6f-98a0-6a43-a60c-36e8525fc007",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5095233",
        oponentTeamId: "5108308",
        opponent: "1ed57738-fb5b-67da-895c-8d36800bd76c",
        playerTeam: [
          {
            gene: "0x80000000000010001c0c080820000000001001428a1400a0001001020810104000100001800c4040001001430804208000100002880c00a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752178,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160e0c20c00000001001428a1450a000100082021420a00010000180145080001001430204104000100101801000a0001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11748229,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000e020b0c208000000010014284144040001000428410504000100001881420c000100103020840c000100041041440c0001001010808506",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11699271,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2081,
        opponentMmrAfter: 2090,
        opponentSlpWon: 0,
        playerMmrBefore: 2058,
        playerMmrAfter: 2049,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "8cbf3630-8bf1-4248-be77-86bfb327916a",
        gameTimestamp: "2023-05-29 12:15:24",
        startTimestamp: "2023-05-29 12:09:59",
        endTimestamp: "2023-05-29 12:15:24",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8cbf3630-8bf1-4248-be77-86bfb327916a&userId=1ec9eb6f-98a0-6a43-a60c-36e8525fc007",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5095233",
        oponentTeamId: "5022359",
        opponent: "1ec9eb6f-7df0-6ed2-a60c-cc7b88a95375",
        playerTeam: [
          {
            gene: "0x80000000000010001c0c080820000000001001428a1400a0001001020810104000100001800c4040001001430804208000100002880c00a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752178,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160e0c20c00000001001428a1450a000100082021420a00010000180145080001001430204104000100101801000a0001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11748229,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000e020b0c208000000010014284144040001000428410504000100001881420c000100103020840c000100041041440c0001001010808506",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11699271,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080508208000000010014282042020001000428008104000100001880830c000100143000c10400010000282043040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11686853,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000080a090830c00000001001428a1450a000100042881010a000100001800c2080001001430a1850c000100002880c4060001001010410404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000380e040820800000001001428a1450a000100042860400400010000188044080001000428a18102000100041080c104000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_3011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717596,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2172,
        opponentMmrAfter: 2178,
        opponentSlpWon: 24,
        playerMmrBefore: 2064,
        playerMmrAfter: 2058,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "6210c5ef-2959-4a69-bdde-936f0e64ce81",
        gameTimestamp: "2023-05-29 12:09:21",
        startTimestamp: "2023-05-29 12:04:54",
        endTimestamp: "2023-05-29 12:09:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6210c5ef-2959-4a69-bdde-936f0e64ce81&userId=1ec9eb6f-98a0-6a43-a60c-36e8525fc007",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095233",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x80000000000010001c0c080820000000001001428a1400a0001001020810104000100001800c4040001001430804208000100002880c00a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752178,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160e0c20c00000001001428a1450a000100082021420a00010000180145080001001430204104000100101801000a0001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11748229,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000e020b0c208000000010014284144040001000428410504000100001881420c000100103020840c000100041041440c0001001010808506",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11699271,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008160208210000000010000100101040001001028a0400400010000304100020001000008004002000100000800c208000100001800c00a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11567779,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2064,
        opponentMmrAfter: 2054,
        opponentSlpWon: 0,
        playerMmrBefore: 2054,
        playerMmrAfter: 2064,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4452e689-720d-47be-bf7b-6f7844cabb18",
        gameTimestamp: "2023-05-29 12:04:27",
        startTimestamp: "2023-05-29 11:58:51",
        endTimestamp: "2023-05-29 12:04:27",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4452e689-720d-47be-bf7b-6f7844cabb18&userId=1ec9eb6f-98a0-6a43-a60c-36e8525fc007",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095233",
        oponentTeamId: "4998450",
        opponent: "1ed19f15-3cc6-65ce-b138-35f04e5ecadf",
        playerTeam: [
          {
            gene: "0x80000000000010001c0c080820000000001001428a1400a0001001020810104000100001800c4040001001430804208000100002880c00a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752178,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160e0c20c00000001001428a1450a000100082021420a00010000180145080001001430204104000100101801000a0001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11748229,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000e020b0c208000000010014284144040001000428410504000100001881420c000100103020840c000100041041440c0001001010808506",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11699271,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040020c000000010004100142020001000810014104000100001800c102000100042800c10a0001001420a10508000100040880830a",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11691934,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040b10410000000010000100080040001000028610308000100001800c0060001000428414304000100041041820c000100000840c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_beast_3013_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11718510,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c020b0840800000001000010008004000100002020840a000100003061000c000100000800440600010000088182020001000018a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11727730,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2025,
        opponentMmrAfter: 2016,
        opponentSlpWon: 0,
        playerMmrBefore: 2045,
        playerMmrAfter: 2054,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "c8a13df7-5e3c-429d-920c-32de0c32e3f9",
        gameTimestamp: "2023-05-29 11:58:27",
        startTimestamp: "2023-05-29 11:53:09",
        endTimestamp: "2023-05-29 11:58:27",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c8a13df7-5e3c-429d-920c-32de0c32e3f9&userId=1ec9eb6f-98a0-6a43-a60c-36e8525fc007",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095233",
        oponentTeamId: "4968813",
        opponent: "1ec9eb6f-9dca-6f95-a60c-a2b057b85e4f",
        playerTeam: [
          {
            gene: "0x80000000000010001c0c080820000000001001428a1400a0001001020810104000100001800c4040001001430804208000100002880c00a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11752178,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160e0c20c00000001001428a1450a000100082021420a00010000180145080001001430204104000100101801000a0001001010808404",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11748229,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000e020b0c208000000010014284144040001000428410504000100001881420c000100103020840c000100041041440c0001001010808506",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11699271,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000804090c208000000010004106042080001000808a14108000100102081030a000100042861810a0001000410208104000100101880c404",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11723960,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100000180e08208000000010004106144080001000808404202000100102081040a000100042880410a000100081840c1040001000008614002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11676725,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012090c21000000001000c0861430200010008086043040001001020a141080001000428214106000100041061030a000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748951,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1985,
        opponentMmrAfter: 1977,
        opponentSlpWon: 0,
        playerMmrBefore: 2037,
        playerMmrAfter: 2045,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:44.000Z",
  },
  {
    clientId: "1ec9eb7e-3dbf-62ec-a60c-70f5169917af",
    name: "Tincho",
    rank: "Challenger",
    tier: 0,
    topRank: 63,
    vstar: 2048,
    avatar: "2;0",
    battles: [
      {
        battleUuid: "d538bb98-e4f4-4954-b822-470fc5d2b50a",
        gameTimestamp: "2023-05-29 01:58:43",
        startTimestamp: "2023-05-29 01:51:56",
        endTimestamp: "2023-05-29 01:58:43",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d538bb98-e4f4-4954-b822-470fc5d2b50a&userId=1ec9eb7e-3dbf-62ec-a60c-70f5169917af",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093907",
        oponentTeamId: "5108322",
        opponent: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        playerTeam: [
          {
            gene: "0x200000000000010001c0e070c1100000000100041021410400010008084042020001001020810308000100042880840a000100041020c504000100101880c404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11675524,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038180c082080000000100041020850400010008082101080001001020810404000100103040c10c00010008186181020001000008004106",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691891,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010040404070c20c000000010004102082080001000808404008000100102081010c000100042821420800010004106084020001001018414004",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11737552,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010002c0e05082080000000100142841020a0001001028810104000100001800c006000100143021450c00010004104044060001001010808302",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11730485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00001020c0000000100142820430400010008208140020001000018604006000100103081850c00010000100085040001001010a08004",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11752113,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2004,
        opponentMmrAfter: 1996,
        opponentSlpWon: 0,
        playerMmrBefore: 2040,
        playerMmrAfter: 2048,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f4d5bf0b-3aa8-46f3-b569-82b313a615d3",
        gameTimestamp: "2023-05-29 01:50:11",
        startTimestamp: "2023-05-29 01:46:15",
        endTimestamp: "2023-05-29 01:50:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f4d5bf0b-3aa8-46f3-b569-82b313a615d3&userId=1ec9eb7e-3dbf-62ec-a60c-70f5169917af",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093907",
        oponentTeamId: "5105821",
        opponent: "1ec9eb6f-404d-69e1-a60c-6faac813d78e",
        playerTeam: [
          {
            gene: "0x200000000000010001c0e070c1100000000100041021410400010008084042020001001020810308000100042880840a000100041020c504000100101880c404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11675524,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038180c082080000000100041020850400010008082101080001001020810404000100103040c10c00010008186181020001000008004106",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691891,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010040404070c20c000000010004102082080001000808404008000100102081010c000100042821420800010004106084020001001018414004",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11737552,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0c040c20c000000010004082042040001000428204402000100001800c006000100042800c40c000100042021410a000100040800c102",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11660693,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c0a070820000000001000010008404000100002801400800010000182104040001001408414504000100081840820c0001000008004502",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1005",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11686976,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e0b10308000000010000100140040001000020010202000100003001820200010000080040020001000008404002000100141800420c",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11631467,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1980,
        opponentMmrAfter: 1972,
        opponentSlpWon: 0,
        playerMmrBefore: 2032,
        playerMmrAfter: 2040,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "da554e4d-2396-4742-9994-4a08fa185447",
        gameTimestamp: "2023-05-29 01:41:13",
        startTimestamp: "2023-05-29 01:34:55",
        endTimestamp: "2023-05-29 01:41:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=da554e4d-2396-4742-9994-4a08fa185447&userId=1ec9eb7e-3dbf-62ec-a60c-70f5169917af",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093907",
        oponentTeamId: "5085210",
        opponent: "1ec9eb7e-4166-65e3-a60c-9eec0c52bc68",
        playerTeam: [
          {
            gene: "0x200000000000010001c0e070c1100000000100041021410400010008084042020001001020810308000100042880840a000100041020c504000100101880c404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11675524,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038180c082080000000100041020850400010008082101080001001020810404000100103040c10c00010008186181020001000008004106",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691891,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010040404070c20c000000010004102082080001000808404008000100102081010c000100042821420800010004106084020001001018414004",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11737552,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c04031040800000001000c1060800400010000288105040001000c2020810600010000100044080001000008a103040001000c1021840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 5356781,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000381c07084100000000100001040400a000100002801400800010000180080060001000028008002000100003060810a0001000008004002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11531660,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e0708410000000010000100140040001000008004002000100001000830c00010000100080060001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_beast_3010_s4_nft",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3303749,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2066,
        opponentMmrAfter: 2055,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2032,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9a0ae30d-06a6-4e86-a630-255a65d4cc62",
        gameTimestamp: "2023-05-29 01:34:25",
        startTimestamp: "2023-05-29 01:27:06",
        endTimestamp: "2023-05-29 01:34:25",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9a0ae30d-06a6-4e86-a630-255a65d4cc62&userId=1ec9eb7e-3dbf-62ec-a60c-70f5169917af",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093907",
        oponentTeamId: "5107175",
        opponent: "1ecadec1-efd3-6bf7-907e-9b7fe4f8c0ac",
        playerTeam: [
          {
            gene: "0x200000000000010001c0e070c1100000000100041021410400010008084042020001001020810308000100042880840a000100041020c504000100101880c404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11675524,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038180c082080000000100041020850400010008082101080001001020810404000100103040c10c00010008186181020001000008004106",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691891,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010040404070c20c000000010004102082080001000808404008000100102081010c000100042821420800010004106084020001001018414004",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11737552,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c160704100000000010004102081020001000808004408000100102021430c000100042820810a000100081840c008000100101880c10c",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728029,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e082080000000100041081410800010008088082020001001020a10004000100103040c50c00010008188083020001000008004402",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11745367,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000001800840c00000001000c082084080001000808404208000100102081040800010004282141060001000410208206000100101880c408",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11748474,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2118,
        opponentMmrAfter: 2105,
        opponentSlpWon: 0,
        playerMmrBefore: 2008,
        playerMmrAfter: 2021,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bd43a84e-f2a1-4c36-a41d-e6cbe5dc7dfe",
        gameTimestamp: "2023-05-29 01:26:23",
        startTimestamp: "2023-05-29 01:20:45",
        endTimestamp: "2023-05-29 01:26:23",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bd43a84e-f2a1-4c36-a41d-e6cbe5dc7dfe&userId=1ec9eb7e-3dbf-62ec-a60c-70f5169917af",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5093907",
        oponentTeamId: "5018425",
        opponent: "1ed47ad1-e453-693b-8a28-108ddd08b24d",
        playerTeam: [
          {
            gene: "0x200000000000010001c0e070c1100000000100041021410400010008084042020001001020810308000100042880840a000100041020c504000100101880c404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11675524,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038180c082080000000100041020850400010008082101080001001020810404000100103040c10c00010008186181020001000008004106",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691891,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010040404070c20c000000010004102082080001000808404008000100102081010c000100042821420800010004106084020001001018414004",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11737552,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a0210308000000010014284084080001000c2881010a00010000186140060001001430a1800800010000282040020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11729639,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e071040c000000010000100084040001000008004508000100001000840800010000080041020001000008210508000100002800c00a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3012_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11474697,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0c040c20800000001000408a141040001000028404302000100001820420400010004308185080001001420a045080001000408204106",
            runes: ["rune_mech_2011_s4_nondec"],
            charms: {
              back: "ecard_reptile_2012_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11688223,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1949,
        opponentMmrAfter: 1961,
        opponentSlpWon: 22,
        playerMmrBefore: 2020,
        playerMmrAfter: 2008,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:45.000Z",
  },
  {
    clientId: "1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
    name: "CATALYST Axie Infinity",
    rank: "Challenger",
    tier: 0,
    topRank: 64,
    vstar: 2048,
    avatar: "easter_momo;0",
    battles: [
      {
        battleUuid: "bf515514-c3ff-44e1-89a0-ec5bbc78f00c",
        gameTimestamp: "2023-05-29 12:00:47",
        startTimestamp: "2023-05-29 11:54:46",
        endTimestamp: "2023-05-29 12:00:47",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bf515514-c3ff-44e1-89a0-ec5bbc78f00c&userId=1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5065158",
        oponentTeamId: "4849605",
        opponent: "1ed19410-20df-695f-9db2-530ad8281856",
        playerTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010000004040830c00000001001428a145040001000820410308000100001800c006000100041021400a000100101880c3040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11742111,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100c060c20800000001001428a142020001000428608102000100001800c006000100040821430c0001000410a084060001001010808408",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2020_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724374,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a05000080000000100142880840a00010010200044080001000018a04006000100103021040c000100101821830c0001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_puffy_1",
            },
            axie_id: 11703843,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1955,
        opponentMmrAfter: 1948,
        opponentSlpWon: 0,
        playerMmrBefore: 2041,
        playerMmrAfter: 2048,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "450a37a1-eadb-4231-80b2-c1e666c40923",
        gameTimestamp: "2023-05-29 11:53:54",
        startTimestamp: "2023-05-29 11:48:37",
        endTimestamp: "2023-05-29 11:53:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=450a37a1-eadb-4231-80b2-c1e666c40923&userId=1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5065158",
        oponentTeamId: "5097142",
        opponent: "1ec9eb6f-4702-677d-a60c-5b43771e8057",
        playerTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140a040830c00000001001428a1450a0001000428214208000100001800c104000100143081030c00010010182085060001001010a18508",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11738685,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003416050c40800000001001428a14004000100082000830a00010000184082040001001030a0810a000100143080c10c0001001010808404",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_reptile_3013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730364,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100e09082080000000100142861450a00010008202043020001000018618108000100103080c40800010000108042060001001010a14404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11731879,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1995,
        opponentMmrAfter: 1987,
        opponentSlpWon: 0,
        playerMmrBefore: 2033,
        playerMmrAfter: 2041,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "51937233-781d-499e-a497-dd762408ad21",
        gameTimestamp: "2023-05-29 11:48:09",
        startTimestamp: "2023-05-29 11:48:00",
        endTimestamp: "2023-05-29 11:48:09",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=51937233-781d-499e-a497-dd762408ad21&userId=1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5065158",
        oponentTeamId: "5017404",
        opponent: "1ec9eb7e-46e7-6363-a60c-be5ac05b26c4",
        playerTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000004050020800000001000410214204000100042820820a000100001820400200010004080140060001000008210008000100040820c204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11728801,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10000810080841000000001000010008004000100002801000800010000300080060001001018a140020001001018a1410c0001000008814306",
            runes: ["rune_beast_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_buba_1",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11536749,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e071040c0000000100001001400200010000200100080001000030018004000100000800450400010000080080020001000020010008",
            runes: ["rune_beast_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nondec",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11582896,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "06a0d345-b5f8-4e55-81a8-684e0a2a4270",
        gameTimestamp: "2023-05-29 10:20:08",
        startTimestamp: "2023-05-29 10:14:57",
        endTimestamp: "2023-05-29 10:20:08",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=06a0d345-b5f8-4e55-81a8-684e0a2a4270&userId=1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5065158",
        oponentTeamId: "5108308",
        opponent: "1ed57738-fb5b-67da-895c-8d36800bd76c",
        playerTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2075,
        opponentMmrAfter: 2064,
        opponentSlpWon: 0,
        playerMmrBefore: 2022,
        playerMmrAfter: 2033,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "57a08b3a-e800-48fb-83a4-320344b8143e",
        gameTimestamp: "2023-05-29 10:14:27",
        startTimestamp: "2023-05-29 10:10:19",
        endTimestamp: "2023-05-29 10:14:27",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=57a08b3a-e800-48fb-83a4-320344b8143e&userId=1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5065158",
        oponentTeamId: "5058491",
        opponent: "1ec9eb6f-a127-6c6a-a60c-9f2e686eef63",
        playerTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001808040c308000000010004086102020001000408204308000100001800c0060001001430a1850c000100002801400a0001000408214108",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11641557,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e10410000000010004208080040001000028014208000100001800c006000100101080800c00010008304183020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "",
            },
            axie_id: 11720382,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100600080508208000000010004082040040001001028814404000100041020800c0001000420a14108000100043021810c000100041820c106",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11703756,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1865,
        opponentMmrAfter: 1879,
        opponentSlpWon: 0,
        playerMmrBefore: 2036,
        playerMmrAfter: 2022,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:45.000Z",
  },
  {
    clientId: "1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
    name: "Zenik",
    rank: "Challenger",
    tier: 0,
    topRank: 65,
    vstar: 2048,
    avatar: "13;silver_s1",
    battles: [
      {
        battleUuid: "fa5de421-216e-40d0-9acb-029e9037999f",
        gameTimestamp: "2023-05-29 03:41:59",
        startTimestamp: "2023-05-29 03:36:50",
        endTimestamp: "2023-05-29 03:41:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fa5de421-216e-40d0-9acb-029e9037999f&userId=1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5051435",
        oponentTeamId: "5022978",
        opponent: "1ec9eb6f-5b78-6a8e-a60c-034ee9429269",
        playerTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404070c1080000000100142880850a0001000428010302000100001800c20a000100143001000a0001000028604406000100000800c20c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746100,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100038120e0830800000001001428a14208000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010a18404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "ecard_neutral_1001",
              mouth: "",
            },
            axie_id: 11751672,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140a050c2080000000100142801050a000100042821410a000100001800c00600010004284081060001000410a081040001001010a04302",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749905,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2112,
        opponentMmrAfter: 2100,
        opponentSlpWon: 0,
        playerMmrBefore: 2036,
        playerMmrAfter: 2048,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d8385c39-a08d-438a-a334-b7d5a8b597aa",
        gameTimestamp: "2023-05-29 03:15:50",
        startTimestamp: "2023-05-29 03:10:31",
        endTimestamp: "2023-05-29 03:15:50",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d8385c39-a08d-438a-a334-b7d5a8b597aa&userId=1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5051435",
        oponentTeamId: "5064203",
        opponent: "1ec9eb6f-5be8-681a-a60c-766f70a72927",
        playerTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002812020840c0000000100142880420800010004282144080001000018010006000100143081830800010010186143060001001010808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11734814,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100a0e0c20800000001001428a1450a000100042821410a000100001800c006000100143020420a000100002841420c000100101080440c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734822,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001012040020c00000001001428a14004000100082080820800010000184181060001001030214402000100101800400c000100101080830c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11727048,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1954,
        opponentMmrAfter: 1947,
        opponentSlpWon: 0,
        playerMmrBefore: 2029,
        playerMmrAfter: 2036,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7f925c20-0f8e-460a-9f8c-564ceafca891",
        gameTimestamp: "2023-05-29 03:09:51",
        startTimestamp: "2023-05-29 03:07:11",
        endTimestamp: "2023-05-29 03:09:51",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7f925c20-0f8e-460a-9f8c-564ceafca891&userId=1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5051435",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008160208210000000010000100101040001001028a0400400010000304100020001000008004002000100000800c208000100001800c00a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11567779,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2061,
        opponentMmrAfter: 2070,
        opponentSlpWon: 24,
        playerMmrBefore: 2038,
        playerMmrAfter: 2029,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "6be800e7-2298-48d5-a706-d148972e22c1",
        gameTimestamp: "2023-05-29 03:06:41",
        startTimestamp: "2023-05-29 03:02:50",
        endTimestamp: "2023-05-29 03:06:41",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6be800e7-2298-48d5-a706-d148972e22c1&userId=1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5051435",
        oponentTeamId: "5102440",
        opponent: "1ec9eb6f-53d1-6d8c-a60c-31212e9282bd",
        playerTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000180e070820800000001001410a144080001000428410408000100001800c50c000100143021440600010010184041040001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11733178,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000181806082080000000100142821020200010004286041040001000018a0830c0001001430a1850c000100002820420a000100101080c002",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11725634,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0a050001000000001001428614402000100102881440a0001000018210002000100081081840a000100101880c4060001001010808402",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721361,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2100,
        opponentMmrAfter: 2088,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2038,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "dd315514-e076-4ad4-a222-ae1316b0cf2c",
        gameTimestamp: "2023-05-29 03:02:24",
        startTimestamp: "2023-05-29 02:58:09",
        endTimestamp: "2023-05-29 03:02:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=dd315514-e076-4ad4-a222-ae1316b0cf2c&userId=1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5051435",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1978,
        opponentMmrAfter: 1970,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2026,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:46.000Z",
  },
  {
    clientId: "1ec9eb6f-4155-680b-a60c-c40e7aa5e393",
    name: "Akira78ve | TPA",
    rank: "Challenger",
    tier: 0,
    topRank: 66,
    vstar: 2048,
    avatar: "patrick23_hat;patrick23",
    battles: [
      {
        battleUuid: "2cabc422-17ad-49c2-80d6-c17164d9acf3",
        gameTimestamp: "2023-05-29 10:47:35",
        startTimestamp: "2023-05-29 10:44:38",
        endTimestamp: "2023-05-29 10:47:35",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2cabc422-17ad-49c2-80d6-c17164d9acf3&userId=1ec9eb6f-4155-680b-a60c-c40e7aa5e393",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043847",
        oponentTeamId: "5109348",
        opponent: "1ec9eb6f-6f07-6ecc-a60c-83310cf795dd",
        playerTeam: [
          {
            gene: "0x80000000000010000c04081020800000001000410208104000100080840420200010010208101020001000428408308000100081840c206000100101821040c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2018_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11724188,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c080c082080000000100041000450800010008084042020001001020810408000100103021440c000100081840c4020001000008004204",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729510,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c04070830c000000010004102081040001000808010202000100102081030c000100042821450200010004106084040001001018414004",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727965,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a000100042821010a000100001800c0060001001430804308000100101801400a0001001010a1820c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742557,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080a0030c0000000100142800850a0001000c08a1400400010000182100040001001430a1020a000100002800c20c0001001010408404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11749135,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000000820c00000001001428408402000100042881040a000100001800c0060001001030814202000100101880c20a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750814,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1989,
        opponentMmrAfter: 1981,
        opponentSlpWon: 0,
        playerMmrBefore: 2040,
        playerMmrAfter: 2048,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "36cd81c1-1bd5-4c86-84ec-d064714bc4e6",
        gameTimestamp: "2023-05-29 10:44:06",
        startTimestamp: "2023-05-29 10:37:36",
        endTimestamp: "2023-05-29 10:44:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=36cd81c1-1bd5-4c86-84ec-d064714bc4e6&userId=1ec9eb6f-4155-680b-a60c-c40e7aa5e393",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043847",
        oponentTeamId: "5060007",
        opponent: "1ec9eb6f-490a-620b-a60c-c736e162e257",
        playerTeam: [
          {
            gene: "0x80000000000010000c04081020800000001000410208104000100080840420200010010208101020001000428408308000100081840c206000100101821040c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2018_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11724188,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c080c082080000000100041000450800010008084042020001001020810408000100103021440c000100081840c4020001000008004204",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729510,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c04070830c000000010004102081040001000808010202000100102081030c000100042821450200010004106084040001001018414004",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727965,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001404030c208000000010014280140040001000428804508000100001800c006000100143020850c000100002880c006000100101080800a",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11740246,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000804031020c00000001001428a1420a000100042821410a0001000018a14108000100143001810a00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728342,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a0d081100000000100142880840a0001001028810202000100001800c304000100001000c0060001001018208406000100101080850c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11735909,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2030,
        opponentMmrAfter: 2021,
        opponentSlpWon: 0,
        playerMmrBefore: 2031,
        playerMmrAfter: 2040,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1176b3d6-a0be-4f1b-8e2e-3a891cb6659e",
        gameTimestamp: "2023-05-29 10:37:09",
        startTimestamp: "2023-05-29 10:29:49",
        endTimestamp: "2023-05-29 10:37:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1176b3d6-a0be-4f1b-8e2e-3a891cb6659e&userId=1ec9eb6f-4155-680b-a60c-c40e7aa5e393",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043847",
        oponentTeamId: "5105092",
        opponent: "1ecb6080-f8d7-638f-8ba8-beb9cd2501e7",
        playerTeam: [
          {
            gene: "0x80000000000010000c04081020800000001000410208104000100080840420200010010208101020001000428408308000100081840c206000100101821040c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2018_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11724188,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c080c082080000000100041000450800010008084042020001001020810408000100103021440c000100081840c4020001000008004204",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729510,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c04070830c000000010004102081040001000808010202000100102081030c000100042821450200010004106084040001001018414004",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727965,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c1a0708208000000010010104100040001000028410104000100001881840c0001001030008402000100103080c0020001000c10808306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_aquatic_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_plant_2011_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11681982,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c100208410000000010000100080040001000008004102000100001000c004000100000820c0020001000008004504000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11501859,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07002000000000100000800850400010000204044080001000018008004000100002801000200010000080080060001000c1000c10c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11553102,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2013,
        opponentMmrAfter: 2004,
        opponentSlpWon: 0,
        playerMmrBefore: 2022,
        playerMmrAfter: 2031,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "944e4362-4fef-498c-bb21-fdc76d84e652",
        gameTimestamp: "2023-05-29 10:29:08",
        startTimestamp: "2023-05-29 10:23:41",
        endTimestamp: "2023-05-29 10:29:08",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=944e4362-4fef-498c-bb21-fdc76d84e652&userId=1ec9eb6f-4155-680b-a60c-c40e7aa5e393",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5043847",
        oponentTeamId: "5010566",
        opponent: "1ed8854a-8d6c-6360-bb21-015eaf0dec6b",
        playerTeam: [
          {
            gene: "0x80000000000010000c04081020800000001000410208104000100080840420200010010208101020001000428408308000100081840c206000100101821040c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2018_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11724188,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c080c082080000000100041000450800010008084042020001001020810408000100103021440c000100081840c4020001000008004204",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729510,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c04070830c000000010004102081040001000808010202000100102081030c000100042821450200010004106084040001001018414004",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727965,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000140c050c20c00000001001428a1450a000100042881410a000100001800c0060001000428a1810c000100002820820a0001001010a18302",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11733244,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000341a040020800000001001428a1440a0001000810204102000100001880400a000100103020440c00010004108142080001001010418404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11707618,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140e050820800000001001428a14504000100082020850a000100001800c0060001001430204304000100101880c2040001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11717549,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1981,
        opponentMmrAfter: 1992,
        opponentSlpWon: 22,
        playerMmrBefore: 2033,
        playerMmrAfter: 2022,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "d792672b-3586-4da3-9695-2b214e611dd3",
        gameTimestamp: "2023-05-29 10:21:33",
        startTimestamp: "2023-05-29 10:15:14",
        endTimestamp: "2023-05-29 10:21:33",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d792672b-3586-4da3-9695-2b214e611dd3&userId=1ec9eb6f-4155-680b-a60c-c40e7aa5e393",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5043847",
        oponentTeamId: "5008594",
        opponent: "1ec9eb73-5e00-6a19-a60c-c4d78dc21e80",
        playerTeam: [
          {
            gene: "0x80000000000010000c04081020800000001000410208104000100080840420200010010208101020001000428408308000100081840c206000100101821040c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2018_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11724188,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c080c082080000000100041000450800010008084042020001001020810408000100103021440c000100081840c4020001000008004204",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11729510,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c04070830c000000010004102081040001000808010202000100102081030c000100042821450200010004106084040001001018414004",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727965,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080406083040000000100000880850400010004286044020001001018a100020001000010414004000100100881030c0001000c10018004",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 5191902,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000200e0708408000000010000100080040001000010a14004000100103081040c000100000800400a0001000008010002000100141801020a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11561675,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c0610408000000010004200102040001000028604404000100102860c5020001000c10808406000100001000c30c000100040840c102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 650146,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1945,
        opponentMmrAfter: 1938,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2033,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:46.000Z",
  },
  {
    clientId: "1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
    name: "Marino443|Elvis",
    rank: "Challenger",
    tier: 0,
    topRank: 67,
    vstar: 2046,
    avatar: "easter_eggs;silver_s3",
    battles: [
      {
        battleUuid: "3914f07a-6a6c-4a66-ac1e-dee5f684a092",
        gameTimestamp: "2023-05-29 11:32:34",
        startTimestamp: "2023-05-29 11:27:49",
        endTimestamp: "2023-05-29 11:32:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3914f07a-6a6c-4a66-ac1e-dee5f684a092&userId=1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088254",
        oponentTeamId: "5055615",
        opponent: "1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        playerTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2041,
        opponentMmrAfter: 2031,
        opponentSlpWon: 0,
        playerMmrBefore: 2036,
        playerMmrAfter: 2046,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "452cb6d1-21a1-4183-8c4c-b9e5d3baf83b",
        gameTimestamp: "2023-05-29 11:26:56",
        startTimestamp: "2023-05-29 11:23:20",
        endTimestamp: "2023-05-29 11:26:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=452cb6d1-21a1-4183-8c4c-b9e5d3baf83b&userId=1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088254",
        oponentTeamId: "5087063",
        opponent: "1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        playerTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2035,
        opponentMmrAfter: 2025,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2036,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f6ce87cb-e77a-48a1-9ca2-c3236e0054aa",
        gameTimestamp: "2023-05-29 11:20:42",
        startTimestamp: "2023-05-29 11:15:17",
        endTimestamp: "2023-05-29 11:20:42",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f6ce87cb-e77a-48a1-9ca2-c3236e0054aa&userId=1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088254",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2048,
        opponentMmrAfter: 2038,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2026,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d92b7cd3-8ef7-49e1-9ace-886632fdde70",
        gameTimestamp: "2023-05-29 11:13:42",
        startTimestamp: "2023-05-29 11:08:21",
        endTimestamp: "2023-05-29 11:13:42",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d92b7cd3-8ef7-49e1-9ace-886632fdde70&userId=1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088254",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1967,
        opponentMmrAfter: 1959,
        opponentSlpWon: 0,
        playerMmrBefore: 2008,
        playerMmrAfter: 2016,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d309802a-27b8-4982-8b38-2899b3b40619",
        gameTimestamp: "2023-05-29 11:05:47",
        startTimestamp: "2023-05-29 11:00:26",
        endTimestamp: "2023-05-29 11:05:47",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d309802a-27b8-4982-8b38-2899b3b40619&userId=1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088254",
        oponentTeamId: "5090043",
        opponent: "1ecef738-05e3-6815-9ea4-32ab1e23f072",
        playerTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000280a0d0820c0000000100142880850a0001000428214308000100001800c20c000100143061400c00010000282104060001001010018404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11740255,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808050c20800000001001428a1450a000100042821410a000100001800c0060001001430218502000100101880820a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11715417,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010160d1020800000001001428a1450a0001000820a044080001000018204208000100103020c50c000100101880c406000100101080840a",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11742218,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1973,
        opponentMmrAfter: 1964,
        opponentSlpWon: 0,
        playerMmrBefore: 1999,
        playerMmrAfter: 2008,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:47.000Z",
  },
  {
    clientId: "1ec9eb6f-65bb-653c-a60c-f32e3614ffc6",
    name: "Furyosinho meu amigo",
    rank: "Challenger",
    tier: 0,
    topRank: 68,
    vstar: 2045,
    avatar: "s3_top1k;silver_s3",
    battles: [
      {
        battleUuid: "40ca4f88-c2c0-4e07-858e-72e0d7dc8cd3",
        gameTimestamp: "2023-05-28 22:51:40",
        startTimestamp: "2023-05-28 22:48:19",
        endTimestamp: "2023-05-28 22:51:40",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=40ca4f88-c2c0-4e07-858e-72e0d7dc8cd3&userId=1ec9eb6f-65bb-653c-a60c-f32e3614ffc6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5045072",
        oponentTeamId: "5105604",
        opponent: "1ec9eb6f-4d30-6fc8-a60c-3395702a4116",
        playerTeam: [
          {
            gene: "0x80000000000010001c08040c30c00000001001428a1450a0001000428410102000100001800c50c0001001430a1050c000100041020850c0001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726587,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100008060d0c30c00000001001428a1450a0001000020214004000100001800c002000100143041010a0001001018a104060001001010808404",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11743193,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010040400090820c00000001001428a140080001001020804208000100001801030c000100003081410400010000082145040001001010808302",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11675758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2800000000000100020180704004000000010014082100040001000820a10004000100001820420c0001001410414004000100141020810a0001000008a08002",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11632113,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120908208000000010008204104080001000828414508000100081840c2060001000830210002000100083041820c0001000810818108",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745435,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000381c080830c00000001000c080103020001000c10204304000100001800c1020001000c0881030a0001000c286143060001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11732552,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1975,
        opponentMmrAfter: 1967,
        opponentSlpWon: 0,
        playerMmrBefore: 2037,
        playerMmrAfter: 2045,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ca939631-42ea-48b5-a19f-704f203e709c",
        gameTimestamp: "2023-05-28 22:47:24",
        startTimestamp: "2023-05-28 22:42:59",
        endTimestamp: "2023-05-28 22:47:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ca939631-42ea-48b5-a19f-704f203e709c&userId=1ec9eb6f-65bb-653c-a60c-f32e3614ffc6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5045072",
        oponentTeamId: "5083225",
        opponent: "1ec9eb6f-820c-6d62-a60c-9a1f7853cb83",
        playerTeam: [
          {
            gene: "0x80000000000010001c08040c30c00000001001428a1450a0001000428410102000100001800c50c0001001430a1050c000100041020850c0001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726587,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100008060d0c30c00000001001428a1450a0001000020214004000100001800c002000100143041010a0001001018a104060001001010808404",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11743193,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010040400090820c00000001001428a140080001001020804208000100001801030c000100003081410400010000082145040001001010808302",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11675758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a020801000000001001428a1450a000100042881010a00010000180080060001001430a1850c000100101880c0080001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000804040430c00000001001428a1450a0001000428408204000100001800c1080001001430a1830200010000282144060001001010808006",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11753041,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101a050830c00000001001428a144040001000020a08004000100001800c0060001001008204202000100000880c00a000100101020840a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11695659,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1963,
        opponentMmrAfter: 1955,
        opponentSlpWon: 0,
        playerMmrBefore: 2029,
        playerMmrAfter: 2037,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1fd4b271-7b80-43b9-9f81-b1d9687ec54b",
        gameTimestamp: "2023-05-28 22:27:10",
        startTimestamp: "2023-05-28 22:23:50",
        endTimestamp: "2023-05-28 22:27:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1fd4b271-7b80-43b9-9f81-b1d9687ec54b&userId=1ec9eb6f-65bb-653c-a60c-f32e3614ffc6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5045072",
        oponentTeamId: "5084757",
        opponent: "1ec9eb73-7fc6-6c3d-a60c-f4e57fa58910",
        playerTeam: [
          {
            gene: "0x80000000000010001c08040c30c00000001001428a1450a0001000428410102000100001800c50c0001001430a1050c000100041020850c0001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726587,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100008060d0c30c00000001001428a1450a0001000020214004000100001800c002000100143041010a0001001018a104060001001010808404",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11743193,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010040400090820c00000001001428a140080001001020804208000100001801030c000100003081410400010000082145040001001010808302",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11675758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001005010c30800000001001428a141080001000428a0410a000100001800c0060001000410a1850c000100002801430a0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740192,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100a070820800000001001428a14108000100082080840a000100001800c206000100143081840c000100101880c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713068,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002400000820c00000001001428a1400a000100102081440a0001000018a102080001001030818006000100001080c406000100101080810a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11708043,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1952,
        opponentMmrAfter: 1944,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2029,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3d7df15f-c515-41f2-82d4-301f3d68555c",
        gameTimestamp: "2023-05-28 22:23:19",
        startTimestamp: "2023-05-28 22:18:29",
        endTimestamp: "2023-05-28 22:23:19",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3d7df15f-c515-41f2-82d4-301f3d68555c&userId=1ec9eb6f-65bb-653c-a60c-f32e3614ffc6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5045072",
        oponentTeamId: "5091322",
        opponent: "1ec9eb6f-4341-6627-a60c-dc2c3fb974f0",
        playerTeam: [
          {
            gene: "0x80000000000010001c08040c30c00000001001428a1450a0001000428410102000100001800c50c0001001430a1050c000100041020850c0001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726587,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100008060d0c30c00000001001428a1450a0001000020214004000100001800c002000100143041010a0001001018a104060001001010808404",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11743193,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010040400090820c00000001001428a140080001001020804208000100001801030c000100003081410400010000082145040001001010808302",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11675758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000001a060830800000001001428a14508000100042801440a00010000182103080001001430a1440c000100041021050c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11741001,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a000100042841040a000100001800c50c0001001430a185080001000028208508000100101080800c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11741784,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000000c20c0000000100142860850a000100102861040a0001000018a1000c000100100860430a00010010186104060001001010410306",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750820,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1977,
        opponentMmrAfter: 1988,
        opponentSlpWon: 22,
        playerMmrBefore: 2032,
        playerMmrAfter: 2021,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "d9a47b43-7afc-4a6a-9158-ebc8ea60e865",
        gameTimestamp: "2023-05-28 22:17:37",
        startTimestamp: "2023-05-28 22:14:16",
        endTimestamp: "2023-05-28 22:17:37",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d9a47b43-7afc-4a6a-9158-ebc8ea60e865&userId=1ec9eb6f-65bb-653c-a60c-f32e3614ffc6",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5045072",
        oponentTeamId: "5104659",
        opponent: "1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        playerTeam: [
          {
            gene: "0x80000000000010001c08040c30c00000001001428a1450a0001000428410102000100001800c50c0001001430a1050c000100041020850c0001001010808008",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726587,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100008060d0c30c00000001001428a1450a0001000020214004000100001800c002000100143041010a0001001018a104060001001010808404",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nondec",
            },
            axie_id: 11743193,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010040400090820c00000001001428a140080001001020804208000100001801030c000100003081410400010000082145040001001010808302",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11675758,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x90000000000001000200e0708108000000010010108084040001000028a1000a000100001881430c00010010280040020001001028208002000100000800450c",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11623986,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000180e1040c0000000100000821440a0001000028604202000100001861830c0001000c2861420400010010180083060001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11746158,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2135,
        opponentMmrAfter: 2142,
        opponentSlpWon: 0,
        playerMmrBefore: 2039,
        playerMmrAfter: 2032,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:48.000Z",
  },
  {
    clientId: "1ec9eb6f-73a2-670f-a60c-35dfb5053630",
    name: "DC: Meat#4834",
    rank: "Challenger",
    tier: 0,
    topRank: 69,
    vstar: 2044,
    avatar: "buba_joker;0",
    battles: [
      {
        battleUuid: "8959549b-c9be-4a17-a229-0d6a8e324525",
        gameTimestamp: "2023-05-29 02:14:07",
        startTimestamp: "2023-05-29 02:08:44",
        endTimestamp: "2023-05-29 02:14:07",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8959549b-c9be-4a17-a229-0d6a8e324525&userId=1ec9eb6f-73a2-670f-a60c-35dfb5053630",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5105160",
        oponentTeamId: "4735960",
        opponent: "1ec9eb7e-5c0f-6f17-a60c-3fda9b444ef7",
        playerTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a0001000428204108000100001800c0060001001430a18308000100101860400a000100101080820c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11750697,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c061020c0000000100142800450a0001000428014108000100001800c30c0001001430a1850c0001000028a08306000100101080840a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11750792,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a000020c0000000100142820850200010008200042080001000018814204000100103060440c00010010180044060001001010610404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11734476,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101a040820c00000001000410204102000100042861010a000100001800c3060001000428214308000100042021010a0001000408204102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742502,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002404070840800000001000010a100040001000028410002000100001800c10a00010000100080080001000010418006000100000820c002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11643475,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040b1040800000001000010014004000100002001000800010000308100060001000008004306000100000800400c0001000020004002",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nondec",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11525786,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2049,
        opponentMmrAfter: 2039,
        opponentSlpWon: 0,
        playerMmrBefore: 2034,
        playerMmrAfter: 2044,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8bea8027-19fa-4a12-93ca-9a715fe515ce",
        gameTimestamp: "2023-05-29 02:08:16",
        startTimestamp: "2023-05-29 02:02:03",
        endTimestamp: "2023-05-29 02:08:16",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8bea8027-19fa-4a12-93ca-9a715fe515ce&userId=1ec9eb6f-73a2-670f-a60c-35dfb5053630",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105160",
        oponentTeamId: "5043888",
        opponent: "1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        playerTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a0001000428204108000100001800c0060001001430a18308000100101860400a000100101080820c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11750697,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c061020c0000000100142800450a0001000428014108000100001800c30c0001001430a1850c0001000028a08306000100101080840a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11750792,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a000020c0000000100142820850200010008200042080001000018814204000100103060440c00010010180044060001001010610404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11734476,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2073,
        opponentMmrAfter: 2082,
        opponentSlpWon: 24,
        playerMmrBefore: 2043,
        playerMmrAfter: 2034,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "ef9a8b1f-0d61-40be-ba9c-63cf47bd13ad",
        gameTimestamp: "2023-05-29 02:01:37",
        startTimestamp: "2023-05-29 01:57:17",
        endTimestamp: "2023-05-29 02:01:37",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ef9a8b1f-0d61-40be-ba9c-63cf47bd13ad&userId=1ec9eb6f-73a2-670f-a60c-35dfb5053630",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105160",
        oponentTeamId: "5026641",
        opponent: "1ec9eb73-b491-656d-a60c-2ebb6144527e",
        playerTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a0001000428204108000100001800c0060001001430a18308000100101860400a000100101080820c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11750697,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c061020c0000000100142800450a0001000428014108000100001800c30c0001001430a1850c0001000028a08306000100101080840a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11750792,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a000020c0000000100142820850200010008200042080001000018814204000100103060440c00010010180044060001001010610404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11734476,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412040820800000001001428a1450a0001000428204102000100001800c006000100041041010a00010004084184040001001010a10508",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e000c2100000000100142801450a0001000820410402000100001800c00a0001001030a1440c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11749565,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002404050c20400000001001428408504000100082020840800010000184180060001000828a18402000100101041800a0001001010814008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11728770,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1984,
        opponentMmrAfter: 1995,
        opponentSlpWon: 22,
        playerMmrBefore: 2054,
        playerMmrAfter: 2043,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "3c421f1c-a1d7-4fb7-b4d3-70ebdc92dafb",
        gameTimestamp: "2023-05-29 01:56:29",
        startTimestamp: "2023-05-29 01:52:56",
        endTimestamp: "2023-05-29 01:56:29",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3c421f1c-a1d7-4fb7-b4d3-70ebdc92dafb&userId=1ec9eb6f-73a2-670f-a60c-35dfb5053630",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5105160",
        oponentTeamId: "5108163",
        opponent: "1ec9eb73-5b41-62e0-a60c-299d63c28684",
        playerTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a0001000428204108000100001800c0060001001430a18308000100101860400a000100101080820c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11750697,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c061020c0000000100142800450a0001000428014108000100001800c30c0001001430a1850c0001000028a08306000100101080840a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11750792,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a000020c0000000100142820850200010008200042080001000018814204000100103060440c00010010180044060001001010610404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11734476,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c0f801020000000001001428a142020001000c08008102000100001800c00600010004100081040001000c0880c1040001001010808204",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_plant_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11703143,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140f80082080000000100142880850a000100042820430a000100001800c006000100041020800c0001000c0820830a0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11710287,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010003401010800c0000000100142841020400010010204144020001000018a041020001001020614004000100002080c5040001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11668140,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2048,
        opponentMmrAfter: 2038,
        opponentSlpWon: 0,
        playerMmrBefore: 2044,
        playerMmrAfter: 2054,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "7111c4d4-5182-466a-a8fb-c0e7345534ce",
        gameTimestamp: "2023-05-29 01:51:54",
        startTimestamp: "2023-05-29 01:47:45",
        endTimestamp: "2023-05-29 01:51:54",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7111c4d4-5182-466a-a8fb-c0e7345534ce&userId=1ec9eb6f-73a2-670f-a60c-35dfb5053630",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105160",
        oponentTeamId: "5096276",
        opponent: "1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        playerTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a0001000428204108000100001800c0060001001430a18308000100101860400a000100101080820c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11750697,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c061020c0000000100142800450a0001000428014108000100001800c30c0001001430a1850c0001000028a08306000100101080840a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11750792,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a000020c0000000100142820850200010008200042080001000018814204000100103060440c00010010180044060001001010610404",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nondec",
            },
            axie_id: 11734476,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2037,
        opponentMmrAfter: 2047,
        opponentSlpWon: 24,
        playerMmrBefore: 2054,
        playerMmrAfter: 2044,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:49.000Z",
  },
  {
    clientId: "1ec9eb7e-6be9-6408-a60c-e4d0238f9669",
    name: "STAY HYDRATED| MizTaH Tv",
    rank: "Challenger",
    tier: 0,
    topRank: 70,
    vstar: 2043,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "77112d58-9637-43af-ae43-4a46fcca028d",
        gameTimestamp: "2023-05-29 12:20:21",
        startTimestamp: "2023-05-29 12:16:47",
        endTimestamp: "2023-05-29 12:20:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=77112d58-9637-43af-ae43-4a46fcca028d&userId=1ec9eb7e-6be9-6408-a60c-e4d0238f9669",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100427",
        oponentTeamId: "5072683",
        opponent: "1ec9eb7e-36e9-6da2-a60c-ed0a11570bd1",
        playerTeam: [
          {
            gene: "0x800000000000100008080d0c30c00000001001428a1450a0001000428214208000100001800c208000100143081050c000100002880c00c0001001010218404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11744373,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002602060800800000001001428a1450a00010008208102040001000018204006000100103081840c00010004106181060001001010404404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742138,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008000831000000001001428a1450a0001001020208004000100001800430c000100100881840a000100101880c40a0001001010808206",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11746203,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c040410308000000010014280105080001000428a102020001000018810004000100102080440c000100041081810a0001001010808004",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740863,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408090820800000001001428a1450a000100082081440a0001000018808006000100143081840c000100101880c4060001000008808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713079,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c12090800800000001001428a1040a0001000820810308000100001840440c000100101080c40c000100002860c40a0001001010218406",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11722283,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1983,
        opponentMmrAfter: 1975,
        opponentSlpWon: 0,
        playerMmrBefore: 2035,
        playerMmrAfter: 2043,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "56bd36d1-4300-4fe8-95fa-478ad4e0cf71",
        gameTimestamp: "2023-05-29 12:16:01",
        startTimestamp: "2023-05-29 12:11:30",
        endTimestamp: "2023-05-29 12:16:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=56bd36d1-4300-4fe8-95fa-478ad4e0cf71&userId=1ec9eb7e-6be9-6408-a60c-e4d0238f9669",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100427",
        oponentTeamId: "5091036",
        opponent: "1ec9eb73-5d3a-6a8a-a60c-6d41702e9a80",
        playerTeam: [
          {
            gene: "0x800000000000100008080d0c30c00000001001428a1450a0001000428214208000100001800c208000100143081050c000100002880c00c0001001010218404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11744373,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002602060800800000001001428a1450a00010008208102040001000018204006000100103081840c00010004106181060001001010404404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742138,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008000831000000001001428a1450a0001001020208004000100001800430c000100100881840a000100101880c40a0001001010808206",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11746203,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c16040820800000001001428a1450a000100042860850a0001000018214006000100143021440c000100041000c1040001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11738617,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240a05083100000000100142820850a000100042881010a000100001800c006000100143020800600010000180141040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721724,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000141a040820c0000000100142861050a00010014280082080001000018018006000100103081830400010010180184060001001010204404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11740692,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1999,
        opponentMmrAfter: 1990,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2035,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ed7c537f-84ae-425f-bc22-4dc6ab6b2f2b",
        gameTimestamp: "2023-05-29 12:10:58",
        startTimestamp: "2023-05-29 12:04:27",
        endTimestamp: "2023-05-29 12:10:58",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ed7c537f-84ae-425f-bc22-4dc6ab6b2f2b&userId=1ec9eb7e-6be9-6408-a60c-e4d0238f9669",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100427",
        oponentTeamId: "5107887",
        opponent: "1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        playerTeam: [
          {
            gene: "0x800000000000100008080d0c30c00000001001428a1450a0001000428214208000100001800c208000100143081050c000100002880c00c0001001010218404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11744373,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002602060800800000001001428a1450a00010008208102040001000018204006000100103081840c00010004106181060001001010404404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742138,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008000831000000001001428a1450a0001001020208004000100001800430c000100100881840a000100101880c40a0001001010808206",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11746203,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c070c20c000000010014282045080001000428214102000100001800c1080001000428010104000100041080c2040001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11730561,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2039,
        opponentMmrAfter: 2029,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2026,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "89b86636-5936-42c5-b8ad-3f7cb5348a67",
        gameTimestamp: "2023-05-29 12:03:42",
        startTimestamp: "2023-05-29 11:57:03",
        endTimestamp: "2023-05-29 12:03:42",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=89b86636-5936-42c5-b8ad-3f7cb5348a67&userId=1ec9eb7e-6be9-6408-a60c-e4d0238f9669",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100427",
        oponentTeamId: "5092268",
        opponent: "1ec9eb6f-5f56-65a9-a60c-754d940f41d2",
        playerTeam: [
          {
            gene: "0x800000000000100008080d0c30c00000001001428a1450a0001000428214208000100001800c208000100143081050c000100002880c00c0001001010218404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11744373,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002602060800800000001001428a1450a00010008208102040001000018204006000100103081840c00010004106181060001001010404404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742138,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008000831000000001001428a1450a0001001020208004000100001800430c000100100881840a000100101880c40a0001001010808206",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11746203,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001816060c3080000000100040821410200010004080141020001000018218006000100043041410c0001000028408108000100040820420a",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11616573,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000080441000000001001410a043080001000408a08504000100042841400c0001001420a1050800010014204101060001000410a04206",
            runes: ["rune_neutral_2011_s4"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_3011_s4_nft",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "ecard_neutral_3013_s4_nondec",
            },
            axie_id: 11615013,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000200a0e0c30400000001000c08a042020001000c28214004000100001800c30a0001000c18804506000100040840c504000100041081040a",
            runes: ["rune_reptile_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_2017_s4",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11709466,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1974,
        opponentMmrAfter: 1965,
        opponentSlpWon: 0,
        playerMmrBefore: 2007,
        playerMmrAfter: 2016,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5019407b-ff65-4d87-a96a-179a73c781dd",
        gameTimestamp: "2023-05-29 11:56:26",
        startTimestamp: "2023-05-29 11:51:51",
        endTimestamp: "2023-05-29 11:56:26",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5019407b-ff65-4d87-a96a-179a73c781dd&userId=1ec9eb7e-6be9-6408-a60c-e4d0238f9669",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100427",
        oponentTeamId: "5103218",
        opponent: "1ec9eb73-5d7e-67ea-a60c-8947fb7b9def",
        playerTeam: [
          {
            gene: "0x800000000000100008080d0c30c00000001001428a1450a0001000428214208000100001800c208000100143081050c000100002880c00c0001001010218404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11744373,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002602060800800000001001428a1450a00010008208102040001000018204006000100103081840c00010004106181060001001010404404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742138,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008000831000000001001428a1450a0001001020208004000100001800430c000100100881840a000100101880c40a0001001010808206",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11746203,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000018000840800000001000410a1030200010008080145020001001020210208000100042880410a000100041080c1040001001018a0c506",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11718882,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030180c08408000000010004108100020001000808404202000100102081020c000100042881850200010008188084040001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11715042,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2800000000000100024120e0420800000001000c08a102020001000808404202000100102020440c00010004282141040001000410208306000100101880c308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11724388,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1986,
        opponentMmrAfter: 1977,
        opponentSlpWon: 0,
        playerMmrBefore: 1998,
        playerMmrAfter: 2007,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:49.000Z",
  },
  {
    clientId: "1ec9eb6f-5e4b-64b4-a60c-031de970f144",
    name: "Cram14",
    rank: "Challenger",
    tier: 0,
    topRank: 71,
    vstar: 2042,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "7bf4cfb2-f4d7-40dd-ba6c-a4cfc0fb3d45",
        gameTimestamp: "2023-05-29 07:54:56",
        startTimestamp: "2023-05-29 07:48:58",
        endTimestamp: "2023-05-29 07:54:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7bf4cfb2-f4d7-40dd-ba6c-a4cfc0fb3d45&userId=1ec9eb6f-5e4b-64b4-a60c-031de970f144",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5098573",
        oponentTeamId: "5087514",
        opponent: "1ec9eb6f-5723-6b13-a60c-06d08618c771",
        playerTeam: [
          {
            gene: "0x8800000000000100014080e0c2100000000100142841050a000100042881010a000100001800c006000100143081830800010010186143060001001010804404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11744645,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000810050840800000001001428a1450a00010004284141020001000018a140060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nondec",
            },
            axie_id: 11743659,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000c0e050830c00000001001428208404000100102881440a00010000184184080001001030a0c00400010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_puffy_1",
            },
            axie_id: 11751286,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100030080e0820800000001000c2860450a0001000c106040040001000c3061830a0001000c086044060001000c18a0c3060001000c10608304",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11734219,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000140e020430800000001000c1021020400010014286045040001000c086043020001001410a145040001001410a081040001000008218308",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_reptile_3013_s4_nft",
            },
            axie_id: 11743219,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002414091021000000001000820410208000100082880420a000100081840c206000100083041820c000100083041430c0001000810804204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722983,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2103,
        opponentMmrAfter: 2091,
        opponentSlpWon: 0,
        playerMmrBefore: 2030,
        playerMmrAfter: 2042,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "70fdfde7-79aa-4f9d-8492-6dd96a43ae3c",
        gameTimestamp: "2023-05-29 06:57:24",
        startTimestamp: "2023-05-29 06:50:25",
        endTimestamp: "2023-05-29 06:57:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=70fdfde7-79aa-4f9d-8492-6dd96a43ae3c&userId=1ec9eb6f-5e4b-64b4-a60c-031de970f144",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5098573",
        oponentTeamId: "5057206",
        opponent: "1ec9eb6f-74d8-6ddd-a60c-06a2e615aec8",
        playerTeam: [
          {
            gene: "0x8800000000000100014080e0c2100000000100142841050a000100042881010a000100001800c006000100143081830800010010186143060001001010804404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11744645,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000810050840800000001001428a1450a00010004284141020001000018a140060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nondec",
            },
            axie_id: 11743659,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000c0e050830c00000001001428208404000100102881440a00010000184184080001001030a0c00400010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_puffy_1",
            },
            axie_id: 11751286,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101c040810c00000001001428a1450a000100042860850a00010000186100060001000410204104000100002820850a0001001010a10404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731295,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c08040c2080000000100142841050a000100041081000a000100001861430c000100100820410a00010000084144060001001010a14402",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11729750,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100805083000000000100142861050a000100042821410a000100001800c0060001001430a1820600010004308082040001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11734587,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1972,
        opponentMmrAfter: 1964,
        opponentSlpWon: 0,
        playerMmrBefore: 2022,
        playerMmrAfter: 2030,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "23217ccd-8657-48b5-ba7f-dab794a897a3",
        gameTimestamp: "2023-05-29 06:49:47",
        startTimestamp: "2023-05-29 06:48:47",
        endTimestamp: "2023-05-29 06:49:47",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=23217ccd-8657-48b5-ba7f-dab794a897a3&userId=1ec9eb6f-5e4b-64b4-a60c-031de970f144",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5098573",
        oponentTeamId: "5097856",
        opponent: "1ec9eb6f-4004-6f6c-a60c-b650107f8686",
        playerTeam: [
          {
            gene: "0x8800000000000100014080e0c2100000000100142841050a000100042881010a000100001800c006000100143081830800010010186143060001001010804404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11744645,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000810050840800000001001428a1450a00010004284141020001000018a140060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nondec",
            },
            axie_id: 11743659,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000c0e050830c00000001001428208404000100102881440a00010000184184080001001030a0c00400010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_puffy_1",
            },
            axie_id: 11751286,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080a0e0030c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11721534,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a0b0820c0000000100142861050a0001000428204208000100001800c006000100143060c30200010004100041020001001010808208",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11733329,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001202090820800000001001428014108000100082080800a000100001840c1020001001030818402000100101880c0020001001010808404",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11720344,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2052,
        opponentMmrAfter: 2041,
        opponentSlpWon: 0,
        playerMmrBefore: 2011,
        playerMmrAfter: 2022,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b76f9e23-25ff-4ecb-aa5e-0b60d40483f4",
        gameTimestamp: "2023-05-29 06:24:58",
        startTimestamp: "2023-05-29 06:19:24",
        endTimestamp: "2023-05-29 06:24:58",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b76f9e23-25ff-4ecb-aa5e-0b60d40483f4&userId=1ec9eb6f-5e4b-64b4-a60c-031de970f144",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5098573",
        oponentTeamId: "4849605",
        opponent: "1ed19410-20df-695f-9db2-530ad8281856",
        playerTeam: [
          {
            gene: "0x8800000000000100014080e0c2100000000100142841050a000100042881010a000100001800c006000100143081830800010010186143060001001010804404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11744645,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000810050840800000001001428a1450a00010004284141020001000018a140060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2012_s4_nondec",
            },
            axie_id: 11743659,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000c0e050830c00000001001428208404000100102881440a00010000184184080001001030a0c00400010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_puffy_1",
            },
            axie_id: 11751286,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010000004040830c00000001001428a145040001000820410308000100001800c006000100041021400a000100101880c3040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2020_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11742111,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100c060c20800000001001428a142020001000428608102000100001800c006000100040821430c0001000410a084060001001010808408",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2020_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724374,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a05000080000000100142880840a00010010200044080001000018a04006000100103021040c000100101821830c0001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11703843,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1950,
        opponentMmrAfter: 1942,
        opponentSlpWon: 0,
        playerMmrBefore: 2003,
        playerMmrAfter: 2011,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9a5c2b26-3145-4337-a82f-e7bdd8fef2ce",
        gameTimestamp: "2023-05-29 06:16:34",
        startTimestamp: "2023-05-29 06:11:25",
        endTimestamp: "2023-05-29 06:16:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9a5c2b26-3145-4337-a82f-e7bdd8fef2ce&userId=1ec9eb6f-5e4b-64b4-a60c-031de970f144",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5098573",
        oponentTeamId: "5064447",
        opponent: "1ec9eb6f-89e9-6513-a60c-0f705d43419f",
        playerTeam: [
          {
            gene: "0x8800000000000100014080e0c2100000000100142841050a000100042881010a000100001800c006000100143081830800010010186143060001001010804404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11744645,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000810050840800000001001428a1450a00010004284141020001000018a140060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_2012_s4_nondec",
            },
            axie_id: 11743659,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000c0e050830c00000001001428208404000100102881440a00010000184184080001001030a0c00400010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_puffy_1",
            },
            axie_id: 11751286,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412050820800000001000410a1450a00010004282144040001000018004006000100143001050c000100042880c1060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11717633,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c1203084080000000100142880840400010004202101020001000018a0c204000100041001810a000100041080c40c0001001010808108",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_2020_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11671822,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080a070c3080000000100142821450a0001001020810002000100001800c102000100043081840c000100101821840c000100101021000a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11732567,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1962,
        opponentMmrAfter: 1953,
        opponentSlpWon: 0,
        playerMmrBefore: 1994,
        playerMmrAfter: 2003,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:50.000Z",
  },
  {
    clientId: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
    name: "Kama | sushiclub",
    rank: "Challenger",
    tier: 0,
    topRank: 72,
    vstar: 2041,
    avatar: "sleepy_beast;fluffy_hooves",
    battles: [
      {
        battleUuid: "25d55502-b1b4-4658-9df8-b2d2ab2b339f",
        gameTimestamp: "2023-05-29 13:02:31",
        startTimestamp: "2023-05-29 12:59:13",
        endTimestamp: "2023-05-29 13:02:31",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=25d55502-b1b4-4658-9df8-b2d2ab2b339f&userId=1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110202",
        oponentTeamId: "5101048",
        opponent: "1eca7faf-813a-6bd2-9fe9-581dcef10f70",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11732056,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c060830c000000010014288102080001000428214408000100001800c006000100100881840a0001001018404406000100101080c20c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11739403,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001410021020800000001000c200080040001000028004002000100001880c5020001000010418004000100000840c3060001000008004204",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11518730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008040b0801000000001000010a140040001000020004008000100003001800c00010000080040020001000008004002000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11591470,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000180e061031000000001000c086043040001000c088083020001000c2061830a0001000c2860c4080001000c3020430c0001000c10610102",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 416844,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2017,
        opponentMmrAfter: 2008,
        opponentSlpWon: 0,
        playerMmrBefore: 2032,
        playerMmrAfter: 2041,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "78b389cb-b464-4d0d-804d-bf488e6daeb5",
        gameTimestamp: "2023-05-29 12:27:24",
        startTimestamp: "2023-05-29 12:22:21",
        endTimestamp: "2023-05-29 12:27:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=78b389cb-b464-4d0d-804d-bf488e6daeb5&userId=1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110202",
        oponentTeamId: "5096814",
        opponent: "1ec9eb6f-5ef8-66fe-a60c-74ff4c567e21",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11732056,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c060830c000000010014288102080001000428214408000100001800c006000100100881840a0001001018404406000100101080c20c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11739403,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c1209082080000000100000800800400010000280144080001000c180182080001000c2821820600010004302140060001000008818002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_plant_3012_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553675,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100a060820c000000010000102141040001000428a0800a000100001800c00c0001001010414102000100101840c5040001000408004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11712189,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x100004160b1020c00000001000010008004000100102861420a0001000c0801800c000100000800400200010000080040040001001418a0c506",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11734834,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1960,
        opponentMmrAfter: 1952,
        opponentSlpWon: 0,
        playerMmrBefore: 2024,
        playerMmrAfter: 2032,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a0861319-5012-49af-a49e-ca964be5a6bc",
        gameTimestamp: "2023-05-29 12:09:01",
        startTimestamp: "2023-05-29 12:04:12",
        endTimestamp: "2023-05-29 12:09:01",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a0861319-5012-49af-a49e-ca964be5a6bc&userId=1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110202",
        oponentTeamId: "5109692",
        opponent: "1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11732056,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c060830c000000010014288102080001000428214408000100001800c006000100100881840a0001001018404406000100101080c20c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11739403,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2017,
        opponentMmrAfter: 2027,
        opponentSlpWon: 24,
        playerMmrBefore: 2034,
        playerMmrAfter: 2024,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "415e1f12-6abb-4fde-b885-5255c48cf86d",
        gameTimestamp: "2023-05-29 10:19:13",
        startTimestamp: "2023-05-29 10:18:55",
        endTimestamp: "2023-05-29 10:19:13",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=415e1f12-6abb-4fde-b885-5255c48cf86d&userId=1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110705",
        oponentTeamId: "5110433",
        opponent: "1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        playerTeam: [
          {
            gene: "0x80000000000010001408060830800000001001428a1450a0001000428814504000100001800c006000100143021450c0001000410208104000100101080830a",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11747167,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000000c0d0c20000000001001428a1450a0001001028814204000100001800c402000100100881840c000100101880c2040001001010404404",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11747496,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2045,
        opponentMmrAfter: 2054,
        opponentSlpWon: 0,
        playerMmrBefore: 2043,
        playerMmrAfter: 2034,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "af06364e-be57-446f-9078-b2ac779f0c71",
        gameTimestamp: "2023-05-29 10:01:57",
        startTimestamp: "2023-05-29 09:55:13",
        endTimestamp: "2023-05-29 10:01:57",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=af06364e-be57-446f-9078-b2ac779f0c71&userId=1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110202",
        oponentTeamId: "5108308",
        opponent: "1ed57738-fb5b-67da-895c-8d36800bd76c",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11732056,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c060830c000000010014288102080001000428214408000100001800c006000100100881840a0001001018404406000100101080c20c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11739403,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2070,
        opponentMmrAfter: 2079,
        opponentSlpWon: 0,
        playerMmrBefore: 2052,
        playerMmrAfter: 2043,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:51.000Z",
  },
  {
    clientId: "1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
    name: "Maramao | ITA",
    rank: "Challenger",
    tier: 0,
    topRank: 73,
    vstar: 2041,
    avatar: "s3_top20k;silver_s3",
    battles: [
      {
        battleUuid: "8c1ca343-38df-4486-a7cd-78f4d30ec11f",
        gameTimestamp: "2023-05-29 11:36:32",
        startTimestamp: "2023-05-29 11:33:18",
        endTimestamp: "2023-05-29 11:36:32",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8c1ca343-38df-4486-a7cd-78f4d30ec11f&userId=1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5055615",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2059,
        opponentMmrAfter: 2049,
        opponentSlpWon: 0,
        playerMmrBefore: 2031,
        playerMmrAfter: 2041,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3914f07a-6a6c-4a66-ac1e-dee5f684a092",
        gameTimestamp: "2023-05-29 11:32:34",
        startTimestamp: "2023-05-29 11:27:49",
        endTimestamp: "2023-05-29 11:32:34",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3914f07a-6a6c-4a66-ac1e-dee5f684a092&userId=1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5055615",
        oponentTeamId: "5088254",
        opponent: "1ec9eb7e-68a5-67ca-a60c-364efb4ab04f",
        playerTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100000040e082080000000100041020810400010008084041080001001020810008000100042881840c000100041021820c0001001018610504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11719506,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003812040821000000001000410208202000100080800450a00010010208104060001001030214302000100081820c4080001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11716446,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000012020830c000000010004102083020001000808a14202000100102081020c000100042820c10a000100041020840c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11740964,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2036,
        opponentMmrAfter: 2046,
        opponentSlpWon: 24,
        playerMmrBefore: 2041,
        playerMmrAfter: 2031,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "74ae2a85-1820-4e04-8a27-8f0dc20c7c2d",
        gameTimestamp: "2023-05-29 11:27:03",
        startTimestamp: "2023-05-29 11:23:22",
        endTimestamp: "2023-05-29 11:27:03",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=74ae2a85-1820-4e04-8a27-8f0dc20c7c2d&userId=1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5055615",
        oponentTeamId: "5088678",
        opponent: "1ec9eb6f-5039-687d-a60c-89a79f5936a1",
        playerTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x90000000000001000301c060030c00000001001410a0830400010008206101020001000c3061440a000100142040810a0001000c300184040001001028a0c008",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2020_s4_nondec",
              horn: "ecard_neutral_3013_s4_nft",
              tail: "",
              mouth: "",
            },
            axie_id: 11750853,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002008080c31000000001001410a10108000100040821020a00010000182183020001001428a083080001000c3061800a0001001408604502",
            runes: ["rune_dawn_3010_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11689265,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000010080c41000000001001410a041040001000410604308000100042861800800010014202185080001000c3061830c0001000408208506",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2020_s4_nft",
              horn: "ecard_reptile_3011_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11724469,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1995,
        opponentMmrAfter: 1987,
        opponentSlpWon: 0,
        playerMmrBefore: 2033,
        playerMmrAfter: 2041,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "92349e5c-a0d4-4879-8155-8ab8019286f5",
        gameTimestamp: "2023-05-29 11:22:55",
        startTimestamp: "2023-05-29 11:16:36",
        endTimestamp: "2023-05-29 11:22:55",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=92349e5c-a0d4-4879-8155-8ab8019286f5&userId=1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5055615",
        oponentTeamId: "5111599",
        opponent: "1ec9eb7e-5f3b-689e-a60c-ee73626c2596",
        playerTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c08040820800000001001428a145020001000010810208000100001861820c000100143081050c00010000280142060001001010618404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11746426,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010040404080820800000001001428a1450a000100042821420a000100001800c0060001001430a1850c000100002880c3060001001010808208",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_3013_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743444,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c02082080000000100142841010a000100001021410a000100001800c004000100100881850800010004106104060001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_2011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11748040,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1995,
        opponentMmrAfter: 2006,
        opponentSlpWon: 24,
        playerMmrBefore: 2044,
        playerMmrAfter: 2033,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "8d0f3385-89e2-4a5b-9cce-0ab92de19f56",
        gameTimestamp: "2023-05-29 11:16:07",
        startTimestamp: "2023-05-29 11:13:35",
        endTimestamp: "2023-05-29 11:16:07",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8d0f3385-89e2-4a5b-9cce-0ab92de19f56&userId=1ec9eb7a-fd18-6361-a60c-0fa014ae8238",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5055615",
        oponentTeamId: "5090650",
        opponent: "1ec9eb77-511b-64e6-a60c-2bc1f8ec5d6f",
        playerTeam: [
          {
            gene: "0x8000000000001000340804082080000000100041021000a0001000428610102000100001860c404000100042821410a000100041021010a0001000408814108",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11751422,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080407102100000000100001021400a000100002801030800010000180081020001000c2801800200010000106140040001000008210002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11553896,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c020710210000000010000100080040001000020608002000100003080800c000100000800400200010000080040020001001418004308",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11723557,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140c050820800000001000c286083020001000c106083020001000c088043060001000c086144020001000c302043080001000c1061000a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11603356,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100000038008308000000010010102144040001000c082140020001000c0840810c0001000c08604406000100041820c304000100000820c106",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "",
              mouth: "",
            },
            axie_id: 11741192,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c0a021020800000001000c282085080001000c10a083040001000c0860430a0001000c0841020800010004184104040001001030a0c008",
            runes: ["rune_beast_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2012_s4",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "ecard_plant_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "",
            },
            axie_id: 11753281,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2090,
        opponentMmrAfter: 2098,
        opponentSlpWon: 0,
        playerMmrBefore: 2052,
        playerMmrAfter: 2044,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:51.000Z",
  },
  {
    clientId: "1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
    name: "SAM | Kurayami Tribe",
    rank: "Challenger",
    tier: 0,
    topRank: 74,
    vstar: 2040,
    avatar: "patrick23_hat;0",
    battles: [
      {
        battleUuid: "7e464a67-7303-4ae7-ba88-0c67d77379c3",
        gameTimestamp: "2023-05-29 10:29:41",
        startTimestamp: "2023-05-29 10:24:38",
        endTimestamp: "2023-05-29 10:29:41",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7e464a67-7303-4ae7-ba88-0c67d77379c3&userId=1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5094798",
        oponentTeamId: "5111351",
        opponent: "1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        playerTeam: [
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11720304,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11736392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "fd3910d9-6ace-4078-bbc4-a7dfdc7a2906",
        gameTimestamp: "2023-05-29 10:24:07",
        startTimestamp: "2023-05-29 10:22:46",
        endTimestamp: "2023-05-29 10:24:07",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fd3910d9-6ace-4078-bbc4-a7dfdc7a2906&userId=1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5094798",
        oponentTeamId: "5111351",
        opponent: "1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        playerTeam: [
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11720304,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11736392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ff878a3b-f230-49e9-b39b-09754a0faee1",
        gameTimestamp: "2023-05-29 10:21:45",
        startTimestamp: "2023-05-29 10:17:16",
        endTimestamp: "2023-05-29 10:21:45",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ff878a3b-f230-49e9-b39b-09754a0faee1&userId=1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5094798",
        oponentTeamId: "5111351",
        opponent: "1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        playerTeam: [
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11720304,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11736392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ca05a082-707c-4318-816d-d2964e95598b",
        gameTimestamp: "2023-05-29 10:10:28",
        startTimestamp: "2023-05-29 10:05:27",
        endTimestamp: "2023-05-29 10:10:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ca05a082-707c-4318-816d-d2964e95598b&userId=1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5094798",
        oponentTeamId: "5049492",
        opponent: "1edb3382-ec4e-6d32-9b2f-cfec81e89567",
        playerTeam: [
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736392,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720304,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000180a051021000000001001428a1020a000100040801400a00010000182085060001001430a0410c000100002860440a0001001010808202",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11697020,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001806000820c00000001001428a1420a0001000820814408000100001800c1020001001030818104000100101800440c0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11727029,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000240a0b0820c00000001001428a1450a00010004282141020001000018804002000100042801410a000100041040c10a000100101000c20c",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11724409,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1994,
        opponentMmrAfter: 1986,
        opponentSlpWon: 0,
        playerMmrBefore: 2032,
        playerMmrAfter: 2040,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "2d195391-efce-4d11-96b8-8f25b2ac5b03",
        gameTimestamp: "2023-05-29 10:04:28",
        startTimestamp: "2023-05-29 10:00:55",
        endTimestamp: "2023-05-29 10:04:28",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2d195391-efce-4d11-96b8-8f25b2ac5b03&userId=1ec9eb6f-40b5-6128-a60c-a8646fa9e4d4",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5094798",
        oponentTeamId: "5110433",
        opponent: "1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        playerTeam: [
          {
            gene: "0x80000000000010001008040420c00000001001428a1450a000100042841410a000100001800c1080001001430018302000100101880c4060001001010808102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736392,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000808080c30800000001001428a1450a000100042821410a000100001800c108000100143081050200010000284184040001001010410204",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "",
            },
            axie_id: 11721546,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000811010820c0000000100142841450a0001000820810408000100001800c10a000100103081840c0001001018a1040c0001001010a0c308",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720304,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2025,
        opponentMmrAfter: 2035,
        opponentSlpWon: 0,
        playerMmrBefore: 2042,
        playerMmrAfter: 2032,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:52.000Z",
  },
  {
    clientId: "1ec9eb6f-415b-6a9d-a60c-1be81a5a1e1d",
    name: "Jaybemusic | SURA",
    rank: "Challenger",
    tier: 0,
    topRank: 75,
    vstar: 2040,
    avatar: "triumphant_wing;shark",
    battles: [
      {
        battleUuid: "1a2354b3-04e1-4456-9525-0c697dac0554",
        gameTimestamp: "2023-05-28 19:34:57",
        startTimestamp: "2023-05-28 19:31:50",
        endTimestamp: "2023-05-28 19:34:57",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1a2354b3-04e1-4456-9525-0c697dac0554&userId=1ec9eb6f-415b-6a9d-a60c-1be81a5a1e1d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5004943",
        oponentTeamId: "5039497",
        opponent: "1ec9eb6f-60cb-64f8-a60c-b5ddd061366c",
        playerTeam: [
          {
            gene: "0x8000000000001000100a040c2080000000100041021410200010004282141040001000408418108000100042821810a000100101821010a0001000408408106",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_bug_2013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11729871,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000080e050c30c000000010008102041020001000c0801420a000100001800c50200010000300181020001000c182101060001000008804002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11676097,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000240e0410208000000010008204082080001000828a0420a000100081840c008000100083041820a000100083021820c0001000810818208",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11686993,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000812040c20800000001000c106042020001000c086043020001000c20008308000100043021810c0001000c306181060001000c10618306",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2013_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_plant_2013_s4_nft",
            },
            axie_id: 9579270,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000100e0908208000000010008206083020001000828414402000100081840c40a000100083041820c00010008304082080001000810408108",
            runes: ["rune_neutral_3010_s4"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_bird_2012_s4_nondec",
              eyes: "ecard_bird_2013_s4_nondec",
              horn: "ecard_bird_2013_s4_nft",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_bird_2012_s4_nondec",
            },
            axie_id: 11682804,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x100008120908208000000010008284142020001000028404104000100043021810c00010008284145020001000830014002000100040881820a",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_bug_2013_s4_nft",
              eyes: "ecard_neutral_3013_s4",
              horn: "ecard_neutral_2012_s4",
              tail: "ecard_neutral_2013_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11676067,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 0,
        opponentMmrAfter: 0,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f87f6edb-3216-4adc-9777-9b776647bd7a",
        gameTimestamp: "2023-05-28 19:30:13",
        startTimestamp: "2023-05-28 19:26:40",
        endTimestamp: "2023-05-28 19:30:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f87f6edb-3216-4adc-9777-9b776647bd7a&userId=1ec9eb6f-415b-6a9d-a60c-1be81a5a1e1d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5028104",
        oponentTeamId: "5109021",
        opponent: "1ed264ea-e4ff-6278-aad0-7c8421181f38",
        playerTeam: [
          {
            gene: "0x8000000000001000140a050820000000001000410210504000100042801420800010004082180060001000408218306000100041000c104000100040820c102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11683261,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001e02020820c000000010000100140040001000028404402000100001800c40c0001000c2861410a00010000080040020001000008808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11557578,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07000080000000100001000800400010000200080080001000030a0c30a000100000861800200010000080080020001001418004502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11546595,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000c0c0010210000000010000100081020001000428208004000100001821040c00010014102085080001000c3080830c0001000008204006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11729648,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010002412090820800000001000820408308000100082841400a000100081840c2060001000830a0810600010008304183080001000810818204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nondec",
              ears: "ecard_bird_3011_s4_nft",
              eyes: "ecard_bird_2012_s4_nondec",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3011_s4_nft",
            },
            axie_id: 11729969,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100034040610308000000010008088044020001000010a0830400010000186082020001000c0860830600010008108043020001000c10004506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 13174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2056,
        opponentMmrAfter: 2046,
        opponentSlpWon: 0,
        playerMmrBefore: 2030,
        playerMmrAfter: 2040,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "922a6767-021a-4d44-b190-5cc076b32b02",
        gameTimestamp: "2023-05-28 19:18:36",
        startTimestamp: "2023-05-28 19:15:07",
        endTimestamp: "2023-05-28 19:18:36",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=922a6767-021a-4d44-b190-5cc076b32b02&userId=1ec9eb6f-415b-6a9d-a60c-1be81a5a1e1d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5028104",
        oponentTeamId: "5022235",
        opponent: "1ecc3d8f-d79c-6c85-bf23-93ca967a1ff6",
        playerTeam: [
          {
            gene: "0x8000000000001000140a050820000000001000410210504000100042801420800010004082180060001000408218306000100041000c104000100040820c102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11683261,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001e02020820c000000010000100140040001000028404402000100001800c40c0001000c2861410a00010000080040020001000008808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11557578,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07000080000000100001000800400010000200080080001000030a0c30a000100000861800200010000080080020001001418004502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11546595,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c1a050821000000001001428a14102000100042820410a000100001800c1080001001430a1850200010000286044060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11722072,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016040c30c00000001001428a14102000100042820450a000100001800c0060001001430a1850c00010004102040040001001010210404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11726525,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140e0b0020000000001001428a1450a0001001028410208000100001800c20c0001001030818204000100101820430c0001001010808404",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721699,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2102,
        opponentMmrAfter: 2090,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2030,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "832842c0-b942-4f96-8288-8d3ac0f79859",
        gameTimestamp: "2023-05-28 19:04:12",
        startTimestamp: "2023-05-28 19:00:24",
        endTimestamp: "2023-05-28 19:04:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=832842c0-b942-4f96-8288-8d3ac0f79859&userId=1ec9eb6f-415b-6a9d-a60c-1be81a5a1e1d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5028104",
        oponentTeamId: "5072486",
        opponent: "1ec9eb6f-990e-63d3-a60c-cd99ea2ceea2",
        playerTeam: [
          {
            gene: "0x8000000000001000140a050820000000001000410210504000100042801420800010004082180060001000408218306000100041000c104000100040820c102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11683261,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001e02020820c000000010000100140040001000028404402000100001800c40c0001000c2861410a00010000080040020001000008808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11557578,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07000080000000100001000800400010000200080080001000030a0c30a000100000861800200010000080080020001001418004502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11546595,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003804030830c00000001001428a14108000100042821450a000100001800c006000100143041410c0001001010a184060001001010808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11721695,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000000e000c30800000001001428a1450a0001001028010204000100001800830c000100102881840a00010010188144060001001010808208",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11729331,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100a09002080000000100142840840a0001000820604108000100001800c00600010010308184080001001018204104000100101080810a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11724677,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2004,
        opponentMmrAfter: 1995,
        opponentSlpWon: 0,
        playerMmrBefore: 2009,
        playerMmrAfter: 2018,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "e7ba3149-7f11-4dfa-b6ba-12692ed19a8e",
        gameTimestamp: "2023-05-28 18:59:54",
        startTimestamp: "2023-05-28 18:56:29",
        endTimestamp: "2023-05-28 18:59:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=e7ba3149-7f11-4dfa-b6ba-12692ed19a8e&userId=1ec9eb6f-415b-6a9d-a60c-1be81a5a1e1d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5028104",
        oponentTeamId: "5104659",
        opponent: "1ec9eb6f-5254-6cc7-a60c-0d9f0cb43f21",
        playerTeam: [
          {
            gene: "0x8000000000001000140a050820000000001000410210504000100042801420800010004082180060001000408218306000100041000c104000100040820c102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11683261,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001e02020820c000000010000100140040001000028404402000100001800c40c0001000c2861410a00010000080040020001000008808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11557578,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07000080000000100001000800400010000200080080001000030a0c30a000100000861800200010000080080020001001418004502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11546595,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100000180e1040c0000000100000821440a0001000028604202000100001861830c0001000c2861420400010010180083060001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_neutral_1001",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11746158,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000200e0708108000000010010108084040001000028a1000a000100001881430c00010010280040020001001028208002000100000800450c",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11623986,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04020041000000001000010008004000100100860440a000100003001820c0001000008010002000100003020c5080001000018014004",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11749230,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2012,
        opponentMmrAfter: 2002,
        opponentSlpWon: 0,
        playerMmrBefore: 1999,
        playerMmrAfter: 2009,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:53.000Z",
  },
  {
    clientId: "1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
    name: "JesDavG | SFG",
    rank: "Challenger",
    tier: 0,
    topRank: 76,
    vstar: 2040,
    avatar: "patrick23_beer;shark",
    battles: [
      {
        battleUuid: "16e987c6-dae0-401f-8601-4010406c58c9",
        gameTimestamp: "2023-05-29 13:08:47",
        startTimestamp: "2023-05-29 13:04:56",
        endTimestamp: "2023-05-29 13:08:47",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=16e987c6-dae0-401f-8601-4010406c58c9&userId=1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105119",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000812081020800000001000010a143020001001028014002000100003001800c000100000800450a000100000800400c000100002000c502",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_buba_1",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11550174,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2080,
        opponentMmrAfter: 2069,
        opponentSlpWon: 0,
        playerMmrBefore: 2029,
        playerMmrAfter: 2040,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b8567243-c855-4b89-8584-0d16c2e64b6b",
        gameTimestamp: "2023-05-29 13:04:30",
        startTimestamp: "2023-05-29 13:01:55",
        endTimestamp: "2023-05-29 13:04:30",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b8567243-c855-4b89-8584-0d16c2e64b6b&userId=1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105119",
        oponentTeamId: "5106459",
        opponent: "1ec9eb6f-50c7-6087-a60c-d7ccebe6ca69",
        playerTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003800000830800000001000410a042020001000808404404000100102081030a000100042821450c0001000818a0420a000100101880c406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11743703,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e084080000000100041020850a000100080840440400010010208100040001001030a1810a000100081840c2060001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1002",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1005",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11721923,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010060001800440800000001000c08808302000100080840440200010010204084080001000428a0c10400010004102081040001001018a18406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11723663,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1956,
        opponentMmrAfter: 1968,
        opponentSlpWon: 0,
        playerMmrBefore: 2041,
        playerMmrAfter: 2029,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "5fc96bf8-449e-483e-810f-e67c0c2f5cb2",
        gameTimestamp: "2023-05-29 13:01:05",
        startTimestamp: "2023-05-29 12:57:48",
        endTimestamp: "2023-05-29 13:01:05",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5fc96bf8-449e-483e-810f-e67c0c2f5cb2&userId=1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105119",
        oponentTeamId: "4782584",
        opponent: "1ec9eb7e-5786-6057-a60c-87d41d1814d8",
        playerTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000000100010160e0020800000001001428a0850a000100082081450a0001000018618006000100103021450200010010182082040001001010408404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11713787,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100018080e082000000000100142820850a000100042841040a000100001800c006000100143021410a000100041080c1040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11725195,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008001020800000001001428a1440200010010208144080001000018204502000100100820840a00010010188141060001001010808002",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11677998,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1986,
        opponentMmrAfter: 1997,
        opponentSlpWon: 22,
        playerMmrBefore: 2052,
        playerMmrAfter: 2041,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "3ca82374-9d5e-4c48-9753-fede9f24152c",
        gameTimestamp: "2023-05-29 12:53:54",
        startTimestamp: "2023-05-29 12:51:45",
        endTimestamp: "2023-05-29 12:53:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3ca82374-9d5e-4c48-9753-fede9f24152c&userId=1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105119",
        oponentTeamId: "5104175",
        opponent: "1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        playerTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000200a070840800000001000008004402000100002020800a000100001800c0060001000c2000420200010008082081060001000c1060c50a",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11741156,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2039,
        opponentMmrAfter: 2030,
        opponentSlpWon: 0,
        playerMmrBefore: 2043,
        playerMmrAfter: 2052,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "899196ad-ab87-4751-b016-a996f1481772",
        gameTimestamp: "2023-05-29 12:50:57",
        startTimestamp: "2023-05-29 12:44:50",
        endTimestamp: "2023-05-29 12:50:57",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=899196ad-ab87-4751-b016-a996f1481772&userId=1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5105119",
        oponentTeamId: "5085010",
        opponent: "1ec9eb7e-5f3d-612b-a60c-1a220f26f83a",
        playerTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000000000c20c0000000100041020810a00010008080105040001001020810408000100042821410a000100081840c2060001001018418406",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_bug_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11745295,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010040c1021000000001000410208404000100080840410800010010208104080001000428804408000100081840c0060001000008004002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11721752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001004070c30c0000000100041001010400010008084042020001001020a1040800010004282143040001000410208104000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2011_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11743799,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1863,
        opponentMmrAfter: 1858,
        opponentSlpWon: 0,
        playerMmrBefore: 2038,
        playerMmrAfter: 2043,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:54.000Z",
  },
  {
    clientId: "1eda41f5-3ea8-69a0-9cde-113ece392b5a",
    name: "Tygar | Huru",
    rank: "Challenger",
    tier: 0,
    topRank: 77,
    vstar: 2040,
    avatar: "patrick23_clover;gold_s3",
    battles: [
      {
        battleUuid: "cf8c7a3f-d42b-4d38-b1ff-2d4089f9c309",
        gameTimestamp: "2023-05-29 07:30:16",
        startTimestamp: "2023-05-29 07:25:51",
        endTimestamp: "2023-05-29 07:30:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cf8c7a3f-d42b-4d38-b1ff-2d4089f9c309&userId=1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5097364",
        oponentTeamId: "5106459",
        opponent: "1ec9eb6f-50c7-6087-a60c-d7ccebe6ca69",
        playerTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003800000830800000001000410a042020001000808404404000100102081030a000100042821450c0001000818a0420a000100101880c406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11743703,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100038000e084080000000100041020850a000100080840440400010010208100040001001030a1810a000100081840c2060001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_1002",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_1005",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11721923,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010060001800440800000001000c08808302000100080840440200010010204084080001000428a0c10400010004102081040001001018a18406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11723663,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1971,
        opponentMmrAfter: 1963,
        opponentSlpWon: 0,
        playerMmrBefore: 2032,
        playerMmrAfter: 2040,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "441b9d4a-7f8d-4770-9942-8242586b49f7",
        gameTimestamp: "2023-05-29 07:25:00",
        startTimestamp: "2023-05-29 07:22:32",
        endTimestamp: "2023-05-29 07:25:00",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=441b9d4a-7f8d-4770-9942-8242586b49f7&userId=1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5097364",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2064,
        opponentMmrAfter: 2053,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2032,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ebbef174-cebc-4dda-adf7-a8bad2b62280",
        gameTimestamp: "2023-05-29 07:22:03",
        startTimestamp: "2023-05-29 07:15:10",
        endTimestamp: "2023-05-29 07:22:03",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ebbef174-cebc-4dda-adf7-a8bad2b62280&userId=1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5097364",
        oponentTeamId: "5092268",
        opponent: "1ec9eb6f-5f56-65a9-a60c-754d940f41d2",
        playerTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001816060c3080000000100040821410200010004080141020001000018218006000100043041410c0001000028408108000100040820420a",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11616573,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000080441000000001001410a043080001000408a08504000100042841400c0001001420a1050800010014204101060001000410a04206",
            runes: ["rune_neutral_2011_s4"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_1001",
              horn: "ecard_reptile_3011_s4_nft",
              tail: "ecard_neutral_2020_s4_nondec",
              mouth: "ecard_neutral_3013_s4_nondec",
            },
            axie_id: 11615013,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000200a0e0c30400000001000c08a042020001000c28214004000100001800c30a0001000c18804506000100040840c504000100041081040a",
            runes: ["rune_reptile_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_2017_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11709466,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1983,
        opponentMmrAfter: 1974,
        opponentSlpWon: 0,
        playerMmrBefore: 2012,
        playerMmrAfter: 2021,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "97bd9d19-e594-4605-b936-5bcb34130b6c",
        gameTimestamp: "2023-05-29 07:14:46",
        startTimestamp: "2023-05-29 07:09:22",
        endTimestamp: "2023-05-29 07:14:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=97bd9d19-e594-4605-b936-5bcb34130b6c&userId=1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5097364",
        oponentTeamId: "5044806",
        opponent: "1ecda672-7ddd-6991-8179-49518582467f",
        playerTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000808030820800000001001428a1420a000100042800810a000100001800c0060001001430a14104000100101880c20a0001001010408404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11728340,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a0e0820800000001001428a1450a0001000428204204000100001800c0060001001430a18102000100002800810c0001001010210006",
            runes: ["rune_neutral_3010_s4"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11736369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050840000000001001428a1450a0001001028808008000100001800c006000100100881850c000100101880c0080001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11745817,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2063,
        opponentMmrAfter: 2052,
        opponentSlpWon: 0,
        playerMmrBefore: 2001,
        playerMmrAfter: 2012,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "26bfa4f9-856f-40d1-ab13-f29c602f4fa7",
        gameTimestamp: "2023-05-29 07:06:56",
        startTimestamp: "2023-05-29 07:01:31",
        endTimestamp: "2023-05-29 07:06:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=26bfa4f9-856f-40d1-ab13-f29c602f4fa7&userId=1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5097364",
        oponentTeamId: "5109348",
        opponent: "1ec9eb6f-6f07-6ecc-a60c-83310cf795dd",
        playerTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000141c091020800000001001428a1420a000100042821010a000100001800c0060001001430804308000100101801400a0001001010a1820c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742557,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080a0030c0000000100142800850a0001000c08a1400400010000182100040001001430a1020a000100002800c20c0001001010408404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nondec",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11749135,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000000820c00000001001428408402000100042881040a000100001800c0060001001030814202000100101880c20a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750814,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1977,
        opponentMmrAfter: 1968,
        opponentSlpWon: 0,
        playerMmrBefore: 1992,
        playerMmrAfter: 2001,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:54.000Z",
  },
  {
    clientId: "1ec9eb6f-9deb-66ac-a60c-50640f758c06",
    name: "Naelith | Huru",
    rank: "Challenger",
    tier: 0,
    topRank: 78,
    vstar: 2039,
    avatar: "easter_momo;silver_s3",
    battles: [
      {
        battleUuid: "fd4ba9ea-566c-42e7-8baf-6b9e9726eeec",
        gameTimestamp: "2023-05-29 01:56:45",
        startTimestamp: "2023-05-29 01:53:17",
        endTimestamp: "2023-05-29 01:56:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fd4ba9ea-566c-42e7-8baf-6b9e9726eeec&userId=1ec9eb6f-9deb-66ac-a60c-50640f758c06",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093548",
        oponentTeamId: "5026641",
        opponent: "1ec9eb73-b491-656d-a60c-2ebb6144527e",
        playerTeam: [
          {
            gene: "0x800000000000010003800050c3080000000100142840450a00010004282141080001000018614408000100143001410400010000280141040001001010808404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740233,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c2080000000100142880450a000100042880840a000100001800c0060001001430414306000100101080c30a0001001010204102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11728439,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010080710110000000010014280142020001000008214502000100001800c00600010010082040040001000008418208000100000821800c",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11732965,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412040820800000001001428a1450a0001000428204102000100001800c006000100041041010a00010004084184040001001010a10508",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11713021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e000c2100000000100142801450a0001000820410402000100001800c00a0001001030a1440c000100101880c4060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11749565,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002404050c20400000001001428408504000100082020840800010000184180060001000828a18402000100101041800a0001001010814008",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11728770,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1992,
        opponentMmrAfter: 1984,
        opponentSlpWon: 0,
        playerMmrBefore: 2031,
        playerMmrAfter: 2039,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1227805d-9882-4de4-99b6-ebb1770f8ee4",
        gameTimestamp: "2023-05-29 01:52:28",
        startTimestamp: "2023-05-29 01:48:44",
        endTimestamp: "2023-05-29 01:52:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1227805d-9882-4de4-99b6-ebb1770f8ee4&userId=1ec9eb6f-9deb-66ac-a60c-50640f758c06",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093548",
        oponentTeamId: "5110433",
        opponent: "1ed4f18c-ecc6-6bc7-b208-f0bd6fa711a4",
        playerTeam: [
          {
            gene: "0x800000000000010003800050c3080000000100142840450a00010004282141080001000018614408000100143001410400010000280141040001001010808404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740233,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c2080000000100142880450a000100042880840a000100001800c0060001001430414306000100101080c30a0001001010204102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11728439,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010080710110000000010014280142020001000008214502000100001800c00600010010082040040001000008418208000100000821800c",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11732965,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001008040810800000001001428a1450a00010004282142040001000018a10306000100143000850c00010000282142020001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11753063,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040800010000000010014282081020001001408410008000100001881000c0001001008610402000100041021410a000100101081450c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11692256,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000280f010c20800000001001428a142080001000428608504000100001820430c00010014288041080001000410804406000100101080c002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11747081,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2030,
        opponentMmrAfter: 2020,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2031,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9b03316d-52e6-4441-a2e8-e53a0cf8215a",
        gameTimestamp: "2023-05-29 00:45:56",
        startTimestamp: "2023-05-29 00:40:34",
        endTimestamp: "2023-05-29 00:45:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9b03316d-52e6-4441-a2e8-e53a0cf8215a&userId=1ec9eb6f-9deb-66ac-a60c-50640f758c06",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093548",
        oponentTeamId: "5109692",
        opponent: "1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        playerTeam: [
          {
            gene: "0x800000000000010003800050c3080000000100142840450a00010004282141080001000018614408000100143001410400010000280141040001001010808404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740233,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c2080000000100142880450a000100042880840a000100001800c0060001001430414306000100101080c30a0001001010204102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11728439,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010080710110000000010014280142020001000008214502000100001800c00600010010082040040001000008418208000100000821800c",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11732965,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1997,
        opponentMmrAfter: 1988,
        opponentSlpWon: 0,
        playerMmrBefore: 2012,
        playerMmrAfter: 2021,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "4c25e905-b952-498c-ad76-4da5e547a9e7",
        gameTimestamp: "2023-05-29 00:39:09",
        startTimestamp: "2023-05-29 00:34:26",
        endTimestamp: "2023-05-29 00:39:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4c25e905-b952-498c-ad76-4da5e547a9e7&userId=1ec9eb6f-9deb-66ac-a60c-50640f758c06",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093548",
        oponentTeamId: "5103445",
        opponent: "1ec9eb7e-37e4-6d1b-a60c-7a7e5a13f25a",
        playerTeam: [
          {
            gene: "0x800000000000010003800050c3080000000100142840450a00010004282141080001000018614408000100143001410400010000280141040001001010808404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740233,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c2080000000100142880450a000100042880840a000100001800c0060001001430414306000100101080c30a0001001010204102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11728439,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010080710110000000010014280142020001000008214502000100001800c00600010010082040040001000008418208000100000821800c",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11732965,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100008040d0c30c00000001001428a1450a000100042021420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11744334,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001012070820c00000001001428a1450a0001001428814208000100001800c0060001001008818002000100101880c40c000100101080820a",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_1011_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_aquatic_3010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11727078,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140e0908308000000010014284102040001001428604408000100001800c30c0001001008018304000100101880c50a000100101080800a",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11739914,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1949,
        opponentMmrAfter: 1941,
        opponentSlpWon: 0,
        playerMmrBefore: 2004,
        playerMmrAfter: 2012,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "91f4e841-139f-442d-9a99-199482d96cea",
        gameTimestamp: "2023-05-29 00:33:37",
        startTimestamp: "2023-05-29 00:28:26",
        endTimestamp: "2023-05-29 00:33:37",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=91f4e841-139f-442d-9a99-199482d96cea&userId=1ec9eb6f-9deb-66ac-a60c-50640f758c06",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5093548",
        oponentTeamId: "5108322",
        opponent: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        playerTeam: [
          {
            gene: "0x800000000000010003800050c3080000000100142840450a00010004282141080001000018614408000100143001410400010000280141040001001010808404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740233,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000141c020c2080000000100142880450a000100042880840a000100001800c0060001001430414306000100101080c30a0001001010204102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11728439,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100010080710110000000010014280142020001000008214502000100001800c00600010010082040040001000008418208000100000821800c",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11732965,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010002c0e05082080000000100142841020a0001001028810104000100001800c006000100143021450c00010004104044060001001010808302",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11730485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11732056,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000381008082000000000100142840850a000100102881410a0001000018a10006000100103021400a0001000010408308000100101060c404",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11729428,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2044,
        opponentMmrAfter: 2033,
        opponentSlpWon: 0,
        playerMmrBefore: 1993,
        playerMmrAfter: 2004,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:55.000Z",
  },
  {
    clientId: "1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
    name: "Pachecoppp | CG NFT",
    rank: "Challenger",
    tier: 0,
    topRank: 79,
    vstar: 2039,
    avatar: "s3_top1k;gold_s3",
    battles: [
      {
        battleUuid: "180d3fdf-e08e-4444-a037-c7cb28f6008b",
        gameTimestamp: "2023-05-29 13:30:24",
        startTimestamp: "2023-05-29 13:22:47",
        endTimestamp: "2023-05-29 13:30:24",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=180d3fdf-e08e-4444-a037-c7cb28f6008b&userId=1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5112385",
        oponentTeamId: "5111978",
        opponent: "1ed552da-3cb7-69e2-8535-64ab838f3d95",
        playerTeam: [
          {
            gene: "0x8000000000001000081802083080000000100142860450a0001000428208308000100001800c0060001001430a1850c000100002880c1080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11715971,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100010160b0c30800000001001428214102000100042820450a000100001800c0060001001430a1850c00010004102084060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "",
            },
            axie_id: 11726531,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0840c00000001001428a1450a000100042881010a000100001800c2060001000828a1830a000100002880c4060001001010808208",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11751919,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003004050430c000000010004100141080001000808a1050a0001000408a0420a000100042821410a0001000410a0c00a0001001018008406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2019_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11687834,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030040e102100000000100041000420a00010008084042020001001020204408000100042880410800010008180082060001000008004508",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11745245,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c00000810800000001000810208202000100080841020200010010208104080001000428214202000100081840c40c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2018_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11720846,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5bbc3357-9a18-453d-999c-6bc6d318fbf2",
        gameTimestamp: "2023-05-29 13:22:07",
        startTimestamp: "2023-05-29 13:13:17",
        endTimestamp: "2023-05-29 13:22:07",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5bbc3357-9a18-453d-999c-6bc6d318fbf2&userId=1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5089792",
        oponentTeamId: "5111978",
        opponent: "1ed552da-3cb7-69e2-8535-64ab838f3d95",
        playerTeam: [
          {
            gene: "0x20000000000001000301c0e0c20800000001000410208104000100080840400800010010208104080001000428210008000100081840c206000100101840840c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740096,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010080c0820800000001000410810104000100080840420200010010208104080001001030804402000100081840c2060001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11745145,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001006001a060821000000001000c0881020400010008084044040001001020810308000100042821410a000100041040c104000100101880c006",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11731513,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003004050430c000000010004100141080001000808a1050a0001000408a0420a000100042821410a0001000410a0c00a0001001018008406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2019_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11687834,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030040e102100000000100041000420a00010008084042020001001020204408000100042880410800010008180082060001000008004508",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2018_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "",
            },
            axie_id: 11745245,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c00000810800000001000810208202000100080841020200010010208104080001000428214202000100081840c40c000100101880c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2018_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11720846,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b876b830-cc6f-4e7c-9b10-ea3520f8c2b5",
        gameTimestamp: "2023-05-29 13:11:25",
        startTimestamp: "2023-05-29 13:11:10",
        endTimestamp: "2023-05-29 13:11:25",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b876b830-cc6f-4e7c-9b10-ea3520f8c2b5&userId=1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5089792",
        oponentTeamId: "5103321",
        opponent: "1ed552da-3cb7-69e2-8535-64ab838f3d95",
        playerTeam: [
          {
            gene: "0x20000000000001000301c0e0c20800000001000410208104000100080840400800010010208104080001000428210008000100081840c206000100101840840c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11740096,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100010080c0820800000001000410810104000100080840420200010010208104080001001030804402000100081840c2060001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11745145,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001006001a060821000000001000c0881020400010008084044040001001020810308000100042821410a000100041040c104000100101880c006",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11731513,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000000100018080e08408000000010014102142080001001408614104000100001800c006000100043020c5080001001420a105020001000408204102",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11725731,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000001000044100000000100040821020a0001000428614504000100042821400c0001001420a0c50800010014206085060001000428204502",
            runes: ["rune_dawn_3010_s4_nft"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3011_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11714719,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000051040400000001000020a083040001000c28a1430a0001000018a0410400010014202183080001001420a1050a0001000410a1830c",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11706105,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d548fa2f-238e-458a-8e51-f8404265dd77",
        gameTimestamp: "2023-05-29 12:54:36",
        startTimestamp: "2023-05-29 12:48:36",
        endTimestamp: "2023-05-29 12:54:36",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d548fa2f-238e-458a-8e51-f8404265dd77&userId=1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5112385",
        oponentTeamId: "5110625",
        opponent: "1ec9eb7e-580f-6839-a60c-fa77eebc5501",
        playerTeam: [
          {
            gene: "0x8000000000001000081802083080000000100142860450a0001000428208308000100001800c0060001001430a1850c000100002880c1080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11715971,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100010160b0c30800000001001428214102000100042820450a000100001800c0060001001430a1850c00010004102084060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "",
            },
            axie_id: 11726531,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0840c00000001001428a1450a000100042881010a000100001800c2060001000828a1830a000100002880c4060001001010808208",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11751919,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003408040c40c0000000100040821010200010004282141080001000018008506000100042821410a000100041020c1080001000408818204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11720939,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c180210210000000010000100080080001000028204508000100001800c0060001000c280100040001000008010006000100000800450a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11581870,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e02084080000000100001001400a0001000008004002000100001041000600010000100080040001000008010008000100002801450c",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 8993015,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0d53ae0c-b943-4312-9dde-7ec378c1e224",
        gameTimestamp: "2023-05-29 12:46:56",
        startTimestamp: "2023-05-29 12:41:31",
        endTimestamp: "2023-05-29 12:46:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0d53ae0c-b943-4312-9dde-7ec378c1e224&userId=1ec9eb6f-50fd-6007-a60c-14ce7a10711d",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5112385",
        oponentTeamId: "5110625",
        opponent: "1ec9eb7e-580f-6839-a60c-fa77eebc5501",
        playerTeam: [
          {
            gene: "0x20000000000001004040609083080000000100142881420a0001001020810408000100001800c4080001001008818402000100100820430c0001001010808504",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11667226,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100010160b0c30800000001001428214102000100042820450a000100001800c0060001001430a1850c00010004102084060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "",
            },
            axie_id: 11726531,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008040e0840c00000001001428a1450a000100042881010a000100001800c2060001000828a1830a000100002880c4060001001010808208",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11751919,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003408040c40c0000000100040821010200010004282141080001000018008506000100042821410a000100041020c1080001000408818204",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11720939,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c180210210000000010000100080080001000028204508000100001800c0060001000c280100040001000008010006000100000800450a",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11581870,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080e02084080000000100001001400a0001000008004002000100001041000600010000100080040001000008010008000100002801450c",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 8993015,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:55.000Z",
  },
  {
    clientId: "1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
    name: "MrBeast",
    rank: "Challenger",
    tier: 0,
    topRank: 80,
    vstar: 2038,
    avatar: "easter_momo;gold_s3",
    battles: [
      {
        battleUuid: "891b5397-132a-4512-b0b7-570eeb73b677",
        gameTimestamp: "2023-05-29 12:14:55",
        startTimestamp: "2023-05-29 12:10:17",
        endTimestamp: "2023-05-29 12:14:55",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=891b5397-132a-4512-b0b7-570eeb73b677&userId=1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109692",
        oponentTeamId: "5108308",
        opponent: "1ed57738-fb5b-67da-895c-8d36800bd76c",
        playerTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000818071041000000001000c106043080001000c0860440a0001000c306083060001000c086143060001000c2061830c0001000008004302",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 9900856,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000180a060030c00000001000c28a145040001001408a1450a0001000c086145040001001410a105060001001410a0c3040001001408008004",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_3012_s4_nondec",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_reptile_3010_s4_nft",
              mouth: "",
            },
            axie_id: 394405,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000301a030840800000001000408010402000100102061430a0001000c0860c1060001000c08608104000100041021410a000100103041840c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_plant_3012_s4_nft",
              eyes: "",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_aquatic_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11743081,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2092,
        opponentMmrAfter: 2081,
        opponentSlpWon: 0,
        playerMmrBefore: 2027,
        playerMmrAfter: 2038,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a0861319-5012-49af-a49e-ca964be5a6bc",
        gameTimestamp: "2023-05-29 12:09:01",
        startTimestamp: "2023-05-29 12:04:12",
        endTimestamp: "2023-05-29 12:09:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a0861319-5012-49af-a49e-ca964be5a6bc&userId=1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109692",
        oponentTeamId: "5110202",
        opponent: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        playerTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000100a040800c00000001001428a1450a0001000428410204000100001800c0060001001430a1810400010000282143040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11732056,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c060830c000000010014288102080001000428214408000100001800c006000100100881840a0001001018404406000100101080c20c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11739403,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2034,
        opponentMmrAfter: 2024,
        opponentSlpWon: 0,
        playerMmrBefore: 2017,
        playerMmrAfter: 2027,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ed008394-04c7-47db-ac68-f23b26e3d6a2",
        gameTimestamp: "2023-05-29 02:33:35",
        startTimestamp: "2023-05-29 02:29:14",
        endTimestamp: "2023-05-29 02:33:35",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ed008394-04c7-47db-ac68-f23b26e3d6a2&userId=1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109692",
        oponentTeamId: "5105169",
        opponent: "1edb3cb8-a80e-6b4b-8ff6-5beaea41a2d9",
        playerTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010003408080c00000000001001428a1410800010010104042040001000018a0c30a0001001430208404000100101880c00a0001001010618308",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11708191,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000001c070820000000001001428a1450a000100102000820400010000188040060001001008010402000100041080c1040001001010808404",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11710388,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000080e03081080000000100142881010a0001001020808508000100001800c00600010010308182020001001018414406000100101040440c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_neutral_1001",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11718668,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1948,
        opponentMmrAfter: 1940,
        opponentSlpWon: 0,
        playerMmrBefore: 2009,
        playerMmrAfter: 2017,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "14805c1b-6636-4a07-8551-57d4f1a8f47e",
        gameTimestamp: "2023-05-29 02:28:39",
        startTimestamp: "2023-05-29 02:24:35",
        endTimestamp: "2023-05-29 02:28:39",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=14805c1b-6636-4a07-8551-57d4f1a8f47e&userId=1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109692",
        oponentTeamId: "4859951",
        opponent: "1ec9eb6f-41b2-6328-a60c-007456abd012",
        playerTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000040a020821000000001000c08608302000100002860420a0001000c3061830c0001000c28614304000100000861830a0001000c0861830c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 2753269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100600040b0830c00000001001010a14404000100100881400800010000180084080001001030818202000100101880c5040001000408808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11693077,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07102100000000100001060430a000100000801400a000100001000850a000100000800c0040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_beast_buba_1",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 7605259,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2047,
        opponentMmrAfter: 2036,
        opponentSlpWon: 0,
        playerMmrBefore: 1998,
        playerMmrAfter: 2009,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f1b88b8a-94dc-4445-85b5-8d3371751803",
        gameTimestamp: "2023-05-29 02:23:52",
        startTimestamp: "2023-05-29 02:17:52",
        endTimestamp: "2023-05-29 02:23:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f1b88b8a-94dc-4445-85b5-8d3371751803&userId=1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        battleType: "pvp",
        playerSlpWon: 22,
        playerTeamId: "5109692",
        oponentTeamId: "5049541",
        opponent: "1ec9eb7e-5b7f-6bdd-a60c-e3f1db5cbd4c",
        playerTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3012_s4_nondec",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100024160708308000000010014282084020001000428604002000100001821000600010014300181040001000028004204000100101001400c",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11714372,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241c050c4080000000100142881030a0001000428214004000100001800c0060001001430a1850c00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11728326,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010002c16050820c00000001001428a0850a0001000428a14102000100001800c006000100042820850c0001000410208404000100101061000a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749857,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2016,
        opponentMmrAfter: 2006,
        opponentSlpWon: 0,
        playerMmrBefore: 1988,
        playerMmrAfter: 1998,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:56.000Z",
  },
  {
    clientId: "1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
    name: "Flotastic",
    rank: "Challenger",
    tier: 0,
    topRank: 81,
    vstar: 2038,
    avatar: "s3_top20k;bronze_s3",
    battles: [
      {
        battleUuid: "33b56f4e-9166-47de-954c-38a811adf107",
        gameTimestamp: "2023-05-29 04:54:45",
        startTimestamp: "2023-05-29 04:51:53",
        endTimestamp: "2023-05-29 04:54:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=33b56f4e-9166-47de-954c-38a811adf107&userId=1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5073649",
        oponentTeamId: "5",
        opponent: "bot-client-id",
        playerTeam: [
          {
            gene: "0x28000000000001000180a050820800000001001428a145080001000410a14504000100001820c304000100141060c50400010014102140020001001418010404",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_reptile_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_bug_3010_s4_nft",
            },
            axie_id: 11731443,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000380a050c20800000001001420a145080001001420a085040001000018004504000100141060c0060001001410a0810a0001001418808308",
            runes: ["rune_reptile_2010_s4_nft"],
            charms: {
              back: "ecard_reptile_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_reptile_2013_s4_nft",
            },
            axie_id: 11731437,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000300b01082080000000100102880430800010000100085040001000018a0c1080001001410a083060001001410a081080001000c1060840a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_reptile_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 11719222,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002412090000000000001000c08604302000100001400a0050001000c206103080001000c0c606303000100440e207103000100440e207103",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 16,
            position: 0,
            axie_type: "starter",
          },
          {
            gene: "0x200000000000010002c160b0000000000001001004802401000100000c00600300010010048024010001001004802401000100140ca065030001001004802401",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 3,
            position: 2,
            axie_type: "starter",
          },
          {
            gene: "0x1000000000000000000000100000c0060030001000004002001000100000c0060030001000c046023010001000c0c606303000100000c006003",
            runes: [],
            charms: {
              back: null,
              ears: null,
              eyes: null,
              horn: null,
              tail: null,
              mouth: null,
            },
            axie_id: 1,
            position: 5,
            axie_type: "starter",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: 0,
        playerMmrAfter: 0,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "c65878e4-e270-4eb5-8d6d-3f63871b55ea",
        gameTimestamp: "2023-05-29 04:49:56",
        startTimestamp: "2023-05-29 04:46:38",
        endTimestamp: "2023-05-29 04:49:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c65878e4-e270-4eb5-8d6d-3f63871b55ea&userId=1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5067164",
        oponentTeamId: "5075273",
        opponent: "1ec9eb7e-320e-6afc-a60c-d89c0f57aabf",
        playerTeam: [
          {
            gene: "0x800000000000100010080e083040000000100142821050a0001000c28410204000100001800c00c0001001410a1810c000100101880c5040001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11741227,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001004040a020020800000001001428a1440a000100042061000800010000180100060001000c0881840c0001001018a040060001001010a18202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11734852,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a050c20000000001001428a1450a0001000820a0810400010000182140060001001410610306000100000861410a0001001010814408",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739855,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0e0c30c00000001001428a1420400010004282144080001000018008006000100143081850c000100000801410c0001001010808006",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11709535,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001404040830800000001001428a14204000100001020430a0001000018604508000100041020850c0001001018a044060001001010808404",
            runes: ["rune_neutral_3010_s4"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_bug_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11716020,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a090030800000001001428a1450a00010004284103040001000018418006000100001861850c00010004108044020001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11731287,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1962,
        opponentMmrAfter: 1954,
        opponentSlpWon: 0,
        playerMmrBefore: 2030,
        playerMmrAfter: 2038,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "db6a2eb0-ae26-45fc-8e35-213463650d9c",
        gameTimestamp: "2023-05-29 04:46:02",
        startTimestamp: "2023-05-29 04:40:19",
        endTimestamp: "2023-05-29 04:46:02",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=db6a2eb0-ae26-45fc-8e35-213463650d9c&userId=1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5067164",
        oponentTeamId: "5105092",
        opponent: "1ecb6080-f8d7-638f-8ba8-beb9cd2501e7",
        playerTeam: [
          {
            gene: "0x800000000000100010080e083040000000100142821050a0001000c28410204000100001800c00c0001001410a1810c000100101880c5040001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11741227,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001004040a020020800000001001428a1440a000100042061000800010000180100060001000c0881840c0001001018a040060001001010a18202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11734852,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a050c20000000001001428a1450a0001000820a0810400010000182140060001001410610306000100000861410a0001001010814408",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739855,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c1a0708208000000010010104100040001000028410104000100001881840c0001001030008402000100103080c0020001000c10808306",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_plant_2011_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11681982,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804020c00c0000000100002801400a0001000008210002000100001000850800010000080040040001000008004106000100002801400a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 7129765,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e07002000000000100000800850400010000204044080001000018008004000100002801000200010000080080060001000c1000c10c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2019_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11553102,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1997,
        opponentMmrAfter: 1988,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2030,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "22704f23-b8d7-4d6b-b376-23c8c1255fe9",
        gameTimestamp: "2023-05-29 04:39:34",
        startTimestamp: "2023-05-29 04:34:09",
        endTimestamp: "2023-05-29 04:39:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=22704f23-b8d7-4d6b-b376-23c8c1255fe9&userId=1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5067164",
        oponentTeamId: "5057206",
        opponent: "1ec9eb6f-74d8-6ddd-a60c-06a2e615aec8",
        playerTeam: [
          {
            gene: "0x800000000000100010080e083040000000100142821050a0001000c28410204000100001800c00c0001001410a1810c000100101880c5040001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11741227,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001004040a020020800000001001428a1440a000100042061000800010000180100060001000c0881840c0001001018a040060001001010a18202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11734852,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a050c20000000001001428a1450a0001000820a0810400010000182140060001001410610306000100000861410a0001001010814408",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739855,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101c040810c00000001001428a1450a000100042860850a00010000186100060001000410204104000100002820850a0001001010a10404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11731295,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c08040c2080000000100142841050a000100041081000a000100001861430c000100100820410a00010000084144060001001010a14402",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11729750,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100805083000000000100142861050a000100042821410a000100001800c0060001001430a1820600010004308082040001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11734587,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1978,
        opponentMmrAfter: 1970,
        opponentSlpWon: 0,
        playerMmrBefore: 2013,
        playerMmrAfter: 2021,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b23f652e-36b8-41ba-b70b-f54276fab96c",
        gameTimestamp: "2023-05-29 04:33:38",
        startTimestamp: "2023-05-29 04:28:12",
        endTimestamp: "2023-05-29 04:33:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b23f652e-36b8-41ba-b70b-f54276fab96c&userId=1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5067164",
        oponentTeamId: "5081096",
        opponent: "1ec9eb6f-6b7e-6ab9-a60c-a0047de0946b",
        playerTeam: [
          {
            gene: "0x800000000000100010080e083040000000100142821050a0001000c28410204000100001800c00c0001001410a1810c000100101880c5040001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11741227,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001004040a020020800000001001428a1440a000100042061000800010000180100060001000c0881840c0001001018a040060001001010a18202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11734852,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a050c20000000001001428a1450a0001000820a0810400010000182140060001001410610306000100000861410a0001001010814408",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739855,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100038040e082000000000100041021430400010014284044040001001020810106000100042820c20600010008182042060001001018810406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11700239,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008021030800000001000410010104000100080840410800010010208103060001001030a0810a0001000818604302000100000800400a",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691913,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002b01800420800000001000c08208104000100080881020200010010204104080001000428214206000100041040c1040001001018a18406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11747635,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2020,
        opponentMmrAfter: 2010,
        opponentSlpWon: 0,
        playerMmrBefore: 2003,
        playerMmrAfter: 2013,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:57.000Z",
  },
  {
    clientId: "1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
    name: "Agus21212",
    rank: "Challenger",
    tier: 0,
    topRank: 82,
    vstar: 2036,
    avatar: "s3_top20k;patrick23",
    battles: [
      {
        battleUuid: "116c0c4e-c1a7-4cd4-b331-2ca692ad75e0",
        gameTimestamp: "2023-05-29 05:49:11",
        startTimestamp: "2023-05-29 05:49:06",
        endTimestamp: "2023-05-29 05:49:11",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=116c0c4e-c1a7-4cd4-b331-2ca692ad75e0&userId=1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5057779",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x2000000000000100008040208200000000010004102081040001000808a042020001001020810408000100042881810a0001000818814006000100101880c208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e08208000000010004102084040001000808a142020001001020218408000100103021410a000100081840c2060001000008418406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11732025,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001004040e020c10c00000001000410208402000100080840410a000100102021830c0001000428a1810a00010004102145060001001018008406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11736031,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2028,
        opponentMmrAfter: 2018,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2036,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "dbc1cd83-2c28-48f5-9d3d-e6e3921c9d04",
        gameTimestamp: "2023-05-29 05:48:28",
        startTimestamp: "2023-05-29 05:43:55",
        endTimestamp: "2023-05-29 05:48:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=dbc1cd83-2c28-48f5-9d3d-e6e3921c9d04&userId=1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5057779",
        oponentTeamId: "5082942",
        opponent: "1ec9eb6f-6ca2-6e24-a60c-ad0421a3e9c6",
        playerTeam: [
          {
            gene: "0x2000000000000100008040208200000000010004102081040001000808a042020001001020810408000100042881810a0001000818814006000100101880c208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e08208000000010004102084040001000808a142020001001020218408000100103021410a000100081840c2060001000008418406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11732025,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001004040e020c10c00000001000410208402000100080840410a000100102021830c0001000428a1810a00010004102145060001001018008406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11736031,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001404040840800000001001428a143040001000428410502000100001800c0060001001430a1810400010010188044040001001010808108",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740182,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0830000000001001428a1450a0001000428a08408000100001800c0060001001430a1800a0001000028a18406000100101001040c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11735453,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000101207083100000000100142801010a00010008204105080001000018804006000100103081800a000100101880c10a000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742104,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2051,
        opponentMmrAfter: 2040,
        opponentSlpWon: 0,
        playerMmrBefore: 2015,
        playerMmrAfter: 2026,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9af8c1d4-6945-4e45-8d7a-abc581c5e0f1",
        gameTimestamp: "2023-05-29 05:41:13",
        startTimestamp: "2023-05-29 05:35:58",
        endTimestamp: "2023-05-29 05:41:13",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9af8c1d4-6945-4e45-8d7a-abc581c5e0f1&userId=1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5057779",
        oponentTeamId: "5095381",
        opponent: "1ec9eb7e-3f44-667a-a60c-f31dd709fb65",
        playerTeam: [
          {
            gene: "0x2000000000000100008040208200000000010004102081040001000808a042020001001020810408000100042881810a0001000818814006000100101880c208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e08208000000010004102084040001000808a142020001001020218408000100103021410a000100081840c2060001000008418406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11732025,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001004040e020c10c00000001000410208402000100080840410a000100102021830c0001000428a1810a00010004102145060001001018008406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11736031,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000141c020c20400000001001428a0850a000100042881050a000100001800c002000100143041810a00010004100081020001001010810304",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bug_3013_s4_nondec",
            },
            axie_id: 11743586,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100404080e0830000000001001428a1450a000100042821450800010000188080060001001430814408000100002820c00a0001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "",
            },
            axie_id: 11740648,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c050820800000001001428a1050a0001001028204202000100001860400a000100100820450c0001001018a08204000100101020c206",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11724523,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2092,
        opponentMmrAfter: 2080,
        opponentSlpWon: 0,
        playerMmrBefore: 2003,
        playerMmrAfter: 2015,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0b91d84b-413f-443b-b8b1-6a12f4438d36",
        gameTimestamp: "2023-05-29 05:35:21",
        startTimestamp: "2023-05-29 05:28:48",
        endTimestamp: "2023-05-29 05:35:21",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0b91d84b-413f-443b-b8b1-6a12f4438d36&userId=1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5057779",
        oponentTeamId: "5026311",
        opponent: "1ec9eb6f-8e03-67d9-a60c-a0cd8ab0ef27",
        playerTeam: [
          {
            gene: "0x2000000000000100008040208200000000010004102081040001000808a042020001001020810408000100042881810a0001000818814006000100101880c208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e08208000000010004102084040001000808a142020001001020218408000100103021410a000100081840c2060001000008418406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11732025,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001004040e020c10c00000001000410208402000100080840410a000100102021830c0001000428a1810a00010004102145060001001018008406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11736031,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e07002040000000100041020830200010008084042020001001020404408000100042881810a000100081840c206000100101880c20c",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11713769,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c0c082100000000100041081010400010008084041080001001020810408000100103040c10a000100081840c4040001000008818002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11705793,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010060012080840800000001000c088083020001000808004504000100102000450a00010004282141060001000410014102000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11712809,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1951,
        opponentMmrAfter: 1962,
        opponentSlpWon: 22,
        playerMmrBefore: 2014,
        playerMmrAfter: 2003,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "5fd6a128-b641-4896-b20b-da923416fb8e",
        gameTimestamp: "2023-05-29 05:27:55",
        startTimestamp: "2023-05-29 05:22:52",
        endTimestamp: "2023-05-29 05:27:55",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5fd6a128-b641-4896-b20b-da923416fb8e&userId=1ec9eb6f-6253-6ec2-a60c-4122f1ae291a",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5057779",
        oponentTeamId: "5000657",
        opponent: "1ed7df8f-bb52-6657-953b-9ccc0ca80cef",
        playerTeam: [
          {
            gene: "0x2000000000000100008040208200000000010004102081040001000808a042020001001020810408000100042881810a0001000818814006000100101880c208",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11731992,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e08208000000010004102084040001000808a142020001001020218408000100103021410a000100081840c2060001000008418406",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11732025,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001004040e020c10c00000001000410208402000100080840410a000100102021830c0001000428a1810a00010004102145060001001018008406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_2011_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11736031,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100010080e0c2000000000100142861020800010004282141020001000018614006000100143040840a000100002880c402000100101080800a",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11749630,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c04051000000000001001428a144040001001028a0430a0001000018210006000100103060c40c000100101880c304000100101040440c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 11736897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0e07103000000000100142820440a00010010288040080001000018a14006000100103040840c000100101880c0040001001010210104",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1011_s4",
            },
            axie_id: 11715262,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1934,
        opponentMmrAfter: 1927,
        opponentSlpWon: 0,
        playerMmrBefore: 2007,
        playerMmrAfter: 2014,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:57.000Z",
  },
  {
    clientId: "1ed06b56-8485-69d1-99e8-a285bfab2eb5",
    name: "Move | VidaLoka ",
    rank: "Challenger",
    tier: 0,
    topRank: 83,
    vstar: 2036,
    avatar: "s3_top1k;silver_s3",
    battles: [
      {
        battleUuid: "bc50c015-085b-4c40-a852-ccc2a4cdfb76",
        gameTimestamp: "2023-05-29 04:52:57",
        startTimestamp: "2023-05-29 04:47:07",
        endTimestamp: "2023-05-29 04:52:57",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bc50c015-085b-4c40-a852-ccc2a4cdfb76&userId=1ed06b56-8485-69d1-99e8-a285bfab2eb5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100288",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x8000000000001000080a050800800000001001428a1450a000100042881020a0001000018008006000100143040450c00010000288084060001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743726,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e040c40c00000001001428604202000100082061020a000100001800c10a000100103021440c000100101820c40a0001001010418508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737174,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408060020c000000010014284042040001000820814102000100001800c20600010014308104080001000410208404000100101080840a",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730540,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2046,
        opponentMmrAfter: 2036,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2036,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "860cf6f7-6439-4ed4-b0d4-3bc40c96a9c4",
        gameTimestamp: "2023-05-29 04:46:35",
        startTimestamp: "2023-05-29 04:40:19",
        endTimestamp: "2023-05-29 04:46:35",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=860cf6f7-6439-4ed4-b0d4-3bc40c96a9c4&userId=1ed06b56-8485-69d1-99e8-a285bfab2eb5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100288",
        oponentTeamId: "5103941",
        opponent: "1ec9eb6f-5043-6b45-a60c-84c89f6ecc67",
        playerTeam: [
          {
            gene: "0x8000000000001000080a050800800000001001428a1450a000100042881020a0001000018008006000100143040450c00010000288084060001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743726,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e040c40c00000001001428604202000100082061020a000100001800c10a000100103021440c000100101820c40a0001001010418508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737174,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408060020c000000010014284042040001000820814102000100001800c20600010014308104080001000410208404000100101080840a",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730540,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c080b0841000000001000810410408000100002841000a000100001800c30c000100141040400c0001000c30618002000100000800c004",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1001",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11642208,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120910208000000010008204102080001000828414002000100081840c206000100083041010c000100083041820c0001000810418204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11663603,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002c180b1021000000001000c1060810800010000100083080001000c30a082060001001410a0850400010004102101080001000c1060800a",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2018_s4_nft",
            },
            axie_id: 11304060,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2001,
        opponentMmrAfter: 1992,
        opponentSlpWon: 0,
        playerMmrBefore: 2017,
        playerMmrAfter: 2026,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8f4d2540-c405-46e0-949a-af8bfa3ddd67",
        gameTimestamp: "2023-05-29 04:39:45",
        startTimestamp: "2023-05-29 04:33:35",
        endTimestamp: "2023-05-29 04:39:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8f4d2540-c405-46e0-949a-af8bfa3ddd67&userId=1ed06b56-8485-69d1-99e8-a285bfab2eb5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100288",
        oponentTeamId: "5097364",
        opponent: "1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        playerTeam: [
          {
            gene: "0x8000000000001000080a050800800000001001428a1450a000100042881020a0001000018008006000100143040450c00010000288084060001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743726,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e040c40c00000001001428604202000100082061020a000100001800c10a000100103021440c000100101820c40a0001001010418508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737174,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408060020c000000010014284042040001000820814102000100001800c20600010014308104080001000410208404000100101080840a",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730540,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1994,
        opponentMmrAfter: 1985,
        opponentSlpWon: 0,
        playerMmrBefore: 2008,
        playerMmrAfter: 2017,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "45413d65-71c7-4969-8deb-6154015a4cfd",
        gameTimestamp: "2023-05-29 04:32:53",
        startTimestamp: "2023-05-29 04:25:26",
        endTimestamp: "2023-05-29 04:32:53",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=45413d65-71c7-4969-8deb-6154015a4cfd&userId=1ed06b56-8485-69d1-99e8-a285bfab2eb5",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100288",
        oponentTeamId: "4068639",
        opponent: "1ec9eb6f-4b99-6d95-a60c-9df2a0af0ce6",
        playerTeam: [
          {
            gene: "0x8000000000001000080a050800800000001001428a1450a000100042881020a0001000018008006000100143040450c00010000288084060001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743726,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e040c40c00000001001428604202000100082061020a000100001800c10a000100103021440c000100101820c40a0001001010418508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737174,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408060020c000000010014284042040001000820814102000100001800c20600010014308104080001000410208404000100101080840a",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730540,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000200c0d0c30c00000001001408a1430a000100142840850a000100142080c108000100142081440c0001001418a0c406000100000800410a",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2013_s4_nft",
              tail: "",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11707540,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002010080430c00000001001408a045020001001428a1450a000100142061410600010014200145020001001418a0c5060001000c28610304",
            runes: ["rune_reptile_2011_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11726869,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002004060c21000000001001408a04404000100142841450a0001001420a105060001000c3061840800010014184105060001000c2861450a",
            runes: ["rune_reptile_2011_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11699624,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1917,
        opponentMmrAfter: 1910,
        opponentSlpWon: 0,
        playerMmrBefore: 2001,
        playerMmrAfter: 2008,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "026511eb-0f7c-4447-8787-c4dd97a7ad82",
        gameTimestamp: "2023-05-29 04:24:43",
        startTimestamp: "2023-05-29 04:18:13",
        endTimestamp: "2023-05-29 04:24:43",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=026511eb-0f7c-4447-8787-c4dd97a7ad82&userId=1ed06b56-8485-69d1-99e8-a285bfab2eb5",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5100288",
        oponentTeamId: "5049541",
        opponent: "1ec9eb7e-5b7f-6bdd-a60c-e3f1db5cbd4c",
        playerTeam: [
          {
            gene: "0x8000000000001000080a050800800000001001428a1450a000100042881020a0001000018008006000100143040450c00010000288084060001001010808008",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11743726,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e040c40c00000001001428604202000100082061020a000100001800c10a000100103021440c000100101820c40a0001001010418508",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737174,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001408060020c000000010014284042040001000820814102000100001800c20600010014308104080001000410208404000100101080840a",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730540,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100024160708308000000010014282084020001000428604002000100001821000600010014300181040001000028004204000100101001400c",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11714372,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241c050c4080000000100142881030a0001000428214004000100001800c0060001001430a1850c00010010184144060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11728326,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000380a060821000000001001428a1420400010004282143020001000018a180060001000010a043040001000028a084060001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11746344,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1987,
        opponentMmrAfter: 1997,
        opponentSlpWon: 22,
        playerMmrBefore: 2011,
        playerMmrAfter: 2001,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:35:58.000Z",
  },
  {
    clientId: "1ed3b7e9-040b-611e-a9d2-7628ecc48461",
    name: "Tidurlah zzz | Youtube",
    rank: "Challenger",
    tier: 0,
    topRank: 84,
    vstar: 2031,
    avatar: "shy;silver_s2",
    battles: [
      {
        battleUuid: "8ef48ca4-692e-4cab-bc6d-c0daf59d924c",
        gameTimestamp: "2023-05-29 08:10:34",
        startTimestamp: "2023-05-29 08:05:57",
        endTimestamp: "2023-05-29 08:10:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8ef48ca4-692e-4cab-bc6d-c0daf59d924c&userId=1ed3b7e9-040b-611e-a9d2-7628ecc48461",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4912461",
        oponentTeamId: "5111487",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x900000000000010001c16090840800000001001008a084040001000c28404202000100102000440c00010014304042060001000818410206000100103080c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11688098,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008000840800000001000010810104000100142840440400010010206084040001000428208402000100081840c206000100000800430c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11656480,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c140b0c20c00000001001008804402000100042821040a000100102041010a00010010308143060001000818604406000100101800c406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3011_s4_nft",
            },
            axie_id: 11632566,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100014040e0c30c00000001001428a1450a0001000428a08004000100001800c0060001001430a1850c000100002880c00a000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751686,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2053,
        opponentMmrAfter: 2043,
        opponentSlpWon: 0,
        playerMmrBefore: 2021,
        playerMmrAfter: 2031,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9572c580-fb65-4d84-a0b7-948245b6b1cc",
        gameTimestamp: "2023-05-29 08:05:18",
        startTimestamp: "2023-05-29 08:02:07",
        endTimestamp: "2023-05-29 08:05:18",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9572c580-fb65-4d84-a0b7-948245b6b1cc&userId=1ed3b7e9-040b-611e-a9d2-7628ecc48461",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4912461",
        oponentTeamId: "5104411",
        opponent: "1ec9eb6f-8326-6f48-a60c-da2745238d9b",
        playerTeam: [
          {
            gene: "0x900000000000010001c16090840800000001001008a084040001000c28404202000100102000440c00010014304042060001000818410206000100103080c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11688098,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008000840800000001000010810104000100142840440400010010206084040001000428208402000100081840c206000100000800430c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11656480,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c140b0c20c00000001001008804402000100042821040a000100102041010a00010010308143060001000818604406000100101800c406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3011_s4_nft",
            },
            axie_id: 11632566,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100010160b0820800000001000410204102000100042821410a000100001800c402000100042860810a00010004100141080001000408208102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2015_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11745279,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081c0000210000000010000100080040001000028014404000100001880800c000100000800800200010000088140020001000c10004002",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11578177,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e050841000000001000010008004000100000880400a000100001000800c000100000800400200010000082102080001000028a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nondec",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11547695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1971,
        opponentMmrAfter: 1963,
        opponentSlpWon: 0,
        playerMmrBefore: 2013,
        playerMmrAfter: 2021,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9b4fc1ef-06fe-4c7a-bb3a-d09ad4a29e15",
        gameTimestamp: "2023-05-29 08:01:34",
        startTimestamp: "2023-05-29 07:56:41",
        endTimestamp: "2023-05-29 08:01:34",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9b4fc1ef-06fe-4c7a-bb3a-d09ad4a29e15&userId=1ed3b7e9-040b-611e-a9d2-7628ecc48461",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4912461",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x900000000000010001c16090840800000001001008a084040001000c28404202000100102000440c00010014304042060001000818410206000100103080c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11688098,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008000840800000001000010810104000100142840440400010010206084040001000428208402000100081840c206000100000800430c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11656480,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c140b0c20c00000001001008804402000100042821040a000100102041010a00010010308143060001000818604406000100101800c406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3011_s4_nft",
            },
            axie_id: 11632566,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2055,
        opponentMmrAfter: 2064,
        opponentSlpWon: 0,
        playerMmrBefore: 2022,
        playerMmrAfter: 2013,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "1d47b14b-d944-406a-ab2a-8bc574f92951",
        gameTimestamp: "2023-05-29 07:56:06",
        startTimestamp: "2023-05-29 07:51:30",
        endTimestamp: "2023-05-29 07:56:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1d47b14b-d944-406a-ab2a-8bc574f92951&userId=1ed3b7e9-040b-611e-a9d2-7628ecc48461",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4912461",
        oponentTeamId: "5108594",
        opponent: "1ec9eb6f-6162-65b5-a60c-2cc2f484e3c2",
        playerTeam: [
          {
            gene: "0x900000000000010001c16090840800000001001008a084040001000c28404202000100102000440c00010014304042060001000818410206000100103080c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11688098,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008000840800000001000010810104000100142840440400010010206084040001000428208402000100081840c206000100000800430c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11656480,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c140b0c20c00000001001008804402000100042821040a000100102041010a00010010308143060001000818604406000100101800c406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3011_s4_nft",
            },
            axie_id: 11632566,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010001c1c0e0820800000001000410208302000100080840400a0001000018810408000100042881810a00010004106181040001000008a0c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11733894,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e0b100080000000100001020400a00010000200100020001000030018008000100000800400200010000080040020001001418a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11563550,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010060300020830800000001000c086085080001000c086085020001000c208043040001000c2861400c0001000c1860c3060001000c10210302",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3012_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "",
              horn: "ecard_plant_ena_1",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 10024692,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1927,
        opponentMmrAfter: 1920,
        opponentSlpWon: 0,
        playerMmrBefore: 2015,
        playerMmrAfter: 2022,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5813c7ee-f58e-4f84-93d3-42ddcd86d012",
        gameTimestamp: "2023-05-29 07:50:57",
        startTimestamp: "2023-05-29 07:47:30",
        endTimestamp: "2023-05-29 07:50:57",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5813c7ee-f58e-4f84-93d3-42ddcd86d012&userId=1ed3b7e9-040b-611e-a9d2-7628ecc48461",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "4912461",
        oponentTeamId: "5052911",
        opponent: "1ec9eb6f-6de2-6c91-a60c-9ddc8777833a",
        playerTeam: [
          {
            gene: "0x900000000000010001c16090840800000001001008a084040001000c28404202000100102000440c00010014304042060001000818410206000100103080c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11688098,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008000840800000001000010810104000100142840440400010010206084040001000428208402000100081840c206000100000800430c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11656480,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c140b0c20c00000001001008804402000100042821040a000100102041010a00010010308143060001000818604406000100101800c406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3011_s4_nft",
            },
            axie_id: 11632566,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c12090001000000001000010008504000100002801400a000100001840c10c000100003000c10a00010004102085040001000008a0c002",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11708438,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10002c0e07104100000000100001000800400010000200100020001000030018406000100000800420200010000080040020001001418a0c506",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11682741,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100010080e082080000000100041020850800010004282105080001000c3020440c000100042861810a00010004184101080001000408204106",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11584034,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1966,
        opponentMmrAfter: 1977,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2015,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
    ],
    lastUpdated: "2023-05-29T13:35:59.000Z",
  },
  {
    clientId: "1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
    name: "Autobus",
    rank: "Challenger",
    tier: 0,
    topRank: 85,
    vstar: 2031,
    avatar: "s3_top1k;gold_s2",
    battles: [
      {
        battleUuid: "41c47b72-d745-4313-935a-e5d69b2c2169",
        gameTimestamp: "2023-05-29 13:34:06",
        startTimestamp: "2023-05-29 13:29:57",
        endTimestamp: "2023-05-29 13:34:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=41c47b72-d745-4313-935a-e5d69b2c2169&userId=1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107887",
        oponentTeamId: "5008594",
        opponent: "1ec9eb73-5e00-6a19-a60c-c4d78dc21e80",
        playerTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000381c020c30800000001001428a041080001000428204108000100001800c4040001001020a1850c000100002880c4080001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11733069,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080406083040000000100000880850400010004286044020001001018a100020001000010414004000100100881030c0001000c10018004",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 5191902,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x1000200e0708408000000010000100080040001000010a14004000100103081040c000100000800400a0001000008010002000100141801020a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11561675,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000240c0610408000000010004200102040001000028604404000100102860c5020001000c10808406000100001000c30c000100040840c102",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 650146,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1955,
        opponentMmrAfter: 1947,
        opponentSlpWon: 0,
        playerMmrBefore: 2023,
        playerMmrAfter: 2031,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "19279829-58dd-43bd-88ab-52c6f6833302",
        gameTimestamp: "2023-05-29 13:29:28",
        startTimestamp: "2023-05-29 13:24:13",
        endTimestamp: "2023-05-29 13:29:28",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=19279829-58dd-43bd-88ab-52c6f6833302&userId=1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107887",
        oponentTeamId: "4068639",
        opponent: "1ec9eb6f-4b99-6d95-a60c-9df2a0af0ce6",
        playerTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000381c020c30800000001001428a041080001000428204108000100001800c4040001001020a1850c000100002880c4080001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11733069,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000200c0d0c30c00000001001408a1430a000100142840850a000100142080c108000100142081440c0001001418a0c406000100000800410a",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2013_s4_nft",
              tail: "",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11707540,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002010080430c00000001001408a045020001001428a1450a000100142061410600010014200145020001001418a0c5060001000c28610304",
            runes: ["rune_reptile_2011_s4_nondec"],
            charms: {
              back: "ecard_reptile_3013_s4_nondec",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11726869,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010002004060c21000000001001408a04404000100142841450a0001001420a105060001000c3061840800010014184105060001000c2861450a",
            runes: ["rune_reptile_2011_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11699624,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1942,
        opponentMmrAfter: 1935,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2023,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "c851f0ec-e808-4c5a-8d3c-739333154584",
        gameTimestamp: "2023-05-29 13:23:29",
        startTimestamp: "2023-05-29 13:17:00",
        endTimestamp: "2023-05-29 13:23:29",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c851f0ec-e808-4c5a-8d3c-739333154584&userId=1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107887",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000381c020c30800000001001428a041080001000428204108000100001800c4040001001020a1850c000100002880c4080001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11733069,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2003,
        opponentMmrAfter: 2013,
        opponentSlpWon: 24,
        playerMmrBefore: 2026,
        playerMmrAfter: 2016,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "801f4c37-2e26-4601-8fd1-daed6c0a1598",
        gameTimestamp: "2023-05-29 13:14:38",
        startTimestamp: "2023-05-29 13:05:53",
        endTimestamp: "2023-05-29 13:14:38",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=801f4c37-2e26-4601-8fd1-daed6c0a1598&userId=1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107887",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c070c20c000000010014282045080001000428214102000100001800c1080001000428010104000100041080c2040001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11730561,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2061,
        opponentMmrAfter: 2070,
        opponentSlpWon: 24,
        playerMmrBefore: 2035,
        playerMmrAfter: 2026,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "3d0b54f2-6bec-4ba6-9d7a-a9a61e299d80",
        gameTimestamp: "2023-05-29 13:05:24",
        startTimestamp: "2023-05-29 13:01:05",
        endTimestamp: "2023-05-29 13:05:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3d0b54f2-6bec-4ba6-9d7a-a9a61e299d80&userId=1ec9eb6f-4752-6bfc-a60c-91c536f3ffbc",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5107887",
        oponentTeamId: "5037828",
        opponent: "1ec9eb6f-a0e6-63e5-a60c-4363f0ea12ad",
        playerTeam: [
          {
            gene: "0x80000000000010003808070c2080000000100142801050a000100042841010a000100001800c4020001001430a105020001000410418102000100101041810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11717712,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008120e0c30800000001001428a1450a0001000428214408000100001800c0060001001430a1850c000100002880c20a000100101080810c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11735448,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c070c20c000000010014282045080001000428214102000100001800c1080001000428010104000100041080c2040001001010808408",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11730561,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000381c080410c0000000100142841050a000100042821400a000100001800c004000100082820430a000100002841800a000100101080840c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2019_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11719229,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001016040c30800000001001428a1450a0001000820804508000100001800c3080001001430814008000100041021410c0001001010808008",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11725201,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c1c04082080000000100142841430a0001001020810302000100001800c30c00010010308043040001001018a0c50a0001001010808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11723042,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1959,
        opponentMmrAfter: 1951,
        opponentSlpWon: 0,
        playerMmrBefore: 2027,
        playerMmrAfter: 2035,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:35:59.000Z",
  },
  {
    clientId: "1ec9eb7b-40aa-6844-a60c-1fcf77594740",
    name: "Topak Infinity.youj1zz.c0m",
    rank: "Challenger",
    tier: 0,
    topRank: 86,
    vstar: 2031,
    avatar: "easter_momo;silver_s3",
    battles: [
      {
        battleUuid: "90f4c60c-5b80-4ff3-bac1-8ab3c278ef18",
        gameTimestamp: "2023-05-29 06:48:54",
        startTimestamp: "2023-05-29 06:46:49",
        endTimestamp: "2023-05-29 06:48:54",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=90f4c60c-5b80-4ff3-bac1-8ab3c278ef18&userId=1ec9eb7b-40aa-6844-a60c-1fcf77594740",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4509175",
        oponentTeamId: "5104411",
        opponent: "1ec9eb6f-8326-6f48-a60c-da2745238d9b",
        playerTeam: [
          {
            gene: "0x200000000000010001c100700008000000010010084144040001001028810504000100102081420a000100103021840c00010010188184060001001010808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 3765494,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040708308000000010000102040040001000020414102000100001800c20200010000080041080001000008a100060001000008004002",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_beast_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11633429,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e090420800000001001008808508000100102881040a0001001020810302000100102881820c000100041080c406000100101081040c",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11545566,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100010160b0820800000001000410204102000100042821410a000100001800c402000100042860810a00010004100141080001000408208102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2015_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11745279,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081c0000210000000010000100080040001000028014404000100001880800c000100000800800200010000088140020001000c10004002",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11578177,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e050841000000001000010008004000100000880400a000100001000800c000100000800400200010000082102080001000028a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3012_s4_nondec",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_buba_1",
            },
            axie_id: 11547695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1976,
        opponentMmrAfter: 1968,
        opponentSlpWon: 0,
        playerMmrBefore: 2023,
        playerMmrAfter: 2031,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0946dd59-1b3a-40b7-bab6-c9a4400583d5",
        gameTimestamp: "2023-05-29 05:53:52",
        startTimestamp: "2023-05-29 05:48:20",
        endTimestamp: "2023-05-29 05:53:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0946dd59-1b3a-40b7-bab6-c9a4400583d5&userId=1ec9eb7b-40aa-6844-a60c-1fcf77594740",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4509175",
        oponentTeamId: "4867948",
        opponent: "1ed69c6d-d7e5-65ae-b7e8-2f8f467f19f1",
        playerTeam: [
          {
            gene: "0x200000000000010001c100700008000000010010084144040001001028810504000100102081420a000100103021840c00010010188184060001001010808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 3765494,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040708308000000010000102040040001000020414102000100001800c20200010000080041080001000008a100060001000008004002",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_beast_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11633429,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e090420800000001001008808508000100102881040a0001001020810302000100102881820c000100041080c406000100101081040c",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11545566,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010002418040821000000001000c088083040001000c106083040001000c2060c3080001000c086143060001000c1861050c0001000c1061830c",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3013_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 10159654,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000341204084080000000100082041000a000100082841420a000100081880c40a000100083040400a000100003041820a0001000810614208",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_neutral_2012_s4",
            },
            axie_id: 11681251,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018000411000000001000c086041020001000c1060820a0001000c3081840a0001000c086083060001000c288100040001000c10604106",
            runes: ["rune_plant_3010_s4_nft"],
            charms: {
              back: "ecard_plant_3013_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 10356733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1973,
        opponentMmrAfter: 1965,
        opponentSlpWon: 0,
        playerMmrBefore: 2015,
        playerMmrAfter: 2023,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "bf79715c-d2d3-406a-bd84-6bfbfb7efa18",
        gameTimestamp: "2023-05-29 04:45:37",
        startTimestamp: "2023-05-29 04:39:51",
        endTimestamp: "2023-05-29 04:45:37",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bf79715c-d2d3-406a-bd84-6bfbfb7efa18&userId=1ec9eb7b-40aa-6844-a60c-1fcf77594740",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4509175",
        oponentTeamId: "5108429",
        opponent: "1ec9eb6f-4a61-65c0-a60c-70a8d1627520",
        playerTeam: [
          {
            gene: "0x200000000000010001c100700008000000010010084144040001001028810504000100102081420a000100103021840c00010010188184060001001010808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 3765494,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040708308000000010000102040040001000020414102000100001800c20200010000080041080001000008a100060001000008004002",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_beast_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11633429,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e090420800000001001008808508000100102881040a0001001020810302000100102881820c000100041080c406000100101081040c",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11545566,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000101a040820000000001001428a1450a000100042840810a000100001800c0060001001430a1840a00010000102145080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11736612,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000140c040830800000001001428a1450a000100042800810a000100001800c006000100042880810a000100002820840c0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11749931,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c0a050c20c00000001001428a04404000100102881440a00010000184184080001001030a0c50a00010010188104020001001010210502",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11743311,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2068,
        opponentMmrAfter: 2057,
        opponentSlpWon: 0,
        playerMmrBefore: 2004,
        playerMmrAfter: 2015,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8c46ecde-b46f-47bd-9040-7d673d1ca3d2",
        gameTimestamp: "2023-05-29 03:44:01",
        startTimestamp: "2023-05-29 03:38:37",
        endTimestamp: "2023-05-29 03:44:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8c46ecde-b46f-47bd-9040-7d673d1ca3d2&userId=1ec9eb7b-40aa-6844-a60c-1fcf77594740",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "4509175",
        oponentTeamId: "5083738",
        opponent: "1ec9eb7e-6cae-6be6-a60c-10bc9f21772c",
        playerTeam: [
          {
            gene: "0x200000000000010001c100700008000000010010084144040001001028810504000100102081420a000100103021840c00010010188184060001001010808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 3765494,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040708308000000010000102040040001000020414102000100001800c20200010000080041080001000008a100060001000008004002",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_beast_2011_s4_nondec",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11633429,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e090420800000001001008808508000100102881040a0001001020810302000100102881820c000100041080c406000100101081040c",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11545566,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001008020c30c0000000100142820450a000100042821410a000100001800c006000100042820c00c000100041021800a0001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742066,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000080a020840800000001001428a1450a000100042821420a000100001800c0060001001430a1800c000100002880c404000100101081810c",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11735480,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000380a0a0c20800000001001428a1420800010004282101080001000018a0c102000100100860850c000100101880c00a0001001010808002",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11747074,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1994,
        opponentMmrAfter: 1985,
        opponentSlpWon: 0,
        playerMmrBefore: 1995,
        playerMmrAfter: 2004,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "ba6faed3-1849-407e-b6ff-f7b22ae95737",
        gameTimestamp: "2023-05-29 03:14:45",
        startTimestamp: "2023-05-29 03:09:55",
        endTimestamp: "2023-05-29 03:14:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ba6faed3-1849-407e-b6ff-f7b22ae95737&userId=1ec9eb7b-40aa-6844-a60c-1fcf77594740",
        battleType: "pvp",
        playerSlpWon: 22,
        playerTeamId: "4509175",
        oponentTeamId: "5075273",
        opponent: "1ec9eb7e-320e-6afc-a60c-d89c0f57aabf",
        playerTeam: [
          {
            gene: "0x200000000000010001c100700008000000010010084144040001001028810504000100102081420a000100103021840c00010010188184060001001010808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_aquatic_2013_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 3765494,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040708308000000010000102040040001000020414102000100001800c20200010000080041080001000008a100060001000008004002",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11633429,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000240e090420800000001001008808508000100102881040a0001001020810302000100102881820c000100041080c406000100101081040c",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11545566,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0e0c30c00000001001428a1420400010004282144080001000018008006000100143081850c000100000801410c0001001010808006",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11709535,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001404040830800000001001428a14204000100001020430a0001000018604508000100041020850c0001001018a044060001001010808404",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_bug_2011_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11716020,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002c0a090030800000001001428a1450a00010004284103040001000018418006000100001861850c00010004108044020001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11731287,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1985,
        opponentMmrAfter: 1976,
        opponentSlpWon: 0,
        playerMmrBefore: 1986,
        playerMmrAfter: 1995,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:00.000Z",
  },
  {
    clientId: "1ec9eb7b-31ea-644a-a60c-90bbfed78000",
    name: "Princess jaijai",
    rank: "Challenger",
    tier: 0,
    topRank: 87,
    vstar: 2031,
    avatar: "easter_momo;paschal_eggs",
    battles: [
      {
        battleUuid: "a202935b-31ee-4bb6-a2ce-a4aac7ff6f5e",
        gameTimestamp: "2023-05-29 10:23:09",
        startTimestamp: "2023-05-29 10:18:34",
        endTimestamp: "2023-05-29 10:23:09",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a202935b-31ee-4bb6-a2ce-a4aac7ff6f5e&userId=1ec9eb7b-31ea-644a-a60c-90bbfed78000",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5100042",
        oponentTeamId: "5078235",
        opponent: "1ec9eb7e-3265-6d64-a60c-98df859e310e",
        playerTeam: [
          {
            gene: "0x10001c0e07104100000000100001000800400010000280040080001000018214106000100041800400200010000080040020001001010004002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538765,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0a02104100000000100001000820a0001000020a0420a0001001030a1440c00010000080040020001000008004302000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11572988,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c0e0830000000001000020a1450a000100102021440a00010014086045080001001430a1410a000100101880c1060001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734717,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010001c0e07083080000000100101021440a000100102800810a0001001008804504000100102881440a00010010208105080001001010808506",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11653703,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003410070c208000000010010108084040001000c2000400a0001001008a18402000100102820410a00010010202081040001001010814404",
            runes: ["rune_dawn_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11653696,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002800000000c000000010004288081040001000428804204000100100821840200010010300181020001001020a0c1060001001010214404",
            runes: ["rune_neutral_3011_s4_nondec"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11693038,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "b2822296-c4a5-4c98-a621-068664a256cd",
        gameTimestamp: "2023-05-29 05:30:43",
        startTimestamp: "2023-05-29 05:26:50",
        endTimestamp: "2023-05-29 05:30:43",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b2822296-c4a5-4c98-a621-068664a256cd&userId=1ec9eb7b-31ea-644a-a60c-90bbfed78000",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100042",
        oponentTeamId: "5107936",
        opponent: "1ec9eb6f-6f6f-6679-a60c-1753c0521219",
        playerTeam: [
          {
            gene: "0x10001c0e07104100000000100001000800400010000280040080001000018214106000100041800400200010000080040020001001010004002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538765,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0a02104100000000100001000820a0001000020a0420a0001001030a1440c00010000080040020001000008004302000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11572988,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c0e0830000000001000020a1450a000100102021440a00010014086045080001001430a1410a000100101880c1060001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734717,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x90000000000001000181204082080000000100041081020200010000282142020001000018810408000100083081410600010008306181040001000008410102",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11753868,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c18040820800000001000820210104000100082821440a000100081840c402000100083041820c0001000830a1420c0001000810a1030a",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11661519,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100010180e0820800000001000c286044020001000c106080040001000c3061830a0001000c0880c0080001000c182101080001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_3012_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11128901,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1972,
        opponentMmrAfter: 1964,
        opponentSlpWon: 0,
        playerMmrBefore: 2023,
        playerMmrAfter: 2031,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "f453d204-65c3-48cd-97c1-b9fd77343d06",
        gameTimestamp: "2023-05-29 05:26:00",
        startTimestamp: "2023-05-29 05:21:09",
        endTimestamp: "2023-05-29 05:26:00",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=f453d204-65c3-48cd-97c1-b9fd77343d06&userId=1ec9eb7b-31ea-644a-a60c-90bbfed78000",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100042",
        oponentTeamId: "5097364",
        opponent: "1eda41f5-3ea8-69a0-9cde-113ece392b5a",
        playerTeam: [
          {
            gene: "0x10001c0e07104100000000100001000800400010000280040080001000018214106000100041800400200010000080040020001001010004002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538765,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0a02104100000000100001000820a0001000020a0420a0001001030a1440c00010000080040020001000008004302000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11572988,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c0e0830000000001000020a1450a000100102021440a00010014086045080001001430a1410a000100101880c1060001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734717,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000804020c00c00000001000410208502000100080840450a0001001020810108000100042821410600010008180101040001001018610406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11710470,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030000e1020c00000001000410614404000100080840440a00010010208104080001001030614304000100081840c206000100000800400a",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nondec",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11728564,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010040700020c20800000001000c0881450a00010008084042020001001020810408000100042821410a0001000410208104000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745810,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1991,
        opponentMmrAfter: 1982,
        opponentSlpWon: 0,
        playerMmrBefore: 2014,
        playerMmrAfter: 2023,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0e67edf3-ca73-4fc5-989f-23827c445101",
        gameTimestamp: "2023-05-29 05:20:05",
        startTimestamp: "2023-05-29 05:16:23",
        endTimestamp: "2023-05-29 05:20:05",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0e67edf3-ca73-4fc5-989f-23827c445101&userId=1ec9eb7b-31ea-644a-a60c-90bbfed78000",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5100042",
        oponentTeamId: "5089049",
        opponent: "1ec9eb6f-6b7a-6b4b-a60c-e5eb1dfdf14b",
        playerTeam: [
          {
            gene: "0x10001c0e07104100000000100001000800400010000280040080001000018214106000100041800400200010000080040020001001010004002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538765,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0a02104100000000100001000820a0001000020a0420a0001001030a1440c00010000080040020001000008004302000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11572988,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c0e0830000000001000020a1450a000100102021440a00010014086045080001001430a1410a000100101880c1060001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734717,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c0a040c30800000001001428a1450a0001000428410102000100001800c006000100143081010a00010004102081040001001010618404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_3013_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11719939,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000100005082080000000100142861050a00010008204101020001000018408506000100143081820400010010180041020001001010a14502",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_neutral_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11727024,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c040b0820800000001001428a144040001000820410404000100001800c20c000100100881840c000100001080c30c0001001010808304",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11741222,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1955,
        opponentMmrAfter: 1966,
        opponentSlpWon: 22,
        playerMmrBefore: 2025,
        playerMmrAfter: 2014,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "5a83b168-cae6-4ce2-b373-f66e79285daa",
        gameTimestamp: "2023-05-29 05:14:52",
        startTimestamp: "2023-05-29 05:12:09",
        endTimestamp: "2023-05-29 05:14:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5a83b168-cae6-4ce2-b373-f66e79285daa&userId=1ec9eb7b-31ea-644a-a60c-90bbfed78000",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100042",
        oponentTeamId: "5108530",
        opponent: "1ec9eb7e-3daa-6fea-a60c-23ee421975a6",
        playerTeam: [
          {
            gene: "0x10001c0e07104100000000100001000800400010000280040080001000018214106000100041800400200010000080040020001001010004002",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11538765,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0a02104100000000100001000820a0001000020a0420a0001001030a1440c00010000080040020001000008004302000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_aquatic_3010_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11572988,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001c0c0e0830000000001000020a1450a000100102021440a00010014086045080001001430a1410a000100101880c1060001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11734717,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010002008080c30c0000000100142840850a0001000428a0410a0001000018008006000100143081050c00010000288041060001001010608404",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11705952,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a030830c00000001001428a1450a0001000428408502000100001800c2080001001430a1850200010000280182060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11752303,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000240c060820800000001001428a1450a0001000428214002000100001800c0060001000818408502000100041040c2060001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738825,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1969,
        opponentMmrAfter: 1961,
        opponentSlpWon: 0,
        playerMmrBefore: 2017,
        playerMmrAfter: 2025,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:00.000Z",
  },
  {
    clientId: "1ec9eb6f-53aa-6a21-a60c-4e17b7b54ba0",
    name: " Luana my love | MOVE",
    rank: "Challenger",
    tier: 0,
    topRank: 88,
    vstar: 2031,
    avatar: "buba_joker;silver_s3",
    battles: [
      {
        battleUuid: "241a62e3-c536-4bbf-9019-b5fd997f0bbc",
        gameTimestamp: "2023-05-28 22:24:55",
        startTimestamp: "2023-05-28 22:17:44",
        endTimestamp: "2023-05-28 22:24:55",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=241a62e3-c536-4bbf-9019-b5fd997f0bbc&userId=1ec9eb6f-53aa-6a21-a60c-4e17b7b54ba0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103471",
        oponentTeamId: "5103748",
        opponent: "1ec9eb6f-852c-690b-a60c-5da5a2041d8c",
        playerTeam: [
          {
            gene: "0x20000000000001000300404082080000000100041020820a0001000808a142020001001020810408000100042881810a000100081840c206000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727867,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c04082080000000100041080450400010008084042020001001020808408000100103021440c000100081840c2080001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711690,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000080e070c30c00000001000410a101040001000808404202000100102040c20c000100042821410a00010004108044040001001018010502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11728473,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010000016090430000000001000c08604404000100142880850a0001001020408002000100100841420c000100081840c40c000100000860440c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 11671146,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001c0e09041000000000100100881440400010008084042020001001020214408000100100881820c000100081840c2060001000008a08202",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11721391,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000241603002080000000100001080400800010010288142020001001020218408000100103081400c000100081880820c0001001018818206",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_puffy_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nondec",
            },
            axie_id: 11652270,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1939,
        opponentMmrAfter: 1932,
        opponentSlpWon: 0,
        playerMmrBefore: 2024,
        playerMmrAfter: 2031,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9225968d-4a02-4662-9ef3-edba2bdfc217",
        gameTimestamp: "2023-05-28 22:16:38",
        startTimestamp: "2023-05-28 22:10:17",
        endTimestamp: "2023-05-28 22:16:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9225968d-4a02-4662-9ef3-edba2bdfc217&userId=1ec9eb6f-53aa-6a21-a60c-4e17b7b54ba0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103471",
        oponentTeamId: "5047939",
        opponent: "1ec9eb6f-9e55-6fc3-a60c-f57acd8f6ba6",
        playerTeam: [
          {
            gene: "0x20000000000001000300404082080000000100041020820a0001000808a142020001001020810408000100042881810a000100081840c206000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727867,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c04082080000000100041080450400010008084042020001001020808408000100103021440c000100081840c2080001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711690,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000080e070c30c00000001000410a101040001000808404202000100102040c20c000100042821410a00010004108044040001001018010502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11728473,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001800070820800000001001428a143040001000428014002000100001800c00c0001001430814406000100101880c4060001001010808404",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11731022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c00050020000000001001428a1440400010008208104080001000018a0c408000100041041440c000100101880c4060001001010808308",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "",
            },
            axie_id: 11727491,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010003816050c2080000000100142821450a0001000820810202000100001800c002000100003021440a00010004102085040001001010808402",
            runes: ["rune_mech_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nondec",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2011_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11730488,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1956,
        opponentMmrAfter: 1948,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2024,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "627e510f-9b8f-4bd6-bf0e-03ddf0df5a37",
        gameTimestamp: "2023-05-28 22:09:45",
        startTimestamp: "2023-05-28 22:03:47",
        endTimestamp: "2023-05-28 22:09:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=627e510f-9b8f-4bd6-bf0e-03ddf0df5a37&userId=1ec9eb6f-53aa-6a21-a60c-4e17b7b54ba0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103471",
        oponentTeamId: "5079651",
        opponent: "1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        playerTeam: [
          {
            gene: "0x20000000000001000300404082080000000100041020820a0001000808a142020001001020810408000100042881810a000100081840c206000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727867,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c04082080000000100041080450400010008084042020001001020808408000100103021440c000100081840c2080001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711690,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000080e070c30c00000001000410a101040001000808404202000100102040c20c000100042821410a00010004108044040001001018010502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11728473,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2027,
        opponentMmrAfter: 2017,
        opponentSlpWon: 0,
        playerMmrBefore: 2006,
        playerMmrAfter: 2016,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "0b81cd2b-3a7c-4835-88bb-04aa1284dedd",
        gameTimestamp: "2023-05-28 22:03:10",
        startTimestamp: "2023-05-28 21:56:03",
        endTimestamp: "2023-05-28 22:03:10",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=0b81cd2b-3a7c-4835-88bb-04aa1284dedd&userId=1ec9eb6f-53aa-6a21-a60c-4e17b7b54ba0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103471",
        oponentTeamId: "5091498",
        opponent: "1ec9eb73-7dfa-63be-a60c-d98be1a64d34",
        playerTeam: [
          {
            gene: "0x20000000000001000300404082080000000100041020820a0001000808a142020001001020810408000100042881810a000100081840c206000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727867,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c04082080000000100041080450400010008084042020001001020808408000100103021440c000100081840c2080001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711690,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000080e070c30c00000001000410a101040001000808404202000100102040c20c000100042821410a00010004108044040001001018010502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11728473,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001004041802083080000000100041020800400010008084042020001001020810404000100042840c40c0001000818204206000100101880c406",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11700287,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000018040820800000001000410208104000100080840420200010010208100040001000428818402000100081840c10a000100000800440c",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711754,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003412070810800000001000c0820820200010008084044040001001020810408000100042820810a000100041020c104000100101800c406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3011_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_plant_3011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11714319,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2041,
        opponentMmrAfter: 2030,
        opponentSlpWon: 0,
        playerMmrBefore: 1995,
        playerMmrAfter: 2006,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "6fe65eb3-584a-4e16-ab5c-bcab68c62ecd",
        gameTimestamp: "2023-05-28 21:55:32",
        startTimestamp: "2023-05-28 21:48:26",
        endTimestamp: "2023-05-28 21:55:32",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6fe65eb3-584a-4e16-ab5c-bcab68c62ecd&userId=1ec9eb6f-53aa-6a21-a60c-4e17b7b54ba0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103471",
        oponentTeamId: "5068085",
        opponent: "1eda70da-ef8a-6486-be22-c1a394b9fe88",
        playerTeam: [
          {
            gene: "0x20000000000001000300404082080000000100041020820a0001000808a142020001001020810408000100042881810a000100081840c206000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_3011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11727867,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c04082080000000100041080450400010008084042020001001020808408000100103021440c000100081840c2080001000008014002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711690,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000080e070c30c00000001000410a101040001000808404202000100102040c20c000100042821410a00010004108044040001001018010502",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11728473,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000804051000800000001001428a14502000100042821410a000100001801430a0001001430a0420c0001000028014406000100101080850c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11750767,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812020c30c00000001001428a1050a000100042821410a000100001800c0060001001430a0450c000100002820850c0001001010808404",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743660,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808050c20c00000001001428a145080001000428208102000100001800c50600010000302141040001000410a1050c0001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730524,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1949,
        opponentMmrAfter: 1941,
        opponentSlpWon: 0,
        playerMmrBefore: 1987,
        playerMmrAfter: 1995,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:01.000Z",
  },
  {
    clientId: "1ec9eb7e-6006-6e83-a60c-56b24ebed2d4",
    name: "Tynator | Huru",
    rank: "Challenger",
    tier: 0,
    topRank: 89,
    vstar: 2031,
    avatar: "s3_top1k;temple_gate_lunar23",
    battles: [
      {
        battleUuid: "7d3dd562-633d-4404-8598-0a4e7fc133bd",
        gameTimestamp: "2023-05-29 10:58:07",
        startTimestamp: "2023-05-29 10:52:15",
        endTimestamp: "2023-05-29 10:58:07",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7d3dd562-633d-4404-8598-0a4e7fc133bd&userId=1ec9eb7e-6006-6e83-a60c-56b24ebed2d4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100606",
        oponentTeamId: "5094856",
        opponent: "1ec9eb6f-9f72-6901-a60c-be72a826ade5",
        playerTeam: [
          {
            gene: "0x80000000000010001202000831000000001001428a1440a0001000428204502000100001840440c00010014308141040001001018004202000100101020c404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749307,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000812070820c000000010014286144020001000428604208000100001801800600010014302081040001001010014006000100101000430c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11741527,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070830800000001001428a105080001000820610408000100001861840c000100102880c40c000100101060c2020001001010218104",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2018_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11731224,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000240e020841000000001000420810508000100002821400a000100001800c00a0001001410a042020001000c3061430c0001000008008204",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11741074,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000100809002080000000100082000840a000100082841420a0001000818a080020001000830a0430a000100083041420c0001000810604204",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11741036,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000000c000c4080000000100042060850200010000106040040001000c3000c30a0001000c2861410c0001000c184101020001000c10608304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_3010_s4_nft",
              ears: "ecard_neutral_2020_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 10759078,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1933,
        opponentMmrAfter: 1926,
        opponentSlpWon: 0,
        playerMmrBefore: 2024,
        playerMmrAfter: 2031,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3dfe370b-7f4d-41e2-be8a-1628cc7f4f1b",
        gameTimestamp: "2023-05-29 10:49:49",
        startTimestamp: "2023-05-29 10:44:29",
        endTimestamp: "2023-05-29 10:49:49",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3dfe370b-7f4d-41e2-be8a-1628cc7f4f1b&userId=1ec9eb7e-6006-6e83-a60c-56b24ebed2d4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100606",
        oponentTeamId: "5106306",
        opponent: "1ec9eb6f-548f-6c55-a60c-cbad9bd97791",
        playerTeam: [
          {
            gene: "0x80000000000010001202000831000000001001428a1440a0001000428204502000100001840440c00010014308141040001001018004202000100101020c404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749307,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000812070820c000000010014286144020001000428604208000100001801800600010014302081040001001010014006000100101000430c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11741527,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070830800000001001428a105080001000820610408000100001861840c000100102880c40c000100101060c2020001001010218104",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2018_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11731224,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001416050c30800000001000408a041080001000428a14008000100001800c006000100143020450c000100002821450c0001000408408102",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11719256,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000240e091041000000001001028a0450a000100002861430a0001000018004306000100101040840a000100142861440c0001000008004502",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11736618,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c01010c3080000000100101080810400010010288145080001000410210108000100042021010800010004302185040001000418804106",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nondec",
            },
            axie_id: 11747841,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1981,
        opponentMmrAfter: 1973,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2024,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "28120b11-0871-4f78-bcab-f5ef1dbe493f",
        gameTimestamp: "2023-05-29 10:42:57",
        startTimestamp: "2023-05-29 10:38:30",
        endTimestamp: "2023-05-29 10:42:57",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=28120b11-0871-4f78-bcab-f5ef1dbe493f&userId=1ec9eb7e-6006-6e83-a60c-56b24ebed2d4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100606",
        oponentTeamId: "5106723",
        opponent: "1ec9eb6f-476f-6652-a60c-68e08b885697",
        playerTeam: [
          {
            gene: "0x80000000000010001202000831000000001001428a1440a0001000428204502000100001840440c00010014308141040001001018004202000100101020c404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749307,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000812070820c000000010014286144020001000428604208000100001801800600010014302081040001001010014006000100101000430c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11741527,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070830800000001001428a105080001000820610408000100001861840c000100102880c40c000100101060c2020001001010218104",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2018_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11731224,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000000a0e0820800000001000c086102080001000c106084040001000c306080040001000c2881040c000100042060c4040001000c1060840a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2013_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11003961,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100020120210200000000010000104140040001000028014202000100001800c0060001001028004102000100140800c00c0001000008004002",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11744090,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1c0e104100000000100001000800400010000200100020001000030018406000100000800410a00010000080040020001001418a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nondec",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nondec",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nondec",
            },
            axie_id: 11675213,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1953,
        opponentMmrAfter: 1945,
        opponentSlpWon: 0,
        playerMmrBefore: 2008,
        playerMmrAfter: 2016,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "1075bf25-70d5-4007-adb4-1fef1e9d3782",
        gameTimestamp: "2023-05-29 10:31:05",
        startTimestamp: "2023-05-29 10:26:29",
        endTimestamp: "2023-05-29 10:31:05",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=1075bf25-70d5-4007-adb4-1fef1e9d3782&userId=1ec9eb7e-6006-6e83-a60c-56b24ebed2d4",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5100606",
        oponentTeamId: "5111487",
        opponent: "1eca98f2-4954-65b6-9c44-1b057f6992fc",
        playerTeam: [
          {
            gene: "0x80000000000010001202000831000000001001428a1440a0001000428204502000100001840440c00010014308141040001001018004202000100101020c404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749307,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000812070820c000000010014286144020001000428604208000100001801800600010014302081040001001010014006000100101000430c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11741527,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070830800000001001428a105080001000820610408000100001861840c000100102880c40c000100101060c2020001001010218104",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2018_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11731224,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100014040e0c30c00000001001428a1450a0001000428a08004000100001800c0060001001430a1850c000100002880c00a000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11751686,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000812070c00800000001001428a1410400010008208082080001000018a1450a000100103040c40a000100101880c40c000100101001040a",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11737068,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001c00070c00000000001001428a10108000100102840840a00010000186104020001001008818406000100101880c4060001001010404404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2011_s4",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11739206,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2014,
        opponentMmrAfter: 2004,
        opponentSlpWon: 0,
        playerMmrBefore: 1998,
        playerMmrAfter: 2008,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d6caf2d5-b1de-4127-958c-451837611338",
        gameTimestamp: "2023-05-29 10:25:48",
        startTimestamp: "2023-05-29 10:21:05",
        endTimestamp: "2023-05-29 10:25:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d6caf2d5-b1de-4127-958c-451837611338&userId=1ec9eb7e-6006-6e83-a60c-56b24ebed2d4",
        battleType: "pvp",
        playerSlpWon: 22,
        playerTeamId: "5100606",
        oponentTeamId: "5109105",
        opponent: "1ec9eb7e-5bb0-6bde-a60c-534f3b09ec86",
        playerTeam: [
          {
            gene: "0x80000000000010001202000831000000001001428a1440a0001000428204502000100001840440c00010014308141040001001018004202000100101020c404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11749307,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000812070820c000000010014286144020001000428604208000100001801800600010014302081040001001010014006000100101000430c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11741527,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010002400070830800000001001428a105080001000820610408000100001861840c000100102880c40c000100101060c2020001001010218104",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2018_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_3010_s4_nft",
            },
            axie_id: 11731224,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000380a0e042080000000100142821050a0001000428208408000100001800c108000100143081450c00010000102180020001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2011_s4",
            },
            axie_id: 11707941,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100014120e0820c00000001001428a1430a000100042821410a00010000186180060001001430a1850c0001000028a0820a0001001010808008",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1011_s4",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11751647,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340c0e1020800000001001428a144080001000428410408000100001800c1060001000010a18408000100002880c4060001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1004",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11753585,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1985,
        opponentMmrAfter: 1976,
        opponentSlpWon: 0,
        playerMmrBefore: 1989,
        playerMmrAfter: 1998,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:02.000Z",
  },
  {
    clientId: "1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
    name: "JMike | Xenkicks Shoes | GCPH",
    rank: "Challenger",
    tier: 0,
    topRank: 90,
    vstar: 2030,
    avatar: "0;paschal_eggs",
    battles: [
      {
        battleUuid: "4ade5dd6-f3b9-4f0d-8d36-7ed78f01f6cf",
        gameTimestamp: "2023-05-29 01:21:37",
        startTimestamp: "2023-05-29 01:15:59",
        endTimestamp: "2023-05-29 01:21:37",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=4ade5dd6-f3b9-4f0d-8d36-7ed78f01f6cf&userId=1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095631",
        oponentTeamId: "5110419",
        opponent: "1ec9eb6f-8c91-652f-a60c-bda9e6416d0d",
        playerTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010002c0e05082080000000100142841020a0001001028810104000100001800c006000100143021450c00010004104044060001001010808302",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11730485,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000380a020c30c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c402000100101041010c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743627,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008080821000000001001428804508000100042840800a00010000180102080001000c2821840200010000286145020001000008808404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11750408,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2034,
        opponentMmrAfter: 2024,
        opponentSlpWon: 0,
        playerMmrBefore: 2020,
        playerMmrAfter: 2030,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "de394027-78e1-4b3c-b5ec-b6b3e50915f8",
        gameTimestamp: "2023-05-29 00:25:38",
        startTimestamp: "2023-05-29 00:21:02",
        endTimestamp: "2023-05-29 00:25:38",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=de394027-78e1-4b3c-b5ec-b6b3e50915f8&userId=1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095631",
        oponentTeamId: "5102209",
        opponent: "1ec9eb6f-5291-633f-a60c-e8a1200f6b71",
        playerTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002412090821000000001000010a04004000100002861420a000100001821000600010008284140020001000830418104000100000800420a",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_2011_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11688572,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101204083000000000100082041020800010008284140080001000818408206000100083060800a0001000830410108000100081041420c",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "ecard_bird_3012_s4_nondec",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_neutral_2012_s4",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11689137,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100030160b0c20800000001001408a040040001000c086083020001000c2021830c0001000c28a0830a0001000c1861010c0001000c10610306",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_3012_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9602078,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1794,
        opponentMmrAfter: 1790,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2020,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "5d13e155-a1b5-42d4-9cc1-7d66ea8e8116",
        gameTimestamp: "2023-05-29 00:18:01",
        startTimestamp: "2023-05-29 00:13:51",
        endTimestamp: "2023-05-29 00:18:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5d13e155-a1b5-42d4-9cc1-7d66ea8e8116&userId=1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095631",
        oponentTeamId: "5104411",
        opponent: "1ec9eb6f-8326-6f48-a60c-da2745238d9b",
        playerTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100010160b0820800000001000410204102000100042821410a000100001800c402000100042860810a00010004100141080001000408208102",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11745279,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000081c0000210000000010000100080040001000028014404000100001880800c000100000800800200010000088140020001000c10004002",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11578177,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000040e050841000000001000010008004000100000880400a000100001000800c000100000800400200010000082102080001000028a0c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_buba_1",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11547695,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2011,
        opponentMmrAfter: 2001,
        opponentSlpWon: 0,
        playerMmrBefore: 2006,
        playerMmrAfter: 2016,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8bf36ca9-a096-462d-b90b-c1f97d55aed3",
        gameTimestamp: "2023-05-29 00:13:12",
        startTimestamp: "2023-05-29 00:10:45",
        endTimestamp: "2023-05-29 00:13:12",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8bf36ca9-a096-462d-b90b-c1f97d55aed3&userId=1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5095631",
        oponentTeamId: "4986314",
        opponent: "1ec9eb7b-2e84-6630-a60c-f999b17e99fe",
        playerTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x88000000000001000080a050c20800000001001428a1450a0001000428214004000100001800c0060001001430a1850c00010010184144040001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11715420,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003812060820000000001001428a1420a0001000428214108000100001880c00600010014300083080001000028014406000100101080820c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11742555,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008060821000000001001428a142020001000820008004000100001800c006000100103021850c00010010186183060001001010808404",
            runes: ["rune_mech_3012_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11733285,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1966,
        opponentMmrAfter: 1957,
        opponentSlpWon: 0,
        playerMmrBefore: 1997,
        playerMmrAfter: 2006,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "fae43855-5fc0-44bf-bdcc-fcb9e092e387",
        gameTimestamp: "2023-05-29 00:10:08",
        startTimestamp: "2023-05-29 00:04:20",
        endTimestamp: "2023-05-29 00:10:08",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=fae43855-5fc0-44bf-bdcc-fcb9e092e387&userId=1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5095631",
        oponentTeamId: "5066884",
        opponent: "1ec9eb73-8772-6185-a60c-ef4fcbf9cbe5",
        playerTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a0001000428204002000100001800c50c000100100821450800010000282085080001001010a08404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11733343,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000081a050c30c00000001001428a1450a000100042881040200010000180140060001001430a1810c000100101880c20a0001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11721664,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001008091020800000001001428a1430a00010010288104080001000018618502000100100820850a0001001018810106000100101041010c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11685968,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2050,
        opponentMmrAfter: 2058,
        opponentSlpWon: 24,
        playerMmrBefore: 2005,
        playerMmrAfter: 1997,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:36:02.000Z",
  },
  {
    clientId: "1ec9eb6f-9de6-6c41-a60c-ba566ce16cfe",
    name: "SPR | Ghops",
    rank: "Challenger",
    tier: 0,
    topRank: 91,
    vstar: 2030,
    avatar: "patrick23_beer;gold_s1",
    battles: [
      {
        battleUuid: "b2badfcb-24ae-48ad-ac69-e75820b761e4",
        gameTimestamp: "2023-05-29 12:40:44",
        startTimestamp: "2023-05-29 12:34:18",
        endTimestamp: "2023-05-29 12:40:44",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=b2badfcb-24ae-48ad-ac69-e75820b761e4&userId=1ec9eb6f-9de6-6c41-a60c-ba566ce16cfe",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5018737",
        oponentTeamId: "5092268",
        opponent: "1ec9eb6f-5f56-65a9-a60c-754d940f41d2",
        playerTeam: [
          {
            gene: "0x80000000000010001c04000c000000000010010108081040001000428808408000100001800c0060001000410404404000100100820c10a000100000800c404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11706065,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120408208000000010008204104080001000828804402000100081840c206000100083061020c000100083041820c0001000810210508",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3012_s4_nft",
            },
            axie_id: 11712604,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100600180110008000000010008106082040001000c10608304000100001880c006000100042861410c0001000c1861810c0001000c10618306",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 9824952,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001816060c3080000000100040821410200010004080141020001000018218006000100043041410c0001000028408108000100040820420a",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11616573,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000080441000000001001410a043080001000408a08504000100042841400c0001001420a1050800010014204101060001000410a04206",
            runes: ["rune_neutral_2011_s4"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_3011_s4_nft",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "ecard_neutral_3013_s4_nondec",
            },
            axie_id: 11615013,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x28000000000001000200a0e0c30400000001000c08a042020001000c28214004000100001800c30a0001000c18804506000100040840c504000100041081040a",
            runes: ["rune_reptile_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_2017_s4",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11709466,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1985,
        opponentMmrAfter: 1977,
        opponentSlpWon: 0,
        playerMmrBefore: 2022,
        playerMmrAfter: 2030,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "af3bab6c-06e8-4712-9163-c5a4ea830997",
        gameTimestamp: "2023-05-29 03:51:44",
        startTimestamp: "2023-05-29 03:46:42",
        endTimestamp: "2023-05-29 03:51:44",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=af3bab6c-06e8-4712-9163-c5a4ea830997&userId=1ec9eb6f-9de6-6c41-a60c-ba566ce16cfe",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5018737",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x80000000000010001c04000c000000000010010108081040001000428808408000100001800c0060001000410404404000100100820c10a000100000800c404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11706065,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120408208000000010008204104080001000828804402000100081840c206000100083061020c000100083041820c0001000810210508",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3012_s4_nft",
            },
            axie_id: 11712604,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100600180110008000000010008106082040001000c10608304000100001880c006000100042861410c0001000c1861810c0001000c10618306",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 9824952,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2001,
        opponentMmrAfter: 1992,
        opponentSlpWon: 0,
        playerMmrBefore: 2013,
        playerMmrAfter: 2022,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "360cf675-4e9c-4ab3-b1ee-dc8ef76adef6",
        gameTimestamp: "2023-05-29 03:46:01",
        startTimestamp: "2023-05-29 03:41:48",
        endTimestamp: "2023-05-29 03:46:01",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=360cf675-4e9c-4ab3-b1ee-dc8ef76adef6&userId=1ec9eb6f-9de6-6c41-a60c-ba566ce16cfe",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5018737",
        oponentTeamId: "5065158",
        opponent: "1ec9eb7e-6aeb-6615-a60c-af7f58ecce17",
        playerTeam: [
          {
            gene: "0x80000000000010001c04000c000000000010010108081040001000428808408000100001800c0060001000410404404000100100820c10a000100000800c404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11706065,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120408208000000010008204104080001000828804402000100081840c206000100083061020c000100083041820c0001000810210508",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3012_s4_nft",
            },
            axie_id: 11712604,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100600180110008000000010008106082040001000c10608304000100001880c006000100042861410c0001000c1861810c0001000c10618306",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 9824952,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000081a020c20c00000001001428604308000100042821420a000100001800c0060001001430a1810a000100002841420c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11736271,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001808060820c00000001001428a1450a000100042881410a000100001841820c000100143021040c000100101880c0040001001010818404",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11730443,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000140a0b0800c00000001001428a145020001000820410402000100001841850a0001001030a08304000100101880c102000100101080800c",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11729669,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2032,
        opponentMmrAfter: 2022,
        opponentSlpWon: 0,
        playerMmrBefore: 2003,
        playerMmrAfter: 2013,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d695dda4-4725-4965-971f-437de568ef0b",
        gameTimestamp: "2023-05-29 03:41:20",
        startTimestamp: "2023-05-29 03:36:34",
        endTimestamp: "2023-05-29 03:41:20",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d695dda4-4725-4965-971f-437de568ef0b&userId=1ec9eb6f-9de6-6c41-a60c-ba566ce16cfe",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5018737",
        oponentTeamId: "5102797",
        opponent: "1ec9eb6f-9b34-68e1-a60c-5e42d2af228f",
        playerTeam: [
          {
            gene: "0x80000000000010001c04000c000000000010010108081040001000428808408000100001800c0060001000410404404000100100820c10a000100000800c404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11706065,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120408208000000010008204104080001000828804402000100081840c206000100083061020c000100083041820c0001000810210508",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3012_s4_nft",
            },
            axie_id: 11712604,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100600180110008000000010008106082040001000c10608304000100001880c006000100042861410c0001000c1861810c0001000c10618306",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 9824952,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000300000082000000000100041061430200010008080145020001001020210408000100042821410a00010004102041040001001418010406",
            runes: ["rune_neutral_3012_s4"],
            charms: {
              back: "ecard_neutral_3011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_bug_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11725478,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100030060c082100000000100041000440800010008084042020001001020810408000100100821440c000100081840c3080001000008004002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "ecard_neutral_3012_s4",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11715044,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x2000000000000100000120b0c40800000001000c082081080001000808404508000100102041040a000100102841840a00010008184082060001001018408406",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "ecard_neutral_2017_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_3012_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11686088,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1958,
        opponentMmrAfter: 1950,
        opponentSlpWon: 0,
        playerMmrBefore: 1995,
        playerMmrAfter: 2003,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "dee2af94-1185-4cef-b77e-815fa3c4a948",
        gameTimestamp: "2023-05-29 03:35:57",
        startTimestamp: "2023-05-29 03:28:04",
        endTimestamp: "2023-05-29 03:35:57",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=dee2af94-1185-4cef-b77e-815fa3c4a948&userId=1ec9eb6f-9de6-6c41-a60c-ba566ce16cfe",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5018737",
        oponentTeamId: "5089018",
        opponent: "1ececc3e-eebd-6451-a3cb-8184c64efdbd",
        playerTeam: [
          {
            gene: "0x80000000000010001c04000c000000000010010108081040001000428808408000100001800c0060001000410404404000100100820c10a000100000800c404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_neutral_2019_s4_nft",
            },
            axie_id: 11706065,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100024120408208000000010008204104080001000828804402000100081840c206000100083061020c000100083041820c0001000810210508",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_3012_s4_nft",
              eyes: "ecard_bird_3011_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_3012_s4_nft",
            },
            axie_id: 11712604,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x1800000000000100600180110008000000010008106082040001000c10608304000100001880c006000100042861410c0001000c1861810c0001000c10618306",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 9824952,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100038120b0040000000001000410a1410400010008084142040001001020810506000100102880c006000100081840c00c000100101880c408",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_bird_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11702196,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001000040840800000001000410a0850400010008084042020001001020810404000100103080410800010008182082080001000008218002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2013_s4_nondec",
            },
            axie_id: 11728287,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000080e090c00c000000010004100102020001000808404108000100102081030a000100042861810a0001000410208104000100101880c404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2011_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11730626,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1986,
        opponentMmrAfter: 1996,
        opponentSlpWon: 22,
        playerMmrBefore: 2005,
        playerMmrAfter: 1995,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:36:03.000Z",
  },
  {
    clientId: "1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
    name: "CO | adrd",
    rank: "Challenger",
    tier: 0,
    topRank: 92,
    vstar: 2029,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "3f4f65f0-6334-44b0-890f-49bca0cb9ec5",
        gameTimestamp: "2023-05-29 13:30:46",
        startTimestamp: "2023-05-29 13:25:37",
        endTimestamp: "2023-05-29 13:30:46",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3f4f65f0-6334-44b0-890f-49bca0cb9ec5&userId=1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104175",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001412080820c0000000100000800400400010000282144080001000018414506000100103000440c00010010080042080001000008a0430c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750424,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2065,
        opponentMmrAfter: 2054,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2029,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "21c035df-8b74-42ff-807d-bc62cc8e9186",
        gameTimestamp: "2023-05-29 13:24:56",
        startTimestamp: "2023-05-29 13:20:11",
        endTimestamp: "2023-05-29 13:24:56",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=21c035df-8b74-42ff-807d-bc62cc8e9186&userId=1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5104175",
        oponentTeamId: "5056612",
        opponent: "1ec9eb6f-4189-6498-a60c-76b150e5a03e",
        playerTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001412080820c0000000100000800400400010000282144080001000018414506000100103000440c00010010080042080001000008a0430c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750424,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100018080e0c2080000000100142861450a0001000428204102000100001800c006000100143041810a0001000410a081080001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11730520,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003812020820800000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c20a0001001010218008",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11743574,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000000009082080000000100142880450a0001001028810508000100001800c00600010010300102020001001018a144060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_puffy_1",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11734171,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2008,
        opponentMmrAfter: 1998,
        opponentSlpWon: 0,
        playerMmrBefore: 2008,
        playerMmrAfter: 2018,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "61e39ce2-996d-4352-b104-4fe2e6ecae91",
        gameTimestamp: "2023-05-29 13:18:15",
        startTimestamp: "2023-05-29 13:13:46",
        endTimestamp: "2023-05-29 13:18:15",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=61e39ce2-996d-4352-b104-4fe2e6ecae91&userId=1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104175",
        oponentTeamId: "5108521",
        opponent: "1ed65bf6-c991-663b-b49e-940ffd623333",
        playerTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001412080820c0000000100000800400400010000282144080001000018414506000100103000440c00010010080042080001000008a0430c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750424,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010000c040e0820800000001000410208304000100080860820200010010202141080001000428814104000100081840c304000100101880c406",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_aquatic_3010_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11711649,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000c0603082080000000100041020830800010008084042020001001020a08408000100103060840c000100081840c206000100000800440c",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11745112,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001006001c080c40000000001000c0860420a00010008084042020001001020810206000100042821410c0001000410804002000100101880c406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_3011_s4_nondec",
            },
            axie_id: 11748430,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2013,
        opponentMmrAfter: 2023,
        opponentSlpWon: 24,
        playerMmrBefore: 2018,
        playerMmrAfter: 2008,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "cd4a1b76-7109-42c4-826c-17442dae77ff",
        gameTimestamp: "2023-05-29 13:12:52",
        startTimestamp: "2023-05-29 13:08:26",
        endTimestamp: "2023-05-29 13:12:52",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cd4a1b76-7109-42c4-826c-17442dae77ff&userId=1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104175",
        oponentTeamId: "5101048",
        opponent: "1eca7faf-813a-6bd2-9fe9-581dcef10f70",
        playerTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001412080820c0000000100000800400400010000282144080001000018414506000100103000440c00010010080042080001000008a0430c",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750424,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x10001410021020800000001000c200080040001000028004002000100001880c5020001000010418004000100000840c3060001000008004204",
            runes: ["rune_beast_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11518730,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008040b0801000000001000010a140040001000020004008000100003001800c00010000080040020001000008004002000100001800c506",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_2012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11591470,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000180e061031000000001000c086043040001000c088083020001000c2061830a0001000c2860c4080001000c3020430c0001000c10610102",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nondec",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "",
            },
            axie_id: 416844,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1996,
        opponentMmrAfter: 2006,
        opponentSlpWon: 24,
        playerMmrBefore: 2028,
        playerMmrAfter: 2018,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "d584b386-c32d-4a82-99dd-87af7a2ff9c8",
        gameTimestamp: "2023-05-29 13:05:21",
        startTimestamp: "2023-05-29 13:01:36",
        endTimestamp: "2023-05-29 13:05:21",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d584b386-c32d-4a82-99dd-87af7a2ff9c8&userId=1ec9eb6f-5088-6bd5-a60c-507ac766eb66",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5104175",
        oponentTeamId: "5108093",
        opponent: "1ec9eb6f-3ff2-6493-a60c-25d25af65861",
        playerTeam: [
          {
            gene: "0x800000000000010002c0e0d0820400000001000420004208000100002801430a0001000018804408000100041041440c0001000c3080c4060001000c1080830a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nondec",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11750438,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0c08103000000000100002001440400010000280142080001000018018006000100041041430c00010008204180040001000008618502",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11699815,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c04081041000000001000010414008000100000801410a0001000010004002000100000841040c00010000300040020001000028814008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3011_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_beast_3010_s4_nft",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11741172,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000080a0a0840800000001001428a0820800010004284100040001000018414006000100143041450c000100101080c5080001001010808404",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11748739,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8800000000000100008080d0c10c00000001001428a144040001000428214208000100001800c2080001001430a185020001000028018206000100101080810c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11752301,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000180c06082080000000100142821050a0001000428014102000100001800c4080001001008a181040001000028014102000100101080820a",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1011_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11734392,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2052,
        opponentMmrAfter: 2061,
        opponentSlpWon: 24,
        playerMmrBefore: 2037,
        playerMmrAfter: 2028,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:36:04.000Z",
  },
  {
    clientId: "1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
    name: "Nesuro",
    rank: "Challenger",
    tier: 0,
    topRank: 93,
    vstar: 2029,
    avatar: "13;silver_s1",
    battles: [
      {
        battleUuid: "057ec81d-51a6-4894-9e1e-916acef2402b",
        gameTimestamp: "2023-05-28 23:55:48",
        startTimestamp: "2023-05-28 23:50:59",
        endTimestamp: "2023-05-28 23:55:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=057ec81d-51a6-4894-9e1e-916acef2402b&userId=1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109700",
        oponentTeamId: "5095631",
        opponent: "1ec9eb6f-9a2b-6b64-a60c-e724e3e042c9",
        playerTeam: [
          {
            gene: "0x800000000000100010080b0830800000001001428a142040001000428204008000100001800c0060001001430a1850c000100002820840c000100101080850c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11747091,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a0e0c40800000001001428a1410a000100042821410a000100001800c0060001001430a1850c0001001018a0c20a0001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751757,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008070c20800000001001428a0400a0001000428210208000100001800c3060001001020a1840c0001000028204406000100101080830c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746428,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001816060830800000001001428a14002000100042861010a000100001800c006000100143081840a00010004108104060001001010814404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nondec",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11748021,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000100c000800c00000001001428a1450a0001000820414204000100001800c402000100103080400800010010180183040001001010a10404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11742120,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060c01000000001001428a145080001000820a1450a0001000018618508000100103001810a000100101880c5080001001010808508",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_1010_s4",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745822,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2010,
        opponentMmrAfter: 2001,
        opponentSlpWon: 0,
        playerMmrBefore: 2020,
        playerMmrAfter: 2029,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8825060e-af3b-46ae-a474-e797a79841d9",
        gameTimestamp: "2023-05-28 23:50:25",
        startTimestamp: "2023-05-28 23:42:36",
        endTimestamp: "2023-05-28 23:50:25",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8825060e-af3b-46ae-a474-e797a79841d9&userId=1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109700",
        oponentTeamId: "3731078",
        opponent: "1ec9eb6f-552d-686e-a60c-af42d65ded08",
        playerTeam: [
          {
            gene: "0x800000000000100010080b0830800000001001428a142040001000428204008000100001800c0060001001430a1850c000100002820840c000100101080850c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11747091,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a0e0c40800000001001428a1410a000100042821410a000100001800c0060001001430a1850c0001001018a0c20a0001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751757,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008070c20800000001001428a0400a0001000428210208000100001800c3060001001020a1840c0001000028204406000100101080830c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746428,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001c00030820c00000001000410a04504000100080840410400010010208104060001001418a1410a0001000818810206000100101820840a",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11727165,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003810020830800000001000410810104000100080840400200010010204104080001000830418308000100081840c2060001000008618002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "",
              horn: "ecard_bird_2013_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11727614,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000240f8004300000000010004204105080001000808a081040001001020810204000100102841040a000100101840c2060001001018408108",
            runes: ["rune_dusk_2011_s4_nondec"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11656929,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1966,
        opponentMmrAfter: 1958,
        opponentSlpWon: 0,
        playerMmrBefore: 2012,
        playerMmrAfter: 2020,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8e558826-058c-4265-867d-d5732e21bb2a",
        gameTimestamp: "2023-05-28 23:41:16",
        startTimestamp: "2023-05-28 23:34:43",
        endTimestamp: "2023-05-28 23:41:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8e558826-058c-4265-867d-d5732e21bb2a&userId=1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109700",
        oponentTeamId: "5108082",
        opponent: "1ed2dedf-29a6-6858-b5d4-2e25ee4c09c0",
        playerTeam: [
          {
            gene: "0x800000000000100010080b0830800000001001428a142040001000428204008000100001800c0060001001430a1850c000100002820840c000100101080850c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11747091,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a0e0c40800000001001428a1410a000100042821410a000100001800c0060001001430a1850c0001001018a0c20a0001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751757,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008070c20800000001001428a0400a0001000428210208000100001800c3060001001020a1840c0001000028204406000100101080830c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746428,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001404090830c00000001001428a1450a000100042821410a000100001800c0060001001430a1850c000100002880c4060001001010808208",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751748,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010003804020c30c00000001001428a1450a000100042821410a000100001800c4020001001430a0450c000100002880c402000100101041050c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11751728,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010003808070c20800000001001428a1450a000100042841010a000100001800c4020001000428a1050200010004106044040001001010418404",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3013_s4_nft",
            },
            axie_id: 11713087,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2039,
        opponentMmrAfter: 2028,
        opponentSlpWon: 0,
        playerMmrBefore: 2001,
        playerMmrAfter: 2012,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "238b8465-7d96-436c-bdf6-83d7d4aca03a",
        gameTimestamp: "2023-05-28 23:27:08",
        startTimestamp: "2023-05-28 23:20:20",
        endTimestamp: "2023-05-28 23:27:08",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=238b8465-7d96-436c-bdf6-83d7d4aca03a&userId=1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5109700",
        oponentTeamId: "5043888",
        opponent: "1ec9eb6f-4cdd-629d-a60c-d32c4bf89b31",
        playerTeam: [
          {
            gene: "0x800000000000100010080b0830800000001001428a142040001000428204008000100001800c0060001001430a1850c000100002820840c000100101080850c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11747091,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a0e0c40800000001001428a1410a000100042821410a000100001800c0060001001430a1850c0001001018a0c20a0001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751757,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008070c20800000001001428a0400a0001000428210208000100001800c3060001001020a1840c0001000028204406000100101080830c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746428,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x18000000000001000140a05082080000000100081060800a00010010200080040001000c20410402000100141020c504000100082880c1080001000c1060c30c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_bird_3013_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11313777,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000000000001000240e02083100000000100000801030a0001000c1001440800010000188103040001000830404008000100001880c0060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nondec",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11605964,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c1204082080000000100082041050a0001000828414402000100081840c3080001000830418106000100083041850a0001000810408208",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "ecard_bird_2012_s4_nft",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11704641,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2034,
        opponentMmrAfter: 2043,
        opponentSlpWon: 24,
        playerMmrBefore: 2010,
        playerMmrAfter: 2001,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "d7d67211-30f9-4a90-859a-c9d8f2d3e16a",
        gameTimestamp: "2023-05-28 23:18:48",
        startTimestamp: "2023-05-28 23:14:07",
        endTimestamp: "2023-05-28 23:18:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d7d67211-30f9-4a90-859a-c9d8f2d3e16a&userId=1ec9eb7e-41e5-62ee-a60c-15166470e6d1",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5109700",
        oponentTeamId: "5084757",
        opponent: "1ec9eb73-7fc6-6c3d-a60c-f4e57fa58910",
        playerTeam: [
          {
            gene: "0x800000000000100010080b0830800000001001428a142040001000428204008000100001800c0060001001430a1850c000100002820840c000100101080850c",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11747091,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000140a0e0c40800000001001428a1410a000100042821410a000100001800c0060001001430a1850c0001001018a0c20a0001001010808208",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751757,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001008070c20800000001001428a0400a0001000428210208000100001800c3060001001020a1840c0001000028204406000100101080830c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746428,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001005010c30800000001001428a141080001000428a0410a000100001800c0060001000410a1850c000100002801430a0001001010808404",
            runes: ["rune_bug_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nondec",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11740192,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000100a070820800000001001428a14108000100082080840a000100001800c206000100143081840c000100101880c4060001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11713068,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002400000820c00000001001428a1400a000100102081440a0001000018a102080001001030818006000100001080c406000100101080810a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11708043,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1931,
        opponentMmrAfter: 1924,
        opponentSlpWon: 0,
        playerMmrBefore: 2003,
        playerMmrAfter: 2010,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:04.000Z",
  },
  {
    clientId: "1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
    name: "Krovarak",
    rank: "Challenger",
    tier: 0,
    topRank: 94,
    vstar: 2029,
    avatar: "shy;perch",
    battles: [
      {
        battleUuid: "3d34ac8a-6ace-4235-8f1f-a6496921b168",
        gameTimestamp: "2023-05-29 01:27:24",
        startTimestamp: "2023-05-29 01:22:37",
        endTimestamp: "2023-05-29 01:27:24",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3d34ac8a-6ace-4235-8f1f-a6496921b168&userId=1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5079651",
        oponentTeamId: "5051435",
        opponent: "1ec9eb6f-82c3-66b2-a60c-c18afe3f61bf",
        playerTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000101c080410c00000001001428a142080001000428214302000100001800c108000100143000c50a000100101880c4080001001010818404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1010_s4",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 11732160,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000c0e0e08308000000010014284102020001000010814404000100001800c0060001001030410204000100101820800a0001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713352,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000000100018000d0c00000000001001428a14202000100102840840a000100001861000600010010088181040001000410a084060001001010808408",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11730872,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2089,
        opponentMmrAfter: 2077,
        opponentSlpWon: 0,
        playerMmrBefore: 2017,
        playerMmrAfter: 2029,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d2ea009f-667e-4acb-a266-e47e16bb75a7",
        gameTimestamp: "2023-05-28 23:10:06",
        startTimestamp: "2023-05-28 23:03:38",
        endTimestamp: "2023-05-28 23:10:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d2ea009f-667e-4acb-a266-e47e16bb75a7&userId=1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5079651",
        oponentTeamId: "4980387",
        opponent: "1ec9eb6f-5036-6c3c-a60c-96cbec80195c",
        playerTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x2000000000000100030080208300000000010004100083040001000808a084040001001020a08408000100042821440c0001000818018102000100101880c406",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11676686,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000301c00082080000000100041020850400010008084042020001001020810306000100103080440c000100081861430a0001000008004002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2017_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11711740,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010001c0c0d0821000000001000c082082040001000808404202000100102060800200010004288104060001000410810206000100101880c406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_2017_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11711094,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1920,
        opponentMmrAfter: 1913,
        opponentSlpWon: 0,
        playerMmrBefore: 2010,
        playerMmrAfter: 2017,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "59872ece-f1a5-409d-8845-b6efd0fd690d",
        gameTimestamp: "2023-05-28 22:59:25",
        startTimestamp: "2023-05-28 22:55:16",
        endTimestamp: "2023-05-28 22:59:25",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=59872ece-f1a5-409d-8845-b6efd0fd690d&userId=1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5079651",
        oponentTeamId: "5096276",
        opponent: "1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        playerTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1999,
        opponentMmrAfter: 2009,
        opponentSlpWon: 0,
        playerMmrBefore: 2020,
        playerMmrAfter: 2010,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "462de696-193a-4ccb-9564-1041aba75929",
        gameTimestamp: "2023-05-28 22:48:15",
        startTimestamp: "2023-05-28 22:43:53",
        endTimestamp: "2023-05-28 22:48:15",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=462de696-193a-4ccb-9564-1041aba75929&userId=1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5079651",
        oponentTeamId: "5103251",
        opponent: "1ecf8a01-918f-62b5-914b-01d0ef517332",
        playerTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100404050108308000000010014286101080001000428208504000100001800c0060001001430a1400800010010180101020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11719122,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001c160d0800800000001001428a082080001000820814208000100001800c50a000100103081010a0001001018014406000100101041840c",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11746160,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a060820000000001001428a1450a0001000820a14104000100001800c002000100042881810a000100101860c00a0001001010808404",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2011_s4_nft",
            },
            axie_id: 11713792,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1982,
        opponentMmrAfter: 1973,
        opponentSlpWon: 0,
        playerMmrBefore: 2011,
        playerMmrAfter: 2020,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "dfb44bc0-ba9d-4da1-a1bf-c15381343e57",
        gameTimestamp: "2023-05-28 22:43:24",
        startTimestamp: "2023-05-28 22:39:43",
        endTimestamp: "2023-05-28 22:43:24",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=dfb44bc0-ba9d-4da1-a1bf-c15381343e57&userId=1ec9eb6f-5215-63bc-a60c-f29edb86bc49",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5079651",
        oponentTeamId: "5109692",
        opponent: "1ec9eb6f-6a3f-6fbc-a60c-95cb432bcef6",
        playerTeam: [
          {
            gene: "0x800000000000100024040200010000000010004202040080001000028014408000100001800c40c00010008284140040001000c280045040001000018218006",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2017_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_beast_3013_s4_nondec",
            },
            axie_id: 11675897,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080402082080000000100001000800a0001001028014204000100000801800c000100000800400a00010000300040020001000018608006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "ecard_beast_2011_s4_nft",
              eyes: "ecard_beast_3010_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_beast_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11539369,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000280a0c0810800000001000c1060410400010014288043080001000c30610208000100043021830600010010086082060001000c10204302",
            runes: ["rune_plant_2010_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_2020_s4_nft",
              tail: "ecard_plant_3013_s4_nft",
              mouth: "ecard_reptile_2011_s4_nft",
            },
            axie_id: 2405588,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010001412050840c00000001001428a1450a000100042860810a000100001800c0060001001430a1850c000100101820c2060001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728318,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000808080c30c00000001001428a1450a00010004288104020001000018014006000100143081050200010000282084040001001010808204",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_reptile_3012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11728381,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001816050820800000001001428a0850a0001000428a14102000100001800c006000100042820850800010000282081040001001010808102",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11741691,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1952,
        opponentMmrAfter: 1964,
        opponentSlpWon: 22,
        playerMmrBefore: 2023,
        playerMmrAfter: 2011,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
    ],
    lastUpdated: "2023-05-29T13:36:05.000Z",
  },
  {
    clientId: "1ec9eb6f-802b-6048-a60c-130d73bcda97",
    name: "Pacch",
    rank: "Challenger",
    tier: 0,
    topRank: 95,
    vstar: 2028,
    avatar: "shy;rainbow",
    battles: [
      {
        battleUuid: "6ed4bbe1-6bbd-4186-b342-76378ed6489a",
        gameTimestamp: "2023-05-29 01:46:47",
        startTimestamp: "2023-05-29 01:46:17",
        endTimestamp: "2023-05-29 01:46:47",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=6ed4bbe1-6bbd-4186-b342-76378ed6489a&userId=1ec9eb6f-802b-6048-a60c-130d73bcda97",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5110401",
        oponentTeamId: "4826699",
        opponent: "1eca409e-7d81-62c6-be45-6c390f5dd231",
        playerTeam: [
          {
            gene: "0x88000000000001000240c060c2100000000100001001420a00010000280143080001000018610208000100082001820800010008308180080001000008004006",
            runes: ["rune_dawn_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_bird_2012_s4_nft",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11652143,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001009800c20800000001000410204202000100042821410a000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734306,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804070820800000001000010a1000400010000200100020001000030a0430a000100000800430400010000084183040001000020a0c008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11568599,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x180000000000010000012040041000000001000c10604302000100042860430400010004286182080001000c2860c30c000100003060800a0001000c08604302",
            runes: ["rune_neutral_2011_s4"],
            charms: {
              back: "ecard_neutral_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_plant_3012_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_bug_2013_s4_nondec",
            },
            axie_id: 4338088,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x2800000000000100018100b04310000000010004208040080001001410a080020001000c1860440600010014202101060001001420a105080001001418a0c204",
            runes: ["rune_neutral_2010_s4"],
            charms: {
              back: "ecard_reptile_2012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_reptile_2012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 11279474,
            position: 4,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0405082080000000100001061440800010000200101080001000030608402000100000801440a000100000800450a000100141801810a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_buba_1",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11549777,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "d02f32d0-d36a-42d2-8543-8d95ddf6e172",
        gameTimestamp: "2023-05-28 21:52:38",
        startTimestamp: "2023-05-28 21:49:53",
        endTimestamp: "2023-05-28 21:52:38",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=d02f32d0-d36a-42d2-8543-8d95ddf6e172&userId=1ec9eb6f-802b-6048-a60c-130d73bcda97",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5088745",
        oponentTeamId: "5015645",
        opponent: "1edc6dba-4dd8-602a-b124-ed86da0c227a",
        playerTeam: [
          {
            gene: "0x900000000000010002c1c090840000000001000010414104000100002801400a0001000018210006000100101801400c00010004102081040001000008004102",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11726128,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001009800c20800000001000410204202000100042821410a000100001800c006000100042821410a00010004102141040001000408814108",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11734306,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804070820800000001000010a1000400010000200100020001000030a0430a000100000800430400010000084183040001000020a0c008",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11568599,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x880000000000010002000001040c00000001000c08408304000100042860450a00010004282145060001001420a0c2080001001420614508000100042840c102",
            runes: ["rune_neutral_2011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11633518,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000201008103000000000100141060430800010010086141040001000018a0c10a0001000430a10506000100142021810c0001000408208208",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11705923,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c0e1020800000001000c20a082080001000410614108000100001800c00600010004302183040001000408a105080001000410808306",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11735633,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9d9a8bc9-d61e-4682-942f-444f85533fb2",
        gameTimestamp: "2023-05-28 21:39:19",
        startTimestamp: "2023-05-28 21:37:07",
        endTimestamp: "2023-05-28 21:39:19",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9d9a8bc9-d61e-4682-942f-444f85533fb2&userId=1ec9eb6f-802b-6048-a60c-130d73bcda97",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5088745",
        oponentTeamId: "5015645",
        opponent: "1edc6dba-4dd8-602a-b124-ed86da0c227a",
        playerTeam: [
          {
            gene: "0x900000000000010002c1c090840000000001000010414104000100002801400a0001000018210006000100101801400c00010004102081040001000008004102",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11726128,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001009800c20800000001000410204202000100042821410a000100001800c006000100042821410a00010004102141040001000408814108",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11734306,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804070820800000001000010a1000400010000200100020001000030a0430a000100000800430400010000084183040001000020a0c008",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11568599,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x880000000000010002000001040c00000001000c08408304000100042860450a00010004282145060001001420a0c2080001001420614508000100042840c102",
            runes: ["rune_dawn_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1004",
              ears: "",
              eyes: "",
              horn: "ecard_neutral_1004",
              tail: "ecard_neutral_1004",
              mouth: "",
            },
            axie_id: 11633518,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000201008103000000000100141060430800010010086141040001000018a0c10a0001000430a10506000100142021810c0001000408208208",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "ecard_reptile_3011_s4_nft",
              ears: "",
              eyes: "",
              horn: "",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "",
            },
            axie_id: 11705923,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c0e1020800000001000c20a082080001000410614108000100001800c00600010004302183040001000408a105080001000410808306",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_1004",
              ears: "",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_1004",
              mouth: "",
            },
            axie_id: 11735633,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "34c51eec-283d-46bd-aea2-ac05a407a32f",
        gameTimestamp: "2023-05-28 21:34:55",
        startTimestamp: "2023-05-28 21:33:33",
        endTimestamp: "2023-05-28 21:34:55",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=34c51eec-283d-46bd-aea2-ac05a407a32f&userId=1ec9eb6f-802b-6048-a60c-130d73bcda97",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5088745",
        oponentTeamId: "5015645",
        opponent: "1edc6dba-4dd8-602a-b124-ed86da0c227a",
        playerTeam: [
          {
            gene: "0x900000000000010002c1c090840000000001000010414104000100002801400a0001000018210006000100101801400c00010004102081040001000008004102",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11726128,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001009800c20800000001000410204202000100042821410a000100001800c006000100042821410a00010004102141040001000408814108",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11734306,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804070820800000001000010a1000400010000200100020001000030a0430a000100000800430400010000084183040001000020a0c008",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11568599,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x880000000000010002000001040c00000001000c08408304000100042860450a00010004282145060001001420a0c2080001001420614508000100042840c102",
            runes: ["rune_dawn_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11633518,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000201008103000000000100141060430800010010086141040001000018a0c10a0001000430a10506000100142021810c0001000408208208",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11705923,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000101c0e1020800000001000c20a082080001000410614108000100001800c00600010004302183040001000408a105080001000410808306",
            runes: ["rune_neutral_3010_s4_nft"],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11735633,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "031fe8a9-ed4a-45c6-9ee7-29badc66c906",
        gameTimestamp: "2023-05-28 19:19:21",
        startTimestamp: "2023-05-28 19:13:53",
        endTimestamp: "2023-05-28 19:19:21",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=031fe8a9-ed4a-45c6-9ee7-29badc66c906&userId=1ec9eb6f-802b-6048-a60c-130d73bcda97",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5088745",
        oponentTeamId: "5075588",
        opponent: "1ec9eb6f-7335-6edb-a60c-d3d12582b4a0",
        playerTeam: [
          {
            gene: "0x900000000000010002c1c090840000000001000010414104000100002801400a0001000018210006000100101801400c00010004102081040001000008004102",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "ecard_aquatic_2011_s4_nondec",
              tail: "ecard_neutral_2020_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11726128,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001009800c20800000001000410204202000100042821410a000100001800c006000100042821410a00010004102141040001000408814108",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2011_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11734306,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x10000804070820800000001000010a1000400010000200100020001000030a0430a000100000800430400010000084183040001000020a0c008",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 11568599,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000180c020830000000001001428410404000100042821410a000100001800c0060001001430a1040c00010004106104060001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11739399,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c00000c20800000001001428a1410a0001000010814204000100001800c006000100103020840c00010010188185040001001010608404",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11739267,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000001c070830000000001001428a140040001000010008402000100001800c006000100103081800c000100002840c4020001001010a0800c",
            runes: ["rune_dawn_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11739257,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1897,
        opponentMmrAfter: 1891,
        opponentSlpWon: 0,
        playerMmrBefore: 2022,
        playerMmrAfter: 2028,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:06.000Z",
  },
  {
    clientId: "1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
    name: "Chonc",
    rank: "Challenger",
    tier: 0,
    topRank: 96,
    vstar: 2027,
    avatar: "8;iron_s1",
    battles: [
      {
        battleUuid: "5f685c53-8ff9-4dc9-8eb1-3f710ed9ba64",
        gameTimestamp: "2023-05-29 02:38:39",
        startTimestamp: "2023-05-29 02:32:48",
        endTimestamp: "2023-05-29 02:38:39",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5f685c53-8ff9-4dc9-8eb1-3f710ed9ba64&userId=1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5107062",
        oponentTeamId: "5096276",
        opponent: "1ec9eb6f-9b0b-6239-a60c-c996889e0e94",
        playerTeam: [
          {
            gene: "0x180000000000010001e02071030c00000001000410208508000100002860400400010000086083040001000c282043060001000c3061830c0001000018a18002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11508847,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1007104100000000100041061450800010000280080080001000c106080020001000c2820440a000100000881810a0001000810014002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11491605,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402084100000000100002000400a00010000080040020001000010008204000100001000800400010000080040020001000028014308",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3265214,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010000806080c204000000010000080144080001000028804502000100001800c506000100141060c00a0001000c2820440c000100000860420a",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11631022,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000101c0a082080000000100082041050a0001000828414104000100081840c40a000100083061020c000100083041020c0001000810408402",
            runes: ["rune_plant_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_3012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11722148,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x18000000000001000340f80082080000000100081081440a00010014284082020001000c3061830c000100141060c0040001000c3061800a0001000c10214102",
            runes: ["rune_plant_3011_s4_nondec"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_neutral_1010_s4",
            },
            axie_id: 1903282,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2059,
        opponentMmrAfter: 2048,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2027,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "84e201d2-68aa-4c47-a07f-f9e61f9b5545",
        gameTimestamp: "2023-05-29 02:32:17",
        startTimestamp: "2023-05-29 02:27:15",
        endTimestamp: "2023-05-29 02:32:17",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=84e201d2-68aa-4c47-a07f-f9e61f9b5545&userId=1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5107062",
        oponentTeamId: "5091322",
        opponent: "1ec9eb6f-4341-6627-a60c-dc2c3fb974f0",
        playerTeam: [
          {
            gene: "0x180000000000010001e02071030c00000001000410208508000100002860400400010000086083040001000c282043060001000c3061830c0001000018a18002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11508847,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1007104100000000100041061450800010000280080080001000c106080020001000c2820440a000100000881810a0001000810014002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11491605,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402084100000000100002000400a00010000080040020001000010008204000100001000800400010000080040020001000028014308",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3265214,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000001a060830800000001001428a14508000100042801440a00010000182103080001001430a1440c000100041021050c0001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11741001,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010001008040830c00000001001428a1450a000100042841040a000100001800c50c0001001430a185080001000028208508000100101080800c",
            runes: ["rune_mech_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11741784,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000010000000000c20c0000000100142860850a000100102861040a0001000018a1000c000100100860430a00010010186104060001001010410306",
            runes: ["rune_mech_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11750820,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2002,
        opponentMmrAfter: 1993,
        opponentSlpWon: 0,
        playerMmrBefore: 2007,
        playerMmrAfter: 2016,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "504821d4-74b8-4924-bef5-ac357626d2e1",
        gameTimestamp: "2023-05-29 02:26:45",
        startTimestamp: "2023-05-29 02:23:00",
        endTimestamp: "2023-05-29 02:26:45",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=504821d4-74b8-4924-bef5-ac357626d2e1&userId=1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5107062",
        oponentTeamId: "5105119",
        opponent: "1ec9eb6f-73b7-6f7e-a60c-eb2abf58bb09",
        playerTeam: [
          {
            gene: "0x180000000000010001e02071030c00000001000410208508000100002860400400010000086083040001000c282043060001000c3061830c0001000018a18002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11508847,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1007104100000000100041061450800010000280080080001000c106080020001000c2820440a000100000881810a0001000810014002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11491605,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402084100000000100002000400a00010000080040020001000010008204000100001000800400010000080040020001000028014308",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3265214,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010001c0809002080000000100101080840200010010208044080001000028810404000100101881040c000100101880c40a0001000c10808402",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11666930,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c040210410000000010000080080040001000028014004000100001800c10c0001000820404202000100000800c0020001000008018004",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11527160,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710410000000010000100080040001000008004002000100001000800200010000100080040001000008004002000100002801400a",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_beast_3012_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 337937,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2049,
        opponentMmrAfter: 2038,
        opponentSlpWon: 0,
        playerMmrBefore: 1996,
        playerMmrAfter: 2007,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "10767b36-53ea-4326-8b73-96581c88c188",
        gameTimestamp: "2023-05-29 02:22:16",
        startTimestamp: "2023-05-29 02:16:40",
        endTimestamp: "2023-05-29 02:22:16",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=10767b36-53ea-4326-8b73-96581c88c188&userId=1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
        battleType: "pvp",
        playerSlpWon: 22,
        playerTeamId: "5107062",
        oponentTeamId: "5105045",
        opponent: "1ec9eb6f-57f4-6c14-a60c-667d677c7590",
        playerTeam: [
          {
            gene: "0x180000000000010001e02071030c00000001000410208508000100002860400400010000086083040001000c282043060001000c3061830c0001000018a18002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11508847,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1007104100000000100041061450800010000280080080001000c106080020001000c2820440a000100000881810a0001000810014002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11491605,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402084100000000100002000400a00010000080040020001000010008204000100001000800400010000080040020001000028014308",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3265214,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140c040820800000001000420a143020001000410208104000100001800c2060001001430a0450c00010000280143080001000408014102",
            runes: ["rune_mech_3011_s4_nondec"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_bug_2011_s4_nft",
            },
            axie_id: 11736928,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000000e051020c000000010010100142020001000428a08004000100001800c0060001001010808404000100002820800a0001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3013_s4_nft",
              ears: "ecard_neutral_3012_s4_nondec",
              eyes: "",
              horn: "ecard_aquatic_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11730754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000006000c4080000000100101020440400010010288040080001000410208308000100042020c1080001000430214106000100041820c304",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_bug_3012_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_3010_s4_nft",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11739495,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2008,
        opponentMmrAfter: 1998,
        opponentSlpWon: 0,
        playerMmrBefore: 1986,
        playerMmrAfter: 1996,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "59418491-308c-41f5-967e-685c5adb624a",
        gameTimestamp: "2023-05-29 02:15:59",
        startTimestamp: "2023-05-29 02:11:30",
        endTimestamp: "2023-05-29 02:15:59",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=59418491-308c-41f5-967e-685c5adb624a&userId=1ec9eb73-7a5c-6f07-a60c-e549686e53bb",
        battleType: "pvp",
        playerSlpWon: 22,
        playerTeamId: "5107062",
        oponentTeamId: "5106620",
        opponent: "1ec9eb6f-53a3-61e4-a60c-9189ecee3a44",
        playerTeam: [
          {
            gene: "0x180000000000010001e02071030c00000001000410208508000100002860400400010000086083040001000c282043060001000c3061830c0001000018a18002",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_2011_s4_nondec",
              tail: "ecard_neutral_3012_s4_nondec",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11508847,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c1007104100000000100041061450800010000280080080001000c106080020001000c2820440a000100000881810a0001000810014002",
            runes: ["rune_beast_tripp_1"],
            charms: {
              back: "ecard_beast_2011_s4_nondec",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_bird_2012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11491605,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0402084100000000100002000400a00010000080040020001000010008204000100001000800400010000080040020001000028014308",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_beast_2011_s4_nondec",
              mouth: "ecard_beast_3012_s4_nft",
            },
            axie_id: 3265214,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010000c0e030c20800000001000410a141040001001020014104000100001880c006000100103020c40c0001000008414406000100101061830c",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2011_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11732536,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003000000010400000001000c088083080001000c104083020001000c3061830c0001000c280043060001000c18a040020001000c1020c00a",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_plant_2012_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 9393479,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x100008020b0c40c0000000100001061000400010000200101080001000030018206000100000800420200010000080040020001001418018006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "",
            },
            axie_id: 11727733,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1938,
        opponentMmrAfter: 1930,
        opponentSlpWon: 0,
        playerMmrBefore: 1978,
        playerMmrAfter: 1986,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:06.000Z",
  },
  {
    clientId: "1ec9eb6f-4237-6c8b-a60c-df7f8ea6afb0",
    name: "Patr1ck | Kurayami Tribe",
    rank: "Challenger",
    tier: 0,
    topRank: 97,
    vstar: 2026,
    avatar: null,
    battles: [
      {
        battleUuid: "5d706d51-244e-4808-856e-7657e1168ae8",
        gameTimestamp: "2023-05-29 03:05:52",
        startTimestamp: "2023-05-29 03:01:20",
        endTimestamp: "2023-05-29 03:05:52",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=5d706d51-244e-4808-856e-7657e1168ae8&userId=1ec9eb6f-4237-6c8b-a60c-df7f8ea6afb0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5019717",
        oponentTeamId: "5047909",
        opponent: "1ec9eb6f-537c-6e1f-a60c-6eb1e10f5430",
        playerTeam: [
          {
            gene: "0x8000000000001000280a040c20800000001001428a142040001000428a10502000100001800c0060001000410a1820a000100002860810a0001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11718850,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a050c30800000001001428a1450a0001000820014102000100001800c006000100143020840800010004102081020001001010808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11719947,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002602030820800000001001428a14008000100082081000a000100001800c402000100103081840a000100101880c40c0001001010610202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720302,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000180c0e0020c00000001001428a141040001000428610108000100001800c4080001001020a1810a00010004102141040001001010210404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11713035,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c0a0e0830800000001001428008004000100042001050a000100001800c00400010014304103080001001018a044060001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_aquatic_2011_s4_nft",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_2019_s4_nft",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11692494,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000180c090c20800000001001428a102040001000010a04104000100001800c10c0001001030208204000100101840c3060001001010808406",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11694785,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1983,
        opponentMmrAfter: 1994,
        opponentSlpWon: 22,
        playerMmrBefore: 2037,
        playerMmrAfter: 2026,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "ebe1f008-b6f7-46d1-827f-b50bd2b2fa0b",
        gameTimestamp: "2023-05-29 03:00:36",
        startTimestamp: "2023-05-29 02:54:52",
        endTimestamp: "2023-05-29 03:00:36",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ebe1f008-b6f7-46d1-827f-b50bd2b2fa0b&userId=1ec9eb6f-4237-6c8b-a60c-df7f8ea6afb0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5019717",
        oponentTeamId: "5081096",
        opponent: "1ec9eb6f-6b7e-6ab9-a60c-a0047de0946b",
        playerTeam: [
          {
            gene: "0x8000000000001000280a040c20800000001001428a142040001000428a10502000100001800c0060001000410a1820a000100002860810a0001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11718850,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a050c30800000001001428a1450a0001000820014102000100001800c006000100143020840800010004102081020001001010808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11719947,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002602030820800000001001428a14008000100082081000a000100001800c402000100103081840a000100101880c40c0001001010610202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720302,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x200000000000010000804040c310000000010004102081040001000808404202000100102081040400010004282142040001000818208104000100101800c506",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11684063,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010003008021030800000001000410010104000100080840410800010010208103060001001030a0810a0001000818604302000100000800400a",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_aquatic_3013_s4_nft",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11691913,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010002b01800420800000001000c08208104000100080881020200010010204104080001000428214206000100041040c1040001001018a18406",
            runes: ["rune_dusk_3012_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_2011_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11747635,
            position: 3,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2061,
        opponentMmrAfter: 2050,
        opponentSlpWon: 0,
        playerMmrBefore: 2026,
        playerMmrAfter: 2037,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "570611c4-e8d4-458c-8d9f-a8fcdef94180",
        gameTimestamp: "2023-05-29 02:54:22",
        startTimestamp: "2023-05-29 02:50:34",
        endTimestamp: "2023-05-29 02:54:22",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=570611c4-e8d4-458c-8d9f-a8fcdef94180&userId=1ec9eb6f-4237-6c8b-a60c-df7f8ea6afb0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5019717",
        oponentTeamId: "5106158",
        opponent: "1ec9eb6f-620d-6035-a60c-ecabf56bb44d",
        playerTeam: [
          {
            gene: "0x8000000000001000280a040c20800000001001428a142040001000428a10502000100001800c0060001000410a1820a000100002860810a0001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11718850,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a050c30800000001001428a1450a0001000820014102000100001800c006000100143020840800010004102081020001001010808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11719947,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002602030820800000001001428a14008000100082081000a000100001800c402000100103081840a000100101880c40c0001001010610202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720302,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e03083080000000100001060820a0001000028014104000100001800c40200010000306144020001001018808102000100000800c204",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11720269,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x8000000000001000340a0410108000000010004102042080001000428610102000100001800820400010010182145080001001018a1810a0001000408218406",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nft",
              ears: "",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nondec",
            },
            axie_id: 11738550,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x100008160208210000000010000100101040001001028a0400400010000304100020001000008004002000100000800c208000100001800c00a",
            runes: ["rune_beast_3012_s4_nft"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11567779,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2055,
        opponentMmrAfter: 2064,
        opponentSlpWon: 24,
        playerMmrBefore: 2035,
        playerMmrAfter: 2026,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "eea46b6f-ab38-4bda-aecc-29469d18baff",
        gameTimestamp: "2023-05-29 02:50:12",
        startTimestamp: "2023-05-29 02:45:46",
        endTimestamp: "2023-05-29 02:50:12",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=eea46b6f-ab38-4bda-aecc-29469d18baff&userId=1ec9eb6f-4237-6c8b-a60c-df7f8ea6afb0",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5019717",
        oponentTeamId: "5102140",
        opponent: "1ec9eb7e-6e7d-6b11-a60c-a3ca02f388a4",
        playerTeam: [
          {
            gene: "0x8000000000001000280a040c20800000001001428a142040001000428a10502000100001800c0060001000410a1820a000100002860810a0001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11718850,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a050c30800000001001428a1450a0001000820014102000100001800c006000100143020840800010004102081020001001010808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11719947,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002602030820800000001001428a14008000100082081000a000100001800c402000100103081840a000100101880c40c0001001010610202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720302,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x1800000000000100004180c102080000000100101060450a0001000c106043020001000c3061430c0001000c086043020001000c18a0c3060001000c10604304",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_plant_2011_s4_nft",
              ears: "ecard_plant_3010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_plant_3010_s4_nft",
              tail: "ecard_plant_2011_s4_nft",
              mouth: "ecard_plant_3010_s4_nft",
            },
            axie_id: 11122748,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100000000000010001c1809104080000000100081041420a0001000008204204000100001821810c000100083041420a00010000084080060001000820410208",
            runes: ["rune_bird_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "ecard_bird_3012_s4_nft",
              mouth: "",
            },
            axie_id: 11630009,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010003018090840800000001000820410404000100082841420a000100081840c206000100083041820c000100083041850a0001000810408204",
            runes: ["rune_plant_3012_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_bird_2012_s4_nft",
              eyes: "ecard_bird_2012_s4_nft",
              horn: "ecard_bird_3012_s4_nft",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11734252,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2132,
        opponentMmrAfter: 2139,
        opponentSlpWon: 24,
        playerMmrBefore: 2042,
        playerMmrAfter: 2035,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "16c9907a-770a-4d41-9747-a028cc3763d9",
        gameTimestamp: "2023-05-29 02:44:48",
        startTimestamp: "2023-05-29 02:40:30",
        endTimestamp: "2023-05-29 02:44:48",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=16c9907a-770a-4d41-9747-a028cc3763d9&userId=1ec9eb6f-4237-6c8b-a60c-df7f8ea6afb0",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5019717",
        oponentTeamId: "5067164",
        opponent: "1ec9eb6f-7369-6969-a60c-0ab4c76aa094",
        playerTeam: [
          {
            gene: "0x8000000000001000280a040c20800000001001428a142040001000428a10502000100001800c0060001000410a1820a000100002860810a0001001010808404",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_bug_2011_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11718850,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000001000140a050c30800000001001428a1450a0001000820014102000100001800c006000100143020840800010004102081020001001010808504",
            runes: ["rune_neutral_3011_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3013_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11719947,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010002602030820800000001001428a14008000100082081000a000100001800c402000100103081840a000100101880c40c0001001010610202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_2013_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11720302,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100010080e083040000000100142821050a0001000c28410204000100001800c00c0001001410a1810c000100101880c5040001001010418404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_1001",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_plant_2011_s4_nft",
            },
            axie_id: 11741227,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001004040a020020800000001001428a1440a000100042061000800010000180100060001000c0881840c0001001018a040060001001010a18202",
            runes: ["rune_aquatic_2010_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3010_s4_nft",
              ears: "",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_3012_s4_nft",
            },
            axie_id: 11734852,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000100a050c20000000001001428a1450a0001000820a0810400010000182140060001001410610306000100000861410a0001001010814408",
            runes: ["rune_aquatic_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11739855,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2041,
        opponentMmrAfter: 2031,
        opponentSlpWon: 0,
        playerMmrBefore: 2032,
        playerMmrAfter: 2042,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:07.000Z",
  },
  {
    clientId: "1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
    name: "Yadz",
    rank: "Challenger",
    tier: 0,
    topRank: 98,
    vstar: 2026,
    avatar: "1;0",
    battles: [
      {
        battleUuid: "ac23ddb0-67be-4607-af9f-fbb0bfd17bd9",
        gameTimestamp: "2023-05-29 13:30:49",
        startTimestamp: "2023-05-29 13:25:59",
        endTimestamp: "2023-05-29 13:30:49",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ac23ddb0-67be-4607-af9f-fbb0bfd17bd9&userId=1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087063",
        oponentTeamId: "5026311",
        opponent: "1ec9eb6f-8e03-67d9-a60c-a0cd8ab0ef27",
        playerTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e07002040000000100041020830200010008084042020001001020404408000100042881810a000100081840c206000100101880c20c",
            runes: ["rune_bug_2011_s4_nondec"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11713769,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000381c0c082100000000100041081010400010008084041080001001020810408000100103040c10a000100081840c4040001000008818002",
            runes: ["rune_dusk_3010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_aquatic_3012_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_1001",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_bird_2013_s4_nft",
            },
            axie_id: 11705793,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010060012080840800000001000c088083020001000808004504000100102000450a00010004282141060001000410014102000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_plant_2011_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11712809,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1969,
        opponentMmrAfter: 1961,
        opponentSlpWon: 0,
        playerMmrBefore: 2018,
        playerMmrAfter: 2026,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "c15a8f9d-0a35-4f5a-ac60-7d1509aed820",
        gameTimestamp: "2023-05-29 13:25:16",
        startTimestamp: "2023-05-29 13:18:30",
        endTimestamp: "2023-05-29 13:25:16",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=c15a8f9d-0a35-4f5a-ac60-7d1509aed820&userId=1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087063",
        oponentTeamId: "5109138",
        opponent: "1ec9eb6f-5741-624e-a60c-647c4afcd6a8",
        playerTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_3011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x8000000000001000080e05043100000000100142821450a0001000428214008000100001800c106000100143000810c000100002820430c0001001010818404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nondec",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11753644,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010001410050830800000001001428a1450a000100040821410a000100001800c0060001001430a1850c00010000280141060001001010808404",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2020_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "",
            },
            axie_id: 11751754,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010001004000830800000001001428a10404000100042881440a000100001800c0060001000420a18208000100000880c1080001001010808404",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11740330,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1994,
        opponentMmrAfter: 2005,
        opponentSlpWon: 24,
        playerMmrBefore: 2029,
        playerMmrAfter: 2018,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "09fd2613-5f00-4cd0-9284-7cca348ace50",
        gameTimestamp: "2023-05-29 13:16:03",
        startTimestamp: "2023-05-29 13:12:12",
        endTimestamp: "2023-05-29 13:16:03",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=09fd2613-5f00-4cd0-9284-7cca348ace50&userId=1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087063",
        oponentTeamId: "5085730",
        opponent: "1eddf7fb-0bab-6104-bf69-dfa815a328db",
        playerTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001c0e0b0821000000001001428a1430a0001000428810208000100001800c50a0001001430214206000100101880c00a0001001010814404",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11726773,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x800000000000100008180e0830800000001001428414108000100042820830800010000188080060001001430a1850c000100002880c1020001001010808404",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_3013_s4_nft",
            },
            axie_id: 11710481,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001c06000c2080000000100142880850a0001000820a1400a0001000018a04006000100100880c204000100000860c3040001001010808106",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_aquatic_2011_s4_nft",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11745708,
            position: 5,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1992,
        opponentMmrAfter: 2003,
        opponentSlpWon: 24,
        playerMmrBefore: 2040,
        playerMmrAfter: 2029,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "2fafca82-a483-44b3-a4cc-b7222da7fb9d",
        gameTimestamp: "2023-05-29 13:11:33",
        startTimestamp: "2023-05-29 13:07:09",
        endTimestamp: "2023-05-29 13:11:33",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=2fafca82-a483-44b3-a4cc-b7222da7fb9d&userId=1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087063",
        oponentTeamId: "5067843",
        opponent: "1ec9eb7e-6efb-6b0a-a60c-6d80c721f88d",
        playerTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000380e000830800000001000410208104000100142881440a0001001020010104000100042881810a0001000410208104000100101880c406",
            runes: ["rune_mech_2011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4",
              eyes: "ecard_neutral_2010_s4",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_neutral_2010_s4",
              mouth: "ecard_neutral_2010_s4",
            },
            axie_id: 11707931,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x9000000000000100024120c0c21000000001000410208404000100080840440400010010208101060001000428214206000100081840c304000100000880c408",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_neutral_3012_s4_nondec",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_1010_s4",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11718902,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x900000000000010000b00070430c00000001000410010302000100080840450a0001001020810002000100042881840c00010008182102060001001018004406",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_aquatic_2013_s4_nondec",
              eyes: "",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_3013_s4_nft",
              mouth: "ecard_bird_2012_s4_nft",
            },
            axie_id: 11718742,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2009,
        opponentMmrAfter: 2020,
        opponentSlpWon: 24,
        playerMmrBefore: 2051,
        playerMmrAfter: 2040,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 497,
      },
      {
        battleUuid: "7eefee0d-26de-49d1-a214-f0ffc766b724",
        gameTimestamp: "2023-05-29 13:06:41",
        startTimestamp: "2023-05-29 13:02:49",
        endTimestamp: "2023-05-29 13:06:41",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=7eefee0d-26de-49d1-a214-f0ffc766b724&userId=1ede7250-5e50-6776-8cc4-9dd3580fa6fe",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5087063",
        oponentTeamId: "5050689",
        opponent: "1ed68029-91ad-621c-8d13-709ea70f2723",
        playerTeam: [
          {
            gene: "0x80000000000010001c0a070820800000001001428a14104000100102881450a000100001800c50c000100143021450c000100041080c2040001001010808404",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11738594,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000808050c30c0000000100142861030a000100042821410a000100001800c006000100103001850c000100041021820c0001001010808508",
            runes: ["rune_aquatic_3010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bug_2013_s4_nft",
            },
            axie_id: 11742752,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001e02000020000000001001428004104000100082001440200010000180080060001001030a0850c000100041001430a000100101000850a",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11743455,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000010002c080408208000000010004102041020001000428214504000100001800c006000100042821410a000100041020810a0001000408814108",
            runes: ["rune_bug_2011_s4_nft"],
            charms: {
              back: "ecard_bug_2013_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_bug_2013_s4_nft",
              horn: "ecard_bug_2013_s4_nft",
              tail: "ecard_bug_2013_s4_nft",
              mouth: "ecard_bug_2012_s4_nft",
            },
            axie_id: 11727385,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c14071040c0000000100001000800a000100002801400a000100001800c0060001000c2881830a00010000100040060001000008004006",
            runes: ["rune_neutral_2010_s4_nondec"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_beast_3013_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_plant_2011_s4_nft",
              tail: "ecard_neutral_2014_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11580129,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x100008100710210000000010000100082080001000020414002000100003001800c0001000008004002000100000821040a0001001418010006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_beast_3011_s4_nft",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "ecard_beast_buba_1",
              tail: "ecard_reptile_2011_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11580685,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2089,
        opponentMmrAfter: 2078,
        opponentSlpWon: 0,
        playerMmrBefore: 2040,
        playerMmrAfter: 2051,
        playerMoonshardWon: 149,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:07.000Z",
  },
  {
    clientId: "1ec9eb6f-40c3-691c-a60c-accaeab29f60",
    name: "RG | Kurayami Tribe",
    rank: "Challenger",
    tier: 0,
    topRank: 99,
    vstar: 2025,
    avatar: "s3_top20k;0",
    battles: [
      {
        battleUuid: "25614bb4-a052-4e58-8077-1aeea5b90482",
        gameTimestamp: "2023-05-29 12:24:33",
        startTimestamp: "2023-05-29 12:19:29",
        endTimestamp: "2023-05-29 12:24:33",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=25614bb4-a052-4e58-8077-1aeea5b90482&userId=1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5111351",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "9b044939-2060-4926-8cbe-9c6cd99c4311",
        gameTimestamp: "2023-05-29 12:18:51",
        startTimestamp: "2023-05-29 12:13:47",
        endTimestamp: "2023-05-29 12:18:51",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=9b044939-2060-4926-8cbe-9c6cd99c4311&userId=1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5111351",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "3c236c42-3c1c-4025-a2c2-3730d876a7dc",
        gameTimestamp: "2023-05-29 12:13:00",
        startTimestamp: "2023-05-29 12:06:52",
        endTimestamp: "2023-05-29 12:13:00",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=3c236c42-3c1c-4025-a2c2-3730d876a7dc&userId=1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5111351",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "51f6783f-a948-48a1-9562-284c6dd5ad5c",
        gameTimestamp: "2023-05-29 12:06:15",
        startTimestamp: "2023-05-29 12:00:30",
        endTimestamp: "2023-05-29 12:06:15",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=51f6783f-a948-48a1-9562-284c6dd5ad5c&userId=1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5111351",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "47036a09-f23c-4e5f-919c-9121fadf2c5c",
        gameTimestamp: "2023-05-29 11:59:44",
        startTimestamp: "2023-05-29 11:54:43",
        endTimestamp: "2023-05-29 11:59:44",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=47036a09-f23c-4e5f-919c-9121fadf2c5c&userId=1ec9eb6f-40c3-691c-a60c-accaeab29f60",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5111351",
        oponentTeamId: "5102685",
        opponent: "1ec9eb6f-4612-6416-a60c-672d6041153d",
        playerTeam: [
          {
            gene: "0x90000000000001000241c090840000000001000420414408000100002801400a0001000018210402000100101820c00c00010004102080060001000008204002",
            runes: ["rune_dusk_3012_s4_nft"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "",
              tail: "",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11732595,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x180000000000010001c040210410000000010000088143020001000028804202000100001861850c000100002820400c000100001000810a0001000c10a1430c",
            runes: ["rune_plant_3011_s4_nft"],
            charms: {
              back: "ecard_beast_2011_s4_nft",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_beast_3013_s4_nft",
              horn: "ecard_beast_2011_s4_nft",
              tail: "ecard_plant_3012_s4_nft",
              mouth: "ecard_beast_3013_s4_nft",
            },
            axie_id: 11644548,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x1000080002084100000000100001040840a0001001008014002000100003001800c0001000008004002000100003000450c0001000018008006",
            runes: ["rune_beast_3012_s4_nondec"],
            charms: {
              back: "ecard_beast_buba_1",
              ears: "",
              eyes: "ecard_beast_3012_s4_nft",
              horn: "",
              tail: "ecard_neutral_3012_s4_nft",
              mouth: "ecard_aquatic_3010_s4_nft",
            },
            axie_id: 11731121,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000101c040030c0000000100142820850a0001000428214204000100001800c006000100143020400c00010004108080020001001010004404",
            runes: ["rune_mech_3010_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_neutral_2010_s4_nondec",
            },
            axie_id: 11750757,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010000000070c2100000000100142821030400010008204104020001000018204006000100103081830a00010010182044060001001010608102",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_1001",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_3012_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_bird_2011_s4_nft",
            },
            axie_id: 11749566,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c08050c3000000000100142840850a0001000428804508000100001801830a000100143021000c0001000028804102000100101081020c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_1001",
              eyes: "ecard_neutral_3012_s4_nondec",
              horn: "ecard_reptile_3013_s4_nft",
              tail: "ecard_neutral_1001",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11746655,
            position: 2,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:08.000Z",
  },
  {
    clientId: "1ec9eb6f-6151-6896-a60c-d4955785d733",
    name: "dangtruongkds",
    rank: "Challenger",
    tier: 0,
    topRank: 100,
    vstar: 2023,
    avatar: "1;paschal_eggs",
    battles: [
      {
        battleUuid: "bdeefb3a-1c76-4dc8-aece-02eb2913f53a",
        gameTimestamp: "2023-05-29 12:51:37",
        startTimestamp: "2023-05-29 12:47:53",
        endTimestamp: "2023-05-29 12:51:37",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=bdeefb3a-1c76-4dc8-aece-02eb2913f53a&userId=1ec9eb6f-6151-6896-a60c-d4955785d733",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103205",
        oponentTeamId: "5108171",
        opponent: "1ec9eb73-9997-66da-a60c-1b93893a6ce1",
        playerTeam: [
          {
            gene: "0x8000000000001000080a020840800000001001428a14108000100042821420a00010000188185020001001430a1850c0001001018014404000100101080810c",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_neutral_3011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_aquatic_3013_s4_nft",
              mouth: "ecard_bug_3011_s4_nft",
            },
            axie_id: 11743653,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000800080820000000001001428a142040001001028008108000100001800c1060001001008818104000100002880c104000100101061430c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "",
              horn: "ecard_neutral_4011_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11721466,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001816070c20c00000001001428a144020001001028814408000100001881840a00010010308044020001000410418004000100101080810c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11742071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000100008100e0840800000001001428a1450a000100042800820a00010000186145020001001430a1850c000100041880c00a0001001010808404",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11743649,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x20000000000001000241605082100000000100142801450a000100001001030a000100001800c0060001000420a18004000100041820c00c0001001010808208",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11740530,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000381c030800800000001001428a1450a0001000010804108000100001800c0060001001030a1050600010004108042060001001010808002",
            runes: [],
            charms: {
              back: "",
              ears: "",
              eyes: "",
              horn: "",
              tail: "",
              mouth: "",
            },
            axie_id: 11730894,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: null,
        opponentMmrAfter: null,
        opponentSlpWon: 0,
        playerMmrBefore: null,
        playerMmrAfter: null,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "a54beb97-109d-4f64-adeb-0c86e53d1382",
        gameTimestamp: "2023-05-29 08:12:52",
        startTimestamp: "2023-05-29 08:07:57",
        endTimestamp: "2023-05-29 08:12:52",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=a54beb97-109d-4f64-adeb-0c86e53d1382&userId=1ec9eb6f-6151-6896-a60c-d4955785d733",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103205",
        oponentTeamId: "3602180",
        opponent: "1ec9eb6f-4b54-68eb-a60c-cb8bb81cb28c",
        playerTeam: [
          {
            gene: "0x8000000000001000080a020840800000001001428a14108000100042821420a00010000188185020001001430a1850c0001001018014404000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11743653,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000800080820000000001001428a142040001001028008108000100001800c1060001001008818104000100002880c104000100101061430c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721466,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001816070c20c00000001001428a144020001001028814408000100001881840a00010010308044020001000410418004000100101080810c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11742071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x20000000000001000001609102000000000100100880840400010010288144040001001020810408000100103080c50600010010188084060001001008808002",
            runes: ["rune_aquatic_3011_s4_nondec"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_2010_s4_nondec",
              eyes: "ecard_aquatic_2013_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2011_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 3692083,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x100008140b08208000000010000104044080001000020804004000100001880c408000100041800400200010008082080020001000008004002",
            runes: ["rune_neutral_2010_s4_nft"],
            charms: {
              back: "ecard_bird_2011_s4_nft",
              ears: "ecard_beast_tripp_1",
              eyes: "ecard_beast_2011_s4_nft",
              horn: "ecard_bug_2012_s4_nft",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_beast_2011_s4_nft",
            },
            axie_id: 11687351,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x10001c0e0c082100000000100001020800a0001000008004002000100001000800a000100082000820400010000082080020001000028014406",
            runes: ["rune_beast_2010_s4_nondec"],
            charms: {
              back: "ecard_beast_3010_s4_nft",
              ears: "ecard_beast_3010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nondec",
              horn: "ecard_bird_2012_s4_nft",
              tail: "ecard_neutral_3013_s4_nft",
              mouth: "ecard_beast_3010_s4_nft",
            },
            axie_id: 11714749,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1792,
        opponentMmrAfter: 1788,
        opponentSlpWon: 0,
        playerMmrBefore: 2019,
        playerMmrAfter: 2023,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "8c9190d8-5d10-4bba-a1a1-608f6bb9f8d0",
        gameTimestamp: "2023-05-29 08:07:01",
        startTimestamp: "2023-05-29 08:02:57",
        endTimestamp: "2023-05-29 08:07:01",
        result: "L",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=8c9190d8-5d10-4bba-a1a1-608f6bb9f8d0&userId=1ec9eb6f-6151-6896-a60c-d4955785d733",
        battleType: "pvp",
        playerSlpWon: 0,
        playerTeamId: "5103205",
        oponentTeamId: "5098929",
        opponent: "1ec9eb77-78ce-6106-a60c-446d97702d21",
        playerTeam: [
          {
            gene: "0x8000000000001000080a020840800000001001428a14108000100042821420a00010000188185020001001430a1850c0001001018014404000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11743653,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000800080820000000001001428a142040001001028008108000100001800c1060001001008818104000100002880c104000100101061430c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721466,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001816070c20c00000001001428a144020001001028814408000100001881840a00010010308044020001000410418004000100101080810c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11742071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x80000000000001000140a0508208000000010004080041080001000428208502000100001800c006000100143081850c000100143001450c0001000408204108",
            runes: ["rune_neutral_3012_s4_nondec"],
            charms: {
              back: "ecard_reptile_2011_s4_nft",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nft",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11629817,
            position: 1,
            axie_type: "ronin",
          },
          {
            gene: "0x88000000000001000241607104080000000100102840410a00010000280145040001000018214406000100101000400200010008304180020001000008004002",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4_nft",
              eyes: "ecard_aquatic_3012_s4_nft",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "",
              mouth: "ecard_neutral_1001",
            },
            axie_id: 11664764,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010040602030c3080000000100101020410a0001001028814504000100042041820c000100042020810800010004302181020001000418804406",
            runes: ["rune_bug_3011_s4_nft"],
            charms: {
              back: "ecard_neutral_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11692242,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2064,
        opponentMmrAfter: 2072,
        opponentSlpWon: 0,
        playerMmrBefore: 2027,
        playerMmrAfter: 2019,
        playerMoonshardWon: 0,
        opponentMoonshardWon: 149,
      },
      {
        battleUuid: "ecad6ab4-5339-4cb2-ae84-1ecdc9d0c372",
        gameTimestamp: "2023-05-29 07:48:34",
        startTimestamp: "2023-05-29 07:43:46",
        endTimestamp: "2023-05-29 07:48:34",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=ecad6ab4-5339-4cb2-ae84-1ecdc9d0c372&userId=1ec9eb6f-6151-6896-a60c-d4955785d733",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103205",
        oponentTeamId: "5086077",
        opponent: "1ec9eb7e-68a9-64ec-a60c-4a1b386ce74e",
        playerTeam: [
          {
            gene: "0x8000000000001000080a020840800000001001428a14108000100042821420a00010000188185020001001430a1850c0001001018014404000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11743653,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000800080820000000001001428a142040001001028008108000100001800c1060001001008818104000100002880c104000100101061430c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721466,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001816070c20c00000001001428a144020001001028814408000100001881840a00010010308044020001000410418004000100101080810c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11742071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x800000000000010001416041020c00000001000c0820830200010004104041020001000018204402000100101021410c0001000c30204102000100040820810c",
            runes: ["rune_mech_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "ecard_plant_3010_s4_nft",
              horn: "ecard_neutral_3012_s4",
              tail: "ecard_bug_3010_s4_nondec",
              mouth: "",
            },
            axie_id: 11712758,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x880000000000010002412050840c00000001000410808504000100002841410400010000186180060001001430a1820a00010000280145080001000008004202",
            runes: ["rune_dawn_3011_s4_nft"],
            charms: {
              back: "",
              ears: "ecard_neutral_3012_s4",
              eyes: "",
              horn: "",
              tail: "ecard_neutral_2017_s4_nft",
              mouth: "ecard_neutral_3012_s4",
            },
            axie_id: 11741090,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x80000000000010000c00030c30800000001001010808104000100102880850800010004202081080001000420618108000100043040c5040001000418804102",
            runes: ["rune_bug_3011_s4_nondec"],
            charms: {
              back: "ecard_bug_3012_s4_nft",
              ears: "ecard_bug_3010_s4_nft",
              eyes: "",
              horn: "ecard_bug_3012_s4_nft",
              tail: "ecard_bug_2011_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11737996,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 2054,
        opponentMmrAfter: 2043,
        opponentSlpWon: 0,
        playerMmrBefore: 2016,
        playerMmrAfter: 2027,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
      {
        battleUuid: "cfd7b594-797b-4496-8a48-3b615bfd6413",
        gameTimestamp: "2023-05-29 07:43:06",
        startTimestamp: "2023-05-29 07:37:56",
        endTimestamp: "2023-05-29 07:43:06",
        result: "W",
        replayUrl:
          "https://storage.googleapis.com/origin-production/origin.html?f=rpl&q=cfd7b594-797b-4496-8a48-3b615bfd6413&userId=1ec9eb6f-6151-6896-a60c-d4955785d733",
        battleType: "pvp",
        playerSlpWon: 24,
        playerTeamId: "5103205",
        oponentTeamId: "5045969",
        opponent: "1ec9eb7e-41bb-60ed-a60c-3e479f74b8b5",
        playerTeam: [
          {
            gene: "0x8000000000001000080a020840800000001001428a14108000100042821420a00010000188185020001001430a1850c0001001018014404000100101080810c",
            runes: ["rune_bug_3010_s4_nft"],
            charms: {
              back: "ecard_aquatic_3012_s4_nondec",
              ears: "ecard_neutral_1010_s4",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_reptile_2011_s4_nft",
              tail: "ecard_neutral_2010_s4_nondec",
              mouth: "ecard_bug_3012_s4_nft",
            },
            axie_id: 11743653,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010000800080820000000001001428a142040001001028008108000100001800c1060001001008818104000100002880c104000100101061430c",
            runes: ["rune_aquatic_2010_s4_nft"],
            charms: {
              back: "ecard_neutral_2013_s4_nft",
              ears: "ecard_beast_3012_s4_nft",
              eyes: "ecard_neutral_2011_s4_nft",
              horn: "ecard_aquatic_3013_s4_nft",
              tail: "ecard_aquatic_2013_s4_nft",
              mouth: "ecard_aquatic_2011_s4_nft",
            },
            axie_id: 11721466,
            position: 2,
            axie_type: "ronin",
          },
          {
            gene: "0x200000000000010001816070c20c00000001001428a144020001001028814408000100001881840a00010010308044020001000410418004000100101080810c",
            runes: ["rune_aquatic_3010_s4_nondec"],
            charms: {
              back: "ecard_bug_2012_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_reptile_2011_s4_nft",
              horn: "ecard_neutral_2010_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_aquatic_3012_s4_nft",
            },
            axie_id: 11742071,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentTeam: [
          {
            gene: "0x900000000000010001c0e020c10c0000000100041000440400010008084042040001001020810406000100042881410a000100041040c1040001001018208102",
            runes: ["rune_neutral_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_neutral_2010_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_bug_2013_s4_nondec",
              tail: "ecard_aquatic_2013_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11705174,
            position: 0,
            axie_type: "ronin",
          },
          {
            gene: "0x90000000000001000001c0e0820800000001000410210504000100080840440a00010010208100040001001030214306000100081840c302000100000800c002",
            runes: ["rune_dusk_3010_s4_nondec"],
            charms: {
              back: "ecard_bird_3010_s4_nft",
              ears: "ecard_aquatic_2011_s4_nft",
              eyes: "ecard_neutral_2010_s4_nft",
              horn: "ecard_neutral_2010_s4_nft",
              tail: "ecard_neutral_2017_s4_nondec",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11715001,
            position: 3,
            axie_type: "ronin",
          },
          {
            gene: "0x280000000000010001c14070c21000000001000410208302000100080840430a00010010206183080001000428214006000100041040c402000100101880c406",
            runes: ["rune_reptile_3012_s4_nft"],
            charms: {
              back: "ecard_neutral_2010_s4_nft",
              ears: "ecard_aquatic_2013_s4_nft",
              eyes: "ecard_neutral_1001",
              horn: "ecard_neutral_3012_s4_nft",
              tail: "ecard_neutral_1010_s4",
              mouth: "ecard_neutral_2010_s4_nft",
            },
            axie_id: 11751011,
            position: 4,
            axie_type: "ronin",
          },
        ],
        opponentMmrBefore: 1956,
        opponentMmrAfter: 1948,
        opponentSlpWon: 0,
        playerMmrBefore: 2008,
        playerMmrAfter: 2016,
        playerMoonshardWon: 497,
        opponentMoonshardWon: 0,
      },
    ],
    lastUpdated: "2023-05-29T13:36:09.000Z",
  },
];

export const originsLeaderboard = rawData.slice(0, 10);
