import { Chip, Stack } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import { HiArrowSmRight } from "react-icons/hi";
import { MdInfo } from "react-icons/md";
import Text from "components/Typography/Text";
import PriceInDollars from "pages/Inspector/atoms/PriceInDollars";

const PriceRecommendations = ({ priceRecommendations, cannotPrice }) => {
  const tooltipText =
    "Please do not depend on these price recommendations to make financial decisions. There are other market factors that are not included in this tool.";
  return (
    <Stack gap={2}>
      <SectionLabel icon={<MdInfo />} tooltip={tooltipText}>
        Price Recommendations
      </SectionLabel>
      {priceRecommendations == null || cannotPrice ? (
        <Text>
          We don't have enough data to calculate price recommendations for this
          Axie.
        </Text>
      ) : (
        <Stack gap={1}>
          <Price recommendation={priceRecommendations?.lastSold} />
          <Price recommendation={priceRecommendations?.quickPrice} />
          <Price recommendation={priceRecommendations?.scaleUpPrice} />
          <Price recommendation={priceRecommendations?.scaleDownPrice} />
          <Price recommendation={priceRecommendations?.patientPrice} />
        </Stack>
      )}
    </Stack>
  );
};

const Price = ({ recommendation }) => {
  if (recommendation == null) {
    return null;
  }

  const { label, eth, ethStart, ethEnd, date } = recommendation;

  if (eth == null && ethStart == null && ethEnd == null) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="flexStart" gap="4px">
      <Text style={{ width: "140px" }}>{label}</Text>

      <Stack direction="row" alignItems="flexStart">
        <Stack>
          {eth != null && <Text color="text.primary">{eth}</Text>}
          <PriceInDollars priceInEth={eth ? eth : null} />
        </Stack>

        {ethStart != null && ethEnd != null && (
          <Stack
            direction="row"
            alignItems="center"
            gap="2px"
            color="text.primary"
          >
            <Stack>
              <Text color="text.primary">{ethStart}</Text>
              <PriceInDollars priceInEth={ethStart ? ethStart : null} />
            </Stack>
            <HiArrowSmRight />
            <Stack>
              <Text color="text.primary">{ethEnd}</Text>
              <PriceInDollars priceInEth={ethEnd ? ethEnd : null} />
            </Stack>
          </Stack>
        )}
        {date && (
          <Chip color="primary" variant="outlined" label={date} size="small" />
        )}
      </Stack>
    </Stack>
  );
};

export default PriceRecommendations;
