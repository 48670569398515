import { createSelector } from "reselect";
import { itemIsFavorite } from "./";
import { ITEM_RARITY_MAPPING } from "config";
import { getAllUniqueRunesForCurrentSeason } from "store/data/dataSelectors";
import { matchesStr, strIsInArray } from "helpers/display";

export const getRuneFilters = (state) => {
  return state.explorer?.runeFilters || {};
};

export const getFavoriteRunes = (state) => {
  return state.explorer?.favoriteRunes || [];
};

const applyRuneFilters = (rune, filters) => {
  if (!rune || !filters) {
    return true;
  }

  return (
    (matchesStr(rune.effect, filters.searchText) ||
      matchesStr(rune.name, filters.searchText)) &&
    strIsInArray(rune.class, filters.axieClasses) &&
    strIsInArray(rune.rarity, filters.rarities)
  );
};

const applySortRunes = (a, b, sortType) => {
  if (sortType === "RARITY_ASC") {
    return ITEM_RARITY_MAPPING[a.rarity] - ITEM_RARITY_MAPPING[b.rarity];
  } else if (sortType === "RARITY_DESC") {
    return ITEM_RARITY_MAPPING[b.rarity] - ITEM_RARITY_MAPPING[a.rarity];
  } else if (sortType === "NAME_DESC") {
    return b.name < a.name ? -1 : a.name < b.name ? 1 : 0;
  }
  return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
};

export const getRunesToDisplay = createSelector(
  [getAllUniqueRunesForCurrentSeason, getRuneFilters, getFavoriteRunes],
  (runes, runeFilters, favoriteRunes) => {
    return runes
      .filter((rune) => applyRuneFilters(rune, runeFilters))
      .sort((a, b) => applySortRunes(a, b, runeFilters.sortType))
      .map((rune) => ({
        ...rune,
        isFavorite: itemIsFavorite(rune, "name", favoriteRunes),
      }));
  }
);

export const getFavoriteRunesToDisplay = createSelector(
  [getFavoriteRunes],
  (favoriteRunes) => {
    return favoriteRunes.map((rune) => ({
      ...rune,
      isFavorite: true,
    }));
  }
);
