import { Stack, useTheme, Typography, Tooltip } from "@mui/material";
import { axie, egg, energy } from "icons/icons";

const ScholarIconStats = ({ scholar }) => {
  const theme = useTheme();
  return (
    <Stack gap={4} direction="row">
      <IconStat
        icon={energy(theme.palette.card.background)}
        amount={scholar.stamina}
        label="Stamina"
      />
      <IconStat
        icon={axie(theme.palette.card.background)}
        amount={scholar.numberOfAxies.adults}
        label="Axies"
      />
      <IconStat
        icon={egg(theme.palette.card.background)}
        amount={scholar.numberOfAxies.eggs}
        label="Eggs"
      />
    </Stack>
  );
};

const IconStat = ({ icon, amount, label }) => {
  const theme = useTheme();
  return (
    <Tooltip title={label}>
      <Stack direction="row" gap={1} alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            svg: {
              width: 18,
              height: "auto",
              path: {
                fill: theme.palette.secondary.main,
              },
            },
          }}
        >
          {icon}
        </Stack>

        <Typography fontSize={18} fontWeight={800} color="text.secondary">
          {amount}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default ScholarIconStats;
