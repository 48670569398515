import { Stack, useTheme, Button, useMediaQuery } from "@mui/material";
import { leaderboardStructures } from "components/Leaderboard/leaderboard.structures.js";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";
import ColumnHeaders from "components/Leaderboard/molecules/ColumnHeaders";
import Rows from "pages/Leaderboards/GuildBattle/molecules/Rows";
import Title from "pages/Leaderboards/GuildBattle/atoms/Title";
import Divider from "pages/Leaderboards/GuildBattle/atoms/Divider";
import { GiSwordsEmblem } from "react-icons/gi";
import { IoCaretForwardCircleOutline } from "react-icons/io5";

const BattleLeaderboard = ({
  data,
  top8,
  top16,
  top25,
  type,
  loading,
  failed,
  retry,
  title,
  titleIcon,
  failedMessage = "Unable to load leaderboard",
  fullSize,
  button,
}) => {
  const theme = useTheme();
  const specs = leaderboardStructures[type];
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      gap={2}
      sx={{
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        padding: smUp ? "24px" : "15px",
        borderRadius: "8px",
        mb: 2,
      }}
    >
      <Title
        title={title || specs.title}
        titleIcon={titleIcon || <GiSwordsEmblem fontSize="35px" />}
      />

      <DisplayLoadingOrContent
        loading={loading}
        failed={failed}
        retry={retry}
        failedMessage={failedMessage}
        data={data}
      >
        <Stack gap={2}>
          <Stack>
            <ColumnHeaders specs={specs} fullSize={fullSize} />
            {top8 ? (
              <>
                <Divider />
                <Rows
                  data={top8}
                  specs={specs}
                  fullSize={fullSize}
                  // icon="⚔️"
                  // icon2={<GiTwoCoins color="#d4af37" />}
                  // topPrize={<GiCoins color="#d4af37" fontSize="28px" />}
                  // secondPlace={<FaCoins color="#d4af37" />}
                />
              </>
            ) : null}

            {top16 ? (
              <>
                <Divider />
                <Rows
                  data={top16}
                  specs={specs}
                  fullSize={fullSize}
                  // icon={<GiTwoCoins color="#d4af37" />}
                />
              </>
            ) : null}

            {top25 ? (
              <>
                <Divider text="The Chasing Pack" />
                <Rows data={top25} specs={specs} fullSize={fullSize} />
              </>
            ) : null}
            <Divider />
          </Stack>
          <Stack alignItems="flex-end" justifyContent="flex-end">
            {button ? (
              <Button
                href="/guild-membership"
                variant="contained"
                endIcon={<IoCaretForwardCircleOutline />}
              >
                Learn More
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </DisplayLoadingOrContent>
    </Stack>
  );
};

export default BattleLeaderboard;
