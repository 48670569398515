import React from "react";
import { Button, useTheme, Stack } from "@mui/material";
import { connect } from "react-redux";
import CardTemplate from "components/GameTemplates/CardTemplate";
import { getCardsToDisplay } from "store/teamBuilder/teamBuilderSelectors";
import MutedText from "components/Typography/MutedText";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { updateDisplayBanishCards } from "store/teamBuilder/teamBuilderActions";

const DeckView = ({
  cardsToDisplay,
  displayBanishCards,
  updateDisplayBanishCards,
}) => {
  const theme = useTheme();

  if (cardsToDisplay.length === 0) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: 634 }}
      >
        <MutedText>Wer Cards?</MutedText>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" gap={4}>
      <Stack
        direction="column"
        gap={2}
        sx={{
          p: 2,
          background: theme.palette.card.background,
          borderRadius: 4,
          boxShadow: theme.palette.card.boxShadow,
          m: "0 auto",
          [theme.breakpoints.down("md")]: {
            gap: 1,
            pr: 1,
          },
        }}
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          sx={{ maxWidth: 1240, m: "0 auto" }}
        >
          {cardsToDisplay.map((card, i) => {
            return (
              <CardTemplate
                card={card}
                width={178}
                key={`CARD_${i}`}
                style={{
                  transition: "0.2s transform ease",
                  "&:hover": {
                    cursor: "pointer",
                    transform: "scale(1.08)",
                  },
                }}
              />
            );
          })}
        </Stack>
      </Stack>

      <Button
        variant="contained"
        color="primary"
        endIcon={displayBanishCards ? <IoEyeOff /> : <IoEye />}
        onClick={() => updateDisplayBanishCards(!displayBanishCards)}
      >
        {displayBanishCards ? "Hide Banish Cards" : "Show Banish Cards"}
      </Button>
    </Stack>
  );
};

const mapDispatchToProps = { updateDisplayBanishCards };

const mapStateToProps = (state) => ({
  cardsToDisplay: getCardsToDisplay(state),
  displayBanishCards: state.teamBuilder.displayBanishCards,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeckView);
