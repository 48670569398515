import React, { useState } from "react";
import { Tooltip, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { MdInfo } from "react-icons/md";
import moment from "moment";
import { DATE_TIME_FORMAT } from "config";

const GameEnded = ({ end }) => {
  const endDateAndTime = moment.utc(end).format(DATE_TIME_FORMAT);
  // set tooltip open onhover for desktop and onclick for touchscreen
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const toggleTooltip = () => {
    setOpen((state) => (state === open ? !open : open));
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip open={open} title={`Game ended on ${endDateAndTime}`}>
      <IconButton
        onClick={mdDown ? toggleTooltip : null}
        onMouseEnter={mdDown ? null : handleTooltipOpen}
        onMouseLeave={mdDown ? null : handleTooltipClose}
        size="small"
        sx={{
          transition: "0.2s all ease",
          opacity: 0.5,
          padding: "4px",
          "&:hover": { opacity: 1 },
        }}
      >
        <MdInfo />
      </IconButton>
    </Tooltip>
  );
};

export default GameEnded;
