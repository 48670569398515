import { Tooltip, Typography, useTheme } from "@mui/material";

const NumberOfBattles = ({ energy, numberOfBattles }) => {
  const theme = useTheme();
  const color =
    numberOfBattles < energy ? theme.palette.feedback.red : "inherit";
  return (
    <Tooltip title={`# of PVP battles yesterday`}>
      <Typography color={color} fontSize="14px" variant="span">
        {numberOfBattles}
      </Typography>
    </Tooltip>
  );
};

export default NumberOfBattles;
