import { Stack, Typography } from "@mui/material";

const ChartError = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "300px" }}
    >
      <Typography
        component="span"
        fontWeight="700"
        color="text.muted"
        fontSize={16}
      >
        Cannot load chart data
      </Typography>
    </Stack>
  );
};

export default ChartError;
