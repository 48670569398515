import { Stack } from "@mui/material";
import Logo from "components/DoLL/Layout/Logo";
import Title from "../atoms/Title";

const Header = () => {
  return (
    <Stack gap={2} alignItems="center" sx={{ position: "relative", zIndex: 2 }}>
      <Logo />
      <Title />
    </Stack>
  );
};

export default Header;
