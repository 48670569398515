import moment from "moment";
import fonts from "theme/fonts";

const TICK_FONT = { size: 13, family: fonts.body, weight: 600 };
const DATE_FORMAT = "MMM D, YY";
const BAR_MARGIN = 1.4;
const DEFAULT_GRID_STYLES = (theme) => ({
  color: theme.palette.chart.gridlines,
  borderColor: theme.palette.chart.gridlines,
});
const formatDateTicks = (value, index, ticks) => {
  if (index === 0 || index === ticks.length - 1) {
    return "";
  }
  return Math.round((value / 1e6) * 10) / 10 + "M";
};

export function externalTooltipHandler(context) {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const chartHeight = chart.chartArea.height;
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const tooltipYPosition = chart.chartArea.top + chartHeight * 0.1;
  const DATE_FORMAT = "MMM D, YY";
  const theme = context?.tooltip.$context?.tooltip.dataPoints[0].dataset.theme;

  //   Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.visibility = "hidden";
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const headEl = document.createElement("div");

    // Add tooltip header
    titleLines.forEach((title) => {
      const headingEl = document.createElement("span");
      const text = document.createTextNode(moment(title).format(DATE_FORMAT));

      headingEl.appendChild(text);
      headEl.appendChild(headingEl);

      const headingStyles = {
        fontWeight: 800,
        marginBottom: "8px",
        display: "block",
        fontSize: "15px",
        color: theme.palette.text.bright,
      };
      Object.assign(headingEl.style, headingStyles);
    });

    const bodyEl = document.createElement("div");

    const bodyStyles = {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    };
    Object.assign(bodyEl.style, bodyStyles);

    // Add body lines
    bodyLines.forEach((body, i) => {
      const bodyArray = body[0].split(":");
      const label = bodyArray[0];
      const value = bodyArray[1];

      const labelEl = document.createElement("span");
      const labelText = document.createTextNode(label);
      labelEl.appendChild(labelText);
      const valueEl = document.createElement("span");
      const valueText = document.createTextNode(value);
      valueEl.appendChild(valueText);

      const valueStyles = {
        fontFamily: "monospace",
      };
      Object.assign(valueEl.style, valueStyles);

      const rowEl = document.createElement("div");

      const rowStyles = {
        display: "flex",
        justifyContent: "space-between",
        gap: "8px",
        color: tooltip.labelColors[i].backgroundColor,
        fontSize: "15px",
        whiteSpace: "nowrap",
        fontWeight: 600,
      };
      Object.assign(rowEl.style, rowStyles);

      rowEl.appendChild(labelEl);
      rowEl.appendChild(valueEl);

      bodyEl.appendChild(rowEl);
    });

    // Clear and append
    const rootEl = tooltipEl.querySelector(".tooltip-content");

    // Remove old children
    while (rootEl.firstChild) {
      rootEl.firstChild.remove();
    }

    // Add new children
    rootEl.appendChild(headEl);
    rootEl.appendChild(bodyEl);
  }

  const conditionalTransform =
    positionX + tooltip.caretX > chart.chartArea.width * 0.8
      ? {
          transform: "translateX(-100%)",
        }
      : { transform: "none" };

  // Display, position, and set styles for font
  const tooltipStyles = {
    visibility: "visible",
    position: "absolute",
    transition: "0.3s visibility ease",
    backgroundColor: theme.palette.popover.background,
    boxShadow: theme.palette.popover.boxShadow,
    borderRadius: "8px",
    padding: "8px",
    left: positionX + tooltip.caretX + "px",
    top: positionY + tooltipYPosition + "px",
    ...conditionalTransform,
  };
  Object.assign(tooltipEl.style, tooltipStyles);
}

function getOrCreateTooltip(chart) {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.setAttribute("id", "chart-tooltip");
    tooltipEl.setAttribute("role", "tooltip");

    const contentEl = document.createElement("div");
    contentEl.classList.add("tooltip-content");

    tooltipEl.appendChild(contentEl);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
}

const COMMON_OPTIONS = (theme) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    zoom: {
      zoom: {},
      pan: {},
    },
    tooltip: {
      enabled: false,
      mode: "index",
      position: "nearest",
      external: externalTooltipHandler,
    },
    legend: {
      position: "bottom",
      labels: {
        font: TICK_FONT,
        color: theme.palette.text.muted,
        padding: 20,
      },
    },
  },
});

export const createStackedOptions = (data, theme) => {
  return {
    ...COMMON_OPTIONS(theme),
    scales: {
      x: {
        grid: DEFAULT_GRID_STYLES(theme),
        stacked: true,
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: function (value, index, ticks) {
            return moment(data.labels[value]).format(DATE_FORMAT);
          },
        },
      },
      y: {
        grid: DEFAULT_GRID_STYLES(theme),
        stacked: true,
        min: 0,
        max: data.domain.y.max * BAR_MARGIN,
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: formatDateTicks,
        },
      },
    },
  };
};

export const createBarOptions = (data, theme) => {
  return {
    ...COMMON_OPTIONS(theme),
    scales: {
      x: {
        grid: DEFAULT_GRID_STYLES(theme),
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: function (value, index, ticks) {
            return moment(data.labels[value]).format(DATE_FORMAT);
          },
        },
      },
      y: {
        grid: DEFAULT_GRID_STYLES(theme),
        stacked: true,
        min: 0,
        max: data.domain.y.max * BAR_MARGIN,
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: formatDateTicks,
        },
      },
    },
  };
};

export const createDualBarOptions = (data, theme) => {
  return {
    ...COMMON_OPTIONS(theme),
    scales: {
      x: {
        grid: DEFAULT_GRID_STYLES(theme),
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: function (value, index, ticks) {
            return moment(data.labels[value]).format(DATE_FORMAT);
          },
        },
      },
      y: {
        grid: DEFAULT_GRID_STYLES(theme),
        min: 0,
        max: data.domain.y.max * BAR_MARGIN,
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: formatDateTicks,
        },
      },
    },
  };
};

export const createNetOptions = (data, theme) => {
  const maxVal = Math.max(
    Math.abs(data.domain.y.min),
    Math.abs(data.domain.y.max)
  );
  return {
    ...COMMON_OPTIONS(theme),
    scales: {
      x: {
        grid: DEFAULT_GRID_STYLES(theme),
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: function (value, index, ticks) {
            return moment(data.labels[value]).format(DATE_FORMAT);
          },
        },
      },
      y: {
        grid: DEFAULT_GRID_STYLES(theme),
        min: -maxVal * BAR_MARGIN,
        max: maxVal * BAR_MARGIN,
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: formatDateTicks,
        },
      },
    },
  };
};

export const createPositiveNegativeOptions = (data, theme) => {
  return {
    ...COMMON_OPTIONS(theme),
    scales: {
      x: {
        grid: DEFAULT_GRID_STYLES(theme),
        stacked: true,
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: function (value, index, ticks) {
            return moment(data.labels[value]).format(DATE_FORMAT);
          },
        },
      },
      y: {
        grid: DEFAULT_GRID_STYLES(theme),
        stacked: true,
        min: data.domain.y.min * BAR_MARGIN,
        max: -(data.domain.y.min * BAR_MARGIN),
        ticks: {
          font: TICK_FONT,
          color: theme.palette.text.muted,
          callback: formatDateTicks,
        },
      },
    },
  };
};

export const createOptions = {
  stacked: createStackedOptions,
  net: createNetOptions,
  positiveNegative: createPositiveNegativeOptions,
  dual: createDualBarOptions,
  bar: createBarOptions,
};

export const formatDataForChart = (data, theme) => {
  const colors = {
    slpBurnt: theme.palette.chart.background.purple,
    slpMinted: theme.palette.chart.background.teal,
    slpNet: theme.palette.chart.background.orange,
  };

  const dataForChart = {
    labels: data.labels,
    datasets: Object.keys(data.datasets).map((k) => ({
      label: data.datasets[k].label,
      backgroundColor: colors[k],
      data: data.datasets[k].data,
      barPercentage: 1,
      categoryPercentage: 0.85,
      hoverBackgroundColor: theme.palette.text.bright,
      theme,
    })),
  };

  return dataForChart;
};
