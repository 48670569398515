import * as types from "./playerActionTypes";
import produce from "immer";

const initialState = {
  loading: true,
  failed: false,
  battles: {
    loading: true,
  },
  controls: {
    currentPageBattles: 1,
  },
};

export const playerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_PLAYER_ORIGIN_STARTED:
        draft.loading = true;
        draft.failed = false;
        draft.data = undefined;
        draft.battles.loading = true;
        draft.battles.data = [];
        draft.controls.currentPageBattles = 1;
        break;

      case types.FETCH_PLAYER_ORIGIN_SUCCEEDED:
        draft.loading = false;
        draft.data = {
          clientId: action.clientId,
          ...action.data,
        };
        break;

      case types.FETCH_PLAYER_ORIGIN_FAILED:
        draft.loading = false;
        draft.failed = true;
        break;

      case types.FETCH_PLAYER_ORIGIN_BATTLES_STARTED:
        draft.battles.loading = true;
        draft.battles.failed = false;
        break;

      case types.FETCH_PLAYER_ORIGIN_BATTLES_SUCCEEDED:
        draft.battles.loading = false;
        draft.battles.data = action.data;
        break;

      case types.FETCH_PLAYER_ORIGIN_BATTLES_FAILED:
        draft.battles.loading = false;
        draft.battles.failed = true;
        break;

      case types.UPDATE_PLAYER_ORIGIN_BATTLES_PAGE:
        draft.controls.currentPageBattles = action.currentPage;
        break;

      default:
        break;
    }
  });

export default playerReducer;
