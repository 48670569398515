import { Tabs as MuiTabs, Tab } from "@mui/material";
import { a11yProps } from "helpers/accessibility";

const Tabs = ({ activeTab, setActiveTab, tabsId }) => {
  return (
    <MuiTabs
      value={activeTab}
      onChange={(e, newValue) => setActiveTab(newValue)}
      aria-label="Scholar Details"
      sx={{ "& .MuiTabs-flexContainer": { flexWrap: "wrap" } }}
    >
      <Tab disableRipple label="Overview" {...a11yProps(tabsId, 0)} />
      <Tab disableRipple label="Axies" {...a11yProps(tabsId, 1)} />
      <Tab disableRipple label="Inventory" {...a11yProps(tabsId, 2)} />
      <Tab disableRipple label="Battles" {...a11yProps(tabsId, 3)} />
      <Tab disableRipple label="Favorite Team" {...a11yProps(tabsId, 4)} />
    </MuiTabs>
  );
};

export default Tabs;
