import React from "react";
import BirthdayCake from "../atoms/BirthdayCake";
import { Typography, useTheme } from "@mui/material";

const ScholarName = ({ dob, name }) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="22px"
      color="text.bright"
      variant="span"
      fontWeight="600"
      sx={{
        lineHeight: 1.3,
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      }}
    >
      {name}
      <BirthdayCake dob={dob} name={name} />
    </Typography>
  );
};

export default ScholarName;
