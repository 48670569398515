import * as types from "./leaderboardActionTypes";
import { put, retry, takeEvery } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./leaderboardActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchGuildMMRLeaderboardRequest() {
  try {
    yield put(actions.fetchGuildMMRLeaderboardStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGuildMMRLeaderboard
    );
    yield put(actions.fetchGuildMMRLeaderboardSucceeded(data));
  } catch (e) {
    yield put(actions.fetchGuildMMRLeaderboardFailed());
  }
}

export default function* guildLeaderboardSaga() {
  yield takeEvery(
    types.FETCH_GUILD_ARENA_LEADERBOARD,
    makeFetchGuildMMRLeaderboardRequest
  );
}
