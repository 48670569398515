import { IconButton } from "@mui/material";
import React from "react";
import { FaDiscord, FaTwitter, FaGlobe } from "react-icons/fa";

const Social = ({ social }) => {
  const icons = {
    twitter: <FaTwitter />,
    discord: <FaDiscord />,
    website: <FaGlobe />,
  };

  return (
    <IconButton href={social.url} target="_blank">
      {icons[social.type]}
    </IconButton>
  );
};

export default Social;
