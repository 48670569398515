import { useState } from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Stack, ToggleButton, useTheme } from "@mui/material";
import { getCardsToDisplay } from "store/explorer/selectors";
import {
  favoriteCard,
  updateCardFilters,
} from "store/explorer/explorerActions";
import Sort from "../../molecules/Sort";
import InfoButton from "components/Origin/atoms/InfoButton";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";
import { getAllCardsV2 } from "store/data/dataSelectors";
import { STARTER_AXIE_PARTIDS } from "config";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import TriballyOriginsExplorerBanner from "components/TriballyAdBanner/TriballyOriginsExplorerBanner";

const Cards = ({
  cardsToDisplay,
  fetchingAxieCards,
  favoriteCard,
  cardFilters,
  updateCardFilters,
  setInfoDialogIsOpen,
  cardsV2,
}) => {
  const theme = useTheme();
  const [selected, setSelected] = useState(false);
  const excludeStarterAxieParts = cardsToDisplay.filter(
    (card) => !STARTER_AXIE_PARTIDS.includes(card.partId)
  );

  return (
    <>
      {fetchingAxieCards ? (
        <CustomLoader />
      ) : (
        <Stack gap={6}>
          <Stack
            alignItems="center"
            direction={{ sm: "column", md: "row" }}
            justifyContent="space-around"
            gap={1}
          >
            <TriballyOriginsExplorerBanner />

            <Stack
              alignItems="center"
              direction={{ sm: "column", md: "row" }}
              justifyContent="space-around"
              gap={1}
            >
              <ToggleButton
                sx={{
                  minWidth: 200,
                  padding: 1,
                  borderRadius: "8px",
                  height: 35,
                  border: `1px solid ${theme.palette.colors.origin.paper.dark}`,
                  backgroundColor: theme.palette.colors.origin.paper.light,
                  boxShadow: "none",
                  "&:active, &.Mui-focused, &:hover": {
                    backgroundColor: theme.palette.colors.origin.paper.medium,
                    border: `1px solid ${theme.palette.colors.origin.text.brown}`,
                    boxShadow: "none",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.colors.origin.paper.medium,
                    border: `1px solid ${theme.palette.colors.origin.text.brown}`,
                    boxShadow: "none",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: theme.palette.colors.origin.paper.light,
                  },
                }}
                value="toggleStarterParts"
                selected={selected}
                onChange={() => {
                  setSelected(!selected);
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  gap={1}
                  sx={{
                    fontSize: "16px",
                    color: theme.palette.colors.origin.text.brown,
                  }}
                >
                  {selected ? (
                    <FaEyeSlash fontSize={16} />
                  ) : (
                    <FaEye fontSize={16} />
                  )}{" "}
                  {selected ? (
                    <span>Hide Starter Cards</span>
                  ) : (
                    <span>Show Starter Cards</span>
                  )}
                </Stack>
              </ToggleButton>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                <Sort
                  filters={cardFilters}
                  updateFilters={updateCardFilters}
                  options={[
                    { label: "Name A-Z", value: "NAME_ASC" },
                    { label: "Name Z-A", value: "NAME_DESC" },
                    { label: "Highest Damage", value: "DAMAGE_DESC" },
                    { label: "Lowest Damage", value: "DAMAGE_ASC" },
                    { label: "Highest Shield", value: "SHIELD_DESC" },
                    { label: "Lowest Shield", value: "SHIELD_ASC" },
                    { label: "Highest Heal", value: "HEAL_DESC" },
                    { label: "Lowest Heal", value: "HEAL_ASC" },
                    { label: "Highest Energy", value: "ENERGY_DESC" },
                    { label: "Lowest Energy", value: "ENERGY_ASC" },
                  ]}
                />
                <InfoButton onClick={() => setInfoDialogIsOpen(true)} />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            gap={3}
            sx={{ px: 2 }}
          >
            {selected ? (
              <>
                {cardsToDisplay.map((card) => (
                  <WrappedCard
                    key={card.id}
                    card={card}
                    favoriteCard={favoriteCard}
                    cardsV2={cardsV2}
                  />
                ))}
              </>
            ) : (
              <>
                {excludeStarterAxieParts.map((card) => (
                  <WrappedCard
                    key={card.id}
                    card={card}
                    favoriteCard={favoriteCard}
                    cardsV2={cardsV2}
                  />
                ))}
              </>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapDispatchToProps = { favoriteCard, updateCardFilters };

const mapStateToProps = (state) => ({
  cardsToDisplay: getCardsToDisplay(state),
  fetchingCards: state.data.fetchingAxieCards,
  cardFilters: state.explorer.cardFilters,
  cardsV2: getAllCardsV2(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
