import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.muted,
  fontSize: 12,
  fontWeight: 600,
  userSelect: "none",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const Badge = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  borderRadius: "2px",
  width: 8,
  height: 8,
  background: color || theme.palette.text.regular,
}));

const RarityTag = ({ rarity, style }) => {
  if (rarity == null) {
    return null;
  }
  const colors = {
    Common: "#B2A1A1",
    Rare: "#9BD100",
    Epic: "#1AB3E3",
    Mystic: "#BD4EF1",
  };
  return (
    <Wrapper sx={style}>
      <Badge color={colors[rarity]} />
      {rarity}
    </Wrapper>
  );
};

export default RarityTag;
