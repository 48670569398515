import { Stack } from "@mui/material";
import { ReactNode } from "react";

const PageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Stack alignItems="center" gap={4} sx={{ pb: 12 }}>
      {children}
    </Stack>
  );
};

export default PageWrapper;
