import React, { useState, useEffect } from "react";
import { DEFAULT_NUMBER_OF_BREEDS_FOR_CALCULATOR } from "config";
import styles from "./inputs.module.scss";
import InventoryItems from "./InventoryItems";
import Parents from "./Parents";
import ChildSales from "./ChildSales";
import NumberOfBreeds from "./NumberOfBreeds";

const Inputs = ({
  updateBreedingCalculatorInputs,
  loadBreedingCalculator,
  firstLoad,
  currencies,
}) => {
  const [parent1BreedCount, setParent1BreedCount] = useState(0);
  const [parent2BreedCount, setParent2BreedCount] = useState(0);
  const [parent1Cost, setParent1Cost] = useState(0);
  const [parent2Cost, setParent2Cost] = useState(0);
  const [parent1Sale, setParent1Sale] = useState(0);
  const [parent2Sale, setParent2Sale] = useState(0);
  const [slp, setSLP] = useState(0);
  const [axs, setAXS] = useState(0);
  const [child1Sale, setChild1Sale] = useState(0);
  const [child2Sale, setChild2Sale] = useState(0);
  const [child3Sale, setChild3Sale] = useState(0);
  const [child4Sale, setChild4Sale] = useState(0);
  const [child5Sale, setChild5Sale] = useState(0);
  const [child6Sale, setChild6Sale] = useState(0);
  const [child7Sale, setChild7Sale] = useState(0);
  const [numberOfBreeds, setNumberOfBreeds] = useState(
    DEFAULT_NUMBER_OF_BREEDS_FOR_CALCULATOR
  );
  const [slpBasisCostETH, setSLPBasisCostETH] = useState(
    currencies["smooth-love-potion"]["eth"]
  );
  const [slpBasisCostUSD, setSLPBasisCostUSD] = useState(
    currencies["smooth-love-potion"]["usd"]
  );
  const [axsBasisCostETH, setAXSBasisCostETH] = useState(
    currencies["axie-infinity"]["eth"]
  );
  const [axsBasisCostUSD, setAXSBasisCostUSD] = useState(
    currencies["axie-infinity"]["usd"]
  );
  const [useMarketPriceSLP, setUseMarketPriceSLP] = useState(true);
  const [useMarketPriceAXS, setUseMarketPriceAXS] = useState(true);

  const arrayOfChildFuncs = [
    {
      childSale: child1Sale,
      setChildSale: setChild1Sale,
    },
    {
      childSale: child2Sale,
      setChildSale: setChild2Sale,
    },
    {
      childSale: child3Sale,
      setChildSale: setChild3Sale,
    },
    {
      childSale: child4Sale,
      setChildSale: setChild4Sale,
    },
    {
      childSale: child5Sale,
      setChildSale: setChild5Sale,
    },
    {
      childSale: child6Sale,
      setChildSale: setChild6Sale,
    },
    {
      childSale: child7Sale,
      setChildSale: setChild7Sale,
    },
  ];

  useEffect(() => {
    const constructBreedPayload = () => ({
      numberOfBreeds: parseInt(numberOfBreeds),
      parent1: {
        costETH: parseFloat(parent1Cost) || 0,
        saleETH: parseFloat(parent1Sale) || 0,
        breedCount: parseInt(parent1BreedCount),
      },
      parent2: {
        costETH: parseFloat(parent2Cost) || 0,
        saleETH: parseFloat(parent2Sale) || 0,
        breedCount: parseInt(parent2BreedCount),
      },
      inventory: {
        slp,
        axs,
        useMarketPrice: {
          slp: useMarketPriceSLP,
          axs: useMarketPriceAXS,
        },
        basisCosts: {
          axs: {
            eth: axsBasisCostETH,
            usd: axsBasisCostUSD,
          },
          slp: {
            eth: slpBasisCostETH,
            usd: slpBasisCostUSD,
          },
        },
      },
      childSalesETH: [
        parseFloat(child1Sale) || 0,
        parseFloat(child2Sale) || 0,
        parseFloat(child3Sale) || 0,
        parseFloat(child4Sale) || 0,
        parseFloat(child5Sale) || 0,
        parseFloat(child6Sale) || 0,
        parseFloat(child7Sale) || 0,
      ],
    });

    const payload = constructBreedPayload();
    updateBreedingCalculatorInputs(payload);
  }, [
    numberOfBreeds,
    parent1BreedCount,
    parent2BreedCount,
    parent1Cost,
    parent2Cost,
    parent1Sale,
    parent2Sale,
    slp,
    axs,
    axsBasisCostETH,
    axsBasisCostUSD,
    slpBasisCostETH,
    slpBasisCostUSD,
    useMarketPriceSLP,
    useMarketPriceAXS,
    child1Sale,
    child2Sale,
    child3Sale,
    child4Sale,
    child5Sale,
    child6Sale,
    child7Sale,
    updateBreedingCalculatorInputs,
  ]);

  const handleUpdateSLPCostETH = (e) => {
    const newValue = e.target.value;
    if (newValue === "") {
      setSLPBasisCostETH(currencies["smooth-love-potion"]["eth"]);
      setSLPBasisCostUSD(currencies["smooth-love-potion"]["usd"]);
    } else {
      setSLPBasisCostETH(newValue);
      setSLPBasisCostUSD(newValue * currencies["ethereum"]["usd"]);
    }
    handleUpdate();
  };

  const handleUpdateSLPCostUSD = (e) => {
    const newValue = e.target.value;
    if (newValue === "") {
      setSLPBasisCostETH(currencies["smooth-love-potion"]["eth"]);
      setSLPBasisCostUSD(currencies["smooth-love-potion"]["usd"]);
    } else {
      setSLPBasisCostUSD(newValue);
      setSLPBasisCostETH(newValue / currencies["ethereum"]["usd"]);
    }
    handleUpdate();
  };

  const handleUpdateAXSCostETH = (e) => {
    const newValue = e.target.value;
    if (newValue === "") {
      setAXSBasisCostETH(currencies["axie-infinity"]["eth"]);
      setAXSBasisCostUSD(currencies["axie-infinity"]["usd"]);
    } else {
      setAXSBasisCostETH(newValue);
      setAXSBasisCostUSD(newValue * currencies["ethereum"]["usd"]);
    }
    handleUpdate();
  };

  const handleUpdateAXSCostUSD = (e) => {
    const newValue = e.target.value;
    if (newValue === "") {
      setAXSBasisCostETH(currencies["axie-infinity"]["eth"]);
      setAXSBasisCostUSD(currencies["axie-infinity"]["usd"]);
    } else {
      setAXSBasisCostUSD(newValue);
      setAXSBasisCostETH(newValue / currencies["ethereum"]["usd"]);
    }
    handleUpdate();
  };

  const handleUpdate = () => {
    if (firstLoad) {
      loadBreedingCalculator();
    }
  };

  const inventoryTokens = [
    {
      name: "SLP",
      icon: "/images/icons/slp.png",
      iconAltText: "Axie Infinity Smooth Love Potions Tokens",
      ethPrice: currencies["smooth-love-potion"]["eth"],
      usdPrice: currencies["smooth-love-potion"]["usd"],
      setNum: setSLP,
      num: slp,
      setUseMarketPrice: setUseMarketPriceSLP,
      useMarketPrice: useMarketPriceSLP,
      setPriceUSD: handleUpdateSLPCostUSD,
      setPriceETH: handleUpdateSLPCostETH,
      basisCostUSD: slpBasisCostUSD,
      basisCostETH: slpBasisCostETH,
    },
    {
      name: "AXS",
      icon: "/images/icons/axs.png",
      iconAltText: "Axie Infinity Tokens",
      ethPrice: currencies["axie-infinity"]["eth"],
      usdPrice: currencies["axie-infinity"]["usd"],
      setNum: setAXS,
      num: axs,
      setUseMarketPrice: setUseMarketPriceAXS,
      useMarketPrice: useMarketPriceAXS,
      setPriceUSD: handleUpdateAXSCostUSD,
      setPriceETH: handleUpdateAXSCostETH,
      basisCostUSD: axsBasisCostUSD,
      basisCostETH: axsBasisCostETH,
    },
  ];

  return (
    <div className={styles.inputsWrapper}>
      <div className={styles.inputsColumn}>
        <Parents
          parent1BreedCount={parent1BreedCount}
          setParent1BreedCount={setParent1BreedCount}
          parent1Sale={parent1Sale}
          setParent1Sale={setParent1Sale}
          parent2BreedCount={parent2BreedCount}
          setParent2BreedCount={setParent2BreedCount}
          parent2Sale={parent2Sale}
          setParent2Sale={setParent2Sale}
          parent1Cost={parent1Cost}
          setParent1Cost={setParent1Cost}
          parent2Cost={parent2Cost}
          setParent2Cost={setParent2Cost}
          handleUpdate={handleUpdate}
        />
        <InventoryItems
          inventoryTokens={inventoryTokens}
          handleUpdate={handleUpdate}
        />
      </div>
      <div className={styles.inputsColumn}>
        <NumberOfBreeds
          numberOfBreeds={numberOfBreeds}
          setNumberOfBreeds={setNumberOfBreeds}
          handleUpdate={handleUpdate}
        />
        <ChildSales
          numberOfBreeds={numberOfBreeds}
          arrayOfChildFuncs={arrayOfChildFuncs}
          handleUpdate={handleUpdate}
        />
      </div>
    </div>
  );
};

export default Inputs;
