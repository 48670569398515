export const interDungeonTheme = {
  footer: {
    background: "#1A2C28",
    text: "#C4DED8",
    textHover: "#fff",
    headerText: "#fff",
  },
  navigation: {
    background: "#1A2C28",
    border: "solid 2px #141F1C",
    text: "#C4DED8",
    textHover: "#fff",
    dropdown: {
      text: "#141F1C",
      textHover: "#41514D",
      background: "#ECECEC",
      border: "none",
      sectionLabel: "#487B70",
    },
    logo: "#fff",
  },
};
