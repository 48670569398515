import * as types from "./recentlyActionTypes";

export const fetchRecentlyListed = () => ({
  type: types.FETCH_RECENTLY_LISTED,
});

export const fetchRecentlyListedStarted = () => ({
  type: types.FETCH_RECENTLY_LISTED_STARTED,
});

export const fetchRecentlyListedFailed = (e) => ({
  type: types.FETCH_RECENTLY_LISTED_FAILED,
});

export const fetchRecentlyListedSucceeded = (data) => ({
  type: types.FETCH_RECENTLY_LISTED_SUCCEEDED,
  data,
});

export const deleteRecentlyListedAxie = (axieId) => ({
  type: types.DELETE_RECENTLY_LISTED_AXIE,
  axieId,
});

export const clearRecentlyListed = () => ({
  type: types.CLEAR_RECENTLY_LISTED,
});

export const fetchRecentlySold = () => ({
  type: types.FETCH_RECENTLY_SOLD,
});

export const fetchRecentlySoldStarted = () => ({
  type: types.FETCH_RECENTLY_SOLD_STARTED,
});

export const fetchRecentlySoldFailed = (e) => ({
  type: types.FETCH_RECENTLY_SOLD_FAILED,
});

export const fetchRecentlySoldSucceeded = (data) => ({
  type: types.FETCH_RECENTLY_SOLD_SUCCEEDED,
  data,
});

export const deleteRecentlySoldAxie = (axieId) => ({
  type: types.DELETE_RECENTLY_SOLD_AXIE,
  axieId,
});

export const clearRecentlySold = () => ({
  type: types.CLEAR_RECENTLY_SOLD,
});
