import { createSelector } from "reselect";
import { parseCardEnergyToInt } from "helpers/cleaning";
import { itemIsFavorite } from "./";
import { matchesStr, matchesTagsOrText, strIsInArray } from "helpers/display";

export const getRevengeCards = (state) => {
  return state.data?.axieCardsV3.revengeCards || [];
};

export const getRevengeCardFilters = (state) => {
  return state.explorer?.revengeCardFilters || {};
};

export const getFavoriteRevengeCards = (state) => {
  return state.explorer?.favoriteRevengeCards || [];
};

const applyRevengeCardFilters = (card, filters) => {
  if (!filters || !card) {
    return true;
  }

  return (
    (matchesStr(card.name, filters.searchText) ||
      matchesStr(card.description, filters.searchText)) &&
    strIsInArray(card.cardEnergy, filters.costs) &&
    matchesTagsOrText(card.tags, card.cardText, filters.tags)
  );
};

const getRevengeCardType = (tags) => {
  const arr = tags.split(",");

  if (arr.includes("attack")) {
    return "attack";
  } else if (arr.includes("skill")) {
    return "skill";
  } else if (arr.includes("secret")) {
    return "secret";
  } else if (arr.includes("power")) {
    return "power";
  } else {
    return undefined;
  }
};

const getRevengeCardTypeSortIndex = (tags) => {
  const cardType = getRevengeCardType(tags);
  const index = {
    attack: 0,
    skill: 1,
    secret: 2,
    power: 3,
  };

  return index[cardType] || 0;
};

const isSortingByName = (sortType) => {
  if (["NAME_DESC", "NAME_ASC"].includes(sortType)) {
    return true;
  }
  return false;
};

const applySortRevengeCards = (a, b, sortType) => {
  const aCardTypeIndex = getRevengeCardTypeSortIndex(a.tags);
  const bCardTypeIndex = getRevengeCardTypeSortIndex(b.tags);

  const aEnergy = parseCardEnergyToInt(a.cardEnergy);
  const bEnergy = parseCardEnergyToInt(b.cardEnergy);

  if (isSortingByName(sortType)) {
    if (sortType === "NAME_DESC") {
      return b.name < a.name ? -1 : a.name < b.name ? 1 : 0;
    } else {
      return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
    }
  }
  if (sortType === "TYPE_DESC") {
    if (bCardTypeIndex < aCardTypeIndex) {
      return -1;
    } else if (aCardTypeIndex < bCardTypeIndex) {
      return 1;
    } else {
      return bEnergy < aEnergy ? 1 : aEnergy < bEnergy ? -1 : 0;
    }
  } else {
    if (bCardTypeIndex < aCardTypeIndex) {
      return 1;
    } else if (aCardTypeIndex < bCardTypeIndex) {
      return -1;
    } else {
      return bEnergy < aEnergy ? 1 : aEnergy < bEnergy ? -1 : 0;
    }
  }
};

export const getRevengeCardsToDisplay = createSelector(
  [getRevengeCards, getRevengeCardFilters, getFavoriteRevengeCards],
  (cards, filters, favorites) => {
    return [...cards]
      .filter((card) => applyRevengeCardFilters(card, filters))
      .sort((a, b) => applySortRevengeCards(a, b, filters.sortType))
      .map((card) => ({
        ...card,
        isFavorite: itemIsFavorite(card, "id", favorites),
      }));
  }
);

export const getFavoriteRevengeCardsToDisplay = createSelector(
  [getFavoriteRevengeCards],
  (favorites) => {
    return [...favorites].map((card) => ({
      ...card,
      isFavorite: true,
    }));
  }
);
