import MarketplaceLink from "./components/MarketplaceLink";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import RuneIcon from "components/GameTemplates/RuneIcon";
import Unknown from "./components/Unknown";
import { axieIsStarter, getAxieImageUrl } from "helpers/axie";
import Charms from "./components/Charms";

const Axie = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "image",
})(({ image }) => ({
  width: "100%",
  backgroundImage: `url(${image})`,
  backgroundSize: "130%",
  backgroundPosition: "center center",
  paddingBottom: "75%",
  backgroundRepeat: "no-repeat",
}));

const VariantImage = ({
  axie,
  width,
  disableMarketplaceLink,
  onClick,
  showCharms,
  showBodyParts,
  showRune,
}) => {
  const wrapperStyles = {
    width,
    position: "relative",
    ".runeIcon": {
      position: "absolute",
      top: 0,
      right: 0,
    },
  };
  return (
    <MarketplaceLink
      axie={axie}
      disable={disableMarketplaceLink || axieIsStarter(axie)}
    >
      <Stack onClick={onClick} sx={{ ...wrapperStyles }}>
        {axie == null ? (
          <Unknown width={width} />
        ) : (
          <Axie image={getAxieImageUrl(axie)} />
        )}
        {showRune && <RuneIcon rune={axie?.runeData} />}

        {showCharms && (
          <Charms
            charmData={axie?.charmData}
            width={(width / 80) * 192}
            showBodyParts={showBodyParts}
          />
        )}
      </Stack>
    </MarketplaceLink>
  );
};

export default VariantImage;
