export const guildBattleResults = [
  {
    id: 1,
    guildId: 278,
    guildName: "SURA",
    logoUri: "SURA",
    numScholars: 10,
    profileImage: "278-avatar",
    prize: "293 AXS",
  },
  {
    id: 2,
    guildId: 293,
    guildName: "Ancient8",
    logoUri: "Ancient8",
    numScholars: 5,
    profileImage: "293-avatar",
    prize: "210 AXS",
  },
  {
    id: 3,
    guildId: 245,
    guildName: "YGG Elite",
    logoUri: "YGG",
    numScholars: 13,
    profileImage: "245-avatar",
    prize: "135 AXS",
  },
  {
    id: 4,
    guildId: 270,
    guildName: "Method MetaGuild",
    logoUri: "Method",
    numScholars: 5,
    profileImage: "270-avatar",
    prize: "90 AXS",
  },
  {
    id: 5,
    guildId: 233,
    guildName: "METAT8",
    logoUri: "METAT8",
    numScholars: 14,
    profileImage: "233-avatar",
    prize: "30 AXS",
  },
  {
    id: 6,
    guildId: 254,
    guildName: "MVP",
    logoUri: "MVP",
    numScholars: 9,
    profileImage: "254-avatar",
    prize: "30 AXS",
  },
  {
    id: 7,
    guildId: 159,
    guildName: "Huru Gaming",
    logoUri: "Huru-Gaming",
    numScholars: 6,
    profileImage: "159-avatar",
    prize: "30 AXS",
  },
  {
    id: 8,
    guildId: 251,
    guildName: "Spriggan",
    logoUri: "Spriggan",
    numScholars: 5,
    profileImage: "251-avatar",
    prize: "30 AXS",
  },
  {
    id: 9,
    guildId: 258,
    guildName: "Gryphus",
    logoUri: "Gryphus",
    numScholars: 5,
    profileImage: "258-avatar",
    prize: "18 AXS",
  },
  {
    id: 10,
    guildId: 284,
    guildName: "Red Cloudz",
    logoUri: "Red-Cloudz",
    numScholars: 7,
    profileImage: "284-avatar",
    prize: "18 AXS",
  },
  {
    id: 11,
    guildId: 273,
    guildName: "PRIMOS WARRIORS GUILD",
    logoUri: "primos",
    numScholars: 10,
    profileImage: "273-avatar",
    prize: "18 AXS",
  },
  {
    id: 12,
    guildId: 286,
    guildName: "Morfyus League",
    logoUri: "Morfyus-League",
    numScholars: 5,
    profileImage: "286-avatar",
    prize: "18 AXS",
  },
  {
    id: 13,
    guildId: 289,
    guildName: "Crit Theory Gaming",
    logoUri: "CTG",
    numScholars: 5,
    profileImage: "289-avatar",
    prize: "18 AXS",
  },
  {
    id: 14,
    guildId: 298,
    guildName: "SolarMoon Gaming",
    logoUri: "SolarMoon",
    numScholars: 5,
    profileImage: "298-avatar",
    prize: "18 AXS",
  },
  {
    id: 15,
    guildId: 275,
    guildName: "Alianza Atlántica",
    logoUri: "Alianza",
    numScholars: 5,
    profileImage: "275-avatar",
    prize: "18 AXS",
  },
  {
    id: 16,
    guildId: 279,
    guildName: "Alpha Guild",
    logoUri: "Alpha-Guild",
    numScholars: 10,
    profileImage: "279-avatar",
    prize: "18 AXS",
  },
];
