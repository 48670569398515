import * as types from "./inspectorActionTypes";
import { augmentedAxieData } from "helpers/axie";
import produce from "immer";

export const initialState = {
  axie: { fetching: true, failed: false },
  lastSold: {},
  parents: {},
  numberOfSimilarAxies: {},
  cheapestSimilarListings: {},
  recentlySold: {},
  mysticFloor: {},
  axieData: {},
  estimatedPrice: {},
};

export const inspectorReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // ---- AXIE
      case types.INSPECTOR_FETCH_AXIE_STARTED:
        draft.axie = { fetching: true, failed: false };
        draft.lastSold = {};
        draft.parents = {};
        draft.numberOfSimilarAxies = {};
        draft.cheapestSimilarListings = {};
        draft.recentlySold = {};
        draft.mysticFloor = {};
        break;
      case types.INSPECTOR_FETCH_AXIE_FAILED:
        draft.axie.fetching = false;
        draft.axie.failed = true;
        break;
      case types.INSPECTOR_FETCH_AXIE_SUCCEEDED:
        draft.axie.fetching = false;
        draft.axie.axieId = action.data.axieId;
        draft.axieData[action.data.axieId] = {
          ...action.data,
          ...augmentedAxieData(action.data),
        };
        break;
      case types.INSPECTOR_AXIE_ALREADY_FETCHED:
        draft.axie.fetching = false;
        draft.axie.axieId = action.axieId;
        break;

      // ---- ESTIMATED AXIE SALE PRICE
      case types.INSPECTOR_FETCH_ESTIMATED_PRICE_STARTED:
        draft.estimatedPrice.fetching = true;
        draft.estimatedPrice.failed = false;
        break;
      case types.INSPECTOR_FETCH_ESTIMATED_PRICE_FAILED:
        draft.estimatedPrice.fetching = false;
        draft.estimatedPrice.failed = true;
        break;
      case types.INSPECTOR_FETCH_ESTIMATED_PRICE_SUCCEEDED:
        draft.estimatedPrice.fetching = false;
        draft.estimatedPrice.data = action.data;
        break;

      // ---- NUMBER OF SIMILAR AXIES
      case types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_STARTED:
        draft.numberOfSimilarAxies.fetching = true;
        draft.numberOfSimilarAxies.failed = false;
        break;
      case types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_FAILED:
        draft.numberOfSimilarAxies.fetching = false;
        draft.numberOfSimilarAxies.failed = true;
        break;
      case types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_SUCCEEDED:
        draft.numberOfSimilarAxies.fetching = false;
        draft.numberOfSimilarAxies.total = action.total;
        break;

      // ---- LAST SOLD
      case types.INSPECTOR_FETCH_LAST_SOLD_STARTED:
        draft.lastSold = {
          fetching: true,
          failed: false,
        };
        break;
      case types.INSPECTOR_FETCH_LAST_SOLD_FAILED:
        draft.lastSold.fetching = false;
        draft.lastSold.failed = true;
        break;
      case types.INSPECTOR_FETCH_LAST_SOLD_SUCCEEDED: {
        const results = action.lastSold.transferHistory.results;
        draft.lastSold.fetching = false;
        draft.lastSold.price =
          results.length > 0 ? results[0]?.withPrice : undefined;
        draft.lastSold.timestamp =
          results.length > 0 ? results[0]?.timestamp : undefined;
        break;
      }

      // ---- MYSTIC FLOOR
      case types.INSPECTOR_FETCH_MYSTIC_FLOOR_STARTED:
        draft.mysticFloor.fetching = true;
        draft.mysticFloor.failed = false;
        break;
      case types.INSPECTOR_FETCH_MYSTIC_FLOOR_FAILED:
        draft.mysticFloor.fetching = false;
        draft.mysticFloor.failed = true;
        break;
      case types.INSPECTOR_FETCH_MYSTIC_FLOOR_SUCCEEDED: {
        const results = action.mysticFloor.axies;
        draft.mysticFloor.fetching = false;
        draft.mysticFloor.total = action.mysticFloor.total;
        draft.mysticFloor.axieIds = action.mysticFloor.axies.map(
          (axie) => axie.axieId
        );
        draft.mysticFloor.price =
          results.length > 0 ? results[0]?.order.currentPrice : undefined;
        break;
      }
      case types.INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL_SUCCEEDED: {
        const axie = {
          ...action.data,
          ...augmentedAxieData(action.data),
        };
        draft.axieData[axie.axieId] = axie;
        break;
      }

      // ---- PARENTS
      case types.INSPECTOR_FETCH_PARENTS_STARTED:
        draft.parents.fetching = true;
        draft.parents.failed = false;
        break;
      case types.INSPECTOR_FETCH_PARENTS_FAILED:
        draft.parents.fetching = false;
        draft.parents.failed = true;
        break;
      case types.INSPECTOR_FETCH_PARENTS_SUCCEEDED: {
        let axieIds = [];
        action.parents.forEach((parent) => {
          axieIds.push(parent.axieId);
          draft.axieData[parent.axieId] = {
            ...parent,
            ...augmentedAxieData(parent),
          };
        });
        draft.parents.fetching = false;
        draft.parents.axieIds = axieIds;
        break;
      }

      // ---- CHEAPEST SIMILAR
      case types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_STARTED:
        draft.cheapestSimilarListings.fetching = true;
        draft.cheapestSimilarListings.failed = false;
        draft.cheapestSimilarListings.axieIds = [];
        break;
      case types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_FAILED:
        draft.cheapestSimilarListings.fetching = false;
        draft.cheapestSimilarListings.failed = true;
        break;
      case types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_SUCCEEDED: {
        draft.cheapestSimilarListings.fetching = false;
        draft.cheapestSimilarListings.total = action.data.total;
        draft.cheapestSimilarListings.axieIds = action.data.axies.map(
          (axie) => axie.axieId
        );
        break;
      }
      case types.INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL_SUCCEEDED: {
        const axie = {
          ...action.data,
          ...augmentedAxieData(action.data),
        };
        draft.axieData[axie.axieId] = axie;
        break;
      }

      // ---- RECENTLY SOLD SIMILAR AXIES
      case types.INSPECTOR_FETCH_RECENTLY_SOLD_STARTED:
        draft.recentlySold.fetching = true;
        draft.recentlySold.failed = false;
        draft.recentlySold.axieIds = [];
        break;
      case types.INSPECTOR_FETCH_RECENTLY_SOLD_FAILED:
        draft.recentlySold.fetching = false;
        draft.recentlySold.failed = true;
        break;
      case types.INSPECTOR_FETCH_RECENTLY_SOLD_SUCCEEDED:
        draft.recentlySold.fetching = false;
        break;
      case types.INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL_SUCCEEDED: {
        const results = action.lastSold.transferHistory.results;
        const axie = {
          ...action.data,
          ...augmentedAxieData(action.data),
          lastSold: {
            price: results.length > 0 ? results[0]?.withPrice : undefined,
            timestamp: results.length > 0 ? results[0]?.timestamp : undefined,
          },
        };
        draft.recentlySold.axieIds.push(axie.axieId);
        draft.axieData[axie.axieId] = axie;
        break;
      }

      default:
        break;
    }
  });

export default inspectorReducer;
