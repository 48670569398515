import { Stack } from "@mui/material";
import RarityTag from "components/Items/RarityTag";
import BlockchainStatus from "components/Items/BlockchainStatus";
import Season from "components/Items/Season";

const ItemDetails = ({ rarity, season, isBlockchain }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <RarityTag rarity={rarity} />
        <Season season={season} />
      </Stack>
      <BlockchainStatus isBlockchain={isBlockchain} />
    </Stack>
  );
};

export default ItemDetails;
