import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import InterDungeonTooltip from "components/InterDungeon/InterDungeonCard/atoms/InterDungeonTooltip";

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "width" })(
  ({ width = 200 }) => ({
    display: "flex",
    width: `${(width / 200) * 30}px`,
    minWidth: `${(width / 200) * 30}px`,
    height: `100%`,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    img: {
      width: `100%`,
      height: "auto",
    },
  })
);

const ManaIcon = ({ crystalColor, crystalAmount, equippableSlot, width }) => {
  const crystalImage = `/images/games/inter-the-dungeon/icons/mana/${crystalColor}-mana.png`;
  if (crystalAmount !== 0) {
    return (
      <InterDungeonTooltip
        title={
          equippableSlot ? (
            <>
              It costs {crystalAmount} {crystalColor} mana to equip this item
            </>
          ) : (
            <>
              It costs {crystalAmount} {crystalColor} mana to use this item
            </>
          )
        }
      >
        <Wrapper id="mana-value">
          <Typography
            sx={{
              color: crystalColor === "yellow" ? "#000000" : "#ffffff",
              fontSize: (width / 200) * 11,
              fontWeight: 700,
              position: "absolute",
              bottom: (width / 200) * 3,
            }}
            variant="span"
          >
            {crystalAmount}
          </Typography>
          <img
            style={{ width: (width / 200) * 20 }}
            src={crystalImage}
            alt={`${crystalAmount} ${crystalColor} mana`}
          />
        </Wrapper>
      </InterDungeonTooltip>
    );
  }
};

export default ManaIcon;
