import * as types from "store/guildDashboard/actions/actionTypes";
import { put, call, select, takeEvery, delay } from "redux-saga/effects";
import { axieTechAPI } from "store/sagaHelpers";
import { getAllScholars } from "store/guildDashboard/selectors/common";
import { createScholarPayload } from "helpers/scholars";
import endpoints from "services/api/endpoints";

export function* fetchScholarSnapshot(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_STARTED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "GET",
    };
    yield delay(2000);
    const response = yield call(
      axieTechAPI,
      endpoints.constructGuildScholarSnapshot(
        action.guildSlug,
        action.roninAddress
      ),
      params
    );
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_SUCCEEDED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_FAILED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* addScholar(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_ADD_SCHOLAR_STARTED,
    });
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        ...action.scholarData,
      }),
    };
    yield call(axieTechAPI, endpoints.scholar, params);
    yield put({
      type: types.GUILD_DASHBOARD_ADD_SCHOLAR_SUCCEEDED,
      scholarData: action.scholarData,
    });
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT,
      roninAddress: action.scholarData.roninAddress,
      guildSlug: action.guildSlug,
      guildTeamId: action.scholarData.guildTeamId,
    });
    yield put({
      type: types.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR,
      roninAddress: action.scholarData.roninAddress,
      start: 0,
      guildTeamId: action.scholarData.guildTeamId,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_ADD_SCHOLAR_FAILED,
    });
  }
}

export function* importScholars(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_IMPORT_SCHOLARS_STARTED,
    });
    for (const scholar in action.scholars) {
      yield call(addScholar, {
        scholarData: action.scholars[scholar],
        loginAddress: action.loginAddress,
        guildSlug: action.guildSlug,
      });
    }
    yield put({
      type: types.GUILD_DASHBOARD_IMPORT_SCHOLARS_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_IMPORT_SCHOLARS_FAILED,
    });
  }
}

export function* deleteScholar(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_DELETE_SCHOLAR_STARTED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        roninAddress: action.roninAddress,
      }),
    };
    yield call(axieTechAPI, endpoints.scholar, params);
    yield put({
      type: types.GUILD_DASHBOARD_DELETE_SCHOLAR_SUCCEEDED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_DELETE_SCHOLAR_FAILED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* editScholar(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_EDIT_SCHOLAR_STARTED,
      roninAddress: action.scholarData.roninAddress,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        ...action.scholarData,
      }),
    };
    yield call(axieTechAPI, endpoints.scholar, params);
    yield put({
      type: types.GUILD_DASHBOARD_EDIT_SCHOLAR_SUCCEEDED,
      scholarData: action.scholarData,
      guildTeamId: action.guildTeamId,
      roninAddress: action.scholarData.roninAddress,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_EDIT_SCHOLAR_FAILED,
      roninAddress: action.scholarData.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* moveScholarsToTeam(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_STARTED,
    });
    const allScholars = yield select((state) => getAllScholars(state));

    for (const idx in action.roninAddresses) {
      const scholar = allScholars.find(
        (scholar) => scholar.roninAddress === action.roninAddresses[idx]
      );

      const payload = createScholarPayload(
        scholar.name,
        scholar.managerShare,
        scholar.roninAddress,
        scholar.payoutAddress,
        scholar.emailAddress,
        scholar.twitterId,
        scholar.discordId,
        scholar.region,
        scholar.country,
        scholar.dob,
        scholar.dateJoined,
        scholar.notes,
        scholar.dailyQuota,
        scholar.guildTeamId
      );

      const oldGuildTeamId = payload.guildTeamId;
      payload.guildTeamId = action.newGuildTeamId;

      yield call(editScholar, {
        loginAddress: action.loginAddress,
        scholarData: payload,
        guildTeamId: oldGuildTeamId,
      });
    }

    yield put({
      type: types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_SUCCEEDED,
    });
  } catch (e) {
    console.log("Error moving scholars to team", e);
    yield put({
      type: types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_FAILED,
    });
  }
}

export function* watchScholar(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_WATCH_SCHOLAR_STARTED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        roninAddress: action.roninAddress,
      }),
    };
    yield call(axieTechAPI, endpoints.watchlist, params);
    yield put({
      type: types.GUILD_DASHBOARD_WATCH_SCHOLAR_SUCCEEDED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_WATCH_SCHOLAR_FAILED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  }
}

export function* unWatchScholar(action) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_UNWATCH_SCHOLAR_STARTED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
        roninAddress: action.roninAddress,
      }),
    };
    yield call(axieTechAPI, endpoints.watchlist, params);
    yield put({
      type: types.GUILD_DASHBOARD_UNWATCH_SCHOLAR_SUCCEEDED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  } catch (e) {
    yield put({
      type: types.GUILD_DASHBOARD_UNWATCH_SCHOLAR_FAILED,
      roninAddress: action.roninAddress,
      guildTeamId: action.guildTeamId,
    });
  }
}

export default function* guildDashboardScholarSaga() {
  yield takeEvery(types.GUILD_DASHBOARD_ADD_SCHOLAR, addScholar);
  yield takeEvery(types.GUILD_DASHBOARD_IMPORT_SCHOLARS, importScholars);
  yield takeEvery(types.GUILD_DASHBOARD_DELETE_SCHOLAR, deleteScholar);
  yield takeEvery(types.GUILD_DASHBOARD_EDIT_SCHOLAR, editScholar);
  yield takeEvery(
    types.GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT,
    fetchScholarSnapshot
  );
  yield takeEvery(types.GUILD_DASHBOARD_WATCH_SCHOLAR, watchScholar);
  yield takeEvery(types.GUILD_DASHBOARD_UNWATCH_SCHOLAR, unWatchScholar);
  yield takeEvery(
    types.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM,
    moveScholarsToTeam
  );
}
