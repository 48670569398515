import React from "react";
import { Stack } from "@mui/material";
import {
  updateToolFilters,
  resetToolFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";

const ToolControls = ({ toolFilters, updateToolFilters, resetToolFilters }) => {
  const handleUpdateToolFilters = (newFilters) => {
    updateToolFilters({ ...toolFilters, ...newFilters });
  };
  return (
    <Stack gap={2}>
      <Search
        value={toolFilters.searchText}
        handleUpdate={handleUpdateToolFilters}
        placeholder="Search tools"
      />
      <TextButton
        onClick={resetToolFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateToolFilters,
  resetToolFilters,
};

const mapStateToProps = (state) => ({
  toolFilters: state.explorer.toolFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolControls);
