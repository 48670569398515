import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const YouTubeEmbed = ({ embedId, title, style = {} }) => (
  <Box
    sx={{
      overflow: "hidden",
      pb: "56.25%",
      position: "relative",
      height: 0,
      iframe: {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
      },
      ...style,
    }}
  >
    <iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allowFullScreen
      title={title}
    />
  </Box>
);

YouTubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default YouTubeEmbed;
