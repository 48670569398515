import React from "react";
import { Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.card.divider}`,

  svg: {
    color: theme.palette.text.secondary,
  },
  img: {
    width: "20px",
  },
}));

const Title = ({ title, titleIcon, titleTooltip }) => {
  if (title == null) {
    return null;
  }

  return (
    <Wrapper>
      {titleIcon}
      <Typography
        variant="span"
        color="text.bright"
        fontSize={19}
        fontWeight={600}
        lineHeight="1.2"
      >
        {title}
      </Typography>
      {titleTooltip != null && titleTooltip}
    </Wrapper>
  );
};

export default Title;
