import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import HiddenScholarMessage from "../molecules/HiddenScholarMessage";

const Wrapper = styled(Stack)(
  ({ theme, showScholars, numScholars }) => ({
    height: 0,
    overflow: "hidden",
    marginLeft: theme.spacing(3),
    ...(showScholars && {
      height: `${numScholars * 32}px`,
    }),
  })
);

const HiddenScholars = ({ scholars }) => {
  const [showScholars, setShowScholars] = useState(false);

  if (!scholars || scholars.length === 0) {
    return null;
  }

  return (
    <Stack gap={2}>
      <HiddenScholarMessage
        numScholars={scholars.length}
        showScholars={showScholars}
        setShowScholars={setShowScholars}
      />
      <Wrapper
        numScholars={scholars.length}
        showScholars={showScholars}
      >
        {scholars.map((scholar, i) => (
          <Typography
            variant="span"
            fontSize="18px"
            color="text.regular"
            sx={{ lineHeight: 2 }}
            key={`HIDDEN_SCHOLAR_${i}`}
          >
            ● {scholar.name}
          </Typography>
        ))}
      </Wrapper>
    </Stack>
  );
};

export default HiddenScholars;
