import { Stack } from "@mui/material";
import Title from "../atoms/Title";
import ArenaChart from "../atoms/ArenaChart";

const ArenaChartWrapper = ({ data }) => {
  return (
    <Stack gap={1}>
      <Title>Victory Stars</Title>
      <ArenaChart data={data} />
    </Stack>
  );
};

export default ArenaChartWrapper;
