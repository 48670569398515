import * as types from "./adminActionTypes";
import produce from "immer";

const initialState = {
  guilds: {
    fetching: false,
    failed: false,
    data: [],
  },
  status: {
    addingGuild: false,
    addingGuildFailed: false,
    updatingGuild: false,
    updatingGuildFailed: false,
  },
  controls: {
    searchQuery: "",
    showInactive: false,
  },
};

export const adminReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // ---------------------- FETCH
      case types.ADMIN_FETCH_GUILDS_STARTED:
        draft.guilds.fetching = true;
        draft.guilds.failed = false;
        break;
      case types.ADMIN_FETCH_GUILDS_SUCCEEDED:
        draft.guilds.fetching = false;
        draft.guilds.data = action.data;
        break;
      case types.ADMIN_FETCH_GUILDS_FAILED:
        draft.guilds.fetching = false;
        draft.guilds.failed = true;
        if (action.error === "UNAUTHORIZED") {
          draft.guilds.unauthorized = true;
        }
        break;

      // ---------------------- ADD
      case types.ADMIN_ADD_GUILD_STARTED:
        draft.status.addingGuild = true;
        draft.status.addingGuildFailed = false;
        break;
      case types.ADMIN_ADD_GUILD_SUCCEEDED:
        draft.status.addingGuild = false;
        if (draft.guilds.data) {
          draft.guilds.data.push(action.data);
        } else {
          draft.guilds.data = [action.data];
        }
        break;
      case types.ADMIN_ADD_GUILD_FAILED:
        draft.status.addingGuild = false;
        draft.status.addingGuildFailed = true;
        break;

      // ---------------------- UPDATE
      case types.ADMIN_UPDATE_GUILD_STARTED:
        draft.status.updatingGuild = true;
        draft.status.updatingGuildFailed = false;
        break;
      case types.ADMIN_UPDATE_GUILD_SUCCEEDED:
        draft.status.updatingGuild = false;
        let guild = draft.guilds.data.find(
          (g) => g.guildId === action.data.guildId
        );
        if (guild) {
          guild = Object.assign(guild, action.data);
        }
        break;
      case types.ADMIN_UPDATE_GUILD_FAILED:
        draft.status.updatingGuild = false;
        draft.status.updatingGuildFailed = true;
        break;

      // ---------------------- CONTROLS
      case types.ADMIN_UPDATE_SEARCH_QUERY:
        draft.controls.searchQuery = action.searchQuery;
        break;
      case types.ADMIN_TOGGLE_SHOW_INACTIVE:
        draft.controls.showInactive = action.showInactive;
        break;

      default:
        break;
    }
  });

export default adminReducer;
