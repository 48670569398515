import React, { useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import {
  getShareLink,
  calculateNumberOfFavorites,
} from "store/explorer/selectors";
import { clearAllFavorites } from "store/explorer/explorerActions";
import { connect } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoLink } from "react-icons/io5";

const FavoriteControls = ({
  shareLink,
  clearAllFavorites,
  numberOfFavorites,
}) => {
  const [hasClickedCopy, setHasClickedCopy] = useState(false);

  useEffect(() => {
    if (hasClickedCopy) {
      setTimeout(() => {
        setHasClickedCopy(false);
      }, 1000);
    }
  }, [hasClickedCopy]);

  const handleClickCopy = () => {
    setHasClickedCopy(true);
  };

  const handleClearAllFavorites = () => {
    clearAllFavorites();
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "row" }}
      alignItems="center"
      gap={2}
      sx={{
        "& .MuiButton-root": { minWidth: 180 },
      }}
    >
      <CopyToClipboard text={shareLink}>
        <Button
          variant="origin"
          endIcon={hasClickedCopy ? <FaCheckCircle /> : <IoLink />}
          onClick={handleClickCopy}
        >
          {hasClickedCopy ? "Copied" : "Copy share link"}
        </Button>
      </CopyToClipboard>
      {numberOfFavorites > 0 && (
        <Button
          variant="origin"
          onClick={handleClearAllFavorites}
          sx={{ minWidth: 180 }}
        >
          Clear All
        </Button>
      )}
    </Stack>
  );
};

const mapDispatchToProps = { clearAllFavorites };

const mapStateToProps = (state) => ({
  shareLink: getShareLink(state),
  numberOfFavorites: calculateNumberOfFavorites(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteControls);
