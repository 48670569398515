import * as types from "./adminActionTypes";

// Fetch Guilds
export const fetchGuilds = (loginAddress) => ({
  type: types.ADMIN_FETCH_GUILDS,
  loginAddress,
});

export const fetchGuildsStarted = () => ({
  type: types.ADMIN_FETCH_GUILDS_STARTED,
});

export const fetchGuildsSucceeded = (data) => ({
  type: types.ADMIN_FETCH_GUILDS_SUCCEEDED,
  data,
});

export const fetchGuildsFailed = (error) => ({
  type: types.ADMIN_FETCH_GUILDS_FAILED,
  error,
});

// Add Guild
export const addGuild = (loginAddress, guild, onSuccess) => ({
  type: types.ADMIN_ADD_GUILD,
  guild,
  loginAddress,
  onSuccess,
});

export const addGuildStarted = () => ({
  type: types.ADMIN_ADD_GUILD_STARTED,
});

export const addGuildSucceeded = (data) => ({
  type: types.ADMIN_ADD_GUILD_SUCCEEDED,
  data,
});

export const addGuildFailed = () => ({
  type: types.ADMIN_ADD_GUILD_FAILED,
});

// Update Guild
export const updateGuild = (loginAddress, guild, onSuccess) => ({
  type: types.ADMIN_UPDATE_GUILD,
  guild,
  loginAddress,
  onSuccess,
});

export const updateGuildStarted = () => ({
  type: types.ADMIN_UPDATE_GUILD_STARTED,
});

export const updateGuildSucceeded = (data) => ({
  type: types.ADMIN_UPDATE_GUILD_SUCCEEDED,
  data,
});

export const updateGuildFailed = () => ({
  type: types.ADMIN_UPDATE_GUILD_FAILED,
});

// Update Search Query
export const updateSearchQuery = (searchQuery) => ({
  type: types.ADMIN_UPDATE_SEARCH_QUERY,
  searchQuery,
});

// Toggle show inactive
export const toggleShowInactive = (showInactive) => ({
  type: types.ADMIN_TOGGLE_SHOW_INACTIVE,
  showInactive,
});

// Get presigned url for image upload
export const requestPresignedUrl = (
  loginAddress,
  guildId,
  filename,
  fileExtension,
  dataUri,
  onSuccess
) => ({
  type: types.ADMIN_REQUEST_PRESIGNED_URL,
  loginAddress,
  guildId,
  filename,
  fileExtension,
  dataUri,
  onSuccess,
});

export const requestPresignedUrlStarted = () => ({
  type: types.ADMIN_REQUEST_PRESIGNED_URL_STARTED,
});

export const requestPresignedUrlSucceeded = () => ({
  type: types.ADMIN_REQUEST_PRESIGNED_URL_SUCCEEDED,
});

export const requestPresignedUrlFailed = () => ({
  type: types.ADMIN_REQUEST_PRESIGNED_URL_FAILED,
});
