export const body = '"Poppins", "sans-serif"';
export const changa = "'Changa One', sans-serif";
export const caesar = "'Caesar Dressing', sans-serif";
export const roboto = "'Roboto', sans serif";

const fonts = {
  body,
  changa,
  caesar,
  roboto,
};

export default fonts;
