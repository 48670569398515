import React from "react";
import styled from "@emotion/styled";
import { Button, Stack } from "@mui/material";
import { FaPlay } from "react-icons/fa";
import Logo from "./Logo";
import { AXIE_DOLL_WEBSITE } from "config";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "shiftAmount",
})(({ theme, shiftAmount }) => ({
  alignItems: "center",
  gap: theme.spacing(6),
  marginTop: -shiftAmount,
  position: "relative",
}));

const Logos = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
}));

const PageFooter = () => {
  const logoWidth = 80;
  const shiftAmount = ((967 / 844) * logoWidth) / 2;
  return (
    <Wrapper className="pageFooter" shiftAmount={shiftAmount}>
      <Logos>
        <Logo width={logoWidth} variant="mark" />
        <Logo width={logoWidth} variant="mark" />
        <Logo width={logoWidth} variant="mark" />
      </Logos>
      <Button
        variant="doll"
        endIcon={<FaPlay />}
        href={AXIE_DOLL_WEBSITE}
        target="_blank"
      >
        Play DoLL
      </Button>
    </Wrapper>
  );
};

export default PageFooter;
