import React from "react";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from "@mui/material";
import { AXIE_INFINITY_TRANSFER_HISTORY_API_FUNCTIONAL } from "config";
import styled from "@emotion/styled";
import views from "./views";

const Nav = ({ view, setView, inspector }) => {
  const handleUpdate = (e, newView) => {
    if (newView != null) {
      setView(newView);
    }
  };

  return (
    <ToggleButtonGroup exclusive value={view} onChange={handleUpdate}>
      <ToggleButton size="small" value={views.profile} aria-label="profile">
        Profile
      </ToggleButton>

      {inspector.axie.cards != null && (
        <ToggleButton size="small" value={views.cards} aria-label="cards">
          Cards
        </ToggleButton>
      )}

      {inspector.axieDoLL.cards != null && (
        <ToggleButton
          size="small"
          value={views.axieDoLL}
          aria-label="axie doll"
        >
          Axie DoLL
        </ToggleButton>
      )}

      {inspector.market.display && (inspector.market.total || 0) !== 0 && (
        <ToggleButton
          size="small"
          value={views.similarListings}
          aria-label="similar listings"
        >
          Similar Listings
        </ToggleButton>
      )}

      {AXIE_INFINITY_TRANSFER_HISTORY_API_FUNCTIONAL && (
        <ToggleButton
          size="small"
          value={views.recentSales}
          aria-label="Recent Sales"
        >
          Recent Sales
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  alignSelf: "flex-start",
  background: theme.palette.inspector.shaded,
  borderRadius: "8px",
  "> .MuiToggleButton-root": { fontWeight: "600" },
  "& .MuiToggleButtonGroup-grouped": {
    margin: "4px",
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type), &:first-of-type": {
      borderRadius: "4px",
    },
  },
  [theme.breakpoints.down("md")]: {
    position: "static",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  [theme.breakpoints.down("sm")]: {
    background: "transparent",
    gap: 1,
  },
}));

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  border: "none",
  color: theme.palette.text.muted,
  backgroundColor: "transparent",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 15,
    background: theme.palette.inspector.shaded,
  },
  "&:hover": {
    color: theme.palette.text.bright,
    backgroundColor: theme.palette.card.background,
  },
  "&.Mui-selected": {
    color: theme.palette.text.bright,
    backgroundColor: theme.palette.card.background,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.06)",
    "&:hover": {
      color: theme.palette.text.bright,
      backgroundColor: theme.palette.card.background,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.card.background,
      },
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.card.background,
    },
  },
}));

export default Nav;
