import { Button, Stack, Grid, Container } from "@mui/material";
import MainTitle from "pages/Home/atoms/MainTitle";
import Project from "pages/Games/molecules/Project";
import data from "pages/Games/data";
import { FaArrowCircleRight } from "react-icons/fa";
import CustomLink from "components/CustomLink/CustomLink";

const DiscoverGamesWidget = () => {
  return (
    <Container>
      <Stack gap={2} alignItems="center" justifyContent="center">
        <MainTitle
          styles={{
            textShadow: "0 0 12px #131e24",
            color: "#fff",
          }}
        >
          Discover Axie Infinity Games
        </MainTitle>

        <CustomLink to="/games">
          <Button
            variant="outlined"
            endIcon={<FaArrowCircleRight />}
            sx={{
              alignSelf: "center",
              color: "#fff",
              borderColor: "#fff",
              mb: 2,
              "&:hover": {
                borderColor: "#fff",
              },
            }}
          >
            More Games
          </Button>
        </CustomLink>

        <Grid gap={2} container justifyContent="space-between">
          <Grid item xs={12} sm={12} md={3.8}>
            <Project
              fontSize={22}
              direction="column"
              gap={1}
              project={data[0]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.8}>
            <Project
              fontSize={22}
              direction="column"
              gap={1}
              project={data[2]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.8}>
            <Project
              fontSize={22}
              direction="column"
              gap={1}
              project={data[8]}
            />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default DiscoverGamesWidget;
