import React from "react";

const DialogForm = ({ children, ...other }) => {
  return (
    <form autoComplete="off" spellCheck="false" {...other}>
      {children}
    </form>
  );
};

export default DialogForm;
