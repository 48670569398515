import { useState } from "react";
import { Stack } from "@mui/material";
import { RiPencilFill } from "react-icons/ri";
import { HiPlus } from "react-icons/hi";
import EditTeamDialog from "../organisms/EditTeamDialog";
import AddScholarDialog from "../../Scholars/organisms/AddScholarDialog";
import { Button, Menu, MenuItem, useTheme } from "@mui/material";
import MoveScholarDialog from "../organisms/MoveScholarDialog";
import { connect } from "react-redux";
import { setOpenEditTeamDialog } from "store/guildDashboard/actions/guildDashboardActions";
import DeleteBattleTeamDialog from "../organisms/DeleteBattleTeamDialog";
import { GUILD_BATTLE_TEAM_NAME } from "config";

const Controls = ({
  teamName,
  guildTeamId,
  setOpenEditTeamDialog,
  openEditTeamDialog,
}) => {
  const [openAddScholarDialog, setOpenAddScholarDialog] = useState(false);
  const [openMoveScholarDialog, setOpenMoveScholarDialog] = useState(false);
  const [openDeleteBattleTeamDialog, setOpenDeleteBattleTeamDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const open = Boolean(anchorEl);

  const handleDeleteBattleTeam = () => {
    setOpenDeleteBattleTeamDialog(true);
  };

  const handleClickEdit = () => {
    setOpenEditTeamDialog(true);
  };

  const handleClickMoveScholar = () => {
    setOpenMoveScholarDialog(true);
    setAnchorEl(null);
  };

  const handleClickAddScholar = () => {
    setOpenAddScholarDialog(true);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Stack direction="row" gap={1}>
      {teamName === GUILD_BATTLE_TEAM_NAME ? (
        <Button
          onClick={handleDeleteBattleTeam}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.feedback.red,
            "&:hover": {
              backgroundColor: theme.palette.feedback.red,
            },
          }}
        >
          Delete Team
        </Button>
      ) : (
        <Button
          variant="outlined"
          endIcon={<RiPencilFill />}
          onClick={handleClickEdit}
          color="secondary"
        >
          Edit Team
        </Button>
      )}
      <Button
        variant="outlined"
        endIcon={<HiPlus />}
        color="secondary"
        id="add-scholar-button"
        aria-controls="add-scholar-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
      >
        Add Scholar
      </Button>
      <Menu
        id="add-scholar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "add-scholar-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleClickAddScholar}>Add new scholar</MenuItem>
        <MenuItem onClick={handleClickMoveScholar}>
          Move existing scholar
        </MenuItem>
      </Menu>
      <MoveScholarDialog
        open={openMoveScholarDialog}
        setOpen={setOpenMoveScholarDialog}
        forceGuildTeamId={guildTeamId}
      />
      <AddScholarDialog
        open={openAddScholarDialog}
        setOpen={setOpenAddScholarDialog}
        forceGuildTeamId={guildTeamId}
      />
      <EditTeamDialog
        open={openEditTeamDialog}
        setOpen={setOpenEditTeamDialog}
      />
      <DeleteBattleTeamDialog
        open={openDeleteBattleTeamDialog}
        setOpen={setOpenDeleteBattleTeamDialog}
      />
    </Stack>
  );
};

const mapDispatchToProps = { setOpenEditTeamDialog };

const mapStateToProps = (state) => ({
  activeGuildTeamId: state.guildDashboard.guild.activeGuildTeamId,
  openEditTeamDialog: state.guildDashboard.openEditTeamDialog,
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
