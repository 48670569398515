import CardTitle from "../atoms/CardTitle";
import { Grid, Stack } from "@mui/material";
import MoreLink from "../atoms/MoreLink";
import RecentBattle from "./RecentBattle";
import { BattleSummary } from "types";

interface Props {
  recentBattleSummary: BattleSummary;
  setActiveTab: (newValue: number) => void;
  showAxieDetail: boolean;
}

const RecentBattles = ({
  recentBattleSummary,
  setActiveTab,
  showAxieDetail,
}: Props) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={showAxieDetail ? 12 : 4}>
      <Stack gap={1} height="100%" width="100%">
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CardTitle>Latest Battles</CardTitle>
          <MoreLink text="View More" onClick={() => setActiveTab(1)} />
        </Stack>
        <Stack gap={2} height="100%" width="100%">
          {recentBattleSummary.toDisplay.map((battle) => (
            <RecentBattle battle={battle} key={battle.battleUuid} />
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default RecentBattles;
