import CustomLink from "components/CustomLink/CustomLink";
import { useTheme } from "@mui/material";
import { TRIBALLY_PLATFORM_URL } from "config";

const TriballyAxieQuestBanner = () => {
  const theme = useTheme();
  return (
    <CustomLink
      style={{
        width: 600,
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingRight: 1,
          paddingLeft: 1,
        },
        "&:hover": { transform: "scale(1.02)" },
      }}
      target="_blank"
      href={`${TRIBALLY_PLATFORM_URL}community/axie-quest`}
    >
      <img
        style={{
          borderRadius: 16,
          width: "100%",
          border: `8px solid ${theme.palette.card.background}`,
          boxShadow: `2px 5px 10px rgba(0, 0, 0, 0.5)`,
        }}
        src="/images/tribally-banner-assets/backgrounds/axie-quest-explorer.jpg"
        alt="Axie Quest Game Space On Tribally Platform"
      />
    </CustomLink>
  );
};

export default TriballyAxieQuestBanner;
