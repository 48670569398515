import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-12-29</Date>
      <Change type="improved">
        Added an Axie DoLL tab to our{" "}
        <CustomLink to="/inspector" endSpace={false}>
          Axie Inspector
        </CustomLink>
        !
      </Change>
    </Entry>
  );
};

export default Changes;
