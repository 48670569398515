import * as types from "./playerActionTypes";

export const fetchClassicPlayer = (roninAddress) => ({
  type: types.FETCH_PLAYER_CLASSIC,
  roninAddress,
});

export const fetchClassicPlayerStarted = () => ({
  type: types.FETCH_PLAYER_CLASSIC_STARTED,
});

export const fetchClassicPlayerFailed = () => ({
  type: types.FETCH_PLAYER_CLASSIC_FAILED,
});

export const fetchClassicPlayerSucceeded = (roninAddress, data) => ({
  type: types.FETCH_PLAYER_CLASSIC_SUCCEEDED,
  roninAddress,
  data,
});

export const fetchClassicPlayerBattles = (roninAddress) => ({
  type: types.FETCH_PLAYER_CLASSIC_BATTLES,
  roninAddress,
});

export const fetchClassicPlayerBattlesStarted = () => ({
  type: types.FETCH_PLAYER_CLASSIC_BATTLES_STARTED,
});

export const fetchClassicPlayerBattlesFailed = () => ({
  type: types.FETCH_PLAYER_CLASSIC_BATTLES_FAILED,
});

export const fetchClassicPlayerBattlesSucceeded = (data) => ({
  type: types.FETCH_PLAYER_CLASSIC_BATTLES_SUCCEEDED,
  data,
});

export const updateClassicPlayerBattlesPage = (currentPage) => ({
  type: types.UPDATE_PLAYER_CLASSIC_BATTLES_PAGE,
  currentPage,
});

export const fetchOriginPlayer = (clientId) => ({
  type: types.FETCH_PLAYER_ORIGIN,
  clientId,
});

export const fetchOriginPlayerStarted = () => ({
  type: types.FETCH_PLAYER_ORIGIN_STARTED,
});

export const fetchOriginPlayerFailed = () => ({
  type: types.FETCH_PLAYER_ORIGIN_FAILED,
});

export const fetchOriginPlayerSucceeded = (clientId, data) => ({
  type: types.FETCH_PLAYER_ORIGIN_SUCCEEDED,
  clientId,
  data,
});

export const fetchOriginPlayerBattles = (clientId) => ({
  type: types.FETCH_PLAYER_ORIGIN_BATTLES,
  clientId,
});

export const fetchOriginPlayerBattlesStarted = () => ({
  type: types.FETCH_PLAYER_ORIGIN_BATTLES_STARTED,
});

export const fetchOriginPlayerBattlesFailed = () => ({
  type: types.FETCH_PLAYER_ORIGIN_BATTLES_FAILED,
});

export const fetchOriginPlayerBattlesSucceeded = (data) => ({
  type: types.FETCH_PLAYER_ORIGIN_BATTLES_SUCCEEDED,
  data,
});

export const updateOriginPlayerBattlesPage = (currentPage) => ({
  type: types.UPDATE_PLAYER_ORIGIN_BATTLES_PAGE,
  currentPage,
});
