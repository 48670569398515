import React from "react";
import { Tooltip } from "@mui/material";

const IndesBadge = () => {
  return (
    <Tooltip title="Coached by Indes">
      <img
        className="indesBadge"
        src="/images/icons/coached-by-indes.png"
        alt="Coached by Indes"
      />
    </Tooltip>
  );
};

export default IndesBadge;
