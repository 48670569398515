const dual = (theme) => {
  const color1 = theme.palette.chart.background.purple;
  const color2 = theme.palette.chart.background.teal;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 4H3V20H9V4Z" fill={color1} />
      <path d="M17 10H11V20H17V10Z" fill={color2} />
    </svg>
  );
};

const stacked = (theme) => {
  const color1 = theme.palette.chart.background.purple;
  const color2 = theme.palette.chart.background.teal;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 8H7V20H13V8Z" fill={color1} />
      <path d="M13 3H7V8H13V3Z" fill={color2} />
    </svg>
  );
};

const positiveNegative = (theme) => {
  const color1 = theme.palette.chart.background.purple;
  const color2 = theme.palette.chart.background.teal;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 10H3V15H9V10Z" fill={color1} />
      <path d="M9 3H3V10H9V3Z" fill={color2} />
      <path d="M17 10H11V17H17V10Z" fill={color1} />
      <path d="M17 7H11V10H17V7Z" fill={color2} />
    </svg>
  );
};

const net = (theme) => {
  const color1 = theme.palette.chart.background.orange;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 10H3V18H9V10Z" fill={color1} />
      <path d="M17 5H11V10H17V5Z" fill={color1} />
    </svg>
  );
};

const bar = (theme) => {
  const color1 = theme.palette.chart.background.purple;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 4H7V20H13V4Z" fill={color1} />
    </svg>
  );
};

const chartIcons = { dual, stacked, positiveNegative, net, bar };

export default chartIcons;
