import { Typography, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import Quantity from "./Quantity";
import CopyAddress from "components/Typography/CopyAddress";
import { makeScholarPageURLFromRoninAddress } from "helpers/guild";

const Wrapper = styled(Stack)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 165px 30px 100px",
  gap: "16px",
  alignItems: "center",
}));

const Owner = ({ owner, isBlockchain }) => {
  return (
    <Wrapper>
      <RouterLink
        to={makeScholarPageURLFromRoninAddress(owner.roninAddress)}
        state={{
          prevPath: "/guild-dashboard/inventory",
        }}
      >
        <Typography
          variant="span"
          fontWeight={600}
          fontSize={13}
          color="text.bright"
          sx={{
            lineHeight: 1.4,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {owner.name}
        </Typography>
      </RouterLink>

      <CopyAddress address={owner.roninAddress} disableHover={true} />
      <Quantity
        quantity={owner.quantity}
        withdrawalAmount={owner.withdrawalAmount}
        nextWithdrawTime={owner.nextWithdrawTime}
        isBlockchain={isBlockchain}
      />
    </Wrapper>
  );
};

export default Owner;
