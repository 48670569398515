import React from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Container, Stack } from "@mui/material";
import { getCursesToDisplay } from "store/explorer/selectors";
import {
  favoriteCurse,
  updateCurseFilters,
} from "store/explorer/explorerActions";
import Sort from "../../molecules/Sort";
import InfoButton from "components/Origin/atoms/InfoButton";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";

const Curses = ({
  cursesToDisplay,
  fetchingAxieCards,
  favoriteCurse,
  curseFilters,
  updateCurseFilters,
  setInfoDialogIsOpen,
}) => {
  return (
    <>
      {fetchingAxieCards ? (
        <CustomLoader />
      ) : (
        <Stack gap={6}>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Sort
                filters={curseFilters}
                updateFilters={updateCurseFilters}
                options={[
                  { label: "Name A-Z", value: "NAME_ASC" },
                  { label: "Name Z-A", value: "NAME_DESC" },
                ]}
              />
              <InfoButton onClick={() => setInfoDialogIsOpen(true)} />
            </Stack>
          </Container>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            gap={3}
            sx={{ px: 2 }}
          >
            {cursesToDisplay.map((card) => (
              <WrappedCard
                key={card.id}
                card={card}
                favoriteCard={favoriteCurse}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapDispatchToProps = { favoriteCurse, updateCurseFilters };

const mapStateToProps = (state) => ({
  cursesToDisplay: getCursesToDisplay(state),
  fetchingCards: state.data.fetchingAxieCards,
  curseFilters: state.explorer.curseFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(Curses);
