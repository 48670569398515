import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const ChartTooltip = ({ active, payload }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  if (active && payload && payload.length) {
    return (
      <Stack
        style={{
          backgroundColor: theme.palette.popover.background,
          boxShadow: theme.palette.popover.boxShadow,
          padding: smUp ? "16px" : "8px",
          borderRadius: "8px",
        }}
        gap={1}
      >
        <Typography
          fontSize="22px"
          variant="span"
          color={theme.palette.card.text}
          fontWeight="700"
        >
          {payload[0].value}
        </Typography>
        <Typography
          fontSize="16px"
          variant="span"
          color="text.secondary"
          fontWeight="600"
        >
          {payload[0].payload.name}
        </Typography>
      </Stack>
    );
  }
  return null;
};

export default ChartTooltip;
