import moment from "moment";
import { countryRegionMapping } from "components/CountryRegionSelect/countryRegionMapping";
import { AXIE_CLASSES } from "config";
import { sortAxiesByClass } from "./axie";

export const getScholarMoonshards = (scholar) => {
  if (scholar?.moonshards != null) {
    return scholar.moonshards;
  }
  return 0;
};

export const calculateNumberOfAxies = (scholar) => {
  if (scholar && scholar?.axies) {
    const total = scholar.axies.length;
    const eggs = scholar.axies.filter(
      (axie) => parseInt(axie.stage) === 1
    ).length;
    return {
      total,
      eggs,
      adults: total - eggs,
    };
  }
  return 0;
};

export const calculateTotalSLP = (scholar) => {
  if (scholar?.unclaimedSLP != null) {
    return scholar.unclaimedSLP;
  }
  return 0;
};

export const calculateInvestorSLP = (scholar) => {
  const totalUnclaimedSLP = calculateTotalSLP(scholar);
  if (totalUnclaimedSLP != null) {
    return totalUnclaimedSLP * (parseInt(scholar.investorShare || 0) / 100);
  }
  return 0;
};

export const calculateManagerSLP = (scholar) => {
  const totalUnclaimedSLP = calculateTotalSLP(scholar);
  if (totalUnclaimedSLP != null) {
    return totalUnclaimedSLP * (parseInt(scholar.managerShare ?? "0") / 100);
  }
  return 0;
};

export const calculateScholarSLP = (scholar) => {
  const totalUnclaimedSLP = calculateTotalSLP(scholar);
  if (totalUnclaimedSLP != null) {
    return (
      totalUnclaimedSLP *
      ((100 -
        (parseInt(scholar.managerShare) +
          parseInt(scholar.investorShare || 0))) /
        100)
    );
  }
  return 0;
};

export const calculateRank = (scholar) => {
  if (
    scholar?.dailyBattleHistory != null &&
    scholar.dailyBattleHistory.length > 0 &&
    scholar.dailyBattleHistory[0].rank != null
  ) {
    return scholar.dailyBattleHistory[0].rank;
  } else {
    return undefined;
  }
};

export const calculateTier = (scholar) => {
  if (
    scholar?.dailyBattleHistory != null &&
    scholar.dailyBattleHistory.length > 0 &&
    scholar.dailyBattleHistory[0].tier != null
  ) {
    return parseInt(scholar.dailyBattleHistory[0].tier);
  } else {
    return undefined;
  }
};

export const calculateNumRank = (scholar) => {
  if (
    scholar?.dailyBattleHistory &&
    scholar.dailyBattleHistory.length > 0 &&
    scholar.dailyBattleHistory[0].leaderboardRank != null
  ) {
    return parseInt(scholar.dailyBattleHistory[0].leaderboardRank);
  }
  return undefined;
};

export const calculateMMR = (scholar) => {
  if (scholar?.elo != null) {
    return scholar.elo;
  }
  return 0;
};

export const calculateStamina = (scholar) => {
  if (scholar?.stamina != null) {
    return scholar.stamina;
  }
  return 0;
};

export const todayIsSholarsBirthday = (birthdayRaw) => {
  const today = moment();
  const birthday = moment(birthdayRaw);
  return today.date() === birthday.date() && today.month() === birthday.month();
};

export const parseCountryOrFallback = (country) => {
  if (country in countryRegionMapping) {
    return countryRegionMapping[country]["country"];
  }
  return "";
};

export const parseRegionOrFallback = (region, country) => {
  if (country in countryRegionMapping) {
    const regionPairs = countryRegionMapping[country]["regions"].split("|");
    const validRegion = regionPairs.find(
      (regionPair) => regionPair.split("~")[0] === region
    );
    if (validRegion) {
      return validRegion.split("~")[0];
    }
  }
  return "";
};

export const createScholarPayload = (
  name,
  managerShare,
  roninAddress,
  payoutAddress,
  emailAddress,
  twitterId,
  discordId,
  region,
  country,
  dob,
  dateJoined,
  notes,
  dailyQuota,
  guildTeamId,
  investorShare
) => ({
  name,
  managerShare: managerShare ? parseInt(managerShare) : 0,
  investorShare: investorShare ? parseInt(investorShare) : 0,
  roninAddress,
  payoutAddress,
  emailAddress,
  twitterId,
  discordId,
  region,
  country,
  dob,
  dateJoined,
  notes,
  dailyQuota: dailyQuota ? parseInt(dailyQuota) : 0,
  guildTeamId: guildTeamId ? guildTeamId : 0,
  profilePicUri: "",
});

export const scholarMatchesSearchQuery = (scholar, searchQuery) => {
  return (
    (searchQuery &&
      scholar?.name &&
      scholar?.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (searchQuery &&
      scholar?.roninAddress &&
      scholar?.roninAddress
        .toLowerCase()
        .includes(searchQuery.toLowerCase())) ||
    !searchQuery ||
    searchQuery === ""
  );
};

export const sortByMMR = (a, b) => {
  return calculateMMR(b) - calculateMMR(a);
};

export const calculateNumberOfBattlesYesterday = (scholar) => {
  if (
    scholar.dailyBattleHistory != null &&
    scholar.dailyBattleHistory.length > 0
  ) {
    return (
      parseInt(scholar.dailyBattleHistory[0].numPvEBattles) +
      parseInt(scholar.dailyBattleHistory[0].numPvPBattles)
    );
  }
  return undefined;
};

export const getDailyChartData = (scholar) => {
  const totalWin = {
    pvp: 0,
    pve: 0,
  };
  const totalBattles = {
    pvp: 0,
    pve: 0,
  };

  const chartData = (scholar.dailyBattleHistory || []).map((day) => {
    const numPvPBattles = parseInt(day.numPvPBattles);
    const numPvPWins = parseInt(day.numPvPWins);
    const numPvPLosses = numPvPBattles - numPvPWins;
    const numPvEBattles = parseInt(day.numPvEBattles);
    const numPvEWins = parseInt(day.numPvEWins);
    const numPvELosses = numPvEBattles - numPvEWins;

    const wonPvESLP = parseInt(day.wonPvESLP);

    let winRatePvP = Math.floor((numPvPWins / numPvPBattles) * 100);
    let winRatePvE = Math.floor((numPvEWins / numPvEBattles) * 100);

    totalWin.pvp += numPvPWins;
    totalWin.pve += numPvEWins;
    totalBattles.pvp += numPvPBattles;
    totalBattles.pve += numPvEBattles;

    if (isNaN(winRatePvP)) {
      winRatePvP = undefined;
    }
    if (isNaN(winRatePvE)) {
      winRatePvE = undefined;
    }

    return {
      name: moment(day.battlesDate).format("MMM D"),
      numPvPBattles,
      numPvPWins,
      numPvPLosses,
      winRatePvP,
      numPvEBattles,
      numPvEWins,
      numPvELosses,
      winRatePvE,
      wonPvESLP,
    };
  });

  return {
    chartData,
    winRate: {
      pvp:
        totalBattles.pvp !== 0
          ? Math.floor((totalWin.pvp / totalBattles.pvp) * 100)
          : undefined,
      pve:
        totalBattles.pve !== 0
          ? Math.floor((totalWin.pve / totalBattles.pve) * 100)
          : undefined,
    },
  };
};

export const getScholarRankInGuild = (roninAddress, allScholars) => {
  let mmr;

  if (allScholars != null) {
    const guildRankedMMR = [...allScholars].sort((a, b) => sortByMMR(a, b));
    mmr = guildRankedMMR.findIndex((s) => s.roninAddress === roninAddress) + 1;
  }

  return {
    mmr,
  };
};

export const getScholarRankInTeam = (roninAddress, guildTeamId, teams) => {
  let mmr;

  if (parseInt(guildTeamId) !== 0) {
    const scholarsInTeam = [...teams].find(
      (t) => t.guildTeamId === guildTeamId
    ).scholars;

    if (scholarsInTeam) {
      const teamRankedMMR = [...scholarsInTeam].sort((a, b) => sortByMMR(a, b));

      mmr = teamRankedMMR.findIndex((s) => s.roninAddress === roninAddress) + 1;
    }
  }

  return {
    mmr,
  };
};

export const scholarIsActive = (scholar) => {
  if (scholar.dailyBattleHistory == null) {
    return true;
  }

  let numBattles = 0;
  let daysToCheck = 3;

  for (let i = 0; i < daysToCheck; i++) {
    if (scholar.dailyBattleHistory.length > i) {
      numBattles +=
        parseInt(scholar.dailyBattleHistory[i].numPvEBattles) +
        parseInt(scholar.dailyBattleHistory[i].numPvPBattles);
    }
  }

  return numBattles !== 0;
};

export const countAxiesByClass = (scholar) => {
  const axieClassCounts = { Egg: 0 };

  for (const axieClass of AXIE_CLASSES) {
    axieClassCounts[axieClass] = 0;
  }

  if (scholar.axies) {
    for (const axie of scholar.axies) {
      if (axie.class) {
        axieClassCounts[axie.class] += 1;
      } else {
        axieClassCounts.Egg += 1;
      }
    }
  }

  return [...Object.entries(axieClassCounts)]
    .sort((a, b) => sortAxiesByClass(a[0], b[0]))
    .filter((axieClass) => axieClass[1] > 0);
};
