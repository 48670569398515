import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AXIE_TECH_API } from "config";
import { LeaderboardPlayerRaw } from "types";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: AXIE_TECH_API,
  }),
  endpoints: (build) => ({
    getOriginsLeaderboard: build.query<LeaderboardPlayerRaw[], void>({
      query: () => "/leaderboard/origin/arena",
    }),
    getInterTheDungeonCards: build.query<any[], void>({
      query: () => "/inter-the-dungeon/cards",
    }),
  }),
});

export const {
  useGetOriginsLeaderboardQuery,
  useGetInterTheDungeonCardsQuery,
} = api;
