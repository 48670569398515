import React from "react";
import { Stack } from "@mui/material";
import TitleWithAccents from "../atoms/TitleWithAccents";

const FavoritesSection = ({ type, children, numItems, buttons, gap = 6 }) => {
  return numItems === 0 ? (
    <></>
  ) : (
    <Stack gap={3} alignItems="center">
      <Stack gap={1} alignItems="center">
        <TitleWithAccents>
          {`${numItems} ${type}${numItems === 1 ? "" : "s"}`}
        </TitleWithAccents>

        {buttons}
      </Stack>

      <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={gap}>
        {children}
      </Stack>
    </Stack>
  );
};

export default FavoritesSection;
