import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-6-5</Date>
      <Change type="improved">
        We have replaced all references to scholars or scholarships in
        preparation for mainstream adoption of Axie Infinity: Origins.
      </Change>
    </Entry>
  );
};

export default Changes;
