import { Stack } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import Label from "components/Typography/Label";
import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  fontSize: 14,
  gap: theme.spacing(0.75),
}));

const Tools = ({ tools }) => {
  if (tools == null) {
    return null;
  }

  return (
    <Wrapper>
      <Label>Tools</Label>
      {tools.map((tool) => (
        <CustomLink key={tool.text} to={tool.to}>
          • {tool.text}
        </CustomLink>
      ))}
    </Wrapper>
  );
};

export default Tools;
