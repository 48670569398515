import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2023-4-04</Date>
      <Change type="new">
        Added a new
        <CustomLink to="/axie-quest/skill-explorer" endSpace={true}>
          Axie Quest Skill Explorer
        </CustomLink>
        page!
      </Change>
    </Entry>
  );
};

export default Changes;
