import React from "react";
import { getGuildLogoURL } from "helpers/guild";
import { getMedalForPlace } from "helpers/arena";
import { Typography } from "@mui/material";
import VictoryStars from "../molecules/VictoryStars";

const Row = ({ row, details, rank }) => {
  return (
    <>
      <Typography
        textAlign="center"
        fontWeight={700}
        fontSize={15}
        color="text.regular"
      >
        {getMedalForPlace(rank)}
      </Typography>
      {details.map((detail, j) => {
        const value = row[detail.key];
        return (
          <Typography
            variant="span"
            fontSize="14px"
            textAlign={detail.align || "left"}
            fontWeight={detail.fontWeight || 600}
            color={detail.color || "text.regular"}
            key={`PLAYER_DETAIL_${j}`}
            sx={{
              lineHeight: 1.4,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {detail.type === "image" ? (
              <img src={getGuildLogoURL(value)} alt="Guild logo" />
            ) : detail.type === "victoryStars" ? (
              <VictoryStars stars={value} height={12} />
            ) : (
              value
            )}
          </Typography>
        );
      })}
    </>
  );
};

export default Row;
