import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Page from "components/DoLL/Layout/Page";
import CustomLoader from "components/CustomLoader/CustomLoader";
import FailedMessage from "components/FailedMessage/FailedMessage";
import Controls from "pages/DoLL/CardExplorer/organisms/Controls";
import SkillsContainer from "pages/DoLL/CardExplorer/organisms/SkillsContainer";
import { fetchDollCards, updateControls } from "store/doll/cardExplorer/action";
import {
  getDollSkillsData,
  getLoading,
  getError,
} from "store/doll/cardExplorer/selectors";
import { Button, Stack } from "@mui/material";
import { FaRedoAlt } from "react-icons/fa";

export const CARD_EXPLORER_BREAKPOINT = 1224;

const CardExplorer = ({
  fetchDollCards,
  data,
  loading,
  error,
  updateControls,
}) => {
  useEffect(() => {
    fetchDollCards();
  }, [fetchDollCards]);

  const handleUpdateControls = useCallback(
    (key) => (val) => {
      updateControls({ ...data.controls, [key]: val });
    },
    [updateControls, data.controls]
  );

  return (
    <Page
      titleImg="/images/games/doll/card-explorer-title.png"
      titleAlt="Axie DoLL Card Explorer"
      breakpoint={CARD_EXPLORER_BREAKPOINT}
      controls={
        <Controls
          width={400}
          controls={data.controls}
          handleUpdateControls={handleUpdateControls}
        />
      }
    >
      {error ? (
        <Stack
          padding="0 16px"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          height="50vh"
        >
          <FailedMessage text="There was an error loading cards. Please press Reload to try again." />
          <Button
            sx={{ maxWidth: "220px", alignSelf: "center" }}
            variant="doll"
            endIcon={<FaRedoAlt />}
            onClick={fetchDollCards}
          >
            Reload
          </Button>
        </Stack>
      ) : loading ? (
        <CustomLoader variant="doll" marginTop={200} />
      ) : (
        <SkillsContainer data={data} />
      )}
    </Page>
  );
};

const mapDispatchToProps = {
  fetchDollCards,
  updateControls,
};

const mapStateToProps = (state) => ({
  data: getDollSkillsData(state),
  loading: getLoading(state),
  error: getError(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardExplorer);
