import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Item = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  fontSize: 18,
  fontWeight: 800,
  color: theme.palette.text.bright,
  img: {
    width: 12,
  },
}));

const ScholarInventory = ({ slp, moonshards }) => {
  return (
    <Stack gap={4} direction="row" alignItems="center">
      <Item>
        <img src="/images/icons/slp.png" alt="SLP" />
        {slp}
      </Item>

      <Item>
        <img src="/images/icons/moonshard.png" alt="Moonshard" />
        {moonshards}
      </Item>
    </Stack>
  );
};

export default ScholarInventory;
