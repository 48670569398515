import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Stack, Tooltip, useTheme } from "@mui/material";

const ClickableItem = ({
  onClick,
  children,
  itemType,
  isSelected,
  canEquip = true,
}) => {
  const theme = useTheme();
  const topMapping = {
    cards: "85%",
    charms: "-4%",
    runes: "-4%",
  };
  const rightMapping = {
    cards: "-9%",
    charms: "-7%",
    runes: "-7%",
  };

  return (
    <CannotClickTooltip canEquip={canEquip}>
      <Stack
        onClick={canEquip ? onClick : undefined}
        sx={{
          opacity: canEquip ? 1 : 0.5,
          position: "relative",
          transition: "0.2s transform ease",
          filter: isSelected
            ? `drop-shadow(0 0 16px ${theme.palette.text.secondary})`
            : "none",
          "&:hover": {
            cursor: "pointer",
            transform: "scale(1.05)",
          },
          "&:active": {
            transform: "scale(1)",
          },
          "& .selected": {
            color: theme.palette.card.background,
            border: `1px solid ${theme.palette.card.background}`,
            fontSize: 48,
            background: theme.palette.secondary.main,
            filter: "drop-shadow(0px 0px 4px rgba(0,0,0,0.9))",
            position: "absolute",
            borderRadius: "50%",
            p: 1,
            right: rightMapping[itemType],
            top: topMapping[itemType],
            zIndex: 10,
            opacity: isSelected ? 1 : 0,
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center" className="selected">
          <BsCheckCircleFill />
        </Stack>
        {children}
      </Stack>
    </CannotClickTooltip>
  );
};

const CannotClickTooltip = ({ children, canEquip }) =>
  canEquip ? (
    <>{children}</>
  ) : (
    <Tooltip title="Not enough potential points">{children}</Tooltip>
  );

export default ClickableItem;
