import React from "react";
import Search from "components/DoLL/Layout/Search";
import { Stack, useMediaQuery } from "@mui/material";
import CustomMultiSelect from "pages/DoLL/CardExplorer/atoms/CustomMultiSelect";
import { BODY_PARTS, AXIE_CLASSES } from "config";
import { CARD_EXPLORER_BREAKPOINT } from "pages/DoLL/CardExplorer";

const Controls = ({ controls, handleUpdateControls }) => {
  const controlCollapse = useMediaQuery(
    `(max-width:${CARD_EXPLORER_BREAKPOINT}px)`
  );

  return (
    <Stack
      gap={2}
      direction={controlCollapse ? "column" : "row"}
      alignItems={"center"}
    >
      <Search
        placeholder="Search"
        width={controlCollapse ? "100%" : 220}
        searchText={controls.searchText}
        updateSearchText={(e) =>
          handleUpdateControls("searchText")(e.target.value)
        }
      />
      <Stack spacing={2} direction="row" sx={{ height: "100%", minHeight: 56 }}>
        <CustomMultiSelect
          label="Class"
          value={controls.axieClasses}
          options={AXIE_CLASSES}
          updateSelectValue={(e) =>
            handleUpdateControls("axieClasses")(e.target.value)
          }
          width={120}
        ></CustomMultiSelect>
        <CustomMultiSelect
          label="Part Type"
          value={controls.partTypes}
          options={BODY_PARTS}
          updateSelectValue={(e) =>
            handleUpdateControls("partTypes")(e.target.value)
          }
          width={120}
        ></CustomMultiSelect>
      </Stack>
    </Stack>
  );
};

export default Controls;
