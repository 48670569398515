import {
  extractCurrentSeasonItems,
  isCurrentSeasonItem,
  isCurrentSeasonNft,
} from "helpers/items";

export const getAxieData = (state) => state.data.axieData || {};
export const getAllRunes = (state) => state.data.axieCardsV3.runes || [];
export const getAllCharms = (state) => state.data.axieCardsV3.charms || [];
export const getAllAbilities = (state) =>
  state.data.axieCardsV3.abilities || [];
export const getAllCurseCards = (state) =>
  state.data.axieCardsV3.curseCards || [];
export const getAllRevengeCards = (state) =>
  state.data.axieCardsV3.revengeCards || [];
export const getAllToolCards = (state) =>
  state.data.axieCardsV3.toolCards || [];
export const getAllCardsV3 = (state) => state.data.axieCardsV3.cards || [];
export const getAllCardsV2 = (state) => state.data.axieCards || [];
export const getAllV3Data = (state) => state.data.axieCardsV3 || {};
export const getAllV3DataLoading = (state) => state.data.fetchingAxieCardsV3;

export const getAllRunesForCurrentSeason = (state) =>
  getAllRunes(state).filter((rune) => isCurrentSeasonItem(rune.season));

export const getAllCharmsForCurrentSeason = (state) =>
  getAllCharms(state).filter((charm) => isCurrentSeasonItem(charm.season));

export const getAllNftRunesForCurrentSeason = (state) =>
  getAllRunes(state).filter((rune) =>
    isCurrentSeasonNft(rune.season, rune.isBlockchain)
  );

export const getAllNftCharmsForCurrentSeason = (state) =>
  getAllCharms(state).filter((charm) =>
    isCurrentSeasonNft(charm.season, charm.isBlockchain)
  );

export const getAllUniqueRunesForCurrentSeason = (state) => {
  return extractCurrentSeasonItems(getAllRunes(state));
};

export const getAllUniqueCharmsForCurrentSeason = (state) => {
  return extractCurrentSeasonItems(getAllCharms(state));
};
