import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import fonts from "theme/fonts";

const Charm = ({ onClick, charm, width = 1181, style }) => {
  if (charm == null) {
    return null;
  }
  const cls = charm.class.toLowerCase();
  return (
    <Box
      onClick={onClick}
      sx={{
        width,
        height: width,
        position: "relative",
        "&::before": {
          content: "''",
          background: "#39241a78",
          filter: `blur(${(14 / 1181) * width}px)`,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: `${(180 / 1181) * width}px`,
        },
        ...style,
      }}
    >
      <CharmBackground />
      <CharmIcon name={charm.name} image={charm.image} />
      <PotentialCost cost={charm.potentialCost} width={width} />
      <CharmName name={charm.name} width={width} />
      <ClassIcon axieClass={cls} />
    </Box>
  );
};

const CharmBackground = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      "& img": {
        width: "100%",
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img
      src={`/images/templates/charm/background-small.png`}
      alt="Charm background"
    />
  </Box>
);

const CharmIcon = ({ image, name }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      position: "absolute",
      top: "22%",
      height: "39.5%",
      left: 0,
      right: 0,
      zIndex: 2,
      "& img": {
        maxWidth: "30%",
        maxHeight: "100%",
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img src={image} alt={name} />
  </Stack>
);

const ClassIcon = ({ axieClass }) => (
  <Box
    sx={{
      zIndex: 3,
      position: "absolute",
      top: "8%",
      left: "8%",
      width: "20.7%",
      "& img": {
        width: "100%",
      },
    }}
  >
    <img
      src={`/images/class-icons/${axieClass}.png`}
      alt={`${axieClass} class charm`}
    />
  </Box>
);

const PotentialCost = ({ cost, width }) => {
  return (
    <Typography
      textAlign="center"
      fontSize={(200 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      sx={{
        position: "absolute",
        width: "26.8%",
        left: "4.8%",
        color: "#fff",
        top: "28%",
        zIndex: 3,
        WebkitTextStrokeWidth: `${(10 / 1181) * width}px`,
        WebkitTextStrokeColor: "#4B3939",
      }}
    >
      {cost}
    </Typography>
  );
};

const CharmName = ({ name, width }) => {
  const theme = useTheme();

  return (
    <Typography
      textAlign="center"
      fontSize={(118 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      sx={{
        position: "absolute",
        maxWidth: "82%",
        margin: "0 auto",
        left: 0,
        right: 0,
        color: theme.palette.colors.origin.wood.background,
        top: "68%",
        lineHeight: 1.15,
        zIndex: 3,
      }}
    >
      {name}
    </Typography>
  );
};

export default Charm;
