import React from "react";
import { useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import { addAlphaToHex } from "helpers/colors";
import styled from "@emotion/styled";
import CustomLink from "components/CustomLink/CustomLink";

const NavLink = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  justifyContent: "center",
  alignItems: "stretch",
  transition: "0.2s all ease",
  width: "100%",
  borderRadius: "4px",
  padding: "20px 32px",
  userSelect: "none",
  display: "block",
  fontSize: 15,
  fontWeight: 600,
  lineHeight: "18px",
  background: isActive
    ? addAlphaToHex(theme.palette.text.primary, 0.08)
    : "transparent",
  color: isActive ? theme.palette.text.primary : theme.palette.text.regular,
  "&:hover": {
    borderColor: "transparent",
    background: addAlphaToHex(theme.palette.text.primary, 0.08),
    color: theme.palette.text.primary,
  },
}));

const Navigation = () => {
  const { pathname } = useLocation();
  const links = [
    { text: "Overview", to: "/guild-dashboard" },
    { text: "Teams", to: "/guild-dashboard/teams" },
    { text: "Scholars", to: "/guild-dashboard/scholars" },
    { text: "Axies", to: "/guild-dashboard/axies" },
    { text: "Inventory", to: "/guild-dashboard/inventory" },
  ];

  return (
    <Stack sx={{ pt: 4 }} gap={1}>
      {links.map((link, i) => (
        <CustomLink key={`NAVIGATION_${i}`} to={link.to}>
          <NavLink isActive={link.to === pathname}>{link.text}</NavLink>
        </CustomLink>
      ))}
    </Stack>
  );
};

export default Navigation;
