import React from "react";
import { TextField, useTheme, InputAdornment, IconButton } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const Search = ({ handleUpdate, value, placeholder }) => {
  const theme = useTheme();
  const origin = theme.palette.colors.origin;

  return (
    <TextField
      onChange={(e) =>
        handleUpdate({
          searchText: e.target.value,
        })
      }
      hiddenLabel
      variant="filled"
      placeholder={placeholder}
      value={value}
      spellCheck={false}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            position="end"
            onClick={() =>
              handleUpdate({
                searchText: "",
              })
            }
          >
            <IoCloseCircle />
          </IconButton>
        ),
        sx: {
          paddingLeft: 2,
        },
      }}
      sx={{
        width: "100%",
        "& .MuiFilledInput-root": {
          background: origin.wood.input.background,
          border: `1px solid ${origin.wood.input.border}`,
          color: origin.wood.text,
          boxShadow: "0px 4px 0px rgba(0,0,0,0.25)",
          height: 51,
          "&:hover": {
            background: origin.wood.input.background,
            border: `1px solid ${origin.wood.text}`,
            boxShadow: "0px 4px 0px rgba(0,0,0,0.25)",
            "& .MuiIconButton-root": {
              opacity: 1,
            },
          },
          "&:active, &.Mui-focused": {
            backgroundColor: origin.wood.input.background,
            border: `1px solid ${origin.wood.text}`,
            boxShadow: `0px 0px 0 3px rgba(255,255,255,0.2)`,
          },
        },
        "& .MuiFilledInput-input:focus, & .Mui-Focused .MuiFilledInput-input": {
          background: origin.wood.input.background,
          color: origin.wood.text,
          borderRadius: "8px",
        },
        "& .MuiInputAdornment-root svg": {
          fill: origin.wood.text,
        },
        "& .MuiIconButton-root": {
          color: origin.wood.text,
          opacity: 0,
        },
        "& .MuiFilledInput-input": {
          color: origin.wood.text,
          height: 49,
          padding: 0,
          "&::placeholder": {
            color: origin.wood.text,
            opacity: 0.2,
            fontSize: 15,
            fontWeight: 600,
          },
        },
      }}
    />
  );
};

export default Search;
