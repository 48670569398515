import * as types from "./guildActionTypes";
import produce from "immer";

const initialState = {};

export const guildReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_GUILD_STARTED:
        draft.fetching = true;
        break;
      case types.FETCH_GUILD_SUCCEEDED:
        draft.fetching = false;
        draft = Object.assign(draft, action.payload);
        break;
      case types.FETCH_GUILD_FAILED:
        draft.fetching = false;
        draft.failed = true;
        break;

      default:
        break;
    }
  });

export default guildReducer;
