import { Stack } from "@mui/material";
import Card from "components/Card/Card";
import SocialIcons from "./SocialIcons";
import WatchIcon from "../../../atoms/WatchIcon";
import { default as Team } from "../atoms/ScholarTeam";
import { default as Name } from "../atoms/ScholarName";
import styled from "@emotion/styled";
import RankIcon from "components/RankIcon";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(2),
  height: "100%",
  position: "relative",

  ".watchIcon": {
    position: "absolute",
    top: -16,
    left: -16,
  },

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {
    ".watchIcon": {
      position: "absolute",
      top: 0,
      left: "initial",
      right: 0,
    },
  },
}));

const ColumnMdDown = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const ScholarInfo = ({ scholar }) => {
  return (
    <Card>
      <Wrapper>
        <Stack gap={2} alignItems="flex-start">
          <WatchIcon scholar={scholar} />

          <ColumnMdDown>
            <RankIcon rank={scholar.rank} tier={scholar.tier} />
            <Stack
              gap={1}
              alignItems="flex-start"
              justifyContent="center"
              sx={{ overflowWrap: "anywhere" }}
            >
              <Name name={scholar.name} />
              <Team name={scholar.teamName} guildTeamId={scholar.guildTeamId} />
              <SocialIcons scholar={scholar} />
            </Stack>
          </ColumnMdDown>
        </Stack>
      </Wrapper>
    </Card>
  );
};

export default ScholarInfo;
