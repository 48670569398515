import { Stack } from "@mui/material";
import SectionLabel from "../atoms/SectionLabel";
import BreedCount from "./BreedCount";
import Purity from "./Purity";

const About = ({ axie }) => (
  <Stack gap={1.5} sx={{ width: "100%" }}>
    <SectionLabel>About</SectionLabel>
    <BreedCount axie={axie} color={axie.color} labelWidth={50} gap={1} />
    <Purity axie={axie} color={axie.color} labelWidth={50} gap={1} />
  </Stack>
);

export default About;
