import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const Stat = ({ title, amount }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      alignItems="center"
      gap={1}
      sx={{
        p: 2,
        borderRadius: 2,
        background: theme.palette.colors.origin.paper.light,
      }}
    >
      <Typography
        variant="span"
        textAlign="center"
        fontSize={16}
        fontWeight={800}
        color={theme.palette.colors.origin.text.brown}
      >
        {title}
      </Typography>
      <Typography
        variant="span"
        textAlign="center"
        fontSize={40}
        fontWeight={800}
        color={theme.palette.colors.origin.wood.background}
      >
        {amount}
      </Typography>
    </Stack>
  );
};

export default Stat;
