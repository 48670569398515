import * as types from "./actionTypes";
import produce from "immer";
import { getCurrentTimestamp } from "helpers/time";

const initialState = {
  controls: {
    searchText: "",
    currentPage: 1,
  },
  fetching: true,
};

export const dollLeaderboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.DOLL_LEADERBOARD_FETCH_LEADERBOARD_STARTED:
        draft.fetching = true;
        draft.failed = false;
        break;

      case types.DOLL_LEADERBOARD_FETCH_LEADERBOARD_SUCCEEDED:
        draft.fetching = false;
        draft.data = action.data;
        draft.lastFetched = getCurrentTimestamp();
        break;

      case types.DOLL_LEADERBOARD_FETCH_LEADERBOARD_FAILED:
        draft.fetching = false;
        draft.failed = true;
        break;

      case types.DOLL_LEADERBOARD_UPDATE_CONTROLS:
        draft.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default dollLeaderboardReducer;
