import React from "react";
import { Stack, Box } from "@mui/material";
import { AXIE_CLASSES } from "config";
import Dropdown from "components/Origin/atoms/Dropdown";
import {
  updateCharmFilters,
  resetCharmFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";

const CharmControls = ({
  charmFilters,
  updateCharmFilters,
  resetCharmFilters,
}) => {
  const handleUpdateCharmFilters = (newFilters) => {
    updateCharmFilters({ ...charmFilters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateCharmFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack gap={2}>
      <Search
        handleUpdate={handleUpdateCharmFilters}
        value={charmFilters.searchText}
        placeholder="Search charms"
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        }}
      >
        <Dropdown
          label="Class"
          value={charmFilters.axieClasses}
          setValue={handleUpdateDropdown("axieClasses")}
          options={["Neutral", ...AXIE_CLASSES]}
        />
        <Dropdown
          label="Rarity"
          value={charmFilters.rarities}
          setValue={handleUpdateDropdown("rarities")}
          options={["common", "rare", "epic", "mystic"]}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridGap: "16px",
        }}
      >
        <Dropdown
          label="Potential Cost"
          value={charmFilters.potentialCosts}
          setValue={handleUpdateDropdown("potentialCosts")}
          options={["1", "2", "3", "4", "5"]}
        />
      </Box>
      <TextButton
        onClick={resetCharmFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCharmFilters,
  resetCharmFilters,
};

const mapStateToProps = (state) => ({
  charmFilters: state.explorer.charmFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(CharmControls);
