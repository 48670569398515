import { Stack } from "@mui/material";
import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import { statusOptions, platformOptions, gameTypeOptions } from "../data";

const Controls = ({
  statuses,
  setStatuses,
  platforms,
  setPlatforms,
  gameTypes,
  setgameTypes,
}) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "row" }}
      gap={2}
      alignItems="center"
      justifyContent="center"
    >
      <CustomMultiSelect
        label="Game Status"
        value={statuses}
        setValue={setStatuses}
        options={statusOptions || []}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Platform"
        value={platforms}
        setValue={setPlatforms}
        options={platformOptions || []}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Game Type"
        value={gameTypes}
        setValue={setgameTypes}
        options={gameTypeOptions || []}
        fullWidth={false}
        minWidth={200}
      />
    </Stack>
  );
};

export default Controls;
