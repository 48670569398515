import Search from "pages/InterDungeon/Explorer/molecules/Search";
import { Stack, useMediaQuery } from "@mui/material";
import MultiSelect from "pages/InterDungeon/Explorer/molecules/MultiSelect";
import useControls from "store/interDungeon/explorer/useControls";

const Controls = ({ data }) => {
  // state from zustand
  const { searchText, setSearchText } = useControls();
  const { rarity, setRarity } = useControls();
  const { battlePower, setBattlePower } = useControls();
  const { equippable, setEquippable } = useControls();
  const { equippedPower, setEquippedPower } = useControls();
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
  };

  const handleSelectRarity = (selectValue) => {
    setRarity(selectValue);
  };

  const handleSelectBattlePower = (selectValue) => {
    setBattlePower(selectValue);
  };

  const handleSelectEquippable = (selectValue) => {
    setEquippable(selectValue);
  };

  const handleSelectequippedPower = (selectValue) => {
    setEquippedPower(selectValue);
  };

  return (
    <Stack
      gap={2}
      direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
      alignItems="center"
      justifyContent="center"
    >
      <Search
        placeholder="Explore Cards"
        searchText={searchText}
        updateSearchText={(e) => handleSearch(e.target.value)}
        width={220}
      />
      <Stack
        gap={2}
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        width={mdDown ? "100%" : "auto"}
      >
        <MultiSelect
          label="Rarity"
          value={rarity}
          options={["common", "uncommon", "rare", "legendary"]}
          updateSelectValue={(e) => handleSelectRarity(e.target.value)}
          width={170}
        />
        <MultiSelect
          label="Battle Power"
          value={battlePower}
          options={["1", "2", "3", "4", "5", "6"]}
          updateSelectValue={(e) => handleSelectBattlePower(e.target.value)}
          width={170}
        />
      </Stack>
      <Stack
        gap={2}
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        width={mdDown ? "100%" : "auto"}
      >
        <MultiSelect
          label="Equippable"
          value={equippable}
          options={["head", "lefthand", "righthand", "bag"]}
          updateSelectValue={(e) => handleSelectEquippable(e.target.value)}
          width={170}
        />
        <MultiSelect
          label="Equipped Power"
          value={equippedPower}
          options={["1", "2", "3", "4", "5", "6"]}
          updateSelectValue={(e) => handleSelectequippedPower(e.target.value)}
          width={170}
        />
      </Stack>
    </Stack>
  );
};

export default Controls;
