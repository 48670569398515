import React from "react";
import { Stack, Typography } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import { isNullOrEmptyArray } from "helpers/cleaning";
import PriceInDollars from "pages/Inspector/atoms/PriceInDollars";
const EstimatedPrice = ({ estimatedPrice }) => {
  if (
    !estimatedPrice?.data ||
    isNullOrEmptyArray(estimatedPrice.data.axies) ||
    estimatedPrice.data.axies[0].estimate == null ||
    estimatedPrice.data.axies[0].estimate === "0.000000000000000001"
  ) {
    return null;
  }

  return (
    <Stack gap={2}>
      <SectionLabel>Metalend Minimum Price Estimate</SectionLabel>
      <Stack direction="column" alignItems="flexStart" gap={0.5}>
        <Typography
          variant="span"
          color="text.bright"
          fontSize={24}
          fontWeight={700}
        >
          Ξ{parseFloat(estimatedPrice.data?.axies[0].estimate).toPrecision(2)}
        </Typography>
        <PriceInDollars
          priceInEth={parseFloat(
            estimatedPrice.data?.axies[0].estimate
          ).toPrecision(2)}
        />
      </Stack>
    </Stack>
  );
};

export default EstimatedPrice;
