import React from "react";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import ClearAllButton from "./ClearAllButton";
import { Stack, Typography } from "@mui/material";
import { getAxieLastSold } from "helpers/axie";

const Axies = ({
  clear,
  deleteAxie,
  axies,
  showGenes = true,
  displayLastSold = false,
}) => {
  return (
    <Stack
      gap={2}
      sx={{
        margin: "32px auto 160px auto",
        minHeight: "200px",
        px: 2,
      }}
    >
      <Stack>
        <Typography variant="h3" textAlign="center" color="text.regular">{`${
          axies.length
        } Result${axies.length === 1 ? "" : "s"}`}</Typography>
        <ClearAllButton clear={clear} axies={axies} />
      </Stack>
      <Stack direction="row" gap={2} flexWrap="wrap" justifyContent="center">
        {axies.map((axie) => (
          <AxieV3
            key={axie.axieId}
            axie={axie}
            showGenes={showGenes}
            deleteAxie={deleteAxie}
            lastSold={
              displayLastSold
                ? getAxieLastSold({
                    price: axie?.transferHistory.results[0].withPrice,
                    priceUsd: axie?.transferHistory.results[0].withPriceUsd,
                    timestamp: axie?.transferHistory.results[0].timestamp,
                  })
                : undefined
            }
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default Axies;
