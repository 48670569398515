import React from "react";
import { Typography, useMediaQuery, useTheme, Stack } from "@mui/material";

const WidgetTitle = ({ text, icon, centered }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      justifyContent={centered ? "center" : "flex-start"}
      style={
        matches
          ? {
              paddingBottom: "12px",
              borderBottom: `2px solid ${theme.palette.card.divider}`,
            }
          : {}
      }
    >
      {icon != null && (
        <Typography
          fontSize="15px"
          variant="span"
          color={theme.palette.card.icon}
        >
          {icon}
        </Typography>
      )}
      <Typography
        fontSize="14px"
        color={theme.palette.card.title}
        fontWeight={600}
        variant="span"
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default WidgetTitle;
