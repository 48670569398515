import moment from "moment";
import { BREEDING_EVENT_END_DATE } from "config";

export const isBreedingEventActive = () => {
  return moment().isBefore(BREEDING_EVENT_END_DATE);
};

export const getCurrentTimestamp = () => {
  return moment().unix();
};
