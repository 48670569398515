import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const NamePercent = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  alignItems: "center",
  overflowWrap: "anywhere",
}));

const Part = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.muted,
  lineHeight: 1,
  fontSize: 14,
}));

const Name = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: theme.palette.text.bright,
  fontSize: 17,
  lineHeight: 1.1,
}));

const Percent = styled(Typography)(({ theme }) => ({
  fontFamily: "monospace",
  fontSize: 15,
  fontWeight: 600,
  color: theme.palette.text.bright,
  whiteSpace: "nowrap",
}));

const Trait = ({ part, name, percent }) => {
  return (
    <Stack gap={0.5}>
      <Part>{part}</Part>

      <NamePercent>
        <Name>{name}</Name>
        <Percent>{`${(percent * 100).toFixed(2)}%`}</Percent>
      </NamePercent>
    </Stack>
  );
};

export default Trait;
