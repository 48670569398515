import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as RoninIcon } from "icons/ronin.svg";
import { Stack, Tooltip } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  svg: {
    height: 13,
    width: "auto",
    path: {
      fill: theme.palette.text.muted,
    },
  },
}));

const BlockchainStatus = ({ isBlockchain }) => {
  if (!isBlockchain) return null;

  return (
    <Tooltip title="Blockchain">
      <Wrapper>
        <RoninIcon />
      </Wrapper>
    </Tooltip>
  );
};

export default BlockchainStatus;
