import React from "react";
import PageHeader from "components/PageHeader/PageHeader";
import BreedingCostTable from "./BreedingCostTable";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-query";
import PullUp from "components/Utility/PullUp";
import CryptoPrices from "components/CryptoPrices/CryptoPrices";
import { fetchCurrencyData } from "helpers/actions";
import { REACT_QUERY_STALE_TIME } from "config";
import { Stack, Box, Typography, Link } from "@mui/material";

const BreedingCosts = () => {
  const { data, status } = useQuery("currency", fetchCurrencyData, {
    staleTime: REACT_QUERY_STALE_TIME,
  });

  return (
    <>
      <PageHeader
        title="Breeding Costs"
        description="Live ETH, AXS &amp; SLP prices are provided by Coingecko."
        disclaimer={
          <>
            <span>
              Remember, breeding is risky and there are many factors to
              consider.
            </span>
            <br />
            <span>
              The results of this calculator are not financial advice. Please do
              your own research.
            </span>
          </>
        }
        centered
      ></PageHeader>
      <PullUp>
        <CryptoPrices data={data} status={status} />
        <Box sx={{ mt: 4, mb: 12 }}>
          <Stack gap={4}>
            <BreedingCostTable data={data} />
            <Typography
              variant="body2"
              fontSize="13px"
              textAlign="center"
              color="text.muted"
            >
              Not breeding virgins? Check out the{" "}
              <Link component={RouterLink} to="/breeding-calculator">
                breeding calculator
              </Link>
              !
            </Typography>
          </Stack>
        </Box>
      </PullUp>
    </>
  );
};

export default BreedingCosts;
