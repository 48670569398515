import React from "react";
import { Typography } from "@mui/material";

const GuildName = ({ name }) => {
  return (
    <Typography
      fontWeight={600}
      color="text.bright"
      fontSize={24}
      variant="span"
      textAlign="center"
      sx={{
        lineHeight: 1.1,
        maxWidth: "160px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {name}
    </Typography>
  );
};

export default GuildName;
