import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Logo from "./Logo";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: `${theme.spacing(3)} 0`,
}));

const PageHeader = () => {
  return (
    <Wrapper>
      <Logo />
      <img
        src={"/images/games/axie-quest/skill-explorer-title.png"}
        width="240px"
        alt="Axie Quest Skill Explorer"
      />
    </Wrapper>
  );
};

export default PageHeader;
