import React from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Container, Stack } from "@mui/material";
import { getToolsToDisplay } from "store/explorer/selectors";
import {
  favoriteTool,
  updateToolFilters,
} from "store/explorer/explorerActions";
import Sort from "../../molecules/Sort";
import InfoButton from "components/Origin/atoms/InfoButton";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";

const Tools = ({
  toolsToDisplay,
  fetchingAxieCards,
  favoriteTool,
  toolFilters,
  updateToolFilters,
  setInfoDialogIsOpen,
}) => {
  return (
    <>
      {fetchingAxieCards ? (
        <CustomLoader />
      ) : (
        <Stack gap={6}>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Sort
                filters={toolFilters}
                updateFilters={updateToolFilters}
                options={[
                  { label: "Name A-Z", value: "NAME_ASC" },
                  { label: "Name Z-A", value: "NAME_DESC" },
                ]}
              />
              <InfoButton onClick={() => setInfoDialogIsOpen(true)} />
            </Stack>
          </Container>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            gap={3}
            sx={{ px: 2 }}
          >
            {toolsToDisplay.map((card) => (
              <WrappedCard
                key={card.id}
                card={card}
                favoriteCard={favoriteTool}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapDispatchToProps = { favoriteTool, updateToolFilters };

const mapStateToProps = (state) => ({
  toolsToDisplay: getToolsToDisplay(state),
  fetchingCards: state.data.fetchingAxieCards,
  toolFilters: state.explorer.toolFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
