import React from "react";
import { Typography } from "@mui/material";

const ChartTitle = ({ children }) => {
  return (
    <Typography
      variant="span"
      color="text.bright"
      fontSize={28}
      fontWeight={700}
      textAlign="center"
      sx={{ lineHeight: 1.3 }}
    >
      {children}
    </Typography>
  );
};

export default ChartTitle;
