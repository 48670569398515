import { Stack, Box, useTheme } from "@mui/material";
import ManaIcon from "components/InterDungeon/InterDungeonCard/atoms/ManaIcon";
import EquipSlot from "components/InterDungeon/InterDungeonCard/atoms/EquipSlot";
import CombatValue from "components/InterDungeon/InterDungeonCard/atoms/CombatValue";
import OpposingValue from "components/InterDungeon/InterDungeonCard/atoms/OpposingValue";

const EquipDetails = ({
  crystalCost,
  value,
  opposingValue,
  equippableSlot,
  name,
  width,
}) => {
  const theme = useTheme();
  return (
    <Stack
      minHeight="100%"
      maxHeight="100%"
      alignItems="center"
      justifyContent="space-between"
      padding={0.5}
      width="100%"
      sx={{
        border: `solid 2px ${theme.palette.colors.interDungeon.cardElements.greyBrown}`,
        borderRadius: `${(width / 200) * 2}px`,
        maxWidth: "80px",
      }}
    >
      {crystalCost && crystalCost.crystals.amount !== 0
        ? crystalCost.crystals?.map((item, i) => {
            return (
              <Box
                sx={{ position: "absolute", top: "149px", right: "15px" }}
                key={`EQUIPPABLE_MODIFIER ${i}`}
              >
                <ManaIcon
                  width={width}
                  crystalAmount={item.amount}
                  crystalColor={item.color}
                  name={name}
                  equippableSlot={equippableSlot}
                />
              </Box>
            );
          })
        : null}
      <CombatValue width={width} value={value} name={name} />
      <Stack
        direction="row"
        alignItems="flex-end"
        gap={2}
        width="100%"
        sx={{
          justifyContent: opposingValue ? "flex-end" : "flex-start",
        }}
      >
        <EquipSlot equippableSlot={equippableSlot} name={name} />
        {opposingValue ? (
          <OpposingValue
            width={width}
            opposingValue={opposingValue}
            name={name}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default EquipDetails;
