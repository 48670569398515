import * as types from "./dataActionTypes";
import { put, retry, spawn, fork, call, take } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./dataActions";
import { channel } from "redux-saga";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* watchFetchAxieData() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchAxieDataRequest, chan);
  }

  try {
    while (true) {
      const { axieId } = yield take(types.FETCH_AXIE_DATA);
      yield put(chan, axieId);
    }
  } catch (e) {}
}

export function* makeFetchAxieDataRequest(chan) {
  try {
    while (true) {
      const axieId = yield take(chan);
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestGetAxieDetail,
        axieId
      );
      yield put(actions.fetchAxieDataSucceeded(data));
    }
  } catch (e) {}
}

export default function* axieDataSaga() {
  yield spawn(watchFetchAxieData);
}
