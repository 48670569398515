import styled from "@emotion/styled";
import { Stack, useTheme } from "@mui/material";
import CardName from "components/InterDungeon/InterDungeonCard/atoms/CardName";
import CardArt from "components/InterDungeon/InterDungeonCard/atoms/CardArt";
import BattlePower from "components/InterDungeon/InterDungeonCard/atoms/BattlePower";
import EquipDetails from "components/InterDungeon/InterDungeonCard/atoms/EquipDetails";

const Wrapper = styled(Stack)(({ theme, width }) => ({
  width: "100%",
  height: "100%",
  borderRadius: `${(width / 200) * 4}px `,
  overflow: "hidden",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingBottom: 20,
}));

const Container = styled(Stack, {
  shouldForwardProp: (prop) => !["width", "hover"].includes(prop),
})(({ theme, width, hover }) => ({
  width,
  height: `${(width / 200) * 260}px `,
  borderRadius: `${(width / 200) * 6}px `,
  overflow: "hidden",
  filter: `drop-shadow(0px ${(width / 200) * 2}px ${
    (width / 200) * 5
  }px rgba(6, 26, 30, 0.18))`,
  transition: "0.2s all ease",
  border: `solid ${(width / 200) * 2}px transparent`,

  ...(hover && {
    "&:hover": {
      border: `solid ${(width / 200) * 2}px #fff`,
      filter: `drop-shadow(0px ${(width / 200) * 2}px ${
        (width / 200) * 10
      }px rgba(6, 26, 30, 0.4))`,
      transform: "scale(1.02)",
    },
  }),
}));

const InterDungeonCard = ({
  width = 200,
  hover = true,
  id,
  name,
  cardImage,
  equippableModifier,
  nonEquippableModifer,
  rarity,
}) => {
  const theme = useTheme();
  return (
    <Container width={width} hover={hover}>
      <Wrapper
        sx={{
          // input correct colours for rarity when known
          background: theme.palette.colors.interDungeon.gradient[rarity],
        }}
        width={width}
      >
        <CardName width={width} name={name} />
        <Stack
          width="85%"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.palette.colors.interDungeon.cardElements.darkBg,
            borderRadius: `${(width / 200) * 2}px`,
            padding: 1,
            height: "200px",
            minHeight: "200px",
          }}
        >
          <CardArt width={width} cardImage={cardImage} name={name} />
          <Stack
            minHeight="90px"
            maxHeight="90px"
            gap={1}
            direction="row"
            alignItems="center"
            width="100%"
            alignSelf="start"
            sx={{
              justifyContent: equippableModifier ? "flex-end" : "flex-start",
            }}
          >
            {nonEquippableModifer ? (
              <BattlePower
                key={`EQUIP ${id}`}
                width={width}
                reuses={nonEquippableModifer.battleUses}
                value={nonEquippableModifer.value}
                opposingValue={nonEquippableModifer.opposingValue}
                crystalCost={
                  nonEquippableModifer.crystalCost
                    ? nonEquippableModifer.crystalCost
                    : null
                }
                name={name}
              />
            ) : null}
            {/* data when item is equipped */}
            {equippableModifier ? (
              <EquipDetails
                key={`NON-EQUIP ${id}`}
                width={width}
                crystalCost={
                  equippableModifier.crystalCost
                    ? equippableModifier.crystalCost
                    : null
                }
                equippableSlot={equippableModifier.equippableSlotTypes[0]}
                value={equippableModifier.value}
                opposingValue={equippableModifier.opposingValue}
                rarity={rarity}
                name={name}
              />
            ) : null}
          </Stack>
        </Stack>
      </Wrapper>
    </Container>
  );
};

export default InterDungeonCard;
