import React from "react";
import Card from "components/Card/Card";
import { ResponsiveContainer, LineChart, YAxis, Tooltip, Line } from "recharts";
import { Stack, useTheme, Typography } from "@mui/material";
import TooltipWrapper from "components/Charts/TooltipWrapper";
import TooltipCapsule from "components/Charts/TooltipCapsule";
import moment from "moment";
import { DATE_TIME_FORMAT } from "config";
import ChartError from "../atoms/ChartError";
import CardTitle from "../atoms/CardTitle";
import { VictoryStarDataPoint } from "types";

const ArenaChart = ({ data }: { data: VictoryStarDataPoint[] }) => {
  return (
    <Card style={{ p: 2.5 }}>
      <Stack gap={4}>
        <CardTitle>Victory Stars</CardTitle>
        {data != null && data.length > 0 ? (
          <Chart data={data} />
        ) : (
          <ChartError />
        )}
      </Stack>
    </Card>
  );
};

const Chart = ({ data }: { data: VictoryStarDataPoint[] }) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height={280}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
        }}
      >
        <YAxis
          stroke={theme.palette.card.subText}
          axisLine={{ stroke: theme.palette.card.pop3 }}
          style={{
            fontSize: "15px",
            fontWeight: "600",
          }}
          tickLine={{ stroke: theme.palette.card.pop3 }}
          type="number"
          domain={["dataMin - 100", "dataMax + 100"]}
        />
        <Tooltip
          content={<ChartTooltip />}
          cursor={{ stroke: "transparent" }}
        />
        <Line
          type="monotone"
          dataKey="victoryStars"
          stroke={theme.palette.secondary.main}
          activeDot={{ r: 8 }}
          strokeWidth={2}
          dot={{
            fill: theme.palette.secondary.main,
            r: 2,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

interface TooltipProps {
  active?: boolean;
  payload?: {
    payload: {
      name: string;
      label: string;
    };
    value: number;
  }[];
}

const ChartTooltip = ({ active, payload }: TooltipProps) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <TooltipCapsule
          color={theme.palette.secondary.main}
          textColor={theme.palette.secondary.contrastText}
          textAlign="center"
        >{`${payload[0].payload.label}: ${payload[0].value}`}</TooltipCapsule>
        <Typography
          component="span"
          color="text.muted"
          fontSize="13px"
          fontWeight="600"
          textAlign="center"
        >
          {moment.utc(payload[0].payload.name).local().format(DATE_TIME_FORMAT)}
        </Typography>
      </TooltipWrapper>
    );
  }
  return null;
};

export default ArenaChart;
