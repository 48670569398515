import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-26</Date>
      <Change type="new">
        Launched our new{" "}
        <Link component={RouterLink} to="/card-explorer">
          Card Explorer
        </Link>
        !
      </Change>
    </Entry>
  );
};

export default Changes;
