import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextareaAutosize,
  TextField,
  DialogActions,
  Tooltip,
  Button,
  Stack,
  Grid,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import CountryRegionSelect from "components/CountryRegionSelect/CountryRegionSelect";
import Label from "components/Typography/Label";
import CopyToClipboard from "react-copy-to-clipboard";
import { GoLinkExternal } from "react-icons/go";
import {
  deleteScholar,
  editScholar,
  setScholarToModifyIds,
  setOpenEditScholarDialog,
} from "store/guildDashboard/actions/guildDashboardActions";
import { Link } from "react-router-dom";
import { IoMdClipboard } from "react-icons/io";
import { useWeb3React } from "@web3-react/core";
import {
  parseRoninAddress,
  forceNumWithinRange,
  dateOrNullDate,
  dateOrNullDateTime,
} from "helpers/cleaning";
import {
  toggleTextWithDelay,
  makeRoninExplorerURLFromAddress,
} from "helpers/display";
import {
  parseCountryOrFallback,
  parseRegionOrFallback,
  createScholarPayload,
} from "helpers/scholars";
import CustomSelect from "components/CustomSelect/CustomSelect";
import * as scholarSelectors from "store/guildDashboard/selectors/scholar";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import LoadingButton from "@mui/lab/LoadingButton";
import Error from "components/Typography/Error";

const EditScholarDialog = ({
  scholar,
  open,
  setOpen,
  deleteScholar,
  editScholar,
  setScholarToModifyIds,
  teamsList,
}) => {
  const { account } = useWeb3React();
  const [roninAddress] = useState(scholar.roninAddress);
  const [scholarName, setScholarName] = useState(scholar.name);
  const [emailAddress, setEmailAddress] = useState(scholar.emailAddress);
  const [twitterId, setTwitterId] = useState(scholar.twitterId);
  const [discordId, setDiscordId] = useState(scholar.discordId);
  const [managerShare, setManagerShare] = useState(scholar.managerShare);
  const [investorShare, setInvestorShare] = useState(scholar.investorShare);
  const [payoutAddress, setPayoutAddress] = useState(scholar.payoutAddress);
  const [region, setRegion] = useState(
    parseRegionOrFallback(scholar.region, scholar.country)
  );
  const [country, setCountry] = useState(
    parseCountryOrFallback(scholar.country)
  );
  const [dob, setDOB] = useState(dateOrNullDate(scholar.dob));
  const [dailyQuota, setDailyQuota] = useState(scholar.dailyQuota);
  const [dateJoined, setDateJoined] = useState(
    dateOrNullDateTime(scholar.dateJoined)
  );
  const [notes, setNotes] = useState(scholar.notes);
  const [error, setError] = useState("");
  const [copyTooltip, setCopyTooltip] = useState("Copy ronin address");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [guildTeamId, setGuildteamId] = useState(scholar.guildTeamId);
  const theme = useTheme();

  const handleToggleTooltipText = () => {
    toggleTextWithDelay(setCopyTooltip, "Copy ronin address", "Copied", 1000);
  };

  useEffect(() => {
    if (scholar.editing === false && scholar.failedToEdit === true) {
      setError("Something went wrong...");
    }
  }, [scholar.editing, scholar.failedToEdit]);

  useEffect(() => {
    setError("");
  }, [open]);

  const handleEditScholar = () => {
    try {
      const parsedPayoutAddress = payoutAddress
        ? parseRoninAddress(payoutAddress)
        : "";
      const payload = createScholarPayload(
        scholarName,
        managerShare,
        roninAddress,
        parsedPayoutAddress,
        emailAddress,
        twitterId,
        discordId,
        region,
        country,
        dob,
        dateJoined,
        notes,
        dailyQuota,
        guildTeamId,
        investorShare
      );
      editScholar(account, payload, scholar.guildTeamId);
    } catch (e) {
      setError("Bad payout address!");
    }
  };

  const handleDeleteScholar = () => {
    deleteScholar(account, scholar.roninAddress, scholar.guildTeamId);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setScholarToModifyIds(undefined, undefined);
  };

  const handleClickDelete = () => {
    if (!scholar.deleting) {
      if (confirmDelete) {
        handleDeleteScholar();
      } else {
        setConfirmDelete(true);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth={"lg"}
      aria-labelledby="edit-scholar-dialog-title"
      className="edit-scholar-dialog"
    >
      <DialogTitle id="edit-scholar-dialog-title">Edit Scholar</DialogTitle>
      <DialogContent dividers={true}>
        <Stack gap={2}>
          <Error>{error}</Error>
          <Stack gap={0.5}>
            <Label>Key Details</Label>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="span"
                fontWeight="600"
                fontSize="14px"
                color="text.muted"
                gutterBottom
              >
                {roninAddress}
              </Typography>
              <Stack gap={0.5} direction="row">
                <CopyToClipboard
                  text={scholar.roninAddress}
                  onCopy={handleToggleTooltipText}
                >
                  <Tooltip title={copyTooltip}>
                    <IconButton sx={{ fontSize: "18px" }}>
                      <IoMdClipboard />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
                <Link
                  to={{
                    pathname: makeRoninExplorerURLFromAddress(
                      scholar.roninAddress
                    ),
                  }}
                  target="_blank"
                >
                  <Tooltip title="Visit ronin explorer">
                    <IconButton sx={{ fontSize: "18px" }}>
                      <GoLinkExternal />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Stack>
            </Stack>
          </Stack>

          <TextField
            onChange={(e) => setPayoutAddress(e.target.value)}
            label="Payout Address"
            variant="filled"
            size="small"
            value={payoutAddress}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => setScholarName(e.target.value)}
                label="Name"
                variant="filled"
                size="small"
                value={scholarName}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={(e) =>
                  setManagerShare(forceNumWithinRange(e.target.value, 0, 100))
                }
                label="Manager Share %"
                variant="filled"
                size="small"
                value={managerShare}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={(e) =>
                  setInvestorShare(forceNumWithinRange(e.target.value, 0, 100))
                }
                label="Investor Share %"
                variant="filled"
                size="small"
                value={investorShare}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                val={guildTeamId}
                setVal={setGuildteamId}
                options={teamsList}
                fullWidth={true}
                label="Team Name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setDailyQuota(
                    forceNumWithinRange(e.target.value, 0, undefined)
                  )
                }
                label="Daily SLP Quota"
                variant="filled"
                size="small"
                value={dailyQuota}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Label>Additional Info</Label>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                onChange={(e) => setDiscordId(e.target.value)}
                label="Discord Id"
                variant="filled"
                size="small"
                value={discordId}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                onChange={(e) => setTwitterId(e.target.value)}
                label="Twitter Id"
                variant="filled"
                size="small"
                value={twitterId}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                onChange={(e) => setEmailAddress(e.target.value)}
                label="Email Address"
                variant="filled"
                size="small"
                value={emailAddress}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => setDateJoined(e.target.value)}
                type="date"
                label="Date Joined"
                variant="filled"
                size="small"
                value={dateJoined ? dateJoined : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => setDOB(e.target.value)}
                type="date"
                label="Birthday"
                variant="filled"
                size="small"
                value={dob ? dob : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <CountryRegionSelect
            setCountry={setCountry}
            country={country}
            region={region}
            setRegion={setRegion}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "16px",
            }}
          />
          <TextareaAutosize
            aria-label="note"
            minRows={6}
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
            spellCheck={false}
            placeholder="Notes"
            style={{
              background: theme.palette.input.filled.background,
              color: theme.palette.text.primary,
              fontSize: "16px",
              fontWeight: "500",
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          onClick={handleClickDelete}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.feedback.red,
            "&:hover": {
              backgroundColor: theme.palette.feedback.red,
            },
          }}
        >
          {confirmDelete ? "Click again to confirm" : "Delete"}
        </Button>
        <Stack gap={1} direction="row">
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          {scholar.editing ? (
            <LoadingButton
              variant="contained"
              loading
              sx={{ alignSelf: "stretch" }}
            />
          ) : (
            <Button onClick={handleEditScholar} variant="contained">
              Save
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  deleteScholar,
  editScholar,
  setScholarToModifyIds,
  setOpen: setOpenEditScholarDialog,
};

const mapStateToProps = (state) => ({
  teamsList: teamSelectors.getTeamsListForDropdown(state),
  open: state.guildDashboard.openEditScholarDialog,
  scholar: scholarSelectors.getScholarToModify(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditScholarDialog);
