import React from "react";
import {
  FormControl,
  useTheme,
  InputLabel,
  Select,
  Chip,
  MenuItem,
} from "@mui/material";

export const Dropdown = ({
  label,
  value,
  setValue,
  options,
  minWidth = 130,
  multiple = true,
  hiddenLabel,
  formControlStyles,
}) => {
  const theme = useTheme();
  const origin = theme.palette.colors.origin;
  return (
    <FormControl
      variant="filled"
      size="small"
      fullWidth
      sx={{
        minWidth,
        "& .MuiFilledInput-root": {
          width: "100%",
          background: origin.wood.input.background,
          border: `1px solid ${origin.wood.input.border}`,
          color: origin.wood.text,
          boxShadow: "0px 4px 0px rgba(0,0,0,0.25)",
          "&:hover": {
            background: origin.wood.input.background,
            border: `1px solid ${origin.wood.text}`,
            boxShadow: "0px 4px 0px rgba(0,0,0,0.25)",
          },
          "&:active, &.Mui-focused": {
            backgroundColor: `${origin.wood.input.background} !important`,
            borderColor: origin.wood.text,
            boxShadow: `0px 0px 0 3px rgba(255,255,255,0.3)`,
          },
        },
        "& .MuiInputLabel-root": {
          color: origin.wood.text,
          fontWeight: 500,
          "&.Mui-focused": { color: origin.wood.text },
        },
        "& .MuiFilledInput-input": {
          "&:focus": {
            backgroundColor: origin.wood.input.background,
          },
        },
        "& .MuiSelect-icon": {
          fill: origin.wood.text,
        },
        ...formControlStyles,
      }}
    >
      <InputLabel id={`${label}-select`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        renderValue={(selected, l) => {
          if (Array.isArray(selected)) {
            return (
              <>
                {selected.map((value) => (
                  <Chip key={value} label={value} className="item" />
                ))}
              </>
            );
          } else {
            const l = options.find((option) => option.value === selected);
            return <>{l.label}</>;
          }
        }}
        multiple={multiple}
        hiddenLabel={hiddenLabel}
        sx={{
          "& .MuiChip-root": {
            background: "#fff",
            color: origin.wood.background,
            marginRight: "2px",
            height: "24px",
          },
          width: 160,
          height: 51,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: origin.wood.input.background,
              border: `1px solid ${origin.wood.input.border}`,
              color: origin.wood.text,
              "& .MuiMenuItem-root": {
                my: 0.5,
                "&:focus": {
                  backgroundColor: `${origin.wood.input.background} !important`,
                  color: origin.wood.text,
                  "&:hover": {
                    backgroundColor: `#fff !important`,
                    color: origin.wood.background,
                  },
                },
                "&:hover, &:active, &.Mui-selected, &.Mui-focusVisible, &:focus-visible":
                  {
                    backgroundColor: `#fff !important`,
                    color: origin.wood.background,
                  },
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={isObject(option) && "value" in option ? option.value : option}
            value={
              isObject(option) && "value" in option ? option.value : option
            }
          >
            {isObject(option) && "label" in option ? option.label : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function isObject(o) {
  return o instanceof Object && o.constructor === Object;
}

export default Dropdown;
