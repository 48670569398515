import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import chartIcons from "icons/chartIcons";
import ToggleGroup from "../atoms/ToggleGroup";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  justifyContent: "center",
  flexWrap: "wrap",
}));

const Toggles = ({ controls, handleUpdateControl }) => {
  if (
    controls == null ||
    handleUpdateControl == null ||
    controls.variantOptions?.length < 2
  ) {
    return null;
  }
  return (
    <Wrapper>
      <ToggleGroup
        title="Chart View"
        options={controls.variantOptions}
        control={controls.variant}
        updateControl={handleUpdateControl("variant")}
        icons={chartIcons ? chartIcons : null}
      />
    </Wrapper>
  );
};

export default Toggles;
