import React from "react";
import { Stack, Box } from "@mui/material";
import Dropdown from "components/Origin/atoms/Dropdown";
import {
  updateCardFilters,
  resetCardFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";
import { BODY_PARTS, AXIE_CLASSES } from "config";

const CardControls = ({ cardFilters, updateCardFilters, resetCardFilters }) => {
  const handleUpdateCardFilters = (newFilters) => {
    updateCardFilters({ ...cardFilters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateCardFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack gap={2}>
      <Search
        value={cardFilters.searchText}
        handleUpdate={handleUpdateCardFilters}
        placeholder="Search cards"
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        }}
      >
        <Dropdown
          label="Class"
          value={cardFilters.axieClasses}
          setValue={handleUpdateDropdown("axieClasses")}
          options={AXIE_CLASSES}
        />
        <Dropdown
          label="Part Type"
          value={cardFilters.partTypes}
          setValue={handleUpdateDropdown("partTypes")}
          options={BODY_PARTS}
        />
        <Dropdown
          label="Energy Cost"
          value={cardFilters.costs}
          setValue={handleUpdateDropdown("costs")}
          options={["0", "1", "2"]}
        />
        <Dropdown
          label="Tag"
          value={cardFilters.tags}
          setValue={handleUpdateDropdown("tags")}
          options={[
            "attack",
            "skill",
            "power",
            "secret",
            "retain",
            "innate",
            "banish",
            "ethereal",
          ]}
        />
      </Box>
      <TextButton
        onClick={resetCardFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCardFilters,
  resetCardFilters,
};

const mapStateToProps = (state) => ({
  cardFilters: state.explorer.cardFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(CardControls);
