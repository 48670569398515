import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageHeader from "./PageHeader";
import TriballyAxieQuestBanner from "components/TriballyAdBanner/TriballyAxieQuestBanner";
// import PageControls from "./PageControls";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "breakpoint",
})(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  padding: `${theme.spacing(1)} 0 ${theme.spacing(6)}`,
}));

const PageContent = ({ titleImg, titleAlt, controls, children }) => {
  return (
    <Stack alignSelf="stretch">
      <Wrapper direction={{ xs: "column", sm: "column", md: "row" }}>
        <PageHeader titleImg={titleImg} titleAlt={titleAlt} />
        <TriballyAxieQuestBanner />
        {/* <PageControls id="page-controls">{controls}</PageControls> */}
      </Wrapper>

      {children}
    </Stack>
  );
};

export default PageContent;
