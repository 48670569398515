import { Stack, Typography } from "@mui/material";
import { isBreedingEventActive } from "helpers/time";
import React from "react";
import { TwitterShareButton } from "react-twitter-embed";
import Panel from "./Panel";

const EventBanner = () => {
  if (!isBreedingEventActive()) {
    return null;
  }

  return (
    <Panel
      style={{ p: 0, img: { borderRadius: "8px 8px 0 0", maxWidth: "100%" } }}
    >
      <img
        src="/images/accents/summer-banner.jpg"
        alt="Summer Breeding Event"
      />
      <Stack gap={1} sx={{ p: 1 }} alignItems="center">
        <Stack>
          <Typography
            variant="span"
            textAlign="center"
            fontWeight={600}
            fontSize={16}
            color="text.regular"
          >
            Share your
          </Typography>
          <Typography
            variant="span"
            textAlign="center"
            fontWeight={800}
            fontSize={26}
            color="text.bright"
          >
            Summer Axies!
          </Typography>
        </Stack>
        <TwitterShareButton
          onLoad={function noRefCheck() {}}
          options={{
            size: "large",
            text: "I bred a Summer Axie! 🏖️ #LunacianSummer",
            via: "Axie_Tech",
          }}
          url="https://axie.tech/breeding-simulator"
        />
      </Stack>
    </Panel>
  );
};

export default EventBanner;
