import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IoCloseCircle } from "react-icons/io5";
import { IconButton } from "@mui/material";

const DeleteIcon = ({ deleteAxie, id }) =>
  deleteAxie && id ? (
    <Tooltip title="Delete axie">
      <IconButton onClick={() => deleteAxie(id)} size="small">
        <IoCloseCircle />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );

export default DeleteIcon;
