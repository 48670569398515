import React, { useCallback, useEffect } from "react";
import Player from "../molecules/Player";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Pagination from "components/DoLL/Layout/Pagination";
import Search from "components/DoLL/Layout/Search";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 1000,
  margin: "0 auto",
  gap: theme.spacing(3),
  "@media (max-width: 1000px)": {
    width: "100%",
  },
}));

const Players = ({ leaderboard, controls, updateControls }) => {
  const handleUpdateControls = useCallback(
    (key) => (val) => {
      updateControls({ ...controls, [key]: val });
    },
    [updateControls, controls]
  );

  useEffect(() => {
    if (leaderboard.currentPage > leaderboard.numberOfPages) {
      handleUpdateControls("currentPage")(1);
    }
  }, [
    leaderboard.numberOfPages,
    leaderboard.currentPage,
    handleUpdateControls,
  ]);
  return (
    <Stack gap={8} alignItems="center">
      <Search
        placeholder="Search players"
        searchText={controls.searchText}
        updateSearchText={(e) =>
          handleUpdateControls("searchText")(e.target.value)
        }
      />
      <Wrapper gap={3}>
        {leaderboard.players.map((player, i) => (
          <Player {...player} key={player._id} />
        ))}
      </Wrapper>

      <Pagination
        onChange={(e, v) => handleUpdateControls("currentPage")(v)}
        count={leaderboard.numberOfPages}
        page={leaderboard.currentPage}
      />
    </Stack>
  );
};

export default Players;
