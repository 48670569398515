import React, { useState } from "react";
import styled from "@emotion/styled";
import { Stack, Tooltip, Typography } from "@mui/material";
import fonts from "theme/fonts";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toggleTextWithDelay } from "helpers/display";

const Wrapper = styled(Stack)(({ theme }) => ({
  border: "1px solid rgba(245, 195, 117, 0.13)",
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  borderRadius: theme.spacing(2),
  flexDirection: "row",
  gap: theme.spacing(1.5),
  alignItems: "center",
  transition: "0.2s transform ease",
  ".viewLogo": {
    display: "block",
  },
  ".viewCopy": {
    display: "none",
  },
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.1)",
    ".viewLogo": {
      display: "none",
    },
    ".viewCopy": {
      display: "block",
    },
  },
  "&:active": {
    transform: "scale(1)",
  },
}));

const IconWrapper = styled(Stack)(({ theme }) => ({
  background: "linear-gradient(225deg, #AC6AEA 0%, #6314AE 100%)",
  width: 42,
  height: 42,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  color: "#fff",
  alignItems: "center",
  justifyContent: "center",
  "img, svg": {
    width: "100%",
  },
}));

const UseCodeAxieTech = () => {
  const [copyTooltip, setCopyTooltip] = useState("");

  return (
    <CopyToClipboard text="AXIETECH">
      <Tooltip
        onClick={() =>
          toggleTextWithDelay(setCopyTooltip, "", "Thank you!", 1000)
        }
        title={copyTooltip === "" ? "Click to copy" : copyTooltip}
        placement="right"
      >
        <Wrapper>
          <IconWrapper>
            <img
              src="/images/axie-tech-logo-mark-white.png"
              alt="Axie Tech logo"
              className="viewLogo"
            />
            <Stack className="viewCopy">
              <FaCopy />
            </Stack>
          </IconWrapper>

          <Stack gap={0.25}>
            <Typography
              variant="span"
              fontFamily={fonts.changa}
              color="#F5C375"
              sx={{ userSelect: "none" }}
            >
              Use Code:
            </Typography>
            <Typography
              variant="span"
              fontFamily={fonts.changa}
              color="#fff"
              fontSize={22}
            >
              AXIETECH
            </Typography>
          </Stack>
        </Wrapper>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default UseCodeAxieTech;
