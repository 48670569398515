import React from "react";
import { Stack, Box } from "@mui/material";

const TabLabel = ({ text, icon }) => (
  <Stack direction="row" alignItems="center" gap="6px">
    <Stack>{icon}</Stack> <Box>{text}</Box>
  </Stack>
);

export default TabLabel;
