import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-12-21</Date>
      <Change type="improved">
        Updated our <CustomLink to="/explorer">Explorer</CustomLink> in
        anticipation of Origins Season 2!
      </Change>
      <Change type="new">
        Guild Dashboard: Support team registration for our first{" "}
        <CustomLink to="/guild-membership">Guild Battle</CustomLink>{" "}
        competition, starting soon.
      </Change>
    </Entry>
  );
};

export default Changes;
