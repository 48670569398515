import React from "react";
import { addOrdinalSuffix } from "helpers/display";
import { Stack, useTheme, Typography } from "@mui/material";
import Label from "../atoms/Label";
import CustomChip from "components/Typography/CustomChip";

const Stat = ({
  label,
  amount,
  amountSuffix,
  subtext,
  large,
  rank,
  addRankSuffix,
  amountColor,
}) => {
  const theme = useTheme();
  return (
    amount != null && (
      <Stack gap={0.5} justifyContent="center">
        {label != null && <Label>{label}</Label>}
        <Stack alignItems="center" gap={1} direction="row">
          <Stack direction="row" gap={0.5} alignItems="flex-end">
            <Typography
              fontWeight="700"
              fontSize={large ? "36px" : "22px"}
              variant="span"
              color={amountColor || theme.palette.card.text}
            >
              {isNaN(amount) ? amount : Math.floor(amount)}
            </Typography>

            {amountSuffix != null && (
              <Typography
                fontWeight="600"
                fontSize={large ? "24px" : "18px"}
                variant="span"
                color={theme.palette.card.text}
                sx={{ position: "relative", bottom: 2 }}
              >
                {amountSuffix}
              </Typography>
            )}
          </Stack>

          {rank != null && (
            <CustomChip>
              {addRankSuffix ? addOrdinalSuffix(rank) : rank}
            </CustomChip>
          )}
        </Stack>

        {subtext != null && (
          <Typography
            fontSize={large ? "18px" : "16px"}
            color={theme.palette.card.subText}
            fontWeight="600"
            variant="span"
          >
            {subtext}
          </Typography>
        )}
      </Stack>
    )
  );
};

export default Stat;
