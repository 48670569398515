import React from "react";
import { Stack, Typography } from "@mui/material";

const InputsHeader = ({ title, description }) => {
  return (
    <Stack gap={1}>
      <Typography
        variant="h2"
        color="text.bright"
        fontSize="28px"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.muted"
        fontSize="16px"
        textAlign="center"
        sx={{ maxWidth: "360px", margin: "0 auto" }}
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default InputsHeader;
