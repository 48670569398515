import * as types from "./chartsActionTypes";
import produce from "immer";
import moment from "moment";

const SLP_ISSUANCE_BLOCKCHAIN_VARIANT_OPTIONS = [
  { value: "positiveNegative", label: "+/-" },
  { value: "stacked", label: "Stacked" },
  { value: "dual", label: "Dual" },
  { value: "net", label: "Net" },
];
const DEFAULT_SLP_ISSUANCE_BLOCKCHAIN_VARIANT =
  SLP_ISSUANCE_BLOCKCHAIN_VARIANT_OPTIONS[0].value;

const SLP_ISSUANCE_INGAME_VARIANT_OPTIONS = [{ value: "bar", label: "Bar" }];
const DEFAULT_SLP_ISSUANCE_INGAME_VARIANT =
  SLP_ISSUANCE_INGAME_VARIANT_OPTIONS[0].value;

const oneMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
const today = moment().format("YYYY-MM-DD");

export const initialState = {
  slpIssuance: {
    blockchain: {
      controls: {
        variant: DEFAULT_SLP_ISSUANCE_BLOCKCHAIN_VARIANT,
        variantOptions: SLP_ISSUANCE_BLOCKCHAIN_VARIANT_OPTIONS,
        startDate: oneMonthAgo,
        endDate: today,
      },
    },
    inGame: {
      controls: {
        variant: DEFAULT_SLP_ISSUANCE_INGAME_VARIANT,
        variantOptions: SLP_ISSUANCE_INGAME_VARIANT_OPTIONS,
        startDate: oneMonthAgo,
        endDate: today,
      },
    },
    data: [],
  },
};

export const chartsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CHARTS_FETCH_SLP_ISSUANCE_STARTED:
        draft.slpIssuance.fetching = true;
        break;
      case types.CHARTS_FETCH_SLP_ISSUANCE_SUCCEEDED:
        draft.slpIssuance.data = action.data;
        draft.slpIssuance.fetching = false;
        break;
      case types.CHARTS_FETCH_SLP_ISSUANCE_FAILED:
        draft.slpIssuance.fetching = false;
        break;
      case types.CHARTS_UPDATE_SLP_ISSUANCE_BLOCKCHAIN_CONTROLS:
        draft.slpIssuance.blockchain.controls = action.controls;
        break;
      case types.CHARTS_UPDATE_SLP_ISSUANCE_INGAME_CONTROLS:
        draft.slpIssuance.inGame.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default chartsReducer;
