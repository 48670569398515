import { Autocomplete as MuiAutocomplete, useTheme } from "@mui/material";
import styled from "@emotion/styled";

const AutocompleteStyled = styled(MuiAutocomplete)(({ theme }) => ({
  minWidth: 160,
  "& .MuiFilledInput-root": {
    minWidth: 160,
    paddingTop: 0,
    position: "relative",
    height: 51,
    background: theme.palette.colors.origin.wood.input.background,
    border: `1px solid ${theme.palette.colors.origin.wood.input.border}`,
    color: theme.palette.colors.origin.wood.text,
    boxShadow: "0px 4px 0px rgba(0,0,0,0.25)",
    flexWrap: "nowrap",
    "&:hover": {
      background: theme.palette.colors.origin.wood.input.background,
      border: `1px solid ${theme.palette.colors.origin.wood.text}`,
      boxShadow: "0px 4px 0px rgba(0,0,0,0.25)",
      cursor: "pointer",
    },
    "&:active, &.Mui-focused": {
      backgroundColor: `${theme.palette.colors.origin.wood.input.background} !important`,
      borderColor: theme.palette.colors.origin.wood.text,
      boxShadow: `0px 0px 0 3px rgba(255,255,255,0.3)`,
    },
    "& .MuiChip-root": {
      background: "#fff",
      color: theme.palette.colors.origin.text.brown,
      border: "none",
      height: 22,
      ".MuiChip-label": {
        padding: "2px 4px",
      },
      " .MuiChip-deleteIcon": {
        color: theme.palette.colors.origin.text.brown,
        fontSize: 16,
        "&:hover": {
          color: theme.palette.colors.origin.wood.background,
        },
      },
    },
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.colors.origin.wood.text,
    fontWeight: 500,
    "&.Mui-focused": { color: theme.palette.colors.origin.wood.text },
  },
  "& .MuiFilledInput-input": {
    height: 49,
    padding: "0 0 0 16px !important",
    position: "absolute",
    left: 0,
    right: 0,
    minWidth: "100% !important",
    background: "transparent",

    "&::placeholder": {
      color: theme.palette.colors.origin.wood.text,
      opacity: 0.2,
      fontSize: 15,
      fontWeight: 600,
    },
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": {
      background: "transparent",
    },
  },
  "& .MuiAutocomplete-endAdornment svg": {
    color: theme.palette.colors.origin.wood.text,
  },
}));

const Autocomplete = (props) => {
  const theme = useTheme();
  const activeStyles = {
    backgroundColor: "#ffffff !important",
    color: theme.palette.colors.origin.wood.background,
  };
  const autocompleteListboxStyles = {
    backgroundColor: theme.palette.colors.origin.wood.input.background,
    border: `1px solid ${theme.palette.colors.origin.wood.input.border}`,
    borderRadius: "8px",
    color: theme.palette.colors.origin.wood.text,
    "& .MuiAutocomplete-option": {
      my: 0.5,
      padding: "4px 10px 4px 12px",
      "&:hover, &:focus, &.Mui-focused, &:active, &.Mui-selected, &.Mui-focusVisible, &:focus-visible, &[aria-selected='true']":
        {
          ...activeStyles,
          "&:hover": {
            ...activeStyles,
          },
        },
    },
  };

  return (
    <AutocompleteStyled
      {...props}
      ListboxProps={{
        sx: autocompleteListboxStyles,
      }}
    />
  );
};

export default Autocomplete;
