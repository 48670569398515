import React from "react";
import SocialIcons from "../../Social/SocialIcons";
import { List, Stack, useTheme } from "@mui/material";
import GuidesButton from "../atoms/GuidesButton";
import DesktopNavItem from "../molecules/DesktopNavItem";

const DesktopNav = ({ links, setMobileMenuOpen, colorOverrides }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      }}
    >
      <List sx={{ display: "flex", alignItems: "center", gap: 4, py: "3px" }}>
        {Object.keys(links).map((link, i) => (
          <DesktopNavItem
            link={links[link]}
            key={`NAVIGATION_LINK_${i}`}
            setMobileMenuOpen={setMobileMenuOpen}
            colorOverrides={colorOverrides}
          />
        ))}
      </List>
      <GuidesButton />
      <SocialIcons
        size="15px"
        gap={0.5}
        color={colorOverrides?.navigation?.text}
      />
    </Stack>
  );
};

export default DesktopNav;
