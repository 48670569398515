import { useEffect } from "react";
import PageHeader from "components/PageHeader/PageHeader";
import {
  GUILD_BATTLE_LEADERBOARD,
  GUILD_BATTLE_LEADERBOARD_TITLE,
} from "config";
import { fetchGuildBattleMMRLeaderboard } from "store/leaderboard/guildBattle/leaderboardActions";
import { getGuildBattleLeaderboardData } from "store/leaderboard/guildBattle/guildBattleSelector";
import { connect } from "react-redux";
import PullUp from "components/Utility/PullUp";
import OtherLeaderboardButtons from "components/Leaderboard/molecules/OtherLeaderboardButtons";
import { Typography, Stack, Button } from "@mui/material";
import FullWidthWrapper from "../FullWidthWrapper";
import styled from "@emotion/styled";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import BattleLeaderboard from "./organisms/BattleLeaderboard";
import { Link as RouterLink } from "react-router-dom";
import { guildBattleResults } from "pages/Leaderboards/GuildBattle/utils/results";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.colors.purple[300],
  backgroundColor: theme.palette.mode === "light" ? "#eee" : "#ddd",
  minWidth: "130px",
  whiteSpace: "nowrap",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const GuildBattleLeaderboard = ({
  fetchGuildBattleMMRLeaderboard,
  mmrBattleLeaderboard,
  data,
  mmrBattleLoading,
  mmrBattleFailed,
  failedMessage = "Unable to load leaderboard",
}) => {
  useEffect(() => {
    fetchGuildBattleMMRLeaderboard();
  }, [fetchGuildBattleMMRLeaderboard]);
  const fullSize = true;

  const button = {
    text: "Learn more",
    to: "/guild-membership",
    icon: <IoCaretForwardCircleOutline />,
  };

  const results = true;

  const pageDescription = (
    <>
      <Stack alignItems="center" spacing={2}>
        <Typography variant="span">
          Axie Tech Guild Battle Final Standings!
        </Typography>
        <ColorButton
          to={button.to}
          component={RouterLink}
          variant="contained"
          endIcon={button.icon}
          size="medium"
        >
          {button.text}
        </ColorButton>
      </Stack>
    </>
  );

  return (
    <>
      <PageHeader
        title="Axie Tech Guild Battle"
        description={pageDescription}
        centered
        headerStyle="axies"
      />

      <FullWidthWrapper>
        <PullUp>
          {results ? (
            <BattleLeaderboard
              type={GUILD_BATTLE_LEADERBOARD}
              title={GUILD_BATTLE_LEADERBOARD_TITLE}
              data={guildBattleResults}
              top8={guildBattleResults.slice(0, 8)}
              top16={guildBattleResults.slice(8, 16)}
              fullsize={fullSize}
            />
          ) : (
            <BattleLeaderboard
              type={GUILD_BATTLE_LEADERBOARD}
              title={GUILD_BATTLE_LEADERBOARD_TITLE}
              data={mmrBattleLeaderboard}
              top8={data.top8}
              top16={data.top16}
              top25={data.top25}
              loading={mmrBattleLoading}
              failed={mmrBattleFailed}
              retry={fetchGuildBattleMMRLeaderboard}
              failedMessage={failedMessage}
              fullsize={fullSize}
            />
          )}
        </PullUp>

        <OtherLeaderboardButtons buttons={["GUILD", "ORIGIN"]} />
      </FullWidthWrapper>
    </>
  );
};

const mapDispatchToProps = {
  fetchGuildBattleMMRLeaderboard,
};

const mapStateToProps = (state) => ({
  mmrBattleLeaderboard: state.guildBattleLeaderboard.mmrBattleLeaderboard.data,
  data: getGuildBattleLeaderboardData(state),
  mmrBattleLoading: state.guildBattleLeaderboard.mmrBattleLeaderboard.fetching,
  mmrBattleFailed: state.guildBattleLeaderboard.mmrBattleLeaderboard.failed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuildBattleLeaderboard);

// export default GuildBattleLeaderboard;
