import { Typography } from "@mui/material";
import { axieIsStarter } from "helpers/axie";

const ID = ({ axie, width, id }) => {
  let axieId;
  const fontSizeRaw = (14 / 320) * width;
  const fontSize = fontSizeRaw < 12 ? 12 : fontSizeRaw;

  if (axieIsStarter(axie)) {
    axieId = axie.name;
  } else {
    const parsed = axie?.axieId || id;
    if (parsed) {
      axieId = `#${parsed}`;
    }
  }

  return axieId == null ? (
    <></>
  ) : (
    <Typography
      fontSize={fontSize}
      fontWeight={600}
      color="text.regular"
      sx={{ lineHeight: 1 }}
    >
      {axieId}
    </Typography>
  );
};

export default ID;
