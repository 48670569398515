import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import { PART_ORDER } from "pages/TeamBuilder";
import Part from "./Part";

const Wrapper = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  gridGap: 6,
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 2,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 1,
  },
}));

const Main = ({
  axie,
  updateCard,
  updateCharm,
  view,
  handleOpenCardDialog,
  handleOpenCharmDialog,
}) => {
  return (
    <Wrapper>
      {PART_ORDER.map((part, i) => {
        const cardData = axie.parts[part].card;
        const cardClass = cardData == null ? undefined : cardData.class;

        return (
          <Part
            part={part}
            axie={axie}
            cardClass={cardClass}
            key={`CARD_${i}`}
            updateCard={updateCard}
            updateCharm={updateCharm}
            view={view}
            handleOpenCardDialog={handleOpenCardDialog}
            handleOpenCharmDialog={handleOpenCharmDialog}
          />
        );
      })}
    </Wrapper>
  );
};

export default Main;
