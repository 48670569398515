import * as types from "store/guildDashboard/actions/actionTypes";
import { put, call, takeLatest, fork } from "redux-saga/effects";
import { axieTechAPI } from "store/sagaHelpers";
import { fetchTeams } from "./guildDashboardTeamsSaga";
import endpoints from "services/api/endpoints";

export function* setGuildData(data) {
  try {
    yield put({
      type: types.GUILD_DASHBOARD_LOGIN_MANAGER_SUCCEEDED,
      payload: data,
    });
  } catch (e) {
    console.log("Could not set data", e);
  }
}

export function* loginManager(action) {
  try {
    yield put({ type: types.GUILD_DASHBOARD_LOGIN_MANAGER_STARTED });
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginAddress: action.loginAddress,
      }),
    };
    const response = yield call(axieTechAPI, endpoints.guildLogin, params);
    const teams = response?.teams ?? [];
    yield call(setGuildData, response);
    yield fork(fetchTeams, teams, action.loginAddress); // Fetch scholars in teams
  } catch (e) {
    console.log("Error logging in");
    yield put({ type: types.GUILD_DASHBOARD_LOGIN_MANAGER_FAILED });
  }
}

export default function* guildDashboardLoginSaga() {
  yield takeLatest(types.GUILD_DASHBOARD_LOGIN_MANAGER, loginManager);
}
