import React, { useState } from "react";
import { fetchAxiesForScholar } from "store/guildDashboard/actions/guildDashboardActions";
import { connect } from "react-redux";
import { getIconForAxieClass } from "helpers/axie";
import { Button, Stack } from "@mui/material";
import styled from "@emotion/styled";

const Total = styled(Stack)(({ theme }) => ({
  background: theme.palette.text.muted,
  color: theme.palette.card.background,
  alignItems: "center",
  justifyContent: "center",
  padding: "2px 4px",
  borderRadius: "8px",
  minWidth: 36,
  fontWeight: 700,
  height: 24,
  fontSize: 12,
}));

const Count = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.muted,
  fontWeight: 700,
  fontSize: 12,
  flexDirection: "row",
  alignItems: "center",
  svg: { width: 14, height: "auto" },
  gap: "1px",
}));

export const DisplayAxies = ({ axieClassCounts = [], totalAxies = 0 }) => {
  return (
    <Stack gap="6px" direction="row" alignItems="center">
      <Total>{totalAxies >= 100 ? "100+" : totalAxies}</Total>
      {axieClassCounts.map((axieClass) => (
        <Count key={axieClass[0]}>
          {axieClass[0] === "Egg"
            ? getIconForAxieClass("egg")
            : getIconForAxieClass(axieClass[0])}
          {axieClass[1]}
        </Count>
      ))}
    </Stack>
  );
};

export const DisplayLoadButton = ({ handleClick, fetchingAxies, buttonText }) =>
  fetchingAxies ? (
    <Button size="small" sx={{ fontSize: 14 }}>
      Loading...
    </Button>
  ) : (
    <Button variant="text" size="small" onClick={handleClick}>
      {buttonText}
    </Button>
  );

export const AxiePreviews = ({
  roninAddress,
  axieClassCounts,
  totalAxies,
  fetchAxiesForScholar,
  fetchingAxies,
  guildTeamId,
  showAllAxies = false,
}) => {
  const [hasClickedLoad, setHasClickedLoad] = useState(false);

  const handleLoadAxies = () => {
    fetchAxiesForScholar(roninAddress, guildTeamId);
    setHasClickedLoad(true);
  };

  if (totalAxies === 0 && !hasClickedLoad) {
    return (
      <DisplayLoadButton
        handleClick={handleLoadAxies}
        fetchingAxies={fetchingAxies}
        buttonText="Load"
      />
    );
  }
  if (totalAxies === 0 && hasClickedLoad) {
    return (
      <DisplayLoadButton
        handleClick={handleLoadAxies}
        fetchingAxies={fetchingAxies}
        buttonText="No Axies"
      />
    );
  }
  return (
    <DisplayAxies
      axieClassCounts={axieClassCounts}
      totalAxies={totalAxies}
      showAllAxies={showAllAxies}
    />
  );
};

const mapDispatchToProps = {
  fetchAxiesForScholar,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AxiePreviews);
