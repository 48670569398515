import {
  GUILD_RATING_STAR_THRESHOLDS,
  GUILD_NOT_EARNING_MMR_THRESHOLD,
  AXIE_TECH_CDN,
} from "config";
import {
  calculateManagerSLP,
  calculateTotalSLP,
  calculateMMR,
  calculateRank,
  calculateTier,
} from "./scholars";
import { average } from "./math";
import { makePlayerUrl } from "./display";
import { createRankTierString, getWinSLPRewardForRankTier } from "./arena";

export const getGuildLogoURL = (guildSlug) =>
  guildSlug ? `${AXIE_TECH_CDN}/guilds/${guildSlug}.jpg` : null;

export const getGuildRating = (averageStars) => {
  if (averageStars == null) return null;

  let guildRating;

  if (averageStars > GUILD_RATING_STAR_THRESHOLDS.s) {
    guildRating = "S";
  } else if (averageStars > GUILD_RATING_STAR_THRESHOLDS.a) {
    guildRating = "A";
  } else if (averageStars > GUILD_RATING_STAR_THRESHOLDS.b) {
    guildRating = "B";
  } else if (averageStars > GUILD_RATING_STAR_THRESHOLDS.c) {
    guildRating = "C";
  } else {
    guildRating = "D";
  }
  return guildRating;
};

export const calculateOverallStats = (teamStats) => {
  const overallStats = {
    numberOfTeams: 0,
    numberOfScholars: 0,
    numberOfAxies: 0,
    numberOfEggs: 0,
    numberOfNotEarningScholars: 0,
    totalUnclaimedSLP: 0,
    managerUnclaimedSLP: 0,
  };

  const mmrs = [];

  for (const team in teamStats) {
    if (teamStats[team].guildTeamId !== 0) {
      overallStats.numberOfTeams += 1;
    }
    if (teamStats[team].numberOfScholars > 0) {
      overallStats.numberOfScholars += teamStats[team].numberOfScholars;
      overallStats.numberOfNotEarningScholars +=
        teamStats[team].numberOfNotEarningScholars;
      overallStats.totalUnclaimedSLP += teamStats[team].totalUnclaimedSLP;
      overallStats.managerUnclaimedSLP += teamStats[team].managerUnclaimedSLP;
      overallStats.numberOfAxies += teamStats[team].numberOfAxies;
      overallStats.numberOfEggs += teamStats[team].numberOfEggs;

      if (teamStats[team].guildTeamId !== 0) {
        mmrs.push({
          name: teamStats[team].name || "No team",
          mmr: teamStats[team].overallAverageMMR,
          guildTeamId: teamStats[team].guildTeamId,
          topScholars: teamStats[team].sortedMMRs.slice(0, 5),
        });
      }
    }
  }

  const sortedMMRs = mmrs.sort((a, b) =>
    a.mmr > b.mmr ? -1 : a.mmr < b.mmr ? 1 : 0
  );

  return {
    ...overallStats,
    sortedMMRs,
    arenaMVP: sortedMMRs[0]?.name,
    highestMMR: sortedMMRs[0]?.mmr,
  };
};

export const scholarStarsAboveThreshold = (stars) => {
  if (!stars || stars < GUILD_NOT_EARNING_MMR_THRESHOLD) {
    return false;
  }
  return true;
};

export const calculateStatsForScholarGroup = (
  scholars,
  showUnderperformers = true
) => {
  var totalUnclaimedSLP = 0;
  var managerUnclaimedSLP = 0;
  var mmrs = [];
  var mmrAmounts = [];
  let numberOfNotEarningScholars = 0;
  var numberOfAxies = 0;
  var numberOfEggs = 0;
  let hiddenArenaScholars = [];

  scholars &&
    scholars.forEach((currentScholar) => {
      const currentScholarUnclaimedSLP = calculateTotalSLP(currentScholar);
      const managerSLP = calculateManagerSLP(currentScholar) ?? 0;
      const currentScholarMMR = calculateMMR(currentScholar) ?? 0;
      const playerUrl = makePlayerUrl(currentScholar.roninAddress);

      const rank = calculateRank(currentScholar);
      const tier = calculateTier(currentScholar);

      managerUnclaimedSLP += managerSLP;

      numberOfAxies += currentScholar.axies ? currentScholar.axies.length : 0;
      numberOfEggs += currentScholar.axies
        ? currentScholar.axies.filter((axie) => parseInt(axie.stage) === 1)
            .length
        : 0;

      if (currentScholarUnclaimedSLP != null) {
        // Add current scholar's unclaimed to total unclaimed
        totalUnclaimedSLP += currentScholarUnclaimedSLP;
      }

      mmrAmounts.push(currentScholarMMR);

      if (
        scholarStarsAboveThreshold(currentScholarMMR) ||
        showUnderperformers
      ) {
        // Scholar should be shown
        mmrs.push({
          name: currentScholar.name,
          mmr: currentScholarMMR,
          roninAddress: currentScholar.roninAddress,
          rankTier: createRankTierString(rank, tier),
          slpPerWin: getWinSLPRewardForRankTier(rank, tier, currentScholarMMR),
          playerUrl,
          dob: currentScholar.dob,
        });
      } else {
        // Scholar should be hidden
        numberOfNotEarningScholars += 1;
        hiddenArenaScholars.push(currentScholar);
      }
    });

  const sortedMMRs = mmrs.sort((a, b) =>
    a.mmr > b.mmr ? -1 : a.mmr < b.mmr ? 1 : 0
  );

  return {
    numberOfScholars: scholars ? scholars.length : 0,
    numberOfNotEarningScholars,
    arenaMVP: sortedMMRs[0]?.name,
    highestMMR: sortedMMRs[0]?.mmr,
    totalUnclaimedSLP,
    managerUnclaimedSLP,
    sortedMMRs,
    overallAverageMMR:
      mmrAmounts.length > 0 ? Math.floor(average(mmrAmounts)) : 0,
    numberOfAxies,
    numberOfEggs,
    hiddenArenaScholars,
  };
};

export const makeScholarPageURLFromRoninAddress = (roninAddress) =>
  `/guild-dashboard/scholar/${roninAddress}`;

export const makeTeamPageURLFromGuildTeamId = (guildTeamId) =>
  `/guild-dashboard/team/${guildTeamId}`;

export const createUserPreferencesPayload = (
  defaultCurrency,
  defaultManagerShare,
  showTodaySLP,
  showYesterdaySLP,
  showAxies,
  showEnergy,
  showInvestorShare,
  isDarkMode
) => ({
  defaultCurrency,
  defaultManagerShare: defaultManagerShare ? parseInt(defaultManagerShare) : 0,
  showTodaySLP: showTodaySLP ? 1 : 0,
  showYesterdaySLP: showYesterdaySLP ? 1 : 0,
  showAxies: showAxies ? 1 : 0,
  showEnergy: showEnergy ? 1 : 0,
  showInvestorShare: showInvestorShare ? 1 : 0,
  isDarkMode: isDarkMode ? 1 : 0,
});
