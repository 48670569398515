import { Stack } from "@mui/material";

const CardArt = ({ cardImage, name, width }) => {
  return (
    <Stack
      sx={{
        inherit: "revert",
        backgroundColor: "#2f2c29",
        padding: `${(width / 200) * 8}px`,
        borderRadius: `${(width / 200) * 2}px`,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <img
        width={(width / 200) * 130}
        style={{
          objectFit: "contain",
          maxHeight: (width / 200) * 60,
        }}
        src={cardImage}
        alt={name}
      />
    </Stack>
  );
};

export default CardArt;
