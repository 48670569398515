import React from "react";
import styles from "./axieCard.module.scss";
import * as specialEffects from "components/Axie/icons/specialEffectIcons";
import { Stack, Typography, Tooltip } from "@mui/material";
import VariantFull from "./VariantFull";

const SpecialEffectPreview = ({ type, width }) => {
  return (
    <Stack
      sx={{
        position: "absolute",
        right: `${(4 / 70) * width}px`,
        bottom: `${(4 / 70) * width}px`,
        svg: {
          width: `${(16 / 70) * width}px`,
          height: "auto",
        },
      }}
    >
      {specialEffects[specialEffects.mapping[type]]()}
    </Stack>
  );
};

const StatPreview = ({ amount, type }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={0.5}
    sx={{
      p: "2px",
      background: type === "damage" ? "rgb(131 21 43)" : "rgb(2 89 89)",
      borderRadius: "4px",
      boxShadow:
        "0px 1px 0px rgba(0,0,0,.3), inset 0px 1px 0px rgba(255,255,255,.3)",
      "& svg": {
        width: "10px",
        height: "auto",
        "& path": {
          fill: "#fff",
        },
      },
    }}
  >
    {type === "damage" && specialEffects.attack()}
    {type === "shield" && specialEffects.shield()}
    <span className={styles.statAmountPreview}>{amount}</span>
  </Stack>
);

const VariantPreview = ({ card, width = "70px" }) => {
  return (
    <>
      <Tooltip
        placement="top"
        title={<VariantFull card={card} width={180} />}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "transparent",
              boxShadow: "none",
            },
          },
        }}
      >
        <Stack className={styles.cardPreview} sx={{ width }}>
          <img
            className={styles.cardImagePreview}
            src={card.cardImage}
            alt={`${card.partName} - ${card.cardName}`}
          />
          <Typography
            variant="span"
            className={styles.namePreview}
            sx={{ fontSize: (8 / 70) * width }}
          >
            {card.cardName}
          </Typography>
          <Stack
            className={styles.previewIcons}
            gap="2px"
            alignItems="flex-start"
          >
            <StatPreview amount={card.cardDamage} type="damage" />
            <StatPreview amount={card.cardShield} type="shield" />
          </Stack>
          <SpecialEffectPreview type={card.specialEffect} width={width} />
          <span className={styles.energyPreview}>{card.cardEnergy}</span>
        </Stack>
      </Tooltip>
    </>
  );
};

export default VariantPreview;
