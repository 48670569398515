import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageHeader from "./PageHeader";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "breakpoint",
})(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  padding: `${theme.spacing(2)} 0 ${theme.spacing(2)}`,
  backgroundPosition: "top center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(/images/games/inter-the-dungeon/dungeon-header.jpg)`,
  maxHeight: "350px",
  minHeight: "300px",
}));

const PageContent = ({ titleImg, titleAlt, children }) => {
  return (
    <Stack alignSelf="stretch">
      <Wrapper direction={{ xs: "column", sm: "column", md: "column" }}>
        <PageHeader titleImg={titleImg} titleAlt={titleAlt} />
      </Wrapper>

      {children}
    </Stack>
  );
};

export default PageContent;
