import React from "react";
import Pairs from "../molecules/Pairs";
import { updateCurrentPageUnlocked } from "store/breedingSimulator/breedingSimulatorActions";
import * as breedingSelectors from "store/breedingSimulator/breedingSelectors";
import { connect } from "react-redux";
import { IoLockClosed } from "react-icons/io5";
import { Stack } from "@mui/material";
import Message from "../atoms/Message";
import ResultsHeader from "../atoms/ResultsHeader";
import IconMessage from "components/IconMessage/IconMessage";

const UnlockedResults = ({
  results,
  updateCurrentPageUnlocked,
  currentPageUnlocked,
  handleDeleteId,
}) => {
  return !results ||
    (results.numberOfUnlockedResults === 0 &&
      results.numberOfLockedResults === 0 &&
      results.numberOfInvalidResults === 0) ? (
    <Message>Add some axie IDs to get started!</Message>
  ) : results.numberOfUnlockedResults === 0 &&
    results.numberOfLockedResults !== 0 &&
    results.numberOfInvalidResults === 0 ? (
    <Message>All of the results are locked.</Message>
  ) : results.numberOfUnlockedResults === 0 &&
    results.numberOfLockedResults === 0 &&
    results.numberOfInvalidResults !== 0 ? (
    <Message>All of the results are hidden.</Message>
  ) : results.numberOfUnlockedResults === 0 &&
    results.numberOfLockedResults !== 0 &&
    results.numberOfInvalidResults !== 0 ? (
    <Message>All of the results are either locked or hidden.</Message>
  ) : (
    <Stack gap={4}>
      <ResultsHeader
        title="Breeding Results"
        description="Potential breeding pairs that you have not locked."
      >
        <IconMessage
          text="Click the lock to add a breeding pair to the locked tab"
          icon={<IoLockClosed />}
          color="yellow"
          style={{ alignSelf: "flex-start" }}
          fontSize={13}
        />
      </ResultsHeader>

      <Pairs
        results={results.unlockedResultsToDisplay}
        totalNumResults={results.numberOfUnlockedResults}
        updateCurrentPage={updateCurrentPageUnlocked}
        currentPage={currentPageUnlocked}
        handleDeleteId={handleDeleteId}
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCurrentPageUnlocked,
};

const mapStateToProps = (state) => {
  return {
    results: breedingSelectors.getBreedingResults(state),
    currentPageUnlocked: state.breedingSimulator.currentPageUnlocked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnlockedResults);
