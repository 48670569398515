import React, { useEffect } from "react";
import { connect } from "react-redux";
import PageHeader from "components/PageHeader/PageHeader";
import Leaderboard from "components/Leaderboard";
import * as config from "config";
import * as leaderboardActions from "store/leaderboard/classic/leaderboardActions";
import PullUp from "components/Utility/PullUp";
import OtherLeaderboardButtons from "components/Leaderboard/molecules/OtherLeaderboardButtons";
import { Typography, Stack } from "@mui/material";
import FullWidthWrapper from "../FullWidthWrapper";

const ClassicLeaderboard = ({
  fetchArenaLeaderboard,
  leaderboard,
  loading,
  failed,
}) => {
  const type = config.CLASSIC_LEADERBOARD;

  useEffect(() => {
    fetchArenaLeaderboard();
  }, [fetchArenaLeaderboard]);

  return (
    <>
      <PageHeader
        title="Classic Leaderboard"
        headerStyle="classic"
        description={
          <Stack gap={0.25}>
            <Typography variant="span">
              A view into the top Axie Infinity Classic players.
            </Typography>
          </Stack>
        }
        centered
      />
      <FullWidthWrapper>
        <PullUp>
          <Leaderboard
            data={leaderboard}
            type={type}
            loading={loading}
            failed={failed}
            retry={fetchArenaLeaderboard}
          />
        </PullUp>

        <OtherLeaderboardButtons buttons={["GUILD", "ORIGIN"]} />
      </FullWidthWrapper>
    </>
  );
};

const mapDispatchToProps = {
  fetchArenaLeaderboard: leaderboardActions.fetchArenaLeaderboard,
};

const mapStateToProps = (state) => ({
  leaderboard: state.arenaLeaderboard.leaderboard.data,
  loading: state.arenaLeaderboard.leaderboard.fetching,
  failed: state.arenaLeaderboard.leaderboard.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassicLeaderboard);
