import styled from "@emotion/styled";
import { Box } from "@mui/material";
import InterDungeonTooltip from "components/InterDungeon/InterDungeonCard/atoms/InterDungeonTooltip";

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "width" })(
  ({ width = 200 }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",

    img: {
      width: `${(width / 200) * 18}px`,
      height: `auto`,
    },
  })
);

const EquipSlot = ({ equippableSlot, name }) => {
  const slotImage = `/images/games/inter-the-dungeon/icons/equipment-slots/${equippableSlot}.png`;
  if (equippableSlot && equippableSlot.length !== 0) {
    return (
      <InterDungeonTooltip
        title={
          <>
            You can equip {name} to your{" "}
            {equippableSlot.replace("lefthand", "left or right hand")}
          </>
        }
      >
        <Wrapper id="equip-icon">
          <img
            width="100%"
            src={slotImage}
            alt={`Equip to your ${equippableSlot}`}
          />
        </Wrapper>
      </InterDungeonTooltip>
    );
  }
};

export default EquipSlot;
