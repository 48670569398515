import React from "react";
import { charmWithPlus } from "icons/icons";
import ClickableSlotCondensed from "./ClickableSlotCondensed";
import { ITEM_WIDTH } from "pages/TeamBuilder";
import ClickableItem from "./ClickableItem";
import CharmTemplate from "components/GameTemplates/CharmTemplate";
import NotClickableSlot from "./NotClickableSlot";
import ClickableSlot from "./ClickableSlot";

const CharmSlot = ({
  data,
  isCardSet,
  cardClass,
  axie,
  part,
  updateCharm,
  view,
  handleOpenCharmDialog,
}) => {
  const deleteCharm = (part) => () => updateCharm(axie.id, part, undefined);

  const handleOpen = () => {
    handleOpenCharmDialog(axie, part, cardClass);
  };

  if (view === "condensed") {
    return data == null && !isCardSet ? (
      <NotClickableSlot width={180} height={30} />
    ) : (
      <ClickableSlotCondensed
        onClick={handleOpen}
        deleteItem={deleteCharm(part)}
        icon={charmWithPlus}
        value={data?.name}
      />
    );
  }

  return data == null && !isCardSet ? (
    <NotClickableSlot width={ITEM_WIDTH} height={(246.45 / 180) * ITEM_WIDTH} />
  ) : data == null && isCardSet ? (
    <ClickableSlot itemType="charm" icon={charmWithPlus} onClick={handleOpen} />
  ) : (
    <ClickableItem
      onClick={handleOpen}
      deleteItem={deleteCharm(part)}
      data={data}
    >
      <CharmTemplate charm={data} width={ITEM_WIDTH} />
    </ClickableItem>
  );
};

export default CharmSlot;
