import React from "react";
import { Stack, useTheme, IconButton, Typography } from "@mui/material";
import { IoCloseCircle } from "react-icons/io5";

const ClickableCardName = ({ onClick, deleteItem, value, color }) => {
  const theme = useTheme();

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteItem();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      onClick={onClick}
      sx={{
        userSelect: "none",
        height: 30,
        transition: "0.2s transform ease",
        "& .delete": {
          opacity: 0,
          zIndex: 8,
          color: theme.palette.text.regular,
        },
        "& .icon svg": {
          height: 20,
          width: "auto",
        },
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.05)",
          "& .delete": {
            opacity: 1,
          },
        },
      }}
    >
      <Typography variant="span" fontSize={18} fontWeight={600} color={color}>
        {value}
      </Typography>

      {value != null && (
        <IconButton size="small" className="delete" onClick={handleDelete}>
          <IoCloseCircle />
        </IconButton>
      )}
    </Stack>
  );
};
export default ClickableCardName;
