import { matchesStr } from "helpers/display";
import { createSelector } from "reselect";
import { itemIsFavorite } from "./";

export const getTools = (state) => {
  return state.data?.axieCardsV3.toolCards || [];
};

export const getToolFilters = (state) => {
  return state.explorer?.toolFilters || {};
};

export const getFavoriteTools = (state) => {
  return state.explorer?.favoriteTools || [];
};

const applyToolFilters = (tool, filters) => {
  if (!tool || !filters) {
    return true;
  }

  return (
    matchesStr(tool.name, filters.searchText) ||
    matchesStr(tool.description, filters.searchText)
  );
};

const applySortTools = (a, b, sortType) => {
  if (sortType === "NAME_DESC") {
    return b.name < a.name ? -1 : a.name < b.name ? 1 : 0;
  }
  return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
};

export const getToolsToDisplay = createSelector(
  [getTools, getToolFilters, getFavoriteTools],
  (tools, toolFilters, favoriteTools) => {
    return [...tools]
      .filter((tool) => applyToolFilters(tool, toolFilters))
      .sort((a, b) => applySortTools(a, b, toolFilters.sortType))
      .map((tool) => ({
        ...tool,
        isFavorite: itemIsFavorite(tool, "name", favoriteTools),
      }));
  }
);

export const getFavoriteToolsToDisplay = createSelector(
  [getFavoriteTools],
  (favoriteTools) => {
    return [...favoriteTools].map((tool) => ({
      ...tool,
      isFavorite: true,
    }));
  }
);
