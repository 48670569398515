export const ADMIN_FETCH_GUILDS = "ADMIN_FETCH_GUILDS";
export const ADMIN_FETCH_GUILDS_STARTED = "ADMIN_FETCH_GUILDS_STARTED";
export const ADMIN_FETCH_GUILDS_SUCCEEDED = "ADMIN_FETCH_GUILDS_SUCCEEDED";
export const ADMIN_FETCH_GUILDS_FAILED = "ADMIN_FETCH_GUILDS_FAILED";

export const ADMIN_ADD_GUILD = "ADMIN_ADD_GUILD";
export const ADMIN_ADD_GUILD_STARTED = "ADMIN_ADD_GUILD_STARTED";
export const ADMIN_ADD_GUILD_SUCCEEDED = "ADMIN_ADD_GUILD_SUCCEEDED";
export const ADMIN_ADD_GUILD_FAILED = "ADMIN_ADD_GUILD_FAILED";

export const ADMIN_UPDATE_GUILD = "ADMIN_UPDATE_GUILD";
export const ADMIN_UPDATE_GUILD_STARTED = "ADMIN_UPDATE_GUILD_STARTED";
export const ADMIN_UPDATE_GUILD_SUCCEEDED = "ADMIN_UPDATE_GUILD_SUCCEEDED";
export const ADMIN_UPDATE_GUILD_FAILED = "ADMIN_UPDATE_GUILD_FAILED";

export const ADMIN_UPDATE_SEARCH_QUERY = "ADMIN_UPDATE_SEARCH_QUERY";
export const ADMIN_TOGGLE_SHOW_INACTIVE = "ADMIN_TOGGLE_SHOW_INACTIVE";

export const ADMIN_REQUEST_PRESIGNED_URL = "ADMIN_REQUEST_PRESIGNED_URL";
export const ADMIN_REQUEST_PRESIGNED_URL_STARTED =
  "ADMIN_REQUEST_PRESIGNED_URL_STARTED";
export const ADMIN_REQUEST_PRESIGNED_URL_SUCCEEDED =
  "ADMIN_REQUEST_PRESIGNED_URL_SUCCEEDED";
export const ADMIN_REQUEST_PRESIGNED_URL_FAILED =
  "ADMIN_REQUEST_PRESIGNED_URL_FAILED";
