import React from "react";
import styles from "./axieCard.module.scss";
import * as specialEffects from "components/Axie/icons/specialEffectIcons";
import { AXIE_CLASS_CARD_STAT_BACKGROUNDS } from "config";
import { Box, Stack, Typography } from "@mui/material";

const SpecialEffect = ({ type }) => {
  return (
    <div className={styles.specialEffect}>
      {specialEffects[specialEffects.mapping[type]]()}
    </div>
  );
};

const Stat = ({ amount, axieClass, type, width }) => (
  <div className={`${styles.stat} ${styles[type]}`}>
    <svg
      width="82"
      height="95"
      viewBox="0 0 82 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.statBackground}
    >
      <path
        d="M0 30.8804C0 26.6188 2.2601 22.677 5.93785 20.5242L37.9689 1.77429C39.8411 0.678402 42.1589 0.678404 44.0311 1.77429L76.0622 20.5242C79.7399 22.677 82 26.6188 82 30.8804V64.1196C82 68.3812 79.7399 72.323 76.0622 74.4758L44.0311 93.2257C42.1589 94.3216 39.8411 94.3216 37.9689 93.2257L5.93784 74.4758C2.2601 72.323 0 68.3812 0 64.1196V30.8804Z"
        fill={AXIE_CLASS_CARD_STAT_BACKGROUNDS[axieClass]}
      />
    </svg>
    {type === "damage" && (
      <Stack
        direction="row"
        justifyContent="center"
        className={styles.statIcon}
      >
        {specialEffects.attack()}
      </Stack>
    )}
    {type === "shield" && (
      <Stack
        direction="row"
        justifyContent="center"
        className={styles.statIcon}
      >
        {specialEffects.shield()}
      </Stack>
    )}
    <Typography
      variant="span"
      className={styles.statAmount}
      fontSize={(24 / 216) * width}
    >
      {amount}
    </Typography>
  </div>
);

const VariantFull = ({ card, style, width = 216 }) => {
  return (
    <Box className={styles.card} sx={{ width, ...style }}>
      <img
        className={styles.cardImage}
        src={card.cardImage}
        alt={`${card.partName} - ${card.cardName}`}
      />
      <Typography
        variant="span"
        className={styles.name}
        fontSize={(16 / 216) * width}
      >
        {card.cardName}
      </Typography>
      <Stat
        width={width}
        amount={card.cardDamage}
        type="damage"
        axieClass={card.class}
      />
      <Stat
        width={width}
        amount={card.cardShield}
        type="shield"
        axieClass={card.class}
      />
      <Typography
        fontSize={(32 / 216) * width}
        variant="span"
        className={styles.energy}
      >
        {card.cardEnergy}
      </Typography>

      <SpecialEffect type={card.specialEffect} />

      <Typography
        variant="span"
        className={styles.cardText}
        fontSize={(12 / 216) * width}
      >
        {card.cardText}
      </Typography>
    </Box>
  );
};

export default VariantFull;
