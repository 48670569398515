import { Typography } from "@mui/material";
import { createRankTierString } from "helpers/arena";

const PlayerRankTier = ({ rank, tier }: { rank: string; tier: number }) => {
  return (
    <Typography
      fontSize={13}
      component="span"
      fontWeight={600}
      sx={{
        color: (theme) => theme.palette.colors.origin.wood.text,
        whiteSpace: "nowrap",
        lineHeight: 1.3,
      }}
    >
      {createRankTierString(rank, tier)}
    </Typography>
  );
};

export default PlayerRankTier;
