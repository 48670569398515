import { Typography, useTheme } from "@mui/material";

const FailedMessage = ({ text }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="span"
      textAlign="center"
      fontSize="16px"
      fontWeight="600"
      color={theme.palette.text.muted}
    >
      {text}
    </Typography>
  );
};

export default FailedMessage;
