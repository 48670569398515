import React from "react";
import { Stack, Typography } from "@mui/material";

const BattlesNotFunctional = () => {
  return (
    <Stack gap={2} sx={{ py: 20, px: 2 }} alignItems="center">
      <Typography
        variant="h1"
        fontSize="20px"
        color="text.muted"
        textAlign="center"
      >
        We can't fetch battle data right now
      </Typography>
    </Stack>
  );
};

export default BattlesNotFunctional;
