import { Stack, DialogContent as MuiDialogContent } from "@mui/material";
import React from "react";

const DialogContent = ({ children }) => {
  return (
    <MuiDialogContent dividers={true}>
      <Stack gap={2}>{children}</Stack>
    </MuiDialogContent>
  );
};

export default DialogContent;
