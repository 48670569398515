import { Grid, Stack } from "@mui/material";
import { isNullOrEmptyArray } from "helpers/cleaning";
import Card from "components/Card/Card";
import CardTitle from "../atoms/CardTitle";
import MoreLink from "../atoms/MoreLink";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import FavoriteAxie from "../molecules/FavoriteAxie";
import { fadeInOut } from "theme/animations/fadeInOut";
import { Card as CardType, BattleAxie } from "types";

interface Props {
  favoriteTeams: BattleAxie[][];
  cardsV3: CardType[];
  showAxieDetail: boolean;
  setShowAxieDetail: (newValue: boolean) => void;
}

const FavoriteTeam = ({
  favoriteTeams,
  cardsV3,
  showAxieDetail,
  setShowAxieDetail,
}: Props) => {
  if (isNullOrEmptyArray(favoriteTeams)) return null;

  const isTeamLoading = favoriteTeams[0].some((axie) => axie.axieId == null);

  return (
    <Grid item xs={12} sm={12} md={12} lg={showAxieDetail ? 12 : 8}>
      <Stack gap={1} sx={{ height: "100%" }}>
        <CardTitle>Favorite Team</CardTitle>

        <Card
          style={{
            flexDirection: "row",
            justifyContent: isTeamLoading ? "space-between" : "center",
            flexWrap: "wrap",
            alignItems: "stretch",
            gap: showAxieDetail ? 2 : 0,
            position: "relative",
            pt: isTeamLoading ? 4 : showAxieDetail ? 2 : 2,

            "& .showDetailLink": {
              opacity: 0,
              cursor: "pointer",
              transition: "0.2s opacity ease",
            },

            "&:hover": {
              "& .showDetailLink": {
                opacity: !isTeamLoading ? 1 : 0,
              },
            },
          }}
        >
          <MoreLink
            text={showAxieDetail ? undefined : "Cards"}
            icon={showAxieDetail ? <FaEyeSlash /> : <FaEye />}
            onClick={() => setShowAxieDetail(!showAxieDetail)}
            className="showDetailLink"
            style={{ position: "absolute", right: 16, top: 16, zIndex: 2 }}
          />
          {favoriteTeams[0].map((axie, i) =>
            isTeamLoading ? (
              <Stack
                sx={{
                  background: (theme) => theme.palette.axieCard.loading,
                  borderRadius: "4px",
                  height: 220,
                  width: 200,
                  animation: `${fadeInOut} ease 4s infinite`,
                }}
              />
            ) : (
              <FavoriteAxie
                key={i}
                axie={axie}
                showAxieDetail={showAxieDetail}
                cardsV3={cardsV3}
              />
            )
          )}
        </Card>
      </Stack>
    </Grid>
  );
};

export default FavoriteTeam;
