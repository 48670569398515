import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "40px 170px 2fr",
  gap: theme.spacing(3),

  ".statIcon": {
    alignSelf: "center",
  },

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",

    ".statIcon": {
      alignSelf: "flex-start",
    },
  },
}));

const StatWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default StatWrapper;
