import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import { getMedalForPlace } from "helpers/arena";
import ScholarName from "./ScholarName";

const ScholarNameAndRank = ({ scholar, rank, numScholars }) => {
  const theme = useTheme();
  return (
    <Stack gap={1} sx={{ width: "100%" }} direction="row" alignItems="center">
      <Stack
        direction="row"
        sx={{
          width: numScholars.toString().length * 20,
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
          },
        }}
      >
        <Typography
          fontSize="28px"
          color="text.muted"
          variant="span"
          fontWeight="700"
        >
          {getMedalForPlace(rank)}
        </Typography>
      </Stack>
      <Stack sx={{ overflow: "hidden", flexBasis: "calc(100% - 32px)" }}>
        <ScholarName dob={scholar.dob} name={scholar.name} />
      </Stack>
    </Stack>
  );
};

export default ScholarNameAndRank;
