import { Stack } from "@mui/material";
import React from "react";
import TitleWithAccents from "pages/Explorer/atoms/TitleWithAccents";
import CollectedOutOf from "./CollectedOutOf";

const PaperHeader = ({ title, totalCollected, grandTotal, clearItems }) => {
  return (
    <Stack gap={0.5} alignItems="center" sx={{ position: "relative" }}>
      <TitleWithAccents>{title}</TitleWithAccents>
      <CollectedOutOf
        total={totalCollected}
        outOf={grandTotal}
        clearItems={clearItems}
      />
    </Stack>
  );
};

export default PaperHeader;
