import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const StackedTitle = styled(Stack)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  top: 0,
  [theme.breakpoints.down("md")]: {
    top: 0,
    alignItems: "center",
  },
}));

export default StackedTitle;
