import { connect } from "react-redux";
import { updateCardFilters } from "store/explorer/explorerActions";
import { Stack, useTheme } from "@mui/material";
import Dropdown from "components/Origin/atoms/Dropdown";

const Sort = ({ filters, updateFilters, options }) => {
  const theme = useTheme();
  const handleUpdateFilters = (newFilters) => {
    updateFilters({ ...filters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack alignItems="flex-end">
      <Dropdown
        value={filters.sortType}
        setValue={handleUpdateDropdown("sortType")}
        hiddenLabel={true}
        options={options}
        multiple={false}
        formControlStyles={{
          "& .MuiFilledInput-root": {
            height: "auto",
            marginLeft: "auto",
            width: "fit-content",
            background: theme.palette.colors.origin.paper.light,
            border: `1px solid ${theme.palette.colors.origin.paper.dark}`,
            boxShadow: "none",
            "&:active, &.Mui-focused, &:hover": {
              background: theme.palette.colors.origin.paper.medium,
              border: `1px solid ${theme.palette.colors.origin.text.brown}`,
              boxShadow: "none",
            },
          },
          "& .MuiFilledInput-input": {
            fontSize: 15,
            color: theme.palette.colors.origin.text.brown,
            pt: "5px",
            pb: "5px",
            "&:focus": {
              backgroundColor: theme.palette.colors.origin.paper.medium,
            },
          },
          "& .MuiSelect-icon": {
            fill: theme.palette.colors.origin.text.brown,
          },
        }}
      />
    </Stack>
  );
};

const mapDispatchToProps = { updateCardFilters };

const mapStateToProps = (state) => ({
  cardFilters: state.explorer.cardFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sort);
