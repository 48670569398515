import { Stack, Box, useTheme } from "@mui/material";
import ManaIcon from "components/InterDungeon/InterDungeonCard/atoms/ManaIcon";
import CombatValue from "components/InterDungeon/InterDungeonCard/atoms/CombatValue";
import OpposingValue from "components/InterDungeon/InterDungeonCard/atoms/OpposingValue";
import Reuses from "components/InterDungeon/InterDungeonCard/atoms/Reuses";

const BattlePower = ({
  width,
  value,
  reuses,
  opposingValue,
  crystalCost,
  name,
}) => {
  const theme = useTheme();
  return (
    <Stack
      minHeight="100%"
      maxHeight="100%"
      alignItems="center"
      justifyContent="space-between"
      padding={0.5}
      width="100%"
      sx={{
        background: theme.palette.colors.interDungeon.cardElements.greyBrown,
        borderRadius: `${(width / 200) * 2}px`,
        maxWidth: "80px",
      }}
    >
      {crystalCost
        ? crystalCost.crystals?.map((item, i) => {
            return (
              <Box
                sx={{ position: "absolute", top: "149px", left: "15px" }}
                key={`NON_EQUIPPABLE_MODIFIER ${i}`}
              >
                <ManaIcon
                  width={width}
                  crystalAmount={item.amount}
                  crystalColor={item.color}
                />
              </Box>
            );
          })
        : null}

      <CombatValue width={width} value={value} name={name} />

      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        width="100%"
      >
        {reuses ? <Reuses width={width} reuses={reuses} name={name} /> : null}
        {opposingValue ? (
          <OpposingValue width={width} opposingValue={opposingValue} />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default BattlePower;
