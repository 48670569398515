import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: "#23B8D1",
  borderTop: "6px solid #000",
  borderLeft: "6px solid #000",
  borderRight: "6px solid #000",
  flexDirection: "row",
  gap: theme.spacing(2),
  borderRadius: "24px 24px 0 0",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

const PageControls = ({ children }) => {
  return <Wrapper className="pageControls">{children}</Wrapper>;
};

export default PageControls;
