import React from "react";
import { AXIE_CLASSES } from "config";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";

const FilterAxieClass = ({ axieClasses, setAxieClasses, options, style }) => {
  return axieClasses != null && setAxieClasses != null ? (
    <CustomMultiSelect
      label="Class"
      value={axieClasses}
      setValue={setAxieClasses}
      options={options || AXIE_CLASSES}
      style={style}
    />
  ) : (
    <></>
  );
};

export default FilterAxieClass;
