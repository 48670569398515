import * as types from "./dataActionTypes";
import produce from "immer";
import { calculatePurityAxieTech } from "helpers/axie";

export const initialState = {
  axieCards: [],
  fetchingAxieCards: true,
  axieCardsV3: {},
  fetchingAxieCardsV3: true,
  fetchingAxieCardsV3Failed: false,
  playerData: {},
  axieData: {},
};

export const dataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_AXIE_CARDS_STARTED:
        draft.fetchingAxieCards = true;
        break;
      case types.FETCH_AXIE_CARDS_SUCCEEDED:
        draft.axieCards = action.cards;
        draft.fetchingAxieCards = false;
        break;
      case types.FETCH_AXIE_CARDS_FAILED:
        draft.fetchingAxieCards = false;
        break;

      case types.FETCH_AXIE_CARDS_V3_STARTED:
        draft.fetchingAxieCardsV3 = true;
        draft.fetchingAxieCardsV3Failed = false;
        break;
      case types.FETCH_AXIE_CARDS_V3_SUCCEEDED:
        draft.axieCardsV3 = action.cards;
        draft.fetchingAxieCardsV3 = false;
        break;
      case types.FETCH_AXIE_CARDS_V3_FAILED:
        draft.fetchingAxieCardsV3 = false;
        draft.fetchingAxieCardsV3Failed = true;
        break;

      case types.FETCH_PLAYER_DATA_SUCCEEDED:
        draft.playerData[action.roninAddress] = {
          roninAddress: action.roninAddress,
          ...action.data,
        };
        break;

      case types.FETCH_AXIE_DATA_SUCCEEDED:
        draft.axieData[action.axie.axieId] = {
          ...action.axie,
          purity: calculatePurityAxieTech(action.axie),
        };
        break;

      default:
        break;
    }
  });

export default dataReducer;
