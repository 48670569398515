import React from "react";
import Trait from "../atoms/Trait";

import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const Traits = ({ parts, partRarity }) => {
  if (parts == null || parts.length === 0) return null;
  return (
    <Wrapper>
      {Object.keys(parts).map((key) => (
        <Trait
          key={key}
          part={key}
          name={parts[key]}
          percent={partRarity[key]}
        />
      ))}
    </Wrapper>
  );
};

export default Traits;
