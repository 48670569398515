import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import Column from "../molecules/Column";
import ScholarNameAndRank from "../molecules/ScholarNameAndRank";
import Row from "../molecules/Row";

const ScholarArenaRow = ({ scholar, rank, numScholars }) => {
  return (
    <Link to={scholar.playerUrl}>
      <Row rowType="arena" rank={rank}>
        <ScholarNameAndRank
          scholar={scholar}
          rank={rank}
          numScholars={numScholars}
        />
        <Column label="Stars" amount={scholar.mmr} />
        {scholar.rankTier != null && (
          <Column
            label="Rank"
            amount={scholar.rankTier}
            capsule={`${scholar.slpPerWin} SLP/win`}
          />
        )}
      </Row>
    </Link>
  );
};

const ArenaLeaderboard = ({ scholars }) => (
  <Stack gap={1}>
    {scholars &&
      scholars.map((scholar, i) => (
        <ScholarArenaRow
          scholar={scholar}
          rank={i + 1}
          key={`SCHOLAR_ROW_${i}`}
          numScholars={scholars.length}
        />
      ))}
  </Stack>
);

export default ArenaLeaderboard;
