import React from "react";
import { makeMarketplaceURLFromID } from "helpers/axie";
import { Link } from "@mui/material";

const MarketplaceLink = ({ disable, axie, children }) => {
  if (
    disable ||
    axie == null ||
    (axie.axieId == null && axie.marketplaceUrl == null)
  ) {
    return <>{children}</>;
  }
  let url = axie.marketplaceUrl;
  if (url == null) {
    url = makeMarketplaceURLFromID(axie.axieId);
  }

  return (
    <Link
      target="_blank"
      underline="none"
      href={url}
      sx={{
        p: 0,
        border: "none",
        position: "relative",
        zIndex: 1,
        "&:hover": { border: "none" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Link>
  );
};

export default MarketplaceLink;
