import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-23</Date>
      <Change type="improved">
        Guild Dashboard: Various enhancements to Teams page to improve page load
        time and filtering performance.
      </Change>
      <Change type="new">
        Guild Dashboard: Added estimated SLP per win to Scholar Arena table.
      </Change>
      <Change type="new">
        Guild Pages &amp; Guild Dashboard: Show 🎂 if today is a scholar's
        birthday!
      </Change>
    </Entry>
  );
};

export default Changes;
