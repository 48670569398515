import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

const OtherLeaderboardButtons = ({ title, buttons, variant }) => {
  if (!buttons || buttons.length === 0) {
    return null;
  }

  const buttonDetails = {
    BATTLE: {
      text: "Guild Battle Leaderboard",
      to: "/guild-battle-leaderboard",
    },
    ORIGIN: {
      text: "Origins Leaderboard",
      to: "/origins-leaderboard",
    },
    GUILD: {
      text: "Guild Leaderboards",
      to: "/guild-leaderboards",
    },
  };
  return (
    <Stack sx={{ textAlign: "center", pt: 8 }} gap={2}>
      <Typography
        variant="h5"
        sx={{
          color:
            variant === "origin"
              ? (theme) => theme.palette.colors.origin.paper.medium
              : (theme) => theme.palette.text.muted,
        }}
      >
        {title || "More leaderboards:"}
      </Typography>
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        {buttons.map((button, i) => (
          <Button
            key={`BUTTON_${i}`}
            variant={variant}
            component={RouterLink}
            to={buttonDetails[button].to}
          >
            {buttonDetails[button].text}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

OtherLeaderboardButtons.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  variant: PropTypes.string,
};

OtherLeaderboardButtons.defaultProps = {
  title: "More Leaderboards",
  buttons: [],
  variant: "contained",
};

export default OtherLeaderboardButtons;
