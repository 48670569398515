import React from "react";
import { connect } from "react-redux";
import { IoCloseCircle } from "react-icons/io5";
import { Button, useTheme } from "@mui/material";
import { clearAllAxies } from "store/teamBuilder/teamBuilderActions";
import { addAlphaToHex } from "helpers/colors";

const ClearAllAxiesButton = ({ clearAllAxies }) => {
  const theme = useTheme();
  const handleClick = () => {
    clearAllAxies();
  };

  return (
    <Button
      disableElevation
      endIcon={<IoCloseCircle />}
      onClick={handleClick}
      variant="text"
      size="small"
      color="secondary"
      sx={{
        "&:hover": {
          color: theme.palette.feedback.red,
          background: addAlphaToHex(theme.palette.feedback.red, 0.05),
        },
      }}
    >
      Clear All
    </Button>
  );
};

const mapDispatchToProps = { clearAllAxies };

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClearAllAxiesButton);
