export const DOLL_LEADERBOARD_FETCH_LEADERBOARD =
  "DOLL_LEADERBOARD_FETCH_LEADERBOARD";
export const DOLL_LEADERBOARD_FETCH_LEADERBOARD_STARTED =
  "DOLL_LEADERBOARD_FETCH_LEADERBOARD_STARTED";
export const DOLL_LEADERBOARD_FETCH_LEADERBOARD_FAILED =
  "DOLL_LEADERBOARD_FETCH_LEADERBOARD_FAILED";
export const DOLL_LEADERBOARD_FETCH_LEADERBOARD_SUCCEEDED =
  "DOLL_LEADERBOARD_FETCH_LEADERBOARD_SUCCEEDED";

export const DOLL_LEADERBOARD_UPDATE_CONTROLS =
  "DOLL_LEADERBOARD_UPDATE_CONTROLS";
