import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import CapsuleLabel from "../atoms/CapsuleLabel";

const LinkText = ({ isActive, text, colorOverrides, label }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Typography
        fontSize="14px"
        variant="span"
        sx={{
          color: colorOverrides?.navigation?.text
            ? colorOverrides?.navigation?.text
            : isActive
            ? theme.palette.text.primary
            : theme.palette.text.regular,
          "&:hover": {
            color: colorOverrides?.navigation?.textHover
              ? colorOverrides?.navigation?.textHover
              : theme.palette.text.primary,
          },
          fontWeight: isActive ? 700 : 600,
          transition: "0.2s color ease",
          lineHeight: "50px",
          userSelect: "none",
          display: "inline-block",
        }}
      >
        {text}
      </Typography>
      <CapsuleLabel
        style={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        {label}
      </CapsuleLabel>
    </Box>
  );
};

export default LinkText;
