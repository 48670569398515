import React from "react";
import { Box, useTheme, Stack, useMediaQuery } from "@mui/material";
import { FaStar } from "react-icons/fa";
import CustomLink from "components/CustomLink/CustomLink";

const Item = ({
  handleClick,
  href,
  isFavorite,
  children,
  itemType,
  onMouseEnter,
  onMouseLeave,
}) => {
  const theme = useTheme();
  const activeStyle =
    "drop-shadow(0px 0px 6px #ffd865) drop-shadow(0px 0px 20px #fff)";
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const hoverStyles = smDown
    ? {
        ".favoriteButton": {
          opacity: 1,
        },
      }
    : {
        filter: isFavorite ? activeStyle : "none",
        transition: "0.2s transform ease, 0.1s filter ease",
        ".favoriteButton": {
          opacity: isFavorite ? 1 : 0,
        },
        "&:hover": {
          transform: "scale(1.02)",
          cursor: "pointer",
          ".favoriteButton": {
            opacity: 1,
          },
        },
        "&:active": {
          transform: "scale(1)",
        },
      };

  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        position: "relative",
        ...hoverStyles,
      }}
    >
      <FavoriteButton
        itemType={itemType}
        handleClick={handleClick}
        isFavorite={isFavorite}
      />
      <CustomLink href={href} style={{ fontWeight: "normal" }}>
        {children}
      </CustomLink>
    </Box>
  );
};

const FavoriteButton = ({ itemType, handleClick, isFavorite }) => {
  return (
    <Stack
      onClick={handleClick}
      className="favoriteButton"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "absolute",
        top: itemType === "CARD" ? "13%" : "4%",
        right: itemType === "CARD" ? "7%" : "6%",
        zIndex: 5,
        filter: "drop-shadow(0px 0px 2px #000)",
        fontWeight: 700,
        height: 48,
        width: 48,
        fontSize: 24,
        color: (theme) => theme.palette.colors.origin.button.color,
        background: (theme) =>
          theme.palette.colors.origin.button.background.default,
        boxShadow: (theme) =>
          theme.palette.colors.origin.button.boxShadow.default,
        borderRadius: "50%",
        "&:hover": {
          color: (theme) => theme.palette.colors.white,
          background: (theme) =>
            theme.palette.colors.origin.button.background.hover,
          boxShadow: (theme) =>
            theme.palette.colors.origin.button.boxShadow.hover,
        },
        ...(isFavorite && {
          color: (theme) => theme.palette.colors.white,
          background: (theme) =>
            theme.palette.colors.origin.button.background.hover,
          boxShadow: (theme) =>
            theme.palette.colors.origin.button.boxShadow.hover,
        }),
      }}
    >
      <FaStar />
    </Stack>
  );
};

export default Item;
