import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import fonts from "theme/fonts";

const MutedCenteredText = ({ text }) => {
  const theme = useTheme();
  return (
    <Box sx={{ pt: 14, pb: 8 }}>
      <Typography
        variant="body2"
        color={theme.palette.colors.origin.paper.dark}
        fontSize={28}
        textAlign="center"
        fontFamily={fonts.changa}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default MutedCenteredText;
