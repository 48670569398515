import React from "react";
import { getGuildLogoURL } from "helpers/guild";
import { Box } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";

const GuildAvatar = ({ name, imageSlug, pageSlug }) => {
  const size = "100px";
  return imageSlug ? (
    <CustomLink to={`/guild/${pageSlug}`} target="_blank" underline="none">
      <Box
        sx={{
          img: {
            transition: "0.2s transform ease",
            display: "block",
            position: "relative",
            width: size,
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
            },
          },
        }}
      >
        <img src={getGuildLogoURL(imageSlug)} alt={`${name}`} />
      </Box>
    </CustomLink>
  ) : (
    <Box sx={{ height: size }} />
  );
};

export default GuildAvatar;
