import React from "react";
import SocialIcons from "components/Social/SocialIcons";
import { useLocation } from "react-router-dom";
import { Stack, Link } from "@mui/material";
import { TRIBALLY_DISCORD } from "config";
import ColumnWrapper from "../atoms/ColumnWrapper";
import { FaHeart } from "react-icons/fa";
import ListOfLinks from "./ListOfLinks";
import ColorButton from "../atoms/ColorButton";

const Column1 = ({ colorOverrides }) => {
  const location = useLocation();

  const companyLinks = [
    { text: "Meet the Team", href: "https://tribally.games/team" },
    { text: "Changelog", to: "/changelog" },
    { text: "Terms and Conditions", to: "/terms" },
    { text: "Privacy Policy", to: "/privacy-policy" },
  ];

  const handleClick = (to) => {
    if (to === location.pathname) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <ColumnWrapper>
      <Stack gap={2}>
        <Link
          to="/"
          underline="none"
          onClick={() => handleClick("/")}
          sx={{
            display: "block",
            img: {
              transition: "0.2s transform ease",
              width: 148,
              "&:hover": {
                transform: "scale(1.05)",
              },
            },
          }}
        >
          <img src="/images/axie-tech-logo-white.png" alt="Axie Tech logo" />
        </Link>

        <SocialIcons color={colorOverrides?.footer?.text || "#fff"} />
        <ListOfLinks links={companyLinks} colorOverrides={colorOverrides} />
      </Stack>

      <ListOfLinks
        links={[]}
        colorOverrides={colorOverrides}
        title="Our Community"
      >
        <ColorButton
          variant="contained"
          sx={{ alignSelf: "flex-start" }}
          href={TRIBALLY_DISCORD}
          target="_blank"
          endIcon={<FaHeart />}
        >
          Join Discord
        </ColorButton>
      </ListOfLinks>
    </ColumnWrapper>
  );
};

export default Column1;
