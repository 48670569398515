import { createSelector } from "reselect";
import { itemIsFavorite } from "./";
import { ITEM_RARITY_MAPPING } from "config";
import { getAllUniqueCharmsForCurrentSeason } from "store/data/dataSelectors";
import { strIsInArray, matchesStr } from "helpers/display";

export const getCharmFilters = (state) => {
  return state.explorer?.charmFilters || {};
};

export const getFavoriteCharms = (state) => {
  return state.explorer?.favoriteCharms || [];
};

const applyCharmFilters = (charm, filters) => {
  if (!filters || !charm) {
    return true;
  }

  return (
    (matchesStr(charm.effect, filters.searchText) ||
      matchesStr(charm.name, filters.searchText)) &&
    strIsInArray(charm.class, filters.axieClasses) &&
    strIsInArray(charm.rarity, filters.rarities) &&
    strIsInArray(charm.potentialCost, filters.potentialCosts)
  );
};

const applySortCharms = (a, b, sortType) => {
  if (sortType === "RARITY_ASC") {
    return ITEM_RARITY_MAPPING[a.rarity] - ITEM_RARITY_MAPPING[b.rarity];
  } else if (sortType === "RARITY_DESC") {
    return ITEM_RARITY_MAPPING[b.rarity] - ITEM_RARITY_MAPPING[a.rarity];
  } else if (sortType === "POTENTIAL_ASC") {
    return parseInt(a.potentialCost) - parseInt(b.potentialCost);
  } else if (sortType === "POTENTIAL_DESC") {
    return parseInt(b.potentialCost) - parseInt(a.potentialCost);
  } else if (sortType === "NAME_DESC") {
    return b.name < a.name ? -1 : a.name < b.name ? 1 : 0;
  }
  return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
};

export const getCharmsToDisplay = createSelector(
  [getAllUniqueCharmsForCurrentSeason, getCharmFilters, getFavoriteCharms],
  (charms, charmFilters, favoriteCharms) => {
    return charms
      .filter((charm) => applyCharmFilters(charm, charmFilters))
      .sort((a, b) => applySortCharms(a, b, charmFilters.sortType))
      .map((charm) => ({
        ...charm,
        isFavorite: itemIsFavorite(charm, "name", favoriteCharms),
      }));
  }
);

export const getFavoriteCharmsToDisplay = createSelector(
  [getFavoriteCharms],
  (favoriteCharms) => {
    return favoriteCharms.map((charm) => ({
      ...charm,
      isFavorite: true,
    }));
  }
);
