import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const ROW_HEIGHT = 80;

const BOX_SHADOWS = {
  1: "4px 4px 0px #f8d576",
  2: "4px 4px 0px #ecfaff",
  3: "4px 4px 0px #ffbc8e",
};

const STRIPES = {
  1: "#fff7e1",
  2: "#F7F4FD",
  3: "#fff1e7",
};

const PlayerWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "rank",
})(({ theme, rank }) => ({
  position: "relative",
  transition: "0.2s transform ease",
  display: "grid",
  gridTemplateColumns: "100px 280px 280px 100px 1fr",
  gridTemplateAreas: `
      "rank name team points info"
    `,
  alignItems: "center",
  borderRadius: theme.spacing(3),
  border: "6px solid #000",
  minHeight: ROW_HEIGHT + 12,
  justifyContent: "space-between",
  padding: `0 ${theme.spacing(6)}`,
  background: `repeating-linear-gradient(45deg, #ffffff, #ffffff 10px, ${
    STRIPES[rank] || theme.palette.colors.doll.stripe
  } 10px, ${STRIPES[rank] || theme.palette.colors.doll.stripe} 20px)`,
  boxShadow: BOX_SHADOWS[rank] || "none",

  ".team": {
    height: ROW_HEIGHT,
    position: "relative",
    bottom: 10,

    ".axie": {
      minWidth: 160,
      marginRight: -16,
    },

    ".sidekick": {
      bottom: 5,
    },
  },

  "&:hover": {
    transform: "scale(1.02)",
    ".axie": {
      transform: "scale(1.05)",
    },
    ".sidekick": {
      transform: "scale(1.08)",
    },
  },

  "@media (max-width: 1000px)": {
    padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
    height: "auto",
    gridTemplateColumns: "100px 1fr 280px",
    gap: theme.spacing(1),
    gridTemplateAreas: `
        "rank name team"
        "info points ."
      `,

    ".team": {
      justifySelf: "flex-end",
      height: 40,
      top: 20,
    },

    ".points": {
      justifySelf: "flex-start",
    },

    ".info": {
      fontSize: 16,
      justifySelf: "flex-start",
    },
  },

  "@media (max-width: 780px)": {
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    gridTemplateAreas: `
        "rank rank"
        "name name"
        "team team"
        "points info"
      `,

    ".name": {
      textAlign: "center",
    },

    ".team": {
      height: 100,
      top: 4,
      justifySelf: "center",
    },

    ".rank .rankText": {
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
    },

    ".sidekick": {
      width: "auto",
    },

    ".points": {
      justifySelf: "flex-end",
    },

    ".info": {
      justifySelf: "flex-start",
    },
  },
}));

export default PlayerWrapper;
