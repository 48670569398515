import React from "react";
import { Stack } from "@mui/material";
import {
  updateCurseFilters,
  resetCurseFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";

const CurseControls = ({
  curseFilters,
  updateCurseFilters,
  resetCurseFilters,
}) => {
  const handleUpdateCurseFilters = (newFilters) => {
    updateCurseFilters({ ...curseFilters, ...newFilters });
  };
  return (
    <Stack gap={2}>
      <Search
        value={curseFilters.searchText}
        handleUpdate={handleUpdateCurseFilters}
        placeholder="Search curses"
      />
      <TextButton
        onClick={resetCurseFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCurseFilters,
  resetCurseFilters,
};

const mapStateToProps = (state) => ({
  curseFilters: state.explorer.curseFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(CurseControls);
