import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, useTheme } from "@mui/material";
import { logoMark } from "logos";
import CustomLink from "components/CustomLink/CustomLink";
import { makeInspectorUrlFromId } from "helpers/axie";

const InspectAxieIcon = ({ axie, width }) => {
  const theme = useTheme();
  return (
    <CustomLink
      to={makeInspectorUrlFromId(axie?.axieId)}
      target="_blank"
      sx={{
        border: "none",
        pb: 0,
        display: "flex",
        "&:hover": {
          border: "none",
          "& svg path": {
            fill: theme.palette.primary.main,
          },
        },
      }}
    >
      <Tooltip title="Inspect axie">
        <IconButton
          size="small"
          sx={{ "& svg": { width: (16 / 320) * width, height: "auto" } }}
        >
          {logoMark(theme.palette.text.muted)}
        </IconButton>
      </Tooltip>
    </CustomLink>
  );
};

export default InspectAxieIcon;
