import { Stack, Typography } from "@mui/material";
import React from "react";

const DialogGuildId = ({ guildId }) => {
  return guildId == null ? null : (
    <Stack sx={{ p: "16px 24px", opacity: 0.5, "&:hover": { opacity: 1 } }}>
      <Typography variant="span" color="text.muted" fontSize={11}>
        id: {guildId}
      </Typography>
    </Stack>
  );
};

export default DialogGuildId;
