import React from "react";
import { Stack, useTheme } from "@mui/material";
import Counter from "./Counter";
import Text from "components/Typography/Text";

const Purity = ({ axie, color }) => {
  const theme = useTheme();
  return axie.purity != null &&
    axie.purity.purityCount != null &&
    axie.purity.purityPercent != null ? (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        py: 1,
        px: 1.5,
        borderRadius: "6px",
        boxShadow: `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
        background: theme.palette.card.background,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.06)",
          cursor: "pointer",
        },
      }}
    >
      <Text style={{ lineHeight: 1, width: 60, whiteSpace: "nowrap" }}>
        {`${axie.purity.purityCount} Purity`}
      </Text>
      <Stack gap={2} direction="row" alignItems="center">
        <Counter filled={axie.purity.purityCount} total={6} color={color} />
        <Text color={color}>{`${Math.round(
          axie.purity.purityPercent * 100
        )}%`}</Text>
      </Stack>
    </Stack>
  ) : (
    <></>
  );
};

export default Purity;
