import * as actionTypes from "./breedingSimulatorActionTypes";
import { getSortedPairString, getSortedPairArray } from "helpers/display";
import produce from "immer";
import { calculatePurityAxieTech } from "helpers/axie";

const initialState = {
  ids: [],
  requiredId: "",
  axieData: {},
  axiesFromRonin: [],
  axieIdsFromRonin: [],
  selectedRoninAxies: [],
  selectedRoninAxieIds: [],
  lockedIds: [],
  lockedPairs: [],
  waitingOnResults: false,
  deletedPairs: [],
  numberOfBreeds: {},
  currentPageUnlocked: 1,
  currentPageLocked: 1,
  currentPageInvalid: 1,
  showOnlyBreedingEventPairs: false,
  hideInvalid: true,
  sortType: "PURITY_DESC",
  currentPageSelectAxiesDialog: 1,
  puritySelectAxiesDialog: [],
  breedCountSelectAxiesDialog: [],
  axieClassesSelectAxiesDialog: [],
  selectAllSelectAxiesDialog: [],
  loadingFromRonin: true,
  loadingFromRoninFailed: false,
  validRoninAddresses: [],
  validRoninAddressesWithAxies: [],
};

export const breedingSimulatorReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.ADD_BREEDING_AXIE_ID:
        if (!draft.ids.includes(action.axieId)) {
          draft.ids.push(action.axieId);
        }
        break;

      case actionTypes.FETCH_AXIE_STARTED:
        draft.axieData[action.axieId] = {
          fetching: true,
        };
        break;
      case actionTypes.FETCH_AXIE_SUCCEEDED:
        draft.axieData[action.data.axieId] = {
          ...action.data,
          fetching: false,
          purity: calculatePurityAxieTech(action.data),
        };
        break;
      case actionTypes.FETCH_AXIE_FAILED:
        delete draft.axieData[action.axieId];
        break;

      case actionTypes.DELETE_BREEDING_AXIE_PAIR:
        const sortedDeletePair = getSortedPairString(action.payload);
        draft.deletedPairs.push(sortedDeletePair);
        draft.lockedIds = draft.lockedIds.filter(
          (el) => !action.payload.includes(el)
        );
        draft.lockedPairs = draft.lockedPairs.filter(
          (el) => el !== sortedDeletePair
        );
        break;
      case actionTypes.DELETE_BREEDING_AXIE_ID:
        draft.ids = draft.ids.filter((el) => el !== action.payload);
        draft.lockedIds = draft.lockedIds.filter((el) => el !== action.payload);
        draft.lockedPairs = draft.lockedPairs.filter(
          (pair) =>
            pair.split("-")[0] !== action.payload &&
            pair.split("-")[1] !== action.payload
        );
        break;
      case actionTypes.DELETE_ALL_BREEDING_AXIE_IDS:
        draft.ids = [];
        draft.deletedPairs = [];
        draft.lockedPairs = [];
        draft.lockedIds = [];
        draft.axieData = {};
        break;
      case actionTypes.DELETE_ALL_UNLOCKED_BREEDING_AXIE_IDS:
        draft.ids = draft.lockedIds;
        draft.deletedPairs = [];
        break;

      case actionTypes.UPDATE_NUMBER_OF_BREEDS_FOR_PAIR:
        draft.numberOfBreeds[getSortedPairString(action.payload.pair)] =
          action.payload.numberOfBreeds;
        break;

      case actionTypes.CLEAR_RONIN_BREEDING_AXIES:
        draft.axiesFromRonin = [];
        draft.axieIdsFromRonin = [];
        break;

      // ---------------------- FETCH ALL RONIN AXIES
      case actionTypes.FETCH_ALL_RONIN_AXIES:
        draft.validRoninAddresses = action.addresses;
        break;
      case actionTypes.FETCH_ALL_RONIN_AXIES_STARTED:
        draft.loadingFromRonin = true;
        draft.loadingFromRoninFailed = false;
        break;
      case actionTypes.FETCH_ALL_RONIN_AXIES_FAILED:
        draft.loadingFromRonin = false;
        draft.loadingFromRoninFailed = true;
        break;
      case actionTypes.FETCH_ALL_RONIN_AXIES_SUCCEEDED:
        draft.loadingFromRonin = false;
        break;

      // ---------------------- FETCH RONIN AXIES FOR ADDRESS
      case actionTypes.FETCH_RONIN_AXIES_FOR_ADDRESS_STARTED:
        break;
      case actionTypes.FETCH_RONIN_AXIES_FOR_ADDRESS_FAILED:
        break;
      case actionTypes.FETCH_RONIN_AXIES_FOR_ADDRESS_SUCCEEDED:
        if (!draft.validRoninAddressesWithAxies.includes(action.address)) {
          draft.validRoninAddressesWithAxies.push(action.address);
        }
        draft.axiesFromRonin.push(
          ...action.axies.reduce((result, axie) => {
            if (!draft.axieIdsFromRonin.includes(axie.axieId)) {
              draft.axieIdsFromRonin.push(axie.axieId);
              result.push(axie);
            }
            return result;
          }, [])
        );
        break;

      // ---------------------- FETCH RONIN AXIE DETAIL
      case actionTypes.FETCH_RONIN_AXIE_DETAIL_SUCCEEDED: {
        draft.axieData[action.axie.axieId] = {
          ...action.axie,
          purity: calculatePurityAxieTech(action.axie),
        };
        break;
      }

      case actionTypes.UPDATE_REQUIRED_BREEDING_ID:
        draft.requiredId = action.payload;
        break;

      case actionTypes.TOGGLE_LOCK_BREEDING_PAIR:
        const sortedPairString = getSortedPairArray(action.payload).join("-");
        if (!draft.lockedPairs.includes(sortedPairString)) {
          draft.lockedPairs.push(sortedPairString);
          draft.lockedIds.push(...action.payload);
        } else {
          draft.lockedPairs = draft.lockedPairs.filter(
            (pair) => pair !== sortedPairString
          );
          draft.lockedIds = draft.lockedIds.filter(
            (el) => el !== action.payload[0] && el !== action.payload[1]
          );
        }
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_UNLOCKED:
        draft.currentPageUnlocked = action.currentPage;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_LOCKED:
        draft.currentPageLocked = action.currentPage;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_INVALID:
        draft.currentPageInvalid = action.currentPage;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_SHOW_ONLY_BREEDING_EVENT_PAIRS:
        draft.showOnlyBreedingEventPairs = action.showOnlyBreedingEventPairs;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_HIDE_INVALID:
        draft.hideInvalid = action.hideInvalid;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_SORT_TYPE:
        draft.sortType = action.sortType;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_CURRENT_PAGE_SELECT_AXIES_DIALOG:
        draft.currentPageSelectAxiesDialog = action.currentPage;
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_BREED_COUNT_SELECT_AXIES_DIALOG:
        draft.breedCountSelectAxiesDialog = action.breedCount;
        draft.selectAllSelectAxiesDialog = [];
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_PURITY_SELECT_AXIES_DIALOG:
        draft.puritySelectAxiesDialog = action.purity;
        draft.selectAllSelectAxiesDialog = [];
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_AXIE_CLASSES_SELECT_AXIES_DIALOG:
        draft.axieClassesSelectAxiesDialog = action.axieClasses;
        draft.selectAllSelectAxiesDialog = [];
        break;
      case actionTypes.UPDATE_BREEDING_SIMULATOR_SELECT_ALL_SELECT_AXIES_DIALOG:
        draft.selectAllSelectAxiesDialog = action.selectAll;
        break;
      case actionTypes.DESELECT_ALL_RONIN_AXIES:
        draft.selectAllSelectAxiesDialog = [];
        draft.selectedRoninAxies = [];
        draft.selectedRoninAxieIds = [];
        break;
      case actionTypes.SELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS:
        let newlySelectedAxies = [];
        let newlySelectedAxieIds = [];
        action.filteredAxies.forEach((axie) => {
          if (action.axieClasses.includes(axie.class)) {
            if (!draft.selectedRoninAxieIds.includes(axie.axieId)) {
              newlySelectedAxieIds.push(axie.axieId);
              newlySelectedAxies.push(axie);
            }
          }
        });
        draft.selectedRoninAxies.push(...newlySelectedAxies);
        draft.selectedRoninAxieIds.push(...newlySelectedAxieIds);
        break;
      case actionTypes.DESELECT_ALL_FILTERED_RONIN_AXIES_OF_CLASS:
        let idsToDelete = action.filteredAxies.reduce((result, axie) => {
          if (action.axieClasses.includes(axie.class)) {
            result.push(axie.axieId);
          }
          return result;
        }, []);
        let remainingIds = [];
        let remainingAxies = [];
        draft.selectedRoninAxies.forEach((axie) => {
          if (!idsToDelete.includes(axie.axieId)) {
            remainingIds.push(axie.axieId);
            remainingAxies.push(axie);
          }
        });
        draft.selectedRoninAxies = remainingAxies;
        draft.selectedRoninAxieIds = remainingIds;
        break;
      case actionTypes.SELECT_RONIN_AXIE:
        if (draft.selectedRoninAxieIds.includes(action.selectedAxie.axieId)) {
          draft.selectedRoninAxies = draft.selectedRoninAxies.filter(
            (axie) => axie.axieId !== action.selectedAxie.axieId
          );
          draft.selectedRoninAxieIds = draft.selectedRoninAxieIds.filter(
            (id) => id !== action.selectedAxie.axieId
          );
        } else {
          draft.selectedRoninAxies.push(action.selectedAxie);
          draft.selectedRoninAxieIds.push(action.selectedAxie.axieId);
        }
        break;
      default:
        break;
    }
  });

export default breedingSimulatorReducer;
