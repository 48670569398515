import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ width }) => ({
  padding: (width / 200) * 8,
  textAlign: "center",
  color: "#fff",
}));

const SkillName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ width }) => ({
  fontSize: (width / 200) * 15,
  fontWeight: 700,
  textShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
  lineHeight: 1.2,
}));

const CardName = ({ width, name }) => {
  if (name == null) return null;

  const longName = name.length > 15;
  return (
    <Wrapper width={longName ? width * 0.8 : width}>
      <SkillName width={width} variant="span">
        {name}
      </SkillName>
    </Wrapper>
  );
};

export default CardName;
