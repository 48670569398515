import { useState } from "react";
import BattleExpanded from "./BattleExpanded";
import BattleCondensed from "./BattleCondensed";

const Battle = ({ battle, playerName, fetchAxieData }) => {
  const [expanded, setExpanded] = useState(false);

  const opponentName = "Opponent";

  const handleClick = () => {
    setExpanded((prev) => !prev);
  };

  return expanded ? (
    <BattleExpanded
      playerName={playerName}
      battle={battle}
      fetchAxieData={fetchAxieData}
      handleClick={handleClick}
      opponentName={opponentName}
    />
  ) : (
    <BattleCondensed
      playerName={playerName}
      battle={battle}
      fetchAxieData={fetchAxieData}
      handleClick={handleClick}
      opponentName={opponentName}
    />
  );
};

export default Battle;
