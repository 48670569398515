import React from "react";
import SoldContent from "./SoldContent";
import PageHeader from "components/PageHeader/PageHeader";

const Sold = () => {
  return (
    <>
      <PageHeader padding={80} headerStyle="sky" />
      <SoldContent />
    </>
  );
};

export default Sold;
