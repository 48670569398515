import { Stack } from "@mui/material";
import SeasonNumber from "../atoms/SeasonNumber";
import VictoryStar from "components/Leaderboard/atoms/VictoryStar";

const Season = () => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        background: (theme) =>
          theme.palette.colors.origin.wood.input.background,
        borderRadius: 3,
        p: "4px 8px",
      }}
    >
      <VictoryStar />
      <SeasonNumber />
      <VictoryStar />
    </Stack>
  );
};

export default Season;
