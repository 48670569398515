import * as types from "./actionTypes";

export const fetchDollCards = () => ({
  type: types.DOLL_EXPLORER_FETCH_DOLLCARDS,
});

export const fetchDollCardsStarted = () => ({
  type: types.DOLL_EXPLORER_FETCH_DOLLCARDS_STARTED,
});

export const fetchDollCardsSucceeded = (data) => ({
  type: types.DOLL_EXPLORER_FETCH_DOLLCARDS_SUCCEEDED,
  data,
});

export const fetchDollCardsFailed = () => ({
  type: types.DOLL_EXPLORER_FETCH_DOLLCARDS_FAILED,
});

export const updateControls = (controls) => ({
  type: types.DOLL_EXPLORER_UPDATE_CONTROLS,
  controls,
});
