import React from "react";
import { Stack, Typography } from "@mui/material";
import ClassIcon from "./ClassIcon";
import { axieIsAdult } from "helpers/axie";

const ParentClasses = ({ axie, width }) => {
  if (
    axieIsAdult(axie) ||
    (axie.matronClass == null && axie.sireClass == null)
  ) {
    return <></>;
  }

  return (
    <Stack direction="row" alignItems="center" gap={(0.5 / 320) * width}>
      <Typography
        variant="span"
        fontSize={(13 / 320) * width}
        fontWeight={600}
        color="text.muted"
      >
        Parents
      </Typography>
      {axie.matronClass != null && (
        <ClassIcon cls={axie.matronClass} width={width} />
      )}
      {axie.sireClass != null && (
        <ClassIcon cls={axie.sireClass} width={width} />
      )}
    </Stack>
  );
};

export default ParentClasses;
