import React from "react";
import { Slider, Tooltip, Stack, Typography } from "@mui/material";

const SliderWithIcon = ({
  value,
  setValue,
  icon,
  iconTitle = "",
  min = 27,
  max = 61,
  step = 1,
}) =>
  value != null && setValue != null ? (
    <Stack direction="row" alignItems="center" gap={2}>
      <Tooltip title={iconTitle}>
        {icon != null && (
          <Typography
            variant="span"
            sx={{
              "& svg": {
                height: "20px",
                width: "auto",
              },
            }}
          >
            {icon}
          </Typography>
        )}
      </Tooltip>
      <Slider
        step={step}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
      />
      <Stack sx={{ minWidth: "24px" }}>
        <Typography
          variant="span"
          fontSize="13px"
          fontWeight="700"
          color="text.muted"
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  ) : (
    <></>
  );

export default SliderWithIcon;
