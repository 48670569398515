import React, { useState, useEffect } from "react";
import styles from "./card-explorer.module.scss";
import { connect } from "react-redux";
import CardTier from "./CardTier";
import { addFavorites } from "store/cardExplorer/cardExplorerActions";
import { fetchAxieCards } from "store/data/dataActions";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Controls from "./Controls";
import Favorites from "./Favorites";
import { Tabs, useTheme, Tab, Stack, Container } from "@mui/material";
import { IoStar } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import CardExplorerTitle from "./CardExplorerTitle";
import FavoritesTitle from "./FavoritesTitle";
import { getAllCardsV2 } from "store/data/dataSelectors";

const CardList = ({
  cards,
  fetchingAxieCards,
  fetchAxieCards,
  favoriteCards,
  addFavorites,
  addingFavorites,
}) => {
  const [tab, setTab] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState({
    searchText: "",
    cost: "",
    attackType: "",
    axieClasses: [],
    partTypes: [],
    sortType: "",
  });
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const favorites = query.get("favorites");
  const theme = useTheme();

  useEffect(() => {
    if (cards == null) {
      fetchAxieCards();
    }
  }, [cards, fetchAxieCards]);

  useEffect(() => {
    if (favorites != null && favorites !== undefined) {
      setTab(1);
      addFavorites(favorites.split(","));
    }
  }, [favorites, addFavorites]);

  const cardsMatchingCriteria = (cards, tier, criteria) =>
    cards.filter(
      (card) =>
        (card.cardIndesTier === tier || tier === undefined) &&
        (card.cardText.toLowerCase().includes(criteria.searchText) ||
          card.partName.toLowerCase().includes(criteria.searchText) ||
          card.cardName.toLowerCase().includes(criteria.searchText)) &&
        (criteria.axieClasses.includes(card.class) ||
          criteria.axieClasses.length === 0) &&
        (criteria.partTypes.includes(card.partId.split("-")[0]) ||
          criteria.partTypes.length === 0) &&
        (criteria.cost === card.cardEnergy ||
          criteria.cost === "" ||
          criteria.cost === "any") &&
        ((criteria.attackType === "melee" && card.isRanged === 0) ||
          (criteria.attackType === "ranged" && card.isRanged === 1) ||
          criteria.attackType === "any" ||
          criteria.attackType === "")
    );

  const a11yProps = (index) => {
    return {
      id: `scholar-tabpanel-${index}`,
      "aria-controls": `scholar-tabpanel-${index}`,
    };
  };

  const getPageTitle = (tab) => {
    if (tab === 0) {
      return <CardExplorerTitle />;
    } else if (tab === 1) {
      return <FavoritesTitle />;
    }
    return <></>;
  };

  return (
    <>
      <Container>
        <Tabs
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
          aria-label="Axie Infinity Card Explorer"
          sx={{
            "& .MuiTab-root": {
              color: theme.palette.card.muted,
              background: theme.palette.card.background,
              fontWeight: "600",
              "& svg": {
                color: theme.palette.text.muted,
              },
              "&:hover": {
                color: theme.palette.text.primary,
                background: theme.palette.card.background,
              },
              "&:hover svg": {
                color: "#ffcf24",
              },
              "&.Mui-selected": {
                background: theme.palette.card.background,
                color: theme.palette.text.primary,
              },
            },

            "& .MuiTab-root.Mui-selected svg": {
              color: "#ffcf24",
            },
          }}
        >
          <Tab
            disableRipple
            label="Card Explorer"
            {...a11yProps(0)}
            className={styles.tab}
          />
          <Tab
            disableRipple
            label={
              <span className={styles.favoritesTabLabel}>
                <IoStar />{" "}
                {favoriteCards.length > 0 ? favoriteCards.length : ""} Favorite
                {favoriteCards.length === 1 ? "" : "s"}
              </span>
            }
            {...a11yProps(1)}
            className={styles.tab}
          />
        </Tabs>
      </Container>

      <Controls
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        title={getPageTitle(tab, favoriteCards.length > 0)}
      />

      <Stack
        gap={4}
        className={styles.allCards}
        sx={{
          backgroundColor: theme.palette.card.background,
          boxShadow: theme.palette.card.boxShadow,
        }}
      >
        <TabPanel value={tab} index={0}>
          {fetchingAxieCards ? (
            <CustomLoader />
          ) : (
            ["S", "A", "B", "C", "D", "E", "F"].map((tier, i) => (
              <CardTier
                tier={tier}
                cards={cardsMatchingCriteria(cards, tier, searchCriteria)}
                key={`CARD_TIER_${tier}`}
              />
            ))
          )}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Favorites
            cardsToDisplay={cardsMatchingCriteria(
              favoriteCards,
              undefined,
              searchCriteria
            )}
            favoriteCards={favoriteCards}
            addingFavorites={addingFavorites}
          />
        </TabPanel>
      </Stack>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`card-explorer-tabpanel-${index}`}
      aria-labelledby={`card-explorer-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div className={styles.tabPanel}>{children}</div>}
    </div>
  );
};

const mapDispatchToProps = { fetchAxieCards, addFavorites };

const mapStateToProps = (state) => ({
  favoriteCards: state.cardExplorer.favoriteCards,
  addingFavorites: state.cardExplorer.addingFavorites,
  cards: getAllCardsV2(state),
  fetchingCards: state.data.fetchingAxieCards,
});

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
