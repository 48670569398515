export const GUILD_DASHBOARD_SEARCH_FOR_SCHOLAR_BY_NAME =
  "GUILD_DASHBOARD_SEARCH_FOR_SCHOLAR_BY_NAME";

export const GUILD_DASHBOARD_ADD_SCHOLAR = "GUILD_DASHBOARD_ADD_SCHOLAR";
export const GUILD_DASHBOARD_ADD_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_ADD_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_ADD_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_ADD_SCHOLAR_FAILED";
export const GUILD_DASHBOARD_ADD_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_ADD_SCHOLAR_SUCCEEDED";

export const GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT =
  "GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT";
export const GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_STARTED =
  "GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_STARTED";
export const GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_FAILED =
  "GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_FAILED";
export const GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_SCHOLAR_SNAPSHOT_SUCCEEDED";

export const GUILD_DASHBOARD_EDIT_SCHOLAR = "GUILD_DASHBOARD_EDIT_SCHOLAR";
export const GUILD_DASHBOARD_EDIT_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_EDIT_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_EDIT_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_EDIT_SCHOLAR_FAILED";
export const GUILD_DASHBOARD_EDIT_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_EDIT_SCHOLAR_SUCCEEDED";

export const GUILD_DASHBOARD_DELETE_SCHOLAR = "GUILD_DASHBOARD_DELETE_SCHOLAR";
export const GUILD_DASHBOARD_DELETE_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_DELETE_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_DELETE_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_DELETE_SCHOLAR_SUCCEEDED";
export const GUILD_DASHBOARD_DELETE_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_DELETE_SCHOLAR_FAILED";

export const GUILD_DASHBOARD_WATCH_SCHOLAR = "GUILD_DASHBOARD_WATCH_SCHOLAR";
export const GUILD_DASHBOARD_WATCH_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_WATCH_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_WATCH_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_WATCH_SCHOLAR_SUCCEEDED";
export const GUILD_DASHBOARD_WATCH_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_WATCH_SCHOLAR_FAILED";

export const GUILD_DASHBOARD_UNWATCH_SCHOLAR =
  "GUILD_DASHBOARD_UNWATCH_SCHOLAR";
export const GUILD_DASHBOARD_UNWATCH_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_UNWATCH_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_UNWATCH_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_UNWATCH_SCHOLAR_SUCCEEDED";
export const GUILD_DASHBOARD_UNWATCH_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_UNWATCH_SCHOLAR_FAILED";

export const GUILD_DASHBOARD_FETCH_ALL_SCHOLAR_AXIES =
  "GUILD_DASHBOARD_FETCH_ALL_SCHOLAR_AXIES";
export const GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR =
  "GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR";
export const GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_FAILED";
export const GUILD_DASHBOARD_FETCH_AXIE_DETAIL_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_AXIE_DETAIL_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_SCHOLAR_AXIE_DATA =
  "GUILD_DASHBOARD_FETCH_SCHOLAR_AXIE_DATA";
export const GUILD_DASHBOARD_FETCH_SCHOLAR_AXIE_DATA_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_SCHOLAR_AXIE_DATA_SUCCEEDED";

export const GUILD_DASHBOARD_LOGIN_MANAGER = "GUILD_DASHBOARD_LOGIN_MANAGER";
export const GUILD_DASHBOARD_LOGIN_MANAGER_STARTED =
  "GUILD_DASHBOARD_LOGIN_MANAGER_STARTED";
export const GUILD_DASHBOARD_LOGIN_MANAGER_SUCCEEDED =
  "GUILD_DASHBOARD_LOGIN_MANAGER_SUCCEEDED";
export const GUILD_DASHBOARD_LOGIN_MANAGER_FAILED =
  "GUILD_DASHBOARD_LOGIN_MANAGER_FAILED";

export const GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR =
  "GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR";
export const GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_FAILED";
export const GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_SUCCEEDED";

export const GUILD_DASHBOARD_FETCH_LIVE_SCHOLAR_DATA_STARTED =
  "GUILD_DASHBOARD_FETCH_LIVE_SCHOLAR_DATA_STARTED";
export const GUILD_DASHBOARD_FETCH_LIVE_SCHOLAR_DATA_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_LIVE_SCHOLAR_DATA_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_LIVE_SCHOLAR_DATA_FAILED =
  "GUILD_DASHBOARD_FETCH_LIVE_SCHOLAR_DATA_FAILED";

export const GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR =
  "GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR";
export const GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_STARTED =
  "GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_STARTED";
export const GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_FAILED =
  "GUILD_DASHBOARD_FETCH_LIVE_DATA_FOR_SCHOLAR_FAILED";

export const GUILD_DASHBOARD_UPDATE_USER_PREFERENCES =
  "GUILD_DASHBOARD_UPDATE_USER_PREFERENCES";
export const GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_STARTED =
  "GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_STARTED";
export const GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_SUCCEEDED =
  "GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_SUCCEEDED";
export const GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_FAILED =
  "GUILD_DASHBOARD_UPDATE_USER_PREFERENCES_FAILED";

export const GUILD_DASHBOARD_ADD_REQUESTS = "GUILD_DASHBOARD_ADD_REQUESTS";
export const GUILD_DASHBOARD_REMOVE_REQUESTS =
  "GUILD_DASHBOARD_REMOVE_REQUESTS";

export const GUILD_DASHBOARD_AXIE_UPDATE_ROLE =
  "GUILD_DASHBOARD_AXIE_UPDATE_ROLE";
export const GUILD_DASHBOARD_AXIE_UPDATE_SPECIALTY =
  "GUILD_DASHBOARD_AXIE_UPDATE_SPECIALTY";
export const GUILD_DASHBOARD_UPDATE_AXIES_BREED_COUNT =
  "GUILD_DASHBOARD_UPDATE_AXIES_BREED_COUNT";
export const GUILD_DASHBOARD_UPDATE_AXIES_PURITY =
  "GUILD_DASHBOARD_UPDATE_AXIES_PURITY";
export const GUILD_DASHBOARD_UPDATE_AXIES_PURITY_PERCENT =
  "GUILD_DASHBOARD_UPDATE_AXIES_PURITY_PERCENT";
export const GUILD_DASHBOARD_UPDATE_AXIES_HP =
  "GUILD_DASHBOARD_UPDATE_AXIES_HP";
export const GUILD_DASHBOARD_UPDATE_AXIES_SPEED =
  "GUILD_DASHBOARD_UPDATE_AXIES_SPEED";
export const GUILD_DASHBOARD_UPDATE_AXIES_MORALE =
  "GUILD_DASHBOARD_UPDATE_AXIES_MORALE";
export const GUILD_DASHBOARD_UPDATE_AXIES_SKILL =
  "GUILD_DASHBOARD_UPDATE_AXIES_SKILL";
export const GUILD_DASHBOARD_UPDATE_AXIES_SORT_TYPE =
  "GUILD_DASHBOARD_UPDATE_AXIES_SORT_TYPE";
export const GUILD_DASHBOARD_UPDATE_AXIES_AXIE_CLASSES =
  "GUILD_DASHBOARD_UPDATE_AXIES_AXIE_CLASSES";
export const GUILD_DASHBOARD_UPDATE_AXIES_SEARCH_QUERY =
  "GUILD_DASHBOARD_UPDATE_AXIES_SEARCH_QUERY";
export const GUILD_DASHBOARD_UPDATE_AXIES_SHOW_GENES =
  "GUILD_DASHBOARD_UPDATE_AXIES_SHOW_GENES";
export const GUILD_DASHBOARD_UPDATE_AXIES_CURRENT_PAGE =
  "GUILD_DASHBOARD_UPDATE_AXIES_CURRENT_PAGE";
export const GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS =
  "GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS";
export const GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_D =
  "GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_D";
export const GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R1 =
  "GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R1";
export const GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R2 =
  "GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R2";
export const GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_INDIVIDUAL_PART_MATCHES =
  "GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_INDIVIDUAL_PART_MATCHES";
export const GUILD_DASHBOARD_RESET_AXIE_FILTERS =
  "GUILD_DASHBOARD_RESET_AXIE_FILTERS";

export const GUILD_DASHBOARD_UPDATE_GUILD_SEARCH_QUERY =
  "GUILD_DASHBOARD_UPDATE_GUILD_SEARCH_QUERY";

export const GUILD_DASHBOARD_IMPORT_SCHOLARS =
  "GUILD_DASHBOARD_IMPORT_SCHOLARS";
export const GUILD_DASHBOARD_IMPORT_SCHOLARS_STARTED =
  "GUILD_DASHBOARD_IMPORT_SCHOLARS_STARTED";
export const GUILD_DASHBOARD_IMPORT_SCHOLARS_SUCCEEDED =
  "GUILD_DASHBOARD_IMPORT_SCHOLARS_SUCCEEDED";
export const GUILD_DASHBOARD_IMPORT_SCHOLARS_FAILED =
  "GUILD_DASHBOARD_IMPORT_SCHOLARS_FAILED";

export const GUILD_DASHBOARD_SET_SCHOLAR_TO_MODIFY_IDS =
  "GUILD_DASHBOARD_SET_SCHOLAR_TO_MODIFY_IDS";
export const GUILD_DASHBOARD_UPDATE_EDIT_SCHOLAR_DIALOG_IS_OPEN =
  "GUILD_DASHBOARD_UPDATE_EDIT_SCHOLAR_DIALOG_IS_OPEN";
export const GUILD_DASHBOARD_UPDATE_DELETE_SCHOLAR_DIALOG_IS_OPEN =
  "GUILD_DASHBOARD_UPDATE_DELETE_SCHOLAR_DIALOG_IS_OPEN";

export const GUILD_DASHBOARD_UPDATE_ADD_TEAM_DIALOG_IS_OPEN =
  "GUILD_DASHBOARD_UPDATE_ADD_TEAM_DIALOG_IS_OPEN";

export const GUILD_DASHBOARD_ADD_TEAM = "GUILD_DASHBOARD_ADD_TEAM";
export const GUILD_DASHBOARD_ADD_TEAM_STARTED =
  "GUILD_DASHBOARD_ADD_TEAM_STARTED";
export const GUILD_DASHBOARD_ADD_TEAM_SUCCEEDED =
  "GUILD_DASHBOARD_ADD_TEAM_SUCCEEDED";
export const GUILD_DASHBOARD_ADD_TEAM_FAILED =
  "GUILD_DASHBOARD_ADD_TEAM_FAILED";

export const GUILD_DASHBOARD_DELETE_TEAM = "GUILD_DASHBOARD_DELETE_TEAM";
export const GUILD_DASHBOARD_DELETE_TEAM_STARTED =
  "GUILD_DASHBOARD_DELETE_TEAM_STARTED";
export const GUILD_DASHBOARD_DELETE_TEAM_SUCCEEDED =
  "GUILD_DASHBOARD_DELETE_TEAM_SUCCEEDED";
export const GUILD_DASHBOARD_DELETE_TEAM_FAILED =
  "GUILD_DASHBOARD_DELETE_TEAM_FAILED";

export const GUILD_DASHBOARD_UPDATE_EDIT_TEAM_DIALOG_IS_OPEN =
  "GUILD_DASHBOARD_UPDATE_EDIT_TEAM_DIALOG_IS_OPEN";
export const GUILD_DASHBOARD_EDIT_TEAM = "GUILD_DASHBOARD_EDIT_TEAM";
export const GUILD_DASHBOARD_EDIT_TEAM_STARTED =
  "GUILD_DASHBOARD_EDIT_TEAM_STARTED";
export const GUILD_DASHBOARD_EDIT_TEAM_SUCCEEDED =
  "GUILD_DASHBOARD_EDIT_TEAM_SUCCEEDED";
export const GUILD_DASHBOARD_EDIT_TEAM_FAILED =
  "GUILD_DASHBOARD_EDIT_TEAM_FAILED";

export const GUILD_DASHBOARD_FETCH_TEAM = "GUILD_DASHBOARD_FETCH_TEAM";
export const GUILD_DASHBOARD_FETCH_TEAM_STARTED =
  "GUILD_DASHBOARD_FETCH_TEAM_STARTED";
export const GUILD_DASHBOARD_FETCH_TEAM_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_TEAM_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_TEAM_FAILED =
  "GUILD_DASHBOARD_FETCH_TEAM_FAILED";

export const GUILD_DASHBOARD_FETCH_TEAMS_STARTED =
  "GUILD_DASHBOARD_FETCH_TEAMS_STARTED";
export const GUILD_DASHBOARD_FETCH_TEAMS_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_TEAMS_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_TEAMS_FAILED =
  "GUILD_DASHBOARD_FETCH_TEAMS_FAILED";

export const GUILD_DASHBOARD_SET_ACTIVE_TEAM =
  "GUILD_DASHBOARD_SET_ACTIVE_TEAM";
export const GUILD_DASHBOARD_SET_ACTIVE_SCHOLAR =
  "GUILD_DASHBOARD_SET_ACTIVE_SCHOLAR";

export const GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM =
  "GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM";
export const GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_STARTED =
  "GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_STARTED";
export const GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_SUCCEEDED =
  "GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_SUCCEEDED";
export const GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_FAILED =
  "GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM_FAILED";

export const GUILD_DASHBOARD_FETCH_CARDS_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_CARDS_SUCCEEDED";

export const GUILD_DASHBOARD_UPDATE_NUMBER_OF_SCHOLARS_PER_PAGE =
  "GUILD_DASHBOARD_UPDATE_NUMBER_OF_SCHOLARS_PER_PAGE";
export const GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE =
  "GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE";
export const GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE_TEAM =
  "GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE_TEAM";
export const GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE =
  "GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE";
export const GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE_TEAM =
  "GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE_TEAM";
export const GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE =
  "GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE";
export const GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE_TEAM =
  "GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE_TEAM";

export const GUILD_DASHBOARD_UPDATE_SCHOLAR_EARNINGS_CHART_TO_DISPLAY =
  "GUILD_DASHBOARD_UPDATE_SCHOLAR_EARNINGS_CHART_TO_DISPLAY";
export const GUILD_DASHBOARD_UPDATE_SCHOLAR_BATTLES_CHART_TO_DISPLAY =
  "GUILD_DASHBOARD_UPDATE_SCHOLAR_BATTLES_CHART_TO_DISPLAY";

export const GUILD_DASHBOARD_UPDATE_SCHOLAR_PVP_BATTLES_CURRENT_PAGE =
  "GUILD_DASHBOARD_UPDATE_SCHOLAR_PVP_BATTLES_CURRENT_PAGE";

export const GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG =
  "GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG";
export const GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_STARTED =
  "GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_STARTED";
export const GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_SUCCEEDED =
  "GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_SUCCEEDED";
export const GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_FAILED =
  "GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_FAILED";

export const GUILD_DASHBOARD_UPDATE_INVENTORY_CONTROLS =
  "GUILD_DASHBOARD_UPDATE_INVENTORY_CONTROLS";
