import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import ExpandedPlayerHeader from "../molecules/ExpandedPlayerHeader";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  background:
    theme.palette.mode === "light"
      ? theme.palette.card.pop1
      : theme.palette.card.shade1,
  borderRadius: "16px",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const PlayerExpanded = ({
  name,
  axies,
  mmrBefore,
  mmrAfter,
  slpWon,
  fetchAxieData,
}) => {
  const handleRefreshAxie = (axieId) => {
    fetchAxieData(axieId);
  };

  return (
    <Wrapper>
      <ExpandedPlayerHeader
        name={name}
        slpWon={slpWon}
        mmrBefore={mmrBefore}
        mmrAfter={mmrAfter}
      />

      <Stack
        direction="row"
        gap={1}
        justifyContent="center"
        alignItems="stretch"
        flexWrap="wrap"
      >
        {axies != null &&
          axies.map((axie, i) => (
            <AxieV3
              axie={axie}
              refreshAxie={handleRefreshAxie}
              variant={"full"}
              width={280}
              displayCard={true}
              fillSpace={true}
              key={`AXIE_${i}`}
            />
          ))}
      </Stack>
    </Wrapper>
  );
};

export default PlayerExpanded;
