export const FAVORITE_CARD = "FAVORITE_CARD";
export const UNFAVORITE_CARD = "UNFAVORITE_CARD";

export const FETCH_CARDS = "FETCH_CARDS";
export const FETCH_CARDS_STARTED = "FETCH_CARDS_STARTED";
export const FETCH_CARDS_SUCCEEDED = "FETCH_CARDS_SUCCEEDED";
export const FETCH_CARDS_FAILED = "FETCH_CARDS_FAILED";

export const ADD_FAVORITES = "ADD_FAVORITES";
export const ADD_FAVORITES_STARTED = "ADD_FAVORITES_STARTED";
export const ADD_FAVORITES_SUCCEEDED = "ADD_FAVORITES_SUCCEEDED";
export const ADD_FAVORITES_FAILED = "ADD_FAVORITES_FAILED";

export const CLEAR_ALL_FAVORITES = "CLEAR_ALL_FAVORITES";
