import React from "react";
import { Stack } from "@mui/material";

const TooltipWrapper = ({ children }) => {
  return (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.palette.popover.background,
        boxShadow: (theme) => theme.palette.popover.boxShadow,
        padding: "8px",
        borderRadius: "4px",
      }}
      gap={1}
    >
      {children}
    </Stack>
  );
};

export default TooltipWrapper;
