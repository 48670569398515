import * as types from "./leaderboardActionTypes";

export const fetchArenaLeaderboard = () => ({
  type: types.FETCH_ARENA_LEADERBOARD,
});

export const fetchArenaLeaderboardStarted = () => ({
  type: types.FETCH_ARENA_LEADERBOARD_STARTED,
});

export const fetchArenaLeaderboardFailed = () => ({
  type: types.FETCH_ARENA_LEADERBOARD_FAILED,
});

export const fetchArenaLeaderboardSucceeded = (data) => ({
  type: types.FETCH_ARENA_LEADERBOARD_SUCCEEDED,
  data,
});
