import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  setActiveTeam,
  updateCurrentPageScholarsTableTeam,
  updateActiveTabScholarsTableTeam,
  updateSortModelScholarsTableTeam,
} from "store/guildDashboard/actions/guildDashboardActions";
import ScholarTable from "../../Scholars/organisms/ScholarTables";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import PerformanceTeam from "../../../organisms/PerformanceTeam";
import WidgetHeadCount from "../../../molecules/WidgetHeadCount";
import NoTeam from "../atoms/NoTeam";
import WidgetMVP from "../../../molecules/WidgetMVP";
import HorizontalBarChart from "../../../molecules/HorizontalBarChart";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Grid, Chip, Typography, Stack } from "@mui/material";
import Card from "components/Card/Card";
import Controls from "./Controls";

const Team = ({
  setActiveTeam,
  guildStats,
  team,
  activeGuildTeamId,
  currentPage,
  updateCurrentPage,
  activeTab,
  updateActiveTab,
  sortModel,
  updateSortModel,
}) => {
  const { guildTeamId } = useParams();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (guildTeamId != null) {
      setFirstLoad(false);
      setActiveTeam(parseInt(guildTeamId), state?.prevPath !== "SCHOLAR_PAGE");
    } else {
      navigate("/guild-dashboard/teams");
    }
  }, [guildTeamId, setActiveTeam, state?.prevPath, navigate]);

  useEffect(() => {
    if (!firstLoad && activeGuildTeamId === undefined) {
      navigate("/guild-dashboard/teams");
    }
  }, [activeGuildTeamId, state?.prevPath, firstLoad, navigate]);

  if (!guildTeamId && !team) {
    return <CustomLoader />;
  } else if (!team && guildTeamId) {
    return <NoTeam />;
  } else {
    const {
      teamRating,
      overallAverageMMR,
      numberOfScholars,
      numberOfAxies,
      highestMMR,
      arenaMVP,
      sortedMMRs,
    } = guildStats.teamStats[guildTeamId];
    return (
      <>
        <Stack
          gap={2}
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack gap={1}>
            <Typography
              variant="span"
              fontSize="16px"
              fontWeight="600"
              color="text.regular"
            >
              Team
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography
                variant="span"
                fontSize="24px"
                fontWeight="700"
                color="text.bright"
              >
                {team.name}
              </Typography>
              <Chip
                variant="outlined"
                size="small"
                color="secondary"
                label={team.isPublic === 1 ? "Public" : "Private"}
                sx={{ alignSelf: "flex-start" }}
              />
            </Stack>
          </Stack>
          <Controls teamName={team.name} guildTeamId={parseInt(guildTeamId)} />
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Card highlight={true}>
              <PerformanceTeam
                title="Team Performance"
                ratingTitle="Team Rating"
                rating={teamRating}
                averageMMR={overallAverageMMR}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <WidgetHeadCount
                numberOfScholars={numberOfScholars}
                numberOfAxies={numberOfAxies}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <WidgetMVP
                title="Scholar MVPs"
                highestMMR={highestMMR}
                arenaMVP={arenaMVP}
              />
            </Card>
          </Grid>
          {sortedMMRs.length > 0 && (
            <Grid item xs={12} sm={12} md={8}>
              <Card>
                <HorizontalBarChart
                  title="Average Stars by Scholar"
                  data={sortedMMRs}
                  yAxis="name"
                  xAxis="mmr"
                  xAxisLabel="Stars"
                />
              </Card>
            </Grid>
          )}
        </Grid>
        <Stack sx={{ maxWidth: "100%" }}>
          <ScholarTable
            currentPage={currentPage}
            setCurrentPage={updateCurrentPage}
            prevPath={pathname}
            activeTab={activeTab}
            setActiveTab={updateActiveTab}
            sortModel={sortModel}
            setSortModel={updateSortModel}
          />
        </Stack>
      </>
    );
  }
};

const mapDispatchToProps = {
  setActiveTeam,
  updateCurrentPage: updateCurrentPageScholarsTableTeam,
  updateActiveTab: updateActiveTabScholarsTableTeam,
  updateSortModel: updateSortModelScholarsTableTeam,
};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
  team: teamSelectors.getActiveTeam(state),
  activeGuildTeamId: state.guildDashboard.guild.activeGuildTeamId,
  currentPage: state.guildDashboard.config.scholarsTableTeam.currentPage,
  activeTab: state.guildDashboard.config.scholarsTableTeam.activeTab,
  sortModel: state.guildDashboard.config.scholarsTableTeam.sortModel,
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
