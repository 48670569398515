import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import SEO from "./components/Utility/SEO";

if (process.env.REACT_APP_MOCK_API === "true") {
  console.log("Mock API enabled!");
  const { worker } = require("./mocks/browser");
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <SEO />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
