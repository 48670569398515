import React, { useState, useEffect, useCallback } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import * as config from "config";
import { formatUSD } from "helpers/display";
import styles from "./breeding-costs.module.scss";
import { Stack, useTheme, Typography, Box } from "@mui/material";

const columnHeaders = [
  { label: "Breed #" },
  { label: "SLP" },
  { label: "AXS" },
  { label: "Total Cost" },
];

export const BreedingCostTable = ({ data }) => {
  const initialBreedingCost = {
    slp: 0,
    axs: 0,
    breeds: [],
    usd: 0,
    eth: 0,
    usdAvg: 0,
    ethAvg: 0,
  };
  const theme = useTheme();
  const [totalBreedingCost, setTotalBreedingCost] =
    useState(initialBreedingCost);
  const [clickedRowNums, setClickedRowNums] = useState([]);
  const [tableData, setTableData] = useState([]);

  const calculateCost = useCallback(
    (slp, axs, currency) => {
      let cost = 0;
      if ("smooth-love-potion" in data) {
        cost += data["smooth-love-potion"][currency] * slp;
      }
      if ("axie-infinity" in data) {
        cost += data["axie-infinity"][currency] * axs;
      }
      return cost;
    },
    [data]
  );

  useEffect(() => {
    const cost = {
      slp: 0,
      axs: 0,
      breeds: [],
      usd: 0,
      eth: 0,
      usdAvg: 0,
      ethAvg: 0,
    };

    tableData.forEach((row) => {
      if (clickedRowNums.includes(row.num)) {
        cost.breeds.push(row.num);
        cost.slp += config.BREEDING_COST_SLP[row.num - 1] * 2;
        cost.axs += config.BREEDING_COST_AXS;
        cost.usd = row.usd === "Error" ? "Error" : cost.usd + row.usd;
        cost.eth = row.eth === "Error" ? "Error" : cost.eth + row.eth;
      }
    });

    cost.usdAvg = cost.usd / cost.breeds.length;
    cost.ethAvg = cost.eth / cost.breeds.length;

    setTotalBreedingCost(cost);
  }, [tableData, clickedRowNums]);

  useEffect(() => {
    const calculateCostsForBreedNumber = (num) => {
      const slpRequired = config.BREEDING_COST_SLP[num - 1];
      const slp = slpRequired * 2;
      const axs = config.BREEDING_COST_AXS;
      const costUsd = data ? calculateCost(slp, axs, "usd") : "Error";
      const costEth = data ? calculateCost(slp, axs, "eth") : "Error";

      return {
        num,
        slp,
        axs,
        slpDetail: `(${slpRequired.toLocaleString()}+${slpRequired.toLocaleString()})`,
        usd: costUsd,
        eth: costEth,
      };
    };

    const newTableData = [1, 2, 3, 4, 5, 6, 7].map((i) =>
      calculateCostsForBreedNumber(i)
    );

    setTableData(newTableData);
  }, [data, calculateCost]);

  const handleClickRow = (row) => {
    const clickedRowIndex = clickedRowNums.findIndex((r) => r === row.num);

    if (clickedRowIndex === -1) {
      setClickedRowNums([...clickedRowNums, row.num]);
    } else {
      let newRows = [...clickedRowNums];
      newRows.splice(clickedRowIndex, 1);
      setClickedRowNums(newRows);
    }
  };

  return (
    <TableContainer>
      <Table className={styles.breedingCostTable}>
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "35%" }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {columnHeaders.map((col, index) => (
              <TableCell
                key={`HEADER_${index}`}
                align="center"
                sx={{ borderBottom: "none" }}
              >
                <Typography
                  fontSize="15px"
                  color="text.muted"
                  fontWeight="700"
                  sx={{
                    letterSpacing: "0.5px",
                    whiteSpace: "nowrap",
                    textTransform: "uppercase",
                  }}
                >
                  {col.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...tableData].map((row) => (
            <TableRow
              key={row.num}
              className={styles.dataRow}
              onClick={() => handleClickRow(row)}
              sx={{
                backgroundColor: totalBreedingCost.breeds.includes(row.num)
                  ? theme.palette.table.rowBackgroundContrast
                  : theme.palette.table.rowBackground,
                boxShadow: theme.palette.table.rowBoxShadow,
                "& .MuiTableCell-root": {
                  color: theme.palette.card.text,
                  fontWeight: "600",
                  fontSize: "18px",
                  border: "none",
                  borderTop: "2px solid transparent",
                  borderBottom: "2px solid transparent",
                  borderColor: totalBreedingCost.breeds.includes(row.num)
                    ? theme.palette.table.rowOutlineContrast
                    : "transparent",
                  "&:first-of-type": {
                    borderLeft: "2px solid transparent",
                    borderColor: totalBreedingCost.breeds.includes(row.num)
                      ? theme.palette.table.rowOutlineContrast
                      : "transparent",
                  },
                  "&:last-of-type": {
                    borderRight: "2px solid transparent",
                    borderColor: totalBreedingCost.breeds.includes(row.num)
                      ? theme.palette.table.rowOutlineContrast
                      : "transparent",
                  },
                },
                "&:hover": {
                  "& .MuiTableCell-root": {
                    backgroundColor: theme.palette.table.rowBackgroundContrast,
                    borderColor: theme.palette.table.rowOutlineContrast,
                  },
                },
              }}
            >
              <TableCell align="center" className={styles.tableCell}>
                <Typography variant="span">{row.num}</Typography>
              </TableCell>
              <TableCell align="center" className={styles.tableCell}>
                <Stack>
                  <Typography variant="span">
                    {row.slp.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="span"
                    color="text.muted"
                    fontWeight="600"
                    fontSize="14px"
                  >
                    {row.slpDetail}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="center" className={styles.tableCell}>
                {row.axs}
              </TableCell>
              <TableCell align="center" className={styles.tableCell}>
                <Stack>
                  <Typography
                    variant="span"
                    color="text.primary"
                    fontWeight="700"
                    fontSize="18px"
                  >
                    {row.eth === "Error" ? "Error" : `Ξ${row.eth.toFixed(4)}`}
                  </Typography>
                  <Typography
                    variant="span"
                    color="text.muted"
                    fontWeight="500"
                    fontSize="16px"
                  >
                    {row.usd === "Error" ? "Error" : formatUSD(row.usd)}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ background: theme.palette.colors.purple[300] }}>
            <TableCell align="center" className={styles.totalCell}>
              <InstructionText>Total</InstructionText>
            </TableCell>
            {totalBreedingCost.breeds.length === 0 ? (
              <TableCell align="left" colSpan={4} className={styles.totalCell}>
                <InstructionText>
                  Select your desired number of breeds to calculate the total
                  breed cost
                </InstructionText>
              </TableCell>
            ) : (
              <>
                <TableCell align="center" className={styles.totalCell}>
                  <Stack>
                    <TotalRowText>
                      {`${totalBreedingCost.slp.toLocaleString()} SLP`}
                    </TotalRowText>
                    <TotalRowSubtext>
                      {formatUSD(
                        calculateCost(totalBreedingCost.slp, 0, "usd")
                      )}
                    </TotalRowSubtext>
                  </Stack>
                </TableCell>
                <TableCell align="center" className={styles.totalCell}>
                  <Stack>
                    <TotalRowText>{`${totalBreedingCost.axs} AXS`}</TotalRowText>
                    <TotalRowSubtext>
                      {formatUSD(
                        calculateCost(0, totalBreedingCost.axs, "usd")
                      )}
                    </TotalRowSubtext>
                  </Stack>
                </TableCell>
                <TableCell align="center" className={styles.totalCell}>
                  <Stack>
                    <Stack direction="row" gap={1} justifyContent="center">
                      <TotalRowText>
                        {totalBreedingCost.eth === "Error"
                          ? "Error"
                          : `Ξ${totalBreedingCost.eth.toFixed(4)}`}
                      </TotalRowText>
                      <AvgCostText>{`Avg: Ξ${totalBreedingCost.ethAvg.toFixed(
                        4
                      )}`}</AvgCostText>
                    </Stack>
                    <Stack direction="row" gap={1} justifyContent="center">
                      <TotalRowSubtext>
                        {totalBreedingCost.usd === "Error"
                          ? "Error"
                          : `${formatUSD(totalBreedingCost.usd)}`}
                      </TotalRowSubtext>
                      <AvgCostText>{`Avg: ${formatUSD(
                        totalBreedingCost.usdAvg
                      )}`}</AvgCostText>
                    </Stack>
                  </Stack>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AvgCostText = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: "rgba(255,255,255,.6)",
        borderRadius: "8px",
        padding: "2px 6px",
        alignSelf: "center",
      }}
    >
      <Typography
        variant="span"
        color={theme.palette.colors.purple[300]}
        fontWeight="700"
        fontSize="12px"
        sx={{ whiteSpace: "nowrap" }}
      >
        {children}
      </Typography>
    </Box>
  );
};

const TotalRowText = ({ children }) => (
  <Typography
    variant="span"
    color="#fff"
    fontWeight="700"
    fontSize="22px"
    sx={{ whiteSpace: "nowrap" }}
  >
    {children}
  </Typography>
);

const TotalRowSubtext = ({ children }) => (
  <Typography variant="span" color="#ddd" fontWeight="500" fontSize="16px">
    {children}
  </Typography>
);

const InstructionText = ({ children }) => (
  <Typography variant="span" color="#fff" fontWeight="600" fontSize="16px">
    {children}
  </Typography>
);

export default BreedingCostTable;
