import * as types from "./actionTypes";

export const fetchDollLeaderboard = () => ({
  type: types.DOLL_LEADERBOARD_FETCH_LEADERBOARD,
});

export const fetchDollLeaderboardStarted = () => ({
  type: types.DOLL_LEADERBOARD_FETCH_LEADERBOARD_STARTED,
});

export const fetchDollLeaderboardFailed = () => ({
  type: types.DOLL_LEADERBOARD_FETCH_LEADERBOARD_FAILED,
});

export const fetchDollLeaderboardSucceeded = (data) => ({
  type: types.DOLL_LEADERBOARD_FETCH_LEADERBOARD_SUCCEEDED,
  data,
});

export const updateControls = (controls) => ({
  type: types.DOLL_LEADERBOARD_UPDATE_CONTROLS,
  controls,
});
