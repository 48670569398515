import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import VictoryStar from "components/Leaderboard/atoms/VictoryStar";
import { themeColors } from "theme/colors";
import { ThemeMode } from "types";

interface Props {
  stars: number;
  height?: number;
  colorMode: ThemeMode;
}

const VictoryStars = ({
  stars,
  height,
  colorMode = ThemeMode.light,
}: Props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={0.75}
      alignItems="center"
    >
      <VictoryStar height={height || smDown ? 15 : 18} />
      <Typography
        fontSize={{ xs: 16, sm: 16, md: 17 }}
        component="span"
        fontWeight={700}
        sx={{
          color:
            themeColors[colorMode]?.game.victoryStars ||
            theme.palette.game.victoryStars,
        }}
      >
        {stars}
      </Typography>
    </Stack>
  );
};

export default VictoryStars;
