import { Box, Stack, useTheme } from "@mui/material";
import Season from "./Season";
import Title from "components/Origin/atoms/Title";

const TitleOnSign = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        width: 500,
        margin: "0 auto",
        img: {
          width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "16%",
        }}
      >
        <Box sx={{ mb: "-4px" }}>
          <Title
            fontSize={40}
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              [theme.breakpoints.down("sm")]: {
                fontSize: 32,
              },
              "@media (max-width: 390px)": {
                fontSize: 28,
              },
            }}
          >
            Origins Leaderboard
          </Title>
        </Box>
        <Season />
      </Stack>
      <img src="/images/accents/wooden-sign.png" alt="Wooden Sign" />
    </Box>
  );
};

export default TitleOnSign;
