import { Stack } from "@mui/material";
import React from "react";

const BannerImage = ({ src, alt }) => {
  return (
    <Stack
      sx={{
        img: {
          borderRadius: (theme) => theme.spacing(2),
          width: "100%",
        },
      }}
    >
      <img src={src} alt={alt} />
    </Stack>
  );
};

export default BannerImage;
