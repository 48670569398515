import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-8-19</Date>
      <Change type="new">
        Added a new
        <CustomLink to="/origins-leaderboard">Origin Leaderboard</CustomLink>
        page to showcase top players in Axie Infinity: Origin!
      </Change>
      <Change type="new">
        Added a new
        <CustomLink to="/charts">Charts</CustomLink>
        page with an SLP Issuance chart!
      </Change>
      <Change type="new">
        Migrated SLP and Arena data fetching from Classic to Origin.
      </Change>
      <Change type="improved">
        Revised the structure of our navigation and improved styling across the
        site.
      </Change>
      <Change type="bug">
        Fixed a bug where special parts were not appearing in search and not
        showing the correct Axie cards.
      </Change>
    </Entry>
  );
};

export default Changes;
