import React from "react";
import { getColorForAxieClass } from "helpers/axie";
import { BODY_PARTS_IN_ORDER } from "config";
import { BODY_PARTS_DICT } from "helpers/axieFeatures";
import { Box, useTheme, Stack } from "@mui/material";
import PartRow from "./PartRow";
import PartIcon from "./PartIcon";
import PartName from "./PartName";
import { fadeInOut } from "theme/animations/fadeInOut";

const DecodedGenes = ({ axie, showGenes, width, large, style }) => {
  const theme = useTheme();
  if (axie.genes == null) {
    return null;
  }

  const getPartDetails = (axie, gene, part) => {
    if (
      (!axie.parts[gene][part] || axie.parts[gene][part] === "") &&
      !BODY_PARTS_DICT[axie.parts[gene][part]]
    ) {
      return undefined;
    }
    return {
      name:
        axie.parts[gene][part]?.name ||
        BODY_PARTS_DICT[axie.parts[gene][part]]?.name,
      class:
        axie.parts[gene][part]?.class ||
        BODY_PARTS_DICT[axie.parts[gene][part]]?.class,
      specialGenes:
        axie.parts[gene][part]?.specialGenes ||
        axie.parts[gene][part]?.specialGenes === null
          ? axie.parts[gene][part]?.specialGenes
          : BODY_PARTS_DICT[axie.parts[gene][part]]?.specialGenes,
    };
  };

  if (parseInt(axie.stage) === 1) {
    return <></>;
  }

  return (
    <Stack
      gap={`${(1 / 320) * width}px`}
      sx={{
        display: showGenes ? "flex" : "none",
        textAlign: "left",
        background:
          theme.palette.mode === "light"
            ? theme.palette.card.pop1
            : theme.palette.card.shade2,
        borderRadius: `${(8 / 320) * width}px`,
        p: (1 / 320) * width,
        mt: large ? 0 : `${(8 / 320) * width}px`,
        ...style,
      }}
    >
      {BODY_PARTS_IN_ORDER.map((part, i) => {
        const dPartClass =
          axie.parts?.d[part].class ||
          BODY_PARTS_DICT[axie.parts?.d[part]]?.class;

        return (
          <PartRow key={`PART_ROW_${i}`} width={width} large={large}>
            <PartIcon
              part={part}
              dPartClass={dPartClass}
              width={width}
              large={large}
            />

            {["d", "r1", "r2"].map((gene, j) => {
              if (axie.parts[gene][part] != null) {
                const partDetails = getPartDetails(axie, gene, part);
                return (
                  <PartName
                    key={`PART_NAME_${j}${i}`}
                    text={partDetails?.name}
                    color={getColorForAxieClass(partDetails?.class)}
                    specialGenes={partDetails?.specialGenes}
                    width={width}
                    large={large}
                  />
                );
              } else {
                return (
                  <Stack
                    justifyContent="center"
                    sx={{
                      height: `${(22 / 320) * width}px`,
                    }}
                    key={`LOADING_PART_${i}_${j}`}
                  >
                    <Box
                      sx={{
                        background: theme.palette.axieCard.loading,
                        height: `${(18 / 320) * width}px`,
                        width: `${(60 / 320) * width}px`,
                        borderRadius: `${(4 / 320) * width}px`,
                        animation: `${fadeInOut} ease 4s infinite`,
                      }}
                    />
                  </Stack>
                );
              }
            })}
          </PartRow>
        );
      })}
    </Stack>
  );
};

export default DecodedGenes;
