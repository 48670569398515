import React from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Container, useTheme, Stack, Typography, Box } from "@mui/material";
import { getStatusEffectsToDisplay } from "store/explorer/selectors";
import { updateStatusEffectFilters } from "store/explorer/explorerActions";
import Sort from "../../molecules/Sort";
import TitleAccent from "../../atoms/TitleAccent";
import MutedCenteredText from "components/Origin/atoms/MutedCenteredText";
import fonts from "theme/fonts";
import { removeSpecialCharactersInDescription } from "helpers/cleaning";

const StatusEffects = ({
  statusEffects,
  fetching,
  statusEffectFilters,
  updateStatusEffectFilters,
}) => {
  const theme = useTheme();
  return (
    <>
      {fetching ? (
        <CustomLoader />
      ) : statusEffects.numResults === 0 ? (
        <MutedCenteredText text="No results" />
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
            sx={{ minHeight: 40 }}
          >
            <Sort
              filters={statusEffectFilters}
              updateFilters={updateStatusEffectFilters}
              options={[
                { label: "Name A-Z", value: "NAME_ASC" },
                { label: "Name Z-A", value: "NAME_DESC" },
              ]}
            />
          </Stack>

          <Stack
            gap={10}
            sx={{
              pt: 4,
              pb: 8,
              [theme.breakpoints.down("lg")]: { px: 4 },
              [theme.breakpoints.down("md")]: { px: 1 },
            }}
          >
            <Effects
              effects={statusEffects.buffs}
              title="Buffs"
              description="Positive status effects."
              id="buffs"
            />
            <Effects
              effects={statusEffects.debuffs}
              title="Debuffs"
              description="Negative status effects."
              id="debuffs"
            />
            <Effects
              effects={statusEffects.neutral}
              title="Neutral"
              description="Neutral status effects."
              id="neutral"
            />
          </Stack>
        </Container>
      )}
    </>
  );
};

const Effects = ({ effects, title, description, id }) => {
  const theme = useTheme();
  return effects.length === 0 ? (
    <></>
  ) : (
    <Stack gap={6} id={id}>
      <Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <TitleAccent />
          <Typography
            variant="h2"
            color={theme.palette.colors.origin.wood.backgroundDark}
            fontFamily={fonts.changa}
            fontWeight={500}
          >
            {title}
          </Typography>
        </Stack>
        <Typography
          variant="body1"
          color={theme.palette.colors.origin.wood.background}
        >
          {description}
        </Typography>
      </Stack>
      <Gallery>
        {effects.map((effect) => {
          return (
            <Effect
              key={effect.id}
              name={effect.name}
              description={effect.description}
              icon={`/images/status-effects/${effect.image}`}
            />
          );
        })}
      </Gallery>
    </Stack>
  );
};

const Effect = ({ icon, name, description }) => {
  const theme = useTheme();
  return (
    <Stack
      style={{
        transition: "0.2s transform ease",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.05)",
        },
      }}
    >
      <Stack
        direction="row"
        gap={3}
        sx={{
          "& img": {
            width: 60,
            objectFit: "contain",
            transition: "0.2s transform ease",
            "&:hover": {
              transform: "scale(1.2)",
            },
          },
        }}
      >
        <img src={icon} alt={`${name} - Axie Status Effect`} />
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h3"
              fontSize={24}
              fontFamily={fonts.changa}
              fontWeight={500}
              color={theme.palette.colors.origin.wood.background}
            >
              {name}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            color={theme.palette.colors.origin.wood.background}
          >
            {removeSpecialCharactersInDescription(description)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Gallery = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 48,
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      {children}
    </Box>
  );
};

const mapDispatchToProps = { updateStatusEffectFilters };

const mapStateToProps = (state) => ({
  statusEffects: getStatusEffectsToDisplay(state),
  fetching: state.data.fetchingAxieCardsV3,
  statusEffectFilters: state.explorer.statusEffectFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusEffects);
