import React from "react";
import { Button } from "@mui/material";
import { FaVideo } from "react-icons/fa";

const ReplayButton = ({ replayUrl }) => {
  return (
    <Button
      target="_blank"
      href={replayUrl}
      variant="contained"
      color="primary"
      size="medium"
      startIcon={<FaVideo />}
    >
      Watch Replay
    </Button>
  );
};

export default ReplayButton;
