import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import Card from "components/Card/Card";
import IndesBadge from "../atoms/IndesBadge";
import GuildLogo from "../atoms/GuildLogo";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  height: "100%",
  ".indesBadge": {
    width: 40,
    position: "absolute",
    left: 0,
    top: 0,
    [theme.breakpoints.down("md")]: {
      top: -8,
      left: -8,
    },
  },
}));

const GuildProfile = ({ guild }) => {
  return (
    <Card outline={true}>
      <Wrapper>
        {guild.isIndesCoached === 1 && <IndesBadge />}
        <GuildLogo guild={guild} />
        <Typography
          fontSize="30px"
          color="text.bright"
          fontWeight="700"
          textAlign="center"
          variant="h1"
        >
          {guild.name}
        </Typography>
      </Wrapper>
    </Card>
  );
};

export default GuildProfile;
