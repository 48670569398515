import { Link } from "@mui/material";
import React from "react";

const Linked = ({ url, children }) =>
  url == null ? (
    <>{children}</>
  ) : (
    <Link href={url} target="_blank" underline="none">
      {children}
    </Link>
  );

export default Linked;
