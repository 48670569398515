import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Item from "../molecules/Item";
import Card from "components/Card/Card";
import NoneFound from "../atoms/NoneFound";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
  },
}));

const Items = ({ items }) => {
  // filter 0 quantities from inventory search
  const quantityIsNotZero = items?.filter((item) => item.quantity !== 0);

  return (
    <Card>
      {quantityIsNotZero && quantityIsNotZero.length > 0 ? (
        <Wrapper>
          {quantityIsNotZero.map((item, i) => (
            <Item item={item} key={item.itemId} />
          ))}
        </Wrapper>
      ) : (
        <NoneFound />
      )}
    </Card>
  );
};

export default Items;
