import { Typography } from "@mui/material";
import fonts from "theme/fonts";

const QuickLinkTitle = ({ children }) => (
  <Typography
    fontFamily={fonts.changa}
    fontWeight="500"
    fontSize={30}
    variant="h2"
    color="text.bright"
  >
    {children}
  </Typography>
);

export default QuickLinkTitle;
