import { matchesStr } from "helpers/display";
import { createSelector } from "reselect";

export const getStatusEffectFilters = (state) => {
  return state.explorer?.statusEffectFilters || {};
};

export const getStatusEffects = (state) => {
  return state.data?.axieCardsV3.abilities || [];
};

const applyStatusEffectFilters = (statusEffect, filters) => {
  if (!statusEffect || !filters) {
    return true;
  }

  return (
    matchesStr(statusEffect.name, filters.searchText) ||
    matchesStr(statusEffect.description, filters.searchText)
  );
};

const applySortStatusEffects = (a, b, sortType) => {
  if (sortType === "NAME_DESC") {
    return b.name < a.name ? -1 : a.name < b.name ? 1 : 0;
  }
  return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
};

export const getStatusEffectsToDisplay = createSelector(
  [getStatusEffects, getStatusEffectFilters],
  (statusEffects, filters) => {
    const buffs = [];
    const debuffs = [];
    const neutral = [];
    let numResults = 0;

    statusEffects.forEach((effect) => {
      if (applyStatusEffectFilters(effect, filters)) {
        numResults += 1;
        if (effect.type === "Buff" || effect.type === "Transform/Buff") {
          buffs.push(effect);
        } else if (effect.type === "Debuff") {
          debuffs.push(effect);
        } else if (effect.type === "Neutral") {
          neutral.push(effect);
        }
      }
    });

    return {
      buffs: buffs.sort((a, b) =>
        applySortStatusEffects(a, b, filters.sortType)
      ),
      debuffs: debuffs.sort((a, b) =>
        applySortStatusEffects(a, b, filters.sortType)
      ),
      neutral: neutral.sort((a, b) =>
        applySortStatusEffects(a, b, filters.sortType)
      ),
      numResults,
    };
  }
);
