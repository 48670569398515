import React from "react";
import { Stack, Typography } from "@mui/material";

const Prices = ({ prices, width, lastSold }) => {
  if (
    prices?.usd == null &&
    prices?.eth == null &&
    lastSold?.eth == null &&
    lastSold?.date == null
  ) {
    return <></>;
  }

  return (
    <Stack gap={(0.5 / 320) * width}>
      <Stack gap={(0.2 / 320) * width}>
        {prices?.eth != null && (
          <Typography
            variant="span"
            color="text.regular"
            fontSize={(18 / 320) * width}
            fontWeight={700}
            sx={{ lineHeight: 1 }}
          >
            {prices.eth}
          </Typography>
        )}
        {prices?.usd != null && (
          <Typography
            fontSize={(13 / 320) * width}
            color="text.veryMuted"
            fontWeight={600}
            sx={{ lineHeight: 1 }}
          >
            {prices.usd}
          </Typography>
        )}
      </Stack>

      {lastSold?.eth != null && lastSold?.date != null && (
        <Stack direction="row" alignItems="center" gap={(1.25 / 320) * width}>
          <Stack direction="row" alignItems="center" gap={(0.5 / 320) * width}>
            <Typography
              variant="span"
              color="text.regular"
              fontSize={(13 / 320) * width}
              fontWeight={600}
              sx={{ lineHeight: 1 }}
            >
              Last sold:{" "}
              <Typography variant="span" fontWeight={800} fontSize={14}>
                {lastSold?.eth}
              </Typography>
            </Typography>
            <Typography
              variant="span"
              color="text.muted"
              fontSize={(12 / 320) * width}
              fontWeight={600}
              sx={{ lineHeight: 1 }}
            >
              {lastSold?.usd}
            </Typography>
          </Stack>
          <Typography
            variant="span"
            color="text.secondary"
            fontSize={(12 / 320) * width}
            fontWeight={600}
            sx={{ lineHeight: 1 }}
          >
            {lastSold?.date}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Prices;
