import { Stack } from "@mui/material";
import CustomLoader from "components/CustomLoader/CustomLoader";

const Loading = () => {
  return (
    <Stack sx={{ py: 20 }}>
      <CustomLoader />
    </Stack>
  );
};

export default Loading;
