import React, { useEffect, useState, useCallback } from "react";
import Page from "components/DoLL/Layout/Page";
import SidekickShowcase from "./organisms/SidekickShowcase";
import { connect } from "react-redux";
import {
  fetchDollSidekicks,
  updateControls,
} from "store/doll/sidekicks/actions";
import { getSidekickData } from "store/doll/sidekicks/selectors";
import Controls from "./organisms/Controls";
import CustomLoader from "components/CustomLoader/CustomLoader";
import TraitDialog from "./organisms/TraitDialog";

const Sidekicks = ({ fetchDollSidekicks, loading, data, updateControls }) => {
  const [sidekickToInspect, setSidekickToInspect] = useState(null);

  useEffect(() => {
    fetchDollSidekicks();
  }, [fetchDollSidekicks]);

  const handleUpdateControls = useCallback(
    (key) => (val) => {
      updateControls({ ...data.controls, [key]: val });
    },
    [updateControls, data.controls]
  );

  const handleInspectSidekick = (tokenId) => {
    const sidekick = data.sidekicks.find((s) => s.tokenId === tokenId);
    setSidekickToInspect(sidekick);
  };

  useEffect(() => {
    if (data.controls.currentPage > data.controls.numberOfPages) {
      handleUpdateControls("currentPage")(1);
    }
  }, [
    data.controls.currentPage,
    data.controls.numberOfPages,
    handleUpdateControls,
  ]);

  return (
    <Page
      titleImg="/images/games/doll/sidekicks-title.png"
      titleAlt="Axie DoLL Sidekicks"
      controls={
        <Controls
          controls={data.controls}
          handleUpdateControls={handleUpdateControls}
        />
      }
    >
      {loading ? (
        <CustomLoader variant="doll" marginTop={200} />
      ) : (
        <SidekickShowcase
          controls={data.controls}
          handleUpdateControls={handleUpdateControls}
          sidekicks={data.sidekicks}
          handleInspectSidekick={handleInspectSidekick}
        />
      )}

      {sidekickToInspect && (
        <TraitDialog
          open={sidekickToInspect != null}
          handleClose={() => setSidekickToInspect(null)}
          sidekick={sidekickToInspect}
        />
      )}
    </Page>
  );
};

const mapDispatchToProps = {
  fetchDollSidekicks,
  updateControls,
};

const mapStateToProps = (state) => ({
  data: getSidekickData(state),
  loading: state.doll.sidekicks.fetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidekicks);
