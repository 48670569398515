import React, { useState } from "react";
import Item from "./Item";
import CardTemplate from "components/GameTemplates/CardTemplate";
import CardCompareIcon from "components/GameTemplates/CardCompareIcon";
import { makeMarketplaceUrlForItem } from "helpers/items";

export const WrappedCard = ({ card, favoriteCard, cardsV2 }) => {
  const [displayV2, setDisplayV2] = useState(false);
  const cardV2 = cardsV2
    ? cardsV2.find((c) => c.partId === card.partId)
    : undefined;

  const handleOnMouseEnter = () => {
    setDisplayV2(true);
  };

  const handleOnMouseLeave = () => {
    setDisplayV2(false);
  };

  return (
    <Item
      handleClick={() => favoriteCard(card)}
      isFavorite={card.isFavorite}
      itemType="CARD"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      href={makeMarketplaceUrlForItem("card", card.partId)}
    >
      <CardCompareIcon card={cardV2} display={displayV2} version="V2" />
      <CardTemplate card={card} width={220} />
    </Item>
  );
};

export default WrappedCard;
