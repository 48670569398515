import Project from "../molecules/Project";
import { Box, useTheme } from "@mui/material";
import data from "../data";
import { sortByStatus } from "../utils";

const Projects = ({ statuses, platforms, gameTypes }) => {
  const theme = useTheme();
  // prioritise games with tools in page order, but apply same status sorting
  const hasTools = data.filter((game) => game.tools);
  const noTools = data.filter((game) => !game.tools);

  const filterProject = (p) => {
    if (
      (statuses.includes(p.status) || statuses.length === 0) &&
      (platforms.some((el) => p.platforms.includes(el)) ||
        platforms.length === 0) &&
      (gameTypes.includes(p.gameType) || gameTypes.length === 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 3,
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      {hasTools
        .filter((p) => filterProject(p))
        .sort((a, b) => sortByStatus(a, b, true))
        .map((project) => (
          <Project project={project} key={project.id} />
        ))}
      {noTools
        .filter((p) => filterProject(p))
        .sort((a, b) => sortByStatus(a, b, true))
        .map((project) => (
          <Project project={project} key={project.id} />
        ))}
    </Box>
  );
};

export default Projects;
