import React from "react";
import { Typography } from "@mui/material";

const Label = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontSize={15}
      color="text.muted"
      fontWeight="600"
      sx={{ lineHeight: 1.4 }}
    >
      {children}
    </Typography>
  );
};

export default Label;
