import React from "react";
import { Stack, Typography } from "@mui/material";
import StarChange from "../molecules/StarChange";
import SLPWon from "../atoms/SLPWon";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  alignItems: "center",
  flexWrap: "wrap",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ExpandedPlayerHeader = ({ name, slpWon, mmrBefore, mmrAfter }) => {
  if (name == null && slpWon == null && mmrBefore == null && mmrAfter == null) {
    return null;
  }
  return (
    <Wrapper>
      <Stack direction="row" gap={1} alignItems="center">
        {name != null && (
          <Typography
            fontWeight="700"
            color="text.bright"
            fontSize="22px"
            variant="span"
          >
            {name}
          </Typography>
        )}
        <SLPWon amount={slpWon} />
      </Stack>

      <StarChange mmrBefore={mmrBefore} mmrAfter={mmrAfter} />
    </Wrapper>
  );
};

export default ExpandedPlayerHeader;
