import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { BiImport } from "react-icons/bi";
import { connect } from "react-redux";
import { importScholars } from "store/guildDashboard/actions/guildDashboardActions";
import Label from "components/Typography/Label";
import Error from "components/Typography/Error";
import LoadingButton from "@mui/lab/LoadingButton";

const ScholarImport = ({ scholar }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Typography
        variant="span"
        fontSize="13px"
        fontWeight="600"
        color="text.primary"
      >
        {scholar.name}:
      </Typography>
      <Stack
        alignItems="center"
        sx={{
          borderRadius: "4px",
          p: "4px 8px",
          background: theme.palette.card.shade2,
        }}
      >
        <Typography
          variant="span"
          fontSize="13px"
          fontWeight="600"
          color="text.bright"
          sx={{ fontFamily: "monospace" }}
        >
          {scholar.roninAddress}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ImportScholarsDialog = ({
  open,
  setOpen,
  importScholars,
  guild,
  validScholars,
  invalidScholars,
}) => {
  const { account } = useWeb3React();
  const [error, setError] = useState("");

  const handleClose = useCallback(() => {
    setError("");
    setOpen(false);
  }, [setError, setOpen]);

  useEffect(() => {
    if (
      guild.importingScholars === false &&
      guild.failedToImportScholars === true
    ) {
      setError("Something went wrong...");
    } else if (
      guild.importingScholars === false &&
      (guild.failedToImportScholars === false || !guild.failedToImportScholars)
    ) {
      handleClose();
    }
  }, [guild.importingScholars, guild.failedToImportScholars, handleClose]);

  const handleImportAllScholars = () => {
    importScholars(account, guild.logoURI, validScholars);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack gap="6px" direction="row" alignItems="center">
          <Typography
            fontSize="20px"
            color="text.bright"
            sx={{ position: "relative", top: "2px" }}
          >
            <BiImport />
          </Typography>
          Import Scholars
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Error>{error}</Error>
          {validScholars.length > 0 && (
            <Stack gap={2}>
              <Label>
                {validScholars.length} Scholar
                {`${validScholars.length === 1 ? "" : "s"}`} to be imported
              </Label>
              <Stack gap={1}>
                {validScholars.map((scholar, i) => (
                  <ScholarImport
                    scholar={scholar}
                    key={`INVALID_SCHOLAR_${i}`}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {invalidScholars.length > 0 && (
            <Stack gap={2}>
              <Label>
                {invalidScholars.length} Duplicate Scholar
                {`${invalidScholars.length === 1 ? "" : "s"}`} – will not be
                imported
              </Label>
              <Stack gap={1}>
                {invalidScholars.map((scholar, i) => (
                  <ScholarImport
                    scholar={scholar}
                    key={`INVALID_SCHOLAR_${i}`}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined">
          Cancel
        </Button>
        {validScholars.length === 0 ? (
          <></>
        ) : guild.importingScholars ? (
          <LoadingButton
            variant="contained"
            loading
            sx={{ alignSelf: "stretch" }}
          />
        ) : (
          <Button onClick={handleImportAllScholars} variant="contained">
            Import All
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = { importScholars };

const mapStateToProps = (state) => ({ guild: state.guildDashboard.guild });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportScholarsDialog);
