import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Box } from "@mui/material";
import { NUM_BREEDING_SIMULATOR_RONIN_AXIES_PER_PAGE } from "config";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import {
  addBreedingAxieId,
  updateCurrentPageSelectAxiesDialog,
  updateBreedCountSelectAxiesDialog,
  updatePuritySelectAxiesDialog,
  updateAxieClassesSelectAxiesDialog,
  updateSelectAllSelectAxiesDialog,
  selectRoninAxie,
  deselectAllRoninAxies,
} from "store/breedingSimulator/breedingSimulatorActions";
import { connect } from "react-redux";
import * as breedingSelectors from "store/breedingSimulator/breedingSelectors";
import { Pagination, useTheme, Button, Stack } from "@mui/material";
import SelectAllButtons from "./SelectAllButtons";
import LoadingDialog from "./LoadingDialog";
import ErrorDialog from "./ErrorDialog";
import SelectAxiesDialogTitle from "./SelectAxiesDialogTitle";
import FilterAxieClass from "components/Axie/Controls/FilterAxieClass";
import FilterAxieBreedCount from "components/Axie/Controls/FilterAxieBreedCount";
import FilterAxiePurity from "components/Axie/Controls/FilterAxiePurity";

const SelectAxiesDialog = ({
  open,
  setOpen,
  axiesFromRonin,
  loadingFromRonin,
  addBreedingAxieId,
  currentPage,
  updateCurrentPage,
  breedingIds,
  breedCount,
  updateBreedCount,
  purity,
  updatePurity,
  axieClasses,
  updateAxieClasses,
  selectAll,
  updateSelectAll,
  selectRoninAxie,
  selectedRoninAxieIds,
  selectedRoninAxies,
  deselectAllRoninAxies,
  axieData,
  validRoninAddresses,
  validRoninAddressesWithAxies,
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (axiesFromRonin.axies.length === 0) {
      updateCurrentPage(1);
    }
  }, [axiesFromRonin.axies, updateCurrentPage]);

  const handleClose = () => {
    setOpen(false);
    handleClearFilters();
    deselectAllRoninAxies();
  };

  const handleOK = (e) => {
    setOpen(false);
    handleClearFilters();
    deselectAllRoninAxies();
    handleDoneSelectingRoninAxies();
  };

  const handleClearFilters = () => {
    updateBreedCount([]);
    updatePurity([]);
    updateAxieClasses([]);
  };

  const handleClearSelection = () => {
    deselectAllRoninAxies();
  };

  const handleDoneSelectingRoninAxies = () => {
    selectedRoninAxies.forEach((axie) => {
      if (!breedingIds.includes(axie.axieId)) {
        addBreedingAxieId(axie.axieId);
      }
    });
    setOpen(false);
  };

  const handlePageChange = (e, value) => {
    updateCurrentPage(value);
  };

  const numberOfPages = axiesFromRonin.numberOfFilteredAxies
    ? Math.ceil(
        axiesFromRonin.numberOfFilteredAxies /
          NUM_BREEDING_SIMULATOR_RONIN_AXIES_PER_PAGE
      )
    : 1;

  if (loadingFromRonin && axiesFromRonin.totalNumberOfAxies === 0) {
    return <LoadingDialog open={open} handleClose={handleClose} />;
  } else if (!loadingFromRonin && axiesFromRonin.totalNumberOfAxies === 0) {
    return (
      <ErrorDialog
        open={open}
        handleClose={handleClose}
        validRoninAddresses={validRoninAddresses}
      />
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      fullScreen={true}
      aria-labelledby="select-axies-dialog-title"
    >
      <SelectAxiesDialogTitle
        total={axiesFromRonin.totalNumberOfAxies}
        selected={selectedRoninAxieIds.length}
        validRoninAddresses={validRoninAddresses}
        validRoninAddressesWithAxies={validRoninAddressesWithAxies}
        loading={loadingFromRonin}
      />

      <DialogContent dividers={true}>
        <Stack gap={2}>
          <Stack gap={3}>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              gap={2}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  justifyContent: "flex-start",
                },
              }}
            >
              <Box
                sx={{
                  width: "130px",
                  [theme.breakpoints.down("sm")]: { width: "100%" },
                }}
              >
                <FilterAxieClass
                  axieClasses={axieClasses}
                  setAxieClasses={updateAxieClasses}
                />
              </Box>
              <Box
                sx={{
                  width: "130px",
                  [theme.breakpoints.down("sm")]: { width: "100%" },
                }}
              >
                <FilterAxieBreedCount
                  breedCount={breedCount}
                  setBreedCount={updateBreedCount}
                />
              </Box>
              <Box
                sx={{
                  width: "130px",
                  [theme.breakpoints.down("sm")]: { width: "100%" },
                }}
              >
                <FilterAxiePurity purity={purity} setPurity={updatePurity} />
              </Box>

              <SelectAllButtons
                selectAll={selectAll}
                updateSelectAll={updateSelectAll}
                filteredAxies={axiesFromRonin.filteredAxies}
              />
            </Stack>

            <Stack justifyContent="center" gap={3} direction="row">
              <Button
                variant="text"
                size="small"
                onClick={() => handleClearFilters()}
              >
                Clear Filters
              </Button>
              <Button
                variant="text"
                size="small"
                onClick={() => handleClearSelection()}
              >
                Clear Selected Axies
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
            justifyContent="center"
            sx={{
              margin: "0 0 24px 0",
              "& > *:hover": {
                cursor: "pointer",
              },
            }}
          >
            {axiesFromRonin.axies.map((roninAxie) => {
              const additionalData = axieData[roninAxie.axieId]
                ? axieData[roninAxie.axieId]
                : {};
              return (
                <AxieV3
                  axie={{ ...roninAxie, ...additionalData }}
                  address={additionalData.ownerRonin}
                  disableMarketplaceLink={true}
                  onClick={() => selectRoninAxie(roninAxie)}
                  key={`RONIN_AXIE_${roninAxie.axieId}`}
                  selected={selectedRoninAxieIds.includes(roninAxie.axieId)}
                  variant="full"
                />
              );
            })}
          </Stack>

          {numberOfPages > 1 && (
            <Pagination
              shape="rounded"
              onChange={handlePageChange}
              count={numberOfPages}
              page={currentPage}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleOK}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  addBreedingAxieId,
  updateCurrentPage: updateCurrentPageSelectAxiesDialog,
  updateBreedCount: updateBreedCountSelectAxiesDialog,
  updatePurity: updatePuritySelectAxiesDialog,
  updateAxieClasses: updateAxieClassesSelectAxiesDialog,
  updateSelectAll: updateSelectAllSelectAxiesDialog,
  selectRoninAxie,
  deselectAllRoninAxies,
};

const mapStateToProps = (state) => ({
  loadingFromRonin: state.breedingSimulator.loadingFromRonin,
  axiesFromRonin: breedingSelectors.getAxiesFromRoninToDisplay(state),
  currentPage: state.breedingSimulator.currentPageSelectAxiesDialog,
  breedingIds: state.breedingSimulator.ids,
  breedCount: state.breedingSimulator.breedCountSelectAxiesDialog,
  purity: state.breedingSimulator.puritySelectAxiesDialog,
  axieClasses: state.breedingSimulator.axieClassesSelectAxiesDialog,
  selectAll: state.breedingSimulator.selectAllSelectAxiesDialog,
  selectedRoninAxieIds: state.breedingSimulator.selectedRoninAxieIds,
  selectedRoninAxies: state.breedingSimulator.selectedRoninAxies,
  axieData: state.breedingSimulator.axieData,
  validRoninAddresses: state.breedingSimulator.validRoninAddresses,
  validRoninAddressesWithAxies:
    state.breedingSimulator.validRoninAddressesWithAxies,
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectAxiesDialog);
