import React from "react";
import WidgetTitle from "../atoms/WidgetTitle";
import { IoTimer } from "react-icons/io5";
import { Stack } from "@mui/material";
import NextWithdrawableItem from "./NextWithdrawableItem";
import NoneFoundMessage from "components/Typography/NoneFoundMessage";

const UpcomingMintableWidget = ({ data = [], title }) => {
  return (
    <Stack gap={2}>
      <WidgetTitle
        text={title || "Upcoming Mintable Assets"}
        icon={<IoTimer />}
      />
      {data.length > 0 ? (
        data.map((topMints, i) => (
          <NextWithdrawableItem
            key={i}
            id={i + 1}
            itemImage={topMints.image}
            itemName={topMints.name}
            itemRarity={topMints.rarity}
            itemClass={topMints.class}
            nextWithdrawTime={topMints.nextWithdrawTime}
            ownerName={topMints.ownerName}
            ownerRonin={topMints.roninAddress}
          />
        ))
      ) : (
        <Stack py={2}>
          <NoneFoundMessage fontSize={17}>No items found</NoneFoundMessage>
        </Stack>
      )}
    </Stack>
  );
};

export default UpcomingMintableWidget;
