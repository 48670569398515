import { Box, useMediaQuery, useTheme } from "@mui/material";
import TooltipWrapper from "./TooltipWrapper";
import React from "react";

const ClickableItem = ({ children, onClick, isCollected, tooltip }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const conditionalStyles = smDown
    ? {}
    : {
        filter: isCollected
          ? `drop-shadow(0 0 4px ${theme.palette.colors.origin.wood.background})`
          : "none",
        "&:hover": {
          opacity: 1,
          transform: "scale(1.5)",
          cursor: "pointer",
          zIndex: 5,
        },
        "&:active": {
          transform: "scale(1.4)",
        },
      };

  return (
    <TooltipWrapper tooltip={tooltip}>
      <Box
        sx={{
          transition: "0.15s all ease",
          opacity: isCollected ? 1 : 0.3,
          position: "relative",
          ...conditionalStyles,
        }}
        onClick={onClick}
      >
        {children}
      </Box>
    </TooltipWrapper>
  );
};

export default ClickableItem;
