import Overview from "pages/GuildDashboard/pages/Overview";
import Scholars from "pages/GuildDashboard/pages/Scholars";
import Axies from "pages/GuildDashboard/pages/Axies";
import Teams from "pages/GuildDashboard/pages/Teams";
import Team from "pages/GuildDashboard/pages/Teams/molecules/Team";
import Scholar from "pages/GuildDashboard/pages/Scholar";
import Guild from "pages/Guild";
import Changelog from "pages/Changelog/Changelog";
import CardExplorerV2 from "pages/CardExplorer/CardExplorer";
import Explorer from "pages/Explorer";
import TermsAndConditions from "pages/About/TermsAndConditions";
import PrivacyPolicy from "pages/About/PrivacyPolicy";
import AutopayoutLog from "pages/GuildDashboard/pages/AutopayoutLog";
import TeamBuilder from "pages/TeamBuilder";
import Games from "pages/Games";
import CraftingCollection from "pages/CraftingCollection/CraftingCollection";
import Admin from "pages/GuildDashboard/pages/Admin/Admin";
import Home from "pages/Home";
import Page404 from "pages/Page404/Page404";
import GuildMembership from "pages/GuildMembership/GuildMembership";
import ClassicLeaderboard from "pages/Leaderboards/Classic";
import Player from "pages/Player";
import RecentlyListed from "pages/Recently/Listed/Listed";
import RecentlySold from "pages/Recently/Sold/Sold";
import BreedingCosts from "pages/BreedingCosts/BreedingCosts";
import GuildLeaderboard from "pages/Leaderboards/Guild";
import GuildBattleLeaderboard from "pages/Leaderboards/GuildBattle";
import BreedingCalculator from "pages/BreedingCalculator/BreedingCalculator";
import Inspector from "pages/Inspector/Inspector";
import BreedingSimulator from "pages/BreedingSimulator/BreedingSimulator";
import GuildDashboard from "pages/GuildDashboard/GuildDashboard";
import Inventory from "./GuildDashboard/pages/Inventory";
import OriginLeaderboard from "pages/Leaderboards/Origin";
import Charts from "pages/Charts";
import { default as DoLLLeaderboard } from "./DoLL/Leaderboard";
import { default as DoLLSidekicks } from "./DoLL/Sidekicks";
import { default as DoLLCardExplorer } from "./DoLL/CardExplorer";
import { default as AxieQuestExplorer } from "./AxieQuest/Explorer";
import { default as InterDungeonExplorer } from "./InterDungeon/Explorer";
import Support from "pages/Support";

export const Pages = {
  Overview,
  Scholars,
  Axies,
  Teams,
  Team,
  Inventory,
  Scholar,
  Guild,
  Changelog,
  CardExplorerV2,
  Explorer,
  TermsAndConditions,
  PrivacyPolicy,
  AutopayoutLog,
  TeamBuilder,
  Games,
  CraftingCollection,
  Admin,
  Home,
  Page404,
  GuildMembership,
  ClassicLeaderboard,
  Player,
  RecentlyListed,
  RecentlySold,
  BreedingCosts,
  GuildLeaderboard,
  GuildBattleLeaderboard,
  BreedingCalculator,
  Inspector,
  BreedingSimulator,
  GuildDashboard,
  OriginLeaderboard,
  Charts,
  DoLLLeaderboard,
  DoLLSidekicks,
  DoLLCardExplorer,
  AxieQuestExplorer,
  InterDungeonExplorer,
  Support,
};
