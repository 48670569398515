import React from "react";
import { Stack } from "@mui/material";

const ClassIcon = ({ axieClassName = "" }) => {
  return (
    <Stack
      sx={{
        zIndex: 5,
        position: "absolute",
        bottom: "4%",
        height: "5%",
        left: "50%",
        transform: "translateX(-50%)",
        m: "0 auto",
        "& img": {
          height: "100%",
          width: "auto",
        },
      }}
    >
      <img
        src={`/images/templates/card/class-icons/${axieClassName.toLowerCase()}.png`}
        alt={`${axieClassName} class card`}
      />
    </Stack>
  );
};

export default ClassIcon;
