import React from "react";
import { FaEnvelope } from "react-icons/fa";
import styles from "./email-signup.module.scss";
import {
  Typography,
  useTheme,
  Container,
  Stack,
  Grid,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const EmailSignup = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div
      className={styles.emailSignup}
      style={{
        backgroundImage:
          theme.palette.mode === "light"
            ? "url(/images/logo-background-light.jpg)"
            : "url(/images/logo-background-dark.jpg)",
      }}
    >
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Stack alignItems="flex-start" gap={2}>
              <Stack direction="row" alignItems="center" gap={1.5}>
                <Typography
                  variant="h6"
                  fontSize="30px"
                  fontWeight="700"
                  sx={{
                    display: "block",
                    lineHeight: 1.3,
                    background: theme.palette.text.gradient,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Be the first to know about our next launch
                </Typography>
              </Stack>
              <Typography
                variant="body1"
                fontWeight="500"
                sx={{ lineHeight: "1.5" }}
                color={theme.palette.text.regular}
              >
                We would love to send you an occasional email whenever we have
                something exciting to share.
              </Typography>
              <Button
                size="large"
                variant="contained"
                target="_blank"
                href="https://tribally.substack.com/"
                endIcon={<FaEnvelope />}
              >
                Subscribe
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <div
        className={styles.bannerWrapper}
        style={{
          backgroundColor: theme.palette.card.background,
          boxShadow:
            theme.palette.mode === "light"
              ? "0px 0px 40px rgba(0, 0, 0, 0.1)"
              : "0px 0px 40px #131e24",
          padding: smUp ? "16px" : "4px",
        }}
      >
        <img
          className={styles.bannerImage}
          src="/images/footer-banner.jpg"
          alt="Axie Tech"
        />
      </div>
    </div>
  );
};

export default EmailSignup;
