import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import { ARENA_SEASONS } from "config";

const FilterSeason = ({ value, setValue, style }) => {
  return value != null && setValue != null ? (
    <CustomMultiSelect
      label="Season"
      value={value}
      setValue={setValue}
      options={ARENA_SEASONS.map((s) => s.label)}
      style={style}
    />
  ) : (
    <></>
  );
};
export default FilterSeason;
