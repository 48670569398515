import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-7-5</Date>
      <Change type="new">
        Added
        <CustomLink to="/explorer/revenge-cards" endSpace={false}>
          Revenge Cards to the Explorer
        </CustomLink>
        .
      </Change>
      <Change type="bug">
        The VIP Guild Dashboard autopayout log now correctly displays recent
        payout details.
      </Change>
    </Entry>
  );
};

export default Changes;
