import React from "react";
import { connect } from "react-redux";
import { getFavoriteToolsToDisplay } from "store/explorer/selectors";
import { favoriteTool } from "store/explorer/explorerActions";
import Section from "../../molecules/FavoritesSection";
import WrappedCard from "pages/Explorer/molecules/WrappedCard";

const Tools = ({ favoriteToolsToDisplay, favoriteTool }) => {
  return (
    <Section type="Tool" numItems={favoriteToolsToDisplay.length}>
      {favoriteToolsToDisplay.map((card) => (
        <WrappedCard key={card.id} favoriteCard={favoriteTool} card={card} />
      ))}
    </Section>
  );
};

const mapDispatchToProps = { favoriteTool };

const mapStateToProps = (state) => ({
  favoriteToolsToDisplay: getFavoriteToolsToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
