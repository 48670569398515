import { useState } from "react";
import { Grid, Stack } from "@mui/material";
import ArenaChartWrapper from "../molecules/ArenaChart";
import CustomLoader from "components/CustomLoader/CustomLoader";
import BattlesNotFunctional from "components/BattleLog/atoms/BattlesNotFunctional";
import FavoriteTeam from "./FavoriteTeam";
import RecentBattles from "../molecules/RecentBattles";
import { VictoryStarDataPoint, Card, BattleAxie, BattleSummary } from "types";

interface Props {
  favoriteTeams: BattleAxie[][];
  victoryStarChartData: VictoryStarDataPoint[];
  fetchingBattles: boolean;
  cardsV3: Card[];
  recentBattleSummary: BattleSummary;
  setActiveTab: (newValue: number) => void;
}

const Profile = ({
  favoriteTeams,
  victoryStarChartData,
  fetchingBattles,
  cardsV3,
  recentBattleSummary,
  setActiveTab,
}: Props) => {
  const [showAxieDetail, setShowAxieDetail] = useState(false);

  if (favoriteTeams.length === 0 && fetchingBattles) {
    return <CustomLoader />;
  }

  if (favoriteTeams.length === 0 && !fetchingBattles) {
    return <BattlesNotFunctional />;
  }

  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <FavoriteTeam
          favoriteTeams={favoriteTeams}
          cardsV3={cardsV3}
          showAxieDetail={showAxieDetail}
          setShowAxieDetail={setShowAxieDetail}
        />
        <RecentBattles
          recentBattleSummary={recentBattleSummary}
          setActiveTab={setActiveTab}
          showAxieDetail={showAxieDetail}
        />
      </Grid>
      <ArenaChartWrapper data={victoryStarChartData} />
    </Stack>
  );
};

export default Profile;
