import { AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES } from "config";
import { getMarketplaceCompatibleCards } from "helpers/items";
import { createSelector } from "reselect";
import {
  getFavoriteCards,
  getFavoriteCharms,
  getFavoriteRunes,
  getFavoriteCurses,
  getFavoriteTools,
  getFavoriteRevengeCards,
} from "./";

export const itemIsFavorite = (item, id, favorites, secondaryId) => {
  return (
    favorites.find((favorite) => {
      if (item[id] != null) {
        return favorite[id] === item[id];
      } else {
        return favorite[secondaryId] === item[secondaryId];
      }
    }) != null
  );
};

export const getShareLink = createSelector(
  [
    getFavoriteCards,
    getFavoriteCharms,
    getFavoriteRunes,
    getFavoriteCurses,
    getFavoriteTools,
    getFavoriteRevengeCards,
  ],
  (
    favoriteCards,
    favoriteCharms,
    favoriteRunes,
    favoriteCurses,
    favoriteTools,
    favoriteRevengeCards
  ) => {
    let start = true;
    let link = "https://axie.tech/explorer/favorites";
    if (favoriteCards.length !== 0) {
      start = false;
      link +=
        "?cards=" + favoriteCards.map((favorite) => favorite.partId).join(",");
    }

    if (favoriteRunes.length !== 0) {
      if (start) {
        link += "?";
        start = false;
      } else {
        link += "&";
      }
      link +=
        "runes=" + favoriteRunes.map((favorite) => favorite.name).join(",");
    }

    if (favoriteCharms.length !== 0) {
      if (start) {
        link += "?";
        start = false;
      } else {
        link += "&";
      }
      link +=
        "charms=" + favoriteCharms.map((favorite) => favorite.name).join(",");
    }

    if (favoriteCurses.length !== 0) {
      if (start) {
        link += "?";
        start = false;
      } else {
        link += "&";
      }
      link +=
        "curses=" + favoriteCurses.map((favorite) => favorite.name).join(",");
    }

    if (favoriteTools.length !== 0) {
      if (start) {
        link += "?";
        start = false;
      } else {
        link += "&";
      }
      link +=
        "tools=" + favoriteTools.map((favorite) => favorite.name).join(",");
    }

    if (favoriteRevengeCards.length !== 0) {
      if (start) {
        link += "?";
        start = false;
      } else {
        link += "&";
      }
      link +=
        "revenge-cards=" +
        favoriteRevengeCards.map((favorite) => favorite.name).join(",");
    }

    return encodeURI(link);
  }
);

export const getMarketplaceSearchLink = createSelector(
  [getFavoriteCards],
  (favoriteCards) => {
    const baseStem = AXIE_INFINITY_WEBSITE_MARKETPLACE_AXIES;
    if (favoriteCards.length === 0) {
      return baseStem;
    }
    const shareLink = baseStem + "?auctionTypes=Sale&parts=";
    const marketplaceCompatible = getMarketplaceCompatibleCards(favoriteCards);
    const partIds = marketplaceCompatible.map((favorite) => favorite.partId);
    return shareLink + partIds.join("&parts=");
  }
);

export const calculateNumberOfFavorites = createSelector(
  [
    getFavoriteCards,
    getFavoriteRunes,
    getFavoriteCharms,
    getFavoriteCurses,
    getFavoriteTools,
    getFavoriteRevengeCards,
  ],
  (
    favoriteCards,
    favoriteRunes,
    favoriteCharms,
    favoriteCurses,
    favoriteTools,
    favoriteRevengeCards
  ) => {
    return (
      favoriteCards.length +
      favoriteRunes.length +
      favoriteCharms.length +
      favoriteCurses.length +
      favoriteTools.length +
      favoriteRevengeCards.length
    );
  }
);
