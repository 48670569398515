import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-7-12</Date>
      <Change type="new">
        Launched a<CustomLink to="/craftingdex">CraftingDex</CustomLink>
        to help you keep track of which runes and charms you have collected!
      </Change>
    </Entry>
  );
};

export default Changes;
