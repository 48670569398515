import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import DropdownSection from "./DropdownSection";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})(({ theme, isMobile }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: 32,
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.text.veryVeryMuted}`,

  ...(isMobile && {
    gridTemplateColumns: "1fr",
    gridGap: "16px",
    borderTop: "none",
  }),
}));

const DropdownSections = ({
  secondaryItems,
  colorOverrides,
  isMobile,
  onClick,
}) => {
  return (
    <Wrapper isMobile={isMobile}>
      {secondaryItems.map((item, i) => (
        <DropdownSection
          item={item}
          colorOverrides={colorOverrides}
          key={`SECONDARY_${i}`}
          onClick={onClick}
        />
      ))}
    </Wrapper>
  );
};

export default DropdownSections;
