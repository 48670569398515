import React from "react";
import {
  Typography,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import CopyAddress from "components/Typography/CopyAddress";
import { makeScholarPageURLFromRoninAddress } from "helpers/guild";
import { DATE_TIME_FORMAT } from "config";
import moment from "moment";
import RarityTag from "components/Items/RarityTag";

const Wrapper = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "20px 27px 20% 27px 16% 13% 15% 10%",
  gap: 2,
  alignItems: "center",
  justifyContent: "flex-start",

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "6px",
  },
}));

const NextWithdrawableItem = ({
  id,
  itemName = "",
  itemRarity = "",
  itemClass = "",
  itemImage = "",
  ownerName = "",
  ownerRonin = "",
  nextWithdrawTime,
}) => {
  const theme = useTheme();
  const smallMode = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Wrapper>
      <Typography
        variant="span"
        fontWeight={800}
        fontSize={12}
        color="text.muted"
      >
        {id}
      </Typography>
      <Tooltip title={itemName}>
        <img width="20px" src={itemImage} alt={itemName} />
      </Tooltip>
      <Stack gap={1}>
        <Typography
          variant="span"
          fontWeight={800}
          fontSize={12}
          color="text.bright"
        >
          {itemName}
        </Typography>
        <RarityTag
          rarity={itemRarity}
          style={smallMode ? { justifyContent: "center" } : {}}
        />
      </Stack>

      <Tooltip title={`${itemClass}`}>
        <img
          width="20px"
          src={`/images/class-icons/${itemClass.toLowerCase()}.png`}
          alt={`${itemClass} class card`}
        />
      </Tooltip>

      {(nextWithdrawTime ?? "") !== "" && (
        <Tooltip
          title={moment.utc(nextWithdrawTime).local().format(DATE_TIME_FORMAT)}
        >
          <Typography
            variant="span"
            fontWeight={800}
            fontSize={12}
            color="text.bright"
          >
            {moment.utc(nextWithdrawTime).fromNow()}
          </Typography>
        </Tooltip>
      )}
      <Tooltip title="Go To Player Guild Stats">
        <RouterLink
          to={makeScholarPageURLFromRoninAddress(ownerRonin)}
          state={{
            prevPath: "/guild-dashboard/inventory",
          }}
        >
          <Typography
            variant="span"
            fontWeight={600}
            fontSize={13}
            color="text.bright"
            sx={{
              lineHeight: 1.4,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              "&:hover": {
                opacity: "75%",
              },
            }}
          >
            {ownerName}
          </Typography>
        </RouterLink>
      </Tooltip>
      <CopyAddress
        address={ownerRonin}
        disableHover={true}
        destinationTab="runes-charms"
      />
    </Wrapper>
  );
};

export default NextWithdrawableItem;
