import React from "react";
import * as statIcons from "components/Axie/icons/statIcons";
import { Stack, useTheme, Typography } from "@mui/material";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import { axieIsAdult } from "helpers/axie";

const StatText = ({ children }) => (
  <Typography
    variant="span"
    fontSize="14px"
    fontWeight="600"
    color="text.regular"
  >
    {children}
  </Typography>
);

const Stat = ({ name, axie, width }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      sx={{
        "& svg": {
          height: "13px",
          width: "auto",
        },
      }}
    >
      <IconWithText name={name} axie={axie} />
      <CustomLinearProgress
        value={(axie.stats[name] / 61) * 100}
        color={theme.palette.colors.axie[name]}
        width={width}
      />
    </Stack>
  );
};

const IconWithText = ({ name, axie }) => (
  <Stack
    direction="row"
    gap={0.25}
    alignItems="center"
    sx={{
      "& svg": {
        height: "13px",
        width: "auto",
      },
    }}
  >
    {statIcons[name]()}
    <StatText>{axie.stats[name]}</StatText>
  </Stack>
);

const Stats = ({ axie, variant, width }) => {
  if (axie.stats == null || !axieIsAdult(axie)) {
    return <></>;
  }
  if (variant === "tiny") {
    return (
      <Stack direction="row" gap={0.75} sx={{ mt: -1, mb: 1 }}>
        <IconWithText name="hp" axie={axie} />
        <IconWithText name="speed" axie={axie} />
        <IconWithText name="skill" axie={axie} />
        <IconWithText name="morale" axie={axie} />
      </Stack>
    );
  }
  return (
    <Stack gap={1}>
      <Stat name="hp" axie={axie} width={width} />
      <Stat name="speed" axie={axie} width={width} />
      <Stat name="skill" axie={axie} width={width} />
      <Stat name="morale" axie={axie} width={width} />
    </Stack>
  );
};

export default Stats;
