import React from "react";
import Controls from "./Controls";
import { Stack } from "@mui/material";
import GuildAvatar from "../atoms/GuildAvatar";
import GuildName from "../atoms/GuildName";

const SidebarHeader = ({ guild }) => (
  <Stack sx={{ p: "0 32px 8px 32px" }} gap={1} alignItems="center">
    <GuildAvatar
      name={guild.name}
      imageSlug={guild.profileImage}
      pageSlug={guild.logoURI}
    />
    <GuildName name={guild.name} />
    <Controls guild={guild} />
  </Stack>
);

export default SidebarHeader;
