import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { MdInfo } from "react-icons/md";
import moment from "moment";
import { DATE_TIME_FORMAT } from "config";

const AsAtDateArena = ({ asAt }) => {
  if (!asAt) {
    return null;
  }
  const asAtMinus1Day = moment(asAt).subtract(1, "day");
  const midnightSnapshot = moment.utc(
    moment(asAtMinus1Day).format("YYYY-MM-DDT23:59:59")
  );
  const formattedMidnightSnapshot = moment(midnightSnapshot)
    .local()
    .format(DATE_TIME_FORMAT);

  return (
    <Tooltip title={`Avg victory stars as at ${formattedMidnightSnapshot}`}>
      <IconButton
        size="small"
        sx={{
          padding: "0px",
          transition: "0.2s all ease",
          opacity: 0.5,
          "&:hover": { opacity: 1 },
        }}
      >
        <MdInfo />
      </IconButton>
    </Tooltip>
  );
};

export default AsAtDateArena;
