import * as types from "./dataActionTypes";
import { put, call, take, spawn, fork, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./dataActions";
import { channel } from "redux-saga";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* watchFetchPlayerData() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchPlayerData, chan);
  }

  while (true) {
    const { roninAddress } = yield take(types.FETCH_PLAYER_DATA);
    yield put(chan, roninAddress);
  }
}

export function* makeFetchPlayerData(chan) {
  try {
    while (true) {
      const roninAddress = yield take(chan);
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestPlayer,
        roninAddress
      );
      yield put(actions.fetchPlayerDataSucceeded(roninAddress, data));
    }
  } catch (e) {
    console.log("Error fetching player data");
  }
}

export default function* playerDataSaga() {
  yield spawn(watchFetchPlayerData);
}
