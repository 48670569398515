import React from "react";
import { Stack, Typography } from "@mui/material";
import Status from "../atoms/Status";
import Linked from "../atoms/Linked";
import GameType from "../atoms/GameType";

const Header = ({
  title,
  tagline,
  status,
  linkTo,
  gameType,
  fontSize,
  direction,
  gap,
}) => {
  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <Stack
          gap={{ xs: 0.5, sm: 0.5, md: gap ? gap : 0 }}
          direction={{
            xs: "column",
            sm: "column",
            md: direction ? direction : "row",
          }}
          justifyContent={{
            xs: "flex-start",
            sm: "flex-start",
            md: "space-between",
          }}
          alignItems="flex-start"
        >
          <Linked url={linkTo}>
            <Typography
              variant="h2"
              fontWeight={700}
              fontSize={fontSize ? fontSize : 28}
              color="text.bright"
              sx={{ lineHeight: 1.2 }}
            >
              {title}
            </Typography>
          </Linked>

          <Stack gap={1} alignItems="center" direction="row">
            <Status status={status} />
            <GameType gameType={gameType} maxWidth="70" />
          </Stack>
        </Stack>

        <Typography
          variant="span"
          color="text.muted"
          fontWeight={600}
          fontSize={16}
          sx={{ lineHeight: 1.4 }}
        >
          {tagline}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Header;
