import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@mui/material";
import { FaArrowRight } from "react-icons/fa";

const ViewMoreButton = ({ viewMore, specs }) => {
  if (viewMore == null) {
    return null;
  }
  return (
    <Button
      component={RouterLink}
      variant="text"
      size="small"
      to={specs.linkTo}
      style={{ alignSelf: "flex-end" }}
      endIcon={<FaArrowRight />}
    >
      View Leaderboard
    </Button>
  );
};

export default ViewMoreButton;
