import { Stack, useTheme } from "@mui/material";
import React from "react";

const SidebarStructure = ({ children }) => {
  const theme = useTheme();
  return (
    <Stack
      gap={2}
      sx={{
        [theme.breakpoints.down("md")]: {
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gridGap: "16px",
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default SidebarStructure;
