import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-1-26</Date>
      <Change type="improved">
        Guild Dashboard: Add Investor Share to data capture fields and allow
        Column customization in the Scholars table.
      </Change>
    </Entry>
  );
};

export default Changes;
