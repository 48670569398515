import React from "react";
import Navigation from "./Navigation";
import { connect } from "react-redux";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import { Stack, Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuToggle from "../atoms/MenuToggle";
import SidebarStats from "../molecules/SidebarStats";
import SidebarFooter from "../molecules/SidebarFooter";
import SidebarHeader from "../molecules/SidebarHeader";
import DrawerDesktop from "../molecules/DrawerDesktop";
import DrawerMobile from "../molecules/DrawerMobile";

const Sidebar = ({ guild, window }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 230;

  const handleToggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Stack sx={{ p: 2, height: "100%" }}>
      <Stack
        gap={4}
        justifyContent="space-between"
        flexWrap="nowrap"
        sx={{
          height: "100%",
          overflowY: "auto",
          pt: 1,
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Stack
          gap={1}
          flexWrap="wrap"
          justifyContent={{ sm: "space-between", md: "flex-start" }}
        >
          <SidebarHeader guild={guild} />
          <SidebarStats
            averageStars={guild?.arenaLeaderboardPosition?.averageElo}
          />
          <Navigation />
        </Stack>

        <SidebarFooter />
      </Stack>
    </Stack>
  );

  return (
    <>
      <MenuToggle show={!smUp} handleToggleDrawer={handleToggleDrawer} />
      <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        {smUp ? (
          <DrawerDesktop drawerWidth={drawerWidth}>{drawer}</DrawerDesktop>
        ) : (
          <DrawerMobile
            open={mobileOpen}
            container={container}
            onClose={handleToggleDrawer}
            drawerWidth={drawerWidth}
          >
            {drawer}
          </DrawerMobile>
        )}
      </Box>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guild: state.guildDashboard.guild,
  guildStats: guildSelectors.getGuildStats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
