import { Chip, Stack, Typography, Link } from "@mui/material";
import React from "react";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import MutedText from "components/Typography/MutedText";
import { MdInfo } from "react-icons/md";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import { GoLinkExternal } from "react-icons/go";

const RecentSales = ({ recentlySold }) => {
  return (
    <Stack gap={4}>
      {recentlySold.total === 0 ? (
        <Stack alignItems="center" sx={{ pt: 40 }}>
          <MutedText>No recent sales of similar axies</MutedText>
        </Stack>
      ) : (
        <>
          <Info
            theMostRecentlySoldAxie={recentlySold.theMostRecentlySoldAxie}
          />
          <Stack direction="row" gap={3} flexWrap="wrap">
            {recentlySold.axies.map((axie, i) => (
              <AxieV3 axie={axie} key={`AXIE_${i}`} variant="full" />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

const Info = ({ theMostRecentlySoldAxie }) => {
  const { price, date, axieId, marketplaceUrl } = theMostRecentlySoldAxie;
  return (
    <Stack gap={0.5} justifyContent="space-between">
      <SectionLabel
        icon={<MdInfo />}
        tooltip="The most recently sold axie with the same class, cards and min purity."
      >
        Last Sale
      </SectionLabel>
      <Stack direction="row" gap={1}>
        <Typography
          variant="span"
          fontSize={22}
          fontWeight={600}
          color="text.primary"
        >
          {price}
        </Typography>
        <Chip label={date} color="primary" size="small" />
      </Stack>

      <Link
        href={marketplaceUrl}
        target="_blank"
        sx={{
          p: 0,
          border: "none",
          "&:hover": {
            border: "none",
          },
        }}
      >
        <Stack
          direction="row"
          gap={1}
          color="text.secondary"
          alignItems="center"
          fontSize={14}
        >
          <Typography variant="span" fontWeight={500}>
            Axie #{axieId}
          </Typography>
          <GoLinkExternal />
        </Stack>
      </Link>
    </Stack>
  );
};

export default RecentSales;
