import React from "react";
import { Stack, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(1),

  [theme.breakpoints.down("lg")]: {
    alignSelf: "center",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
  },
}));

const DatePickerInput = styled(TextField)(({ theme }) => ({
  width: 160,
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
}));

const DatePickers = ({ handleUpdateControl, startDate, endDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Wrapper>
        <DesktopDatePicker
          label="Start Date"
          inputFormat="YYYY/MM/DD"
          value={startDate}
          onChange={(newVal) => {
            handleUpdateControl("startDate")(newVal);
          }}
          renderInput={(params) => <DatePickerInput size="small" {...params} />}
        />
        <DesktopDatePicker
          label="End Date"
          inputFormat="YYYY/MM/DD"
          value={endDate}
          onChange={(newVal) => {
            handleUpdateControl("endDate")(newVal);
          }}
          renderInput={(params) => <DatePickerInput size="small" {...params} />}
        />
      </Wrapper>
    </LocalizationProvider>
  );
};

export default DatePickers;
