import React from "react";
import Item from "./Item";
import RuneTemplate from "components/GameTemplates/RuneTemplate";
import { makeMarketplaceUrlForItem } from "helpers/items";

const WrappedRune = ({ rune, favoriteRune }) => {
  return (
    <Item
      handleClick={() => favoriteRune(rune)}
      isFavorite={rune.isFavorite}
      href={makeMarketplaceUrlForItem("rune", rune.tokenId)}
    >
      <RuneTemplate rune={rune} width={220} />
    </Item>
  );
};

export default WrappedRune;
