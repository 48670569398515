import { TextField } from "@mui/material";
import React from "react";

const Input = ({ onChange, name, label, value, error }) => {
  return (
    <TextField
      onChange={onChange}
      label={label}
      name={name}
      variant="filled"
      size="small"
      value={value}
      fullWidth
      {...(error && { error: true, helperText: error })}
    />
  );
};

export default Input;
