import * as actionTypes from "./breedingCalculatorActionTypes";

const initialState = {
  firstLoad: true,
  inputs: {
    numberOfBreeds: 4,
    parent1: {
      costETH: 0,
      saleETH: 0,
      breedCount: 0,
    },
    parent2: {
      costETH: 0,
      saleETH: 0,
      breedCount: 0,
    },
    inventory: {
      slp: 0,
      axs: 0,
      basisCosts: {
        slp: undefined,
        axs: undefined,
      },
      useMarketPrice: {
        slp: true,
        axs: true,
      },
    },
    childSalesETH: [0, 0, 0, 0, 0, 0, 0],
  },
};

export const breedingCalculatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BREEDING_CALCULATOR_INPUTS:
      return {
        ...state,
        inputs: action.payload,
      };
    case actionTypes.LOAD_BREEDING_CALCULATOR:
      return {
        ...state,
        firstLoad: false,
      };
    default:
      return state;
  }
};

export default breedingCalculatorReducer;
