import { Stack } from "@mui/material";
import React from "react";
import Platform from "../atoms/Platform";
import Social from "../atoms/Social";

const Footer = ({ platforms, socials }) => {
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {socials != null &&
          socials.map((social, i) => (
            <Social social={social} key={`SOCIAL_${i}`} />
          ))}
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        {platforms.map((platform, i) => (
          <Platform platform={platform} key={`PLATFORM_${i}`} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Footer;
