import React from "react";
import { IoStar } from "react-icons/io5";
import { Typography } from "@mui/material";
import IconMessage from "components/IconMessage/IconMessage";

export const CardExplorerTitle = () => {
  return (
    <>
      <Typography variant="body1" fontSize="20px" color="text.regular">
        <strong>Explore all V2 Axie cards and abilities.</strong> Card tiers are
        based on analysis by Arena Pro, Indes.
      </Typography>
      <IconMessage
        color="yellow"
        text="Click cards to add them to your favorites tab!"
        icon={<IoStar />}
        style={{ alignSelf: "flex-start" }}
      />
    </>
  );
};

export default CardExplorerTitle;
