import { Typography } from "@mui/material";

const SubtleText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      component="span"
      color="text.veryMuted"
      fontSize={13}
      fontWeight={600}
      sx={{ lineHeight: 1.1 }}
    >
      {children}
    </Typography>
  );
};

export default SubtleText;
