import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@mui/material";

const CopyJsonButton = ({ lockedResultJSON }) => {
  return (
    <CopyToClipboard text={JSON.stringify(lockedResultJSON)}>
      <Button variant="contained">Copy JSON</Button>
    </CopyToClipboard>
  );
};

export default CopyJsonButton;
