import { Stack, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { FaClock } from "react-icons/fa";
import { DATE_TIME_FORMAT } from "config";
import moment from "moment";

const Capsule = styled(Stack)(({ theme, style }) => ({
  padding: `2px 2px`,
  borderRadius: "36px",
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: 10,
  fontWeight: 800,
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(0.5),
  justifyContent: "center",
  minWidth: 20,

  "&:hover": {
    cursor: "pointer",
  },

  svg: {
    height: 10,
    width: "auto",
  },
}));

const WithdrawalAmount = styled(Capsule)(({ theme }) => ({
  background: theme.palette.feedback.green,
  color: theme.palette.feedbackContrast.green,
}));

const NextWithdrawTime = styled(Stack)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 600,
  color: theme.palette.text.muted,
  whiteSpace: "nowrap",
  flexDirection: "row",
  gap: 4,
}));

const Quantity = ({
  quantity,
  withdrawalAmount,
  nextWithdrawTime,
  isBlockchain,
}) => {
  return (
    <Stack direction="row" gap={0.5} alignItems="center">
      <Tooltip title={`Total: ${quantity}`}>
        <Capsule>{quantity}</Capsule>
      </Tooltip>

      {withdrawalAmount != null && isBlockchain && (
        <Tooltip
          title={`Mintable & not currently equipped: ${withdrawalAmount}`}
        >
          <WithdrawalAmount>
            <span> {withdrawalAmount}</span>
          </WithdrawalAmount>
        </Tooltip>
      )}

      {nextWithdrawTime != null && isBlockchain && (
        <Tooltip title="Next mint date">
          <NextWithdrawTime>
            <FaClock />
            <span>
              {moment.utc(nextWithdrawTime).local().format(DATE_TIME_FORMAT)}
            </span>
          </NextWithdrawTime>
        </Tooltip>
      )}
    </Stack>
  );
};

export default Quantity;
