import React from "react";
import { Typography, Grid, Stack, Box, Container } from "@mui/material";
import Testimonial from "components/Testimonial/Testimonial";

const HighlightText = ({ children }) => (
  <Typography
    color="text.primary"
    fontWeight="700"
    variant="body2"
    display="inline"
    fontSize="20px"
  >
    {children}
  </Typography>
);

const Testimonials = () => {
  return (
    <Container fixed>
      <Box sx={{ pt: 6, pb: 1 }}>
        <Stack gap={6}>
          <Typography
            variant="h3"
            fontWeight="700"
            color="text.bright"
            textAlign="center"
          >
            Testimonials
          </Typography>
          <Grid
            container
            className="testimonials"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            spacing={2}
          >
            <Grid item sm={12} md={6}>
              <Stack gap={2}>
                <Testimonial
                  text={
                    <>
                      <HighlightText>
                        The Axie.Tech guild tools are a game changer and must
                        for any guild.
                      </HighlightText>{" "}
                      The information is easily read and available at a click of
                      a button. The best part is your players are able to check
                      their ranking within your guild, encouraging them to apply
                      themselves further! Keep up the good work!
                    </>
                  }
                  author="Axie Flyers"
                />

                <Testimonial
                  text={
                    <>
                      The interface is simple, clear, and precise enough to save
                      me time when reviewing each member's performance. One of
                      its key features, guild page, allows my members to see
                      information that helps them continue having a competitive
                      spirit but at the same time{" "}
                      <HighlightText>
                        allows my managers to see who are in need of help.
                      </HighlightText>{" "}
                      As a bonus, I have used the "Teams" tab to help me think
                      about team efficiency without having to go into Ronin -
                      great also for breed planning.
                    </>
                  }
                  author="Axieland"
                />
                <Testimonial
                  text={
                    <>
                      Ranking players by moonshards and MMR on the manager page
                      has helped me better identify which teams needed to be
                      improved. I already have a method in the guild for
                      individuals to ask for team changes, but turns out my
                      bottom 10 players never even made the request and I’ve
                      been able to identify that the majority of their issues
                      have been team quality. It’s also helped me identify high
                      performing players.{" "}
                      <HighlightText>
                        I’ve been able to identify 5 people who I think can also
                        push top 500 and have invested top quality teams for
                        them.
                      </HighlightText>
                    </>
                  }
                  author="Showdown Scholars"
                />
              </Stack>
            </Grid>
            <Grid item sm={12} md={6}>
              <Stack gap={2}>
                <Testimonial
                  text={
                    <>
                      Team VicTree began using the Axie Tech Guild Tools and
                      immediately noticed our team members becoming more
                      engaged. The public dashboard allows for friendly
                      competition and comparison from our team members. It also{" "}
                      <HighlightText>
                        provides immediate public recognition to our top
                        performers.
                      </HighlightText>
                    </>
                  }
                  author="Team VicTree"
                />
                <Testimonial
                  text={
                    <>
                      Axie.Tech is a group of kind-hearted folks doing great
                      things in the Axie Infinity ecosystem. They are emblematic
                      of the great people we find throughout this new Axie
                      metaverse. Upon launch in August 2021, I signed up for the
                      Axie.Tech Guild Tools with the primary need of having a
                      leaderboard that was updated daily for my whole player
                      group. The leaderboard report has really motivated the
                      AxieJoy players. We achieved 225 SLP/day at our highest,
                      only four weeks after our business launch with a wave of
                      50 scholars. Using the “team tool”, I am able to inspect
                      the components of a team build very quickly and relate
                      that information back to what I’m seeing on the
                      leaderboard.{" "}
                      <HighlightText>
                        Our business achievements and quick growth would not be
                        possible without Axie.Tech.
                      </HighlightText>{" "}
                      They are a great team and we, at AxieJoy, love working
                      with them!
                    </>
                  }
                  author="Axie Joy"
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Container>
  );
};

export default Testimonials;
