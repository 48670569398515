import CustomLink from "components/CustomLink/CustomLink";
import { INTER_THE_DUNGEON_TWITTER } from "config";

const Logo = ({ width }) => {
  return (
    <CustomLink href={INTER_THE_DUNGEON_TWITTER}>
      <img
        width={width}
        src="/images/games/inter-the-dungeon/logo.png"
        alt="Inter The Dungeon Logo"
        title="Inter The Dungeon"
      />
    </CustomLink>
  );
};

export default Logo;
