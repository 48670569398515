import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import Sidebar from "./Sidebar";
import Main from "./Main";
import styled from "@emotion/styled";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "midSized",
})(({ theme, midSized }) => ({
  flexDirection: midSized ? "column" : "row",
  gap: midSized ? theme.spacing(1) : theme.spacing(3),
  padding: "8px",
  background: theme.palette.card.background,
  borderRadius: 4,
  boxShadow: theme.palette.card.boxShadow,
  margin: "0 auto",
}));

const AxieSlot = ({
  axie,
  handleOpenCharmDialog,
  handleOpenCardDialog,
  handleOpenRuneDialog,
  updateRune,
  updateCard,
  updateCharm,
  clearAxie,
  view,
  updateClass,
}) => {
  const midSized = useMediaQuery("(max-width:1440px)");

  if (axie == null) {
    return null;
  }

  return (
    <Wrapper midSized={midSized}>
      <Sidebar
        axie={axie}
        handleOpenRuneDialog={handleOpenRuneDialog}
        updateRune={updateRune}
        updateClass={updateClass}
        updateCharm={updateCharm}
      />
      <Main
        axie={axie}
        handleOpenCharmDialog={handleOpenCharmDialog}
        handleOpenCardDialog={handleOpenCardDialog}
        updateCard={updateCard}
        updateCharm={updateCharm}
        clearAxie={clearAxie}
        view={view}
      />
    </Wrapper>
  );
};

export default AxieSlot;
