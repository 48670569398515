import React from "react";
import * as bodyPartIcons from "components/Axie/icons/bodyPartIcons";
import { displayPercent } from "helpers/display";
import { Box } from "@mui/material";
import { getColorForAxieClass } from "helpers/axie";
import styles from "./breeding-result.module.scss";
import Label from "components/Typography/Label";
import { keyframes } from "@emotion/react";
import { Typography, useTheme, Stack } from "@mui/material";
import { BREEDING_EVENT_SPECIAL_GENES } from "config";

const PartProbabilities = ({ parts }) => {
  const theme = useTheme();
  return (
    <div className={styles.bodyParts}>
      {Object.keys(parts).map((bodyPart, i) => {
        let possibilities = Object.keys(parts[bodyPart]);
        return (
          <Box
            className={styles.bodyPart}
            key={`BODY_PART_${bodyPart}`}
            sx={{
              background: theme.palette.card.background,
              border: `1px solid ${theme.palette.card.divider}`,
            }}
          >
            <div className={styles.bodyPartIcon}>
              {
                bodyPartIcons[
                  `${bodyPart}_${parts[bodyPart][0].axieClass.toLowerCase()}`
                ]
              }
              <Label>{bodyPart[0].toUpperCase() + bodyPart.substring(1)}</Label>
            </div>
            {possibilities.map((p) => {
              const isBreedingEventPart =
                parts[bodyPart][p].specialGenes ===
                BREEDING_EVENT_SPECIAL_GENES;
              const isShinyPart = parts[bodyPart][p].shiny;
              return (
                <PartTextWrapper
                  isBreedingEventPart={isBreedingEventPart}
                  isShinyPart={isShinyPart}
                  key={`${bodyPart}_${parts[bodyPart][p].trait}`}
                >
                  <Stack
                    sx={{
                      mr: 1,
                      color: isBreedingEventPart
                        ? "#000"
                        : getColorForAxieClass(parts[bodyPart][p].axieClass),
                    }}
                  >
                    {parts[bodyPart][p].trait}
                  </Stack>

                  <Typography
                    variant="span"
                    color={isBreedingEventPart ? "#000" : "text.bright"}
                    sx={{ fontFamily: "monospace" }}
                  >
                    {displayPercent(parts[bodyPart][p].percent)}
                  </Typography>
                </PartTextWrapper>
              );
            })}
          </Box>
        );
      })}
    </div>
  );
};

const PartTextWrapper = ({ isBreedingEventPart, children, isShinyPart }) => {
  const theme = useTheme();

  const specialStyles = isBreedingEventPart
    ? {
        borderRadius: 1,
        px: "3px",
        py: "2px",
        backgroundSize: isShinyPart ? "200% 200%" : "100% 100%",
        backgroundImage: isShinyPart
          ? theme.palette.colors.events.summerBreedingShiny
          : theme.palette.colors.events.summerBreeding,
        animation: isShinyPart ? `${animateGradient} ease 4s infinite` : "none",
      }
    : {};

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="1px"
      sx={{
        borderBottom: `1px solid ${theme.palette.card.divider}`,
        pb: "4px",
        ...specialStyles,
      }}
    >
      {children}
    </Stack>
  );
};

export default PartProbabilities;

const animateGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}`;
