import { Stack } from "@mui/material";
import Label from "components/Typography/Label";
import React from "react";
import Capsule from "../atoms/InfoCapsule";

const TotalLockedCost = ({ slp, axs }) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Label>Total Cost</Label>
      <Capsule amount={slp} icon="/images/icons/slp.png" iconWidth="24px" />
      <Capsule amount={axs} icon="/images/icons/axs.png" />
    </Stack>
  );
};

export default TotalLockedCost;
