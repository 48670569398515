import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Owner from "../atoms/Owner";

const Wrapper = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.popover.divider}`,
  gap: theme.spacing(0.5),
  maxHeight: 200,
  overflowY: "auto",
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const Owners = ({ data, isBlockchain }) => {
  // filter 0 quantities from owner array
  const quantityIsNotZero = data?.filter((owner) => owner.quantity !== 0);

  if (data == null) {
    return null;
  }
  return (
    <Wrapper>
      {quantityIsNotZero.map((owner) => (
        <Owner
          owner={owner}
          key={owner.roninAddress}
          isBlockchain={isBlockchain}
        />
      ))}
    </Wrapper>
  );
};

export default Owners;
