import { Stack, useTheme, Button } from "@mui/material";
import MainTitle from "pages/Home/atoms/MainTitle";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";
import { getAxieLastSold } from "helpers/axie";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import { FaArrowCircleRight } from "react-icons/fa";
import { AXIE_INFINITY_MARKETPLACE } from "config";

const RecentlyListed = ({
  axies,
  fetchRecentlyListed,
  fetchingAxies,
  failedAxies,
  showGenes = true,
  displayLastSold = false,
}) => {
  const theme = useTheme();
  const data = axies.slice(0, 4);
  return (
    <Stack
      gap={4}
      alignItems="center"
      justifyContent="center"
      pt={8}
      sx={{
        [theme.breakpoints.down("md")]: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      }}
    >
      <MainTitle styles={{ fontSize: 32 }}>Recently Listed Axies</MainTitle>
      <Button
        href={AXIE_INFINITY_MARKETPLACE}
        variant="outlined"
        endIcon={<FaArrowCircleRight />}
        target="_blank"
        sx={{
          alignSelf: "center",
          mb: 2,
        }}
      >
        Axie Infinity Marketplace
      </Button>

      <DisplayLoadingOrContent
        loading={fetchingAxies}
        failed={failedAxies}
        retry={fetchRecentlyListed}
        data={data}
        failedMessage="Unable to load axies"
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={1}
          flexWrap="wrap"
          justifyContent="center"
        >
          {data.map((axie) => (
            <AxieV3
              key={axie.axieId}
              axie={axie}
              showGenes={showGenes}
              lastSold={
                displayLastSold
                  ? getAxieLastSold({
                      price: axie?.transferHistory.results[0].withPrice,
                      priceUsd: axie?.transferHistory.results[0].withPriceUsd,
                      timestamp: axie?.transferHistory.results[0].timestamp,
                    })
                  : undefined
              }
            />
          ))}
        </Stack>
      </DisplayLoadingOrContent>
    </Stack>
  );
};

export default RecentlyListed;
