import { augmentBattleData, getFavoriteTeams } from "helpers/arena";
import { createSelector } from "reselect";
import { getAllCharms, getAllRunes } from "store/data/dataSelectors";
import { getTopNRunes } from "helpers/items";
import { matchesStr } from "helpers/display";
import { api } from "store/apiSlice";

const getControls = (state) => state.originLeaderboard.controls || {};

const favoriteTeamIncludesRunes = (favoriteTeam, runes) => {
  if (!favoriteTeam || !runes || runes.length === 0) {
    return true;
  }

  // filter out where there is no runeData value
  const hasRuneData = favoriteTeam.filter((axie) => axie.runeData);
  const favoriteTeamRunes = hasRuneData.map((axie) => axie.runeData.name);

  for (const rune of runes) {
    if (!favoriteTeamRunes.includes(rune.name)) {
      return false;
    }
  }

  return true;
};

const favoriteTeamIncludesCharms = (favoriteTeam, charms) => {
  if (!favoriteTeam || !charms || charms.length === 0) {
    return true;
  }

  const favoriteTeamCharms = favoriteTeam.flatMap((axie) =>
    Object.values(axie.charmData).reduce((prev, curr) => {
      if (curr) {
        prev.push(curr.name);
      }
      return prev;
    }, [])
  );

  for (const charm of charms) {
    if (!favoriteTeamCharms.includes(charm.name)) {
      return false;
    }
  }

  return true;
};

const applyFilters = (player, controls) => {
  if (!controls || !player) {
    return true;
  }

  return (
    matchesStr(player.name, controls.searchText) &&
    favoriteTeamIncludesRunes(player.favoriteTeam, controls.runes) &&
    favoriteTeamIncludesCharms(player.favoriteTeam, controls.charms)
  );
};

export const getOriginLeaderboardData = createSelector(
  [
    api.endpoints.getOriginsLeaderboard.select(),
    getControls,
    getAllRunes,
    getAllCharms,
  ],
  ({ data: leaderboard = [] }, controls, allRunes, allCharms) => {
    const numTopRunes = 10;
    const allPlayers = leaderboard.map((player) => {
      const battles = augmentBattleData(player.battles, allRunes, allCharms);
      const favoriteTeams = getFavoriteTeams(battles);
      return {
        ...player,
        favoriteTeam: favoriteTeams.length > 0 ? favoriteTeams[0] : undefined,
      };
    });

    const filteredPlayers = allPlayers.filter((player) =>
      applyFilters(player, controls)
    );

    return {
      top3Players: allPlayers.slice(0, 3),
      players: filteredPlayers,
      numPlayers: allPlayers.length,
      topRunes: getTopNRunes(allPlayers, numTopRunes),
      numTopRunes,
    };
  }
);
