import * as types from "./leaderboardActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./leaderboardActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchArenaLeaderboardRequest() {
  try {
    yield put(actions.fetchArenaLeaderboardStarted());
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestClassicLeaderboard
    );
    yield put(actions.fetchArenaLeaderboardSucceeded(data));
  } catch (e) {
    console.log("Error fetching leaderboard");
    yield put(actions.fetchArenaLeaderboardFailed());
  }
}

export default function* classicLeaderboardSaga() {
  yield takeEvery(
    types.FETCH_ARENA_LEADERBOARD,
    makeFetchArenaLeaderboardRequest
  );
}
