import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Stack,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import { deleteTeam } from "store/guildDashboard/actions/guildDashboardActions";
import { useWeb3React } from "@web3-react/core";
import LoadingButton from "@mui/lab/LoadingButton";
import * as teamSelectors from "store/guildDashboard/selectors/team";
import Error from "components/Typography/Error";

const DeleteBattleTeamDialog = ({ open, setOpen, team, deleteTeam }) => {
  const { account } = useWeb3React();
  const [error, setError] = useState("");
  const theme = useTheme();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (team.deletingTeam === false && team.deletingTeamFailed === true) {
      setError("Unable to delete team...");
    } else if (
      team.deletingTeam === false &&
      team.deletingTeamFailed === false
    ) {
      handleClose();
    }
  }, [team, handleClose]);

  const handleDeleteTeam = () => {
    deleteTeam(account, team.guildId, team.guildTeamId, team?.scholars);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="edit-team-dialog-title"
      sx={{ "& .MuiPaper-root": { width: "380px" } }}
    >
      <DialogTitle id="edit-team-dialog-title">
        Delete Guild Battle Team
      </DialogTitle>
      <DialogContent dividers={true}>
        <Error>{error}</Error>
        <Typography
          variant="span"
          fontSize="14px"
          fontWeight="500"
          color="text.muted"
          lineHeight={1.4}
        >
          Are you sure you want to delete this team?
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Stack gap={1} direction="row">
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </Stack>

        {team.deletingTeam ? (
          <LoadingButton
            variant="contained"
            loading
            sx={{ alignSelf: "stretch" }}
          />
        ) : (
          <Button
            onClick={handleDeleteTeam}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.feedback.red,
              "&:hover": {
                backgroundColor: theme.palette.feedback.red,
              },
            }}
          >
            Delete Team
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  deleteTeam,
};

const mapStateToProps = (state) => ({
  team: teamSelectors.getActiveTeam(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteBattleTeamDialog);
