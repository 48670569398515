import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Button from "components/InterDungeon/Layout/Button";
import { INTER_THE_DUNGEON_TWITTER } from "config";
import { interDungeonTheme } from "theme/interDungeon/theme";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: `${theme.spacing(14)} 0`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `linear-gradient(180deg, transparent, ${interDungeonTheme.footer.background}), url(/images/games/inter-the-dungeon/dungeon-footer.jpg)`,
}));

const PageFooter = () => {
  return (
    <Wrapper>
      <Button href={INTER_THE_DUNGEON_TWITTER}>Play Inter The Dungeon</Button>
    </Wrapper>
  );
};

export default PageFooter;
