import React from "react";
import { Stack, useTheme } from "@mui/material";
import SectionLabel from "../atoms/SectionLabel";
import Text from "components/Typography/Text";

const Badges = ({ badges }) => (
  <Stack gap={1.5}>
    <SectionLabel>Badges</SectionLabel>

    <Stack gap={1} direction="row" flexWrap="wrap">
      {badges != null &&
        badges.map((badge, i) => <Badge badge={badge} key={`BADGE_${i}`} />)}
    </Stack>
  </Stack>
);

const Badge = ({ badge }) => {
  const theme = useTheme();
  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="center"
      sx={{
        background: theme.palette.card.background,
        borderRadius: "16px",
        boxShadow: `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
        py: 0.75,
        px: 1.25,
        fontSize: 14,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.1)",
          cursor: "pointer",
        },
      }}
    >
      {badge.icon}
      <Text>{badge.title}</Text>
    </Stack>
  );
};

export default Badges;
