import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  Chip,
  useTheme,
} from "@mui/material";

const activeStyle = {
  backgroundColor: (theme) =>
    `${theme.palette.colors.interDungeon.controls.teal} !important`,
  color: "#fff",
};

const MenuProps = {
  PaperProps: {
    sx: {
      backgroundColor: "#fff",
      width: 90,
      borderRadius: "5px",
      top: "275px",
      color: "rgba(0, 0, 0, 1)",
      "& .MuiMenuItem-root": {
        margin: "1px 5px",
        "&:focus": {
          ...activeStyle,
          opacity: 0.9,
          "&:hover": {
            ...activeStyle,
            opacity: 0.9,
          },
        },
        "&:hover, &:active, &.Mui-selected, &.Mui-focusVisible, &:focus-visible":
          {
            ...activeStyle,
          },
      },
    },
  },
};

export default function CustomMultiSelect({
  width,
  label,
  value,
  setValue,
  options,
  updateSelectValue,
  styles,
  height,
}) {
  const theme = useTheme();

  return (
    <FormControl
      variant="filled"
      size="small"
      sx={{
        ...styles,
        minWidth: width,
        maxWidth: width,
        height: "100%" || height,
        "& .MuiFilledInput-root": {
          borderRadius: 2,
          backgroundColor: (theme) =>
            `${theme.palette.colors.interDungeon.controls.teal} !important`,
          border: "1px solid transparent",
          height: "100%",
          "&:hover, &:active, &.Mui-focused": {
            background: "rgba(255,255,255,0.2)",
            border: `solid 1px #fff`,
            boxShadow: "none",
          },
        },
        "& .MuiFilledInput-input": {
          borderRadius: 2,
          "&:focus": {
            borderRadius: 2,
            background: "transparent",
          },
        },
        "& .MuiInputLabel-root": {
          color: "rgba(255, 255, 255)",
          fontWeight: 600,
          "&.Mui-focused": {
            color: "rgba(255, 255, 255)",
          },
        },
        "& .MuiSelect-select": {
          borderRadius: 2,
          backgroundColor: "rbga(255, 255, 255)",
        },
        "&.MuiSvgIcon-root, .MuiSelect-iconFilled ": {
          color: "#ffff",
          transition: "transform 0.1s ease-in-out",
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "initial",
          minWidth: "100%",
        },
      }}
    >
      <InputLabel id={`${label}-select`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select`}
        multiple
        value={value}
        onChange={updateSelectValue}
        renderValue={(selected) => (
          <Box
            sx={{
              maxWidth: "130px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "no-wrap",
              overflow: "hidden",
              gap: 0.5,
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        sx={{
          "& .MuiChip-root": {
            backgroundColor: "#000",
            color: "#fff",
            height: 20,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
