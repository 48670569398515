import { useState } from "react";
import { Stack, Button } from "@mui/material";
import { HiRefresh } from "react-icons/hi";
import { fetchAxiesForScholar } from "store/guildDashboard/actions/guildDashboardActions";
import { connect } from "react-redux";
import Card from "components/Card/Card";
import CenteredMessage from "../atoms/CenteredMessage";
import PlayerSidebar from "./PlayerSidebar";
import styled from "@emotion/styled";
import PlayerAxies from "./PlayerAxies";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(4),
  flexWrap: "wrap",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    display: "grid",
    gridTemplateColumns: "160px 1fr",
    gridGap: "64px",
    justifyContent: "flex-start",
  },
}));

export const Player = ({ player, fetchAxiesForScholar, showGenes, guild }) => {
  const [hasLoadedAxies, setHasLoadedAxies] = useState(false);

  if (player == null) return null;

  const {
    fetchingAxies,
    axies,
    filteredAxies,
    roninAddress,
    failedToFetchAxies,
    guildTeamId,
    name,
  } = player;
  const hasAxies = axies && axies.length > 0;
  const hasFilteredAxies = filteredAxies.length > 0;

  const handleFetchAxiesForPlayer = () => {
    fetchAxiesForScholar(roninAddress, guildTeamId);
    setHasLoadedAxies(true);
  };

  return (
    <Card>
      <Wrapper>
        <PlayerSidebar
          scholar={player}
          guild={guild}
          handleFetchAxiesForScholar={handleFetchAxiesForPlayer}
        />

        {fetchingAxies && <CenteredMessage>Loading axies...</CenteredMessage>}

        {!fetchingAxies && !hasAxies && hasLoadedAxies && (
          <CenteredMessage>No axies</CenteredMessage>
        )}

        {!fetchingAxies && !hasAxies && !hasLoadedAxies && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              endIcon={<HiRefresh />}
              onClick={handleFetchAxiesForPlayer}
            >
              Load Axies
            </Button>
          </Stack>
        )}

        {hasFilteredAxies && (
          <PlayerAxies
            showGenes={showGenes}
            filteredAxies={filteredAxies}
            roninAddress={roninAddress}
            playerName={name}
          />
        )}

        {failedToFetchAxies && (
          <CenteredMessage>Error fetching axies</CenteredMessage>
        )}
      </Wrapper>
    </Card>
  );
};

const mapDispatchToProps = {
  fetchAxiesForScholar,
};

const mapStateToProps = (state) => ({
  showGenes: state.guildDashboard.axies.showGenes,
  guild: state.guildDashboard.guild,
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
