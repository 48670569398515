import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import ConditionalWrapper from "components/Utility/ConditionalWrapper";
import CharmTemplate from "./CharmTemplate";

const Charm = ({ onClick, charm, width = 1181, style, tooltip }) => {
  if (charm == null) {
    return null;
  }
  return (
    <ConditionalWrapper
      condition={tooltip}
      wrapper={(children) => (
        <Tooltip
          title={<CharmTemplate charm={charm} width={180} />}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "transparent",
                boxShadow: "none",
                border: "none",
              },
            },
          }}
        >
          {children}
        </Tooltip>
      )}
    >
      <Box
        onClick={onClick}
        sx={{
          width,
          height: width,
          position: "relative",
          ...style,
        }}
      >
        <CharmIcon name={charm.name} image={charm.image} width={width} />
      </Box>
    </ConditionalWrapper>
  );
};

const CharmIcon = ({ image, name, width }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      "& img": {
        height: width,
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img src={image} alt={name} />
  </Stack>
);

export default Charm;
