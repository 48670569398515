import ArenaPerformance from "../molecules/ArenaPerformance";
import { Grid, Stack } from "@mui/material";
import { updateScholarBattlesChartToDisplay } from "store/guildDashboard/actions/guildDashboardActions";
import { connect } from "react-redux";
import BattlesChartWrapper from "../molecules/BattlesChartWrapper";
import Card from "components/Card/Card";
import ArenaChartWrapper from "../molecules/ArenaChartWrapper";

const Overview = ({
  scholarBattlesChartToDisplay,
  updateScholarBattlesChartToDisplay,
  scholar,
}) => {
  return (
    <Stack gap={2}>
      <Card>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <ArenaPerformance scholar={scholar} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={9}>
            <BattlesChartWrapper
              data={scholar.chartData}
              toDisplay={scholarBattlesChartToDisplay}
              updateToDisplay={updateScholarBattlesChartToDisplay}
            />
          </Grid>
        </Grid>
      </Card>

      <Card>
        <ArenaChartWrapper data={scholar.arenaChartData} />
      </Card>
    </Stack>
  );
};

const mapDispatchToProps = {
  updateScholarBattlesChartToDisplay,
};

const mapStateToProps = (state) => ({
  scholarBattlesChartToDisplay:
    state.guildDashboard.config.scholarBattlesChartToDisplay,
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
