import { BsFillInfoCircleFill } from "react-icons/bs";
import React from "react";
import { IconButton, useTheme } from "@mui/material";

const InfoButton = ({ onClick }) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        color: theme.palette.colors.origin.text.brown,
        "&:hover": {
          color: "#df8e2a",
          backgroundColor: "rgba(255,255,255,0.3)",
        },
      }}
      onClick={onClick}
    >
      <BsFillInfoCircleFill />
    </IconButton>
  );
};

export default InfoButton;
