import { Stack, Typography, useTheme } from "@mui/material";
import SupportCard from "pages/Support/molecules/SupportCard";
import Socials from "pages/Support/atoms/Socials";

const Cards = ({ cards, title, twitter, discord }) => {
  const theme = useTheme();
  if (!cards.length) return null;

  return (
    <Stack
      sx={{
        width: "60%",
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("xl")]: { width: "80%" },
        [theme.breakpoints.down("md")]: { width: "75%" },
      }}
    >
      <Stack alignItems="center" justifyContent="center" gap={0.5}>
        <Typography color="text.bright" fontSize={30} variant="h2">
          {title}
        </Typography>
        {twitter || discord ? (
          <Socials twitter={twitter} discord={discord} />
        ) : null}
      </Stack>
      <Stack
        sx={{
          width: "100%",
          gap: 4,
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          alignItems: "flex-start",
          justifyContent: "center",
          [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr 1fr" },
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        {cards.map((card) => (
          <SupportCard card={card} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Cards;
