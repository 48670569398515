import React from "react";
import Item from "./Item";
import CharmTemplate from "components/GameTemplates/CharmTemplate";
import { makeMarketplaceUrlForItem } from "helpers/items";

const WrappedCharm = ({ charm, favoriteCharm }) => {
  return (
    <Item
      handleClick={() => favoriteCharm(charm)}
      isFavorite={charm.isFavorite}
      href={makeMarketplaceUrlForItem("charm", charm.tokenId)}
    >
      <CharmTemplate charm={charm} width={220} />
    </Item>
  );
};

export default WrappedCharm;
