import React, { useState } from "react";
import { ImShare2 } from "react-icons/im";
import { IoSettingsSharp } from "react-icons/io5";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, Stack, IconButton as MuiIconButton } from "@mui/material";
import UserPreferencesDialog from "../organisms/UserPreferencesDialog";
import { FaExternalLinkAlt } from "react-icons/fa";
import { toggleTextWithDelay } from "helpers/display";
import { FaListUl } from "react-icons/fa";
import CustomLink from "components/CustomLink/CustomLink";
import styled from "@emotion/styled";

const IconButton = styled(MuiIconButton)(() => ({
  fontSize: "14px",
  transition: "0.2s opacity ease",
  opacity: 0.5,
  "&:hover": { opacity: 1 },
}));

const Controls = ({ guild }) => {
  const [guildURLTooltip, setGuildURLTooltip] = useState("Copy guild URL");
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  return (
    <>
      {openSettingsDialog && (
        <UserPreferencesDialog
          open={openSettingsDialog}
          setOpen={setOpenSettingsDialog}
        />
      )}
      <Stack gap={1} direction="row">
        <Tooltip title="User preferences" placement="bottom">
          <IconButton size="small" onClick={() => setOpenSettingsDialog(true)}>
            <IoSettingsSharp />
          </IconButton>
        </Tooltip>
        <CustomLink to="/guild-dashboard/autopayout-log">
          <Tooltip title="Autopayout log" placement="bottom">
            <IconButton size="small">
              <FaListUl />
            </IconButton>
          </Tooltip>
        </CustomLink>
        <CopyToClipboard
          text={`https://axie.tech/guild/${guild.logoURI}`}
          onCopy={() =>
            toggleTextWithDelay(
              setGuildURLTooltip,
              "Copy guild URL",
              "Copied",
              1000
            )
          }
        >
          <Tooltip title={guildURLTooltip} placement="bottom">
            <IconButton size="small">
              <ImShare2 />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
        <CustomLink href={`/guild/${guild.logoURI}`}>
          <Tooltip title="Visit Guild page" placement="bottom">
            <IconButton size="small">
              <FaExternalLinkAlt />
            </IconButton>
          </Tooltip>
        </CustomLink>
      </Stack>
    </>
  );
};

export default Controls;
