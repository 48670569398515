import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoLink } from "react-icons/io5";
import { getShareLink } from "store/teamBuilder/teamBuilderSelectors";
import { Button } from "@mui/material";

const ShareTeamButton = ({ shareLink }) => {
  const [hasClickedCopy, setHasClickedCopy] = useState(false);

  useEffect(() => {
    if (hasClickedCopy) {
      setTimeout(() => {
        setHasClickedCopy(false);
      }, 1000);
    }
  }, [hasClickedCopy]);

  const handleClickCopy = () => {
    setHasClickedCopy(true);
  };

  return (
    <CopyToClipboard text={shareLink}>
      <Button
        endIcon={hasClickedCopy ? <FaCheckCircle /> : <IoLink />}
        onClick={handleClickCopy}
        variant="text"
        size="small"
        color="secondary"
        sx={{ minWidth: 141 }}
      >
        {hasClickedCopy ? "Copied" : "Copy share link"}
      </Button>
    </CopyToClipboard>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  shareLink: getShareLink(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareTeamButton);
