import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-30</Date>
      <Change type="bug">
        Breeding Simulator: Locking all pairs no longer causes locked pairs
        section to disappear.
      </Change>
      <Change type="improved">
        Breeding Costs: Added average cost per axie column.
      </Change>
    </Entry>
  );
};

export default Changes;
