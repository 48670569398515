import { Typography } from "@mui/material";
import React from "react";

const Name = ({ name }) => {
  return (
    <Typography
      variant="span"
      fontSize={20}
      fontWeight={900}
      color="#000"
      gridArea="name"
      className="name"
      sx={{ letterSpacing: "-1px", display: "block", wordWrap: "anywhere" }}
    >
      {name}
    </Typography>
  );
};

export default Name;
