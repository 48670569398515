import { Stack } from "@mui/material";
import Player from "components/Top3DollPlayers/molecules/Player";
import DisplayLoadingOrContent from "components/Utility/DisplayLoadingOrContent";

const Top3DollPlayers = ({ topPlayers, loading, fetchDollLeaderboard }) => {
  return (
    <DisplayLoadingOrContent
      loading={loading}
      failed={!loading && !topPlayers.length}
      retry={fetchDollLeaderboard}
      data={topPlayers}
      failedMessage="Unable to load players"
    >
      <Stack
        gap={{ xs: 1, lg: 3 }}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {topPlayers.map((player, i) => (
          <Player
            key={`player_${i}`}
            username={player.username}
            xpTotal={player.xpTotal}
            survivalTime={player.survivalTime}
            monstersDefeated={player.monstersDefeated}
            rank={player.rank}
            sidekick={player.sidekick}
            avatar={player.avatar}
          />
        ))}
      </Stack>
    </DisplayLoadingOrContent>
  );
};

export default Top3DollPlayers;
