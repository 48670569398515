import React from "react";
import WidgetTitle from "../atoms/WidgetTitle";
import Stat from "./Stat";
import { Stack } from "@mui/material";

const StatWidget = ({ title, icon, stats, children }) => (
  <Stack gap={2}>
    <WidgetTitle text={title} icon={icon} />
    <Stack gap={2}>
      <Stack direction="row" gap={6} flexWrap="wrap">
        {stats.map((stat, i) => (
          <Stat
            key={`STAT_${i}`}
            label={stat.label}
            amount={stat.amount}
            amountSuffix={stat.amountSuffix}
            subtext={stat.subtext}
            rank={stat.rank}
            large={true}
            addRankSuffix={stat.addRankSuffix}
          />
        ))}
      </Stack>
      {children}
    </Stack>
  </Stack>
);

export default StatWidget;
