import React from "react";
import { makeMarketplaceURLFromID } from "helpers/axie";
import { IoCloseCircle } from "react-icons/io5";
import {
  Link,
  useTheme,
  Tooltip,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import ToggleIcon from "components/Axie/AxieV3/components/ToggleIcon";

const AxieId = ({
  id,
  requiredId,
  updateRequiredBreedingId,
  handleDeleteId,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      key={`ID_WRAPPER_${id}`}
      sx={{
        borderBottom: `1px solid ${theme.palette.card.divider}`,
        width: "100%",
        padding: "4px 6px 4px 0",
      }}
    >
      <ToggleIcon
        id={id}
        updateRequiredBreedingId={updateRequiredBreedingId}
        requiredId={requiredId}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", padding: "0 10px 0 10px" }}
      >
        <Tooltip title="View on marketplace" placement="right">
          <Link
            underline="none"
            target="_blank"
            href={makeMarketplaceURLFromID(id)}
            sx={{
              border: "none",
              "&:hover": {
                borderColor: "transparent",
              },
            }}
          >
            <Typography
              variant="span"
              color="text.regular"
              fontWeight="600"
              fontSize={14}
            >
              {id}
            </Typography>
          </Link>
        </Tooltip>
        <Tooltip title="Remove from simulator" placement="right">
          <IconButton
            size="small"
            onClick={() => handleDeleteId(id)}
            sx={{
              color: theme.palette.text.veryMuted,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <IoCloseCircle />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default AxieId;
