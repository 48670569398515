import React from "react";
import { Typography, Stack } from "@mui/material";

const CenteredMessage = ({ children }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    sx={{
      p: "2px 8px",
      background: (theme) => theme.palette.primary.main,
      borderRadius: "4px",
      alignSelf: "center",
      justifyContent: "center",
      margin: "0 auto",
    }}
  >
    <Typography
      variant="span"
      fontSize={16}
      fontWeight={600}
      sx={{
        color: (theme) => theme.palette.primary.contrastText,
        userSelect: "none",
        lineHeight: 1.6,
      }}
    >
      {children}
    </Typography>
  </Stack>
);

export default CenteredMessage;
