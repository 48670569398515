import { addAlphaToHex } from "helpers/colors";
import { Colors, ModeColors, colors, themeColors } from "./colors";
import fonts from "./fonts";
import { ThemeMode } from "types/theme";
import { ThemeOptions } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { shine } from "./animations/shine";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    doll: true;
    origin: true;
  }
}

type CustomTypeText = ModeColors["text"];

declare module "@mui/material/styles" {
  interface TypeText extends CustomTypeText {}

  interface Palette {
    primary: Palette["primary"];
    secondary: Palette["secondary"];
    text: TypeText;
    colors: Colors;
    game: ModeColors["game"];
    feedback: ModeColors["feedback"];
    feedbackContrast: ModeColors["feedbackContrast"];
    footer: ModeColors["footer"];
    navigation: ModeColors["navigation"];
    chart: ModeColors["chart"];
    card: ModeColors["card"];
    axieCard: ModeColors["axieCard"];
    table: ModeColors["table"];
    input: ModeColors["input"];
    inspector: ModeColors["inspector"];
    popover: ModeColors["popover"];
    toggles: ModeColors["toggles"];
    tooltip: ModeColors["tooltip"];
  }
  interface PaletteOptions {
    primary?: PaletteOptions["primary"];
    secondary?: PaletteOptions["secondary"];
    text?: Partial<TypeText>;
    colors?: Colors;
    game?: ModeColors["game"];
    feedback?: ModeColors["feedback"];
    feedbackContrast?: ModeColors["feedbackContrast"];
    footer?: ModeColors["footer"];
    navigation?: ModeColors["navigation"];
    chart?: ModeColors["chart"];
    card?: ModeColors["card"];
    axieCard?: ModeColors["axieCard"];
    table?: ModeColors["table"];
    input?: ModeColors["input"];
    inspector?: ModeColors["inspector"];
    popover?: ModeColors["popover"];
    toggles?: ModeColors["toggles"];
    tooltip?: ModeColors["tooltip"];
  }
}

export const getDesignTokens = (mode: ThemeMode): ThemeOptions => {
  const modeColors = themeColors[mode];

  return {
    typography: {
      fontFamily: fonts.body,
      button: {
        textTransform: "lowercase",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: `${modeColors.scrollbar.thumb} ${modeColors.card.background}`,
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: modeColors.card.background,
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: modeColors.scrollbar.thumb,
              minHeight: 24,
              border: `3px solid ${modeColors.card.background}`,
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: modeColors.scrollbar.thumbHover,
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: modeColors.scrollbar.thumbHover,
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: modeColors.scrollbar.thumbHover,
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: modeColors.card.background,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontWeight: 700,
            fontSize: "2.5rem",
            lineHeight: 1.3,
          },
          h2: {
            fontWeight: 600,
            fontSize: "2.062rem",
            lineHeight: 1.3,
          },
          h3: {
            fontWeight: 600,
            fontSize: "1.75rem",
            lineHeight: 1.3,
          },
          h4: {
            fontWeight: 600,
            fontSize: "1.438rem",
            lineHeight: 1.3,
          },
          h5: {
            fontWeight: 600,
            fontSize: "1.188rem",
            lineHeight: 1.3,
          },
          body1: {
            lineHeight: 1.6,
            fontSize: "1.25rem",
            fontWeight: 500,
          },
          body2: {
            lineHeight: 1.6,
            fontSize: "1rem",
            fontWeight: 500,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            lineHeight: 1.4,
            fontWeight: 600,
            background: modeColors.tooltip.background,
            boxShadow: modeColors.tooltip.boxShadow,
            color: modeColors.tooltip.color,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
            backgroundColor: modeColors.card.background,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            color: modeColors.bright,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
            fontWeight: 600,
            "&.Mui-error": {
              color: modeColors.feedback.red,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
            fontWeight: 600,
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            color: colors.neutral[300],
            "&:hover, &.Mui-selected": {
              backgroundColor: modeColors.primary.main,
              color: colors.white,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            transition: "0.2s all ease",
            borderRadius: "8px",
            fontWeight: 500,
            backgroundColor: modeColors.input.outlined.background,
            "& .MuiOutlinedInput-notchedOutline": {
              transition: "0.2s all ease",
              border: `2px solid ${modeColors.input.outlined.borderColor}`,
            },
            "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: modeColors.input.outlined.borderColorSecondary,
              },
            "&:hover, &:active, &.Mui-focused": {
              backgroundColor: modeColors.input.outlined.borderColorSecondary,
            },
            "&:hover": {
              backgroundColor: modeColors.input.outlined.background,
            },
            "&:active, &.Mui-focused": {
              backgroundColor: modeColors.input.outlined.background,
            },
          },
          input: {
            color: modeColors.regular,
            "&:focus": {
              borderRadius: "8px",
              backgroundColor: modeColors.input.outlined.background,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: modeColors.input.filled.background,
            border: "1px solid transparent",
            borderRadius: "8px",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: modeColors.input.filled.backgroundSecondary,
              border: `1px solid ${modeColors.input.filled.borderColor}`,
              boxShadow: modeColors.input.filled.boxShadow,
            },
            "&:active, &.Mui-focused": {
              backgroundColor: modeColors.input.filled.backgroundSecondary,
              border: `1px solid ${modeColors.input.filled.borderColor}`,
              boxShadow: modeColors.input.filled.boxShadow,
            },
            "&:hover:not(.Mui-disabled):before": {
              borderColor: "transparent",
            },
            "&::before": {
              borderColor: "transparent",
            },
            "&::after": {
              borderColor: "transparent",
            },
            "&.Mui-error:after": {
              borderBottomColor: modeColors.feedback.red,
            },
            "&.Mui-disabled": {
              backgroundColor: modeColors.input.filled.backgroundDisabled,
            },
          },
          input: {
            borderColor: "transparent",
            color: modeColors.regular,
            "&:focus": {
              borderRadius: "8px",
              backgroundColor: modeColors.input.filled.backgroundSecondary,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            "&.Mui-error": {
              color: modeColors.feedback.red,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            userSelect: "none",
          },
          label: {
            lineHeight: 1.2,
            paddingTop: "3px",
            paddingBottom: "2px",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            "+ .MuiTypography-root": {
              color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
              fontSize: "15px",
            },
          },
          thumb: {
            width: "12px",
            height: "12px",
          },
          valueLabel: {
            fontWeight: 700,
            background: colors.purple[300],
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: modeColors.popover.background,
            boxShadow: modeColors.popover.boxShadow,
            borderRadius: "8px",
            backgroundImage: "none",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            background: modeColors.popover.background,
            boxShadow: modeColors.popover.boxShadow,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            justifyContent: "center",
            fontSize: "14px",
            margin: "0 8px",
            borderRadius: "4px",
            "&:hover, &:focus, &:active, &.Mui-selected, &.Mui-focusVisible, &:focus-visible":
              {
                backgroundColor: `${addAlphaToHex(
                  modeColors.primary.main,
                  0.2
                )} !important`,
                color: modeColors.primary.main,
              },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            background: modeColors.card.background,
            "&:hover, &.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover":
              {
                color: modeColors.primary.contrastText,
                background: modeColors.primary.main,
              },
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          header: {
            "& .MuiDayPicker-weekDayLabel": {
              fontWeight: 800,
            },
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontWeight: 600,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            zIndex: 998, // below the cookie consent banner (999)
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: modeColors.secondary.main,
          },
          message: {
            fontWeight: 600,
            color: modeColors.card.background,
          },
          action: {
            "& .MuiIconButton-root": {
              color: modeColors.card.background,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            borderRadius: "8px",
            marginRight: "8px",
            color: modeColors.muted,
            "&:hover, &.Mui-selected": {
              color: modeColors.primary.main,
              background: addAlphaToHex(modeColors.primary.main, 0.08),
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            transition: "0.2s all ease",
            textDecoration: "none",
            color: modeColors.secondary.main,
            fontWeight: 600,
            "&:hover": {
              cursor: "pointer",
            },
            "&.MuiLink-underlineAlways": {
              paddingBottom: "1px",
              borderBottom: "2px solid transparent",
              "&:hover": {
                borderColor: modeColors.secondary.main,
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: mode === "light" ? colors.neutral[300] : colors.neutral[500],
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: "1rem",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
          },
          selectLabel: {
            color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
          },
          select: {
            color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderColor: "transparent",
            fontWeight: "500",
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
          },
          cell: {
            borderColor: "transparent",
            color:
              mode === "light"
                ? themeColors.light.bright
                : themeColors.dark.regular,
            "&:focus-within": {
              outline: "none",
            },
          },
          columnHeader: {
            "&:focus-within": {
              outline: "none",
            },
          },
          columnHeaders: {
            borderBottom: `1px solid ${modeColors.veryVeryMuted}`,
          },
          columnHeaderTitle: {
            fontSize: "12px",
            fontWeight: "600",
            color: mode === "light" ? colors.neutral[300] : "#A7A4BC",
            "&:hover": {
              color:
                mode === "light" ? colors.neutral[200] : colors.neutral[300],
            },
          },
          columnHeaderTitleContainer: {
            padding: 0,
          },
          columnSeparator: {
            color: mode === "light" ? colors.neutral[300] : colors.neutral[100],
          },
          menuIconButton: {
            color:
              mode === "light"
                ? colors.purple[400]
                : themeColors.dark.secondary.main,
          },
          row: {
            borderRadius: "8px",
            "&:nth-of-type(2n)": {
              background: mode === "light" ? colors.purple[600] : "#27253b",
            },
            "&:hover": {
              background: mode === "light" ? colors.purple[500] : "#3b3854",
            },
            "& .Mui-selected:hover": {
              background: mode === "light" ? colors.purple[500] : "#3b3854",
            },
            "& .Mui-selected": {
              background:
                mode === "light"
                  ? colors.purple[500] + " !important"
                  : "#3b3854  !important",
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            zIndex: 997, // below the cookie consent banner (999)
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: mode === "light" ? "#e9ebef" : "#484562",
          },
          middle: {
            width: "50%",
            alignSelf: "center",
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "origin" },
            style: {
              fontWeight: 700,
              padding: "12px 16px",
              fontSize: "16px",
              textTransform: "none",
              color: colors.origin.button.color,
              background: colors.origin.button.background.default,
              boxShadow: colors.origin.button.boxShadow.default,
              "&:hover": {
                color: colors.white,
                background: colors.origin.button.background.hover,
                boxShadow: colors.origin.button.boxShadow.hover,
              },
            },
          },
          {
            props: { variant: "doll" },
            style: {
              fontWeight: 700,
              padding: "16px 16px",
              fontSize: 24,
              borderRadius: "8px",
              textTransform: "none",
              color: colors.white,
              lineHeight: 1,
              background: colors.doll.pink,
              boxShadow: `2px 2px ${colors.doll.pinkDark}`,
              transition: "0.2s all ease",
              "&:hover": {
                transform: "scale(1.1)",
                color: colors.white,
                background: colors.doll.pink,
                boxShadow: `8px 8px ${colors.doll.pinkDark}`,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
          },
          containedPrimary: {
            "&:hover": {
              backgroundColor: mode === "light" ? "#6314AE" : "#D4B0FF",
            },
          },
          containedSecondary: {
            "&:hover": {
              backgroundColor: mode === "light" ? "#A04BE2" : "#8FFFF4",
            },
          },
          contained: {
            fontWeight: 700,
            borderRadius: "8px",
            "&:before": {
              boxShadow: "0 0 15px 3px #fff",
              content: '""',
              display: "block",
              height: "100%",
              left: "0%",
              opacity: 0,
              position: "absolute",
              top: "0%",
              transform: "skewX(-18deg)",
              width: 0,
            },
            "&:hover": {
              "&:before": {
                animation: `${shine} 0.5s 0s linear`,
              },
            },
          },
          containedSizeSmall: {
            fontSize: "13px",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 16,
            paddingRight: 16,
          },
          containedSizeMedium: {
            fontSize: "14px",
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 18,
            paddingRight: 18,
          },
          containedSizeLarge: {
            fontSize: "16px",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 22,
            paddingRight: 22,
          },
          outlined: {
            fontWeight: 700,
            borderRadius: "8px",
            "&:before": {
              transition: "0.2s all ease",
              boxShadow: "0 0 15px 3px #fff",
              content: '""',
              display: "block",
              height: "100%",
              left: "0%",
              opacity: 0,
              position: "absolute",
              top: "0%",
              transform: "skewX(-18deg)",
              width: 0,
            },
            "&:hover": {
              "&:before": {
                animation: `${shine} 0.5s 0s linear`,
              },
            },
          },
          outlinedPrimary: {
            "&:hover": {
              backgroundColor:
                mode === "light"
                  ? "rgba(138,64,207,0.08)"
                  : "rgba(197,149,255,0.08)",
            },
          },
          outlinedSecondary: {
            "&:hover": {
              backgroundColor:
                mode === "light"
                  ? "rgba(209,111,255,0.08)"
                  : "rgba(9,221,201,0.08)",
            },
          },
          outlinedSizeSmall: {
            fontSize: "13px",
            padding: "6px 16px",
          },
          outlinedSizeMedium: {
            fontSize: "14px",
            padding: "8px 18px",
          },
          outlinedSizeLarge: {
            fontSize: "16px",
            padding: "12px 22px",
          },
          text: {
            textTransform: "uppercase",
            fontWeight: 700,
          },
          textSizeSmall: {
            padding: "4px 8px",
            fontSize: "12px",
            "& > .MuiButton-startIcon": {
              marginRight: 1,
            },
          },
          textSizeMedium: {
            padding: "6px 10px",
            fontSize: "14px",
          },
          textSizeLarge: {
            padding: "8px 12px",
            fontSize: "15px",
          },
          iconSizeSmall: {
            "& > svg:first-of-type": {
              fontSize: 13,
              position: "relative",
            },
          },
          iconSizeMedium: {
            "& > svg:first-of-type": {
              fontSize: 15,
              position: "relative",
              top: 0,
            },
          },
          iconSizeLarge: {
            "& > svg:first-of-type": {
              fontSize: 16,
            },
          },
        },
      },
    },
    palette: {
      mode,
      colors: {
        axie: colors.axie,
        neutral: colors.neutral,
        purple: colors.purple,
        origin: colors.origin,
        events: colors.events,
        brands: colors.brands,
        white: colors.white,
        black: colors.black,
        doll: colors.doll,
        rarity: colors.rarity,
        axieQuest: colors.axieQuest,
        interDungeon: colors.interDungeon,
      },
      game: modeColors.game,
      primary: modeColors.primary,
      secondary: modeColors.secondary,
      feedback: modeColors.feedback,
      feedbackContrast: modeColors.feedbackContrast,
      background: modeColors.background,
      footer: modeColors.footer,
      navigation: modeColors.navigation,
      chart: modeColors.chart,
      card: modeColors.card,
      text: modeColors.text,
      axieCard: modeColors.axieCard,
      table: modeColors.table,
      input: modeColors.input,
      inspector: modeColors.inspector,
      popover: modeColors.popover,
      toggles: modeColors.toggles,
      tooltip: modeColors.tooltip,
    },
  };
};
