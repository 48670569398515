import React from "react";
import { Chip, Stack } from "@mui/material";
import Text from "components/Typography/Text";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import LargeCapsuleLabel from "pages/Inspector/atoms/LargeCapsuleLabel";

const Bonus = ({ bonus }) => {
  return (
    <LargeCapsuleLabel backgroundImage="linear-gradient(88deg, #f0f 0%, rgb(250 218 94) 100%)">
      <Chip
        variant="outlined"
        color="primary"
        size="small"
        label={bonus.label}
      />
      <Stack alignItems="center" gap={0.5}>
        <SectionLabel color="text.bright">{bonus.characteristic}</SectionLabel>
        <Text textAlign="center" style={{ lineHeight: 1.25 }}>
          {bonus.description}
        </Text>
      </Stack>
    </LargeCapsuleLabel>
  );
};

export default Bonus;
