import { matchesStr } from "helpers/display";
import { createSelector } from "reselect";
import { itemIsFavorite } from "./";

export const getCurses = (state) => {
  return state.data?.axieCardsV3.curseCards || [];
};

export const getCurseFilters = (state) => {
  return state.explorer?.curseFilters || {};
};

export const getFavoriteCurses = (state) => {
  return state.explorer?.favoriteCurses || [];
};

const applyCurseFilters = (curse, filters) => {
  if (!filters || !curse) {
    return true;
  }

  return (
    matchesStr(curse.name, filters.searchText) ||
    matchesStr(curse.description, filters.searchText)
  );
};

const applySortCurses = (a, b, sortType) => {
  if (sortType === "NAME_DESC") {
    return b.name < a.name ? -1 : a.name < b.name ? 1 : 0;
  }
  return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
};

export const getCursesToDisplay = createSelector(
  [getCurses, getCurseFilters, getFavoriteCurses],
  (curses, curseFilters, favoriteCurses) => {
    return [...curses]
      .filter((curse) => applyCurseFilters(curse, curseFilters))
      .sort((a, b) => applySortCurses(a, b, curseFilters.sortType))
      .map((curse) => ({
        ...curse,
        isFavorite: itemIsFavorite(curse, "name", favoriteCurses),
      }));
  }
);

export const getFavoriteCursesToDisplay = createSelector(
  [getFavoriteCurses],
  (favoriteCurses) => {
    return [...favoriteCurses].map((curse) => ({
      ...curse,
      isFavorite: true,
    }));
  }
);
