import { useState } from "react";
import { Stack, useTheme } from "@mui/material";
import PageHeader from "pages/Support/organisms/PageHeader";
import Section from "pages/Support/organisms/Section";
import Cards from "pages/Support/organisms/Cards";
import {
  axieSupport,
  roninSupport,
  roninGames,
  abpGames,
} from "pages/Support/data/data";
import { matchesStr, strIsInArray } from "helpers/display";
import FailedMessage from "components/FailedMessage/FailedMessage";
import { FaSearch } from "react-icons/fa";

const Support = () => {
  const theme = useTheme();
  const data = axieSupport
    .concat(roninSupport)
    .concat(roninGames)
    .concat(abpGames);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState([]);

  const filteredData = data.filter((item) => {
    return (
      (matchesStr(item.title, search) || !search) &&
      (strIsInArray(item.category.name, category) || category.length === 0)
    );
  });

  const axieData = filteredData.filter(
    (item) => item.category.name === "Axie Infinity"
  );
  const roninData = filteredData.filter(
    (item) => item.category.name === "Ronin"
  );
  const roninGamesData = filteredData.filter(
    (item) => item.category.name === "Ronin Games"
  );
  const abpGamesData = filteredData.filter(
    (item) => item.category.name === "ABP Games"
  );

  return (
    <Stack
      paddingTop={5}
      justifyContent="center"
      alignItems="center"
      gap={{ xs: 2, sm: 4, md: 4 }}
    >
      <PageHeader
        search={search}
        setSearch={setSearch}
        category={category}
        setCategory={setCategory}
      />
      {filteredData.length === 0 ? (
        <FailedMessage
          text={
            <>
              <FaSearch /> No results found, please try again
            </>
          }
        />
      ) : (
        <>
          {axieData.length !== 0 && (
            <Section
              style={{
                backgroundImage:
                  theme.palette.mode === "light"
                    ? "url(/images/support-page/backgrounds/bg-axie-support.png)"
                    : "url(/images/support-page/backgrounds/bg-axie-support-dark.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom center",
                backgroundSize: "cover",
              }}
            >
              <Cards
                title="Axie Infinity Support"
                twitter="https://twitter.com/AxieSupport"
                discord="https://discord.gg/axie"
                cards={axieData}
              />
            </Section>
          )}

          {roninData.length !== 0 && (
            <Section>
              <Cards
                title="Ronin Support"
                twitter="https://twitter.com/Ronin_Network"
                discord="https://discord.gg/roninnetwork"
                cards={roninData}
              />
            </Section>
          )}
          {roninGamesData.length !== 0 && (
            <Section>
              <Cards title="Ronin Games Support" cards={roninGamesData} />
            </Section>
          )}
          {abpGamesData.length !== 0 && (
            <Section
              style={{
                backgroundImage:
                  theme.palette.mode === "light"
                    ? "url(/images/support-page/backgrounds/bg-abp-support.png)"
                    : "url(/images/support-page/backgrounds/bg-abp-support-dark.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                paddingBottom: 16,
              }}
            >
              <Cards title="Axie Builders Games Support" cards={abpGamesData} />
            </Section>
          )}
        </>
      )}
    </Stack>
  );
};

export default Support;
