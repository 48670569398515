import { Stack, Typography, Button } from "@mui/material";
import React from "react";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import { MdInfo } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";
import PriceInDollars from "pages/Inspector/atoms/PriceInDollars";

const Header = ({ total, floorPrice, marketplaceUrl, tooltip }) => (
  <Stack direction="row" gap={4} flexWrap="wrap">
    <Info
      label="# of Listings"
      value={total}
      icon={<MdInfo />}
      tooltip={tooltip}
    />
    <Stack gap={0.5}>
      <Info label="Floor Price" value={floorPrice} />
      <PriceInDollars priceInEth={floorPrice} />
    </Stack>
    <Button
      href={marketplaceUrl}
      target="_blank"
      variant="text"
      disableElevation
      size="small"
      endIcon={<GoLinkExternal />}
      sx={{ alignSelf: "flex-start" }}
    >
      Open Marketplace
    </Button>
  </Stack>
);

const Info = ({ label, value, tooltip, icon }) => (
  <Stack gap={0.5}>
    <SectionLabel icon={icon} tooltip={tooltip}>
      {label}
    </SectionLabel>
    <Typography
      variant="span"
      fontSize={22}
      fontWeight={600}
      color="text.primary"
    >
      {value}
    </Typography>
  </Stack>
);

export default Header;
