import VariantFull from "./VariantFull";
import VariantImage from "./VariantImage";
import VariantUnknown from "./VariantUnknown";
import { CSSProperties } from "react";

interface Props {
  axie: any;
  width?: number;
  variant?: string;
  disableMarketplaceLink?: boolean;
  showGenes?: boolean;
  onClick?: any;
  selected?: boolean;
  deleteAxie?: (id: string) => void;
  refreshAxie?: (id: string) => void;
  address?: string;
  lastSold?: any;
  disableElevation?: boolean;
  id?: string;
  style?: CSSProperties;
  requiredId?: string;
  updateRequiredBreedingId?: (id: string) => void;
  axieType?: string;
  showCharms?: boolean;
  showBodyParts?: boolean;
  showRune?: boolean;
  fillSpace?: boolean;
}

const AxieV3 = ({
  axie,
  width = 320,
  variant,
  disableMarketplaceLink,
  showGenes = true,
  onClick,
  selected,
  deleteAxie,
  refreshAxie,
  address,
  lastSold,
  disableElevation,
  id,
  style,
  requiredId,
  updateRequiredBreedingId,
  showCharms,
  showBodyParts,
  showRune,
  fillSpace,
}: Props) => {
  if (variant === "image") {
    return (
      <VariantImage
        axie={axie}
        width={width}
        disableMarketplaceLink={disableMarketplaceLink}
        onClick={onClick}
        showCharms={showCharms}
        showBodyParts={showBodyParts}
        showRune={showRune}
      />
    );
  }

  if (axie == null || axie.fetching === true) {
    return (
      <VariantUnknown
        id={id}
        disableElevation={disableElevation}
        width={width}
        deleteAxie={deleteAxie}
        refreshAxie={refreshAxie}
        style={style}
        fillSpace={fillSpace}
      />
    );
  }

  return (
    <VariantFull
      axie={axie}
      width={width}
      showGenes={showGenes}
      selected={selected}
      deleteAxie={deleteAxie}
      refreshAxie={refreshAxie}
      address={address}
      lastSold={lastSold}
      disableElevation={disableElevation}
      disableMarketplaceLink={disableMarketplaceLink}
      onClick={onClick}
      updateRequiredBreedingId={updateRequiredBreedingId}
      requiredId={requiredId}
      fillSpace={fillSpace}
    />
  );
};

export default AxieV3;
