import { Stack, Tooltip } from "@mui/material";
import React from "react";

const ClassIcon = ({
  axieClass,
  width = 40,
  style,
  disableTooltip = false,
}) => {
  return axieClass == null ? null : (
    <TooltipWrapper disableTooltip={disableTooltip} axieClass={axieClass}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          "& img": {
            width,
          },
          ...style,
        }}
      >
        <img
          src={`/images/class-icons/${axieClass.toLowerCase()}.png`}
          alt={`Axie ${axieClass} Class`}
        />
      </Stack>
    </TooltipWrapper>
  );
};

const TooltipWrapper = ({ children, disableTooltip, axieClass }) =>
  disableTooltip ? (
    <>{children}</>
  ) : (
    <Tooltip title={axieClass}>{children}</Tooltip>
  );

export default ClassIcon;
