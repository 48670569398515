import * as actionTypes from "./leaderboardActionTypes";
import produce from "immer";

const initialState = {
  mmrBattleLeaderboard: { fetching: true },
};

export const guildBattleLeaderboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD_STARTED:
        draft.mmrBattleLeaderboard.fetching = true;
        draft.mmrBattleLeaderboard.failed = false;
        break;

      case actionTypes.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD_SUCCEEDED:
        draft.mmrBattleLeaderboard.fetching = false;
        draft.mmrBattleLeaderboard.data = action.data;
        break;

      case actionTypes.FETCH_GUILD_BATTLE_ARENA_LEADERBOARD_FAILED:
        draft.mmrBattleLeaderboard.fetching = false;
        draft.mmrBattleLeaderboard.failed = true;
        break;

      default:
        return state;
    }
  });

export default guildBattleLeaderboardReducer;
