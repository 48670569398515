import React from "react";
import { Stack, Box } from "@mui/material";
import { AXIE_CLASSES } from "config";
import Dropdown from "components/Origin/atoms/Dropdown";
import {
  updateRuneFilters,
  resetRuneFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";

const RuneControls = ({ runeFilters, updateRuneFilters, resetRuneFilters }) => {
  const handleUpdateRuneFilters = (newFilters) => {
    updateRuneFilters({ ...runeFilters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateRuneFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack gap={2}>
      <Search
        handleUpdate={handleUpdateRuneFilters}
        value={runeFilters.searchText}
        placeholder="Search runes"
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        }}
      >
        <Dropdown
          label="Class"
          value={runeFilters.axieClasses}
          setValue={handleUpdateDropdown("axieClasses")}
          options={["Neutral", ...AXIE_CLASSES]}
        />
        <Dropdown
          label="Rarity"
          value={runeFilters.rarities}
          setValue={handleUpdateDropdown("rarities")}
          options={["common", "rare", "epic", "mystic"]}
        />
      </Box>
      <TextButton
        onClick={resetRuneFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateRuneFilters,
  resetRuneFilters,
};

const mapStateToProps = (state) => ({
  runeFilters: state.explorer.runeFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(RuneControls);
