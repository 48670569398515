import React from "react";
import { axieIsAdult } from "helpers/axie";
import GenesDecoded from "./GenesDecoded";
import GenesSimulated from "./GenesSimulated";

const Genes = ({ axie }) => {
  return axieIsAdult(axie) ? (
    <GenesDecoded axie={axie} />
  ) : (
    <GenesSimulated axie={axie} />
  );
};

export default Genes;
