import React from "react";
import Items from "./Items";
import Paper from "./Paper";
import ClearButton from "../atoms/ClearButton";
import PaperHeader from "./PaperHeader";
import { Stack } from "@mui/material";

const Collection = ({
  title,
  totalCollected,
  grandTotal,
  items,
  type,
  collection,
  toggleItem,
  clearItems,
  view,
}) => {
  return (
    <Paper>
      <ClearButton onClick={() => clearItems(type)} />
      <Stack
        gap={{ xs: 2, sm: 2, md: 2, lg: 4 }}
        alignItems="center"
        sx={{ py: 2, px: 1, position: "relative", zIndex: 3 }}
      >
        <PaperHeader
          title={title}
          totalCollected={totalCollected}
          grandTotal={grandTotal}
        />
        <Items
          items={items}
          type={type}
          collection={collection}
          toggleItem={toggleItem}
          view={view}
        />
      </Stack>
    </Paper>
  );
};

export default Collection;
