import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Label from "components/Typography/Label";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(4),
  color: theme.palette.text.bright,
  fontWeight: 700,
  fontSize: 24,

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

const Total = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

const Totals = ({ totalBurnt, totalMinted, totalSpent }) => {
  return (
    <Wrapper>
      {totalMinted != null && (
        <Total>
          <Label>Total Minted</Label>
          {totalMinted}
        </Total>
      )}
      {totalBurnt != null && (
        <Total>
          <Label>Total Burnt</Label>
          {totalBurnt}
        </Total>
      )}
      {totalSpent != null && (
        <Total>
          <Label>Total Spent</Label>
          {totalSpent}
        </Total>
      )}
    </Wrapper>
  );
};

export default Totals;
