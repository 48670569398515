import React from "react";
import { Grid } from "@mui/material";
import Card from "components/Card/Card";
import * as guildSelectors from "store/guild/guildSelectors";
import { connect } from "react-redux";
import GuildProfile from "../molecules/GuildProfile";
import Stat from "../atoms/Stat";
import AsAtDateArena from "../atoms/AsAtDateArena";
import StatWrapper from "../atoms/StatWrapper";
import StatIcon from "../atoms/StatIcon";

const GuildStats = ({ guild, guildStats }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <GuildProfile guild={guild} />
      </Grid>

      <Grid item xs={12} sm={12} md={8}>
        <Card
          outline={true}
          style={{ display: "flex", justifyContent: "center", gap: 4 }}
        >
          <StatWrapper>
            <StatIcon type="shield" />
            <Stat label="Guild Rating" amount={guildStats.guildRating} />
            <Stat label="# of Players" amount={guildStats.numberOfScholars} />
          </StatWrapper>
          <StatWrapper>
            <StatIcon type="arena" />
            <Stat
              label="Avg Stars"
              amount={guild?.arenaLeaderboardPosition?.averageElo}
              rank={guild?.arenaLeaderboardPosition?.position}
              asAt={
                <AsAtDateArena
                  asAt={guild?.arenaLeaderboardPosition?.timestamp}
                />
              }
            />
            <Stat
              label="Arena MVP"
              amount={guildStats.arenaMVP}
              subtext={guildStats.highestMMR}
              subtextSuffix="Stars"
              amountSize="30px"
            />
          </StatWrapper>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuildStats);
