import { Stack, Button } from "@mui/material";
import Card from "components/Card/Card";
import SecondaryTitle from "pages/Home/atoms/SecondaryTitle";
import { FaArrowRight } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import Top3DollPlayers from "components/Top3DollPlayers/organisms/Top3DollPlayers";
import Header from "components/Widget/Header";
import { AXIE_DOLL_WEBSITE, AXIE_DOLL_CURRENT_SEASON } from "config";

const DoLLWidget = ({ topPlayers, loading, fetchDollLeaderboard }) => {
  return (
    <Card>
      <Stack
        gap={3}
        paddingTop={1.5}
        alignItems="center"
        justifyContent="center"
      >
        <Header
          title={`Season ${AXIE_DOLL_CURRENT_SEASON} Snapshot`}
          imageUrl="images/games/doll/axie-doll-logo.png"
          imageHref={AXIE_DOLL_WEBSITE}
          imageAltText="Axie DoLL Logo"
        />
        <Stack width="100%" gap={1} alignItems="center" justifyContent="center">
          <SecondaryTitle>Top 3 Players</SecondaryTitle>
          <Top3DollPlayers
            topPlayers={topPlayers}
            loading={loading}
            fetchDollLeaderboard={fetchDollLeaderboard}
          />
          <Button
            component={RouterLink}
            variant="text"
            size="small"
            to="/doll/leaderboard"
            endIcon={<FaArrowRight />}
            sx={{ mt: 2 }}
          >
            View Leaderboard
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default DoLLWidget;
