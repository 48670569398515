import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "width" })(
  ({ width }) => ({
    display: "inline-flex",
    width: `${(width / 200) * 30}px`,
    minWidth: `${(width / 200) * 30}px`,
    height: `${(width / 200) * 30}px`,
    minHeight: `${(width / 200) * 30}px`,
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.8)",
    alignItems: "center",
    justifyContent: "center",

    img: {
      width: `${(width / 200) * 20}px`,
      height: `${(width / 200) * 20}px`,
    },
  })
);

const BodyPart = ({ bodyPart = "", alt, title, width }) => {
  const bodyPartImg = `/images/templates/card/body-parts/${bodyPart.toLowerCase()}.png`;
  return (
    <Wrapper width={width}>
      <img src={bodyPartImg} title={title} alt={alt} />
    </Wrapper>
  );
};

export default BodyPart;
