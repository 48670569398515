import * as actionTypes from "./leaderboardActionTypes";
import produce from "immer";

const initialState = {
  mmrLeaderboard: { fetching: true },
};

export const guildLeaderboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.FETCH_SCHOLAR_ARENA_LEADERBOARD_STARTED:
        draft.mmrLeaderboard.fetching = true;
        draft.mmrLeaderboard.failed = false;
        break;

      case actionTypes.FETCH_SCHOLAR_ARENA_LEADERBOARD_SUCCEEDED:
        draft.mmrLeaderboard.fetching = false;
        draft.mmrLeaderboard.data = action.data;
        break;

      case actionTypes.FETCH_SCHOLAR_ARENA_LEADERBOARD_FAILED:
        draft.mmrLeaderboard.fetching = false;
        draft.mmrLeaderboard.failed = true;
        break;

      default:
        return state;
    }
  });

export default guildLeaderboardReducer;
