import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2023-09-01</Date>
      <Change type="new">
        Added a new
        <CustomLink to="/axie-ronin-support" endSpace={true}>
          Axie & Ronin Support
        </CustomLink>
        page!
      </Change>
    </Entry>
  );
};

export default Changes;
