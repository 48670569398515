import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { connect } from "react-redux";
import { addTeam } from "store/guildDashboard/actions/guildDashboardActions";
import { useWeb3React } from "@web3-react/core";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import LoadingButton from "@mui/lab/LoadingButton";
import Error from "components/Typography/Error";

const AddTeamDialog = ({ open, setOpen, guild, addTeam }) => {
  const { account } = useWeb3React();
  const [teamName, setTeamName] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState("");

  const clearAllInputs = useCallback(() => {
    setTeamName("");
    setIsPublic(true);
    setError("");
  }, [setTeamName, setIsPublic, setError]);

  const handleClose = useCallback(() => {
    setOpen(false);
    clearAllInputs();
  }, [setOpen, clearAllInputs]);

  useEffect(() => {
    if (guild.addingTeam === false && guild.addingTeamFailed === true) {
      setError("Something went wrong...");
    } else if (guild.addingTeam === false && guild.addingTeamFailed === false) {
      handleClose();
    }
  }, [guild.addingTeam, guild.addingTeamFailed, handleClose]);

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleAddTeam(e);
    }
    if (e.which === 27) {
      setOpen(false);
    }
  };

  const handleUpdateIsPublic = (e) => {
    setIsPublic(e.target.checked);
  };

  const handleAddTeam = () => {
    if (teamName === "") {
      setError("Team name cannot be empty.");
    } else {
      addTeam(account, guild.guildId, {
        name: teamName,
        isPublic,
      });
    }
  };

  const publicLabel = isPublic ? <>Public</> : <>Private</>;
  const publicMessage = isPublic ? (
    <>
      Scholars in this team <strong>are visible</strong> on your public
      leaderboard.
    </>
  ) : (
    <>
      Scholars in this team <strong>are not visible</strong> on your public
      leaderboard.
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="add-team-dialog-title"
      sx={{ "& .MuiPaper-root": { width: "360px" } }}
    >
      <DialogTitle id="add-team-dialog-title">Add Team</DialogTitle>

      <form onKeyPress={(e) => handleKeypress(e)}>
        <DialogContent dividers={true}>
          <Stack gap={2}>
            <Error>{error}</Error>
            <TextField
              autoFocus
              onChange={(e) => setTeamName(e.target.value)}
              label="Team Name"
              variant="filled"
              size="small"
              value={teamName}
              required
              spellCheck={false}
            />

            <Stack gap={1}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                direction="row"
              >
                <Typography
                  variant="span"
                  color="text.regular"
                  fontWeight="600"
                  fontSize="16px"
                >
                  {publicLabel}
                </Typography>
                <CustomSwitch
                  checked={isPublic}
                  onChange={handleUpdateIsPublic}
                />
              </Stack>
              <Typography
                variant="span"
                fontSize="14px"
                fontWeight="500"
                color="text.muted"
                lineHeight={1.4}
              >
                {publicMessage}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          {guild.addingTeam ? (
            <LoadingButton
              variant="contained"
              loading
              sx={{ alignSelf: "stretch" }}
            />
          ) : (
            <Button onClick={handleAddTeam} variant="contained">
              OK
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapDispatchToProps = {
  addTeam,
};

const mapStateToProps = (state) => ({ guild: state.guildDashboard.guild });

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamDialog);
