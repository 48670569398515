import { Stack, IconButton } from "@mui/material";
import React from "react";
import { IoCloseCircle } from "react-icons/io5";

const ClickableItem = ({ onClick, deleteItem, children }) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    deleteItem();
  };

  return (
    <Stack
      onClick={onClick}
      sx={{
        userSelect: "none",
        position: "relative",
        transition: "0.2s transform ease",
        "& .delete": {
          opacity: 0,
          position: "absolute",
          right: 8,
          top: 8,
          zIndex: 8,
          color: "#fff",
        },
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.05)",
          "& .delete": {
            opacity: 1,
            filter: "drop-shadow(0px 0px 4px rgba(0,0,0,0.9))",
          },
        },
        "&:active": {
          transform: "scale(0.95)",
        },
      }}
    >
      {children}
      <IconButton className="delete" onClick={handleDelete}>
        <IoCloseCircle />
      </IconButton>
    </Stack>
  );
};
export default ClickableItem;
