import { Stack } from "@mui/material";

const PlayerRankIcon = ({ rank }) => {
  const rankImage = rank?.toLowerCase() || null;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className="rankIcon"
      sx={{
        width: 24,
        height: 24,
        background: (theme) => theme.palette.colors.purple[700],
        backgroundSize: "130%",
        backgroundPosition: "center center",
        backgroundImage: `url('/images/icons/arena-ranks/${rankImage}.png')`,
        borderRadius: "50%",
        overflow: "hidden",
      }}
    />
  );
};

export default PlayerRankIcon;
