import { useEffect } from "react";
import { connect } from "react-redux";
import PageHeader from "components/PageHeader/PageHeader";
import Leaderboard from "components/Leaderboard";
import { GUILD_ARENA_LEADERBOARD } from "config";
import { fetchGuildMMRLeaderboard } from "store/leaderboard/guild/leaderboardActions";
import PullUp from "components/Utility/PullUp";
import OtherLeaderboardButtons from "components/Leaderboard/molecules/OtherLeaderboardButtons";
import { Typography } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import FullWidthWrapper from "../FullWidthWrapper";
import { getGuildLeaderboardData } from "store/leaderboard/guild/guildLeaderboardSelector";

const GuildLeaderboard = ({
  fetchGuildMMRLeaderboard,
  mmrLoading,
  mmrFailed,
  data,
}) => {
  useEffect(() => {
    fetchGuildMMRLeaderboard();
  }, [fetchGuildMMRLeaderboard]);

  const pageDescription = (
    <>
      <Typography variant="span">
        Wondering how your squad would fare? <br /> Click here to learn more
        about our{" "}
        <CustomLink to="/guild-membership" underline="none" endSpace={false}>
          Guild Membership
        </CustomLink>
        .
      </Typography>
    </>
  );

  return (
    <>
      <PageHeader
        title="Top 25 Guilds"
        description={pageDescription}
        centered
      />
      <FullWidthWrapper>
        <PullUp>
          <Leaderboard
            type={GUILD_ARENA_LEADERBOARD}
            data={data.top25}
            loading={mmrLoading}
            failed={mmrFailed}
            retry={fetchGuildMMRLeaderboard}
          />
        </PullUp>

        <OtherLeaderboardButtons buttons={["BATTLE", "ORIGIN"]} />
      </FullWidthWrapper>
    </>
  );
};

const mapDispatchToProps = {
  fetchGuildMMRLeaderboard,
};

const mapStateToProps = (state) => ({
  data: getGuildLeaderboardData(state),
  mmrLoading: state.guildLeaderboard.mmrLeaderboard.fetching,
  mmrFailed: state.guildLeaderboard.mmrLeaderboard.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(GuildLeaderboard);
