import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Stack } from "@mui/material";
import CardTemplate from "components/GameTemplates/CardTemplate";
import CharmTemplate from "components/GameTemplates/CharmTemplate";
import RuneTemplate from "components/GameTemplates/RuneTemplate";
import { ITEM_WIDTH } from "pages/TeamBuilder";
import Controls from "./Controls";
import { canEquipCharm } from "helpers/axie";
import ClickableItem from "./ClickableItem";
import Title from "./Title";
import Buttons from "./Buttons";
import { calculatePotentialPointsUsed } from "helpers/items";

const ItemDialog = ({
  open,
  setDialogClosed,
  data,
  updateCard,
  updateCharm,
  updateRune,
}) => {
  const [item, setItem] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (item !== "") {
      if (data.itemType === "cards") {
        setTitle(`Selected: ${item.cardName}`);
      } else if (data.itemType === "charms") {
        setTitle(`Selected: ${item.name}`);
      } else if (data.itemType === "runes") {
        setTitle(`Selected: ${item.name}`);
      }
    }
  }, [item, data.itemType]);

  const handleClose = () => {
    setDialogClosed();
    setItem("");
    setTitle("");
  };

  const selectItem = (option) => {
    setItem(option);
  };

  const handleUpdateItem = () => {
    if (item !== "") {
      if (data.itemType === "cards") {
        updateCard(data.axieId, data.part, item);
        updateCharm(data.axieId, data.part, undefined);
      }

      if (data.itemType === "charms") {
        const potentialPointsUsed = calculatePotentialPointsUsed(
          item,
          data.currentCard.class,
          data.potentialPoints
        );
        updateCharm(data.axieId, data.part, item, potentialPointsUsed);
      }

      if (data.itemType === "runes") {
        updateRune(data.axieId, item);
      }

      handleClose();
    }
  };

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleUpdateItem();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={true}
      aria-labelledby="item-dialog"
      onKeyPress={handleKeypress}
    >
      <Title data={data} title={title} />

      <DialogContent dividers={true}>
        <Stack gap={4}>
          <Controls itemType={data.itemType} />
          <Stack
            gap={5}
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            {data.options.map((option, i) => {
              if (data.itemType === "cards") {
                const isSelected = item.partId === option.partId;
                return (
                  <ClickableItem
                    itemType={data.itemType}
                    isSelected={isSelected}
                    key={option.id}
                    onClick={() => selectItem(option)}
                  >
                    <CardTemplate card={option} width={ITEM_WIDTH * 1.2} />
                  </ClickableItem>
                );
              } else if (data.itemType === "charms") {
                const isSelected = item.name === option.name;
                return (
                  <ClickableItem
                    itemType={data.itemType}
                    isSelected={isSelected}
                    key={option.id}
                    canEquip={canEquipCharm(option, data.potentialPoints)}
                    onClick={() => selectItem(option)}
                  >
                    <CharmTemplate charm={option} width={ITEM_WIDTH * 1.2} />
                  </ClickableItem>
                );
              } else if (data.itemType === "runes") {
                const isSelected = item.name === option.name;
                return (
                  <ClickableItem
                    itemType={data.itemType}
                    isSelected={isSelected}
                    key={option.id}
                    onClick={() => selectItem(option)}
                  >
                    <RuneTemplate rune={option} width={ITEM_WIDTH * 1.2} />
                  </ClickableItem>
                );
              } else return null;
            })}
          </Stack>
        </Stack>
      </DialogContent>

      <Buttons handleClose={handleClose} handleUpdateItem={handleUpdateItem} />
    </Dialog>
  );
};

export default ItemDialog;
