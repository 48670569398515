import * as types from "./chartsActionTypes";

export const fetchSlpIssuance = () => ({
  type: types.CHARTS_FETCH_SLP_ISSUANCE,
});

export const fetchSlpIssuanceStarted = () => ({
  type: types.CHARTS_FETCH_SLP_ISSUANCE_STARTED,
});

export const fetchSlpIssuanceSucceeded = (data) => ({
  type: types.CHARTS_FETCH_SLP_ISSUANCE_SUCCEEDED,
  data,
});

export const fetchSlpIssuanceFailed = () => ({
  type: types.CHARTS_FETCH_SLP_ISSUANCE_FAILED,
});

export const updateSlpIssuanceBlockchainControls = (controls) => ({
  type: types.CHARTS_UPDATE_SLP_ISSUANCE_BLOCKCHAIN_CONTROLS,
  controls,
});

export const updateSlpIssuanceInGameControls = (controls) => ({
  type: types.CHARTS_UPDATE_SLP_ISSUANCE_INGAME_CONTROLS,
  controls,
});
