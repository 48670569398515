import React from "react";
import { Stack, useTheme } from "@mui/material";
import SectionLabel from "../atoms/SectionLabel";
import { icon } from "components/Axie/icons/classificationIcons";
import Text from "components/Typography/Text";

const RoleSpecialty = ({ axie }) => {
  return axie.role == null && axie.specialty == null ? (
    <></>
  ) : (
    <Stack gap={1.5} alignItems="flex-start">
      <SectionLabel>Classification</SectionLabel>

      <Stack direction="row" gap={1} flexWrap="wrap">
        <Classification classification={axie.role} />
        <Classification classification={axie.specialty} />
      </Stack>
    </Stack>
  );
};

const Classification = ({ classification }) => {
  const theme = useTheme();
  return classification == null ? (
    <></>
  ) : (
    <Stack
      gap={0.5}
      direction="row"
      alignItems="center"
      sx={{
        background: theme.palette.card.background,
        borderRadius: "16px",
        boxShadow: `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
        py: 0.75,
        px: 1.25,
        fontSize: 14,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.1)",
          cursor: "pointer",
        },
        svg: {
          height: 16,
          width: "auto",
        },
      }}
    >
      {icon(classification, theme.palette.text.regular)}
      <Text>{classification}</Text>
    </Stack>
  );
};

export default RoleSpecialty;
