import React from "react";
import { Button } from "@mui/material";

const ToggleButton = ({ children, isActive, onClick }) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        opacity: isActive ? 1 : 0.8,
        fontWeight: isActive ? 800 : 500,
        letterSpacing: 0.25,
        color: "#fff",
        fontSize: 14,
        padding: 0,
        "&:hover": {
          background: "none",
          opacity: 1,
        },
      }}
    >
      {children}
    </Button>
  );
};

export default ToggleButton;
