import { combineReducers } from "redux";
import dollLeaderboardReducer from "./leaderboard/reducer";
import dollSidekicksReducer from "./sidekicks/reducer";
import dollCardsReducer from "./cardExplorer/reducer";

const dollReducer = combineReducers({
  leaderboard: dollLeaderboardReducer,
  sidekicks: dollSidekicksReducer,
  skills: dollCardsReducer,
});

export default dollReducer;
