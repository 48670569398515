import React from "react";
import { IconButton, Tooltip, Typography, Stack } from "@mui/material";

const SectionLabel = ({
  children,
  color = "text.muted",
  textAlign,
  icon,
  tooltip,
  iconUrl,
  fontSize = 17,
}) => (
  <Stack direction="row" gap={0.25} alignItems="center">
    <Typography
      variant="span"
      fontSize={fontSize}
      color={color}
      fontWeight="600"
      textAlign={textAlign ? textAlign : "left"}
      sx={{ lineHeight: 1.4, whiteSpace: "nowrap" }}
    >
      {children}
    </Typography>
    {icon != null && (
      <Tooltip title={tooltip}>
        <IconButton
          size="small"
          href={iconUrl}
          target="_blank"
          sx={{
            opacity: 0.5,
            transition: "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&:hover": { opacity: 1 },
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    )}
  </Stack>
);

export default SectionLabel;
