import * as types from "./guildActionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import { axieTechAPI } from "../sagaHelpers";
import * as actions from "./guildActions";
import endpoints from "services/api/endpoints";

export function* fetchGuild(action) {
  try {
    yield put(actions.fetchGuildStarted());
    const params = {
      method: "GET",
    };
    const data = yield call(
      axieTechAPI,
      endpoints.constructGuildScholars(action.guildSlug),
      params
    );
    yield put(actions.fetchGuildSucceeded(data));
  } catch (e) {
    console.log("Error fetching guild: ", e);
    yield put(actions.fetchGuildFailed());
  }
}

export default function* guildSaga() {
  yield takeLatest(types.FETCH_GUILD, fetchGuild);
}
