import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  padding: (width / 200) * 6,
  gap: (width / 200) * 5,
  textAlign: "center",
  color: "#fff",
  zIndex: 2,
  position: "absolute",
  top: "53%",
}));

const SkillName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  fontSize: (width / 200) * 20,
  fontWeight: 700,
  textShadow: "2px 2px 0px rgba(0, 0, 0, 0.25)",
}));

const SkillDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  fontSize: (width / 200) * 12.5,
  fontWeight: 600,
  lineHeight: 1.22,
  textShadow: "1px 0px 1px rgba(0, 0, 0, 0.25)",
}));

const CardInfo = ({ width, name, description }) => {
  return (
    <Wrapper width={width}>
      <SkillName width={width} variant="span">
        {name}
      </SkillName>
      <SkillDescription width={width} variant="span">
        {description}
      </SkillDescription>
    </Wrapper>
  );
};

export default CardInfo;
