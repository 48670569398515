import React from "react";
import PlayerControls from "../molecules/PlayerControls";
import { Stack, Typography, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { calculateMMR } from "helpers/scholars";
import TeamStat from "../atoms/TeamStat";
import {
  makeScholarPageURLFromRoninAddress,
  makeTeamPageURLFromGuildTeamId,
} from "helpers/guild";
import { getScholarTeamName } from "helpers/team";

const PlayerSidebar = ({ scholar, guild, handleFetchAxiesForScholar }) => {
  const teamName = getScholarTeamName(scholar, guild.teams);

  return (
    <Stack gap={4}>
      <PlayerControls
        scholar={scholar}
        handleFetchAxiesForScholar={handleFetchAxiesForScholar}
      />

      <Stack gap={2} alignItems="flex-start">
        <Stack gap={0.5}>
          <Link
            to={makeScholarPageURLFromRoninAddress(scholar.roninAddress)}
            state={{ prevPath: "/guild-dashboard/axies" }}
          >
            <Typography
              variant="body2"
              color="text.bright"
              fontWeight="700"
              fontSize="26px"
              sx={{ lineHeight: 1.2 }}
            >
              {scholar.name}
            </Typography>
          </Link>
          {teamName != null && (
            <Link to={makeTeamPageURLFromGuildTeamId(scholar.guildTeamId)}>
              <Typography
                variant="span"
                color="text.regular"
                fontWeight="500"
                fontSize="17px"
              >
                {teamName}
              </Typography>
            </Link>
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack gap={1.5}>
        <TeamStat label="Stars" amount={calculateMMR(scholar)} />
        <TeamStat label="Total Axies" amount={scholar.numAxies} />
        <TeamStat label="Adults" amount={scholar.numAdults} />
        <TeamStat label="Eggs" amount={scholar.numEggs} />
      </Stack>
    </Stack>
  );
};

export default PlayerSidebar;
