import { createSelector } from "reselect";

const getGuildBattleLeaderboard = (state) =>
  state.guildBattleLeaderboard?.mmrBattleLeaderboard?.data || [];

export const getGuildBattleLeaderboardData = createSelector(
  [getGuildBattleLeaderboard],
  (leaderboardData) => {
    const mappedData = leaderboardData.map((teamData, _i) => {
      return {
        id: _i + 1,
        ...teamData,
      };
    });

    const top8 = mappedData.slice(0, 8);
    const top16 = mappedData.slice(8, 16);
    const top25 = mappedData.slice(16);

    return {
      top8,
      top16,
      top25,
    };
  }
);
