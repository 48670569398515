import AxieV3 from "components/Axie/AxieV3/AxieV3";
import { Stack, Typography } from "@mui/material";

const ScholarAxies = ({ axies }) => {
  return (
    <Stack gap={2} alignItems={{ xs: "center", sm: "flex-start" }}>
      {axies && axies.length > 0 ? (
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          {axies.map((axie, i) => (
            <AxieV3 key={`AXIE_${i} `} axie={axie} variant="full" />
          ))}
        </Stack>
      ) : (
        <NoAxies />
      )}
    </Stack>
  );
};

const NoAxies = () => (
  <Stack gap={2} sx={{ width: "100%", marginTop: 8 }}>
    <Typography
      variant="span"
      color="text.muted"
      fontSize="20px"
      fontWeight="700"
      textAlign="center"
    >
      Wer axies?
    </Typography>
    <Typography
      variant="span"
      color="text.regular"
      fontSize="16px"
      fontWeight="600"
      textAlign="center"
    >
      This scholar doesn't have any axies.
    </Typography>
  </Stack>
);

export default ScholarAxies;
