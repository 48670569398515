import { Stack } from "@mui/material";
import Parents from "./Parents";
import Genes from "./Genes";
import Rarity from "./Rarity";
import PriceRecommendations from "./PriceRecommendations";
import EstimatedPrice from "./EstimatedPrice";
import Market from "./Market";
import CurrentPrice from "./CurrentPrice";
import TwoColumnLayout from "pages/Inspector/atoms/TwoColumnLayout";
import CurrentLevel from "./CurrentLevel";
import NextLevel from "./NextLevel";

const Profile = ({ inspector, axie, setView }) => {
  return (
    <TwoColumnLayout>
      <Stack gap={4}>
        <Genes axie={axie} />
        <Parents
          parentAxies={inspector.parents.parentAxies}
          isOrigin={axie.isOrigin}
        />
      </Stack>

      {axie.isAdult && (
        <Stack gap={6} sx={{ maxWidth: 340 }}>
          <Rarity axie={axie} />
          {axie.axpInfo && (
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              gap={2}
            >
              <CurrentLevel
                level={axie.axpInfo.level ? axie.axpInfo.level : null}
                axp={axie.axpInfo.xp ? axie.axpInfo.xp : null}
                onchainLevel={
                  axie.axpInfo.onchainLevel ? axie.axpInfo.onchainLevel : null
                }
              />
              <NextLevel
                nextOnchainLevel={
                  axie.axpInfo.nextOnchainLevel
                    ? axie.axpInfo.nextOnchainLevel
                    : null
                }
                axpToLevelUp={
                  axie.axpInfo.xpToLevelUp ? axie.axpInfo.xpToLevelUp : null
                }
                shouldAscend={
                  axie.axpInfo.shouldAscend ? axie.axpInfo.shouldAscend : null
                }
              />
            </Stack>
          )}
          <PriceRecommendations
            priceRecommendations={axie.priceRecommendations}
            cannotPrice={axie.cannotPrice}
          />
          <EstimatedPrice estimatedPrice={inspector.estimatedPrice} />
          <Market
            loading={inspector.market.loading}
            total={inspector.market.total}
            label={inspector.market.label}
            floorPrice={inspector.market.floorPrice}
            marketplaceUrl={inspector.market.marketplaceUrl}
            tooltip={inspector.market.tooltip}
            setView={setView}
          />
          <CurrentPrice currentPrice={axie.currentPrice} />
        </Stack>
      )}
    </TwoColumnLayout>
  );
};

export default Profile;
