import React from "react";
import { runes } from "icons/icons";
import ClickableSlotCondensed from "./ClickableSlotCondensed";
import ClickableItem from "./ClickableItem";
import { ITEM_WIDTH } from "pages/TeamBuilder";
import RuneTemplate from "components/GameTemplates/RuneTemplate";
import ClickableSlot from "./ClickableSlot";

const RuneSlot = ({ data, axie, updateRune, handleOpenRuneDialog, view }) => {
  const deleteRune = () => updateRune(axie.id, undefined);

  const handleOpen = () => {
    handleOpenRuneDialog(axie);
  };

  if (view === "condensed") {
    return (
      <ClickableSlotCondensed
        onClick={handleOpen}
        deleteItem={deleteRune}
        icon={runes}
        value={data?.name}
      />
    );
  }

  if (data == null) {
    return <ClickableSlot itemType="rune" icon={runes} onClick={handleOpen} />;
  }
  return (
    <ClickableItem onClick={handleOpen} deleteItem={deleteRune} data={data}>
      <RuneTemplate rune={data} width={ITEM_WIDTH} />
    </ClickableItem>
  );
};

export default RuneSlot;
