import { Typography } from "@mui/material";
import React from "react";

const Title = () => {
  return (
    <Typography textAlign="center" variant="h1" color="#fff">
      CraftingDex
    </Typography>
  );
};

export default Title;
