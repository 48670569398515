import { Stack, Typography, Tooltip, useTheme } from "@mui/material";
import { specialEffectIcons } from "components/Axie/icons/specialEffectIcons";
import { CardColors } from "theme/colors";

interface Props {
  data: any;
  width: number;
  title?: string;
  addCardsToLabel?: boolean;
  noWrapper?: boolean;
  gapMultiplier?: number;
}

const CardSummary = ({
  data,
  width,
  title,
  addCardsToLabel = false,
  noWrapper = true,
  gapMultiplier = 1,
}: Props) => {
  const theme = useTheme();
  const mapping: {
    [key in keyof CardColors]: {
      icon: string;
      label: string;
    };
  } = {
    cardEnergy: {
      icon: "energy_gain",
      label: "energy",
    },
    cardDamage: {
      icon: "attack",
      label: "damage",
    },
    cardShield: {
      icon: "shield",
      label: "shield",
    },
    cardHeal: {
      icon: "healStar",
      label: "heal",
    },
    skill: { icon: "tagSkill", label: "skill" },
    ethereal: { icon: "tagEthereal", label: "ethereal" },
    banish: { icon: "tagBanish", label: "banish" },
    innate: { icon: "tagInnate", label: "innate" },
    secret: { icon: "tagSecret", label: "secret" },
    power: { icon: "tagPower", label: "power" },
    attack: { icon: "tagDamage", label: "damage" },
    retain_effect: { icon: "tagRetain", label: "retain" },
  };

  const height = (15 / 320) * width;

  return data != null ? (
    <Wrapper title={title} noWrapper={noWrapper}>
      <Stack
        direction="row"
        alignItems="center"
        gap={(0.5 / 320) * width * gapMultiplier}
      >
        {Object.keys(data).map((key: keyof CardColors, i) => (
          <Tooltip
            title={`${data[key]} ${mapping[key].label} ${
              addCardsToLabel ? (data[key] === 1 ? "card" : "cards") : ""
            }`}
            key={`STAT_${i}`}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={((1 / 320) * width) / 4}
              sx={{ cursor: "pointer" }}
            >
              {key in mapping && (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    height: height,
                    width: height,
                    "& svg": { height: height * 0.9, width: "auto" },
                  }}
                >
                  {specialEffectIcons[mapping[key].icon](
                    theme.palette.game.cards[key]
                  )}
                </Stack>
              )}
              <Typography
                component="span"
                fontSize={height}
                fontWeight={700}
                color={theme.palette.game.cards[key]}
              >
                {data[key]}
              </Typography>
            </Stack>
          </Tooltip>
        ))}
      </Stack>
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = ({
  children,
  title,
  noWrapper,
}: {
  children: React.ReactNode;
  title?: string;
  noWrapper: boolean;
}) => {
  const theme = useTheme();

  if (noWrapper) {
    return <>{children}</>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      justifyContent="center"
      sx={{
        py: 1,
        px: 1.5,
        borderRadius: "6px",
        boxShadow: `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
        background: theme.palette.card.background,
      }}
    >
      {title != null && (
        <Typography
          fontWeight={700}
          color="text.regular"
          fontSize={13}
          sx={{ textTransform: "uppercase" }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Stack>
  );
};

export default CardSummary;
