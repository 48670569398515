import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Page from "components/Utility/Page";
import { fetchGuild } from "store/guild/guildActions";
import GuildLeaderboard from "./organisms/GuildLeaderboard";
import GuildLoading from "./atoms/GuildLoading";
import GuildError from "./atoms/GuildError";
import GuildStats from "./organisms/GuildStats";
import PageSEO from "components/Utility/PageSEO";
import { AXIE_TECH_VIP_MEMBERSHIP_TAGLINE } from "config";

const Guild = ({ fetchGuild, guild }) => {
  const { guildSlug } = useParams();

  useEffect(() => {
    if (guildSlug != null) {
      fetchGuild(guildSlug);
    }
  }, [guildSlug, fetchGuild]);

  return (
    <>
      <PageSEO
        title={`${
          guild.name ? guild.name + " | " : ""
        } VIP Guild Profile | Axie Tech`}
        description={AXIE_TECH_VIP_MEMBERSHIP_TAGLINE}
      />

      <Page>
        {guild.fetching ? (
          <GuildLoading />
        ) : guild.failed ? (
          <GuildError />
        ) : (
          <>
            <GuildStats guild={guild} />
            <GuildLeaderboard />
          </>
        )}
      </Page>
    </>
  );
};

const mapDispatchToProps = { fetchGuild };

const mapStateToProps = (state) => ({
  guild: state.guild,
});

export default connect(mapStateToProps, mapDispatchToProps)(Guild);
