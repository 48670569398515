import { TextField, IconButton, InputAdornment, useTheme } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const Search = ({ placeholder, width = 300, searchText, updateSearchText }) => {
  const theme = useTheme();
  return (
    <TextField
      hiddenLabel
      value={searchText}
      onChange={updateSearchText}
      variant="filled"
      autoComplete="off"
      spellCheck={false}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            position="end"
            onClick={() =>
              updateSearchText({
                target: {
                  value: "",
                },
              })
            }
            sx={{ color: "#fff" }}
          >
            <IoCloseCircle />
          </IconButton>
        ),
      }}
      sx={{
        width,
        "& .MuiFilledInput-root": {
          borderRadius: 2,
          background: theme.palette.colors.interDungeon.controls.teal,
          height: 48,
          paddingLeft: "20px",
          border: `solid 1px transparent`,

          "& .MuiInputAdornment-root": {
            color: "#fff",
            opacity: 0.9,
          },

          "& .MuiIconButton-root": {
            color: searchText === "" ? "transparent" : "#fff",
          },

          "&:hover, &:active, &.Mui-focused": {
            boxShadow: "none",
            border: `solid 1px #fff`,
            background: theme.palette.colors.interDungeon.controls.teal,

            "& .MuiIconButton-root": {
              color: "#fff",
            },
          },

          "&.Mui-focused": {
            "& .MuiFilledInput-input": {
              "&::placeholder": {
                opacity: 0,
              },
            },
          },

          "&::after, &::before": {
            display: "none",
          },
        },
        "& .MuiFilledInput-input": {
          background: "transparent",
          boxShadow: "none",
          border: "none",
          paddingLeft: "8px",
          color: "#fff",
          fontWeight: 600,
          fontSize: 14,

          "&:focus": {
            background: "transparent",
          },

          "&::placeholder": {
            color: "#fff",
            opacity: 1,
            fontWeight: 600,
            fontSize: 14,
          },
        },
        [theme.breakpoints.down("lg")]: {
          width: "100%",
        },
      }}
    />
  );
};

export default Search;
