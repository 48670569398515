import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Stack, Typography } from "@mui/material";
import {
  displayShortenedRoninAddress,
  makeMarketplaceURLFromRoninAddress,
  toggleTextWithDelay,
} from "helpers/display";
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CustomLink from "components/CustomLink/CustomLink";
import { FaExternalLinkSquareAlt } from "react-icons/fa";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  background: theme.palette.card.pop1,
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.muted,
  fontFamily: "monospace",
  "&:hover": {
    cursor: "pointer",
    "& .MuiTypography-span": {
      color: theme.palette.text.regular,
      transition: "0.2s all ease",
    },
  },
  "& .MuiLink-root": {
    transition: "0.2s all ease",
    color: theme.palette.text.muted,
    "&:hover": {
      color: theme.palette.text.regular,
    },
  },
}));

const CopyAddress = ({ address, disableHover, style, destinationTab }) => {
  if (address == null) return null;

  let destinationUrl = makeMarketplaceURLFromRoninAddress(
    address,
    destinationTab
  );

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <CopyTooltipIfEnabled disableHover={disableHover} address={address}>
        <Wrapper style={style}>
          <CopyToClipboard text={address}>
            <Typography variant="span" sx={{ lineHeight: 1 }}>
              {displayShortenedRoninAddress(address)}
            </Typography>
          </CopyToClipboard>
        </Wrapper>
      </CopyTooltipIfEnabled>

      <CustomLink href={destinationUrl} style={{ display: "flex" }}>
        <MarketTooltipIfEnabled disableHover={disableHover}>
          <Wrapper>
            <FaExternalLinkSquareAlt />
          </Wrapper>
        </MarketTooltipIfEnabled>
      </CustomLink>
    </Stack>
  );
};

const CopyTooltipIfEnabled = ({ children, address, disableHover }) => {
  const [copyRoninAddressTooltip, setCopyRoninAddressTooltip] = useState("");

  return disableHover ? (
    <>{children}</>
  ) : (
    <CustomWidthTooltip
      onClick={() =>
        toggleTextWithDelay(setCopyRoninAddressTooltip, "", "Copied", 1000)
      }
      title={copyRoninAddressTooltip === "" ? address : copyRoninAddressTooltip}
    >
      {children}
    </CustomWidthTooltip>
  );
};

const MarketTooltipIfEnabled = ({ children, disableHover }) =>
  disableHover ? (
    <>{children}</>
  ) : (
    <Tooltip title="View on Marketplace">{children}</Tooltip>
  );

export default CopyAddress;
