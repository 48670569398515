import { Stack } from "@mui/material";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import Search from "components/CustomSearch/Search";

const Controls = ({ search, setSearch, category, setCategory }) => {
  const handleSearch = (searchValue) => {
    setSearch(searchValue);
  };

  const categoryOptions = [
    "Axie Infinity",
    "Ronin",
    "Ronin Games",
    "ABP Games",
  ];
  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "row" }}
      gap={2}
      alignItems="center"
      justifyContent="center"
    >
      <Search
        searchText={search}
        updateSearchText={(e) => handleSearch(e.target.value)}
        placeholder="Search"
        width={300}
        variant="filled"
        style={{ ".MuiInputBase-input": { maxHeight: "1em" } }}
      />

      <CustomMultiSelect
        label="Category"
        value={category}
        setValue={setCategory}
        options={categoryOptions || []}
        fullWidth={false}
        minWidth={200}
      />
    </Stack>
  );
};

export default Controls;
