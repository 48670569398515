import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import { getOriginCardArtUrl } from "helpers/card";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  position: "relative",
  width,
  height: width,
}));

const CardArt = ({ cardImage, alt, width }) => {
  const cardArtImageUrl = getOriginCardArtUrl(cardImage);

  return (
    <Wrapper width={width}>
      <img
        style={{ objectFit: "cover" }}
        width="100%"
        src={cardArtImageUrl}
        title={alt}
        alt={alt}
      />
    </Wrapper>
  );
};

export default CardArt;
