import { useState, useEffect } from "react";
import { connect } from "react-redux";
import ScholarTable from "./organisms/ScholarTables";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import { HiPlus } from "react-icons/hi";
import AddScholarDialog from "./organisms/AddScholarDialog";
import {
  setActiveTeam,
  updateActiveTabScholarsTable,
  updateCurrentPageScholarsTable,
  updateSortModelScholarsTable,
} from "store/guildDashboard/actions/guildDashboardActions";
import { Button, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageTitleWithChips from "pages/GuildDashboard/molecules/PageTitleWithChips";

const Scholars = ({
  guildStats,
  setActiveTeam,
  currentPage,
  updateCurrentPage,
  activeTab,
  updateActiveTab,
  sortModel,
  updateSortModel,
}) => {
  const { state, pathname } = useLocation();
  const [openAddScholarDialog, setOpenAddScholarDialog] = useState(false);
  useEffect(() => {
    setActiveTeam(0, state?.prevPath !== "SCHOLAR_PAGE");
  }, [setActiveTeam, state?.prevPath]);
  return (
    <>
      <Stack
        gap={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <PageTitleWithChips
          title="Scholars"
          chips={[`${guildStats.overallStats.numberOfScholars} Total`]}
        />
        <Button
          variant="outlined"
          color="secondary"
          endIcon={<HiPlus />}
          onClick={() => setOpenAddScholarDialog(true)}
        >
          Add Scholar
        </Button>
      </Stack>
      <AddScholarDialog
        open={openAddScholarDialog}
        setOpen={setOpenAddScholarDialog}
      />
      <Stack sx={{ maxWidth: "100%" }}>
        <ScholarTable
          currentPage={currentPage}
          setCurrentPage={updateCurrentPage}
          prevPath={pathname}
          activeTab={activeTab}
          setActiveTab={updateActiveTab}
          sortModel={sortModel}
          setSortModel={updateSortModel}
        />
      </Stack>
    </>
  );
};

const mapDispatchToProps = {
  setActiveTeam,
  updateCurrentPage: updateCurrentPageScholarsTable,
  updateActiveTab: updateActiveTabScholarsTable,
  updateSortModel: updateSortModelScholarsTable,
};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
  currentPage: state.guildDashboard.config.scholarsTable.currentPage,
  activeTab: state.guildDashboard.config.scholarsTable.activeTab,
  sortModel: state.guildDashboard.config.scholarsTable.sortModel,
});

export default connect(mapStateToProps, mapDispatchToProps)(Scholars);
