import { useState, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import AxieV3 from "components/Axie/AxieV3/AxieV3";
import ClassIcon from "components/Axie/AxieV3/components/ClassIcon";
import CardSummary from "components/Axie/AxieV3/components/CardSummary";
import { getCardsForAxie } from "helpers/axie";
import AxieItems from "components/Axie/AxieV3/components/AxieItems";
import PopoverMenu from "./PopoverMenu";
import { BattleAxie, Card } from "types";

interface Props {
  axie: BattleAxie;
  cardsV3: Card[];
  showAxieDetail?: boolean;
}

const FavoriteAxie = ({ axie, cardsV3, showAxieDetail }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const popupRef = useRef(null);
  const cards = getCardsForAxie(axie, cardsV3);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      event.stopPropagation();
      if (
        popupRef.current &&
        !(popupRef.current as any).contains(event.target as Node)
      ) {
        handleCloseMenu();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Stack alignItems="stretch" ref={popupRef} sx={{ cursor: "pointer" }}>
      <PopoverMenu open={open} anchorEl={anchorEl} axieId={axie.axieId} />

      <AxieV3
        variant={showAxieDetail ? "full" : "image"}
        axie={axie}
        width={showAxieDetail ? 320 : 232}
        disableMarketplaceLink={true}
        onClick={handleOpenMenu}
        fillSpace={true}
      />

      {!showAxieDetail && (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            gap={0.5}
            alignItems="center"
            mb={1}
          >
            <ClassIcon axie={axie} width={300} cls={axie.class} />
            <CardSummary data={cards?.cardV3Stats} width={300} />
          </Stack>
          <AxieItems axie={axie} width={224} />
        </>
      )}
    </Stack>
  );
};

export default FavoriteAxie;
