import { Stack } from "@mui/material";
import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import { AXIE_CLASSES } from "config";
import {
  updateCardFilters,
  updateCharmFilters,
  updateRuneFilters,
} from "store/teamBuilder/teamBuilderActions";
import { connect } from "react-redux";
import Search from "./Search";

const Controls = ({
  itemType,
  updateCardFilters,
  updateCharmFilters,
  updateRuneFilters,
  filters,
  requiredClass,
}) => {
  return itemType === "cards" ? (
    <CardControls updateFilters={updateCardFilters} filters={filters.cards} />
  ) : itemType === "charms" ? (
    <CharmControls
      updateFilters={updateCharmFilters}
      filters={filters.charms}
      requiredClass={requiredClass}
    />
  ) : itemType === "runes" ? (
    <RuneControls
      updateFilters={updateRuneFilters}
      filters={filters.runes}
      requiredClass={requiredClass}
    />
  ) : (
    <></>
  );
};

const CardControls = ({ updateFilters, filters }) => {
  const handleUpdateFilters = (newFilters) => {
    updateFilters({ ...filters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={4}>
      <Search
        value={filters.searchText}
        handleUpdate={handleUpdateFilters}
        placeholder="Search cards"
      />
      <CustomMultiSelect
        label="Class"
        value={filters.axieClasses}
        setValue={handleUpdateDropdown("axieClasses")}
        options={AXIE_CLASSES}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Energy Cost"
        value={filters.costs}
        setValue={handleUpdateDropdown("costs")}
        options={["0", "1", "2", "X"]}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Tag"
        value={filters.tags}
        setValue={handleUpdateDropdown("tags")}
        options={[
          "attack",
          "skill",
          "power",
          "secret",
          "retain",
          "innate",
          "banish",
          "ethereal",
        ]}
        fullWidth={false}
        minWidth={200}
      />
    </Stack>
  );
};

const CharmControls = ({ updateFilters, filters, requiredClass }) => {
  const handleUpdateFilters = (newFilters) => {
    updateFilters({ ...filters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={4}>
      <Search
        value={filters.searchText}
        handleUpdate={handleUpdateFilters}
        placeholder="Search charms"
      />
      <CustomMultiSelect
        label="Class"
        value={filters.axieClasses}
        setValue={handleUpdateDropdown("axieClasses")}
        options={["neutral", requiredClass]}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Rarity"
        value={filters.rarities}
        setValue={handleUpdateDropdown("rarities")}
        options={["common", "rare", "epic", "mystic"]}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Potential Cost"
        value={filters.potentialCosts}
        setValue={handleUpdateDropdown("potentialCosts")}
        options={["1", "2", "3", "4", "5"]}
        fullWidth={false}
        minWidth={200}
      />
    </Stack>
  );
};

const RuneControls = ({ updateFilters, filters, requiredClass }) => {
  const handleUpdateFilters = (newFilters) => {
    updateFilters({ ...filters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateFilters({
        [property]: newVal,
      });
  };

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={4}>
      <Search
        value={filters.searchText}
        handleUpdate={handleUpdateFilters}
        placeholder="Search runes"
      />
      <CustomMultiSelect
        label="Class"
        value={filters.axieClasses}
        setValue={handleUpdateDropdown("axieClasses")}
        options={["neutral", requiredClass]}
        fullWidth={false}
        minWidth={200}
      />
      <CustomMultiSelect
        label="Rarity"
        value={filters.rarities}
        setValue={handleUpdateDropdown("rarities")}
        options={["common", "rare", "epic", "mystic"]}
        fullWidth={false}
        minWidth={200}
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateCardFilters,
  updateCharmFilters,
  updateRuneFilters,
};

const mapStateToProps = (state) => ({
  filters: state.teamBuilder.dialog.filters,
  requiredClass: state.teamBuilder.dialog.requiredClass,
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
