export const initialCardFilters = {
  searchText: "",
  costs: [],
  tags: [],
  axieClasses: [],
  partTypes: [],
};
export const initialRuneFilters = {
  searchText: "",
  axieClasses: [],
  rarities: [],
};
export const initialCharmFilters = {
  searchText: "",
  axieClasses: [],
  potentialCosts: [],
  rarities: [],
};
export const initialCurseFilters = {
  searchText: "",
};
export const initialToolFilters = {
  searchText: "",
};
export const initialRevengeCardFilters = {
  searchText: "",
  costs: [],
  tags: [],
};
export const initialStatusEffectFilters = {
  searchText: "",
};

export const initialState = {
  favoriteCards: [],
  favoriteCharms: [],
  favoriteRunes: [],
  favoriteCurses: [],
  favoriteTools: [],
  favoriteRevengeCards: [],
  cardFilters: {
    ...initialCardFilters,
    sortType: "NAME_ASC",
  },
  runeFilters: {
    ...initialRuneFilters,
    sortType: "NAME_ASC",
  },
  charmFilters: {
    ...initialCharmFilters,
    sortType: "NAME_ASC",
  },
  curseFilters: {
    ...initialCurseFilters,
    sortType: "NAME_ASC",
  },
  toolFilters: {
    ...initialToolFilters,
    sortType: "NAME_ASC",
  },
  statusEffectFilters: {
    ...initialStatusEffectFilters,
    sortType: "NAME_ASC",
  },
  revengeCardFilters: {
    ...initialRevengeCardFilters,
    sortType: "TYPE_ASC",
  },
};
