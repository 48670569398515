import React from "react";
import CookieConsent from "react-cookie-consent";
import { useMediaQuery, useTheme } from "@mui/material";

const CookieConsentBanner = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <CookieConsent
      location="bottom"
      buttonText="accept"
      cookieName="axie_tech_cookie_consent"
      style={{
        background: theme.palette.mode === "light" ? "#ecd9ff" : "#eee",
        padding: smDown ? "8px" : mdDown ? "16px" : "24px 32px",
        borderRadius: "16px",
        alignItems: "center",
        border: `3px solid ${
          theme.palette.mode === "light" ? "#B76FFF" : "#a672d7"
        }`,
        maxWidth: smDown ? "100%" : "1200px",
        right: smDown ? "initial" : 0,
        margin: smDown ? "0" : "0 auto 16px auto",
        flexDirection: smDown ? "column" : "row",
      }}
      buttonStyle={{
        color: "#fff",
        backgroundColor: theme.palette.colors.purple[300],
        fontWeight: "700",
        fontSize: "18px",
        borderRadius: "8px",
        padding: "12px 22px",
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
      contentStyle={{
        lineHeight: 1.4,
        color: theme.palette.colors.black,
        flexBasis: smDown ? "100px" : "300px",
        textAlign: smDown ? "center" : "left",
        fontSize: smDown ? "15px" : "18px",
      }}
      expires={365}
    >
      This website stores data such as cookies to enable essential site
      functionality, as well as marketing, personalization and analytics. By
      remaining on this website you indicate your consent.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
