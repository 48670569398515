import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import TextWithHighlights from "./TextWithHighlights";
import fonts from "theme/fonts";

const Charm = ({ onClick, charm, width = 1181, boxShadow = true, style }) => {
  if (charm == null) {
    return null;
  }
  const cls = charm.class.toLowerCase();
  return (
    <Box
      onClick={onClick}
      sx={{
        width,
        height: (1616.2 / 1181) * width,
        position: "relative",
        ...(boxShadow && {
          "&::before": {
            content: "''",
            background: "#39241a78",
            filter: `blur(${(28 / 1181) * width}px)`,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: `${(130 / 1181) * width}px`,
          },
        }),
        ...style,
      }}
    >
      <CharmIcon image={charm.image} name={charm.name} />
      <CharmBackground />
      <CharmFrame />
      <PotentialCost cost={charm.potentialCost} width={width} />
      <CharmText text={charm.effect} width={width} />
      <CharmName name={charm.name} width={width} />
      <CharmRarity rarity={charm.rarity} width={width} />
      <ClassIcon axieClass={cls} />
    </Box>
  );
};

const CharmFrame = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      "& img": {
        width: "100%",
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img src={`/images/templates/charm/frame.png`} alt="Charm background" />
  </Box>
);

const CharmBackground = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      "& img": {
        width: "100%",
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img
      src={`/images/templates/charm/background.png`}
      alt="Charm background"
    />
  </Box>
);

const CharmIcon = ({ image, name }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      position: "absolute",
      top: "10.5%",
      height: "39.5%",
      left: 0,
      right: 0,
      zIndex: 2,
      "& img": {
        maxWidth: "30%",
        maxHeight: "90%",
        m: "0 auto",
        display: "block",
      },
    }}
  >
    <img src={image} alt={name} />
  </Stack>
);

const ClassIcon = ({ axieClass }) => (
  <Box
    sx={{
      zIndex: 3,
      position: "absolute",
      top: "4.8%",
      left: "6.6%",
      width: "20.7%",
      "& img": {
        width: "100%",
      },
    }}
  >
    <img
      src={`/images/class-icons/${axieClass}.png`}
      alt={`${axieClass} class charm`}
    />
  </Box>
);

const PotentialCost = ({ cost, width }) => {
  return (
    <Typography
      textAlign="center"
      fontSize={(200 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      sx={{
        position: "absolute",
        width: "26.8%",
        left: "3%",
        color: "#fff",
        top: "19%",
        zIndex: 3,
        WebkitTextStrokeWidth: `${(10 / 1181) * width}px`,
        WebkitTextStrokeColor: "#4B3939",
      }}
    >
      {cost}
    </Typography>
  );
};

const CharmName = ({ name, width }) => {
  return (
    <Typography
      textAlign="center"
      fontSize={(94 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      sx={{
        position: "absolute",
        maxWidth: "82%",
        margin: "0 auto",
        left: 0,
        right: 0,
        color: "#fff",
        bottom: "42%",
        lineHeight: 1.15,
        zIndex: 3,
        textShadow: "0px 0px 2px rgb(58,49,48)",
        fontWeight: 400,
      }}
    >
      {name}
    </Typography>
  );
};

const CharmRarity = ({ rarity, width }) => {
  const theme = useTheme();
  return (
    <Typography
      textAlign="center"
      fontSize={(76 / 1181) * width}
      fontFamily={fonts.changa}
      variant="span"
      sx={{
        backgroundColor: theme.palette.colors.rarity[rarity],
        borderRadius: 2,
        paddingTop: 0.35,
        paddingBottom: 0.35,
        position: "absolute",
        maxWidth: "33%",
        margin: "0 auto",
        left: 0,
        right: 0,
        color: "#fff",
        bottom: "5%",
        lineHeight: 1,
        zIndex: 3,
        textShadow: "0px 0px 2px rgb(58,49,48)",
        fontWeight: 300,
      }}
    >
      {rarity ? rarity : null}
    </Typography>
  );
};

const CharmText = ({ text, width }) => {
  if (text == null) {
    return <></>;
  }

  let fontSize = 78;
  if (text.length > 130) {
    fontSize = 70;
  }

  return (
    <Typography
      variant="span"
      textAlign="center"
      fontSize={(fontSize / 1181) * width}
      fontWeight={600}
      fontFamily={fonts.roboto}
      sx={{
        position: "absolute",
        top: "59%",
        color: "#DBDBDB",
        maxWidth: "88%",
        left: 0,
        right: 0,
        m: "0 auto",
        lineHeight: 1.2,
        zIndex: 5,
        textShadow: "0px 0px 2px rgb(58,52,51)",
      }}
    >
      <TextWithHighlights text={text} />
    </Typography>
  );
};

export default Charm;
