import React from "react";
import { displayPercentRounded } from "helpers/display";
import CustomSelectMinimal from "components/CustomSelect/CustomSelectMinimal";
import { calculateMaxNumberOfBreeds } from "helpers/axie";
import styles from "./breeding-result.module.scss";
import Label from "components/Typography/Label";
import { Stack } from "@mui/material";
import Capsule from "../atoms/InfoCapsule";

const CostsAndPurity = ({
  purityScore,
  breedingCost,
  numberOfBreeds,
  updateNumberOfBreedsForPair,
  parentIds,
  parentData,
}) => {
  const getBreedingOptions = () => {
    return Array.from(
      {
        length: calculateMaxNumberOfBreeds(
          parentData[0].breedCount,
          parentData[1].breedCount
        ),
      },
      (_, i) => i + 1
    ).map((option) => ({
      label: `${option} Breed${option > 1 ? "s" : ""}`,
      value: option,
    }));
  };
  const handleUpdateNumberOfBreeds = (newNum) => {
    updateNumberOfBreedsForPair([parentIds[0], parentIds[1]], newNum);
  };

  const breedingOptions = getBreedingOptions();

  return (
    <div className={styles.breedCostsAndPurity}>
      <Stack gap={1} alignItems="center">
        <Stack direction="row" gap={1}>
          <Capsule
            amount={breedingCost.slp}
            icon="/images/icons/slp.png"
            iconWidth="24px"
          />
          <Capsule amount={breedingCost.axs} icon="/images/icons/axs.png" />
        </Stack>

        <div className={styles.labelAndDropdown}>
          <Label>Cost:</Label>
          <CustomSelectMinimal
            val={numberOfBreeds}
            setVal={handleUpdateNumberOfBreeds}
            options={breedingOptions}
            minimal={true}
          />
        </div>
      </Stack>
      <Stack gap={1} alignItems="center">
        <Capsule
          amount={displayPercentRounded(purityScore)}
          icon="/images/icons/star.png"
        />
        <Label>Purity Score</Label>
      </Stack>
    </div>
  );
};

export default CostsAndPurity;
