import { Stack } from "@mui/material";
import React from "react";

const DashboardHeader = ({ children }) => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      {children}
    </Stack>
  );
};

export default DashboardHeader;
