import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  Tooltip,
} from "recharts";
import WidgetTitle from "../atoms/WidgetTitle";
import ChartTooltip from "../atoms/ChartTooltip";
import { RiSwordFill } from "react-icons/ri";
import { useTheme } from "@mui/material";

const HorizontalBarChart = ({
  title,
  icon = <RiSwordFill />,
  data,
  yAxis,
  xAxis,
  xAxisLabel,
}) => {
  const theme = useTheme();
  // eslint-disable-next-line
  const tickFormatter = (value, index) => {
    const limit = 10;
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
  };

  const dataToUse = data.length > 50 ? data.slice(0, 50) : data;
  const chartTitle = data.length > 50 ? `${title} (Top 50)` : title;

  return data.length > 0 ? (
    <>
      <WidgetTitle text={chartTitle} icon={icon} />
      <div style={{ minWidth: 0 }}>
        <ResponsiveContainer width="99%" height={120 + dataToUse.length * 20}>
          <BarChart
            data={dataToUse}
            layout="vertical"
            margin={{ left: 20, right: 20, top: 20 }}
          >
            <YAxis
              stroke={theme.palette.card.subText}
              axisLine={{ stroke: theme.palette.card.pop3 }}
              tickLine={{ stroke: theme.palette.card.pop3 }}
              dataKey={yAxis}
              type="category"
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
              tickFormatter={tickFormatter}
              width={100}
              interval={0}
            />
            <XAxis
              stroke={theme.palette.card.subText}
              axisLine={{ stroke: theme.palette.card.pop3 }}
              tickLine={{ stroke: theme.palette.card.pop3 }}
              type="number"
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            />
            <Tooltip
              content={<ChartTooltip />}
              cursor={{ fill: "transparent" }}
            />
            <Bar
              dataKey={xAxis}
              name={xAxisLabel}
              fill={theme.palette.secondary.main}
              radius={[0, 10, 10, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  ) : (
    <></>
  );
};

export default HorizontalBarChart;
