export const FETCH_AXIE_CARDS = "FETCH_AXIE_CARDS";
export const FETCH_AXIE_CARDS_STARTED = "FETCH_AXIE_CARDS_STARTED";
export const FETCH_AXIE_CARDS_FAILED = "FETCH_AXIE_CARDS_FAILED";
export const FETCH_AXIE_CARDS_SUCCEEDED = "FETCH_AXIE_CARDS_SUCCEEDED";

export const FETCH_AXIE_CARDS_V3 = "FETCH_AXIE_CARDS_V3";
export const FETCH_AXIE_CARDS_V3_STARTED = "FETCH_AXIE_CARDS_V3_STARTED";
export const FETCH_AXIE_CARDS_V3_FAILED = "FETCH_AXIE_CARDS_V3_FAILED";
export const FETCH_AXIE_CARDS_V3_SUCCEEDED = "FETCH_AXIE_CARDS_V3_SUCCEEDED";

export const FETCH_PLAYER_DATA = "FETCH_PLAYER_DATA";
export const FETCH_PLAYER_DATA_SUCCEEDED = "FETCH_PLAYER_DATA_SUCCEEDED";

export const FETCH_AXIE_DATA = "FETCH_AXIE_DATA";
export const FETCH_AXIE_DATA_SUCCEEDED = "FETCH_AXIE_DATA_SUCCEEDED";
