import React from "react";
import DropdownItem from "../atoms/DropdownItem";
import { DropdownWrapper } from "../atoms/DropdownWrapper";
import DropdownSections from "./DropdownSections";

const NavigationDropdown = ({
  items,
  secondaryItems,
  alignRight,
  colorOverrides,
}) => {
  return (
    <DropdownWrapper
      twoColumn={items.length > 6}
      colorOverrides={colorOverrides}
      alignRight={alignRight}
    >
      {items.map((item, index) => (
        <DropdownItem
          item={item}
          index={index}
          key={`DROPDOWN_ITEM_${index}`}
          colorOverrides={colorOverrides}
        >
          {item.text}
        </DropdownItem>
      ))}

      {secondaryItems != null && (
        <DropdownSections
          secondaryItems={secondaryItems}
          colorOverrides={colorOverrides}
        />
      )}
    </DropdownWrapper>
  );
};

export default NavigationDropdown;
