import * as types from "./chartsActionTypes";
import * as actions from "./chartsActions";
import { put, retry, takeEvery } from "redux-saga/effects";
import api from "services/api";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchSlpIssuanceRequest() {
  try {
    yield put(actions.fetchSlpIssuanceStarted());
    const data = yield retry(RETRY_TIMES, RETRY_DELAY, api.requestSlpIssuance);
    yield put(actions.fetchSlpIssuanceSucceeded(data));
  } catch (e) {
    yield put(actions.fetchSlpIssuanceFailed());
  }
}

export default function* chartsSaga() {
  yield takeEvery(types.CHARTS_FETCH_SLP_ISSUANCE, makeFetchSlpIssuanceRequest);
}
