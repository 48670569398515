import { useState, useRef } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import RuneTemplate from "components/GameTemplates/RuneTemplate";
import CharmTemplate from "components/GameTemplates/CharmTemplate";
import ItemPopup from "./ItemPopup";
import ItemDetails from "./ItemDetails";
import ItemQuantities from "./ItemQuantities";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.text.veryVeryMuted}`,
  transition: "0.2s box-shadow ease",

  "&:hover": {
    boxShadow: theme.palette.card.boxShadow,
  },
}));

const Item = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const id = `${item.id}-item-detail-menu`;

  return (
    <>
      <Wrapper>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <ItemDetails
            rarity={item.rarity}
            isBlockchain={item.isBlockchain}
            season={item.season}
          />
          <ItemQuantities
            quantity={item.quantity}
            withdrawalAmount={item.withdrawalAmount}
            isBlockchain={item.isBlockchain}
            handleOpen={handleOpen}
            handleClose={handleClose}
            anchorEl={anchorEl}
            id={id}
          />
        </Stack>

        {item.itemType === "Charm" ? (
          <CharmTemplate charm={item} width={190} boxShadow={false} />
        ) : item.itemType === "Rune" ? (
          <RuneTemplate rune={item} width={190} boxShadow={false} />
        ) : null}
      </Wrapper>

      <ItemPopup
        id={id}
        item={item}
        anchorEl={anchorEl}
        isOpen={isOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      />
    </>
  );
};

export default Item;
