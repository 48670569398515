import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";

const FilterItemType = ({ value, setValue, style }) => {
  return value != null && setValue != null ? (
    <CustomMultiSelect
      label="Collection"
      value={value}
      setValue={setValue}
      options={["Rune", "Charm"]}
      style={style}
    />
  ) : (
    <></>
  );
};
export default FilterItemType;
