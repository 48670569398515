import { useMemo } from "react";
import Page from "components/InterDungeon/Layout/Page";
import CardsContainer from "pages/InterDungeon/Explorer/organisms/CardsContainer";
import Controls from "pages/InterDungeon/Explorer/organisms/Controls";
import PageControls from "components/InterDungeon/Layout/PageControls";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { useGetInterTheDungeonCardsQuery } from "store/apiSlice";
import useControls from "store/interDungeon/explorer/useControls";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Failed from "pages/InterDungeon/Explorer/molecules/Failed";
import NoResults from "pages/InterDungeon/Explorer/molecules/NoResults";
import { cardMatchesFilters } from "./utils";

const Wrapper = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 60,
  paddingBottom: 80,
}));

const Explorer = () => {
  const {
    data = [],
    isFetching,
    isError,
    refetch,
  } = useGetInterTheDungeonCardsQuery();
  const { searchText, rarity, equippable, battlePower, equippedPower } =
    useControls();

  const allCards = useMemo(() => {
    return data.filter(
      (card) => card.cardType === "hero" && card.cardImage.url
    );
  }, [data]);

  const hasFiltered =
    searchText !== "" ||
    rarity.length > 0 ||
    battlePower.length > 0 ||
    equippable.length > 0 ||
    equippedPower.length > 0;

  const filteredData = useMemo(() => {
    return [...allCards].filter((card) =>
      cardMatchesFilters(card, {
        searchText,
        rarity,
        battlePower,
        equippedPower,
        equippable,
      })
    );
  }, [allCards, searchText, rarity, battlePower, equippedPower, equippable]);

  return (
    <Page>
      <Wrapper>
        <PageControls id="page-controls">
          <Controls />
        </PageControls>
        {isFetching ? (
          <CustomLoader variant="inter-the-dungeon" />
        ) : isError ? (
          <Failed refetch={refetch} />
        ) : hasFiltered && filteredData?.length === 0 ? (
          <NoResults />
        ) : (
          <CardsContainer data={filteredData} />
        )}
      </Wrapper>
    </Page>
  );
};

export default Explorer;
