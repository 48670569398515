import React from "react";
import styled from "@emotion/styled";
import { Pagination as MuiPagination } from "@mui/material";

const Light = styled(MuiPagination)(({ theme }) => ({
  ".MuiPaginationItem-root": {
    color: "#fff",
    fontWeight: 800,
    fontSize: 24,
    margin: "0 12px",
    height: 48,
    width: 48,

    "&.Mui-selected, &:hover": {
      background: "#fff",
      color: "#000",
    },

    "& .MuiPaginationItem-icon": {
      fontSize: 32,
    },
  },
}));

const Dark = styled(MuiPagination)(({ theme }) => ({
  ".MuiPaginationItem-root": {
    color: theme.palette.colors.doll.muted,
    fontWeight: 800,
    fontSize: 24,
    margin: "0 12px",
    height: 48,
    width: 48,

    "&.Mui-selected, &:hover": {
      background: theme.palette.colors.doll.stripe,
      color: "#000",
    },

    "& .MuiPaginationItem-icon": {
      fontSize: 32,
    },
  },
}));

const Pagination = ({ variant = "light", ...props }) => {
  if (variant === "dark") {
    return <Dark {...props} />;
  }
  return <Light {...props} />;
};

export default Pagination;
