import * as types from "./teamBuilderActionTypes";

export const updateCard = (axieId, part, card) => ({
  type: types.TEAM_BUILDER_UPDATE_CARD,
  axieId,
  part,
  card,
});

export const updateCharm = (axieId, part, charm, potentialPointsUsed) => ({
  type: types.TEAM_BUILDER_UPDATE_CHARM,
  axieId,
  part,
  charm:
    charm == null
      ? undefined
      : {
          ...charm,
          potentialPointsUsed,
        },
});

export const updateRune = (axieId, rune) => ({
  type: types.TEAM_BUILDER_UPDATE_RUNE,
  axieId,
  rune,
});

export const updateClass = (axieId, axieClass) => ({
  type: types.TEAM_BUILDER_UPDATE_CLASS,
  axieId,
  axieClass,
});

export const clearAxie = (id) => ({
  type: types.TEAM_BUILDER_CLEAR_AXIE,
  id,
});

export const clearAllAxies = () => ({
  type: types.TEAM_BUILDER_CLEAR_ALL_AXIES,
});

export const setDialogOpen = (axieId, itemType, part, requiredClass) => ({
  type: types.TEAM_BUILDER_SET_DIALOG_OPEN,
  axieId,
  itemType,
  part,
  requiredClass,
});

export const setDialogClosed = () => ({
  type: types.TEAM_BUILDER_SET_DIALOG_CLOSED,
});

export const updateCardFilters = (filters) => ({
  type: types.TEAM_BUILDER_UPDATE_CARD_FILTERS,
  filters,
});

export const updateRuneFilters = (filters) => ({
  type: types.TEAM_BUILDER_UPDATE_RUNE_FILTERS,
  filters,
});

export const updateCharmFilters = (filters) => ({
  type: types.TEAM_BUILDER_UPDATE_CHARM_FILTERS,
  filters,
});

export const updateView = (view) => ({
  type: types.TEAM_BUILDER_UPDATE_VIEW,
  view,
});

export const updateDisplayBanishCards = (display) => ({
  type: types.TEAM_BUILDER_DISPLAY_BANISH_CARDS,
  display,
});
