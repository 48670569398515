import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { addAlphaToHex } from "helpers/colors";

const StarChangeAmount = ({ change }) => {
  const theme = useTheme();
  const color =
    change >= 0 ? theme.palette.feedback.green : theme.palette.feedback.red;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        border: `1px solid ${color}`,
        background: addAlphaToHex(color, 0.24),
        p: "4px",
        alignSelf: "center",
        borderRadius: "4px",
      }}
    >
      <Stack color={color}>
        {change >= 0 ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
      </Stack>
      <Typography variant="span" fontSize="14px" fontWeight="700" color={color}>
        {change < 0 ? change * -1 : change}
      </Typography>
    </Stack>
  );
};

export default StarChangeAmount;
