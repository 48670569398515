import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack, SxProps, useTheme } from "@mui/material";

interface Props {
  children: React.ReactNode;
  fullHeight?: boolean;
  highlight?: boolean;
  className?: string;
  style?: SxProps;
  outline?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Card = ({
  children,
  fullHeight = true,
  highlight,
  className,
  style = {},
  outline,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const baseStyles = {
    borderRadius: "16px",
    height: fullHeight ? "100%" : "auto",
  };

  if (highlight) {
    return (
      <Stack
        className={className}
        sx={{
          ...baseStyles,
          padding: "8px",
          boxShadow: theme.palette.card.boxShadow,
          background: theme.palette.card.gradient,
          ...style,
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Stack
          sx={{
            backgroundColor: theme.palette.card.background,
            borderRadius: "6px",
            padding: "24px",
            height: "100%",
          }}
        >
          {children}
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      className={className}
      sx={{
        ...baseStyles,
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
        padding: smUp ? "32px" : "24px",
        border: outline ? `1px solid ${theme.palette.card.border}` : "none",
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </Stack>
  );
};

export default Card;
