import React from "react";
import { Stack, Box } from "@mui/material";
import {
  updateRevengeCardFilters,
  resetRevengeCardFilters,
} from "store/explorer/explorerActions";
import { connect } from "react-redux";
import Search from "components/Origin/atoms/Search";
import TextButton from "components/Origin/atoms/TextButton";
import Dropdown from "components/Origin/atoms/Dropdown";

const RevengeCardControls = ({
  updateRevengeCardFilters,
  resetRevengeCardFilters,
  revengeCardFilters,
}) => {
  const handleUpdateRevengeCardFilters = (newFilters) => {
    updateRevengeCardFilters({ ...revengeCardFilters, ...newFilters });
  };
  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateRevengeCardFilters({
        [property]: newVal,
      });
  };
  return (
    <Stack gap={2}>
      <Search
        value={revengeCardFilters.searchText}
        handleUpdate={handleUpdateRevengeCardFilters}
        placeholder="Search cards"
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        }}
      >
        <Dropdown
          label="Energy Cost"
          value={revengeCardFilters.costs}
          setValue={handleUpdateDropdown("costs")}
          options={["0", "1", "2"]}
        />
        <Dropdown
          label="Tag"
          value={revengeCardFilters.tags}
          setValue={handleUpdateDropdown("tags")}
          options={["attack", "skill", "power", "secret", "banish"]}
        />
      </Box>
      <TextButton
        onClick={resetRevengeCardFilters}
        text="Reset Filters"
        textAlign="right"
      />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateRevengeCardFilters,
  resetRevengeCardFilters,
};

const mapStateToProps = (state) => ({
  revengeCardFilters: state.explorer.revengeCardFilters,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevengeCardControls);
