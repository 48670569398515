import { Button } from "@mui/material";
import React from "react";
import { HiPlus } from "react-icons/hi";

const ButtonAddGuild = ({ handleOpenAddGuild }) => {
  return (
    <Button
      onClick={handleOpenAddGuild}
      variant="outlined"
      color="secondary"
      endIcon={<HiPlus />}
    >
      Add Guild
    </Button>
  );
};

export default ButtonAddGuild;
