import * as types from "./craftingCollectionActionTypes";

export const toggleItem = (item, itemType) => ({
  type: types.CRAFTING_COLLECTION_TOGGLE_ITEM,
  item,
  itemType,
});

export const bulkAddItems = (items) => ({
  type: types.CRAFTING_COLLECTION_BULK_ADD_ITEMS,
  items,
});

export const clearItems = (itemType) => ({
  type: types.CRAFTING_COLLECTION_CLEAR_ITEMS,
  itemType,
});
