import * as types from "./leaderboardActionTypes";

export const fetchGuildMMRLeaderboard = () => ({
  type: types.FETCH_GUILD_ARENA_LEADERBOARD,
});

export const fetchGuildMMRLeaderboardStarted = () => ({
  type: types.FETCH_GUILD_ARENA_LEADERBOARD_STARTED,
});

export const fetchGuildMMRLeaderboardFailed = () => ({
  type: types.FETCH_GUILD_ARENA_LEADERBOARD_FAILED,
});

export const fetchGuildMMRLeaderboardSucceeded = (data) => ({
  type: types.FETCH_GUILD_ARENA_LEADERBOARD_SUCCEEDED,
  data,
});
