import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { addOrdinalSuffix } from "helpers/display";

const Stat = ({
  label,
  amount,
  rank,
  subtext,
  subtextSuffix,
  amountSize = "36px",
  asAt,
}) => {
  const subTextParsed = subtext == null ? "" : `${subtext} ${subtextSuffix}`;

  return amount == null ? (
    <></>
  ) : (
    <Stack
      gap={0.5}
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      <Stack gap={0.5} direction="row" alignItems="center">
        <Typography
          fontSize="16px"
          color="text.primary"
          variant="span"
          fontWeight="700"
        >
          {label}
        </Typography>
        {asAt != null && asAt}
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <Typography
          fontSize={amountSize}
          color="text.regular"
          variant="span"
          fontWeight="600"
          sx={{
            lineHeight: 1.24,
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {amount}
        </Typography>
        {rank != null && rank > 0 && (
          <Box
            sx={{
              background: (theme) => theme.palette.colors.purple[500],
              p: "4px 8px",
              borderRadius: "8px",
            }}
          >
            <Typography
              fontSize="14px"
              variant="span"
              fontWeight="700"
              sx={{ color: (theme) => theme.palette.colors.purple[300] }}
            >
              {addOrdinalSuffix(rank)}
            </Typography>
          </Box>
        )}
      </Stack>
      <Typography
        fontSize="16px"
        color="text.muted"
        variant="span"
        fontWeight="600"
      >
        {subTextParsed}
      </Typography>
    </Stack>
  );
};

export default Stat;
