import React from 'react'
import { Typography } from '@mui/material';

const ColumnLabel = ({ children }) => (
    <Typography
      fontSize="14px"
      color="text.muted"
      variant="span"
      fontWeight="600"
    >
      {children}
    </Typography>
  );
  

export default ColumnLabel