import * as types from "./actionTypes";
import { put, takeEvery, retry, select } from "redux-saga/effects";
import api from "services/api";
import * as actions from "./actions";
import moment from "moment";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makefetchDollLeaderboardRequest() {
  try {
    const lastFetched = yield select(
      (state) => state.doll.leaderboard.lastFetched
    );

    const threshold = moment().subtract(10, "minutes").unix();

    if (lastFetched == null || lastFetched < threshold) {
      yield put(actions.fetchDollLeaderboardStarted());
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestAxieDollLeaderboard
      );
      const leaderboard = (data || []).map((p, i) => ({ ...p, rank: i + 1 }));
      yield put(actions.fetchDollLeaderboardSucceeded(leaderboard));
    }
  } catch (e) {
    yield put(actions.fetchDollLeaderboardFailed());
  }
}

export default function* dollLeaderboardSaga() {
  yield takeEvery(
    types.DOLL_LEADERBOARD_FETCH_LEADERBOARD,
    makefetchDollLeaderboardRequest
  );
}
