import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextareaAutosize,
  DialogActions,
  useTheme,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import {
  extractRoninAddressesFromString,
  validateRoninAddress,
} from "helpers/cleaning";
import * as breedingSimulatorActions from "store/breedingSimulator/breedingSimulatorActions";
import { connect } from "react-redux";
import Error from "components/Typography/Error";

const RoninAddressDialog = ({
  open,
  setOpen,
  clearRoninAxies,
  fetchAllRoninAxies,
  setOpenAxieDialog,
}) => {
  const theme = useTheme();
  const [roninAddresses, setRoninAddresses] = useState("");
  const [error, setError] = useState("");

  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  const handleDoneEnteringMultipleRoninAddresses = (e) => {
    const addressesArray = extractRoninAddressesFromString(roninAddresses);
    let validAddresses = [];
    clearRoninAxies();

    addressesArray.forEach((address) => {
      try {
        const validAddress = validateRoninAddress(address);
        validAddresses.push(validAddress);
      } catch (e) {
        console.log("Bad ronin address: ", address);
      }
    });

    if (validAddresses.length > 0) {
      fetchAllRoninAxies(validAddresses);
      handleClose();
      setOpenAxieDialog(true);
    } else {
      setError(
        `Hmm...something doesn't look right with ${
          addressesArray.length === 1 ? "that address." : "those addresses."
        }`
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth={"lg"}
      aria-labelledby="import-from-ronin-addresses-dialog"
    >
      <DialogTitle id="import-from-ronin-addresses-dialog">
        Import from Ronin Addresses
      </DialogTitle>

      <DialogContent dividers={true}>
        <Stack gap={1}>
          <Error>{error}</Error>

          <Typography variant="body2" color="text.regular">
            Enter ronin wallet addresses separated by commas.
          </Typography>

          <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            placeholder="ronin:"
            onChange={(e) => setRoninAddresses(e.target.value)}
            value={roninAddresses}
            spellCheck={false}
            style={{
              background: theme.palette.input.filled.background,
              color: theme.palette.text.primary,
              fontSize: "16px",
              fontWeight: "500",
              minWidth: "500px",
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={handleDoneEnteringMultipleRoninAddresses}
          text="Done"
          variant="contained"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  fetchAllRoninAxies: breedingSimulatorActions.fetchAllRoninAxies,
  clearRoninAxies: breedingSimulatorActions.clearRoninAxies,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RoninAddressDialog);
