import { Stack } from "@mui/material";
import { BODY_PARTS_IN_ORDER } from "config";
import CharmTemplateTiny from "components/GameTemplates/CharmTemplateTiny";
import styled from "@emotion/styled";

const Grid = styled(Stack, {
  shouldForwardProp: (prop) => !["showBodyParts", "itemGap"].includes(prop),
})(({ showBodyParts, itemGap }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: itemGap,
  alignSelf: "center",

  ...(showBodyParts && {
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  }),

  "& .bodyPart": {
    img: {
      maxWidth: "90%",
    },
  },
}));

const Charms = ({ charmData, width, showBodyParts }) => {
  if (
    charmData == null ||
    Object.values(charmData).filter((charm) => (charm ?? "") !== "").length ===
      0
  ) {
    return null;
  }
  const itemWidth = showBodyParts ? (36 / 320) * width : (30 / 320) * width;
  const itemGap = showBodyParts
    ? ((32 / 320) * width) / 8
    : ((24 / 320) * width) / 8;

  return (
    <Grid itemGap={itemGap} showBodyParts={showBodyParts}>
      {BODY_PARTS_IN_ORDER.map((part, i) => {
        let charmContent;
        if (charmData[part]) {
          charmContent = (
            <CharmTemplateTiny
              charm={charmData[part]}
              width={itemWidth}
              tooltip={true}
            />
          );
        } else {
          charmContent = (
            <Stack
              sx={{
                minHeight: itemWidth,
                minWidth: itemWidth,
              }}
            />
          );
        }

        return (
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={(0.5 / 320) * width}
            key={`CHARM_${i}`}
          >
            {charmContent}

            {showBodyParts && (
              <Stack
                alignItems="center"
                justifyContent="center"
                className="bodyPart"
                sx={{ width: itemWidth, height: itemWidth }}
              >
                <img
                  src={`/images/templates/card/body-parts/${part}.png`}
                  alt={`${part} Body Part Card`}
                  key={part}
                />
              </Stack>
            )}
          </Stack>
        );
      })}
    </Grid>
  );
};

export default Charms;
