import { useState, useEffect } from "react";
import styles from "./welcome.module.scss";
import { injected } from "web3/connectors";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { useTheme } from "@mui/material";
import Header from "./atoms/Header";
import WelcomeMessage from "./molecules/WelcomeMessage";

const Welcome = ({ loading, loginFailed }) => {
  const { activate, deactivate } = useWeb3React();
  const [chainIdNotSupportedError, setChainIdNotSupportedError] =
    useState(false);
  const theme = useTheme();

  const connect = async () => {
    try {
      const onError = undefined;
      const shouldThrowErrors = true;
      await activate(injected, onError, shouldThrowErrors);
    } catch (e) {
      if (e instanceof UnsupportedChainIdError) {
        setChainIdNotSupportedError(true);
      }
      console.log("Error connecting: ", e);
    }
  };

  useEffect(() => {
    if (loginFailed) {
      try {
        deactivate(injected);
      } catch (e) {
        console.log("Error disconnecting: ", e);
      }
    }
  }, [loginFailed, deactivate]);

  const handleConnect = () => {
    connect();
  };

  return (
    <div className={styles.welcome}>
      <Header />
      <WelcomeMessage
        loading={loading}
        loginFailed={loginFailed}
        handleConnect={handleConnect}
        chainIdNotSupportedError={chainIdNotSupportedError}
        theme={theme}
      />
      <div
        className={styles.clouds}
        style={{
          backgroundImage: "url(/images/clouds.png)",
          opacity: theme.palette.mode === "light" ? 1 : 0.15,
        }}
      ></div>
    </div>
  );
};

export default Welcome;
