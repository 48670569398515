import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.colors.purple[300],
  backgroundColor: theme.palette.mode === "light" ? "#eee" : "#ddd",
  fontSize: 17,
  whiteSpace: "nowrap",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 17,
  },
}));

export default ColorButton;
