import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  alignItems: "center",
  flexDirection: "row",
  color: "#7DA0BA",
}));

const InfoItem = ({ icon, points }) => {
  return (
    <Wrapper>
      {icon}
      <Typography variant="span">{points}</Typography>
    </Wrapper>
  );
};

export default InfoItem;
