import PageTitle from "pages/GuildDashboard/atoms/PageTitle";
import React from "react";
import ButtonAddGuild from "../atoms/ButtonAddGuild";
import DashboardHeader from "../molecules/DashboardHeader";

const DashboardHeaderGuilds = ({ handleOpenAddGuild }) => {
  return (
    <DashboardHeader>
      <PageTitle>Manage Guilds</PageTitle>
      <ButtonAddGuild handleOpenAddGuild={handleOpenAddGuild} />
    </DashboardHeader>
  );
};

export default DashboardHeaderGuilds;
