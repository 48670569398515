import { Chip } from "@mui/material";
import React from "react";

const Status = ({ status }) => {
  return status == null ? null : (
    <Chip size="small" color="primary" label={status} />
  );
};

export default Status;
