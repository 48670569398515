import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageFooter from "./PageFooter";
import PageContent from "./PageContent";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(/images/games/inter-the-dungeon/brick-row.jpg)`,
  backgroundRepeat: "repeat-y",
  backgroundSize: "contain",
}));

const Page = ({ children }) => {
  return (
    <Wrapper>
      <PageContent>{children}</PageContent>
      <PageFooter />
    </Wrapper>
  );
};

export default Page;
