import React from "react";
import Label from "components/Typography/Label";
import { Slider, Stack, Typography } from "@mui/material";

const SliderWithTitle = ({ val, setVal, title, min, max, step }) => (
  <Stack
    direction="row"
    gap={2}
    alignItems="center"
    justifyContent="space-between"
  >
    <Label>{title}</Label>
    <Slider
      step={step}
      min={min}
      max={max}
      valueLabelDisplay="auto"
      value={val}
      onChange={(e, newVal) => setVal(newVal)}
    />
    <Typography variant="span" fontSize="11px" fontWeight="700">
      {val}
    </Typography>
  </Stack>
);

export default SliderWithTitle;
