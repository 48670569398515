import React from "react";
import { Typography } from "@mui/material";

const MessageText = ({ children }) => (
  <Typography
    variant="span"
    fontSize="18px"
    color="text.muted"
    sx={{ lineHeight: 1.5 }}
  >
    {children}
  </Typography>
);

const ClickableText = ({ children, onClick }) => (
  <Typography
    variant="span"
    fontSize="18px"
    fontWeight="600"
    color="text.regular"
    onClick={onClick}
    sx={{
      transition: "0.2s opacity ease",
      lineHeight: 1.5,
      opacity: 0.8,
      "&:hover": { opacity: 1, cursor: "pointer" },
    }}
  >
    {children}
  </Typography>
);

const HiddenScholarMessage = ({
  numScholars,
  showScholars,
  setShowScholars,
}) => {
  const handleShowScholars = () => {
    setShowScholars(!showScholars);
  };

  let message;
  if (numScholars === 1) {
    message = (
      <MessageText>
        There is{" "}
        <ClickableText onClick={handleShowScholars}>
          1 hidden player
        </ClickableText>{" "}
        who was added too recently to display.
      </MessageText>
    );
  } else {
    message = (
      <MessageText>
        There are{" "}
        <ClickableText onClick={handleShowScholars}>
          {numScholars} hidden players
        </ClickableText>{" "}
        who were added too recently to display.
      </MessageText>
    );
  }

  return message;
};

export default HiddenScholarMessage;
