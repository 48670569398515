import NoneFoundMessage from "components/Typography/NoneFoundMessage";

const NoneFound = () => {
  return (
    <NoneFoundMessage fontSize={20}>
      No items found. Try adjusting the filters?
    </NoneFoundMessage>
  );
};

export default NoneFound;
