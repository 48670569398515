import React from "react";
import { Stack, IconButton, useTheme, Typography } from "@mui/material";
import { IoCloseCircle } from "react-icons/io5";

const ClickableSlotCondensed = ({ onClick, deleteItem, value, icon }) => {
  const theme = useTheme();

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteItem();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
      onClick={onClick}
      sx={{
        p: "3px 3px 3px 5px",
        background: theme.palette.card.pop1,
        borderRadius: 2,
        overflow: "hidden",
        width: 178,
        minHeight: 30,
        flexGrow: value != null ? 1 : 0,
        userSelect: "none",
        transition: "0.2s transform ease",
        border:
          value == null
            ? `1px dashed ${theme.palette.text.muted}`
            : "1px solid transparent",
        "& .delete": {
          opacity: 0,
          zIndex: 8,
          color: theme.palette.text.regular,
        },
        "& .icon svg": {
          height: 16,
          width: "auto",
        },
        "&:hover": {
          border:
            value == null
              ? `1px dashed ${theme.palette.text.regular}`
              : "1px solid transparent",
          cursor: "pointer",
          transform: "scale(1.05)",
          "& .delete": {
            opacity: 1,
          },
        },
      }}
    >
      <Stack gap={0.75} direction="row" alignItems="center">
        {icon != null && value == null && (
          <Stack className="icon">{icon(theme.palette.text.muted)}</Stack>
        )}
        <Typography
          variant="span"
          fontSize={12}
          fontWeight={600}
          color="text.regular"
          sx={{ lineHeight: 1.2 }}
        >
          {value}
        </Typography>
      </Stack>

      {value != null && (
        <IconButton
          size="small"
          className="delete"
          onClick={handleDelete}
          sx={{ ml: -1, p: "1px" }}
        >
          <IoCloseCircle />
        </IconButton>
      )}
    </Stack>
  );
};
export default ClickableSlotCondensed;
