import React from "react";
import { BREEDING_EVENT_INFO_URL, BREEDING_EVENT_COUNTDOWN_URL } from "config";
import { Typography, Stack } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";

const Countdown = () => {
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <CustomLink
        href={BREEDING_EVENT_COUNTDOWN_URL}
        style={{
          paddingBottom: 0,
          lineHeight: "13px",
          border: "none",
          fontSize: 13,
          "&:hover": {
            fontWeight: 800,
          },
        }}
      >
        <Typography
          variant="span"
          fontWeight={800}
          fontSize={13}
          color="text.bright"
          sx={{
            lineHeight: "13px",
          }}
        >
          View Countdown
        </Typography>
      </CustomLink>

      <CustomLink
        href={BREEDING_EVENT_INFO_URL}
        style={{
          paddingBottom: 0,
          lineHeight: "13px",
          border: "none",
          fontSize: 13,
        }}
      >
        <Typography
          variant="span"
          fontWeight={500}
          fontSize={13}
          color="text.bright"
          sx={{ lineHeight: "13px" }}
        >
          {" • "} Learn More
        </Typography>
      </CustomLink>
    </Stack>
  );
};

export default Countdown;
