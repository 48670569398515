import { Typography } from "@mui/material";

const SecondaryTitle = ({ children }) => (
  <Typography
    variant="span"
    fontSize="20px"
    fontWeight="700"
    color="text.secondary"
  >
    {children}
  </Typography>
);

export default SecondaryTitle;
