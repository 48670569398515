import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";

const FilterAxieBreedCount = ({ breedCount, setBreedCount, style }) => {
  return breedCount != null && setBreedCount != null ? (
    <CustomMultiSelect
      label="Breed Count"
      value={breedCount}
      setValue={setBreedCount}
      options={["0", "1", "2", "3", "4", "5", "6", "7"]}
      style={style}
    />
  ) : (
    <></>
  );
};

export default FilterAxieBreedCount;
