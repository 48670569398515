import React from "react";
import { connect } from "react-redux";
import {
  updateRequiredBreedingId,
  deleteAllBreedingAxieIds,
  deleteAllUnlockedBreedingAxieIds,
} from "store/breedingSimulator/breedingSimulatorActions";
import * as breedingSelectors from "store/breedingSimulator/breedingSelectors";
import { Stack, Button, Box } from "@mui/material";
import Panel from "../atoms/Panel";
import IconMessage from "components/IconMessage/IconMessage";
import AxieId from "../atoms/AxieId";
import NumberOfIdsAdded from "../atoms/NumberOfIdsAdded";

const IdsAdded = ({
  ids,
  requiredId,
  handleDeleteId,
  deleteAllBreedingAxieIds,
  deleteAllUnlockedBreedingAxieIds,
  updateRequiredBreedingId,
  message,
}) => {
  const handleDeleteAllBreedingAxieIds = () => {
    deleteAllBreedingAxieIds();
  };

  const handleDeleteAllUnlockedBreedingAxieIds = () => {
    deleteAllUnlockedBreedingAxieIds();
  };

  return (
    <Panel>
      <Stack gap={2} alignItems="center">
        <NumberOfIdsAdded numIds={ids.length} />

        <Box sx={{ maxHeight: 330, overflow: "auto", width: "100%" }}>
          <Stack alignItems="stretch">
            {ids.map((id, i) => {
              return (
                <AxieId
                  key={`ID_${i}`}
                  id={id}
                  requiredId={requiredId}
                  updateRequiredBreedingId={updateRequiredBreedingId}
                  handleDeleteId={handleDeleteId}
                />
              );
            })}
          </Stack>
        </Box>

        <Stack gap={1}>
          {message && <IconMessage text={message} fontSize={13} />}
          {requiredId && (
            <IconMessage
              fontSize={13}
              color="green"
              style={{ textAlign: "center" }}
              text={
                <>
                  Pair must include <strong>#{requiredId}</strong>
                </>
              }
            />
          )}
          {ids.length > 0 ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ padding: "8px 0" }}
            >
              <Button
                onClick={handleDeleteAllUnlockedBreedingAxieIds}
                variant="text"
                size="small"
              >
                Clear Unlocked
              </Button>
              <Button
                onClick={handleDeleteAllBreedingAxieIds}
                variant="text"
                size="small"
              >
                Clear All
              </Button>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Panel>
  );
};

const mapDispatchToProps = {
  updateRequiredBreedingId,
  deleteAllBreedingAxieIds,
  deleteAllUnlockedBreedingAxieIds,
};

const mapStateToProps = (state) => {
  return {
    ids: state.breedingSimulator.ids,
    requiredId: state.breedingSimulator.requiredId,
    message: breedingSelectors.getBreedingMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdsAdded);
