import React, { useState } from "react";
import PFPSaved from "./PFPSaved";
import Crop from "../atoms/Crop";
import { useWeb3React } from "@web3-react/core";

const ManagePFP = ({
  profileImageFullUrl,
  alt,
  guildId,
  width,
  requestPresignedUrl,
  handleUpdateAvatar,
  isEditingAvatar,
  setIsEditingAvatar,
}) => {
  const { account } = useWeb3React();
  const [crop, setCrop] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgUrlToDisplay, setImgUrlToDisplay] = useState(profileImageFullUrl);

  const onSelectFile = (e) => {
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].type.match("image.*")
    ) {
      setIsEditingAvatar(true);
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const resetEditor = () => {
    setIsEditingAvatar(false);
    setCrop(undefined);
    setImgSrc(null);
  };

  const handleRequestPresignedUrl = (dataUri) => {
    const filename = `${guildId}-avatar`;
    const fileExtension = "jpg";

    requestPresignedUrl(
      account,
      guildId,
      filename,
      fileExtension,
      dataUri,
      handleDoneEditing
    );
  };

  const handleDoneEditing = (presignedUrl, filename, dataUri) => {
    setImgUrlToDisplay(dataUri);
    handleUpdateAvatar(presignedUrl, filename, dataUri);
    resetEditor();
  };

  const handleCancelEditing = () => {
    resetEditor();
  };

  if (isEditingAvatar) {
    return (
      <Crop
        setCrop={setCrop}
        crop={crop}
        imgSrc={imgSrc}
        handleDone={handleRequestPresignedUrl}
        handleCancel={handleCancelEditing}
      />
    );
  }
  return (
    <PFPSaved
      url={imgUrlToDisplay}
      alt={alt}
      width={width}
      onChange={onSelectFile}
    />
  );
};

export default ManagePFP;
