import React from "react";
import Header from "./molecules/Header";
import Players from "./organisms/Players";

const Axies = () => {
  return (
    <>
      <Header />
      <Players />
    </>
  );
};

export default Axies;
