import React from "react";
import { Box, useTheme } from "@mui/material";

const PartRow = ({ children, width, large }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: `${(22 / 320) * width * 1.2}px repeat(3, 1fr)`,
        gridGap: `${(4 / 320) * width}px`,
        [theme.breakpoints.down("lg")]: {
          gridGap: large ? `${(10 / 320) * width}px` : `${(4 / 320) * width}px`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default PartRow;
