import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-7-31</Date>
      <Change type="improved">
        Enhanced our{" "}
        <CustomLink to="/breeding-simulator">Breeding Simulator</CustomLink>
        with new toggles and info panels.
      </Change>
      <Change type="bug">Correctly highlight shiny summer parts.</Change>
    </Entry>
  );
};

export default Changes;
