import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import CustomLink from "components/CustomLink/CustomLink";
import { makeMarketplaceUrlForItem } from "helpers/items";

const Wrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.veryMuted,
  fontSize: 13,
  transition: "0.2s color ease",
  "&:hover": {
    color: theme.palette.text.muted,
  },
}));

const MarketplaceIcon = ({ itemType, tokenId }) => {
  const url = makeMarketplaceUrlForItem(itemType, tokenId);

  if (!url) {
    return null;
  }

  return (
    <CustomLink href={makeMarketplaceUrlForItem(itemType, tokenId)}>
      <Wrapper>
        <FaExternalLinkSquareAlt />
      </Wrapper>
    </CustomLink>
  );
};

export default MarketplaceIcon;
