import Page from "components/Utility/Page";
import Releases from "./releases";
import PageTitle from "components/Typography/PageTitle";

const Changelog = () => {
  return (
    <Page>
      <PageTitle>Changelog</PageTitle>
      <Releases.Update20230901 />
      <Releases.Update20230804 />
      <Releases.Update20230404 />
      <Releases.Update20230130 />
      <Releases.Update20211229 />
      <Releases.Update20221221 />
      <Releases.Update20221130 />
      <Releases.Update20221024 />
      <Releases.Update20220926 />
      <Releases.Update20220916 />
      <Releases.Update20220902 />
      <Releases.Update20220819 />
      <Releases.Update20220731 />
      <Releases.Update20220715 />
      <Releases.Update20220712 />
      <Releases.Update20220705 />
      <Releases.Update20220617 />
      <Releases.Update20220609 />
      <Releases.Update20220605 />
      <Releases.Update20220427 />
      <Releases.Update20220402 />
      <Releases.Update20220331 />
      <Releases.Update20220325 />
      <Releases.Update20220307 />
      <Releases.Update20220126 />
      <Releases.Update20220121 />
      <Releases.Update20220112 />
      <Releases.Update20220111 />
      <Releases.Update20220105 />
      <Releases.Update20211206 />
      <Releases.Update20211130 />
      <Releases.Update20211127 />
      <Releases.Update20211126 />
      <Releases.Update20211123 />
      <Releases.Update20211122 />
      <Releases.Update20211120 />
      <Releases.Update20211119 />
      <Releases.Update20211118 />
    </Page>
  );
};

export default Changelog;
