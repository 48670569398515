import * as actionTypes from "./actionTypes";

export const importScholars = (loginAddress, guildSlug, scholars) => ({
  type: actionTypes.GUILD_DASHBOARD_IMPORT_SCHOLARS,
  guildSlug,
  loginAddress,
  scholars,
});

export const addScholar = (loginAddress, guildSlug, scholarData) => ({
  type: actionTypes.GUILD_DASHBOARD_ADD_SCHOLAR,
  guildSlug,
  loginAddress,
  scholarData,
});

export const editScholar = (loginAddress, scholarData, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_EDIT_SCHOLAR,
  loginAddress,
  scholarData,
  guildTeamId,
});

export const deleteScholar = (loginAddress, roninAddress, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_DELETE_SCHOLAR,
  loginAddress,
  roninAddress,
  guildTeamId,
});

export const unwatchScholar = (loginAddress, roninAddress, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_UNWATCH_SCHOLAR,
  loginAddress,
  roninAddress,
  guildTeamId,
});

export const watchScholar = (loginAddress, roninAddress, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_WATCH_SCHOLAR,
  loginAddress,
  roninAddress,
  guildTeamId,
});

export const fetchAllScholarAxies = (scholars, forceRefresh = false) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_ALL_SCHOLAR_AXIES,
  scholars,
  forceRefresh,
});

export const fetchAxiesForScholar = (roninAddress, guildTeamId, start = 0) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR,
  roninAddress,
  start,
  guildTeamId,
});

export const fetchAxiesForScholarSucceeded = (
  roninAddress,
  guildTeamId,
  axies
) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_SUCCEEDED,
  roninAddress,
  guildTeamId,
  axies,
});

export const fetchAxiesForScholarFailed = (roninAddress, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AXIES_FOR_SCHOLAR_FAILED,
  roninAddress,
  guildTeamId,
});

export const fetchAxieDetailSucceeded = (roninAddress, guildTeamId, axie) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AXIE_DETAIL_SUCCEEDED,
  roninAddress,
  guildTeamId,
  axie,
});

export const loginManager = (loginAddress) => ({
  type: actionTypes.GUILD_DASHBOARD_LOGIN_MANAGER,
  loginAddress,
});

export const updateUserPreferences = (loginAddress, userPreferences) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_USER_PREFERENCES,
  loginAddress,
  userPreferences,
});

export const updateAxiesBreedCount = (breedCount) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_BREED_COUNT,
  breedCount,
});

export const updateAxiesRole = (role) => ({
  type: actionTypes.GUILD_DASHBOARD_AXIE_UPDATE_ROLE,
  role,
});

export const updateAxiesSpecialty = (specialty) => ({
  type: actionTypes.GUILD_DASHBOARD_AXIE_UPDATE_SPECIALTY,
  specialty,
});

export const updateAxiesPurity = (purity) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_PURITY,
  purity,
});

export const updateAxiesPurityPercent = (purityPercent) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_PURITY_PERCENT,
  purityPercent,
});

export const updateAxiesHP = (hp) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_HP,
  hp,
});

export const updateAxiesSkill = (skill) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SKILL,
  skill,
});

export const updateAxiesSpeed = (speed) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SPEED,
  speed,
});

export const updateAxiesMorale = (morale) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_MORALE,
  morale,
});

export const updateAxiesSortType = (sortType) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SORT_TYPE,
  sortType,
});

export const updateAxiesAxieClasses = (axieClasses) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_AXIE_CLASSES,
  axieClasses,
});

export const updateAxiesSearchQuery = (searchQuery) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SEARCH_QUERY,
  searchQuery,
});

export const updateAxiesShowGenes = (showGenes) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SHOW_GENES,
  showGenes,
});

export const updateAxiesCurrentPage = (currentPage) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_CURRENT_PAGE,
  currentPage,
});

export const updateAxiesSelectedParts = (selectedParts) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS,
  selectedParts,
});

export const updateAxiesSelectedPartsMatchD = (match) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_D,
  match,
});

export const updateAxiesSelectedPartsMatchR1 = (match) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R1,
  match,
});

export const updateAxiesSelectedPartsMatchR2 = (match) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_MATCH_R2,
  match,
});

export const updateAxiesSelectedPartsIndividualPartMatches = (
  partId,
  gene
) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_AXIES_SELECTED_PARTS_INDIVIDUAL_PART_MATCHES,
  partId,
  gene,
});

export const resetAxieFilters = () => ({
  type: actionTypes.GUILD_DASHBOARD_RESET_AXIE_FILTERS,
});

export const updateGuildSearchQuery = (searchQuery) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_GUILD_SEARCH_QUERY,
  searchQuery,
});

export const setScholarToModifyIds = (roninAddress, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_SET_SCHOLAR_TO_MODIFY_IDS,
  roninAddress,
  guildTeamId,
});

export const setOpenAddTeamDialog = (isOpen) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_ADD_TEAM_DIALOG_IS_OPEN,
  isOpen,
});

export const setOpenEditScholarDialog = (isOpen) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_EDIT_SCHOLAR_DIALOG_IS_OPEN,
  isOpen,
});

export const setOpenDeleteScholarDialog = (isOpen) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_DELETE_SCHOLAR_DIALOG_IS_OPEN,
  isOpen,
});

export const setOpenEditTeamDialog = (isOpen) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_EDIT_TEAM_DIALOG_IS_OPEN,
  isOpen,
});

export const addTeam = (loginAddress, guildId, teamData) => ({
  type: actionTypes.GUILD_DASHBOARD_ADD_TEAM,
  loginAddress,
  teamData,
  guildId,
});

export const deleteTeam = (loginAddress, guildId, guildTeamId, scholars) => ({
  type: actionTypes.GUILD_DASHBOARD_DELETE_TEAM,
  loginAddress,
  guildTeamId,
  scholars,
  guildId,
});

export const editTeam = (loginAddress, guildId, guildTeamId, teamData) => ({
  type: actionTypes.GUILD_DASHBOARD_EDIT_TEAM,
  loginAddress,
  guildTeamId,
  teamData,
  guildId,
});

export const setActiveTeam = (guildTeamId, resetTable = true) => ({
  type: actionTypes.GUILD_DASHBOARD_SET_ACTIVE_TEAM,
  guildTeamId,
  resetTable,
});

export const setActiveScholar = (roninAddress) => ({
  type: actionTypes.GUILD_DASHBOARD_SET_ACTIVE_SCHOLAR,
  roninAddress,
});

export const moveScholarsToTeam = (
  loginAddress,
  roninAddresses,
  newGuildTeamId
) => ({
  type: actionTypes.GUILD_DASHBOARD_MOVE_SCHOLARS_TO_TEAM,
  loginAddress,
  roninAddresses,
  newGuildTeamId,
});

export const updateNumberOfScholarsPerPage = (pageSize) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_NUMBER_OF_SCHOLARS_PER_PAGE,
  pageSize,
});

export const updateCurrentPageScholarsTable = (tab, page) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE,
  tab,
  page,
});

export const updateCurrentPageScholarsTableTeam = (tab, page) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_CURRENT_PAGE_SCHOLARS_TABLE_TEAM,
  tab,
  page,
});

export const updateActiveTabScholarsTable = (tab) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE,
  tab,
});

export const updateActiveTabScholarsTableTeam = (tab) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_ACTIVE_TAB_SCHOLARS_TABLE_TEAM,
  tab,
});

export const updateScholarEarningsChartToDisplay = (toDisplay) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_SCHOLAR_EARNINGS_CHART_TO_DISPLAY,
  toDisplay,
});

export const updateScholarBattlesChartToDisplay = (toDisplay) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_SCHOLAR_BATTLES_CHART_TO_DISPLAY,
  toDisplay,
});

export const updateSortModelScholarsTable = (tab, sortModel) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE,
  tab,
  sortModel,
});

export const updateSortModelScholarsTableTeam = (tab, sortModel) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_SORT_MODEL_SCHOLARS_TABLE_TEAM,
  tab,
  sortModel,
});

export const updateScholarPVPBattlesCurrentPage = (currentPage, scholar) => ({
  type: actionTypes.GUILD_DASHBOARD_UPDATE_SCHOLAR_PVP_BATTLES_CURRENT_PAGE,
  currentPage,
  scholar,
});

export const fetchAutopayoutLog = (loginAddress) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG,
  loginAddress,
});

export const fetchAutopayoutLogStarted = () => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_STARTED,
});

export const fetchAutopayoutLogSucceeded = (data) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_SUCCEEDED,
  data,
});

export const fetchAutopayoutLogFailed = () => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_AUTOPAYOUT_LOG_FAILED,
});

export const fetchBattlesDataForScholar = (roninAddress, guildTeamId) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR,
  roninAddress,
  guildTeamId,
});

export const fetchBattlesDataForScholarStarted = (
  roninAddress,
  guildTeamId
) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_STARTED,
  roninAddress,
  guildTeamId,
});

export const fetchBattlesDataForScholarFailed = (
  roninAddress,
  guildTeamId
) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_FAILED,
  roninAddress,
  guildTeamId,
});

export const fetchBattlesDataForScholarSucceeded = (
  roninAddress,
  guildTeamId,
  payload
) => ({
  type: actionTypes.GUILD_DASHBOARD_FETCH_BATTLES_DATA_FOR_SCHOLAR_SUCCEEDED,
  roninAddress,
  guildTeamId,
  payload,
});
