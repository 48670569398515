import * as types from "./inspectorActionTypes";

export const cancelFetching = () => ({
  type: types.INSPECTOR_CANCEL_FETCHING,
});

// ---- AXIE

export const search = (axieId) => ({
  type: types.INSPECTOR_SEARCH,
  axieId,
});

export const fetchAxie = (axieId) => ({
  type: types.INSPECTOR_FETCH_AXIE,
  axieId,
});

export const fetchAxieStarted = () => ({
  type: types.INSPECTOR_FETCH_AXIE_STARTED,
});

export const fetchAxieFailed = () => ({
  type: types.INSPECTOR_FETCH_AXIE_FAILED,
});

export const fetchAxieSucceeded = (data) => ({
  type: types.INSPECTOR_FETCH_AXIE_SUCCEEDED,
  data,
});

export const axieAlreadyFetched = (axieId) => ({
  type: types.INSPECTOR_AXIE_ALREADY_FETCHED,
  axieId,
});

// ---- PARENTS

export const fetchParents = (axie) => ({
  type: types.INSPECTOR_FETCH_PARENTS,
  axie,
});

export const fetchParentsStarted = () => ({
  type: types.INSPECTOR_FETCH_PARENTS_STARTED,
});

export const fetchParentsFailed = () => ({
  type: types.INSPECTOR_FETCH_PARENTS_FAILED,
});

export const fetchParentsSucceeded = (parents) => ({
  type: types.INSPECTOR_FETCH_PARENTS_SUCCEEDED,
  parents,
});

// ---- NUMBER OF SIMILAR AXIES

export const fetchNumberOfSimilarAxies = (axie) => ({
  type: types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES,
  axie,
});

export const fetchNumberOfSimilarAxiesStarted = () => ({
  type: types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_STARTED,
});

export const fetchNumberOfSimilarAxiesFailed = () => ({
  type: types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_FAILED,
});

export const fetchNumberOfSimilarAxiesSucceeded = (total) => ({
  type: types.INSPECTOR_FETCH_NUMBER_OF_SIMILAR_AXIES_SUCCEEDED,
  total,
});

// ---- LAST SOLD

export const fetchLastSold = (axie) => ({
  type: types.INSPECTOR_FETCH_LAST_SOLD,
  axie,
});

export const fetchLastSoldStarted = () => ({
  type: types.INSPECTOR_FETCH_LAST_SOLD_STARTED,
});

export const fetchLastSoldFailed = () => ({
  type: types.INSPECTOR_FETCH_LAST_SOLD_FAILED,
});

export const fetchLastSoldSucceeded = (lastSold) => ({
  type: types.INSPECTOR_FETCH_LAST_SOLD_SUCCEEDED,
  lastSold,
});

// ---- MYSTIC FLOOR

export const fetchMysticFloor = (axie) => ({
  type: types.INSPECTOR_FETCH_MYSTIC_FLOOR,
  axie,
});

export const fetchMysticFloorStarted = () => ({
  type: types.INSPECTOR_FETCH_MYSTIC_FLOOR_STARTED,
});

export const fetchMysticFloorFailed = () => ({
  type: types.INSPECTOR_FETCH_MYSTIC_FLOOR_FAILED,
});

export const fetchMysticFloorSucceeded = (mysticFloor) => ({
  type: types.INSPECTOR_FETCH_MYSTIC_FLOOR_SUCCEEDED,
  mysticFloor,
});

export const fetchMysticAxieDetail = (axieId) => ({
  type: types.INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL,
  axieId,
});

export const fetchMysticAxieDetailSucceeded = (data) => ({
  type: types.INSPECTOR_FETCH_MYSTIC_AXIE_DETAIL_SUCCEEDED,
  data,
});

// ---- CHEAPEST SIMILAR LISTINGS

export const fetchCheapestSimilarListings = (axie) => ({
  type: types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS,
  axie,
});

export const fetchCheapestSimilarListingsStarted = () => ({
  type: types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_STARTED,
});

export const fetchCheapestSimilarListingsFailed = () => ({
  type: types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_FAILED,
});

export const fetchCheapestSimilarListingsSucceeded = (data) => ({
  type: types.INSPECTOR_FETCH_CHEAPEST_SIMILAR_LISTINGS_SUCCEEDED,
  data,
});

export const fetchCheapSimilarAxieDetail = (axieId) => ({
  type: types.INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL,
  axieId,
});

export const fetchCheapSimilarAxieDetailSucceeded = (data) => ({
  type: types.INSPECTOR_FETCH_CHEAP_SIMILAR_AXIE_DETAIL_SUCCEEDED,
  data,
});

// ---- RECENTLY SOLD

export const fetchRecentlySold = (axie) => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD,
  axie,
});

export const fetchRecentlySoldStarted = () => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD_STARTED,
});

export const fetchRecentlySoldFailed = () => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD_FAILED,
});

export const fetchRecentlySoldSucceeded = () => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD_SUCCEEDED,
});

export const fetchRecentlySoldTransferHistory = (axieId, timeCutOff) => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD_TRANSFER_HISTORY,
  axieId,
  timeCutOff,
});

export const fetchRecentlySoldAxieDetail = (axieId, lastSold) => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL,
  lastSold,
  axieId,
});

export const fetchRecentlySoldAxieDetailSucceeded = (data, lastSold) => ({
  type: types.INSPECTOR_FETCH_RECENTLY_SOLD_AXIE_DETAIL_SUCCEEDED,
  data,
  lastSold,
});

// ---- ESTIMATED PRICE FROM METALEND

export const fetchEstimatedPrice = (axieId) => ({
  type: types.INSPECTOR_FETCH_ESTIMATED_PRICE,
  axieId,
});

export const fetchEstimatedPriceStarted = () => ({
  type: types.INSPECTOR_FETCH_ESTIMATED_PRICE_STARTED,
});

export const fetchEstimatedPriceFailed = () => ({
  type: types.INSPECTOR_FETCH_ESTIMATED_PRICE_FAILED,
});

export const fetchEstimatedPriceSucceeded = (data) => ({
  type: types.INSPECTOR_FETCH_ESTIMATED_PRICE_SUCCEEDED,
  data,
});
