import { fork } from "redux-saga/effects";
import dollLeaderboardSaga from "./leaderboard/saga";
import dollSidekicksSaga from "./sidekicks/saga";
import dollCardsSaga from "./cardExplorer/saga";

export default function* dollSaga() {
  yield fork(dollLeaderboardSaga);
  yield fork(dollSidekicksSaga);
  yield fork(dollCardsSaga);
}
