import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import TitleAccent from "../atoms/TitleAccent";
import fonts from "theme/fonts";

const TitleWithAccents = ({ children }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TitleAccent />
      <Typography
        variant="h1"
        color={theme.palette.colors.origin.text.brown}
        fontSize={32}
        fontFamily={fonts.changa}
        fontWeight={500}
        lineHeight={1}
      >
        {children}
      </Typography>
      <TitleAccent rotate={true} />
    </Stack>
  );
};

export default TitleWithAccents;
