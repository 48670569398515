import React from "react";
import { Typography, useTheme, Stack } from "@mui/material";
import CustomChip from "components/Typography/CustomChip";

const ColumnAmount = ({ children, color, capsule }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography
        fontSize="22px"
        color={color ? theme.palette.feedback[color] : "text.regular"}
        variant="span"
        fontWeight="600"
      >
        {children}
      </Typography>
      {capsule != null && (
        <CustomChip style={{ fontSize: "13px" }}>{capsule}</CustomChip>
      )}
    </Stack>
  );
};

export default ColumnAmount;
