import { Typography } from "@mui/material";
import React from "react";

const BoldText = ({ children }) => {
  return (
    <Typography fontWeight={800} fontSize={16} variant="span">
      {children}
    </Typography>
  );
};

export default BoldText;
