import endpoints from "services/api/endpoints";

const requestCards = async () => {
  const response = await fetch(endpoints.cards, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestCardsV3 = async () => {
  const response = await fetch(endpoints.cardsV3, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestGetSaleEstimate = async (payload) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(endpoints.axieSalePriceEstimate, params);
  const data = await response.json();
  return data;
};

const requestGetAxieDetail = async (id) => {
  const response = await fetch(endpoints.constructGetAxieDetail(id), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestGetRecentlyListed = async () => {
  const response = await fetch(endpoints.recentlyListed, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestGetRecentlySold = async () => {
  const response = await fetch(endpoints.recentlySold, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestTransferHistory = async (id) => {
  const response = await fetch(endpoints.constructTransferHistory(id), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestSearchAxies = async (variables) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(variables),
  };

  const response = await fetch(endpoints.searchAxies, params);
  const data = await response.json();
  return data;
};

const requestOriginLeaderboard = async () => {
  const response = await fetch(endpoints.originLeaderboard, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

// new classic leaderboard function
const fetchClassicLeaderboardData = async (url) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const fetchClassicLeaderboardPaginatedData = async (limit) => {
  const groupSize = 100;
  const limitPerRequest = getLimitPerRequest(limit, groupSize);

  const numRequests = limitPerRequest.length;
  const allData = [];

  for (let i = 0; i < numRequests; i++) {
    const offset = i * groupSize;
    const url = `${endpoints.classicLeaderboard}?offset=${offset}&limit=${limitPerRequest[i]}`;

    try {
      const data = await fetchClassicLeaderboardData(url);
      allData.push(...data);

      if (data.length < limitPerRequest[i]) {
        break;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return allData;
};

function getLimitPerRequest(number, groupSize) {
  const groups = Math.floor(number / groupSize);
  const remainder = number % groupSize;

  const result = [];
  for (let i = 0; i < groups; i++) {
    result.push(groupSize);
  }

  if (remainder > 0) {
    result.push(remainder);
  }

  return result;
}

const requestClassicLeaderboard = async () => {
  const totalRecords = 512;
  return fetchClassicLeaderboardPaginatedData(totalRecords);
};

const requestPlayer = async (clientId) => {
  const response = await fetch(endpoints.constructPlayer(clientId), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestPlayerPVPBattles = async (clientId) => {
  const response = await fetch(endpoints.constructPlayerPVPBattles(clientId), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestPlayerPVEBattles = async (clientId) => {
  const response = await fetch(endpoints.constructPlayerPVEBattles(clientId), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestClassicPlayer = async (roninAddress) => {
  const response = await fetch(endpoints.constructClassicPlayer(roninAddress), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestClassicPlayerPVPBattles = async (roninAddress) => {
  const response = await fetch(
    endpoints.constructClassicPlayerPVPBattles(roninAddress),
    {
      method: "GET",
    }
  );
  const data = await response.json();
  return data;
};

const requestClassicPlayerPVEBattles = async (roninAddress) => {
  const response = await fetch(
    endpoints.constructClassicPlayerPVEBattles(roninAddress),
    {
      method: "GET",
    }
  );
  const data = await response.json();
  return data;
};

const requestGuildMMRLeaderboard = async () => {
  const response = await fetch(endpoints.constructGuildLeaderboardMMR(), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

// request guild battle lederboard from api endpoint
const requestGuildBattleMMRLeaderboard = async () => {
  const response = await fetch(endpoints.constructGuildBattleLeaderboardMMR(), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestScholarMMRLeaderboard = async () => {
  const response = await fetch(endpoints.constructScholarLeaderboardMMR(), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestScholarLiveArenaData = async (roninAddress) => {
  const response = await fetch(endpoints.constructPlayer(roninAddress), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestAutopayoutLogData = async (loginAddress) => {
  const response = await fetch(
    endpoints.constructGuildDashboardAutopayoutLog(loginAddress),
    {
      method: "GET",
    }
  );
  const data = await response.json();
  return data;
};

const requestGuildsAsAdmin = async (loginAddress) => {
  const params = {
    method: "GET",
    headers: {
      "wallet-address": loginAddress,
    },
  };
  const response = await fetch(endpoints.guilds, params);
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error("UNAUTHORIZED");
    }
    throw new Error("ERROR");
  }

  const data = await response.json();
  return data;
};

const requestAddGuildAsAdmin = async (loginAddress, guild) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "wallet-address": loginAddress,
    },
    body: JSON.stringify(guild),
  };
  const response = await fetch(endpoints.addGuild, params);
  const data = await response.json();
  return data;
};

const requestUpdateGuildAsAdmin = async (loginAddress, guildId, payload) => {
  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "wallet-address": loginAddress,
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(endpoints.constructUpdateGuild(guildId), params);
  const data = await response.json();
  return data;
};

const requestPresignedUrl = async (
  loginAddress,
  guildId,
  filename,
  fileExtension
) => {
  const params = {
    method: "GET",
    headers: {
      "wallet-address": loginAddress,
    },
  };
  const response = await fetch(
    endpoints.constructPresignedUrl(guildId, filename, fileExtension),
    params
  );
  const data = await response.json();
  return data;
};

const requestUploadImageToS3 = async (dataUri, presignedUrl) => {
  const getBlob = async (fileUri) => {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();
    return imageBody;
  };

  const imageBody = await getBlob(dataUri);

  const params = {
    method: "PUT",
    body: imageBody,
  };

  const data = await fetch(presignedUrl, params);
  return data;
};

const requestSlpIssuance = async () => {
  const response = await fetch(endpoints.slpIssuance, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestAxieDollLeaderboard = async () => {
  const response = await fetch(endpoints.axieDollLeaderboard, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestAxieDollSidekicks = async () => {
  const response = await fetch(endpoints.axieDollSidekicks, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestAxieDollSkills = async () => {
  const response = await fetch(endpoints.axieDollSkills, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestAxieQuestCards = async () => {
  const response = await fetch(endpoints.axieQuestSkills, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const requestInterDungeonCards = async () => {
  const response = await fetch(endpoints.interDungeonCards, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

const exported = {
  requestCards,
  requestCardsV3,
  requestGetAxieDetail,
  requestGetSaleEstimate,
  requestGetRecentlyListed,
  requestGetRecentlySold,
  requestTransferHistory,
  requestSearchAxies,
  requestOriginLeaderboard,
  requestClassicLeaderboard,
  requestPlayer,
  requestClassicPlayer,
  requestPlayerPVPBattles,
  requestClassicPlayerPVPBattles,
  requestPlayerPVEBattles,
  requestClassicPlayerPVEBattles,
  requestGuildMMRLeaderboard,
  requestGuildBattleMMRLeaderboard,
  requestScholarMMRLeaderboard,
  requestScholarLiveArenaData,
  requestAutopayoutLogData,
  requestGuildsAsAdmin,
  requestAddGuildAsAdmin,
  requestUpdateGuildAsAdmin,
  requestPresignedUrl,
  requestUploadImageToS3,
  requestSlpIssuance,
  requestAxieDollLeaderboard,
  requestAxieDollSidekicks,
  requestAxieDollSkills,
  requestAxieQuestCards,
  requestInterDungeonCards,
};

export default exported;
