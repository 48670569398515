import React, { useEffect } from "react";
import {
  fetchSlpIssuance,
  updateSlpIssuanceBlockchainControls,
  updateSlpIssuanceInGameControls,
} from "store/charts/chartsActions";
import {
  makeSlpIssuanceBlockchainChartData,
  makeSlpIssuanceInGameChartData,
} from "store/charts/chartsSelectors";
import { connect } from "react-redux";
import SLPIssuance from "./organisms/SLPIssuance";
import Card from "components/Card/Card";
import BannerImage from "components/BannerImage";
import Wrapper from "./atoms/Wrapper";

const Charts = ({
  fetchSlpIssuance,
  slpIssuanceBlockchain,
  slpIssuanceInGame,
  updateSlpIssuanceBlockchainControls,
  updateSlpIssuanceInGameControls,
}) => {
  useEffect(() => {
    fetchSlpIssuance();
  }, [fetchSlpIssuance]);

  return (
    <Wrapper>
      <BannerImage
        src="/images/accents/charts-banner.jpg"
        alt="Axie Infinity Charts by Axie Tech"
      />
      <Card>
        <SLPIssuance
          rawData={slpIssuanceBlockchain.data}
          loading={slpIssuanceBlockchain.loading}
          controls={slpIssuanceBlockchain.data.controls}
          updateControls={updateSlpIssuanceBlockchainControls}
          total={slpIssuanceBlockchain.total}
        />
      </Card>
      <Card>
        <SLPIssuance
          rawData={slpIssuanceInGame.data}
          loading={slpIssuanceInGame.loading}
          controls={slpIssuanceInGame.data.controls}
          updateControls={updateSlpIssuanceInGameControls}
          total={slpIssuanceInGame.total}
        />
      </Card>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  fetchSlpIssuance,
  updateSlpIssuanceBlockchainControls,
  updateSlpIssuanceInGameControls,
};

const mapStateToProps = (state) => ({
  slpIssuanceBlockchain: makeSlpIssuanceBlockchainChartData(state),
  slpIssuanceInGame: makeSlpIssuanceInGameChartData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
