import { Stack, useTheme, Typography, Box } from "@mui/material";
import GuildLogoImg from "pages/GuildMembership/GuildLogoImg";

const GuildShowcaseBanner = ({
  guildDetails,
  title,
  icon,
  prefix,
  amount,
  suffix,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: theme.palette.card.background,
        boxShadow: theme.palette.card.boxShadow,
      }}
    >
      <Box sx={{ py: 6, px: 4 }}>
        <Stack
          direction="column"
          gap={4}
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography
              fontSize="2.25rem"
              fontWeight="700"
              sx={{ color: theme.palette.text.secondary, lineHeight: "34px" }}
            >
              {icon}
            </Typography>
            <Typography
              fontSize="1.75rem"
              fontWeight="700"
              color="text.bright"
              align="center"
              sx={{ lineHeight: "34px" }}
            >
              {title}
            </Typography>
          </Stack>
          <Stack
            maxWidth="95%"
            gap={3}
            direction="row"
            justifyContent="center"
            sx={{ flexWrap: "wrap" }}
          >
            {guildDetails.map((item) => (
              <GuildLogoImg
                width={40}
                key={item.guildId}
                guildImage={item.profileImage}
                guildLink={item.logoUri}
                guildName={item.guildName}
              />
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            paddingTop={2}
            gap={2}
            sx={{
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                textAlign: "center",
              },
            }}
          >
            <Typography
              variant="span"
              color="text.primary"
              fontWeight="600"
              fontSize="26px"
              sx={{ lineHeight: 1.3 }}
            >
              {prefix}
            </Typography>
            <Typography
              variant="span"
              color="text.bright"
              fontWeight="700"
              fontSize="28px"
            >
              {amount}
            </Typography>
            <Typography
              variant="span"
              color="text.primary"
              fontWeight="600"
              fontSize="26px"
              sx={{ lineHeight: 1.3 }}
            >
              {suffix}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default GuildShowcaseBanner;
