import { useState } from "react";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { Stack, Container } from "@mui/material";
import { getRunesToDisplay } from "store/explorer/selectors";
import {
  favoriteRune,
  updateRuneFilters,
} from "store/explorer/explorerActions";
import Sort from "../../molecules/Sort";
import ToggleStarterItems from "../../molecules/ToggleStarterItems";
import InfoButton from "components/Origin/atoms/InfoButton";
import WrappedRune from "pages/Explorer/molecules/WrappedRune";
import { isStarterRuneOrCharm } from "helpers/items";
import { STARTER_AXIE_RUNEIDS } from "config";
import TriballyOriginsExplorerBanner from "components/TriballyAdBanner/TriballyOriginsExplorerBanner";

const Runes = ({
  runesToDisplay,
  fetchingAxieRunes,
  favoriteRune,
  runeFilters,
  updateRuneFilters,
  setInfoDialogIsOpen,
}) => {
  const showStarterRunes = isStarterRuneOrCharm(runesToDisplay);
  const excludeStarterRunes = runesToDisplay.filter(
    (rune) => !STARTER_AXIE_RUNEIDS.includes(rune.itemId)
  );

  const [value, setValue] = useState("showAll");

  return (
    <>
      {fetchingAxieRunes ? (
        <CustomLoader />
      ) : (
        <Stack gap={6}>
          <Container>
            <Stack
              direction={{ md: "column", lg: "row" }}
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <TriballyOriginsExplorerBanner />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <ToggleStarterItems
                  value={value}
                  setValue={setValue}
                  options={[
                    { label: "Starter Runes", value: "starters" },
                    { label: "Personal Runes", value: "hideStarters" },
                    { label: "Show All", value: "showAll" },
                  ]}
                />
                <Sort
                  filters={runeFilters}
                  updateFilters={updateRuneFilters}
                  options={[
                    { label: "Name A-Z", value: "NAME_ASC" },
                    { label: "Name Z-A", value: "NAME_DESC" },
                    { label: "Highest Rarity", value: "RARITY_DESC" },
                    { label: "Lowest Rarity", value: "RARITY_ASC" },
                  ]}
                />
                <InfoButton onClick={() => setInfoDialogIsOpen(true)} />
              </Stack>
            </Stack>
          </Container>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            gap={6}
            sx={{ px: 2 }}
          >
            {value === "starters"
              ? showStarterRunes.map((rune) => (
                  <WrappedRune
                    key={rune.id}
                    rune={rune}
                    favoriteRune={favoriteRune}
                  />
                ))
              : null}
            {value === "hideStarters"
              ? excludeStarterRunes.map((rune) => (
                  <WrappedRune
                    key={rune.id}
                    rune={rune}
                    favoriteRune={favoriteRune}
                  />
                ))
              : null}
            {value === "showAll"
              ? runesToDisplay.map((rune) => (
                  <WrappedRune
                    key={rune.id}
                    rune={rune}
                    favoriteRune={favoriteRune}
                  />
                ))
              : null}
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapDispatchToProps = { favoriteRune, updateRuneFilters };

const mapStateToProps = (state) => ({
  runesToDisplay: getRunesToDisplay(state),
  fetchingRunes: state.data.fetchingAxieRunes,
  runeFilters: state.explorer.runeFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(Runes);
