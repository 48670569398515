import React from "react";
import { Box, Container } from "@mui/material";
import InfoDialog from "./InfoDialog";
import Flag from "../atoms/Flag";
import SubTitle from "../atoms/SubTitle";
import Title from "components/Origin/atoms/Title";
import controls from "./controls";
import styled from "@emotion/styled";
import StackedTitle from "../atoms/StackedTitle";
import ControlsWrapper from "../atoms/ControlsWrapper";
import HeaderInnerWrapper from "../atoms/HeaderInnerWrapper";
import UseCodeAxieTech from "../atoms/UseCodeAxieTech";
import UseCodeAxieTechWrapper from "../atoms/UseCodeAxieTechWrapper";

const subTitle = "Axie Infinity: Origins";

export const rawHeaderData = {
  cards: {
    title: "Cards",
    subTitle,
    controls: <controls.CardControls />,
    dialogKey: "card",
  },
  curses: {
    title: "Curses",
    subTitle,
    controls: <controls.CurseControls />,
    dialogKey: "curse",
  },
  tools: {
    title: "Tools",
    subTitle,
    controls: <controls.ToolControls />,
    dialogKey: "tool",
  },
  "revenge-cards": {
    title: "Revenge Cards",
    subTitle,
    controls: <controls.RevengeCardControls />,
    dialogKey: "revenge",
  },
  runes: {
    title: "Runes",
    subTitle,
    controls: <controls.RuneControls />,
    dialogKey: "rune",
  },
  charms: {
    title: "Charms",
    subTitle,
    controls: <controls.CharmControls />,
    dialogKey: "charm",
  },
  "status-effects": {
    title: "Status Effects",
    subTitle,
    controls: <controls.StatusEffectControls />,
  },
  favorites: {
    title: "Your Favorites",
    controls: <controls.FavoriteControls />,
  },
};

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(4),
  },
}));

const Header = ({ pageSlug, infoDialogIsOpen, setInfoDialogIsOpen }) => {
  if (pageSlug == null || rawHeaderData[pageSlug] == null) {
    return null;
  }

  const data = rawHeaderData[pageSlug];

  return (
    <Wrapper>
      <InfoDialog
        type={data.dialogKey}
        open={infoDialogIsOpen}
        setOpen={setInfoDialogIsOpen}
      />
      <Container sx={{ position: "relative" }}>
        <UseCodeAxieTechWrapper>
          <UseCodeAxieTech />
        </UseCodeAxieTechWrapper>
        <HeaderInnerWrapper>
          <StackedTitle>
            <SubTitle>{data.subTitle}</SubTitle>
            <Title>{data.title}</Title>
          </StackedTitle>
          <ControlsWrapper>{data.controls}</ControlsWrapper>
        </HeaderInnerWrapper>
        <Flag />
      </Container>
    </Wrapper>
  );
};

export default Header;
