import React from "react";
import { Typography } from "@mui/material";

const PriceUSD = ({ children, large, color }) => (
  <Typography
    variant="span"
    fontSize={large ? "20px" : "16px"}
    fontWeight="600"
    color={color ? color : "text.muted"}
  >
    {children}
  </Typography>
);

export default PriceUSD;
