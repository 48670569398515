import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Card from "components/Card/Card";
import CustomLink from "components/CustomLink/CustomLink";
import { makeEtherscanUrlFromEthAddress } from "helpers/display";
import ActionButtons from "../molecules/ActionButtons";
import { connect } from "react-redux";
import { getGuildsToDisplay } from "store/guildDashboardAdmin/adminSelectors";
import moment from "moment";
import { DATE_TIME_FORMAT } from "config";
import GuildPFP from "../atoms/GuildPFP";
import { Stack } from "@mui/material";

const GuildTable = ({ guilds, handleOpenEditGuild }) => {
  const columns = [
    {
      field: "logoImageUrl",
      headerName: "",
      minWidth: 80,
      align: "center",
      renderCell: (cellValues) =>
        cellValues.row.profileImage == null ? (
          <></>
        ) : (
          <GuildPFP
            url={cellValues.row.profileImageFullUrl}
            alt={cellValues.row.name}
          />
        ),
    },
    {
      field: "name",
      headerName: "Guild Name",
      minWidth: 170,
      align: "left",
      renderCell: (cellValues) => (
        <CustomLink to={`/guild/${cellValues.row.logoUri}`} target="_blank">
          {cellValues.row.name}
        </CustomLink>
      ),
    },
    {
      field: "logoUri",
      headerName: "Page Slug",
      minWidth: 170,
      align: "left",
    },
    {
      field: "loginAddress",
      headerName: "Login Address",
      flex: 1,
      minWidth: 200,
      align: "left",
      renderCell: (cellValues) => (
        <CustomLink
          href={makeEtherscanUrlFromEthAddress(cellValues.row.loginAddress)}
        >
          {cellValues.row.loginAddress}
        </CustomLink>
      ),
    },
    {
      field: "isEnabled",
      headerName: "Status",
      minWidth: 100,
      align: "left",
      renderCell: (cellValues) => {
        return cellValues.row.isEnabled === 1 ? "active" : "inactive";
      },
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 160,
      align: "left",
      renderCell: (cellValues) => {
        return moment(cellValues.row.createdAt).format(DATE_TIME_FORMAT);
      },
    },
    {
      field: "actionButtons",
      headerName: "",
      width: 40,
      sortable: false,
      disableClickEventBubbling: true,
      align: "right",
      renderCell: (cellValues) => (
        <ActionButtons
          guild={{ ...cellValues.row }}
          handleOpenEditGuild={handleOpenEditGuild}
        />
      ),
    },
  ];

  return (
    <Stack sx={{ width: "100%" }}>
      <Card>
        {guilds != null && (
          <DataGrid
            autoHeight
            rows={guilds}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[25, 50, 100]}
            getRowId={(row) => row.guildId}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
          />
        )}
      </Card>
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guilds: getGuildsToDisplay(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuildTable);
