export const AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS =
  "AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS";
export const AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_STARTED =
  "AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_STARTED";
export const AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_FAILED =
  "AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_FAILED";
export const AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_SUCCEEDED =
  "AXIE_QUEST_EXPLORER_FETCH_AXIE_QUEST_CARDS_SUCCEEDED";
export const AXIE_QUEST_EXPLORER_UPDATE_CONTROLS =
  "AXIE_QUEST_EXPLORER_UPDATE_CONTROLS";
