import { Box, Stack, Typography } from "@mui/material";

const Divider = ({ icon, text }) => {
  return (
    <>
      <Stack justify-content="center" marginTop="10px">
        <Box
          sx={{
            background: (theme) => theme.palette.text.primary,
            minHeight: "4px",
            marginBottom: "15px",
          }}
        ></Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "8px",
            marginBottom: "10px",
          }}
        >
          <Typography variant="span" align="center" marginRight="5px">
            {icon ? icon : null}
          </Typography>
          <Typography
            variant="span"
            fontSize="14px"
            color="text.regular"
            align="center"
            fontWeight={600}
          >
            {text ? text : null}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default Divider;
