import React from "react";
import { Typography } from "@mui/material";

const Title = ({ text, color }) => {
  return (
    <Typography
      variant="span"
      fontWeight="600"
      sx={{ color: (theme) => color || theme.palette.footer.header }}
    >
      {text}
    </Typography>
  );
};

export default Title;
