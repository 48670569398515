import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import ConditionalWrapper from "components/Utility/ConditionalWrapper";
import RuneTemplate from "./RuneTemplate";

const Rune = ({ onClick, rune, width = 1181, style, tooltip }) => {
  if (rune == null) {
    return null;
  }
  return (
    <ConditionalWrapper
      condition={tooltip}
      wrapper={(children) => (
        <Tooltip
          title={<RuneTemplate rune={rune} width={180} />}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "transparent",
                boxShadow: "none",
                border: "none",
              },
            },
          }}
        >
          {children}
        </Tooltip>
      )}
    >
      <Box
        onClick={onClick}
        sx={{
          width,
          height: width,
          position: "relative",
          ...style,
        }}
      >
        <RuneIcon image={rune.image} name={rune.name} width={width} />
      </Box>
    </ConditionalWrapper>
  );
};

const RuneIcon = ({ image, name, width }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        "& img": {
          height: width,
          m: "0 auto",
          display: "block",
        },
      }}
    >
      <img src={image} alt={name} />
    </Stack>
  );
};

export default Rune;
