import React from "react";
import { Stack, useTheme, Tooltip } from "@mui/material";
import SectionLabel from "../atoms/SectionLabel";
import Text from "components/Typography/Text";

const PotentialPoints = ({ potentialPoints }) => {
  const theme = useTheme();

  return (
    <Stack gap={1.5}>
      <SectionLabel>Potential Points</SectionLabel>
      <Stack gap={1} direction="row" alignItems="center" flexWrap="wrap">
        {potentialPoints.map((points, i) => (
          <Tooltip title={points.class} key={`POINT_${i}`}>
            <Stack
              gap={0.75}
              alignItems="center"
              sx={{
                background: theme.palette.card.background,
                borderRadius: "16px",
                boxShadow: `0px 0px 15px ${theme.palette.inspector.boxShadow}`,
                py: 0.75,
                px: 0.75,
                fontSize: 14,
                transition: "0.2s transform ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  cursor: "pointer",
                },
                img: { height: 20 },
              }}
            >
              <img
                src={`/images/class-icons/${points.class}.png`}
                alt={`${points.class} potential points`}
              />
              <Text>{points.amount}</Text>
            </Stack>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
};

export default PotentialPoints;
