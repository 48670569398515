import { Stack, Typography } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import Text from "components/Typography/Text";
import styled from "@emotion/styled";

// check dark mode colors- add in theme
const Level = styled(
  Typography,
  {}
)(() => ({
  fontSize: 19,
  fontWeight: 700,
  color: "#C595FF",
  lineHeight: 1.6,
}));

const Axp = styled(
  Typography,
  {}
)(() => ({
  fontSize: 19,
  fontWeight: 700,
  color: "#52B6CB",
}));

const CurrentLevel = ({ level, onchainLevel, axp }) => {
  return (
    <Stack justifyContent="flex-start" gap={1}>
      <SectionLabel>Current Level</SectionLabel>
      <Stack alignItems="flex-start">
        <Text>Level</Text>
        <Level>{level}</Level>
      </Stack>
      <Stack alignItems="flex-start">
        <Text>On-Chain Level</Text>
        <Level>{onchainLevel}</Level>
      </Stack>
      <Stack alignItems="flex-start">
        <Text>AXP</Text>
        <Axp>{axp ?? 0}</Axp>
      </Stack>
    </Stack>
  );
};

export default CurrentLevel;
