import { createSelector } from "reselect";
import {
  calculateMMR,
  calculateTotalSLP,
  countAxiesByClass,
  getScholarMoonshards,
} from "helpers/scholars";
import { getScholarTeamName } from "helpers/team";
import {
  getGuildTeams,
  getActiveGuildTeamId,
  getTeamlessScholars,
  getTeamedScholars,
} from "./common";

export const getActiveTeam = createSelector(
  [getGuildTeams, getActiveGuildTeamId],
  (teams, guildTeamId) => {
    return teams.find((team) => team.guildTeamId === guildTeamId);
  }
);

export const getGuildBattleTeam = createSelector([getGuildTeams], (teams) => {
  return teams.filter((team) => team.name === "Guild Battle");
});

export const getFilteredTeams = createSelector([getGuildTeams], (teams) => {
  return teams.filter((team) => team.name !== "Guild Battle");
});

export const getAllScholarsNotInActiveTeam = createSelector(
  [getTeamlessScholars, getTeamedScholars, getActiveGuildTeamId, getGuildTeams],
  (teamlessScholars, teamedScholars, activeGuildTeamId, teams) => {
    return [
      ...teamlessScholars,
      ...teamedScholars.filter(
        (scholar) => scholar.guildTeamId !== activeGuildTeamId
      ),
    ].map((scholar) => ({
      id: scholar.roninAddress,
      name: scholar.name,
      axies: scholar.axies,
      totalSLP: calculateTotalSLP(scholar),
      moonshards: getScholarMoonshards(scholar),
      mmr: calculateMMR(scholar),
      fetchingAxies: scholar?.fetchingAxies,
      guildTeamId: scholar.guildTeamId,
      guildTeamName: getScholarTeamName(scholar, teams),
      axieClassCounts: countAxiesByClass(scholar),
      totalAxies: (scholar.axies || []).length,
    }));
  }
);

export const getTeamsListForDropdown = createSelector(
  [getGuildTeams],
  (teams) => {
    return [
      { label: "No team", value: 0 },
      ...teams.map((team) => ({ label: team.name, value: team.guildTeamId })),
    ];
  }
);

export const getAllScholarRoninAddresses = createSelector(
  [getTeamlessScholars, getGuildTeams],
  (teamlessScholars, teams) => {
    const teamedScholars = teams.reduce((result, team) => {
      if (team.scholars && team.scholars.length > 0) {
        result.push(...team.scholars.map((scholar) => scholar.roninAddress));
      }
      return result;
    }, []);
    return [
      ...teamlessScholars.map((scholar) => scholar.roninAddress),
      ...teamedScholars,
    ];
  }
);

export const getAllTeamIds = createSelector([getGuildTeams], (teams) => {
  if (teams == null || teams.length === 0) {
    return undefined;
  }
  const ids = teams.map((team) => team.guildTeamId);
  return [0, ...ids];
});
