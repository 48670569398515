import React from "react";
import { IconButton, Typography } from "@mui/material";

const CardDisplayToggle = ({ setDisplayV3Cards, displayV3Cards, display }) => {
  return display ? (
    <IconButton
      onClick={() => setDisplayV3Cards(!displayV3Cards)}
      sx={{
        opacity: 0,
        position: "absolute",
        right: 0,
        top: -26,
        zIndex: 3,
        p: 0.75,
      }}
      className="cardVersionButton"
    >
      <Typography
        variant="span"
        fontSize={10}
        fontWeight={600}
        color="text.muted"
      >
        {displayV3Cards ? "V3" : "V2"}
      </Typography>
    </IconButton>
  ) : (
    <></>
  );
};

export default CardDisplayToggle;
