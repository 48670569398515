import { colors } from "theme/colors";

export const originTheme = {
  background: colors.origin.wood.background,
  footer: {
    background: colors.origin.wood.background,
    text: colors.origin.wood.text,
    textHover: "#fff",
    headerText: "#fff",
  },
  navigation: {
    background: "#241410",
    border: "#100e0d",
    text: colors.origin.wood.text,
    textHover: "#fff",
    dropdown: {
      text: colors.origin.wood.background,
      textHover: colors.origin.wood.background,
      background: colors.origin.paper.medium,
      border: colors.origin.paper.dark,
      fontWeight: 800,
      sectionLabel: "#906A56",
    },
    logo: "#fff",
  },
};
