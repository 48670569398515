import React from "react";
import { Stack, Typography } from "@mui/material";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Card from "components/Card/Card";

const GuildLoading = () => {
  return (
    <Card outline={true}>
      <Stack gap={2} alignItems="center">
        <CustomLoader marginTop={0} marginBottom={32} />
        <Typography
          fontSize="28px"
          color="text.regular"
          fontWeight="700"
          variant="h1"
        >
          Loading guild...
        </Typography>
      </Stack>
    </Card>
  );
};

export default GuildLoading;
