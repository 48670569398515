import { useTheme, Stack, Typography } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";

const ScholarTeam = ({ name, guildTeamId }) => {
  const theme = useTheme();

  if (name == null) {
    return null;
  }

  return (
    <CustomLink to={`/guild-dashboard/team/${guildTeamId}`}>
      <Stack
        sx={{
          background: theme.palette.card.pop1,
          p: "6px 8px 4px 8px",
          borderRadius: "4px",
          "&:hover": {
            borderColor: "transparent",
            background: theme.palette.secondary.main,
            "& span": {
              color: theme.palette.secondary.contrastText,
            },
          },
          alignSelf: "flex-start",
        }}
      >
        <Typography
          variant="span"
          fontSize={15}
          fontWeight="600"
          color="text.muted"
        >
          {name}
        </Typography>
      </Stack>
    </CustomLink>
  );
};

export default ScholarTeam;
