import React from "react";
import GuildPFP from "../atoms/GuildPFP";
import { Button, Stack } from "@mui/material";

const PFPSaved = ({ onChange, url, alt, width }) => {
  if (url == null) {
    return (
      <Stack direction="row" alignItems="center" gap={2}>
        <Button variant="contained" component="label">
          Upload Avatar
          <input type="file" accept="image/*" onChange={onChange} hidden />
        </Button>
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <GuildPFP url={url} alt={alt} width={width} />
      <Button variant="contained" component="label">
        Replace
        <input type="file" accept="image/*" onChange={onChange} hidden />
      </Button>
    </Stack>
  );
};

export default PFPSaved;
