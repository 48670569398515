import { Box, Stack, useTheme } from "@mui/material";
import React from "react";

const Paper = ({ children }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        zIndex: 3,
        p: 2,
        borderRadius: 6,
        background: theme.palette.colors.origin.paper.medium,
      }}
    >
      <Stack
        sx={{
          pt: 1,
          pb: 10,
          background: theme.palette.colors.origin.paper.light,
          borderRadius: 4,
          position: "relative",
          height: "100%",
        }}
      >
        {children}
        <Box
          sx={{
            backgroundImage: "url('/images/explorer/pattern.png')",
            height: 56,
            width: "100%",
            backgroundRepeat: "repeat-x",
            opacity: 0.2,
            transform: "rotate(180deg)",
            position: "absolute",
            bottom: 16,
            top: "initial",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Paper;
