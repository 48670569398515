import { Grid, Typography, Stack, Box, Chip, useTheme } from "@mui/material";
import Card from "components/Card/Card";
import * as guildSelectors from "store/guildDashboard/selectors/guild";
import { connect } from "react-redux";
import Label from "../../../atoms/Label";
import { Link } from "react-router-dom";
import { makeScholarPageURLFromRoninAddress } from "helpers/guild";

const notableTeamTableStyles = {
  px: 1,
  display: "grid",
  gridTemplateColumns: "30px 2fr 1fr",
  gap: "8px",
  borderRadius: "4px",
};

const Scholar = ({ scholar, i }) => {
  const theme = useTheme();
  return (
    <Link to={makeScholarPageURLFromRoninAddress(scholar.roninAddress)}>
      <Box
        alignItems="center"
        sx={{
          background: i % 2 === 0 ? theme.palette.card.pop1 : "transparent",
          p: 1,
          ...notableTeamTableStyles,
        }}
      >
        <Cell align="center">{i}</Cell>
        <Cell fontWeight="600">{scholar.name}</Cell>
        <Cell align="right">{scholar.mmr}</Cell>
      </Box>
    </Link>
  );
};

const Cell = ({ children, align, fontWeight }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="span"
      fontSize="15px"
      fontWeight={fontWeight ? fontWeight : 500}
      color={theme.palette.card.text}
      textAlign={align}
    >
      {children}
    </Typography>
  );
};

const NotableTeam = ({ title, team }) => {
  const theme = useTheme();
  const { name, mmr, guildTeamId, scholars } = team;
  return (
    <Card>
      <Stack gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack gap={0.5}>
            <Typography
              variant="span"
              fontSize="13px"
              fontWeight="700"
              color={theme.palette.card.title}
              display="block"
            >
              {title}
            </Typography>
            <Link to={`/guild-dashboard/team/${guildTeamId}`}>
              <Typography
                variant="h1"
                fontSize="22px"
                fontWeight="700"
                color={theme.palette.text.primary}
              >
                {name}
              </Typography>
            </Link>
          </Stack>
          <Chip label={`${mmr} Avg Stars`} color="primary" />
        </Stack>
        <Stack>
          <Box sx={notableTeamTableStyles}>
            <Label align="center" color={theme.palette.card.subText}>
              #
            </Label>
            <Label color={theme.palette.card.subText}>Scholar</Label>
            <Label align="right" color={theme.palette.card.subText}>
              Stars
            </Label>
          </Box>
          {scholars &&
            scholars.map((scholar, i) => (
              <Scholar scholar={scholar} i={i + 1} key={`SCHOLAR_${i}`} />
            ))}
        </Stack>
      </Stack>
    </Card>
  );
};

const NotableTeams = ({ guildStats }) => {
  return guildStats?.bestTeam || guildStats?.worstTeam ? (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        {guildStats?.bestTeam && (
          <NotableTeam title="Top Team" team={guildStats?.bestTeam} />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {guildStats?.worstTeam && (
          <NotableTeam title="Bottom Team" team={guildStats?.worstTeam} />
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  guildStats: guildSelectors.getGuildStats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotableTeams);
