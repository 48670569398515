import Card from "components/Card/Card";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  useTheme,
  Button,
  Grid,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import QuickLinkTitle from "../atoms/QuickLinkTitle";

const QuickLinks = () => {
  const theme = useTheme();

  return (
    <Box sx={{ pb: 4 }}>
      <Container fixed>
        <Grid container spacing={3.5}>
          <Grid item xs={12} sm={12} md={4}>
            {/* card one */}
            <Card>
              <Stack
                position="relative"
                gap={1}
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                  height: "180px",
                  [theme.breakpoints.down("sm")]: {
                    height: "auto",
                  },
                }}
              >
                <Stack gap={0.75}>
                  <QuickLinkTitle>Card Explorer</QuickLinkTitle>
                  <Typography
                    variant="body2"
                    color={theme.palette.card.subText}
                    lineHeight={1.4}
                    sx={{
                      width: "70%",
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  >
                    Explore Cards, and Axie abilities in Axie Infinity: Origins!
                  </Typography>
                </Stack>
                <Button
                  sx={{ zIndex: 1 }}
                  component={RouterLink}
                  to="/explorer"
                  variant="contained"
                  endIcon={<IoCaretForwardCircleOutline />}
                >
                  Explore Cards
                </Button>
                <Box
                  sx={{
                    width: 190,
                    position: "absolute",
                    top: 7,
                    left: "161px",
                    "&img": { objectFit: "contain" },
                    [theme.breakpoints.down("lg")]: {
                      width: "170px",
                      left: "110px",
                      top: "30px",
                    },
                    [theme.breakpoints.down("md")]: {
                      width: "185px",
                      left: "311px",
                      top: "-27px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      alignSelf: "center",
                      position: "static",
                      width: "135px",
                    },
                  }}
                >
                  <img
                    width="100%"
                    src="/images/homepage-graphics/card-explorer.png"
                    title="Card Explorer"
                    alt="Card Explorer"
                  ></img>
                </Box>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            {/* card two */}
            <Card>
              <Stack
                position="relative"
                gap={1}
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ height: "100%" }}
              >
                <Stack gap={0.75}>
                  <QuickLinkTitle>Axie Inspector</QuickLinkTitle>
                  <Typography
                    variant="body2"
                    color={theme.palette.card.subText}
                    lineHeight={1.4}
                    sx={{
                      width: "70%",
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  >
                    Inspect an Axie to see its cards, stats, estimated price and
                    more!
                  </Typography>
                </Stack>
                <Button
                  sx={{ zIndex: 1 }}
                  component={RouterLink}
                  to="/inspector"
                  variant="contained"
                  endIcon={<IoCaretForwardCircleOutline />}
                >
                  Inspect axies
                </Button>
                <Box
                  sx={{
                    width: "210px",
                    position: "absolute",
                    top: "70px",
                    left: "152px",
                    "&img": { objectFit: "contain" },
                    [theme.breakpoints.down("lg")]: {
                      width: "170px",
                      left: "110px",
                      top: "55px",
                    },
                    [theme.breakpoints.down("md")]: {
                      width: "210px",
                      left: "300px",
                      top: "25px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      alignSelf: "center",
                      position: "static",
                      width: "135px",
                    },
                  }}
                >
                  <img
                    width="100%"
                    src="/images/homepage-graphics/olek-inspect.png"
                    title="Axie Inspector"
                    alt="Axie Inspector"
                  ></img>
                </Box>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            {/* card three */}
            <Card>
              <Stack
                position="relative"
                gap={1}
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ height: "100%" }}
              >
                <Stack gap={0.75}>
                  <QuickLinkTitle>Breeding Simulator</QuickLinkTitle>
                  <Typography
                    variant="body2"
                    color={theme.palette.card.subText}
                    lineHeight={1.4}
                    sx={{
                      width: "70%",
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  >
                    Enter Axie IDs to simulate all possible genetic outcomes!
                  </Typography>
                </Stack>
                <Button
                  sx={{ zIndex: 1 }}
                  component={RouterLink}
                  to="/breeding-simulator"
                  variant="contained"
                  endIcon={<IoCaretForwardCircleOutline />}
                >
                  Breed Axies
                </Button>
                <Box
                  sx={{
                    width: "200px",
                    transform: `rotate(10deg)`,
                    position: "absolute",
                    top: "35px",
                    left: "175px",
                    "&img": { objectFit: "contain" },
                    [theme.breakpoints.down("lg")]: {
                      width: "200px",
                      left: "120px",
                      top: "90px",
                    },
                    [theme.breakpoints.down("md")]: {
                      width: "210px",
                      left: "310px",
                      top: "0px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      alignSelf: "center",
                      position: "static",
                      width: "135px",
                    },
                  }}
                >
                  <img
                    width="100%"
                    src="/images/homepage-graphics/axie-egg-breeding-sim.png"
                    title="Breeding Simulator"
                    alt="Breeding Simulator"
                  ></img>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default QuickLinks;
