import * as types from "./actionTypes";
import produce from "immer";
import { getCurrentTimestamp } from "helpers/time";

const initialState = {
  controls: {
    searchText: "",
    currentPage: 1,
  },
  data: [],
  fetching: true,
};

export const dollSidekicksReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.DOLL_SIDEKICKS_FETCH_SIDEKICKS_STARTED:
        draft.fetching = true;
        draft.failed = false;
        break;

      case types.DOLL_SIDEKICKS_FETCH_SIDEKICKS_SUCCEEDED:
        draft.fetching = false;
        draft.data = action.data;
        draft.lastFetched = getCurrentTimestamp();
        break;

      case types.DOLL_SIDEKICKS_FETCH_SIDEKICKS_FAILED:
        draft.fetching = false;
        draft.failed = true;
        break;

      case types.DOLL_SIDEKICKS_UPDATE_CONTROLS:
        draft.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default dollSidekicksReducer;
