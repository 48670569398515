import React from "react";
import { Typography, useTheme } from "@mui/material";

const Label = ({ children, fontSize = 13, align, color }) => {
  const theme = useTheme();
  return (
    <Typography
      color={color || theme.palette.card.label}
      fontSize={fontSize}
      letterSpacing="0.25px"
      fontWeight="700"
      sx={{ textTransform: "uppercase" }}
      textAlign={align}
    >
      {children}
    </Typography>
  );
};

export default Label;
