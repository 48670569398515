import React from "react";
import { Container, Stack } from "@mui/material";

const Wrapper = ({ children }) => {
  return (
    <Container fixed>
      <Stack
        gap={4}
        sx={{
          pt: {
            xs: 4,
            sm: 4,
            md: 6,
          },
          pb: {
            xs: 12,
            sm: 12,
            md: 16,
          }
        }}
      >
        {children}
      </Stack>
    </Container>
  );
};

export default Wrapper;
