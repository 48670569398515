export enum AxieType {
  STARTER = "starter",
  RONIN = "ronin",
}

export interface Axie {
  axieId: string;
  name: string;
  class: string;
  breedCount: number;
  image: string;
  stage: number;
  genes: string;
  body: {
    d: string;
    r1: string;
    r2: string;
  };
  parts: {
    d: {
      eyes: Part;
      ears: Part;
      back: Part;
      mouth: Part;
      horn: Part;
      tail: Part;
    };
    r1: {
      eyes: Part;
      ears: Part;
      back: Part;
      mouth: Part;
      horn: Part;
      tail: Part;
    };
    r2: {
      eyes: Part;
      ears: Part;
      back: Part;
      mouth: Part;
      horn: Part;
      tail: Part;
    };
  };
  purity: {
    purityCount: number;
    purityPercent: number;
  };
  order: {
    id: number;
    maker: string;
    kind: string;
    assets: {
      erc: string;
      address: string;
      id: string;
      quantity: string;
      orderId: number;
      __typename: string;
    }[];
    expiredAt: number;
    paymentToken: string;
    startedAt: number;
    basePrice: string;
    endedAt: number;
    endedPrice: string;
    expectedState: string;
    nonce: number;
    marketFeePercentage: number;
    signature: string;
    hash: string;
    duration: number;
    timeLeft: number;
    currentPrice: string;
    suggestedPrice: string;
    currentPriceUsd: string;
    __typename: string;
  };
  stats: {
    hp: number;
    speed: number;
    skill: number;
    morale: number;
    __typename: string;
  };
  title: string;
  level: number;
  isBanned: boolean;
  birthDate: number;
  children: {
    id: string;
    name: string;
    class: string;
    image: string;
    title: string;
    stage: number;
    __typename: string;
  }[];
  matronClass: string;
  matronId: number;
  sireClass: string;
  sireId: number;
  ownerRonin: string;
}

interface Part {
  partId: string;
  class: string;
  specialGenes: any;
  type: string;
  name: string;
}
