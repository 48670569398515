import React, { useEffect, useState } from "react";
import { cancelFetching, search } from "store/inspector/inspectorActions";
import { connect } from "react-redux";
import { Stack, Box } from "@mui/material";
import Sidebar from "./Sidebar/Sidebar";
import Nav from "./Nav";
import { getInspectorData } from "store/inspector/inspectorSelectors";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { useParams } from "react-router";
import { removeNonNumericChars } from "helpers/cleaning";
import Search from "./Search";
import styled from "@emotion/styled";
import views from "./views";
import Tabs from "./Tabs";

const Inspector = ({ cancelFetching, search, inspector, loading, failed }) => {
  const [view, setView] = useState([views.profile]);
  const { id } = useParams();

  useEffect(() => {
    return () => {
      cancelFetching();
    };
  }, [cancelFetching]);

  useEffect(() => {
    if (id != null) {
      const cleanedId = removeNonNumericChars(id);
      if (cleanedId !== "") {
        search(cleanedId);
      }
    }
  }, [id, search]);

  /* Search */
  if (id == null || failed) {
    return (
      <Search
        cancelFetching={cancelFetching}
        setView={setView}
        failed={failed}
      />
    );
  }

  /* Loading */
  if (loading) {
    return <CustomLoader marginTop={300} marginBottom={300} />;
  }

  /* Dashboard */
  let page;
  if (view.includes(views.profile)) {
    page = (
      <Tabs.Profile
        inspector={inspector}
        setView={setView}
        axie={inspector?.axie}
      />
    );
  } else if (view.includes(views.cards)) {
    page = (
      <Tabs.Cards
        cards={inspector?.axie?.cards}
        color={inspector?.axie?.color}
        isAdult={inspector?.axie?.isAdult}
      />
    );
  } else if (view.includes(views.similarListings)) {
    page = <Tabs.SimilarListings inspector={inspector} />;
  } else if (view.includes(views.recentSales)) {
    page = <Tabs.RecentSales recentlySold={inspector?.recentlySold} />;
  } else if (view.includes(views.axieDoLL)) {
    page = <Tabs.AxieDoLL data={inspector?.axieDoLL} />;
  }

  return (
    <Box
      sx={{
        p: 3,
        pb: 8,
        background: (theme) => theme.palette.inspector.background,
      }}
    >
      <DashboardLayout>
        <Sidebar axie={inspector?.axie} />
        <Stack gap={4} sx={{ width: "100%" }}>
          <Nav inspector={inspector} view={view} setView={setView} />
          {page}
        </Stack>
      </DashboardLayout>
    </Box>
  );
};

const DashboardLayout = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  margin: "0 auto",
  width: "auto",
  maxWidth: 1400,
  gap: theme.spacing(12),

  "@media(min-width:1400px)": {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "auto",
  },
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(7),
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const mapDispatchToProps = {
  cancelFetching,
  search,
};

const mapStateToProps = (state) => ({
  inspector: getInspectorData(state),
  loading: state.inspector.axie.fetching,
  failed: state.inspector.axie.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Inspector);
