import React from "react";
import { TextField, Typography, Stack, Tooltip } from "@mui/material";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { formatUSD } from "helpers/display";
import Label from "components/Typography/Label";
import InputsHeader from "./InputsHeader";

const InventoryItems = ({ inventoryTokens, handleUpdate }) => {
  return (
    <Stack gap={2}>
      <InputsHeader
        title="Inventory"
        description="How many tokens do you currently have?"
      />
      <Stack direction="row" justifyContent="center" gap={1} flexWrap="wrap">
        {inventoryTokens.map((token, i) => (
          <Stack key={`ITEM_${i}`} alignItems="center" gap={1}>
            <img
              src={token.icon}
              alt={token.iconAltText}
              style={{ height: "40px" }}
            />
            <Label>{token.name}</Label>
            <Tooltip title="Current market price">
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography
                  variant="span"
                  color="text.bright"
                  fontSize="22px"
                  fontWeight="600"
                  sx={{ lineHeight: 1 }}
                >
                  {formatUSD(token.usdPrice)}
                </Typography>

                <Typography
                  fontSize="15px"
                  color="text.muted"
                  fontWeight="600"
                  sx={{ lineHeight: 1 }}
                >
                  Ξ{token.ethPrice}
                </Typography>
              </Stack>
            </Tooltip>
            <TextField
              onChange={(e) => {
                token.setNum(e.target.value);
                handleUpdate();
              }}
              label={`${token.name} Held`}
              type="number"
              variant="filled"
              size="small"
              value={token.num}
              InputProps={{
                disableUnderline: true,
              }}
            />

            <Stack direction="row" alignItems="center">
              <CustomCheckbox
                checked={token.useMarketPrice}
                onChange={() => token.setUseMarketPrice(!token.useMarketPrice)}
              />
              <Label>Use Market Price?</Label>
            </Stack>

            {!token.useMarketPrice && (
              <>
                <TextField
                  onChange={(e) => token.setPriceUSD(e)}
                  label={`${token.name} Cost (USD)`}
                  type="number"
                  variant="filled"
                  size="small"
                  value={token.basisCostUSD}
                  helperText="Defaults to market price"
                />
                <TextField
                  onChange={(e) => token.setPriceETH(e)}
                  label={`${token.name} Cost (ETH)`}
                  type="number"
                  variant="filled"
                  size="small"
                  value={token.basisCostETH}
                  helperText="Defaults to market price"
                />
              </>
            )}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default InventoryItems;
