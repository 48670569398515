import React from "react";
import { Stack, useTheme, Typography } from "@mui/material";
import ClearAllAxiesButton from "./ClearAllAxiesButton";
import ShareTeamButton from "./ShareTeamButton";
import Toggle from "./Toggle";

const Header = () => {
  const theme = useTheme();

  return (
    <Stack
      gap={1}
      flexWrap="wrap"
      alignItems="center"
      sx={{
        img: {
          height: 70,
        },
      }}
    >
      <Stack
        gap={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        sx={{
          img: {
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        }}
      >
        <Typography variant="h1" fontSize={32} color="text.bright">
          Team Builder
        </Typography>

        <Toggle />
        <img src="/images/axies/axie-2.png" alt="Fierce Axie" />
        <ShareTeamButton />
        <ClearAllAxiesButton />
      </Stack>
    </Stack>
  );
};

export default Header;
