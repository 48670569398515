import * as types from "./breedingSimulatorActionTypes";
import {
  put,
  call,
  takeEvery,
  retry,
  fork,
  take,
  spawn,
  select,
} from "redux-saga/effects";
import api from "services/api";
import * as actions from "./breedingSimulatorActions";
import { channel } from "redux-saga";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeAddBreedingAxieId(action) {
  const axieData = yield select((state) => state.breedingSimulator.axieData);
  if (!(action.axieId in axieData) || axieData[action.axieId] == null) {
    yield put(actions.fetchAxie(action.axieId));
  }
}

export function* makeFetchAxieRequest(action) {
  try {
    yield put(actions.fetchAxieStarted(action.axieId));
    const data = yield call(api.requestGetAxieDetail, action.axieId);
    yield put(actions.fetchAxieSucceeded(data));
  } catch (e) {
    console.log("Error fetching axie: ", action.axieId);
    yield put(actions.fetchAxieFailed(action.axieId));
  }
}

export function* makeFetchAllRoninAxiesRequest(action) {
  try {
    yield put(actions.fetchAllRoninAxiesStarted());
    for (const address of action.addresses) {
      yield call(makeFetchRoninAxiesRequest, address);
    }
    yield put(actions.fetchAllRoninAxiesSucceeded());
  } catch (e) {
    yield put(actions.fetchAllRoninAxiesFailed());
  }
}

export function* makeFetchRoninAxiesRequest(address) {
  try {
    yield put(actions.fetchRoninAxiesForAddressStarted(address));

    const variables = {
      from: 0,
      size: 100,
      sort: "IdDesc",
      auctionType: "All",
      owner: address,
      stages: [4],
    };
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestSearchAxies,
      variables
    );

    yield put(actions.fetchRoninAxiesForAddressSucceeded(data.axies, address));
  } catch (e) {
    yield put(actions.fetchRoninAxiesForAddressFailed(address));
  }
}

export function* fetchRoninAxieDetails(axies) {
  try {
    for (const idx in axies) {
      yield put(actions.fetchRoninAxieDetail(axies[idx].axieId));
    }
  } catch (e) {}
}

export function* watchFetchRoninAxieDetails() {
  const chan = yield call(channel);

  for (var i = 0; i < 5; i++) {
    yield fork(makeFetchRoninAxieDetailRequest, chan);
  }

  while (true) {
    const { axieId } = yield take(types.FETCH_RONIN_AXIE_DETAIL);
    yield put(chan, axieId);
  }
}

export function* makeFetchRoninAxieDetailRequest(chan) {
  while (true) {
    const axieId = yield take(chan);
    const data = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      api.requestGetAxieDetail,
      axieId
    );
    yield put(actions.fetchRoninAxieDetailSucceeded(data));
  }
}

export default function* breedingSimulatorSaga() {
  yield takeEvery(types.ADD_BREEDING_AXIE_ID, makeAddBreedingAxieId);
  yield takeEvery(types.FETCH_ALL_RONIN_AXIES, makeFetchAllRoninAxiesRequest);
  yield takeEvery(types.FETCH_AXIE, makeFetchAxieRequest);
  yield spawn(watchFetchRoninAxieDetails);
}
