import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";
import CustomLink from "components/CustomLink/CustomLink";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-11-30</Date>
      <Change type="improved">
        Updated and renamed our ABP games page to include non-ABP games:
        <CustomLink to="/ronin" endSpace={false}>
          Ronin Games
        </CustomLink>
        .
      </Change>
      <Change type="improved">
        Guild Dashboard: Various improvements to the Players table and Teams
        page.
      </Change>
    </Entry>
  );
};

export default Changes;
