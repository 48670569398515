import React from "react";
import ListOfLinks from "./ListOfLinks";
import { dropdownItems } from "components/Navigation/navigation.links";
import ColumnWrapper from "../atoms/ColumnWrapper";

const Column2 = ({ colorOverrides }) => (
  <ColumnWrapper>
    <ListOfLinks
      links={dropdownItems.axies}
      colorOverrides={colorOverrides}
      title="Axies"
    />

    <ListOfLinks
      links={dropdownItems.games}
      colorOverrides={colorOverrides}
      title="Games"
    />
  </ColumnWrapper>
);

export default Column2;
