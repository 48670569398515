import { useEffect } from "react";
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { loginManager } from "store/guildDashboard/actions/guildDashboardActions";
import Welcome from "./pages/Welcome";
import LoggedInWrapper from "./LoggedInWrapper";
import PageSEO from "components/Utility/PageSEO";
import { augmentTitle } from "data/seo";
import { AXIE_TECH_VIP_MEMBERSHIP_TAGLINE } from "config";
import { Box, useTheme } from "@mui/material";

const GuildDashboard = ({ guild, children, loginManager, fetchingTeams }) => {
  const { active, account } = useWeb3React();
  const theme = useTheme();

  useEffect(() => {
    if (account) {
      loginManager(account);
    }
  }, [account, loginManager]);

  return (
    <>
      <PageSEO
        title={augmentTitle("Guild Dashboard")}
        description={AXIE_TECH_VIP_MEMBERSHIP_TAGLINE}
      />
      <Box
        sx={{
          background: theme.palette.background.gradient,
          minHeight: "100vh",
          width: "100%",
        }}
      >
        {(!active || guild.fetching || guild.loginFailed) && (
          <Welcome loading={guild.fetching} loginFailed={guild.loginFailed} />
        )}

        {active && guild.fetching === false && !guild.loginFailed && (
          <LoggedInWrapper
            guild={guild}
            fetchingGuild={guild.fetching}
            fetchingTeams={fetchingTeams}
            children={children}
          />
        )}
      </Box>
    </>
  );
};

const mapDispatchToProps = { loginManager };

const mapStateToProps = (state) => ({
  guild: state.guildDashboard.guild,
  fetchingTeams: state.guildDashboard.guild.fetchingTeams,
});

export default connect(mapStateToProps, mapDispatchToProps)(GuildDashboard);
