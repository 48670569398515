import React from "react";
import { Stack, Typography } from "@mui/material";
import GameEnded from "./GameEnded";
import moment from "moment";

const GameTimestamp = ({ end }) => {
  const battleEnded = moment.utc(end).fromNow();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="2px"
      sx={{
        color: (theme) => theme.palette.text.muted,
        svg: { fontSize: "0.8em" },
      }}
    >
      <GameEnded end={end} />
      <Typography
        variant="span"
        fontSize={14}
        fontWeight={600}
        textAlign="center"
      >
        {battleEnded}
      </Typography>
    </Stack>
  );
};

export default GameTimestamp;
