import { create } from "zustand";

const useControls = create((set) => ({
  searchText: "",
  setSearchText: (searchText) => set((state) => ({ searchText })),
  rarity: [],
  setRarity: (rarity) => set((state) => ({ rarity })),
  battlePower: [],
  setBattlePower: (battlePower) => set((state) => ({ battlePower })),
  equippable: [],
  setEquippable: (equippable) => set((state) => ({ equippable })),
  equippedPower: [],
  setEquippedPower: (equippedPower) => set((state) => ({ equippedPower })),
}));

export default useControls;
