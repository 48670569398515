import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2021-11-18</Date>
      <Change type="bug">
        Guild Dashboard: We found a bug which was preventing some users from
        logging in – this should now be fixed.
      </Change>
      <Change type="improved">
        Guild Dashboard: Some of our bigger guilds were seeing browser crashes
        caused by the scholar tables not being paginated and the teams page
        being too busy. We have introduced a new, paginated scholar table
        (styling not yet completed), and stopped loading all teams by default.
        Instead, you can load individual scholar teams from the scholar table
        (right hand side). This will show you a preview of the axie classes that
        are in that scholar account. Once you've loaded a scholar's team in this
        way, it will be viewable in the Teams page.
      </Change>
      <Change type="improved">
        Guild Dashboard: We have changed the way that live data is loaded so
        that it should now be a fair bit faster.
      </Change>
    </Entry>
  );
};

export default Changes;
