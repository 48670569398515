import React from "react";
import Date from "../atoms/Date";
import Entry from "../atoms/Entry";
import Change from "../atoms/Change";

const Changes = () => {
  return (
    <Entry>
      <Date>2022-6-9</Date>
      <Change type="new">
        Guild Dashboard: Added a status indicator to show which guild members
        have not played any battles in the last 3 days.
      </Change>
    </Entry>
  );
};

export default Changes;
