import React from "react";
import { FaWindows } from "react-icons/fa";
import { SiIos } from "react-icons/si";
import { DiAndroid } from "react-icons/di";
import { IoBrowsers } from "react-icons/io5";
import { Stack, Tooltip, useTheme } from "@mui/material";
import { MdDesktopMac } from "react-icons/md";

const Platform = ({ platform }) => {
  const theme = useTheme();
  const icons = {
    PC: <FaWindows />,
    Android: <DiAndroid />,
    iOS: <SiIos />,
    Browser: <IoBrowsers />,
    Mac: <MdDesktopMac />,
  };

  return (
    <Tooltip title={platform}>
      <Stack
        sx={{
          svg: {
            color: theme.palette.text.veryMuted,
            transition: "0.2s color ease",
            "&:hover": {
              color: theme.palette.text.muted,
            },
          },
        }}
      >
        {icons[platform]}
      </Stack>
    </Tooltip>
  );
};

export default Platform;
