import React, { useEffect } from "react";
import BreedingResult from "./BreedingResult";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { connect } from "react-redux";
import {
  toggleLockBreedingPair,
  deleteBreedingAxiePair,
  updateNumberOfBreedsForPair,
} from "store/breedingSimulator/breedingSimulatorActions";
import { getSortedPairString } from "helpers/display";
import { Pagination, Stack } from "@mui/material";
import { NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE } from "config";

const Pairs = ({
  toggleLockBreedingPair,
  deleteBreedingAxiePair,
  numberOfBreeds,
  updateNumberOfBreedsForPair,
  handleDeleteId,
  axieData,
  results,
  updateCurrentPage,
  currentPage,
  loading,
  totalNumResults,
  locked,
  hideLock,
}) => {
  const handlePageChange = (e, value) => {
    updateCurrentPage(value);
  };

  useEffect(() => {
    if (results.length === 0) {
      updateCurrentPage(1);
    }
  }, [results, updateCurrentPage]);

  const numberOfPages = totalNumResults
    ? Math.ceil(totalNumResults / NUM_BREEDING_SIMULATOR_RESULTS_PER_PAGE)
    : 1;

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <Stack gap={8}>
            <Stack gap={3}>
              {[...results].map((result) => (
                <BreedingResult
                  data={result}
                  parentData={[
                    axieData[result.pair[0]],
                    axieData[result.pair[1]],
                  ]}
                  parentIds={[result.pair[0], result.pair[1]]}
                  key={`BREEDING_RESULT_${result.pair[0]}_${result.pair[1]}`}
                  toggleLockPair={toggleLockBreedingPair}
                  deleteBreedingAxiePair={deleteBreedingAxiePair}
                  handleDeleteId={handleDeleteId}
                  numberOfBreeds={
                    numberOfBreeds[
                      getSortedPairString([result.pair[0], result.pair[1]])
                    ] || 1
                  }
                  updateNumberOfBreedsForPair={updateNumberOfBreedsForPair}
                  locked={locked}
                  hideLock={hideLock}
                />
              ))}
            </Stack>
            {numberOfPages > 1 && (
              <Pagination
                shape="rounded"
                onChange={handlePageChange}
                count={numberOfPages}
                page={currentPage}
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  toggleLockBreedingPair,
  deleteBreedingAxiePair,
  updateNumberOfBreedsForPair,
};

const mapStateToProps = (state) => {
  return {
    axieData: state.breedingSimulator.axieData,
    loading: state.breedingSimulator.loading,
    numberOfBreeds: state.breedingSimulator.numberOfBreeds,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pairs);
