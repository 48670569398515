import React from "react";
import { Stack } from "@mui/material";
import CardTemplatePreview from "components/GameTemplates/CardTemplatePreview";
import { BODY_PARTS_IN_ORDER } from "config";

const Cards = ({ cards, width }) => {
  if (cards == null) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={(1 / 320) * width}
    >
      {BODY_PARTS_IN_ORDER.map(
        (part, i) =>
          cards[part] != null && (
            <CardTemplatePreview
              key={`CARD_${i}`}
              card={cards[part]}
              width={Math.floor(
                (width - (24 / 320) * width - (18 / 320) * width) / 3
              )}
            />
          )
      )}
    </Stack>
  );
};

export default Cards;
