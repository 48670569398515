import React from "react";
import LinkWrapper from "../atoms/LinkText";
import { NavLink, useLocation } from "react-router-dom";
import NavigationDropdown from "./Dropdown";
import { ListItem } from "@mui/material";
import { MOBILE_BREAKPOINT } from "../Navigation";

const DesktopNavItem = ({ link, colorOverrides }) => {
  const { pathname } = useLocation();

  const onMouseEnterDropdown = (setOpen) => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const onMouseLeaveDropdown = (setOpen) => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  if (link.dropdownItems != null) {
    const isActive =
      link.dropdownItems.some((item) => pathname.includes(item.to)) ||
      link.to === pathname;

    return (
      <ListItem
        onMouseEnter={() => onMouseEnterDropdown(link.setIsOpen)}
        onMouseLeave={() => onMouseLeaveDropdown(link.setIsOpen)}
        sx={{ p: 0 }}
      >
        <LinkWrapper
          isActive={isActive}
          text={link.text}
          colorOverrides={colorOverrides}
          label={link.label}
        />
        {link.isOpen && (
          <NavigationDropdown
            items={link.dropdownItems}
            secondaryItems={link.secondaryDropdownItems}
            alignRight={link.alignRight}
            colorOverrides={colorOverrides}
          />
        )}
      </ListItem>
    );
  }

  return (
    <ListItem sx={{ p: 0 }}>
      <NavLink to={link.to}>
        <LinkWrapper
          isActive={link.to === pathname}
          text={link.text}
          colorOverrides={colorOverrides}
        />
      </NavLink>
    </ListItem>
  );
};

export default DesktopNavItem;
