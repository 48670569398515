import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  top: -${(props) => props.amount}px;
  margin-bottom: -${(props) => props.amount}px;
`;

const PullUp = ({ children, amount = 20 }) => {
  return <Wrapper amount={amount}>{children}</Wrapper>;
};

export default PullUp;
