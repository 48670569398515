import { Button, Stack, useTheme } from "@mui/material";
import { HiArrowSmLeft } from "react-icons/hi";
import OtherInfo from "./OtherInfo";
import About from "./About";
import Image from "./Image";
import Badges from "./Badges";
import { Link as RouterLink } from "react-router-dom";
import PotentialPoints from "./PotentialPoints";
import RoleSpecialty from "./RoleSpecialty";
import Stats from "./Stats";

const Sidebar = ({ axie }) => {
  const theme = useTheme();
  return axie == null ? (
    <></>
  ) : (
    <Stack
      gap={4}
      sx={{
        minHeight: "75vh",
        pt: 2,
        width: 200,
        [theme.breakpoints.down("sm")]: {
          minHeight: 0,
          width: "100%",
        },
      }}
    >
      <Stack alignItems="flex-start">
        <Button
          variant="text"
          startIcon={<HiArrowSmLeft />}
          size="small"
          component={RouterLink}
          to="/inspector"
          sx={{ position: "relative", zIndex: 2 }}
        >
          Search Again
        </Button>
        <Image axie={axie} />
      </Stack>
      <About axie={axie} />
      <RoleSpecialty axie={axie} />
      <PotentialPoints potentialPoints={axie.potentialPoints} />
      <Stats axie={axie} />
      <Badges badges={axie.badges} />
      <OtherInfo birthday={axie.birthday} owner={axie.owner} />
    </Stack>
  );
};

export default Sidebar;
