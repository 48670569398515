import React from "react";
import { Stack, useTheme } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import DecodedGenes from "components/Axie/AxieV3/components/DecodedGenes/DecodedGenes";
import { useMediaQuery } from "@mui/material";

const GenesDecoded = ({ axie }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack gap={2}>
      <SectionLabel>Genes</SectionLabel>
      <DecodedGenes
        width={smDown ? 340 : 450}
        axie={axie}
        large={true}
        showGenes={true}
        variant="full"
        style={{
          background: theme.palette.card.background,
          borderRadius: "8px",
          boxShadow: "0px 2px 15px rgba(0,0,0,.09)",
          alignSelf: "flex-start",
        }}
      />
    </Stack>
  );
};

export default GenesDecoded;
