export const getColumnPreferences = (state) => {
  const guild = state.guildDashboard?.guild;
  return (
    {
      isDarkMode: guild.isDarkMode,
      showTodaySLP: guild.showTodaySLP,
      showYesterdaySLP: guild.showYesterdaySLP,
      showEnergy: guild.showEnergy,
      showAxies: guild.showAxies,
      showInvestorShare: guild.showInvestorShare,
    } || {
      isDarkMode: 0,
      showTodaySLP: 1,
      showYesterdaySLP: 1,
      showEnergy: 1,
      showAxies: 1,
      showInvestorShare: 1,
    }
  );
};

export const getTeamlessScholars = (state) => {
  return state.guildDashboard?.guild.scholars || [];
};

export const getTeamedScholars = (state) => {
  if (!state.guildDashboard?.guild?.teams) {
    return [];
  }
  return state.guildDashboard?.guild.teams.flatMap((team) =>
    team.scholars ? team.scholars : []
  );
};

export const getAllScholars = (state) => [
  ...getTeamlessScholars(state),
  ...getTeamedScholars(state),
];

export const getGuildTeams = (state) => {
  return state.guildDashboard?.guild?.teams || [];
};

export const getActiveGuildTeamId = (state) => {
  return state.guildDashboard?.guild?.activeGuildTeamId || 0;
};

export const getActiveScholarRoninAddress = (state) => {
  return state.guildDashboard?.config?.activeScholarRoninAddress;
};

export const getScholarToModifyIds = (state) => {
  return state.guildDashboard.scholarToModifyIds || {};
};

export const getGuildSearchQuery = (state) => {
  return state.guildDashboard?.guild.searchQuery || "";
};

export const getScholarPVPBattlesCurrentPage = (state) => {
  return state.guildDashboard.config.scholarPVPBattlesCurrentPage || 1;
};

export const getAxieData = (state) => {
  return state.data?.axieData || {};
};

export const getAutopayoutLog = (state) => {
  return state.guildDashboard?.autopayout?.log?.data || [];
};

export const getAxiesCriteria = (state) => {
  return (
    state.guildDashboard?.axies || {
      breedCount: [],
      purity: [],
      purityPercent: 0,
      hp: 27,
      skill: 27,
      speed: 27,
      morale: 27,
      sortType: "MMR_DESC",
      axieClasses: [],
      searchQuery: "",
      showGenes: true,
      selectedParts: [],
      currentPage: 1,
    }
  );
};

export const getAverageDailySLP = (state) => {
  return (
    parseInt(
      state.guildDashboard?.guild?.slpLeaderboardPosition?.averageDailySLP
    ) || {}
  );
};

export const sortTeams = (a, b, sortType) => {
  const aName = a.name;
  const bName = b.name;

  const getElo = (scholar) => {
    if ("liveData" in scholar && scholar.liveData?.elo) {
      return parseInt(scholar.liveData.elo);
    } else if (scholar.elo) {
      return parseInt(scholar.elo);
    }
    return 0;
  };

  const aMMR = getElo(a);
  const bMMR = getElo(b);
  const aAxies = a?.axies ? a.axies.length : 0;
  const bAxies = b?.axies ? b.axies.length : 0;

  switch (sortType) {
    case "AXIES_DESC":
      return aAxies > bAxies ? -1 : aAxies < bAxies ? 1 : 0;
    case "AXIES_ASC":
      return aAxies < bAxies ? -1 : aAxies > bAxies ? 1 : 0;
    case "MMR_DESC":
      return aMMR > bMMR ? -1 : aMMR < bMMR ? 1 : 0;
    case "MMR_ASC":
      return aMMR < bMMR ? -1 : aMMR > bMMR ? 1 : 0;
    case "NAME_ASC":
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    case "NAME_DESC":
    default:
      return aName > bName ? -1 : aName < bName ? 1 : 0;
  }
};
