export const gamesPageUrl = "/games";

const axies = [
  {
    text: "Inspector",
    to: "/inspector",
  },
  {
    text: "Breeding Simulator",
    to: "/breeding-simulator",
  },
  {
    text: "Breeding Costs",
    to: "/breeding-costs",
  },
  {
    text: "Breeding Calculator",
    to: "/breeding-calculator",
  },
  {
    text: "Recently Listed",
    to: "/recently-listed",
  },
  {
    text: "Recently Sold",
    to: "/recently-sold",
  },
];

export const games = [
  {
    text: "Games Directory",
    to: gamesPageUrl,
  },
  {
    text: "Axie & Ronin Support",
    to: "/axie-ronin-support",
  },
];

export const dollLinks = [
  {
    text: "Leaderboard",
    to: "/doll/leaderboard",
  },
  {
    text: "Card Explorer",
    to: "/doll/card-explorer",
  },
  {
    text: "Sidekicks",
    to: "/doll/sidekicks",
  },
];

export const axieQuestLinks = [
  {
    text: "Skill Explorer",
    to: "/axie-quest/skill-explorer",
  },
];

export const interDungeonLinks = [
  {
    text: "Card Explorer",
    to: "/inter-the-dungeon/card-explorer",
  },
];

const gamesSecondary = [
  {
    label: "Axie DoLL",
    links: dollLinks,
  },
  {
    label: "Axie Quest",
    links: axieQuestLinks,
  },
  {
    label: "Inter The Dungeon",
    links: interDungeonLinks,
  },
];

const classic = [
  {
    text: "Card Explorer",
    to: "/classic/card-explorer",
  },
  {
    text: "Leaderboard",
    to: "/classic/arena-leaderboard",
  },
];

const origins = [
  {
    text: "Card Explorer",
    to: "/explorer/cards",
  },
  {
    text: "Leaderboard",
    to: "/origins-leaderboard",
  },
  {
    text: "Rune Explorer",
    to: "/explorer/runes",
  },
  {
    text: "Team Builder",
    to: "/team-builder",
  },
  {
    text: "Charm Explorer",
    to: "/explorer/charms",
  },
  {
    text: "CraftingDex",
    to: "/craftingdex",
  },
  {
    text: "Status Effects",
    to: "/explorer/status-effects",
  },
];

const guilds = [
  {
    text: "Guild Dashboard",
    to: "/guild-dashboard",
  },
  {
    text: "Guild Membership",
    to: "/guild-membership",
  },
  {
    text: "Guild Battle",
    to: "/guild-battle-leaderboard",
  },
  {
    text: "Top 25 Guilds",
    to: "/guild-leaderboards",
  },
];

export const dropdownItems = {
  axies,
  origins,
  classic,
  games,
  gamesSecondary,
  guilds,
};
