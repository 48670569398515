import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const ControlsWrapper = styled(Stack)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  justifyContent: "center",
}));

export default ControlsWrapper;
