import { keyframes } from "@mui/system";

export const shine = keyframes`
  from {
    opacity: 0;
    left: 0%;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 0;
    left: 100%;
  }
`;
