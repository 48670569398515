import React from "react";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";

const FilterBlockchainStatus = ({ value, setValue, style }) => {
  return value != null && setValue != null ? (
    <CustomMultiSelect
      label="Ronin Status"
      value={value}
      setValue={setValue}
      options={["Blockchain", "Non-Blockchain"]}
      style={style}
    />
  ) : (
    <></>
  );
};
export default FilterBlockchainStatus;
