import React from "react";
import { Button } from "@mui/material";
import { FaFilter } from "react-icons/fa";

const FilterPopupButton = ({ id, isOpen, updateIsOpen }) => {
  return (
    <Button
      variant="outlined"
      endIcon={<FaFilter />}
      id={id}
      aria-controls={id}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : undefined}
      onClick={updateIsOpen}
      size="small"
      disableElevation={true}
      sx={{
        textTransform: "none",
        fontSize: 15,
        fontWeight: 500,
        height: 47,
        background: (theme) =>
          theme.palette.input.outlined.background,
        color: (theme) => theme.palette.text.regular,
        border: (theme) =>
          `2px solid ${theme.palette.input.outlined.borderColor}`,
        "&:hover": {
          background: (theme) =>
            theme.palette.input.outlined.background,
          border: (theme) =>
            `2px solid ${theme.palette.input.outlined.borderColorSecondary}`,
        },
      }}
    >
      Filter
    </Button>
  );
};

export default FilterPopupButton;
