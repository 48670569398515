import fonts from "theme/fonts";

const BarChartLabel = ({ x, y, fill, value, width }) => (
  <text
    x={x + width / 2}
    y={y}
    dy={-4}
    fontSize="14"
    fontWeight="bold"
    fontFamily={fonts.body}
    fill={fill}
    textAnchor="middle"
  >
    {value}
  </text>
);

export default BarChartLabel;
