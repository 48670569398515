import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const HeaderInnerWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexWrap: "wrap",
  minHeight: 230,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
    minHeight: 386,
    alignItems: "center",
    paddingBottom: theme.spacing(10),
  },
}));

export default HeaderInnerWrapper;
