import { Stack, Typography, useTheme } from "@mui/material";
import fonts from "theme/fonts";

const PlayerRankNumber = ({ number }: { number: number }) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: "4px",
        height: 32,
        gridArea: "rank",
        [theme.breakpoints.down("sm")]: {
          height: "auto",
        },
      }}
    >
      <Typography
        component="span"
        fontWeight={400}
        fontSize={number >= 100 ? 24 : 30}
        fontFamily={fonts.changa}
        color={theme.palette.colors.origin.wood.text}
        sx={{
          position: "relative",
          bottom: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: 24,
          },
        }}
      >
        {number}
      </Typography>
    </Stack>
  );
};

export default PlayerRankNumber;
