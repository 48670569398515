import React from "react";
import { useTheme, Stack } from "@mui/material";
import NameAndClass from "./NameAndClass";
import RuneSlot from "../../atoms/RuneSlot";
import { useMediaQuery } from "@mui/material";
import SidebarInfo from "./SidebarInfo";
import styled from "@emotion/styled";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "midSized",
})(({ theme, midSized }) => ({
  gap: theme.spacing(2),
  flexDirection: midSized ? "row" : "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.card.border}`,
  padding: "8px",
  borderRadius: theme.spacing(3),
  maxWidth: midSized ? "100%" : 198,
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(8),
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    gap: theme.spacing(3),
    justifyContent: "center",
  },
}));

const Sidebar = ({
  axie,
  updateRune,
  handleOpenRuneDialog,
  view,
  updateClass,
  updateCharm,
}) => {
  const theme = useTheme();
  const midSized = useMediaQuery("(max-width:1440px)");

  return (
    <Wrapper midSized={midSized}>
      <Stack
        gap={midSized ? 8 : 2}
        flexDirection={midSized ? "row" : "column"}
        flexWrap={midSized ? "wrap" : "nowrap"}
        sx={{
          [theme.breakpoints.down("sm")]: {
            gap: 3,
            justifyContent: "center",
          },
        }}
      >
        <NameAndClass
          axie={axie}
          updateClass={updateClass}
          updateCharm={updateCharm}
          updateRune={updateRune}
        />
        <SidebarInfo axie={axie} midSized={midSized} />
      </Stack>

      <RuneSlot
        axie={axie}
        data={axie.rune}
        updateRune={updateRune}
        handleOpenRuneDialog={handleOpenRuneDialog}
        view={view}
      />
    </Wrapper>
  );
};

export default Sidebar;
