import React, { useState } from "react";
import { addBreedingAxieId } from "store/breedingSimulator/breedingSimulatorActions";
import { connect } from "react-redux";
import RoninAddressDialog from "./RoninAddressDialog";
import { BiImport } from "react-icons/bi";
import Panel from "../atoms/Panel";
import { Stack, TextField, Button } from "@mui/material";
import Or from "../atoms/Or";
import Title from "../atoms/Title";

const AddIds = ({
  addBreedingAxieId,
  breedingIds,
  setOpenSelectAxiesDialog,
}) => {
  const [axieId, setAxieId] = useState("");
  const [openRoninAddressDialog, setOpenRoninAddressDialog] = useState(false);

  const handleAddBreedingAxieId = (e) => {
    e.preventDefault();

    if (
      axieId !== "" &&
      Number.isInteger(parseFloat(axieId)) &&
      !breedingIds.includes(axieId)
    ) {
      if (parseInt(axieId) < 1) {
        setAxieId("");
      } else {
        addBreedingAxieId(axieId);
        setAxieId("");
      }
    } else {
      setAxieId("");
    }
  };

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleAddBreedingAxieId(e);
    }
  };

  return (
    <Panel>
      <RoninAddressDialog
        open={openRoninAddressDialog}
        setOpen={setOpenRoninAddressDialog}
        setOpenAxieDialog={setOpenSelectAxiesDialog}
      />
      <Stack gap={2}>
        <Title />

        <form onKeyPress={(e) => handleKeypress(e)}>
          <Stack
            flexWrap="wrap"
            direction={{ xs: "row", sm: "row", md: "column" }}
            alignItems="center"
            gap={2}
          >
            <Stack gap={1} direction="row">
              <TextField
                variant="filled"
                size="small"
                type="number"
                spellCheck={false}
                onChange={(e) => setAxieId(e.target.value)}
                onWheel={(e) => e.target.blur()}
                value={axieId}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    min: 0,
                  },
                }}
                hiddenLabel
                autoComplete="off"
                placeholder="Axie ID"
                sx={{
                  "& .MuiFilledInput-root ": {
                    width: "140px",
                    height: 47,
                  },
                }}
              />
              <Button onClick={handleAddBreedingAxieId} variant="contained">
                Add
              </Button>
            </Stack>

            <Or />

            <Button
              onClick={() => setOpenRoninAddressDialog(true)}
              sx={{
                alignSelf: "stretch",
                justifyContent: "center",
                height: "48px",
              }}
              variant="outlined"
              icon={<BiImport />}
            >
              Import
            </Button>
          </Stack>
        </form>
      </Stack>
    </Panel>
  );
};

const mapDispatchToProps = {
  addBreedingAxieId,
};

const mapStateToProps = (state) => {
  return {
    breedingIds: state.breedingSimulator.ids,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIds);
