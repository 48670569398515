import React from "react";
import { Stack } from "@mui/material";
import SectionLabel from "pages/Inspector/atoms/SectionLabel";
import AxieV3 from "components/Axie/AxieV3/AxieV3";

const Parents = ({ parentAxies, isOrigin }) => {
  return isOrigin ? (
    <></>
  ) : (
    <Stack gap={2}>
      <SectionLabel>Parents</SectionLabel>
      <Stack
        direction="row"
        gap={2}
        flexWrap={{ xs: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" }}
        justifyContent={{ xs: "center", sm: "flex-start", md: "flex-start" }}
      >
        {parentAxies != null &&
          parentAxies.map((axie, i) => (
            <AxieV3 axie={axie} key={`AXIE_${i}`} width={280} />
          ))}
      </Stack>
    </Stack>
  );
};

export default Parents;
