import React from "react";
import { BREEDING_EVENT_PART_LIST, BREEDING_EVENT_SKIN_LIST } from "config";
import { Stack, useTheme, Tooltip, Typography } from "@mui/material";
import { BREEDING_EVENT_PART_PAIRS } from "config";
import { isBreedingEventActive } from "helpers/time";

const PartNameText = ({ text, color, width }) => {
  const theme = useTheme();
  if (isBreedingEventActive()) {
    const specialParts = BREEDING_EVENT_PART_LIST;
    const skins = BREEDING_EVENT_SKIN_LIST;

    if (text && specialParts.includes(text)) {
      const pairs = BREEDING_EVENT_PART_PAIRS.filter((p) =>
        p.includes(text)
      ).flat();
      const matchingParts = pairs.filter((p) => p !== text);

      return (
        <Tooltip
          title={`Breed ${text} with ${matchingParts.join(
            " or "
          )} for a chance to get a summer skin!`}
        >
          <Stack
            sx={{
              background: theme.palette.colors.events.summerBreeding,
              borderRadius: 1,
              px: (0.25 / 320) * width,
              py: (0.25 / 320) * width,
            }}
          >
            <Stack
              sx={{
                background:
                  theme.palette.mode === "light"
                    ? theme.palette.card.pop1
                    : theme.palette.card.shade2,
                borderRadius: 0.7,
                px: (0.25 / 320) * width,
              }}
            >
              <Typography
                fontSize={(12 / 320) * width}
                fontWeight={600}
                variant="span"
                sx={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: 1.5,
                  color,
                }}
              >
                {text}
              </Typography>
            </Stack>
          </Stack>
        </Tooltip>
      );
    }
    if (text && skins.includes(text.replace(" Shiny", ""))) {
      return (
        <Stack
          sx={{
            background: theme.palette.colors.events.summerBreeding,
            borderRadius: 1,
            px: (0.5 / 320) * width,
            py: (0.25 / 320) * width,
          }}
        >
          <Typography
            fontSize={(12 / 320) * width}
            fontWeight={600}
            variant="span"
            sx={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: 1.5,
              color: "#000",
            }}
          >
            {text}
          </Typography>
        </Stack>
      );
    }
  }

  return (
    <Typography
      fontSize={(12 / 320) * width}
      fontWeight={600}
      variant="span"
      sx={{
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: 1.5,
        color,
      }}
    >
      {text}
    </Typography>
  );
};

export default PartNameText;
